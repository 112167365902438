import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import store from '../../../../redux/store';
import '../AdTemplate2/Template2.scss';
class Template2 extends Component {
  header = store.getState().header.header;
  advertisementObject = {
    heading: 'Heading',
    subHeading: 'Subheading',
    message: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
    website: '',
  };
  constructor(props) {
    // let pageId = props.pageId;
    let action = props.action;
    let advertisementDetails = props.data;
    console.log('action', action);
    super(props);
    this.state = {
      action: action,
      sponsorUser: this.sponsorUser,
      heading: advertisementDetails ? advertisementDetails.template.heading : '',
      subHeading: advertisementDetails ? advertisementDetails.template.subheading : '',
      message: advertisementDetails ? advertisementDetails.template.message : '',
      website: advertisementDetails ? advertisementDetails.template.website : '',

      action: this.props.action,
      onChange: this.props.onChange,
      imagePosition: '',
      leftImageFile: this.props.data ? this.props.data.template.leftimage : '',
      backgroundImageFile: this.props.data ? this.props.data.template.backgroundimage : '',
      showRemoveButton: false,
    };
  }

  render() {
    return (
      <>
        {this.state.backgroundImageFile !== '' ? (
          <>
            <header
              id="Ad01-header"
              style={{
                display: 'block',
                textAlign: 'center',
                color: ' #fff',
                width: '100%',
                backgroundImage: `url(${`data:image/png;base64,${this.state.backgroundImageFile}`})`,
                overflowy: 'scroll',
                overflowx: 'hidden',
                minHeight: '120px',
              }}
              className="templateBackground"
            >
              <h3 id="Ad01-h1"> {this.state.heading}</h3>
              <h4 id="Ad01-h2">{this.state.subHeading}</h4>
              <p id="Ad01-p" style={{ marginBottom: '0em' }}>
                {this.state.message}
              </p>
              <a
                href={this.props.data.template.website}
                target="_blank"
                style={{
                  textTransform: 'lowercase',
                  cursor: 'pointer',
                }}
              >
                {this.props.data.template.website}
              </a>
            </header>
          </>
        ) : (
          <header id="Ad01-header">
            <h3 id="Ad01-h1"> {this.state.heading}</h3>
            <h4 id="Ad01-h2">{this.state.subHeading}</h4>
            <p id="Ad01-p" style={{ marginBottom: '0em' }}>
              {this.state.message}
            </p>
            <a
              href={this.props.data.template.website}
              target="_blank"
              style={{
                textTransform: 'lowercase',
                cursor: 'pointer',
              }}
            >
              {this.props.data.template.website}
            </a>
          </header>
        )}
      </>
    );
  }
}

export default Template2;
