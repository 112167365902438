import { CLEAR_MEMBER_Home_URL, GET_MEMBER_Home_URL, SET_MEMBER_Home_URL } from './memberHomeUrlTypes';

const initialState = {
  memberHomeUrl: {},
};

const memberHomeUrlReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MEMBER_Home_URL:
      return {
        ...state,
      };
    case SET_MEMBER_Home_URL:
      return {
        ...state,

        memberHomeUrl: action.payload,
      };

    case CLEAR_MEMBER_Home_URL:
      return {
        ...state,
        memberData: null,
      };
    default:
      return state;
  }
};

export default memberHomeUrlReducer;
