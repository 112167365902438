import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import FetchServerData from '../../../../provider/FetchServerData';
import Utils from '../../../../provider/Utils';
import store from '../../../../redux/store';
import '../../contactUs/assets/vendor/animate.css/animate.min.css';
import '../../contactUs/assets/vendor/bootstrap/css/bootstrap.min.css';
import '../../contactUs/assets/vendor/boxicons/css/boxicons.min.css';
import '../../contactUs/assets/vendor/icofont/icofont.min.css';
import '../../contactUs/assets/vendor/line-awesome/css/line-awesome.min.css';
import '../../contactUs/assets/vendor/owl.carousel/assets/owl.carousel.min.css';
import '../../contactUs/assets/vendor/remixicon/remixicon.css';
import '../../contactUs/assets/vendor/venobox/venobox.css';
import MenuWithBreadScrum from '../../menu/MenuWithBreadScrum';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import './Success.scss';
import appUserDataAction from '../../../../redux/appUserData/appUserDataAction';
const Success = (props) => {
  let title = 'Payment Success';
  let breadCrumb = [{ pageid: '/profileHome', uiname: 'Payment Success' }];

  const [orderList, setOrderList] = useState([]);
  const [orderDetails, setOrderDetails] = useState({});
  const [setOneTime] = useState(false);
  const [setSubscription] = useState(false);
  const location = useLocation();
  let sessionId = location.search.replace('?session_id=', '');
  let params = queryString.parse(location.search);
  sessionId = params.session_id;
  useEffect(() => {
    async function fetchSession() {
      let userData = store.getState().userData.userData;
      let header = store.getState().header.header;
      let inputObj = {
        user: {
          email: userData.email,
        },
        header: header,
        sessionId: sessionId,
      };
      FetchServerData.callPostService('/pg/paymentSuccessful', inputObj)
        .then((output) => {
          if (output.status === 'SUCCESS') {
            let responseData = output.data;
            let orderItemList = [];
            let orderData = {};
            if (responseData) {
              orderItemList = responseData.order.orderItems;
              orderData = responseData.member;
            }
            store.dispatch(appUserDataAction.setUser(responseData.userDetails));
            setOrderList(orderItemList);
            setOrderDetails(orderData);
            // setSession(sessionDetails);
            if (responseData.order.type === 'OneTime') {
              setOneTime(true);
              setSubscription(false);
            } else {
              setOneTime(false);
              setSubscription(true);
            }
          }
        })
        .catch((error) => {});
    }
    fetchSession();
  }, [sessionId]);
  // const BackToHome = () => {
  //   this.props.history.push({
  //     pathname: '/home',
  //   });
  // };

  let itemRow1 = orderList.map((report, i) => {
    return (
      <>
        <div data-aos="zoom-in" data-aos-delay={300}>
          <div className="success_billing_plan-inner">
            <div className="entry-title">
              <h3 style={{ padding: '7px', textTransform: 'capitalize' }}>
                {report !== null ? report.description : ''}{' '}
              </h3>
              <div className="price">
                <span style={{ left: '7px' }}>{report !== null ? '$' + report.price : ''}</span>
              </div>
            </div>
            <div className="entry-content">
              <ul>
                {/* <li>
                  <p>Upto 3 Family Members can be added in this Subscription</p>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  });
  return (
    <div>
      <MenuWithBreadScrum breadCrumb={breadCrumb} history={props.history} name={title} headerWidth="home" />

      <section id="pricing" className="pricing" style={{ padding: '1em' }}>
        <Row>
          <Col md={12} style={{ textAlign: 'left' }}>
            <Link to="/home">
              <Button
                style={{
                  background: '#f58d51',
                  fontSize: '0.8em',
                  webkitBorderRadius: '10px',

                  margin: 'auto',
                  marginRight: '5px',
                  borderBottom: '2px solid #636262',
                  color: '#fff',
                }}
                // onClick={BackToHome}
                // onClick={handleClick}
              >
                <ArrowBackIcon style={{ fontSize: '1.3em' }} /> Back To Home
              </Button>
            </Link>
          </Col>
        </Row>
        <Row style={{ marginTop: '25px' }}>
          <Col md={1}></Col>
          <Col md={7}>
            <Card
              style={{
                height: '100%',
                boxShadow:
                  'rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px',
              }}
            >
              <CardHeader
                style={{
                  background: '#2b7d6d',
                  color: '#fff',
                  padding: '10px !important',
                }}
              >
                Personal Details
              </CardHeader>
              <CardBody>
                <Row
                  style={{
                    margin: 0,
                    padding: '3px',
                    borderBottom: '0.5px solid #f3e5e5',
                  }}
                >
                  <Col md={2}>Name</Col>
                  <Col md={10} style={{ textAlign: 'left' }}>
                    {orderDetails.firstName ? orderDetails.firstName : ''}{' '}
                    {orderDetails.lastName ? orderDetails.lastName : ''}
                  </Col>
                </Row>
                <Row
                  style={{
                    margin: 0,
                    padding: '3px',
                    borderBottom: '0.5px solid #f3e5e5',
                  }}
                >
                  <Col md={2}>Email</Col>
                  <Col md={10} style={{ textAlign: 'left' }}>
                    {orderDetails.email ? orderDetails.email : ''}
                  </Col>
                </Row>
                <Row
                  style={{
                    margin: 0,
                    padding: '3px',
                    borderBottom: '0.5px solid #f3e5e5',
                  }}
                >
                  <Col md={2}>Phone</Col>
                  <Col md={10} style={{ textAlign: 'left' }}>
                    {orderDetails.phone === null || orderDetails.phone === undefined
                      ? ''
                      : Utils.displayUsaPhoneNumberFormate(orderDetails.phone)}
                  </Col>
                </Row>
                <Row
                  style={{
                    margin: 0,
                    padding: '3px',
                  }}
                >
                  <Col md={2}>Address</Col>
                  <Col md={10} style={{ textAlign: 'left' }}>
                    {Utils.getAddressAsText(orderDetails.address)}
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>

          <Col md={3} style={{ margin: '0em 0em 0em 2em', padding: '0em' }}>
            {itemRow1}
          </Col>
          <Col md={1}></Col>
        </Row>
      </section>
    </div>
  );
};

export default Success;
