/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React, { Component } from 'react';
// reactstrap components
import { withRouter } from 'react-router-dom';
import { DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import adminFccPageStateAction from '../../../redux/adminPanel/adminFccPageState/adminFccPageStateAction';

import adminAppUserDataAction from '../../../redux/adminPanel/appUserData/adminAppUserDataAction';
import adminHeaderAction from '../../../redux/adminPanel/header/adminHeaderAction';
import paidAdvertiserDetailsAction from '../../../redux/adminPanel/paidAdvertiserDetails/paidAdvertiserDetailsAction';
import sponsorDetailsAction from '../../../redux/adminPanel/sponsorDetails/sponsorDetailsAction';
import adminStaticDataAction from '../../../redux/adminPanel/staticData/adminStaticDataAction';
import store from '../../../redux/store';
import routes from '../../../views';
import AdminHeader from '../adminHeader/AdminHeader';
import './AdminNavbar.scss';
import applicationStateAction from '../../../redux/applicationState/applicationStateAction';
import ActiveMenuAction from '../../../redux/activeMenu/ActiveMenuAction';
class AdminNavbar extends Component {
  header = store.getState().adminHeader.adminHeader;
  userDetails = store.getState().adminUserDetails.adminUserDetails;
  constructor(props) {
    super(props);
    let sponsorshipId = props.sponsorshipId;

    // let action = props.action;
    // let title = '';
    // let pageId = '';
    if (!sponsorshipId && props.history && props.history.location && props.history.location.state) {
      sponsorshipId = props.history.location.state.sponsorshipId;
      // action = props.history.location.state.action;
      // pageId = props.history.location.state.pageId;
      // title = props.history.location.state.title;
    }
    // let canRender = false;

    if (null !== sponsorshipId && sponsorshipId > 0) {
      this.getSponsorDetails(sponsorshipId);
    } else {
      // canRender = true;
    }
    this.state = {};
  }

  logoutHandler = (e) => {
    if (this.userDetails.isUserLogin === 'admin') {
      this.adminLogoutHandler();
    } else if (this.userDetails.isUserLogin === 'sponsor') {
      this.sponsorLogoutHandler();
    } else if (this.userDetails.isUserLogin === 'paidAdvertiser') {
      this.paidAdvertiserLogoutHandler();
    }
  };
  adminLogoutHandler = (e) => {
    let postObject = {
      header: this.header,
      appuser: {
        id: this.userDetails.id,
        email: this.userDetails.email,
      },
    };
    FetchServerData.callPostService('/adminpanelAppuserms/logout', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        this.props.history.push({
          pathname: '/APLogin',
        });

        store.dispatch(sponsorDetailsAction.setSponsor({}));
        store.dispatch(adminStaticDataAction.setAdminStaticData({}));
        store.dispatch(adminHeaderAction.setAdminHeader({}));
        store.dispatch(adminAppUserDataAction.setAdminUser({}));
        store.dispatch(adminFccPageStateAction.setAdminFccPageState({}));
        store.dispatch(paidAdvertiserDetailsAction.setPaidAdvertiser({}));
        store.dispatch(applicationStateAction.setApplicationState({}));
        store.dispatch(ActiveMenuAction.setActiveMenu({}));
      } else {
      }
    });
  };
  sponsorLogoutHandler = (e) => {
    let postObject = {
      header: this.header,
    };
    FetchServerData.callPostService('/sponsoruserms/logout', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        this.props.history.push({
          pathname: '/APSponsorLogin',
        });

        store.dispatch(sponsorDetailsAction.setSponsor({}));
        store.dispatch(adminStaticDataAction.setAdminStaticData({}));
        store.dispatch(adminHeaderAction.setAdminHeader({}));
        store.dispatch(adminAppUserDataAction.setAdminUser({}));
        store.dispatch(adminFccPageStateAction.setAdminFccPageState({}));
        store.dispatch(paidAdvertiserDetailsAction.setPaidAdvertiser({}));
        store.dispatch(ActiveMenuAction.setActiveMenu({}));
      } else {
      }
    });
  };

  paidAdvertiserLogoutHandler = (e) => {
    let postObject = {
      header: this.header,
    };
    FetchServerData.callPostService('/paidadvertiseruserms/logout', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        this.props.history.push({
          pathname: '/APPaidAdvertiserLogin',
        });

        store.dispatch(sponsorDetailsAction.setSponsor({}));
        store.dispatch(adminStaticDataAction.setAdminStaticData({}));
        store.dispatch(adminHeaderAction.setAdminHeader({}));
        store.dispatch(adminAppUserDataAction.setAdminUser({}));
        store.dispatch(adminFccPageStateAction.setAdminFccPageState({}));
        store.dispatch(paidAdvertiserDetailsAction.setPaidAdvertiser({}));
        store.dispatch(ActiveMenuAction.setActiveMenu({}));
      } else {
      }
    });
  };
  render() {
    return (
      <>
        <AdminHeader
          toggleSidebar={this.props.toggleSideCollapse}
          isSidebarCollapsed={this.props.sidebarCollapsed}
          routes={routes}
          {...this.props}
          history={this.props.history}
        >
          <React.Fragment>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav>
                <span title="Logout" onClick={(e) => this.logoutHandler(e)}>
                  <i className="fa fa-sign-out" style={{ color: '#fff', fontSize: '18px' }} />
                </span>
              </DropdownToggle>
            </UncontrolledDropdown>
          </React.Fragment>
        </AdminHeader>
      </>
    );
  }
}

export default withRouter(AdminNavbar);
