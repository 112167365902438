import React, { Component } from 'react';
import {
  Accordion,
  AccordionItem,
  AccordionItemButton,
  AccordionItemHeading,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import Fab from '@material-ui/core/Fab';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import { Col, Modal, ModalBody, Row } from 'reactstrap';
import CloseIcon from '@material-ui/icons/Close';
import { Container } from 'react-floating-action-button';
import Appointment from './assets/img/Appointment03.png';
import Communication from './assets/img/Communications03.png';
import CaregiverList from './assets/img/CaregiverCircle03.jpg';
import Event from './assets/img/Event01.png';
import Incident from './assets/img/Incident03.png';
import Home from './assets/img/Homepage04.jpg';
import Purchase from './assets/img/Purchase04.png';
import Calender from './assets/img/Calender05.png';
// Demo styles, see 'Styles' section below for some notes on use.
import 'react-accessible-accordion/dist/fancy-example.css';
import { Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import './assets/vendor/animate.css/animate.min.css';
import './assets/vendor/bootstrap/css/bootstrap.min.css';
import './assets/vendor/boxicons/css/boxicons.min.css';
import './assets/vendor/icofont/icofont.min.css';
import './assets/vendor/line-awesome/css/line-awesome.min.css';
import './assets/vendor/owl.carousel/assets/owl.carousel.min.css';
import './assets/vendor/remixicon/remixicon.css';
import './assets/vendor/venobox/venobox.css';
import './FccHome.scss';
import FccAdvertisement from '../fccAdvertisement/FccAdvertisement';
import AppleStore from './assets/img/appleStore.png';
import GooglePlayStore from './assets/img/googlePlayStore.png';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import FetchServerData from '../../../provider/FetchServerData';

// Demo styles, see 'Styles' section below for some notes on use.
// import 'react-accessible-accordion/dist/fancy-example.css';
class FccHome extends Component {
  constructor(props) {
    super(props);
    let str = window.location.href;
    let url = '';
    let urlArr = '';
    let urlEmail = '';
    let visitorUrlCode = '';
    let newVisitorCode = '';
    console.log('str', str);
    if (str !== undefined) {
      url = str.split('=');
      if (url.length > 1) {
        urlArr = str.split('?');
        urlEmail = urlArr[1].split('=');
        visitorUrlCode = urlEmail[1];
        newVisitorCode = visitorUrlCode.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
      }
    }
    if (newVisitorCode) {
      this.visitor(newVisitorCode);
    }
    this.state = {
      headerClass: 'fixed-top d-flex align-items-center header-transparent',
      sliderTest: [
        {
          heading: ' Welcome to Family Care Circle',
          desc: 'We are focused on improving communication between a Members Caregivers and Friends!',
        },
        {
          heading: 'Family Member Care',
          desc: 'Track communication between family members related to the care of your loved ones.',
        },
        {
          heading: 'Appointment Management',
          desc: 'Track your loved ones appointments, key notes to remember for the appointment, plan transportation effectively and share information with caregivers about the appointment.',
        },
        {
          heading: 'Purchase Management',
          desc: 'Keep track of purchases of medical supplies or medical equipment',
        },
        {
          heading: 'Incident Management',
          desc: 'Log incidents and symptoms for tracking purposes, which can help doctors in diagnosis and treatment.',
        },
        {
          heading: 'Communication Management',
          desc: 'Communication, among the circle of family members is Important for particular appointments and in general.',
        },
      ],
      menuClassName: '',
      homeActiveClass: 'active',
      menuList: false,
      isOpen: false,
      isOpenHomeImage: false,
      isOpenCommunication: false,
      isOpenAppointment: false,
      isOpenIncident: false,
      isOpenPurchase: false,
      isOpenCalender: false,
      isOpenEventImage: false,
      visitorCode: newVisitorCode,
      activeElement: '',
    };
  }

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll);
  }

  /*if visitor enter the visitor url the call this service*/
  visitor(newVisitorCode) {
    // make API call
    const postObject = {
      visitorCode: newVisitorCode,
    };
    FetchServerData.callPostService('visitorms/create', postObject)
      .then((response) => {
        let output = response;
        if (output.status === 'SUCCESS') {
          this.props.history.push({
            pathname: '/',
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  handleScroll = () => {
    let scroll = document.getElementsByClassName('landingPage');
    if (window.pageYOffset > 0) {
      if (this.state.className === 'fixed-top d-flex align-items-center header-transparent') {
        this.setState({ className: 'fixed-top d-flex align-items-center header-transparent header-scrolled' });
      }
    } else {
      if (this.state.className !== 'fixed-top d-flex align-items-center header-transparent') {
        this.setState({ className: 'fixed-top d-flex align-items-center header-transparent' });
      }
    }
  };
  handleShowDialog = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  handleEventShowDialog = () => {
    this.setState({ isOpenEventImage: !this.state.isOpenEventImage });
  };

  handleShowHomeDialog = () => {
    this.setState({ isOpenHomeImage: !this.state.isOpenHomeImage });
  };

  handleShowCommunicationDialog = () => {
    this.setState({ isOpenCommunication: !this.state.isOpenCommunication });
  };

  handleShowAppointmentDialog = () => {
    this.setState({ isOpenAppointment: !this.state.isOpenAppointment });
  };

  handleShowIncidentDialog = () => {
    this.setState({ isOpenIncident: !this.state.isOpenIncident });
  };

  handleShowPurchaseDialog = () => {
    this.setState({ isOpenPurchase: !this.state.isOpenPurchase });
  };

  handleShowCalenderDialog = () => {
    this.setState({ isOpenCalender: !this.state.isOpenCalender });
  };

  menuListToggle = () => {
    this.setState({
      menuList: !this.state.menuList,
    });
  };

  openMenu() {
    this.setState({
      menuList: true,
    });
  }

  navigateToLink(linkToNavigate) {
    console.log('linkToNavigate', linkToNavigate);
    this.setState({
      activeElement: linkToNavigate,
    });
    this.props.history.push(linkToNavigate);
  }
  openLoginTab = () => {
    const win = window.open('/#/login', '_blank');
    win.focus();
  };
  scrollToSection(elementID) {
    this.setState({
      activeElement: elementID,
    });
    console.log('elementID', elementID);
    if (document.getElementById(elementID)) {
      document.getElementById(elementID).scrollIntoView();
    }
  }
  render() {
    const zoomOutProperties = {
      indicators: false,
      scale: 0.2,
      duration: 2000,
      arrows: false,
    };
    let url = window.location.href;
    let urlArr = url.split('/');
    let activeUrl = urlArr[4];
    console.log(activeUrl, 'activeUrl');
    return (
      <>
        <div style={{ height: '100vh', overflowy: 'scroll' }} className="landingPage">
          <link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0-alpha.6/css/bootstrap.min.css" rel="stylesheet" />
          <link href="//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css" rel="stylesheet" />

          {/* =======================================================
  * Template Name: Selecao - v2.3.1
  * Template URL: https://bootstrapmade.com/selecao-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== */}
          {/* ======= Header ======= */}
          <header id="header" className={this.state.headerClass}>
            <div className="container d-flex align-items-center">
              <div className="logo mr-auto">
                <span style={{ display: 'flex' }}>
                  <img
                    src="../assets/img/fccLogo.png"
                    alt=""
                    style={{ height: '5em', width: '2.7em', marginTop: '-6px', fontSize: '16px' }}
                  />
                  <h1 className="text-light" style={{ paddingLeft: '0.2em' }}>
                    <a href="/">Family Care Circle</a>
                  </h1>
                </span>
              </div>
              <nav className="nav-menu d-none d-lg-block">
                <ul>
                  <li
                    className={
                      this.state.activeElement === '/' ||
                      this.state.activeElement === 'hero' ||
                      this.state.activeElement === ''
                        ? this.state.homeActiveClass
                        : ''
                    }
                  >
                    <a
                      style={{ color: '#fff', cursor: 'pointer' }}
                      onClick={() => this.scrollToSection('hero')}
                      // onClick={() => this.navigateToLink('/')}
                    >
                      Home
                    </a>
                  </li>
                  <li className={this.state.activeElement === 'about' ? this.state.homeActiveClass : ''}>
                    <a style={{ color: '#fff', cursor: 'pointer' }} onClick={() => this.scrollToSection('about')}>
                      About
                    </a>
                  </li>
                  <li className={this.state.activeElement === 'services' ? this.state.homeActiveClass : ''}>
                    <a style={{ color: '#fff', cursor: 'pointer' }} onClick={() => this.scrollToSection('services')}>
                      Services
                    </a>
                  </li>
                  <li>
                    <a style={{ color: '#fff', cursor: 'pointer' }} onClick={() => this.openLoginTab()} target="_blank">
                      Login
                    </a>
                  </li>
                  <li>
                    <a style={{ color: '#fff', cursor: 'pointer' }} onClick={() => this.navigateToLink('/pricing')}>
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a
                      style={{ color: '#fff', cursor: 'pointer' }}
                      onClick={() => this.navigateToLink('/BusinessPromotion')}
                    >
                      Sponsorship
                    </a>
                  </li>
                  <li>
                    <a style={{ color: '#fff', cursor: 'pointer' }} onClick={() => this.navigateToLink('/contactUs')}>
                      Contact
                    </a>
                  </li>
                </ul>
              </nav>
              {/* .nav-menu */}
            </div>
          </header>
          <button
            type="button"
            className="mobile-nav-toggle d-lg-none"
            style={{ marginRight: '0.7em' }}
            onClick={(e) => this.openMenu()}
          >
            <i className="fa fa-bars" style={{ fontSize: '1.5em' }}></i>
          </button>
          {/* End Header */}
          {/* ======= Hero Section ======= */}

          <section id="hero">
            <div className="slide-container" id="fccSlider">
              <Zoom {...zoomOutProperties}>
                {this.state.sliderTest.map((each, index) => (
                  <div key={index} style={{ width: '100%' }}>
                    <h2>{each.heading}</h2>
                    <p style={{ fontSize: '18px' }}>{each.desc}</p>
                    <a
                      style={{ cursor: 'pointer', backgroundColor: '#ef6603' }}
                      onClick={() => this.scrollToSection('about')}
                      className="btn-get-started"
                    >
                      Read More
                    </a>
                  </div>
                ))}
              </Zoom>
            </div>
          </section>
          {/* End Hero */}
          <main id="main">
            {/* ======= About Section ======= */}

            <section id="about" className="about">
              <div className="row" style={{ margin: '1em' }}>
                <div className="col-md-12">
                  <FccAdvertisement advertisementType="Horizontal" />
                </div>
              </div>
              <div className="container" style={{ fontFamily: 'sans-serif, sans-serif' }}>
                <div className="section-title">
                  <h2>About</h2>
                  <p>Who we are</p>
                </div>
                <div className="row content">
                  <div className="col-lg-6">
                    <p>
                      <b>We are also available on</b>{' '}
                      <a
                        style={{ color: '#fff', cursor: 'pointer' }}
                        href="https://apps.apple.com/us/app/family-care-circle/id1602521967"
                        target="_blank"
                      >
                        <img src={AppleStore} style={{ height: '35px' }} />
                      </a>
                      &nbsp;&nbsp;
                      <a
                        target="_blank"
                        href="https://play.google.com/store/apps/details?id=com.familycarecircle.fccmobileapp"
                        style={{ color: '#fff', cursor: 'pointer' }}
                      >
                        <img src={GooglePlayStore} style={{ height: '35px' }} />
                      </a>
                    </p>
                    {/* <ul><li>  <a style={{ color: '#fff', cursor: 'pointer' }} onClick={() => this.navigateToLink('/contactUs')}>
                     <img src={AppleStore} style={{height:'40px'}}/>
                    </a>


                    <a style={{ color: '#fff', cursor: 'pointer' }} onClick={() => this.navigateToLink('/contactUs')}>
                     <img src={GooglePlayStore} style={{height:'40px'}}/>
                    </a></li></ul> */}
                    <p>
                      The key to the best family care of a loved one is Communication. Let us help you track
                      appointments, notes, incidents, transportation needs and more. Family Care Circle will enable you
                      have notes ready to discuss at every Doctor's appointment with confidence.
                    </p>
                    <ul>
                      <li>
                        <i className="fa fa-check" /> Track communication between family members related to Family Care
                        of your loved ones.
                      </li>
                      <li>
                        <i className="fa fa-check" /> Track your loved ones appointments and key notes to remember for
                        &amp; from the appointments. Share important information with family about the appointment and
                        plan transportation effectively.
                      </li>
                      <li>
                        <i className="fa fa-check" />
                        Log and track Incidents to help your loved ones keep up with a member's schedule and health
                        status.
                      </li>
                    </ul>
                    <p>Take care of your loved one with confidence!</p>
                    <a
                      style={{ cursor: 'pointer' }}
                      onClick={() => this.scrollToSection('services')}
                      className="btn-learn-more"
                    >
                      Learn More
                    </a>
                  </div>
                  <div className="col-lg-6 pt-4 pt-lg-0">
                    <img src="./assets/img/aboutUs.jpg" />
                  </div>
                </div>
              </div>
            </section>
            {/* End About Section */}
            {/* =======Virtual Reports End ======= */}
            <section id="graphics" className="py-5" style={{ backgroundColor: '#000' }}>
              <div className="container graphics py-4">
                {/* <div className="section-title-about" style={{ color: '#fff', fontSize: '15px' }}>
                  <h2>Insights of Member Activities</h2>
                </div> */}
                <div className="section-title">
                  <h2>Insights</h2>
                  <p style={{ color: '#fff' }}>Insights of Member Activities</p>
                </div>
                <div className="row py-4">
                  <div className="col-lg-12 col-md-12 col-ms-12 col-xs-12"></div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-ms-12 col-xs-12 ">
                    <div className="card text-white mb-4" data-aos="fade-up" style={{ backgroundColor: '#82e0aa' }}>
                      <div className="card-body" style={{ padding: '5px !important' }}>
                        <h4 className="card-title text-center">Home</h4>
                        <img
                          src={Home}
                          style={{ height: '20em' }}
                          className="img-fluid"
                          onClick={this.handleShowHomeDialog}
                        />
                        {this.state.isOpenHomeImage && (
                          <Dialog
                            className="imageDialog"
                            style={{ position: 'absolute' }}
                            open
                            onClick={this.handleShowHomeDialog}
                          >
                            <DialogContent style={{ padding: '0px' }}>
                              <img className="image" src={Home} onClick={this.handleShowHomeDialog} alt="no image" />
                            </DialogContent>
                          </Dialog>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-ms-12 col-xs-12 ">
                    <div className="card text-white mb-4" data-aos="fade-left" style={{ backgroundColor: '#82e0aa' }}>
                      <div className="card-body" style={{ padding: '5px !important' }}>
                        <h4 className="card-title text-center">Member Calender</h4>
                        <img
                          src={Calender}
                          style={{ height: '20em' }}
                          className="img-fluid"
                          onClick={this.handleShowCalenderDialog}
                        />
                        {this.state.isOpenCalender && (
                          <Dialog
                            className="imageDialog"
                            style={{ position: 'absolute' }}
                            open
                            onClick={this.handleShowCalenderDialog}
                          >
                            <DialogContent style={{ padding: '0px' }}>
                              <img
                                className="image"
                                src={Calender}
                                onClick={this.handleShowCalenderDialog}
                                alt="no image"
                              />
                            </DialogContent>
                          </Dialog>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-ms-12 col-xs-12 ">
                    <div className="card text-white mb-4" data-aos="fade-up" style={{ backgroundColor: '#82e0aa' }}>
                      <div className="card-body" style={{ padding: '5px !important' }}>
                        <h4 className="card-title text-center">Communications</h4>
                        <img
                          src={Communication}
                          onClick={this.handleShowCommunicationDialog}
                          style={{ height: '20em' }}
                          className="img-fluid"
                        />
                        {this.state.isOpenCommunication && (
                          <Dialog
                            className="imageDialog"
                            style={{ position: 'absolute' }}
                            open
                            onClick={this.handleShowCommunicationDialog}
                          >
                            <DialogContent style={{ padding: '0px' }}>
                              <img
                                className="image"
                                src={Communication}
                                onClick={this.handleShowCommunicationDialog}
                                alt="no image"
                              />
                            </DialogContent>
                          </Dialog>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-ms-12 col-xs-12 ">
                    <div className="card text-white mb-4" data-aos="fade-left" style={{ backgroundColor: '#82e0aa' }}>
                      <div className="card-body" style={{ padding: '5px !important' }}>
                        <h4 className="card-title text-center">Appointment Management</h4>
                        <img
                          src={Appointment}
                          // src="assets/.png"
                          style={{ height: '20em' }}
                          className="img-fluid"
                          onClick={this.handleShowAppointmentDialog}
                        />
                        {this.state.isOpenAppointment && (
                          <Dialog
                            className="imageDialog"
                            style={{ position: 'absolute' }}
                            open
                            onClick={this.handleShowAppointmentDialog}
                          >
                            <DialogContent style={{ padding: '0px' }}>
                              <img
                                className="image"
                                src={Appointment}
                                onClick={this.handleShowAppointmentDialog}
                                alt="no image"
                              />
                            </DialogContent>
                          </Dialog>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-ms-12 col-xs-12 ">
                    <div className="card text-white mb-4" data-aos="fade-up" style={{ backgroundColor: '#82e0aa' }}>
                      <div className="card-body" style={{ padding: '5px !important' }}>
                        <h4 className="card-title text-center">Incident Management</h4>
                        <img
                          src={Incident}
                          onClick={this.handleShowIncidentDialog}
                          style={{ height: '20em' }}
                          className="img-fluid"
                        />
                        {this.state.isOpenIncident && (
                          <Dialog
                            className="imageDialog"
                            style={{ position: 'absolute' }}
                            open
                            onClick={this.handleShowIncidentDialog}
                          >
                            <DialogContent style={{ padding: '0px' }}>
                              <img
                                className="image"
                                src={Incident}
                                onClick={this.handleShowIncidentDialog}
                                alt="no image"
                              />
                            </DialogContent>
                          </Dialog>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-ms-12 col-xs-12 ">
                    <div className="card text-white mb-4" data-aos="fade-left" style={{ backgroundColor: '#82e0aa' }}>
                      <div className="card-body" style={{ padding: '5px !important' }}>
                        <h4 className="card-title text-center">Purchase Management</h4>
                        <img
                          src={Purchase}
                          onClick={this.handleShowPurchaseDialog}
                          style={{ height: '20em' }}
                          className="img-fluid"
                        />
                        {this.state.isOpenPurchase && (
                          <Dialog
                            className="imageDialog"
                            style={{ position: 'absolute' }}
                            open
                            onClick={this.handleShowPurchaseDialog}
                          >
                            <DialogContent style={{ padding: '0px' }}>
                              <img
                                className="image"
                                src={Purchase}
                                onClick={this.handleShowPurchaseDialog}
                                alt="no image"
                              />
                            </DialogContent>
                          </Dialog>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-ms-12 col-xs-12 ">
                    <div className="card text-white mb-4" data-aos="fade-up" style={{ backgroundColor: '#82e0aa' }}>
                      <div className="card-body" style={{ padding: '5px !important' }}>
                        <h4 className="card-title text-center">Caregiver Management</h4>
                        <img
                          src={CaregiverList}
                          style={{ height: '20em' }}
                          className="img-fluid"
                          onClick={this.handleShowDialog}
                        />
                        {this.state.isOpen && (
                          <Dialog
                            className="imageDialog"
                            style={{ position: 'absolute' }}
                            open
                            onClick={this.handleShowDialog}
                          >
                            <DialogContent style={{ padding: '0px' }}>
                              <img
                                className="image"
                                src={CaregiverList}
                                onClick={this.handleShowDialog}
                                alt="no image"
                              />
                            </DialogContent>
                          </Dialog>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-ms-12 col-xs-12 ">
                    <div className="card text-white mb-4" data-aos="fade-up" style={{ backgroundColor: '#82e0aa' }}>
                      <div className="card-body" style={{ padding: '5px !important' }}>
                        <h4 className="card-title text-center">Event Screen</h4>
                        <img
                          src={Event}
                          style={{ height: '20em' }}
                          className="img-fluid"
                          onClick={this.handleEventShowDialog}
                        />
                        {this.state.isOpenEventImage && (
                          <Dialog
                            className="imageDialog"
                            style={{ position: 'absolute' }}
                            open
                            onClick={this.handleEventShowDialog}
                          >
                            <DialogContent style={{ padding: '0px' }}>
                              <img className="image" src={Event} onClick={this.handleEventShowDialog} alt="no image" />
                            </DialogContent>
                          </Dialog>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/*--------------*/}
              </div>
            </section>
            {/* ======= Services Section ======= */}
            <section className="services" id="services">
              <div className="row" style={{ margin: '1em' }}>
                <div className="col-md-12">
                  <FccAdvertisement advertisementType="Horizontal" />
                </div>
              </div>
              <div className="container">
                <div className="section-title" data-aos="zoom-out">
                  <h2>Services</h2>
                  <p style={{ lineHeight: '1.1em' }}>What we offer</p>
                </div>
                <div className="row" style={{ padding: '15px' }}>
                  <div className="col-lg-6 col-md-6 pb-2.8">
                    <div className="icon-box" data-aos="zoom-in-left">
                      <div className="title" style={{ display: 'flex', margin: '0' }}>
                        <i className="fa fa-edit" style={{ color: '#ff689b', fontSize: '2em' }} />
                        {/* <i className="bx bx-edit-alt" style={{ color: '#ff689b', fontSize: '2em' }} /> */}
                        <h3 style={{ cursor: 'initial' }}>&nbsp;Appointment Tracking</h3>
                      </div>
                      {/* <p className="description"> */}
                      <ul className="sectionList">
                        <li>
                          Start by entering a calendar event for an upcoming appointment and ensure transportation is
                          arranged.
                        </li>
                        <li>
                          With Family Care Circle, you can enter notes in advance and associate them with a specific
                          doctor. An example would be sharing the results of a test from a doctor or a change in
                          prescription.
                        </li>
                        <li>
                          Transportation providers will be reminded of an upcoming transportation need and driving
                          directions to the doctor's office.
                        </li>
                        <li>
                          A quick view of all notes that have been made to discuss with the Doctor will be readily
                          available. Never miss an important detail again.
                        </li>
                      </ul>
                      {/* </p> */}
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 mt-5 mt-lg-0 pb-2.8">
                    <div className="icon-box" data-aos="zoom-in-left" data-aos-delay={200}>
                      <div className="icon" />
                      <div className="title" style={{ display: 'flex', margin: '0' }}>
                        <i className="fa fa-calendar" style={{ color: '#f7b04d', fontSize: '2em' }} />
                        <h3 style={{ cursor: 'initial' }}>&nbsp;Consolidated Caregiver Calendar View</h3>
                      </div>
                      <ul className="sectionList">
                        <li>
                          View all appointments, incidents, and events of all your Family Care Circles at a glance.
                        </li>
                        <li>
                          The main advantage of this calendar is that it makes planning, scheduling appointments easy
                          and resolve any schedule conflicts.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 mt-5 mt-md-0 pb-2.8">
                    <div className="icon-box" data-aos="zoom-in-left" data-aos-delay={100}>
                      <div className="icon" />
                      <div className="title" style={{ display: 'flex', margin: '0' }}>
                        <i className="fa fa-comment" style={{ color: '#3fcdc7', fontSize: '2em' }} />
                        <h3 style={{ cursor: 'initial' }}>&nbsp;Communication Tracking</h3>
                      </div>
                      <ul className="sectionList">
                        <li>
                          Communication is key for optimal care. Family Care Circle will help you build and track
                          communication between circle of family members &amp; caregivers.
                        </li>
                        <li>
                          Only users approved by the Family Admin are allowed to participate in the communication.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 mt-5 mt-lg-0 pb-2.8">
                    <div className="icon-box" data-aos="zoom-in-left" data-aos-delay={200}>
                      <div className="icon" />
                      <div className="title" style={{ display: 'flex', margin: '0' }}>
                        <i className="fa fa-exclamation-triangle" style={{ color: '#e9bf06', fontSize: '2em' }} />
                        <h3 style={{ cursor: 'initial' }}>&nbsp;Incident Tracking</h3>
                      </div>
                      <ul className="sectionList">
                        <li> Enter an incident in Family Care Circle when it occurs.</li>
                        <li>
                          Share a communication trail with <b>Approved</b> Family Care Members so everyone is made
                          aware.
                        </li>
                        <li>
                          Associate the incident with a Doctor so you are reminded to share important information with
                          the doctor.
                        </li>
                        <li>
                          Review historical information to clearly see the frequency of the incidents, well-being,
                          reactions, etc. along with an overall view of a member's status.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 mt-5 mt-lg-0 ">
                    <div className="icon-box" data-aos="zoom-in-left" data-aos-delay={300}>
                      <div className="icon" />
                      <div className="title" style={{ display: 'flex', margin: '0' }}>
                        <i className="fa fa-calendar-check-o" style={{ color: '#4982C4', fontSize: '2em' }} />
                        <h3 style={{ cursor: 'initial' }}>&nbsp;Event Tracking</h3>
                      </div>

                      <ul className="sectionList">
                        <li>
                          An event can be social occasion or an activity. Add a calendar entry for the upcoming event
                          and ensure transportation is arranged.
                        </li>
                        <li>
                          With Family Care Circle, you can add notes and start a conversation you’re your caregiver
                          circle. For an example, you are visiting a sick person and would like the family to send
                          flowers and get well wishes along with request help with a transportation need.
                        </li>
                        <li>
                          If Transportation is needed, Transportation providers will be reminded of an upcoming
                          transportation need and with one click they can get driving directions to the hospital, home
                          or whatever address is associated with the Event.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 mt-5 mt-lg-0 ">
                    <div className="icon-box" data-aos="zoom-in-left" data-aos-delay={300}>
                      <div className="icon" />
                      <div className="title" style={{ display: 'flex', margin: '0' }}>
                        <i className="fa fa-shopping-cart" style={{ color: '#41cf2e', fontSize: '2em' }} />
                        <h3 style={{ cursor: 'initial' }}>&nbsp;Purchase Tracking</h3>
                      </div>

                      <ul className="sectionList">
                        <li>
                          Enter the purchase of medical supplies/equipment either prescribed by doctors or bought over
                          the counter.
                        </li>
                        <li>Family members can check the medical supplies you have and order more when needed.</li>
                        <li>
                          Keeping records of purchased medical supplies reduces confusion between family members &amp;
                          caregivers, and allows them to easily track what items need reordering.
                        </li>
                        <li>
                          Creating such a simple and easy-to-use system increases the quality of care and compliance by
                          family members &amp; caregivers,
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </section>
            {/* End Services Section */}
            {/* ======= Portfolio Section ======= */}
            {/* ======= F.A.Q Section ======= */}
            <section id="faq" className="faq">
              <div className="container">
                <div className="section-title" data-aos="zoom-out">
                  <h2>F.A.Q</h2>
                  <p style={{ lineHeight: '1.1em' }}>Frequently Asked Questions</p>
                </div>
                <div>
                  <div className="row">
                    <div className=" col-md-12">
                      <ul className="faq-list">
                        <Accordion allowZeroExpanded className="fccFaqHeading">
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                What are the key benefits of using Family Care Circle ?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                FCC will improve communication between multiple Caregivers of a loved one. Appointment
                                tracking helps in keeping up with Notes, Pre Appointment Labs, Post Appointment Notes,
                                Instructions and Transportation needs. Here are a few examples of how FCC can help with
                                caregiving.
                              </p>
                              <ul>
                                <li>
                                  Request a Family Member or Caregiver in the Family Circle to help with Transportation.
                                </li>
                                <li>Send a quick update to all Family Members about a recent appointment.</li>
                                <li>
                                  Make a quick note with date and location about a Fall or other incident for quick
                                  reference.
                                </li>
                                <li>
                                  Quickly review historical data to answer questions like “When was the last time Mom
                                  fell?”
                                </li>
                              </ul>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                What is the difference between a Member and a Family Member?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                A Member is the focal point of the Family Circle. All information (appointments,
                                incidents, communication and notes) within the Family Care Circle is focused on the
                                Member. Family Members are the Caregivers of the Member. FCC is focused on successful
                                communication between all Caregivers by tracking key information and having it readily
                                available to all.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItem>
                              <AccordionItemHeading>
                                <AccordionItemButton>What is the role of an Authorized Caregiver?</AccordionItemButton>
                              </AccordionItemHeading>
                              <AccordionItemPanel>
                                <p>
                                  Authorized Caregivers represents the Member in the situations where the Member is not
                                  able to approve the creation of the Family Care Circle. An Authorized Caregiver is
                                  essentially acting on the Member’s behalf. The Authorized Caregiver and Member are the
                                  only ones that can delete the Family Circle’s data. The Authorized Caregiver and
                                  Member have unrestricted rights within the Family Care Circle.
                                </p>
                              </AccordionItemPanel>
                            </AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                What is the difference between the role of Admin and Family Member?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                Admins are in complete control of FCC and are allowed to expand the Family Circle by
                                inviting others to join. They also have the ability to update &amp; delete Family
                                Members and Caregivers. Family Members can create notes, appointments, incidents and
                                transportation request. Notes can be sent within multiple segments of FCC and are only
                                seen by the users the note is adressed to.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                How many Family Care Circles can a family member be part of ?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                A family member can be part of as many Family Care Circles as needed and may easily
                                start a new Family Care Circle around themselves at any time.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                Why does Family Care Circle require a Family Member to have an email address and phone
                                number?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                Email messaging gives Family Care Circle a method to send notifications outside of the
                                application. FCC will also use this gateway to Invite Family Members to join a Family
                                Care Circle, to communicate notes and reminders between Family Members when needed.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                What if a member of Family Member does not have an email address?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                Email addresses are a key part of communication and authentication. We suggest you help
                                all members to setup and e-mail with a Gmail account or another free emailing service.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>What if a Doctor has multiple locations ? </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                No Problem. When you set up a new Doctor or edit an existing Doctor, you can easily add
                                multiple locations. Then, when creating an appointment, you will select the Doctor and
                                location.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>What are Facilities ? </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                Facilities are locations where a Member would go for procedures like Blood Test, MRIs,
                                Pet Scans or Surgeries.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>What is an incident ? </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                An incident would be health or well-being occurrences that you might want to track to
                                determine frequency and/or help understand if there is an issue to be concerned about. A
                                Fall is a good example of an incident. Trending may be found with a historical review of
                                key information (date, location and notes). Tracking falls and incidents will help you
                                communicate a concern at the member's next Dr Appt. “Dad fell 3 times in 2 months.”
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>Can I track the purchase of medical devices ? </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                Yes,You can easily enter and track purchases such as Hearing aids or eye glasses in the
                                purchase management section.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>How secure is my data ? </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                FCC implements current security practices and will continue to maintain the latest
                                updates to keep your information secure.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>What is an Event? </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p style={{ marginRight: '10px' }}>
                                An event can be personal, public or social occasion or activity. Member can request
                                transportation, enter notes and have communication regarding the same. Ex: You are
                                visiting a sick person and would like the family to send flowers and get well wishes
                                along with request help with a transportation need.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>How can I quickly import Doctors or Facilities?</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                The Import utility allows you to enter in a list of doctors or facilities into an Excel
                                template. The Excel template contains import tabs for Doctors and Facilities. Each tab
                                has sample data needed. Start by downloading the sample excel sheet and fill data as per
                                instructions given. You may find this easier then adding multiple doctors one at a time.
                                This feature is very helpful for authorized caregivers that are looking for a way to
                                transfer information from another source.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>
                                How do I inactivate a Member’s Family Circle account?
                              </AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                We are sorry to hear you are thinking about closing your account with us. You will find
                                a “Delete” button that will allow you to simply remove a Member’s Circle. This will
                                permanently delete all appointments, incidents, notes, doctors and facilities associated
                                with that member. Only the Member or an Authorized Caregiver can complete this task.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                          <AccordionItem>
                            <AccordionItemHeading>
                              <AccordionItemButton>How to delete account of Family Care Circle?</AccordionItemButton>
                            </AccordionItemHeading>
                            <AccordionItemPanel>
                              <p>
                                If you delete this account, you will be deleted from all Family Care Circles, your
                                history of appointments, events, prescriptions will be deleted.To delete account, go to
                                Profile and click DELETE ACCOUNT, enter your password and click CONFIRM. Your FCC
                                account will be deleted.
                              </p>
                            </AccordionItemPanel>
                          </AccordionItem>
                        </Accordion>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* Modal */}

              <Container>
                <a onClick={() => this.scrollToSection('hero')} style={{ textDecoration: 'none' }}>
                  <Fab
                    aria-label="add"
                    style={{
                      float: 'right',
                      fontSize: 'small',
                      height: '15px',
                      width: '35px',
                      background: '#ef6603',
                      cursor: 'pointer',
                      color: 'white',
                      backgroundImage: '-webkit-linear-gradient(bottom, #843b07 0%, #f7b571 100%);',
                      border: '2px solid #edf0f1',
                      margin: '0em 3em',
                    }}
                  >
                    <ArrowUpwardIcon />
                  </Fab>{' '}
                </a>
              </Container>
            </section>
            <Modal isOpen={this.state.menuList} toggle={this.menuListToggle} size="sm">
              <Row>
                <Col md={12}>
                  <CloseIcon
                    onClick={(e) => this.menuListToggle()}
                    style={{ float: 'right', margin: '0.5em 1em 0em 0em' }}
                  />
                </Col>
              </Row>
              <ModalBody>
                <nav className="mobileViewNav">
                  <ul>
                    <li>
                      <a onClick={() => this.navigateToLink('/')}>Home</a>
                    </li>

                    <li>
                      <a onClick={() => this.openLoginTab()} target="_blank">
                        Login
                      </a>
                    </li>
                    <li className="active">
                      <a onClick={() => this.navigateToLink('/pricing')}>Pricing</a>
                    </li>
                    <li>
                      <a onClick={() => this.navigateToLink('/BusinessPromotion')}>Sponsorship</a>
                    </li>
                    <li>
                      <a onClick={() => this.navigateToLink('/contactUs')}>Contact</a>
                    </li>
                  </ul>
                </nav>
              </ModalBody>
            </Modal>
            {/* End F.A.Q Section */}
            {/* ======= Team Section ======= */}
          </main>
          {/* End #main */}
          {/* ======= Footer ======= */}
          <footer id="homeFooter">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <h3>Family Care Circle</h3>
                  <div className="social-links">
                    <a href="/" className="twitter">
                      <i className="fa fa-twitter" />
                    </a>
                    <a href="https://www.facebook.com/FamilyCareCircle" className="facebook" target="_blank">
                      <i className="fa fa-facebook-f" />
                    </a>
                    <a href="/" className="instagram">
                      <i className="fa fa-instagram" />
                    </a>
                    <a href="/" className="google-plus">
                      <i className="fa fa-skype" />
                    </a>
                    <a href="/" className="linkedin">
                      <i className="fa fa-linkedin" />
                    </a>
                  </div>
                  <div className="copyright" style={{ fontSize: '12px' }}>
                    © Copyright
                    <strong>
                      <span> Family Care Circle</span>
                    </strong>
                    . All Rights Reserved
                  </div>
                </div>
                <div className="col-lg-3">
                  <a
                    style={{ color: '#fff', cursor: 'pointer' }}
                    href="https://apps.apple.com/us/app/family-care-circle/id1602521967"
                    target="_blank"
                  >
                    <img src={AppleStore} style={{ height: '40px', border: '1px solid white', borderRadius: '5px' }} />
                  </a>
                  &nbsp;&nbsp;&nbsp;
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.familycarecircle.fccmobileapp"
                    style={{ color: '#fff', cursor: 'pointer' }}
                  >
                    <img
                      src={GooglePlayStore}
                      style={{ height: '40px', border: '1px solid white', borderRadius: '5px' }}
                    />
                  </a>
                </div>
                <div className="col-lg-3">
                  <ul style={{ float: 'right', listStyle: 'none', lineHeight: '3em', margin: '0 100px 0px 0' }}>
                    <li style={{ textAlign: 'left' }}>
                      <i className="fa fa-angle-right" />

                      <a onClick={() => this.navigateToLink('/contactUs')} style={{ color: '#fff', cursor: 'pointer' }}>
                        {' '}
                        Feedback
                      </a>
                    </li>
                    <li style={{ textAlign: 'left' }}>
                      <i className="fa fa-angle-right" />

                      <a
                        onClick={() => this.navigateToLink('/termsConditions')}
                        style={{ color: '#fff', cursor: 'pointer' }}
                      >
                        {' '}
                        Terms &amp; Conditions
                      </a>
                    </li>
                    <li style={{ textAlign: 'left' }}>
                      <i className="fa fa-angle-right" />

                      <a
                        onClick={() => this.navigateToLink('/privacyPolicyWeb')}
                        style={{ color: '#fff', cursor: 'pointer' }}
                      >
                        {' '}
                        Privacy Policy
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </>
    );
  }
}

export default FccHome;
