import React, { Component } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';
class DialogBox extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  ConfirmationWindow() {
    this.props.ConfirmationClick();
  }
  render() {
    return (
      <div>
        <Dialog
          open={this.props.open}
          onClose={this.props.onClose}
          onHide={this.props.onHide}
          style={{ padding: '1em' }}
          className="subscriptionModal"
        >
          <DialogContent style={{ textAlign: 'center', padding: '2em' }}>{this.props.children}</DialogContent>
          <DialogActions
            style={{
              marginTop: '1em',
              padding: '2em',
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            <Button
              variant="contained"
              size="large"
              style={{
                background: '#0f666b',
                color: '#fff',
                marginRight: '1rem',
              }}
              startIcon={<SaveIcon />}
              onClick={(e) => this.ConfirmationWindow(e)}
            >
              Confirm
            </Button>
            <Button
              variant="contained"
              size="large"
              style={{
                background: '#717373',
                color: '#fff',
                marginRight: '1rem',
              }}
              startIcon={<CloseIcon />}
              onClick={this.props.onClose}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

export default DialogBox;
