import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
export class EditIconButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onClick: props.onClick,
      caption: props.caption,
    };
  }

  onClick() {
    if (this.state.onClick) {
      this.state.onClick();
    }
  }
  render() {
    return (
      <span
        className="editDetails"
        onClick={this.onClick.bind(this)}
        style={{ cursor: 'pointer', fontSize: 'x-larger', float: 'right', marginRight: '3px' }}
      >
        <Button
          className="add"
          onClick={this.onClick.bind(this)}
          style={{
            background: '#867e7e',
            color: 'white',
            fontSize: 'x-large',
            textTransform: 'capitalize',
            cursor: 'pointer',
            fontSize: 'x-larger',
            float: 'right',
            margin: '0.2em 0.5em 0.3em 0em',
          }}
          startIcon={<EditIcon />}
        >
          Edit
        </Button>
      </span>
    );
  }
}
export default EditIconButton;
