import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import React, { Component } from 'react';

export class CancelIconButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onClick: props.onClick,
      caption: props.caption,
    };
  }

  onClick() {
    if (this.state.onClick) {
      this.state.onClick();
    }
  }
  render() {
    return (
      <Tooltip title="Cancel">
        <Button
          type={this.state.type}
          variant="contained"
          size="small"
          style={{ background: '#717373', color: '#fff', marginRight: '1rem' }}
          startIcon={<CloseIcon />}
          onClick={this.onClick.bind(this)}
        >
          Cancel
        </Button>
      </Tooltip>
    );
  }
}
export default CancelIconButton;
