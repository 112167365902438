import React, { Component } from 'react';
import { matchPath, NavLink } from 'react-router-dom';
import { Collapse, Nav, Navbar, NavbarToggler } from 'reactstrap';
import vibeLogo from '../../../assets/images/fccLogo.png';
import ToggleSidebarButton from '../../components/header/components/ToggleSidebarButton';
import './AdminHeader.scss';
import PageLoader from '../../components/pageLoader/PageLoader';
export default class AdminHeader extends Component {
  constructor(props) {
    super(props);
    let pathUrl = '';
    let isButtonHide = true;
    if (props.history && props.history.location && props.history.location.state) {
      pathUrl = props.history.location.state.pathname;
    }
    let str = window.location.href;
    let strArr = str.split('/');
    let pageId = strArr[4];
    if (pageId === 'home') {
      isButtonHide = false;
    }
    this.state = {
      pathUrl: pathUrl,
      isOpen: false,
      isButtonHide: isButtonHide,
    };
  }

  toggle = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };

  getPageTitle = () => {
    let name;
    this.props.routes.map((prop) => {
      if (
        matchPath(this.props.location.pathname, {
          path: prop.path,
          exact: true,
          strict: false,
        })
      ) {
        name = prop.name;
      }
      return null;
    });
    return name;
  };

  render() {
    if (window.location.pathname === '/ApLogin') return null;

    return (
      <header
        className="app-header"
        style={{ position: 'fixed', background: 'linear-gradient(0deg, #066f72 0%, #1c5c65 100%)' }}
      >
        <SkipToContentLink focusId="primary-content" />
        <div className="top-nav">
          <Navbar color="faded" light expand="md">
            {this.state.isButtonHide && (
              <ToggleSidebarButton
                toggleSidebar={this.props.toggleSidebar}
                isSidebarCollapsed={this.props.isSidebarCollapsed}
              />
            )}

            <NavLink to="/ApHome" className="navbar-brand" style={{ backgroundColor: 'transparent' }}>
              {<img src={vibeLogo} alt="" style={{ height: '1.7em', width: '1.8em' }} />}
              {
                <span className="logo-text" style={{ fontWeight: 'bold', color: '#fff', paddingLeft: '0.2em' }}>
                  {'Admin Panel'}
                </span>
              }
            </NavLink>

            <NavbarToggler onClick={this.toggle} style={{ backgroundColor: '#fff' }} />
            <Collapse isOpen={this.state.isOpen} navbar>
              <Nav className="ml-auto" navbar>
                {this.props.children}
              </Nav>
            </Collapse>
            <PageLoader />
          </Navbar>
        </div>
      </header>
    );
  }
}

const SkipToContentLink = ({ focusId }) => {
  return (
    <a href={`#${focusId}`} tabIndex="1" className="skip-to-content">
      Skip to Content
    </a>
  );
};
