import { CLEAR_MEMBER_LIST, GET_MEMBER_LIST, SET_MEMBER_LIST } from './memberListTypes';

const getMemberList = () => {
  return {
    type: GET_MEMBER_LIST,
  };
};

const setMemberList = (data) => {
  return {
    type: SET_MEMBER_LIST,
    payload: data,
  };
};

const clearMemberList = (data) => {
  return {
    type: CLEAR_MEMBER_LIST,
    payload: data,
  };
};
const memberListAction = { getMemberList, setMemberList, clearMemberList };

export default memberListAction;
