import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { createMuiTheme } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import PrintRoundedIcon from '@material-ui/icons/PrintRounded';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import React, { Component } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { ValidatorForm } from 'react-material-ui-form-validator';
import 'react-tabs/style/react-tabs.css';
import ReactToPrint from 'react-to-print';
import { Card, CardBody, CardFooter, CardHeader, Col, Label, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import fccPageStateAction from '../../../redux/fccPageState/fccPageStateAction';
import helpDataAction from '../../../redux/help/displayHelpAction';
import store from '../../../redux/store';
import AddIconButton from '../buttons/AddIconButton';
import BackButton from '../buttons/BackButton';
import CancelIconButton from '../buttons/CancelIconButton';
import CreateButton from '../buttons/CreateButton';
import EditIconButton from '../buttons/EditIconButton';
import SaveButton from '../buttons/SaveButton';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
import PtsAlert from '../ptsAlert/PtsAlert';
import './MyPurchase.scss';
import MyPurchaseNote from './MyPurchaseNote';
import PurchaseReminder from './PurchaseReminder';
import Button from '@material-ui/core/Button';
import BreadCrumbDetailsAction from '../../../redux/breadCrumbDetails/BreadCrumbDetailsAction';
class MyPurchase extends Component {
  purchaseFrequencyList = store.getState().staticData.purchaseFrequencyList;
  purchaseTypeList = store.getState().staticData.purchaseTypeList;
  header = store.getState().header.header;
  memberDetails = store.getState().memberData.memberData;
  memberName = store.getState().memberData.memberData.firstname + ' ' + store.getState().memberData.memberData.lastname;
  isFreeUser = store.getState().userData.userData.isFreeUser;
  displayHelp = store.getState().help.help;
  fccPageState = store.getState().fccPageState.fccPageState;
  memberHomeUrl = store.getState().memberHomeUrl.memberHomeUrl.homeUrl;
  title = 'Purchase Details';
  breadCrumb = [
    { pageid: this.memberHomeUrl, uiname: this.memberName },
    { pageid: '/memberPurchase', uiname: 'Purchase List' },
    { pageid: '', uiname: 'Purchase Details' },
  ];
  purchaseReminderRefList = [];
  purchaseNoteRefList = [];

  constructor(props) {
    super(props);
    let purchaseId = props.purchaseId;
    let action = props.action;
    let isPrintView = props.isPrintView;
    let title = props.title;
    let purchaseDetails = {};
    let pageId = '';
    let isButtonHide = false;
    let isEditButtonHide = true;
    let canRender = false;
    let isViewButtonHide = true;
    let purchaseMessageData = '';
    let purchaseDataId = '';
    let purchaseNoteId = '';
    let str = window.location.href;
    let urlArr = str.split('?');
    let purchaseVo = urlArr[1];
    let reminderSectionId = '';
    if (this.breadCrumb) {
      Utils.getActiveMenu(this.breadCrumb);
      store.dispatch(BreadCrumbDetailsAction.setBreadCrumbDetails(this.breadCrumb));
    }
    if (purchaseVo) {
      let purchaseDataVo = purchaseVo.split('=');
      purchaseMessageData = purchaseDataVo[1].split('&');
      purchaseDataId = purchaseMessageData[0];
      purchaseNoteId = purchaseDataVo[2];
      purchaseNoteId = purchaseNoteId.split('&');
      purchaseNoteId = purchaseNoteId[0];
      action = 'view';
      this.getPurchaseDetails(purchaseDataId);
    } else {
      if (!purchaseId && props.history && props.history.location && props.history.location.state) {
        purchaseId = props.history.location.state.purchaseId;
        action = props.history.location.state.action;
        pageId = props.history.location.state.pageId;
        title = props.history.location.state.title;
        purchaseDetails = props.history.location.state.purchaseDetails;
        reminderSectionId = props.history.location.state.reminderId;
      }

      if (pageId === 'view_Purchase') {
        isButtonHide = true;
        isEditButtonHide = false;
      }

      if (isPrintView === 'view_print') {
        isViewButtonHide = false;
      }

      if (null !== purchaseId && purchaseId > 0) {
        this.getPurchaseDetails(purchaseId);
      } else {
        canRender = true;
      }
    }
    if (purchaseId === undefined) {
      purchaseId = this.fccPageState.id;
      action = this.fccPageState.action;
      if (action !== 'create') {
        this.getPurchaseDetails(purchaseId);
      }

      canRender = true;
    }
    if (pageId === 'createPurchase') {
      let getDomainObjectPermission = purchaseDetails.domainObjectPrivileges;

      let getCreatePermission = false;
      let getReadPermission = false;
      let getUpdatePermission = false;
      let getDeletePermission = false;
      let showPurchasePermission = false;
      let showPurchaseNotePermission = false;
      let getPurchaseNoteCreatePermission = false;
      let getPurchaseNoteReadPermission = false;
      let getPurchaseNoteUpdatePermission = false;
      let getPurchaseNoteDeletePermission = false;

      for (let domainObjectKey in getDomainObjectPermission) {
        if (domainObjectKey.toLowerCase() === 'purchase') {
          let purchasePermission = getDomainObjectPermission[domainObjectKey];
          let getPermission = Utils.getDomainObjectRolePermission(purchasePermission);
          getCreatePermission = getPermission[0];
          getReadPermission = getPermission[1];
          getUpdatePermission = getPermission[2];
          getDeletePermission = getPermission[3];

          showPurchasePermission = true;
          getCreatePermission = getCreatePermission;
          getReadPermission = getReadPermission;
          getUpdatePermission = getUpdatePermission;
          getDeletePermission = getDeletePermission;
        } else if (domainObjectKey === 'purchasenote') {
          let purchaseNotePermission = getDomainObjectPermission[domainObjectKey];

          if (purchaseNotePermission.length > 0 && purchaseNotePermission !== null) {
            showPurchaseNotePermission = true;
          }
          let getPermission = Utils.getDomainObjectRolePermission(purchaseNotePermission);
          getCreatePermission = getPermission[0];
          getReadPermission = getPermission[1];
          getUpdatePermission = getPermission[2];
          getDeletePermission = getPermission[3];

          getPurchaseNoteCreatePermission = getCreatePermission;
          getPurchaseNoteReadPermission = getReadPermission;
          getPurchaseNoteUpdatePermission = getUpdatePermission;
          getPurchaseNoteDeletePermission = getDeletePermission;
        }
      }
      let date = purchaseDetails.purchase.date;
      let newStartDate = Utils.convertUTCDateToUserTimeZoneDate(date);
      let viewStartDate = Utils.convertUTCDateToUserTimeZoneViewDateFormate(date);
      let selectedDoctor = '';
      if (purchaseDetails.purchase.doctor !== null) {
        selectedDoctor = {
          doctorName:
            purchaseDetails.purchase.doctor.salutation +
            ' ' +
            purchaseDetails.purchase.doctor.firstName +
            ' ' +
            purchaseDetails.purchase.doctor.lastName,
          id: purchaseDetails.purchase.doctor.id,
        };
      } else {
        selectedDoctor = '';
      }
      console.log('doctor', selectedDoctor.doctorName);
      if (purchaseDetails.purchase.reminderList !== []) {
        this.state = {
          render: true,
          // getAllPermission: getAllPermission,
          getUpdatePermission: getUpdatePermission,
          getDeletePermission: getDeletePermission,
          getReadPermission: getReadPermission,
          getCreatePermission: getCreatePermission,
          purchaseData: purchaseDetails.purchase,
          id: purchaseDetails.purchase.id,
          viewPurchaseCard: true,
          editPurchaseCard: false,
          startDate: new Date(newStartDate),
          purchaseStartDate: viewStartDate,
          purchaseType: purchaseDetails.purchase.type,
          selectedDoctor: selectedDoctor,
          doctor: selectedDoctor.name,
          shortDescription: purchaseDetails.purchase.shortDescription,
          longDescription: purchaseDetails.purchase.description,
          reminderItems: purchaseDetails.purchase.reminderList,
          purchaseNoteList: purchaseDetails.purchase.noteList,
          createdByName: purchaseDetails.purchase.createdByName,
          createdOn: purchaseDetails.purchase.createdOn,
          noteListRow: true,
          title: title,
          isPrintView: isPrintView,
          isViewButtonHide: isViewButtonHide,
          isButtonHide: isButtonHide,
          pageId: pageId,
          doctorList: [],
          isEditButtonHide: isEditButtonHide,
          action: action,
          isPrescribedBy: purchaseDetails.purchase.isPrescribedBy,
          isFreeUser: this.isFreeUser,
          noteId: purchaseNoteId,
          currentCompareDateTime: new Date(),
          stepsEnabled: false,
          reminderSection: reminderSectionId,
          initialStep: 0,
          steps: [
            {
              element: '.editDetails',
              intro: 'Edit/update Purchase Details',
            },

            {
              element: '.addNote',
              intro: 'Add Notes. User can add multiple Notes.',
            },
            {
              element: '.reminderTitle',
              intro: 'Reminders can be set for future purchases.',
            },
            {
              element: '.newReminder',
              intro: 'Add reminders. User can add multiple Reminders.',
            },

            {
              element: '.print',
              intro: 'Print Purchase  Details ',
            },
            {
              element: '.createSimilar',
              intro: 'create Similar Purchase ',
            },
            {
              element: '.delete',
              intro: 'Delete Purchase  Details ',
            },
          ],
          stepsWithNote: [
            {
              element: '.editDetails',
              intro: 'Edit/update Purchase Details',
            },

            {
              element: '.noteTitle',
              intro:
                'Notes can be added, edited, deleted. Communication for this Note is started by first adding Note and clicking on Messaging icon.',
            },
            {
              element: '.addNote',
              intro: 'Add Notes. User can add multiple Notes.',
            },
            {
              element: '.deleteIcon',
              intro: 'Delete Note.',
            },
            {
              element: '.editLocation',
              intro: 'Edit Note.',
            },
            {
              element: '.purchaseCommunication',
              intro: ' Start new Communication Thread.',
            },
            {
              element: '.createdBy',
              intro: ' View who has created this note at what date and time.',
            },
            {
              element: '.reminderTitle',
              intro: 'Reminders can be set for future purchases.',
            },
            {
              element: '.newReminder',
              intro: 'Add reminders. User can add multiple Reminders.',
            },
            {
              element: '.print',
              intro: 'Print Purchase  Details ',
            },
            {
              element: '.createSimilar',
              intro: 'create Similar Purchase ',
            },
            {
              element: '.delete',
              intro: 'Delete Purchase  Details ',
            },
          ],
          displayFloatButton: this.displayHelp,
          helpModal: false,
          showPurchaseNotePermission: showPurchaseNotePermission,
          getPurchaseNoteCreatePermission: getPurchaseNoteCreatePermission,
          getPurchaseNoteReadPermission: getPurchaseNoteReadPermission,
          getPurchaseNoteUpdatePermission: getPurchaseNoteUpdatePermission,
          getPurchaseNoteDeletePermission: getPurchaseNoteDeletePermission,
        };
      } else {
        this.state = {
          render: true,
          purchaseData: purchaseDetails.purchase,
          id: purchaseDetails.purchase.id,
          viewPurchaseCard: true,
          editPurchaseCard: false,
          startDate: new Date(newStartDate),
          purchaseStartDate: viewStartDate,
          purchaseType: purchaseDetails.purchase.type,
          selectedDoctor: selectedDoctor,
          doctor: selectedDoctor.doctorName,
          shortDescription: purchaseDetails.purchase.shortDescription,
          longDescription: purchaseDetails.purchase.description,
          purchaseNoteList: purchaseDetails.purchase.noteList,
          createdByName: purchaseDetails.purchase.createdByName,
          createdOn: purchaseDetails.purchase.createdOn,
          reminderItems: [],
          noteListRow: true,
          title: title,
          purchaseNoteCreateList: [],
          isButtonHide: isButtonHide,
          pageId: pageId,
          doctorList: [],
          isEditButtonHide: isEditButtonHide,
          action: action,
          isPrintView: isPrintView,
          isViewButtonHide: isViewButtonHide,
          isPrescribedBy: purchaseDetails.purchase.isPrescribedBy,
          isFreeUser: this.isFreeUser,
          reminderSection: reminderSectionId,
          noteId: purchaseNoteId,
          currentCompareDateTime: new Date(),
          stepsEnabled: false,
          initialStep: 0,
          steps: [
            {
              element: '.editDetails',
              intro: 'Edit/update Purchase Details',
            },

            {
              element: '.addNote',
              intro: 'Add Notes. User can add multiple Notes.',
            },
            {
              element: '.reminderTitle',
              intro: 'Reminders can be set for future purchases.',
            },
            {
              element: '.newReminder',
              intro: 'Add reminders. User can add multiple Reminders.',
            },
            {
              element: '.print',
              intro: 'Print Purchase  Details ',
            },
            {
              element: '.createSimilar',
              intro: 'create Similar Purchase ',
            },
            {
              element: '.delete',
              intro: 'Delete Purchase  Details ',
            },
          ],
          stepsWithNote: [
            {
              element: '.editDetails',
              intro: 'Edit/update Purchase Details',
            },

            {
              element: '.noteTitle',
              intro:
                'Notes can be added, edited, deleted. Communication for this Note is started by first adding Note and clicking on Messaging icon.',
            },
            {
              element: '.addNote',
              intro: 'Add Notes. User can add multiple Notes.',
            },
            {
              element: '.deleteIcon',
              intro: 'Delete Note.',
            },
            {
              element: '.editLocation',
              intro: 'Edit Note.',
            },
            {
              element: '.purchaseCommunication',
              intro: ' Start new Communication Thread.',
            },
            {
              element: '.createdBy',
              intro: ' View who has created this note at what date and time.',
            },
            {
              element: '.reminderTitle',
              intro: 'Reminders can be set for future purchases.',
            },
            {
              element: '.newReminder',
              intro: 'Add reminders. User can add multiple Reminders.',
            },
            {
              element: '.print',
              intro: 'Print Purchase  Details ',
            },
            {
              element: '.createSimilar',
              intro: 'create Similar Purchase ',
            },
            {
              element: '.delete',
              intro: 'Delete Purchase  Details ',
            },
          ],
          displayFloatButton: this.displayHelp,
          helpModal: false,
          getUpdatePermission: getUpdatePermission,
          getDeletePermission: getDeletePermission,
          getReadPermission: getReadPermission,
          getCreatePermission: getCreatePermission,
          showPurchaseNotePermission: showPurchaseNotePermission,
          getPurchaseNoteCreatePermission: getPurchaseNoteCreatePermission,
          getPurchaseNoteReadPermission: getPurchaseNoteReadPermission,
          getPurchaseNoteUpdatePermission: getPurchaseNoteUpdatePermission,
          getPurchaseNoteDeletePermission: getPurchaseNoteDeletePermission,
        };
      }
    } else {
      this.state = {
        id: purchaseId,
        action: action,
        render: canRender,
        startDate: new Date(),
        purchaseType: '',
        selectedDoctor: {},
        shortDescription: '',
        longDescription: '',
        doctor: '',
        title: title,
        reminderItems: [],
        purchaseNoteList: [],
        purchaseNoteCreateList: [],
        isButtonHide: isButtonHide,
        pageId: pageId,
        doctorList: [],
        isEditButtonHide: isEditButtonHide,
        viewPurchaseCard: false,
        editPurchaseCard: false,
        purchaseData: {},
        isPrintView: isPrintView,
        isViewButtonHide: isViewButtonHide,
        getUpdatePermission: false,
        getDeletePermission: false,
        getReadPermission: false,
        getCreatePermission: false,
        isFreeUser: this.isFreeUser,
        isPrescribedBy: false,
        showPrescribedBy: false,
        noteId: purchaseNoteId,
        currentCompareDateTime: new Date(),
        createdByName: '',
        createdOn: '',
        stepsEnabled: false,
        initialStep: 0,
        steps: [
          {
            element: '.editDetails',
            intro: 'Edit/update Purchase Details',
          },

          {
            element: '.addNote',
            intro: 'Add Notes. User can add multiple Notes.',
          },
          {
            element: '.reminderTitle',
            intro: 'Reminders can be set for future purchases.',
          },
          {
            element: '.newReminder',
            intro: 'Add reminders. User can add multiple Reminders.',
          },
          {
            element: '.print',
            intro: 'Print Purchase  Details ',
          },
          {
            element: '.createSimilar',
            intro: 'create Similar Purchase ',
          },
          {
            element: '.delete',
            intro: 'Delete Purchase  Details ',
          },
        ],
        stepsWithNote: [
          {
            element: '.editDetails',
            intro: 'Edit/update Purchase Details',
          },

          {
            element: '.noteTitle',
            intro:
              'Notes can be added, edited, deleted. Communication for this Note is started by first adding Note and clicking on Messaging icon.',
          },
          {
            element: '.addNote',
            intro: 'Add Notes. User can add multiple Notes.',
          },
          {
            element: '.deleteIcon',
            intro: 'Delete Note.',
          },
          {
            element: '.editLocation',
            intro: 'Edit Note.',
          },
          {
            element: '.purchaseCommunication',
            intro: ' Start new Communication Thread.',
          },
          {
            element: '.createdBy',
            intro: ' View who has created this note at what date and time.',
          },
          {
            element: '.reminderTitle',
            intro: 'Reminders can be set for future purchases.',
          },
          {
            element: '.newReminder',
            intro: 'Add reminders. User can add multiple Reminders.',
          },
          {
            element: '.print',
            intro: 'Print Purchase  Details ',
          },
          {
            element: '.createSimilar',
            intro: 'create Similar Purchase ',
          },
          {
            element: '.delete',
            intro: 'Delete Purchase  Details ',
          },
        ],
        displayFloatButton: this.displayHelp,
        reminderSection: '',
        helpModal: false,
        showPurchaseNotePermission: false,
        getPurchaseNoteCreatePermission: false,
        getPurchaseNoteReadPermission: false,
        getPurchaseNoteUpdatePermission: false,
        getPurchaseNoteDeletePermission: false,
      };
    }

    this.getDoctorList();
  }

  closeFloatingButton() {
    this.setState({ displayFloatButton: false });
    let displayFloat = false;

    store.dispatch(helpDataAction.setHelpData(displayFloat));
  }

  getPurchaseDetails(purchaseId) {
    let id = purchaseId;
    const postObject = {
      header: this.header,
      purchase: {
        id: id,
      },
    };
    FetchServerData.callPostService('purchasems/getPurchase', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let purchaseData = output.data.purchase;

        let getDomainObjectPermission = output.data.domainObjectPrivileges;
        let getCreatePermission = false;
        let getReadPermission = false;
        let getUpdatePermission = false;
        let getDeletePermission = false;

        for (let domainObjectKey in getDomainObjectPermission) {
          // let keyPermission = domainObjectKey;
          if (domainObjectKey.toLowerCase() === 'purchase') {
            let purchasePermission = getDomainObjectPermission[domainObjectKey];
            let getPermission = Utils.getDomainObjectRolePermission(purchasePermission);
            // getAllPermission = getPermission[0];
            getCreatePermission = getPermission[0];
            getReadPermission = getPermission[1];
            getUpdatePermission = getPermission[2];
            getDeletePermission = getPermission[3];
            this.setState({
              showPurchasePermission: true,
              getCreatePermission: getCreatePermission,
              getReadPermission: getReadPermission,
              getUpdatePermission: getUpdatePermission,
              getDeletePermission: getDeletePermission,
            });
          } else if (domainObjectKey.toLowerCase() === 'purchasenote') {
            let purchaseNotePermission = getDomainObjectPermission[domainObjectKey];
            let showPurchaseNotePermission = false;
            if (purchaseNotePermission.length > 0 && purchaseNotePermission !== null) {
              showPurchaseNotePermission = true;
            }
            let getPermission = Utils.getDomainObjectRolePermission(purchaseNotePermission);
            getCreatePermission = getPermission[0];
            getReadPermission = getPermission[1];
            getUpdatePermission = getPermission[2];
            getDeletePermission = getPermission[3];
            this.setState({
              showPurchaseNotePermission: showPurchaseNotePermission,
              getPurchaseNoteCreatePermission: getCreatePermission,
              getPurchaseNoteReadPermission: getReadPermission,
              getPurchaseNoteUpdatePermission: getUpdatePermission,
              getPurchaseNoteDeletePermission: getDeletePermission,
            });
          }
        }
        let date = purchaseData.date;
        let newStartDate = Utils.convertUTCDateToUserTimeZoneDate(date);
        let viewStartDate = Utils.convertUTCDateToUserTimeZoneViewDateFormate(date);
        let selectedDoctor = '';
        if (purchaseData.doctor !== null) {
          selectedDoctor = {
            doctorName:
              purchaseData.doctor.salutation + ' ' + purchaseData.doctor.firstName + ' ' + purchaseData.doctor.lastName,
            id: purchaseData.doctor.id,
          };
        } else {
          selectedDoctor = '';
        }
        console.log('selectedDoctor', selectedDoctor);

        let showPrescribedBy = false;
        if (purchaseData.isPrescribedBy === true) {
          showPrescribedBy = true;
        } else {
          showPrescribedBy = false;
        }
        if (purchaseData.reminderList !== null) {
          this.setState({
            render: true,

            purchaseData: purchaseData,
            id: purchaseData.id,
            viewPurchaseCard: true,
            editPurchaseCard: false,
            startDate: new Date(newStartDate),
            purchaseStartDate: viewStartDate,
            purchaseType: purchaseData.type,
            selectedDoctor: selectedDoctor,
            doctor: selectedDoctor.doctorName,
            shortDescription: purchaseData.shortDescription,
            longDescription: purchaseData.description,
            reminderItems: purchaseData.reminderList,
            purchaseNoteList: purchaseData.noteList,
            noteListRow: true,
            isPrescribedBy: purchaseData.isPrescribedBy,
            showPrescribedBy: showPrescribedBy,
            createdByName: purchaseData.createdByName,
            createdOn: purchaseData.createdOn,
          });
        } else {
          this.setState({
            render: true,

            purchaseData: purchaseData,
            viewPurchaseCard: true,
            editPurchaseCard: false,
            id: purchaseData.id,
            startDate: new Date(newStartDate),
            purchaseStartDate: viewStartDate,
            purchaseType: purchaseData.type,
            selectedDoctor: selectedDoctor,
            doctor: selectedDoctor.doctorName,
            shortDescription: purchaseData.shortDescription,
            longDescription: purchaseData.description,
            purchaseNoteList: purchaseData.noteList,
            reminderItems: [],
            noteListRow: true,
            isPrescribedBy: purchaseData.isPrescribedBy,
            showPrescribedBy: showPrescribedBy,
            createdByName: purchaseData.createdByName,
            createdOn: purchaseData.createdOn,
          });
        }
      } else {
      }
    });
  }

  toggleSteps = () => {
    this.setState((prevState) => ({ stepsEnabled: !prevState.stepsEnabled }));
  };

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };
  getDoctorList() {
    const postObject = {
      header: this.header,
      doctor: {
        member: {
          id: this.memberDetails.member,
        },
      },
    };
    FetchServerData.callPostService('doctorms/getMemberDoctors', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let doctorListData = output.data.list;
        this.setState({
          doctorList: doctorListData,
          render: true,
        });
      } else {
      }
    });
  }

  handlePurchaseStartDateChange(date) {
    this.setState({ startDate: date });
  }

  handlePurchaseTypeChange(e) {
    this.setState({ purchaseType: e.target.value });
  }

  handleDoctorChange(selectedDoctor) {
    this.setState({
      selectedDoctor: selectedDoctor,
    });
    if (undefined !== this.state.onChange && null !== this.state.onChange) {
      this.state.onChange(this.state);
    }
  }

  handlePrescribedByChange(e) {
    if (e.target.checked === true) {
      this.setState({ isPrescribedBy: e.target.checked, showPrescribedBy: true });
    } else {
      this.setState({ isPrescribedBy: e.target.checked, showPrescribedBy: false });
    }
  }

  handleLongDescriptionChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 256);
    if (res.length < 256) {
      this.setState({ longDescription: res });
    } else {
      Utils.maxFieldLength(256);
    }
  }

  addReminder(type) {
    if (type === 'edit') {
      let items = this.state.reminderItems;
      let item = {
        action: 'create',
        operation: 'add',
        content: this.state.purchaseType,
        purchase: {
          id: this.state.id,
        },
      };
      items.push(item);
      this.purchaseReminderRefList.push(React.createRef());

      this.setState({ reminderItems: items });
    } else {
      let items = this.state.reminderItems;
      let item = {
        action: 'create',
        operation: 'add',
        content: this.state.purchaseType,
      };
      items.push(item);
      this.purchaseReminderRefList.push(React.createRef());

      this.setState({ reminderItems: items });
    }
  }

  addNewReminderInBackend(reminder) {
    let reminderItems = {};
    for (let i = 0; i < this.state.reminderItems.length; i++) {
      if (
        this.state.reminderItems[i] &&
        reminder.index === i &&
        this.purchaseReminderRefList[i] &&
        this.purchaseReminderRefList[i].current
      ) {
        let reminderDateTime = Utils.convertUserTimeZoneDateToUTCDate(
          this.purchaseReminderRefList[i].current.state.startDate
        );

        reminderItems = {
          id: this.purchaseReminderRefList[i].current.state.id,
          content: this.purchaseReminderRefList[i].current.state.note,
          date: reminderDateTime,
          purchase: {
            id: this.state.id,
          },
        };
        const postObject = {
          header: this.header,
          reminder: reminderItems,
        };
        FetchServerData.callPostService('purchasems/createReminder', postObject)
          .then((output) => {
            if (output.status === 'SUCCESS') {
              PtsAlert.success(output.message);
              this.state.reminderItems = [];
              this.purchaseReminderRefList[i].current.state.action = 'view';
              this.setState({
                reminderItems: output.data.purchase.reminderList,
              });
            } else {
            }
          })
          .catch((error) => {});
      }
    }
  }

  updateReminderInBackend(e) {
    let reminderItems = {};
    for (let i = 0; i < this.purchaseReminderRefList.length; i++) {
      if (null !== this.purchaseReminderRefList[i].current) {
        let reminderDateTime = Utils.convertUserTimeZoneDateToUTCDate(
          this.purchaseReminderRefList[i].current.state.startDate
        );

        reminderItems = {
          id: this.purchaseReminderRefList[i].current.state.id,
          content: this.purchaseReminderRefList[i].current.state.note,
          date: reminderDateTime,
          purchase: {
            id: this.state.id,
          },
        };
      }
    }
    const postObject = {
      header: this.header,
      reminder: reminderItems,
    };
    FetchServerData.callPostService('purchasems/updateReminder', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          PtsAlert.success(output.message);
          this.state.reminderItems = [];
          this.setState({
            reminderItems: output.data.purchase.reminderList,
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  reminderItemDeleted(item) {
    let items = [];
    if (item.operation && 'update' === item.operation) {
      /* Existing Item Deleted, Mark it deleted */
      let index = item.index;

      for (let i = 0; i < this.state.reminderItems.length; i++) {
        let item = this.state.reminderItems[i];
        if (this.state.reminderItems[i].operation === 'update' && index === i) {
          item.operation = 'delete';
        }

        items.push(item);
      }

      this.setState({ reminderItems: items });
    }
    if (item.operation && 'add' === item.operation) {
      /* Added Item Deleted, Remove from list */
      let index = item.index;
      for (let i = 0; i < this.state.reminderItems.length; i++) {
        if (this.state.reminderItems[i].operation === 'add' && index === i) {
          this.purchaseReminderRefList.splice(i, 1);
          // continue;
        } else {
          items.push(this.state.reminderItems[i]);
        }
      }
      this.setState({ reminderItems: items });
    }
  }

  addPurchaseInBackend(e) {
    e.preventDefault();

    for (let i = 0; i < this.purchaseReminderRefList.length; i++) {
      if (null !== this.purchaseReminderRefList[i].current) {
        let reminderDateTime = Utils.convertUserTimeZoneDateToUTCDate(
          this.purchaseReminderRefList[i].current.state.startDate
        );

        this.state.reminderItems[i].content = this.purchaseReminderRefList[i].current.state.note;
        this.state.reminderItems[i].date = reminderDateTime;
      }
    }

    let dateTime = Utils.convertUserTimeZoneDateToUTCDate(this.state.startDate);
    const postObject = {
      header: this.header,
      purchase: {
        date: dateTime,
        shortDescription: this.state.shortDescription,
        description: this.state.longDescription,
        type: this.state.purchaseType,
        isPrescribedBy: this.state.isPrescribedBy,
        member: {
          id: this.memberDetails.member,
        },
        doctor: {
          id: this.state.selectedDoctor.id,
        },
        reminderList: this.state.reminderItems,
      },
    };
    FetchServerData.callPostService('/purchasems/create', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let purchaseData = output.data;
          PtsAlert.success(output.message);
          let fccPageState = {
            id: purchaseData.purchase.id,
            action: 'view',
          };
          store.dispatch(fccPageStateAction.setFccPageState(fccPageState));

          this.props.history.push({
            pathname: '/viewPurchase',
            state: {
              action: 'view',
              title: 'Purchase Details',
              // purchaseId: purchaseData.id,
              purchaseDetails: purchaseData,
              pageId: 'createPurchase',
            },
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  updatePurchaseInBackend(e) {
    for (let i = 0; i < this.purchaseReminderRefList.length; i++) {
      if (null !== this.purchaseReminderRefList[i].current) {
        let reminderDateTime = Utils.convertUserTimeZoneDateToUTCDate(
          this.purchaseReminderRefList[i].current.state.startDate
        );

        this.state.reminderItems[i].content = this.purchaseReminderRefList[i].current.state.note;
        this.state.reminderItems[i].date = reminderDateTime;
      }
    }
    let dateTime = Utils.convertUserTimeZoneDateToUTCDate(this.state.startDate);
    const postObject = {
      header: this.header,
      purchase: {
        id: this.state.id,
        date: dateTime,
        shortDescription: this.state.shortDescription,
        description: this.state.longDescription,
        isPrescribedBy: this.state.isPrescribedBy,
        type: this.state.purchaseType,
        member: {
          id: this.memberDetails.member,
        },
        doctor: {
          id: this.state.selectedDoctor.id,
        },
      },
    };
    FetchServerData.callPostService('/purchasems/update', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let purchaseData = output.data.purchase;
          PtsAlert.success(output.message);
          let date = purchaseData.date;
          let newStartDate = Utils.convertUTCDateToUserTimeZoneDate(date);
          let viewStartDate = Utils.convertUTCDateToUserTimeZoneDate(date);
          let selectedDoctor = '';
          if (purchaseData.doctor !== null) {
            selectedDoctor = {
              doctorName:
                purchaseData.doctor.salutation +
                ' ' +
                purchaseData.doctor.firstName +
                ' ' +
                purchaseData.doctor.lastName,
              id: purchaseData.doctor.id,
            };
          } else {
            selectedDoctor = '';
          }

          let showPrescribedBy = false;
          if (purchaseData.isPrescribedBy === true) {
            showPrescribedBy = true;
          } else {
            showPrescribedBy = false;
          }

          if (purchaseData.reminderList !== null) {
            this.setState({
              render: true,
              id: purchaseData.id,
              viewPurchaseCard: true,
              editPurchaseCard: false,
              startDate: new Date(newStartDate),
              purchaseStartDate: viewStartDate,
              purchaseType: purchaseData.type,
              selectedDoctor: selectedDoctor,
              doctor: selectedDoctor.doctorName,
              shortDescription: purchaseData.shortDescription,
              longDescription: purchaseData.description,
              isPrescribedBy: purchaseData.isPrescribedBy,
              showPrescribedBy: showPrescribedBy,
              noteListRow: true,
            });
          } else {
            this.setState({
              render: true,
              viewPurchaseCard: true,
              editPurchaseCard: false,
              id: purchaseData.id,
              startDate: new Date(newStartDate),
              purchaseStartDate: viewStartDate,
              purchaseType: purchaseData.type,
              selectedDoctor: selectedDoctor,
              doctor: selectedDoctor.doctorName,
              shortDescription: purchaseData.shortDescription,
              longDescription: purchaseData.description,
              isPrescribedBy: purchaseData.isPrescribedBy,
              showPrescribedBy: showPrescribedBy,
              noteListRow: true,
            });
          }
        } else {
        }
      })
      .catch((error) => {});
  }

  deletePurchaseInBackend = () => {
    const postObject = {
      header: this.header,
      purchase: {
        id: this.state.id,
      },
    };

    FetchServerData.callPostService('purchasems/delete', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          this.props.history.push({
            pathname: '/memberPurchase',
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  createCancel() {
    this.props.history.push({
      pathname: '/memberPurchase',
    });
  }

  viewCancel() {
    this.props.history.push({
      pathname: '/memberPurchase',
    });
  }

  editPurchase = (e) => {
    this.setState({
      viewPurchaseCard: false,
      editPurchaseCard: true,
    });
  };

  purchaseEditCancel = (e) => {
    this.setState({
      viewPurchaseCard: true,
      editPurchaseCard: false,
    });
  };
  cancelPrintView() {
    this.setState({ action: 'view' });
  }
  addNotes() {
    let notes = this.state.purchaseNoteList;
    let note = {
      action: 'create',
      operation: 'add',
      shortDescription: this.state.purchaseType,
      purchaseOccurance: this.state.purchaseType,
      purchase: {
        id: this.state.id,
      },
      occurance: {
        occurance: '',
      },
    };
    notes.push(note);
    this.setState({ purchaseNoteList: notes });
  }

  backToNoteList() {
    this.setState({ createNoteListRow: false, noteListRow: true });
  }

  purchaseNoteRemove(item) {
    let items = [];
    if (item.operation && 'update' === item.operation) {
      /* Existing Item Deleted, Mark it deleted */
      let index = item.index;

      for (let i = 0; i < this.state.purchaseNoteList.length; i++) {
        let item = this.state.purchaseNoteList[i];
        if (this.state.purchaseNoteList[i].operation === 'update' && index === i) {
          item.operation = 'delete';
        }

        items.push(item);
      }

      this.setState({ purchaseNoteList: items });
    }
    if (item.operation && 'add' === item.operation) {
      /* Added Item Deleted, Remove from list */
      let index = item.index;
      for (let i = 0; i < this.state.purchaseNoteList.length; i++) {
        if (this.state.purchaseNoteList[i].operation === 'add' && index === i) {
          this.purchaseNoteRefList.splice(i, 1);
          // continue;
        } else {
          items.push(this.state.purchaseNoteList[i]);
        }
      }
      this.setState({ purchaseNoteList: items });
    }

    /*Go Back to Location list view if all new Locations are deleted */
    let flag = true;
    for (let i = 0; i < items.length; i++) {
      if (items[i].operation === 'add') {
        flag = false;
      }
    }
    if (flag) {
      this.backToNoteList();
    }
  }

  addPurchaseNotesInBackend(note) {
    for (let i = 0; i < this.state.purchaseNoteList.length; i++) {
      if (
        this.state.purchaseNoteList[i] &&
        note.index === i &&
        this.purchaseNoteRefList[i] &&
        this.purchaseNoteRefList[i].current
      ) {
        let noteObj = {
          purchase: {
            id: this.state.id,
          },
          occurance: {
            occurance: this.purchaseNoteRefList[i].current.state.occurrence,
          },
          note: this.purchaseNoteRefList[i].current.state.note,
          shortDescription: this.purchaseNoteRefList[i].current.state.shortDescription,
        };

        const postObject = {
          header: this.header,
          note: noteObj,
        };
        FetchServerData.callPostService('purchasems/createNote', postObject)
          .then((output) => {
            if (output.status === 'SUCCESS') {
              let noteListData = output.data.purchase;

              for (let i = 0; i < this.state.purchaseNoteList.length; i++) {
                this.purchaseNoteRefList[i].current.state.action = 'view';
              }

              this.setState({
                purchaseNoteList: noteListData.noteList,
              });
            } else {
            }
          })
          .catch((error) => {});
      }
    }
  }

  updatePurchaseNoteInBackend = (purchaseNote) => {
    let postObject = {};
    for (let i = 0; i < this.state.purchaseNoteList.length; i++) {
      if (purchaseNote.id === this.state.purchaseNoteList[i].id) {
        postObject = {
          header: this.header,
          note: {
            id: purchaseNote.id,
            note: purchaseNote.note,
            purchase: {
              id: this.state.id,
            },
            occurance: {
              occurance: purchaseNote.occurrence,
            },
            shortDescription: purchaseNote.shortDescription,
          },
        };
        FetchServerData.callPostService('purchasems/updateNote', postObject).then((output) => {
          if (output.status === 'SUCCESS') {
            PtsAlert.success(output.message);
            this.state.purchaseNoteList = [];
            this.setState({
              purchaseNoteList: output.data.purchase.noteList,
            });
          } else {
          }
        });
      }
    }
  };

  editNote(type, note) {}

  deletePurchaseNoteInBackend(e, note) {
    let id = note.id;
    const postObject = {
      header: this.header,
      note: {
        id: id,
      },
    };

    FetchServerData.callPostService('purchasems/deleteNote', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        for (let i = 0; i < this.state.purchaseNoteList.length; i++) {
          if (this.state.purchaseNoteList[i].id === id) {
            this.state.purchaseNoteList.splice(i, 1);
          }
        }

        this.setState({
          purchaseNoteList: this.state.purchaseNoteList,
          noteAlert: false,
        });
      } else {
      }
    });
  }

  deleteReminderInBackend(e, reminderItem) {
    const postObject = {
      header: this.header,
      reminder: {
        id: reminderItem.id,
        purchase: {
          id: this.state.id,
        },
      },
    };

    FetchServerData.callPostService('purchasems/deleteReminder', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        for (let i = 0; i < this.state.reminderItems.length; i++) {
          if (this.state.reminderItems[i].id === reminderItem.id) {
            this.state.reminderItems.splice(i, 1);
          }
        }

        this.setState({
          reminderItems: this.state.reminderItems,
        });
      } else {
      }
    });
  }

  createSimilarPurchase() {
    this.props.history.push({
      pathname: '/createSimilarPurchase',
      state: {
        action: 'createSimilarIncident',
        title: 'Create Similar Purchase',
        purchaseData: this.state.purchaseData,
      },
    });
  }

  printPurchaseContent(e) {
    e.preventDefault();
    let printContents = document.getElementById('printform').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();
    document.body.innerHTML = originalContents;

    this.setState({
      action: 'printPurchase',
      render: true,
    });
  }

  render() {
    this.purchaseNoteRefList = [];
    for (let i = 0; this.state.purchaseNoteList && i < this.state.purchaseNoteList.length; i++) {
      this.purchaseNoteRefList[i] = React.createRef();
    }
    if (this.state.action === 'create') {
      return this.createPurchase();
    } else if (this.state.action === 'view') {
      return this.viewPurchase();
    } else if (this.state.action === 'printPurchase') {
      return this.printPurchase();
    }
  }

  createPurchase() {
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={'Create Purchase For' + ' ' + this.memberName}
          domainObject="Purchase"
        />
        <ValidatorForm style={{ width: '100%' }} ref="form" onSubmit={this.addPurchaseInBackend.bind(this)}>
          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={12} style={{ position: 'relative', padding: '5px' }}>
              <Card className="doctorViewCard" style={{ height: '100%' }}>
                <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                  <Label style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}>{this.state.title}</Label>
                </CardHeader>
                <CardBody>
                  <Row style={{ marginTop: '1em' }}>
                    <Col md={6} style={{ marginBottom: '1em' }}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ThemeProvider theme={materialTheme}>
                          <DateTimePicker
                            value={this.state.startDate}
                            variant="outlined"
                            fullWidth
                            label="Date"
                            className="ptsDateTimePicker"
                            inputVariant="outlined"
                            timeInputLabel="Time:"
                            format="MM/dd/yyyy  h:mm aa"
                            style={{ background: '#fff' }}
                            onChange={this.handlePurchaseStartDateChange.bind(this)}
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>
                    </Col>
                    <Col md={6}>
                      <input
                        className="pts_dataList_dropDown"
                        type="text"
                        required
                        // labelId="Purchase-outlined-label"
                        id="Purchase-outlined"
                        list="Purchase Type"
                        placeholder="Purchase Type*"
                        label="Purchase Type*"
                        value={this.state.purchaseType}
                        onChange={(event, value) => {
                          this.handlePurchaseTypeChange(event, value);
                        }}
                      />
                      <datalist id="Purchase Type">
                        {this.purchaseTypeList.map((event, key) => (
                          <option key={event.id} value={event.type} />
                        ))}
                      </datalist>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Row>
                        <Col md={6} style={{ padding: '2em' }}>
                          <span style={{ color: '#000' }}>
                            <strong>Is It Prescribed by Doctor?</strong>
                            &nbsp;{' '}
                            <Checkbox
                              checked={this.state.isPrescribedBy}
                              onChange={(e) => this.handlePrescribedByChange(e)}
                              color="primary"
                              inputprops={{ 'aria-label': 'secondary checkbox' }}
                            />
                          </span>
                        </Col>
                        {this.state.showPrescribedBy && (
                          <Col md={6}>
                            <Autocomplete
                              id="Prescribed By"
                              options={this.state.doctorList}
                              value={this.state.selectedDoctor}
                              getOptionLabel={(option) => option.doctorName}
                              onChange={(event, value) => {
                                this.handleDoctorChange(value);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Prescribed By"
                                  margin="normal"
                                  required
                                />
                              )}
                            />
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '1em' }}>
                    <Col md={12}>
                      <TextareaAutosize
                        aria-label="minimum height"
                        fullWidth
                        style={{ width: '100%', padding: '10px', border: '0.5px solid #D5D8DC' }}
                        variant="outlined"
                        value={this.state.longDescription}
                        onChange={this.handleLongDescriptionChange.bind(this)}
                        minRows={4}
                        placeholder="Description *"
                        required
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <div style={{ textAlign: 'center' }}>
            <CreateButton>Create</CreateButton>
            &nbsp;
            <CancelIconButton onClick={(e) => this.createCancel()} />
          </div>
        </ValidatorForm>
      </>
    );
  }

  printPreviewPurchase() {
    this.setState({
      action: 'printPurchase',
      helpModal: true,
    });
  }
  helpModelToggle = () => {
    this.setState({
      helpModal: !this.state.helpModal,
    });
  };

  // printPreviewPurchase = () => {
  //   this.setState({
  //     helpModal: true,
  //   });
  // };
  getBreadCrumb() {
    return (
      <MenuWithBreadScrum
        getDeletePermission={this.state.getDeletePermission}
        getAllPermission={this.state.getAllPermission}
        backCallback={this.viewCancel.bind(this)}
        deleteCallback={this.deletePurchaseInBackend.bind(this)}
        createSimilarCallback={this.createSimilarPurchase.bind(this)}
        breadCrumb={this.breadCrumb}
        history={this.props.history}
        name={this.title + ' For ' + this.memberName}
        domainObject="Purchase"
      />
    );
  }

  viewPurchase() {
    if (this.state.render === false) {
      return <div />;
    } else {
      if (this.state.isFreeUser === true) {
        return this.viewPurchaseWithAdvertisement();
      } else {
        return this.viewPurchaseWithoutAdvertisement();
      }
    }
  }

  viewPurchaseWithAdvertisement() {
    if (this.state.render === false) {
      return <div />;
    } else {
      let reminderList = [];
      this.state.reminderItems.forEach((reminder, index) => {
        this.purchaseReminderRefList[index] = React.createRef();
        if ('delete' !== reminder.operation) {
          reminderList.push(
            <Col md={4}>
              <PurchaseReminder
                ref={this.purchaseReminderRefList[index]}
                key={index}
                reminderDate={this.state.startDate}
                data={reminder}
                srNo={index}
                pageId={this.state.pageId}
                isPrintView={this.state.isPrintView}
                getDeletePermission={this.state.getDeletePermission}
                getUpdatePermission={this.state.getUpdatePermission}
                action={reminder.action ? reminder.action : 'viewPurchaseReminder'}
                onReminderAdd={this.addNewReminderInBackend.bind(this)}
                onCancel={this.reminderItemDeleted.bind(this)}
                onDelete={this.deleteReminderInBackend.bind(this, reminder)}
                onUpdate={this.updateReminderInBackend.bind(this)}
              />
            </Col>
          );
        }
      });

      let taskNotes = [];
      this.state.purchaseNoteList.forEach((note, index) => {
        this.purchaseNoteRefList[index] = React.createRef();
        if ('delete' !== note.operation) {
          taskNotes.unshift(
            <Col md={6} style={{ marginTop: '1em' }}>
              <MyPurchaseNote
                ref={this.purchaseNoteRefList[index]}
                key={index}
                data={note}
                srno={index}
                action={note.action ? note.action : 'view'}
                getDeletePermission={this.state.getPurchaseNoteDeletePermission}
                getCreatePermission={this.state.getPurchaseNoteCreatePermission}
                getUpdatePermission={this.state.getPurchaseNoteUpdatePermission}
                isPrintView={this.state.isPrintView}
                purchaseId={this.state.id}
                shortDescription={note.shortDescription}
                noteId={this.state.noteId}
                pageId={this.state.pageId}
                onNoteEdit={this.editNote.bind(this, note)}
                onNoteDelete={this.deletePurchaseNoteInBackend.bind(this, note)}
                // onNoteUpdate={this.updatePurchaseNoteInBackend.bind(this, note, index)}
                onNoteUpdate={this.updatePurchaseNoteInBackend.bind(this)}
                addNote={this.addPurchaseNotesInBackend.bind(this)}
                onDelete={this.purchaseNoteRemove.bind(this)}
                onSuccess={this.getPurchaseDetails.bind(this)}
              />
            </Col>
          );
        }
      });

      return (
        <>
          {this.state.isViewButtonHide && (
            <>
              <MenuWithBreadScrum
                getDeletePermission={this.state.getDeletePermission}
                printCallback={this.printPreviewPurchase.bind(this)}
                backCallback={this.viewCancel.bind(this)}
                getCreatePermission={this.state.getCreatePermission}
                deleteCallback={this.deletePurchaseInBackend.bind(this)}
                createSimilarCallback={this.createSimilarPurchase.bind(this)}
                breadCrumb={this.breadCrumb}
                history={this.props.history}
                name={this.title + ' For ' + this.memberName}
                domainObject="Purchase"
              />
            </>
          )}
          {this.state.isButtonHide && (
            <MenuWithBreadScrum
              breadCrumb={this.breadCrumb}
              history={this.props.history}
              name="Edit Purchase"
              domainObject="Purchase"
            />
          )}

          {this.state.purchaseNoteList.length > 0 ? (
            <Steps
              enabled={this.state.stepsEnabled}
              steps={this.state.stepsWithNote}
              initialStep={this.state.initialStep}
              onExit={this.onExit}
            />
          ) : (
            <Steps
              enabled={this.state.stepsEnabled}
              steps={this.state.steps}
              initialStep={this.state.initialStep}
              onExit={this.onExit}
            />
          )}
          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={12} style={{ position: 'relative', padding: '5px' }}>
              <Card style={{ height: '100%', borderRadius: '20px' }}>
                {this.state.viewPurchaseCard && (
                  <>
                    <CardHeader
                      style={{
                        color: '#162c50',
                        fontWeight: ' bold',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
                        padding: '0.3em',
                      }}
                    >
                      <Row>
                        <Col md={10}>
                          <Label
                            style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                          >
                            {this.state.title}
                          </Label>
                        </Col>
                        <Col md={2}>
                          {this.state.isViewButtonHide && (
                            <>
                              {this.state.getUpdatePermission && (
                                <EditIconButton onClick={this.editPurchase.bind(this)} />
                              )}
                            </>
                          )}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md={2}>
                          <label className="purchaseLabel">Date</label>
                        </Col>
                        <Col md={10}>
                          <span>{this.state.purchaseStartDate}</span>
                        </Col>
                      </Row>
                      <Row className="purchaseRow">
                        <Col md={2}>
                          <label className="purchaseLabel">Purchase Type</label>
                        </Col>
                        <Col md={10}>
                          <span>{this.state.purchaseType}</span>
                        </Col>
                      </Row>
                      {this.state.doctor && (
                        <Row className="purchaseRow">
                          <Col md={2}>
                            <label className="purchaseLabel">Prescribed By</label>
                          </Col>
                          <Col md={10}>
                            <span>{this.state.doctor}</span>
                          </Col>
                        </Row>
                      )}
                      <Row className="purchaseRow">
                        <Col md={2}>
                          <label className="purchaseLabel">Description</label>
                        </Col>
                        <Col md={10}>
                          <span>
                            <pre className="view_purchaseNote" style={{ marginLeft: '-1.9px' }}>
                              {this.state.longDescription}
                            </pre>
                          </span>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <label
                            style={{
                              fontWeight: 'normal',
                              color: '#888888',
                              width: 'auto',
                              fontSize: 'xx-small',
                              textAlign: 'left',
                              padding: '0',
                              margin: '0em 0em 0em 2em',
                            }}
                          >
                            {this.state.createdByName && 'Created by ' + this.state.createdByName}
                            {this.state.createdOn &&
                              ' on ' + Utils.convertUTCDateToUserTimeZoneDate(this.state.createdOn)}
                          </label>
                        </Col>
                      </Row>
                    </CardBody>
                  </>
                )}

                {this.state.editPurchaseCard && (
                  <>
                    <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                      <Label style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}>
                        {this.state.title}
                      </Label>
                    </CardHeader>
                    <CardBody>
                      <Row style={{ marginTop: '1em' }}>
                        <Col md={6} style={{ marginBottom: '1em' }}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <ThemeProvider theme={materialTheme}>
                              <DateTimePicker
                                value={this.state.startDate}
                                variant="outlined"
                                fullWidth
                                label="Date"
                                className="ptsDateTimePicker"
                                inputVariant="outlined"
                                timeInputLabel="Time:"
                                format="MM/dd/yyyy  h:mm aa"
                                style={{ background: '#fff' }}
                                onChange={this.handlePurchaseStartDateChange.bind(this)}
                              />
                            </ThemeProvider>
                          </MuiPickersUtilsProvider>
                        </Col>
                        <Col md={6}>
                          <input
                            className="pts_dataList_dropDown"
                            type="text"
                            required
                            id="Purchase-outlined"
                            list="Purchase Type"
                            placeholder="Purchase Type*"
                            label="Purchase Type*"
                            value={this.state.purchaseType}
                            onChange={(event, value) => {
                              this.handlePurchaseTypeChange(event, value);
                            }}
                          />
                          <datalist id="Purchase Type">
                            {this.purchaseTypeList.map((event, key) => (
                              <option key={event.id} value={event.type} />
                            ))}
                          </datalist>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <Row>
                            <Col md={6} style={{ padding: '2em' }}>
                              <span style={{ color: '#000' }}>
                                <strong>Is It Prescribed by Doctor?</strong>
                                &nbsp;{' '}
                                <Checkbox
                                  checked={this.state.isPrescribedBy}
                                  onChange={(e) => this.handlePrescribedByChange(e)}
                                  color="primary"
                                  inputprops={{ 'aria-label': 'secondary checkbox' }}
                                />
                              </span>
                            </Col>
                            {this.state.showPrescribedBy && (
                              <Col md={6}>
                                <Autocomplete
                                  id="Prescribed By"
                                  options={this.state.doctorList}
                                  value={this.state.selectedDoctor}
                                  getOptionLabel={(option) => option.doctorName}
                                  onChange={(event, value) => {
                                    this.handleDoctorChange(value);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      label="Prescribed By"
                                      margin="normal"
                                      required
                                    />
                                  )}
                                />
                              </Col>
                            )}
                          </Row>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '1em' }}>
                        <Col md={12}>
                          <TextareaAutosize
                            aria-label="minimum height"
                            required
                            fullWidth
                            style={{ width: '100%', padding: '10px', border: '0.5px solid #D5D8DC' }}
                            variant="outlined"
                            value={this.state.longDescription}
                            onChange={this.handleLongDescriptionChange.bind(this)}
                            minRows={4}
                            placeholder="Description *"
                          />
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter style={{ textAlign: 'center' }}>
                      <SaveButton type="submit" onClick={(e) => this.updatePurchaseInBackend()} />
                      &nbsp;
                      <CancelIconButton type="button" onClick={(e) => this.purchaseEditCancel(e, this.state.id)} />
                    </CardFooter>
                  </>
                )}
              </Card>
            </Col>
            <Col md={2} style={{ position: 'relative' }}></Col>
          </Row>
          {this.state.showPurchaseNotePermission === true ? (
            <>
              <Row md={12} style={{ position: 'relative', margin: '1em', padding: '5px' }}>
                <Card style={{ borderRadius: '20px', width: '100%', margin: 'auto' }}>
                  <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                    <Row>
                      <Col md={8}>
                        <Label
                          style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                          className="noteTitle"
                        >
                          Notes
                        </Label>
                      </Col>
                      <Col md={4}>
                        {this.state.isViewButtonHide && (
                          <>
                            {this.state.getPurchaseNoteCreatePermission && (
                              <span className="addNote" style={{ float: 'right' }}>
                                <Tooltip title="Add Location" aria-label="add">
                                  <AddIconButton onClick={this.addNotes.bind(this)} btnName="Add Note" />
                                </Tooltip>
                              </span>
                            )}
                          </>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>

                  <CardBody>
                    <Row>{taskNotes}</Row>
                  </CardBody>
                </Card>
              </Row>
            </>
          ) : (
            <></>
          )}
          <Row md={12} style={{ position: 'relative', margin: '1em' }} id="reminderSection">
            <Col md={12} style={{ position: 'relative', padding: '5px' }}>
              <Card style={{ height: '100%', borderRadius: '20px' }}>
                <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                  <Row>
                    <Col md={8}>
                      <Label
                        className="reminderTitle"
                        style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                      >
                        Reminders
                      </Label>
                    </Col>
                    <Col md={4}>
                      <>
                        {this.state.isViewButtonHide && (
                          <>
                            {this.state.getCreatePermission && (
                              <Tooltip title="Add Reminder" aria-label="add">
                                <span className="newReminder" style={{ float: 'right' }}>
                                  <AddIconButton onClick={this.addReminder.bind(this, 'edit')} btnName="Add Reminder" />{' '}
                                </span>
                              </Tooltip>
                            )}{' '}
                          </>
                        )}
                      </>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row style={{ marginTop: '1em' }}>{reminderList}</Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {this.state.isViewButtonHide && (
            <>
              {this.state.isEditButtonHide && (
                <div style={{ textAlign: 'center', padding: '0em 0em 1em' }}>
                  <BackButton
                    type="button"
                    style={{ backgroundColor: '#162c50', color: '#fff' }}
                    variant="contained"
                    startIcon={<CloseIcon />}
                    onClick={(e) => this.viewCancel()}
                  >
                    Back
                  </BackButton>{' '}
                </div>
              )}
            </>
          )}
        </>
      );
    }
  }

  viewPurchaseWithoutAdvertisement() {
    if (this.state.render === false) {
      return <div />;
    } else {
      let reminderList = [];
      this.state.reminderItems.forEach((reminder, index) => {
        this.purchaseReminderRefList[index] = React.createRef();
        if ('delete' !== reminder.operation) {
          reminderList.push(
            <Col md={4}>
              <PurchaseReminder
                ref={this.purchaseReminderRefList[index]}
                key={index}
                reminderDate={this.state.startDate}
                data={reminder}
                srNo={index}
                pageId={this.state.pageId}
                isPrintView={this.state.isPrintView}
                getDeletePermission={this.state.getDeletePermission}
                getUpdatePermission={this.state.getUpdatePermission}
                action={reminder.action ? reminder.action : 'viewPurchaseReminder'}
                onReminderAdd={this.addNewReminderInBackend.bind(this)}
                onCancel={this.reminderItemDeleted.bind(this)}
                onDelete={this.deleteReminderInBackend.bind(this, reminder)}
                onUpdate={this.updateReminderInBackend.bind(this)}
              />
            </Col>
          );
        }
      });

      let taskNotes = [];
      this.state.purchaseNoteList.forEach((note, index) => {
        this.purchaseNoteRefList[index] = React.createRef();
        if ('delete' !== note.operation) {
          taskNotes.unshift(
            <Col md={6} style={{ marginTop: '1em' }}>
              <MyPurchaseNote
                ref={this.purchaseNoteRefList[index]}
                key={index}
                data={note}
                srno={index}
                action={note.action ? note.action : 'view'}
                getDeletePermission={this.state.getPurchaseNoteDeletePermission}
                getCreatePermission={this.state.getPurchaseNoteCreatePermission}
                getUpdatePermission={this.state.getPurchaseNoteUpdatePermission}
                isPrintView={this.state.isPrintView}
                purchaseId={this.state.id}
                shortDescription={note.shortDescription}
                noteId={this.state.noteId}
                pageId={this.state.pageId}
                onNoteEdit={this.editNote.bind(this, note)}
                onNoteDelete={this.deletePurchaseNoteInBackend.bind(this, note)}
                // onNoteUpdate={this.updatePurchaseNoteInBackend.bind(this, note, index)}
                onNoteUpdate={this.updatePurchaseNoteInBackend.bind(this)}
                addNote={this.addPurchaseNotesInBackend.bind(this)}
                onDelete={this.purchaseNoteRemove.bind(this)}
                onSuccess={this.getPurchaseDetails.bind(this)}
              />
            </Col>
          );
        }
      });

      return (
        <>
          {this.state.isViewButtonHide && (
            <>
              <MenuWithBreadScrum
                getDeletePermission={this.state.getDeletePermission}
                printCallback={this.printPreviewPurchase.bind(this)}
                backCallback={this.viewCancel.bind(this)}
                getCreatePermission={this.state.getCreatePermission}
                deleteCallback={this.deletePurchaseInBackend.bind(this)}
                createSimilarCallback={this.createSimilarPurchase.bind(this)}
                breadCrumb={this.breadCrumb}
                history={this.props.history}
                name={this.title + ' For ' + this.memberName}
                domainObject="Purchase"
              />
            </>
          )}
          {this.state.isButtonHide && (
            <MenuWithBreadScrum
              breadCrumb={this.breadCrumb}
              history={this.props.history}
              name="Edit Purchase"
              domainObject="Purchase"
            />
          )}

          {this.state.purchaseNoteList.length > 0 ? (
            <Steps
              enabled={this.state.stepsEnabled}
              steps={this.state.stepsWithNote}
              initialStep={this.state.initialStep}
              onExit={this.onExit}
            />
          ) : (
            <Steps
              enabled={this.state.stepsEnabled}
              steps={this.state.steps}
              initialStep={this.state.initialStep}
              onExit={this.onExit}
            />
          )}
          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={12} style={{ position: 'relative', padding: '5px' }}>
              <Card style={{ height: '100%', borderRadius: '20px' }}>
                {this.state.viewPurchaseCard && (
                  <>
                    <CardHeader
                      style={{
                        color: '#162c50',
                        fontWeight: ' bold',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
                        padding: '0.3em',
                      }}
                    >
                      <Row>
                        <Col md={10}>
                          <Label
                            style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                          >
                            {this.state.title}
                          </Label>
                        </Col>
                        <Col md={2}>
                          {this.state.isViewButtonHide && (
                            <>
                              {this.state.getUpdatePermission && (
                                <EditIconButton onClick={this.editPurchase.bind(this)} />
                              )}
                            </>
                          )}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md={2}>
                          <label className="purchaseLabel">Date</label>
                        </Col>
                        <Col md={10}>
                          <span>{this.state.purchaseStartDate}</span>
                        </Col>
                      </Row>
                      <Row className="purchaseRow">
                        <Col md={2}>
                          <label className="purchaseLabel">Purchase Type</label>
                        </Col>
                        <Col md={10}>
                          <span>{this.state.purchaseType}</span>
                        </Col>
                      </Row>
                      {this.state.doctor && (
                        <Row className="purchaseRow">
                          <Col md={2}>
                            <label className="purchaseLabel">Prescribed By</label>
                          </Col>
                          <Col md={10}>
                            <span>{this.state.doctor}</span>
                          </Col>
                        </Row>
                      )}
                      <Row className="purchaseRow">
                        <Col md={2}>
                          <label className="purchaseLabel">Description</label>
                        </Col>
                        <Col md={10}>
                          <span>
                            <pre className="view_purchaseNote" style={{ marginLeft: '-1.9px' }}>
                              {this.state.longDescription}
                            </pre>
                          </span>
                        </Col>
                      </Row>
                    </CardBody>
                    <Row>
                      <Col md={12}>
                        <label
                          style={{
                            fontWeight: 'normal',
                            color: '#888888',
                            width: 'auto',
                            fontSize: 'xx-small',
                            textAlign: 'left',
                            padding: '0',
                            margin: '0em 0em 0em 2em',
                          }}
                        >
                          {this.state.createdByName && 'Created by ' + this.state.createdByName}
                          {this.state.createdOn &&
                            ' on ' + Utils.convertUTCDateToUserTimeZoneDate(this.state.createdOn)}
                        </label>
                      </Col>
                    </Row>
                  </>
                )}

                {this.state.editPurchaseCard && (
                  <>
                    <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                      <Label style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}>
                        {this.state.title}
                      </Label>
                    </CardHeader>
                    <CardBody>
                      <Row style={{ marginTop: '1em' }}>
                        <Col md={6} style={{ marginBottom: '1em' }}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <ThemeProvider theme={materialTheme}>
                              <DateTimePicker
                                value={this.state.startDate}
                                variant="outlined"
                                fullWidth
                                label="Date"
                                className="ptsDateTimePicker"
                                inputVariant="outlined"
                                timeInputLabel="Time:"
                                format="MM/dd/yyyy  h:mm aa"
                                style={{ background: '#fff' }}
                                onChange={this.handlePurchaseStartDateChange.bind(this)}
                              />
                            </ThemeProvider>
                          </MuiPickersUtilsProvider>
                        </Col>
                        <Col md={6}>
                          <input
                            className="pts_dataList_dropDown"
                            type="text"
                            required
                            id="Purchase-outlined"
                            list="Purchase Type"
                            placeholder="Purchase Type*"
                            label="Purchase Type*"
                            value={this.state.purchaseType}
                            onChange={(event, value) => {
                              this.handlePurchaseTypeChange(event, value);
                            }}
                          />
                          <datalist id="Purchase Type">
                            {this.purchaseTypeList.map((event, key) => (
                              <option key={event.id} value={event.type} />
                            ))}
                          </datalist>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6}>
                          <Row>
                            <Col md={6} style={{ padding: '2em' }}>
                              <span style={{ color: '#000' }}>
                                <strong>Is It Prescribed by Doctor?</strong>
                                &nbsp;{' '}
                                <Checkbox
                                  checked={this.state.isPrescribedBy}
                                  onChange={(e) => this.handlePrescribedByChange(e)}
                                  color="primary"
                                  inputprops={{ 'aria-label': 'secondary checkbox' }}
                                />
                              </span>
                            </Col>
                            {this.state.showPrescribedBy && (
                              <Col md={6}>
                                <Autocomplete
                                  id="Prescribed By"
                                  options={this.state.doctorList}
                                  value={this.state.selectedDoctor}
                                  getOptionLabel={(option) => option.doctorName}
                                  onChange={(event, value) => {
                                    this.handleDoctorChange(value);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      label="Prescribed By"
                                      margin="normal"
                                      required
                                    />
                                  )}
                                />
                              </Col>
                            )}
                          </Row>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '1em' }}>
                        <Col md={12}>
                          <TextareaAutosize
                            aria-label="minimum height"
                            required
                            fullWidth
                            style={{ width: '100%', padding: '10px', border: '0.5px solid #D5D8DC' }}
                            variant="outlined"
                            value={this.state.longDescription}
                            onChange={this.handleLongDescriptionChange.bind(this)}
                            minRows={4}
                            placeholder="Description *"
                          />
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter style={{ textAlign: 'center' }}>
                      <SaveButton type="submit" onClick={(e) => this.updatePurchaseInBackend()} />
                      &nbsp;
                      <CancelIconButton type="button" onClick={(e) => this.purchaseEditCancel(e, this.state.id)} />
                    </CardFooter>
                  </>
                )}
              </Card>
            </Col>
          </Row>
          {this.state.showPurchaseNotePermission === true ? (
            <>
              <Row md={12} style={{ position: 'relative', margin: '1em', padding: '5px' }}>
                <Card style={{ borderRadius: '20px', width: '100%', margin: 'auto' }}>
                  <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                    <Row>
                      <Col md={8}>
                        <Label
                          style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                          className="noteTitle"
                        >
                          Notes
                        </Label>
                      </Col>
                      <Col md={4}>
                        {this.state.isViewButtonHide && (
                          <>
                            {this.state.getPurchaseNoteCreatePermission && (
                              <>
                                <span className="addNote" style={{ float: 'right' }}>
                                  <Tooltip title="Add Location" aria-label="add">
                                    <AddIconButton onClick={this.addNotes.bind(this)} btnName="Add Note" />
                                  </Tooltip>
                                </span>
                              </>
                            )}
                          </>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>

                  <CardBody>
                    <Row>{taskNotes}</Row>
                  </CardBody>
                </Card>
              </Row>
            </>
          ) : (
            <></>
          )}
          <Row md={12} style={{ position: 'relative', margin: '1em' }} id="reminderSection">
            <Col md={12} style={{ position: 'relative', padding: '5px' }}>
              <Card style={{ height: '100%', borderRadius: '20px' }}>
                <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                  <Row>
                    <Col md={8}>
                      <Label
                        className="reminderTitle"
                        style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                      >
                        Reminders
                      </Label>
                    </Col>

                    <Col md={4}>
                      <>
                        {this.state.isViewButtonHide && (
                          <>
                            {this.state.getCreatePermission && (
                              <Tooltip title="Add Reminder" aria-label="add">
                                <span className="newReminder" style={{ float: 'right' }}>
                                  <AddIconButton onClick={this.addReminder.bind(this, 'edit')} btnName="Add Reminder" />{' '}
                                </span>
                              </Tooltip>
                            )}{' '}
                          </>
                        )}
                      </>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>{reminderList}</Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          {this.state.isViewButtonHide && (
            <>
              {this.state.isEditButtonHide && (
                <div style={{ textAlign: 'center', padding: '0em 0em 1em' }}>
                  <BackButton
                    type="button"
                    style={{ backgroundColor: '#162c50', color: '#fff' }}
                    variant="contained"
                    startIcon={<CloseIcon />}
                    onClick={(e) => this.viewCancel()}
                  >
                    Back
                  </BackButton>{' '}
                </div>
              )}
            </>
          )}
        </>
      );
    }
  }

  printPurchase() {
    if (this.state.render === false) {
      return <div />;
    } else {
      let reminderList = [];
      this.state.reminderItems.forEach((reminder, index) => {
        this.purchaseReminderRefList[index] = React.createRef();
        if ('delete' !== reminder.operation) {
          reminderList.push(
            <Col md={4}>
              <PurchaseReminder
                ref={this.purchaseReminderRefList[index]}
                key={index}
                reminderDate={this.state.startDate}
                data={reminder}
                srNo={index}
                pageId={this.state.pageId}
                isPrintView="view_print"
                getDeletePermission={this.state.getDeletePermission}
                getUpdatePermission={this.state.getUpdatePermission}
                action="printReminder"
                onReminderAdd={this.addNewReminderInBackend.bind(this)}
                onCancel={this.reminderItemDeleted.bind(this)}
                onDelete={this.deleteReminderInBackend.bind(this, reminder)}
                onUpdate={this.updateReminderInBackend.bind(this)}
              />
            </Col>
          );
        }
      });

      let taskNotes = [];
      this.state.purchaseNoteList.forEach((note, index) => {
        this.purchaseNoteRefList[index] = React.createRef();
        if ('delete' !== note.operation) {
          taskNotes.unshift(
            <Col md={6} style={{ marginTop: '1em' }}>
              <MyPurchaseNote
                ref={this.purchaseNoteRefList[index]}
                key={index}
                data={note}
                srno={index}
                action={note.action ? note.action : 'view'}
                getDeletePermission={this.state.getPurchaseNoteDeletePermission}
                getCreatePermission={this.state.getPurchaseNoteCreatePermission}
                getUpdatePermission={this.state.getPurchaseNoteUpdatePermission}
                isPrintView="view_print"
                purchaseId={this.state.id}
                shortDescription={note.shortDescription}
                noteId={this.state.noteId}
                pageId={this.state.pageId}
                onNoteEdit={this.editNote.bind(this, note)}
                onNoteDelete={this.deletePurchaseNoteInBackend.bind(this, note)}
                onNoteUpdate={this.updatePurchaseNoteInBackend.bind(this)}
                addNote={this.addPurchaseNotesInBackend.bind(this)}
                onDelete={this.purchaseNoteRemove.bind(this)}
                onSuccess={this.getPurchaseDetails.bind(this)}
              />
            </Col>
          );
        }
      });

      return (
        <>
          {this.state.isPrintView === 'view_print' ? (
            <>
              <Row md={12} className={this.state.headerClassName}>
                <Col md={4}>
                  <h5 style={{ textTransform: 'capitalize' }}>
                    <b>{this.title + ' For ' + this.memberName}</b>
                  </h5>
                </Col>
                <Col md={8} style={{ textTransform: 'capitalize' }}></Col>
              </Row>
            </>
          ) : (
            <MenuWithBreadScrum
              backCallback={this.cancelPrintView.bind(this)}
              breadCrumb={this.breadCrumb}
              history={this.props.history}
              name={'Print Preview of Purchase' + ' For ' + this.memberName}
              domainObject="Purchase"
            />
          )}
          {this.state.isPrintView === 'view_print' ? (
            <></>
          ) : (
            <Row>
              <Col md={12}>
                <ReactToPrint
                  trigger={() => (
                    <span style={{ cursor: 'pointer', fontSize: 'x-larger', marginRight: '2em', float: 'right' }}>
                      <Tooltip title="Print">
                        <Button
                          className="add"
                          style={{
                            background: '#867e7e',
                            color: 'white',
                            fontSize: 'x-large',
                            textTransform: 'capitalize',
                            cursor: 'pointer',
                            fontSize: 'x-larger',
                            float: 'right',
                            margin: '0.2em 0.5em 0.3em 0em',
                          }}
                          startIcon={<PrintRoundedIcon />}
                        >
                          Print
                        </Button>
                      </Tooltip>
                    </span>
                  )}
                  content={() => this.componentRef}
                />
              </Col>
            </Row>
          )}
          <div id="printform" style={{ overflowY: 'scroll' }} ref={(el) => (this.componentRef = el)}>
            <Row style={{ position: 'relative', margin: '1em' }}>
              <Col md={12} style={{ position: 'relative', padding: '5px' }}>
                <Card style={{ height: '100%', borderRadius: '20px' }}>
                  {this.state.viewPurchaseCard && (
                    <>
                      <CardHeader
                        style={{
                          color: '#162c50',
                          fontWeight: ' bold',
                          borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
                          padding: '0.3em',
                        }}
                      >
                        <Row style={{ position: 'relative', marginTop: '0.5em' }}>
                          <Col md={12}>
                            {' '}
                            <label style={{ color: '#000' }}>{'Purchase for ' + this.memberName}</label>
                          </Col>
                        </Row>
                        {/* <Row>
                          <Col md={10}>
                            <Label
                              style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                            >
                              {this.state.title}
                            </Label>
                          </Col>
                          <Col md={2}></Col>
                        </Row> */}
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md={2}>
                            <label className="purchaseLabel">Date</label>
                          </Col>
                          <Col md={10}>
                            <span>{this.state.purchaseStartDate}</span>
                          </Col>
                        </Row>
                        <Row className="purchaseRow">
                          <Col md={2}>
                            <label className="purchaseLabel">Purchase Type</label>
                          </Col>
                          <Col md={10}>
                            <span>{this.state.purchaseType}</span>
                          </Col>
                        </Row>
                        {this.state.doctor && (
                          <Row className="purchaseRow">
                            <Col md={2}>
                              <label className="purchaseLabel">Prescribed By</label>
                            </Col>
                            <Col md={10}>
                              <span>{this.state.doctor}</span>
                            </Col>
                          </Row>
                        )}
                        <Row className="purchaseRow">
                          <Col md={2}>
                            <label className="purchaseLabel">Description</label>
                          </Col>
                          <Col md={10}>
                            <span>
                              <pre className="view_purchaseNote" style={{ marginLeft: '-1px' }}>
                                {this.state.longDescription}
                              </pre>
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <label
                              style={{
                                fontWeight: 'normal',
                                color: '#888888',
                                width: 'auto',
                                fontSize: 'xx-small',
                                textAlign: 'left',
                                padding: '0',
                                margin: '0em 0em 0em 2em',
                              }}
                            >
                              {this.state.createdByName && 'Created by ' + this.state.createdByName}
                              {this.state.createdOn &&
                                ' on ' + Utils.convertUTCDateToUserTimeZoneDate(this.state.createdOn)}
                            </label>
                          </Col>
                        </Row>
                      </CardBody>
                    </>
                  )}
                </Card>
              </Col>
            </Row>
            {this.state.showPurchaseNotePermission === true ? (
              <>
                <Row md={12} style={{ position: 'relative', margin: '1em', padding: '5px' }}>
                  <Card style={{ borderRadius: '20px', width: '100%', margin: 'auto' }}>
                    <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                      <Row>
                        <Col md={11}>
                          {undefined !== this.state.title && this.state.title.length > 0 && (
                            <Label
                              style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                              className="noteTitle"
                            >
                              Notes
                            </Label>
                          )}
                        </Col>
                        <Col md={1}></Col>
                      </Row>
                    </CardHeader>

                    <CardBody>
                      <Row>{taskNotes}</Row>
                    </CardBody>
                  </Card>
                </Row>
              </>
            ) : (
              <></>
            )}
            <Row md={12} style={{ position: 'relative', margin: '1em' }}>
              <Col md={12} style={{ position: 'relative', padding: '5px' }}>
                <Card style={{ height: '100%', borderRadius: '20px' }}>
                  <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                    <Row>
                      <Col md={11}>
                        {undefined !== this.state.title && this.state.title.length > 0 && (
                          <Label
                            className="reminderTitle"
                            style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                          >
                            Reminders
                          </Label>
                        )}
                      </Col>
                      <Col md={1}></Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>{reminderList}</Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
          {/* </PtsModal> */}
        </>
      );
    }
  }
}

export default MyPurchase;
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#008181',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: 'white',
        color: '#008181',
      },
    },
  },
});
