import TextField from '@material-ui/core/TextField';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';

import FetchServerData from '../../../provider/FetchServerData';

import store from '../../../redux/store';
import PtsAlert from '../../components/ptsAlert/PtsAlert';
import template1 from '../../../assets/images/AdTemplate4/PATemplate2.jpg';

class PATemplate2 extends Component {
  // sponsorUser = store.getState().Sponsorship.Sponsorship;
  header = store.getState().adminHeader.adminHeader;
  advertisementObject = {
    heading: 'Heading2222',
    subHeading: 'Subheading22222222',
    message: 'saepe placeat eius cum ducimus sunt, libero consequatur nemo voluptas sapiente possimus.',
    website: '',
  };
  constructor(props) {
    // let pageId = props.pageId;
    let action = props.action;
    let advertisementDetails = props.data;

    super(props);
    this.state = {
      action: action,
      sponsorUser: this.sponsorUser,
      // pageId: pageId,
      advertisementId: this.props.advertisementId,
      heading: advertisementDetails ? advertisementDetails.heading : '',
      website: advertisementDetails ? advertisementDetails.website : '',
      createHeading: advertisementDetails ? advertisementDetails.heading : this.advertisementObject.heading,
      createWebsite: advertisementDetails ? advertisementDetails.website : this.advertisementObject.website,
      onChange: this.props.onChange,
      imagePosition: '',
      leftImageFile: this.props.data ? this.props.data.leftimage : '',
      backgroundImageFile: this.props.data ? this.props.data.backgroundimage : '',
      showRemoveButton: false,
    };
  }

  handleHeadingChange(e) {
    this.setState({ heading: e.target.value });
  }
  handleSubHeadingChange(e) {
    this.setState({ subHeading: e.target.value });
  }
  handleWebsiteChange(e) {
    this.setState({ website: e.target.value });
  }
  handleMessageChange(e) {
    this.setState({ message: e.target.value });
  }
  handleCreateHeadingChange(e) {
    this.setState({ createHeading: e.target.value });
  }
  handleCreatWebsiteChange(e) {
    this.setState({ createWebsite: e.target.value });
  }

  backgroundImageFileHandler(position, e) {
    var file = e.target.files[0];
    let reader = new FileReader();
    this.setState({
      imagePosition: position.toString(),
      selectedFile: e.target.files[0],
    });
    reader.onload = (e) => {
      let image = e.target.result;
      let img = image.split(',');
      this.setImgae = img[1];

      const formData = new FormData();

      // Update the formData object
      formData.append('file', this.state.selectedFile, this.state.selectedFile.name);
      formData.append('sessionid', this.header.sessionid);
      formData.append('sid', this.header.sessionid);
      formData.append('imageposition', position);
      formData.append('uid', this.header.uid);
      formData.append('adid', this.props.advertisementId);
      formData.append('appclient', 'W');

      FetchServerData.callPostService('/paidadvertiseradsms/uploadImage', formData).then((output) => {
        if (output.status === 'SUCCESS') {
          // let imageDetails = output.data;
          // this.setState({
          //   action: "viewTemplate",
          // });
          PtsAlert.success(output.message);
        } else {
        }
      });
      this.setState({ backgroundImageFile: this.setImgae });
    };
    reader.readAsDataURL(file);
  }
  editBackgroundImageFileHandler(position, e) {
    var file = e.target.files[0];
    let reader = new FileReader();
    this.setState({
      imagePosition: position.toString(),
      selectedFile: e.target.files[0],
    });
    reader.onload = (e) => {
      let image = e.target.result;
      let img = image.split(',');
      this.setImgae = img[1];

      const formData = new FormData();

      // Update the formData object
      formData.append('file', this.state.selectedFile, this.state.selectedFile.name);
      formData.append('sessionid', this.header.sessionid);
      formData.append('sid', this.header.sessionid);
      formData.append('imageposition', position);
      formData.append('uid', this.header.uid);
      formData.append('adid', this.props.advertisementId);
      formData.append('appclient', 'W');

      FetchServerData.callPostService('/paidadvertiseradsms/uploadImage', formData).then((output) => {
        if (output.status === 'SUCCESS') {
          // let imageDetails = output.data;
          this.setState({
            action: 'edit',
          });
          PtsAlert.success(output.message);
        } else {
        }
      });
      this.setState({ backgroundImageFile: this.setImgae });
    };
    reader.readAsDataURL(file);
  }

  removeBackgroundImage(position, e) {
    const postObject = {
      header: this.header,
      advertisement: {
        id: this.state.advertisementId,
      },
      position: position,
    };

    FetchServerData.callPostService('/paidadvertiseradsms/deleteImage', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        this.setState({ backgroundImageFile: '' });
      } else {
      }
    });
  }
  render() {
    if (this.state.action === 'create') {
      return this.createTemplate();
    } else if (this.state.action === 'edit') {
      return this.editTemplate();
    } else if (this.state.action === 'preView') {
      return this.preViewTemplate();
    } else if (this.state.action === 'view') {
      return this.viewTemplate();
    } else if (this.state.action === 'viewTemplate') {
      return this.viewTemplateWithImage();
    }
  }

  createTemplate() {
    return (
      <div
        className="row"
        style={{
          margin: 'auto',
          alignItems: 'center',
          display: 'flex',
          alignContent: 'center',
          textAlign: 'center',
        }}
      >
        <a
          href={this.state.website}
          target="_blank"
          rel="noopener noreferrer"
          style={{ display: 'flex', textAlign: 'center', margin: 'auto' }}
        >
          <img
            src={template1}
            alt=""
            width="100%"
            // height="100%"
            style={{ maxHeight: '100px', display: 'block', width: '100%' }}
          />
        </a>
      </div>
    );
  }
  viewTemplate() {
    return (
      <>
        {this.state.backgroundImageFile !== '' ? (
          <>
            {this.state.website !== '' ? (
              <div
                className="row"
                style={{
                  margin: 'auto',
                  alignItems: 'center',
                  display: 'flex',
                  alignContent: 'center',
                  textAlign: 'center',
                }}
              >
                <a
                  // href={this.state.website}
                  href={
                    this.state.website !== null && this.state.website !== undefined
                      ? this.state.website.includes('https://') || this.state.website.includes('http://')
                        ? this.state.website
                        : 'http://' + this.state.website
                      : ''
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    display: 'flex',
                    textAlign: 'center',
                    margin: 'auto',
                  }}
                >
                  <img
                    src={`data:image/png;base64,${this.state.backgroundImageFile}`}
                    alt=""
                    width="100%"
                    // height="100%"
                    style={{
                      maxHeight: '100px',
                      width: '100%',
                      // maxwidth: "1100px",
                      display: 'block',
                    }}
                  />
                </a>

                <span style={{ float: 'right' }}>
                  <i
                    className="fa fa-times-circle"
                    aria-hidden="true"
                    title="Delete User"
                    style={{ color: '#000' }}
                    onClick={this.removeBackgroundImage.bind(this, 'BG')}
                  ></i>
                </span>
                <div className="imgUploadForm">
                  <div class="upload-btn-wrapper" style={{ float: 'right', padding: '0em 1em' }}>
                    <button className="fileUploadBtn">Upload Background file</button>
                    <input
                      type="file"
                      name="myfile"
                      id="file-input"
                      onChange={this.backgroundImageFileHandler.bind(this, 'BG')}
                    />
                  </div>
                  &nbsp;
                </div>
              </div>
            ) : (
              <div
                className="row"
                style={{
                  margin: 'auto',
                  alignItems: 'center',
                  display: 'flex',
                  alignContent: 'center',
                  textAlign: 'center',
                }}
              >
                <img
                  src={`data:image/png;base64,${this.state.backgroundImageFile}`}
                  alt=""
                  width="100%"
                  // height="100%"
                  style={{
                    maxHeight: '100px',
                    width: '100%',
                    // maxwidth: "1100px",
                    display: 'block',
                  }}
                />

                <span style={{ float: 'right' }}>
                  <i
                    className="fa fa-times-circle"
                    aria-hidden="true"
                    title="Delete User"
                    style={{ color: '#000' }}
                    onClick={this.removeBackgroundImage.bind(this, 'BG')}
                  ></i>
                </span>
                <div className="imgUploadForm">
                  <div class="upload-btn-wrapper" style={{ float: 'right', padding: '0em 1em' }}>
                    <button className="fileUploadBtn">Upload Background file</button>
                    <input
                      type="file"
                      name="myfile"
                      id="file-input"
                      onChange={this.backgroundImageFileHandler.bind(this, 'BG')}
                    />
                  </div>
                  &nbsp;
                </div>
              </div>
            )}
          </>
        ) : (
          <>
            {this.state.website !== '' ? (
              <div
                className="row"
                style={{
                  margin: 'auto',
                  alignItems: 'center',
                  display: 'flex',
                  alignContent: 'center',
                  textAlign: 'center',
                }}
              >
                <a
                  href={this.state.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{
                    display: 'flex',
                    textAlign: 'center',
                    margin: 'auto',
                  }}
                ></a>

                <span style={{ float: 'right' }}>
                  <i
                    className="fa fa-times-circle"
                    aria-hidden="true"
                    title="Delete User"
                    style={{ color: '#000' }}
                    onClick={this.removeBackgroundImage.bind(this, 'BG')}
                  ></i>
                </span>
                <div className="imgUploadForm">
                  <div class="upload-btn-wrapper" style={{ float: 'right', padding: '0em 1em' }}>
                    <button className="fileUploadBtn">Upload Background file</button>
                    <input
                      type="file"
                      name="myfile"
                      id="file-input"
                      onChange={this.backgroundImageFileHandler.bind(this, 'BG')}
                    />
                  </div>
                  &nbsp;
                </div>
              </div>
            ) : (
              <div
                className="row"
                style={{
                  margin: 'auto',
                  alignItems: 'center',
                  display: 'flex',
                  alignContent: 'center',
                  textAlign: 'center',
                }}
              >
                <span style={{ float: 'right' }}>
                  <i
                    className="fa fa-times-circle"
                    aria-hidden="true"
                    title="Delete User"
                    style={{ color: '#000' }}
                    onClick={this.removeBackgroundImage.bind(this, 'BG')}
                  ></i>
                </span>
                <div className="imgUploadForm">
                  <div class="upload-btn-wrapper" style={{ float: 'right', padding: '0em 1em' }}>
                    <button className="fileUploadBtn">Upload Background file</button>
                    <input
                      type="file"
                      name="myfile"
                      id="file-input"
                      onChange={this.backgroundImageFileHandler.bind(this, 'BG')}
                    />
                  </div>
                  &nbsp;
                </div>
              </div>
            )}
          </>
        )}
        <div style={{ float: 'right', marginTop: '15px' }}>
          {this.state.website !== null ? (
            <a
              href={
                this.state.website.includes('https://') || this.state.website.includes('http://')
                  ? this.state.website
                  : 'http://' + this.state.website
              }
              target="blank"
              rel="noopener noreferrer"
            >
              {this.state.website}
            </a>
          ) : (
            ' '
          )}
        </div>
        <p style={{ marginTop: '20px' }}>Note: Image size should be 760*100 pixels.</p>
      </>
    );
  }

  preViewTemplate() {
    return (
      <>
        <div
          className="row"
          style={{
            margin: 'auto',
            alignItems: 'center',
            display: 'flex',
            alignContent: 'center',
            textAlign: 'center',
          }}
        >
          <img
            // src={global.config.projectName + "/AdTemplate4/Template4.png"}
            src={template1}
            alt=""
            width="100%"
            // height="100%"
            style={{
              maxHeight: '100px',
              width: '100%',
              // maxwidth: "1100px",
              display: 'block',
            }}
          />
        </div>
        <Row>
          <Col md={12} style={{ textAlign: 'center' }}>
            <TextField
              margin="normal"
              variant="outlined"
              fullWidth
              id="heading"
              type="text"
              onChange={this.handleCreatWebsiteChange.bind(this)}
              label="Website"
              name="heading"
              style={{ background: '#fff', width: '50%' }}
              inputProps={{
                maxLength: 128,
              }}
              value={this.state.createWebsite}
            />
          </Col>
        </Row>
        <p style={{ marginTop: '10px' }}>Note: Image size should be 760*100 pixels.</p>
      </>
    );
  }

  viewTemplateWithImage() {
    return (
      <>
        <section id="Ad-jumbo-02">
          {this.state.backgroundImageFile !== '' ? (
            <>
              {this.state.website !== '' ? (
                <div
                  className="row"
                  style={{
                    margin: 'auto',
                    alignItems: 'center',
                    display: 'flex',
                    alignContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  <a
                    // href={this.state.website}
                    href={
                      this.state.website !== null && this.state.website !== undefined
                        ? this.state.website.includes('https://') || this.state.website.includes('http://')
                          ? this.state.website
                          : 'http://' + this.state.website
                        : ''
                    }
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      display: 'flex',
                      textAlign: 'center',
                      margin: 'auto',
                    }}
                  >
                    <img
                      src={`data:image/png;base64,${this.state.backgroundImageFile}`}
                      alt=""
                      width="100%"
                      style={{
                        maxHeight: '100px',
                        width: '100%',
                        // maxwidth: "1100px",
                        display: 'block',
                      }}
                    />
                  </a>
                </div>
              ) : (
                <div
                  className="row"
                  style={{
                    margin: 'auto',
                    alignItems: 'center',
                    display: 'flex',
                    alignContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  <img
                    src={`data:image/png;base64,${this.state.backgroundImageFile}`}
                    alt=""
                    width="100%"
                    // height="100%"
                    style={{
                      maxHeight: '100px',
                      width: '100%',
                      // maxwidth: "1100px",
                      display: 'block',
                    }}
                  />

                  <span style={{ float: 'right' }}>
                    <i
                      className="fa fa-times-circle"
                      aria-hidden="true"
                      title="Delete User"
                      style={{ color: '#000' }}
                      onClick={this.removeBackgroundImage.bind(this, 'BG')}
                    ></i>
                  </span>
                  <div className="imgUploadForm">
                    <div class="upload-btn-wrapper" style={{ float: 'right', padding: '0em 1em' }}>
                      <button className="fileUploadBtn">Upload Background file</button>
                      <input
                        type="file"
                        name="myfile"
                        id="file-input"
                        onChange={this.backgroundImageFileHandler.bind(this, 'BG')}
                      />
                    </div>
                    &nbsp;
                  </div>
                </div>
              )}
            </>
          ) : (
            <>
              {this.state.website !== '' ? (
                <div
                  className="row"
                  style={{
                    margin: 'auto',
                    alignItems: 'center',
                    display: 'flex',
                    alignContent: 'center',
                    textAlign: 'center',
                  }}
                >
                  <a
                    href={this.state.website}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      display: 'flex',
                      textAlign: 'center',
                      margin: 'auto',
                    }}
                  ></a>
                </div>
              ) : (
                <div
                  className="row"
                  style={{
                    margin: 'auto',
                    alignItems: 'center',
                    display: 'flex',
                    alignContent: 'center',
                    textAlign: 'center',
                  }}
                ></div>
              )}
            </>
          )}
        </section>
        <div style={{ float: 'right' }}>
          {/* <p>Note:Image size 760 X 100 Pixel</p> */}
          <div>
            <span>URL:- </span>
            {this.state.website !== null ? (
              <a
                href={
                  this.state.website.includes('https://') || this.state.website.includes('http://')
                    ? this.state.website
                    : 'http://' + this.state.website
                }
                target="blank"
                rel="noopener noreferrer"
              >
                {this.state.website}
              </a>
            ) : (
              ' '
            )}
          </div>
        </div>
      </>
    );
  }

  editTemplate() {
    return (
      <>
        <div
          className="row"
          style={{
            margin: 'auto',
            alignItems: 'center',
            display: 'flex',
            alignContent: 'center',
            textAlign: 'center',
          }}
        >
          <a
            // href={this.state.website}
            href={
              this.state.website.includes('https://') || this.state.website.includes('http://')
                ? this.state.website
                : 'http://' + this.state.website
            }
            target="_blank"
            rel="noopener noreferrer"
            style={{
              display: 'flex',
              textAlign: 'center',
              margin: 'auto',
            }}
          >
            <img
              src={`data:image/png;base64,${this.state.backgroundImageFile}`}
              alt=""
              width="100%"
              // height="100%"
              style={{
                maxHeight: '100px',
                width: '100%',
                // maxwidth: "1100px",
                display: 'block',
              }}
            />
          </a>

          <span style={{ float: 'right' }}>
            <i
              className="fa fa-times-circle"
              aria-hidden="true"
              title="Delete Image"
              style={{ color: '#000' }}
              onClick={this.removeBackgroundImage.bind(this, 'BG')}
            ></i>
          </span>
          <div className="imgUploadForm">
            <div class="upload-btn-wrapper" style={{ float: 'right', padding: '0em 1em' }}>
              <button className="fileUploadBtn">Upload Background file</button>
              <input
                type="file"
                name="myfile"
                id="file-input"
                onChange={this.editBackgroundImageFileHandler.bind(this, 'BG')}
              />
            </div>
            &nbsp;
          </div>
        </div>
        <Row>
          <Col md={12} style={{ textAlign: 'center' }}>
            <TextField
              margin="normal"
              variant="outlined"
              fullWidth
              id="heading"
              type="text"
              onChange={this.handleWebsiteChange.bind(this)}
              label="Website"
              name="heading"
              style={{ background: '#fff', width: '50%' }}
              inputProps={{
                maxLength: 128,
              }}
              value={this.state.website}
            />
          </Col>
        </Row>
        <p style={{ marginTop: '10px' }}>Note: Image size should be 760*100 pixels.</p>
      </>
    );
  }
}

export default PATemplate2;
