import { CLEAR_HELP_DATA, GET_HELP_DATA, SET_HELP_DATA } from './displayHelpTypes';

const getHelpData = () => {
  return {
    type: GET_HELP_DATA,
  };
};

const setHelpData = (data) => {
  return {
    type: SET_HELP_DATA,
    payload: data,
  };
};

const clearHelpData = (data) => {
  return {
    type: CLEAR_HELP_DATA,
    payload: data,
  };
};
const helpDataAction = { getHelpData, setHelpData, clearHelpData };

export default helpDataAction;
