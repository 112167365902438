import React, { Component } from 'react';
import store from '../../../../redux/store';
import { Col, Row } from 'reactstrap';
class Template3 extends Component {
  header = store.getState().header.header;
  advertisementObject = {
    heading: 'Heading2222',
    subHeading: 'Subheading22222222',
    message: 'saepe placeat eius cum ducimus sunt, libero consequatur nemo voluptas sapiente possimus.',
    website: '',
  };
  constructor(props) {
    // let pageId = props.pageId;
    let action = props.action;
    let advertisementDetails = props.data;

    super(props);
    this.state = {
      action: action,
      sponsorUser: this.sponsorUser,
      heading: advertisementDetails ? advertisementDetails.template.heading : '',
      subHeading: advertisementDetails ? advertisementDetails.template.subheading : '',
      message: advertisementDetails ? advertisementDetails.template.message : '',
      website: advertisementDetails ? advertisementDetails.template.website : '',

      action: this.props.action,
      onChange: this.props.onChange,
      imagePosition: '',
      leftImageFile: this.props.data ? this.props.data.template.leftimage : '',
      backgroundImageFile: this.props.data ? this.props.data.template.backgroundimage : '',

      showRemoveButton: false,
    };
  }

  render() {
    return (
      <>
        {this.state.backgroundImageFile !== '' ? (
          <>
            <div
              style={{
                backgroundImage: `url(${`data:image/png;base64,${this.state.backgroundImageFile}`})`,
                backgroundRepeat: 'no-repeat',
                width: ' 100%',
                backgroundSize: 'cover',
                overflowy: 'scroll',
                overflowx: 'hidden',
                display: 'block',
                minHeight: '120px',
                padding: '0.5em',
              }}
            >
              <Row>
                <Col md={6}>
                  <h3 className="text-white" style={{ fontSize: '25px' }}>
                    <b>{this.state.heading}</b>
                  </h3>
                </Col>
                <Col md={6} style={{ textAlign: 'right' }}>
                  <a href={this.state.website} target="_blank">
                    {this.state.website}
                  </a>
                </Col>
              </Row>
              <h4 className="text-white" style={{ fontSize: '20px' }}>
                {this.state.subHeading}
              </h4>
              <p className="text-white" style={{ marginBottom: '0em', fontSize: '13px' }}>
                {this.state.message}
              </p>
            </div>
          </>
        ) : (
          <div
            style={{
              backgroundImage: `url(${`data:image/png;base64,${this.state.backgroundImageFile}`})`,
              backgroundRepeat: 'no-repeat',
              width: ' 100%',
              backgroundSize: 'cover',
              overflowy: 'scroll',
              overflowx: 'hidden',
              display: 'block',
              minHeight: '130px',
              padding: '0.5em',
            }}
          >
            <Row>
              <Col md={6}>
                <h3 className="text-white" style={{ fontSize: '25px' }}>
                  <b>{this.state.heading}</b>
                </h3>
              </Col>
              <Col md={6} style={{ textAlign: 'right' }}>
                <a href={this.state.website} target="_blank">
                  {this.state.website}
                </a>
              </Col>
            </Row>
            <h4 className="text-white" style={{ fontSize: '20px' }}>
              {this.state.subHeading}
            </h4>
            <p className="text-white" style={{ marginBottom: '0em', fontSize: '13px' }}>
              {this.state.message}
            </p>
          </div>
        )}
      </>
    );
  }
}

export default Template3;
