import Axios from 'axios';
import PtsAlert from '../pts/components/ptsAlert/PtsAlert';
import appUserAction from '../redux/appUser/appUserAction';
import store from '../redux/store';
export const serverUrl = '/fccApi/2.0/';
export const callPostServiceForPDFData = (url, data) => {
  return new Promise(function (accept, reject) {
    Axios.post(url, data).then((response) => {
      const responseData = response.data;
      if (undefined !== responseData && responseData.length > 0) {
        //PtsAlert.success(responseData.message);
        accept(responseData);
      } else {
        let errorMessage = 'No Data From Server';
        PtsAlert.error('No Data Received from Server');
        reject(errorMessage);
      }
    });
  });
};

const showLoader = () =>
  document.getElementById('iAmUniqueModalAT')
    ? (document.getElementById('iAmUniqueModalAT').style.display = 'block')
    : '';
const hideLoader = () =>
  document.getElementById('iAmUniqueModalAT')
    ? (document.getElementById('iAmUniqueModalAT').style.display = 'none')
    : '';
export const callPostFileDownload = (url, data) => {
  url = serverUrl + url;
  url = url.replace(/\/\//g, '/');
  url = url.replace(/\/+/g, '/');
  return new Promise(function (accept, reject) {
    Axios.post(url, data)
      .then((response) => {
        accept(response);
      })
      .catch((error) => {
        if (error.name !== null && error.name === 'TimeoutError') {
          reject("Can't reach to server,please try again later.");
        } else if (error.status) {
          let errorMessage = 'Unknown Error occurred';
          switch (error.status) {
            /*
                        Unauthorized
             */
            case 401:
              errorMessage = 'You are not authenticate to call this service';
              break;
            /*
                        Forbidden
           */
            case 403:
              errorMessage =
                'You are not authorized to perform this operation or the resource is unavailable for some reason';
              break;
            /*
                        internal server error
             */
            case 500:
              errorMessage = 'Internal Server Error occurred , please try again later';
              break;
            /*
                        Request Timeout
            */
            case 408:
              /*
                          gatewat timeout
             */
              errorMessage = 'Internal Server Error occurred , please try again later';
              break;
            case 504:
              /*
                         Network connect timeout error
             */
              errorMessage = 'Internal Server Error occurred , please try again later';
              break;
            case 599:
              errorMessage = "Can't reach to server , please try again later.";
              break;
            /*
                         Service Unavailable
             */
            case 503:
              errorMessage = 'Service you are trying is currently unavailable , please try again later.';
              break;
            default:
              errorMessage = 'You are not authenticate to call this service';
              break;
          }
          reject(errorMessage);
        } else {
          reject(error);
        }
      });
  });
};
export const callPostFileUpload = (url, data) => {
  url = serverUrl + url;
  url = url.replace(/\/\//g, '/');
  url = url.replace(/\/+/g, '/');
  return new Promise(function (accept, reject) {
    Axios.post(url, data)
      .then((response) => {
        const responseData = response.data;
        if (responseData.status === 'SUCCESS') {
          //PtsAlert.success(responseData.message);

          // responseData.errorCode='TID_1000';
          // checkRequests.CheckRequests(responseData);
          accept(responseData);
        } else {
          let errCode = responseData.errorCode;
          responseData.message = null === responseData.message ? 'Null Response' : responseData.message;
          PtsAlert.error(responseData.message);
          if (errCode === 'SEP_101') {
            try {
              localStorage.clear();
              store.dispatch(appUserAction.logout());
            } catch (err) {}

            window.location = '/#/login';
            reject('');

            // checkRequests(responseData);
            /*
                      Navigate to Login Page
        */
            //this.navCtr.setRoot(LoginPage);
          } else if (errCode === 'APSEP_101') {
            try {
              localStorage.clear();
              store.dispatch(appUserAction.logout());
            } catch (err) {}

            window.location = '/#/ApLogin';
            reject('');
          } else {
            accept(responseData);
          }
        }
      })
      .catch((error) => {
        if (error.name !== null && error.name === 'TimeoutError') {
          reject("Can't reach to server,please try again later.");
        } else if (error.status) {
          let errorMessage = 'Unknown Error occurred';
          switch (error.status) {
            /*
                        Unauthorized
             */
            case 401:
              errorMessage = 'You are not authenticate to call this service';
              break;
            /*
                        Forbidden
           */
            case 403:
              errorMessage =
                'You are not authorized to perform this operation or the resource is unavailable for some reason';
              break;
            /*
                        internal server error
             */
            case 500:
              errorMessage = 'Internal Server Error occurred , please try again later';
              break;
            /*
                        Request Timeout
            */
            case 408:
              /*
                          gatewat timeout
             */
              errorMessage = 'Internal Server Error occurred , please try again later';
              break;
            case 504:
              /*
                         Network connect timeout error
             */
              errorMessage = 'Internal Server Error occurred , please try again later';
              break;
            case 599:
              errorMessage = "Can't reach to server , please try again later.";
              break;
            /*
                         Service Unavailable
             */
            case 503:
              errorMessage = 'Service you are trying is currently unavailable , please try again later.';
              break;
            default:
              errorMessage = 'You are not authenticate to call this service';
              break;
          }
          reject(errorMessage);
        } else {
          reject(error);
        }
      });
  });
};

export const callPostService = (url, data) => {
  url = serverUrl + url;
  url = url.replace(/\/\//g, '/');
  url = url.replace(/\/+/g, '/');
  hideLoader();
  showLoader();
  return new Promise(function (accept, reject) {
    Axios.post(url, data)
      .then((response) => {
        hideLoader();
        const responseData = response.data;
        if (responseData.status === 'SUCCESS') {
          accept(responseData);
        } else if (responseData.status === 'FAIL' && responseData.errorCode === 'DuplicateAppointmentEvent') {
          accept(responseData);
        } else {
          let errCode = responseData.errorCode;
          responseData.message = null === responseData.message ? 'Null Response' : responseData.message;
          PtsAlert.error(responseData.message);
          if (errCode === 'SEP_101') {
            try {
              localStorage.clear();
              store.clear();
            } catch (err) {}
            window.location = '/#/login';
            reject('');
          } else if (errCode === 'APSEP_101') {
            try {
              localStorage.clear();
              store.dispatch(appUserAction.logout());
            } catch (err) {}

            window.location = '/#/ApLogin';
            reject('');
          } else {
            accept(responseData);
          }
        }
      })
      .catch((error) => {
        hideLoader();
        if (error.name !== null && error.name === 'TimeoutError') {
          PtsAlert.error("Can't reach to server,please try again later.");
          reject("Can't reach to server,please try again later.");
        } else if (error.status) {
          let errorMessage =
            undefined !== error.message && null !== error.message ? error.message : 'Unknown Error occurred';

          switch (error.status) {
            /*
                        Unauthorized
             */
            case 401:
              errorMessage = 'You are not authenticate to call this service';
              break;
            /*
                        Forbidden
           */
            case 403:
              errorMessage =
                'You are not authorized to perform this operation or the resource is unavailable for some reason';
              break;
            /*
                        internal server error
             */
            case 500:
              errorMessage = 'Internal Server Error occurred , please try again later';
              break;
            /*
                        Request Timeout
            */
            case 408:
              /*
                          gatewat timeout
             */
              errorMessage = 'Internal Server Error occurred , please try again later';
              break;
            case 504:
              /*
                         Network connect timeout error
             */
              errorMessage = 'Internal Server Error occurred , please try again later';
              break;
            case 599:
              errorMessage = "Can't reach to server , please try again later.";
              break;
            /*
                         Service Unavailable
             */
            case 503:
              errorMessage = 'Service you are trying is currently unavailable , please try again later.';
              break;
            default:
              errorMessage = 'You are not authenticate to call this service';
              break;
          }
          PtsAlert.error(errorMessage);
          reject(errorMessage);
        } else {
          let errorMessage =
            undefined !== error.message && null !== error.message ? error.message : 'Unknown Error occurred';
          PtsAlert.error(errorMessage);
          reject(error);
        }
      });
  });
  hideLoader();
};

export const callPostServiceWithoutHeader = (url, data) => {
  // export callPostService(url, data){
  url = serverUrl + url;
  url = url.replace(/\/\//g, '/');
  url = url.replace(/\/+/g, '/');
  return new Promise(function (accept, reject) {
    Axios.post(url, data)
      .then((response) => {
        const responseData = response.data;
        if (responseData.status === 'SUCCESS') {
          //PtsAlert.success(responseData.message);

          // responseData.errorCode='TID_1000';
          // checkRequests.CheckRequests(responseData);
          accept(responseData);
        } else {
          let errCode = responseData.errorCode;
          responseData.message = null === responseData.message ? 'Null Response' : responseData.message;
          PtsAlert.error(responseData.message);
          if (errCode === 'SEP_101') {
            try {
              localStorage.clear();
              store.clear();
            } catch (err) {}

            window.location = '/#/login';
            reject('');

            /*
                      Navigate to Login Page
        */
            //this.navCtr.setRoot(LoginPage);
          } else if (errCode === 'APSEP_101') {
            try {
              localStorage.clear();
              store.dispatch(appUserAction.logout());
            } catch (err) {}

            window.location = '/#/ApLogin';
            reject('');
          } else {
            accept(responseData);
          }
        }
      })
      .catch((error) => {
        if (error.name !== null && error.name === 'TimeoutError') {
          reject("Can't reach to server,please try again later.");
        } else if (error.status) {
          let errorMessage = 'Unknown Error occurred';
          switch (error.status) {
            /*
                        Unauthorized
             */
            case 401:
              errorMessage = 'You are not authenticate to call this service';
              break;
            /*
                        Forbidden
           */
            case 403:
              errorMessage =
                'You are not authorized to perform this operation or the resource is unavailable for some reason';
              break;
            /*
                        internal server error
             */
            case 500:
              errorMessage = 'Internal Server Error occurred , please try again later';
              break;
            /*
                        Request Timeout
            */
            case 408:
              /*
                          gatewat timeout
             */
              errorMessage = 'Internal Server Error occurred , please try again later';
              break;
            case 504:
              /*
                         Network connect timeout error
             */
              errorMessage = 'Internal Server Error occurred , please try again later';
              break;
            case 599:
              errorMessage = "Can't reach to server , please try again later.";
              break;
            /*
                         Service Unavailable
             */
            case 503:
              errorMessage = 'Service you are trying is currently unavailable , please try again later.';
              break;
            default:
              errorMessage = 'You are not authenticate to call this service';
              break;
          }
          reject(errorMessage);
        } else {
          reject(error);
        }
      });
  });
};
//}
export default {
  callPostFileDownload,
  callPostFileUpload,
  callPostService,
  callPostServiceForPDFData,
  callPostServiceWithoutHeader,
  hideLoader,
};
