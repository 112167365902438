import { Card } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import React, { Component } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { withRouter } from 'react-router-dom';
import { CardBody, CardFooter, CardHeader, Col, Label, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import store from '../../../redux/store';
import Address from '../address/Address';
import CancelIconButton from '../buttons/CancelIconButton';
import { Member } from '../member/Member';
import { NewMember } from '../member/NewMember';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
import PtsAlert from '../ptsAlert/PtsAlert';
import PtsModal from '../modal/PtsModal';

export class AddFamilyMember extends Component {
  title = 'Add Caregiver';
  memberName = store.getState().memberData.memberData.firstname + ' ' + store.getState().memberData.memberData.lastname;
  breadCrumb = [
    { pageid: '/memberdeatils', uiname: this.memberName },
    { pageid: '', uiname: 'Add Caregiver' },
  ];

  relationWithMemberList = store.getState().staticData.relationWithMemberList;
  roleList = store.getState().staticData.profileList;
  memberDetails = store.getState().memberData.memberData;
  header = store.getState().header.header;
  addressRef = React.createRef();
  newFamilyMemberRef = [];
  constructor(props) {
    super(props);
    let action = props.action;
    let memberId = props.memberId;
    let title = '';
    let pageId = '';
    if (!memberId && props.history && props.history.location && props.history.location.state) {
      memberId = props.history.location.state.memberId;
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
      title = props.history.location.state.title;
    }
    let canRender = false;
    this.currentDate = moment().format('DD-MM-YYYY');

    this.state = {
      pageId: pageId,
      memberId: this.memberDetails.member,
      title: title,
      action: action,
      render: canRender,
      memberData: '',
      onChange: this.props.onChange,
      selfData: {},
      selfAddressDetails: {},
      searchFamilyMemberData: {},
      searchFamilyMemberAddress: {},
      searchFamilyMemberId: '',
      relation: {},
      role: {},
      searchMemberEmail: '',
      addMember: true,
      isMemberExisting: false,
      profileInfoModel: false,
    };
  }

  handleRoleChange(selectedRole) {
    this.state.role = selectedRole;
    this.setState({
      role: selectedRole,
    });
    if (undefined !== this.state.onChange && null !== this.state.onChange) {
      this.state.onChange(this.state);
    }
  }
  handleAddressChange(inputAddress) {
    this.setState({ address: inputAddress });
  }
  handleNewFamilyMemberChange(selectedData) {
    this.setState({ newFamilyMember: selectedData });
  }

  componentDidMount() {
    window.addEventListener('load', this.searchExistingFamilyMemberDetails);
  }
  /* search  existing caregiver by phone number or email then this api call*/
  searchExistingFamilyMemberDetails = () => {
    let searchMember = '';
    let searchEmailPhone = '';

    if (
      this.newFamilyMemberRef &&
      this.newFamilyMemberRef.current &&
      this.newFamilyMemberRef.current.state.email === ''
    ) {
      searchMember = this.newFamilyMemberRef.current.state.mobile;
    } else if (
      this.newFamilyMemberRef &&
      this.newFamilyMemberRef.current &&
      this.newFamilyMemberRef.current.state.email
    ) {
      searchMember = this.newFamilyMemberRef.current.state.email;
    }

    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(searchMember)) {
      searchEmailPhone = searchMember;
    } else {
      searchEmailPhone = searchMember.replace(/[^\d]/g, '');
    }
    if (searchEmailPhone !== '') {
      const postObject = {
        member: {
          email: searchEmailPhone,
        },
        header: this.header,
      };

      FetchServerData.callPostService('memberms/findByEmailOrPhone', postObject).then((output) => {
        if (output.status === 'SUCCESS') {
          let searchData = output.data;

          if (null == searchData) {
            this.setState({
              render: true,
              addMember: true,
            });
          } else {
            this.setState({
              render: true,
              addMember: false,
              isMemberExisting: true,
              searchFamilyMemberData: searchData,
              searchFamilyMemberId: searchData.id,
              searchMemberEmail: searchData.email,
              email: searchData.email,
              searchFamilyMemberAddress: searchData.address,
              searchMember: true,
            });
          }
        } else {
        }
      });
    }
  };

  /* add caregiver in backend then this api call*/
  addFamilyMemberInBackend = () => {
    let postObject = {};

    if (this.state.isMemberExisting === true) {
      postObject = {
        header: this.header,

        familyMember: {
          memberId: this.state.memberId,
          familyMemberId: this.state.searchFamilyMemberId,

          relationRole: {
            name: this.state.role.name,
            uid: this.state.role.uid,
          },
        },
      };

      FetchServerData.callPostService('/caregiverms/create', postObject)
        .then((output) => {
          if (output.status === 'SUCCESS') {
            let familyMemberDetails = output.data;
            PtsAlert.success(output.message);
            this.props.history.push({
              pathname: '/viewFamilyMember',
              state: {
                action: 'view',
                membershipId: familyMemberDetails.familyMember.id,
                familyMemberData: familyMemberDetails,
                pageId: 'createFamilyMember',
              },
            });
          } else {
          }
        })
        .catch((error) => {});
    } else {
      let mobile = this.state.newFamilyMember.mobile.replace(/[^\d]/g, '');
      postObject = {
        header: this.header,

        familyMember: {
          firstName: this.state.newFamilyMember.fname,
          lastName: this.state.newFamilyMember.lname,
          email: this.state.newFamilyMember.email,
          phone: mobile,
          familyMember: null,
          memberId: this.state.memberId,
          relationRole: {
            name: this.state.newFamilyMember.role.name,
            uid: this.state.newFamilyMember.role.uid,
          },
        },
      };

      FetchServerData.callPostService('/caregiverms/create', postObject)
        .then((output) => {
          if (output.status === 'SUCCESS') {
            let familyMemberDetails = output.data;
            PtsAlert.success(output.message);
            this.props.history.push({
              pathname: '/viewFamilyMember',
              state: {
                action: 'view',
                membershipId: familyMemberDetails.familyMember.id,
                familyMemberData: familyMemberDetails,
                pageId: 'createFamilyMember',
              },
            });
          } else {
          }
        })
        .catch((error) => {});
    }
  };

  createCancel = () => {
    this.props.history.push({
      pathname: '/familyCircle',
    });
  };

  profileInfoModalOpen = () => {
    this.setState({
      profileInfoModel: true,
    });
  };
  profileInfoModelToggle = () => {
    this.setState({
      profileInfoModel: !this.state.profileInfoModel,
    });
  };
  /* if create new caregiver or add  existing caregiver then this function call*/
  render() {
    this.newFamilyMemberRef = React.createRef();
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title + ' For ' + this.memberName}
        />
        <div style={{ margin: '1em' }}>
          <ValidatorForm ref="form" onSubmit={this.addFamilyMemberInBackend.bind(this)}>
            {this.state.addMember && (
              <Row style={{ position: 'relative' }}>
                <Col md={8} style={{ position: 'relative' }}>
                  <Card style={{ borderRadius: '20px', height: '100%' }}>
                    <CardBody>
                      <NewMember
                        title="Caregiver Details"
                        ref={this.newFamilyMemberRef}
                        data={this.state.newFamilyMember}
                        onChange={this.handleNewFamilyMemberChange.bind(this)}
                        onBlur={this.searchExistingFamilyMemberDetails.bind(this)}
                        pageId="addFamilyMember"
                        action="create"
                      ></NewMember>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}

            {this.state.searchMember && (
              <>
                <Row style={{ padding: '1em' }}>
                  <Col md={6} style={{ position: 'relative', padding: '5px' }}>
                    <Card style={{ borderRadius: '20px', height: '100%' }}>
                      <CardBody>
                        <Member
                          title="Caregiver Details"
                          action="view"
                          data={this.state.searchFamilyMemberData}
                        ></Member>

                        <Row>
                          <Col md={6}>
                            <Autocomplete
                              required
                              id="Role"
                              options={this.roleList}
                              value={this.state.role}
                              getOptionLabel={(option) => (option.name ? option.name : '')}
                              onChange={(event, value) => {
                                this.handleRoleChange(value);
                              }}
                              renderInput={(params) => (
                                <TextField {...params} variant="outlined" label="Profile" margin="normal" required />
                              )}
                            />
                          </Col>
                          <Col md={6} style={{ margin: 'auto' }}>
                            <span>
                              {' '}
                              <i
                                className="fa fa-info-circle"
                                id="menuIconStyle"
                                style={{ color: '#4f5d7c', cursor: 'pointer' }}
                                onClick={this.profileInfoModalOpen}
                              />
                            </span>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </>
            )}

            <CardFooter style={{ width: '100%' }}>
              <div className="send_request_btn">
                <Button variant="contained" size="small" type="submit" style={{ background: '#ef6603', color: '#fff' }}>
                  Send Request
                </Button>
                &nbsp;&nbsp; &nbsp;
                <CancelIconButton onClick={(e) => this.createCancel()} />
              </div>
            </CardFooter>
          </ValidatorForm>
        </div>
        <PtsModal open={this.state.profileInfoModel} onClose={this.profileInfoModelToggle} title="Family Care Circle ">
          <h5>Profile Information</h5>
          <Row>
            <Col md={12}>
              <ul style={{ listStyle: 'none' }}>
                <li>
                  {' '}
                  <p>
                    <b style={{ color: '#2B88C7', fontSize: '15px' }}>Authorized Caregiver - </b>
                    {/* </h6>
                    <p> */}{' '}
                    This role has total responsibility for the Circle.They represent the Member “Center of the Circle”
                    with the ability to expand the Circle to other caregivers, assign the roles to other caregivers and
                    delete the circle.Typically this role is assigned to one person when the Member needs someone to
                    represent them.
                  </p>
                </li>
                <li>
                  {' '}
                  <p>
                    <b style={{ color: '#2B88C7', fontSize: '15px' }}>Family Manager - </b>
                    This role also has the ability to expand the Circle and has all rights needed to create appointments
                    and communicate with others. Typically this role is assigned to a close relative. This role is very
                    similar to Authorized Caregiver with the exception of the ability to delete the Circle for the
                    Member.
                  </p>
                </li>
                <li>
                  {' '}
                  <p>
                    <b style={{ color: '#2B88C7', fontSize: '15px' }}>Family Member - </b>
                    As an important caregiver, they can help manage all appointments. They are restricted from adding
                    others to the Circle. Typically this role is assigned to extended relatives or close friends.
                  </p>
                </li>
                <li>
                  {' '}
                  <p>
                    <b style={{ color: '#2B88C7', fontSize: '15px' }}>Transporter - </b>
                    Can only view Appointments/Events for which they are responsible for transportation. Typically this
                    role is assigned to friends that are wanting to help by offering to take the loved one to an
                    appointment. This is the most restrictive role.
                  </p>
                </li>
              </ul>
            </Col>{' '}
          </Row>
        </PtsModal>
      </>
    );
  }
}
export default withRouter(AddFamilyMember);
