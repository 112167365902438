import Fab from '@material-ui/core/Fab';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import LanguageIcon from '@material-ui/icons/Language';
import PhoneIcon from '@material-ui/icons/Phone';
import PinDropIcon from '@material-ui/icons/PinDrop';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import Utils from '../../../provider/Utils';
import store from '../../../redux/store';
import Address from '../address/Address';
import DeleteButton from '../buttons/DeleteButton';
import EditButton from '../buttons/EditButton';
import PtsAlert from '../ptsAlert/PtsAlert';
import './Location.scss';
import LocationPinIcon from '../buttons/LocationPinIcon';
import HomeIcon from '@material-ui/icons/Home';
export default class Location extends Component {
  countryListArray = store.getState().staticData.countryList;
  stateListArray = [];
  constructor(props) {
    super(props);
    let isButtonHide = true;
    let isShowField = false;
    let isDeleteButtonHide = true;
    let isHideNickNameAddress = true;
    if (props.pageId === 'incident') {
      isButtonHide = false;
      isShowField = true;
      isHideNickNameAddress = false;
    }
    if (props.pageId === 'event') {
      isHideNickNameAddress = false;
    }

    if (props.pageId === 'editMemberIncident') {
      isButtonHide = false;
    }
    if (props.isImport === 'import') {
      isDeleteButtonHide = false;
    }

    let action = props.action;
    let address = props.data.address;

    if (address === undefined) {
      address = store.getState().fccAddressState.fccAddressState;
    } else {
      address = props.data.address;
    }
    if (address.state) {
      if (address.state.code !== '') {
        address = address;
      } else {
        address = null;
      }
    }
    this.state = {
      getDeletePermission: props.getDeletePermission,
      getUpdatePermission: props.getUpdatePermission,
      id: props.data.id,
      shortDescription: props.data.shortDescription,
      nickName: props.data.nickName,
      website: props.data.website,
      fax: props.data.fax,
      address: address,
      operation: props.data.operation,
      phone:
        undefined !== props.data.phone && null !== props.data.phone
          ? Utils.displayUsaPhoneNumberFormate(props.data.phone)
          : '',

      index: props.srNo,
      onDelete: props.onDelete,
      onCancel: props.onCancel,
      onEditChange: props.onEditChange,
      onLocationAdd: props.onLocationAdd,
      onUpdate: props.onUpdate,
      isButtonHide: isButtonHide,
      isShowField: isShowField,
      isDeleteButtonHide: isDeleteButtonHide,
      pageId: props.pageId,
      action: action,
      render: true,
      startDate: '',
      expanded: true,
      isImport: props.isImport,
      isHideNickNameAddress: isHideNickNameAddress,
    };
  }
  handleStateChange(selectedState) {
    this.state.state = selectedState;
    this.setState({ state: selectedState });
    if (undefined !== this.onChange && null !== this.onChange) {
      this.onChange(this.state);
    }
  }

  handleShortDescriptionChange(e) {
    let val = e.target.value;

    this.setState({ shortDescription: val });
  }

  handleNicknameChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 10);
    this.state.address = this.addressRef.current.state;
    this.setState({ nickName: res, shortDescription: val });
  }
  handleShortNameChange(e) {
    let val = e.target.value;

    let res = val.substring(0, 10);
    this.setState({ nickName: res });
  }
  handleWebsiteChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ website: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }
  handleFaxChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ fax: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }

  handlePhoneChange({ target: { value } }) {
    this.setState((prevState) => ({
      phone: Utils.convertPhoneNumberToUsaPhoneNumberFormate(value, prevState.phone),
    }));
  }
  handleAddressChange(address) {
    this.setState({ address: address });
  }

  addLocationInBackend(e) {
    if (this.state.onLocationAdd) {
      this.state.address = this.addressRef.current.state;
      this.state.onLocationAdd(this.state);
    }
  }

  validateLocation() {
    if (this.addressRef && this.addressRef.current && this.addressRef.current.validateAddress() === false) {
      return false;
    }
    if (!this.state.shortDescription) {
      document.getElementById('country').focus();
      PtsAlert.error('Please Enter Description for Location');
      return false;
    }
    return true;
  }

  updateLocationInBackend(e) {
    if (this.validateLocation() === false) {
      this.setState({ action: 'edit' });
      return false;
    }

    if (this.state.onUpdate) {
      this.setState({ action: 'editLocation' });
      this.state.address = this.addressRef.current.state;
      this.state.onUpdate(this.state);
    }
  }

  locationCancel(e) {
    this.setState({ action: 'editLocation' });
  }

  editLocationItem(e) {
    if (undefined !== this.state.onEditChange && null !== this.state.onEditChange) {
      this.state.onEditChange(this.state);
    }
    this.setState({ action: 'edit', render: true });
  }

  deleteLocation(e) {
    if (this.state.onDelete) {
      this.state.onDelete(this.state);
    }
  }

  cancelLocation(e) {
    if (this.state.onCancel) {
      this.state.onCancel(this.state);
    }
  }
  openGoogleMapUrl(e) {
    // return `google.navigation:q=${'Maharashtra, India'}`;
    let shortDescription = this.props.data.shortDescription ? this.props.data.shortDescription : '';
    let line = this.state.address.line ? this.state.address.line : '';
    let city = this.state.address.city ? this.state.address.city : '';
    let state = this.state.address.state.name ? this.state.address.state.name : '';
    let country = this.state.address.state.country.name ? this.state.address.state.country.name : '';
    let address = `${shortDescription} ${line} ${city} ${state} ${country}`;
    var url = 'https://www.google.com/maps/dir/?api=1&travelmode=driving&dir_action=navigate&destination=' + address;
    if (!url) {
      console.log("Can't handle url: " + url);
    } else {
      return window.open(url, '_blank');
    }
  }
  render() {
    if (this.state.render === false) {
      return '';
    }
    this.addressRef = React.createRef();
    if (this.state.action === 'create') {
      return this.createLocation();
    } else if (this.state.action === 'defaultCreate') {
      return this.createLocation1();
    } else if (this.state.action === 'editLocation') {
      return this.viewLocationWithEditIcon();
    } else if (this.state.action === 'edit') {
      return this.editLocation();
    } else if (this.state.action === 'appointmentLocation') {
      return this.viewAppointmentLocation();
    } else {
      return this.viewLocation();
    }
  }

  createLocation1() {
    return (
      <>
        {this.state.isHideNickNameAddress && (
          <Row>
            <Col md={6}>
              {this.state.isButtonHide && (
                <TextField
                  fullWidth
                  variant="outlined"
                  required
                  margin="normal"
                  id={'Description' + this.state.index}
                  label="Location Name "
                  value={this.state.shortDescription}
                  onChange={this.handleNicknameChange.bind(this)}
                  name="Description"
                  type="text"
                  className="textField"
                  inputprops={{ maxLength: 64, style: { textTransform: 'capitalize' } }}
                />
              )}

              {this.state.isShowField && (
                <TextField
                  fullWidth
                  variant="outlined"
                  required
                  id={'Description' + this.state.index}
                  label=" Location Description "
                  value={this.state.shortDescription}
                  onChange={this.handleNicknameChange.bind(this)}
                  name="Description"
                  type="text"
                  margin="normal"
                  className="textField"
                  inputprops={{ style: { textTransform: 'capitalize' } }}
                />
              )}
            </Col>

            <Col md={6}>
              <TextField
                fullWidth
                variant="outlined"
                required
                id={'shortName' + this.state.index}
                label="Short Name "
                margin="normal"
                value={this.state.nickName}
                onChange={this.handleShortNameChange.bind(this)}
                name="shortName"
                type="text"
                className="textField"
                inputprops={{ maxLength: 256, style: { textTransform: 'capitalize' } }}
              />
            </Col>
          </Row>
        )}
        <Row>
          <Col md={12}>
            <Address
              ref={this.addressRef}
              data={this.state.address}
              action="edit"
              onChange={this.handleAddressChange.bind(this)}
            />
          </Col>
        </Row>

        {this.state.isHideNickNameAddress && (
          <Row>
            <Col md={6}>
              <TextField
                fullWidth
                variant="outlined"
                id={'phone' + this.state.index}
                label="Phone"
                value={this.state.phone}
                onChange={this.handlePhoneChange.bind(this)}
                name="phone"
                margin="normal"
                type="text"
                className="textField"
                pattern="^-?[0-9]\d*\.?\d*$"
              />
            </Col>

            {this.state.isButtonHide && (
              <Col md={6}>
                <TextField
                  fullWidth
                  variant="outlined"
                  id={'fax' + this.state.index}
                  label="Fax"
                  margin="normal"
                  inputprops={{ maxLength: 64 }}
                  value={this.state.fax}
                  onChange={this.handleFaxChange.bind(this)}
                  name="fax"
                  type="text"
                  className="textField"
                />
              </Col>
            )}
          </Row>
        )}
        {this.state.isHideNickNameAddress && (
          <>
            {' '}
            {this.state.isButtonHide && (
              <Row>
                <Col md={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id={'website' + this.state.index}
                    label="Website"
                    inputprops={{ maxLength: 64 }}
                    value={this.state.website}
                    onChange={this.handleWebsiteChange.bind(this)}
                    name="website"
                    margin="normal"
                    type="text"
                    className="textField"
                  />
                </Col>
              </Row>
            )}
          </>
        )}
      </>
    );
  }

  createLocation() {
    return (
      <>
        <div className="location_details_view">
          <div className="panel panel-blue">
            <div className="panel-heading">
              {this.state.isHideNickNameAddress && (
                <Row>
                  <Col md={6}>
                    {this.state.isButtonHide && (
                      <TextField
                        fullWidth
                        variant="outlined"
                        required
                        id={'Description' + this.state.index}
                        label="Location Name "
                        value={this.state.shortDescription}
                        onChange={this.handleNicknameChange.bind(this)}
                        name="Description"
                        type="text"
                        className="textField"
                        margin="normal"
                        inputprops={{ maxLength: 64, style: { textTransform: 'capitalize' } }}
                      />
                    )}

                    {this.state.isShowField && (
                      <TextField
                        fullWidth
                        variant="outlined"
                        required
                        id={'Description' + this.state.index}
                        label=" Location Description "
                        value={this.state.shortDescription}
                        onChange={this.handleNicknameChange.bind(this)}
                        name="Description"
                        type="text"
                        className="textField"
                        margin="normal"
                        inputprops={{ maxLength: 64, style: { textTransform: 'capitalize' } }}
                      />
                    )}
                  </Col>

                  <Col md={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      required
                      id={'shortName' + this.state.index}
                      label="Short Name "
                      value={this.state.nickName}
                      onChange={this.handleShortNameChange.bind(this)}
                      name="shortName"
                      type="text"
                      margin="normal"
                      className="textField"
                      inputprops={{ maxLength: 256, style: { textTransform: 'capitalize' } }}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col md={12}>
                  <Address
                    ref={this.addressRef}
                    onChange={this.handleAddressChange.bind(this)}
                    data={this.state.address}
                    action="create"
                  />
                </Col>
              </Row>

              {this.state.isHideNickNameAddress && (
                <Row>
                  <Col md={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id={'phone' + this.state.index}
                      label="Phone"
                      value={this.state.phone}
                      onChange={this.handlePhoneChange.bind(this)}
                      name="phone"
                      type="text"
                      className="textField"
                      margin="normal"
                      pattern="^-?[0-9]\d*\.?\d*$"
                    />
                  </Col>

                  {this.state.isButtonHide && (
                    <Col md={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        id={'fax' + this.state.index}
                        label="Fax"
                        value={this.state.fax}
                        onChange={this.handleFaxChange.bind(this)}
                        name="fax"
                        inputprops={{ maxLength: 64 }}
                        type="text"
                        margin="normal"
                        className="textField"
                      />
                    </Col>
                  )}
                </Row>
              )}
              {this.state.isHideNickNameAddress && (
                <>
                  {' '}
                  {this.state.isButtonHide && (
                    <Row>
                      <Col md={6}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          id={'website' + this.state.index}
                          label="Website"
                          inputprops={{ maxLength: 64 }}
                          value={this.state.website}
                          onChange={this.handleWebsiteChange.bind(this)}
                          name="website"
                          type="text"
                          margin="normal"
                          className="textField"
                        />
                      </Col>
                    </Row>
                  )}
                </>
              )}

              <Row style={{ padding: '0.5em' }}>
                <Col md={12} style={{ textAlign: 'center' }}>
                  <Tooltip title="Create">
                    <Fab
                      aria-label="add"
                      style={{
                        fontSize: 'small',
                        height: '15px',
                        width: '35px',
                        background: '#76767d',
                        cursor: 'pointer',
                        color: 'white',
                        border: '2px solid #edf0f1',
                      }}
                      onClick={this.addLocationInBackend.bind(this)}
                      id="iconButton"
                    >
                      {' '}
                      <CheckIcon style={{ cursor: 'pointer' }} />
                    </Fab>
                  </Tooltip>
                  &nbsp;&nbsp;
                  <Tooltip title="Cancel">
                    <Fab
                      aria-label="add"
                      style={{
                        fontSize: 'small',
                        height: '15px',
                        width: '35px',
                        background: '#76767d',
                        cursor: 'pointer',
                        color: 'white',
                        border: '2px solid #edf0f1',
                      }}
                      onClick={(e) => this.cancelLocation(e)}
                      id="iconButton"
                    >
                      <CloseIcon style={{ cursor: 'pointer' }} />
                    </Fab>
                  </Tooltip>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </>
    );
  }

  editLocation() {
    return (
      <>
        <div className="location_details_view">
          <div className="panel panel-blue">
            <div className="panel-heading">
              {this.state.isHideNickNameAddress && (
                <Row>
                  <Col md={6}>
                    {this.state.isButtonHide && (
                      <TextField
                        fullWidth
                        variant="outlined"
                        required
                        id={'Description' + this.state.index}
                        label="Location Name "
                        value={this.state.shortDescription}
                        onChange={this.handleNicknameChange.bind(this)}
                        name="Description"
                        type="text"
                        margin="normal"
                        className="textField"
                        inputprops={{ maxLength: 64, style: { textTransform: 'capitalize' } }}
                      />
                    )}

                    {this.state.isShowField && (
                      <TextField
                        fullWidth
                        variant="outlined"
                        required
                        id={'Description' + this.state.index}
                        label=" Location Description "
                        value={this.state.shortDescription}
                        onChange={this.handleNicknameChange.bind(this)}
                        name="Description"
                        type="text"
                        margin="normal"
                        className="textField"
                        inputprops={{ maxLength: 64, style: { textTransform: 'capitalize' } }}
                      />
                    )}
                  </Col>

                  <Col md={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      required
                      id={'shortName' + this.state.index}
                      label="Short Name "
                      value={this.state.nickName}
                      onChange={this.handleShortNameChange.bind(this)}
                      name="shortName"
                      type="text"
                      margin="normal"
                      className="textField"
                      inputprops={{ maxLength: 256, style: { textTransform: 'capitalize' } }}
                    />
                  </Col>
                </Row>
              )}
              <Row>
                <Col md={12}>
                  <Address
                    ref={this.addressRef}
                    onChange={this.handleAddressChange.bind(this)}
                    data={this.state.address}
                    action="edit"
                  />
                </Col>
              </Row>
              {this.state.isHideNickNameAddress && (
                <Row>
                  <Col md={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id={'phone' + this.state.index}
                      label="Phone"
                      value={this.state.phone}
                      onChange={this.handlePhoneChange.bind(this)}
                      name="phone"
                      type="text"
                      margin="normal"
                      className="textField"
                      pattern="^-?[0-9]\d*\.?\d*$"
                    />
                  </Col>

                  {this.state.isButtonHide && (
                    <Col md={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        id={'fax' + this.state.index}
                        label="Fax"
                        value={this.state.fax}
                        onChange={this.handleFaxChange.bind(this)}
                        inputprops={{ maxLength: 64 }}
                        name="fax"
                        margin="normal"
                        type="text"
                        className="textField"
                      />
                    </Col>
                  )}
                </Row>
              )}
              {this.state.isHideNickNameAddress && (
                <>
                  {' '}
                  {this.state.isButtonHide && (
                    <>
                      {' '}
                      <Row>
                        <Col md={6}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            id={'website' + this.state.index}
                            label="Website"
                            value={this.state.website}
                            inputprops={{ maxLength: 64 }}
                            onChange={this.handleWebsiteChange.bind(this)}
                            name="website"
                            margin="normal"
                            type="text"
                            className="textField"
                          />
                        </Col>
                      </Row>
                    </>
                  )}
                </>
              )}

              {this.state.isButtonHide && (
                <Row style={{ padding: '0.5em' }}>
                  <Col md={12} style={{ textAlign: 'center' }}>
                    <Tooltip title="Save">
                      <Fab
                        aria-label="add"
                        style={{
                          fontSize: 'small',
                          height: '15px',
                          width: '35px',
                          background: '#76767d',
                          cursor: 'pointer',
                          color: 'white',

                          border: '2px solid #edf0f1',
                        }}
                        onClick={this.updateLocationInBackend.bind(this)}
                        id="iconButton"
                      >
                        <CheckIcon style={{ cursor: 'pointer' }} />
                      </Fab>
                    </Tooltip>
                    &nbsp;&nbsp;
                    <Tooltip title="Cancel">
                      <Fab
                        aria-label="add"
                        style={{
                          fontSize: 'small',
                          height: '15px',
                          width: '35px',
                          background: '#76767d',
                          cursor: 'pointer',
                          color: 'white',

                          border: '2px solid #edf0f1',
                        }}
                        id="iconButton"
                        onClick={(e) => this.locationCancel(e)}
                      >
                        <CloseIcon style={{ cursor: 'pointer' }} />
                      </Fab>
                    </Tooltip>
                  </Col>
                </Row>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }

  viewLocationWithEditIcon() {
    if (this.state.render === false) {
      return <div />;
    }
    return (
      <>
        <div className="location_details_view">
          <div className="panel panel-blue">
            <div className="panel-heading">
              <Row>
                <Col md={7}>
                  {this.state.isHideNickNameAddress && (
                    <span style={{ color: '#E67E22', padding: '5px 0px' }}>
                      {this.state.shortDescription ? <strong>{this.state.shortDescription}</strong> : ''}
                    </span>
                  )}
                </Col>
                <Col md={5}>
                  {this.state.isButtonHide && (
                    <>
                      {this.state.getUpdatePermission === true && (
                        <>
                          <span>
                            <EditButton onClick={(e) => this.editLocationItem(e)} />
                          </span>
                        </>
                      )}

                      {this.state.isDeleteButtonHide && (
                        <>
                          {this.state.getDeletePermission === true && (
                            <span>
                              <DeleteButton
                                domainObject="Location"
                                style={{
                                  color: '#76767d',

                                  fontSize: 'x-large',
                                }}
                                onClick={(e) => this.deleteLocation(e)}
                              />
                            </span>
                          )}{' '}
                        </>
                      )}
                      {this.state.isButtonHide && (
                        <>
                          <span>
                            <LocationPinIcon style={{ fontSize: 'large' }} onClick={(e) => this.openGoogleMapUrl(e)} />

                            {/* <EditButton onClick={(e) => this.editLocationItem(e)} /> */}
                          </span>
                        </>
                      )}
                    </>
                  )}
                </Col>
              </Row>

              <Row style={{ padding: '3px 3px 3px 0px', textTransform: 'capitalize' }}>
                <Col md={1}>
                  <Tooltip title="Address" aria-label="add">
                    <HomeIcon style={{ fontSize: 'large' }} />
                  </Tooltip>
                </Col>
                <Col md={11}>{Utils.getAddressAsText(this.state.address)}</Col>
              </Row>
              {this.state.isHideNickNameAddress && (
                <>
                  {' '}
                  {this.state.phone ? (
                    <Row style={{ padding: '3px 3px 3px 0px' }}>
                      <Col md={1}>
                        <Tooltip title="Phone" aria-label="add">
                          <PhoneIcon style={{ fontSize: 'large' }} />
                        </Tooltip>
                      </Col>
                      <Col md={11}>
                        {this.state.phone ? (
                          <a href={'tel:' + this.state.phone} target="_top">
                            {Utils.displayUsaPhoneNumberFormate(this.state.phone)}
                          </a>
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  ) : (
                    <span style={{ display: 'none' }}></span>
                  )}
                </>
              )}
              {this.state.isHideNickNameAddress && (
                <>
                  {' '}
                  {this.state.fax ? (
                    <Row style={{ padding: '3px 3px 3px 0px' }}>
                      <Col md={1}>
                        <Tooltip title="fax" aria-label="add">
                          <i className="fa fa-fax" aria-hidden="true" style={{ fontSize: 'large', color: '#000' }}></i>
                        </Tooltip>
                      </Col>
                      <Col md={11}>{this.state.fax ? this.state.fax : ''}</Col>
                    </Row>
                  ) : (
                    <span style={{ display: 'none' }}></span>
                  )}
                </>
              )}
              {this.state.isHideNickNameAddress && (
                <>
                  {' '}
                  {this.state.website ? (
                    <Row style={{ padding: '3px 3px 3px 0px' }}>
                      <Col md={1}>
                        <Tooltip title="Website" aria-label="add">
                          <LanguageIcon style={{ fontSize: 'large' }} />
                        </Tooltip>
                      </Col>
                      <Col md={11}>
                        {this.state.website ? (
                          <a
                            href={
                              this.state.website.includes('https://') || this.state.website.includes('http://')
                                ? this.state.website
                                : 'http://' + this.state.website
                            }
                            target="blank"
                          >
                            {this.state.website}
                          </a>
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  ) : (
                    <span style={{ display: 'none' }}></span>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }

  viewLocation() {
    if (this.state.render === false) {
      return <div />;
    }

    return (
      <>
        <div className="location_details_view">
          <div className="panel panel-blue">
            <div className="panel-heading">
              <Row>
                <Col md={10}>
                  {this.state.isHideNickNameAddress && (
                    <span style={{ color: '#566573', padding: '5px 0px' }}>
                      {this.state.shortDescription ? <strong>{this.state.shortDescription}</strong> : ''}
                    </span>
                  )}
                </Col>
                <Col md={2}>
                  {' '}
                  <span>
                    <LocationPinIcon style={{ fontSize: 'large' }} onClick={(e) => this.openGoogleMapUrl(e)} />

                    {/* <EditButton onClick={(e) => this.editLocationItem(e)} /> */}
                  </span>
                </Col>
              </Row>

              <Row style={{ padding: '3px 3px 3px 0px', textTransform: 'capitalize' }}>
                <Col md={1}>
                  <Tooltip title="Address" aria-label="add">
                    <HomeIcon style={{ fontSize: 'large' }} />
                  </Tooltip>
                </Col>
                <Col md={11}>{Utils.getAddressAsText(this.state.address)}</Col>
              </Row>
              {this.state.isHideNickNameAddress && (
                <>
                  {this.state.phone ? (
                    <Row style={{ padding: '3px 3px 3px 0px' }}>
                      <Col md={1}>
                        <Tooltip title="Phone" aria-label="add">
                          <PhoneIcon style={{ fontSize: 'large' }} />
                        </Tooltip>
                      </Col>
                      <Col md={11}>
                        {this.state.phone ? (
                          <a href={'tel:' + this.state.phone} target="_top">
                            {Utils.displayUsaPhoneNumberFormate(this.state.phone)}
                          </a>
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  ) : (
                    <span style={{ display: 'none' }}></span>
                  )}
                </>
              )}
              {this.state.isHideNickNameAddress && (
                <>
                  {' '}
                  {this.state.fax ? (
                    <Row style={{ padding: '3px 3px 3px 0px' }}>
                      <Col md={1}>
                        <Tooltip title="fax" aria-label="add">
                          <i className="fa fa-fax" aria-hidden="true" style={{ fontSize: 'large', color: '#000' }}></i>
                        </Tooltip>
                      </Col>
                      <Col md={11}>{this.state.fax ? this.state.fax : ''}</Col>
                    </Row>
                  ) : (
                    <span style={{ display: 'none' }}></span>
                  )}
                </>
              )}
              {this.state.isHideNickNameAddress && (
                <>
                  {' '}
                  {this.state.website ? (
                    <Row style={{ padding: '3px 3px 3px 0px' }}>
                      <Col md={1}>
                        <Tooltip title="Website" aria-label="add">
                          <LanguageIcon style={{ fontSize: 'large' }} />
                        </Tooltip>
                      </Col>
                      <Col md={11}>
                        {this.state.website ? (
                          <a
                            href={
                              this.state.website.includes('https://') || this.state.website.includes('http://')
                                ? this.state.website
                                : 'http://' + this.state.website
                            }
                            target="blank"
                          >
                            {this.state.website}
                          </a>
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  ) : (
                    <span style={{ display: 'none' }}></span>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }

  viewAppointmentLocation() {
    if (this.state.render === false) {
      return <div />;
    }

    return (
      <>
        <div className="location_details_view">
          <div className="panel panel-blue">
            <div className="panel-heading">
              <Row>
                <Col md={10}>
                  <span style={{ color: '#566573', padding: '5px 0px' }}>
                    {this.props.data.shortDescription ? <strong>{this.props.data.shortDescription}</strong> : ''}
                  </span>
                </Col>
                <Col md={2}>
                  {' '}
                  <span>
                    <LocationPinIcon style={{ fontSize: 'large' }} onClick={(e) => this.openGoogleMapUrl(e)} />

                    {/* <EditButton onClick={(e) => this.editLocationItem(e)} /> */}
                  </span>
                </Col>
              </Row>
              <Row style={{ padding: '3px 3px 3px 0px', textTransform: 'capitalize' }}>
                <Col md={1}>
                  <Tooltip title="Address" aria-label="add">
                    <HomeIcon style={{ fontSize: 'large' }} />
                  </Tooltip>
                </Col>
                <Col md={11}>{Utils.getAddressAsText(this.props.data.address)}</Col>
              </Row>
              {this.state.phone ? (
                <Row style={{ padding: '3px 3px 3px 0px' }}>
                  <Col md={1}>
                    <Tooltip title="Phone" aria-label="add">
                      <PhoneIcon style={{ fontSize: 'large' }} />
                    </Tooltip>
                  </Col>
                  <Col md={11}>
                    {this.state.phone ? (
                      <a href={'tel:' + this.state.phone} target="_top">
                        {Utils.displayUsaPhoneNumberFormate(this.state.phone)}
                      </a>
                    ) : (
                      ''
                    )}
                  </Col>
                </Row>
              ) : (
                <span style={{ display: 'none' }}></span>
              )}
              {this.state.fax ? (
                <Row style={{ padding: '3px 3px 3px 0px' }}>
                  <Col md={1}>
                    <Tooltip title="fax" aria-label="add">
                      <i className="fa fa-fax" aria-hidden="true" style={{ fontSize: 'large', color: '#000' }}></i>
                    </Tooltip>
                  </Col>
                  <Col md={11}>{this.props.data.fax ? this.props.data.fax : ''}</Col>
                </Row>
              ) : (
                <span style={{ display: 'none' }}></span>
              )}
              {this.state.website ? (
                <Row style={{ padding: '3px 3px 3px 0px' }}>
                  <Col md={1}>
                    <Tooltip title="Website" aria-label="add">
                      <LanguageIcon style={{ fontSize: 'large' }} />
                    </Tooltip>
                  </Col>
                  <Col md={11}>
                    {/* {this.props.data.website ? this.props.data.website : ''} */}
                    {this.props.data.website ? (
                      <a
                        href={
                          this.props.data.website.includes('https://') || this.props.data.website.includes('http://')
                            ? this.props.data.website
                            : 'http://' + this.props.data.website
                        }
                        target="blank"
                      >
                        {this.props.data.website}
                      </a>
                    ) : (
                      ''
                    )}
                  </Col>
                </Row>
              ) : (
                <span style={{ display: 'none' }}></span>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}
