import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ValidatorForm } from 'react-material-ui-form-validator';
import 'react-tabs/style/react-tabs.css';
import { Card, CardBody, CardFooter, CardHeader, Col, Label, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import fccPageStateAction from '../../../redux/fccPageState/fccPageStateAction';
import store from '../../../redux/store';
import CancelIconButton from '../buttons/CancelIconButton';
import CreateButton from '../buttons/CreateButton';
import Location from '../location/Location';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
import PtsAlert from '../ptsAlert/PtsAlert';
import { MuiPickersUtilsProvider, DateTimePicker, LocalizationProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { createMuiTheme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import Address from '../address/Address';
class Event extends Component {
  eventTypeList = store.getState().staticData.eventTypeList;
  header = store.getState().header.header;
  memberDetails = store.getState().memberData.memberData;
  memberName = store.getState().memberData.memberData.firstname + ' ' + store.getState().memberData.memberData.lastname;
  title = 'Event Details';
  breadCrumb = [
    { pageid: '/memberDetails', uiname: this.memberName },
    { pageid: '/member_event', uiname: 'Event List' },
    { pageid: ' ', uiname: 'Event Details' },
  ];
  eventTypeList = store.getState().staticData.eventTypeListArray;
  header = store.getState().header.header;
  memberDetails = store.getState().memberData.memberData;
  memberName = store.getState().memberData.memberData.name;
  locationReferenceList = [];
  eventNoteRefList = [];
  eventReminderRefList = [];
  addressRef = React.createRef();
  addressRef = {};
  constructor(props) {
    super(props);
    let eventId = '';
    let action = '';
    let title = '';
    let pageId = '';
    let isButtonHide = false;
    let isEditButtonHide = true;
    // let canRender = false;
    let eventMessageData = '';
    let eventDataId = '';
    let eventNoteId = '';
    let str = window.location.href;
    let urlArr = str.split('?');
    let eventVo = urlArr[1];
    let eventData = {};
    if (eventVo) {
      let eventDataVo = eventVo.split('=');
      eventMessageData = eventDataVo[1].split('&');
      eventDataId = eventMessageData[0];
      eventNoteId = eventDataVo[2];
      eventNoteId = eventNoteId.split('&');
      eventNoteId = eventNoteId[0];
      action = 'view';
      this.getEventDetails(eventDataId);
    } else {
      eventId = props.eventId;
      action = props.action;
      title = '';
      pageId = '';
      if (!eventId && props.history && props.history.location && props.history.location.state) {
        eventId = props.history.location.state.eventId;
        action = props.history.location.state.action;
        pageId = props.history.location.state.pageId;
        title = props.history.location.state.title;
        eventData = props.history.location.state.eventDetails;
      }
      isButtonHide = false;
      isEditButtonHide = true;
      if (pageId === 'editEvent') {
        isButtonHide = true;
        isEditButtonHide = false;
      }

      // if (null !== eventData && eventData !=={}) {
      //     canRender = true;
      // }
    }
    let newStartDate = Utils.convertUTCDateToUserTimeZoneDate(eventData.date);
    // this.getParticipantList();
    this.state = {
      id: eventId,
      action: action,
      // render: canRender,
      noteId: eventNoteId,
      showSuccessAlert: false,
      showFailAlert: false,
      startDate: new Date(newStartDate),
      addParticipantModal: false,
      eventType: eventData.type,
      longDescription: eventData.description,
      reminderItems: [],

      locationList: [
        {
          operation: 'add',
          action: 'defaultCreate',
          nickName: eventData.location.nickName,
          shortDescription: eventData.location.shortDescription,
          fax: eventData.location.fax,
          website: eventData.location.website,
          phone: eventData.location.phone,
          address: eventData.location.address,
        },
      ],

      title: title,
      eventTitle: eventData.title,
      note: eventData.description,
      pageId: pageId,
      locationDetails: {},
      isButtonHide: isButtonHide,
      isEditButtonHide: isEditButtonHide,
      contactList: [],
      participantList: [],
      displayParticipantList: [],
      invitedFamilyMemberList: [],
      taskReminderListDiv: true,
      eventParticipantMemberList: [],
      eventNoteCreateList: [],
      eventNoteList: [],
      transportationModal: false,
      eventTransportation: {},
      eventTransportationAddress: {},
    };
    this.locationReferenceList.push(React.createRef());
  }

  // getParticipantList = () => {
  //   // e.stopPropagation();
  //   const postObject = {
  //     header: this.header,
  //     member: {
  //       id: this.memberDetails.member,
  //     },
  //   };
  //   let url = 'memberms/getMemberParticipant';
  //   FetchServerData.callPostService(url, postObject).then((output) => {
  //     if (output.status === 'SUCCESS') {
  //       let familyMemberList = [];
  //       let memberParticipantList = [];

  //       familyMemberList = output.data.memberList;
  //       if (this.state.pageId === 'createSimilarEvent') {
  //         for (let i = 0; i < familyMemberList.length; i++) {
  //           familyMemberList[i].operation = 'Add';
  //           if (familyMemberList[i].isMember === true) {
  //             familyMemberList[i].checked = true;

  //             let participantMemberObj = {
  //               member: {
  //                 id: familyMemberList[i].id,
  //               },
  //             };
  //             memberParticipantList.push(participantMemberObj);
  //           } else {
  //             familyMemberList[i].checked = false;
  //           }
  //         }
  //       }
  //       this.setState({
  //         render: true,
  //         eventParticipantMemberList: familyMemberList,
  //         participantList: memberParticipantList,
  //       });
  //     } else {
  //     }
  //   });
  // };

  addNewLocation(locationData) {
    for (let i = 0; i < this.state.locationList.length; i++) {
      if (
        this.state.locationList[i] &&
        locationData.index === i &&
        this.locationReferenceList[i] &&
        this.locationReferenceList[i].current &&
        this.locationReferenceList[i].current.validateLocation()
      ) {
        this.state.locationList[i] = locationData;
        this.locationReferenceList[i].current.state = locationData;
        this.locationReferenceList[i].current.state.action = 'editLocation';
        this.setState({ render: true });
      }
    }
  }

  updateLocation(locationData) {
    for (let i = 0; i < this.state.locationList.length; i++) {
      if (
        this.state.locationList[i] &&
        locationData.index === i &&
        this.locationReferenceList[i] &&
        this.locationReferenceList[i].current &&
        this.locationReferenceList[i].current.validateLocation()
      ) {
        this.state.locationList[i] = locationData;
        this.locationReferenceList[i].current.state = locationData;
        this.locationReferenceList[i].current.state.action = 'editLocation';
        this.setState({ render: true });
      }
    }
  }

  handleEventTypeChange(e) {
    this.setState({ eventType: e.target.value });
  }

  handleLongDescriptionChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 256);
    if (res.length < 256) {
      this.setState({ longDescription: res });
    } else {
      Utils.maxFieldLength(256);
    }
  }

  handleEventStartDateChange(date) {
    this.setState({ startDate: date });
  }
  handleEventTitleChange(e) {
    this.setState({ eventTitle: e.target.value });
  }

  handleDescriptionChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 256);
    if (res.length < 256) {
      this.setState({ note: res });
    } else {
      Utils.maxFieldLength(256);
    }
  }

  handleEventTransportationChange(inputTransportation) {
    this.setState({ eventTransportation: inputTransportation });
  }

  transportationToggle = () => {
    this.setState({ transportationModal: !this.state.transportationModal });
  };
  cancelCreate() {
    this.props.history.push({
      pathname: '/member_event',
    });
  }
  addEventInBackend(e) {
    let locations = [];
    for (let i = 0; i < this.locationReferenceList.length; i++) {
      // if (null !== this.locationReferenceList[i].current) {
      let location = {};
      // location.id = this.locationReferenceList[i].current.state.id;
      // location.shortDescription = this.locationReferenceList[i].current.state.shortDescription;
      // location.nickName = this.locationReferenceList[i].current.state.nickName;
      // location.fax = this.locationReferenceList[i].current.state.fax;
      // location.website = this.locationReferenceList[i].current.state.website;
      // location.phone = this.locationReferenceList[i].current.state.phone;
      location.address = {};
      location.address.id = this.addressRef.current.state.id;
      location.address.line = this.addressRef.current.state.line;
      location.address.city = this.addressRef.current.state.city;
      location.address.zipCode = this.addressRef.current.state.zipCode;
      location.address.state = this.addressRef.current.state.state;
      locations[i] = location;
      // }
    }
    let dateTime = Utils.convertUserTimeZoneDateToUTCDate(this.state.startDate);

    const postObject = {
      header: this.header,
      event: {
        date: dateTime,
        description: this.state.note,
        type: this.state.eventType,
        title: this.state.eventTitle,
        member: {
          id: this.memberDetails.member,
        },
        location: locations[0],
        contactList: [],

        reminderList: this.state.reminderItems,
      },
    };

    FetchServerData.callPostService('/eventms/create', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let eventdata = output.data;
          PtsAlert.success(output.message);
          let fccPageState = {
            id: eventdata.event.id,
            action: 'view',
          };
          store.dispatch(fccPageStateAction.setFccPageState(fccPageState));

          this.props.history.push({
            pathname: '/viewEvent',
            state: {
              action: 'view',
              title: 'Facility Details',
              eventdata: eventdata,
            },
          });
        } else if (Utils.equalsIgnoreCase(output.status, 'FAIL')) {
          if (output.errorCode && Utils.equalsIgnoreCase(output.errorCode, 'DuplicateAppointmentEvent')) {
            // PtsAlert.warn(output.message);
            // this.warningMessage(output.message)
            this.setState({
              sameDateEventWindow: true,
              WarningMessage: output.message,
            });
          }
        } else {
        }
      })
      .catch((error) => {});
  }

  selectedParticipant(e, rowData, type) {
    const { checked } = e.target;

    let selectedParticipantList = this.state.participantList;
    // let contactObj = {};
    let memberObj = {};
    if (checked === true) {
      memberObj = {
        member: {
          id: rowData.id,
        },
        operation: 'Add',
      };
      selectedParticipantList.push(memberObj);
      this.setState({
        participantList: selectedParticipantList,
      });
    } else {
      if (rowData.operation === 'update') {
        for (let i = 0; i < selectedParticipantList.length; i++) {
          if (selectedParticipantList[i].member.id === rowData.id) {
            memberObj = {
              id: selectedParticipantList[i].id,
              member: {
                id: selectedParticipantList[i].member.id,
              },
              operation: 'Delete',
            };
            selectedParticipantList.push(memberObj);
            selectedParticipantList.splice(i, 1);
          }
        }
      } else {
        for (let i = 0; i < selectedParticipantList.length; i++) {
          if (selectedParticipantList[i].member.id === rowData.id) {
            selectedParticipantList.splice(i, 1);
          }
        }
      }
      this.setState({
        participantList: selectedParticipantList,
      });
    }
  }

  addSameEventInBackend(e) {
    let locations = [];
    for (let i = 0; i < this.locationReferenceList.length; i++) {
      // if (null !== this.locationReferenceList[i].current) {
      let location = {};
      // location.id = this.locationReferenceList[i].current.state.id;
      // location.shortDescription = this.locationReferenceList[i].current.state.shortDescription;
      // location.nickName = this.locationReferenceList[i].current.state.nickName;
      // location.fax = this.locationReferenceList[i].current.state.fax;
      // location.website = this.locationReferenceList[i].current.state.website;
      // location.phone = this.locationReferenceList[i].current.state.phone;
      location.address = {};
      location.address.id = this.addressRef.current.state.id;
      location.address.line = this.addressRef.current.state.line;
      location.address.city = this.addressRef.current.state.city;
      location.address.zipCode = this.addressRef.current.state.zipCode;
      location.address.state = this.addressRef.current.state.state;
      locations[i] = location;
      // }
    }
    let dateTime = Utils.convertUserTimeZoneDateToUTCDate(this.state.startDate);

    const postObject = {
      header: this.header,
      event: {
        date: dateTime,
        description: this.state.note,
        type: this.state.eventType,
        title: this.state.eventTitle,
        member: {
          id: this.memberDetails.member,
        },
        location: locations[0],
        contactList: [],

        reminderList: this.state.reminderItems,
        forceCreateOnSameDate: true,
      },
    };

    FetchServerData.callPostService('/eventms/create', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let eventdata = output.data;
          this.setState({ sameDateEventWindow: false });
          PtsAlert.success(output.message);
          let fccPageState = {
            id: eventdata.event.id,
            action: 'view',
          };
          store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
          if (this.state.pageId === 'memberdeatils') {
            this.props.history.push({
              pathname: '/memberDetails',
            });
          } else {
            this.props.history.push({
              pathname: '/viewEvent',
              state: {
                action: 'view',
                title: 'Facility Details',
                eventdata: eventdata,
                pageId: 'createEvent',
              },
            });
          }
        } else {
        }
      })
      .catch((error) => {});
  }

  handleSameDateEventCreateWindowClose = () => {
    this.setState({
      sameDateEventWindow: false,
    });
  };
  createCancel() {
    this.props.history.push({
      pathname: '/member_event',
    });
  }
  render() {
    if (this.state.render === false) {
      return '';
    }
    if (this.state.action === 'createSimilarEvent') {
      return this.createEvent();
    }
  }
  handleAddressChange(address) {
    this.setState({ address: address });
  }
  createEvent() {
    let locationItems = [];
    this.state.locationList.forEach((element, index) => {
      if ('delete' !== element.operation) {
        locationItems.push(
          <Col md={12} style={{ padding: '0.5em' }}>
            <Location
              ref={this.locationReferenceList[index]}
              key={index}
              data={element}
              srNo={index}
              action={element.action}
              pageId="event"
              isButtonHide="true"
              onLocationAdd={this.addNewLocation.bind(this)}
              onUpdate={this.updateLocation.bind(this)}
            />
          </Col>
        );
      }
    });

    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={'Add Event For' + ' ' + this.memberName}
          domainObject="Event"
        />
        <ValidatorForm style={{ width: '100%' }} ref="form" onSubmit={this.addEventInBackend.bind(this)}>
          <Row style={{ position: 'relative', margin: '1em' }}>
            {' '}
            <Col md={6} style={{ position: 'relative', padding: '5px' }}>
              <Card className="doctorViewCard" style={{ padding: '1em', height: '100%' }}>
                <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                  <Label style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em' }}>{this.state.title}</Label>
                </CardHeader>
                <CardBody>
                  <Row style={{ marginTop: '1em', marginBottom: '-1em' }}>
                    <Col md={6} style={{ marginBottom: '1em' }}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ThemeProvider theme={materialTheme}>
                          <DateTimePicker
                            value={this.state.startDate}
                            variant="outlined"
                            fullWidth
                            className="ptsDateTimePicker"
                            inputVariant="outlined"
                            timeInputLabel="Time:"
                            format="MM/dd/yyyy  h:mm aa"
                            style={{ background: '#fff' }}
                            onChange={this.handleEventStartDateChange.bind(this)}
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>
                    </Col>
                    <Col md={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="description"
                        label="Title"
                        value={this.state.eventTitle}
                        onChange={this.handleEventTitleChange.bind(this)}
                        name="name"
                        type="text"
                        className="textField"
                        InputProps={{}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} style={{ marginTop: '25px' }}>
                      <TextareaAutosize
                        aria-label="minimum height"
                        style={{ width: '100%', padding: '10px' }}
                        rowsMin={5}
                        id="description"
                        value={this.state.note}
                        inputprops={{ maxLength: 256, padding: '10px' }}
                        name="note"
                        onChange={this.handleDescriptionChange.bind(this)}
                        onPressEnter={this.handleDescriptionChange.bind(this)}
                        placeholder="Enter Note Details"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} style={{ padding: '5px' }}>
              <Card className="doctorViewCard" style={{ padding: '1em', height: '100%' }}>
                <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                  <Label style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em' }}>Location</Label>
                </CardHeader>
                <CardBody>
                  {/* {locationItems} */}
                  <div className="location_details_view">
                    <div className="panel panel-blue">
                      <div className="panel-heading">
                        <Address
                          ref={this.addressRef}
                          onChange={this.handleAddressChange.bind(this)}
                          data={this.state.address}
                          action="create"
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <CardFooter>
            <div style={{ textAlign: 'center', padding: '0.5em' }}>
              <CreateButton>Add</CreateButton>
              &nbsp;
              <CancelIconButton onClick={(e) => this.cancelCreate()} />
            </div>
          </CardFooter>
        </ValidatorForm>
        <Dialog
          open={this.state.sameDateEventWindow}
          onClose={this.handleSameDateEventCreateWindowClose.bind(this)}
          onHide={this.close}
          style={{ padding: '1em' }}
        >
          <DialogContent style={{ marginTop: '2em' }}>
            <Row>
              <Col md={12} style={{ textAlign: 'center' }}>
                <h5>{this.state.WarningMessage}</h5>
                <br />
                {this.state.WarningMessage ? <h5>Do you still want to continue to create?</h5> : ''}
              </Col>
            </Row>
          </DialogContent>
          <DialogActions style={{ marginTop: '0em', padding: '1em', marginLeft: 'auto', marginRight: 'auto' }}>
            <Button
              variant="contained"
              size="large"
              style={{ background: '#0f666b', color: '#fff', marginRight: '1rem' }}
              startIcon={<SaveIcon />}
              onClick={this.addSameEventInBackend.bind(this)}
            >
              Ok
            </Button>
            <Button
              variant="contained"
              size="large"
              style={{ background: '#717373', color: '#fff', marginRight: '1rem' }}
              startIcon={<CloseIcon />}
              onClick={this.handleSameDateEventCreateWindowClose.bind(this)}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default Event;
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#008181',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: 'white',
        color: '#008181',
      },
    },
  },
});
