import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import React, { Component } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import PtsAlert from '../ptsAlert/PtsAlert';
import './ForgotPassword.scss';

export default class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    let action = props.action;
    if (
      null !== props.history &&
      props.history !== undefined &&
      props.history.location !== null &&
      props.history.location.state !== null &&
      props.history.location.state !== undefined
    ) {
      action = props.history.location.state.action;
    }
    this.state = {
      email: '',
      showPassword: false,
      action: action,
    };
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  resetPasswordInBackend = (e) => {
    e.preventDefault();
    const postObject = {
      appuserVo: {
        emailOrPhone: this.state.email,
      },
    };
    FetchServerData.callPostService('userms/forgetPassword', postObject)
      .then((response) => {
        let output = response;
        if (output.status === 'SUCCESS') {
          PtsAlert.success(output.message);
          this.props.history.push({
            pathname: '/resetpassword',
            state: { email: this.state.email, pathUrl: 'forgotPassword' },
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  goToPage(pageid) {
    if (pageid && pageid.length > 0) {
      this.props.history.push({
        pathname: pageid,
        state: { email: this.state.email },
      });
    }
  }

  render() {
    return (
      <div md={12} style={{ padding: '1em', display: 'flex', margin: '2em' }}>
        <Card
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: 'auto',
            padding: '1em',
          }}
        >
          <CardHeader>
            <Row>
              <span style={{ color: '#169ba2', fontSize: 'large', padding: '0.2em' }}>
                <b>Reset Password</b>
              </span>
            </Row>
          </CardHeader>
          <ValidatorForm
            ref="form"
            onSubmit={this.resetPasswordInBackend.bind(this)}
            style={{ width: '100%', padding: '1em' }}
          >
            <CardBody style={{ width: '100%', paddingBottom: '0' }}>
              <Row md={12}>
                <Col md={12}>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    required
                    fullWidth
                    id="email"
                    onChange={this.handleEmailChange.bind(this)}
                    label="Email"
                    name="email"
                    autoFocus
                    value={this.state.email}
                    autoComplete="off"
                  />
                </Col>
              </Row>
            </CardBody>

            <CardFooter style={{ width: '100%', border: 'none' }}>
              <Row>
                <Col md={12} style={{ textAlign: 'center' }}>
                  <Button
                    type="submit"
                    style={{ backgroundColor: '#ef6603', color: '#fff' }}
                    variant="contained"
                    startIcon={<LockOpenIcon />}
                  >
                    RESET
                  </Button>
                </Col>
              </Row>
              <Row style={{ marginTop: '1em' }}>
                <Col md={12} style={{ textAlign: 'center' }}>
                  <span style={{ color: 'black' }}>
                    Not an account?&nbsp;
                    <Link
                      style={{ color: 'blue', fontWeight: '400', cursor: 'pointer' }}
                      onClick={(e) => this.goToPage('/createAccount')}
                    >
                      Sign Up
                    </Link>
                  </span>
                </Col>
              </Row>
              <Row style={{ marginTop: '1em' }}>
                <Col md={12} style={{ textAlign: 'center' }}>
                  <span style={{ color: 'black' }}>
                    <Link
                      style={{ color: 'blue', fontWeight: '400', cursor: 'pointer' }}
                      onClick={(e) => this.goToPage('/login')}
                    >
                      Login
                    </Link>
                  </span>
                </Col>
              </Row>
            </CardFooter>
          </ValidatorForm>
        </Card>
      </div>
    );
  }
}
