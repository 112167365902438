import React, { Component } from 'react';
import store from '../../../redux/store';
import { Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import PtsAlert from '../../components/ptsAlert/PtsAlert';
import CancelIconButton from '../../components/buttons/CancelIconButton';
import CreateButton from '../../components/buttons/CreateButton';
import SaveButton from '../../components/buttons/SaveButton';
import FetchServerData from '../../../provider/FetchServerData';
import AdminMenuWithBreadScrum from '../adminMenu/AdminMenuWithBreadScrum';
import profileManagementAction from '../../../redux/adminPanel/profileManagement/ProfileManagementAction';
import adminFccPageStateAction from '../../../redux/adminPanel/adminFccPageState/adminFccPageStateAction';
import applicationStateAction from '../../../redux/applicationState/applicationStateAction';
import ToggleButton from '@material-ui/lab/ToggleButton';
import Utils from '../../../provider/Utils';
import BackButton from '../../components/buttons/BackButton';
class FccProfileManagement extends Component {
  header = store.getState().adminHeader.adminHeader;
  profileDetails = store.getState().profileDetails.profileDetails;
  adminFccPageState = store.getState().adminFccPageState.adminFccPageState;

  adminBreadCrumb = [
    { pageid: 'fccProfileList', uiname: 'FCC Profile Management List' },
    { pageid: '', uiname: 'FCC Profile Management Details' },
  ];
  constructor(props) {
    super(props);
    let action = props.action;
    let profileId = props.profileId;
    let pageId = '';
    let title = '';
    let canRender = false;

    if (!profileId && props.history && props.history.location && props.history.location.state) {
      profileId = props.history.location.state.profileId;
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
      title = props.history.location.state.title;
    }

    if (null !== profileId && profileId > 0) {
      this.getProfileDetails(profileId);
    } else {
      canRender = true;
    }
    if (this.adminFccPageState) {
      pageId = this.adminFccPageState.pageId;
    }
    if (profileId === undefined) {
      if (pageId === 'profileList') {
        profileId = this.adminFccPageState.id;
        action = this.adminFccPageState.action;
        title = this.adminFccPageState.title;
      } else {
        profileId = this.profileDetails.id;
        action = 'edit';
      }
      if (action !== 'create') {
        this.getProfileDetails(profileId);
      }
    }

    this.state = {
      action: action,
      profileId: profileId,
      pageId: pageId,
      title: title,
      profile: '',
      initialPage: 0,
      pageSize: 10,
      profileList: [],
      menus: [],
      isStatus: true,
      activeMenus: new Map(),
      updateProfile: false,
      addProfile: true,
      isPaging: false,
      getUpdatePermission: false,
      getDeletePermission: false,
      render: false,
    };
  }
  getProfileDetails(profileId) {
    const postObject = {
      header: this.header,
      profile: {
        id: profileId,
      },
    };

    FetchServerData.callPostService('/profilems/get', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let profileDetails = output.data.profile;
          store.dispatch(profileManagementAction.setProfile(profileDetails));
          let getPermission =
            profileDetails.owner === 'FCCProfile'
              ? Utils.getRolePermission([])
              : Utils.getRolePermission(output.data.privilegeList);
          let getUpdatePermission = getPermission[3];
          let getDeletePermission = getPermission[4];

          if (this.state.action === 'view') {
            profileDetails.menus.map((menu) => {
              this.showrolesubMenu(menu.id);
              menu.domainObjects.map((domainObject) => {
                this.showDomainObject(domainObject.id, menu.id);
              });
            });
          }
          let profileStatus = '';
          if (profileStatus === null) {
            profileStatus = false;
          } else {
            if (profileDetails.status?.status === 'Active') {
              profileStatus = true;
            } else {
              profileStatus = false;
            }
          }

          this.setState({
            render: true,
            id: profileDetails.id,
            profile: profileDetails.name,
            profileOwner: profileDetails.owner,
            menus: profileDetails.menus,
            isStatus: profileStatus,
            profileStatus: profileDetails.status,
            getUpdatePermission: getUpdatePermission,
            getDeletePermission: getDeletePermission,
          });
        } else {
        }
      })
      .catch((error) => {});
  }
  handleProfileChange(e) {
    this.setState({ profile: e.target.value });
  }
  handleStatusChange(e) {
    this.setState({ isStatus: !this.state.isStatus });
  }
  createCancel() {
    this.props.history.push({
      pathname: '/fccProfileList',
    });
  }

  editCancel() {
    this.props.history.push({
      pathname: '/viewProfileManagement',
      state: {
        action: 'view',
        title: 'profile Details',
        profileId: this.state.id,
      },
    });
  }

  createProfileToBackend() {
    const postObject = {
      header: this.header,
      profile: {
        name: this.state.profile,
      },
    };
    FetchServerData.callPostService('/profilems/create', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        PtsAlert.success(output.message);
        let profileDetails = output.data.profile;
        // this.getProfileDetails(profileDetails.id);
        this.setState({
          updateProfile: true,
          addProfile: false,
          id: profileDetails.id,
          profile: profileDetails.name,
          menus: profileDetails.menus,
        });
      } else {
      }
    });
  }

  updateProfileToBackend() {
    let profileStatus;
    if (this.state.isStatus === true) {
      profileStatus = 'Active';
    } else {
      profileStatus = 'InActive';
    }
    const postObject = {
      header: this.header,
      profile: {
        name: this.state.profile,
        id: this.state.id,
        menus: this.state.menus,
        status: {
          status: profileStatus,
          id: profileStatus === 'Active' ? 1 : 2,
        },
      },
    };
    FetchServerData.callPostService('/profilems/update', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        PtsAlert.success(output.message);
        let profileData = output.data.profile;
        this.props.history.push({
          pathname: '/viewProfileManagement',
          state: { action: 'view', title: 'profile Details', profileId: profileData.id },
        });
      } else {
      }
    });
  }
  checkIfActive(domainObjectAccessRules) {
    // let isActive = false;
    for (let i = 0; i < domainObjectAccessRules.length; i++) {
      const element = domainObjectAccessRules[i];
      if (element.isactive) {
        return true;
      }
    }
    return false;
  }
  showrolesubMenu(menuId) {
    let menus = this.state.activeMenus;
    if (menus.has(menuId)) {
      menus.delete(menuId);
    } else {
      menus.set(menuId, new Set());
    }
    this.setState({ activeMenus: menus });
  }
  showDomainObject(domainObjectId, menuId) {
    let domainObjects = this.state.activeMenus.get(menuId);
    if (domainObjects.has(domainObjectId)) {
      //remove
      domainObjects.delete(domainObjectId);
    } else {
      //Add
      domainObjects.add(domainObjectId);
    }
    this.setState({ activeMenus: this.state.activeMenus.set(menuId, domainObjects) });
  }

  onDomainObjectPrivilegeClicked(id) {
    this.state.menus.map((menu) => {
      menu.domainObjects.map((domainObject) => {
        domainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
          if (domainObjectPrivilege.id === id) {
            if (domainObjectPrivilege.isactive === false) {
              this.handleDomainObjectPrivilegeChecked(domainObjectPrivilege, domainObject, menu);
            } else {
              this.handleDomainObjectPrivilegeUnChecked(domainObjectPrivilege, domainObject, menu);
            }
            return;
          }
        });
      });
      menu.childMenus?.map((submenu) => {
        submenu.domainObjects.map((domainObject) => {
          domainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
            if (domainObjectPrivilege.id === id) {
              if (domainObjectPrivilege.isactive === false) {
                this.handleDomainObjectPrivilegeChecked(domainObjectPrivilege, domainObject, menu);
              } else {
                this.handleDomainObjectPrivilegeUnChecked(domainObjectPrivilege, domainObject, menu);
              }

              return;
            }
          });
        });
      });
    });
    this.setState({ menus: this.state.menus });
  }
  onDomainObjectAccessRulesClicked(id) {
    this.state.menus.map((menu) => {
      menu.domainObjects.map((domainObject) => {
        domainObject.domainObjectAccessRules.map((domainObjectAccessRule) => {
          if (domainObjectAccessRule.id === id) {
            if (domainObjectAccessRule.isactive === false) {
              this.handleDomainObjectAccessRulesChecked(domainObjectAccessRule, domainObject, menu);
            } else {
              this.handleDomainObjectAccessRulesUnChecked(domainObjectAccessRule, domainObject, menu);
            }
            return;
          }
        });
      });
      menu.childMenus?.map((submenu) => {
        submenu.domainObjects.map((domainObject) => {
          domainObject.domainObjectAccessRules.map((domainObjectAccessRule) => {
            if (domainObjectAccessRule.id === id) {
              if (domainObjectAccessRule.isactive === false) {
                this.handleDomainObjectAccessRulesChecked(domainObjectAccessRule, domainObject, menu);
              } else {
                this.handleDomainObjectAccessRulesUnChecked(domainObjectAccessRule, domainObject, menu);
              }

              return;
            }
          });
        });
      });
    });
    this.setState({ menus: this.state.menus });
  }

  handleDomainObjectPrivilegeChecked(inputDomainObjectPrivilege, inputdomainObject, menu) {
    let inputPrivilge = inputDomainObjectPrivilege;
    if (inputPrivilge.name === '*') {
      inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
        domainObjectPrivilege.isactive = false;
      });
      inputDomainObjectPrivilege.isactive = true;
    } else if (inputPrivilge.name === 'Read') {
      inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
        let privilege = domainObjectPrivilege;
        if (privilege.name === 'Read') {
          domainObjectPrivilege.isactive = true;
        }
        if (privilege.name === '*') {
          domainObjectPrivilege.isactive = false;
        }
      });
    } else {
      inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
        let privilege = domainObjectPrivilege;
        if (privilege.name === 'Read') {
          domainObjectPrivilege.isactive = true;
        } else if (privilege.name === inputPrivilge.name) {
          domainObjectPrivilege.isactive = true;
        }
      });

      let isCreateCheckd = false;
      let isUpdateCheckd = false;
      let isDeleteheckd = false;
      let isApproveCheckd = false;
      inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
        let privilege = domainObjectPrivilege;
        if (privilege.name === 'Create' && domainObjectPrivilege.isactive === true) {
          isCreateCheckd = true;
        } else if (privilege.name === 'Update' && domainObjectPrivilege.isactive === true) {
          isUpdateCheckd = true;
        } else if (privilege.name === 'Delete' && domainObjectPrivilege.isactive === true) {
          isDeleteheckd = true;
        } else if (privilege.name === 'Approve' && domainObjectPrivilege.isactive === true) {
          isApproveCheckd = true;
        }
      });
      // console.log("isCreateCheckd=" + isCreateCheckd, "isUpdateCheckd=" + isUpdateCheckd, "isDeleteheckd=" + isDeleteheckd, "isApproveCheckd=" + isApproveCheckd);
      if (isCreateCheckd && isUpdateCheckd && isDeleteheckd && isApproveCheckd) {
        inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
          let privilege = domainObjectPrivilege;
          if (privilege.name === '*') {
            domainObjectPrivilege.isactive = true;
          } else {
            domainObjectPrivilege.isactive = false;
          }
        });
      } else {
        inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
          let privilege = domainObjectPrivilege;
          if (privilege.name === '*') {
            domainObjectPrivilege.isactive = false;
          }
        });
      }
    }
    inputdomainObject.isactive = true;
    this.handleDomainObjectChecked(inputdomainObject, menu);
  }

  handleDomainObjectPrivilegeUnChecked(inputDomainObjectPrivilege, inputdomainObject, menu) {
    let inputPrivilge = inputDomainObjectPrivilege;
    if (inputPrivilge.name === '*') {
      inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
        domainObjectPrivilege.isactive = false;
      });
    } else if (inputPrivilge.name === 'Read') {
      let flag = false;
      inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
        let privilege = domainObjectPrivilege;
        if (privilege.name !== 'Read' && domainObjectPrivilege.isactive === true) {
          flag = true;
        }
      });
      // console.log('Read deselected flag:', flag);
      inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
        let privilege = domainObjectPrivilege;
        if (privilege.name === 'Read') {
          // console.log("inputDomainObjectPrivilege", inputDomainObjectPrivilege);
          inputDomainObjectPrivilege.isactive = flag;
        }
      });
    } else {
      inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
        let privilege = domainObjectPrivilege;
        if (privilege.name === inputPrivilge.name) {
          domainObjectPrivilege.isactive = false;
        }
      });
    }

    let flag = false;
    inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
      if (domainObjectPrivilege.isactive === true) {
        flag = true;
      }
    });
    inputdomainObject.domainObjectAccessRules.map((domainObjectAccessRule) => {
      if (domainObjectAccessRule.isactive === true) {
        flag = true;
      }
    });
    if (flag === false) {
      this.handleDomainObjectUnChecked(inputdomainObject, menu);
    }
  }
  handleDomainObjectAccessRulesChecked(inputDomainObjectAccessRule, inputdomainObject, menu) {
    let inputPrivilge = inputDomainObjectAccessRule;
    inputDomainObjectAccessRule.isactive = true;
    // inputdomainObject.isactive = true;
    inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
      let privilege = domainObjectPrivilege;
      if (privilege.name === 'Read') {
        domainObjectPrivilege.isactive = true;
      } else if (privilege.name === inputPrivilge.name) {
        domainObjectPrivilege.isactive = true;
      }
    });
    this.handleDomainObjectChecked(inputdomainObject, menu);
  }
  handleDomainObjectAccessRulesUnChecked(inputDomainObjectAccessRule, inputdomainObject, menu) {
    inputDomainObjectAccessRule.isactive = false;
    // inputdomainObject.isactive = false;
    let flag = false;
    for (let i = 0; i < inputdomainObject.domainObjectAccessRules.length; i++) {
      const element = inputdomainObject.domainObjectAccessRules[i];
      if (element.isactive) {
        flag = true;
      }
    }
    for (let i = 0; i < inputdomainObject.domainObjectPrivileges.length; i++) {
      const element = inputdomainObject.domainObjectPrivileges[i];
      if (element.isactive) {
        flag = true;
      }
    }
    if (!flag) {
      this.handleDomainObjectUnChecked(inputdomainObject, menu);
    }
  }
  renderDomainObjectPrivileges(domainObjectPrivilege, menuId, domainObjectId, action) {
    return (
      <>
        {action === 'view' ? (
          <div style={{ width: '21em', marginLeft: '5em', paddingBottom: '0px' }}>
            {domainObjectPrivilege.isactive ? (
              <>
                <span className="bold" style={{ color: 'green' }}>
                  {' '}
                  {domainObjectPrivilege.name}&nbsp;
                </span>
                <i class="fa fa-check" aria-hidden="true"></i>
              </>
            ) : (
              <span style={{ color: 'black' }}> {domainObjectPrivilege.name}&nbsp;</span>
            )}
          </div>
        ) : (
          <li className="list-group-item list-group-item-action prev" style={{ width: '21em', marginLeft: '2em' }}>
            <input
              className="checkbox"
              type="checkbox"
              checked={domainObjectPrivilege.isactive}
              onChange={this.onDomainObjectPrivilegeClicked.bind(this, domainObjectPrivilege.id)}
            />
            &nbsp; {domainObjectPrivilege.name}
          </li>
        )}
      </>
    );
  }
  domainObjectAccessRules(domainObjectAccessRule, menuId, domainObjectId, action) {
    return (
      <>
        {action === 'view' ? (
          <div style={{ width: '22em', marginLeft: '2.2em' }}>
            {domainObjectAccessRule.isactive ? (
              <>
                <span style={{ fontWeight: 550, marginLeft: '3px', fontSize: '14px', color: 'green' }}>
                  {domainObjectAccessRule.rulename}&nbsp;
                </span>
                <i class="fa fa-check" aria-hidden="true"></i>
                <div
                  className="row"
                  style={{ marginLeft: '6px', marginRight: '15px', textAlign: 'justify', color: 'black' }}
                >
                  {domainObjectAccessRule.description}
                </div>
              </>
            ) : (
              <>
                <span style={{ fontWeight: 550, marginLeft: '3px', fontSize: '14px', color: 'black' }}>
                  {domainObjectAccessRule.rulename}{' '}
                </span>
                <div
                  className="row"
                  style={{ marginLeft: '6px', marginRight: '15px', textAlign: 'justify', color: 'black' }}
                >
                  {domainObjectAccessRule.description}
                </div>
              </>
            )}
          </div>
        ) : (
          <li className="list-group-item list-group-item-action prev" style={{ width: '26.5em', marginLeft: '2em' }}>
            <input
              className="checkbox"
              type="checkbox"
              checked={domainObjectAccessRule.isactive}
              onChange={this.onDomainObjectAccessRulesClicked.bind(this, domainObjectAccessRule.id)}
            />
            &nbsp;
            <span style={{ fontWeight: 550, marginLeft: '3px', fontSize: '14px' }}>
              {domainObjectAccessRule.rulename}{' '}
            </span>
            <div className="row" style={{ marginLeft: '20px' }}>
              {domainObjectAccessRule.description}
            </div>
          </li>
        )}
      </>
    );
  }

  onDomainObjectClicked(id) {
    this.state.menus.map((menu) => {
      menu.domainObjects.map((domainObject) => {
        if (domainObject.id === id) {
          if (domainObject.isactive === false) {
            this.handleDomainObjectChecked(domainObject, menu, true);
          } else {
            this.handleDomainObjectUnChecked(domainObject, menu, true);
          }
          return;
        }
      });
      menu.childMenus?.map((submenu) => {
        submenu.domainObjects.map((domainObject) => {
          if (domainObject.id === id) {
            if (domainObject.isactive === false) {
              this.handleDomainObjectChecked(domainObject, submenu, true);
            } else {
              this.handleDomainObjectUnChecked(domainObject, submenu, true);
            }
          }
          return;
        });
      });
    });
    this.setState({ menus: this.state.menus });
  }

  handleDomainObjectChecked(inputdomainObject, inputMenu, checkPrivilges) {
    if (checkPrivilges && checkPrivilges === true) {
      inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
        // if (domainObjectPrivilege.name === '*') {
        // 	domainObjectPrivilege.isactive = true;
        // } else {
        domainObjectPrivilege.isactive = true;
        // }
      });
    }

    inputdomainObject.isactive = true;
    this.state.menus.map((menu) => {
      menu.domainObjects.map((domainObject) => {
        if (inputdomainObject.name === domainObject.name) {
          menu.isactive = true;
          domainObject.isactive = true;
        }
      });

      menu.childMenus?.map((submenu) => {
        submenu.domainObjects.map((domainObject) => {
          if (inputdomainObject.name === domainObject.name) {
            submenu.isactive = true;
            menu.isactive = true;
          }
        });
      });
    });
  }

  handleDomainObjectUnChecked(inputdomainObject, menu, checkPrivilges) {
    if (checkPrivilges && checkPrivilges == true) {
      inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
        domainObjectPrivilege.isactive = false;
      });
      inputdomainObject.domainObjectAccessRules.map((domainObjectAccessRule) => {
        domainObjectAccessRule.isactive = false;
      });
    }
    inputdomainObject.isactive = false;
    this.state.menus.map((menu) => {
      let flag = false;
      let found = false;
      menu.domainObjects.map((domainObject) => {
        if (inputdomainObject.name === domainObject.name) {
          domainObject.isactive = false;
          found = true;
        }
        if (domainObject.isactive === true) {
          flag = true;
        }
      });
      if (found) {
        menu.isactive = flag;
      }

      menu.childMenus?.map((submenu) => {
        let subFlag = false;
        let subFound = false;
        submenu.domainObjects.map((subdomainObject) => {
          if (inputdomainObject.name === subdomainObject.name) {
            subdomainObject.isactive = false;
            submenu.isactive = false;
            subFound = true;
          }
          if (subdomainObject.isactive === true) {
            subFlag = true;
          }
        });

        if (subFound === true) {
          menu.childMenus.map((submenu) => {
            if (submenu.isactive === true) {
              subFlag = true;
            }
          });
          submenu.isactive = subFlag;
          menu.isactive = subFlag;
        }
      });
    });
  }

  renderDomainObject(domainObject, menu, action) {
    let activeMenus = this.state.activeMenus;
    let menuId = menu.id;
    return (
      <>
        {action === 'view' ? (
          <>
            <div style={{ width: '21em', paddingBottom: '0px', marginLeft: '1.5em', marginTop: '0.5em' }}>
              {domainObject.isactive ? (
                <>
                  <span className="bold" style={{ fontSize: '15px', color: '#e67300' }}>
                    {' '}
                    {domainObject.name}&nbsp;
                  </span>
                  <i class="fa fa-check" aria-hidden="true"></i>
                </>
              ) : (
                <span className="bold" style={{ fontSize: '15px', color: 'black' }}>
                  {' '}
                  {domainObject.name}&nbsp;
                </span>
              )}
            </div>
            <div className="mt-1">
              <ul className="sub-menu list-group" style={{ display: 'inline' }}>
                <div style={{ width: '21em', paddingBottom: '0px' }}>
                  <span className="bold" style={{ fontSize: '14px', marginLeft: '4.5em', color: 'black' }}>
                    Privileges
                  </span>
                </div>
                {domainObject.domainObjectPrivileges.map((domainObjectPrivilege) =>
                  this.renderDomainObjectPrivileges(domainObjectPrivilege, menuId, domainObject.id, action)
                )}
              </ul>
              &nbsp;
              {domainObject.domainObjectAccessRules.length > 0 ? (
                <ul className="sub-menu list-group" style={{ marginLeft: '2em' }}>
                  <div style={{ width: '21em', paddingBottom: '0px' }}>
                    <span className="bold" style={{ fontSize: '14px', color: 'black' }}>
                      Access Rules
                    </span>
                  </div>
                  {domainObject.domainObjectAccessRules.map((domainObjectAccessRule) =>
                    this.domainObjectAccessRules(domainObjectAccessRule, menuId, domainObject.id, action)
                  )}
                </ul>
              ) : (
                ''
              )}
            </div>
          </>
        ) : (
          <li className="list-group-item list-group-item-action prev">
            <input
              className="checkbox"
              type="checkbox"
              checked={domainObject.isactive}
              onChange={this.onDomainObjectClicked.bind(this, domainObject.id, menu)}
            />
            {/* &nbsp; &nbsp; {domainObject.name}&nbsp; &nbsp; */}
            <span style={{ marginLeft: '5px' }}> {domainObject.name}&nbsp;&nbsp;</span>
            <a className="btn-flat pull-right ps-2" style={{ display: 'contents' }}>
              <i
                className={
                  activeMenus.has(menuId) && activeMenus.get(menuId).has(domainObject.id)
                    ? 'fa fa-lg fa-minus'
                    : 'fa fa-lg fa-plus'
                }
                onClick={this.showDomainObject.bind(this, domainObject.id, menuId)}
              ></i>
              {activeMenus.has(menuId) && activeMenus.get(menuId).has(domainObject.id) ? (
                <>
                  <div className="row d-flex mt-2">
                    <ul className="sub-menu list-group" stype={{ marginLeft: '2em' }}>
                      <li
                        className="list-group-item list-group-item-action prev"
                        style={{ width: '21em', marginLeft: '2em', paddingBottom: '0px' }}
                      >
                        <span className="bold" style={{ fontSize: '14px' }}>
                          Privileges
                        </span>
                      </li>
                      {domainObject.domainObjectPrivileges.map((domainObjectPrivilege) =>
                        this.renderDomainObjectPrivileges(domainObjectPrivilege, menuId, domainObject.id, action)
                      )}
                    </ul>
                    &nbsp;
                    {domainObject.domainObjectAccessRules.length > 0 ? (
                      <ul className="sub-menu list-group" stype={{ marginLeft: '2em' }}>
                        <li
                          className="list-group-item list-group-item-action prev"
                          style={{ width: '26.5em', marginLeft: '2em', paddingBottom: '0px' }}
                        >
                          <span className="bold" style={{ fontSize: '14px' }}>
                            Access Rules
                          </span>
                        </li>
                        {domainObject.domainObjectAccessRules.map((domainObjectAccessRule) =>
                          this.domainObjectAccessRules(domainObjectAccessRule, menuId, domainObject.id, action)
                        )}
                      </ul>
                    ) : (
                      ''
                    )}
                  </div>
                </>
              ) : (
                ''
              )}
            </a>
          </li>
        )}
      </>
    );
  }

  onMenuClicked(id) {
    this.state.menus.map((menu) => {
      if (menu.id === id) {
        if (menu.isactive === false) {
          this.handleMenuChecked(menu);
        } else {
          this.handleMenuUnChecked(menu);
        }
      }
      if (menu.childMenus && menu.childMenus.length > 0) {
        menu.childMenus.map((submenu) => {
          if (submenu.id === id) {
            if (submenu.isactive === false) {
              this.handleMenuChecked(submenu, menu);
            } else {
              this.handleMenuUnChecked(submenu, menu);
            }
          }
        });
      }
    });

    this.setState({ menus: this.state.menus });
  }

  handleMenuChecked(inputmenu) {
    this.state.menus.map((menu) => {
      if (menu.menuid === inputmenu.menuid) {
        menu.isactive = true;
        menu.childMenus?.map((submenu) => {
          submenu.isactive = true;
          submenu.domainObjects.map((domainObject) => {
            domainObject.isactive = true;
            domainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
              domainObjectPrivilege.isactive = true;
            });
          });
        });
        menu.domainObjects.map((domainObject) => {
          domainObject.isactive = true;
          domainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
            // if (domainObjectPrivilege.name === '*') {
            // 	domainObjectPrivilege.isactive = true;
            // } else {
            domainObjectPrivilege.isactive = true;
            // }
          });
        });
      } else {
        menu.childMenus?.map((submenu) => {
          if (submenu.menuid === inputmenu.menuid) {
            submenu.isactive = true;
            submenu.domainObjects.map((domainObject) => {
              domainObject.isactive = true;
              domainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
                // if (domainObjectPrivilege.name === '*') {
                // 	domainObjectPrivilege.isactive = true;
                // } else {
                domainObjectPrivilege.isactive = true;
                // }
              });
            });
            menu.isactive = true;
          }
        });
      }
    });
  }

  handleMenuUnChecked(inputmenu, parentmenu) {
    this.state.menus.map((menu) => {
      if (menu.menuid === inputmenu.menuid) {
        menu.isactive = false;
        menu.childMenus?.map((submenu) => {
          submenu.isactive = false;
          submenu.domainObjects.map((domainObject) => {
            domainObject.isactive = false;
            domainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
              domainObjectPrivilege.isactive = false;
            });
          });
        });
        menu.domainObjects.map((domainObject) => {
          domainObject.isactive = false;
          domainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
            domainObjectPrivilege.isactive = false;
          });
          domainObject.domainObjectAccessRules.map((domainObjectAccessRule) => {
            domainObjectAccessRule.isactive = false;
          });
        });
      } else {
        menu.childMenus?.map((submenu) => {
          if (submenu.menuid === inputmenu.menuid) {
            submenu.isactive = false;
            submenu.domainObjects.map((domainObject) => {
              domainObject.isactive = false;
              domainObject.domainObjectPrivileges?.map((domainObjectPrivilege) => {
                domainObjectPrivilege.isactive = false;
              });
            });

            let flag = false;
            menu.childMenus.map((submenu) => {
              if (submenu.isactive === true) {
                flag = true;
              }
            });
            menu.isactive = flag;
          }
        });
      }
    });
  }

  renderMenus(menu, action) {
    if (menu.childMenus && menu.childMenus.length > 0) {
      return (
        <>
          {action === 'view' ? (
            <div className="row d-flex">
              <li className="list-group-item list-group-item-action prev border-0" style={{ marginLeft: '1em' }}>
                <div style={{ width: '27em', paddingBottom: '0px' }}>
                  {menu.isactive ? (
                    <>
                      <span className="bold" style={{ fontSize: '16px', color: '#0099f1' }}>
                        {' '}
                        {menu.uiname}&nbsp;&nbsp;
                      </span>
                      <i class="fa fa-check" aria-hidden="true" style={{ color: '#0099f1' }}></i>
                    </>
                  ) : (
                    <span className="bold" style={{ fontSize: '15px', color: 'black' }}>
                      {' '}
                      {menu.uiname}&nbsp;
                    </span>
                  )}
                </div>
                <ul className="list-group" style={{ display: 'content' }}>
                  {menu.childMenus.map((submenu) => this.renderMenus(submenu, action))}
                </ul>
              </li>
            </div>
          ) : (
            <li className="list-group-item list-group-item-action prev" style={{ marginLeft: '1em' }}>
              <input
                className="checkbox"
                type="checkbox"
                checked={menu.isactive}
                onChange={this.onMenuClicked.bind(this, menu.id)}
              />
              &nbsp; &nbsp;{menu.uiname}&nbsp; &nbsp;
              <a style={{ display: 'contents' }}>
                <i
                  className={this.state.activeMenus.has(menu.id) ? 'fa fa-lg fa-minus' : 'fa fa-lg fa-plus'}
                  onClick={this.showrolesubMenu.bind(this, menu.id)}
                ></i>
                {this.state.activeMenus.has(menu.id) ? (
                  <ul className="list-group" style={{ display: 'content' }}>
                    {menu.childMenus.map((submenu) => this.renderMenus(submenu, action))}
                  </ul>
                ) : (
                  ''
                )}
              </a>
            </li>
          )}
        </>
      );
    } else {
      return (
        <>
          {action === 'view' ? (
            <div className="row d-flex">
              <li className="list-group-item list-group-item-action prev border-0" style={{ marginLeft: '1em' }}>
                <div style={{ width: '27em', paddingBottom: '0px' }}>
                  {menu.isactive ? (
                    <>
                      <span className="bold" style={{ fontSize: '16px', color: '#0099f1' }}>
                        {' '}
                        {menu.uiname}&nbsp;&nbsp;
                      </span>
                      <i class="fa fa-check" aria-hidden="true" style={{ color: '#0099f1' }}></i>
                    </>
                  ) : (
                    <span className="bold" style={{ fontSize: '15px', color: 'black' }}>
                      {' '}
                      {menu.uiname}&nbsp;
                    </span>
                  )}
                </div>
                <div className="row d-flex">
                  <ul
                    className="sub-menu list-group"
                    style={{ display: 'flex', marginLeft: '2em', width: 'fit-content' }}
                  >
                    {menu.domainObjects.map((domainObject) => this.renderDomainObject(domainObject, menu, action))}
                  </ul>
                </div>
              </li>
            </div>
          ) : (
            <li className="list-group-item list-group-item-action prev" style={{ marginLeft: '1em' }}>
              <input
                className="checkbox"
                type="checkbox"
                checked={menu.isactive}
                onChange={this.onMenuClicked.bind(this, menu.id)}
              />
              <span style={{ marginLeft: '6px' }}>{menu.uiname}</span> &nbsp;
              {menu.domainObjects.length > 0 ? (
                <a style={{ display: 'contents' }}>
                  <i
                    className={this.state.activeMenus.has(menu.id) ? 'fa fa-lg fa-minus' : 'fa fa-lg fa-plus'}
                    onClick={this.showrolesubMenu.bind(this, menu.id)}
                  ></i>
                  {this.state.activeMenus.has(menu.id) ? (
                    <ul
                      className="sub-menu list-group"
                      style={{ display: 'flex', marginLeft: '2em', marginTop: '10px', width: 'fit-content' }}
                    >
                      {menu.domainObjects.map((domainObject) => this.renderDomainObject(domainObject, menu, action))}
                    </ul>
                  ) : (
                    ''
                  )}
                </a>
              ) : (
                ''
              )}
            </li>
          )}
        </>
      );
    }
  }

  editFCCProfileManagement() {
    this.props.history.push({
      pathname: '/editProfileManagement',
      state: {
        action: 'edit',
        title: 'Edit Profile',
        profileId: this.state.id,
      },
    });
  }
  viewCancel() {
    this.props.history.push({
      pathname: '/fccProfileList',
    });
  }

  deleteProfileToBackend = () => {
    const postObject = {
      header: this.header,
      profile: {
        id: this.state.id,
      },
    };
    FetchServerData.callPostService('/profilems/delete', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        this.props.history.push({
          pathname: '/fccProfileList',
        });
      } else {
      }
    });
  };

  render() {
    if (this.state.action === 'create') {
      return this.createProfile();
    } else if (this.state.action === 'view') {
      return this.viewProfile();
    } else if (this.state.action === 'edit') {
      return this.editProfile();
    }
  }

  createProfile() {
    return (
      <>
        <AdminMenuWithBreadScrum
          breadCrumb={this.adminBreadCrumb}
          history={this.props.history}
          name={'Create FCC Profile Management'}
          domainObject="Profile"
        />
        <Row style={{ position: 'relative', margin: '1em' }}>
          <Col md={12} style={{ position: 'relative', width: '550px' }}>
            <Card className="formCard">
              <CardHeader className="cardHeader">
                <Row>
                  <Col md={9}>
                    {/* {undefined !== this.state.title &&
											this.state.title.length > 0 && ( */}
                    <Label className="cardHeaderTitle">Create Profile</Label>
                    {/* )} */}
                  </Col>
                </Row>
              </CardHeader>{' '}
              <CardBody>
                {this.state.addProfile ? (
                  <Row>
                    <Col md={4} style={{ textAlign: 'center' }}>
                      <TextField
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                        id="profile"
                        type="text"
                        onChange={this.handleProfileChange.bind(this)}
                        value={this.state.profile}
                        label="Profile"
                        name="lname"
                        inputProps={{
                          maxLength: 128,
                          style: { textTransform: 'capitalize' },
                        }}
                      />
                    </Col>
                    <Col md={4}>
                      <div style={{ paddingTop: '25px' }}>
                        <CreateButton id="loginButton" type="submit" onClick={this.createProfileToBackend.bind(this)}>
                          Create
                        </CreateButton>
                        &nbsp;
                        <CancelIconButton onClick={(e) => this.createCancel()} />
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col md={4} style={{ textAlign: 'center' }}>
                      <TextField
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                        id="profile"
                        type="text"
                        onChange={this.handleProfileChange.bind(this)}
                        value={this.state.profile}
                        label="Profile"
                        name="lname"
                        inputProps={{
                          maxLength: 128,
                          style: { textTransform: 'capitalize' },
                        }}
                      />
                    </Col>
                  </Row>
                )}
                <div className="mt-4">
                  <ul className="list-group text-start">
                    {this.state.menus &&
                      this.state.menus.sort((a, b) => a.menu - b.menu).map((menu) => this.renderMenus(menu))}
                  </ul>
                </div>
                {this.state.updateProfile && (
                  <div style={{ textAlign: 'center', padding: '1em 0em 1em' }}>
                    <SaveButton id="loginButton" type="submit" onClick={this.updateProfileToBackend.bind(this)}>
                      Save
                    </SaveButton>
                    &nbsp;
                    <CancelIconButton onClick={(e) => this.editCancel()} />
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }

  viewProfile() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <AdminMenuWithBreadScrum
            breadCrumb={this.adminBreadCrumb}
            history={this.props.history}
            backCallback={this.viewCancel.bind(this)}
            name="FCC Profile Management"
            deleteCallback={this.deleteProfileToBackend.bind(this)}
            editCallback={this.editFCCProfileManagement.bind(this)}
            getDeletePermission={this.state.getDeletePermission}
            getUpdatePermission={this.state.getUpdatePermission}
          />
          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={12} className="sponsorDetailColumn" style={{ position: 'relative', padding: '5px' }}>
              <Card style={{ height: '100%' }}>
                <CardHeader className="cardHeader">
                  <Row style={{ marginRight: '1em' }}>
                    <Col md={6}>
                      <Label className="cardHeaderTitle" style={{ fontSize: '16px' }}>
                        {/* Advertisement Details */}
                        {this.state.profile}
                      </Label>
                    </Col>
                    <Col md={6} style={{ textAlign: 'end', marginRigth: '30px' }}>
                      <label style={{ paddingRight: '0px' }}> Status:</label>&nbsp;
                      <span style={{ color: '#000' }}>{this.state.profileStatus?.status}</span>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="mt-0">
                    <ul
                      className="list-group text-start"
                      style={{ display: 'inline-flex', flexWrap: 'wrap', flexDirection: 'row', listStyleType: 'none' }}
                    >
                      {this.state.menus &&
                        this.state.menus.sort((a, b) => a.menu - b.menu).map((menu) => this.renderMenus(menu, 'view'))}
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div className="sponsorDetailFooter">
            <BackButton
              type="button"
              style={{ backgroundColor: '#162c50', color: '#fff' }}
              variant="contained"
              onClick={(e) => this.viewCancel()}
            >
              Back
            </BackButton>
          </div>
        </>
      );
    }
  }

  editProfile() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <AdminMenuWithBreadScrum
            breadCrumb={this.adminBreadCrumb}
            history={this.props.history}
            name={'Edit FCC Profile Management'}
            domainObject="Profile"
          />
          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={12} style={{ position: 'relative', width: '550px' }}>
              <Card className="formCard">
                <CardHeader className="cardHeader">
                  <Row>
                    <Col md={9}>
                      {/* {undefined !== this.state.title &&
											this.state.title.length > 0 && ( */}
                      <Label className="cardHeaderTitle">Edit Profile</Label>
                      {/* )} */}
                    </Col>
                  </Row>
                </CardHeader>{' '}
                <CardBody>
                  <Row>
                    <Col md={4} style={{ textAlign: 'center' }}>
                      <TextField
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                        id="profile"
                        type="text"
                        onChange={this.handleProfileChange.bind(this)}
                        value={this.state.profile}
                        label="Profile"
                        name="lname"
                        inputProps={{
                          maxLength: 128,
                          style: { textTransform: 'capitalize' },
                        }}
                      />
                    </Col>
                    <Col md={4}></Col>
                    <Col md={4} style={{ textAlign: 'end' }}>
                      <label className="form-label " htmlFor="collapsible-email">
                        Status
                      </label>
                      <Tooltip title="Please click 'Click’ to change Status">
                        <span className="ms-4">
                          <ToggleButton
                            value={this.state.isStatus ? 'Active' : 'InActive'}
                            className="ToggleButtonActive"
                            onChange={this.handleStatusChange.bind(this)}
                            style={
                              this.state.isStatus
                                ? {
                                    background: 'rgb(0, 84, 118)',
                                    color: '#fff',
                                    height: '33px',
                                    width: '80px',
                                    borderRadius: '5px',
                                    textTransform: 'none',
                                  }
                                : {
                                    background: '#D7DBDD',
                                    color: '#000',
                                    height: '33px',
                                    width: '80px',
                                    borderRadius: '5px',
                                    textTransform: 'none',
                                  }
                            }
                          >
                            {this.state.isStatus ? 'Active' : 'InActive'}
                          </ToggleButton>
                        </span>
                      </Tooltip>
                    </Col>
                  </Row>{' '}
                  <div className="mt-4">
                    <ul className="list-group text-start">
                      {this.state.menus &&
                        this.state.menus.sort((a, b) => a.menu - b.menu).map((menu) => this.renderMenus(menu))}
                    </ul>
                  </div>
                  <div style={{ textAlign: 'center', padding: '1em 0em 1em' }}>
                    <SaveButton id="loginButton" type="submit" onClick={this.updateProfileToBackend.bind(this)}>
                      Save
                    </SaveButton>
                    &nbsp;
                    <CancelIconButton onClick={(e) => this.editCancel()} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      );
    }
  }
}
export default FccProfileManagement;
