import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import '@fullcalendar/timegrid/main.css';
import '@fullcalendar/daygrid/main.css';
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import timeGridPlugin from '@fullcalendar/timegrid';
import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import { format } from 'date-fns';
import $ from 'jquery';
import MaterialTable from 'material-table';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import AdSense from 'react-adsense';
import 'react-tabs/style/react-tabs.css';
import { Card, Col, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import UiUtils from '../../../provider/UiUtils';
import applicationStateAction from '../../../redux/applicationState/applicationStateAction';
import store from '../../../redux/store';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
import SponsorShipTemplate from '../sponsorShipTemplate/SponsorShipTemplate';
import './MemberDetails.scss';
import ChatIcon from '@material-ui/icons/Chat';
import Badge from '@material-ui/core/Badge';
import AccessAlarmTwoToneIcon from '@material-ui/icons/AccessAlarmTwoTone';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import Utils from '../../../provider/Utils';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import Button from '@material-ui/core/Button';
import helpDataAction from '../../../redux/help/displayHelpAction';
import Advertisement from '../advertisement/Advertisement';
import AddIconButton from '../buttons/AddIconButton';
import BreadCrumbDetailsAction from '../../../redux/breadCrumbDetails/BreadCrumbDetailsAction';
import ActiveMenuAction from '../../../redux/activeMenu/ActiveMenuAction';
export default class MemberDetails extends Component {
  calendarState = store.getState().applicationState.calendarState;
  memberName = store.getState().memberData.memberData.firstname + ' ' + store.getState().memberData.memberData.lastname;
  memberHomeUrl = store.getState().memberHomeUrl.memberHomeUrl;
  title = 'Calendar';
  breadCrumb = [{ pageid: this.memberHomeUrl, uiname: this.memberName }];
  memberDetails = store.getState().memberData.memberData;

  header = store.getState().header.header;
  isFreeUser = store.getState().userData.userData.isFreeUser;
  sponsorUser = store.getState().Sponsorship.Sponsorship;
  displayHelp = store.getState().help.help;
  calendarRef = React.createRef();
  doctorListArray = [];
  constructor(props) {
    super(props);
    this.memberDetails = store.getState().memberData.memberData;
    let action = props.action;

    /*current URL path for sponsorship template*/
    let currentLocationUrl = window.location.href;
    let pathArray = currentLocationUrl.split('/');
    let host = pathArray[4];
    let currentLocationPath = '/' + host;

    if (props.history && props.history.location && props.history.location.state) {
      action = props.history.location.state.action;
    }

    let calendarState = {};
    if (this.calendarState) {
      calendarState = Object.getOwnPropertyNames(this.calendarState);
    }
    if (calendarState.length > 0) {
      let currentMonth = this.calendarState.selectedDate;
      let monYrArr = currentMonth.split('/');
      let month = monYrArr[1];
      let year = monYrArr[2];

      if (month === null || undefined === month) {
        let date = new Date();
        month = date.getMonth() + 1;
        month = '' + month;
        year = '' + date.getFullYear();
      }

      this.state = {
        sponsorUser: this.sponsorUser,
        currentLocationPath: currentLocationPath,
        memberId: this.memberDetails.member,
        userSelectedDate: this.calendarState.userSelectedMonth,
        weekendsVisible: true,
        currentEvents: [],
        calendarWeekends: true,
        calendarItemList: [],
        appointmentList: [],
        calenderAppointmentList: [],
        calenderIncidentList: [],
        calenderEventList: [],
        modal: false,
        event: [],
        note: '',
        time: '',
        isDoctor: false,
        isOther: false,
        location: '',
        description: '',
        preDescription: '',
        appoiPurposeDes: '',
        frequency: '',
        appoiPurpose: '',
        startDate: format(new Date(), 'yyyy-MM-dd'),
        endDate: format(new Date(), 'yyyy-MM-dd'),
        calenderDateView: '',
        appointmentType: '',
        doctor: '',
        action: action,
        isFreeUser: this.isFreeUser,
        appointmentDate: '',
        currentDateTime: new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000),
        defaultCalenderView: 'month',
        selectedUserMonth: '',
        selectedMonth: this.calendarState.calenderNxtPrvMonth,
        incidentCompareDate: '',
        currentCompareDate: new Date(),
        calendarItemListData: [],
        displayFloatButton: this.displayHelp,
        stepsEnabled: false,
        initialStep: 0,
        steps: [
          {
            element: '.user-panel',
            intro: 'Member name who’s card you are viewing',
          },
          {
            element: '#sidebarMenu1',
            intro: 'List of Caregivers’ Circle',
          },
          {
            element: '#sidebarMenu2',
            intro: 'List of Communications',
          },
          {
            element: '#sidebarMenu3',
            intro: 'Calendar showing Appointments and Incidents. You can add Appointments and Incidents from here too.',
          },
          {
            element: '#sidebarMenu4',
            intro: 'List of Appointments. You can add Appointments from here too.',
          },
          {
            element: '#sidebarMenu5',
            intro: 'List of Incidents. You can add Incidents from here too.',
          },
          {
            element: '#sidebarMenu6',
            intro: 'List of Doctors. You can add Doctors here.',
          },
          {
            element: '#sidebarMenu7',
            intro: 'List of Facilities. You can add Facilities here.',
          },
          {
            element: '#sidebarMenu8',
            intro: 'List of Purchases. You can add Purchases here.',
          },
          {
            element: '.fc-today-button',
            intro: 'Current day of current Month',
          },
          {
            element: '.fc-prev-button ',
            intro: 'Move to Previous month',
          },

          {
            element: '.fc-next-button',
            intro: 'Move to next month',
          },
          {
            element: '.fc-dayGridMonth-button',
            intro: 'Monthly View',
          },
          {
            element: '.fc-timeGridWeek-button',
            intro: 'Weekly View',
          },

          {
            element: '.fc-timeGridDay-button',
            intro: 'View for the whole Day',
          },
          {
            element: '.fc-daygrid-day-frame',
            intro: 'Click to add an Appointment or Incident. Incidents can be added for past dates Only.',
          },
        ],
        getAppointmentCreatePermission: false,
        getAppointmentAllPermission: false,
        showIncidentPermission: false,
        getIncidentCreatePermission: false,
        getIncidentAllPermission: false,
        showEventPermission: false,
        getEventCreatePermission: false,
        getEventAllPermission: false,
      };
      this.MemberCalenderByMonth(month.toUpperCase(), year);
    } else {
      this.d = new Date();
      this.selectedMonth = moment(this.d);
      this.state = {
        sponsorUser: this.sponsorUser,
        currentLocationPath: currentLocationPath,
        memberId: this.memberDetails.member,
        weekendsVisible: true,
        currentEvents: [],
        calendarWeekends: true,
        calendarItemList: [],
        appointmentList: [],
        calenderAppointmentList: [],
        calenderIncidentList: [],
        calenderEventList: [],
        modal: false,
        event: [],
        note: '',
        time: '',
        isDoctor: false,
        isOther: false,
        location: '',
        description: '',
        preDescription: '',
        appoiPurposeDes: '',
        frequency: '',
        appoiPurpose: '',
        startDate: format(new Date(), 'yyyy-MM-dd'),
        endDate: format(new Date(), 'yyyy-MM-dd'),
        calenderDateView: '',
        appointmentType: '',
        doctor: '',
        action: action,
        isFreeUser: this.isFreeUser,
        appointmentDate: '',
        currentDateTime: new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000),
        userSelectedDate: format(new Date(), 'yyyy-MM-dd'),
        defaultCalenderView: 'month',
        selectedUserMonth: '',
        selectedMonth: this.selectedMonth,
        incidentCompareDate: '',
        currentCompareDate: new Date(),
        calendarItemListData: [],
        stepsEnabled: false,
        initialStep: 0,
        steps: [
          {
            element: '.user-panel',
            intro: 'Member name who’s card you are viewing',
          },
          {
            element: '#sidebarMenu1',
            intro: 'List of Caregivers’ Circle',
          },
          {
            element: '#sidebarMenu2',
            intro: 'List of Communications',
          },
          {
            element: '#sidebarMenu3',
            intro: 'Calendar showing Appointments and Incidents. You can add Appointments and Incidents from here too.',
          },
          {
            element: '#sidebarMenu4',
            intro: 'List of Appointments. You can add Appointments from here too.',
          },
          {
            element: '#sidebarMenu5',
            intro: 'List of Incidents. You can add Incidents from here too.',
          },
          {
            element: '#sidebarMenu6',
            intro: 'List of Doctors. You can add Doctors here.',
          },
          {
            element: '#sidebarMenu7',
            intro: 'List of Facilities. You can add Facilities here.',
          },
          {
            element: '#sidebarMenu8',
            intro: 'List of Purchases. You can add Purchases here.',
          },
          {
            element: '.fc-today-button',
            intro: 'Current day of current Month',
          },
          {
            element: '.fc-prev-button ',
            intro: 'Move to Previous month',
          },

          {
            element: '.fc-next-button',
            intro: 'Move to next month',
          },
          {
            element: '.fc-dayGridMonth-button',
            intro: 'Monthly View',
          },
          {
            element: '.fc-timeGridWeek-button',
            intro: 'Weekly View',
          },

          {
            element: '.fc-timeGridDay-button',
            intro: 'View for the whole Day',
          },
          {
            element: '.fc-daygrid-day-frame ',
            intro: 'Click to add an Appointment or Incident. Incidents can be added for past dates Only.',
          },
        ],
        displayFloatButton: this.displayHelp,
        getAppointmentCreatePermission: false,
        getAppointmentAllPermission: false,
        showIncidentPermission: false,
        getIncidentCreatePermission: false,
        getIncidentAllPermission: false,
        showEventPermission: false,
        getEventCreatePermission: false,
        getEventAllPermission: false,
      };
      let currentMonth = format(new Date(), 'yyyy-MMM-dd');
      let monYrArr = currentMonth.split('-');
      let month = monYrArr[1];
      let year = monYrArr[0];
      this.MemberCalenderByMonth(month, year);
    }
  }

  toggleSteps = () => {
    this.setState((prevState) => ({ stepsEnabled: !prevState.stepsEnabled }));
  };
  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };

  componentDidMount() {
    let startDate = '';
    /* On click previous button,we get previous month and year and backend API call for selected month and events are displayed on calendar */
    document.querySelector('.fc-prev-button').addEventListener('click', (e) => {
      var lastDayOfNextMonth = moment(this.state.selectedMonth.add(-1, 'M')).endOf('month');
      this.d = lastDayOfNextMonth.format('DD/MMM/YYYY');
      let userSelectedMonth = lastDayOfNextMonth.format('YYYY-MM-DD');
      let calendarState = {
        calenderNxtPrvMonth: moment(this.d),
        selectedDate: this.d,
        userSelectedMonth: userSelectedMonth,
        src: 'memberDetails',
      };
      store.dispatch(
        applicationStateAction.setApplicationState({
          calendarState,
        })
      );
      let monYrArr = this.d.split('/');
      let month = monYrArr[1];
      let year = monYrArr[2];
      month = month.toUpperCase();
      if (this.state.defaultCalenderView === 'month') {
        this.MemberCalenderByMonth(month, year);
        startDate = this.calendarRef.current.getApi().view.activeStart - 1;
      } else if (this.state.defaultCalenderView === 'day') {
        startDate = this.calendarRef.current.getApi().view.activeStart - 1;
        startDate = moment(startDate).format('DD/MMM/YYYY');
        let monYrArr = startDate.split('/');
        let day = monYrArr[0];
        let month = monYrArr[1];
        let year = monYrArr[2];
        month = month.slice(0, 3);
        month = month.toUpperCase();
        if (day.toString() === '30' || day.toString() === '31') {
          this.MemberCalenderByMonth(month, year);
        }
      } else {
        startDate = this.calendarRef.current.getApi().view.activeStart - 1;
        let weekEndDate = moment(startDate).format('DD/MMM/YYYY');
        let weekStartDate = moment(new Date(startDate).setDate(new Date(startDate).getDate() - 6)).format(
          'DD/MMM/YYYY'
        );
        let weekStartDateInp = weekStartDate;
        let weekEndDateInp = weekEndDate;
        this.MemberCalenderDataByWeek(
          moment(weekStartDateInp).format('YYYY-MM-DD'),
          moment(weekEndDateInp).format('YYYY-MM-DD')
        );
      }
    });
    /* On click next button,we get next month and year and backend API call for selected month and events are displayed on calendar */
    document.querySelector('.fc-next-button').addEventListener('click', (e) => {
      var lastDayOfNextMonth = moment(this.state.selectedMonth.add(1, 'M')).endOf('month');
      this.d = lastDayOfNextMonth.format('DD/MMM/YYYY');
      let userSelectedMonth = lastDayOfNextMonth.format('YYYY-MM-DD');
      let calendarState = {
        calenderNxtPrvMonth: moment(this.d),
        selectedDate: this.d,
        userSelectedMonth: userSelectedMonth,
        src: 'memberDetails',
      };
      store.dispatch(
        applicationStateAction.setApplicationState({
          calendarState,
        })
      );
      let monYrArr = this.d.split('/');
      let month = monYrArr[1];
      let year = monYrArr[2];
      month = month.toUpperCase();
      if (this.state.defaultCalenderView === 'month') {
        this.MemberCalenderByMonth(month, year);
        startDate = this.calendarRef.current.getApi().view.activeEnd;
      } else if (this.state.defaultCalenderView === 'day') {
        startDate = this.calendarRef.current.getApi().view.activeEnd;
        startDate = moment(startDate).format('DD/MMM/YYYY');
        let monYrArr = startDate.split('/');
        let day = monYrArr[0];
        let month = monYrArr[1];
        let year = monYrArr[2];
        month = month.slice(0, 3);
        month = month.toUpperCase();

        if (day.toString() === '01') {
          this.MemberCalenderByMonth(month, year);
        }
      } else {
        startDate = this.calendarRef.current.getApi().view.activeEnd;
        let weekStartDate = moment(startDate).format('DD/MMM/YYYY');
        let weekEndDate = moment(startDate.setDate(startDate.getDate() + 6)).format('DD/MMM/YYYY');
        let weekStartDateInp = weekStartDate;
        let weekEndDateInp = weekEndDate;
        this.MemberCalenderDataByWeek(
          moment(weekStartDateInp).format('YYYY-MM-DD'),
          moment(weekEndDateInp).format('YYYY-MM-DD')
        );
      }
    });

    /* Here we get access of day month button and we perform some operation on it */
    document.querySelector('.fc-dayGridMonth-button').addEventListener('click', (e) => {
      let text = $('.fc-dayGridMonth-button').text();
      this.d = new Date();
      let datGridSetDate = '';
      if (startDate) {
        datGridSetDate = moment(startDate);
      } else {
        datGridSetDate = moment(this.state.selectedMonth);
      }
      let mmmm = moment(datGridSetDate).format('MMM');
      let yyyy = moment(datGridSetDate).format('YYYY');
      this.setState({
        selectedMonth: datGridSetDate,

        defaultCalenderView: text,
        userSelectedDate: format(new Date(), 'yyyy-MM-dd'),
      });

      this.MemberCalenderByMonth(mmmm.toUpperCase(), yyyy);
    });
    /* Here we get access of week view button and we perform some operation on it */
    document.querySelector('.fc-timeGridWeek-button').addEventListener('click', (e) => {
      let text = $('.fc-timeGridWeek-button').text();
      this.setState({
        defaultCalenderView: text,
        selectedMonth: this.state.selectedMonth,
      });
    });
    /* Here we get access of day view button and we perform some operation on it */
    document.querySelector('.fc-timeGridDay-button').addEventListener('click', (e) => {
      let text = $('.fc-timeGridDay-button').text();

      if (startDate) {
        this.setState({
          selectedMonth: moment(startDate),
        });
      } else {
        let selectedMonth = this.state.selectedMonth;
        selectedMonth = selectedMonth;
        this.setState({
          selectedMonth: selectedMonth,
        });
      }

      this.setState({
        defaultCalenderView: text,
      });
    });
    /* Here we get access of today button and we perform some operation on it */
    document.querySelector('.fc-today-button').addEventListener('click', (e) => {
      this.d = new Date();
      this.setState({
        selectedMonth: moment(this.d),
      });
    });
  }

  /*Get member calender month data */
  MemberCalenderByMonth = (month, year) => {
    const postObject = {
      header: this.header,
      member: {
        id: this.memberDetails.member,
      },
      month: month,
      year: year,
    };
    FetchServerData.callPostService('/memberms/getMemberCalenderMonthItems', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let calenderList = [];
          let events = [];
          calenderList = output.data.calenderItemList;
          let title = '';
          let color = '';
          let time = '';
          let doctorFacilityLocation = '';
          let action = '';
          let incidentLocation = '';
          let calenderDateTimeArr = '';
          let status = '';
          let eventLocation = '';
          let getDomainObjectPermission = output.data.allowedDomainObjects;
          for (let domainObjectKey in getDomainObjectPermission) {
            // let keyPermission = domainObjectKey;
            if (domainObjectKey === 'Appointment') {
              let appointmentPermission = getDomainObjectPermission[domainObjectKey];
              let getAppointmentPermission = Utils.getRolePermission(appointmentPermission);
              let getAppointmentAllPermission = getAppointmentPermission[0];
              let getAppointmentCreatePermission = getAppointmentPermission[1];
              this.setState({
                showAppointmentPermission: true,
                getAppointmentCreatePermission: getAppointmentCreatePermission,
                getAppointmentAllPermission: getAppointmentAllPermission,
              });
            } else if (domainObjectKey === 'Incident') {
              let incidentPermission = getDomainObjectPermission[domainObjectKey];
              let getIncidentPermission = Utils.getRolePermission(incidentPermission);
              let getIncidentAllPermission = getIncidentPermission[0];
              let getIncidentCreatePermission = getIncidentPermission[1];
              this.setState({
                showIncidentPermission: true,
                getIncidentCreatePermission: getIncidentCreatePermission,
                getIncidentAllPermission: getIncidentAllPermission,
              });
            } else if (domainObjectKey === 'Event') {
              let eventPermission = getDomainObjectPermission[domainObjectKey];
              let getEventPermission = Utils.getRolePermission(eventPermission);
              let getEventAllPermission = getEventPermission[0];
              let getEventCreatePermission = getEventPermission[1];
              this.setState({
                showEventPermission: true,
                getEventCreatePermission: getEventCreatePermission,
                getEventAllPermission: getEventAllPermission,
              });
            }
          }

          for (let i = 0; i < calenderList.length; i++) {
            if (calenderList[i].action === 'Appointment') {
              if (calenderList[i].date !== null) {
                let dateTime = calenderList[i].date;
                dateTime = moment(dateTime).format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
                calenderDateTimeArr = Utils.UserTimeZoneToCalenderMonthWeakDateTimeFormate(calenderList[i].date);
                title = calenderList[i].type;
                time = Utils.calenderTimeZoneFormate(calenderList[i].date);
                doctorFacilityLocation = calenderList[i].location;
                action = calenderList[i].action;
                status = calenderList[i].status;
                if (calenderList[i].status === 'Cancelled') {
                  color = '#cccccc';
                } else if (calenderList[i].status === 'Completed') {
                  color = '#e6dab8';
                } else {
                  color = '#D4EFDF';
                }
              }
            } else if (calenderList[i].action === 'Incident') {
              if (calenderList[i].date !== null) {
                calenderDateTimeArr = Utils.UserTimeZoneToCalenderMonthWeakDateTimeFormate(calenderList[i].date);

                title = calenderList[i].type;
                incidentLocation = calenderList[i].location;

                time = Utils.calenderTimeZoneFormate(calenderList[i].date);
                action = calenderList[i].action;
                color = '#FADBD8';
              }
            } else if (calenderList[i].action === 'Event') {
              if (calenderList[i].date !== null) {
                calenderDateTimeArr = Utils.UserTimeZoneToCalenderMonthWeakDateTimeFormate(calenderList[i].date);

                title = calenderList[i].type;
                eventLocation = calenderList[i].location;
                status = calenderList[i].status;
                time = Utils.calenderTimeZoneFormate(calenderList[i].date);
                action = calenderList[i].action;
                color = '#D6EAF8';
              }
            }
            let calendarObj = {
              id: calenderList[i].id,
              title: title,
              time: time,
              doctorFacilityLocation: doctorFacilityLocation,
              incidentLocation: incidentLocation,
              eventLocation: eventLocation,
              start: calenderDateTimeArr,
              color: color,
              action: action,
              status: status,
            };
            events.push(calendarObj);
          }
          this.setState({
            calendarItemList: events,
            calendarItemListData: calenderList,
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  /* Get the list of event weekly */
  MemberCalenderDataByWeek = (weekStartDate, weekEndDate) => {
    const postObject = {
      header: this.header,
      member: {
        id: this.memberDetails.member,
      },
      startDate: weekStartDate,
      endDate: weekEndDate,
    };
    FetchServerData.callPostService('memberms/getMemberCalenderItemsByDate', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          this.state.calendarItemList = [];
          let calenderList = [];
          let events = [];
          calenderList = output.data.calenderItemList;
          let title = '';
          let color = '';
          let time = '';
          let doctorFacilityLocation = '';
          let status = '';
          let action = '';
          let calenderDateTimeArr = '';
          let incidentLocation = '';
          let eventLocation = '';
          let getDomainObjectPermission = output.data.allowedDomainObjects;
          for (let domainObjectKey in getDomainObjectPermission) {
            // let keyPermission = domainObjectKey;
            if (domainObjectKey === 'Appointment') {
              let appointmentPermission = getDomainObjectPermission[domainObjectKey];
              let getAppointmentPermission = Utils.getRolePermission(appointmentPermission);
              let getAppointmentAllPermission = getAppointmentPermission[0];
              let getAppointmentCreatePermission = getAppointmentPermission[1];
              this.setState({
                showAppointmentPermission: true,
                getAppointmentCreatePermission: getAppointmentCreatePermission,
                getAppointmentAllPermission: getAppointmentAllPermission,
              });
            } else if (domainObjectKey === 'Incident') {
              let incidentPermission = getDomainObjectPermission[domainObjectKey];
              let getIncidentPermission = Utils.getRolePermission(incidentPermission);
              let getIncidentAllPermission = getIncidentPermission[0];
              let getIncidentCreatePermission = getIncidentPermission[1];
              this.setState({
                showIncidentPermission: true,
                getIncidentCreatePermission: getIncidentCreatePermission,
                getIncidentAllPermission: getIncidentAllPermission,
              });
            } else if (domainObjectKey === 'Event') {
              let eventPermission = getDomainObjectPermission[domainObjectKey];
              let getEventPermission = Utils.getRolePermission(eventPermission);
              let getEventAllPermission = getEventPermission[0];
              let getEventCreatePermission = getEventPermission[1];
              this.setState({
                showEventPermission: true,
                getEventCreatePermission: getEventCreatePermission,
                getEventAllPermission: getEventAllPermission,
              });
            }
          }
          for (let i = 0; i < calenderList.length; i++) {
            if (calenderList[i].action === 'Appointment') {
              if (calenderList[i].date !== null) {
                title = calenderList[i].type;

                calenderDateTimeArr = Utils.UserTimeZoneToCalenderMonthWeakDateTimeFormate(calenderList[i].date);

                time = Utils.calenderTimeZoneFormate(calenderList[i].date);
                doctorFacilityLocation = calenderList[i].location;
                action = calenderList[i].action;
                status = calenderList[i].status;
                // color = '#D4EFDF';
                if (calenderList[i].status === 'Cancelled') {
                  color = '#cccccc';
                } else if (calenderList[i].status === 'Completed') {
                  color = '#e6dab8';
                } else {
                  color = '#D4EFDF';
                }
              }
            } else if (calenderList[i].action === 'Incident') {
              if (calenderList[i].date !== null) {
                calenderDateTimeArr = Utils.UserTimeZoneToCalenderMonthWeakDateTimeFormate(calenderList[i].date);

                title = calenderList[i].type;
                incidentLocation = calenderList[i].location;

                time = Utils.calenderTimeZoneFormate(calenderList[i].date);

                time = Utils.calenderTimeZoneFormate(calenderList[i].date);
                action = calenderList[i].action;
                color = '#FADBD8';
              }
            } else if (calenderList[i].action === 'Event') {
              if (calenderList[i].date !== null) {
                calenderDateTimeArr = Utils.UserTimeZoneToCalenderMonthWeakDateTimeFormate(calenderList[i].date);

                title = calenderList[i].type;
                eventLocation = calenderList[i].location;

                time = Utils.calenderTimeZoneFormate(calenderList[i].date);
                action = calenderList[i].action;
                status = calenderList[i].status;
                color = '#D6EAF8';
              }
            }

            let calendarObj = {
              id: calenderList[i].id,
              title: title,
              time: time,
              doctorFacilityLocation: doctorFacilityLocation,
              incidentLocation: incidentLocation,
              start: calenderDateTimeArr,
              eventLocation: eventLocation,
              status: status,
              color: color,
              action: action,
            };
            console.log(
              calendarObj,

              'week'
            );
            events.push(calendarObj);
          }
          console.log(
            events,

            'week events'
          );
          this.setState({
            calendarItemList: events,
            calendarItemListData: calenderList,
          });
        } else {
        }
      })
      .catch((error) => {});
  };
  handleDateClick = (arg) => {
    alert(arg.dateStr);
  };

  /* On event click,here we get selected day appointment or incident by matching selected day date and date from calender event list*/
  handleDateSelect = (selectInfo) => {
    /* Click on date get the particular day Appointment and incident */
    let date = selectInfo.startStr;
    let appointmentDate = selectInfo.startStr;
    this.selectedDayCalenderList = [];
    let calenderAppointmentList = [];
    let dateTime = new Date(date);
    let incidentCompareDate = selectInfo.start;
    let calenderDate = moment(dateTime).format('DD MMM YYYY ');
    let calenderIncidentList = [];
    let eventDate = selectInfo.start;
    let calenderEventList = [];
    if (this.state.calendarItemListData) {
      for (let i = 0; i < this.state.calendarItemListData.length; i++) {
        if ('Appointment' === this.state.calendarItemListData[i].action) {
          let strtDate = this.state.calendarItemListData[i].date;
          let appointmentDate = Utils.calenderUserTimeZoneDateFormate(strtDate);

          if (date === appointmentDate) {
            calenderAppointmentList.push(this.state.calendarItemListData[i]);
          }
        } else if ('Incident' === this.state.calendarItemListData[i].action) {
          let strtDate = this.state.calendarItemListData[i].date;
          let incidentDate = Utils.calenderUserTimeZoneDateFormate(strtDate);

          if (date === incidentDate) {
            calenderIncidentList.push(this.state.calendarItemListData[i]);
          }
        } else if ('Event' === this.state.calendarItemListData[i].action) {
          let strtDate = this.state.calendarItemListData[i].date;
          let eventDate = Utils.calenderUserTimeZoneDateFormate(strtDate);

          if (date === eventDate) {
            calenderEventList.push(this.state.calendarItemListData[i]);
          }
        }
      }
    }
    this.setState({
      modal: true,
      startDate: selectInfo.startStr,
      appointmentList: this.selectedDayCalenderList,
      calenderDateView: calenderDate,
      calenderAppointmentList: calenderAppointmentList,
      calenderIncidentList: calenderIncidentList,
      calenderEventList: calenderEventList,
      appointmentDate: appointmentDate,
      incidentCompareDate: incidentCompareDate,
      eventDate: eventDate,
    });
  };

  /* On event click,here we get selected day appointment or incident by matching selected day date and date from calender event list
  and with help of selected day appointment ID or facility ID we navigate to selected appointment or facility
  */

  handleEventClickOnDay = (clickInfo) => {
    let date1 = clickInfo.event.start;
    let selectedAppointmentId = clickInfo.event.id;
    let date = moment(date1).format('YYYY-MM-DD');
    this.selectedDayCalenderList = [];
    let calenderAppointmentList = [];
    let calenderIncidentList = [];
    let calenderEventList = [];

    if ('Appointment' === clickInfo.event.extendedProps.action) {
      for (let i = 0; i < this.state.calendarItemListData.length; i++) {
        let appointmentDate = Utils.calenderUserTimeZoneDateFormate(this.state.calendarItemListData[i].date);
        if (date === appointmentDate) {
          let calendarState = {
            calenderNxtPrvMonth: moment(this.state.selectedMonth),
            selectedDate: moment(appointmentDate).format('DD/MMM/YYYY'),
            userSelectedMonth: appointmentDate,
            src: 'memberDetails',
          };
          store.dispatch(
            applicationStateAction.setApplicationState({
              calendarState,
            })
          );
          this.props.history.push({
            pathname: '/viewMemberAppointment',
            state: {
              action: 'view',
              title: 'Appointment Details',
              appointmentId: selectedAppointmentId,
              pageId: 'memberdeatils',
            },
          });
          calenderAppointmentList.push(this.state.calendarItemListData[i]);
        }
      }
    } else if ('Incident' === clickInfo.event.extendedProps.action) {
      for (let i = 0; i < this.state.calendarItemListData.length; i++) {
        let incidentDate = Utils.calenderUserTimeZoneDateFormate(this.state.calendarItemListData[i].date);

        if (date === incidentDate) {
          let calendarState = {
            calenderNxtPrvMonth: moment(this.state.selectedMonth),
            selectedMonth: this.state.selectedMonth,
            selectedDate: moment(incidentDate).format('DD/MMM/YYYY'),
            userSelectedMonth: incidentDate,
            src: 'memberDetails',
          };
          store.dispatch(
            applicationStateAction.setApplicationState({
              calendarState,
            })
          );
          this.props.history.push({
            pathname: '/viewIncident',
            state: {
              action: 'view',
              title: 'Incident Details',
              incidentId: selectedAppointmentId,
              pageId: 'memberdeatils',
            },
          });
          calenderIncidentList.push(this.state.calendarItemListData[i]);
        }
      }
    } else if ('Event' === clickInfo.event.extendedProps.action) {
      for (let i = 0; i < this.state.calendarItemListData.length; i++) {
        let eventDate = Utils.calenderUserTimeZoneDateFormate(this.state.calendarItemListData[i].date);

        if (date === eventDate) {
          let calendarState = {
            calenderNxtPrvMonth: moment(this.state.selectedMonth),
            selectedMonth: this.state.selectedMonth,
            selectedDate: moment(eventDate).format('DD/MMM/YYYY'),
            userSelectedMonth: eventDate,
            src: 'memberDetails',
          };
          store.dispatch(
            applicationStateAction.setApplicationState({
              calendarState,
            })
          );
          this.props.history.push({
            pathname: '/viewEvent',
            state: {
              action: 'view',
              title: 'Event Details',
              eventId: selectedAppointmentId,
              pageId: 'memberdeatils',
            },
          });
          calenderEventList.push(this.state.calendarItemListData[i]);
        }
      }
    }
  };
  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };
  showStatusColor(status) {
    let color = '';
    if (status === 'Accepted') {
      color = '#2ECC71';
    } else if (status === 'Requested') {
      color = '#D4AC0D';
    } else if (status === 'Rejected') {
      color = '#E74C3C';
    } else {
      color = '';
    }
    return color;
  }
  /* Here we customize the design of event which are displayed on month view,weekly view and day view*/
  renderEventContent(eventInfo) {
    let eventLocation = '';
    let event = '';
    if (eventInfo.event.extendedProps.action === 'Appointment') {
      eventLocation = eventInfo.event.extendedProps.doctorFacilityLocation;
      event = (
        <>
          <Tooltip title={eventInfo.event.title + ' ' + eventInfo.event.extendedProps.time + ' ' + eventLocation}>
            <span
              style={{
                borderLeft: '2px solid green',
                background: eventInfo.backgroundColor,
                width: '100%',
                color: '#000',
                padding: '0.3em',
              }}
            >
              <b>
                {' '}
                <i>{eventInfo.event.title}</i>
              </b>

              <br></br>
              <i>{eventInfo.event.extendedProps.time}</i>
              <i style={{ float: 'right' }}>{eventInfo.event.extendedProps.status}</i>
            </span>
          </Tooltip>
          <br></br>
        </>
      );
    } else if (eventInfo.event.extendedProps.action === 'Incident') {
      eventLocation = eventInfo.event.extendedProps.incidentLocation;
      event = (
        <>
          <Tooltip
            title={
              eventLocation
                ? eventInfo.event.title + ' ' + eventInfo.event.extendedProps.time + ' ' + eventLocation
                : eventInfo.event.title + ' ' + eventInfo.event.extendedProps.time
            }
          >
            <span
              style={{
                borderLeft: '2px solid #EC7063',
                background: '#FADBD8',
                width: '100%',
                color: '#000',
                padding: '0.3em',
              }}
            >
              <b>
                {' '}
                <i>
                  {eventInfo.event.extendedProps.action} - {eventInfo.event.title}
                </i>
                {/* <i style={{ float: 'right' }}>{eventInfo.event.extendedProps.time}</i> */}
              </b>
              <br></br>

              <i>{eventInfo.event.extendedProps.time}</i>
              {/* <i style={{ float: 'right' }}>{eventInfo.event.extendedProps.status}</i> */}
              <br></br>
            </span>
          </Tooltip>
          <br></br>
        </>
      );
    } else if (eventInfo.event.extendedProps.action === 'Event') {
      eventLocation = eventInfo.event.extendedProps.eventLocation;
      event = (
        <>
          <Tooltip title={eventInfo.event.title + ' ' + eventInfo.event.extendedProps.time + ' ' + eventLocation}>
            <span
              style={{
                borderLeft: '2px solid #2e78ac',
                background: eventInfo.backgroundColor,
                width: '100%',
                color: '#000',
                whiteSpace: 'normal',
                padding: '0.3em',
              }}
            >
              <b>
                {' '}
                <span>
                  <i>
                    {eventInfo.event.extendedProps.action} - {eventInfo.event.title}
                  </i>
                </span>
                {/* <span style={{ float: 'right' }}>{eventInfo.event.extendedProps.time}</span> */}
              </b>
              <br></br>

              <span>
                <i>{eventInfo.event.extendedProps.time}</i>
              </span>
              <h6 style={{ float: 'right' }}>
                <i>{eventInfo.event.extendedProps.status}</i>
              </h6>
            </span>
          </Tooltip>
          <br></br>
        </>
      );
    }

    return event;
  }
  eventRender(info) {
    if (info.action === 'Appointment') {
    } else {
    }
  }

  handleEvents = (events) => {
    this.setState({
      currentEvents: events,
    });
  };

  addAppointment = () => {
    let date = moment(this.state.appointmentDate).format('MM/DD/YYYY') + ' ' + moment().format('h:mm a');
    this.props.history.push({
      pathname: '/addAppointment',
      state: {
        action: 'create',
        title: 'Create Appointment',
        pageId: 'memberdeatils',
        startDate: date,
        appointmentId: '',
      },
    });
  };
  addIncident = (e) => {
    let date = moment(this.state.appointmentDate).format('MM/DD/YYYY') + ' ' + moment().format('h:mm a');
    this.props.history.push({
      pathname: '/addIncident',
      state: {
        action: 'create',
        title: 'Create Incident',
        pageId: 'memberdeatils',
        startDate: date,
        incidentId: '',
      },
    });
  };

  addEvent = (e) => {
    let date = moment(this.state.appointmentDate).format('MM/DD/YYYY') + ' ' + moment().format('h:mm a');
    this.props.history.push({
      pathname: '/addEvent',
      state: {
        action: 'create',
        title: 'Add Event',
        pageId: 'memberdeatils',
        eventId: '',
        startDate: date,
      },
    });
  };

  viewAppointment = (e, data) => {
    let appointmentId = data.id;
    this.props.history.push({
      pathname: '/viewMemberAppointment',
      state: { action: 'view', title: 'Appointment Details', appointmentId: appointmentId, pageId: 'memberdeatils' },
    });
  };

  viewIncident = (e, data) => {
    let incidentId = data.id;
    this.props.history.push({
      pathname: '/viewIncident',
      state: { action: 'view', title: 'Incident Details', incidentId: incidentId, pageId: 'memberdeatils' },
    });
  };
  viewEvent = (e, data) => {
    let eventId = data.id;
    this.props.history.push({
      pathname: '/viewEvent',
      state: { action: 'view', title: 'Event Details', eventId: eventId, pageId: 'memberdeatils' },
    });
  };
  closeFloatingButton() {
    this.setState({ displayFloatButton: false });
    let displayFloat = false;

    store.dispatch(helpDataAction.setHelpData(displayFloat));
  }
  render() {
    if (this.breadCrumb) {
      let userMenuArray = store.getState().selectedMemberData.selectedMemberData.menuList;
      for (let i = 0; i < userMenuArray.length; i++) {
        for (let j = 0; this.breadCrumb && j < this.breadCrumb.length; j++) {
          if (userMenuArray[i].pageid === this.breadCrumb[j].pageid.homeUrl) {
            store.dispatch(ActiveMenuAction.setActiveMenu(userMenuArray[i].id));
          }
        }
      }
      store.dispatch(BreadCrumbDetailsAction.setBreadCrumbDetails(this.breadCrumb));
    }
    return (
      <>
        <MenuWithBreadScrum breadCrumb={this.breadCrumb} history={this.props.history} name={this.title} />
        <Steps
          enabled={this.state.stepsEnabled}
          steps={this.state.steps}
          initialStep={this.state.initialStep}
          onExit={this.onExit}
        />
        <Row style={{ margin: '1em' }}>
          <Advertisement pageId="Calendar" />
        </Row>

        <Row style={{ margin: ' 1em -0em' }}>
          {/* <AddToCalendarButton
            name="Title"
            // options={['Apple', 'Google']}
            options="'Apple','Google','iCal','Outlook.com','Microsoft 365','Microsoft Teams','Yahoo'"
            location="World Wide Web"
            startDate="2023-09-21"
            endDate="2023-09-21"
            startTime="10:15"
            endTime="23:30"
            timeZone="America/Los_Angeles"
          ></AddToCalendarButton> */}
          <Col md={12}>
            <Card>
              <FullCalendar
                ref={this.calendarRef}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                  left: 'prev, today next',
                  center: 'title',
                  right: 'dayGridMonth, timeGridWeek, timeGridDay',
                }}
                initialView="dayGridMonth"
                editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                weekends={this.state.weekendsVisible}
                events={this.state.calendarItemList}
                initialDate={this.state.userSelectedDate}
                select={this.handleDateSelect}
                eventContent={this.renderEventContent} // custom render function
                eventClick={this.handleEventClickOnDay}
                eventsSet={this.handleEvents}
                eventRender={this.eventRender}
                eventReceive={() => {}}
              />

              {/* create Modal for Appointment */}
              <Modal isOpen={this.state.modal} toggle={this.toggle} size="xl">
                <ModalHeader toggle={this.toggle}>
                  <label> {this.state.calenderDateView}</label>
                </ModalHeader>
                <ModalBody>
                  {this.state.showAppointmentPermission === true && (
                    <Row style={{ marginTop: '0em' }}>
                      <Col md={12}>
                        <Row>
                          <Col md={9}>
                            <Label
                              style={{
                                fontWeight: '600',
                                color: '#162c50',
                                marginLeft: '0.5em',
                                textTransform: 'capitalize',
                              }}
                            >
                              Appointment For {this.memberName}
                            </Label>
                          </Col>
                          <Col md={3} style={{ textAlign: 'end' }}>
                            {this.state.getAppointmentCreatePermission === true && (
                              <div style={{ float: 'right', marginBottom: '1em', marginRight: '2em' }}>
                                <AddIconButton btnName="Add Appointment" onClick={(e) => this.addAppointment()} />
                              </div>
                            )}
                          </Col>
                        </Row>
                        {this.state.calenderAppointmentList.length > 0 ? (
                          <MaterialTable
                            icons={UiUtils.getMaterialTableIcons()}
                            title=""
                            localization={{
                              body: {
                                emptyDataSourceMessage: Utils.communizeTableText(),
                              },
                            }}
                            columns={[
                              {
                                title: '#',
                                render: (rowData) => rowData.tableData.id + 1,
                                filtering: false,
                              },

                              {
                                title: 'Date',
                                type: 'date',

                                render: (rowData) => <>{Utils.convertUTCDateToUserTimeZoneDate(rowData.date)}</>,
                                customSort: (a, b) => {
                                  let aTime = a.date !== null ? new Date(a.date).getTime() : '';
                                  let bTime = b.date !== null ? new Date(b.date).getTime() : '';
                                  return aTime - bTime;
                                },
                                align: 'left',
                                width: '20%',
                              },
                              { title: 'Type', field: 'type', width: '20%' },
                              {
                                title: 'Appointment',
                                field: 'doctorFacilityLocation',

                                width: '20%',
                              },
                              { title: 'Purpose', field: 'purpose', width: '20%' },

                              { title: 'Status', field: 'status', width: '20%' },
                            ]}
                            data={this.state.calenderAppointmentList}
                            options={{
                              actionsColumnIndex: -1,
                              headerStyle: {
                                backgroundColor: '#1ABC9C',
                                fontWeight: 'bold',
                                color: '#fff',
                                align: 'center',
                              },
                              rowStyle: { height: '50px' },
                              paging: false,
                              toolbar: false,
                            }}
                            actions={[
                              (rowData) => ({
                                icon: () =>
                                  rowData.hasReferenceAppointment === true ? (
                                    <AccountTreeIcon style={{ fontSize: '25px', color: '#2E86C1' }} />
                                  ) : (
                                    <div style={{ marginLeft: '1em' }}></div>
                                  ),
                                tooltip: 'Reference Appointment',
                              }),
                              (rowData) => ({
                                icon: () =>
                                  rowData.hasMessageThreads === true ? (
                                    <Badge badgeContent={rowData.unreadMessageCount} color="primary">
                                      <ChatIcon style={{ fontSize: '25px', color: '#58D68D' }} />
                                    </Badge>
                                  ) : (
                                    <div style={{ marginLeft: '1em' }}></div>
                                  ),
                                tooltip: 'Message',
                              }),

                              (rowData) => ({
                                icon: () =>
                                  rowData.hasReminders === true ? (
                                    <Badge badgeContent={rowData.activeReminderCount} color="primary">
                                      <AccessAlarmTwoToneIcon style={{ fontSize: '25px', color: '#EF797D' }} />
                                    </Badge>
                                  ) : (
                                    <div style={{ marginLeft: '1em' }}></div>
                                  ),
                                tooltip: 'Reminder',
                              }),
                              (rowData) => ({
                                icon: () =>
                                  rowData.transportationCount > 0 ? (
                                    <DriveEtaIcon
                                      style={{
                                        fontSize: '25px',
                                        color: this.showStatusColor(rowData.transportationStatus),
                                        marginLeft: '0.2em',
                                      }}
                                    />
                                  ) : (
                                    <div style={{ marginLeft: '1em' }}></div>
                                  ),
                                tooltip: 'Transportation',
                              }),
                            ]}
                            onRowClick={(event, rowData) => {
                              this.viewAppointment(event, rowData);
                              event.stopPropagation();
                            }}
                          />
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  )}

                  {/* create Modal for Incident */}
                  {this.state.showIncidentPermission === true && (
                    <Row style={{ marginTop: '2em' }}>
                      <Col md={12}>
                        {this.state.incidentCompareDate < this.state.currentCompareDate ? (
                          <Row>
                            <Col md={9}>
                              <Label
                                style={{
                                  fontWeight: '600',
                                  color: '#162c50',
                                  marginLeft: '0.5em',
                                  textTransform: 'capitalize',
                                }}
                              >
                                Incident For {this.memberName}
                              </Label>
                            </Col>
                            <Col md={3} style={{ textAlign: 'end' }}>
                              {this.state.getIncidentCreatePermission === true && (
                                <div style={{ float: 'right', marginBottom: '1em', marginRight: '2em' }}>
                                  <AddIconButton btnName="Add Incident" onClick={(e) => this.addIncident()} />
                                </div>
                              )}
                            </Col>
                          </Row>
                        ) : (
                          ''
                        )}
                        {this.state.calenderIncidentList.length > 0 ? (
                          <MaterialTable
                            icons={UiUtils.getMaterialTableIcons()}
                            title=""
                            localization={{
                              body: {
                                emptyDataSourceMessage: Utils.communizeTableText(),
                              },
                            }}
                            columns={[
                              { title: '#', render: (rowData) => rowData.tableData.id + 1, width: '5%' },
                              {
                                title: 'Date',
                                field: 'date',
                                render: (rowData) => <> {Utils.convertUTCDateToUserTimeZoneDate(rowData.date)}</>,
                                customSort: (a, b) => {
                                  let aTime = a.date !== null ? new Date(a.date).getTime() : '';
                                  let bTime = b.date !== null ? new Date(b.date).getTime() : '';
                                  return aTime - bTime;
                                },
                                align: 'left',
                              },
                              {
                                title: 'Type',

                                field: 'incidentType',
                              },
                              { title: 'Location', field: 'location', align: 'left' },
                            ]}
                            data={this.state.calenderIncidentList}
                            options={{
                              actionsColumnIndex: -1,
                              headerStyle: {
                                backgroundColor: '#1ABC9C',
                                fontWeight: 'bold',
                                color: '#fff',
                                align: 'center',
                              },
                              rowStyle: { height: '10px' },
                              paging: false,
                              toolbar: false,
                            }}
                            onRowClick={(event, rowData) => {
                              this.viewIncident(event, rowData);
                              event.stopPropagation();
                            }}
                          />
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  )}

                  {this.state.showEventPermission === true && (
                    <Row style={{ marginTop: '2em' }}>
                      <Col md={12}>
                        <Row>
                          <Col md={9}>
                            <Label
                              style={{
                                fontWeight: '600',
                                color: '#162c50',
                                marginLeft: '0.5em',
                                textTransform: 'capitalize',
                              }}
                            >
                              Event For {this.memberName}
                            </Label>
                          </Col>
                          <Col md={3} style={{ textAlign: 'end' }}>
                            {this.state.getEventCreatePermission === true && (
                              <div style={{ float: 'right', marginBottom: '1em', marginRight: '2em' }}>
                                <AddIconButton btnName="Add Event" onClick={(e) => this.addEvent()} />
                              </div>
                            )}
                          </Col>
                        </Row>

                        {this.state.calenderEventList.length > 0 ? (
                          <MaterialTable
                            icons={UiUtils.getMaterialTableIcons()}
                            title=""
                            localization={{
                              body: {
                                emptyDataSourceMessage: Utils.communizeTableText(),
                              },
                            }}
                            columns={[
                              {
                                title: '#',
                                render: (rowData) => rowData.tableData.id + 1,
                                filtering: false,
                              },
                              {
                                title: 'Date',
                                type: 'date',
                                field: 'date',
                                filtering: false,
                                width: '20%',
                                customSort: (a, b) => {
                                  let aTime = a.date !== null ? new Date(a.date).getTime() : '';
                                  let bTime = b.date !== null ? new Date(b.date).getTime() : '';
                                  return aTime - bTime;
                                },

                                align: 'left',
                              },
                              { title: 'Event Type', field: 'type', width: '20%' },
                              { title: 'Location', field: 'description', width: '20%' },
                              { title: 'Status', field: 'status', width: '20%' },
                            ]}
                            data={this.state.calenderEventList}
                            options={{
                              sorting: true,
                              actionsColumnIndex: -1,
                              headerStyle: {
                                backgroundColor: '#1ABC9C',
                                fontWeight: 'bold',
                                color: '#fff',
                                align: 'center',
                              },
                              paging: this.state.isScheduledEventListPaging,
                              pageSize: this.state.pageSize,
                              initialPage: this.state.initialPage,
                              filtering: true,
                            }}
                            actions={[
                              (rowData) => ({
                                icon: () =>
                                  /*if appointment has message threads then show then button */
                                  rowData.hasMessageThreads === true ? (
                                    <Badge badgeContent={rowData.unreadMessageCount} color="primary">
                                      <ChatIcon
                                        className="hasMessageThreads"
                                        style={{ fontSize: '25px', color: '#58D68D', marginRight: '0.2em' }}
                                      />
                                    </Badge>
                                  ) : (
                                    <div style={{ marginLeft: '1em' }}></div>
                                  ),
                                tooltip: 'Message',
                              }),
                              (rowData) => ({
                                icon: () =>
                                  /*if appointment has active Reminder  then show then button */
                                  rowData.hasReminders === true ? (
                                    <Badge badgeContent={rowData.activeReminderCount} color="primary">
                                      <AccessAlarmTwoToneIcon
                                        className="reminder"
                                        style={{ fontSize: '25px', color: '#EF797D' }}
                                      />
                                    </Badge>
                                  ) : (
                                    <div style={{ marginLeft: '1em', fontSize: '25px' }}></div>
                                  ),
                                tooltip: 'Reminder',
                              }),
                              (rowData) => ({
                                /*if appointment has transportation  then show then button */
                                icon: () =>
                                  rowData.transportationCount > 0 ? (
                                    <DriveEtaIcon
                                      className="transportation"
                                      style={{
                                        fontSize: '25px',
                                        color: this.showStatusColor(rowData.transportationStatus),
                                      }}
                                    />
                                  ) : (
                                    <div style={{ marginLeft: '1em', fontSize: '25px' }}></div>
                                  ),
                                tooltip: 'Transportation',
                              }),
                            ]}
                            onRowClick={(event, rowData) => {
                              this.viewEvent(event, rowData);
                              event.stopPropagation();
                            }}
                          />
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  )}
                </ModalBody>
              </Modal>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
