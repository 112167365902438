import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Fab from '@material-ui/core/Fab';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import CloseIcon from '@material-ui/icons/Close';
import PrintIcon from '@material-ui/icons/Print';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { format } from 'date-fns';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Card, CardBody, CardFooter, CardHeader, Col, Modal, ModalBody, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import PrintIconButton from '../buttons/PrintIconButton';
import PtsAlert from '../ptsAlert/PtsAlert';
import TermsConditions from './TermsConditions';
import debounce from 'lodash.debounce';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PasswordRequirementsTooltip from '../passwordRequirementsTooltip/passwordRequirementsTooltip';
import TermsCondition from '../termsCondition/TermsCondition';
import PrivacyPolicy from '../privacyPolicy/PrivacyPolicy';
const ct = require('countries-and-timezones');
// import VisibilityIcon from '@material-ui/icons/Visibility';
// import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
export default class Registration extends Component {
  addressRef = React.createRef();
  countryOptionList = [];
  stateOptionList = [];
  stateListArray = [];
  timeZoneListArray = [];
  // countryListArray = [];
  constructor(props) {
    super(props);

    let str = window.location.href;
    let url = '';
    let urlArr = '';
    let urlEmail = '';
    let visitorUrlCode = '';
    let newVisitorCode = '';
    if (str !== undefined) {
      url = str.split('=');
      if (url.length > 1) {
        urlArr = str.split('?');
        urlEmail = urlArr[1].split('=');
        visitorUrlCode = urlEmail[1];
        newVisitorCode = visitorUrlCode.replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, '');
      }
    }
    let referralCodeStr = window.location.href;
    let refUrl = '';
    let refUrlArr = '';
    let refUrlCode = '';
    let newRefCode = '';

    if (referralCodeStr !== undefined) {
      refUrl = referralCodeStr.split('=');
      if (refUrl.length > 1) {
        refUrlArr = referralCodeStr.split('?');
        urlEmail = refUrlArr[1].split('=');
        newRefCode = urlEmail[1];
      }
    }

    let timeZone = moment.tz.guess();
    // let timeZone = 'America/New_York';
    let newTimeZone = '';
    if (timeZone === 'Asia/Calcutta') {
      newTimeZone = 'Asia/Kolkata';
    } else {
      newTimeZone = timeZone;
    }

    this.state = {
      userId: '',
      email: '',
      password: '',
      fname: '',
      lName: '',
      phone: '',
      render: true,
      address: {},
      address1: {},
      selectPlanType: {},
      dob: format(new Date(), 'yyyy-MM-dd'),
      checkbox: false,
      planList: [],
      termsConditionModal: false,
      privacyPolicyModal: false,
      visitorCode: newVisitorCode,
      referralCode: newRefCode,
      showConfirmPassword: false,
      confirmPassword: '',
      error: {
        one: true,
        two: true,
        three: true,
        four: true,
        five: true,
      },
      passwordFocussed: false,
      passwordHelperText: '',
      confirmPasswordHelperText: '',
      country: null,
      state: null,
      timeZone: null,
      countryList: [],
      defaultTimeZone: newTimeZone,
      stateList: [],
      timeZoneList: [],
    };
    this.getCountryList();
  }
  handleClickCheckBox = (e) => {
    if (e.target.checked === 'true') {
      this.setState({
        checkbox: e.target.checked,
        isDisabled: false,
      });
    } else {
      this.setState({
        checkbox: e.target.checked,
        isDisabled: true,
      });
    }
  };

  visitor(newVisitorCode) {
    // make API call
    const postObject = {
      visitorCode: newVisitorCode,
    };
    FetchServerData.callPostService('visitorms/create', postObject)
      .then((response) => {
        let output = response;
        if (output.status === 'SUCCESS') {
          this.props.history.push({
            pathname: '/createAccount',
          });
        } else {
        }
      })
      .catch((error) => {});
  }
  getCountryList() {
    // let timeZone = 'America/New_York';
    let timeZone = moment.tz.guess();
    let newTimeZone = '';
    if (timeZone === 'Asia/Calcutta') {
      newTimeZone = 'Asia/Kolkata';
    } else {
      newTimeZone = timeZone;
    }
    const defaultBrowserCountry = ct.getCountriesForTimezone(newTimeZone);
    // make API call
    const postObject = {};
    FetchServerData.callPostService('/countryms/getAll', postObject)
      .then((response) => {
        let output = response;
        if (output.status === 'SUCCESS') {
          let staticData = output.data;
          this.countryListArray = output.data;
          this.setState({
            render: true,
            // country: this.selectedCountry,
            countryList: this.countryListArray,
          });
          for (let i = 0; i < this.countryListArray.length; i++) {
            for (let j = 0; j < defaultBrowserCountry.length; j++) {
              let defaultBrowserCountryName = '';
              if (defaultBrowserCountry[j].name === 'United States of America') {
                defaultBrowserCountryName = 'United States';
              } else {
                defaultBrowserCountryName = defaultBrowserCountry[j].name;
              }
              if (this.countryListArray[i].name === defaultBrowserCountryName) {
                this.selectedCountry = {
                  namecode: this.countryListArray[i].namecode,
                  name: this.countryListArray[i].name,
                  code: this.countryListArray[i].code,
                  isoCode: this.countryListArray[i].isoCode,
                  description: this.countryListArray[i].description,
                  id: this.countryListArray[i].id,
                };
                // this.setStatesForCountry(this.selectedCountry);
                if (this.selectedCountry !== null && undefined !== this.selectedCountry) {
                  this.handleCountryChange(this.selectedCountry);
                } else {
                }
                this.setState({
                  render: true,
                  country: this.selectedCountry,
                  countryList: this.countryListArray,
                });
              }
            }
          }
        } else {
        }
      })
      .catch((error) => {});
  }
  setStatesForCountry(selectedCountry) {
    this.setState({ country: selectedCountry, selectedCountry: selectedCountry });
  }
  handleEmailChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ email: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }

  handlePasswordChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 256);

    if (res.length < 256) {
      this.setState({ password: res });
    } else {
      Utils.maxFieldLength(256);
    }
  }

  handleFirstNameChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 128);
    if (res.length < 128) {
      this.setState({ fname: res });
    } else {
      Utils.maxFieldLength(128);
    }
  }

  handleLastNameChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 128);
    if (res.length < 128) {
      this.setState({ lName: res });
    } else {
      Utils.maxFieldLength(128);
    }
  }

  handlePhoneChange({ target: { value } }) {
    this.setState((prevState) => ({
      phone: Utils.convertPhoneNumberToUsaPhoneNumberFormate(value, prevState.phone),
    }));
  }
  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  searchExistingEmail() {
    const postObject = {
      header: this.header,
      registration: {
        email: this.state.email,
      },
    };
    if (!Utils.isNullOrEmpty(this.state.email)) {
      FetchServerData.callPostService('regms/findRegistrationByEmail', postObject).then((output) => {
        if (output.status === 'SUCCESS') {
        } else {
        }
      });
    }
  }

  searchExistingPhone() {
    const postObject = {
      header: this.header,
      registration: {
        phone: this.state.phone,
      },
    };
  }

  formValidation(e) {
    if (this.addressRef && this.addressRef.current) {
      if (this.addressRef.current.validateAddress() === true) {
        this.createAccountInBackend(this.addressRef.current.state);
      }
    }
  }

  createAccountInBackend = (e) => {
    let mobile = this.state.phone.replace(/[^\d]/g, '');
    let timeZone = moment.tz.guess();
    let newTimeZone = '';
    if (timeZone === 'Asia/Calcutta') {
      newTimeZone = 'Asia/Kolkata';
    } else {
      newTimeZone = timeZone;
    }
    if (this.state.password !== this.state.confirmPassword) {
      PtsAlert.error('Password & confirm password must match');
    } else {
      e.preventDefault();
      const postObject = {
        registration: {
          firstName: this.state.fname,
          lastName: this.state.lName,
          phone: mobile,
          email: this.state.email,
          credential: this.state.password,
          userTimezone: this.state.defaultTimeZone,
          referralCode: this.state.referralCode,
          address: {
            state: { id: this.state.state.id },
          },
        },
      };

      FetchServerData.callPostService('/regms/create', postObject)
        .then((response) => {
          let output = response;
          if (output.status === 'SUCCESS') {
            PtsAlert.success(output.message);
            this.props.history.push({
              pathname: '/verification',
              state: { email: this.state.email, url: '/createAccount' },
            });
          } else {
          }
        })
        .catch((error) => {});
    }
  };

  goToPage(pageid) {
    if (pageid && pageid.length > 0) {
      this.props.history.push({
        pathname: pageid,
        state: {},
      });
    }
  }

  backToHome() {
    this.props.history.push({
      pathname: '/',
    });
  }

  printContent() {
    let printContents = document.getElementById('policySection').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();

    document.body.innerHTML = originalContents;
    this.setState({ render: true });
    document.location.reload(true);
  }

  termsConditionToggle = () => {
    this.setState({
      termsConditionModal: !this.state.termsConditionModal,
    });
  };
  openTermsCondition() {
    this.setState({
      termsConditionModal: true,
    });
  }

  openPrivacyPolicy() {
    this.setState({
      privacyPolicyModal: true,
    });
  }

  privacyPolicyToggle = () => {
    this.setState({
      privacyPolicyModal: !this.state.privacyPolicyModal,
    });
  };
  createCancel() {
    window.location.href = '/home.html';
  }
  handleClickShowConfirmPassword() {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  }
  toggleFocusNewPassword = () => {
    this.setState((prevState) => {
      return { passwordFocussed: !prevState.passwordFocussed };
    });
  };

  handlePasswordChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    if (name === 'password') {
      this.debouncedValidatePassword(value);
    } else if (name === 'confirmPassword') {
      this.debouncedValidatePassword(this.state.password);
    }
  };

  debouncedValidatePassword = debounce((password) => this.validatePassword(password), 1000);
  validatePassword = (password) => {
    const { confirmPassword } = this.state;
    let one = false,
      two = false,
      three = false,
      four = false,
      five = false,
      confirmPasswordHelperText = '';

    if (password.length < 8 || password.length > 16) {
      one = true;
    }
    if (!password.match(/[A-Z]/)) {
      two = true;
    }
    if (!password.match(/[a-z]/)) {
      three = true;
    }
    if (!password.match(/[\d]/)) {
      four = true;
    }
    if (!password.match(/[\W]/)) {
      five = true;
    }

    if (password !== confirmPassword) {
      confirmPasswordHelperText = 'Password & confirm password must match';
    }

    if (one || two || three || four || five) {
      this.setState({
        error: { one: one, two: two, three: three, four: four, five: five },
        confirmPasswordHelperText: confirmPasswordHelperText,
        passwordHelperText: 'Password requirements not met',
      });
      return;
    }

    this.setState({
      disable: false || password !== confirmPassword,
      error: {},
      confirmPasswordHelperText: confirmPasswordHelperText,
      passwordHelperText: '',
    });
  };

  handleCountryChange(selectedCountry) {
    if (null === selectedCountry || selectedCountry === '') {
      this.stateListArray = [];
      this.timeZoneListArray = [];
      this.setState({
        stateClear: !this.state.stateClear,
      });
      return;
    }
    this.state.country = selectedCountry;
    if (undefined !== this.onChange && null !== this.onChange) {
      this.onChange(this.state);
    }

    this.setState({
      country: selectedCountry,
      state: '',
      timeZone: null,
    });

    const postObject = {
      country: { id: selectedCountry.id },
    };

    let url = '/timezonems/getForCountry';
    FetchServerData.callPostService(url, postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let stateTimezoneList = output.data;
        this.stateListArray = stateTimezoneList.stateList;
        this.timeZoneListArray = stateTimezoneList.timeZoneList;
        this.setState({
          render: true,
          // timeZone: this.selectedTimeZone,
          stateList: this.stateListArray,
          timeZoneList: this.timeZoneListArray,
        });
        for (let i = 0; i < this.timeZoneListArray.length; i++) {
          if (this.timeZoneListArray[i].name === this.state.defaultTimeZone) {
            this.selectedTimeZone = {
              name: this.timeZoneListArray[i].name,
              isoAlpha2: this.timeZoneListArray[i].isoAlpha2,
              abbreviation: this.timeZoneListArray[i].abbreviation,
              id: this.timeZoneListArray[i].id,
            };
            this.setState({
              render: true,
              defaultTimeZone: this.timeZoneListArray[i].name,
              timeZone: this.selectedTimeZone,
              stateList: this.stateListArray,
              timeZoneList: this.timeZoneListArray,
            });
          }
        }
      } else {
      }
    });
  }
  handleStateChange(selectedState) {
    this.state.state = selectedState;
    this.setState({ state: selectedState });
    if (undefined !== this.onChange && null !== this.onChange) {
      this.onChange(this.state);
    }
  }

  handleTimeZoneChange(selectedTimezone) {
    this.state.timeZone = selectedTimezone;
    this.setState({ timeZone: selectedTimezone, defaultTimeZone: selectedTimezone.name });
    if (undefined !== this.onChange && null !== this.onChange) {
      this.onChange(this.state);
    }
  }
  render() {
    if (this.state.render === false) {
      return <></>;
    }
    const { error, passwordFocussed } = this.state;
    return (
      <>
        <div md={12} style={{ padding: '1em', display: 'flex', margin: '2em' }}>
          <Card
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              margin: 'auto',
            }}
          >
            <CardHeader>
              <span style={{ color: '#169ba2', fontSize: 'large' }}>
                <b>Family Care Circle Registration</b>
              </span>
            </CardHeader>

            <ValidatorForm
              ref="form"
              onSubmit={this.createAccountInBackend.bind(this)}
              style={{ width: '100%', padding: '1em' }}
            >
              <CardBody>
                <Row md={12}>
                  <Col md={6}>
                    <TextField
                      variant="outlined"
                      autoFocus
                      required
                      fullWidth
                      id="email"
                      type="email"
                      inputProps={{ maxLength: 64 }}
                      onChange={this.handleEmailChange.bind(this)}
                      onBlur={this.searchExistingEmail.bind(this)}
                      label="Email Address"
                      name="email"
                      value={this.state.email}
                      autoComplete="off"
                      margin="normal"
                    />
                  </Col>
                  <Col md={6}>
                    <TextField
                      variant="outlined"
                      fullWidth
                      type="tel"
                      value={this.state.phone}
                      onChange={this.handlePhoneChange.bind(this)}
                      onBlur={this.searchExistingPhone.bind(this)}
                      id="phone"
                      label="Phone"
                      name="phone"
                      autoComplete="off"
                      margin="normal"
                    />
                  </Col>
                </Row>
                <Row md={12}>
                  <Col md={6}>
                    <TextField
                      name="firstName"
                      variant="outlined"
                      value={this.state.fname}
                      onChange={this.handleFirstNameChange.bind(this)}
                      inputProps={{ maxLength: 128, style: { textTransform: 'capitalize' } }}
                      required
                      fullWidth
                      id="firstName"
                      label="First Name"
                      autoComplete="off"
                      margin="normal"
                    />
                  </Col>
                  <Col md={6}>
                    <TextField
                      name="lastName"
                      variant="outlined"
                      value={this.state.lName}
                      onChange={this.handleLastNameChange.bind(this)}
                      inputProps={{ maxLength: 128, style: { textTransform: 'capitalize' } }}
                      required
                      fullWidth
                      id="lastName"
                      label="Last Name"
                      autoComplete="off"
                      margin="normal"
                    />
                  </Col>
                </Row>
                <Row md={12}>
                  <Col md={6}>
                    <TextField
                      variant="outlined"
                      // autoComplete="off"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      inputProps={{ maxLength: 256 }}
                      label="Password"
                      type={this.state.showPassword ? 'text' : 'password'}
                      id="password"
                      autoComplete="new-password"
                      onChange={this.handlePasswordChange.bind(this)}
                      value={this.state.password}
                      onFocus={this.toggleFocusNewPassword}
                      onBlur={this.toggleFocusNewPassword}
                      helperText={this.state.passwordHelperText}
                      error={this.state.passwordHelperText !== ''}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword.bind(this)}
                            >
                              {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                    {this.state.passwordHelperText ? (
                      <PasswordRequirementsTooltip
                        error={error}
                        open={passwordFocussed}
                        style={{ float: 'right', marginTop: '-1em' }}
                      />
                    ) : (
                      ''
                    )}{' '}
                  </Col>
                  <Col md={6}>
                    {' '}
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      value={this.state.confirmPassword}
                      name="confirmPassword"
                      placeholder="Confirm Password "
                      autoComplete="new-password"
                      type={this.state.showConfirmPassword ? 'text' : 'password'}
                      id="confirmPassword"
                      helperText={this.state.confirmPasswordHelperText}
                      error={this.state.confirmPasswordHelperText !== ''}
                      onChange={this.handlePasswordChange.bind(this)}
                      InputProps={{
                        // <-- This is where the toggle button is added.
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowConfirmPassword.bind(this)}
                            >
                              {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Col>
                </Row>
                <Row className="editAddressRow">
                  <Col md={6}>
                    <Autocomplete
                      id="country"
                      options={this.state.countryList}
                      value={this.state.country}
                      defaultValue={this.state.country}
                      getOptionLabel={(option) => option.namecode}
                      onChange={(event, value) => {
                        this.handleCountryChange(value);
                      }}
                      inputProps={{ style: { textTransform: 'capitalize' } }}
                      renderInput={(params) => (
                        <TextField {...params} label="Country" variant="outlined" required margin="normal" />
                      )}
                    />
                  </Col>

                  <Col md={6}>
                    <Autocomplete
                      id="state"
                      options={this.state.stateList}
                      value={this.state.state}
                      getOptionLabel={(option) => option.namecode}
                      defaultValue={this.state.state}
                      onChange={(event, value) => {
                        this.handleStateChange(value);
                      }}
                      inputProps={{ style: { textTransform: 'capitalize' } }}
                      renderInput={(params) => (
                        <TextField {...params} label="State" variant="outlined" required margin="normal" />
                      )}
                    />
                  </Col>
                </Row>
                <Row className="editAddressRow">
                  <Col md={6}>
                    <Autocomplete
                      id="timeZone"
                      required
                      options={this.state.timeZoneList}
                      value={this.state.timeZone}
                      getOptionLabel={(option) => option.name + ' (' + option.abbreviation + ')'}
                      defaultValue={this.state.timeZone}
                      onChange={(event, value) => {
                        this.handleTimeZoneChange(value);
                      }}
                      inputProps={{ style: { textTransform: 'capitalize' } }}
                      renderInput={(params) => (
                        <TextField {...params} label="Time Zone" variant="outlined" required margin="normal" />
                      )}
                    />
                  </Col>
                </Row>
                <Row>
                  <span style={{ color: '#162c50', margin: '1.1em 1em' }}>
                    {' '}
                    <Checkbox
                      style={{ color: 'black' }}
                      checked={this.state.checkbox}
                      onChange={this.handleClickCheckBox}
                    />
                    &nbsp; I accept the{' '}
                    <a onClick={(e) => this.openTermsCondition()} style={{ color: '#007bff', cursor: 'pointer' }}>
                      Terms & Conditions{' '}
                    </a>
                    and{' '}
                    <a onClick={(e) => this.openPrivacyPolicy()} style={{ color: '#007bff', cursor: 'pointer' }}>
                      {' '}
                      Privacy Policy
                    </a>
                    .
                  </span>
                </Row>
                <Row md={12}>
                  <Col md={12} style={{ textAlign: 'center' }}>
                    {this.state.checkbox &&
                    this.state.checkbox === true &&
                    this.state.password === this.state.confirmPassword ? (
                      <Button
                        type={this.state.type ? this.state.type : ''}
                        variant="contained"
                        size="small"
                        style={{ background: '#ef6603', color: '#fff', marginRight: '1rem' }}
                      >
                        Register for Free
                      </Button>
                    ) : (
                      <Button
                        type={this.state.type ? this.state.type : ''}
                        variant="contained"
                        size="small"
                        style={{ background: '#ef6603', color: '#fff', marginRight: '1rem', opacity: '0.5' }}
                        disabled
                      >
                        Register for Free
                      </Button>
                    )}{' '}
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <Row md={12} style={{ marginTop: '0.5em' }}>
                  <Col md={12}>
                    <span style={{ float: 'left', color: 'black' }}>
                      <Link
                        style={{ color: 'blue', fontWeight: '400', cursor: 'pointer' }}
                        onClick={(e) => this.backToHome()}
                      >
                        <ArrowBackIcon />
                        Back to home
                      </Link>
                    </span>
                    <span style={{ float: 'right', color: 'black' }}>
                      Already have an account?&nbsp;
                      <Link
                        style={{ color: 'blue', fontWeight: '400', cursor: 'pointer' }}
                        onClick={(e) => this.goToPage('/login')}
                      >
                        Sign In
                      </Link>
                    </span>
                  </Col>
                </Row>
              </CardFooter>
            </ValidatorForm>
          </Card>
          <Modal isOpen={this.state.termsConditionModal} toggle={this.termsConditionToggle} size="xl">
            <Row style={{ marginTop: '1em' }}>
              <Col md={11}></Col>
              <Col md={1}>
                <Fab
                  aria-label="add"
                  style={{
                    float: 'left',
                    fontSize: 'small',
                    height: '15px',
                    width: '35px',
                    background: '#76767d',
                    cursor: 'pointer',
                    color: 'white',
                    backgroundImage: '-webkit-linear-gradient(bottom, #843b07 0%, #f7b571 100%);',
                    border: '2px solid #edf0f1',
                  }}
                >
                  <CloseIcon onClick={(e) => this.termsConditionToggle()} />
                </Fab>
              </Col>
            </Row>
            <ModalBody>
              {/* <Row md={12} style={{ margin: '1em 1em 1em 0em' }}>
                <Col style={{ textAlign: 'center' }}>
                  <span style={{ color: '#169ba2', fontSize: 'large', width: '100%', textAlign: 'center' }}>
                    <b>Family Care Circle - Registration</b>
                  </span>
                </Col>
              </Row> */}

              <TermsCondition pageId="registration" />
            </ModalBody>
          </Modal>{' '}
          <Modal isOpen={this.state.privacyPolicyModal} toggle={this.privacyPolicyToggle} size="xl">
            <Row style={{ marginTop: '1em' }}>
              <Col md={11}></Col>
              <Col md={1}>
                <Fab
                  aria-label="add"
                  style={{
                    float: 'left',
                    fontSize: 'small',
                    height: '15px',
                    width: '35px',
                    background: '#76767d',
                    cursor: 'pointer',
                    color: 'white',
                    backgroundImage: '-webkit-linear-gradient(bottom, #843b07 0%, #f7b571 100%);',
                    border: '2px solid #edf0f1',
                  }}
                >
                  <CloseIcon onClick={(e) => this.privacyPolicyToggle()} />
                </Fab>
              </Col>
            </Row>
            <ModalBody>
              <section
                id="policySection"
                style={{ height: '100vh', overflowY: 'scroll', padding: '3em 2em 2em 2em', background: '#fff' }}
              >
                <CardBody
                  id="printform"
                  style={{ marginTop: '1em', background: '#fff', border: '2px solid lightgray' }}
                >
                  <PrivacyPolicy pageId="registration" />
                  <Row>
                    <Col md={12}>
                      <span style={{ float: 'right' }}>
                        <a onClick={() => this.scrollToSection('policySection')} style={{ textDecoration: 'none' }}>
                          <Fab
                            aria-label="add"
                            style={{
                              float: 'right',
                              fontSize: 'small',
                              height: '15px',
                              width: '35px',
                              background: '#ef6603',
                              cursor: 'pointer',
                              color: 'white',
                              backgroundImage: '-webkit-linear-gradient(bottom, #843b07 0%, #f7b571 100%);',
                              border: '2px solid #edf0f1',
                            }}
                          >
                            <ArrowUpwardIcon />
                          </Fab>{' '}
                        </a>
                      </span>
                    </Col>
                  </Row>
                </CardBody>
              </section>
            </ModalBody>
          </Modal>{' '}
        </div>
      </>
    );
  }
}
