import { CLEAR_FCC_ADDRESS_STATE, GET_FCC_ADDRESS_STATE, SET_FCC_ADDRESS_STATE } from './fccAddressStateType';

const getFccAddressState = () => {
  return {
    type: GET_FCC_ADDRESS_STATE,
  };
};

const setFccAddressState = (data) => {
  return {
    type: SET_FCC_ADDRESS_STATE,
    payload: data,
  };
};

const clearFccAddressState = (data) => {
  return {
    type: CLEAR_FCC_ADDRESS_STATE,
    payload: data,
  };
};
const fccAddressStateAction = { getFccAddressState, setFccAddressState, clearFccAddressState };
export default fccAddressStateAction;
