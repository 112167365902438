import MaterialTable from '@material-table/core';
import Badge from '@material-ui/core/Badge';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AccessAlarmTwoToneIcon from '@material-ui/icons/AccessAlarmTwoTone';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ChatIcon from '@material-ui/icons/Chat';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import Utils from '../../../provider/Utils';
import consolidatedMemberAction from '../../../redux/consolidatedMemberDetails/consolidatedMemberAction';
import fccPageStateAction from '../../../redux/fccPageState/fccPageStateAction';
import memberDataAction from '../../../redux/memberData/memberDataAction';
import store from '../../../redux/store';
import Tooltip from '@material-ui/core/Tooltip';
import './CalenderEventCard.scss';
class CalenderEventCard extends Component {
  constructor(props) {
    super(props);
    let timeZone = '';
    if (store.getState().userData.userData.address !== null) {
      timeZone = store.getState().userData.userData.address.timezone;
    } else {
      timeZone = '';
    }
    this.state = {
      memberActivityDetails: props.memberActivityDetails ? props.memberActivityDetails : [],
      timeZone: timeZone,
    };
  }

  changeRowColor(data) {
    let color = '';
    if (data.type === 'Event') {
      color = '#E4F4FF';
    } else if (data.type === 'Incident') {
      color = '#F9E9E7';
    } else if (data.type === 'Doctor Appointment') {
      color = '#F9F0D5';
    } else if (data.type === 'Facility Appointment') {
      color = '#E1FBEC';
    }
    return color;
  }
  render() {
    if (this.props.consolidatedView === 'dayView') {
      return this.dayViewCard();
    } else if (this.props.consolidatedView === 'weekView') {
      return this.WeekViewCard();
    }
  }
  displayActionTitle() {
    return <h5 className="doctorAppointmentAction">Action</h5>;
  }

  showStatusColor(status) {
    let color = '';
    if (status === 'Accepted') {
      color = '#2ECC71';
    } else if (status === 'Requested') {
      color = '#D4AC0D';
    } else if (status === 'Rejected') {
      color = '#E74C3C';
    } else {
      color = '';
    }
    return color;
  }
  showTooltip(rowData) {
    let tooltip = '';
    if (rowData.transportationStatus === 'Accepted') {
      tooltip = rowData.accompanyname ? 'Transportation - ' + rowData.accompanyname : '';
    } else {
      tooltip = 'Transportation';
    }
    return tooltip;
  }
  showDate(date) {
    let timeZone = '';
    let tz = moment.tz.guess();
    let defaultTimeZone = moment().tz(tz).format('z');
    if (this.state.timeZone) {
      timeZone = '(' + this.state.timeZone.abbreviation + ')';
    } else {
      timeZone = '(' + defaultTimeZone + ')';
    }
    let Date = '';
    Date = moment(date).format('DD MMM YYYY hh:mm a') + ' ' + timeZone;
    return Date;
  }
  WeekViewCard() {
    return (
      <div id="weekTableBody">
        <MaterialTable
          // icons={UiUtils.getMaterialTableIcons()}

          title=""
          columns={[
            {
              title: 'Date',
              type: 'date',
              render: (rowData) => this.showDate(rowData.date),

              align: 'left',
              width: '17%',
            },
            { title: 'Member', width: '12%', field: 'membername' },
            { title: 'type', width: '17%', field: 'type' },
            {
              title: 'Purpose',
              field: 'purpose',
              width: '18%',
            },
            { title: 'Address', field: 'address', width: '27%' },

            {
              title: 'Status',
              field: 'status',
              width: '9%',
              headerStyle: {
                textAlign: 'center',
              },
            },
          ]}
          data={this.props.memberActivityDetails}
          localization={{
            header: {
              actions: this.displayActionTitle(),
            },
            body: {
              emptyDataSourceMessage: 'No Details',
            },
          }}
          options={{
            headerStyle: {
              backgroundColor: '#B2BABB',
              fontWeight: 'bold',
              border: '1px solid #fff',
            },
            sorting: true,
            toolbar: false,
            actionsColumnIndex: -1,
            paging: false,
            rowStyle: (rowData) => ({
              backgroundColor: this.changeRowColor(rowData),
              height: '3em',
            }),
          }}
          onRowClick={(event, rowData) => {
            this.toWeekViewRowDetails(event, rowData);
            event.stopPropagation();
          }}
          actions={[
            (rowData) => ({
              /*if appointment has Reference Appointment then show then button */
              icon: () =>
                rowData.hasReference === true ? (
                  <>
                    <AccountTreeIcon className="referenceAppointment" style={{ fontSize: '25px', color: '#2E86C1' }} />
                  </>
                ) : (
                  <div style={{ marginLeft: '1.1em', fontSize: '1.5rem' }}></div>
                ),
              tooltip: 'Reference Appointment',
            }),
            (rowData) => ({
              icon: () =>
                /*if appointment has message threads then show then button */
                rowData.hasNotes === true ? (
                  <>
                    <Badge badgeContent={rowData.unreadMessageCount} color="primary" overlap="rectangular">
                      <ChatIcon
                        className="message"
                        style={{ fontSize: '25px', color: '#58D68D', marginRight: '0.2em' }}
                      />
                    </Badge>
                    &nbsp;
                  </>
                ) : (
                  <div style={{ marginLeft: '1.3em', fontSize: '1.5rem' }}></div>
                ),
              tooltip: 'Message',
            }),

            (rowData) => ({
              icon: () =>
                /*if appointment has active Reminder  then show then button */
                rowData.hasReminders === true ? (
                  <>
                    <Badge badgeContent={rowData.activeReminderCount} color="primary" overlap="rectangular">
                      <AccessAlarmTwoToneIcon className="reminder" style={{ fontSize: '25px', color: '#EF797D' }} />
                    </Badge>
                    &nbsp;
                  </>
                ) : (
                  <div style={{ marginLeft: '1.3em', fontSize: '1.5rem' }}></div>
                ),
              tooltip: 'Reminder',
            }),
            (rowData) => ({
              icon: () =>
                /*if appointment has transportation  then show then button */
                rowData.hasTransportation === true ? (
                  <>
                    <DriveEtaIcon
                      className="transportation"
                      style={{
                        fontSize: '25px',
                        color: this.showStatusColor(rowData.transportationStatus),
                        marginLeft: '0.2em',
                      }}
                    />
                    &nbsp;
                  </>
                ) : (
                  <div style={{ marginLeft: '1em' }}></div>
                ),
              tooltip: this.showTooltip(rowData),
            }),
          ]}
        />
      </div>
    );
  }
  dayViewCard() {
    return (
      <>
        <TableRow
          style={{
            cursor: 'pointer',
            borderBottom: '1px solid #fff',
            backgroundColor: this.changeRowColor(this.props.memberActivityDetails),
            height: '4em',
          }}
          id="dayRow"
          onClick={(e) => this.toDetails(this.props.memberActivityDetails)}
        >
          <TableCell width="15%">
            {this.showDate(this.props.memberActivityDetails.date)}
            {/* {moment(this.props.memberActivityDetails.date).format('DD MMM YYYY hh:mm a')}
            {this.state.timeZone ? ' (' + moment().tz(this.state.timeZone.name).format('z') + ')' : ''} */}
          </TableCell>
          <TableCell width="15%">{this.props.memberActivityDetails.membername}</TableCell>
          <TableCell width="10%">{this.props.memberActivityDetails.type}</TableCell>
          <TableCell width="15%">{this.props.memberActivityDetails.purpose}</TableCell>
          <TableCell width="24%">{this.props.memberActivityDetails.address}</TableCell>
          <TableCell width="5%">{this.props.memberActivityDetails.status}</TableCell>
          <TableCell width="16%" style={{ textAlign: 'center' }}>
            <span style={{ textAlign: 'center', float: 'right' }}>
              {this.props.memberActivityDetails.hasReference === true ? (
                <>
                  <AccountTreeIcon
                    className="referenceAppointment"
                    style={{ fontSize: '25px', color: '#2E86C1', marginRight: '0.2em' }}
                  />
                  &nbsp;
                </>
              ) : (
                <div style={{ marginLeft: '1em', fontSize: '1.5rem' }}></div>
              )}
              {this.props.memberActivityDetails.hasNotes === true ? (
                <>
                  <Badge
                    badgeContent={this.props.memberActivityDetails.unreadMessageCount}
                    color="primary"
                    overlap="rectangular"
                  >
                    <ChatIcon
                      className="message"
                      style={{ fontSize: '25px', color: '#58D68D', marginRight: '0.2em' }}
                    />
                  </Badge>
                  &nbsp;&nbsp;
                </>
              ) : (
                <div style={{ marginLeft: '1em', fontSize: '1.5rem' }}></div>
              )}
              {this.props.memberActivityDetails.hasReminders === true ? (
                <>
                  <Badge
                    badgeContent={this.props.memberActivityDetails.activeReminderCount}
                    color="primary"
                    overlap="rectangular"
                  >
                    <AccessAlarmTwoToneIcon className="reminder" style={{ fontSize: '25px', color: '#EF797D' }} />
                  </Badge>
                  &nbsp;
                </>
              ) : (
                <div style={{ marginLeft: '1em', fontSize: '1.5rem' }}></div>
              )}
              {this.props.memberActivityDetails.hasTransportation === true ? (
                <>
                  <Tooltip title={this.showTooltip(this.props.memberActivityDetails)} aria-label="add">
                    <DriveEtaIcon
                      className="transportation"
                      style={{
                        fontSize: '25px',
                        color: this.showStatusColor(this.props.memberActivityDetails.transportationStatus),
                        marginLeft: '0.2em',
                      }}
                    />
                  </Tooltip>
                  &nbsp;
                </>
              ) : (
                <div style={{ marginLeft: '1em' }}></div>
              )}
            </span>
          </TableCell>
        </TableRow>{' '}
      </>
    );
  }
  toDetails = (data, e) => {
    if (data.type === 'Event') {
      this.toEventDetails(data);
    } else if (data.type === 'Incident') {
      this.toIncidentDetails(data);
    } else if (data.type === 'Doctor Appointment') {
      this.toDoctorFacilityAppointment(data);
    } else if (data.type === 'Facility Appointment') {
      this.toDoctorFacilityAppointment(data);
    } else if (data.type === 'Purchase') {
      this.toPurchaseDetails(data);
    }
  };
  toWeekViewRowDetails = (e, data) => {
    if (data.type === 'Event') {
      this.toEventDetails(data);
    } else if (data.type === 'Incident') {
      this.toIncidentDetails(data);
    } else if (data.type === 'Doctor Appointment') {
      this.toDoctorFacilityAppointment(data);
    } else if (data.type === 'Facility Appointment') {
      this.toDoctorFacilityAppointment(data);
    } else if (data.type === 'Purchase') {
      this.toPurchaseDetails(data);
    }
  };
  toDoctorFacilityAppointment(Details) {
    let name = Details.membername.split(' ');
    let firstName = name[0];
    let lastName = name[1];
    let memberData = {
      firstname: firstName,
      lastname: lastName,

      member: Details.memberid,
    };

    let consolidatedViewDetails = {
      consolidatedDetails: {
        pageId: 'consolidated',
        currentDayDate: this.props.currentDayDate,
        currentDay: this.props.currentDay,
        consolidatedView: this.props.consolidatedView,
        weekStartDate: this.props.weekStartDate,
        weekEndDate: this.props.weekEndDate,
      },
    };
    let DetailObj = { ...Details, ...consolidatedViewDetails };
    let fccPageState = {
      id: Details.id,
      action: 'view',
    };
    store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
    store.dispatch(memberDataAction.setMember(memberData));
    store.dispatch(consolidatedMemberAction.setConsolidatedMember(DetailObj));
    this.props.history.push({
      pathname: '/viewMemberAppointment',
      state: {
        action: 'view',
        title: 'Appointment Details',
        appointmentId: Details.id,
        pageId: 'consolated',
      },
    });
  }

  toEventDetails(Details) {
    let name = Details.membername.split(' ');
    let firstName = name[0];
    let lastName = name[1];
    let memberData = {
      firstname: firstName,
      lastname: lastName,

      member: Details.memberid,
    };

    let consolidatedViewDetails = {
      consolidatedDetails: {
        pageId: 'consolidated',
        currentDayDate: this.props.currentDayDate,
        currentDay: this.props.currentDay,
        consolidatedView: this.props.consolidatedView,
        weekStartDate: this.props.weekStartDate,
        weekEndDate: this.props.weekEndDate,
      },
    };
    let DetailObj = { ...Details, ...consolidatedViewDetails };
    let fccPageState = {
      id: Details.id,
      action: 'view',
      title: 'Event Details',
    };
    store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
    store.dispatch(memberDataAction.setMember(memberData));
    store.dispatch(consolidatedMemberAction.setConsolidatedMember(DetailObj));
    this.props.history.push({
      pathname: '/viewEvent',
      state: { action: 'view', title: 'Event Details', eventId: Details.id, pageId: 'eventList' },
    });
  }

  toIncidentDetails(Details) {
    let name = Details.membername.split(' ');
    let firstName = name[0];
    let lastName = name[1];
    let memberData = {
      firstname: firstName,
      lastname: lastName,

      member: Details.memberid,
    };

    let consolidatedViewDetails = {
      consolidatedDetails: {
        pageId: 'consolidated',
        currentDayDate: this.props.currentDayDate,
        currentDay: this.props.currentDay,
        consolidatedView: this.props.consolidatedView,
        weekStartDate: this.props.weekStartDate,
        weekEndDate: this.props.weekEndDate,
      },
    };
    let DetailObj = { ...Details, ...consolidatedViewDetails };
    let fccPageState = {
      id: Details.id,
      action: 'view',
      title: 'Incident Details',
    };
    store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
    store.dispatch(memberDataAction.setMember(memberData));
    store.dispatch(consolidatedMemberAction.setConsolidatedMember(DetailObj));
    this.props.history.push({
      pathname: '/viewIncident',
      state: { action: 'view', title: 'Incident Details', incidentId: Details.id, pageId: 'eventList' },
    });
  }
  getEventCard() {
    const { memberActivityDetails } = this.props;
    return (
      <Card className="activeMemberCard" onClick={(e) => this.toEventDetails(memberActivityDetails)}>
        <CardHeader style={{ background: '#D6EAF8', padding: '0.4em' }}>
          <Row>
            <Col md={6} className="memberNameCol">
              <p>
                <b>{memberActivityDetails.membername}</b>
              </p>{' '}
            </Col>
            <Col md={6} className="activityTypeCol">
              <p>
                <b>{memberActivityDetails.type ? memberActivityDetails.type : ''}</b>
              </p>{' '}
            </Col>
          </Row>
        </CardHeader>

        <CardBody className="activityCardBody">
          <p>{memberActivityDetails.address && memberActivityDetails.address}</p>
        </CardBody>
        <span className="dateTimeRow">
          <h6 style={{ float: 'left' }}>
            <i>{moment(memberActivityDetails.date).format('DD-MM-YYYY')}</i>{' '}
          </h6>
          <h6 style={{ float: 'right' }}>
            <i>{Utils.consolidatedViewUTCDateToUserTimeZoneTimeFormate(memberActivityDetails.date)}</i>
          </h6>
        </span>
      </Card>
    );
  }

  getIncidentCard() {
    const { memberActivityDetails } = this.state;
    return (
      <Card className="activeMemberCard" onClick={(e) => this.toIncidentDetails(memberActivityDetails)}>
        <CardHeader style={{ background: '#FADBD8', padding: '0.4em' }}>
          <Row>
            <Col md={6} className="memberNameCol">
              <p>
                <b>{memberActivityDetails.membername}</b>
              </p>{' '}
            </Col>
            <Col md={6} className="activityTypeCol">
              <p>
                <b>{memberActivityDetails.type ? memberActivityDetails.type : ''}</b>
              </p>{' '}
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="activityCardBody">
          <p>{memberActivityDetails.type ? memberActivityDetails.type : ''}</p>
          <p>{memberActivityDetails.address && memberActivityDetails.address}</p>
        </CardBody>
        <span className="dateTimeRow">
          <h6 style={{ float: 'left' }}>
            <i>{moment(memberActivityDetails.date).format('DD-MM-YYYY')}</i>{' '}
          </h6>
          <h6 style={{ float: 'right' }}>
            <i>{Utils.consolidatedViewUTCDateToUserTimeZoneTimeFormate(memberActivityDetails.date)}</i>
          </h6>
        </span>
      </Card>
    );
  }
  getDoctorAppointmentCard() {
    const { memberActivityDetails } = this.state;
    let date = memberActivityDetails.date;
    let date1 = date.split('T');
    let date2 = date1[0].split('-').reverse().join('');
    return (
      <Card className="activeMemberCard" onClick={(e) => this.toDoctorFacilityAppointment(memberActivityDetails)}>
        <CardHeader style={{ background: '#e6dab8', padding: '0.4em' }}>
          <Row>
            <Col md={6} className="memberNameCol">
              <p>
                <b>{memberActivityDetails.membername}</b>
              </p>{' '}
            </Col>
            <Col md={6} className="activityTypeCol">
              <p>
                <b>{memberActivityDetails.type === 'Doctor Appointment' ? 'Doctor' : ''}</b>
              </p>{' '}
            </Col>
          </Row>
        </CardHeader>

        <CardBody className="activityCardBody">
          <p>{memberActivityDetails.with ? memberActivityDetails.with : ''}</p>
          <p>{memberActivityDetails.address && memberActivityDetails.address}</p>
        </CardBody>
        <span className="dateTimeRow">
          <h6 style={{ float: 'left' }}>
            <i>{moment(memberActivityDetails.date).format('DD-MM-YYYY')}</i>{' '}
          </h6>
          <h6 style={{ float: 'right' }}>
            <i>{Utils.consolidatedViewUTCDateToUserTimeZoneTimeFormate(memberActivityDetails.date)}</i>
          </h6>
        </span>
      </Card>
    );
  }
  getFacilityAppointmentCard() {
    const { memberActivityDetails } = this.state;

    return (
      <Card className="activeMemberCard" onClick={(e) => this.toDoctorFacilityAppointment(memberActivityDetails)}>
        <CardHeader style={{ background: '#D4EFDF', padding: '0.4em' }}>
          <Row>
            <Col md={6} className="memberNameCol">
              <p>
                <b>{memberActivityDetails.membername}</b>
              </p>{' '}
            </Col>
            <Col md={6} className="activityTypeCol">
              <p>
                <b>{memberActivityDetails.type === 'Facility Appointment' ? 'Facility' : ''}</b>
              </p>{' '}
            </Col>
          </Row>
        </CardHeader>
        <CardBody className="activityCardBody">
          <p>{memberActivityDetails.with ? memberActivityDetails.with : ''}</p>
          <p>{memberActivityDetails.address && memberActivityDetails.address}</p>
        </CardBody>
        <span className="dateTimeRow">
          <h6 style={{ float: 'left' }}>
            <i>{moment(memberActivityDetails.date).format('DD-MM-YYYY')}</i>
          </h6>
          <h6 style={{ float: 'right' }}>
            <i>{Utils.consolidatedViewUTCDateToUserTimeZoneTimeFormate(memberActivityDetails.date)}</i>
          </h6>
        </span>
      </Card>
    );
  }
}

export default CalenderEventCard;
