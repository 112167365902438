import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import React, { Component } from 'react';

export class GenericButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onClick: props.onClick,
      caption: props.caption,
      type: props.type,
      name: props.name,
      icon: props.icon,
      tooltip: props.tooltip,
    };
  }

  onClick() {
    if (this.state.onClick) {
      this.state.onClick();
    }
  }
  render() {
    return (
      <Tooltip title={this.state.tooltip ? this.state.tooltip : this.state.name}>
        <Button
          type={this.state.type ? this.state.type : ''}
          variant="contained"
          size="small"
          style={{ background: '#ef6603', color: '#fff', marginRight: '1rem' }}
          startIcon={this.state.icon}
          onClick={this.onClick.bind(this)}
        >
          {this.state.name ? this.state.name : 'NO NAME'}
        </Button>
      </Tooltip>
    );
  }
}
export default GenericButton;
