import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import background from '../../src/assets/images/background.jpg';
import PageAlert from '../pts/components/pageAlert/PageAlert';
import ContextProviders from '../pts/components/utilities/ContextProviders';
import handleKeyAccessibility, { handleClickAccessibility } from '../pts/helpers/handleTabAccessibility';
import routes from '../views';

const MOBILE_SIZE = 992;

export class LoginLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sidebarCollapsed: false,
      isMobile: window.innerWidth <= MOBILE_SIZE,
      props: this.props,
    };
  }

  componentDidUpdate(prev) {
    if (this.state.isMobile && prev.location.pathname !== this.props.location.pathname) {
      // this.toggleSideCollapse();
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    document.addEventListener('keydown', handleKeyAccessibility);
    document.addEventListener('click', handleClickAccessibility);
  }
  render() {
    const { sidebarCollapsed } = this.state;
    const sidebarCollapsedClass = sidebarCollapsed ? 'side-menu-collapsed' : '';
    return (
      <ContextProviders>
        <div
          className={`app ${sidebarCollapsedClass}`}
          style={{
            fontSize: '14px',
            backgroundSize: 'cover',
            backgroundRepeat: 'noRepeat',

            // width: '100%',
            // height: '100vh',
            overflow: 'hidden',
            backgroundImage: `url(${background})`,
            display: 'flex',
          }}
        >
          <PageAlert />

          <Switch>
            {routes.map((page, key) => (
              <Route path={page.path} component={page.component} key={key} />
            ))}
          </Switch>
        </div>
      </ContextProviders>
    );
  }
}
export default withRouter(LoginLayout);
