import { CLEAR_SPONSORSHIP, GET_SPONSORSHIP, SET_SPONSORSHIP } from './SponsorshipTypes';

const getSponsorship = () => {
  return {
    type: GET_SPONSORSHIP,
  };
};

const setSponsorship = (data) => {
  return {
    type: SET_SPONSORSHIP,
    payload: data,
  };
};

const clearSponsorship = (data) => {
  return {
    type: CLEAR_SPONSORSHIP,
    payload: data,
  };
};
const sponsorshipAction = { getSponsorship, setSponsorship, clearSponsorship };

export default sponsorshipAction;
