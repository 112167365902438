import { CLEAR_ACTIVE_MENU, GET_ACTIVE_MENU, SET_ACTIVE_MENU } from './ActiveMenuType';

const initialState = {
  activeMenu: '',
};

const ActiveMenuReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ACTIVE_MENU:
      return {
        ...state,
      };
    case SET_ACTIVE_MENU:
      return {
        ...state,

        activeMenu: action.payload,
      };

    case CLEAR_ACTIVE_MENU:
      return {
        ...state,

        activeMenu: null,
      };
    default:
      return state;
  }
};

export default ActiveMenuReducer;
