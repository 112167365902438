import { CLEAR_SELECTED_PLAN, GET_SELECTED_PLAN, SET_SELECTED_PLAN } from './selectedPlanType';

const initialState = {
  selectedPlan: {},
};

const selectedPlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SELECTED_PLAN:
      return {
        ...state,
      };
    case SET_SELECTED_PLAN:
      return {
        ...state,
        selectedPlan: action.payload,
      };

    case CLEAR_SELECTED_PLAN:
      return {
        ...state,
        selectedPlan: null,
      };
    default:
      return state;
  }
};

export default selectedPlanReducer;
