import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import InputAdornment from '@material-ui/core/InputAdornment';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Tooltip from '@material-ui/core/Tooltip';
import AssignmentIcon from '@material-ui/icons/Assignment';
import CloseIcon from '@material-ui/icons/Close';
import DescriptionIcon from '@material-ui/icons/Description';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import EmailIcon from '@material-ui/icons/Email';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import SaveIcon from '@material-ui/icons/Save';
import React, { Component } from 'react';
// import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import Checkbox from '@material-ui/core/Checkbox';
import Fab from '@material-ui/core/Fab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CancelIcon from '@material-ui/icons/Cancel';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { Card, CardBody, CardHeader, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import FetchServerData from '../../../../provider/FetchServerData';
import Utils from '../../../../provider/Utils';
import memberDataAction from '../../../../redux/memberData/memberDataAction';
import memberHomeUrlAction from '../../../../redux/memberHomeUrl/memberHomeUrlAction';
import store from '../../../../redux/store';
import ConsentForm from '../../consentForm/ConsentForm';
import Map from '../../map/Map';
import PtsAlert from '../../ptsAlert/PtsAlert';
import ShareIcon from '@material-ui/icons/Share';
import PostAddIcon from '@material-ui/icons//PostAdd';
import NoteAddIcon from '@material-ui/icons/NoteAdd';
// import IosShareIcon from '@material-ui/icons/IosShare';
import './MemberCardComponent.scss';
import MapDirection from '../../map/MapDirection';
// import MarkEmailUnreadIcon from '@material-ui/icons/MarkEmailUnread';
// import ScheduleSendIcon from '@material-ui/icons/ScheduleSend';
import { withScriptjs } from 'react-google-maps';

class MemberCardComponent extends Component {
  header = store.getState().header.header;

  totalTransportationCount = '';
  AppointmentList = [];
  CommunicationMessageList = [];
  notificationRequestList = [];
  TransportationRequestList = [];
  appointmentIconRef = React.createRef();
  communicationIconRef = React.createRef();
  transportationRequestIconRef = React.createRef();
  notificationsIconRef = React.createRef();
  doctorSharingNotificationsIconRef = React.createRef();
  eventIconRef = React.createRef();
  scollToRef = React.createRef();
  constructor(props) {
    super(props);
    let googleLocationAPIKey = Utils.getLocationAPIKey();

    this.state = {
      render: props.render,
      memberDetail: props.memberDetail ? props.memberDetail : {},
      anchorEl1: null,
      anchorCommunication: null,
      anchorTransportationRequest: null,
      anchorNotificationRequest: null,
      anchorDoctorSharingRequest: null,
      anchorEvent: null,
      position: props.position,
      upComingAppointmentList: [],
      memberName: props.memberDetail ? props.memberDetail.firstname + ' ' + props.memberDetail.lastname : '',
      acceptTransportationRequest: true,
      rejectTransportationRequest: false,
      rejectTransportationWindow: false,
      transportationRejectReason: '',
      transportationType: '',
      consentFormModal: false,
      rejectRequest: false,
      acceptRequest: true,
    };
  }

  memberDetailsComponent(id, memberData) {
    store.dispatch(memberDataAction.setMember(memberData));
    const postObject = {
      header: this.header,
      member: {
        id: memberData.member,
      },
    };
    FetchServerData.callPostService('/memberms/getMemberMenus', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let memberData = output.data;
          let menuList = memberData.menuList;
          let menuId = 'MyCalendar';
          var chek = menuList.find((c) => c.menuid === menuId);

          //or:
          if (chek) {
            this.props.history.push({
              pathname: '/memberDetails',
              state: {
                memberData: memberData,
                pageId: 'home',
              },
            });
            let memberHomeUrl = {
              homeUrl: '/memberDetails',
            };
            store.dispatch(memberHomeUrlAction.setMemberHomeUrl(memberHomeUrl));
          } else {
            for (let i = 0; i < menuList.length; i++) {
              if (menuList[i].menuid !== 'MyCalendar') {
                this.props.history.push({
                  pathname: menuList[0].pageid,

                  state: {
                    memberData: memberData,
                    pageId: 'home',
                  },
                });
                let memberHomeUrl = {
                  homeUrl: menuList[0].pageid,
                };
                store.dispatch(memberHomeUrlAction.setMemberHomeUrl(memberHomeUrl));
              }
            }
          }
        } else {
        }
      })
      .catch((error) => {});
  }

  goToAppointments(id, memberData) {
    store.dispatch(memberDataAction.setMember(memberData));
    this.props.history.push({
      pathname: '/memberAppointment',
      state: {
        memberData: memberData,
        pageId: 'home',
      },
    });
  }

  handleAppointmentClick = (memberData, event) => {
    if (
      null == memberData.upcomingAppointment ||
      null === memberData.upcomingAppointment.upcomingCount ||
      memberData.upcomingAppointment.upcomingCount == 0
    ) {
      return;
    }
    const postObject = {
      header: this.header,
      member: { id: memberData.member },
    };

    FetchServerData.callPostService('/homems/getUpcomingAppointments', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let UpComingAppointmentData = output.data;

        this.AppointmentList = [];
        UpComingAppointmentData.forEach((requestData, index) => {
          this.AppointmentList.push(
            <MenuItem
              key={index}
              style={{
                backgroundColor: index % 2 === 0 ? '#dff0d8' : '#fff',
              }}
            >
              <Row>
                <Col style={{ textAlign: 'left' }}>
                  {requestData.date && (
                    <>
                      {' '}
                      {Utils.convertUTCDateToUserCardTimeZoneDate(requestData.date, this.state.selectedMemberTimeZone)}
                    </>
                  )}
                </Col>
                {requestData.type && requestData.type === 'Doctor Appointment' && (
                  <Col style={{ textAlign: 'left' }}>{requestData.doctor ? requestData.doctor : ''}</Col>
                )}
                {requestData.type && requestData.type === 'Facility Appointment' && (
                  <Col style={{ textAlign: 'left' }}>{requestData.facility ? requestData.facility : ''}</Col>
                )}
              </Row>
            </MenuItem>
          );
        });
        this.setState({
          selectedMemberTimeZone: memberData.timezone,
          anchorEl1: this.appointmentIconRef.current,
          render: true,
        });
      }
    });
  };

  handleEventClick = (memberData, event) => {
    if (
      null == memberData.upcomingEvent ||
      null === memberData.upcomingEvent.upcomingCount ||
      memberData.upcomingEvent.upcomingCount == 0
    ) {
      return;
    }
    const postObject = {
      header: this.header,
      member: { id: memberData.member },
    };

    FetchServerData.callPostService('/homems/getUpcomingEvents', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let UpComingEventData = output.data;

        this.EventList = [];
        UpComingEventData.forEach((requestData, index) => {
          this.EventList.push(
            <MenuItem
              key={index}
              style={{
                backgroundColor: index % 2 === 0 ? '#dff0d8' : '#fff',
              }}
            >
              <Row>
                <Col style={{ textAlign: 'left' }}>
                  {requestData.date && (
                    <>
                      {' '}
                      {Utils.convertUTCDateToUserCardTimeZoneDate(requestData.date, this.state.selectedMemberTimeZone)}
                    </>
                  )}
                </Col>

                <Col style={{ textAlign: 'left' }}>{requestData.title ? requestData.title : ''}</Col>

                <Col style={{ textAlign: 'left' }}>{requestData.location ? requestData.location : ''}</Col>
              </Row>
            </MenuItem>
          );
        });
        this.setState({
          selectedMemberTimeZone: memberData.timezone,
          anchorEvent: this.eventIconRef.current,
          render: true,
        });
      }
    });
  };
  handleNotificationClose = () => {
    this.setState({ anchorNotificationRequest: null });
  };
  handleAppointmentClose = () => {
    this.setState({ anchorEl1: null });
  };

  handleEventClose = () => {
    this.setState({ anchorEvent: null });
  };

  handleDoctorSharingNotificationClose = () => {
    this.setState({ anchorDoctorSharingRequest: null });
  };

  handleCommunicationsClick = (memberData, event) => {
    let UpComingMessageData = memberData.unreadMessages;
    this.CommunicationMessageList = [];
    if (UpComingMessageData && UpComingMessageData.length <= 0) {
      return;
    }
    UpComingMessageData.forEach((requestData, index) => {
      this.CommunicationMessageList.push(
        <MenuItem key={index} style={{ backgroundColor: index % 2 === 0 ? '#dff0d8' : '#fff' }}>
          <Row>
            <Col>{requestData.type ? requestData.type : ''}</Col>
            <Col style={{ textAlign: 'right' }}>
              {requestData.unreadMessageCount ? requestData.unreadMessageCount : ''}
            </Col>
          </Row>
        </MenuItem>
      );
    });
    this.setState({
      openCommunication: true,
      render: true,
      anchorCommunication: this.communicationIconRef.current,
    });
  };

  handleNotificationsClick = (memberData, event) => {
    let caregiverRequestsData = memberData.requestsForMember;
    this.notificationRequestList = [];
    const colours = ['#F39C12', '#A569BD', '#008080', '#3498DB', '#F39C12'];
    if (caregiverRequestsData && caregiverRequestsData.length <= 0) {
      return;
    }
    caregiverRequestsData.forEach((requestData, index) => {
      let name = '';
      if (requestData.requestraisedby === 'M') {
        name = requestData.membername;
      } else {
        name = requestData.caregivername;
      }

      this.notificationRequestList.push(
        <MenuItem key={index} style={{ backgroundColor: index % 2 === 0 ? '#ECF0F1' : '#fff' }}>
          <Row style={{ padding: '0.5em', width: '100%' }}>
            <Col md={8} style={{ display: 'inline-flex' }}>
              <span style={{ color: '#000', display: 'block', marginTop: '0.5em' }}>
                {requestData.requestraisedby === 'M' ? (
                  <>
                    <span style={{ paddingTop: '1px' }}>
                      <h5 style={{ fontSize: '15px' }}>
                        <b style={{ textTransform: 'capitalize' }}>
                          {requestData.membername ? requestData.membername : ''}
                        </b>{' '}
                        has requested you to join her/his caregiver circle as{' '}
                        <b> {requestData.role ? requestData.role : ''}</b>.
                      </h5>
                    </span>
                  </>
                ) : (
                  <>
                    {memberData.role === 'AuthorizedCaregiver' ? (
                      <>
                        <span
                          style={{
                            wordWrap: 'break-word',
                            whiteSpace: 'normal',
                          }}
                        >
                          <h6 style={{ fontSize: '14px', lineHeight: '20px' }}>
                            <b style={{ textTransform: 'capitalize' }}>
                              {requestData.caregivername ? requestData.caregivername : ''}{' '}
                            </b>
                            has requested to join{' '}
                            <b style={{ wordSpacing: '-1px' }}>
                              {requestData.membername ? requestData.membername : ''}'s
                            </b>{' '}
                            caregiver circle as <b> {requestData.role ? requestData.role : ''}</b>.
                          </h6>
                        </span>
                      </>
                    ) : (
                      <>
                        <span
                          style={{
                            wordWrap: 'break-word',
                            whiteSpace: 'normal',
                          }}
                        >
                          <h6 style={{ fontSize: '14px', lineHeight: '20px' }}>
                            <b style={{ textTransform: 'capitalize' }}>
                              {requestData.caregivername ? requestData.caregivername : ''}{' '}
                            </b>
                            has requested to join your caregiver circle as{' '}
                            <b> {requestData.role ? requestData.role : ''}</b>.
                          </h6>
                          {/* Nalini Godse has requested to join your caregiver circle as Admin */}
                        </span>
                      </>
                    )}
                  </>
                )}
              </span>
            </Col>
            <Col md={4} style={{ textAlign: 'right' }}>
              {/* &nbsp;&nbsp; */}
              {/* <span> */}
              <div style={{ paddingTop: '4px', marginLeft: '0.5em' }}>
                <Button
                  size="small"
                  variant="contained"
                  value={this.state.acceptRequest}
                  onClick={(e) => this.acceptConsentForm(e, requestData.id)}
                  // onClick={(e) => this.acceptMemberRequestInBackend(e, requestData.id)}
                  style={{ backgroundColor: '#27AE60', color: '#fff' }}
                >
                  Accept
                </Button>
                &nbsp; &nbsp;
                <Button
                  size="small"
                  variant="contained"
                  value={this.state.rejectRequest}
                  onClick={(e) => this.rejectMemberRequestInBackend(requestData.id)}
                  style={{ backgroundColor: '#EC7063', color: '#fff' }}
                >
                  Reject
                </Button>
              </div>
              {/* </span> */}
            </Col>
          </Row>
        </MenuItem>
      );
    });
    this.setState({
      openCommunication: true,
      render: true,
      anchorNotificationRequest: this.notificationsIconRef.current,
    });
  };
  handleCommunicationClose = () => {
    this.setState({ anchorCommunication: null });
  };

  goToCommunications(id, memberData) {
    store.dispatch(memberDataAction.setMember(memberData));
    this.props.history.push({
      pathname: '/memberCommunication',
      state: {
        memberData: memberData,
        pageId: 'home',
      },
    });
  }

  goToIncident(id, memberData) {
    store.dispatch(memberDataAction.setMember(memberData));
    this.props.history.push({
      pathname: '/incidentAppointment',
      state: {
        memberData: memberData,
        pageId: 'home',
      },
    });
  }
  goToEvent(id, memberData) {
    store.dispatch(memberDataAction.setMember(memberData));
    this.props.history.push({
      pathname: '/member_event',
      state: {
        memberData: memberData,
        pageId: 'home',
      },
    });
  }

  toRejectTransportationWindow = (e, requestMemberData) => {
    this.setState({
      rejectTransportationWindow: true,
      transportationId: requestMemberData.id,
      isTaskTransportation: requestMemberData.isTaskTransporation,
      transportationType: requestMemberData.type,
    });
  };
  handleTransportationNotificationClick = (memberData, event) => {
    const postObject = {
      header: this.header,
      member: { id: memberData.member },
    };
    FetchServerData.callPostService('/homems/getTransportationRequests', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let appointmentTransportation = output.data;
        this.TransportationRequestList = [];
        appointmentTransportation.forEach((requestData, index) => {
          this.TransportationRequestList.push(
            <MenuItem key={index} style={{ backgroundColor: index % 2 === 0 ? '#dff0d8' : '#fff' }}>
              <Row style={{ padding: '1.5em' }}>
                <Col md={12}>
                  <h5>{requestData.type && requestData.type + ':'}</h5>
                  <span style={{ color: '#000', display: 'block' }}>
                    <p style={{ color: '#000', fontSize: '14px' }}>
                      <b>{null !== requestData.createdbyname ? requestData.createdbyname : ' '}</b> has requested you to
                      accompany &nbsp;
                      <b>{null !== this.state.memberName ? this.state.memberName : ''}</b>
                      &nbsp;while visiting{' '}
                      <b style={{ color: '#3498DB', fontSize: '14px' }}>
                        {requestData.name && requestData.name}
                        {requestData.location && ' - ' + requestData.location + ' '}
                      </b>
                      on
                      <b style={{ color: '#3498DB', fontSize: '14px' }}>
                        {' ' +
                          Utils.convertUTCDateToUserCardTimeZoneDate(
                            requestData.date,
                            this.state.selectedMemberTimeZone
                          )}
                      </b>
                    </p>
                  </span>
                  <span>
                    <div style={{ paddingTop: '4px' }}>
                      <Button
                        size="small"
                        variant="contained"
                        value={this.state.acceptTransportationRequest}
                        onClick={(e) => this.acceptTransportationRequestInBackend(e, requestData)}
                        style={{ backgroundColor: '#58D68D', color: '#fff' }}
                      >
                        Accept
                      </Button>
                      &nbsp; &nbsp;
                      <Button
                        size="small"
                        variant="contained"
                        value={this.state.rejectTransportationRequest}
                        onClick={(e) => this.toRejectTransportationWindow(e, requestData)}
                        style={{ backgroundColor: '#E74C3C', color: '#fff' }}
                      >
                        Reject
                      </Button>
                    </div>
                  </span>
                </Col>
              </Row>
            </MenuItem>
          );
        });

        let memberName = memberData.firstname + ' ' + memberData.lastname;
        this.setState({
          selectedMemberTimeZone: memberData.timezone,
          accompanyMemberName: memberName,
          render: true,
          transportationRequestOpen: true,
          anchorTransportationRequest: this.transportationRequestIconRef.current,
        });
      } else {
      }
    });
  };

  handleTransportationClose = () => {
    this.setState({ anchorTransportationRequest: null });
  };

  acceptTransportationRequestInBackend(e, requestData) {
    let transportationId = requestData.id;
    if (requestData.type === 'Event') {
      const postObject = {
        header: this.header,
        transportationVo: {
          id: transportationId,
          reason: '',
          isApprove: this.state.acceptTransportationRequest,
        },
      };
      FetchServerData.callPostService('eventtransportms/acceptTransportationRequest', postObject).then((output) => {
        if (output.status === 'SUCCESS') {
          PtsAlert.success(output.message);
          this.props.onSuccess();
          this.setState({
            transportationRequestOpen: false,
          });
        } else {
        }
      });
    } else {
      if (requestData.isTaskTransporation === false) {
        const postObject = {
          header: this.header,
          transportationVo: {
            id: transportationId,
            reason: '',
            isApprove: this.state.acceptTransportationRequest,
          },
        };
        FetchServerData.callPostService('/apptransportms/acceptTransporationRequest', postObject).then((output) => {
          if (output.status === 'SUCCESS') {
            PtsAlert.success(output.message);
            this.props.onSuccess();
            this.setState({
              transportationRequestOpen: false,
            });
          } else {
          }
        });
      } else {
        const postObject = {
          header: this.header,
          transportationVo: {
            id: transportationId,
            reason: '',
            isApprove: this.state.acceptTransportationRequest,
          },
        };
        FetchServerData.callPostService('/apptransportms/acceptTransporationRequest', postObject).then((output) => {
          if (output.status === 'SUCCESS') {
            PtsAlert.success(output.message);
            this.props.onSuccess();
            this.setState({
              transportationRequestOpen: false,
            });
          } else {
          }
        });
      }
    }
  }

  rejectTransportationRequestInBackend(e) {
    if (this.state.transportationType === 'Event') {
      const postObject = {
        header: this.header,
        transportationVo: {
          id: this.state.transportationId,
          reason: this.state.transportationRejectReason,
          isApprove: this.state.rejectTransportationRequest,
        },
      };
      FetchServerData.callPostService('eventtransportms/rejectTransportationRequest', postObject).then((output) => {
        if (output.status === 'SUCCESS') {
          PtsAlert.success(output.message);
          this.props.onSuccess();
          this.setState({
            rejectTransportationWindow: false,
            transportationRequestOpen: false,
            transportationRejectReason: '',
          });
        } else {
        }
      });
    } else {
      if (this.state.isTaskTransportation === false) {
        const postObject = {
          header: this.header,
          transportationVo: {
            id: this.state.transportationId,
            reason: this.state.transportationRejectReason,
            isApprove: this.state.rejectTransportationRequest,
          },
        };
        FetchServerData.callPostService('/apptransportms/rejectTransportationRequest', postObject).then((output) => {
          if (output.status === 'SUCCESS') {
            PtsAlert.success(output.message);
            this.props.onSuccess();
            this.setState({
              rejectTransportationWindow: false,
              transportationRequestOpen: false,
              transportationRejectReason: '',
            });
          } else {
          }
        });
      } else {
        const postObject = {
          header: this.header,
          transportationVo: {
            id: this.state.transportationId,
            reason: this.state.transportationRejectReason,
            isApprove: this.state.rejectTransportationRequest,
          },
        };
        FetchServerData.callPostService('/apptransportms/rejectTransportationRequest', postObject).then((output) => {
          if (output.status === 'SUCCESS') {
            PtsAlert.success(output.message);
            this.props.onSuccess();
            this.setState({
              rejectTransportationWindow: false,
              transportationRequestOpen: false,
              transportationRejectReason: '',
            });
          } else {
          }
        });
      }
    }
  }

  handleTransportationRejectReasonChange(e) {
    let val = e.target.value;
    this.setState({ transportationRejectReason: val });
  }

  handleRejectTransportationWindowClose = () => {
    this.setState({
      rejectTransportationWindow: false,
    });
  };
  handleClickCheckBox = (e) => {
    if (e.target.checked === 'true') {
      this.setState({
        checkbox: e.target.checked,
        isDisabled: false,
      });
    } else {
      this.setState({
        checkbox: e.target.checked,
        isDisabled: true,
      });
    }
  };
  acceptMemberRequestInBackend(e, id) {
    let requestMemberId = id;
    if (this.state.checkbox) {
      const postObject = {
        header: this.header,
        memberVo: {
          familyMemberMemberId: requestMemberId,
          isActive: this.state.acceptRequest,
        },
      };
      FetchServerData.callPostService('memberms/acceptMembershipRequest', postObject).then((output) => {
        if (output.status === 'SUCCESS') {
          let memberData = output.data;
          PtsAlert.success(output.message);
          let myRequestsToCaregivers = memberData.myRequestsTocaregivers;
          let caregiversRequestsToMe = memberData.caregiversRequestsToMe;
          let notificationRequestData = [...myRequestsToCaregivers, ...caregiversRequestsToMe];
          this.setState({
            render: true,
            consentFormModal: false,
            checkbox: false,
            allNotificationRequestList: notificationRequestData,
            myRequestsToCaregivers: myRequestsToCaregivers,
            caregiversRequestsToMe: caregiversRequestsToMe,

            totalNotificationCount: notificationRequestData.length,
          });

          this.props.onSuccess();
        } else {
        }
      });
    } else {
      document.getElementById('acceptTerms').focus();
    }
  }
  consentFormToggle = () => {
    this.setState({
      consentFormModal: !this.state.consentFormModal,
    });
  };
  acceptConsentForm = (e, familyMemberMemberId) => {
    this.setState({
      consentFormModal: true,
      familyMemberMemberId: familyMemberMemberId,
    });
  };

  rejectMemberRequestInBackend(id) {
    let requestMemberId = id;
    const postObject = {
      header: this.header,
      memberVo: {
        familyMemberMemberId: requestMemberId,
        isActive: this.state.rejectRequest,
      },
    };
    FetchServerData.callPostService('memberms/rejectMembershipRequest', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        PtsAlert.success(output.message);
        let memberData = output.data;

        let myRequestsToCaregivers = memberData.myRequestsTocaregivers;
        let caregiversRequestsToMe = memberData.caregiversRequestsToMe;
        let notificationRequestData = [...myRequestsToCaregivers, ...caregiversRequestsToMe];
        this.setState({
          render: true,
          consentFormModal: false,
          allNotificationRequestList: notificationRequestData,
          myRequestsToCaregivers: myRequestsToCaregivers,
          caregiversRequestsToMe: caregiversRequestsToMe,

          totalNotificationCount: notificationRequestData.length,
        });
        this.props.onSuccess();
      } else {
      }
    });
  }
  showTransportationCount(transportationList) {
    let eventCount = '';
    let appointmentCount = '';

    for (let i = 0; i < transportationList.length; i++) {
      if (Utils.equalsIgnoreCase(transportationList[i].type, 'Event')) {
        eventCount = transportationList[i].count;
      } else {
        appointmentCount = transportationList[i].count;
      }

      if (
        (eventCount !== null && eventCount !== undefined) ||
        (appointmentCount !== null && appointmentCount !== undefined)
      )
        this.totalTransportationCount = eventCount + appointmentCount;
    }

    return (
      <>
        {appointmentCount && 'Appointment -' + appointmentCount} {eventCount && 'Event -' + eventCount}
      </>
    );
  }
  async showMap(memberAddress) {
    let locaData1 = [];

    for (let i = 0; i < this.state.locationDetails.length; i++) {
      let locdata = await Utils.googleMapOpenUrl(
        this.state.locationDetails[i].shortDescription,
        this.state.locationDetails[i].address.line,
        this.state.locationDetails[i].address.city,
        this.state.locationDetails[i].address.state.country.name,
        this.state.locationDetails[i].address.state.name,
        i
      );
      locaData1.push(locdata);
    }
    this.setState({
      showMapView: true,
      memberAddress1: locaData1,
    });
  }
  googleMapOpenUrl = (currentMemberDetails) => {
    // return `google.navigation:q=${'Maharashtra, India'}`;
    // let line = currentMemberDetails.addressLine ? currentMemberDetails.addressLine : '';
    // let city = currentMemberDetails.addressCity ? currentMemberDetails.addressCity : '';
    // let state = currentMemberDetails.addressState ? currentMemberDetails.addressState : '';
    // let country = currentMemberDetails.addressCountry ? currentMemberDetails.addressCountry : '';
    // let address = `${line} ${city} ${state} ${country}`;
    let address = currentMemberDetails.address ? currentMemberDetails.address : '';
    var url = 'https://www.google.com/maps/dir/?api=1&travelmode=driving&dir_action=navigate&destination=' + address;
    if (!url) {
      console.log("Can't handle url: " + url);
    } else {
      return window.open(url, '_blank');
    }
  };
  showMapToggle() {
    this.setState({
      showMapView: false,
    });
  }
  handleDoctorSharingClick = (memberData, event) => {
    let doctorSharingRequestsData = memberData.sharingInfoRequests;
    this.doctorSharingRequestList = [];
    const colours = ['#F39C12', '#A569BD', '#008080', '#3498DB', '#F39C12'];
    if (doctorSharingRequestsData && doctorSharingRequestsData.length <= 0) {
      return;
    }
    doctorSharingRequestsData.forEach((requestData, index) => {
      this.doctorSharingRequestList.push(
        <MenuItem key={index} style={{ backgroundColor: index % 2 === 0 ? '#ECF0F1' : '#fff' }}>
          <Row style={{ padding: '0.5em', width: '100%' }}>
            <Col md={8} style={{ display: 'inline-flex' }}>
              <span style={{ color: '#000', display: 'block', marginTop: '0.5em', paddingTop: '1px' }}>
                <>
                  {/* <span style={{ paddingTop: '1px' }}> */}
                  <h5 style={{ fontSize: '15px' }}>
                    <b style={{ textTransform: 'capitalize' }}>
                      {requestData.frommemberName ? requestData.frommemberName : ''}
                    </b>{' '}
                    has shared details of{' '}
                  </h5>
                  <h5
                    style={{
                      fontSize: '15px',
                      // textTransform: 'capitalize'
                    }}
                  >
                    {Utils.equalsIgnoreCase(requestData.type, 'Facility')
                      ? requestData.facilityName
                        ? requestData.facilityName
                        : ''
                      : requestData.doctorName
                      ? requestData.doctorName
                      : ''}{' '}
                    .{/* <b> {requestData.role ? requestData.role : ''}</b>. */}
                  </h5>
                  {/* </span> */}
                </>
              </span>
            </Col>
            <Col md={4} style={{ textAlign: 'right' }}>
              {/* &nbsp;&nbsp; */}
              {/* <span> */}
              <div style={{ paddingTop: '4px', marginLeft: '0.5em' }}>
                {requestData.type === 'Doctor' ? (
                  <Button
                    size="small"
                    variant="contained"
                    value={this.state.acceptRequest}
                    onClick={(e) => this.acceptShareDoctorInBackend(e, requestData, memberData)}
                    style={{ backgroundColor: '#27AE60', color: '#fff' }}
                  >
                    Accept
                  </Button>
                ) : (
                  <Button
                    size="small"
                    variant="contained"
                    value={this.state.acceptRequest}
                    onClick={(e) => this.acceptShareFacilityInBackend(e, requestData, memberData)}
                    style={{ backgroundColor: '#27AE60', color: '#fff' }}
                  >
                    Accept
                  </Button>
                )}
                &nbsp; &nbsp;
                <Button
                  size="small"
                  variant="contained"
                  value={this.state.rejectRequest}
                  onClick={(e) => this.rejectShareDoctorInBackend(requestData.id)}
                  style={{ backgroundColor: '#EC7063', color: '#fff' }}
                >
                  Reject
                </Button>
              </div>
              {/* </span> */}
            </Col>
          </Row>
        </MenuItem>
      );
    });
    this.setState({
      openCommunication: true,
      render: true,
      anchorDoctorSharingRequest: this.doctorSharingNotificationsIconRef.current,
    });
  };
  acceptShareDoctorInBackend(e, requestData, memberDetails) {
    console.log('requestedData', requestData, memberDetails);
    let requestMemberId = requestData.id;

    const postObject = {
      header: this.header,
      doctorSharingInfo: {
        id: requestMemberId,
      },
    };
    FetchServerData.callPostService('/infoSharingMs/accept', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let requestData = output.data;
        PtsAlert.success(output.message);
        let doctorSharingRequests = requestData.sharingInfoRequests;
        store.dispatch(memberDataAction.setMember(memberDetails));
        this.props.history.push({
          pathname: '/doctorAppointment',
          state: {
            memberData: memberDetails,
            pageId: 'home',
          },
        });
        this.setState({
          render: true,
          doctorSharingRequests: doctorSharingRequests,
        });

        // this.props.onSuccess();
      } else {
      }
    });
  }
  acceptShareFacilityInBackend(e, requestData, memberDetails) {
    console.log('requestedData', requestData, memberDetails);
    let requestMemberId = requestData.id;

    const postObject = {
      header: this.header,
      doctorSharingInfo: {
        id: requestMemberId,
      },
    };
    FetchServerData.callPostService('/infoSharingMs/accept', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let requestData = output.data;
        PtsAlert.success(output.message);
        let doctorSharingRequests = requestData.sharingInfoRequests;
        store.dispatch(memberDataAction.setMember(memberDetails));
        this.props.history.push({
          pathname: '/memberFacility',
          state: {
            memberData: memberDetails,
            pageId: 'home',
          },
        });
        this.setState({
          render: true,
          doctorSharingRequests: doctorSharingRequests,
        });

        // this.props.onSuccess();
      } else {
      }
    });
  }
  rejectShareDoctorInBackend(id) {
    let requestMemberId = id;
    const postObject = {
      header: this.header,
      doctorSharingInfo: {
        id: requestMemberId,
      },
    };
    FetchServerData.callPostService('/infoSharingMs/reject', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        PtsAlert.success(output.message);
        let memberData = output.data;

        let doctorSharingRequests = memberData.sharingInfoRequests;
        this.setState({
          render: true,
          consentFormModal: false,
          doctorSharingRequests: doctorSharingRequests,

          // totalNotificationCount: notificationRequestData.length,
        });
        this.props.onSuccess();
      } else {
      }
    });
  }
  render() {
    const colours = ['#A569BD', '#000080', '#3498DB', '#800080', '#800000'];
    if (this.state.render === false) {
      return <div />;
    } else {
      let memberNamesList = store.getState().memberList.memberList;
      let currentMemberDetails = this.props.memberDetail ? this.props.memberDetail : {};
      let getDomainObjectPermission = currentMemberDetails.allowedDomainObjects;
      let appointmentDate = '';
      let appointmentPurpose = '';
      let doctorFacilityLocation = '';
      let upcomingAppointmentCount = '';
      let incidentDate = '';
      let incidentType = '';
      let incidentLocation = '';
      let eventCount = '';
      let appointmentCount = '';
      let totalTransportationCount = '';
      let totalUnreadMessageCount = '';
      let eventDate = '';
      let eventTitle = '';
      let eventLocation = '';
      let upcomingEventCount = '';
      let generalCount = '';
      let purchaseCount = '';
      let incidentCount = '';

      let showIncidentPermission = false;
      let showAppointmentPermission = false;
      let showEventPermission = false;
      let showMessageThreadPermission = false;

      let memberAddress1 =
        null !== currentMemberDetails.address && undefined !== currentMemberDetails.address
          ? currentMemberDetails.address
          : '';
      let locationDetails1 = [];
      locationDetails1.push({
        address: {
          line: currentMemberDetails.addressLine ? currentMemberDetails.addressLine : '',
          city: currentMemberDetails.addressCity ? currentMemberDetails.addressCity : '',
          zipCode: currentMemberDetails.addressZipcode ? currentMemberDetails.addressZipcode : '',

          state: {
            name: currentMemberDetails.addressState ? currentMemberDetails.addressState : '',

            country: {
              name: currentMemberDetails.addressCountry ? currentMemberDetails.addressCountry : '',
            },
          },
        },
      });
      this.state.locationDetails = locationDetails1;

      let name = currentMemberDetails.firstname.trim() + ' ' + currentMemberDetails.lastname.trim();

      let timeZone =
        null !== currentMemberDetails.timezone && undefined !== currentMemberDetails.timezone
          ? currentMemberDetails.timezone
          : '';
      if (currentMemberDetails.upcomingAppointment !== null && currentMemberDetails.upcomingAppointment !== undefined) {
        if (
          currentMemberDetails.upcomingAppointment.date !== null &&
          currentMemberDetails.upcomingAppointment.date !== undefined
        ) {
          appointmentDate = Utils.convertUTCDateToUserCardTimeZoneDate(
            currentMemberDetails.upcomingAppointment.date,
            timeZone
          );
        }
        appointmentPurpose =
          null !== currentMemberDetails.upcomingAppointment.purpose &&
          undefined !== currentMemberDetails.upcomingAppointment.purpose
            ? currentMemberDetails.upcomingAppointment.purpose
            : '';
        doctorFacilityLocation =
          null !== currentMemberDetails.upcomingAppointment.location &&
          undefined !== currentMemberDetails.upcomingAppointment.location
            ? currentMemberDetails.upcomingAppointment.location
            : '';
        upcomingAppointmentCount =
          null !== currentMemberDetails.upcomingAppointment.upcomingCount &&
          undefined !== currentMemberDetails.upcomingAppointment.upcomingCount
            ? currentMemberDetails.upcomingAppointment.upcomingCount
            : '';
      }

      if (currentMemberDetails.upcomingEvent !== null && currentMemberDetails.upcomingEvent !== undefined) {
        if (currentMemberDetails.upcomingEvent.date !== null && currentMemberDetails.upcomingEvent.date !== undefined) {
          eventDate = Utils.convertUTCDateToUserCardTimeZoneDate(currentMemberDetails.upcomingEvent.date, timeZone);
        }
        eventTitle =
          null !== currentMemberDetails.upcomingEvent.title && undefined !== currentMemberDetails.upcomingEvent.title
            ? currentMemberDetails.upcomingEvent.title
            : '';

        eventLocation =
          null !== currentMemberDetails.upcomingEvent.location &&
          undefined !== currentMemberDetails.upcomingEvent.location
            ? currentMemberDetails.upcomingEvent.location
            : '';
        upcomingEventCount =
          null !== currentMemberDetails.upcomingEvent.upcomingCount &&
          undefined !== currentMemberDetails.upcomingEvent.upcomingCount
            ? currentMemberDetails.upcomingEvent.upcomingCount
            : '';
      }

      if (currentMemberDetails.recentIncident !== null && currentMemberDetails.recentIncident !== undefined) {
        if (
          currentMemberDetails.recentIncident.date !== null &&
          currentMemberDetails.recentIncident.date !== undefined
        ) {
          incidentDate = Utils.convertUTCDateToUserCardTimeZoneDate(currentMemberDetails.recentIncident.date, timeZone);
        }
        incidentType =
          null !== currentMemberDetails.recentIncident.type && undefined !== currentMemberDetails.recentIncident.type
            ? currentMemberDetails.recentIncident.type
            : '';
        incidentLocation =
          null !== currentMemberDetails.recentIncident.location &&
          undefined !== currentMemberDetails.recentIncident.location
            ? currentMemberDetails.recentIncident.location
            : '';
      }

      let accompanyBy =
        null !== currentMemberDetails.accompanyName && undefined !== currentMemberDetails.accompanyName
          ? currentMemberDetails.accompanyName
          : '';

      for (let i = 0; i < currentMemberDetails.transportationRequests.length; i++) {
        if (Utils.equalsIgnoreCase(currentMemberDetails.transportationRequests[i].type, 'Event')) {
          eventCount = currentMemberDetails.transportationRequests[i].count;
        } else {
          appointmentCount = currentMemberDetails.transportationRequests[i].count;
        }

        if (
          (eventCount !== null && eventCount !== undefined) ||
          (appointmentCount !== null && appointmentCount !== undefined)
        )
          totalTransportationCount = eventCount + appointmentCount;
      }
      totalUnreadMessageCount = null;
      for (let i = 0; i < currentMemberDetails.unreadMessages.length; i++) {
        if (Utils.isNotNullAndEmpty(currentMemberDetails.unreadMessages[i].unreadMessageCount)) {
          totalUnreadMessageCount += currentMemberDetails.unreadMessages[i].unreadMessageCount;
        }
      }
      //
      for (let domainObjectKey in getDomainObjectPermission) {
        if (domainObjectKey === 'Appointment') {
          showAppointmentPermission = true;
        } else if (domainObjectKey === 'Incident') {
          showIncidentPermission = true;
        } else if (domainObjectKey === 'Event') {
          showEventPermission = true;
        } else if (domainObjectKey === 'MessageThread' || domainObjectKey === 'MESSAGETHREAD') {
          showMessageThreadPermission = true;
        }
      }
      const MapLoader = withScriptjs(MapDirection);
      return (
        <>
          <Col md={3} style={{ margin: '1em 0em 1em 0em' }}>
            <Card
              md={3}
              ref={this.scollToRef.current}
              style={{
                boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                borderRadius: '5px',
                height: 'auto',
              }}
              className="h-100"
            >
              <CardHeader
                style={{
                  backgroundColor: Utils.equalsIgnoreCase(currentMemberDetails.roleuid, 'FamilyMember')
                    ? '#4C984C '
                    : Utils.equalsIgnoreCase(currentMemberDetails.roleuid, 'Admin')
                    ? '#c47b46 '
                    : Utils.equalsIgnoreCase(currentMemberDetails.roleuid, 'AuthorizedCaregiver')
                    ? '#DE3163'
                    : Utils.equalsIgnoreCase(currentMemberDetails.roleuid, 'My Circle')
                    ? '#066f72'
                    : Utils.equalsIgnoreCase(currentMemberDetails.roleuid, 'Transporter')
                    ? '#800080'
                    : '#526D82',

                  padding: '4px',
                  cursor: 'pointer',
                }}
              >
                <Row style={{ padding: '0.2em' }}>
                  <Col md={2} style={{ alignItems: 'left', textAlign: 'left', padding: 0, marginTop: '0.2em' }}>
                    {Utils.getMemberName(name, memberNamesList.indexOf(name) % 26)}
                  </Col>
                  <Col
                    md={6}
                    style={{ alignSelf: 'middle' }}
                    onClick={(e) => this.memberDetailsComponent(currentMemberDetails.member, currentMemberDetails)}
                  >
                    {currentMemberDetails.role === null ? (
                      <>
                        <span
                          style={{
                            textAlign: 'center',
                            textTransform: 'capitalize',
                            marginBottom: '1px',
                            color: '#fff',
                          }}
                        >
                          <b>{name}</b>
                        </span>{' '}
                        <span style={{ fontSize: 'small', color: '#fff' }}>
                          {' '}
                          <u>{currentMemberDetails.role}</u>
                        </span>
                      </>
                    ) : (
                      <>
                        <span
                          style={{
                            textAlign: 'center',
                            textTransform: 'capitalize',
                            marginBottom: '1px',
                            color: '#fff',
                          }}
                        >
                          <b>{name}</b>
                        </span>
                        <br></br>

                        <span style={{ fontSize: 'small', color: '#fff', textDecoration: 'underline' }}>
                          <Tooltip title="This is your Profile for this Member.This member decides your role in his/her Caregiver Circle.">
                            <u>{currentMemberDetails.role}</u>
                          </Tooltip>
                        </span>
                      </>
                    )}
                  </Col>

                  <Col
                    md={4}
                    //  style={{ margin: 'auto auto auto -2.2em' }}
                    style={{ margin: 'auto 0em auto -0.9em', padding: ' 0.2em', alignItems: 'end' }}
                  >
                    {currentMemberDetails.requestsForMember.length > 0 && (
                      <>
                        <span style={{ float: 'right', marginRight: '0.2em' }}>
                          {currentMemberDetails.requestsForMember.length > 0 && (
                            <Badge
                              ref={this.notificationsIconRef}
                              badgeContent={currentMemberDetails.requestsForMember.length}
                              id="notificationBadge"
                              className="memberBadge"
                              onClick={this.handleNotificationsClick.bind(this, currentMemberDetails)}
                              overlap="rectangular"
                              style={{ color: '#fff', backgroundColor: 'limegreen', border: '1px solid #fff' }}
                            />
                          )}
                          <NotificationsIcon
                            style={{
                              cursor: 'pointer',
                              background: '#fff',
                              color: '#000',
                              padding: '2px',
                              borderRadius: '50%',
                              fontSize: 'x-large',
                              float: 'right',
                              marginRight: '0.2em',
                              border: '2px solid #fff',
                            }}
                            id="notificationIcon"
                            aria-owns={this.state.anchorNotificationRequest ? 'simple-menu4' : undefined}
                            aria-haspopup="true"
                            onClick={this.handleNotificationsClick.bind(this, currentMemberDetails)}
                          />
                          <Menu
                            anchorEl={this.notificationsIconRef.current}
                            open={Boolean(this.state.anchorNotificationRequest)}
                            onClose={this.handleNotificationClose}
                            onClick={this.handleNotificationClose}
                            id="simple-menu4"
                          >
                            {this.notificationRequestList}
                          </Menu>
                        </span>
                      </>
                    )}

                    {/* </Col>
                  <Col md={1} style={{ margin: 'auto auto auto -2.3em' }}> */}
                    <span style={{ float: 'right', marginRight: '0.2em' }}>
                      <Tooltip title="Location">
                        <LocationOnIcon
                          style={{
                            cursor: 'pointer',
                            background: '#fff',
                            color: '#000',
                            padding: '2px',
                            float: 'right',
                            borderRadius: '50%',
                            fontSize: 'x-large',
                            // marginTop: '0.2em',
                            marginRight: '0.2em',
                            border: '2px solid #fff',
                          }}
                          // onClick={(e) => this.showMap()}
                          onClick={(e) => this.googleMapOpenUrl(currentMemberDetails)}
                        />
                      </Tooltip>
                    </span>
                    {/* </Col>
                  <Col md={1} style={{ margin: 'auto auto auto -2.5em' }}> */}
                    {/* <Tooltip title="Location"> */}

                    {currentMemberDetails.sharingInfoRequests.length > 0 && (
                      <>
                        <span style={{ float: 'right', marginRight: '0.2em' }}>
                          {currentMemberDetails.sharingInfoRequests.length > 0 && (
                            <Badge
                              ref={this.doctorSharingNotificationsIconRef}
                              badgeContent={currentMemberDetails.sharingInfoRequests.length}
                              id="notificationBadge"
                              className="memberBadge"
                              onClick={this.handleDoctorSharingClick.bind(this, currentMemberDetails)}
                              overlap="rectangular"
                              style={{ color: '#fff', backgroundColor: 'limegreen', border: '1px solid #fff' }}
                            />
                          )}
                          {/*  */}
                          <PostAddIcon
                            style={{
                              cursor: 'pointer',
                              background: '#fff',
                              color: '#000',
                              padding: '2px',
                              borderRadius: '50%',
                              fontSize: 'x-large',
                              float: 'right',
                              // marginTop: '0.2em',
                              marginRight: '0.2em',
                              border: '2px solid #fff',
                            }}
                            id="notificationIcon"
                            aria-owns={this.state.anchorDoctorSharingRequest ? 'simple-menu5' : undefined}
                            aria-haspopup="true"
                            onClick={this.handleDoctorSharingClick.bind(this, currentMemberDetails)}
                          />

                          <Menu
                            anchorEl={this.doctorSharingNotificationsIconRef.current}
                            open={Boolean(this.state.anchorDoctorSharingRequest)}
                            onClose={this.handleDoctorSharingNotificationClose}
                            onClick={this.handleDoctorSharingNotificationClose}
                            id="simple-menu5"
                          >
                            {this.doctorSharingRequestList}
                          </Menu>
                          {/* </Tooltip> */}
                        </span>
                      </>
                    )}

                    {/* </Tooltip> */}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody style={{ padding: '5px' }}>
                <ul className="menuList">
                  {showAppointmentPermission === true ? (
                    <li className="memberAppointments" style={{ cursor: 'pointer', marginBottom: '5px' }}>
                      <Row>
                        <Col md={2} style={{ fontSize: 'small', margin: 'auto', alignSelf: 'middle' }}>
                          {upcomingAppointmentCount && (
                            <Badge
                              ref={this.appointmentIconRef}
                              badgeContent={upcomingAppointmentCount}
                              color="primary"
                              className="memberBadge"
                              onClick={this.handleAppointmentClick.bind(this, currentMemberDetails)}
                              overlap="rectangular"
                            />
                          )}
                          <AssignmentIcon
                            style={{
                              background: '#5ac489',
                              color: '#fff',
                              padding: '5px',
                              borderRadius: '50%',
                              fontSize: 'xx-large',

                              border: '2px solid #5ac489',
                            }}
                            aria-owns={this.state.anchorEl1 ? this.state.anchorEl1 : false}
                            aria-haspopup="true"
                            onClick={this.handleAppointmentClick.bind(this, currentMemberDetails)}
                            id="appointmentIcon"
                          />

                          <Menu
                            id="simple-menu1"
                            anchorEl={this.appointmentIconRef.current}
                            open={Boolean(this.state.anchorEl1)}
                            onClose={this.handleAppointmentClose}
                            onClick={this.handleAppointmentClose}
                          >
                            {this.AppointmentList}
                          </Menu>
                        </Col>
                        <Col
                          md={10}
                          style={{
                            textAlign: 'left',
                            alignSelf: 'middle',
                            fontSize: 'small',
                          }}
                          onClick={(e) => this.goToAppointments(currentMemberDetails.member, currentMemberDetails)}
                        >
                          <div>
                            <span className="memberAppointmentHeading">Appointments</span>
                            {appointmentDate || appointmentPurpose || doctorFacilityLocation || accompanyBy ? (
                              <p className="memberAppointments">
                                {appointmentDate && <>{appointmentDate + ', '}</>}
                                {appointmentPurpose && <>{appointmentPurpose + ', '}</>}

                                {doctorFacilityLocation && <>{doctorFacilityLocation + ', '}</>}
                                {accompanyBy && (
                                  <>
                                    {' Accompany By' + ' - '}
                                    <b>{accompanyBy}</b>
                                  </>
                                )}
                              </p>
                            ) : (
                              ''
                            )}
                          </div>
                        </Col>
                      </Row>
                    </li>
                  ) : (
                    <li
                      className="memberAppointments"
                      style={{ cursor: 'pointer', marginBottom: '5px', opacity: '0.5', cursor: 'no-drop' }}
                    >
                      <Row>
                        <Col md={2} style={{ fontSize: 'small', margin: 'auto', alignSelf: 'middle' }}>
                          <AssignmentIcon
                            style={{
                              background: '#5ac489',
                              color: '#fff',
                              padding: '5px',
                              borderRadius: '50%',
                              fontSize: 'xx-large',

                              border: '2px solid #5ac489',
                            }}
                            aria-owns={this.state.anchorEl1 ? this.state.anchorEl1 : false}
                            aria-haspopup="true"
                            id="appointmentIcon"
                          />
                        </Col>
                        <Col
                          md={10}
                          style={{
                            textAlign: 'left',
                            alignSelf: 'middle',
                            fontSize: 'small',
                          }}
                        >
                          <div>
                            <span className="memberAppointmentHeading">Appointments</span>
                            {appointmentDate || appointmentPurpose || doctorFacilityLocation || accompanyBy ? (
                              <p className="memberAppointments">
                                {appointmentDate && <>{appointmentDate + ', '}</>}
                                {appointmentPurpose && <>{appointmentPurpose + ', '}</>}

                                {doctorFacilityLocation && <>{doctorFacilityLocation + ', '}</>}
                                {accompanyBy && (
                                  <>
                                    {' Accompany By' + ' - '}
                                    <b>{accompanyBy}</b>
                                  </>
                                )}
                              </p>
                            ) : (
                              ''
                            )}
                          </div>
                        </Col>
                      </Row>
                    </li>
                  )}
                  {showMessageThreadPermission === true ? (
                    <li className="memberCommunication" style={{ cursor: 'pointer', marginBottom: '5px' }}>
                      <Row>
                        <Col md={2} style={{ fontSize: 'small', margin: 'auto', alignSelf: 'middle' }}>
                          {totalUnreadMessageCount && (
                            <Badge
                              ref={this.communicationIconRef}
                              badgeContent={totalUnreadMessageCount}
                              color="primary"
                              className="memberBadge"
                              onClick={this.handleCommunicationsClick.bind(this, currentMemberDetails)}
                              overlap="rectangular"
                            />
                          )}
                          <EmailIcon
                            style={{
                              background: '#F39C12',
                              color: '#fff',
                              padding: '5px',
                              borderRadius: '50%',
                              fontSize: 'xx-large',

                              border: '2px solid #F39C12',
                            }}
                            aria-owns={this.state.anchorCommunication ? 'simple-menu2' : undefined}
                            aria-haspopup="true"
                            onClick={this.handleCommunicationsClick.bind(this, currentMemberDetails)}
                            id="communicationIcon"
                          />

                          <Menu
                            id="simple-menu2"
                            anchorEl={this.communicationIconRef.current}
                            open={Boolean(this.state.anchorCommunication)}
                            onClose={this.handleCommunicationClose}
                            // MenuListProps={{ onClick: this.handleCommunicationClose }}

                            onClick={this.handleCommunicationClose}
                          >
                            {this.CommunicationMessageList}
                          </Menu>
                        </Col>
                        <Col
                          md={10}
                          style={{
                            textAlign: 'left',
                            alignSelf: 'middle',
                            fontSize: 'small',
                          }}
                          onClick={(e) => this.goToCommunications(currentMemberDetails.id, currentMemberDetails)}
                        >
                          <div>
                            <span className="memberCommunicationHeading">Communications</span>
                          </div>
                        </Col>
                      </Row>
                    </li>
                  ) : (
                    <li
                      className="memberCommunication"
                      style={{ cursor: 'pointer', marginBottom: '5px', opacity: '0.5', cursor: 'no-drop' }}
                    >
                      <Row>
                        <Col md={2} style={{ fontSize: 'small', margin: 'auto', alignSelf: 'middle' }}>
                          <EmailIcon
                            style={{
                              background: '#F39C12',
                              color: '#fff',
                              padding: '5px',
                              borderRadius: '50%',
                              fontSize: 'xx-large',

                              border: '2px solid #F39C12',
                            }}
                            aria-owns={this.state.anchorCommunication ? 'simple-menu2' : undefined}
                            aria-haspopup="true"
                            id="communicationIcon"
                          />
                        </Col>
                        <Col
                          md={10}
                          style={{
                            textAlign: 'left',
                            alignSelf: 'middle',
                            fontSize: 'small',
                          }}
                        >
                          <div>
                            <span className="memberCommunicationHeading">Communications</span>
                          </div>
                        </Col>
                      </Row>
                    </li>
                  )}
                  {showEventPermission === true ? (
                    <li className="memberEvent" style={{ cursor: 'pointer', marginBottom: '5px' }}>
                      <Row>
                        <Col md={2} style={{ fontSize: 'small', margin: 'auto', alignSelf: 'middle' }}>
                          {upcomingEventCount && (
                            <Badge
                              ref={this.eventIconRef}
                              badgeContent={upcomingEventCount}
                              color="primary"
                              className="memberBadge"
                              onClick={this.handleEventClick.bind(this, currentMemberDetails)}
                              overlap="rectangular"
                            />
                          )}
                          <EventAvailableIcon
                            style={{
                              background: '#8E44AD',
                              color: '#fff',
                              padding: '5px',
                              borderRadius: '50%',
                              fontSize: 'xx-large',

                              border: '2px solid #8E44AD',
                            }}
                            aria-owns={this.state.anchorEvent ? this.state.anchorEvent : false}
                            aria-haspopup="true"
                            onClick={this.handleEventClick.bind(this, currentMemberDetails)}
                            id="eventIcon"
                          />
                          <Menu
                            id="simple-menu1"
                            anchorEl={this.eventIconRef.current}
                            open={Boolean(this.state.anchorEvent)}
                            onClose={this.handleEventClose}
                            onClick={this.handleEventClose}
                          >
                            {this.EventList}
                          </Menu>
                        </Col>
                        <Col
                          md={10}
                          style={{
                            textAlign: 'left',
                            alignSelf: 'middle',
                            fontSize: 'small',
                          }}
                          onClick={(e) => this.goToEvent(currentMemberDetails.member, currentMemberDetails)}
                        >
                          <div>
                            <span className="memberEventHeading">Event</span>
                            <p className="memberEvent">
                              {eventDate && <>{eventDate + ', '}</>}
                              {eventTitle && <>{eventTitle + ', '}</>}
                              {eventLocation && <>{eventLocation}</>}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </li>
                  ) : (
                    <li
                      className="memberEvent"
                      style={{ cursor: 'pointer', marginBottom: '5px', opacity: '0.5', cursor: 'no-drop' }}
                    >
                      <Row>
                        <Col md={2} style={{ fontSize: 'small', margin: 'auto', alignSelf: 'middle' }}>
                          <EventAvailableIcon
                            style={{
                              background: '#8E44AD',
                              color: '#fff',
                              padding: '5px',
                              borderRadius: '50%',
                              fontSize: 'xx-large',

                              border: '2px solid #8E44AD',
                            }}
                            aria-owns={this.state.anchorEvent ? this.state.anchorEvent : false}
                            aria-haspopup="true"
                            id="eventIcon"
                          />
                        </Col>
                        <Col
                          md={10}
                          style={{
                            textAlign: 'left',
                            alignSelf: 'middle',
                            fontSize: 'small',
                          }}
                        >
                          <div>
                            <span className="memberEventHeading">Event</span>
                            <p className="memberEvent"></p>
                          </div>
                        </Col>
                      </Row>
                    </li>
                  )}
                  {showIncidentPermission === true ? (
                    <li className="memberIncident" style={{ cursor: 'pointer', marginBottom: '5px' }}>
                      <Row>
                        <Col md={2} style={{ fontSize: 'small', margin: 'auto', alignSelf: 'middle' }}>
                          <ReportProblemIcon
                            style={{
                              background: '#e74c3c',
                              color: '#fff',
                              padding: '5px',
                              borderRadius: '50%',
                              fontSize: 'xx-large',

                              border: '2px solid #e74c3c',
                            }}
                            id="incidentIcon"
                          />
                        </Col>
                        <Col
                          md={10}
                          style={{
                            textAlign: 'left',
                            alignSelf: 'middle',
                            fontSize: 'small',
                          }}
                          onClick={(e) => this.goToIncident(currentMemberDetails.member, currentMemberDetails)}
                        >
                          <div>
                            <span className="memberIncidentHeading">Incident</span>
                            <p className="memberIncident">
                              {incidentDate !== null && incidentDate !== undefined ? <>{incidentDate + ', '}</> : ''}
                              {incidentType && <>{incidentType + ', '}</>}
                              {incidentLocation && <>{incidentLocation}</>}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </li>
                  ) : (
                    <li
                      className="memberIncident"
                      style={{ cursor: 'pointer', marginBottom: '5px', opacity: '0.5', cursor: 'no-drop' }}
                    >
                      <Row>
                        <Col md={2} style={{ fontSize: 'small', margin: 'auto', alignSelf: 'middle' }}>
                          <ReportProblemIcon
                            style={{
                              background: '#e74c3c',
                              color: '#fff',
                              padding: '5px',
                              borderRadius: '50%',
                              fontSize: 'xx-large',

                              border: '2px solid #e74c3c',
                            }}
                            id="incidentIcon"
                          />
                        </Col>
                        <Col
                          md={10}
                          style={{
                            textAlign: 'left',
                            alignSelf: 'middle',
                            fontSize: 'small',
                          }}
                        >
                          <div>
                            <span className="memberIncidentHeading">
                              {' '}
                              Incident
                              <p className="memberIncident"></p>
                            </span>
                          </div>
                        </Col>
                      </Row>
                    </li>
                  )}
                  <li className="memberTransportation" style={{ cursor: 'pointer', marginBottom: '5px' }}>
                    {totalTransportationCount ? (
                      <Row>
                        <Col md={2} style={{ fontSize: 'small', margin: 'auto', alignSelf: 'middle' }}>
                          {totalTransportationCount && (
                            <Badge
                              ref={this.transportationRequestIconRef}
                              badgeContent={totalTransportationCount}
                              color="primary"
                              className="memberBadge"
                              onClick={this.handleTransportationNotificationClick.bind(this, currentMemberDetails)}
                              overlap="rectangular"
                            />
                          )}

                          <DriveEtaIcon
                            style={{
                              cursor: 'pointer',
                              background: '#5DADE2',
                              color: '#fff',
                              padding: '5px',
                              borderRadius: '50%',
                              fontSize: 'xx-large',

                              border: '2px solid #5DADE2',
                            }}
                            aria-owns={
                              this.state.anchorTransportationRequest ? this.state.anchorTransportationRequest : false
                            }
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            onClick={this.handleTransportationNotificationClick.bind(this, currentMemberDetails)}
                            id="transportationIcon"
                          />

                          <Menu
                            anchorEl={this.transportationRequestIconRef.current}
                            open={Boolean(this.state.anchorTransportationRequest)}
                            onClose={this.handleTransportationClose}
                            onClick={this.handleTransportationClose}
                            id="simple-menu3"
                          >
                            {this.TransportationRequestList}
                          </Menu>
                        </Col>
                        <Col
                          md={10}
                          style={{
                            textAlign: 'left',
                            alignSelf: 'middle',
                            fontSize: 'small',
                          }}
                        >
                          <div>
                            <span
                              className="memberTransportationHeading"
                              onClick={this.handleTransportationNotificationClick.bind(this, currentMemberDetails)}
                            >
                              Transportation
                            </span>
                            <p className="memberTransportation">
                              {this.showTransportationCount(currentMemberDetails.transportationRequests)}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    ) : (
                      <Row>
                        <Col md={2} style={{ fontSize: 'small', margin: 'auto', alignSelf: 'middle' }}>
                          {totalTransportationCount && (
                            <Badge
                              ref={this.transportationRequestIconRef}
                              badgeContent={totalTransportationCount}
                              color="primary"
                              className="memberBadge"
                              overlap="rectangular"
                            />
                          )}

                          <DriveEtaIcon
                            style={{
                              cursor: 'pointer',
                              background: '#5DADE2',
                              color: '#fff',
                              padding: '5px',
                              borderRadius: '50%',
                              fontSize: 'xx-large',

                              border: '2px solid #5DADE2',
                            }}
                            aria-owns={
                              this.state.anchorTransportationRequest ? this.state.anchorTransportationRequest : false
                            }
                            aria-controls="simple-menu"
                            aria-haspopup="true"
                            id="transportationIcon"
                          />

                          <Menu
                            anchorEl={this.transportationRequestIconRef.current}
                            open={Boolean(this.state.anchorTransportationRequest)}
                            onClose={this.handleTransportationClose}
                            onClick={this.handleTransportationClose}
                            id="simple-menu3"
                          >
                            {this.TransportationRequestList}
                          </Menu>
                        </Col>
                        <Col
                          md={10}
                          style={{
                            textAlign: 'left',
                            alignSelf: 'middle',
                            fontSize: 'small',
                          }}
                        >
                          <div>
                            <span className="memberTransportationHeading">Transportation</span>
                            <p className="memberTransportation">
                              {this.showTransportationCount(currentMemberDetails.transportationRequests)}
                            </p>
                          </div>
                        </Col>
                      </Row>
                    )}
                  </li>
                </ul>
              </CardBody>
            </Card>
          </Col>
          <Dialog
            open={this.state.rejectTransportationWindow}
            onClose={this.handleRejectTransportationWindowClose.bind(this)}
            onHide={this.close}
            style={{ padding: '1em' }}
          >
            <DialogContent style={{ marginTop: '2em' }}>
              <Row>
                <Col md={12} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                  <TextareaAutosize
                    aria-label="minimum height"
                    fullWidth
                    style={{ width: '100%' }}
                    variant="outlined"
                    value={this.state.transportationRejectReason}
                    onChange={this.handleTransportationRejectReasonChange.bind(this)}
                    minRows={4}
                    placeholder="Reason"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <DescriptionIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Col>
              </Row>
            </DialogContent>
            <DialogActions style={{ marginTop: '1em', padding: '2em', marginLeft: 'auto', marginRight: 'auto' }}>
              <Button
                variant="contained"
                size="large"
                style={{ background: '#0f666b', color: '#fff', marginRight: '1rem' }}
                startIcon={<SaveIcon />}
                onClick={(e) => this.rejectTransportationRequestInBackend(e)}
              >
                Reject
              </Button>
              <Button
                variant="contained"
                size="large"
                style={{ background: '#717373', color: '#fff', marginRight: '1rem' }}
                startIcon={<CloseIcon />}
                onClick={this.handleRejectTransportationWindowClose.bind(this)}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <Modal isOpen={this.state.consentFormModal} toggle={this.consentFormToggle} size="xl">
            <Row style={{ marginTop: '1em' }}>
              <Col md={11}></Col>
              <Col md={1}>
                <Fab
                  aria-label="add"
                  style={{
                    float: 'left',
                    fontSize: 'small',
                    height: '15px',
                    width: '35px',
                    background: '#76767d',
                    cursor: 'pointer',
                    color: 'white',

                    border: '2px solid #edf0f1',
                  }}
                  id="myRequestIcon"
                >
                  <CloseIcon onClick={(e) => this.consentFormToggle()} />
                </Fab>
              </Col>
            </Row>
            <ModalBody>
              <Row>
                <Col md={12}>
                  <ConsentForm></ConsentForm>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Row md={12} style={{ margin: '0.5em', alignItems: 'center', width: '100%' }}>
                <Col md={6} style={{ textAlign: 'center' }}>
                  <FormControlLabel
                    id="acceptTerms"
                    control={
                      <Checkbox
                        style={{ color: 'black' }}
                        checked={this.state.checkbox}
                        onChange={this.handleClickCheckBox}
                      />
                    }
                    label="I accept the consent form"
                    style={{ color: '#162c50', fontWeight: 'bold' }}
                  />
                </Col>
                <Col md={6} style={{ textAlign: 'center' }}>
                  {this.state.checkbox && this.state.checkbox === true ? (
                    <Button
                      variant="contained"
                      size="small"
                      style={{
                        // float: 'right',
                        background: '#ef6603',
                        color: '#fff',
                      }}
                      startIcon={<ArrowForwardIcon />}
                      onClick={(e) => this.acceptMemberRequestInBackend(e, this.state.familyMemberMemberId)}
                    >
                      Accept
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      size="small"
                      disabled
                      style={{
                        // float: 'right',
                        opacity: '0.5',
                        background: '#ef6603',
                        color: '#fff',
                      }}
                      onClick={(e) => this.acceptMemberRequestInBackend(e, this.state.familyMemberMemberId)}
                    >
                      Accept
                    </Button>
                  )}
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    variant="contained"
                    size="small"
                    style={{ backgroundColor: '#777777', color: '#fff' }}
                    startIcon={<CancelIcon />}
                    onClick={(e) => this.consentFormToggle()}
                  >
                    Decline
                  </Button>
                </Col>
              </Row>
            </ModalFooter>
          </Modal>
          <Modal isOpen={this.state.showMapView} toggle={this.showMapToggle} size="xl">
            <Row style={{ marginTop: '1em' }}>
              <Col md={11}></Col>
              <Col md={1}>
                <Fab
                  aria-label="add"
                  style={{
                    float: 'left',
                    fontSize: 'small',
                    height: '15px',
                    width: '35px',
                    background: '#76767d',
                    cursor: 'pointer',
                    color: 'white',

                    border: '2px solid #edf0f1',
                  }}
                  id="myRequestIcon"
                >
                  <CloseIcon onClick={(e) => this.showMapToggle()} />
                </Fab>
              </Col>
            </Row>
            <ModalBody>
              <Row>
                <Col md={12}>
                  <Map data={this.state.memberAddress1} />
                  {/* <MapLoader
                    data={this.state.memberAddress1}
                    googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyC2X652RPdeR7LYXtbK6X7Vemh_VEQdgMM"
                    loadingElement={<div style={{ height: `100%` }} />}
                  /> */}
                  {/* {this.getMapView(this.state.memberAddress)}{' '} */}
                </Col>
              </Row>
            </ModalBody>
          </Modal>
          {/* <PtsModal
            open={this.state.showMapView}
            onClose={this.showMapToggle.bind(this)}
            // title="Family Care Circle Document"
          >
            <Row style={{ margin: '0em' }}>
              <Col md={12}>
                <Row md={12}>
                  <Col md={12} style={{ padding: '0em' }}>
                    {' '}
                    <span className="closeFloatingButton" aria-label="add">
                      {' '}

                    </span>
                  </Col>
                </Row>{' '}
                <Row>
                  <Col md={12}>
                    <Map data={this.state.memberAddress1} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </PtsModal> */}
        </>
      );
      // });
    }
  }
}

export default MemberCardComponent;
