import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import React, { Component } from 'react';
import Utils from '../../../provider/Utils';
// import store from "../../../redux/store";
class PageAdvertisementMappingList extends Component {
  // header = store.getState().adminHeader.adminHeader;
  // sponsorDetails = store.getState().adminSponsorDetails.adminSponsorDetails;
  constructor(props) {
    super(props);
    let action = props.action;
    this.state = {
      mappingId: props.data.id,
      fccPage: props.data.fccpage.name,
      fccPageId: props.data.fccpage.id,
      index: props.srno,
      onRowEdit: props.onRowEdit,
      onRowUpdate: props.onRowUpdate,
      advertisementList: props.advertisementList,
      pageList: props.pageList,
      advertisement: props.data ? props.data.advertisementId : '',
      viewAdvertisement: props.data.advertisement ? props.data.advertisement : '',
      action: action,
      render: true,
    };
  }

  editUserDetails(e) {
    this.setState({ action: 'edit' });
  }

  handleFccPageChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ fccPage: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }
  handleEmailChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ email: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }

  handlePhoneChange({ target: { value } }) {
    this.setState((prevState) => ({
      phone: Utils.convertPhoneNumberToUsaPhoneNumberFormate(value, prevState.phone),
    }));
  }

  handleAdvertisementChange(e) {
    this.setState({ advertisement: e.target.value });
  }

  updateNote(e) {
    if (this.state.onRowUpdate) {
      this.setState({ action: 'list' });
      this.state.onRowUpdate(this.state.key);
    }
  }

  backToViewAction() {
    this.setState({ action: 'list' });
  }
  render() {
    if (this.state.render === false) {
      return '';
    }
    if (this.state.action === 'list') {
      return this.sponsorUserList();
    } else if (this.state.action === 'edit') {
      return this.editSponsorUser();
    }
  }

  sponsorUserList() {
    return (
      <>
        <TableRow>
          <TableCell component="th" scope="row" width="80px" align="left">
            {this.state.index + 1}
          </TableCell>{' '}
          <TableCell width="400px" align="left">{this.state.fccPage}</TableCell>
          <TableCell width="600px" align="left"> {this.props.data ? this.props.data.uid : ''}  {this.props.data.uid === "" ? "None" : ''}</TableCell>
          <TableCell align="leftr">
            {' '}
            <EditIcon
              style={{
                borderRadius: '25%',
                background: '#867e7e',
                color: 'white',
                fontSize: 'x-large',
                width: '30px',
                minHeight: '30px',
                padding: '3px',
              }}
              onClick={(e) => this.editUserDetails(e, this.props.data)}
            />
          </TableCell>
        </TableRow>
      </>
    );
  }

  editSponsorUser() {
    return (
      <>
        <>
          <TableRow>
            <TableCell component="th" scope="row" width="80px" align="left">
              {this.state.index + 1}
            </TableCell>{' '}
            <TableCell width="400px" align="left">{this.state.fccPage}</TableCell>
            <TableCell align="left">
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="occurrence-label">Advertisement</InputLabel>
                <Select
                  // labelId="occurrence-label"
                  id={'advertisement' + this.state.index}
                  style={{ background: '#fff', width: "500px"  }}
                  value={this.state.advertisement}
                  label="advertisement"
                  required
                  onChange={(e) => {
                    this.handleAdvertisementChange(e);
                  }}
                >
                  {this.props.advertisementList.map((event, keyIndex) => {
                    return (
                      <MenuItem key={keyIndex} value={event.id}>
                        {event.uid}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </TableCell>
            <TableCell align="left">
              <Tooltip title="Create">
                <Fab
                  aria-label="add"
                  style={{
                    fontSize: 'small',
                    height: '15px',
                    width: '35px',
                    background: '#76767d',
                    cursor: 'pointer',
                    color: 'white',
                    backgroundImage: '-webkit-linear-gradient(bottom, #843b07 0%, #f7b571 100%);',
                    border: '2px solid #edf0f1',
                  }}
                  onClick={this.updateNote.bind(this)}
                >
                  {' '}
                  <CheckIcon style={{ cursor: 'pointer' }} />
                </Fab>
              </Tooltip>
              &nbsp;&nbsp;
              <Tooltip title="Cancel">
                <Fab
                  aria-label="add"
                  style={{
                    fontSize: 'small',
                    height: '15px',
                    width: '35px',
                    background: '#76767d',
                    cursor: 'pointer',
                    color: 'white',
                    backgroundImage: '-webkit-linear-gradient(bottom, #843b07 0%, #f7b571 100%);',
                    border: '2px solid #edf0f1',
                  }}
                  onClick={(e) => this.backToViewAction(e)}
                >
                  <CloseIcon style={{ cursor: 'pointer' }} />
                </Fab>
              </Tooltip>
            </TableCell>
          </TableRow>
        </>
      </>
    );
  }
}

export default PageAdvertisementMappingList;
