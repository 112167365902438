import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { createMuiTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Tooltip from '@material-ui/core/Tooltip';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import OfflinePinIcon from '@material-ui/icons/OfflinePin';
import PrintRoundedIcon from '@material-ui/icons/PrintRounded';
import SaveIcon from '@material-ui/icons/Save';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import es from 'date-fns/locale/es';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import moment from 'moment';
import React, { Component } from 'react';
import { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Geocode from 'react-geocode';
import { ValidatorForm } from 'react-material-ui-form-validator';
import 'react-tabs/style/react-tabs.css';
import ReactToPrint from 'react-to-print';
import { Card, CardBody, CardFooter, CardHeader, Col, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import fccAddressStateAction from '../../../redux/fccAddressState/fccAddressStateAction';
import fccPageStateAction from '../../../redux/fccPageState/fccPageStateAction';
import helpDataAction from '../../../redux/help/displayHelpAction';
import store from '../../../redux/store';
import AddIconButton from '../buttons/AddIconButton';
import BackButton from '../buttons/BackButton';
import CancelIconButton from '../buttons/CancelIconButton';
import CreateButton from '../buttons/CreateButton';
import EditIconButton from '../buttons/EditIconButton';
import SaveButton from '../buttons/SaveButton';
import EventTransportation from '../eventTransportation/EventTransportation';
import Location from '../location/Location';
import Map from '../map/Map';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
import PtsAlert from '../ptsAlert/PtsAlert';
import Reminder from '../reminder/Reminder';
import ShortLocation from '../shortLocation/ShortLocation';
import './MemberEvent.scss';
import MemberEventNote from './MemberEventNote';
registerLocale('es', es);

export default class MemberEvent extends Component {
  eventTypeList = store.getState().staticData.eventTypeListArray;
  header = store.getState().header.header;
  memberDetails = store.getState().memberData.memberData;
  memberName = store.getState().memberData.memberData.firstname + ' ' + store.getState().memberData.memberData.lastname;
  isFreeUser = store.getState().userData.userData.isFreeUser;
  memberHomeUrl = store.getState().memberHomeUrl.memberHomeUrl.homeUrl;
  displayHelp = store.getState().help.help;
  fccPageState = store.getState().fccPageState.fccPageState;
  userEmail = store.getState().userData.userData.email;
  addressRef = React.createRef();
  addressRef = {};
  title = 'Event Details';
  breadCrumb = [
    { pageid: this.memberHomeUrl, uiname: this.memberName },
    { pageid: '/member_event', uiname: 'Event List' },
    { pageid: '', uiname: 'Event Details' },
  ];

  locationReferenceList = [];
  eventReminderRefList = [];
  eventNoteRefList = [];
  eventTypeOptionList = [];

  constructor(props) {
    super(props);
    let calenderStartDate = new Date();
    let eventId = '';
    let action = '';
    let title = '';
    let pageId = '';
    let isButtonHide = false;
    let isEditButtonHide = true;
    let isViewButtonHide = true;
    let isPrintView = props.isPrintView;
    let canRender = false;
    let eventData = '';
    let eventDataId = '';
    let eventNoteId = '';
    let str = window.location.href;
    let eventDetails = {};
    let urlArr = str.split('?');
    let eventVo = urlArr[1];
    if (eventVo) {
      let eventDataVo = eventVo.split('=');
      eventData = eventDataVo[1].split('&');
      eventDataId = eventData[0];
      eventNoteId = eventDataVo[2];
      eventNoteId = eventNoteId.split('&');
      eventNoteId = eventNoteId[0];

      action = 'view';
      this.getEventDetails(eventDataId);
    } else {
      eventId = props.eventId;
      action = props.action;

      if (!eventId && props.history && props.history.location && props.history.location.state) {
        eventId = props.history.location.state.eventId;
        action = props.history.location.state.action;
        pageId = props.history.location.state.pageId;
        title = props.history.location.state.title;
        eventDetails = props.history.location.state.eventdata;
        if (props.history.location.state.startDate) {
          calenderStartDate = new Date(moment(props.history.location.state.startDate));
        }
      }
      isButtonHide = false;
      isEditButtonHide = true;
      canRender = false;
      if (null !== eventId && eventId > 0) {
        this.getEventDetails(eventId);
      } else {
        canRender = true;
      }
    }
    if (isPrintView === 'view_print') {
      isViewButtonHide = false;
    }

    if (eventId === undefined) {
      eventId = this.fccPageState.id;
      action = this.fccPageState.action;
      if (action !== 'create') {
        this.getEventDetails(eventId);
      }
    }
    // if (this.breadCrumb) {
    //   Utils.getActiveMenu(this.breadCrumb);
    //   store.dispatch(BreadCrumbDetailsAction.setBreadCrumbDetails(this.breadCrumb));
    // }
    if (pageId === 'createEvent') {
      let createdEventData = eventDetails.event;
      let eventData = createdEventData.eventWithPreviousEvent;
      let date = createdEventData.date;
      let newStartDate = Utils.convertUTCDateToUserTimeZoneDate(date);
      let viewStartDate = Utils.convertUTCDateToUserTimeZoneViewDateFormate(date);

      let getDomainObjectPermission = eventDetails.domainObjectPrivileges;

      let getCreatePermission = false;
      let getReadPermission = false;
      let getUpdatePermission = false;
      let getDeletePermission = false;
      let showEventPermission = false;
      let showEventNotePermission = false;
      let getEventNoteCreatePermission = false;
      let getEventNoteReadPermission = false;
      let getEventNoteUpdatePermission = false;
      let getEventNoteDeletePermission = false;
      let showEventTransportationPermission = false;
      let getEventTransportationCreatePermission = false;
      let getEventTransportationReadPermission = false;
      let getEventTransportationUpdatePermission = false;
      let getEventTransportationDeletePermission = false;
      for (let domainObjectKey in getDomainObjectPermission) {
        if (domainObjectKey === 'Event') {
          let eventPermission = getDomainObjectPermission[domainObjectKey];
          let getPermission = Utils.getDomainObjectRolePermission(eventPermission);

          getCreatePermission = getPermission[0];
          getReadPermission = getPermission[1];
          getUpdatePermission = getPermission[2];
          getDeletePermission = getPermission[3];

          showEventPermission = true;
          getCreatePermission = getCreatePermission;
          getReadPermission = getReadPermission;
          getUpdatePermission = getUpdatePermission;
          getDeletePermission = getDeletePermission;
        } else if (domainObjectKey === 'EventNote') {
          let eventNotePermission = getDomainObjectPermission[domainObjectKey];

          if (eventNotePermission.length > 0 && eventNotePermission !== null) {
            showEventNotePermission = true;
          }
          let getPermission = Utils.getDomainObjectRolePermission(eventNotePermission);
          getCreatePermission = getPermission[0];
          getReadPermission = getPermission[1];
          getUpdatePermission = getPermission[2];
          getDeletePermission = getPermission[3];

          getEventNoteCreatePermission = getCreatePermission;
          getEventNoteReadPermission = getReadPermission;
          getEventNoteUpdatePermission = getUpdatePermission;
          getEventNoteDeletePermission = getDeletePermission;
        } else if (domainObjectKey === 'EventTransportation') {
          let eventTransportationPermission = getDomainObjectPermission[domainObjectKey];

          if (eventTransportationPermission.length > 0 && eventTransportationPermission !== null) {
            showEventTransportationPermission = true;
          }
          let getPermission = Utils.getDomainObjectRolePermission(eventTransportationPermission);
          getCreatePermission = getPermission[0];
          getReadPermission = getPermission[1];
          getUpdatePermission = getPermission[2];
          getDeletePermission = getPermission[3];

          getEventTransportationCreatePermission = getCreatePermission;
          getEventTransportationReadPermission = getReadPermission;
          getEventTransportationUpdatePermission = getUpdatePermission;
          getEventTransportationDeletePermission = getDeletePermission;
        }
      }

      let locationList = [];
      locationList.push(createdEventData.location);
      this.state = {
        eventNoteList: [],

        getUpdatePermission: getUpdatePermission,
        getDeletePermission: getDeletePermission,
        getReadPermission: getReadPermission,
        getCreatePermission: getCreatePermission,
        id: createdEventData.id,
        eventData: createdEventData,
        eventType: createdEventData.type,
        viewEventTitle: createdEventData.title,
        type: createdEventData.type?.type,
        eventStartDate: viewStartDate,
        startDate: new Date(newStartDate),
        timeView: createdEventData.dateTime,
        locationDetails: createdEventData.location,
        address: createdEventData.location,
        locationList: locationList,
        render: true,
        eventTitle: createdEventData.title,
        note: createdEventData.description,
        isEditButtonHide: true,
        isButtonHide: false,
        title: title,
        isPrintView: isPrintView,
        isViewButtonHide: isViewButtonHide,
        pageId: pageId,
        action: action,
        eventDetails: createdEventData.event,
        isFreeUser: this.isFreeUser,
        noteId: eventNoteId,
        stepsEnabled: false,
        initialStep: 0,
        currentCompareDateTime: new Date(),
        reminderItems: [],
        referenceEventReminderItems: [],
        transportationList: [],

        status: createdEventData.status.status,

        steps: [
          {
            element: '.editDetails',
            intro: 'Edit/update Event Details',
          },
          {
            element: '.noteTitle',
            intro:
              'Notes can be added, edited, deleted. Communication for this Note is started by adding Note and clicking on Messaging icon.',
          },
          {
            element: '.addNote',
            intro: 'Add Notes. User can add multiple Notes. ',
          },
          {
            element: '.reminderTitle',
            intro: 'Reminders can be set for future purchases.',
          },
          {
            element: '.newReminder',
            intro: 'Add reminders. User can add multiple Reminders.',
          },
          {
            element: '.print',
            intro: 'Print Event Details',
          },
          {
            element: '.createSimilar',
            intro: 'create Similar Event ',
          },
          {
            element: '.delete',
            intro: ' Delete Event Details',
          },
        ],
        stepsWithNote: [
          {
            element: '.editDetails',
            intro: 'Edit/update Event Details',
          },
          {
            element: '.noteTitle',
            intro:
              'Notes can be added, edited, deleted. Communication for this Note is started by adding Note and clicking on Messaging icon.',
          },
          {
            element: '.addNote',
            intro: 'Add Notes. User can add multiple Notes. ',
          },
          {
            element: '.deleteIcon',
            intro: 'Delete Note ',
          },
          {
            element: '.editEventNote',
            intro: 'Edit Note ',
          },

          {
            element: '.startCommunicationNote',
            intro: 'Start new Communication Thread',
          },
          {
            element: '.createdBy',
            intro: 'Check who has created this note at what date and time.',
          },
          {
            element: '.reminderTitle',
            intro: 'Reminders can be set for future purchases.',
          },
          {
            element: '.newReminder',
            intro: 'Add reminders. User can add multiple Reminders.',
          },
          {
            element: '.print',
            intro: 'Print Event Details',
          },
          {
            element: '.createSimilar',
            intro: 'create Similar Event ',
          },
          {
            element: '.delete',
            intro: ' Delete Event Details',
          },
        ],
        displayFloatButton: this.displayHelp,
        showMapView: false,
        showLocationView: true,
        eventTransportation: {},
        openCancelAppointment: false,
        showEventNotePermission: showEventNotePermission,
        getEventNoteCreatePermission: getEventNoteCreatePermission,
        getEventNoteReadPermission: getEventNoteReadPermission,
        getEventNoteUpdatePermission: getEventNoteUpdatePermission,
        getEventNoteDeletePermission: getEventNoteDeletePermission,
        showEventTransportationPermission: showEventTransportationPermission,
        getEventTransportationCreatePermission: getEventTransportationCreatePermission,
        getEventTransportationReadPermission: getEventTransportationReadPermission,
        getEventTransportationUpdatePermission: getEventTransportationUpdatePermission,
        getEventTransportationDeletePermission: getEventTransportationDeletePermission,
      };
    } else {
      // let date = Utils.convertUTCDateToUserTimeZoneDate(calenderStartDate);
      this.state = {
        id: eventId,
        noteId: eventNoteId,
        action: action,
        render: canRender,
        showSuccessAlert: false,
        showFailAlert: false,
        startDate: new Date(calenderStartDate),
        // startDate: calenderStartDate,
        description: '',
        reminderItems: [],
        // appoiPurpose: '',
        locationList: [
          {
            operation: 'add',
            action: 'defaultCreate',
            nickName: '',
            shortDescription: '',
            fax: '',
            website: '',
            phone: '',
            address: {
              line: '',
              city: '',
              zipCode: '',
              state: {
                code: '',
              },
            },
          },
        ],
        viewLocationitems: [],
        title: title,
        pageId: pageId,
        locationItemsView: true,
        createFacilityTypeModal: false,
        locationItemsCreateDiv: false,
        facilityEditDiv: false,
        facilityViewDiv: true,
        sweetAlert: false,
        isButtonHide: isButtonHide,
        isEditButtonHide: isEditButtonHide,
        contactList: [],
        memberList: [],
        eventData: '',
        locationDetails: {},
        contactDetails: {},
        eventReminderList: [],
        eventNoteCreateList: [],
        eventNoteList: [],
        noteAlert: false,
        isMember: true,
        isContact: false,
        contactListId: '',
        eventType: '',
        eventTitle: '',
        viewEventTitle: '',
        isPrintView: isPrintView,
        isViewButtonHide: isViewButtonHide,
        getUpdatePermission: false,
        getDeletePermission: false,
        getReadPermission: false,
        getCreatePermission: false,
        isFreeUser: this.isFreeUser,
        eventDetails: {},
        stepsEnabled: false,
        initialStep: 0,
        currentCompareDateTime: new Date(),
        referenceEventReminderItems: [],
        referenceEventStartDate: new Date(),

        taskReminderListDiv: true,

        transportationList: [],
        eventTransportation: {},

        steps: [
          {
            element: '.editDetails',
            intro: 'Edit/update Event Details',
          },
          {
            element: '.noteTitle',
            intro:
              'Notes can be added, edited, deleted. Communication for this Note is started by adding Note and clicking on Messaging icon.',
          },

          {
            element: '.addNote',
            intro: 'Add Notes. User can add multiple Notes. ',
          },
          {
            element: '.reminderTitle',
            intro: 'Reminders can be set for future purchases.',
          },
          {
            element: '.newReminder',
            intro: 'Add reminders. User can add multiple Reminders.',
          },
          {
            element: '.print',
            intro: 'Print Event Details',
          },
          {
            element: '.createSimilar',
            intro: 'create Similar Event ',
          },

          {
            element: '.delete',
            intro: ' Delete Event Details',
          },
        ],
        stepsWithNote: [
          {
            element: '.editDetails',
            intro: 'Edit/update Event Details',
          },
          {
            element: '.noteTitle',
            intro:
              'Notes can be added, edited, deleted. Communication for this Note is started by adding Note and clicking on Messaging icon.',
          },
          {
            element: '.addNote',
            intro: 'Add Notes. User can add multiple Notes. ',
          },
          {
            element: '.deleteIcon',
            intro: 'Delete Note ',
          },
          {
            element: '.editEventNote',
            intro: 'Edit Note ',
          },

          {
            element: '.startCommunicationNote',
            intro: 'Start new Communication Thread',
          },
          {
            element: '.createdBy',
            intro: 'Check who has created this note at what date and time.',
          },
          {
            element: '.reminderTitle',
            intro: 'Reminders can be set for future purchases.',
          },
          {
            element: '.newReminder',
            intro: 'Add reminders. User can add multiple Reminders.',
          },
          {
            element: '.print',
            intro: 'Print Event Details',
          },
          {
            element: '.createSimilar',
            intro: 'create Similar Event ',
          },
          {
            element: '.delete',
            intro: ' Delete Event Details',
          },
        ],

        displayFloatButton: this.displayHelp,
        showMapView: false,
        showLocationView: true,
        openCancelAppointment: false,
        status: '',
        showEventNotePermission: false,
        getEventNoteCreatePermission: false,
        getEventNoteReadPermission: false,
        getEventNoteUpdatePermission: false,
        getEventNoteDeletePermission: false,
        showEventTransportationPermission: false,
        getEventTransportationCreatePermission: false,
        getEventTransportationReadPermission: false,
        getEventTransportationUpdatePermission: false,
        getEventTransportationDeletePermission: false,
        sameDateEventWindow: false,
      };
    }

    this.locationReferenceList.push(React.createRef());
  }

  closeFloatingButton() {
    this.setState({ displayFloatButton: false });
    let displayFloat = false;

    store.dispatch(helpDataAction.setHelpData(displayFloat));
  }

  toggleSteps = () => {
    this.setState((prevState) => ({ stepsEnabled: !prevState.stepsEnabled }));
  };

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };

  handleEventStartDateChange(date) {
    this.setState({ startDate: date });
  }

  handleEventTitleChange(e) {
    this.setState({ eventTitle: e.target.value });
  }

  handleEventTypeChange(e) {
    this.setState({ eventType: e.target.value });
  }

  handleDescriptionChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 256);
    if (res.length < 256) {
      this.setState({ note: res });
    } else {
      Utils.maxFieldLength(256);
    }
  }

  async getEventDetails(eventId) {
    let id = eventId;
    const postObject = {
      header: this.header,
      event: {
        id: id,
        member: {
          id: this.memberDetails.member,
        },
      },
    };

    await FetchServerData.callPostService('eventms/get', postObject).then(async (output) => {
      if (output.status === 'SUCCESS') {
        let eventData = output.data.event;

        let getDomainObjectPermission = output.data.domainObjectPrivileges;
        let getCreatePermission = false;
        let getReadPermission = false;
        let getUpdatePermission = false;
        let getDeletePermission = false;

        for (let domainObjectKey in getDomainObjectPermission) {
          if (domainObjectKey.toLowerCase() === 'event') {
            let eventPermission = getDomainObjectPermission[domainObjectKey];
            let getPermission = Utils.getDomainObjectRolePermission(eventPermission);

            getCreatePermission = getPermission[0];
            getReadPermission = getPermission[1];
            getUpdatePermission = getPermission[2];
            getDeletePermission = getPermission[3];
            this.setState({
              showEventPermission: true,
              getCreatePermission: getCreatePermission,
              getReadPermission: getReadPermission,
              getUpdatePermission: getUpdatePermission,
              getDeletePermission: getDeletePermission,
            });
          } else if (domainObjectKey.toLowerCase() === 'eventnote') {
            let eventNotePermission = getDomainObjectPermission[domainObjectKey];
            let showEventNotePermission = false;
            if (eventNotePermission.length > 0 && eventNotePermission !== null) {
              showEventNotePermission = true;
            }
            let getPermission = Utils.getDomainObjectRolePermission(eventNotePermission);
            getCreatePermission = getPermission[0];
            getReadPermission = getPermission[1];
            getUpdatePermission = getPermission[2];
            getDeletePermission = getPermission[3];
            this.setState({
              showEventNotePermission: showEventNotePermission,
              getEventNoteCreatePermission: getCreatePermission,
              getEventNoteReadPermission: getReadPermission,
              getEventNoteUpdatePermission: getUpdatePermission,
              getEventNoteDeletePermission: getDeletePermission,
            });
          } else if (domainObjectKey.toLowerCase() === 'eventtransportation') {
            let eventTransportationPermission = getDomainObjectPermission[domainObjectKey];
            let showEventTransportationPermission = false;
            if (eventTransportationPermission.length > 0 && eventTransportationPermission !== null) {
              showEventTransportationPermission = true;
            }
            let getPermission = Utils.getDomainObjectRolePermission(eventTransportationPermission);
            getCreatePermission = getPermission[0];
            getReadPermission = getPermission[1];
            getUpdatePermission = getPermission[2];
            getDeletePermission = getPermission[3];
            this.setState({
              showEventTransportationPermission: showEventTransportationPermission,
              getEventTransportationCreatePermission: getCreatePermission,
              getEventTransportationReadPermission: getReadPermission,
              getEventTransportationUpdatePermission: getUpdatePermission,
              getEventTransportationDeletePermission: getDeletePermission,
            });
          }
        }
        let isMember = '';
        let locationDetails = [];
        let date = eventData.date;
        let newStartDate = Utils.convertUTCDateToUserTimeZoneDate(date);
        let viewStartDate = Utils.convertUTCDateToUserTimeZoneViewDateFormate(date);
        let googleLocationAPIKey = Utils.getLocationAPIKey();
        for (let i = 0; i < eventData.noteList.length; i++) {
          eventData.noteList[i].action = 'view';
        }
        this.state.locationList = [];
        if (eventData.location !== null) {
          this.state.locationList.push(eventData.location);
        } else {
        }
        if (eventData.location !== null) {
          store.dispatch(fccAddressStateAction.setFccAddressState(eventData.location.address));
        }
        Geocode.setApiKey(googleLocationAPIKey);
        Geocode.setLanguage('en');
        Geocode.setLocationType('ROOFTOP');
        Geocode.enableDebug();
        if (eventData.location !== null) {
          locationDetails.push(eventData.location);
        }
        let locaData1 = [];
        if (locationDetails !== null) {
          for (let i = 0; i < locationDetails.length; i++) {
            let locdata = await Utils.getLatLongMapData(
              locationDetails[i].shortDescription,
              locationDetails[i].address.line,
              locationDetails[i].address.city,
              locationDetails[i].address.state.country.name,
              locationDetails[i].address.state.name,
              i
            );
            locaData1.push(locdata);
          }
        }
        if (eventData.location !== null) {
          this.setState({
            render: true,
            eventDetails: eventData,
            eventData: eventData,
            eventType: eventData.type,
            eventTitle: eventData.title,
            viewEventTitle: eventData.title,
            type: eventData.type?.type,
            eventStartDate: viewStartDate,
            description: eventData.description,
            startDate: new Date(newStartDate),
            timeView: eventData.dateTime,
            locationDetails: eventData.location,
            address: eventData.location,
            eventNoteList: eventData.noteList,
            addressLocations: locaData1,
            locationList: this.state.locationList,
            noteListRow: true,
            isMember: isMember,
            note: eventData.description,

            currentCompareDateTime: new Date(),
            referenceEventReminderItems: [],
            reminderItems: eventData.reminderList,
            transportationList: eventData.transportationList,
            transportationAddress: eventData.location.address,
            status: eventData.status.status,
          });
        } else {
          this.setState({
            render: true,
            eventDetails: eventData,
            eventData: eventData,
            eventType: eventData.type,
            viewEventTitle: eventData.title,
            type: eventData.type?.type,
            eventStartDate: viewStartDate,
            description: eventData.description,
            startDate: new Date(newStartDate),
            timeView: eventData.dateTime,
            locationDetails: {},
            eventNoteList: eventData.noteList,
            locationList: this.state.locationList,
            noteListRow: true,
            isMember: isMember,
            eventTitle: eventData.title,
            note: eventData.description,

            currentCompareDateTime: new Date(),
            referenceEventReminderItems: [],
            referenceEventStartDate: new Date(),
            transportationList: eventData.transportationList,
            transportationAddress: {},
            status: eventData.status.status,
          });
        }

        if (eventData.transportationList !== null && eventData.transportationList.length > 0) {
          for (let i = 0; i < eventData.transportationList.length; i++) {
            let ref = React.createRef();
            this.eventReminderRefList.push(ref);
            /*Set Default Operation as Update */
            eventData.transportationList[i].transportationId = i;
          }
        }
      } else {
      }
    });
  }

  cancelCreate() {
    if (this.state.pageId === 'memberdeatils') {
      this.props.history.push({
        pathname: '/memberDetails',
      });
    } else {
      this.props.history.push({
        pathname: '/member_event',
      });
    }
  }
  cancelView() {
    if (this.state.pageId === 'memberdeatils') {
      this.props.history.push({
        pathname: '/memberDetails',
      });
    } else {
      this.props.history.push({
        pathname: '/member_event',
      });
    }
  }

  editCancel(e, eventId) {
    this.setState({
      isEditButtonHide: true,
      isButtonHide: false,
    });
  }
  cancelPrintView() {
    this.setState({ action: 'view' });
  }
  deleteAlert(e, facilityId) {
    this.setState({
      sweetAlert: true,
    });
  }

  cancelDelete(e, facilityId) {
    this.setState({
      sweetAlert: false,
      reminderSweetAlert: false,
      noteAlert: false,
    });
  }

  addNewLocation(locationData) {
    for (let i = 0; i < this.state.locationList.length; i++) {
      if (
        this.state.locationList[i] &&
        locationData.index === i &&
        this.locationReferenceList[i] &&
        this.locationReferenceList[i].current &&
        this.locationReferenceList[i].current.validateLocation()
      ) {
        this.state.locationList[i] = locationData;
        this.locationReferenceList[i].current.state = locationData;
        this.locationReferenceList[i].current.state.action = 'editLocation';
        this.setState({ render: true });
      }
    }
  }

  updateLocation(locationData) {
    for (let i = 0; i < this.state.locationList.length; i++) {
      if (
        this.state.locationList[i] &&
        locationData.index === i &&
        this.locationReferenceList[i] &&
        this.locationReferenceList[i].current &&
        this.locationReferenceList[i].current.validateLocation()
      ) {
        this.state.locationList[i] = locationData;
        this.locationReferenceList[i].current.state = locationData;
        this.locationReferenceList[i].current.state.action = 'editLocation';
        this.setState({ render: true });
      }
    }
  }

  addNotes() {
    let notes = this.state.eventNoteList;
    let note = {
      action: 'create',
      operation: 'add',
      shortDescription: this.state.eventTitle,
      event: {
        id: this.state.id,
      },
    };

    notes.push(note);
    this.setState({ eventNoteList: notes });
  }
  editNote(type, note) {}

  updateEventNote = (eventNote) => {
    let postObject = {};
    for (let i = 0; i < this.state.eventNoteList.length; i++) {
      if (eventNote.id === this.state.eventNoteList[i].id) {
        postObject = {
          header: this.header,
          note: {
            id: eventNote.id,
            note: eventNote.note,

            event: {
              id: this.state.id,
            },

            shortDescription: eventNote.shortDescription,
          },
        };
        FetchServerData.callPostService('eventms/updateNote', postObject).then((output) => {
          if (output.status === 'SUCCESS') {
            let outputData = output.data;
            this.state.eventNoteList = [];
            this.setState({
              eventNoteList: outputData.noteList,
            });
          } else {
          }
        });
      }
    }
  };
  addEventNotesInBackend(note) {
    for (let i = 0; i < this.state.eventNoteList.length; i++) {
      if (
        this.state.eventNoteList[i] &&
        note.index === i &&
        this.eventNoteRefList[i] &&
        this.eventNoteRefList[i].current
      ) {
        let noteObj = {
          event: {
            id: this.state.id,
          },

          note: this.eventNoteRefList[i].current.state.note,
          shortDescription: this.eventNoteRefList[i].current.state.shortDescription,
        };
        const postObject = {
          header: this.header,
          note: noteObj,
        };
        FetchServerData.callPostService('eventms/createNote', postObject)
          .then((output) => {
            if (output.status === 'SUCCESS') {
              let noteListData = output.data;

              for (let i = 0; i < this.state.eventNoteList.length; i++) {
                this.eventNoteRefList[i].current.state.action = 'view';
              }

              this.setState({
                eventNoteList: noteListData.noteList,
              });
            } else {
            }
          })
          .catch((error) => {});
      }
    }
  }

  backToEventList() {
    this.setState({ createNoteListRow: false, noteListRow: true });
  }

  addReminder(type) {
    let items = this.state.reminderItems;
    let item = {
      action: 'create',
      operation: 'add',
      content: this.state.eventTitle,
    };
    items.push(item);
    this.eventReminderRefList.push(React.createRef());

    this.setState({ reminderItems: items });
  }

  addNewReminder = (reminderData) => {
    for (let i = 0; i < this.state.reminderItems.length; i++) {
      if (
        this.state.reminderItems[i] &&
        reminderData.index === i &&
        this.eventReminderRefList[i] &&
        this.eventReminderRefList[i].current &&
        this.eventReminderRefList[i].current.validateReminder(i)
      ) {
        this.state.reminderItems[i] = reminderData;
        this.eventReminderRefList[i].current.state = reminderData;
        this.eventReminderRefList[i].current.state.action = 'view';
        this.setState({ render: true });
      }
    }
  };

  addNewReminderToBackend = (reminderData) => {
    let postObject = {};
    for (let i = 0; i < this.state.reminderItems.length; i++) {
      if (
        this.state.reminderItems[i] &&
        reminderData.index === i &&
        this.eventReminderRefList[i] &&
        this.eventReminderRefList[i].current &&
        this.eventReminderRefList[i].current.validateReminder(i)
      ) {
        this.state.reminderItems[i] = reminderData;
        this.eventReminderRefList[i].current.state = reminderData;
        let reminderDateTime = Utils.convertUserTimeZoneDateToUTCDate(
          this.eventReminderRefList[i].current.state.startDate
        );
        postObject = {
          header: this.header,
          reminder: {
            content: this.eventReminderRefList[i].current.state.note,
            date: reminderDateTime,
            event: {
              id: this.state.id,
            },
          },
        };
        FetchServerData.callPostService('/eventms/createReminder', postObject)
          .then((output) => {
            if (output.status === 'SUCCESS') {
              this.state.reminderItems = [];
              let reminderListData = output.data;
              this.eventReminderRefList[i].current.state.action = 'view';
              let reminders = [];
              PtsAlert.success(output.message);
              if (reminderListData.length > 0) {
                for (let i = 0; i < reminderListData.length; i++) {
                  reminders.push(reminderListData[i]);
                }
              }
              this.setState({
                render: true,
                reminderItems: reminders,
              });
            } else {
            }
          })
          .catch((error) => {});
      }
    }
  };

  updateReminder = (reminderData) => {
    let postObject = {};
    for (let i = 0; i < this.state.reminderItems.length; i++) {
      if (
        this.state.reminderItems[i] &&
        reminderData.index === i &&
        this.eventReminderRefList[i] &&
        this.eventReminderRefList[i].current &&
        this.eventReminderRefList[i].current.validateReminder()
      ) {
        this.state.reminderItems[i] = reminderData;
        this.eventReminderRefList[i].current.state = reminderData;

        let reminderDateTime = Utils.convertUserTimeZoneDateToUTCDate(
          this.eventReminderRefList[i].current.state.startDate
        );
        postObject = {
          header: this.header,
          reminder: {
            content: this.eventReminderRefList[i].current.state.note,
            id: this.eventReminderRefList[i].current.state.id,
            date: reminderDateTime,
            event: {
              id: this.state.id,
            },
          },
        };
        FetchServerData.callPostService('/eventms/updateReminder', postObject)
          .then((output) => {
            if (output.status === 'SUCCESS') {
              let outputData = output.data;
              this.state.reminderItems = [];
              this.setState({
                reminderItems: outputData,
              });
            } else {
            }
          })
          .catch((error) => {});
      }
    }
  };

  deleteReminderInBackend = (reminderData) => {
    const postObject = {
      header: this.header,
      reminder: {
        id: reminderData.id,
        event: {
          id: this.state.id,
        },
      },
    };

    FetchServerData.callPostService('/eventms/deleteReminder', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          for (let i = 0; i < this.state.reminderItems.length; i++) {
            if (this.state.reminderItems[i].id === reminderData.id) {
              this.state.reminderItems.splice(i, 1);
            }
          }
          this.setState({
            reminderItems: this.state.reminderItems,
            sweetAlert: false,
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  deleteReminder(item) {
    let items = [];
    if (item.operation && 'update' === item.operation) {
      /* Existing Item Deleted, Mark it deleted */
      let index = item.index;

      for (let i = 0; i < this.state.reminderItems.length; i++) {
        let item = this.state.reminderItems[i];
        if (this.state.reminderItems[i].operation === 'update' && index === i) {
          item.operation = 'delete';
        }

        items.push(item);
      }

      this.setState({ reminderItems: items });
    }
    if (item.operation && 'add' === item.operation) {
      /* Added Item Deleted, Remove from list */
      let index = item.index;
      for (let i = 0; i < this.state.reminderItems.length; i++) {
        if (this.state.reminderItems[i].operation === 'add' && index === i) {
          this.eventReminderRefList.splice(i, 1);
          // continue;
        } else {
          items.push(this.state.reminderItems[i]);
        }
      }
      this.setState({ reminderItems: items });
    }
  }

  addReferenceEventNewReminder = (reminderData) => {
    for (let i = 0; i < this.state.referenceEventReminderItems.length; i++) {
      if (
        this.state.referenceEventReminderItems[i] &&
        reminderData.index === i &&
        this.eventReminderRefList[i] &&
        this.eventReminderRefList[i].current &&
        this.eventReminderRefList[i].current.validateReminder(i)
      ) {
        this.state.referenceEventReminderItems[i] = reminderData;
        this.eventReminderRefList[i].current.state = reminderData;
        this.eventReminderRefList[i].current.state.action = 'view';
        this.setState({ render: true });
      }
    }
  };

  updateReferenceEventReminder = (reminderData) => {
    for (let i = 0; i < this.state.referenceEventReminderItems.length; i++) {
      if (
        this.state.referenceEventReminderItems[i] &&
        reminderData.index === i &&
        this.eventReminderRefList[i] &&
        this.eventReminderRefList[i].current &&
        this.eventReminderRefList[i].current.validateReminder()
      ) {
        this.state.referenceEventReminderItems[i] = reminderData;
        this.eventReminderRefList[i].current.state = reminderData;
        this.eventReminderRefList[i].current.state.action = 'view';
        this.setState({ render: true });
      }
    }
  };

  referenceEventReminderItemDeleted(item) {
    let items = [];
    if (item.operation && 'update' === item.operation) {
      /* Existing Item Deleted, Mark it deleted */
      let index = item.index;

      for (let i = 0; i < this.state.referenceEventReminderItems.length; i++) {
        let item = this.state.referenceEventReminderItems[i];
        if (this.state.referenceEventReminderItems[i].operation === 'update' && index === i) {
          item.operation = 'delete';
        }

        items.push(item);
      }

      this.setState({ referenceEventReminderItems: items });
    }
    if (item.operation && 'add' === item.operation) {
      /* Added Item Deleted, Remove from list */
      let index = item.index;
      for (let i = 0; i < this.state.referenceEventReminderItems.length; i++) {
        if (this.state.referenceEventReminderItems[i].operation === 'add' && index === i) {
          this.eventReminderRefList.splice(i, 1);
          // continue;
        } else {
          items.push(this.state.referenceEventReminderItems[i]);
        }
      }
      this.setState({ referenceEventReminderItems: items });
    }
  }

  transportationToggle = () => {
    this.setState({ transportationModal: !this.state.transportationModal });
  };

  /* delete Transportation Data In Backend */
  deleteTransportation = (transportationData) => {};

  addTransportationInBackend = (transportationData) => {
    let transportationReminder = [];
    let postObject = {};

    if (this.eventTransportationRef.current.state) {
      let reminderList = this.eventTransportationRef.current.state.transportationReminder;
      for (let i = 0; i < reminderList.length; i++) {
        let reminderDateTime = Utils.convertUserTimeZoneDateToUTCDate(reminderList[i].startDate);

        let reminderObj = {
          content: reminderList[i].note,
          date: reminderDateTime,

          operation: reminderList[i].operation,
        };
        transportationReminder.push(reminderObj);
      }

      let eventTransportationDate = this.eventTransportationRef.current.state.transportationDate;

      let dateTime = Utils.convertUserTimeZoneDateToUTCDate(eventTransportationDate);

      postObject = {
        header: this.header,

        transportation: {
          date: dateTime,

          description: this.eventTransportationRef.current.state.transportationDesc,
          accompany: this.eventTransportationRef.current.state.contactMemberName,
          address: {
            line: this.eventTransportationRef.current.state.line,
            city: this.eventTransportationRef.current.state.city,
            zipCode: this.eventTransportationRef.current.state.zipCode,
            state: {
              code: this.eventTransportationRef.current.state.state,
              id: this.eventTransportationRef.current.state.stateId,
            },
          },
          accompanyType: {
            type: this.eventTransportationRef.current.state.transportationType,
          },

          event: {
            id: this.state.id,
          },
          reminderList: transportationReminder,
        },
      };
    }

    FetchServerData.callPostService('/eventtransportms/create', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let outputData = output.data;
          let transportation = this.state.transportationList;
          transportation.push(outputData);
          PtsAlert.success(output.message);
          // this.getEventDetails(this.state.id);
          this.setState({
            transportationModal: false,
            transportationList: transportation,
          });
        } else {
        }
      })
      .catch((error) => {});
  };
  /* update Transportation Data In Backend */
  updateTransportationInBackend = (transportationData) => {
    this.getEventDetails(this.state.id);
  };

  eventNoteRemove(item) {
    let items = [];
    if (item.operation && 'update' === item.operation) {
      /* Existing Item Deleted, Mark it deleted */
      let index = item.index;

      for (let i = 0; i < this.state.eventNoteList.length; i++) {
        let item = this.state.eventNoteList[i];
        if (this.state.eventNoteList[i].operation === 'update' && index === i) {
          item.operation = 'delete';
        }

        items.push(item);
      }

      this.setState({ eventNoteList: items });
    }
    if (item.operation && 'add' === item.operation) {
      /* Added Item Deleted, Remove from list */
      let index = item.index;
      for (let i = 0; i < this.state.eventNoteList.length; i++) {
        if (this.state.eventNoteList[i].operation === 'add' && index === i) {
          this.eventNoteRefList.splice(i, 1);
          // continue;
        } else {
          items.push(this.state.eventNoteList[i]);
        }
      }
      this.setState({ eventNoteList: items });
    }

    /*Go Back to Location list view if all new Locations are deleted */
    let flag = true;
    for (let i = 0; i < items.length; i++) {
      if (items[i].operation === 'add') {
        flag = false;
      }
    }
    if (flag) {
      this.backToEventList();
    }
  }

  addTransportationRequest() {
    this.setState({
      transportationModal: true,
    });
  }

  addEventInBackend(e) {
    let locations = [];
    // for (let i = 0; i < this.addressRef.length; i++) {
    // if (null !== this.addressRef[i].current) {
    let location = {};
    // location.id = this.locationReferenceList[i].current.state.id;
    location.shortDescription = this.addressRef.current.state.shortDescription;
    location.nickName = this.addressRef.current.state.nickName;
    // location.fax = this.locationReferenceList[i].current.state.fax;
    // location.website = this.locationReferenceList[i].current.state.website;
    // location.phone = this.locationReferenceList[i].current.state.phone;
    location.address = {};
    location.address.id = this.addressRef.current.state.id;
    location.address.line = this.addressRef.current.state.line;
    location.address.city = this.addressRef.current.state.city;
    location.address.zipCode = this.addressRef.current.state.zipCode;
    location.address.state = this.addressRef.current.state.state;
    // locations[i] = location;
    // }
    // }
    let dateTime = Utils.convertUserTimeZoneDateToUTCDate(this.state.startDate);

    const postObject = {
      header: this.header,
      event: {
        date: dateTime,
        description: this.state.note,
        type: this.state.eventType,
        title: this.state.eventTitle,
        member: {
          id: this.memberDetails.member,
        },
        location: location,
        contactList: [],

        reminderList: this.state.reminderItems,
      },
    };

    FetchServerData.callPostService('/eventms/create', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let eventdata = output.data;
          PtsAlert.success(output.message);
          let fccPageState = {
            id: eventdata.event.id,
            action: 'view',
          };
          store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
          if (this.state.pageId === 'memberdeatils') {
            this.props.history.push({
              pathname: '/memberDetails',
            });
          } else {
            this.props.history.push({
              pathname: '/viewEvent',
              state: {
                action: 'view',
                title: 'Facility Details',
                eventdata: eventdata,
                pageId: 'createEvent',
              },
            });
          }
        } else if (Utils.equalsIgnoreCase(output.status, 'FAIL')) {
          if (output.errorCode && Utils.equalsIgnoreCase(output.errorCode, 'DuplicateAppointmentEvent')) {
            // PtsAlert.warn(output.message);
            // this.warningMessage(output.message)
            this.setState({
              sameDateEventWindow: true,
              WarningMessage: output.message,
            });
          }
        } else {
        }
      })
      .catch((error) => {});
  }

  addSameEventInBackend(e) {
    let locations = [];
    // for (let i = 0; i < this.locationReferenceList.length; i++) {
    // if (null !== this.locationReferenceList[i].current) {
    let location = {};
    // location.id = this.locationReferenceList[i].current.state.id;
    location.shortDescription = this.addressRef.current.state.shortDescription;
    location.nickName = this.addressRef.current.state.nickName;
    // location.fax = this.locationReferenceList[i].current.state.fax;
    // location.website = this.locationReferenceList[i].current.state.website;
    // location.phone = this.locationReferenceList[i].current.state.phone;
    location.address = {};
    location.address.id = this.addressRef.current.state.id;
    location.address.line = this.addressRef.current.state.line;
    location.address.city = this.addressRef.current.state.city;
    location.address.zipCode = this.addressRef.current.state.zipCode;
    location.address.state = this.addressRef.current.state.state;
    // locations[i] = location;
    // }
    // }
    let dateTime = Utils.convertUserTimeZoneDateToUTCDate(this.state.startDate);

    const postObject = {
      header: this.header,
      event: {
        date: dateTime,
        description: this.state.note,
        type: this.state.eventType,
        title: this.state.eventTitle,
        member: {
          id: this.memberDetails.member,
        },
        location: location,
        contactList: [],

        reminderList: this.state.reminderItems,
        forceCreateOnSameDate: true,
      },
    };

    FetchServerData.callPostService('/eventms/create', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let eventdata = output.data;
          this.setState({ sameDateEventWindow: false });
          PtsAlert.success(output.message);
          let fccPageState = {
            id: eventdata.event.id,
            action: 'view',
          };
          store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
          if (this.state.pageId === 'memberdeatils') {
            this.props.history.push({
              pathname: '/memberDetails',
            });
          } else {
            this.props.history.push({
              pathname: '/viewEvent',
              state: {
                action: 'view',
                title: 'Facility Details',
                eventdata: eventdata,
                pageId: 'createEvent',
              },
            });
          }
        } else {
        }
      })
      .catch((error) => {});
  }

  handleSameDateEventCreateWindowClose = () => {
    this.setState({
      sameDateEventWindow: false,
    });
  };
  backToLocationView() {
    this.setState({ locationItemsView: true, locationItemsCreateDiv: false });
  }

  updateEventInBackend(e) {
    let locations = {};

    // for (let i = 0; i < this.state.locationList.length; i++) {
    // if (this.state.locationList[i].operation === 'delete') {
    //   let location = {};
    //   location.id = this.state.locationList[i].id;
    //   location.operation = this.state.locationList[i].operation;

    //   locations = location;
    // } else {
    //   if (this.locationReferenceList[i] && this.locationReferenceList[i].current) {
    //     if (false === this.locationReferenceList[i].current.validateLocation) {
    //       return;
    //     }
    let location = {};
    // location.id = this.locationReferenceList[i].current.state.id;
    location.operation = this.addressRef.current.state.operation;
    location.shortDescription = this.addressRef.current.state.shortDescription;
    // location.nickName = this.locationReferenceList[i].current.state.nickName;
    // location.fax = this.locationReferenceList[i].current.state.fax;
    // location.website = this.locationReferenceList[i].current.state.website;
    // location.phone = this.locationReferenceList[i].current.state.phone;
    location.address = {};
    location.address.id = this.addressRef.current.state.id;
    location.address.line = this.addressRef.current.state.line;
    location.address.city = this.addressRef.current.state.city;
    location.address.zipCode = this.addressRef.current.state.zipCode;
    location.address.state = this.addressRef.current.state.state;
    locations = location;
    // }
    // }

    let dateTime = Utils.convertUserTimeZoneDateToUTCDate(this.state.startDate);
    const postObject = {
      header: this.header,
      event: {
        id: this.state.id,
        date: dateTime,
        description: this.state.note,
        type: this.state.eventType,
        title: this.state.eventTitle,
        member: {
          id: this.memberDetails.member,
        },
        location: locations,
        contactList: [],

        reminderList: this.state.eventReminderList,
      },
    };
    FetchServerData.callPostService('/eventms/update', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let eventData = output.data.event;

          let name = '';
          let email = '';
          let phone = '';
          let isContact = '';
          let isMember = '';

          let date = eventData.date;
          let newStartDate = Utils.convertUTCDateToUserTimeZoneDate(date);
          let viewStartDate = Utils.convertUTCDateToUserTimeZoneViewDateFormate(date);
          for (let i = 0; i < eventData.noteList.length; i++) {
            eventData.noteList[i].action = 'view';
          }
          this.state.locationList = [];
          this.state.locationList.push(eventData.location);
          if (eventData.location !== null) {
            this.setState({
              eventData: eventData,
              eventType: eventData.type,
              viewEventTitle: eventData.title,
              type: eventData.type?.type,
              eventStartDate: viewStartDate,
              startDate: new Date(newStartDate),
              timeView: eventData.dateTime,
              locationDetails: eventData.location,
              address: eventData.location,
              eventNoteList: eventData.noteList,
              render: true,
              eventTitle: eventData.title,
              note: eventData.description,
              isEditButtonHide: true,
              isButtonHide: false,
            });
          } else {
            this.setState({
              eventData: eventData,
              eventType: eventData.type,
              viewEventTitle: eventData.title,
              type: eventData.type?.type,
              eventStartDate: viewStartDate,
              description: eventData.description,
              startDate: new Date(newStartDate),
              timeView: eventData.dateTime,
              locationDetails: {},
              contactDetails: eventData.contactList[0],
              line: '',
              city: '',
              zipCode: '',
              state: '',
              country: '',
              contactName: name,
              contactPhone: phone,
              contactEmail: email,
              eventNoteList: eventData.noteList,
              locationList: this.state.locationList,
              render: true,
              noteListRow: true,
              isContact: isContact,
              isMember: isMember,
              eventTitle: eventData.title,
              note: eventData.description,
              isEditButtonHide: true,
              isButtonHide: false,
            });
          }
          PtsAlert.success(output.message);
        } else if (Utils.equalsIgnoreCase(output.status, 'FAIL')) {
          if (output.errorCode && Utils.equalsIgnoreCase(output.errorCode, 'DuplicateAppointmentEvent')) {
            // PtsAlert.warn(output.message);
            // this.warningMessage(output.message)
            this.setState({
              sameDateEventWindow: true,
              WarningMessage: output.message,
            });
          }
        } else {
        }
      })
      .catch((error) => {});
    // }
  }
  updateSameEventInBackend(e) {
    let locations = {};

    for (let i = 0; i < this.state.locationList.length; i++) {
      let location = {};

      location.address = {};
      location.address.id = this.addressRef.current.state.id;
      location.address.line = this.addressRef.current.state.line;
      location.address.city = this.addressRef.current.state.city;
      location.address.zipCode = this.addressRef.current.state.zipCode;
      location.address.state = this.addressRef.current.state.state;
      locations = location;

      let dateTime = Utils.convertUserTimeZoneDateToUTCDate(this.state.startDate);
      const postObject = {
        header: this.header,
        event: {
          id: this.state.id,
          date: dateTime,
          description: this.state.note,
          type: this.state.eventType,
          title: this.state.eventTitle,
          member: {
            id: this.memberDetails.member,
          },
          location: locations,
          contactList: [],
          forceCreateOnSameDate: true,
          reminderList: this.state.eventReminderList,
        },
      };
      FetchServerData.callPostService('/eventms/update', postObject)
        .then((output) => {
          if (output.status === 'SUCCESS') {
            let eventData = output.data.event;

            let name = '';
            let email = '';
            let phone = '';
            let isContact = '';
            let isMember = '';

            let date = eventData.date;
            let newStartDate = Utils.convertUTCDateToUserTimeZoneDate(date);
            let viewStartDate = Utils.convertUTCDateToUserTimeZoneViewDateFormate(date);
            for (let i = 0; i < eventData.noteList.length; i++) {
              eventData.noteList[i].action = 'view';
            }
            this.state.locationList = [];
            this.state.locationList.push(eventData.location);
            if (eventData.location !== null) {
              this.setState({
                eventData: eventData,
                eventType: eventData.type,
                viewEventTitle: eventData.title,
                type: eventData.type?.type,
                eventStartDate: viewStartDate,
                startDate: new Date(newStartDate),
                timeView: eventData.dateTime,
                locationDetails: eventData.location,
                address: eventData.location,
                eventNoteList: eventData.noteList,
                render: true,
                eventTitle: eventData.title,
                note: eventData.description,
                isEditButtonHide: true,
                isButtonHide: false,
              });
            } else {
              this.setState({
                eventData: eventData,
                eventType: eventData.type,
                viewEventTitle: eventData.title,
                type: eventData.type?.type,
                eventStartDate: viewStartDate,
                description: eventData.description,
                startDate: new Date(newStartDate),
                timeView: eventData.dateTime,
                locationDetails: {},
                address: {},
                contactDetails: eventData.contactList[0],
                line: '',
                city: '',
                zipCode: '',
                state: '',
                country: '',
                contactName: name,
                contactPhone: phone,
                contactEmail: email,
                eventNoteList: eventData.noteList,
                locationList: this.state.locationList,
                render: true,
                noteListRow: true,
                isContact: isContact,
                isMember: isMember,
                eventTitle: eventData.title,
                note: eventData.description,
                isEditButtonHide: true,
                isButtonHide: false,
              });
            }
            this.setState({ sameDateEventWindow: false });
            PtsAlert.success(output.message);
          } else {
          }
        })
        .catch((error) => {});
    }
  }
  deleteEvent() {
    let id = this.state.id;
    const postObject = {
      header: this.header,
      event: {
        id: id,
      },
    };

    FetchServerData.callPostService('/eventms/delete', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        this.props.history.push({
          pathname: '/member_event',
          sweetAlert: false,
        });
      } else {
      }
    });
  }

  deleteEventNote(e, note) {
    let id = note.id;
    const postObject = {
      header: this.header,
      note: {
        id: id,
      },
    };

    FetchServerData.callPostService('eventms/deleteNote', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        for (let i = 0; i < this.state.eventNoteList.length; i++) {
          if (this.state.eventNoteList[i].id === id) {
            this.state.eventNoteList.splice(i, 1);
          }
        }

        this.setState({
          eventNoteList: this.state.eventNoteList,
          noteAlert: false,
        });
        this.getEventDetails(this.state.id);
      } else {
      }
    });
  }

  editEventDetails(e) {
    this.setState({
      isEditButtonHide: false,
      isButtonHide: true,
    });
  }

  repeatEvent() {
    this.props.history.push({
      pathname: '/createSimilarEvent',
      state: { action: 'createSimilarEvent', title: 'Add Event', eventDetails: this.state.eventDetails },
    });
  }

  showMap() {
    this.setState({
      showMapView: true,
      showLocationView: false,
    });
  }

  closeButton() {
    this.setState({
      showMapView: false,
      showLocationView: true,
    });
  }
  getMapView(locations) {
    return <Map data={locations}></Map>;
  }

  handleEventTransportationChange(inputTransportation) {
    this.setState({ eventTransportation: inputTransportation });
  }

  addEventStatusInBackend(status) {
    let appointmentStatus = status;
    const postObject = {
      header: this.header,
      event: {
        id: this.state.id,
        status: {
          status: appointmentStatus,
        },
        member: {
          id: this.memberDetails.member,
        },
      },
    };

    FetchServerData.callPostService('eventms/updateStatus', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let eventData = output.data;
          this.setState({
            status: eventData.status.status,
          });
          this.props.history.push({
            pathname: '/member_event',
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  eventSyncToCalenderInBackend = () => {
    const postObject = {
      header: this.header,
      event: {
        id: this.state.id,
      },
    };

    FetchServerData.callPostService('calendarEventMS/syncEventToCalendar', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          this.setState({ calendarSyncModal: true });
        } else {
        }
      })
      .catch((error) => {});
  };
  handleCancelAppointmentConfirm(status) {
    let appointmentStatus = status;
    const postObject = {
      header: this.header,
      event: {
        id: this.state.id,
        status: {
          status: appointmentStatus,
        },
        member: {
          id: this.memberDetails.member,
        },
      },
    };

    FetchServerData.callPostService('eventms/updateStatus', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let eventData = output.data;
          this.setState({
            status: eventData.status.status,
            openCancelAppointment: false,
          });
          this.props.history.push({
            pathname: '/member_event',
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  changeAppointmentStatusInBackend() {
    this.setState({ openCancelAppointment: true });
  }

  handleClose = () => {
    this.setState({ openCancelAppointment: false });
  };

  calendarSyncToggle = () => {
    this.setState({
      calendarSyncModal: !this.state.calendarSyncModal,
    });
  };
  render() {
    this.eventTransportationRef = [];

    for (let i = 0; this.state.transportationList && i < this.state.transportationList.length; i++) {
      this.eventTransportationRef[i] = React.createRef();
    }
    this.eventNoteRefList = [];
    for (let i = 0; this.state.eventNoteList && i < this.state.eventNoteList.length; i++) {
      this.eventNoteRefList[i] = React.createRef();
    }
    this.eventReminderRefList = [];
    for (let i = 0; this.state.reminderItems && i < this.state.reminderItems.length; i++) {
      this.eventReminderRefList[i] = React.createRef();
    }
    this.eventTypeRef = React.createRef();
    if (this.state.render === false) {
      return <div />;
    } else if (this.state.action === 'create') {
      return this.createEvent();
    } else if (this.state.action === 'edit') {
      return this.editEvent();
    } else if (this.state.action === 'view') {
      return this.viewEvent();
    } else if (this.state.action === 'printEvent') {
      return this.printEvent();
    }
  }
  printPreviewEvent() {
    this.setState({
      action: 'printEvent',
    });
  }
  handleAddressChange(address) {
    this.setState({ address: address });
  }
  printEventContent() {
    let printContents = document.getElementById('printform').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();

    document.body.innerHTML = originalContents;
    this.setState({
      action: 'printEvent',
      render: true,
    });
    document.location.reload(true);
  }

  createEvent() {
    let locationItems = [];
    this.state.locationList.forEach((element, index) => {
      if ('delete' !== element.operation) {
        locationItems.push(
          <Col md={12} style={{ padding: '0.5em' }}>
            <Location
              ref={this.locationReferenceList[index]}
              key={index}
              data={element}
              srNo={index}
              action={element.action}
              pageId="event"
              isButtonHide="true"
              onLocationAdd={this.addNewLocation.bind(this)}
              onUpdate={this.updateLocation.bind(this)}
            />
          </Col>
        );
      }
    });
    let reminderList = [];
    this.state.reminderItems.forEach((element, index) => {
      if ('delete' !== element.operation) {
        reminderList.push(
          <Col md={6} style={{ marginTop: '1em' }}>
            <Reminder
              ref={this.eventReminderRefList[index]}
              key={index}
              data={element}
              srNo={index}
              reminderDate={this.state.startDate}
              pageId={this.state.pageId}
              action="create"
              onReminderAdd={this.addNewReminder.bind(this)}
              onDelete={this.deleteReminder.bind(this)}
              onUpdate={this.updateReminder.bind(this)}
            />
          </Col>
        );
      }
    });
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={'Add Event For' + ' ' + this.memberName}
          domainObject="Event"
        />
        <ValidatorForm style={{ width: '100%' }} ref="form" onSubmit={this.addEventInBackend.bind(this)}>
          <Row style={{ position: 'relative', margin: '1em' }}>
            {' '}
            <Col md={6} style={{ position: 'relative', padding: '5px' }}>
              <Card className="doctorViewCard" style={{ padding: '1em', height: '100%' }}>
                <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.5em' }}>
                  <Row>
                    <Col md={9}>
                      {undefined !== this.state.title && this.state.title.length > 0 && (
                        <Label style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.3em' }}>
                          {this.state.title}
                        </Label>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row style={{ marginTop: '1em', marginBottom: '-1em' }}>
                    <Col md={6} style={{ marginBottom: '1em' }}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ThemeProvider theme={materialTheme}>
                          <DateTimePicker
                            value={this.state.startDate}
                            variant="outlined"
                            fullWidth
                            className="ptsDateTimePicker"
                            label="Date"
                            inputVariant="outlined"
                            timeInputLabel="Time:"
                            // minDate={subDays(new Date(), 1)}
                            format="MM/dd/yyyy  h:mm aa"
                            style={{ background: '#fff' }}
                            onChange={this.handleEventStartDateChange.bind(this)}
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>
                    </Col>
                    <Col md={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="title"
                        label="Title"
                        value={this.state.eventTitle}
                        onChange={this.handleEventTitleChange.bind(this)}
                        name="name"
                        type="text"
                        className="textField"
                        InputProps={{}}
                        required
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12} style={{ marginTop: '25px' }}>
                      <TextareaAutosize
                        aria-label="minimum height"
                        style={{ width: '100%', padding: '10px' }}
                        rowsMin={5}
                        id="description*"
                        value={this.state.note}
                        inputProps={{ maxLength: 256, padding: '10px' }}
                        name="note"
                        onChange={this.handleDescriptionChange.bind(this)}
                        onPressEnter={this.handleDescriptionChange.bind(this)}
                        placeholder="Description"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} style={{ padding: '5px' }}>
              <Card className="doctorViewCard" style={{ padding: '1em', height: '100%' }}>
                <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.5em' }}>
                  <Row>
                    <Col md={9}>
                      {undefined !== this.state.title && this.state.title.length > 0 && (
                        <Label style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.3em' }}>Location</Label>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {/* {locationItems} */}
                  <div className="location_details_view">
                    <div className="panel panel-blue">
                      <div className="panel-heading">
                        <ShortLocation
                          ref={this.addressRef}
                          onChange={this.handleAddressChange.bind(this)}
                          data={this.state.address}
                          action="create"
                          pageId="event"
                          isButtonHide="true"
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <CardFooter>
            <div style={{ textAlign: 'center', padding: '0.5em' }}>
              <CreateButton>Add</CreateButton>
              &nbsp;
              <CancelIconButton onClick={(e) => this.cancelCreate()} />
            </div>
          </CardFooter>
        </ValidatorForm>
        <Dialog
          open={this.state.sameDateEventWindow}
          onClose={this.handleSameDateEventCreateWindowClose.bind(this)}
          onHide={this.close}
          style={{ padding: '1em' }}
        >
          <DialogContent style={{ marginTop: '2em' }}>
            <Row>
              <Col md={12} style={{ textAlign: 'center' }}>
                <h5>{this.state.WarningMessage}</h5>
                <br />
                {this.state.WarningMessage ? <h5>Do you still want to continue to create?</h5> : ''}
              </Col>
            </Row>
          </DialogContent>
          <DialogActions style={{ marginTop: '0em', padding: '1em', marginLeft: 'auto', marginRight: 'auto' }}>
            <Button
              variant="contained"
              size="large"
              style={{ background: '#0f666b', color: '#fff', marginRight: '1rem' }}
              startIcon={<SaveIcon />}
              onClick={this.addSameEventInBackend.bind(this)}
            >
              Ok
            </Button>
            <Button
              variant="contained"
              size="large"
              style={{ background: '#717373', color: '#fff', marginRight: '1rem' }}
              startIcon={<CloseIcon />}
              s
              onClick={this.handleSameDateEventCreateWindowClose.bind(this)}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  viewEvent() {
    if (this.state.isFreeUser === true) {
      return this.viewEventWithAdvertisement();
    } else {
      return this.viewEventWithoutAdvertisement();
    }
  }

  viewEventWithAdvertisement() {
    if (this.state.render === false) {
      return <div />;
    } else {
      let taskNotes = [];
      this.state.eventNoteList.forEach((note, index) => {
        if ('delete' !== note.operation) {
          taskNotes.unshift(
            <Col md={6} style={{ marginTop: '1em' }}>
              <MemberEventNote
                ref={this.eventNoteRefList[index]}
                key={index}
                data={note}
                srno={index}
                eventId={this.state.id}
                getDeletePermission={this.state.getEventNoteDeletePermission}
                getUpdatePermission={this.state.getEventNoteUpdatePermission}
                getCreatePermission={this.state.getEventNoteCreatePermission}
                action={note.action ? note.action : 'view'}
                noteId={this.state.noteId}
                pageId={this.state.pageId}
                isPrintView={this.state.isPrintView}
                onNoteEdit={this.editNote.bind(this, note)}
                onNoteDelete={this.deleteEventNote.bind(this, note)}
                onNoteUpdate={this.updateEventNote.bind(this)}
                addNote={this.addEventNotesInBackend.bind(this)}
                onDelete={this.eventNoteRemove.bind(this)}
                onSuccess={this.getEventDetails.bind(this)}
              />
            </Col>
          );
        }
      });

      let reminderList = [];
      this.state.reminderItems.forEach((reminder, index) => {
        if ('delete' !== reminder.operation) {
          reminderList.push(
            <Col md={4} style={{ marginTop: '1em' }}>
              <Reminder
                ref={this.eventReminderRefList[index]}
                key={index}
                reminderDate={this.state.startDate}
                data={reminder}
                srNo={index}
                pageId={this.state.pageId}
                action={reminder.action ? reminder.action : 'view'}
                getDeletePermission={this.state.getDeletePermission}
                getUpdatePermission={this.state.getUpdatePermission}
                onReminderAdd={this.addNewReminderToBackend.bind(this)}
                onCancel={this.deleteReminder.bind(this)}
                onUpdate={this.updateReminder.bind(this)}
                onDelete={this.deleteReminderInBackend.bind(this)}
              />
            </Col>
          );
        }
      });

      return (
        <>
          {this.state.isViewButtonHide && (
            <>
              {this.state.isEditButtonHide && (
                <MenuWithBreadScrum
                  backCallback={this.cancelView.bind(this)}
                  deleteCallback={this.deleteEvent.bind(this)}
                  createSimilarCallback={this.repeatEvent.bind(this)}
                  printCallback={this.printPreviewEvent.bind(this)}
                  markCompleteCallback={this.addEventStatusInBackend.bind(this, 'Completed')}
                  syncToCalenderCallback={this.eventSyncToCalenderInBackend.bind(this)}
                  getDeletePermission={this.state.getDeletePermission}
                  getCreatePermission={this.state.getCreatePermission}
                  getAllPermission={this.state.getAllPermission}
                  breadCrumb={this.breadCrumb}
                  history={this.props.history}
                  name={this.title + ' For ' + this.memberName}
                  domainObject="Event"
                  status={this.state.status}
                  appointmentDate={this.state.startDate}
                  pageId="Event"
                />
              )}
            </>
          )}
          {this.state.isButtonHide && (
            <MenuWithBreadScrum
              breadCrumb={this.breadCrumb}
              history={this.props.history}
              name={'Edit Event For' + ' ' + this.memberName}
              domainObject="Event"
            />
          )}
          {this.state.eventNoteList.length > 0 ? (
            <Steps
              enabled={this.state.stepsEnabled}
              steps={this.state.stepsWithNote}
              initialStep={this.state.initialStep}
              onExit={this.onExit}
            />
          ) : (
            <Steps
              enabled={this.state.stepsEnabled}
              steps={this.state.steps}
              initialStep={this.state.initialStep}
              onExit={this.onExit}
            />
          )}
          <div>
            <Row style={{ position: 'relative', marginLeft: '1.2em', marginRight: '1.2em', marginTop: '1em' }}>
              {this.state.isEditButtonHide && (
                <>
                  <Col md={5} style={{ position: 'relative', padding: '8px' }}>
                    <Card style={{ borderRadius: '20px', minHeight: '25em' }}>
                      <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                        <Row>
                          <Col md={10}>
                            <label style={{ color: '#EC7063' }}>{this.state.viewEventTitle}</label>
                          </Col>
                          <Col md={2}>
                            {this.state.isViewButtonHide && (
                              <>
                                {this.state.getUpdatePermission === true && (
                                  <EditIconButton onClick={(e) => this.editEventDetails(e, 'edit')} />
                                )}
                              </>
                            )}
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md={12}>
                            <Row style={{ padding: '5px 0px' }}>
                              <Col md={3}>
                                <span style={{ color: '#000' }}>
                                  <strong>Date</strong>
                                </span>
                              </Col>
                              <Col md={8}>
                                <span style={{ color: '#000' }}>{this.state.eventStartDate}</span>
                              </Col>
                            </Row>

                            <Row style={{ padding: '5px 0px' }}>
                              <Col md={3}>
                                <span style={{ color: '#000' }}>
                                  <strong>Status</strong>
                                </span>
                              </Col>
                              <Col md={8}>
                                <span style={{ color: '#000' }}>{this.state.status}</span>
                              </Col>
                            </Row>
                            <Row style={{ padding: '5px 0px' }}>
                              <Col md={3}>
                                <span style={{ color: '#000' }}>
                                  <strong>Description</strong>
                                </span>
                              </Col>
                              <Col md={8}>
                                <span style={{ color: '#000' }}>
                                  <pre
                                    className="view_AppointmentNote"
                                    style={{ marginLeft: '-1.9px', textAlign: 'justify' }}
                                  >
                                    {this.state.note}
                                  </pre>
                                </span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={7} style={{ position: 'relative', padding: '8px' }}>
                    {this.state.showMapView && (
                      <Row>
                        <Col md={12}>
                          <Card style={{ borderRadius: '20px', minHeight: '25em' }}>
                            <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                              <Row>
                                <Col md={10}>
                                  <Label style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em' }}>
                                    Location Details
                                  </Label>
                                </Col>
                                <Col md={2}></Col>
                              </Row>
                            </CardHeader>
                            <CardBody>
                              <Row>
                                <Col md={12}>
                                  <ShortLocation
                                    // ref={this.addressRef}
                                    action="view"
                                    pageId="event"
                                    data={this.state.address}
                                    // isButtonHide="true"
                                  />
                                  {/* <Location action="view" pageId="event" data={this.state.locationDetails} /> */}
                                </Col>
                              </Row>
                              <Row style={{ margin: '0em' }}>
                                <Col md={12}>
                                  <Row md={12}>
                                    <Col md={12} style={{ padding: '0em' }}>
                                      {' '}
                                      <span className="closeFloatingButton" aria-label="add">
                                        {' '}
                                        <CloseIcon
                                          onClick={this.closeButton.bind(this)}
                                          style={{ float: 'right', fontSize: '15px', cursor: 'pointer' }}
                                        />
                                      </span>
                                    </Col>
                                  </Row>{' '}
                                  <Row>
                                    <Col md={12}>{this.getMapView(this.state.addressLocations)} </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>{' '}
                      </Row>
                    )}
                    {this.state.showLocationView && (
                      <Card style={{ borderRadius: '20px', minHeight: '25em' }}>
                        <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                          <Row>
                            <Col md={10}>
                              <Label style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em' }}>
                                Location Details
                              </Label>
                            </Col>
                            <Col md={2}></Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md={12}>
                              <ShortLocation
                                // ref={this.addressRef}
                                action="view"
                                pageId="event"
                                data={this.state.address}
                                // isButtonHide="true"
                              />
                              {/* <Location action="view" pageId="event" data={this.state.locationDetails} /> */}
                            </Col>
                          </Row>
                          <Row md={12} style={{ marginLeft: 'auto' }}>
                            {' '}
                            {/* <Button
                              type={this.state.type ? this.state.type : ''}
                              variant="contained"
                              size="small"
                              style={{ background: '#717373', color: '#fff', marginRight: '1rem', marginBottom: '1em' }}
                              onClick={(e) => this.showMap()}
                            >
                              Show Map
                            </Button> */}
                          </Row>
                        </CardBody>
                      </Card>
                    )}
                  </Col>
                </>
              )}
              {this.state.isButtonHide && this.editEvent()}
            </Row>
            {this.state.showEventNotePermission === true ? (
              <Row style={{ margin: 'auto', marginLeft: '0.8em', marginRight: '0.8em', marginTop: '-0.7em' }}>
                <Col md={12}>
                  <Card style={{ borderRadius: '20px', height: ' 100%' }}>
                    <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                      <Row>
                        <Col md={9}>
                          <Label
                            style={{
                              fontWeight: '600',
                            }}
                            className="appointment_section_header"
                            id="noteTitle"
                          >
                            Notes
                          </Label>
                        </Col>
                        <Col md={3} style={{ textAlign: 'end' }}>
                          {this.state.isViewButtonHide && (
                            <>
                              {this.state.getEventNoteCreatePermission === true && (
                                <span className="addNote" style={{ float: 'right' }}>
                                  <Tooltip title="Add Notes" aria-label="add">
                                    <AddIconButton onClick={this.addNotes.bind(this)} btnName="Add Note" />
                                  </Tooltip>
                                </span>
                              )}
                            </>
                          )}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row>{taskNotes}</Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : (
              <></>
            )}
            <Row style={{ position: 'relative', marginLeft: '0.8em', marginRight: '0.8em' }}>
              <Col md={12}>
                <Card style={{ height: '100%', borderRadius: '20px', marginTop: '1em' }}>
                  <CardHeader
                    style={{
                      borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
                    }}
                  >
                    <Row className="">
                      <Col md={9} className=" appointment_section_header">
                        <Label className="reminderTitle" style={{ fontWeight: '600', color: '#0099f1' }}>
                          Reminders
                        </Label>
                      </Col>
                      <Col md={3} style={{ textAlign: 'end' }}>
                        <>
                          {this.state.getCreatePermission === true && (
                            <span className="addReminder" style={{ float: 'right' }}>
                              <Tooltip title="Add Reminder" aria-label="add">
                                <AddIconButton onClick={this.addReminder.bind(this)} btnName="Add Reminder" />
                              </Tooltip>
                            </span>
                          )}
                        </>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>{reminderList}</Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {this.state.showEventTransportationPermission === true ? (
              <Row
                style={{ position: 'relative', marginLeft: '0.8em', marginRight: '0.8em', marginTop: '1em' }}
                id="isTransportationChecked"
                className="transporation_list"
              >
                <Col md={12}>
                  <Card style={{ borderRadius: '20px', marginTop: '1em' }}>
                    <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                      <Row>
                        <Col md={9}>
                          <Label style={{}} className="transportationTitle appointment_section_header">
                            Transportation
                          </Label>
                        </Col>

                        <Col md={3} style={{ textAlign: 'end' }}>
                          {this.state.isViewButtonHide && (
                            <>
                              {this.state.getEventTransportationCreatePermission === true ? (
                                this.state.appointmentStatus === 'Completed' ||
                                this.state.startDate < this.state.currentCompareDateTime ? (
                                  ' '
                                ) : (
                                  <Tooltip title="Add  Transportation" aria-label="add">
                                    <span className="addTransportation" style={{ float: 'right' }}>
                                      <AddIconButton
                                        onClick={this.addTransportationRequest.bind(this)}
                                        btnName="Add Transportation"
                                      />
                                    </span>
                                  </Tooltip>
                                )
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      {this.state.transportationList.length > 0 && (
                        <EventTransportation
                          transportationEventList={this.state.transportationList}
                          isPrintView={this.state.isPrintView}
                          getDeletePermission={this.state.getEventTransportationDeletePermission}
                          getUpdatePermission={this.state.getEventTransportationUpdatePermission}
                          getCreatePermission={this.state.getEventTransportationCreatePermission}
                          onTransportationSuccess={this.updateTransportationInBackend.bind(this)}
                          eventId={this.state.id}
                          action="list"
                        ></EventTransportation>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : (
              <></>
            )}
            <>
              {this.state.isViewButtonHide && (
                <>
                  <div
                    style={{ textAlign: 'center', padding: '0em 0em 1em', margin: '2em' }}
                    className="btn_appointment_group"
                  >
                    <BackButton
                      type="button"
                      style={{ backgroundColor: '#162c50', color: '#fff', marginBottom: '1em' }}
                      variant="contained"
                      startIcon={<CloseIcon />}
                      onClick={(e) => this.cancelView()}
                    >
                      Back
                    </BackButton>
                    {this.state.getCreatePermission ||
                    this.state.getUpdatePermission ||
                    this.state.getDeletePermission ? (
                      <>
                        {(this.state.startDate < this.state.currentCompareDateTime &&
                          this.state.startDate !== this.state.currentCompareDateTime &&
                          this.state.status === 'Scheduled') ||
                        this.state.status === 'Cancelled' ? (
                          <>
                            {' '}
                            <Button
                              type="submit"
                              size="small"
                              style={{ background: '#1b7189', color: '#fff', marginRight: '1rem' }}
                              variant="contained"
                              startIcon={<OfflinePinIcon />}
                              onClick={(e) => this.addEventStatusInBackend('Completed')}
                            >
                              {' '}
                              Mark Completed
                            </Button>
                          </>
                        ) : (
                          ''
                        )}
                        {this.state.status === 'Scheduled' ? (
                          <Button
                            type="submit"
                            style={{ background: '#1b7189', color: '#fff', marginRight: '1rem' }}
                            variant="contained"
                            size="small"
                            startIcon={<CancelScheduleSendIcon />}
                            onClick={this.changeAppointmentStatusInBackend.bind(this)}
                          >
                            {' '}
                            Mark Cancelled
                          </Button>
                        ) : (
                          ''
                        )}
                        &nbsp;
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              )}
            </>
          </div>
          <Modal isOpen={this.state.transportationModal} toggle={this.transportationToggle} size="lg">
            <ModalHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }} toggle={this.transportationToggle}>
              <Label style={{ fontWeight: '600', color: '#162c50' }}>Transportation Request</Label>
            </ModalHeader>
            <ModalBody>
              <Row>
                <EventTransportation
                  ref={this.eventTransportationRef}
                  title="Transportation Request"
                  data={this.state.eventTransportation}
                  addressData={this.state.transportationAddress}
                  transportationDate={this.state.startDate}
                  action="create"
                  pageId={this.state.pageId}
                  eventId={this.state.id}
                  onChange={this.handleEventTransportationChange.bind(this)}
                  onTransportationAdd={this.addTransportationInBackend.bind(this)}
                  onUpdate={this.updateTransportationInBackend.bind(this)}
                  onSuccess={this.getEventDetails.bind(this)}
                />
              </Row>
            </ModalBody>
          </Modal>
          <Dialog
            open={this.state.openCancelAppointment}
            onClose={this.handleClose.bind(this)}
            onHide={this.close}
            style={{ padding: '1em' }}
          >
            <DialogContent>
              <Row style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                <Col md={12} style={{ textAlign: 'center' }}>
                  <ErrorOutlineIcon
                    style={{
                      border: '2px solid #fff',
                      borderRadius: '25%',
                      background: '#066f72 !important',
                      color: 'red',
                      fontSize: '50px',
                    }}
                  />
                </Col>
              </Row>
              <span style={{ textAlign: 'center' }}>
                <h2 style={{ fontSize: '20px' }}>Do you really want to cancel event?</h2>

                <h5 style={{ color: '#0f666b', fontWeight: 'bold', fontSize: '25px' }}>{this.state.text}</h5>

                <h2 style={{ fontSize: '20px' }}>It cannot be recovered once cancelled.</h2>
              </span>
            </DialogContent>
            <DialogActions style={{ marginTop: '1em', padding: '2em', marginLeft: 'auto', marginRight: 'auto' }}>
              <Button
                variant="contained"
                size="large"
                style={{ background: '#0f666b', color: '#fff', marginRight: '1rem' }}
                startIcon={<SaveIcon />}
                onClick={(e) => this.handleCancelAppointmentConfirm('Cancelled ')}
              >
                Confirm
              </Button>
              <Button
                variant="contained"
                size="large"
                style={{ background: '#717373', color: '#fff', marginRight: '1rem' }}
                startIcon={<CloseIcon />}
                onClick={this.handleClose.bind(this)}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.calendarSyncModal}
            onClose={this.calendarSyncToggle.bind(this)}
            style={{ padding: '1em' }}
          >
            <DialogContent>
              <Row>
                <Col md={12} style={{ textAlign: 'center' }}>
                  <h5>
                    Sent to <b style={{ color: '#0f666b' }}>{this.userEmail ? this.userEmail : ''}</b>.
                  </h5>
                  <h5> Check your email for the event invite.</h5>
                </Col>
              </Row>
            </DialogContent>
            <DialogActions style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              <Button
                variant="contained"
                size="small"
                style={{ background: '#0f666b', color: '#fff', marginRight: '1rem' }}
                startIcon={<SaveIcon />}
                onClick={(e) => this.calendarSyncToggle()}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    }
  }
  viewEventWithoutAdvertisement() {
    if (this.state.render === false) {
      return <div />;
    } else {
      let taskNotes = [];
      this.state.eventNoteList.forEach((note, index) => {
        if ('delete' !== note.operation) {
          taskNotes.unshift(
            <Col md={6} style={{ marginTop: '1em' }}>
              <MemberEventNote
                ref={this.eventNoteRefList[index]}
                key={index}
                data={note}
                srno={index}
                eventId={this.state.id}
                getDeletePermission={this.state.getEventNoteDeletePermission}
                getUpdatePermission={this.state.getEventNoteUpdatePermission}
                getCreatePermission={this.state.getEventNoteCreatePermission}
                action={note.action ? note.action : 'view'}
                noteId={this.state.noteId}
                pageId={this.state.pageId}
                isPrintView={this.state.isPrintView}
                onNoteEdit={this.editNote.bind(this, note)}
                onNoteDelete={this.deleteEventNote.bind(this, note)}
                onNoteUpdate={this.updateEventNote.bind(this)}
                addNote={this.addEventNotesInBackend.bind(this)}
                onDelete={this.eventNoteRemove.bind(this)}
                onSuccess={this.getEventDetails.bind(this)}
              />
            </Col>
          );
        }
      });
      let reminderList = [];
      this.state.reminderItems.forEach((reminder, index) => {
        if ('delete' !== reminder.operation) {
          reminderList.push(
            <Col md={4} style={{ marginTop: '1em' }}>
              <Reminder
                ref={this.eventReminderRefList[index]}
                key={index}
                reminderDate={this.state.startDate}
                data={reminder}
                srNo={index}
                pageId={this.state.pageId}
                action={reminder.action ? reminder.action : 'view'}
                getDeletePermission={this.state.getDeletePermission}
                getUpdatePermission={this.state.getUpdatePermission}
                onReminderAdd={this.addNewReminderToBackend.bind(this)}
                onCancel={this.deleteReminder.bind(this)}
                onUpdate={this.updateReminder.bind(this)}
                onDelete={this.deleteReminderInBackend.bind(this)}
              />
            </Col>
          );
        }
      });

      return (
        <>
          {this.state.isViewButtonHide && (
            <>
              {this.state.isEditButtonHide && (
                <MenuWithBreadScrum
                  backCallback={this.cancelView.bind(this)}
                  deleteCallback={this.deleteEvent.bind(this)}
                  createSimilarCallback={this.repeatEvent.bind(this)}
                  printCallback={this.printPreviewEvent.bind(this)}
                  markCompleteCallback={this.addEventStatusInBackend.bind(this, 'Completed')}
                  syncToCalenderCallback={this.eventSyncToCalenderInBackend.bind(this)}
                  getDeletePermission={this.state.getDeletePermission}
                  getCreatePermission={this.state.getCreatePermission}
                  getAllPermission={this.state.getAllPermission}
                  breadCrumb={this.breadCrumb}
                  history={this.props.history}
                  name={this.title + ' For ' + this.memberName}
                  domainObject="Event"
                  appointmentDate={this.state.startDate}
                  status={this.state.status}
                  pageId="Event"
                />
              )}
            </>
          )}
          {this.state.isButtonHide && (
            <MenuWithBreadScrum
              breadCrumb={this.breadCrumb}
              history={this.props.history}
              name={'Edit Event For' + ' ' + this.memberName}
              domainObject="Event"
            />
          )}
          {this.state.eventNoteList.length > 0 ? (
            <Steps
              enabled={this.state.stepsEnabled}
              steps={this.state.stepsWithNote}
              initialStep={this.state.initialStep}
              onExit={this.onExit}
            />
          ) : (
            <Steps
              enabled={this.state.stepsEnabled}
              steps={this.state.steps}
              initialStep={this.state.initialStep}
              onExit={this.onExit}
            />
          )}
          <div>
            <Row style={{ position: 'relative', marginLeft: '1.2em', marginRight: '1.2em', marginTop: '1em' }}>
              {this.state.isEditButtonHide && (
                <>
                  <Col md={5} style={{ position: 'relative', padding: '8px' }}>
                    <Card style={{ borderRadius: '20px', minHeight: '25em' }}>
                      <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                        <Row>
                          <Col md={10}>
                            <label style={{ color: '#EC7063' }}>{this.state.viewEventTitle}</label>
                          </Col>
                          <Col md={2}>
                            {this.state.isViewButtonHide && (
                              <>
                                {this.state.getUpdatePermission === true && (
                                  <EditIconButton onClick={(e) => this.editEventDetails(e, 'edit')} />
                                )}
                              </>
                            )}
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md={12}>
                            <Row style={{ padding: '5px 0px' }}>
                              <Col md={3}>
                                <span style={{ color: '#000' }}>
                                  <strong>Date</strong>
                                </span>
                              </Col>
                              <Col md={8}>
                                <span style={{ color: '#000' }}>{this.state.eventStartDate}</span>
                              </Col>
                            </Row>

                            <Row style={{ padding: '5px 0px' }}>
                              <Col md={3}>
                                <span style={{ color: '#000' }}>
                                  <strong>Status</strong>
                                </span>
                              </Col>
                              <Col md={8}>
                                <span style={{ color: '#000' }}>{this.state.status}</span>
                              </Col>
                            </Row>
                            <Row style={{ padding: '5px 0px' }}>
                              <Col md={3}>
                                <span style={{ color: '#000' }}>
                                  <strong>Description</strong>
                                </span>
                              </Col>
                              <Col md={8}>
                                <span style={{ color: '#000' }}>
                                  <pre
                                    className="view_AppointmentNote"
                                    style={{ marginLeft: '-1.9px', textAlign: 'justify' }}
                                  >
                                    {this.state.note}
                                  </pre>
                                </span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={7} style={{ position: 'relative', padding: '8px' }}>
                    {this.state.showMapView && (
                      <Row>
                        <Col md={12}>
                          <Card style={{ borderRadius: '20px', minHeight: '25em' }}>
                            <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                              <Row>
                                <Col md={10}>
                                  <Label style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em' }}>
                                    Location Details
                                  </Label>
                                </Col>
                                <Col md={2}></Col>
                              </Row>
                            </CardHeader>
                            <CardBody>
                              <Row>
                                <Col md={12}>
                                  <ShortLocation
                                    // ref={this.addressRef}
                                    action="view"
                                    pageId="event"
                                    data={this.state.address}
                                    // isButtonHide="true"
                                  />
                                  {/* <Location action="view" pageId="event" data={this.state.locationDetails} /> */}
                                </Col>
                              </Row>
                              <Row style={{ margin: '0em' }}>
                                <Col md={12}>
                                  <Row md={12}>
                                    <Col md={12} style={{ padding: '0em' }}>
                                      {' '}
                                      <span className="closeFloatingButton" aria-label="add">
                                        {' '}
                                        <CloseIcon
                                          onClick={this.closeButton.bind(this)}
                                          style={{ float: 'right', fontSize: '15px', cursor: 'pointer' }}
                                        />
                                      </span>
                                    </Col>
                                  </Row>{' '}
                                  <Row>
                                    <Col md={12}>{this.getMapView(this.state.addressLocations)} </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>{' '}
                      </Row>
                    )}
                    {this.state.showLocationView && (
                      <Card style={{ borderRadius: '20px', minHeight: '25em' }}>
                        <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                          <Row>
                            <Col md={10}>
                              <Label style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em' }}>
                                Location Details
                              </Label>
                            </Col>
                            <Col md={2}></Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md={12}>
                              <ShortLocation
                                // ref={this.addressRef}
                                action="view"
                                pageId="event"
                                data={this.state.address}
                                // isButtonHide="true"
                              />
                              {/* <Location action="view" pageId="event" data={this.state.locationDetails} /> */}
                            </Col>
                          </Row>
                          <Row md={12} style={{ marginLeft: 'auto' }}>
                            {' '}
                            {/* <Button
                              type={this.state.type ? this.state.type : ''}
                              variant="contained"
                              size="small"
                              style={{ background: '#717373', color: '#fff', marginRight: '1rem', marginBottom: '1em' }}
                              onClick={(e) => this.showMap()}
                            >
                              Show Map
                            </Button> */}
                          </Row>
                        </CardBody>
                      </Card>
                    )}
                  </Col>
                </>
              )}
              {this.state.isButtonHide && this.editEvent()}
            </Row>
            {this.state.showEventNotePermission === true ? (
              <Row style={{ margin: 'auto', marginLeft: '0.8em', marginRight: '0.8em', marginTop: '-0.7em' }}>
                <Col md={12}>
                  <Card style={{ borderRadius: '20px', height: ' 100%' }}>
                    <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                      <Row>
                        <Col md={9}>
                          <Label
                            style={{
                              fontWeight: '600',
                            }}
                            className="appointment_section_header"
                            id="noteTitle"
                          >
                            Notes
                          </Label>
                        </Col>
                        <Col md={3} style={{ textAlign: 'end' }}>
                          {this.state.isViewButtonHide && (
                            <>
                              {this.state.getEventNoteCreatePermission === true && (
                                <span className="addNote" style={{ float: 'right' }}>
                                  <Tooltip title="Add Notes" aria-label="add">
                                    <AddIconButton onClick={this.addNotes.bind(this)} btnName="Add Note" />
                                  </Tooltip>
                                </span>
                              )}
                            </>
                          )}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row>{taskNotes}</Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : (
              <></>
            )}
            <Row style={{ position: 'relative', marginLeft: '0.8em', marginRight: '0.8em' }}>
              <Col md={12}>
                <Card style={{ height: '100%', borderRadius: '20px', marginTop: '1em' }}>
                  <CardHeader
                    style={{
                      borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
                    }}
                  >
                    <Row className="">
                      <Col md={9} className=" appointment_section_header">
                        <Label className="reminderTitle" style={{ fontWeight: '600', color: '#0099f1' }}>
                          Reminders
                        </Label>
                      </Col>
                      <Col md={3} style={{ textAlign: 'end' }}>
                        <>
                          {this.state.getCreatePermission === true && (
                            <span className="addReminder" style={{ float: 'right' }}>
                              <Tooltip title="Add Reminder" aria-label="add">
                                <AddIconButton onClick={this.addReminder.bind(this)} btnName="Add Reminder" />
                              </Tooltip>
                            </span>
                          )}
                        </>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {' '}
                    <Row>{reminderList}</Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {this.state.showEventTransportationPermission === true ? (
              <Row style={{ position: 'relative', marginLeft: '0.8em', marginRight: '0.8em', marginTop: '2em' }}>
                <Col md={12}>
                  <Card style={{ borderRadius: '20px', margin: 'auto' }}>
                    <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                      <Row>
                        <Col md={9}>
                          <Label style={{}} className="transportationTitle appointment_section_header">
                            Transportation
                          </Label>
                        </Col>

                        <Col md={3} style={{ textAlign: 'end' }}>
                          {this.state.isViewButtonHide && (
                            <>
                              {this.state.getEventTransportationCreatePermission === true ? (
                                this.state.appointmentStatus === 'Completed' ||
                                this.state.startDate < this.state.currentCompareDateTime ? (
                                  ' '
                                ) : (
                                  <Tooltip title="Add  Transportation" aria-label="add">
                                    <span className="addTransportation" style={{ float: 'right' }}>
                                      <AddIconButton
                                        onClick={this.addTransportationRequest.bind(this)}
                                        btnName="Add Transportation"
                                      />
                                    </span>
                                  </Tooltip>
                                )
                              ) : (
                                <></>
                              )}
                            </>
                          )}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      {this.state.transportationList.length > 0 && (
                        <EventTransportation
                          transportationEventList={this.state.transportationList}
                          isPrintView={this.state.isPrintView}
                          getDeletePermission={this.state.getEventTransportationDeletePermission}
                          getUpdatePermission={this.state.getEventTransportationUpdatePermission}
                          getCreatePermission={this.state.getEventTransportationCreatePermission}
                          onTransportationSuccess={this.updateTransportationInBackend.bind(this)}
                          eventId={this.state.id}
                          action="list"
                          onSuccess={this.getEventDetails.bind(this)}
                        ></EventTransportation>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : (
              <></>
            )}
            <>
              {this.state.isViewButtonHide && (
                <>
                  <div
                    style={{ textAlign: 'center', padding: '0em 0em 1em', margin: '2em' }}
                    className="btn_appointment_group"
                  >
                    <BackButton
                      type="button"
                      style={{ backgroundColor: '#162c50', color: '#fff', marginBottom: '1em !important' }}
                      variant="contained"
                      startIcon={<CloseIcon />}
                      onClick={(e) => this.cancelView()}
                    >
                      Back
                    </BackButton>
                    {this.state.getCreatePermission ||
                    this.state.getUpdatePermission ||
                    this.state.getDeletePermission ? (
                      <>
                        {(this.state.startDate < this.state.currentCompareDateTime &&
                          this.state.startDate !== this.state.currentCompareDateTime &&
                          this.state.status === 'Scheduled') ||
                        this.state.status === 'Cancelled' ? (
                          <>
                            {' '}
                            <Button
                              type="submit"
                              size="small"
                              style={{ background: '#1b7189', color: '#fff', marginRight: '1rem' }}
                              variant="contained"
                              startIcon={<OfflinePinIcon />}
                              onClick={(e) => this.addEventStatusInBackend('Completed')}
                            >
                              {' '}
                              Mark Completed
                            </Button>
                          </>
                        ) : (
                          ''
                        )}
                        {this.state.status === 'Scheduled' ? (
                          <Button
                            type="submit"
                            style={{ background: '#1b7189', color: '#fff', marginRight: '1rem' }}
                            variant="contained"
                            size="small"
                            startIcon={<CancelScheduleSendIcon />}
                            onClick={this.changeAppointmentStatusInBackend.bind(this)}
                          >
                            {' '}
                            Mark Cancelled
                          </Button>
                        ) : (
                          ''
                        )}
                        &nbsp;
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              )}
            </>
          </div>
          <Modal isOpen={this.state.transportationModal} toggle={this.transportationToggle} size="lg">
            <ModalHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }} toggle={this.transportationToggle}>
              <Label style={{ fontWeight: '600', color: '#162c50' }}>Transportation Request</Label>
            </ModalHeader>
            <ModalBody>
              <Row>
                <EventTransportation
                  ref={this.eventTransportationRef}
                  title="Transportation Request"
                  data={this.state.eventTransportation}
                  addressData={this.state.transportationAddress}
                  transportationDate={this.state.startDate}
                  action="create"
                  pageId={this.state.pageId}
                  eventId={this.state.id}
                  onChange={this.handleEventTransportationChange.bind(this)}
                  onTransportationAdd={this.addTransportationInBackend.bind(this)}
                  onUpdate={this.updateTransportationInBackend.bind(this)}
                  onSuccess={this.getEventDetails.bind(this)}
                />
              </Row>
            </ModalBody>
          </Modal>
          <Dialog
            open={this.state.openCancelAppointment}
            onClose={this.handleClose.bind(this)}
            onHide={this.close}
            style={{ padding: '1em' }}
          >
            <DialogContent>
              <Row style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                <Col md={12} style={{ textAlign: 'center' }}>
                  <ErrorOutlineIcon
                    style={{
                      border: '2px solid #fff',
                      borderRadius: '25%',
                      background: '#066f72 !important',
                      color: 'red',
                      fontSize: '50px',
                    }}
                  />
                </Col>
              </Row>
              <span style={{ textAlign: 'center' }}>
                <h2 style={{ fontSize: '20px' }}>Do you really want to cancel event?</h2>

                <h5 style={{ color: '#0f666b', fontWeight: 'bold', fontSize: '25px' }}>{this.state.text}</h5>

                <h2 style={{ fontSize: '20px' }}>It cannot be recovered once cancelled.</h2>
              </span>
            </DialogContent>
            <DialogActions style={{ marginTop: '1em', padding: '2em', marginLeft: 'auto', marginRight: 'auto' }}>
              <Button
                variant="contained"
                size="large"
                style={{ background: '#0f666b', color: '#fff', marginRight: '1rem' }}
                startIcon={<SaveIcon />}
                onClick={(e) => this.handleCancelAppointmentConfirm('Cancelled ')}
              >
                Confirm
              </Button>
              <Button
                variant="contained"
                size="large"
                style={{ background: '#717373', color: '#fff', marginRight: '1rem' }}
                startIcon={<CloseIcon />}
                onClick={this.handleClose.bind(this)}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.calendarSyncModal}
            onClose={this.calendarSyncToggle.bind(this)}
            style={{ padding: '1em' }}
          >
            <DialogContent>
              <Row>
                <Col md={12} style={{ textAlign: 'center' }}>
                  <h5>
                    Sent to <b style={{ color: '#0f666b' }}>{this.userEmail ? this.userEmail : ''}</b>.
                  </h5>
                  <h5> Check your email for the event invite.</h5>
                </Col>
              </Row>
            </DialogContent>
            <DialogActions style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              <Button
                variant="contained"
                size="small"
                style={{ background: '#0f666b', color: '#fff', marginRight: '1rem' }}
                startIcon={<SaveIcon />}
                onClick={(e) => this.calendarSyncToggle()}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    }
  }

  editEvent() {
    let locationItems = [];

    this.state.locationList.forEach((element, index) => {
      if ('delete' !== element.operation) {
        locationItems.push(
          <Col md={12} style={{ padding: '0.5em' }}>
            <Location
              ref={this.locationReferenceList[index]}
              key={index}
              data={element}
              srNo={index}
              pageId="event"
              action={element.action ? element.action : 'edit'}
              onLocationAdd={this.addNewLocation.bind(this)}
              onUpdate={this.updateLocation.bind(this)}
            />
          </Col>
        );
      }
    });

    return (
      <>
        <Col md={12}>
          {' '}
          <Card className="doctorViewCard">
            <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.5em' }}>
              <Row>
                <Col md={9}>
                  {undefined !== this.state.title && this.state.title.length > 0 && (
                    <Label style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.3em' }}>
                      {this.state.title}
                    </Label>
                  )}
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row style={{ marginTop: '1em', marginBottom: '-1em' }}>
                <Col md={6}>
                  <Row style={{ marginBottom: '-1em' }}>
                    <Col md={6} style={{ marginBottom: '1em' }}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ThemeProvider theme={materialTheme}>
                          <DateTimePicker
                            value={this.state.startDate}
                            variant="outlined"
                            fullWidth
                            className="ptsDateTimePicker"
                            label="Date"
                            inputVariant="outlined"
                            timeInputLabel="Time:"
                            format="MM/dd/yyyy  h:mm aa"
                            style={{ background: '#fff' }}
                            onChange={this.handleEventStartDateChange.bind(this)}
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>
                    </Col>
                    <Col md={6}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="description"
                        label="Title"
                        value={this.state.eventTitle}
                        onChange={this.handleEventTitleChange.bind(this)}
                        name="name"
                        type="text"
                        className="textField"
                        InputProps={{}}
                      />
                    </Col>
                  </Row>

                  <Row>
                    <Col md={12} style={{ marginTop: '25px' }}>
                      <TextareaAutosize
                        aria-label="minimum height"
                        style={{ width: '100%', padding: '10px' }}
                        rowsMin={5}
                        id="description"
                        value={this.state.note}
                        inputProps={{ maxLength: 256, padding: '10px' }}
                        name="note"
                        onChange={this.handleDescriptionChange.bind(this)}
                        onPressEnter={this.handleDescriptionChange.bind(this)}
                        placeholder="Description"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={6} className="locationRow">
                  <div className="location_details_view">
                    <div className="panel panel-blue">
                      <div className="panel-heading">
                        {/* {locationItems} */}
                        <ShortLocation
                          ref={this.addressRef}
                          onChange={this.handleAddressChange.bind(this)}
                          data={this.state.address}
                          action="edit"
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <div style={{ textAlign: 'center', padding: '0em 0em 1em' }}>
                <SaveButton type="submit" onClick={(e) => this.updateEventInBackend(e)} />
                &nbsp;
                <CancelIconButton type="button" onClick={(e) => this.editCancel(e, this.state.id)} />
              </div>
            </CardFooter>
          </Card>
        </Col>
        <Dialog
          open={this.state.sameDateEventWindow}
          onClose={this.handleSameDateEventCreateWindowClose.bind(this)}
          onHide={this.close}
          style={{ padding: '1em' }}
        >
          <DialogContent style={{ marginTop: '2em' }}>
            <Row>
              <Col md={12} style={{ textAlign: 'center' }}>
                <h5>{this.state.WarningMessage}</h5>
                <br />
                {this.state.WarningMessage ? <h5>Do you still want to continue to create?</h5> : ''}
              </Col>
            </Row>
          </DialogContent>
          <DialogActions style={{ marginTop: '0em', padding: '1em', marginLeft: 'auto', marginRight: 'auto' }}>
            <Button
              variant="contained"
              size="large"
              style={{ background: '#0f666b', color: '#fff', marginRight: '1rem' }}
              startIcon={<SaveIcon />}
              onClick={this.updateSameEventInBackend.bind(this)}
            >
              Ok
            </Button>
            <Button
              variant="contained"
              size="large"
              style={{ background: '#717373', color: '#fff', marginRight: '1rem' }}
              startIcon={<CloseIcon />}
              s
              onClick={this.handleSameDateEventCreateWindowClose.bind(this)}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  printEvent() {
    let taskNotes = [];
    this.state.eventNoteList.forEach((note, index) => {
      if ('delete' !== note.operation) {
        taskNotes.unshift(
          <Col md={6} style={{ marginTop: '1em' }}>
            <MemberEventNote
              ref={this.eventNoteRefList[index]}
              key={index}
              data={note}
              srno={index}
              eventId={this.state.id}
              getDeletePermission={this.state.getEventNoteDeletePermission}
              getUpdatePermission={this.state.getEventNoteUpdatePermission}
              getCreatePermission={this.state.getEventNoteCreatePermission}
              action={note.action ? note.action : 'view'}
              noteId={this.state.noteId}
              pageId={this.state.pageId}
              isPrintView="view_print"
              onNoteEdit={this.editNote.bind(this, note)}
              onNoteDelete={this.deleteEventNote.bind(this, note)}
              onNoteUpdate={this.updateEventNote.bind(this)}
              addNote={this.addEventNotesInBackend.bind(this)}
              onDelete={this.eventNoteRemove.bind(this)}
              onSuccess={this.getEventDetails.bind(this)}
            />
          </Col>
        );
      }
    });
    let reminderList = [];
    this.state.reminderItems.forEach((reminder, index) => {
      this.eventReminderRefList[index] = React.createRef();
      if ('delete' !== reminder.operation) {
        reminderList.push(
          <Col md={4} style={{ marginTop: '1em' }}>
            <Reminder
              ref={this.eventReminderRefList[index]}
              key={index}
              reminderDate={this.state.startDate}
              data={reminder}
              srNo={index}
              pageId={this.state.pageId}
              isPrintView="view_print"
              action={reminder.action ? reminder.action : 'view'}
              onReminderAdd={this.addNewReminderToBackend.bind(this)}
              onCancel={this.deleteReminder.bind(this)}
              onUpdate={this.updateReminder.bind(this)}
              onDelete={this.deleteReminderInBackend.bind(this)}
            />
          </Col>
        );
      }
    });

    return (
      <>
        {this.state.isPrintView === 'view_print' ? (
          <>
            <Row md={12} className={this.state.headerClassName}>
              <Col md={4}>
                <h5 style={{ textTransform: 'capitalize' }}>
                  <b>{this.title + ' For ' + this.memberName}</b>
                </h5>
              </Col>
              <Col md={8} style={{ textTransform: 'capitalize' }}></Col>
            </Row>
          </>
        ) : (
          <MenuWithBreadScrum
            backCallback={this.cancelPrintView.bind(this)}
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            name={'Print Preview of Event' + ' For ' + this.memberName}
            domainObject="Event"
          />
        )}

        {this.state.isPrintView === 'view_print' ? (
          <></>
        ) : (
          <Row>
            <Col md={12}>
              <ReactToPrint
                trigger={() => (
                  <span style={{ cursor: 'pointer', fontSize: 'x-larger', marginRight: '2em', float: 'right' }}>
                    <Tooltip title="Print">
                      {/* <PrintRoundedIcon
                        style={{
                          borderRadius: '25%',
                          background: '#867e7e',
                          color: 'white',
                          padding: '3px',
                          fontSize: 'x-large',
                          width: '30px',
                          minHeight: '30px',
                        }}
                      /> */}
                      <Button
                        className="add"
                        style={{
                          background: '#867e7e',
                          color: 'white',
                          fontSize: 'x-large',
                          textTransform: 'capitalize',
                          cursor: 'pointer',
                          fontSize: 'x-larger',
                          float: 'right',
                          margin: '0.2em 0.5em 0.3em 0em',
                        }}
                        startIcon={<PrintRoundedIcon />}
                      >
                        Print
                      </Button>
                    </Tooltip>
                  </span>
                )}
                content={() => this.componentRef}
              />
            </Col>
          </Row>
        )}
        <div id="printform" style={{ overflowY: 'scroll', margin: '1em' }} ref={(el) => (this.componentRef = el)}>
          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={12}>
              {this.state.isEditButtonHide && (
                <Row>
                  <Col md={6}>
                    <Card style={{ borderRadius: '20px', minHeight: '25em' }}>
                      <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                        <Row style={{ position: 'relative', marginTop: '0.5em' }}>
                          <Col md={12}>
                            {' '}
                            <label style={{ color: '#000' }}>{'Event For ' + this.memberName}</label>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <label style={{ color: '#EC7063' }}>{this.state.viewEventTitle}</label>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md={12}>
                            <Row style={{ padding: '5px 0px' }}>
                              <Col md={3}>
                                <span style={{ color: '#000' }}>
                                  <strong>Date</strong>
                                </span>
                              </Col>
                              <Col md={8}>
                                <span style={{ color: '#000' }}>{this.state.eventStartDate}</span>
                              </Col>
                            </Row>

                            <Row style={{ padding: '5px 0px' }}>
                              <Col md={3}>
                                <span style={{ color: '#000' }}>
                                  <strong>Status</strong>
                                </span>
                              </Col>
                              <Col md={8}>
                                <span style={{ color: '#000' }}>{this.state.status}</span>
                              </Col>
                            </Row>
                            <Row style={{ padding: '5px 0px' }}>
                              <Col md={3}>
                                <span style={{ color: '#000' }}>
                                  <strong>Description</strong>
                                </span>
                              </Col>

                              <Col md={8}>
                                <span style={{ color: '#000' }}>
                                  <pre
                                    className="view_AppointmentNote"
                                    style={{ marginLeft: '-1.9px', textAlign: 'justify' }}
                                  >
                                    {this.state.note}
                                  </pre>
                                </span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={6}>
                    {this.state.showMapView && (
                      <Row>
                        <Col md={12}>
                          <Card style={{ borderRadius: '20px', minHeight: '25em' }}>
                            <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                              <Row>
                                <Col md={10}>
                                  <Label style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em' }}>
                                    Location Details
                                  </Label>
                                </Col>
                                <Col md={2}></Col>
                              </Row>
                            </CardHeader>
                            <CardBody>
                              <Row>
                                <Col md={12}>
                                  <ShortLocation
                                    // ref={this.addressRef}
                                    action="view"
                                    pageId="event"
                                    data={this.state.address}
                                    // isButtonHide="true"
                                  />
                                  {/* <Location action="view" pageId="event" data={this.state.locationDetails} /> */}
                                </Col>
                              </Row>
                              <Row style={{ margin: '0em' }}>
                                <Col md={12}>
                                  <Row md={12}>
                                    <Col md={12} style={{ padding: '0em' }}>
                                      {' '}
                                      <span className="closeFloatingButton" aria-label="add">
                                        {' '}
                                        <CloseIcon
                                          onClick={this.closeButton.bind(this)}
                                          style={{ float: 'right', fontSize: '15px', cursor: 'pointer' }}
                                        />
                                      </span>
                                    </Col>
                                  </Row>{' '}
                                  <Row>
                                    <Col md={12}>{this.getMapView(this.state.addressLocations)} </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>{' '}
                      </Row>
                    )}
                    {this.state.showLocationView && (
                      <Card style={{ borderRadius: '20px', minHeight: '25em' }}>
                        <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                          <Row>
                            <Col md={10}>
                              <Label style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em' }}>
                                Location Details
                              </Label>
                            </Col>
                            <Col md={2}></Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md={12}>
                              <ShortLocation
                                // ref={this.addressRef}
                                action="view"
                                pageId="event"
                                data={this.state.address}
                                // isButtonHide="true"
                              />
                              {/* <Location action="view" pageId="event" data={this.state.locationDetails} /> */}
                            </Col>
                          </Row>
                          <Row md={12} style={{ marginLeft: 'auto' }}>
                            {' '}
                            {/* <Button
                              type={this.state.type ? this.state.type : ''}
                              variant="contained"
                              size="small"
                              style={{ background: '#717373', color: '#fff', marginRight: '1rem', marginBottom: '1em' }}
                              onClick={(e) => this.showMap()}
                            >
                              Show Map
                            </Button> */}
                          </Row>
                        </CardBody>
                      </Card>
                    )}
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
          {this.state.showEventNotePermission === true ? (
            <Row style={{ position: 'relative', margin: '1em' }}>
              <Col md={12}>
                <Card style={{ height: '100%', borderRadius: '20px', marginTop: '1em' }}>
                  <CardHeader
                    style={{
                      borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
                    }}
                  >
                    <Row className="">
                      <Col md={12} className=" appointment_section_header">
                        <Label className="reminderTitle" style={{ fontWeight: '600', color: '#0099f1' }}>
                          Notes
                        </Label>
                      </Col>
                    </Row>
                  </CardHeader>
                  {this.state.noteListRow && (
                    <CardBody>
                      <Row>{taskNotes}</Row>
                    </CardBody>
                  )}
                </Card>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={12}>
              <Card style={{ height: '100%', borderRadius: '20px', marginTop: '1em' }}>
                <CardHeader
                  style={{
                    borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
                  }}
                >
                  <Row className="">
                    <Col md={12} className=" appointment_section_header">
                      <Label className="reminderTitle" style={{ fontWeight: '600', color: '#0099f1' }}>
                        Reminders
                      </Label>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {' '}
                  <Row>{reminderList}</Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          {this.state.showEventTransportationPermission === true ? (
            <Row style={{ position: 'relative', margin: '1em' }}>
              <Col md={12}>
                <Card style={{ height: '100%', borderRadius: '20px', marginTop: '1em' }}>
                  <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                    <Row>
                      <Col md={12}>
                        <Label style={{}} className="transportationTitle appointment_section_header">
                          Transportation
                        </Label>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {this.state.transportationList.length > 0 && (
                      <EventTransportation
                        transportationEventList={this.state.transportationList}
                        isPrintView={this.state.isPrintView}
                        getDeletePermission={this.state.getEventTransportationDeletePermission}
                        getUpdatePermission={this.state.getEventTransportationUpdatePermission}
                        getCreatePermission={this.state.getEventTransportationCreatePermission}
                        onTransportationSuccess={this.updateTransportationInBackend.bind(this)}
                        eventId={this.state.id}
                        action="list"
                        onSuccess={this.getEventDetails.bind(this)}
                      ></EventTransportation>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </div>
        {/* <Dialog
          open={this.state.sameDateEventWindow}
          onClose={this.handleSameDateEventCreateWindowClose.bind(this)}
          onHide={this.close}
          style={{ padding: '1em' }}
        >
          <DialogContent style={{ marginTop: '2em' }}>
            <Row>
              <Col md={12} style={{ textAlign: 'center' }}>
                <h5>{this.state.WarningMessage}</h5>
              </Col>
            </Row>
          </DialogContent>
          <DialogActions style={{ marginTop: '0em', padding: '1em', marginLeft: 'auto', marginRight: 'auto' }}>
            <Button
              variant="contained"
              size="small"
              style={{ background: '#3498DB', color: '#fff', marginRight: '1rem' }}
              onClick={this.addSameEventInBackend.bind(this)}
            >
              Ok
            </Button>
            <Button
              variant="contained"
              size="small"
              style={{ background: '#717D7E', color: '#fff', marginRight: '1rem' }}
              onClick={this.handleSameDateEventCreateWindowClose.bind(this)}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog> */}
      </>
    );
  }
}
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#008181',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: 'white',
        color: '#008181',
      },
    },
  },
});
