import { CLEAR_FCC_ADDRESS_STATE, GET_FCC_ADDRESS_STATE, SET_FCC_ADDRESS_STATE } from './fccAddressStateType';

const initialState = {};

const fccAddressStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FCC_ADDRESS_STATE:
      return {
        ...state,
      };
    case SET_FCC_ADDRESS_STATE:
      return {
        ...state,
        fccAddressState: action.payload,
      };

    case CLEAR_FCC_ADDRESS_STATE:
      return {
        ...state,
        fccAddressState: null,
      };
    default:
      return state;
  }
};

export default fccAddressStateReducer;
