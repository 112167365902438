import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import AccessAlarmTwoToneIcon from '@material-ui/icons/AccessAlarmTwoTone';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ChatIcon from '@material-ui/icons/Chat';
import CreateIcon from '@material-ui/icons/Create';
import DescriptionIcon from '@material-ui/icons/Description';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MaterialTable from 'material-table';
import moment from 'moment';
import React, { Component } from 'react';
// import DatePicker from 'react-datepicker';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Card, CardBody, CardFooter, CardHeader, Col, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import UiUtils from '../../../provider/UiUtils';
import Utils from '../../../provider/Utils';
import store from '../../../redux/store';
import CancelIconButton from '../buttons/CancelIconButton';
import CreateButton from '../buttons/CreateButton';
import DeleteButton from '../buttons/DeleteButton';
import EditButton from '../buttons/EditButton';
import SaveButton from '../buttons/SaveButton';
import Location from '../location/Location';
import PtsAlert from '../ptsAlert/PtsAlert';
import Reminder from '../reminder/Reminder';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { DatePicker, MuiPickersUtilsProvider, DateTimePicker, LocalizationProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';
import './ReferenceAppointment.scss';
class ReferenceAppointment extends Component {
  taskOccuranceList = store.getState().staticData.taskOccuranceList;
  appointmentTypeListArray = store.getState().staticData.appointmentTypeList;
  appointmentPurposeListData = store.getState().staticData.appointmentPurposeList;
  header = store.getState().header.header;
  memberDetails = store.getState().memberData.memberData;
  appointmentReminderRefList = [];
  constructor(props) {
    super(props);

    let action = props.action;
    let isViewButtonHide = true;
    if (props.isPrintView === 'view_print') {
      isViewButtonHide = false;
    }
    this.state = {
      referenceAppointmentList: props.referenceAppointmentList,
      action: action,
      onCancel: props.onCancel,
      onSuccess: props.onSuccess,
      onCreate: props.onCreate,
      onUpdate: props.onUpdate,
      onDelete: props.onDelete,
      appointmentId: props.appointmentId,
      isReferenceAppointmentEdit: false,
      isReferenceAppointmentView: true,
      isDoctorReferenceAppointment: false,
      isFacilityReferenceAppointment: false,
      isCreateDoctor: false,
      isCreateFacility: false,
      appointmentTaskReminderList: [],
      referenceAppointmentReminderItems: [],
      appointmentList: [],
      doctorLocationList: [],
      facilityLocationList: [],
      taskNote: '',
      taskType: '',
      appointmentReference: '',
      taskOccurrence: '',
      facility: '',
      noteDate: new Date(),
      isViewButtonHide: isViewButtonHide,
      getDeletePermission: props.getDeletePermission,
      getUpdatePermission: props.getUpdatePermission,
      selectedDoctorLocation: '',
      referenceAppointmentFacilityLocation: {},
      referenceAppointmentDoctor: {},
      pageSize: 10,
      isPaging: false,
    };
  }

  cancelReferenceAppointment(e) {
    if (undefined !== this.state.onCancel && null !== this.state.onCancel) {
      this.state.onCancel(this.state);
    }
  }

  deleteReferenceAppointment = (e, id) => {
    const postObject = {
      header: this.header,
      appointmentTask: {
        id: id,
      },
    };
    FetchServerData.callPostService('/appointmentTaskms/delete/', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        for (let i = 0; i < this.state.referenceAppointmentList.length; i++) {
          if (this.state.referenceAppointmentList[i].id === id) {
            this.state.referenceAppointmentList.splice(i, 1);
          }
        }
        this.props.onSuccess();
        this.setState({
          referenceAppointmentList: this.state.referenceAppointmentList,
          sweetAlert: false,
        });
      } else {
      }
    });
  };
  addAppointmentTaskInBackend(e) {
    if (undefined !== this.state.onCreate && null !== this.state.onCreate) {
      this.state.onCreate(this.state);
    }
  }
  updateAppointmentTaskInBackend(e) {
    let dateTime = Utils.convertUserTimeZoneDateToUTCDate(this.state.noteDate);
    const postObject = {
      header: this.header,
      appointmentTask: {
        id: this.state.appointmentTaskId,
        note: this.state.taskNote,
        date: dateTime,
        appointment: {
          id: this.state.appointmentId,
        },
        referenceAppointment: {
          id: this.state.appointmentReferenceId,
        },
        facility: {
          id: this.state.facility,
        },
        type: {
          type: 'Appointment',
        },
        occurance: {
          occurance: this.state.taskOccurrence,
        },
        reminderList: [],
      },
    };
    FetchServerData.callPostService('/appointmentTaskms/update', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          this.props.onSuccess();
          let referenceAppointmentData = output.data;
          PtsAlert.success(output.message);
          this.setState({
            isReferenceAppointmentView: true,
          });
        } else {
        }
      })
      .catch((error) => {});
    if (undefined !== this.state.onUpdate && null !== this.state.onUpdate) {
      this.state.onUpdate(this.state);
    }
  }
  handleTaskOccurrenceChange(e) {
    this.setState({ taskOccurrence: e.target.value });
  }
  toggle = () => {
    this.setState({
      listModal: !this.state.listModal,
    });
  };

  /* get  Member Appointments list data */
  getReferenceAppointments = () => {
    const postObject = {
      header: this.header,
      appointmentTask: {
        occurance: {
          occurance: this.state.taskOccurrence,
        },
        appointment: {
          id: this.state.appointmentId,
          member: {
            id: this.memberDetails.member,
          },
        },
      },
    };
    FetchServerData.callPostService('appointmentms/getReferanceAppointmentForMember', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let appointmentListArr = [];
          appointmentListArr = output.data;
          let isPaging = false;
          if (appointmentListArr.length > 10) {
            isPaging = true;
          }
          this.setState({
            isPaging: isPaging,
            appointmentList: appointmentListArr,
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  selectAppointment = (e) => {
    if (this.state.taskOccurrence === '') {
      PtsAlert.error('Please Select Occurrence');
    } else {
      this.d = new Date();
      this.currentDate = moment(this.d).format('DD/MMMM/YYYY');
      let monYrArr = this.currentDate.split('/');
      let currentMonth = monYrArr[1];
      let year = monYrArr[2];
      this.setState({
        headerMonth: currentMonth,
        headerYear: year,
      });
      this.getReferenceAppointments();
      this.setState({
        listModal: true,
      });
    }
  };

  createAppointmentModal = (e) => {
    if (this.state.taskOccurrence === '') {
      PtsAlert.error('Please Select Occurrence');
    } else {
      this.setState({
        createAppointmentModal: true,
        pageId: '/viewMemberAppointment',
        referenceAppointmentStartDate: new Date(),
        referenceAppointmentType: '',
        referenceAppointmentDescription: '',
        referenceAppointmentPurpose: '',
        referenceAppointmentDoctor: '',
        referenceAppointmentReminderItems: [],
        listModal: false,
      });
    }
  };

  handleNoteDateChange(data) {
    console.log(data);
    this.setState({ noteDate: data });
  }

  handleTaskNoteChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 256);
    if (res.length < 256) {
      this.setState({ taskNote: res });
    } else {
      Utils.maxFieldLength(256);
    }
  }

  handleReferenceAppointmentChange(e) {
    this.setState({
      appointmentReference: e.target.value,
    });
  }

  cancelUpdateReferenceAppoint(e) {
    this.setState({
      isReferenceAppointmentView: true,
      isReferenceAppointmentEdit: false,
    });
  }

  /* selected Reference Appointment data */
  selectedReferenceAppointment(e, rowData) {
    let startDate = '';
    let newStartDate = '';

    if (rowData.date !== undefined) {
      startDate = rowData.date;

      newStartDate = Utils.convertUTCDateToUserTimeZoneDate(startDate);
    }
    let appointment = rowData.type === 'Doctor Appointment' ? rowData.doctor : rowData.facility;
    let referenceAppointment = appointment.split('-');
    this.setState({
      listModal: false,
      appointmentReference: referenceAppointment[0] + '-' + rowData.purpose,

      appointmentReferenceId: rowData.id,
      taskNote: rowData.description,
      noteDate: new Date(newStartDate),
    });
  }

  handleReferenceAppointmentTypeChange(e) {
    if (e.target.value === 'Doctor Appointment') {
      this.setState({
        isDoctorReferenceAppointment: true,
        isFacilityReferenceAppointment: false,
        referenceAppointmentType: e.target.value,
      });
      this.getReferenceAppointmentDoctorLocationList();
    } else {
      this.setState({
        isDoctorReferenceAppointment: false,
        isFacilityReferenceAppointment: true,
        referenceAppointmentType: e.target.value,
      });
      this.getReferenceAppointmentFacilityLocationList();
    }
  }

  handleReferenceAppointmentStartDateChange(data) {
    this.setState({ referenceAppointmentStartDate: data });
  }

  handleReferenceAppointmentPurposeChange(e) {
    if (e.target.value === 'Other') {
      this.setState({
        isOther: true,
        referenceAppointmentPurpose: e.target.value,
      });
    } else {
      this.setState({
        isOther: false,
        referenceAppointmentPurpose: e.target.value,
      });
    }
  }

  handleReferenceAppointmentDoctorChange(doctor) {
    this.setState({ referenceAppointmentDoctor: doctor, isDoctorLocation: false });
    if (doctor) {
      this.getDoctorLocation(doctor.locationId, doctor.doctorId);
    }

    this.state.referenceAppointmentDoctor = doctor;
    if (undefined !== this.state.onChange && null !== this.state.onChange) {
      this.state.onChange(this.state);
    }
  }

  getDoctorLocation(locationId, doctorId) {
    const postObject = {
      header: this.header,
      doctorLocation: {
        id: locationId,
        doctor: {
          id: doctorId,
        },
      },
    };
    FetchServerData.callPostService('/doctorms/getLocation', postObject).then((output) => {
      if (Utils.equalsIgnoreCase(output.status, 'SUCCESS')) {
        let doctorLocation = output.data;
        this.setState({
          selectedDoctorLocation: doctorLocation,
          isDoctorLocation: true,
        });
      } else if (Utils.equalsIgnoreCase(output.status, 'FAIL')) {
      }
    });
  }

  toDoctorCreate = (e) => {
    this.props.history.push({
      pathname: '/addDoctor',
      state: { action: 'create', title: 'Add Doctor' },
    });
  };

  handleReferenceAppointmentFacilityLocationChange(facility) {
    this.setState({ referenceAppointmentFacilityLocation: facility, isDoctorLocation: false });
    if (facility) {
      this.getFacilityLocation(facility.locationId, facility.facilityId);
    }
    this.state.referenceAppointmentFacilityLocation = facility;
    if (undefined !== this.state.onChange && null !== this.state.onChange) {
      this.state.onChange(this.state);
    }
  }

  getFacilityLocation(locationId, facilityId) {
    const postObject = {
      header: this.header,
      facilityLocation: {
        id: locationId,
        facility: {
          id: facilityId,
        },
      },
    };
    FetchServerData.callPostService('/facilityms/getLocation', postObject).then((output) => {
      if (Utils.equalsIgnoreCase(output.status, 'SUCCESS')) {
        let facilityLocation = output.data;

        this.setState({
          selectedDoctorLocation: facilityLocation,
          isDoctorLocation: true,
        });
      } else if (Utils.equalsIgnoreCase(output.status, 'FAIL')) {
      }
    });
  }

  handleReferenceAppointmentDescriptionChange(e) {
    this.setState({ referenceAppointmentDescription: e.target.value });
  }

  toFacilityCreate = (e) => {
    this.props.history.push({
      pathname: '/addFacility',
      state: { action: 'create', title: 'Add Facility' },
    });
  };

  addReferenceAppointmentReminder(type) {
    let items = this.state.referenceAppointmentReminderItems;
    let item = {
      action: 'create',
      operation: 'add',
    };
    items.push(item);
    this.appointmentReminderRefList.push(React.createRef());

    this.setState({ referenceAppointmentReminderItems: items });
  }
  toggleCreateAppointmentModal = () => {
    this.setState({
      createAppointmentModal: !this.state.createAppointmentModal,
    });
  };
  addReferenceAppointmentNewReminder = (reminderData) => {
    for (let i = 0; i < this.state.referenceAppointmentReminderItems.length; i++) {
      if (
        this.state.referenceAppointmentReminderItems[i] &&
        reminderData.index === i &&
        this.appointmentReminderRefList[i] &&
        this.appointmentReminderRefList[i].current &&
        this.appointmentReminderRefList[i].current.validateReminder(i)
      ) {
        this.state.referenceAppointmentReminderItems[i] = reminderData;
        this.appointmentReminderRefList[i].current.state = reminderData;
        this.appointmentReminderRefList[i].current.state.action = 'view';
        this.setState({ render: true });
      }
    }
  };

  updateReferenceAppointmentReminder = (reminderData) => {
    for (let i = 0; i < this.state.referenceAppointmentReminderItems.length; i++) {
      if (
        this.state.referenceAppointmentReminderItems[i] &&
        reminderData.index === i &&
        this.appointmentReminderRefList[i] &&
        this.appointmentReminderRefList[i].current &&
        this.appointmentReminderRefList[i].current.validateReminder()
      ) {
        this.state.referenceAppointmentReminderItems[i] = reminderData;
        this.appointmentReminderRefList[i].current.state = reminderData;
        this.appointmentReminderRefList[i].current.state.action = 'view';
        this.setState({ render: true });
      }
    }
  };

  referenceAppointmentReminderItemDeleted(item) {
    let items = [];
    if (item.operation && 'update' === item.operation) {
      /* Existing Item Deleted, Mark it deleted */
      let index = item.index;

      for (let i = 0; i < this.state.referenceAppointmentReminderItems.length; i++) {
        let item = this.state.referenceAppointmentReminderItems[i];
        if (this.state.referenceAppointmentReminderItems[i].operation === 'update' && index === i) {
          item.operation = 'delete';
        }

        items.push(item);
      }

      this.setState({ referenceAppointmentReminderItems: items });
    }
    if (item.operation && 'add' === item.operation) {
      /* Added Item Deleted, Remove from list */
      let index = item.index;
      for (let i = 0; i < this.state.referenceAppointmentReminderItems.length; i++) {
        if (this.state.referenceAppointmentReminderItems[i].operation === 'add' && index === i) {
          this.appointmentReminderRefList.splice(i, 1);
          // continue;
        } else {
          items.push(this.state.referenceAppointmentReminderItems[i]);
        }
      }
      this.setState({ referenceAppointmentReminderItems: items });
    }
  }

  reminderItemDeleted(item) {
    let items = [];
    if (item.operation && 'update' === item.operation) {
      /* Existing Item Deleted, Mark it deleted */
      let index = item.index;

      for (let i = 0; i < this.state.reminderItems.length; i++) {
        let item = this.state.reminderItems[i];
        if (this.state.reminderItems[i].operation === 'update' && index === i) {
          item.operation = 'delete';
        }

        items.push(item);
      }

      this.setState({ reminderItems: items });
    }
    if (item.operation && 'add' === item.operation) {
      /* Added Item Deleted, Remove from list */
      let index = item.index;
      for (let i = 0; i < this.state.reminderItems.length; i++) {
        if (this.state.reminderItems[i].operation === 'add' && index === i) {
          this.appointmentReminderRefList.splice(i, 1);
          // continue;
        } else {
          items.push(this.state.reminderItems[i]);
        }
      }
      this.setState({ reminderItems: items });
    }
  }

  getReferenceAppointmentFacilityLocationList() {
    const postObject = {
      header: this.header,
      facility: {
        member: {
          id: this.memberDetails.member,
        },
      },
    };
    FetchServerData.callPostService('/facilityms/getMemberFacilityLocations/', postObject).then((output) => {
      if (Utils.equalsIgnoreCase(output.status, 'SUCCESS')) {
        let facilityLocationList = output.data;
        this.setState({
          isFacilityReferenceAppointment: true,
          isDoctorReferenceAppointment: false,

          facilityLocationList: facilityLocationList,
        });
      } else if (Utils.equalsIgnoreCase(output.status, 'FAIL')) {
        if (output.errorCode && Utils.equalsIgnoreCase(output.errorCode, 'FOC_100')) {
          /* facility is Not created, Go To facility create Page */
          this.setState({
            isCreateFacility: true,
            isCreateDoctor: false,
          });
        }
      }
    });
  }
  getReferenceAppointmentDoctorLocationList() {
    const postObject = {
      header: this.header,
      doctor: {
        member: {
          id: this.memberDetails.member,
        },
      },
    };

    FetchServerData.callPostService('doctorms/getMemberDoctorLocations', postObject).then((output) => {
      if (Utils.equalsIgnoreCase(output.status, 'SUCCESS')) {
        let doctorLocationList = output.data;
        this.setState({
          isDoctorReferenceAppointment: true,

          isFacilityReferenceAppointment: false,
          doctorLocationList: doctorLocationList,
        });
      } else if (Utils.equalsIgnoreCase(output.status, 'FAIL')) {
        if (output.errorCode && Utils.equalsIgnoreCase(output.errorCode, 'DOC_100')) {
          /* doctor is Not created, Go To doctor create Page */
          this.setState({
            isCreateDoctor: true,
            isCreateFacility: false,
          });
        }
      }
    });
  }

  /* edit Reference Appointment data */
  editReferenceAppointment = (e, id, type) => {
    let appointmentTaskId = id;
    const postObject = {
      header: this.header,
      appointmentTask: {
        id: appointmentTaskId,
      },
    };
    let url = '/appointmentTaskms/get/';
    if (type === 'facility') {
      FetchServerData.callPostService(url, postObject)
        .then((output) => {
          if (output.status === 'SUCCESS') {
            let appointmentTaskData = output.data;
            let viewStartDate = '';
            let startDate = '';
            let newStartDate = '';
            if (appointmentTaskData.date !== undefined) {
              startDate = appointmentTaskData.date;

              newStartDate = Utils.convertUTCDateToUserTimeZoneDate(startDate);
              viewStartDate = Utils.convertUTCDateToUserTimeZoneDate(startDate);
            }

            let appointmentReference =
              appointmentTaskData.referenceAppointment.purpose +
              '-' +
              Utils.convertUTCDateToUserTimeZoneDate(appointmentTaskData.referenceAppointment.date);
            if (appointmentTaskData.reminderList.length > 0) {
              for (let i = 0; i < appointmentTaskData.reminderList.length; i++) {
                let ref = React.createRef();
                this.appointmentTaskReminderRefList.push(ref);
                /*Set Default Operation as Update */
                appointmentTaskData.reminderList[i].operation = 'update';
              }

              this.setState({
                appointmentTaskId: appointmentTaskData.id,
                taskNote: appointmentTaskData.note,
                appointmentReference: appointmentReference,

                taskOccurrence: appointmentTaskData.occurance.occurance,
                taskType: appointmentTaskData.type.type,
                facilityTaskEditReminderItems: appointmentTaskData.reminderList,
                appointmentReferenceId: appointmentTaskData.referenceAppointment.id,
                facility: appointmentTaskData.facility.id,
                noteDate: new Date(newStartDate),
                facilityDate: viewStartDate,
                taskFacilityEditRow: true,
                taskFacility: appointmentTaskData.facility.facility,
                facilityTransportationList: appointmentTaskData.transportationList,
                isReferenceAppointmentEdit: true,
                isReferenceAppointmentView: false,
              });
            } else {
              this.setState({
                appointmentTaskId: appointmentTaskData.id,
                taskNote: appointmentTaskData.note,
                appointmentReference: appointmentReference,
                taskOccurrence: appointmentTaskData.occurance.occurance,
                appointmentReferenceId: appointmentTaskData.referenceAppointment.id,
                taskType: appointmentTaskData.type.type,
                facilityTaskEditReminderItems: [],
                facility: appointmentTaskData.facility.id,
                noteDate: new Date(newStartDate),
                facilityDate: viewStartDate,
                taskFacilityEditRow: true,
                taskFacility: appointmentTaskData.facility.facility,
                facilityTransportationList: appointmentTaskData.transportationList,
                isReferenceAppointmentEdit: true,
                isReferenceAppointmentView: false,
              });
            }
          } else {
          }
        })
        .catch((error) => {});
    } else {
      FetchServerData.callPostService(url, postObject)
        .then((output) => {
          if (output.status === 'SUCCESS') {
            let appointmentTaskData = output.data;

            let viewStartDate = '';
            let startDate = '';
            let newStartDate = '';
            if (appointmentTaskData.date !== undefined) {
              startDate = appointmentTaskData.date;
              newStartDate = Utils.convertUTCDateToUserTimeZoneDate(startDate);
              viewStartDate = Utils.convertUTCDateToUserTimeZoneDate(startDate);
            }

            let appointmentReference = '';
            if (appointmentTaskData.referenceAppointment.type.type === 'Facility Appointment') {
              appointmentReference =
                appointmentTaskData.referenceAppointment.facilityLocation.facility.name +
                '-' +
                appointmentTaskData.referenceAppointment.purpose;
            } else {
              appointmentReference =
                'Dr' +
                ' ' +
                appointmentTaskData.referenceAppointment.doctorLocation.doctor.firstName +
                ' ' +
                appointmentTaskData.referenceAppointment.doctorLocation.doctor.lastName +
                '-' +
                appointmentTaskData.referenceAppointment.purpose;
            }
            this.setState({
              appointmentTaskId: appointmentTaskData.id,
              taskNote: appointmentTaskData.note,
              taskOccurrence: appointmentTaskData.occurance.occurance,
              appointmentReference: appointmentReference,
              appointmentReferenceId: appointmentTaskData.referenceAppointment.id,
              taskType: appointmentTaskData.type.type,
              appointmentTaskReminderList: [],
              taskReminder: [],
              noteDate: new Date(newStartDate),
              facilityDate: viewStartDate,
              appointmentsTaskEdit: true,
              addReminder: false,
              taskFacility: '',
              isReferenceAppointmentEdit: true,
              isReferenceAppointmentView: false,
            });
          } else {
          }
        })
        .catch((error) => {});
    }
  };

  /* add Reference Appointment Data In Backend */
  addReferenceAppointmentInBackend(e) {
    let dateTime = Utils.convertUserTimeZoneDateToUTCDate(this.state.noteDate);
    const postObject = {
      header: this.header,
      appointment: {
        date: dateTime,

        description: this.state.referenceAppointmentDescription,
        appointmentPreNote: this.state.preDescription,
        purpose: this.state.referenceAppointmentPurpose,
        type: {
          type: this.state.referenceAppointmentType,
        },
        member: {
          id: this.memberDetails.member,
        },

        facilityLocation: {
          id: this.state.referenceAppointmentFacilityLocation.locationId,
        },
        doctorLocation: {
          id: this.state.referenceAppointmentDoctor.locationId,
        },
        reminderList: this.state.referenceAppointmentReminderItems,
      },
    };

    FetchServerData.callPostService('/appointmentms/create', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let outputData = output.data.appointmentWithPreviousAppointment.appointment;
          let newStartDate1 = Utils.convertUTCDateToUserTimeZoneDate(outputData.date);
          console.log('newStartDate1', newStartDate1);
          PtsAlert.success(outputData.message);

          this.setState({
            appointmentReference: outputData.purpose + '-' + newStartDate1,

            noteDate: newStartDate1,
            taskNote: outputData.description,
            appointmentReferenceId: outputData.id,
            createAppointmentModal: false,
          });
          // }
        } else {
        }
      })
      .catch((error) => {});
  }
  render() {
    if (this.state.action === 'list') {
      return this.referenceAppointmentList();
    } else if (this.state.action === 'create') {
      return this.referenceAppointmentCreate();
    }
  }
  /* Reference appointment list,
  Input data is appointment reference list
  */
  referenceAppointmentList() {
    const columns = [
      {
        text: 'Reference Appointment',
        formatter: (cellContent, row) =>
          row.referenceAppointment.type.type === 'Facility Appointment'
            ? row.referenceAppointment.facilityLocation.facility.name + ' - ' + row.referenceAppointment.purpose
            : row.referenceAppointment.type.type === 'Doctor Appointment'
            ? 'Dr.' +
              ' ' +
              row.referenceAppointment.doctorLocation.doctor.firstName +
              ' ' +
              row.referenceAppointment.doctorLocation.doctor.lastName +
              ' - ' +
              row.referenceAppointment.purpose
            : '',
      },
      {
        dataField: 'note',
        text: ' Additional Details',
      },
      {
        dataField: 'occurance.occurance',
        text: 'Occurance',
      },
      {
        dataField: 'date',
        text: 'Date',
        formatter: (cellContent, row) => Utils.convertUTCDateToUserTimeZoneDate(row.date),
      },
    ];
    const products = this.state.referenceAppointmentList;
    const expandRow = {
      onlyOneExpanding: true,
      showExpandColumn: true,
      renderer: (row) => {
        if (this.state.isReferenceAppointmentView === true) {
          return this.referenceAppointmentView(row);
        } else {
          return this.referenceAppointmentEdit();
        }
      },
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <b>-</b>;
        }
        return <b>+</b>;
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return <i className="fa fa-chevron-down" aria-hidden="true" style={{ color: 'rgba(0, 0, 0, 0.54)' }}></i>;
        }
        return <i className="fa fa-chevron-right" aria-hidden="true" style={{ color: 'rgba(0, 0, 0, 0.54)' }}></i>;
      },
    };
    return (
      <div className="referenceAppointmentList">
        <MaterialTable
          title="Reference Appointment"
          icons={UiUtils.getMaterialTableIcons()}
          columns={[
            {
              title: 'Reference Appointment',
              render: (rowData) =>
                rowData.referenceAppointment.type.type === 'Facility Appointment'
                  ? rowData.referenceAppointment.facilityLocation.facility.name +
                    ' - ' +
                    rowData.referenceAppointment.purpose
                  : rowData.referenceAppointment.type.type === 'Doctor Appointment'
                  ? 'Dr.' +
                    ' ' +
                    rowData.referenceAppointment.doctorLocation.doctor.firstName +
                    ' ' +
                    rowData.referenceAppointment.doctorLocation.doctor.lastName +
                    ' - ' +
                    rowData.referenceAppointment.purpose
                  : '',
            },
            { title: 'Additional Details', field: 'note' },
            { title: 'Occurance', field: 'occurance.occurance' },
            { title: 'Date', render: (rowData) => <>{Utils.convertUTCDateToUserTimeZoneDate(rowData.date)}</> },
          ]}
          data={this.state.referenceAppointmentList}
          options={{
            search: false,
            showTitle: false,
            toolbar: false,
            paging: false,
            headerStyle: { backgroundColor: '#1ABC9C', fontWeight: 'bold', color: '#fff', align: 'left', zIndex: '0' },
          }}
          detailPanel={(rowData) => {
            if (this.state.isReferenceAppointmentView === true) {
              return this.referenceAppointmentView(rowData);
            } else {
              return this.referenceAppointmentEdit();
            }
          }}
        />
      </div>
    );
  }

  /* Create reference appointment  */
  referenceAppointmentCreate() {
    let referenceAppointmentReminderList = [];
    this.state.referenceAppointmentReminderItems.forEach((element, index) => {
      if ('delete' !== element.operation) {
        referenceAppointmentReminderList.push(
          <Col md={6} style={{ marginTop: '1em' }}>
            <Reminder
              ref={this.appointmentReminderRefList[index]}
              key={index}
              data={element}
              srNo={index}
              reminderDate={this.state.referenceAppointmentStartDate}
              pageId={this.state.pageId}
              action="create"
              onReminderAdd={this.addReferenceAppointmentNewReminder.bind(this)}
              onCancel={this.referenceAppointmentReminderItemDeleted.bind(this)}
              onUpdate={this.updateReferenceAppointmentReminder.bind(this)}
            />
          </Col>
        );
      }
    });
    return (
      <div>
        <>
          <Card style={{ background: '#d6eaf8' }} id="refAppoint">
            <ValidatorForm
              style={{ width: '100%', padding: '1.5em' }}
              ref="form"
              onSubmit={this.addAppointmentTaskInBackend.bind(this)}
            >
              <Row md={12} style={{ padding: '0.5em' }}>
                <div md={12} style={{ width: '100%' }}>
                  <Row
                    md={12}
                    className="appointmentNoteRow"
                    style={{
                      marginTop: '0em',
                      marginBottom: '1.8em',
                    }}
                  >
                    <Col md={3}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="occurrence-label">Occurrence*</InputLabel>
                        <Select
                          id="occurrence"
                          required
                          value={this.state.taskOccurrence}
                          label="occurrence*"
                          onChange={(e) => {
                            this.handleTaskOccurrenceChange(e);
                          }}
                          style={{ background: '#fff' }}
                        >
                          {this.taskOccuranceList.map((event, keyIndex) => {
                            return (
                              <MenuItem key={keyIndex} value={event.occurance}>
                                {event.occurance}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Col>
                    <Col md={3}>
                      <TextField
                        fullWidth
                        disabled
                        variant="outlined"
                        id="referenceAppointment"
                        label="Select/Create Appointment*"
                        value={this.state.appointmentReference}
                        onChange={this.handleReferenceAppointmentChange.bind(this)}
                        name="referenceAppointment"
                        type="text"
                        className="textField"
                        style={{ background: '#d3d3d30a' }}
                      />

                      <a
                        onClick={(e) => this.selectAppointment()}
                        className="appointmentLink"
                        style={{ marginLeft: '1em' }}
                      >
                        Select Reference Appointment
                      </a>
                      <br></br>
                      <a
                        onClick={(e) => this.createAppointmentModal()}
                        className="appointmentLink"
                        style={{ marginLeft: '1em' }}
                      >
                        Create Reference Appointment
                      </a>
                    </Col>

                    {this.state.taskFacility && (
                      <Col md={3}>
                        <FormControl variant="outlined" fullWidth>
                          <InputLabel id="facility-label">Facility</InputLabel>
                          <Select
                            id="facility"
                            required
                            value={this.state.facility}
                            label="Facility"
                            onChange={(e) => {
                              this.handleFacilityChange(e);
                            }}
                            style={{ background: '#fff' }}
                          >
                            {this.state.facilityList.map((event, keyIndex) => {
                              return (
                                <MenuItem key={keyIndex} value={event.id}>
                                  {event.name}
                                </MenuItem>
                              );
                            })}
                          </Select>
                        </FormControl>
                        <br></br>
                      </Col>
                    )}

                    <Col md={3}>
                      {/* <DatePicker
                        selected={this.state.noteDate}
                        onChange={this.handleNoteDateChange.bind(this)}
                        timeInputLabel="Time:"
                        dateFormat="MM/dd/yyyy h:mm aa"
                        placeholderText="Date*"
                        required
                        showTimeInput
                        disabled
                        style={{
                          border: '1px solid #c4c4c4 !important',
                        }}
                      /> */}
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ThemeProvider theme={materialTheme}>
                          <DateTimePicker
                            // value={this.state.startDate}
                            value={this.state.noteDate}
                            onChange={this.handleNoteDateChange.bind(this)}
                            variant="outlined"
                            fullWidth
                            // margin="normal"
                            label="Date"
                            inputVariant="outlined"
                            format="MM/dd/yyyy  h:mm aa"
                            // onChange={this.handleStartDateChange.bind(this)}
                            // renderInput={(props) => (
                            //   <TextField {...props} variant="outlined" label="Date" margin="normal" required />
                            // )}
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>
                    </Col>
                    <Col md={3}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="note"
                        label="Additional Details"
                        value={this.state.taskNote}
                        onChange={this.handleTaskNoteChange.bind(this)}
                        name="note"
                        type="text"
                        inputProps={{ maxLength: 256 }}
                        className="textField"
                        style={{ background: '#fff' }}
                      />
                    </Col>
                  </Row>

                  {this.state.addReminder && (
                    <>
                      <CardHeader
                        style={{
                          borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
                        }}
                      >
                        <Row>
                          <Col md={11}>
                            <Label
                              style={{
                                fontWeight: '600',
                                color: '#162c50',
                                marginLeft: '0.5em',
                              }}
                            >
                              Add Reminder
                            </Label>
                          </Col>
                          <Col md={1}>
                            <i
                              className="fa fa-plus-square addReminder"
                              aria-hidden="true"
                              onClick={this.addFacilityTaskReminder.bind(this)}
                            ></i>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody></CardBody>
                    </>
                  )}

                  <div style={{ textAlign: 'center' }}>
                    <CreateButton>Create</CreateButton>
                    &nbsp;
                    <CancelIconButton onClick={(e) => this.cancelReferenceAppointment(e)} />
                  </div>
                </div>
              </Row>
            </ValidatorForm>
          </Card>
          {/* Select Appointment Modal */}
          <Modal isOpen={this.state.listModal} toggle={this.toggle} size="xl">
            <ModalHeader toggle={this.toggle}>
              <Row>
                <Col md={12}>
                  <Label
                    style={{
                      fontWeight: '600',
                      color: '#162c50',
                      marginLeft: '0.5em',
                    }}
                  >
                    Appointment List
                  </Label>
                </Col>
              </Row>
            </ModalHeader>
            <ModalBody>
              <Row style={{ margin: '1em' }}>
                <MaterialTable
                  title=""
                  icons={UiUtils.getMaterialTableIcons()}
                  data={this.state.appointmentList}
                  columns={[
                    {
                      title: '#',
                      render: (rowData) => rowData.tableData.id + 1,
                      filtering: false,
                    },

                    {
                      title: 'Date',
                      type: 'date',
                      field: 'date',

                      render: (rowData) => <>{Utils.convertUTCDateToUserTimeZoneDate(rowData.date)}</>,

                      align: 'left',
                      width: '20%',
                    },
                    { title: 'Type', field: 'type', width: '20%' },
                    {
                      title: 'Appointment',
                      render: (rowData) => (rowData.type === 'Doctor Appointment' ? rowData.doctor : rowData.facility),
                      width: '20%',
                    },
                    { title: 'Purpose', field: 'purpose', width: '20%' },

                    { title: 'Status', field: 'status', width: '20%' },
                    {
                      title: 'Select',
                      field: 'imageUrl',
                      filtering: false,
                      width: '10%',
                      render: (rowData) => (
                        <>
                          <Button
                            style={{ backgroundColor: '#E67E22', color: '#fff', textTransform: 'none' }}
                            variant="contained"
                            onClick={(e) => this.selectedReferenceAppointment(e, rowData)}
                          >
                            Select
                          </Button>
                        </>
                      ),
                    },
                  ]}
                  options={{
                    sorting: true,
                    actionsColumnIndex: -1,
                    headerStyle: {
                      backgroundColor: '#1ABC9C',
                      fontWeight: 'bold',
                      color: '#fff',
                      align: 'center',
                      zIndex: '0',
                    },
                    rowStyle: { height: '10px' },
                    filtering: true,
                    borderRadius: '20px',
                    pageSize: this.state.pageSize,

                    paging: this.state.isPaging,
                  }}
                  actions={[
                    (rowData) => ({
                      icon: () =>
                        rowData.hasReferenceAppointment === true ? (
                          <AccountTreeIcon style={{ fontSize: '25px', color: '#2E86C1' }} />
                        ) : (
                          <div style={{ marginLeft: '1em' }}></div>
                        ),
                      tooltip: 'Reference Appointment',
                    }),
                    (rowData) => ({
                      icon: () =>
                        rowData.unreadMessageCount > 0 ? (
                          <Badge badgeContent={rowData.unreadMessageCount} color="primary">
                            <ChatIcon style={{ fontSize: '25px', color: '#58D68D' }} />
                          </Badge>
                        ) : (
                          <div style={{ marginLeft: '1em' }}></div>
                        ),
                      tooltip: 'Message',
                    }),

                    (rowData) => ({
                      icon: () =>
                        rowData.reminderCount > 0 ? (
                          <Badge badgeContent={rowData.reminderCount} color="primary">
                            <AccessAlarmTwoToneIcon style={{ fontSize: '25px', color: '#EF797D' }} />
                          </Badge>
                        ) : (
                          <div style={{ marginLeft: '1em' }}></div>
                        ),
                      tooltip: 'Reminder',
                    }),
                    (rowData) => ({
                      icon: () =>
                        rowData.transportationCount > 0 ? (
                          <DriveEtaIcon style={{ fontSize: '25px', color: '#E67E22', marginLeft: '0.2em' }} />
                        ) : (
                          <div style={{ marginLeft: '1em' }}></div>
                        ),
                      tooltip: 'Transportation',
                    }),
                  ]}
                />
              </Row>
            </ModalBody>
          </Modal>

          {/*Create Appointment Modal  */}
          <Modal isOpen={this.state.createAppointmentModal} size="xl">
            <ModalHeader toggle={this.toggleCreateAppointmentModal} style={{ color: '#fff' }}>
              Reference Appointment
            </ModalHeader>
            <ModalBody>
              <ValidatorForm
                style={{ width: '100%' }}
                ref="form"
                onSubmit={this.addReferenceAppointmentInBackend.bind(this)}
              >
                <Row style={{ position: 'relative', margin: '1em' }}>
                  <Col md={6} style={{ position: 'relative', padding: '5px' }}>
                    <Card md={12} style={{ height: '100%', borderRadius: '20px' }}>
                      <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                        <Label
                          style={{
                            marginLeft: '0.5em',
                          }}
                          className="appointment_section_header"
                        >
                          Reference Appointment Details
                        </Label>
                      </CardHeader>
                      <CardBody style={{ padding: '2em' }}>
                        <Row md={12} style={{ padding: '0.5em' }}>
                          <div md={12} style={{ width: '100%' }}>
                            <Row className="dateRow" style={{ marginBottom: '0.9em' }}>
                              <Col md={6}>
                                <FormControl variant="outlined" fullWidth>
                                  <InputLabel id="referenceAppointmentType-label">Appointment Type</InputLabel>
                                  <Select
                                    required
                                    id="referenceAppointmentType"
                                    value={this.state.referenceAppointmentType}
                                    label="Appointment Type"
                                    onChange={(e) => {
                                      this.handleReferenceAppointmentTypeChange(e);
                                    }}
                                    startAdornment={
                                      <InputAdornment position="start">
                                        <CreateIcon />
                                      </InputAdornment>
                                    }
                                  >
                                    {this.appointmentTypeListArray.map((event, keyIndex) => {
                                      return (
                                        <MenuItem key={keyIndex} value={event.type}>
                                          {event.type}
                                        </MenuItem>
                                      );
                                    })}
                                  </Select>
                                </FormControl>
                              </Col>
                              <Col md={6}>
                                {/* <DatePicker
                                  selected={this.state.referenceAppointmentStartDate}
                                  onChange={this.handleReferenceAppointmentStartDateChange.bind(this)}
                                  timeInputLabel="Time:"
                                  dateFormat="MM/dd/yyyy h:mm aa"
                                  placeholderText="Date*"
                                  required
                                  showTimeInput
                                  style={{
                                    border: '1px solid #c4c4c4 !important',
                                  }}
                                /> */}
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                  <ThemeProvider theme={materialTheme}>
                                    <DateTimePicker
                                      // value={this.state.startDate}
                                      variant="outlined"
                                      fullWidth
                                      // margin="normal"
                                      label="Date"
                                      inputVariant="outlined"
                                      format="MM/dd/yyyy  h:mm aa"
                                      // onChange={this.handleStartDateChange.bind(this)}
                                      value={this.state.noteDate}
                                      onChange={this.handleNoteDateChange.bind(this)}
                                      // renderInput={(props) => (
                                      //   <TextField
                                      //     {...props}
                                      //     variant="outlined"
                                      //     label="Date"
                                      //     margin="normal"
                                      //     required
                                      //   />
                                      // )}
                                    />
                                  </ThemeProvider>
                                </MuiPickersUtilsProvider>
                              </Col>
                            </Row>
                            <Row style={{ marginBottom: '0.9em' }}>
                              <Col md={6}>
                                <input
                                  className="pts_dataList_dropDown"
                                  type="text"
                                  required
                                  id="demo-simple-select-outlined"
                                  list="data"
                                  placeholder="Appointment Purpose"
                                  label="Appointment Purpose"
                                  value={this.state.referenceAppointmentPurpose}
                                  onChange={(event, value) => {
                                    this.handleReferenceAppointmentPurposeChange(event, value);
                                  }}
                                />
                                <datalist id="data">
                                  {this.appointmentPurposeListData.map((event, key) => (
                                    <option key={event.purpose} value={event.purpose} />
                                  ))}
                                </datalist>
                              </Col>

                              {this.state.isDoctorReferenceAppointment && (
                                <>
                                  <Col md={6} style={{ marginTop: '-1em' }}>
                                    <Autocomplete
                                      id="Role"
                                      options={this.state.doctorLocationList}
                                      value={this.state.referenceAppointmentDoctor}
                                      getOptionLabel={(option) => option.name}
                                      onChange={(event, value) => {
                                        this.handleReferenceAppointmentDoctorChange(value);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          variant="outlined"
                                          label="Doctor"
                                          margin="normal"
                                          required
                                        />
                                      )}
                                    />

                                    {this.state.isCreateDoctor && (
                                      <span
                                        onClick={this.toDoctorCreate.bind(this)}
                                        style={{ cursor: 'pointer', color: '#162c50' }}
                                      >
                                        <a>Create Doctor</a>
                                      </span>
                                    )}
                                  </Col>
                                </>
                              )}

                              {this.state.isFacilityReferenceAppointment && (
                                <>
                                  <Col md={6} style={{ marginTop: '-1em' }}>
                                    <Autocomplete
                                      id="Role"
                                      options={this.state.facilityLocationList}
                                      value={this.state.referenceAppointmentFacilityLocation}
                                      getOptionLabel={(option) => option.name}
                                      onChange={(event, value) => {
                                        this.handleReferenceAppointmentFacilityLocationChange(value);
                                      }}
                                      renderInput={(params) => (
                                        <TextField
                                          {...params}
                                          variant="outlined"
                                          label="Facility"
                                          margin="normal"
                                          required
                                        />
                                      )}
                                    />

                                    {this.state.isCreateFacility && (
                                      <span
                                        onClick={this.toFacilityCreate.bind(this)}
                                        style={{ cursor: 'pointer', color: '#162c50' }}
                                      >
                                        <a>Create Facility</a>
                                      </span>
                                    )}
                                  </Col>
                                </>
                              )}
                            </Row>

                            <Row style={{ marginBottom: '0.9em' }}>
                              <Col md={12}>
                                <TextField
                                  fullWidth
                                  variant="outlined"
                                  id="description"
                                  label=" Description "
                                  value={this.state.referenceAppointmentDescription}
                                  onChange={this.handleReferenceAppointmentDescriptionChange.bind(this)}
                                  name="name"
                                  type="text"
                                  className="textField"
                                  InputProps={{
                                    startAdornment: (
                                      <InputAdornment position="start">
                                        <DescriptionIcon />
                                      </InputAdornment>
                                    ),
                                  }}
                                />
                              </Col>
                            </Row>
                          </div>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={6} style={{ position: 'relative', padding: '5px' }}>
                    <Card
                      md={12}
                      style={{
                        height: '100%',
                        borderRadius: '20px',
                      }}
                    >
                      <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                        <Row>
                          <Col md={11}>
                            <Label
                              style={{
                                marginLeft: '0.5em',
                              }}
                              className="appointment_section_header"
                            >
                              Location Details
                            </Label>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        {this.state.isDoctorLocation && (
                          <Row style={{ marginTop: '1em' }}>
                            <Col md={12}>
                              <Location data={this.state.selectedDoctorLocation} action="simpleView" />
                            </Col>
                          </Row>
                        )}
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <div style={{ textAlign: 'center' }}>
                  <CreateButton>Create</CreateButton>
                  &nbsp;
                  <CancelIconButton onClick={(e) => this.toggleCreateAppointmentModal()} />
                </div>
              </ValidatorForm>
            </ModalBody>
          </Modal>
        </>
      </div>
    );
  }

  /* Edit reference appointment  */
  referenceAppointmentEdit() {
    return (
      <div>
        <>
          <Card style={{ background: '#d6eaf8' }}>
            <ValidatorForm
              style={{ width: '100%', padding: '1.5em' }}
              ref="form"
              onSubmit={this.updateAppointmentTaskInBackend.bind(this)}
            >
              <Row md={12} style={{ padding: '0.5em' }}>
                <div md={12} style={{ width: '100%' }}>
                  <Row md={12} className="appointmentNoteRow" style={{ marginTop: '0em', marginBottom: '1.8em' }}>
                    <Col md={3}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="occurrence-label">Occurrence*</InputLabel>
                        <Select
                          id="occurrence"
                          required
                          value={this.state.taskOccurrence}
                          label="occurrence"
                          onChange={(e) => {
                            this.handleTaskOccurrenceChange(e);
                          }}
                          style={{ background: '#fff' }}
                        >
                          {this.taskOccuranceList.map((event, keyIndex) => {
                            return (
                              <MenuItem key={keyIndex} value={event.occurance}>
                                {event.occurance}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Col>
                    <Col md={3}>
                      <TextField
                        disabled
                        fullWidth
                        variant="outlined"
                        id="referenceAppointment"
                        label="Select/Create Appointment *"
                        value={this.state.appointmentReference}
                        onChange={this.handleReferenceAppointmentChange.bind(this)}
                        name="referenceAppointment"
                        type="text"
                        className="textField"
                        style={{ background: '#d3d3d30a' }}
                      />

                      <a
                        onClick={(e) => this.selectAppointment()}
                        className="appointmentLink"
                        style={{ marginLeft: '1em' }}
                      >
                        Select Reference Appointment
                      </a>
                      <br></br>
                      <a
                        onClick={(e) => this.createAppointmentModal()}
                        className="appointmentLink"
                        style={{ marginLeft: '1em' }}
                      >
                        Create Reference Appointment
                      </a>
                    </Col>

                    <Col md={3}>
                      {/* <DatePicker
                        selected={this.state.noteDate}
                        onChange={this.handleNoteDateChange.bind(this)}
                        timeInputLabel="Time:"
                        dateFormat="MM/dd/yyyy h:mm aa"
                        placeholderText="Date*"
                        required
                        showTimeInput
                        disabled
                        style={{
                          border: '1px solid #c4c4c4 !important',
                        }}
                      /> */}
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ThemeProvider theme={materialTheme}>
                          <DateTimePicker
                            // value={this.state.startDate}
                            variant="outlined"
                            fullWidth
                            // margin="normal"
                            label="Date"
                            inputVariant="outlined"
                            format="MM/dd/yyyy  h:mm aa"
                            id="ptsDateTimePicker"
                            // onChange={this.handleStartDateChange.bind(this)}
                            value={this.state.noteDate}
                            onChange={this.handleNoteDateChange.bind(this)}
                            // renderInput={(props) => (
                            //   <TextField {...props} variant="outlined" label="Date" margin="normal" required />
                            // )}
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>
                    </Col>
                    <Col md={3}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="note"
                        label="Additional Details"
                        value={this.state.taskNote}
                        inputProps={{ maxLength: 256 }}
                        onChange={this.handleTaskNoteChange.bind(this)}
                        name="note"
                        type="text"
                        className="textField"
                        style={{ background: '#fff' }}
                      />
                    </Col>
                  </Row>

                  <div style={{ textAlign: 'center' }}>
                    <SaveButton type="submit" />
                    &nbsp;
                    <CancelIconButton type="button" onClick={(e) => this.cancelUpdateReferenceAppoint()} />
                  </div>
                </div>
              </Row>
            </ValidatorForm>
          </Card>
          <Modal isOpen={this.state.listModal} toggle={this.toggle} size="xl">
            <ModalHeader toggle={this.toggle}>
              <Row>
                <Col md={12}>
                  <Label
                    style={{
                      fontWeight: '600',
                      color: '#162c50',
                      marginLeft: '0.5em',
                    }}
                  >
                    Appointment List
                  </Label>
                </Col>
              </Row>
            </ModalHeader>
            <ModalBody>
              <Row style={{ marginTop: '0em' }}>
                <Col md={12}>
                  <MaterialTable
                    title=""
                    icons={UiUtils.getMaterialTableIcons()}
                    data={this.state.appointmentList}
                    columns={[
                      {
                        title: '#',
                        render: (rowData) => rowData.tableData.id + 1,
                        filtering: false,
                      },

                      {
                        title: 'Date',
                        type: 'date',
                        field: 'date',

                        render: (rowData) => <>{Utils.convertUTCDateToUserTimeZoneDate(rowData.date)}</>,

                        align: 'left',
                        width: '20%',
                      },
                      { title: 'Type', field: 'type', width: '20%' },
                      {
                        title: 'Appointment',
                        render: (rowData) =>
                          rowData.type === 'Doctor Appointment' ? rowData.doctor : rowData.facility,
                        width: '20%',
                      },
                      { title: 'Purpose', field: 'purpose', width: '20%' },

                      { title: 'Status', field: 'status', width: '20%' },
                      {
                        title: '',
                        width: '10%',
                        render: (rowData) =>
                          rowData.markCompleteOrCancel === true ? (
                            <>
                              <h6 className="blink_me">Mark Completed / Mark Canceled</h6>
                            </>
                          ) : (
                            ''
                          ),
                      },
                      {
                        title: '',
                        width: '10%',
                        render: (rowData) =>
                          rowData.markCompleteOrCancel === true ? (
                            <>
                              <h6 className="blink_me">Mark Completed / Mark Canceled</h6>
                            </>
                          ) : (
                            ''
                          ),
                      },
                      {
                        title: 'Select',
                        field: 'imageUrl',
                        width: '10%',
                        filtering: false,
                        render: (rowData) => (
                          <>
                            <Button
                              style={{ backgroundColor: '#E67E22', color: '#fff', textTransform: 'none' }}
                              variant="contained"
                              onClick={(e) => this.selectedReferenceAppointment(e, rowData)}
                            >
                              Select
                            </Button>
                          </>
                        ),
                      },
                    ]}
                    options={{
                      sorting: true,
                      actionsColumnIndex: -1,
                      headerStyle: {
                        backgroundColor: '#1ABC9C',
                        fontWeight: 'bold',
                        color: '#fff',
                        align: 'center',
                        zIndex: '0',
                      },
                      rowStyle: { height: '10px' },
                      filtering: true,
                      borderRadius: '20px',
                      pageSize: this.state.pageSize,

                      paging: this.state.isPaging,
                    }}
                    actions={[
                      (rowData) => ({
                        icon: () =>
                          rowData.hasReferenceAppointment === true ? (
                            <AccountTreeIcon style={{ fontSize: '25px', color: '#2E86C1' }} />
                          ) : (
                            <div style={{ marginLeft: '1em' }}></div>
                          ),
                        tooltip: 'Reference Appointment',
                      }),
                      (rowData) => ({
                        icon: () =>
                          rowData.unreadMessageCount > 0 ? (
                            <Badge badgeContent={rowData.unreadMessageCount} color="primary">
                              <ChatIcon style={{ fontSize: '25px', color: '#58D68D' }} />
                            </Badge>
                          ) : (
                            <div style={{ marginLeft: '1em' }}></div>
                          ),
                        tooltip: 'Message',
                      }),

                      (rowData) => ({
                        icon: () =>
                          rowData.reminderCount > 0 ? (
                            <Badge badgeContent={rowData.reminderCount} color="primary">
                              <AccessAlarmTwoToneIcon style={{ fontSize: '25px', color: '#EF797D' }} />
                            </Badge>
                          ) : (
                            <div style={{ marginLeft: '1em' }}></div>
                          ),
                        tooltip: 'Reminder',
                      }),
                      (rowData) => ({
                        icon: () =>
                          rowData.transportationCount > 0 ? (
                            <DriveEtaIcon style={{ fontSize: '25px', color: '#E67E22', marginLeft: '0.2em' }} />
                          ) : (
                            <div style={{ marginLeft: '1em' }}></div>
                          ),
                        tooltip: 'Transportation',
                      }),
                    ]}
                  />
                </Col>
              </Row>
            </ModalBody>
          </Modal>
          {/*Create Appointment Modal  */}
        </>
      </div>
    );
  }
  /* View reference appointment  */
  referenceAppointmentView(referenceAppointmentData) {
    return (
      <div>
        <Col md={12} className="referenceAppointmentView">
          <Card>
            <CardHeader>
              <Row>
                <Col md={10}>
                  {' '}
                  <Label style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em' }}>
                    Reference Appointment Details
                  </Label>
                </Col>

                <Col md={2}>
                  {this.state.isViewButtonHide && (
                    <>
                      {this.props.getUpdatePermission && (
                        <EditButton
                          onClick={(e) => this.editReferenceAppointment(e, referenceAppointmentData.id, 'appointment')}
                        />
                      )}
                      {this.props.getDeletePermission && (
                        <DeleteButton
                          domainObject="Reference Appointments"
                          onClick={(e) => this.deleteReferenceAppointment(e, referenceAppointmentData.id)}
                        />
                      )}
                    </>
                  )}
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row>
                <>
                  {referenceAppointmentData.referenceAppointment.doctorLocation !== null && (
                    <>
                      <>
                        <Col md={6}>
                          <Row>
                            <Col md={3}>
                              <lable className="refAppointmentLabel">Doctor</lable>
                            </Col>
                            <Col md={9}>
                              {'Dr.' +
                                ' ' +
                                referenceAppointmentData.referenceAppointment.doctorLocation.doctor.firstName +
                                ' ' +
                                referenceAppointmentData.referenceAppointment.doctorLocation.doctor.lastName}
                            </Col>
                          </Row>
                          <Row>
                            <Col md={3}>
                              <lable className="refAppointmentLabel">Occurance</lable>
                            </Col>
                            <Col md={6}>{referenceAppointmentData.occurance.occurance}</Col>
                          </Row>

                          <Row>
                            <Col md={3}>
                              <lable className="refAppointmentLabel">Additional Details</lable>
                            </Col>
                            <Col md={9}>{referenceAppointmentData.note}</Col>
                          </Row>
                        </Col>
                        <Col md={6}>
                          <Row>
                            <Col md={3}>
                              <lable className="refAppointmentLabel">Date</lable>
                            </Col>
                            <Col md={9}>
                              {Utils.convertUTCDateToUserTimeZoneDate(
                                referenceAppointmentData.referenceAppointment.date
                              )}
                            </Col>
                          </Row>
                          <Row>
                            <Col md={3}>
                              <lable className="refAppointmentLabel">Purpose</lable>
                            </Col>
                            <Col md={9}>{referenceAppointmentData.referenceAppointment.purpose}</Col>
                          </Row>
                          <Row>
                            <Col md={3}>
                              <lable className="refAppointmentLabel">Status</lable>
                            </Col>
                            <Col md={9}>{referenceAppointmentData.referenceAppointment.status.status}</Col>
                          </Row>
                        </Col>
                      </>
                    </>
                  )}
                  {null !== referenceAppointmentData.referenceAppointment.facilityLocation && (
                    <>
                      <Col md={6}>
                        <Row>
                          <Col md={3}>
                            <lable className="refAppointmentLabel">Name</lable>
                          </Col>
                          <Col md={9}>
                            {referenceAppointmentData.referenceAppointment.facilityLocation.facility.name}
                          </Col>
                        </Row>
                        <Row>
                          <Col md={3}>
                            <lable className="refAppointmentLabel">Type</lable>
                          </Col>
                          <Col md={9}>
                            {referenceAppointmentData.referenceAppointment.facilityLocation.facility.type}
                          </Col>
                        </Row>
                        <Row>
                          <Col md={3}>
                            <lable className="refAppointmentLabel">Occurance</lable>
                          </Col>
                          <Col md={6}>{referenceAppointmentData.occurance.occurance}</Col>
                        </Row>
                        <Row>
                          <Col md={3}>
                            <lable className="refAppointmentLabel">Additional Details</lable>
                          </Col>
                          <Col md={9}>
                            {referenceAppointmentData.referenceAppointment.facilityLocation.facility.description}
                          </Col>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <Row>
                          <Col md={3}>
                            <lable className="refAppointmentLabel">Date</lable>
                          </Col>
                          <Col md={9}>
                            {Utils.convertUTCDateToUserTimeZoneDate(referenceAppointmentData.referenceAppointment.date)}
                          </Col>
                        </Row>
                        <Row>
                          <Col md={3}>
                            <lable className="refAppointmentLabel">Purpose</lable>
                          </Col>
                          <Col md={9}>{referenceAppointmentData.referenceAppointment.purpose}</Col>
                        </Row>
                        <Row>
                          <Col md={3}>
                            <lable className="refAppointmentLabel">Status</lable>
                          </Col>
                          <Col md={9}>{referenceAppointmentData.referenceAppointment.status.status}</Col>
                        </Row>
                      </Col>
                    </>
                  )}
                </>
              </Row>
            </CardBody>
            <CardFooter>
              <label
                style={{
                  fontWeight: 'normal',
                  color: '#888888',
                  width: '100%',
                  fontSize: 'xx-small',
                  textAlign: 'left',
                  padding: '6px',
                  margin: '0',
                }}
              >
                {referenceAppointmentData.createdByName && 'Created by ' + referenceAppointmentData.createdByName}
                {referenceAppointmentData.createdOn &&
                  ' on ' + Utils.convertUTCDateToUserTimeZoneDate(referenceAppointmentData.createdOn)}
              </label>
            </CardFooter>
          </Card>
        </Col>
      </div>
    );
  }
}

export default ReferenceAppointment;
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#008181',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: 'white',
        color: '#008181',
      },
    },
  },
});
