import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import debounce from 'lodash.debounce';
import React, { Component } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import PasswordRequirementsTooltip from '../passwordRequirementsTooltip/passwordRequirementsTooltip';
export default class ResetPassword extends Component {
  constructor(props) {
    super(props);
    let action = props.action;
    let email = props.email;
    let isDisabled = false;
    let pathUrl = '';
    if (props.history && props.history.location && props.history.location.state) {
      action = props.history.location.state.action;
      email = props.history.location.state.email;
      pathUrl = props.history.location.state.pathUrl;
    }

    let emailAddress = '';

    if (pathUrl === 'forgotPassword') {
      emailAddress = email;
    } else {
      let str = window.location.href;
      let urlArr = str.split('?');
      let urlEmail = urlArr[1].split('=');
      emailAddress = urlEmail[1];
    }
    if (emailAddress && emailAddress.length > 0) {
      isDisabled = true;
    }
    this.state = {
      email: emailAddress,
      isEmailDisabled: isDisabled,
      password: '',
      code: '',
      confPassword: '',
      showPassword: false,
      showConfPassword: false,
      action: action,
      showConfirmPassword: false,
      confirmPassword: '',
      error: {
        one: true,
        two: true,
        three: true,
        four: true,
        five: true,
      },
      passwordFocussed: false,
      passwordHelperText: '',
      confirmPasswordHelperText: '',
    };
  }
  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }
  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }
  handleConfPasswordChange(e) {
    this.setState({ confPassword: e.target.value });
  }
  handleVerificationCodeChange(e) {
    this.setState({ code: e.target.value });
  }
  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }
  handleClickShowConfPassword() {
    this.setState({ showConfPassword: !this.state.showConfPassword });
  }
  goToPage(pageid) {
    if (pageid && pageid.length > 0) {
      this.props.history.push({
        pathname: pageid,
        state: { email: this.state.email },
      });
    }
  }
  resetPasswordInBackend = (e) => {
    e.preventDefault();

    // make API call
    const postObject = {
      appuserVo: {
        emailOrPhone: this.state.email,
        tempPassword: this.state.code,
        credential: this.state.password,
      },
    };
    FetchServerData.callPostService('userms/resetPassword', postObject)
      .then((response) => {
        let output = response;
        if (output.status === 'SUCCESS') {
          this.props.history.push({
            pathname: '/login',
            state: { email: this.state.email },
          });
        } else {
        }
      })
      .catch((error) => {});
  };
  handleClickShowConfirmPassword() {
    this.setState({ showConfirmPassword: !this.state.showConfirmPassword });
  }
  toggleFocusNewPassword = () => {
    this.setState((prevState) => {
      return { passwordFocussed: !prevState.passwordFocussed };
    });
  };

  handlePasswordChange = (e) => {
    const { name, value } = e.target;
    this.setState({ [name]: value });
    if (name === 'password') {
      this.debouncedValidatePassword(value);
    } else if (name === 'confirmPassword') {
      this.debouncedValidatePassword(this.state.password);
    }
  };

  debouncedValidatePassword = debounce((password) => this.validatePassword(password), 1000);
  validatePassword = (password) => {
    const { confirmPassword } = this.state;
    let one = false,
      two = false,
      three = false,
      four = false,
      five = false,
      confirmPasswordHelperText = '';

    if (password.length < 8 || password.length > 16) {
      one = true;
    }
    if (!password.match(/[A-Z]/)) {
      two = true;
    }
    if (!password.match(/[a-z]/)) {
      three = true;
    }
    if (!password.match(/[\d]/)) {
      four = true;
    }
    if (!password.match(/[\W]/)) {
      five = true;
    }

    if (password !== confirmPassword) {
      confirmPasswordHelperText = 'Password & confirm password must match';
    }

    if (one || two || three || four || five) {
      this.setState({
        error: { one: one, two: two, three: three, four: four, five: five },
        confirmPasswordHelperText: confirmPasswordHelperText,
        passwordHelperText: 'Password requirements not met',
      });
      return;
    }

    this.setState({
      disable: false || password !== confirmPassword,
      error: {},
      confirmPasswordHelperText: confirmPasswordHelperText,
      passwordHelperText: '',
    });
  };
  render() {
    const { error, passwordFocussed } = this.state;
    return (
      <div md={12} style={{ display: 'flex', margin: '2em' }}>
        <Card
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: 'auto',
            padding: '1em',
          }}
        >
          <CardHeader>
            <span style={{ color: '#169ba2', fontSize: 'large' }}>
              <b>Reset Password</b>
            </span>
          </CardHeader>
          <ValidatorForm ref="form" onSubmit={this.resetPasswordInBackend.bind(this)} style={{ width: '100%' }}>
            <CardBody>
              <Row md={12}>
                <Col md={6}>
                  {this.state.isEmailDisabled && this.state.isEmailDisabled === true && (
                    <TextField
                      margin="normal"
                      variant="outlined"
                      required
                      fullWidth
                      disabled
                      id="email"
                      type="email"
                      onChange={this.handleEmailChange.bind(this)}
                      label="Email"
                      name="email"
                      autoFocus
                      value={this.state.email}
                      autoComplete="off"
                    />
                  )}

                  {!this.state.isEmailDisabled ||
                    (this.state.isEmailDisabled !== true && (
                      <TextField
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        type="email"
                        onChange={this.handleEmailChange.bind(this)}
                        label="Email"
                        name="email"
                        autoFocus
                        value={this.state.email}
                        autoComplete="off"
                      />
                    ))}
                </Col>

                <Col md={6}>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    required
                    value={this.state.code}
                    onChange={this.handleVerificationCodeChange.bind(this)}
                    fullWidth
                    id="code"
                    label="Enter Authentication Code"
                    name="code"
                    autoFocus
                    autoComplete="off"
                  />
                </Col>
              </Row>

              <Row md={12}>
                <Col md={6}>
                  <TextField
                    variant="outlined"
                    // autoComplete="off"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    inputProps={{ maxLength: 256 }}
                    label="Password"
                    type={this.state.showPassword ? 'text' : 'password'}
                    id="password"
                    autoComplete="new-password"
                    onChange={this.handlePasswordChange.bind(this)}
                    value={this.state.password}
                    onFocus={this.toggleFocusNewPassword}
                    onBlur={this.toggleFocusNewPassword}
                    helperText={this.state.passwordHelperText}
                    error={this.state.passwordHelperText !== ''}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword.bind(this)}
                          >
                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                  {this.state.passwordHelperText ? (
                    <PasswordRequirementsTooltip
                      error={error}
                      open={passwordFocussed}
                      style={{ float: 'right', marginTop: '-1em' }}
                    />
                  ) : (
                    ''
                  )}{' '}
                </Col>
                <Col md={6}>
                  {' '}
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    value={this.state.confirmPassword}
                    name="confirmPassword"
                    placeholder="Confirm Password "
                    autoComplete="new-password"
                    type={this.state.showConfirmPassword ? 'text' : 'password'}
                    id="confirmPassword"
                    helperText={this.state.confirmPasswordHelperText}
                    error={this.state.confirmPasswordHelperText !== ''}
                    onChange={this.handlePasswordChange.bind(this)}
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowConfirmPassword.bind(this)}
                          >
                            {this.state.showConfirmPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Col>
              </Row>
            </CardBody>

            <CardFooter style={{ width: '100%' }}>
              <div style={{ textAlign: 'center' }}>
                <Button
                  type="submit"
                  style={{ backgroundColor: '#ef6603', color: '#fff' }}
                  variant="contained"
                  startIcon={<LockOpenIcon />}
                >
                  RESET
                </Button>
              </div>
              <Row md={12} style={{ marginTop: '0.5em' }}>
                <Col md={12}>
                  <span style={{ float: 'left', color: 'black' }}>
                    Not an account?&nbsp;
                    <Link
                      style={{ color: 'blue', fontWeight: '400', cursor: 'pointer' }}
                      onClick={(e) => this.goToPage('/createAccount')}
                    >
                      Sign Up
                    </Link>
                  </span>
                  <span style={{ float: 'right', color: 'black' }}>
                    Already have an account?&nbsp;
                    <Link
                      style={{ color: 'blue', fontWeight: '400', cursor: 'pointer' }}
                      onClick={(e) => this.goToPage('/login')}
                    >
                      Login
                    </Link>
                  </span>
                </Col>
              </Row>
            </CardFooter>
          </ValidatorForm>
        </Card>
      </div>
    );
  }
}
