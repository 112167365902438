import React, { Component } from 'react';
import { Card, Col, Row } from "reactstrap";
class Help extends Component {
    render() {
        return (
            <div>
                  <Row style={{ position: "relative", margin: "1em" }}>
              <Col md={12} style={{ position: "relative", padding: "5px" }}>
                <Card className="formCard" style={{textAlign: 'center', padding: '1em'}}>
                <h3>Family Care Circle</h3>
                <p>Admin panel</p></Card>
                </Col>
                </Row>
            </div>
        );
    }
}

export default Help;