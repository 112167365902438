import { CLEAR_USER_SUBSCRIBE_PLAN, GET_USER_SUBSCRIBE_PLAN, SET_USER_SUBSCRIBE_PLAN } from './userSubscribePlanType';

const getUserSubscribePlan = () => {
  return {
    type: GET_USER_SUBSCRIBE_PLAN,
  };
};

const setUserSubscribePlan = (data) => {
  return {
    type: SET_USER_SUBSCRIBE_PLAN,
    payload: data,
  };
};

const clearUserSubscribePlan = (data) => {
  return {
    type: CLEAR_USER_SUBSCRIBE_PLAN,
    payload: data,
  };
};
const userSubscribePlanPlanAction = { getUserSubscribePlan, setUserSubscribePlan, clearUserSubscribePlan };

export default userSubscribePlanPlanAction;
