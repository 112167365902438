import { GET_USER_PROFILE, SET_USER_PROFILE } from "./UserProfileTypes";

const initialState = {
    UserProfileList: {},
};

const UserProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_USER_PROFILE:
            return {
                ...state,
            };
        case SET_USER_PROFILE:
            return {
                ...state,
                UserProfileList: action.payload,
            };
        default:
            return state;
    }
};

export default UserProfileReducer;
