import { GET_STATIC_DATA, SET_STATIC_DATA } from './staticDataTypes';

const getStaticData = () => {
  return {
    type: GET_STATIC_DATA,
  };
};
const setStaticData = (data) => {
  return {
    type: SET_STATIC_DATA,
    payload: data,
  };
};
const staticDataAction = { getStaticData, setStaticData };
export default staticDataAction;
