import Button from '@material-ui/core/Button';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import MaterialTable from 'material-table';
import moment from 'moment';
import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import UiUtils from '../../../provider/UiUtils';
import store from '../../../redux/store';
import userSubscribePlanPlanAction from '../../../redux/userSubscribePlan/userSubscribePlanAction';
import CancelIconButton from '../buttons/CancelIconButton';
import PtsAlert from '../ptsAlert/PtsAlert';
import './BillingDetails.scss';

class BillingDetails extends Component {
  title = 'Billing Details';
  breadCrumb = [{ pageid: '/billing_details', uiname: 'Billing Details' }];
  header = store.getState().header.header;
  userId = store.getState().userData.userData.memberId;
  isFreeUser = store.getState().userData.userData.isFreeUser;
  addressRef = React.createRef();
  addressRef = {};
  premiumFeatures = store.getState().userData.premiumFeatures;
  constructor(props) {
    super(props);
    let action = props.action;
    let memberId = props.memberId;
    let title = '';
    let pageId = '';
    let orderListDetails = [];
    let autoPaid = true;
    let planItems = [];
    let planId = '';
    orderListDetails = props.orderListDetails;
    let frereSubscription = props.freeSubscriptionDetails;

    if (
      null == memberId &&
      null !== props.history &&
      props.history !== undefined &&
      props.history.location !== null &&
      props.history.location.state !== null &&
      props.history.location.state !== undefined
    ) {
      memberId = props.history.location.state.memberId;
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
      title = props.history.location.state.title;
    }
    let orderItem = [];
    let selectedUserList = [];
    let subscriptionId = '';
    let numberOfUser = '';
    let isDisplayPlanSubscription = false;
    let isDisplayFreePlanSubscription = false;

    for (let i = 0; i < orderListDetails.length; i++) {
      numberOfUser = orderListDetails.userSubscription.numberOfUsers;
    }
    if (orderListDetails.orderList !== null) {
      for (let i = 0; i < orderListDetails.orderList.length; i++) {
        orderItem = orderListDetails.orderList[i].orderItems;
      }
    }
    if (orderListDetails && orderListDetails.userSubscription !== null) {
      selectedUserList = orderListDetails.userSubscription.subscriptionUserList;
      numberOfUser = orderListDetails.userSubscription.numberOfUsers;
      subscriptionId = orderListDetails.userSubscription.id;
      autoPaid = orderListDetails.userSubscription.autoPaid;
      planId = orderListDetails.userSubscription.plan ? orderListDetails.userSubscription.plan.id : '';
      if (
        orderListDetails.userSubscription.plan &&
        orderListDetails.userSubscription.plan.planItems &&
        orderListDetails.userSubscription.plan.planItems.length > 0
      ) {
        planItems = orderListDetails.userSubscription.plan.planItems;
      }
    }
    if (orderListDetails.appuser.isFreeUser === false) {
      isDisplayPlanSubscription = true;
      isDisplayFreePlanSubscription = false;
    } else {
      isDisplayPlanSubscription = false;
      isDisplayFreePlanSubscription = true;
    }
    if (orderListDetails.orderList !== null) {
      if (frereSubscription !== null && frereSubscription !== '') {
        orderListDetails.orderList.sort(function compare(a, b) {
          var dateA = new Date(a.date);
          var dateB = new Date(b.date);
          return dateB - dateA;
        });
      }
      this.state = {
        pageId: pageId,
        email: orderListDetails.member ? orderListDetails.member.email : '',
        action: action,
        title: title,
        render: true,
        id: memberId,
        orderList: orderListDetails.orderList,
        checked: autoPaid,
        orderItemsModal: false,
        memberListModal: false,
        memberList: [],
        pageSize: 5,
        isPaging: false,
        selectedUser: [],
        isDisplayPlanSubscription: isDisplayPlanSubscription,
        userSubscription: orderListDetails.userSubscription,
        isDisplayFreePlanSubscription: isDisplayFreePlanSubscription,
        frereSubscription: frereSubscription,
        frereSubscriptionName: '',
        frereSubscriptionPrice: '',
        orderItemList: orderItem,
        subscriptionId: subscriptionId,
        selectedUserList: selectedUserList,
        numberOfUser: numberOfUser,
        invoiceModal: false,
        isAddMemberCount: false,
        isAddMemberBtn: true,
        isCancelBtn: false,
        planId: planId,
        planItems: planItems,
        isFreeUser: orderListDetails.appuser.isFreeUser,
      };
    } else {
      this.state = {
        pageId: pageId,
        email: orderListDetails.member ? orderListDetails.member.email : '',
        action: action,
        title: title,
        render: true,
        id: memberId,
        orderList: orderListDetails.orderList,
        checked: autoPaid,
        orderItemsModal: false,
        memberListModal: false,
        memberList: [],
        pageSize: 5,
        isPaging: false,
        selectedUser: [],
        isDisplayPlanSubscription: isDisplayPlanSubscription,
        userSubscription: orderListDetails.userSubscription,
        isDisplayFreePlanSubscription: isDisplayFreePlanSubscription,
        frereSubscription: {},
        frereSubscriptionName: '',
        frereSubscriptionPrice: '',
        orderItemList: orderItem,
        subscriptionId: subscriptionId,
        selectedUserList: selectedUserList,
        numberOfUser: numberOfUser,
        invoiceModal: false,
        isAddMemberCount: false,
        isAddMemberBtn: true,
        isCancelBtn: false,
        planId: planId,
        planItems: planItems,
        isFreeUser: orderListDetails.appuser.isFreeUser,
      };
    }
  }

  handleAddressChange(address) {
    this.setState({ address: address });
  }

  handleSubscriptionChargeChange(e) {
    this.setState({ checked: e.target.checked });
    if (e.target.checked === false) {
      const postObject = {
        header: this.header,
        email: this.state.email,
      };
      FetchServerData.callPostService('pg/makeSubscriptionManualCharge', postObject)

        .then((output) => {
          if (output.status === 'SUCCESS') {
            PtsAlert.success(output.message);
            let paymentDetails = output.data;
            this.setState({ checked: e.target.checked, userSubscription: paymentDetails });
          } else {
          }
        })
        .catch((error) => {});
    } else {
      const postObject = {
        header: this.header,
        email: this.state.email,
      };
      FetchServerData.callPostService('pg/makeSubscriptionAutocharge', postObject)
        .then((output) => {
          if (output.status === 'SUCCESS') {
            PtsAlert.success(output.message);
            let paymentDetails = output.data;
            this.setState({ checked: e.target.checked, userSubscription: paymentDetails });
          } else {
          }
        })
        .catch((error) => {});
    }
  }

  cancelSubscription(e) {
    const postObject = {
      header: this.header,
      email: this.state.email,
    };
    FetchServerData.callPostService('pg/cancelOnCurrentSubscriptionEnd', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          PtsAlert.success(output.message);
          this.getProfileData();
        } else {
        }
      })
      .catch((error) => {});
  }
  toUpgradePlan(e) {
    let selectedPlan = this.state.userSubscription.plan;
    store.dispatch(userSubscribePlanPlanAction.setUserSubscribePlan({ selectedPlan }));
    this.props.history.push({
      pathname: '/upgradePlan',
    });
  }

  toRenewPlan(e) {
    let selectedPlan = this.state.userSubscription.plan;
    store.dispatch(userSubscribePlanPlanAction.setUserSubscribePlan({ selectedPlan }));
    this.props.history.push({
      pathname: '/renewPlan',
    });
  }

  addMoreMembers(e) {
    this.setState({
      isAddMemberCount: true,
      isAddMemberBtn: false,
      isCancelBtn: true,
      addMemberCount: '',
    });
  }

  cancelAddMember(e) {
    this.setState({
      isAddMemberCount: false,
      isAddMemberBtn: true,
      isCancelBtn: false,
      addMemberCount: '',
    });
  }

  handleMemberCount(e) {
    this.setState({
      addMemberCount: e.target.value,
    });
  }

  showOrderItems(order, e) {
    this.setState({
      orderItemList: order.orderItems,
      orderItemsModal: true,
    });
  }

  getInvoice(orderData, orderData1) {
    const postObject = {
      header: this.header,
      invoiceId: orderData.invoiceId,
    };
    FetchServerData.callPostService('pg/getInvoice', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let invoiceDetails = output.data;

          this.setState({
            // invoiceModal: true,
            invoiceUrl: invoiceDetails.invoicePdf,
          });
        } else {
        }
      })
      .catch((error) => {});
  }
  showOrderItemList() {
    return this.state.orderItemList.map((data, index) => {
      if (this.state.render === false) {
        return <div />;
      } else {
        return (
          <>
            <tr style={{ textAlign: 'left', background: '#fff' }}>
              <td>{index + 1}</td>
              <td>{data.description}</td>
              <td> {'$' + data.price}</td>
            </tr>
          </>
        );
      }
    });
  }

  orderItemsModalToggle = () => {
    this.setState({
      orderItemsModal: !this.state.orderItemsModal,
    });
  };

  memberListModalToggle = () => {
    this.setState({
      memberListModal: !this.state.memberListModal,
    });
  };
  toggle = () => {
    this.setState({
      invoiceModal: !this.state.invoiceModal,
    });
  };
  selectedParticipant(e, rowData, type) {
    const { checked } = e.target;
    let selectedMemberList = this.state.selectedUser;

    let memberObj = {};
    if (checked === true) {
      memberObj = {
        user: {
          id: rowData.id,
          name: rowData.name,
        },
      };
      selectedMemberList.push(memberObj);
      this.setState({
        selectedUser: selectedMemberList,
      });
    } else {
      for (let i = 0; i < selectedMemberList.length; i++) {
        if (selectedMemberList[i].user.id === rowData.id) {
          selectedMemberList.splice(i, 1);
        }
      }
      this.setState({
        selectedUser: selectedMemberList,
      });
    }
  }

  addSelectedUserList() {
    if (this.state.selectedUserList.length === this.state.numberOfUser) {
      PtsAlert.error('The subscription you have purchased is allowed to add 4 users only.');
    } else {
      const postObject = {
        header: this.header,
        ptsSubscription: {
          id: this.state.subscriptionId,
          subscriptionUserList: this.state.selectedUser,
        },
      };
      FetchServerData.callPostService('subcriptionms/create', postObject)
        .then((output) => {
          if (output.status === 'SUCCESS') {
            PtsAlert.success(output.message);
            let subscriptionData = output.data;
            let newSelectedMember = this.state.selectedUserList;
            for (let i = 0; i < subscriptionData.subscriptionUserList.length; i++) {
              newSelectedMember.push(subscriptionData.subscriptionUserList[i]);
            }

            this.setState({
              memberListModal: false,
              selectedUserList: newSelectedMember,
            });
            this.getProfileData();
          } else {
          }
        })

        .catch((error) => {});
    }
  }

  purchaseSubscription = () => {
    this.props.history.push({
      pathname: '/homePricing',
    });
  };

  getPlanFeatures() {
    let orderListItem = this.state.planItems.map((plan, index) => {
      return <li>{plan.name}</li>;
    });

    return orderListItem;
  }

  displayPlanSubscription() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <>
            <Row>
              <Col md={3} style={{ margin: '0px 0px 0px 0px', padding: '1em' }} className="subscriptionPlan">
                <div data-aos="zoom-in" data-aos-delay={300}>
                  <div className="billing_plan-inner">
                    <div className="entry-title">
                      <h3 style={{ padding: '7px', textTransform: 'capitalize' }}>
                        {this.state.userSubscription && this.state.userSubscription.plan
                          ? this.state.userSubscription.plan.description
                          : ''}{' '}
                      </h3>
                      <div className="price">
                        <span style={{ left: '18px' }}>
                          {this.state.userSubscription && this.state.userSubscription.price
                            ? '$' + this.state.userSubscription.price
                            : ''}
                        </span>
                      </div>
                    </div>
                    <div className="entry-content">
                      {/* <ul>
                        <li>
                          <p>Upto 3 Family Members can be added in this Subscription</p>
                        </li>
                      </ul> */}
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={6} style={{ padding: '1em' }}>
                <Row>
                  <Col md={6} style={{ textAlign: 'right', padding: '0em' }}>
                    <Label style={{ color: '#000', textAlign: 'right' }}> Start Date:</Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left', padding: '0em' }}>
                    {this.state.userSubscription && this.state.userSubscription.startDate !== null
                      ? moment(this.state.userSubscription.startDate).format('DD-MMM-YYYY')
                      : ''}
                  </Col>
                </Row>
                <Row>
                  <Col md={6} style={{ textAlign: 'right', padding: '0em' }}>
                    <Label style={{ color: '#000', textAlign: 'right' }}> End Date:</Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left', padding: '0em' }}>
                    {this.state.userSubscription && this.state.userSubscription.endDate !== null
                      ? moment(this.state.userSubscription.endDate).format('DD-MMM-YYYY')
                      : ''}
                  </Col>
                </Row>
                {this.state.userSubscription && this.state.userSubscription.status !== null ? (
                  <Row>
                    <Col md={6} style={{ textAlign: 'right', padding: '0em' }}>
                      <Label style={{ color: '#000', textAlign: 'right' }}> Status:</Label>
                    </Col>
                    <Col md={6} style={{ textAlign: 'left', padding: '0em', textTransform: 'capitalize' }}>
                      {this.state.userSubscription && this.state.userSubscription.status !== null
                        ? this.state.userSubscription.status
                        : ''}
                    </Col>
                  </Row>
                ) : (
                  ''
                )}

                {this.state.userSubscription.plan && this.state.userSubscription.plan.description === 'Basic Yearly' ? (
                  ''
                ) : (
                  <Row>
                    <Col md={12} style={{ textAlign: 'center', marginTop: '1em' }}>
                      <Button
                        variant="contained"
                        className="cancelSubscription"
                        size="small"
                        style={{
                          background: '#ef6603',
                          color: '#fff',
                          borderBottom: '3px solid #636262',
                        }}
                        onClick={(e) => this.toUpgradePlan(e)}
                      >
                        Upgrade Plan
                      </Button>
                    </Col>
                  </Row>
                )}
                {this.state.userSubscription &&
                this.state.userSubscription.status === 'active' &&
                this.state.userSubscription.autoPaid === false ? (
                  <Row>
                    <Col md={12} style={{ textAlign: 'center', marginTop: '1em' }}>
                      <Button
                        variant="contained"
                        className="cancelSubscription"
                        size="small"
                        style={{
                          background: '#ef6603',
                          color: '#fff',
                          borderBottom: '3px solid #636262',
                          width: '122px',
                        }}
                        onClick={(e) => this.toRenewPlan(e)}
                      >
                        Renew Plan
                      </Button>
                    </Col>
                  </Row>
                ) : (
                  ''
                )}
              </Col>
              <Col md={3}>
                {this.state.userSubscription && (
                  <>
                    {this.state.userSubscription.source === 'stripe' ||
                    this.state.userSubscription.source === 'Stripe' ? (
                      <>
                        <Row>
                          <Col md={12} style={{ textAlign: 'center' }}>
                            <b className="manualCharge">Manual Charge</b>
                            <span className="billing_cycle">
                              <Switch
                                checked={this.state.checked}
                                onChange={this.handleSubscriptionChargeChange.bind(this)}
                                color="primary"
                                name="checkedB"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                              />
                            </span>
                            <b className="autoCharge">Auto Charge</b>
                          </Col>
                        </Row>
                        <Row>
                          <Col
                            md={12}
                            style={{
                              textAlign: 'center',
                              marginTop: '1em',
                            }}
                          >
                            <Tooltip title="Subscription will be canceled after the end of current cycle">
                              <Button
                                variant="contained"
                                className="cancelSubscription"
                                size="small"
                                style={{
                                  background: '#717373',
                                  color: '#fff',
                                  borderBottom: '3px solid #636262',
                                }}
                                onClick={(e) => this.cancelSubscription(e)}
                              >
                                Cancel Subscription
                              </Button>
                            </Tooltip>
                          </Col>
                        </Row>
                      </>
                    ) : (
                      ''
                    )}
                  </>
                )}
              </Col>
            </Row>
          </>
        </>
      );
    }
  }

  displayFreeSubscription() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <Row>
            <Col md={1}></Col>
            <Col
              md={3}
              style={{ margin: '0px 45px 0px 0px', padding: '1em', textAlign: 'center' }}
              className="subscriptionPlan"
            >
              <div data-aos="zoom-in" data-aos-delay={300}>
                <div className="billing_plan-inner">
                  <div className="entry-title">
                    <h3 style={{ padding: '7px', textTransform: 'capitalize' }}>FREE WITH ADS</h3>
                    <div className="price">
                      <span style={{ left: '12px' }}>{'$0.00'}</span>
                    </div>
                  </div>
                </div>
              </div>
            </Col>

            <Col md={2}>
              {this.state.userSubscription && this.state.userSubscription.startDate !== null ? (
                <Row>
                  <Col md={6} style={{ textAlign: 'right', padding: '0em' }}>
                    <Label style={{ color: '#000', textAlign: 'right' }}> Start Date:</Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left', padding: '0em' }}>
                    {this.state.userSubscription && this.state.userSubscription.startDate !== null
                      ? moment(this.state.userSubscription.startDate).format('DD-MMM-YYYY')
                      : ''}
                  </Col>
                </Row>
              ) : (
                ''
              )}
              {this.state.userSubscription && this.state.userSubscription.endDate !== null ? (
                <Row>
                  <Col md={6} style={{ textAlign: 'right', padding: '0em' }}>
                    <Label style={{ color: '#000', textAlign: 'right' }}> End Date:</Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left', padding: '0em' }}>
                    {this.state.userSubscription && this.state.userSubscription.endDate !== null
                      ? moment(this.state.userSubscription.endDate).format('DD-MMM-YYYY')
                      : ''}
                  </Col>
                </Row>
              ) : (
                ''
              )}
              {this.state.userSubscription && this.state.userSubscription.status !== null ? (
                <Row>
                  <Col md={6} style={{ textAlign: 'right', padding: '0em' }}>
                    <Label style={{ color: '#000', textAlign: 'right' }}> Status:</Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left', padding: '0em', textTransform: 'capitalize' }}>
                    {this.state.userSubscription && this.state.userSubscription.status !== null
                      ? this.state.userSubscription.status
                      : ''}
                  </Col>
                </Row>
              ) : (
                ''
              )}
              <Button
                className="purchaseSubscription"
                variant="contained"
                size="small"
                style={{
                  background: '#f26d21',
                  color: '#fff',
                  borderBottom: '3px solid #636262',
                  borderRadius: '5px',
                  margin: '50px auto',
                  width: '100%',
                }}
                onClick={(e) => this.purchaseSubscription(e)}
              >
                Purchase Subscription
              </Button>
            </Col>
            <Col md={4}>
              <Row>
                <Col md={12} style={{ textAlign: 'center' }}>
                  <b>Trial Period Details</b>
                </Col>
              </Row>
              {this.premiumFeatures && this.premiumFeatures.startDate !== null ? (
                <Row style={{ marginTop: '1em' }}>
                  <Col md={6} style={{ textAlign: 'right', padding: '0em' }}>
                    <Label style={{ color: '#000', textAlign: 'right' }}>Start Date:</Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left', padding: '0em' }}>
                    {this.premiumFeatures && this.premiumFeatures.startDate !== null
                      ? moment(this.premiumFeatures.startDate).format('DD-MMM-YYYY')
                      : ''}
                  </Col>
                </Row>
              ) : (
                ''
              )}
              {this.premiumFeatures && this.premiumFeatures.endDate !== null ? (
                <Row>
                  <Col md={6} style={{ textAlign: 'right', padding: '0em' }}>
                    <Label style={{ color: '#000', textAlign: 'right' }}> End Date:</Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left', padding: '0em' }}>
                    {this.premiumFeatures && this.premiumFeatures.endDate !== null
                      ? moment(this.premiumFeatures.endDate).format('DD-MMM-YYYY')
                      : ''}
                  </Col>
                </Row>
              ) : (
                ''
              )}
              {this.premiumFeatures && this.premiumFeatures.status !== null ? (
                <Row>
                  <Col md={6} style={{ textAlign: 'right', padding: '0em' }}>
                    <Label style={{ color: '#000', textAlign: 'right' }}> Status:</Label>
                  </Col>
                  <Col md={6} style={{ textAlign: 'left', padding: '0em', textTransform: 'capitalize' }}>
                    {this.premiumFeatures && this.premiumFeatures.status !== null
                      ? this.premiumFeatures.status.status
                      : ''}
                  </Col>
                </Row>
              ) : (
                ''
              )}
            </Col>
            <Col md={2}></Col>
          </Row>
        </>
      );
    }
  }

  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <Row style={{ position: 'relative', margin: '0em 1em 1em 1em', marginLeft: '0em' }}>
            <Col md={12} style={{ position: 'relative', padding: '5px' }}>
              <Card
                style={{
                  height: '100%',
                  borderRadius: '20px',
                  boxShadow:
                    '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2)',
                }}
              >
                <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                  <Row>
                    <Col md={9}>
                      <Label style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em' }}>
                        Plan and Subscription
                      </Label>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  {this.state.isDisplayFreePlanSubscription && this.displayFreeSubscription()}
                  {this.state.isDisplayPlanSubscription && this.displayPlanSubscription()}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row style={{ margin: '0em 1em 0em 0em' }}>
            {this.state.orderList === [] ? (
              ''
            ) : (
              <Col md={12} style={{ padding: '5px' }}>
                <Card style={{ height: '100%' }}>
                  <CardHeader
                    style={{
                      background: '#1ABC9C',
                      color: '#fff',
                      borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
                      padding: '8px',
                    }}
                  >
                    <b> Order History</b>
                  </CardHeader>

                  <CardBody style={{ padding: '0' }}>
                    <Table aria-label="simple table" className="orderHistory">
                      <TableHead style={{ fontFamily: 'bold' }}>
                        <TableRow>
                          <TableCell align="center">
                            <b>#</b>
                          </TableCell>
                          <TableCell align="center">
                            <b>Order</b>
                          </TableCell>
                          <TableCell align="center">
                            <b>Date</b>
                          </TableCell>
                          <TableCell align="center">
                            <b>Price</b>
                          </TableCell>
                          <TableCell align="center">
                            <b>Status</b>
                          </TableCell>
                          <TableCell align="center" style={{ width: '10%' }}>
                            <b>Download Invoice</b>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.orderList && (
                          <>
                            {this.state.orderList.map((row, index) => (
                              <TableRow key={index} style={{ backgroundColor: index % 2 === 0 ? '#fff' : '#e4f0ff' }}>
                                <TableCell component="th" scope="row" align="center">
                                  {index + 1}
                                </TableCell>{' '}
                                <>
                                  {row.orderItems.length > 0 ? (
                                    <TableCell align="center">{row.orderItems[0].description}</TableCell>
                                  ) : (
                                    <TableCell align="center"></TableCell>
                                  )}
                                </>
                                <TableCell align="center">
                                  {row.date ? moment(row.date).format('DD-MMM-YYYY') : <span></span>}
                                </TableCell>
                                <TableCell align="center">{row.price ? '$' + row.price : <span></span>}</TableCell>
                                <TableCell align="center">{row.status ? row.status : <span></span>}</TableCell>
                                <TableCell align="center">
                                  {row.invoiceId && (
                                    <>
                                      <span onClick={this.getInvoice.bind(this, row)}>
                                        <i
                                          className="fa fa-download"
                                          aria-hidden="true"
                                          style={{ cursor: 'pointer', color: 'rgb(26, 188, 156)', fontSize: '20px' }}
                                        ></i>
                                      </span>
                                      <span style={{ display: 'none' }}>
                                        <iframe
                                          src={this.state.invoiceUrl}
                                          style={{ width: '100%', height: '500px' }}
                                          frameborder="0"
                                          title="indpdf"
                                        ></iframe>
                                      </span>
                                    </>
                                  )}
                                </TableCell>
                              </TableRow>
                            ))}
                          </>
                        )}
                      </TableBody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
          <Modal isOpen={this.state.orderItemsModal} toggle={this.orderItemsModalToggle} size="md">
            <ModalHeader toggle={this.orderItemsModalToggle}>Order Details</ModalHeader>
            <ModalBody>
              <Table
                responsive
                className="table table-striped"
                style={{
                  border: '1px solid',
                  margin: '0.5em 0em 0em 0em',
                  width: '100%',
                }}
              >
                <thead
                  style={{
                    background: '#1ABC9C',
                    color: '#fff',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
                    padding: '8px',
                  }}
                >
                  <tr>
                    <th>
                      <b>#</b>
                    </th>
                    <th>
                      <b>Order Item</b>
                    </th>
                    <th>
                      <b>Price</b>
                    </th>
                  </tr>
                </thead>
                <tbody>{this.showOrderItemList()}</tbody>
              </Table>
            </ModalBody>
          </Modal>

          <Modal isOpen={this.state.memberListModal} toggle={this.memberListModalToggle} size="lg">
            <ModalHeader toggle={this.memberListModalToggle}>Family member in the plan</ModalHeader>
            <ModalBody>
              <Row>
                <Col md={12}>
                  <MaterialTable
                    title=""
                    icons={UiUtils.getMaterialTableIcons()}
                    data={this.state.memberList}
                    columns={[
                      { title: '#', render: (rowData) => rowData.tableData.id + 1, width: '5%' },
                      { title: 'Name', render: (rowData) => rowData.name },
                      {
                        title: 'Select',
                        field: 'imageUrl',
                        filtering: false,
                        width: '10%',
                        render: (rowData) => (
                          <input
                            type="checkbox"
                            defaultChecked={rowData.checked}
                            name="checkedSacCode"
                            onChange={(e) => this.selectedParticipant(e, rowData, 'member')}
                          />
                        ),
                      },
                    ]}
                    options={{
                      sorting: true,
                      actionsColumnIndex: -1,
                      pageSize: this.state.pageSize,
                      headerStyle: { backgroundColor: '#1ABC9C', fontWeight: 'bold', color: '#fff', align: 'left' },
                      rowStyle: {
                        height: '2em',
                        textTransform: 'capitalize',
                      },
                      filtering: true,
                      paging: this.state.isPaging,
                    }}
                  />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <div style={{ textAlign: 'center' }}>
                <Button
                  variant="contained"
                  size="small"
                  style={{ background: '#ef6603', color: '#fff' }}
                  onClick={(e) => this.addSelectedUserList()}
                  type="submit"
                >
                  Add
                </Button>
                &nbsp;&nbsp;
                <CancelIconButton onClick={(e) => this.memberListModalToggle()} />
              </div>
            </ModalFooter>
          </Modal>

          <Modal isOpen={this.state.invoiceModal} size="xl" className="modalContain">
            <ModalHeader toggle={this.toggle}>
              <Label
                style={{
                  fontWeight: '600',
                  color: '#162c50',
                  textAlign: 'center',
                  float: 'left',
                }}
              ></Label>
              Report
            </ModalHeader>
            <ModalBody>
              <iframe
                src={this.state.invoiceUrl}
                style={{ width: '100%', height: '500px' }}
                frameborder="0"
                title="indpdf"
              ></iframe>
            </ModalBody>
          </Modal>
        </>
      );
    }
  }
}

export default BillingDetails;
