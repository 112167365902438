import moment from 'moment-timezone';
import PtsAlert from '../pts/components/ptsAlert/PtsAlert';
import store from '../redux/store';
import FetchServerData from './FetchServerData';
import Geocode from 'react-geocode';
import Avatar, { ConfigProvider } from 'react-avatar';
import ActiveMenuAction from '../redux/activeMenu/ActiveMenuAction';
export const getAmountInWords = (amt, currency) => {
  let currName = 'Rupees';
  let currSubunit = 'Paisa';
  if (undefined !== currency && null !== currency) {
    currName = currency.unit;
    currSubunit = currency.subunit;
  }
  let a = [
    '',
    'One ',
    'Two ',
    'Three ',
    'Four ',
    'Five ',
    'Six ',
    'Seven ',
    'Eight ',
    'Nine ',
    'Ten ',
    'Eleven ',
    'Twelve ',
    'Thirteen ',
    'Fourteen ',
    'Fifteen ',
    'Sixteen ',
    'Seventeen ',
    'Eighteen ',
    'Nineteen ',
  ];
  let b = ['', '', 'Twenty', 'Thirty', 'Forty', 'Fifty', 'Sixty', 'Seventy', 'Eighty', 'Ninety'];
  let number = parseFloat(amt).toFixed(2).split('.');

  let num = parseInt(number[0], 10);
  let digit = parseInt(number[1], 10);
  if (num.toString().length > 11) return 'overflow';
  let n = ('000000000' + num).substr(-11).match(/^(\d{2})(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  let d = ('00' + digit).substr(-2).match(/^(\d{2})$/);
  if (!n) return;
  let str = '';
  str += n[1] !== 0 ? (a[Number(n[1])] || b[n[1][0]] + ' ' + a[n[1][1]]) + 'Arab ' : '';
  str += n[2] !== 0 ? (a[Number(n[2])] || b[n[2][0]] + ' ' + a[n[2][1]]) + 'Crore ' : '';
  str += n[3] !== 0 ? (a[Number(n[3])] || b[n[3][0]] + ' ' + a[n[3][1]]) + 'Lakh ' : '';
  str += n[4] !== 0 ? (a[Number(n[4])] || b[n[4][0]] + ' ' + a[n[4][1]]) + 'Thousand ' : '';
  str += n[5] !== 0 ? (a[Number(n[5])] || b[n[5][0]] + ' ' + a[n[5][1]]) + 'Hundred ' : '';
  if (num === 1) {
    str += n[6] !== 0 ? (a[Number(n[6])] || b[n[6][0]] + ' ' + a[n[6][1]]) + currName + ' ' : '';
  } else {
    str += n[6] !== 0 ? (a[Number(n[6])] || b[n[6][0]] + ' ' + a[n[6][1]]) + currName + 's ' : '' + currName + 's ';
  }
  str +=
    d[1] !== 0
      ? (str !== '' ? 'and ' : '') + (a[Number(d[1])] || b[d[1][0]] + ' ' + a[d[1][1]]) + currSubunit + ' '
      : 'Only!';

  str = str.charAt(0).toUpperCase() + str.slice(1);
  return str;
  // });
};

export const hasPrivilege = (privilegeName, privilegeList) => {
  if (isNull(privilegeList) || isNull(privilegeName)) {
    return false;
  }
  for (let i = 0; i < privilegeList.length; i++) {
    if (equalsIgnoreCase(privilegeName, privilegeList[i])) {
      return true;
    }
  }

  return false;
};

export const isSupportedCurrency = (input) => {
  let supportedArray = [
    'CAD',
    'HKD',
    'ISK',
    'PHP',
    'DKK',
    'HUF',
    'CZK',
    'GBP',
    'RON',
    'SEK',
    'IDR',
    'INR',
    'BRL',
    'RUB',
    'HRK',
    'JPY',
    'THB',
    'CHF',
    'EUR',
    'MYR',
    'BGN',
    'TRY',
    'CNY',
    'NOK',
    'NZD',
    'ZAR',
    'USD',
    'MXN',
    'SGD',
    'AUD',
    'ILS',
    'KRW',
    'PLN',
  ];
  for (let i = 0; i < supportedArray.length; i++) {
    if (input === supportedArray[i]) {
      return true;
    }
  }
  return false;
};

export const equalsIgnoreCase = (string1, string2) => {
  if (undefined === string1 || null === string1 || undefined === string2 || null === string2) {
    return false;
  }
  if (string1.toString().trim().toUpperCase() === string2.toString().trim().toUpperCase()) {
    return true;
  }

  return false;
};

export const isNull = (input) => {
  if (undefined === input || null === input) {
    return true;
  }
  return false;
};
export const isNullOrEmpty = (input) => {
  if (undefined === input || null === input || input === '') {
    return true;
  }
  if (input.toString().trim() === '') {
    return true;
  }
  return false;
};

export const isNotNull = (input) => {
  if (undefined === input || null === input) {
    return false;
  }
  return true;
};

export const isNotNullAndEmpty = (input) => {
  if (undefined === input || null === input || input.toString().trim() === '') {
    return false;
  }
  return true;
};

export const getFloatValue = (input) => {
  if (undefined === input || null === input || '' === input) {
    return 0.0;
  }
  return parseFloat(input).toFixed(2);
};

export const getMonth = (monthInNumber) => {
  monthInNumber = Number(monthInNumber);
  let months = {
    1: 'JAN',
    2: 'FEB',
    3: 'MAR',
    4: 'APR',
    5: 'MAY',
    6: 'JUN',
    7: 'JUL',
    8: 'AUG',
    9: 'SEP',
    10: 'OCT',
    11: 'NOV',
    12: 'DEC',
  };
  return months[monthInNumber];
};

export const getMonthNumber = (monthInNumber) => {
  // monthInNumber = Number(monthInNumber);
  let months = {
    JAN: '01',
    FEB: '02',
    MAR: '03',
    APR: '04',
    MAY: '05',
    JUN: '06',
    JUL: '07',
    AUG: '08',
    SEP: '09',
    OCT: '10',
    NOV: '11',
    DEC: '12',
  };
  return months[monthInNumber];
};

export const getMonthList = () => {
  return ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
};

export const quarterList = () => {
  return ['APR - JUN', 'JUL-SEP', 'OCT-DEC', 'JAN-MAR'];
};

export const getQuarterList = () => {
  let arr = [];
  arr.push({ month: 'APR', quarter: 'APR-JUN' });
  arr.push({ month: 'JUL', quarter: 'JUL-SEP' });
  arr.push({ month: 'OCT', quarter: 'OCT-DEC' });
  arr.push({ month: 'JAN', quarter: 'JAN-MAR' });
  return arr;
};

export const getQuarterForMonth = (month) => {
  if (equalsIgnoreCase('APR', month) || equalsIgnoreCase('MAY', month) || equalsIgnoreCase('JUN', month)) {
    return { month: 'APR', quarter: 'APR-JUN' };
  }
  if (equalsIgnoreCase('JUL', month) || equalsIgnoreCase('AUG', month) || equalsIgnoreCase('SEP', month)) {
    return { month: 'JUL', quarter: 'JUL-SEP' };
  }
  if (equalsIgnoreCase('OCT', month) || equalsIgnoreCase('NOV', month) || equalsIgnoreCase('DEC', month)) {
    return { month: 'OCT', quarter: 'OCT-DEC' };
  }
  if (equalsIgnoreCase('JAN', month) || equalsIgnoreCase('FEB', month) || equalsIgnoreCase('MAR', month)) {
    return { month: 'JAN', quarter: 'JAN-MAR' };
  }
};

export const getLogo = () => {
  let imageData = '';
  if (store.getState().appData.logoData) {
    imageData = imageData + store.getState().appData.logoData;
  }
  return imageData;
};

export const getLocalToUtcDate = (dateTime) => {
  let date = '';
  date = new Date(dateTime).toISOString();

  return date;
};
export const communizeTableText = () => {
  let emptyRecordText = '';

  emptyRecordText = 'Please click  +  to get started';
  return emptyRecordText;
};

export const getUtcToLocalDate = (dateTime) => {
  let timeZone = '';
  if (store.getState().selectedMemberData.selectedMemberData.address !== null) {
    timeZone = store.getState().selectedMemberData.selectedMemberData.address.timezone;
  } else if (store.getState().userData.userData.address !== null) {
    timeZone = store.getState().userData.userData.address.timezone;
  } else {
    timeZone = null;
  }
  let date = '';
  date = new Date(dateTime);

  let localDateTimeFormate = '';
  if (timeZone !== null) {
    let localDateTime = new Date(dateTime).toLocaleString('en-US', { timeZone: timeZone.name });
    localDateTimeFormate = moment(localDateTime).format('DD MMM YYYY hh:mm a');
  } else {
    localDateTimeFormate = moment(date).format('DD MMM YYYY hh:mm a');
  }
  return localDateTimeFormate;
};

export const convertUserTimeZoneDateToUTCDate = (date) => {
  let timeZone = '';
  if (store.getState().selectedMemberData.selectedMemberData.address !== null) {
    timeZone = store.getState().selectedMemberData.selectedMemberData.address.timezone;
  } else if (store.getState().userData.userData.address !== null) {
    timeZone = store.getState().userData.userData.address.timezone;
  } else {
    timeZone = null;
  }
  let utcDateTime = '';
  if (timeZone !== null) {
    let dateTimeFormate = moment(date.toString()).format('DD MMM YYYY hh:mm a');
    utcDateTime = moment.tz(dateTimeFormate.toString(), 'DD MMM YYYY hh:mm a', timeZone.name);
  } else {
    utcDateTime = new Date(date).toISOString();
  }
  return utcDateTime;
};

export const adminPanelConvertUserTimeZoneDateToUTCDate = (date) => {
  let timeZone = '';

  let utcDateTime = '';
  if (timeZone !== null) {
    let dateTimeFormate = moment(date.toString()).format('DD MMM YYYY hh:mm a');
    utcDateTime = moment.tz(dateTimeFormate.toString(), 'DD MMM YYYY hh:mm a', timeZone.name);
  } else {
    utcDateTime = new Date(date).toISOString();
  }
  return utcDateTime;
};

export const consolidatedViewUTCDateToUserTimeZoneDateFormate = (date) => {
  let consolidatedDate = '';

  consolidatedDate = moment(date).format('DD-MM-YYYY');
  return consolidatedDate;
};
export const consolidatedViewUTCDateToUserTimeZoneTimeFormate = (UtcDate) => {
  let timeZone = '';

  if (store.getState().userData.userData.address !== null) {
    timeZone = store.getState().userData.userData.address.timezone;
  } else {
    timeZone = null;
  }
  let utcTimeZone = '';
  if (timeZone !== null) {
    let utcCutoff = moment(UtcDate);

    utcTimeZone =
      utcCutoff.tz(timeZone.name).format('hh:mm a') + ' ' + '(' + moment().tz(timeZone.name).format('z') + ')';
  } else {
    utcTimeZone = moment(UtcDate).format('hh:mm');
  }
  return utcTimeZone;
};
export const convertUTCDateToUserTimeZoneDate = (UtcDate) => {
  let timeZone = '';

  if (store.getState().selectedMemberData.selectedMemberData.address !== null) {
    timeZone = store.getState().selectedMemberData.selectedMemberData.address.timezone;
  } else if (store.getState().userData.userData.address !== null) {
    timeZone = store.getState().userData.userData.address.timezone;
  } else {
    timeZone = null;
  }
  let utcTimeZone = '';
  if (timeZone !== null) {
    let utcCutoff = moment(UtcDate);

    utcTimeZone =
      utcCutoff.tz(timeZone.name).format('DD MMM YYYY hh:mm a') +
      ' ' +
      '(' +
      moment().tz(timeZone.name).format('z') +
      ')';
  } else {
    utcTimeZone = moment(UtcDate).format('DD MMM YYYY hh:mm a');
  }
  return utcTimeZone;
};

export const convertUTCDateToUserTimeZoneViewDateFormate = (UtcDate) => {
  let timeZone = '';

  if (store.getState().selectedMemberData.selectedMemberData.address !== null) {
    timeZone = store.getState().selectedMemberData.selectedMemberData.address.timezone;
  } else if (store.getState().userData.userData.address !== null) {
    timeZone = store.getState().userData.userData.address.timezone;
  } else {
    timeZone = null;
  }
  let utcTimeZone = '';
  if (timeZone !== null) {
    let utcCutoff = moment(UtcDate);

    utcTimeZone =
      utcCutoff.tz(timeZone.name).format('MMM DD, YYYY (ddd) - hh:mm a') +
      '(' +
      moment().tz(timeZone.name).format('z') +
      ')';
  } else {
    utcTimeZone = moment(UtcDate).format('MMM DD, YYYY (ddd) - hh:mm a');
  }
  return utcTimeZone;
};

export const convertUTCDateToUserTimeZoneDateFormate = (UtcDate) => {
  let timeZone = '';

  if (store.getState().selectedMemberData.selectedMemberData.address !== null) {
    timeZone = store.getState().selectedMemberData.selectedMemberData.address.timezone;
  } else if (store.getState().userData.userData.address !== null) {
    timeZone = store.getState().userData.userData.address.timezone;
  } else {
    timeZone = null;
  }
  let utcTimeZone = '';
  if (timeZone !== null) {
    let utcCutoff = moment(UtcDate);

    utcTimeZone =
      utcCutoff.tz(timeZone.name).format('MMM DD, YYYY') + ' ' + '(' + moment().tz(timeZone.name).format('z') + ')';
  } else {
    utcTimeZone = moment(UtcDate).format('MMM DD, YYYY ');
  }
  return utcTimeZone;
};

export const convertUTCDateToUserTimeZoneDateWithoutTimezoneFormate = (UtcDate) => {
  let timeZone = '';

  if (store.getState().selectedMemberData.selectedMemberData.address !== null) {
    timeZone = store.getState().selectedMemberData.selectedMemberData.address.timezone;
  } else if (store.getState().userData.userData.address !== null) {
    timeZone = store.getState().userData.userData.address.timezone;
  } else {
    timeZone = null;
  }
  let utcTimeZone = '';
  if (timeZone !== null) {
    let utcCutoff = moment(UtcDate);

    utcTimeZone = utcCutoff.tz(timeZone.name).format('MMM DD, YYYY');
  } else {
    utcTimeZone = moment(UtcDate).format('MMM DD, YYYY ');
  }
  return utcTimeZone;
};
export const convertUTCDateToUserCardTimeZoneDate = (UtcDate, memberCardTimeZone) => {
  let timeZone = '';
  if (memberCardTimeZone !== null && memberCardTimeZone !== undefined && memberCardTimeZone !== '') {
    timeZone = memberCardTimeZone;
  } else if (
    store.getState().userData.userData.address !== null &&
    store.getState().userData.userData.address !== undefined &&
    store.getState().userData.userData.address !== '' &&
    store.getState().userData.userData.address.timezone !== null
  ) {
    timeZone = store.getState().userData.userData.address.timezone.name;
  } else {
    timeZone = null;
  }
  let utcTimeZone = '';
  if (timeZone !== null && timeZone !== undefined && timeZone !== '') {
    let utcCutoff = moment(UtcDate);
    utcTimeZone =
      utcCutoff.tz(timeZone).format('MMM DD, YYYY (ddd)- hh:mm a') +
      ' ' +
      '(' +
      moment().tz(timeZone).format('z') +
      ')';
  } else {
    utcTimeZone = moment(UtcDate).format('MMM DD, YYYY (ddd) - hh:mm a');
  }
  return utcTimeZone;
};

export const calenderTimeZoneFormate = (calenderDate) => {
  let timeZone = '';
  let utcTimeZone = '';
  if (store.getState().selectedMemberData.selectedMemberData.address !== null) {
    timeZone = store.getState().selectedMemberData.selectedMemberData.address.timezone;
  } else if (store.getState().userData.userData.address !== null) {
    timeZone = store.getState().userData.userData.address.timezone;
  } else {
    timeZone = null;
  }
  if (timeZone !== null) {
    let utcCutoff = moment(calenderDate);
    utcTimeZone =
      utcCutoff.tz(timeZone.name).format('hh:mm a') + ' ' + '(' + moment().tz(timeZone.name).format('z') + ')';
  } else {
    utcTimeZone = moment(calenderDate).format('hh:mm a');
  }
  return utcTimeZone;
};

export const calenderUserTimeZoneDateFormate = (calenderDate) => {
  let timeZone = '';
  let utcTimeZone = '';
  if (store.getState().selectedMemberData.selectedMemberData.address !== null) {
    timeZone = store.getState().selectedMemberData.selectedMemberData.address.timezone;
  } else if (store.getState().userData.userData.address !== null) {
    timeZone = store.getState().userData.userData.address.timezone;
  } else {
    timeZone = null;
  }
  if (timeZone !== null) {
    let utcCutoff = moment(calenderDate);
    utcTimeZone = utcCutoff.tz(timeZone.name).format('YYYY-MM-DD');
  } else {
    utcTimeZone = moment(calenderDate).format('YYYY-MM-DD');
  }
  return utcTimeZone;
};

export const UserTimeZoneToCalenderMonthWeakDateTimeFormate = (calenderDate) => {
  let timeZone = '';
  let utcTimeZone = '';
  if (store.getState().selectedMemberData.selectedMemberData.address !== null) {
    timeZone = store.getState().selectedMemberData.selectedMemberData.address.timezone;
  } else if (store.getState().userData.userData.address !== null) {
    timeZone = store.getState().userData.userData.address.timezone;
  } else {
    timeZone = null;
  }
  if (timeZone !== null) {
    let utcCutoff = moment(calenderDate);
    utcTimeZone = utcCutoff.tz(timeZone.name).format('YYYY-MM-DDTHH:mm:ss');
  } else {
    utcTimeZone = moment(calenderDate).format('YYYY-MM-DDTHH:mm:ss');
  }
  return utcTimeZone;
};

export const displayUsaPhoneNumberFormate = (phoneNumber) => {
  if (phoneNumber === null) {
    return '';
  }

  let output = '';
  if (phoneNumber) {
    output = '(';
  } else {
    output = '';
  }
  phoneNumber.replace(/^\D*(\d{0,3})\D*(\d{0,3})\D*(\d{0,4})/, function (match, g1, g2, g3) {
    if (g1.length) {
      output += g1;
      if (g1.length === 3) {
        output += ')';
        if (g2.length) {
          output += ' ' + g2;
          if (g2.length === 3) {
            output += ' - ';
            if (g3.length) {
              output += g3;
            }
          }
        }
      }
    }
  });
  return output;
};

export const convertPhoneNumberToUsaPhoneNumberFormate = (value, previousValue) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, '');
  const cvLength = currentValue.length;

  if (!previousValue || value.length > previousValue.length) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7) return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3)}`;
    return `(${currentValue.slice(0, 3)}) ${currentValue.slice(3, 6)}-${currentValue.slice(6, 10)}`;
  }
};

export const toCamelCase = (string) => {
  String.prototype.toCamelCase = function () {
    return this.substring(0, 1).toUpperCase() + this.substring(1);
  };
  return string.toCamelCase();
};

export const maxFieldLength = (characters) => {
  let maxLength = PtsAlert.error('The size of this field is limited to ' + characters + ' characters only');

  return maxLength;
};

export const getAddressAsText = (address) => {
  if (isNull(address)) {
    return '';
  }
  let finalAddress = '';
  let addressLine1 = address.line1;
  if (addressLine1 && addressLine1.length > 0) {
    finalAddress = addressLine1.toString().trim() + ', ';
  }
  let addressLine2 = address.line2;
  if (addressLine2 && addressLine2.length > 0) {
    finalAddress = finalAddress + addressLine2.toString().trim() + ', ';
  }

  let addressLine = address.line;
  if (addressLine && addressLine.length > 0) {
    finalAddress = finalAddress + addressLine.toString().trim() + ', ';
  }

  let city = address.city;
  if (city && city.length > 0) {
    finalAddress = finalAddress + city.toString().trim() + ', ';
  }

  let state = address.statevo;
  if (state && state.namecode && state.namecode.toString().length > 0) {
    finalAddress = finalAddress + state.namecode.toString().trim() + ', ';
    if (state.countryvo && state.countryvo.namecode && state.countryvo.namecode.toString().length > 0) {
      finalAddress = finalAddress + state.countryvo.namecode.toString().trim() + ', ';
    }
  } else {
    state = address.state;
    if (state && state.namecode && state.namecode.toString().length > 0) {
      finalAddress = finalAddress + state.namecode.toString().trim() + ', ';
      if (state.countryvo && state.countryvo.namecode && state.countryvo.namecode.toString().length > 0) {
        finalAddress = finalAddress + state.countryvo.namecode.toString().trim() + ', ';
      }
      if (state.country && state.country.namecode && state.country.namecode.toString().length > 0) {
        finalAddress = finalAddress + state.country.namecode.toString().trim() + ', ';
      }
    }
  }

  let pin = address.pin;
  if (pin && pin.length > 0) {
    finalAddress = finalAddress + pin.toString().trim();
  } else if (address.pincode && address.pincode > 0) {
    finalAddress = finalAddress + address.pincode.toString().trim();
  }

  let zip = address.zipCode;
  if (zip && zip.length > 0) {
    finalAddress = finalAddress + zip.toString().trim();
  }

  return finalAddress;
};

export const getPercentAmount = (percent, amount) => {
  if (percent && amount && percent > 0 && amount > 0) {
    let val = parseFloat(percent) * parseFloat(amount);
    val = parseFloat(val) / 100;
    return parseFloat(val);
  } else {
    return 0.0;
  }
};

export const consoleLog = (value) => {
  let consoleValue;

  return consoleValue;
};

export const setFCMTokenForUser = (inputHeader) => {
  let header = store.getState().header.header;
  if (!header) {
    header = inputHeader;
  }
  const fcmToken = store.getState().appUserReducer.fcmToken;
  if (!header || !header.appuserId) {
    return;
  }

  if (!fcmToken || fcmToken.length <= 0) {
    /*Fcm Token Not Exists, Create Fcm Token*/

    return;
  }

  /* Call Service to Set Token */
  let requestObject = {
    header: header,
    appuserVo: {
      fcmToken: fcmToken,
    },
  };
  FetchServerData.callPostService('/userms/updateFcmToken/', requestObject)
    .then((output) => {
      if (output.status === 'SUCCESS') {
      }
    })
    .catch((error) => {});
};
export const toBase64 = (input) => {
  let keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';

  var output = [];
  var chr1,
    chr2,
    chr3 = '';
  var enc1,
    enc2,
    enc3,
    enc4 = '';
  var i = 0;
  do {
    chr1 = input.charCodeAt(i++);
    chr2 = input.charCodeAt(i++);
    chr3 = input.charCodeAt(i++);

    enc1 = chr1 >> 2;
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4);
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6);
    enc4 = chr3 & 63;

    if (isNaN(chr2)) {
      enc3 = enc4 = 64;
    } else if (isNaN(chr3)) {
      enc4 = 64;
    }
    output.push(keyStr.charAt(enc1) + keyStr.charAt(enc2) + keyStr.charAt(enc3) + keyStr.charAt(enc4));
    chr1 = chr2 = chr3 = '';
    enc1 = enc2 = enc3 = enc4 = '';
  } while (i < input.length);

  return output.join('');
};
export const getRolePermission = (privilegeList) => {
  let allPermission = false;
  let createPermission = false;
  let readPermission = false;
  let updatePermission = false;
  let deletePermission = false;
  if (privilegeList.length > 0) {
    for (let i = 0; i < privilegeList.length; i++) {
      switch (privilegeList[i].toLowerCase()) {
        case '*':
          allPermission = true;
          createPermission = true;
          readPermission = true;
          updatePermission = true;
          deletePermission = true;
          break;
        case 'create':
          createPermission = true;
          readPermission = true;
          break;
        case 'update':
          updatePermission = true;
          readPermission = true;
          break;
        case 'delete':
          deletePermission = true;
          readPermission = true;
          break;
        default:
          break;
      }
    }
  }

  return [allPermission, createPermission, readPermission, updatePermission, deletePermission];
};
export const getDomainObjectRolePermission = (privilegeList) => {
  // let allPermission = false;
  let createPermission = false;
  let readPermission = false;
  let updatePermission = false;
  let deletePermission = false;
  if (privilegeList.length > 0) {
    for (let i = 0; i < privilegeList.length; i++) {
      switch (privilegeList[i].toLowerCase()) {
        case 'create':
          createPermission = true;
          readPermission = true;
          break;
        case 'update':
          updatePermission = true;
          readPermission = true;
          break;
        case 'read':
          readPermission = true;
          break;
        case 'delete':
          deletePermission = true;
          readPermission = true;
          break;
        default:
          break;
      }
    }
  }

  return [createPermission, readPermission, updatePermission, deletePermission];
};
export const getLocationAPIKey = () => {
  let googleMapAPIKey = 'AIzaSyC2X652RPdeR7LYXtbK6X7Vemh_VEQdgMM';
  return googleMapAPIKey;
};
export const getHours = (UtcDate) => {
  let dateTime = '';
  const start = moment(UtcDate);

  dateTime = moment(start).format('H');
  return dateTime;
};

export const getHoursFormate = (hourValue) => {
  let hoursFormate = '';
  var hours = Math.floor(hourValue);
  var minutes = Math.round((hourValue % 1) * 100) / 100;

  let number = moment(hours + ':' + minutes * 60 + '0', 'LT');
  hoursFormate = moment(number).format('h a');
  return hoursFormate;
};
export const getLatLongMapData = async (shortDescription, line, city, country, state) => {
  let mapObj = {};
  let newAddress = `${line} ${city} ${state} ${country}`;
  let googleLocationAPIKey = 'AIzaSyC2X652RPdeR7LYXtbK6X7Vemh_VEQdgMM';
  await Geocode.fromAddress(newAddress, googleLocationAPIKey).then(
    (response) => {
      if (response.results) {
        let latlongdata = response.results[0].geometry.location;
        mapObj = {
          shortDescription: shortDescription,
          line: line,
          city: city,
          country: country,
          state: state,
          title: city + ',' + country,
          lat: latlongdata.lat,
          lng: latlongdata.lng,
        };
      }
    },
    (error) => {
      PtsAlert.error(error);
    }
  );

  return mapObj;
};

export const getLatLongNavigationMapData = (address) => {
  let mapObj = '';

  Geocode.fromAddress(address).then((response) => {
    mapObj = response.results[0].geometry.location;
    return mapObj;
    // mapObj = {
    //   // shortDescription: shortDescription,
    //   line: line,
    //   city: city,
    //   country: country,
    //   state: state,
    //   title: city + ',' + country,
    //   lat: lat,
    //   lng: lng,
    // };
    // return mapObj;
  });
};
export const getWeekDatesList = (currentDate) => {
  let startOfWeek = moment(currentDate).startOf('week').format();
  let endOfWeek = moment(currentDate).endOf('week').format();

  let days = [];
  let day = startOfWeek;
  while (moment(day).isBefore(endOfWeek)) {
    days.push(moment(day).format());
    day = moment(day).add(1, 'd');
  }
  const weekDate = {};

  for (const key of days) {
    let dateKey = moment(key).format('ddd MM/DD');
    weekDate[dateKey] = [];
  }
  return weekDate;
};

export const getHeaderWeekDatesList = (currentDate) => {
  let startOfWeek = moment(currentDate).startOf('week').format();
  let endOfWeek = moment(currentDate).endOf('week').format();

  let days = [];
  let day = startOfWeek;
  while (moment(day).isBefore(endOfWeek)) {
    days.push(moment(day).format());
    day = moment(day).add(1, 'd');
  }
  return days;
};
export const getDate = (UtcDate) => {
  let dateTime = '';
  const start = moment(UtcDate);

  dateTime = moment(start).format('DD-MM-YYYY');
  return dateTime;
};
export const getWeekDate = (UtcDate) => {
  let dateTime = '';
  const start = moment(UtcDate);

  dateTime = moment(start).format('ddd MM/DD');
  return dateTime;
};
export const convertConsolidatedUTCDateToUserTimeZoneDate = (UtcDate) => {
  console.log(UtcDate);
  let timeZone = '';

  if (store.getState().userData.userData.address !== null) {
    timeZone = store.getState().userData.userData.address.timezone;
  } else {
    timeZone = null;
  }
  let localDateTime = '';
  if (timeZone !== null) {
    let utcCutoff = moment(UtcDate);

    localDateTime = utcCutoff.tz(timeZone.name).format();
  } else {
    localDateTime = moment(UtcDate).format();
  }
  console.log(localDateTime);
  return localDateTime;
};

export const convertUTCDateToUserLoginTimeZoneViewDateFormate = (UtcDate) => {
  let timeZone = moment.tz.guess();

  let localDateTime = '';
  if (timeZone !== null) {
    let utcCutoff = moment(UtcDate);

    localDateTime = utcCutoff.tz(timeZone).format();
  } else {
    localDateTime = moment(UtcDate).format();
  }
  console.log(localDateTime);
  return localDateTime;
};

export const convertUTCDateToUserLoginTimeZoneDate = (UtcDate) => {
  let timeZone = moment.tz.guess();
  let utcTimeZone = '';
  if (timeZone !== null) {
    let utcCutoff = moment(UtcDate);

    // utcTimeZone = utcCutoff.tz(timeZone).format('hh:mm a') + ' ' + '(' + moment().tz(timeZone).format('z') + ')';
    utcTimeZone = utcCutoff.tz(timeZone).format('hh:mm a');
  } else {
    utcTimeZone = moment(UtcDate).format('hh:mm a');
  }
  console.log(utcTimeZone);
  return utcTimeZone;
};

export const getDayDatesList = (Date) => {
  const dayDate = {};

  let dateKey = moment(Date).format('DD-MM-YYYY');
  dayDate[dateKey] = [];
  return dayDate;
};
export const getAddressLine = (address) => {
  let line = address.line;
  let city = address.city;
  let state = address.state.name;
  let country = address.state.country.name;
  // let zipCode = address.zipCode;
  let newAddress = `${line} ${city} ${state} ${country}`;
  return newAddress;
};

export const getColorSet = (index) => {
  let colorObj = [];

  colorObj = [
    '#A2416B',
    '#000080',
    '#65d48a',
    '#99627A',
    '#536fa3',
    '#800000',
    '#2C2891',
    '#009DAE',
    '#7b631c',
    '#8843F2',
    '#9A0680',
    '#146356',
    '#A86464',
    '#EA5455',
    '#0A2647',
    '#b055cc',
    '#495579',
    '#600090',
    '#68B984',
    '#3FA796',
    '#600090',
    '#069A8E',
    '#9A86A4',
    '#0093AB',
    '#00C897',
    '#3E497A',
    '#D9534F',
  ];

  return colorObj[index];
};
export const getMemberName = (name, index, length) => {
  return (
    <>
      <Avatar
        color={getColorSet(index)}
        name={name ? name : ''}
        round="35px"
        size="35px"
        alt={name}
        className="profileIcon"
        style={{ marginLeft: '1.2em' }}
      />
      &nbsp;
    </>
  );
};

export const getMemberProfileName = (name, index, length) => {
  return (
    <>
      <Avatar
        color={getColorSet(index)}
        name={name ? name : ''}
        round="60px"
        size="60px"
        alt={name}
        className="profileIcon"
      />
      &nbsp;
    </>
  );
};
export const camelizeText = (text) => {
  return text.replace(/\b[a-z]/g, (m) => m.toUpperCase());
};

export const PremiumFeatures = (premiumFeatures) => {
  let showPremiumFeatures = '';
  // let showSecondPremiumFeatures = false;
  // let showThirdPremiumFeatures = false;
  if (premiumFeatures !== null) {
    if (premiumFeatures.iteration === 1 && premiumFeatures.status.status === 'Expired') {
      return (showPremiumFeatures = 2);
    } else if (premiumFeatures.iteration === 2 && premiumFeatures.status.status === 'Expired') {
      return (showPremiumFeatures = 3);
    }
  } else {
    if (premiumFeatures === null || premiumFeatures.status.status === 'Expired') {
      return (showPremiumFeatures = 1);
    }
  }
};
export const getActiveMenu = (breadCrumb) => {
  let userMenuArray = store.getState().selectedMemberData.selectedMemberData.menuList;
  for (let i = 0; i < userMenuArray.length; i++) {
    for (let j = 0; breadCrumb && j < breadCrumb.length; j++) {
      if (userMenuArray[i].pageid === breadCrumb[j].pageid) {
        store.dispatch(ActiveMenuAction.setActiveMenu(userMenuArray[i].id));
      }
    }
  }
  return;
};
export const getAdminPanelActiveMenu = (breadCrumb) => {
  let userMenuArray = [];
  let userLogin = store.getState().adminUserDetails.adminUserDetails.isUserLogin;
  if (userLogin === 'sponsor') {
    userMenuArray = [
      {
        uiname: 'Sponsor Home',
        pageid: '/viewSponsor',
        icon: 'fa fa-home text-white',

        id: 1,
      },
      {
        uiname: 'Advertisement',
        pageid: '/advertisementList',
        icon: 'fa fa-adn text-white',
        id: 2,
      },
      {
        uiname: 'Sponsorship Plans',
        pageid: '/sponsorshipList',
        icon: 'fa fa-usd text-white',
        id: 3,
      },
      {
        uiname: 'Help',
        pageid: '/help',
        icon: 'fa fa-question-circle',
        id: 4,
      },
    ];
  } else if (userLogin === 'paidAdvertiser') {
    userMenuArray = [
      {
        uiname: 'Advertiser Home',
        pageid: '/viewAdvertiser',
        icon: 'fa fa-home text-white',
        id: 1,
      },
      {
        uiname: 'Commercials',
        pageid: '/paidAdvertisementList',
        icon: 'fa fa-adn text-white',
        id: 2,
      },
      {
        uiname: 'Commercial Plans',
        pageid: '/paidCommercialsList',
        icon: 'fa fa-usd text-white',
        id: 3,
      },
      {
        uiname: 'Help',
        pageid: '/paidHelp',
        icon: 'fa fa-question-circle',
        id: 4,
      },
    ];
  } else {
    userMenuArray = store.getState().adminUserDetails.adminUserDetails.profile.menus;
  }
  for (let i = 0; i < userMenuArray.length; i++) {
    for (let j = 0; breadCrumb && j < breadCrumb.length; j++) {
      if (userMenuArray[i].pageid === breadCrumb[j].pageid) {
        store.dispatch(ActiveMenuAction.setActiveMenu(userMenuArray[i].id));
      }
    }
  }
  return;
};
// export const googleMapOpenUrl = (shortDescription, line, city, country, state) => {
//   // return `google.navigation:q=${'Maharashtra, India'}`;
//   let address = `${line} ${city} ${state} ${country}`;
//   var url = 'https://www.google.com/maps/dir/?api=1&travelmode=driving&dir_action=navigate&destination=' + address;
//   Linking.canOpenURL(url)
//     .then((supported) => {
//       if (!supported) {
//       } else {
//         return Linking.openURL(url);
//       }
//     })
//     .catch((err) => console.error('An error occurred', err));
// };

export const getBrowserInfo = () => {
  var userAgent = navigator.userAgent.toLowerCase();
  let browserName = '';
  if (userAgent.search('edg') > -1) {
    return 'Microsoft Edge';
  } else if (userAgent.search('chrome') > -1) {
    browserName = 'Chrome';
  } else if (userAgent.search('firefox') > -1) {
    browserName = 'Firefox';
  } else if (userAgent.search('safari') > -1) {
    browserName = 'Safari';
  } else if (userAgent.search('opera') > -1) {
    browserName = 'Opera';
  } else if (userAgent.search('trident') > -1 || userAgent.indexOf('MSIE') > -1) {
    browserName = 'Internet Explorer';
  }
  return browserName;
};
export default {
  setFCMTokenForUser,
  getPercentAmount,
  getLocalToUtcDate,
  getUtcToLocalDate,
  getAddressAsText,
  getLogo,
  getFloatValue,
  getAmountInWords,
  hasPrivilege,
  isSupportedCurrency,
  equalsIgnoreCase,
  isNull,
  isNullOrEmpty,
  isNotNull,
  isNotNullAndEmpty,
  getMonth,
  getMonthNumber,
  getMonthList,
  getQuarterList,
  quarterList,
  getQuarterForMonth,
  toCamelCase,
  maxFieldLength,
  getRolePermission,
  convertUserTimeZoneDateToUTCDate,
  convertUTCDateToUserTimeZoneDate,
  convertUTCDateToUserTimeZoneViewDateFormate,
  calenderTimeZoneFormate,
  calenderUserTimeZoneDateFormate,
  UserTimeZoneToCalenderMonthWeakDateTimeFormate,
  displayUsaPhoneNumberFormate,
  convertPhoneNumberToUsaPhoneNumberFormate,
  toBase64,
  convertUTCDateToUserCardTimeZoneDate,
  consoleLog,
  adminPanelConvertUserTimeZoneDateToUTCDate,
  communizeTableText,
  getLocationAPIKey,
  getHours,
  getHoursFormate,
  getLatLongMapData,
  consolidatedViewUTCDateToUserTimeZoneDateFormate,
  consolidatedViewUTCDateToUserTimeZoneTimeFormate,
  getWeekDatesList,
  getDate,
  convertConsolidatedUTCDateToUserTimeZoneDate,
  getDayDatesList,
  getHeaderWeekDatesList,
  getWeekDate,
  getDomainObjectRolePermission,
  getColorSet,
  getMemberName,
  getMemberProfileName,
  convertUTCDateToUserTimeZoneDateFormate,
  camelizeText,
  PremiumFeatures,
  convertUTCDateToUserTimeZoneDateWithoutTimezoneFormate,
  convertUTCDateToUserLoginTimeZoneViewDateFormate,
  convertUTCDateToUserLoginTimeZoneDate,
  getLatLongNavigationMapData,
  getActiveMenu,
  getAddressLine,
  getAdminPanelActiveMenu,
  // googleMapOpenUrl,
  getBrowserInfo,
};
