import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { Component } from 'react';
import { TextValidator } from 'react-material-ui-form-validator';
import { withRouter } from 'react-router-dom';
import { Col, Label, Row } from 'reactstrap';
import Utils from '../../../provider/Utils';
import store from '../../../redux/store';
import PtsModal from '../modal/PtsModal';

export class NewMember extends Component {
  title = 'Add Family Member';

  countryList = store.getState().staticData.countryList;
  roleList = store.getState().staticData.profileList;
  relationTypeList = store.getState().staticData.relationTypeList;
  relationWithMemberList = store.getState().staticData.relationWithMemberList;
  memberDetails = store.getState().memberData.memberData;
  header = store.getState().header.header;

  constructor(props) {
    super(props);
    let action = props.action;
    let title = '';
    let pageId = props.pageId;
    let pathUrl = props.pathUrl;
    if (
      null !== props.history &&
      props.history !== undefined &&
      props.history.location !== null &&
      props.history.location.state !== null &&
      props.history.location.state !== undefined
    ) {
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
      title = props.history.location.state.title;
    }
    let canRender = true;
    let memberName = '';
    let isShowRole = false;
    if (props.pageId === 'addFamilyMember') {
      isShowRole = true;
    }
    if (pathUrl === 'home') {
      memberName = store.getState().userData.userData.memberName;
    } else {
      memberName =
        store.getState().memberData.memberData.firstName + ' ' + store.getState().memberData.memberData.lastName;
    }
    this.state = {
      memberName: memberName,
      pageId: pageId,
      memberId: '',
      title: title,
      action: action,
      render: canRender,
      fname: '',
      mname: '',
      lname: '',
      isShowRole: isShowRole,
      email: '',
      mobile: '',
      description: '',
      memberData: '',
      selectedRelation: {},
      onChange: this.props.onChange,
      onBlur: this.props.onBlur,
      salutationList: [
        { id: '1', salutation: 'Mr' },
        { id: '2', salutation: 'Miss' },
        { id: '3', salutation: 'Mrs' },
      ],
      salutation: {},
      role: {},
      familyMemberComp: {},
      profileInfoModel: false,
    };
  }

  handleSalutationChange(selectedSalutation) {
    this.state.salutation = selectedSalutation;
    this.setState({
      salutation: selectedSalutation,
    });
    if (undefined !== this.state.onChange && null !== this.state.onChange) {
      this.state.onChange(this.state);
    }
  }

  handleRoleChange(selectedRole) {
    this.state.role = selectedRole;
    this.setState({
      role: selectedRole,
    });
    if (undefined !== this.state.onChange && null !== this.state.onChange) {
      this.state.onChange(this.state);
    }
  }

  handleFnameChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 128);

    if (res.length < 128) {
      this.setState({ fname: res });
    } else {
      Utils.maxFieldLength(128);
    }
  }

  handleLnameChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 128);

    if (res.length < 128) {
      this.setState({ lname: res });
    } else {
      Utils.maxFieldLength(128);
    }
  }

  handleEmailChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);

    if (res.length < 64) {
      this.setState({ email: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }

  handleMobileChange({ target: { value } }) {
    this.setState((prevState) => ({
      mobile: Utils.convertPhoneNumberToUsaPhoneNumberFormate(value, prevState.mobile),
    }));
  }

  handleAddressChange(inputAddress) {
    this.setState({ address: inputAddress });
  }
  onBlurEmailChange(e) {}
  onBlurMobileChange(e) {}
  render() {
    if (this.state.render === false) {
      return <div />;
    }
    if (this.state.action === 'create') {
      return this.createNewMember();
    }
    if (this.state.action === 'withoutEmail') {
      return this.createNewMemberWithoutEmail();
    }
  }
  profileInfoModalOpen = () => {
    this.setState({
      profileInfoModel: true,
    });
  };
  profileInfoModelToggle = () => {
    this.setState({
      profileInfoModel: !this.state.profileInfoModel,
    });
  };
  createNewMember() {
    if (this.state.render === false) {
      return <div />;
    } else
      return (
        <>
          <Row style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
            <Col>
              <Label style={{ fontWeight: '600', color: '#1b7189', marginLeft: '0.5em' }}>{this.props.title}</Label>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <TextValidator
                fullWidth
                required
                margin="normal"
                variant="outlined"
                label="Email"
                type="email"
                inputProps={{ maxLength: 64 }}
                value={this.state.email}
                onChange={this.handleEmailChange.bind(this)}
                onBlur={this.props.onBlur}
                autoComplete="off"
                validators={['required', 'isEmail']}
                errorMessages={['this field is required', 'email is not valid']}
              />
            </Col>
            <Col md={6}>
              <TextField
                margin="normal"
                variant="outlined"
                fullWidth
                id="Phone"
                type="text"
                onChange={this.handleMobileChange.bind(this)}
                label="Phone"
                name="Phone"
                validators={['required', 'isNumber']}
                errorMessages={['this field is required', 'phone is not valid']}
                autoComplete="off"
                value={this.state.mobile}
                // onBlur={this.props.onBlur}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <TextField
                margin="normal"
                variant="outlined"
                required
                fullWidth
                id="fname"
                onChange={this.handleFnameChange.bind(this)}
                inputProps={{ maxLength: 128, style: { textTransform: 'capitalize' } }}
                label="First Name"
                name="fname"
                value={this.state.fname}
                onBlur={() => {
                  this.props.onChange(this.state);
                }}
                autoComplete="off"
              />
            </Col>

            <Col md={6}>
              <TextField
                margin="normal"
                variant="outlined"
                required
                fullWidth
                id="lname"
                onChange={this.handleLnameChange.bind(this)}
                inputProps={{ maxLength: 128, style: { textTransform: 'capitalize' } }}
                label="Last Name"
                name="lname"
                value={this.state.lname}
                onBlur={() => {
                  this.props.onChange(this.state);
                }}
                autoComplete="off"
              />
            </Col>
          </Row>
          <Row>
            {this.state.isShowRole && (
              <>
                <Col md={6}>
                  <Autocomplete
                    id="Role"
                    options={this.roleList}
                    value={this.state.role}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      this.handleRoleChange(value);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Profile" margin="normal" required />
                    )}
                  />
                </Col>
                <Col md={6} style={{ margin: 'auto' }}>
                  <span>
                    {' '}
                    <i
                      className="fa fa-info-circle"
                      id="menuIconStyle"
                      style={{ color: '#4f5d7c', cursor: 'pointer' }}
                      onClick={this.profileInfoModalOpen}
                    />
                  </span>
                </Col>
              </>
            )}
          </Row>
          <PtsModal
            open={this.state.profileInfoModel}
            onClose={this.profileInfoModelToggle}
            title="Family Care Circle "
          >
            <h5>Profile Information</h5>
            <Row>
              <Col md={12}>
                <ul style={{ listStyle: 'none' }}>
                  <li>
                    {' '}
                    <p>
                      <b style={{ color: '#2B88C7', fontSize: '15px' }}>Authorized Caregiver - </b>
                      {/* </h6>
                    <p> */}{' '}
                      This role has total responsibility for the Circle.They represent the Member “Center of the Circle”
                      with the ability to expand the Circle to other caregivers, assign the roles to other caregivers
                      and delete the circle.Typically this role is assigned to one person when the Member needs someone
                      to represent them.
                    </p>
                  </li>
                  <li>
                    {' '}
                    <p>
                      <b style={{ color: '#2B88C7', fontSize: '15px' }}>Family Manager - </b>
                      This role also has the ability to expand the Circle and has all rights needed to create
                      appointments and communicate with others. Typically this role is assigned to a close relative.
                      This role is very similar to Authorized Caregiver with the exception of the ability to delete the
                      Circle for the Member.
                    </p>
                  </li>
                  <li>
                    {' '}
                    <p>
                      <b style={{ color: '#2B88C7', fontSize: '15px' }}>Family Member - </b>
                      As an important caregiver, they can help manage all appointments. They are restricted from adding
                      others to the Circle. Typically this role is assigned to extended relatives or close friends.
                    </p>
                  </li>
                  <li>
                    {' '}
                    <p>
                      <b style={{ color: '#2B88C7', fontSize: '15px' }}>Transporter - </b>
                      Can only view Appointments/Events for which they are responsible for transportation. Typically
                      this role is assigned to friends that are wanting to help by offering to take the loved one to an
                      appointment. This is the most restrictive role.
                    </p>
                  </li>
                </ul>
              </Col>{' '}
            </Row>
          </PtsModal>
        </>
      );
  }

  createNewMemberWithoutEmail() {
    if (this.state.render === false) {
      return <div />;
    } else
      return (
        <>
          <Row style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
            <Col>
              <Label style={{ fontWeight: '600', color: '#1b7189', marginLeft: '0.5em' }}>{this.props.title}</Label>
            </Col>
          </Row>

          <Row>
            <Col md={6}>
              <TextField
                margin="normal"
                variant="outlined"
                required
                fullWidth
                id="fname"
                onChange={this.handleFnameChange.bind(this)}
                inputProps={{ maxLength: 128, style: { textTransform: 'capitalize' } }}
                label="First Name"
                name="fname"
                value={this.state.fname}
                onBlur={() => {
                  this.props.onChange(this.state);
                }}
                autoComplete="off"
              />
            </Col>

            <Col md={6}>
              <TextField
                margin="normal"
                variant="outlined"
                required
                fullWidth
                id="lname"
                onChange={this.handleLnameChange.bind(this)}
                inputProps={{ maxLength: 128, style: { textTransform: 'capitalize' } }}
                label="Last Name"
                name="lname"
                value={this.state.lname}
                onBlur={() => {
                  this.props.onChange(this.state);
                }}
                autoComplete="off"
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <TextField
                margin="normal"
                variant="outlined"
                fullWidth
                id="Phone"
                type="text"
                onChange={this.handleMobileChange.bind(this)}
                label="Phone"
                name="Phone"
                validators={['required', 'isNumber']}
                errorMessages={['this field is required', 'phone is not valid']}
                autoComplete="off"
                value={this.state.mobile}
                // onBlur={this.props.onBlur}
              />
            </Col>
          </Row>
          <Row>
            {this.state.isShowRole && (
              <>
                <Col md={6}>
                  <Autocomplete
                    id="Role"
                    options={this.roleList}
                    value={this.state.role}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      this.handleRoleChange(value);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Profile" margin="normal" required />
                    )}
                  />
                </Col>
                <Col md={6} style={{ margin: 'auto' }}>
                  <span>
                    {' '}
                    <i
                      className="fa fa-info-circle"
                      id="menuIconStyle"
                      style={{ color: '#4f5d7c', cursor: 'pointer' }}
                      onClick={this.profileInfoModalOpen}
                    />
                  </span>
                </Col>
              </>
            )}
          </Row>
          <PtsModal
            open={this.state.profileInfoModel}
            onClose={this.profileInfoModelToggle}
            title="Family Care Circle "
          >
            <h5>Profile Information</h5>
            <Row>
              <Col md={12}>
                <ul style={{ listStyle: 'none' }}>
                  <li>
                    {' '}
                    <p>
                      <b style={{ color: '#2B88C7', fontSize: '15px' }}>Authorized Caregiver - </b>
                      {/* </h6>
                    <p> */}{' '}
                      This role has total responsibility for the Circle.They represent the Member “Center of the Circle”
                      with the ability to expand the Circle to other caregivers, assign the roles to other caregivers
                      and delete the circle.Typically this role is assigned to one person when the Member needs someone
                      to represent them.
                    </p>
                  </li>
                  <li>
                    {' '}
                    <p>
                      <b style={{ color: '#2B88C7', fontSize: '15px' }}>Family Manager - </b>
                      This role also has the ability to expand the Circle and has all rights needed to create
                      appointments and communicate with others. Typically this role is assigned to a close relative.
                      This role is very similar to Authorized Caregiver with the exception of the ability to delete the
                      Circle for the Member.
                    </p>
                  </li>
                  <li>
                    {' '}
                    <p>
                      <b style={{ color: '#2B88C7', fontSize: '15px' }}>Family Member - </b>
                      As an important caregiver, they can help manage all appointments. They are restricted from adding
                      others to the Circle. Typically this role is assigned to extended relatives or close friends.
                    </p>
                  </li>
                  <li>
                    {' '}
                    <p>
                      <b style={{ color: '#2B88C7', fontSize: '15px' }}>Transporter - </b>
                      Can only view Appointments/Events for which they are responsible for transportation. Typically
                      this role is assigned to friends that are wanting to help by offering to take the loved one to an
                      appointment. This is the most restrictive role.
                    </p>
                  </li>
                </ul>
              </Col>{' '}
            </Row>
          </PtsModal>
        </>
      );
  }
}
export default withRouter(NewMember);
