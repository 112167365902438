import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import React, { Component } from 'react';
import { Button, Col, Row } from 'reactstrap';
import MenuWithBreadScrum from '../../menu/MenuWithBreadScrum';
class Canceled extends Component {
  title = 'Canceled';
  breadCrumb = [
    { pageid: '/profileHome', uiname: 'User Profile Details' },
    { pageid: '/homePricing', uiname: 'Pricing' },
    { pageid: '/purchase_checkout', uiname: 'Checkout' },
    { pageid: '', uiname: 'Canceled' },
  ];
  constructor(props) {
    window.scrollTo(0, 0);
    super(props);
    this.state = {};
  }

  BackToPricing = () => {
    this.props.history.push({
      pathname: '/homePricing',
    });
  };
  render() {
    return (
      <div>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
          domainObject="Canceled"
          headerWidth="home"
        />

        <section id="pricing" className="pricing" style={{ padding: '7em' }}>
          <Row style={{ marginTop: '5em' }}>
            <Col md={12} style={{ marginTop: '4em', textAlign: 'center' }}>
              <h1>Your payment was cancelled</h1>
            </Col>
          </Row>
          <Row>
            <Col md={12} style={{ textAlign: 'center' }}>
              <Button
                style={{
                  background: '#f58d51',
                  fontSize: '0.8em',
                  webkitBorderRadius: '10px',

                  margin: 'auto',
                  marginRight: '5px',
                  borderBottom: '2px solid #636262',
                  color: '#fff',
                }}
                onClick={this.BackToPricing.bind(this)}
              >
                <ArrowBackIcon style={{ fontSize: '1.3em' }} /> Back To Pricing
              </Button>
            </Col>
          </Row>
        </section>
      </div>
    );
  }
}

export default Canceled;
