import {
  CLEAR_COMMUNICATION_THREAD,
  GET_COMMUNICATION_THREAD,
  SET_COMMUNICATION_THREAD,
} from './communicationThreadsTypes';

const getCommunicationThreads = () => {
  return {
    type: GET_COMMUNICATION_THREAD,
  };
};

const setCommunicationThreads = (data) => {
  return {
    type: SET_COMMUNICATION_THREAD,
    payload: data,
  };
};

const clearCommunicationThreads = (data) => {
  return {
    type: CLEAR_COMMUNICATION_THREAD,
    payload: data,
  };
};
const communicationThreadAction = { getCommunicationThreads, setCommunicationThreads, clearCommunicationThreads };
export default communicationThreadAction;
