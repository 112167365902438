import Button from '@material-ui/core/Button';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import moment from 'moment';
import React, { Component } from 'react';
import { Card, Col, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import store from '../../../redux/store';
import MemberAppointment from '../appointment/MemberAppointment';
import BackButton from '../buttons/BackButton';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
import './appointmentHierarchy.scss';
export class appointmentHierarchy extends Component {
  header = store.getState().header.header;

  memberDetails = store.getState().memberData.memberData;
  memberName = store.getState().memberData.memberData.firstname + ' ' + store.getState().memberData.memberData.lastname;
  breadCrumb = [
    { pageid: '/memberDetails', uiname: this.memberName },
    { pageid: '/memberAppointment', uiname: 'Appointment List' },
    { pageid: '/viewMemberAppointment', uiname: 'Member Appointment' },
    { pageid: '', uiname: 'Appointment Trails' },
  ];
  treeItems = [];

  constructor(props) {
    super(props);

    let action = props.action;
    let title = '';
    let pageId = '';
    let appointmentId = '';
    let canRender = false;
    if (!appointmentId && props.history && props.history.location && props.history.location.state) {
      appointmentId = props.history.location.state.appointmentId;
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
      title = props.history.location.state.title;
    }
    if (null !== appointmentId && appointmentId > 0) {
      this.getAppointmentHierarchy(appointmentId);
    } else {
      canRender = true;
    }

    this.state = {
      id: appointmentId,
      appointmentType: '',
      startDate: '',
      appoiPurpose: '',
      doctor: '',
      facilityLocation: '',
      description: '',
      treeItems: [],
      action: action,
      render: canRender,
      modal: false,
      title: title,
      AppointmentTreeNodeId: '',
      expandedNodes: [],
      selectedRow: null,
      setSelectedRow: null,
      open: true,
      setopen: false,
      TreeItemId: [],
      collapseTreeItem: [],
      pageId: pageId,
      // expanded: true,
    };
    this.originalState = this.state;
  }

  getAppointmentHierarchy(appointmentId) {
    const postObject = {
      header: this.header,
      appointment: {
        id: appointmentId,
      },
    };
    FetchServerData.callPostService('appointmentms/getAppointmentHierachy', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let acceptMemberData = output.data;

        let AppointmentTree = [];
        AppointmentTree.push(acceptMemberData);

        this.setState({
          render: true,
          treeItems: AppointmentTree,
        });
      } else {
      }
    });
  }

  handleChange = (nodes, e) => {
    this.setState({
      expanded: nodes,
    });
  };

  showAppointment(e, data) {
    let appointmentDataId = data;
    this.setState({
      modal: true,
      AppointmentTreeNodeId: appointmentDataId,
    });
  }
  getLabelData = (labelData) => {
    return (
      <tr
        onClick={(e) => this.showAppointment(e, labelData.id)}
        className="labelData"
        style={{
          color: this.state.id === labelData.id ? '#148F77' : '',
          fontWeight: this.state.id === labelData.id ? 'bold' : '',
        }}
      >
        <td>{labelData.date && <> {moment(labelData.date).format('DD MMM YYYY hh:mma')}</>}</td>
        <td>{labelData.type && <> {labelData.type}</>}</td>
        <td>{labelData.purpose && <> {labelData.purpose}</>}</td>
        <td>{labelData.doctorFacilityLocation && <>{labelData.doctorFacilityLocation}</>}</td>
        <td>{labelData.status && <>{labelData.status}</>}</td>
      </tr>
    );
  };

  getTreeItemsFromData = (treeItems) => {
    return treeItems.map((treeItemData) => {
      let childAppointmentList = undefined;
      if (treeItemData.childAppointmentList && treeItemData.childAppointmentList.length > 0) {
        childAppointmentList = this.getTreeItemsFromData(treeItemData.childAppointmentList);
      }

      this.selectedTreeNode = treeItemData.id;
      this.state.TreeItemId.push(treeItemData.id);

      return (
        <TreeItem
          onClick={(e) => this.onNodeClick(treeItemData.id)}
          className="treeItemsChildData"
          style={{ padding: '1em' }}
          key={treeItemData.id}
          nodeId={treeItemData.id}
          label={
            <table>
              <tbody>{this.getLabelData(treeItemData)}</tbody>
            </table>
          }
          children={childAppointmentList}
        ></TreeItem>
      );
    });
  };

  backToAppointmentDetails = () => {
    this.props.history.push({
      pathname: '/viewMemberAppointment',
      state: { action: 'view', title: 'Appointment Details', appointmentId: this.state.id },
    });
  };

  toggle = () => {
    this.setState({ modal: !this.state.modal });
  };
  setOpenIcon = () => {
    this.setState({ open: !this.state.open });
  };
  onNodeClick = (nodeIds) => {
    this.setState({
      TreeItemId: [],
    });
  };
  handleToggle = (event, nodeIds) => {
    this.setState({
      collapseTreeItem: [],
    });
  };

  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      this.tableRef = React.createRef();
      return (
        <div>
          <MenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            name={'Appointment Trails For' + ' ' + this.memberName}
            domainObject="Appointment"
          />
          <Row style={{ margin: '1em' }}>
            <Col md={12} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              <Card>
                <TreeView
                  defaultCollapseIcon={<ExpandMoreIcon />}
                  defaultExpandIcon={<ChevronRightIcon />}
                  expanded={this.state.TreeItemId}
                  collapsed={this.state.collapseTreeItem}
                  onNodeToggle={this.handleToggle}
                >
                  {this.getTreeItemsFromData(this.state.treeItems)}
                </TreeView>
              </Card>
            </Col>
          </Row>
          <div style={{ textAlign: 'center', padding: '0em 0em 1em' }}>
            <BackButton
              type="button"
              style={{ backgroundColor: '#162c50', color: '#fff' }}
              variant="contained"
              startIcon={<CloseIcon />}
              onClick={(e) => this.backToAppointmentDetails()}
            >
              Back
            </BackButton>
          </div>

          <Modal isOpen={this.state.modal} toggle={this.toggle} size="xl">
            <ModalHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }} toggle={this.toggle}>
              <Label style={{ fontWeight: '600', color: '#162c50' }}>Appointment Details</Label>
            </ModalHeader>
            <ModalBody style={{ backgroundColor: '#F2F4F4' }}>
              <div id="printform" style={{ overflowY: 'scroll' }}>
                <MemberAppointment
                  action="printAppointment"
                  title="Appointment Details"
                  appointmentId={this.state.AppointmentTreeNodeId}
                  isPrintView="view_print"
                  pageId="view"
                />
              </div>
            </ModalBody>

            <div style={{ textAlign: 'center' }}>
              <Button
                type={this.state.type}
                variant="contained"
                size="small"
                style={{ background: '#717373', color: '#fff' }}
                onClick={this.toggle.bind(this)}
              >
                Close
              </Button>{' '}
            </div>
          </Modal>
        </div>
      );
    }
  }
}

export default appointmentHierarchy;
