/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import { useLocation, Route, Switch } from 'react-router-dom';
// reactstrap components
import { Container, Row } from 'reactstrap';
import './Auth.scss';
import routes from '../../views';

const Auth = (props) => {
  const mainContent = React.useRef(null);
  const location = useLocation();

  React.useEffect(() => {
    document.body.classList.add('loginLayoutBackground');
    return () => {
      document.body.classList.remove('loginLayoutBackground');
    };
  }, []);
  React.useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
  }, [location]);

  return (
    <>
      <div className="main-content" ref={mainContent} id="loginLayoutBackground">
        {/* Page content */}
        <Container>
          <Row>
            {/* <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to="/auth/login" />
            </Switch> */}
            <Switch>
              {/* <Redirect from="*" to="/login" /> */}
              {routes.map((page, key) => (
                <Route path={page.path} component={page.component} key={key} />
              ))}
            </Switch>
          </Row>
        </Container>
      </div>
    </>
  );
};

export default Auth;
