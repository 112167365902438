import { CLEAR_PREMIUM_FEATURE, GET_PREMIUM_FEATURE, SET_PREMIUM_FEATURE } from './PremiumFeatureType';

const initialState = {
  premiumFeatures: {},
};

const premiumFeaturesReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PREMIUM_FEATURE:
      return {
        ...state,
      };
    case SET_PREMIUM_FEATURE:
      return {
        ...state,

        premiumFeatures: action.payload,
      };

    case CLEAR_PREMIUM_FEATURE:
      return {
        ...state,

        PremiumFeatures: null,
      };
    default:
      return state;
  }
};

export default premiumFeaturesReducer;
