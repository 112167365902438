import { combineReducers } from 'redux';
import adminFccPageStateReducer from './adminPanel/adminFccPageState/adminFccPageStateReducer';
import UserProfileReducer from './adminPanel/adminProfile/UserProfileReducer';
import adminAppUserDataReducer from './adminPanel/appUserData/adminAppUserDataReducer';
import adminHeaderReducer from './adminPanel/header/adminHeaderReducer';
import paidAdvertiserDetailsReducer from './adminPanel/paidAdvertiserDetails/paidAdvertiserDetailsReducer';
import profileManagementReducer from './adminPanel/profileManagement/ProfileManagementReducer';
import sponsorDetailsReducer from './adminPanel/sponsorDetails/sponsorDetailsReducer';
import sponsorUserReducer from './adminPanel/SponsorUser/sponsorUserReducer';
import adminStaticDataReducer from './adminPanel/staticData/adminStaticDataReducer';
import applicationStateReducer from './applicationState/applicationStateReducer';
import appointmentDataReducer from './appointmentData/appointmentDataReducer';
import appointmentStateReducer from './appointmentState/appointmentStateReducer';
import appUserReducer from './appUser/appUserReducer';
import userDataReducer from './appUserData/appUserDataReducer';
import CommercialsReducer from './commercials/CommercialsReducer';
import communicationThreadsReducer from './communicationThreads/communicationThreadsReducer';
import consolidatedMemberReducer from './consolidatedMemberDetails/consolidatedMemberReducer';
import doctorFacilityStateReducer from './doctorFacilityApplicationState/doctorFacilityStateReducer';
import familyCircleReducer from './familyCircle/familyCircleReducer';
import fccAddressStateReducer from './fccAddressState/fccAddressStateReducer';
import fccPageStateReducer from './fccPageState/fccPageStateReducer';
import headerReducer from './header/headerReducer';
import displayHelpReducer from './help/displayHelpReducer';
import memberCommunicationStateReducer from './memberCommunicationState/memberCommunicationStateReducer';
import memberDataReducer from './memberData/memberDataReducer';
import selectedMemberDetailsReducer from './selectedMemberDetails/selectedMemberDetailsReducer';
import selectedPlanReducer from './selectedPlan/selectedPlanReducer';
import SponsorshipReducer from './sponsorship/SponsorshipReducer';
import staticDataReducer from './staticData/staticDataReducer';
import userSubscribePlanReducer from './userSubscribePlan/userSubscribePlanReducer';
import memberHomeUrlReducer from './memberHomeUrl/memberHomeUrlReducer';
import memberListReducer from './memberList/memberListReducer';
import premiumFeaturesReducer from './premiumFeatureDetails/PremiumFeatureReducer';
import ActiveMenuReducer from './activeMenu/ActiveMenuReducer';
import BreadCrumbDetailsReducer from './breadCrumbDetails/BreadCrumbDetailsReducer';

const appReducer = combineReducers({
  staticData: staticDataReducer,
  header: headerReducer,
  appUserReducer: appUserReducer,
  memberData: memberDataReducer,
  userData: userDataReducer,
  appointmentData: appointmentDataReducer,
  messageThreadsData: communicationThreadsReducer,
  selectedPlanData: selectedPlanReducer,
  appointmentState: appointmentStateReducer,
  memberCommunicationState: memberCommunicationStateReducer,
  familyCircleState: familyCircleReducer,
  applicationState: applicationStateReducer,
  Sponsorship: SponsorshipReducer,
  help: displayHelpReducer,
  fccPageState: fccPageStateReducer,
  userSubscribePlan: userSubscribePlanReducer,
  selectedMemberData: selectedMemberDetailsReducer,
  fccAddressState: fccAddressStateReducer,
  adminHeader: adminHeaderReducer,
  adminStaticData: adminStaticDataReducer,
  adminSponsorDetails: sponsorDetailsReducer,
  adminSponsorUser: sponsorUserReducer,
  adminUserDetails: adminAppUserDataReducer,
  adminFccPageState: adminFccPageStateReducer,
  paidAdvertiserDetails: paidAdvertiserDetailsReducer,
  profileDetails: profileManagementReducer,
  commercialsDetails: CommercialsReducer,
  doctorFacilityAppointmentState: doctorFacilityStateReducer,
  consolidatedMemberDetails: consolidatedMemberReducer,
  userProfile: UserProfileReducer,
  memberHomeUrl: memberHomeUrlReducer,
  memberList: memberListReducer,
  premiumFeatures: premiumFeaturesReducer,
  activeMenu: ActiveMenuReducer,
  breadCrumbDetails: BreadCrumbDetailsReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'RESET_ACTION' || action.type === 'USER_LOGOUT') {
    state = undefined;
  }

  return appReducer(state, action);
};
export default rootReducer;
