import React, { Component } from 'react';

class FccAdvertisement extends Component {
  constructor(props) {
    super(props);
    this.state = {
      render: true,
      advertisementType: props.advertisementType,
    };
  }

  componentDidMount() {
    const installGoogleAds = () => {
      const elem = document.createElement('script');
      elem.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
      elem.async = true;
      elem.defer = true;
      document.body.insertBefore(elem, document.body.firstChild);
    };
    installGoogleAds();
    (window.adsbygoogle = window.adsbygoogle || []).push({});
  }
  render() {
    if (this.state.advertisementType === 'Vertical') {
      return this.verticalAdvertisement();
    } else if (this.state.advertisementType === 'Horizontal') {
      return this.horizontalAdvertisement();
    } else if (this.state.advertisementType === 'Square') {
      return this.squareAdvertisement();
    } else {
      return this.horizontalAdvertisement();
    }
  }

  verticalAdvertisement() {
    return (
      <ins
        key="VerticalAds"
        className="adsbygoogle"
        style={{ display: 'inline-block', height: '760px', width: '120px', margin: 'auto' }}
        data-ad-client="ca-pub-1294083358269513"
        data-ad-slot="5132174771"
        data-full-width-responsive="true"
      />
    );
  }

  horizontalAdvertisement() {
    return (
      <ins
        key="horizontalAds"
        className="adsbygoogle"
        style={{ display: 'block', width: '760px', height: '100px', margin: 'auto' }}
        data-ad-client="ca-pub-1294083358269513"
        data-ad-slot="9809786381"
        data-full-width-responsive="true"
      />
    );
  }

  squareAdvertisement() {
    return (
      <ins
        key="squareAds"
        className="adsbygoogle"
        style={{ display: 'block', height: '100% !important', width: '100% !important', margin: 'auto' }}
        data-ad-client="ca-pub-1294083358269513"
        data-ad-slot="6062113067"
        data-full-width-responsive="true"
      />
    );
  }
}

export default FccAdvertisement;
