import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import PinDropIcon from '@material-ui/icons/PinDrop';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import React, { Component } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Card, CardBody, CardFooter, CardHeader, Col, Label, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import helpDataAction from '../../../redux/help/displayHelpAction';
import store from '../../../redux/store';
import BackButton from '../buttons/BackButton';
import CancelIconButton from '../buttons/CancelIconButton';
import EditIconButton from '../buttons/EditIconButton';
import SaveButton from '../buttons/SaveButton';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
import PtsAlert from '../ptsAlert/PtsAlert';
import PtsModal from '../modal/PtsModal';
import BreadCrumbDetailsAction from '../../../redux/breadCrumbDetails/BreadCrumbDetailsAction';
export default class FamilyCircle extends Component {
  countryListArray = store.getState().staticData.countryList;
  roleList = store.getState().staticData.profileList;
  roleAccessList = store.getState().staticData.roleAccessList;
  relationTypeList = store.getState().staticData.relationTypeList;
  relationWithMemberList = store.getState().staticData.relationWithMemberList;
  contactTypeList = store.getState().staticData.contactTypeList;
  memberDetails = store.getState().memberData.memberData;
  memberName = store.getState().memberData.memberData.firstname + ' ' + store.getState().memberData.memberData.lastname;
  header = store.getState().header.header;
  isFreeUser = store.getState().userData.userData.isFreeUser;
  loginMemberId = store.getState().userData.userData.memberId;
  displayHelp = store.getState().help.help;
  fccPageState = store.getState().fccPageState.fccPageState;
  title = 'Caregiver Details';
  addressRef = React.createRef();
  loginUserName = store.getState().userData.userData.firstName + ' ' + store.getState().userData.userData.lastName;
  memberHomeUrl = store.getState().memberHomeUrl.memberHomeUrl.homeUrl;
  breadCrumb = [
    { pageid: this.memberHomeUrl, uiname: this.memberName },
    { pageid: '/familyCircle', uiname: 'Caregiver Circle List' },
    { pageid: '/viewFamilyMember', uiname: 'Caregiver Details' },
  ];

  constructor(props) {
    super(props);
    // debugger;
    let membershipId = props.membershipId;
    let action = props.action;
    let title = '';
    let pageId = '';
    let createFamilyMemberData = {};
    if (!membershipId && props.history && props.history.location && props.history.location.state) {
      membershipId = props.history.location.state.membershipId;
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
      title = props.history.location.state.title;
      createFamilyMemberData = props.history.location.state.familyMemberData;
    }
    let canRender = false;
    if (null !== membershipId && membershipId > 0) {
      this.getFamilyCircleDetails(membershipId);
    } else {
      canRender = true;
    }

    if (membershipId === undefined) {
      membershipId = this.fccPageState.id;
      action = this.fccPageState.action;
      if (action !== 'create') {
        this.getFamilyCircleDetails(membershipId);
      }
    }
    if (pageId === 'createFamilyMember') {
      let familyMemberDetails = createFamilyMemberData.familyMember;
      let familyMemberAddress = familyMemberDetails.address;
      let getPermission = Utils.getRolePermission(createFamilyMemberData.privileges);

      let getAllPermission = getPermission[0];
      let getCreatePermission = getPermission[1];
      let getReadPermission = getPermission[2];
      let getUpdatePermission = getPermission[3];
      let getDeletePermission = getPermission[4];
      let appstatus = familyMemberDetails.familyMemberMemberStatus.status;
      let memberStatus = '';
      if (appstatus === 'Active') {
        memberStatus = true;
      } else {
        memberStatus = false;
      }
      // if (this.breadCrumb) {
      //   Utils.getActiveMenu(this.breadCrumb);
      //   store.dispatch(BreadCrumbDetailsAction.setBreadCrumbDetails(this.breadCrumb));
      // }
      if (familyMemberAddress) {
        this.state = {
          render: canRender,
          pageId: pageId,
          title: title,
          action: action,
          getAllPermission: getAllPermission,
          getUpdatePermission: getUpdatePermission,
          getDeletePermission: getDeletePermission,
          getReadPermission: getReadPermission,
          getCreatePermission: getCreatePermission,
          editFamilyMemberDetails: false,
          viewFamilyMemberDetails: true,
          id: familyMemberDetails.id,
          fname: familyMemberDetails.firstName,
          lname: familyMemberDetails.lastName,
          requestStatus: familyMemberDetails.familyMemberMemberStatus.status,
          email: familyMemberDetails.email,
          mobile: Utils.displayUsaPhoneNumberFormate(familyMemberDetails.phone),
          description: familyMemberDetails.description,
          selectedRelation: familyMemberDetails.relationWithMember,
          role: familyMemberDetails.relationRole,
          status: familyMemberDetails.familyMemberMemberStatus.status,
          familyMemberMemberId: familyMemberDetails.familyMemberMemberId,
          isStatus: memberStatus,
          address: familyMemberAddress,
          isFreeUser: this.isFreeUser,
          stepsEnabled: false,
          initialStep: 0,
          loginMemberId: this.loginMemberId !== null ? this.loginMemberId : '',
          steps: [
            {
              element: '.editDetails',
              intro: 'Edit/Update Details of Caregiver',
            },

            {
              element: '.delete',
              intro: 'Delete Caregiver. You can add them back (if accidentally deleted) by sending them request again.',
            },
            {
              element: '.statusMember',
              intro:
                'Status can be changed in Edit mode.. Active Caregiver can add/Update Appointments/Doctors/Facilities, request/accompany Transportation, be part of Communication. Inactive Caregiver won’t be able to view/update any activity of your Circle.',
            },
            {
              element: '.memberRole',
              intro:
                'Role of Caregiver can be of Admin/FamilyMember. Admin has equal rights as User. FamilyMember has limited rights to read Appointments, accompany Transportation. Role can be changed in Edit mode.',
            },
          ],
          displayFloatButton: this.displayHelp,
          profileInfoModel: false,
        };
      } else {
        this.state = {
          render: canRender,
          pageId: pageId,
          title: title,
          action: action,

          editFamilyMemberDetails: false,
          viewFamilyMemberDetails: true,
          id: familyMemberDetails.id,
          fname: familyMemberDetails.firstName,
          lname: familyMemberDetails.lastName,
          familyMemberMemberId: familyMemberDetails.familyMemberMemberId,
          requestStatus: familyMemberDetails.familyMemberMemberStatus.status,
          role: familyMemberDetails.relationRole,
          email: familyMemberDetails.email,
          mobile: Utils.displayUsaPhoneNumberFormate(familyMemberDetails.phone),
          description: familyMemberDetails.description,
          selectedRelation: familyMemberDetails.relationWithMember,
          status: familyMemberDetails.familyMemberMemberStatus.status,
          isStatus: memberStatus,
          address: {},
          isFreeUser: this.isFreeUser,
          getAllPermission: getAllPermission,
          getUpdatePermission: getUpdatePermission,
          getDeletePermission: getDeletePermission,
          getReadPermission: getReadPermission,
          getCreatePermission: getCreatePermission,
          stepsEnabled: false,
          initialStep: 0,
          loginMemberId: this.loginMemberId !== null ? this.loginMemberId : '',
          steps: [
            {
              element: '.editDetails',
              intro: 'Edit/Update Details of Caregiver',
            },

            {
              element: '.delete',
              intro: 'Delete Caregiver. You can add them back (if accidentally deleted) by sending them request again.',
            },
            {
              element: '.statusMember',
              intro:
                'Status can be changed in Edit mode.. Active Caregiver can add/Update Appointments/Doctors/Facilities, request/accompany Transportation, be part of Communication. Inactive Caregiver won’t be able to view/update any activity of your Circle.',
            },
            {
              element: '.memberRole',
              intro:
                'Role of Caregiver can be of Admin/FamilyMember. Admin has equal rights as User. FamilyMember has limited rights to read Appointments, accompany Transportation. Role can be changed in Edit mode.',
            },
          ],
          displayFloatButton: this.displayHelp,
          roleList: this.roleList,
          profileInfoModel: false,
        };
      }
    } else {
      this.state = {
        pageId: pageId,
        id: membershipId,
        title: title,
        render: canRender,
        action: action,
        membershipId: '',
        value: '',
        address: {},
        fname: '',
        mname: '',
        lname: '',
        email: '',
        mobile: '',
        description: '',
        memberData: '',
        status: 'Active',
        selectedRelation: {},
        onChange: this.props.onChange,
        getAllPermission: false,
        getUpdatePermission: false,
        getDeletePermission: false,
        getReadPermission: false,
        getCreatePermission: false,
        appuserId: '',
        memberType: '',
        memberId: this.memberDetails.member,
        checked: true,
        isStatus: true,
        editFamilyMemberDetails: false,
        viewFamilyMemberDetails: false,
        requestStatus: '',
        familyMemberMemberId: '',
        role: {},
        isFreeUser: this.isFreeUser,
        editUnRegisterFamilyMember: false,
        viewUnRegisterFamilyMember: false,
        stepsEnabled: false,
        initialStep: 0,
        loginMemberId: this.loginMemberId !== null ? this.loginMemberId : '',
        steps: [
          {
            element: '.editDetails',
            intro: 'Edit/Update Details of Caregiver',
          },

          {
            element: '.delete',
            intro: 'Delete Caregiver. You can add them back (if accidentally deleted) by sending them request again.',
          },
          {
            element: '.statusMember',
            intro:
              'Status can be changed in Edit mode.. Active Caregiver can add/Update Appointments/Doctors/Facilities, request/accompany Transportation, be part of Communication. Inactive Caregiver won’t be able to view/update any activity of your Circle.',
          },
          {
            element: '.memberRole',
            intro:
              'Role of Caregiver can be of Admin/FamilyMember. Admin has equal rights as User. FamilyMember has limited rights to read Appointments, accompany Transportation. Role can be changed in Edit mode.',
          },
        ],
        displayFloatButton: this.displayHelp,
        roleList: this.roleList,
        profileInfoModel: false,
      };
    }
  }

  toggleSteps = () => {
    this.setState((prevState) => ({ stepsEnabled: !prevState.stepsEnabled }));
  };

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };
  profileInfoModalOpen = () => {
    this.setState({
      profileInfoModel: true,
    });
  };
  profileInfoModelToggle = () => {
    this.setState({
      profileInfoModel: !this.state.profileInfoModel,
    });
  };
  getFamilyCircleDetails(membershipId) {
    const postObject = {
      header: this.header,

      familyMember: {
        id: membershipId,
        memberId: this.memberDetails.member,
      },
    };
    FetchServerData.callPostService('/caregiverms/get', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let familyMemberData = output.data.familyMember;
        let familyMemberAddress = familyMemberData.address;

        let getDomainObjectPrivileges = output.data.domainObjectPrivileges;

        let getCreatePermission = false;
        let getReadPermission = false;
        let getUpdatePermission = false;
        let getDeletePermission = false;

        for (let domainObjectKey in getDomainObjectPrivileges) {
          if (domainObjectKey.toLowerCase() === 'caregiver') {
            let familyMemberPermission = getDomainObjectPrivileges[domainObjectKey];
            let getPermission = Utils.getDomainObjectRolePermission(familyMemberPermission);
            getCreatePermission = getPermission[0];
            getReadPermission = getPermission[1];
            getUpdatePermission = getPermission[2];
            getDeletePermission = getPermission[3];
          }
        }
        let appstatus = familyMemberData.familyMemberMemberStatus.status;
        let memberStatus = '';
        if (appstatus === 'Active') {
          memberStatus = true;
        } else {
          memberStatus = false;
        }

        if (familyMemberAddress) {
          this.setState({
            render: true,
            editFamilyMemberDetails: false,
            viewFamilyMemberDetails: true,
            editUnRegisterFamilyMember: false,
            viewUnRegisterFamilyMember: true,
            id: familyMemberData.id,
            fname: familyMemberData.firstName,
            lname: familyMemberData.lastName,
            requestStatus: familyMemberData.familyMemberMemberStatus.status,
            email: familyMemberData.email,
            mobile: Utils.displayUsaPhoneNumberFormate(familyMemberData.phone),
            description: familyMemberData.description,
            selectedRelation: familyMemberData.relationWithMember,
            role: familyMemberData.relationRole,
            status: familyMemberData.familyMemberMemberStatus.status,
            familyMemberMemberId: familyMemberData.familyMemberMemberId,
            isStatus: memberStatus,
            address: familyMemberAddress,

            getUpdatePermission: getUpdatePermission,
            getDeletePermission: getDeletePermission,
            getReadPermission: getReadPermission,
            getCreatePermission: getCreatePermission,
          });
        } else {
          this.setState({
            render: true,
            editFamilyMemberDetails: false,
            viewFamilyMemberDetails: true,
            editUnRegisterFamilyMember: false,
            viewUnRegisterFamilyMember: true,
            id: familyMemberData.id,
            fname: familyMemberData.firstName,
            lname: familyMemberData.lastName,
            familyMemberMemberId: familyMemberData.familyMemberMemberId,
            requestStatus: familyMemberData.familyMemberMemberStatus.status,
            role: familyMemberData.relationRole,
            email: familyMemberData.email,
            mobile: Utils.displayUsaPhoneNumberFormate(familyMemberData.phone),
            description: familyMemberData.description,
            selectedRelation: familyMemberData.relationWithMember,
            status: familyMemberData.familyMemberMemberStatus.status,
            isStatus: memberStatus,
            address: {},
            getUpdatePermission: getUpdatePermission,
            getDeletePermission: getDeletePermission,
            getReadPermission: getReadPermission,
            getCreatePermission: getCreatePermission,
          });
        }
      } else {
      }
    });
  }

  viewCancel = () => {
    this.props.history.push({
      pathname: '/familyCircle',
    });
  };

  editCancel = (e, membershipId) => {
    this.props.history.push({
      pathname: '/viewFamilyMember',
      state: { action: 'view', title: ' Caregiver Details ', membershipId: membershipId },
    });
  };

  handleFamilyMemberChange(familyMemberDetails) {
    this.setState({ familyMemberComp: familyMemberDetails });
  }

  handleAddressChange(address) {
    this.setState({ address: address });
  }
  handleRoleChange(selectedRole) {
    this.state.role = selectedRole;
    this.setState({
      role: selectedRole,
    });
    if (undefined !== this.state.onChange && null !== this.state.onChange) {
      this.state.onChange(this.state);
    }
  }

  handleRelationsChange(selectedRelation) {
    this.state.selectedRelation = selectedRelation;
    this.setState({
      selectedRelation: selectedRelation,
    });
    if (undefined !== this.state.onChange && null !== this.state.onChange) {
      this.state.onChange(this.state);
    }
  }

  handleFnameChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 128);
    if (res.length < 128) {
      this.setState({ fname: res });
    } else {
      Utils.maxFieldLength(128);
    }
  }

  handleLnameChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 128);
    if (res.length < 128) {
      this.setState({ lname: res });
    } else {
      Utils.maxFieldLength(128);
    }
  }

  handleEmailChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ email: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }
  handleMobileChange({ target: { value } }) {
    this.setState((prevState) => ({
      mobile: Utils.convertPhoneNumberToUsaPhoneNumberFormate(value, prevState.mobile),
    }));
  }

  handleDescriptionChange(e) {
    this.setState({ description: e.target.value });
  }

  handleStatusChange(e) {
    this.setState({ isStatus: !this.state.isStatus });
  }

  deleteFamilyMember = () => {
    const postObject = {
      header: this.header,
      familyMember: {
        id: this.state.id,
        memberId: this.memberDetails.member,
      },
    };

    FetchServerData.callPostService('/caregiverms/delete', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let memberDetails = output.data.familyMember;
          if (this.state.loginMemberId === memberDetails.familyMemberId) {
            this.props.history.push({
              pathname: '/home',
            });
          } else {
            this.props.history.push({
              pathname: '/familyCircle',
            });
          }
        } else {
        }
      })
      .catch((error) => {});
  };

  deleteUnregisterFamilyMember = () => {
    const postObject = {
      header: this.header,
      familyMember: {
        id: this.state.id,
        memberId: this.memberDetails.member,
      },
    };
    FetchServerData.callPostService('/caregiverms/delete', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          this.props.history.push({
            pathname: '/familyCircle',
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  toSendRequest = (e) => {
    const postObject = {
      header: this.header,
      memberVo: {
        id: this.memberDetails.member,
        familyMemberMemberId: this.state.familyMemberMemberId,
      },
    };

    FetchServerData.callPostService('/memberms/resendRequest', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          this.props.history.push({
            pathname: '/familyCircle',
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  updateFamilyMemberInBackend = (e) => {
    e.preventDefault();
    let familyMemberStatus;
    if (this.state.isStatus === true) {
      familyMemberStatus = 'Active';
    } else {
      familyMemberStatus = 'Inactive';
    }
    const postObject = {
      header: this.header,

      familyMember: {
        memberId: this.memberDetails.member,
        id: this.state.id,
        familyMemberMemberStatus: {
          status: familyMemberStatus,
        },
        relationRole: {
          name: this.state.role.name,
          uid: this.state.role.uid,
        },
      },
    };
    FetchServerData.callPostService('/caregiverms/update', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let memberDetails = output.data;
          PtsAlert.success(output.message);
          this.setState({
            editFamilyMemberDetails: false,
            viewFamilyMemberDetails: true,
          });
          this.props.history.push({
            pathname: '/viewFamilyMember',
            state: { action: 'view', title: 'Caregiver Details', membershipId: memberDetails.id },
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  updateUnRegisterFamilyMemberInBackend = (e) => {
    e.preventDefault();
    let familyMemberStatus;
    if (this.state.isStatus === true) {
      familyMemberStatus = 'Active';
    } else {
      familyMemberStatus = 'Inactive';
    }
    const postObject = {
      header: this.header,

      familyMember: {
        memberId: this.memberDetails.member,
        id: this.state.id,
        familyMemberMemberStatus: {
          status: familyMemberStatus,
        },
        relationRole: {
          name: this.state.role.name,
          uid: this.state.role.uid,
        },
      },
    };
    FetchServerData.callPostService('/caregiverms/update', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let memberDetails = output.data;
          PtsAlert.success(output.message);
          this.setState({
            editUnRegisterFamilyMember: false,
            viewUnRegisterFamilyMember: true,
          });
          this.props.history.push({
            pathname: '/viewFamilyMember',
            state: { action: 'view', title: 'Caregiver Details', membershipId: memberDetails.id },
          });
        } else {
        }
      })
      .catch((error) => {});
  };
  updateFamilyMemberDetails = (e) => {
    this.setState({
      title: 'Caregiver Details',
      editFamilyMemberDetails: true,
      viewFamilyMemberDetails: false,
    });
  };

  updateUnRegisterFamilyMemberDetails = (e) => {
    this.setState({
      title: 'Caregiver Details',
      editUnRegisterFamilyMember: true,
      viewUnRegisterFamilyMember: false,
    });
  };

  EditCancelFamilyMember = (e) => {
    this.setState({
      editFamilyMemberDetails: false,
      viewFamilyMemberDetails: true,
    });
  };

  EditCancelUnRegisterFamilyMember = (e) => {
    this.setState({
      editUnRegisterFamilyMember: false,
      viewUnRegisterFamilyMember: true,
    });
  };

  render() {
    if (this.state.action === 'view') {
      return this.viewFamilyMember();
    } else if (this.state.action === 'unRegisterView') {
      return this.unRegisterFamilyMember();
    }
  }

  viewFamilyMember() {
    if (this.state.render === false) {
      return <div />;
    } else {
      if (this.state.isFreeUser === true) {
        return this.viewFamilyMemberWithAdvertisement();
      } else {
        return this.viewFamilyMemberWithoutAdvertisement();
      }
    }
  }
  closeFloatingButton() {
    this.setState({ displayFloatButton: false });
    let displayFloat = false;

    store.dispatch(helpDataAction.setHelpData(displayFloat));
  }
  modalData() {
    return (
      <span style={{ textAlign: 'center' }}>
        <h2 style={{ fontSize: '16px', lineHeight: '1.3em' }}>
          Dear <b style={{ color: '#0f666b' }}>{this.loginUserName}</b>
          <br></br>
          You are about to remove <b style={{ color: '#0f666b' }}>{this.state.fname + ' ' + this.state.lname}</b> from
          circle of <b style={{ color: '#0f666b' }}>{this.memberName}</b>.<br></br> Once removed,
          <b style={{ color: '#0f666b' }}>{this.state.fname + ' ' + this.state.lname}</b> cannot view or monitor
          appointments, Incidents, Purchases, Notes and Communications associated with{' '}
          <b style={{ color: '#0f666b' }}>{this.memberName}</b>.<br></br> Do you want to continue?
        </h2>
      </span>
    );
  }
  viewFamilyMemberWithAdvertisement() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <>
            <MenuWithBreadScrum
              backCallback={this.viewCancel.bind(this)}
              deleteCallback={this.deleteFamilyMember.bind(this)}
              getDeletePermission={this.state.getDeletePermission}
              getAllPermission={this.state.getAllPermission}
              breadCrumb={this.breadCrumb}
              history={this.props.history}
              name={this.title + ' For ' + this.memberName}
              domainObject={this.modalData()}
              pageId="caregiver"
            ></MenuWithBreadScrum>
          </>
          <Steps
            enabled={this.state.stepsEnabled}
            steps={this.state.steps}
            initialStep={this.state.initialStep}
            onExit={this.onExit}
          />
          <div>
            <Row style={{ position: 'relative', margin: '1em' }}>
              <Col md={5} style={{ position: 'relative', padding: '5px' }}>
                <Card style={{ height: '100%', borderRadius: '20px' }}>
                  {this.state.viewFamilyMemberDetails && (
                    <>
                      <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                        <Row>
                          <Col md={10}>
                            <Label
                              style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.7em', marginTop: '0.5em' }}
                            >
                              Caregiver Details
                            </Label>
                          </Col>
                          <Col md={2}>
                            {this.state.getUpdatePermission === true && (
                              <span>
                                <EditIconButton onClick={this.updateFamilyMemberDetails.bind(this)} />
                              </span>
                            )}
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md={2}>
                            <label>Name</label>
                          </Col>
                          <Col md={10}>
                            {' '}
                            <span style={{ textTransform: 'capitalize' }}>
                              {' '}
                              {this.state.fname === null ? '' : this.state.fname + ' '}
                              {this.state.lname === null ? '' : this.state.lname}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={2}>
                            <label>Email</label>
                          </Col>
                          <Col md={10}>
                            {' '}
                            <span>
                              <a href={'mailto:' + this.state.email} target="_top">
                                {' '}
                                {this.state.email}{' '}
                              </a>
                            </span>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={2}>
                            <label htmlFor="status"> Status </label>
                          </Col>
                          <Col md={10} className="statusMember">
                            {this.state.isStatus === true && (
                              <Tooltip title="Please click to change status">
                                <span>
                                  <ToggleButton
                                    value="Active"
                                    className="ToggleButtonActive"
                                    onChange={this.handleStatusChange.bind(this)}
                                    style={{
                                      background: '#27AE60',
                                      color: '#fff',
                                      height: '25px',
                                      textTransform: 'none',
                                    }}
                                    disabled
                                  >
                                    Active
                                  </ToggleButton>
                                </span>
                              </Tooltip>
                            )}
                            {this.state.isStatus === false && (
                              <Tooltip title="Please click to change status">
                                <span>
                                  <ToggleButton
                                    value="Inactive"
                                    className="ToggleButtonInActive"
                                    onChange={this.handleStatusChange.bind(this)}
                                    style={{
                                      background: '#D7DBDD',
                                      color: '#000',
                                      height: '25px',
                                      textTransform: 'none',
                                    }}
                                    disabled
                                  >
                                    Inactive
                                  </ToggleButton>
                                </span>
                              </Tooltip>
                            )}
                          </Col>
                        </Row>
                        {this.state.role && (
                          <Row>
                            <Col md={2}>
                              <label>Profile</label>
                              <br />
                              <span>
                                {' '}
                                <i
                                  className="fa fa-info-circle"
                                  id="menuIconStyle"
                                  style={{ color: '#4f5d7c', cursor: 'pointer' }}
                                  onClick={this.profileInfoModalOpen}
                                />
                              </span>
                            </Col>
                            <Col md={10} className="memberRole">
                              {' '}
                              <span>{this.state.role.name}</span>
                            </Col>
                          </Row>
                        )}
                        <Row style={{ marginTop: '1em' }}></Row>
                      </CardBody>
                    </>
                  )}
                  {this.state.editFamilyMemberDetails && (
                    <>
                      <ValidatorForm
                        style={{ width: '100%' }}
                        ref="form"
                        onSubmit={this.updateFamilyMemberInBackend.bind(this)}
                      >
                        <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                          <Row>
                            <Col md={12}>
                              {undefined !== this.state.title && this.state.title.length > 0 && (
                                <Label style={{ fontWeight: '600', color: '#0099f1' }}>{this.state.title}</Label>
                              )}
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md={2}>
                              <label>Name</label>
                            </Col>
                            <Col md={10}>
                              {' '}
                              <span style={{ textTransform: 'capitalize' }}>
                                {' '}
                                {this.state.fname + ' ' + this.state.lname}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={2}>
                              <label>Email</label>
                            </Col>
                            <Col md={10}>
                              {' '}
                              <span>
                                <a href={'mailto:' + this.state.email} target="_top">
                                  {' '}
                                  {this.state.email}
                                </a>
                              </span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={2}>
                              <label htmlFor="status"> Status </label>
                            </Col>
                            <Col md={10} className="statusMember">
                              {this.state.isStatus === true && (
                                <Tooltip title="Please click to change status">
                                  <span>
                                    <ToggleButton
                                      value="Active"
                                      className="ToggleButtonActive"
                                      onChange={this.handleStatusChange.bind(this)}
                                      style={{
                                        background: '#1e718b',
                                        color: '#fff',
                                        height: '25px',
                                        textTransform: 'none',
                                      }}
                                    >
                                      Active
                                    </ToggleButton>
                                  </span>
                                </Tooltip>
                              )}
                              {this.state.isStatus === false && (
                                <Tooltip title="Please click to change status">
                                  <span>
                                    <ToggleButton
                                      value="Inactive"
                                      className="ToggleButtonInActive"
                                      onChange={this.handleStatusChange.bind(this)}
                                      style={{
                                        background: '#c7c7c7',
                                        color: '#000',
                                        height: '25px',
                                        textTransform: 'none',
                                      }}
                                    >
                                      Inactive
                                    </ToggleButton>
                                  </span>
                                </Tooltip>
                              )}
                            </Col>
                          </Row>

                          <Row style={{ marginTop: '1em' }}>
                            <Col md={6}>
                              <Autocomplete
                                required
                                id="Role"
                                options={this.state.roleList}
                                value={this.state.role}
                                getOptionLabel={(option) => (option.name ? option.name : '')}
                                onChange={(event, value) => {
                                  this.handleRoleChange(value);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} variant="outlined" label="Profile" margin="normal" />
                                )}
                              />{' '}
                            </Col>{' '}
                            <Col md={6} style={{ margin: 'auto' }}>
                              <span>
                                {' '}
                                <i
                                  className="fa fa-info-circle"
                                  id="menuIconStyle"
                                  style={{ color: '#4f5d7c', cursor: 'pointer' }}
                                  onClick={this.profileInfoModalOpen}
                                />
                              </span>
                            </Col>
                          </Row>
                        </CardBody>
                        <CardFooter>
                          <div style={{ textAlign: 'center' }}>
                            <SaveButton type="submit" />
                            &nbsp;
                            <CancelIconButton
                              type="button"
                              onClick={(e) => this.EditCancelFamilyMember(e, this.state.id)}
                            />
                          </div>
                        </CardFooter>
                      </ValidatorForm>
                    </>
                  )}
                </Card>
              </Col>

              <Col md={2} style={{ position: 'relative' }}></Col>
            </Row>

            <div style={{ textAlign: 'center' }}>
              <BackButton
                type="button"
                style={{ backgroundColor: '#162c50', color: '#fff' }}
                variant="contained"
                startIcon={<CloseIcon />}
                onClick={(e) => this.viewCancel()}
              >
                Back
              </BackButton>
            </div>
          </div>
          <PtsModal
            open={this.state.profileInfoModel}
            onClose={this.profileInfoModelToggle}
            title="Family Care Circle "
          >
            <h5>Profile Information</h5>
            <Row>
              <Col md={12}>
                <ul style={{ listStyle: 'none' }}>
                  <li>
                    {' '}
                    <p>
                      <b style={{ color: '#2B88C7', fontSize: '15px' }}>Authorized Caregiver - </b>
                      {/* </h6>
                    <p> */}{' '}
                      This role has total responsibility for the Circle.They represent the Member “Center of the Circle”
                      with the ability to expand the Circle to other caregivers, assign the roles to other caregivers
                      and delete the circle.Typically this role is assigned to one person when the Member needs someone
                      to represent them.
                    </p>
                  </li>
                  <li>
                    {' '}
                    <p>
                      <b style={{ color: '#2B88C7', fontSize: '15px' }}>Family Manager - </b>
                      This role also has the ability to expand the Circle and has all rights needed to create
                      appointments and communicate with others. Typically this role is assigned to a close relative.
                      This role is very similar to Authorized Caregiver with the exception of the ability to delete the
                      Circle for the Member.
                    </p>
                  </li>
                  <li>
                    {' '}
                    <p>
                      <b style={{ color: '#2B88C7', fontSize: '15px' }}>Family Member - </b>
                      As an important caregiver, they can help manage all appointments. They are restricted from adding
                      others to the Circle. Typically this role is assigned to extended relatives or close friends.
                    </p>
                  </li>
                  <li>
                    {' '}
                    <p>
                      <b style={{ color: '#2B88C7', fontSize: '15px' }}>Transporter - </b>
                      Can only view Appointments/Events for which they are responsible for transportation. Typically
                      this role is assigned to friends that are wanting to help by offering to take the loved one to an
                      appointment. This is the most restrictive role.
                    </p>
                  </li>
                </ul>
              </Col>{' '}
            </Row>
          </PtsModal>
        </>
      );
    }
  }

  viewFamilyMemberWithoutAdvertisement() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <>
            <MenuWithBreadScrum
              backCallback={this.viewCancel.bind(this)}
              deleteCallback={this.deleteFamilyMember.bind(this)}
              getDeletePermission={this.state.getDeletePermission}
              getAllPermission={this.state.getAllPermission}
              breadCrumb={this.breadCrumb}
              history={this.props.history}
              name={this.title + ' For ' + this.memberName}
              domainObject={this.modalData()}
              deleteClassName="delete"
              pageId="caregiver"
            />
          </>
          <Steps
            enabled={this.state.stepsEnabled}
            steps={this.state.steps}
            initialStep={this.state.initialStep}
            onExit={this.onExit}
          />
          <div>
            <Row style={{ position: 'relative', margin: '1em' }}>
              <Col md={6} style={{ position: 'relative', padding: '5px' }}>
                <Card style={{ height: '100%', borderRadius: '20px' }}>
                  {this.state.viewFamilyMemberDetails && (
                    <>
                      <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                        <Row>
                          <Col md={10}>
                            <Label
                              style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.7em', marginTop: '0.5em' }}
                            >
                              Caregiver Details
                            </Label>
                          </Col>
                          <Col md={2}>
                            {this.state.getUpdatePermission === true && (
                              <EditIconButton onClick={this.updateFamilyMemberDetails.bind(this)} />
                            )}
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md={2}>
                            <label>Name</label>
                          </Col>
                          <Col md={10}>
                            {' '}
                            <span style={{ textTransform: 'capitalize' }}>
                              {' '}
                              {this.state.fname + ' ' + this.state.lname}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={2}>
                            <label>Email</label>
                          </Col>
                          <Col md={10}>
                            {' '}
                            <span>
                              <a href={'mailto:' + this.state.email} target="_top">
                                {' '}
                                {this.state.email}{' '}
                              </a>
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={2}>
                            <label htmlFor="status"> Status </label>
                          </Col>
                          <Col md={10} className="statusMember">
                            {this.state.isStatus === true && (
                              <Tooltip title="Please click to change status">
                                <span>
                                  <ToggleButton
                                    value="Active"
                                    className="ToggleButtonActive"
                                    onChange={this.handleStatusChange.bind(this)}
                                    style={{
                                      background: '#27AE60',
                                      color: '#fff',
                                      height: '25px',
                                      textTransform: 'none',
                                    }}
                                    disabled
                                  >
                                    Active
                                  </ToggleButton>
                                </span>
                              </Tooltip>
                            )}
                            {this.state.isStatus === false && (
                              <Tooltip title="Please click to change status">
                                <span>
                                  <ToggleButton
                                    value="Inactive"
                                    className="ToggleButtonInActive"
                                    onChange={this.handleStatusChange.bind(this)}
                                    style={{
                                      background: '#D7DBDD',
                                      color: '#000',
                                      height: '25px',
                                      textTransform: 'none',
                                    }}
                                    disabled
                                  >
                                    Inactive
                                  </ToggleButton>
                                </span>
                              </Tooltip>
                            )}
                          </Col>
                        </Row>{' '}
                        {this.state.role && (
                          <Row>
                            <Col md={2}>
                              <label>Profile</label>
                              <br />
                              <span>
                                {' '}
                                <i
                                  className="fa fa-info-circle"
                                  id="menuIconStyle"
                                  style={{ color: '#4f5d7c', cursor: 'pointer' }}
                                  onClick={this.profileInfoModalOpen}
                                />
                              </span>
                            </Col>
                            <Col md={10} className="memberRole">
                              {' '}
                              <span>{this.state.role.name}</span>
                            </Col>
                          </Row>
                        )}
                        <Row style={{ marginTop: '1em' }}></Row>
                      </CardBody>
                    </>
                  )}
                  {this.state.editFamilyMemberDetails && (
                    <>
                      <ValidatorForm
                        style={{ width: '100%' }}
                        ref="form"
                        onSubmit={this.updateFamilyMemberInBackend.bind(this)}
                      >
                        <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                          <Row>
                            <Col md={12}>
                              {undefined !== this.state.title && this.state.title.length > 0 && (
                                <Label style={{ fontWeight: '600', color: '#0099f1' }}>{this.state.title}</Label>
                              )}
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md={2}>
                              <label>Name</label>
                            </Col>
                            <Col md={10}>
                              {' '}
                              <span style={{ textTransform: 'capitalize' }}>
                                {' '}
                                {this.state.fname + ' ' + this.state.lname}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={2}>
                              <label>Email</label>
                            </Col>
                            <Col md={10}>
                              {' '}
                              <span>
                                <a href={'mailto:' + this.state.email} target="_top">
                                  {' '}
                                  {this.state.email}{' '}
                                </a>
                              </span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={2}>
                              <label htmlFor="status"> Status </label>
                            </Col>
                            <Col md={10} className="statusMember">
                              {this.state.isStatus === true && (
                                <Tooltip title="Please click to change status">
                                  <span>
                                    <ToggleButton
                                      value="Active"
                                      className="ToggleButtonActive"
                                      onChange={this.handleStatusChange.bind(this)}
                                      style={{
                                        background: '#1e718b',
                                        color: '#fff',
                                        height: '25px',
                                        textTransform: 'none',
                                      }}
                                    >
                                      Active
                                    </ToggleButton>
                                  </span>
                                </Tooltip>
                              )}
                              {this.state.isStatus === false && (
                                <Tooltip title="Please click to change status">
                                  <span>
                                    <ToggleButton
                                      value="Inactive"
                                      className="ToggleButtonInActive"
                                      onChange={this.handleStatusChange.bind(this)}
                                      style={{
                                        background: '#c7c7c7',
                                        color: '#000',
                                        height: '25px',
                                        textTransform: 'none',
                                      }}
                                    >
                                      Inactive
                                    </ToggleButton>
                                  </span>
                                </Tooltip>
                              )}
                            </Col>
                          </Row>

                          <Row style={{ marginTop: '1em' }}>
                            <Col md={6}>
                              <Autocomplete
                                required
                                id="Role"
                                options={this.state.roleList}
                                value={this.state.role}
                                getOptionLabel={(option) => (option.name ? option.name : '')}
                                onChange={(event, value) => {
                                  this.handleRoleChange(value);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} variant="outlined" label="Profile" margin="normal" />
                                )}
                              />{' '}
                            </Col>{' '}
                            <Col md={6} style={{ margin: 'auto' }}>
                              <span>
                                {' '}
                                <i
                                  className="fa fa-info-circle"
                                  id="menuIconStyle"
                                  style={{ color: '#4f5d7c', cursor: 'pointer' }}
                                  onClick={this.profileInfoModalOpen}
                                />
                              </span>
                            </Col>
                          </Row>
                        </CardBody>
                        <CardFooter>
                          <div style={{ textAlign: 'center' }}>
                            <SaveButton type="submit" />
                            &nbsp;
                            <CancelIconButton
                              type="button"
                              onClick={(e) => this.EditCancelFamilyMember(e, this.state.id)}
                            />
                          </div>
                        </CardFooter>
                      </ValidatorForm>
                    </>
                  )}
                </Card>
              </Col>
            </Row>

            <div style={{ textAlign: 'center' }}>
              <BackButton
                type="button"
                style={{ backgroundColor: '#162c50', color: '#fff' }}
                variant="contained"
                startIcon={<CloseIcon />}
                onClick={(e) => this.viewCancel()}
              >
                Back
              </BackButton>
            </div>
          </div>
          <PtsModal
            open={this.state.profileInfoModel}
            onClose={this.profileInfoModelToggle}
            title="Family Care Circle "
          >
            <h5>Profile Information</h5>
            <Row>
              <Col md={12}>
                <ul style={{ listStyle: 'none' }}>
                  <li>
                    {' '}
                    <p>
                      <b style={{ color: '#2B88C7', fontSize: '15px' }}>Authorized Caregiver - </b>
                      {/* </h6>
                    <p> */}{' '}
                      This role has total responsibility for the Circle.They represent the Member “Center of the Circle”
                      with the ability to expand the Circle to other caregivers, assign the roles to other caregivers
                      and delete the circle.Typically this role is assigned to one person when the Member needs someone
                      to represent them.
                    </p>
                  </li>
                  <li>
                    {' '}
                    <p>
                      <b style={{ color: '#2B88C7', fontSize: '15px' }}>Family Manager - </b>
                      This role also has the ability to expand the Circle and has all rights needed to create
                      appointments and communicate with others. Typically this role is assigned to a close relative.
                      This role is very similar to Authorized Caregiver with the exception of the ability to delete the
                      Circle for the Member.
                    </p>
                  </li>
                  <li>
                    {' '}
                    <p>
                      <b style={{ color: '#2B88C7', fontSize: '15px' }}>Family Member - </b>
                      As an important caregiver, they can help manage all appointments. They are restricted from adding
                      others to the Circle. Typically this role is assigned to extended relatives or close friends.
                    </p>
                  </li>
                  <li>
                    {' '}
                    <p>
                      <b style={{ color: '#2B88C7', fontSize: '15px' }}>Transporter - </b>
                      Can only view Appointments/Events for which they are responsible for transportation. Typically
                      this role is assigned to friends that are wanting to help by offering to take the loved one to an
                      appointment. This is the most restrictive role.
                    </p>
                  </li>
                </ul>
              </Col>{' '}
            </Row>
          </PtsModal>
        </>
      );
    }
  }

  /* show unregister family member details  */
  unRegisterFamilyMember() {
    if (this.state.render === false) {
      return <div />;
    } else {
      if (this.state.isFreeUser === true) {
        return this.unRegisterFamilyMemberWithAdvertisement();
      } else {
        return this.unRegisterFamilyMemberWithoutAdvertisement();
      }
    }
  }

  unRegisterFamilyMemberWithAdvertisement() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <MenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            backCallback={this.viewCancel.bind(this)}
            getDeletePermission={this.state.getDeletePermission}
            getAllPermission={this.state.getAllPermission}
            deleteCallback={this.deleteUnregisterFamilyMember.bind(this)}
            history={this.props.history}
            name={this.title + ' For ' + this.memberName}
            domainObject={this.modalData()}
            pageId="caregiver"
          />

          <div>
            <Row style={{ position: 'relative', margin: '1em' }}>
              <Col md={8} style={{ position: 'relative', padding: '5px' }}>
                <Card style={{ height: '100%', borderRadius: '20px' }}>
                  {this.state.viewUnRegisterFamilyMember && (
                    <>
                      <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                        <Row>
                          <Col md={10}>
                            <Label
                              style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.7em', marginTop: '0.5em' }}
                            >
                              Caregiver Details
                            </Label>
                          </Col>
                          <Col md={2}>
                            {this.state.getUpdatePermission === true && (
                              <EditIconButton onClick={this.updateUnRegisterFamilyMemberDetails.bind(this)} />
                            )}
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md={2}>
                            <label>Name</label>
                          </Col>
                          <Col md={10}>
                            {' '}
                            <span style={{ textTransform: 'capitalize' }}>
                              {' '}
                              {this.state.fname + ' ' + this.state.lname}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={2}>
                            <label>Email</label>
                          </Col>
                          <Col md={10}>
                            {' '}
                            <span>
                              <a href={'mailto:' + this.state.email} target="_top">
                                {' '}
                                {this.state.email}{' '}
                              </a>
                            </span>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={2}>
                            <label htmlFor="status"> Status </label>
                          </Col>
                          <Col md={10} className="statusMember">
                            {this.state.isStatus === true && (
                              <Tooltip title="Please click to change status">
                                <span>
                                  <ToggleButton
                                    value="Active"
                                    className="ToggleButtonActive"
                                    onChange={this.handleStatusChange.bind(this)}
                                    style={{
                                      background: '#27AE60',
                                      color: '#fff',
                                      height: '25px',
                                      textTransform: 'none',
                                    }}
                                    disabled
                                  >
                                    Active
                                  </ToggleButton>
                                </span>
                              </Tooltip>
                            )}
                            {this.state.isStatus === false && (
                              <Tooltip title="Please click to change status">
                                <span>
                                  <ToggleButton
                                    value="Inactive"
                                    className="ToggleButtonInActive"
                                    onChange={this.handleStatusChange.bind(this)}
                                    style={{
                                      background: '#D7DBDD',
                                      color: '#000',
                                      height: '25px',
                                      textTransform: 'none',
                                    }}
                                    disabled
                                  >
                                    Inactive
                                  </ToggleButton>
                                </span>
                              </Tooltip>
                            )}
                          </Col>
                        </Row>
                        {this.state.role && (
                          <Row>
                            <Col md={2}>
                              <label>Profile</label>
                              <br />
                              <span>
                                {' '}
                                <i
                                  className="fa fa-info-circle"
                                  id="menuIconStyle"
                                  style={{ color: '#4f5d7c', cursor: 'pointer' }}
                                  onClick={this.profileInfoModalOpen}
                                />
                              </span>
                            </Col>
                            <Col md={10} className="memberRole">
                              {' '}
                              <span>{this.state.role.name}</span>
                            </Col>
                          </Row>
                        )}
                        <Row style={{ marginTop: '1em' }}></Row>
                      </CardBody>
                    </>
                  )}
                  {this.state.editUnRegisterFamilyMember && (
                    <>
                      <ValidatorForm
                        style={{ width: '100%' }}
                        ref="form"
                        onSubmit={this.updateUnRegisterFamilyMemberInBackend.bind(this)}
                      >
                        <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                          <Row>
                            <Col md={12}>
                              {undefined !== this.state.title && this.state.title.length > 0 && (
                                <Label style={{ fontWeight: '600', color: '#0099f1' }}>{this.state.title}</Label>
                              )}
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md={2}>
                              <label>Name</label>
                            </Col>
                            <Col md={10}>
                              {' '}
                              <span style={{ textTransform: 'capitalize' }}>
                                {' '}
                                {this.state.fname + ' ' + this.state.lname}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={2}>
                              <label>Email</label>
                            </Col>
                            <Col md={10}>
                              {' '}
                              <span>
                                <a href={'mailto:' + this.state.email} target="_top">
                                  {' '}
                                  {this.state.email}
                                </a>
                              </span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={2}>
                              <label htmlFor="status"> Status </label>
                            </Col>
                            <Col md={10} className="statusMember">
                              {this.state.isStatus === true && (
                                <Tooltip title="Please click to change status">
                                  <span>
                                    <ToggleButton
                                      value="Active"
                                      className="ToggleButtonActive"
                                      onChange={this.handleStatusChange.bind(this)}
                                      style={{
                                        background: '#1e718b',
                                        color: '#fff',
                                        height: '25px',
                                        textTransform: 'none',
                                      }}
                                    >
                                      Active
                                    </ToggleButton>
                                  </span>
                                </Tooltip>
                              )}
                              {this.state.isStatus === false && (
                                <Tooltip title="Please click to change status">
                                  <span>
                                    <ToggleButton
                                      value="Inactive"
                                      className="ToggleButtonInActive"
                                      onChange={this.handleStatusChange.bind(this)}
                                      style={{
                                        background: '#c7c7c7',
                                        color: '#000',
                                        height: '25px',
                                        textTransform: 'none',
                                      }}
                                    >
                                      Inactive
                                    </ToggleButton>
                                  </span>
                                </Tooltip>
                              )}
                            </Col>
                          </Row>

                          <Row style={{ marginTop: '1em' }}>
                            <Col md={6}>
                              <Autocomplete
                                required
                                id="Role"
                                options={this.state.roleList}
                                value={this.state.role}
                                getOptionLabel={(option) => (option.name ? option.name : '')}
                                onChange={(event, value) => {
                                  this.handleRoleChange(value);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} variant="outlined" label="Profile" margin="normal" />
                                )}
                              />{' '}
                            </Col>{' '}
                            <Col md={6} style={{ margin: 'auto' }}>
                              <span>
                                {' '}
                                <i
                                  className="fa fa-info-circle"
                                  id="menuIconStyle"
                                  style={{ color: '#4f5d7c', cursor: 'pointer' }}
                                  onClick={this.profileInfoModalOpen}
                                />
                              </span>
                            </Col>
                          </Row>
                        </CardBody>
                        <CardFooter>
                          <div style={{ textAlign: 'center' }}>
                            <SaveButton type="submit" />
                            &nbsp;
                            <CancelIconButton
                              type="button"
                              onClick={(e) => this.EditCancelUnRegisterFamilyMember(e, this.state.id)}
                            />
                          </div>
                        </CardFooter>
                      </ValidatorForm>
                    </>
                  )}
                </Card>
              </Col>
              <Col md={4} style={{ position: 'relative' }}></Col>
            </Row>

            <div style={{ textAlign: 'center' }}>
              <BackButton
                type="button"
                style={{ backgroundColor: '#162c50', color: '#fff' }}
                variant="contained"
                startIcon={<CloseIcon />}
                onClick={(e) => this.viewCancel()}
              >
                Back
              </BackButton>
            </div>
          </div>
          <PtsModal
            open={this.state.profileInfoModel}
            onClose={this.profileInfoModelToggle}
            title="Family Care Circle "
          >
            <h5>Profile Information</h5>
            <Row>
              <Col md={12}>
                <ul style={{ listStyle: 'none' }}>
                  <li>
                    {' '}
                    <p>
                      <b style={{ color: '#2B88C7', fontSize: '15px' }}>Authorized Caregiver - </b>
                      {/* </h6>
                    <p> */}{' '}
                      This role has total responsibility for the Circle.They represent the Member “Center of the Circle”
                      with the ability to expand the Circle to other caregivers, assign the roles to other caregivers
                      and delete the circle.Typically this role is assigned to one person when the Member needs someone
                      to represent them.
                    </p>
                  </li>
                  <li>
                    {' '}
                    <p>
                      <b style={{ color: '#2B88C7', fontSize: '15px' }}>Family Manager - </b>
                      This role also has the ability to expand the Circle and has all rights needed to create
                      appointments and communicate with others. Typically this role is assigned to a close relative.
                      This role is very similar to Authorized Caregiver with the exception of the ability to delete the
                      Circle for the Member.
                    </p>
                  </li>
                  <li>
                    {' '}
                    <p>
                      <b style={{ color: '#2B88C7', fontSize: '15px' }}>Family Member - </b>
                      As an important caregiver, they can help manage all appointments. They are restricted from adding
                      others to the Circle. Typically this role is assigned to extended relatives or close friends.
                    </p>
                  </li>
                  <li>
                    {' '}
                    <p>
                      <b style={{ color: '#2B88C7', fontSize: '15px' }}>Transporter - </b>
                      Can only view Appointments/Events for which they are responsible for transportation. Typically
                      this role is assigned to friends that are wanting to help by offering to take the loved one to an
                      appointment. This is the most restrictive role.
                    </p>
                  </li>
                </ul>
              </Col>{' '}
            </Row>
          </PtsModal>
        </>
      );
    }
  }

  unRegisterFamilyMemberWithoutAdvertisement() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <MenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            backCallback={this.viewCancel.bind(this)}
            getDeletePermission={this.state.getDeletePermission}
            getAllPermission={this.state.getAllPermission}
            deleteCallback={this.deleteUnregisterFamilyMember.bind(this)}
            history={this.props.history}
            name={this.title + ' For ' + this.memberName}
            domainObject={this.modalData()}
            pageId="caregiver"
          />

          <div>
            <Row style={{ position: 'relative', margin: '1em' }}>
              <Col md={8} style={{ position: 'relative', padding: '5px' }}>
                <Card style={{ height: '100%', borderRadius: '20px' }}>
                  {this.state.viewUnRegisterFamilyMember && (
                    <>
                      <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                        <Row>
                          <Col md={10}>
                            <Label
                              style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.7em', marginTop: '0.5em' }}
                            >
                              Caregiver Details
                            </Label>
                          </Col>
                          <Col md={2}>
                            {this.state.getUpdatePermission === true && (
                              <EditIconButton onClick={this.updateUnRegisterFamilyMemberDetails.bind(this)} />
                            )}
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md={2}>
                            <label>Name</label>
                          </Col>
                          <Col md={10}>
                            {' '}
                            <span style={{ textTransform: 'capitalize' }}>
                              {' '}
                              {this.state.fname + ' ' + this.state.lname}
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={2}>
                            <label>Email</label>
                          </Col>
                          <Col md={10}>
                            {' '}
                            <span>
                              <a href={'mailto:' + this.state.email} target="_top">
                                {' '}
                                {this.state.email}
                              </a>
                            </span>
                          </Col>
                        </Row>

                        <Row>
                          <Col md={2}>
                            <label htmlFor="status"> Status </label>
                          </Col>
                          <Col md={10} className="statusMember">
                            {this.state.isStatus === true && (
                              <Tooltip title="Please click to change status">
                                <span>
                                  <ToggleButton
                                    value="Active"
                                    className="ToggleButtonActive"
                                    onChange={this.handleStatusChange.bind(this)}
                                    style={{
                                      background: '#27AE60',
                                      color: '#fff',
                                      height: '25px',
                                      textTransform: 'none',
                                    }}
                                    disabled
                                  >
                                    Active
                                  </ToggleButton>
                                </span>
                              </Tooltip>
                            )}
                            {this.state.isStatus === false && (
                              <Tooltip title="Please click to change status">
                                <span>
                                  <ToggleButton
                                    value="Inactive"
                                    className="ToggleButtonInActive"
                                    onChange={this.handleStatusChange.bind(this)}
                                    style={{
                                      background: '#D7DBDD',
                                      color: '#000',
                                      height: '25px',
                                      textTransform: 'none',
                                    }}
                                    disabled
                                  >
                                    Inactive
                                  </ToggleButton>
                                </span>
                              </Tooltip>
                            )}
                          </Col>
                        </Row>
                        {this.state.role && (
                          <Row>
                            <Col md={2}>
                              <label>Profile</label>
                              <br />
                              <span>
                                {' '}
                                <i
                                  className="fa fa-info-circle"
                                  id="menuIconStyle"
                                  style={{ color: '#4f5d7c', cursor: 'pointer' }}
                                  onClick={this.profileInfoModalOpen}
                                />
                              </span>
                            </Col>
                            <Col md={10} className="memberRole">
                              {' '}
                              <span>{this.state.role.name}</span>
                            </Col>
                          </Row>
                        )}
                        <Row style={{ marginTop: '1em' }}></Row>
                      </CardBody>
                    </>
                  )}
                  {this.state.editUnRegisterFamilyMember && (
                    <>
                      <ValidatorForm
                        style={{ width: '100%' }}
                        ref="form"
                        onSubmit={this.updateUnRegisterFamilyMemberInBackend.bind(this)}
                      >
                        <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                          <Row>
                            <Col md={12}>
                              {undefined !== this.state.title && this.state.title.length > 0 && (
                                <Label style={{ fontWeight: '600', color: '#0099f1' }}>{this.state.title}</Label>
                              )}
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md={2}>
                              <label>Name</label>
                            </Col>
                            <Col md={10}>
                              {' '}
                              <span style={{ textTransform: 'capitalize' }}>
                                {' '}
                                {this.state.fname + ' ' + this.state.lname}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={2}>
                              <label>Email</label>
                            </Col>
                            <Col md={10}>
                              {' '}
                              <span>
                                <a href={'mailto:' + this.state.email} target="_top">
                                  {this.state.email}
                                </a>
                              </span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={2}>
                              <label htmlFor="status"> Status </label>
                            </Col>
                            <Col md={10} className="statusMember">
                              {this.state.isStatus === true && (
                                <Tooltip title="Please click to change status">
                                  <span>
                                    <ToggleButton
                                      value="Active"
                                      className="ToggleButtonActive"
                                      onChange={this.handleStatusChange.bind(this)}
                                      style={{
                                        background: '#1e718b',
                                        color: '#fff',
                                        height: '25px',
                                        textTransform: 'none',
                                      }}
                                    >
                                      Active
                                    </ToggleButton>
                                  </span>
                                </Tooltip>
                              )}
                              {this.state.isStatus === false && (
                                <Tooltip title="Please click to change status">
                                  <span>
                                    <ToggleButton
                                      value="Inactive"
                                      className="ToggleButtonInActive"
                                      onChange={this.handleStatusChange.bind(this)}
                                      style={{
                                        background: '#c7c7c7',
                                        color: '#000',
                                        height: '25px',
                                        textTransform: 'none',
                                      }}
                                    >
                                      Inactive
                                    </ToggleButton>
                                  </span>
                                </Tooltip>
                              )}
                            </Col>
                          </Row>

                          <Row style={{ marginTop: '1em' }}>
                            <Col md={6}>
                              <Autocomplete
                                required
                                id="Role"
                                options={this.state.roleList}
                                value={this.state.role}
                                getOptionLabel={(option) => (option.name ? option.name : '')}
                                onChange={(event, value) => {
                                  this.handleRoleChange(value);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} variant="outlined" label="Profile" margin="normal" />
                                )}
                              />{' '}
                            </Col>
                            <Col md={6} style={{ margin: 'auto' }}>
                              <span>
                                {' '}
                                <i
                                  className="fa fa-info-circle"
                                  id="menuIconStyle"
                                  style={{ color: '#4f5d7c', cursor: 'pointer' }}
                                  onClick={this.profileInfoModalOpen}
                                />
                              </span>
                            </Col>
                          </Row>
                        </CardBody>
                        <CardFooter>
                          <div style={{ textAlign: 'center' }}>
                            <SaveButton type="submit" />
                            &nbsp;
                            <CancelIconButton
                              type="button"
                              onClick={(e) => this.EditCancelUnRegisterFamilyMember(e, this.state.id)}
                            />
                          </div>
                        </CardFooter>
                      </ValidatorForm>
                    </>
                  )}
                </Card>
              </Col>
            </Row>

            <div style={{ textAlign: 'center' }}>
              <BackButton
                type="button"
                style={{ backgroundColor: '#162c50', color: '#fff' }}
                variant="contained"
                startIcon={<CloseIcon />}
                onClick={(e) => this.viewCancel()}
              >
                Back
              </BackButton>
            </div>
          </div>
          <PtsModal
            open={this.state.profileInfoModel}
            onClose={this.profileInfoModelToggle}
            title="Family Care Circle "
          >
            <h5>Profile Information</h5>
            <Row>
              <Col md={12}>
                <ul style={{ listStyle: 'none' }}>
                  <li>
                    {' '}
                    <p>
                      <b style={{ color: '#2B88C7', fontSize: '15px' }}>Authorized Caregiver - </b>
                      {/* </h6>
                    <p> */}{' '}
                      This role has total responsibility for the Circle.They represent the Member “Center of the Circle”
                      with the ability to expand the Circle to other caregivers, assign the roles to other caregivers
                      and delete the circle.Typically this role is assigned to one person when the Member needs someone
                      to represent them.
                    </p>
                  </li>
                  <li>
                    {' '}
                    <p>
                      <b style={{ color: '#2B88C7', fontSize: '15px' }}>Family Manager - </b>
                      This role also has the ability to expand the Circle and has all rights needed to create
                      appointments and communicate with others. Typically this role is assigned to a close relative.
                      This role is very similar to Authorized Caregiver with the exception of the ability to delete the
                      Circle for the Member.
                    </p>
                  </li>
                  <li>
                    {' '}
                    <p>
                      <b style={{ color: '#2B88C7', fontSize: '15px' }}>Family Member - </b>
                      As an important caregiver, they can help manage all appointments. They are restricted from adding
                      others to the Circle. Typically this role is assigned to extended relatives or close friends.
                    </p>
                  </li>
                  <li>
                    {' '}
                    <p>
                      <b style={{ color: '#2B88C7', fontSize: '15px' }}>Transporter - </b>
                      Can only view Appointments/Events for which they are responsible for transportation. Typically
                      this role is assigned to friends that are wanting to help by offering to take the loved one to an
                      appointment. This is the most restrictive role.
                    </p>
                  </li>
                </ul>
              </Col>{' '}
            </Row>
          </PtsModal>
        </>
      );
    }
  }
}
