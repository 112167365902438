import { CLEAR_PREMIUM_FEATURE, GET_PREMIUM_FEATURE, SET_PREMIUM_FEATURE } from './PremiumFeatureType';

const getPremiumFeature = () => {
  return {
    type: GET_PREMIUM_FEATURE,
  };
};

const setPremiumFeature = (data) => {
  return {
    type: SET_PREMIUM_FEATURE,
    payload: data,
  };
};

const clearPremiumFeature = (data) => {
  return {
    type: CLEAR_PREMIUM_FEATURE,
    payload: data,
  };
};
const PremiumFeatureAction = { getPremiumFeature, setPremiumFeature, clearPremiumFeature };
export default PremiumFeatureAction;
