import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import store from '../../../redux/store';
import DeleteIconButton from '../buttons/DeleteIconButton';
import EditIconButton from '../buttons/EditIconButton';
import PtsAlert from '../ptsAlert/PtsAlert';

class Sponsorship extends Component {
  header = store.getState().header.header;
  constructor(props) {
    super(props);
    let userProfileData = props.userProfileData;
    let sponsorAddress = [];
    let sponsorDetails = props.sponsorUser;
    let sponsorUser = {};
    if (props.sponsorUser !== null) {
      sponsorUser = props.sponsorUser.sponsorship;
      sponsorAddress = sponsorUser.sponsor ? sponsorUser.sponsor.address : {};
    }

    let sponsorCodeEdit = false;
    let sponsorCodeView = true;
    if (props.sponsorUser === null) {
      sponsorCodeEdit = true;
      sponsorCodeView = false;
    } else {
      sponsorCodeEdit = false;
      sponsorCodeView = true;
    }

    this.state = {
      sponsorCode: props.sponsorUser ? props.sponsorUser.code : '',
      userEmail: userProfileData.email,
      sponsorDetails: sponsorUser ? sponsorUser : {},
      sponsorCodeEdit: sponsorCodeEdit,
      sponsorCodeView: sponsorCodeView,
      sponsorAddress: sponsorAddress,
      userId: userProfileData.id,
      // onSuccess: props.onSuccess,
    };
  }

  handleSponsorCodeChange(e) {
    this.setState({ sponsorCode: e.target.value });
  }
  updateSponsorCode() {
    this.setState({
      sponsorCodeEdit: true,
      sponsorCodeView: false,
    });
  }
  cancelSponsorVerification() {
    this.setState({
      sponsorCodeEdit: false,
      sponsorCodeView: true,
    });
  }
  sponsorVerificationCodeInBackend = (e) => {
    const postObject = {
      header: this.header,
      appuserVo: {
        sponsorCode: this.state.sponsorCode,
        email: this.state.userEmail,
      },
    };
    FetchServerData.callPostService('memberms/updateSponsorCode', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let sponsorship = output.data.sponsorshipUser;
          PtsAlert.success(output.message);
          this.props.onSuccess();
          let sponsorData = {};
          if (sponsorship !== null) {
            sponsorData = sponsorship;
          } else {
            sponsorData = {};
          }
          this.setState({
            sponsorDetails: sponsorData.sponsorship,
            sponsorCode: sponsorData.code,
            userEmail: sponsorData.email,
            sponsorAddress: sponsorData.sponsorship.sponsor.address,
            // sponsorCode: '',
            sponsorCodeEdit: false,
            sponsorCodeView: true,
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  deleteSponsorCode() {
    const postObject = {
      header: this.header,
      appuserVo: {
        id: this.state.userId,
        sponsorCode: this.state.sponsorCode,
        email: this.state.userEmail,
      },
    };

    FetchServerData.callPostService('/userms/deleteSponsorCode', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        this.props.onSuccess();
        this.setState({
          sponsorCodeEdit: true,
          sponsorCodeView: false,
          sponsorCode: '',
        });
      } else {
      }
    });
  }
  render() {
    return (
      <div>
        {this.state.sponsorCodeEdit && (
          <Card style={{ height: '100%', borderRadius: '20px' }} className="Sponsor">
            <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
              <Row>
                <Col md={12}>
                  <Label style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em' }}>
                    Sponsorship Details
                  </Label>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <Row md={12} style={{ padding: '0.5em' }}>
                <Col md={4}>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    required
                    fullWidth
                    id="sponsorCode"
                    onChange={this.handleSponsorCodeChange.bind(this)}
                    label="Sponsor Code"
                    name="sponsorCode"
                    inputProps={{ maxLength: 128 }}
                    autoFocus
                    value={this.state.sponsorCode}
                  />
                </Col>
                <Col md={2}>
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      background: '#f26d21',
                      color: '#fff',

                      borderBottom: '3px solid #636262',
                      borderRadius: '5px',
                      marginTop: '2em',
                    }}
                    onClick={(e) => this.sponsorVerificationCodeInBackend(e)}
                  >
                    Save
                  </Button>

                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      background: '#717373',
                      color: '#fff',

                      borderBottom: '3px solid #636262',
                      borderRadius: '5px',
                      marginTop: '2em',
                      marginLeft: '1em',
                    }}
                    onClick={(e) => this.cancelSponsorVerification(e)}
                  >
                    Cancel
                  </Button>
                </Col>
                <Col md={6}></Col>
              </Row>
            </CardBody>
          </Card>
        )}
        {this.state.sponsorCodeView && (
          <>
            <Row>
              <Col md={6}>
                <Card style={{ height: '100%', borderRadius: '20px' }} className="Sponsor">
                  <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                    <Row>
                      <Col md={8}>
                        <Label style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em' }}>
                          Sponsorship Details
                        </Label>
                      </Col>
                      {this.state.sponsorCodeEdit === false ? (
                        <Col md={4}>
                          <DeleteIconButton
                            className="deleteIncidentNote"
                            domainObject="Sponsor Code"
                            style={{
                              color: '#76767d',
                              fontSize: 'x-large',
                            }}
                            onClick={(e) => this.deleteSponsorCode()}
                          />
                          <EditIconButton onClick={this.updateSponsorCode.bind(this)} />
                        </Col>
                      ) : (
                        ''
                      )}
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      {this.state.sponsorCode && (
                        <>
                          <Col md={2}>
                            <label style={{ paddingRight: '0px' }}>Code :</label>
                          </Col>
                          <Col md={10}>
                            <span style={{ color: '#000' }}>{this.state.sponsorCode}</span>
                          </Col>
                        </>
                      )}
                    </Row>

                    <Row>
                      {this.state.sponsorDetails.sponsor && (
                        <>
                          <Col md={2}>
                            <label style={{ paddingRight: '0px' }}>Start Date :</label>
                          </Col>
                          <Col md={10}>
                            <span style={{ color: '#000' }}>
                              {this.state.sponsorDetails
                                ? moment(this.state.sponsorDetails.startDate).format('DD-MMM-YYYY')
                                : ''}
                            </span>
                          </Col>
                        </>
                      )}
                    </Row>
                    <Row>
                      {this.state.sponsorDetails.sponsor && (
                        <>
                          <Col md={2}>
                            <label style={{ paddingRight: '0px' }}>End Date :</label>
                          </Col>
                          <Col md={10}>
                            <span style={{ color: '#000' }}>
                              {this.state.sponsorDetails
                                ? moment(this.state.sponsorDetails.endDate).format('DD-MMM-YYYY')
                                : ''}
                            </span>
                          </Col>
                        </>
                      )}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={6}>
                <Card style={{ height: '100%', borderRadius: '20px' }} className="Sponsor">
                  <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                    <Row>
                      <Col md={12}>
                        <Label style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em' }}>
                          Sponsor Details
                        </Label>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      {this.state.sponsorDetails.sponsor && (
                        <>
                          <Col md={2}>
                            <label style={{ paddingRight: '0px' }}>Name :</label>
                          </Col>
                          <Col md={10}>
                            <span style={{ color: '#000' }}>{this.state.sponsorDetails.sponsor.name}</span>
                          </Col>
                        </>
                      )}
                    </Row>
                    <Row>
                      {this.state.sponsorDetails.sponsor && (
                        <>
                          <Col md={2}>
                            <label style={{ paddingRight: '0px' }}>Website :</label>
                          </Col>
                          <Col md={10}>
                            <span style={{ color: '#000' }}>{this.state.sponsorDetails.sponsor.website}</span>
                          </Col>
                        </>
                      )}
                    </Row>
                    <Row>
                      {this.state.sponsorDetails.sponsor && (
                        <>
                          <Col md={2}>
                            <label style={{ paddingRight: '0px' }}>Fax :</label>
                          </Col>
                          <Col md={10}>
                            <span style={{ color: '#000' }}>{this.state.sponsorDetails.sponsor.fax}</span>
                          </Col>
                        </>
                      )}
                    </Row>

                    <Row>
                      {this.state.sponsorDetails.sponsor && (
                        <>
                          <Col md={2}>
                            <label style={{ paddingRight: '0px' }}>Phone :</label>
                          </Col>
                          <Col md={10}>
                            <span style={{ color: '#000' }}>{this.state.sponsorDetails.sponsor.phone}</span>
                          </Col>
                        </>
                      )}
                    </Row>

                    <Row>
                      {this.state.sponsorDetails.sponsor && (
                        <>
                          <Col md={2} style={{ paddingRight: '0px' }}>
                            <label style={{ paddingRight: '0px' }}>Address :</label>
                          </Col>
                          <Col md={10}>{Utils.getAddressAsText(this.state.sponsorAddress)}</Col>
                        </>
                      )}
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </div>
    );
  }
}

export default Sponsorship;
