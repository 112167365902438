import { Card } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import TextField from '@material-ui/core/TextField';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import SendIcon from '@material-ui/icons/Send';
import 'intro.js/introjs.css';
import MaterialTable from 'material-table';
import React from 'react';
import Avatar from 'react-avatar';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { connect } from 'react-redux';
import ScrollToBottom from 'react-scroll-to-bottom';
import { CardBody, CardHeader, Col, Label, Modal, ModalBody, ModalHeader, ModalFooter, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import UiUtils from '../../../provider/UiUtils';
import Utils from '../../../provider/Utils';
import helpDataAction from '../../../redux/help/displayHelpAction';
import store from '../../../redux/store';
import MemberAppointment from '../appointment/MemberAppointment';
import CancelIconButton from '../buttons/CancelIconButton';
import MemberEvent from '../events/MemberEvent';
import MemberIncident from '../incident/MemberIncident';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
import PtsAlert from '../ptsAlert/PtsAlert';
import MyPurchase from '../purchases/MyPurchase';
import './MemberCommunication.scss';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import Tooltip from '@material-ui/core/Tooltip';
import memberListAction from '../../../redux/memberList/memberListAction';
import BreadCrumbDetailsAction from '../../../redux/breadCrumbDetails/BreadCrumbDetailsAction';
export class MemberCommunication extends React.Component {
  title = 'Communications';
  messageListRef = [];
  isFreeUser = store.getState().userData.userData.isFreeUser;
  displayHelp = store.getState().help.help;
  header = store.getState().header.header;
  memberDetails = store.getState().memberData.memberData;
  memberName = store.getState().memberData.memberData.firstname + ' ' + store.getState().memberData.memberData.lastname;
  fccPageState = store.getState().fccPageState.fccPageState;
  memberHomeUrl = store.getState().memberHomeUrl.memberHomeUrl.homeUrl;
  memberNamesList = store.getState().memberList.memberList;
  breadCrumb = [
    { pageid: this.memberHomeUrl, uiname: this.memberName },
    { pageid: '/memberCommunication', uiname: 'Communication List' },
    { pageid: '', uiname: 'Communication Details' },
  ];

  constructor(props, context) {
    super(props, context);

    let threadId = '';
    let title = '';
    let pageId = '';
    let action = '';
    let threadData = '';
    let threadNoteId = '';
    let canRender = false;
    let str = window.location.href;
    let urlArr = str.split('?');
    let threadVo = urlArr[1];
    if (threadVo) {
      let incidentDataVo = threadVo.split('=');
      threadData = incidentDataVo[1].split('&');
      threadId = threadData[0];
      threadNoteId = incidentDataVo[2];
      threadNoteId = threadNoteId.split('&');
      threadNoteId = threadNoteId[0];
      action = 'view';
      this.getCommunicationList(threadId);
    } else {
      action = props.action;
      threadId = props.threadId;

      if (!threadId && props.history && props.history.location && props.history.location.state) {
        threadId = props.history.location.state.threadId;
        action = props.history.location.state.action;
        pageId = props.history.location.state.pageId;
        title = props.history.location.state.title;
      }
      canRender = false;
      if (null !== threadId && threadId > 0) {
        this.getCommunicationList(threadId);
      } else {
        canRender = true;
      }

      if (threadId === undefined) {
        threadId = this.fccPageState.id;
        action = this.fccPageState.action;
        title = this.fccPageState.title;
        if (action !== 'create') {
          this.getCommunicationList(threadId);
        }
      }
    }

    this.state = {
      id: threadId,
      subject: '',
      participantList: [],
      noteId: '',
      messageList: [],
      senderId: '',
      sendText: '',
      historyDetailsModal: false,
      historyDetails: [],
      action: action,
      anchorEl: '',
      participantMemberList: [],
      title: title,
      type: '',
      location: '',
      pageId: pageId,
      note: '',
      render: false,
      date: '',
      incidentType: '',
      incidentDescription: '',
      appointmentType: '',
      appointmentDescription: '',
      purpose: '',
      appointmentOccurance: '',
      purchaseDate: '',
      purchaseType: '',
      purchaseDescription: '',
      purchaseOccurance: '',
      prescribedBy: '',
      shortDescription: '',
      isPaging: false,
      pageSize: 10,
      appointmentModal: false,
      appointmentId: '',
      incidentOccurance: '',
      isAppointment: false,
      isPurchase: false,
      isIncident: false,
      incidentId: '',
      purchaseId: '',
      isFreeUser: this.isFreeUser,
      threadNoteId: threadNoteId,
      stepsEnabled: false,
      initialStep: 0,
      eventType: '',
      eventDate: '',
      eventDescription: '',
      eventId: '',
      isEvent: false,
      addParticipateModal: false,
      displayFloatButton: this.displayHelp,
      isCreatedByUser: false,
    };
    this.getMemberList();
  }
  toggleSteps = () => {
    this.setState((prevState) => ({ stepsEnabled: !prevState.stepsEnabled }));
  };

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };
  /*Real time messaging thread functionality */
  componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      let newThreadId = '';
      let receiveMessageList = this.state.messageList;
      let newCommunictionData = store.getState().messageThreadsData.messageThreadsData;
      for (let i = 0; i < newCommunictionData.length; i++) {
        newThreadId = newCommunictionData[i].MsgThreadId;
        if (this.state.id === newThreadId) {
          let receivedMessageObj = {
            SubDomainObjectName: '',
            senderName: newCommunictionData[i].MsgCreatedBy,
            DomainObjectId: '',
            SubDomainObjectId: '',
            MsgDate: newCommunictionData[i].MsgDate,
            MsgThreadId: newCommunictionData[i].MsgThreadId,
            MessageType: newCommunictionData[i].MessageType,
            DomainObjectName: '',
            MemberId: newCommunictionData[i].MemberId,
            MsgId: newCommunictionData[i].MsgId,
            body: newCommunictionData[i].MsgContent,
            sender: {
              id: 0,
            },
          };
          receiveMessageList.push(receivedMessageObj);
          this.setState({
            messageList: receiveMessageList,
          });
        }
      }
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  getCommunicationList(threadId) {
    let sender = this.header.appuserId;

    const postObject = {
      header: this.header,
      messageThread: {
        id: threadId,
      },
    };

    FetchServerData.callPostService('messageThreadms/getThread', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let communicationList = output.data.messageThread;
        this.messageListRef = communicationList.messageList;
        let getDomainObjectPrivileges = output.data.domainObjectPrivileges;

        let getCreatePermission = false;
        let getReadPermission = false;
        let getUpdatePermission = false;
        let getDeletePermission = false;

        for (let domainObjectKey in getDomainObjectPrivileges) {
          if (domainObjectKey.toLowerCase() === 'messagethread') {
            let communicationPermission = getDomainObjectPrivileges[domainObjectKey];
            let getPermission = Utils.getDomainObjectRolePermission(communicationPermission);
            getCreatePermission = getPermission[0];
            getReadPermission = getPermission[1];
            getUpdatePermission = getPermission[2];
            getDeletePermission = getPermission[3];
          }
        }
        this.setState({
          render: true,
          senderId: sender,
          type: communicationList.type,
          location: communicationList.location,
          note: communicationList.note,
          threadId: communicationList.id,
          subject: communicationList.subject,
          date: communicationList.date,
          incidentDescription: communicationList.incidentDescription,
          incidentType: communicationList.incidentType,
          appointmentId: communicationList.appointmentId,
          appointmentType: communicationList.appointmentType,
          appointmentDescription: communicationList.appointmentDescription,
          purpose: communicationList.purpose,
          appointmentOccurance: communicationList.appointmentOccurance,
          purchaseId: communicationList.purchaseId,
          purchaseDate: communicationList.purchaseDate,
          purchaseType: communicationList.purchaseType,
          purchaseDescription: communicationList.purchaseDescription,
          purchaseOccurance: communicationList.purchaseOccurance,
          prescribedBy: communicationList.prescribedBy,
          messageList: this.messageListRef,
          participantMemberList: communicationList.participantList,
          shortDescription: communicationList.shortDescription,
          incidentOccurance: communicationList.incidentOccurance,
          incidentId: communicationList.incidentId,
          accompanyName: communicationList.accompanyName,
          eventId: communicationList.eventId,
          eventDate: communicationList.eventDate,
          eventType: communicationList.eventType,
          eventDescription: communicationList.eventDescription,
          isCreatedByUser: communicationList.isCreatedByUser,
          getUpdatePermission: getUpdatePermission,
          getDeletePermission: getDeletePermission,
          getReadPermission: getReadPermission,
          getCreatePermission: getCreatePermission,
        });
      } else {
      }
    });
  }

  handleSubjectChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ subject: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }

  handleSendTextChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 512);
    if (res.length < 512) {
      this.setState({ sendText: res });
    } else {
      PtsAlert.error('The size of this field is limited to 512 characters only');
    }
  }
  getMemberList = () => {
    const postObject = {
      header: this.header,
      member: {
        id: this.memberDetails.member,
      },
      messageThreadType: {
        type: 'General',
      },
    };
    let url = 'memberms/getMessageThreadParticipants';
    FetchServerData.callPostService(url, postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let contactList = [];
        let memberList = [];
        let participantList = [];
        contactList = output.data.contactList;
        memberList = output.data;
        for (let i = 0; i < memberList.length; i++) {
          if (memberList[i].isMember === true) {
            memberList[i].checked = true;
            let participantMemberObj = {
              user: {
                id: memberList[i].id,
              },
            };
            participantList.push(participantMemberObj);
          } else {
            memberList[i].checked = false;
          }
        }
        let isPaging = false;
        if (memberList.length > 10) {
          isPaging = true;
        }
        this.setState({
          render: true,
          isPaging: isPaging,
          noteContactList: contactList,
          noteMemberList: memberList,
          addMessageModal: true,
          noteId: this.state.id,
          participantList: participantList,
        });
      } else {
      }
    });
  };

  seenMessageHistoryToggle = () => {
    this.setState({
      historyDetailsModal: !this.state.historyDetailsModal,
    });
  };

  selectedParticipant(e, rowData, type) {
    const { checked } = e.target;
    let participantList = this.state.participantList;

    let memberObj = {};
    if (checked === true) {
      memberObj = {
        user: {
          id: rowData.id,
        },
      };
      participantList.push(memberObj);

      this.setState({
        participantList: participantList,
      });
    } else {
      for (let i = 0; i < participantList.length; i++) {
        if (participantList[i].user.id === rowData.id) {
          participantList.splice(i, 1);
        }
      }
      this.setState({
        participantList: participantList,
      });
    }
  }

  createMessageThreadInBackend = (e) => {
    const postObject = {
      header: this.header,
      messageThread: {
        subject: this.state.subject,
        member: this.memberDetails.member,

        type: {
          type: 'General',
        },
        participantList: this.state.participantList,
        // appointmentNote: {},

        messageList: [],
      },
    };
    FetchServerData.callPostService('/messageThreadms/create', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let messageData = output.data.messageThread;
          PtsAlert.success(output.message);
          this.props.history.push({
            pathname: '/viewCommunication',
            state: { action: 'view', threadId: messageData.id },
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  sendMessage() {
    const postObject = {
      header: this.header,
      messageThread: {
        id: this.state.threadId,
        messageList: [
          {
            body: this.state.sendText,
            operation: 'Add',
          },
        ],
      },
    };
    FetchServerData.callPostService('/messageThreadms/update', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let threadId = output.data.messageThread.id;
          let message = output.data.messageThread.messageList[0];
          let receiveMessage = this.state.messageList;
          receiveMessage.push(message);
          this.setState({
            render: true,

            threadId: threadId,
            sendText: '',
            messageList: receiveMessage,
          });
          this.getCommunicationList(this.state.threadId);
        } else {
        }
      })
      .catch((error) => {});
  }

  /* Call backend service for read message participant List */
  viewMessageHistoryDetails = (e, messageId) => {
    let id = messageId;

    const postObject = {
      header: this.header,
      message: {
        id: id,
      },
    };
    FetchServerData.callPostService('messagems/readMessageUsers', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let historyDetails = output.data;

        this.setState({
          render: true,
          historyDetailsModal: true,
          historyDetails: historyDetails,
        });
      } else {
      }
    });
  };

  /* Read message participant List */

  showReadMessageParticipantList() {
    this.state.historyDetails.map((data) => {
      let fullName = data.name;
      if (this.memberNamesList.includes(fullName) === false) {
        this.memberNamesList.push(fullName);
        store.dispatch(memberListAction.setMemberList(this.memberNamesList));
      }
    });

    let participantList = this.state.historyDetails.map((member, index) => {
      return (
        <>
          <List
            style={{
              backgroundColor: index % 2 === 0 ? '#01c770' : '#00aded',
              paddingTop: '0em',
              borderBottom: '0px',
            }}
          >
            <ListItem style={{ padding: '1em' }}>
              <ListItemAvatar>
                {Utils.getMemberName(member.name, this.memberNamesList.indexOf(member.name) % 26)}
              </ListItemAvatar>
              <span style={{ color: '#fff', padding: '1em' }}>{member.name}</span>{' '}
            </ListItem>{' '}
          </List>
          <Divider style={{ borderBottom: '8px solid #fff' }} />
        </>
      );
    });
    return participantList;
  }
  /* show messaging thread participant List */
  showParticipantList() {
    const colours = ['#F39C12', '#A569BD', '#2ECC71', '#EC7063', '#5DADE2'];

    this.state.participantMemberList.map((data) => {
      let fullName = data.participantName;
      if (this.memberNamesList.includes(fullName) === false) {
        this.memberNamesList.push(fullName);
        store.dispatch(memberListAction.setMemberList(this.memberNamesList));
      }
    });

    let participantList = this.state.participantMemberList.map((member, index) => {
      return (
        <>{Utils.getMemberName(member.participantName, this.memberNamesList.indexOf(member.participantName) % 26)}</>
      );
    });
    return participantList;
  }

  createCancel() {
    this.props.history.push({
      pathname: '/memberCommunication',
    });
  }

  viewCancel = () => {
    this.props.history.push({
      pathname: '/memberCommunication',
    });
  };

  appointmentModalToggle = () => {
    this.setState({ appointmentModal: !this.state.appointmentModal });
  };

  showAppointment(type) {
    if (type === 'Appointment') {
      this.setState({
        isAppointment: true,
        appointmentModal: true,
      });
    } else if (type === 'Purchase') {
      this.setState({
        isPurchase: true,
        appointmentModal: true,
      });
    } else if (type === 'Incident') {
      this.setState({
        isIncident: true,
        appointmentModal: true,
      });
    } else if (type === 'Event') {
      this.setState({
        isEvent: true,
        appointmentModal: true,
      });
    }
  }

  printAppointmentContent() {
    let printContents = document.getElementById('printform').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();

    document.body.innerHTML = originalContents;
    this.setState({ render: true });
    document.location.reload(true);
    this.setState({
      isViewButtonHide: false,
      action: 'view',
      render: true,
    });
  }
  render() {
    this.messageListRef = React.createRef();
    this.incidentTypeRef = React.createRef();
    if (this.state.render === false) {
      return <div />;
    } else if (this.state.action === 'create') {
      return this.createCommunication();
    } else if (this.state.action === 'edit') {
      return this.editCommunication();
    } else if (this.state.action === 'view') {
      return this.viewCommunication();
    }
  }

  createCommunication() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <MenuWithBreadScrum breadCrumb={this.breadCrumb} history={this.props.history} name={this.title} />
          <ValidatorForm style={{ width: '100%' }} ref="form" onSubmit={this.createMessageThreadInBackend.bind(this)}>
            <Row style={{ position: 'relative', margin: '1em' }}>
              <Col md={12} style={{ position: 'relative', padding: '5px' }}>
                <Card style={{ height: '100%', borderRadius: '20px' }}>
                  <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                    <Row>
                      <Col md={12}>
                        {undefined !== this.state.title && this.state.title.length > 0 && (
                          <Label
                            style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                          >
                            {this.state.title}
                          </Label>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody style={{ padding: '1em', marginTop: '0em' }}>
                    <Row style={{ marginTop: '-1em' }}>
                      <Col md={6}>
                        <TextField
                          margin="normal"
                          variant="outlined"
                          required
                          fullWidth
                          id="subject"
                          inputProps={{ maxLength: 64 }}
                          onChange={this.handleSubjectChange.bind(this)}
                          label="Title"
                          name="subject"
                          value={this.state.subject}
                        />
                      </Col>
                    </Row>

                    <Row style={{ paddingTop: '1em' }}>
                      <Col md={12}>
                        <MaterialTable
                          title="Participant List"
                          icons={UiUtils.getMaterialTableIcons()}
                          data={this.state.noteMemberList}
                          localization={{
                            body: {
                              emptyDataSourceMessage: 'Please add Caregivers to start communication',
                            },
                          }}
                          columns={[
                            { title: '#', render: (rowData) => rowData.tableData.id + 1, width: '5%' },
                            {
                              title: 'Name',
                              render: (rowData) => (
                                <>
                                  <span>
                                    <Avatar
                                      name={null !== rowData.name && undefined !== rowData.name ? rowData.name : ''}
                                      round="30px"
                                      size="30px"
                                      style={{ marginRight: '4px' }}
                                    />
                                  </span>
                                  <span>{rowData.name}</span>
                                </>
                              ),
                            },
                            {
                              title: 'Select',
                              field: 'imageUrl',
                              width: '10%',

                              render: (rowData) => (
                                <input
                                  style={{ height: 18, width: 18, marginLeft: 10 }}
                                  type="checkbox"
                                  defaultChecked={rowData.checked}
                                  name="checkedSacCode"
                                  onChange={(e) => this.selectedParticipant(e, rowData, 'member')}
                                />
                              ),
                            },
                          ]}
                          options={{
                            sorting: true,
                            actionsColumnIndex: -1,
                            pageSize: this.state.pageSize,
                            paging: this.state.isPaging,
                            headerStyle: {
                              backgroundColor: '#1ABC9C',
                              fontWeight: 'bold',
                              color: '#fff',
                              align: 'left',
                            },
                            rowStyle: {
                              height: '2em',
                              textTransform: 'capitalize',
                            },
                          }}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <div style={{ textAlign: 'center', padding: '0em 0em 1em' }}>
              <Button variant="contained" size="small" type="submit" style={{ background: '#ef6603', color: '#fff' }}>
                Start Messaging
              </Button>
              &nbsp;&nbsp;
              <CancelIconButton onClick={(e) => this.createCancel()} />
            </div>
          </ValidatorForm>
        </>
      );
    }
  }
  addNewParticipateToggle = () => {
    this.setState({
      addParticipateModal: !this.state.addParticipateModal,
    });
  };

  addNewParticipateInBackend = (e) => {
    const postObject = {
      header: this.header,
      messageThread: {
        id: this.state.threadId,
        type: {
          type: 'General',
        },

        participantList: this.state.participantList,
      },
    };
    FetchServerData.callPostService('/messageThreadms/updateParticipants', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          PtsAlert.success(output.message);
          this.getCommunicationList(this.state.id);
          this.setState({
            addParticipateModal: false,
            createThread: false,
            viewMessage: true,
            threadLength: 1,
            isNoteConversation: true,
          });
        } else {
        }
      })
      .catch((error) => {});
  };
  addParticipantRequest = (e, noteDetails) => {
    const postObject = {
      header: this.header,
      member: {
        id: this.memberDetails.member,
      },
      messageThreadType: {
        type: 'General',
      },
    };
    let url = 'memberms/getMessageThreadParticipants';
    FetchServerData.callPostService(url, postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let contactList = [];
        let memberList = [];
        let participantList = [];
        if (output.data.contactList) {
          contactList = output.data.contactList;
        }
        if (output.data) {
          memberList = output.data;
          for (let i = 0; i < memberList.length; i++) {
            for (let j = 0; j < this.state.participantMemberList.length; j++) {
              if (this.state.participantMemberList[j].user.id === memberList[i].id) {
                memberList[i].checked = true;

                let memberObj = {
                  user: {
                    id: memberList[i].id,
                  },
                  operation: 'ADD',
                };
                participantList.push(memberObj);
              }
            }
          }
        }
        let isPaging = false;
        if (memberList.length > 10) {
          isPaging = true;
        }
        this.setState({
          isPaging: isPaging,
          noteContactList: contactList,
          noteMemberList: memberList,
          addParticipateModal: true,
          noteId: this.state.id,
          participantList: participantList,
        });
      } else {
      }
    });
  };
  selectedNewParticipant(e, rowData, type) {
    const { checked } = e.target;
    let participantList = this.state.participantList;
    let contactObj = {};
    let memberObj = {};
    if (checked === true) {
      if (type === 'contact') {
        contactObj = {
          contact: {
            id: rowData.id,
          },
        };
        participantList.push(contactObj);
      } else {
        memberObj = {
          user: {
            id: rowData.id,
          },
          operation: 'ADD',
        };
        participantList.push(memberObj);
      }

      this.setState({
        participantList: participantList,
      });
    } else {
      for (let i = 0; i < participantList.length; i++) {
        if (participantList[i].user.id === rowData.id) {
          participantList.splice(i, 1);
        }
      }
      this.setState({
        participantList: participantList,
      });
    }
  }
  viewCommunication() {
    if (this.state.render === false) {
      return <div />;
    } else {
      if (this.state.isFreeUser === true) {
        return this.viewCommunicationWithAdvertisement();
      } else {
        return this.viewCommunicationWithoutAdvertisement();
      }
    }
  }
  closeFloatingButton() {
    this.setState({ displayFloatButton: false });
    let displayFloat = false;

    store.dispatch(helpDataAction.setHelpData(displayFloat));
  }
  viewCommunicationWithAdvertisement() {
    if (this.state.render === false) {
      return <div />;
    } else {
      /* Separate Out sender and receiver message by matching the senderId with message list senderId */
      let chatMessageList = [];
      this.state.messageList.forEach((message, index) => {
        if (this.state.senderId === message.sender) {
          chatMessageList.push(
            <div className="communication-box-holder">
              <div className="communication-box">
                <div style={{ fontSize: '1.2em' }}>
                  <pre className="view_communication">{message.body}</pre>
                </div>
                <span className="textMsg" style={{ float: 'right', marginTop: '0.2em' }}>
                  {Utils.convertUTCDateToUserTimeZoneDate(message.createdOn)}
                  {message.status !== null && message.status === 'SENT' ? (
                    <>
                      <DoneIcon style={{ fontSize: 'small', marginLeft: '0.5em' }} />
                    </>
                  ) : (
                    <>
                      <DoneAllIcon style={{ fontSize: 'small', color: '#3498DB', marginLeft: '0.5em' }} />
                      <span style={{ fontSize: '0.7em', float: 'right', marginTop: '0.2em', marginLeft: '0.5em' }}>
                        <i
                          className="fa fa-history"
                          title="Seen By"
                          aria-hidden="true"
                          style={{ fontSize: 'small' }}
                          onClick={(e) => this.viewMessageHistoryDetails(e, message.id)}
                        ></i>
                      </span>
                    </>
                  )}
                </span>
              </div>
            </div>
          );
        } else {
          chatMessageList.push(
            <div className="communication-box-holder">
              <div className="communication-box message-partner">
                <div className="message-sender">
                  <a href="#">{message.senderName}</a>
                </div>
                <div>{message.body}</div>
                <span className="textMsg">{Utils.convertUTCDateToUserTimeZoneDate(message.createdOn)}</span>
              </div>
            </div>
          );
        }
      });
      return (
        <div>
          <MenuWithBreadScrum
            backCallback={this.viewCancel.bind(this)}
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            name={this.title}
          />

          <>
            {this.state.type.type === 'General' ? (
              <>
                <Row style={{ position: 'relative', margin: '1em' }}>
                  {' '}
                  <Col md={8} className="communicationWindow" style={{ position: 'relative' }}>
                    <div className="chatbox group-chat" style={{ minHeight: '100%', wordBreak: 'break-all' }}>
                      <div className="chatbox-top">
                        <div className="chat-group-name">
                          <span className="status away"></span>
                          <span className="showTitle" style={{ textTransform: 'capitalize' }}>
                            {this.state.subject}
                          </span>
                          {this.state.isCreatedByUser === true ? (
                            <span style={{ float: 'right', marginRight: '22px', cursor: 'pointer', marginTop: '4px' }}>
                              <Tooltip title="Add/Delete Participant">
                                <PersonAddIcon onClick={(e) => this.addParticipantRequest(e, this.props.data)} />
                              </Tooltip>
                            </span>
                          ) : (
                            <></>
                          )}
                          <span style={{ float: 'right', marginRight: '22px' }} className="showMemberList">
                            {this.showParticipantList()}
                          </span>
                        </div>
                      </div>

                      <div className="chatBox-messages">
                        <ScrollToBottom className="ROOT_CSS">{chatMessageList}</ScrollToBottom>
                      </div>
                      {this.state.getUpdatePermission === true ? (
                        <div className="chat-input-holder">
                          <textarea
                            className="chat-input"
                            value={this.state.sendText}
                            onChange={this.handleSendTextChange.bind(this)}
                            maxLength="512"
                          ></textarea>
                          <button
                            type="submit"
                            value="Send"
                            style={{
                              background: this.state.sendText === '' ? 'rgb(75 114 130)' : '#005476',
                              marginTop: '-0.1em',
                            }}
                            disabled={!this.state.sendText}
                            className="message-send"
                            onClick={(e) => this.sendMessage()}
                          >
                            <SendIcon></SendIcon>
                          </button>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Col>
                </Row>
              </>
            ) : (
              <>
                <Row style={{ position: 'relative', margin: '1em' }}>
                  <Col md={4} style={{ position: 'relative' }}>
                    {this.state.type.type === 'Incident' ? (
                      <>
                        <Card style={{ height: 'auto', borderRadius: '20px' }}>
                          <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.5em' }}>
                            <Row>
                              <Col md={12}>
                                {undefined !== this.state.type.type && this.state.type.type.length > 0 && (
                                  <Label
                                    style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}
                                    className="detailTitle"
                                  >
                                    {this.state.type.type + '  Details'}
                                  </Label>
                                )}
                              </Col>
                            </Row>
                          </CardHeader>

                          <CardBody style={{ padding: '1em', marginTop: '0em' }}>
                            <Row>
                              {this.state.date && (
                                <>
                                  <Col md={5}>
                                    <label style={{ paddingRight: '0px' }}>Date :</label>
                                  </Col>
                                  <Col md={7}>
                                    {' '}
                                    <span style={{ color: '#000' }}>
                                      {Utils.convertUTCDateToUserTimeZoneViewDateFormate(this.state.date)}
                                    </span>
                                  </Col>
                                </>
                              )}
                            </Row>
                            <Row>
                              {this.state.incidentType && (
                                <>
                                  {' '}
                                  <Col md={5}>
                                    <label>Incident Type</label>
                                  </Col>
                                  <Col md={7}>
                                    {' '}
                                    <span>{this.state.incidentType}</span>
                                  </Col>
                                </>
                              )}
                            </Row>

                            <Row>
                              {this.state.incidentDescription && (
                                <>
                                  <Col md={5}>
                                    <label>Description</label>{' '}
                                  </Col>
                                  <Col md={7}>
                                    <span>{this.state.incidentDescription}</span>
                                  </Col>
                                </>
                              )}
                            </Row>
                            <Row>
                              {this.state.location && (
                                <>
                                  {' '}
                                  <Col md={5}>
                                    <label>Location</label>
                                  </Col>
                                  <Col md={7}>
                                    {' '}
                                    <span>{this.state.location}</span>
                                  </Col>
                                </>
                              )}
                            </Row>
                            <Row style={{ marginTop: '1em' }}>
                              <Col md={12} className="viewDetails">
                                <Button
                                  variant="contained"
                                  size="small"
                                  style={{
                                    background: '#ef6603',
                                    color: '#fff',
                                    marginRight: '1rem',
                                    textTransform: 'none',
                                  }}
                                  onClick={this.showAppointment.bind(this, 'Incident')}
                                >
                                  View Incident
                                </Button>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                        <Card style={{ height: 'auto', borderRadius: '20px', marginTop: '1em' }}>
                          <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.5em' }}>
                            <Row>
                              <Col md={12}>
                                {undefined !== this.state.type.type && this.state.type.type.length > 0 && (
                                  <Label
                                    style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}
                                    className="noteTitle"
                                  >
                                    {' Note Details'}
                                  </Label>
                                )}
                              </Col>
                            </Row>
                          </CardHeader>

                          <CardBody style={{ padding: '1em', marginTop: '0em' }}>
                            <Row>
                              {this.state.shortDescription && (
                                <>
                                  {' '}
                                  <Col md={5}>
                                    <label>Title</label>
                                  </Col>
                                  <Col md={7}>
                                    {' '}
                                    <span>{this.state.shortDescription}</span>
                                  </Col>
                                </>
                              )}
                            </Row>

                            <Row>
                              {this.state.incidentOccurance && (
                                <>
                                  {' '}
                                  <Col md={5}>
                                    <label>Occurrence</label>
                                  </Col>
                                  <Col md={7}>
                                    {' '}
                                    <span>{this.state.incidentOccurance.occurance}</span>
                                  </Col>
                                </>
                              )}
                            </Row>

                            <Row>
                              {this.state.note && (
                                <>
                                  {' '}
                                  <Col md={5}>
                                    <label>Note</label>
                                  </Col>
                                  <Col md={7}>
                                    {' '}
                                    <span>{this.state.note}</span>
                                  </Col>
                                </>
                              )}
                            </Row>
                          </CardBody>
                        </Card>
                      </>
                    ) : (
                      ''
                    )}
                    {this.state.type.type === 'Appointment' ? (
                      <>
                        <Card style={{ height: 'auto', borderRadius: '20px' }}>
                          <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.5em' }}>
                            <Row>
                              <Col md={12}>
                                {undefined !== this.state.type.type && this.state.type.type.length > 0 && (
                                  <Label
                                    style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}
                                    className="detailTitle"
                                  >
                                    {this.state.type.type + '  Details'}
                                  </Label>
                                )}
                              </Col>
                            </Row>
                          </CardHeader>

                          <CardBody style={{ padding: '1em', marginTop: '0em' }}>
                            <Row>
                              {this.state.date && (
                                <>
                                  <Col md={5}>
                                    <label style={{ paddingRight: '0px' }}>Date</label>
                                  </Col>
                                  <Col md={7}>
                                    {' '}
                                    <span style={{ color: '#000' }}>
                                      {Utils.convertUTCDateToUserTimeZoneViewDateFormate(this.state.date)}
                                    </span>
                                  </Col>
                                </>
                              )}
                            </Row>
                            <Row>
                              {this.state.appointmentType && (
                                <>
                                  {' '}
                                  <Col md={5}>
                                    <label>Type</label>
                                  </Col>
                                  <Col md={7}>
                                    {' '}
                                    <span>{this.state.appointmentType.type}</span>
                                  </Col>
                                </>
                              )}
                            </Row>
                            <Row>
                              {this.state.purpose && (
                                <>
                                  {' '}
                                  <Col md={5}>
                                    <label>Purpose</label>
                                  </Col>
                                  <Col md={7}>
                                    {' '}
                                    <span>{this.state.purpose}</span>
                                  </Col>
                                </>
                              )}
                            </Row>

                            <Row>
                              {this.state.appointmentDescription && (
                                <>
                                  <Col md={5}>
                                    <label>Description</label>{' '}
                                  </Col>
                                  <Col md={7}>
                                    <span>{this.state.appointmentDescription}</span>
                                  </Col>
                                </>
                              )}
                            </Row>
                            <Row>
                              {this.state.location && (
                                <>
                                  {' '}
                                  <Col md={5}>
                                    <label>Location</label>
                                  </Col>
                                  <Col md={7}>
                                    {' '}
                                    <span>{this.state.location}</span>
                                  </Col>
                                </>
                              )}
                            </Row>
                            <Row>
                              {this.state.accompanyName && (
                                <>
                                  {' '}
                                  <Col md={5}>
                                    <label>Accompany Name</label>
                                  </Col>
                                  <Col md={7}>
                                    {' '}
                                    <span>{this.state.accompanyName}</span>
                                  </Col>
                                </>
                              )}
                            </Row>
                            <Row style={{ marginTop: '1em' }}>
                              <Col md={12} className="viewDetails">
                                <Button
                                  variant="contained"
                                  size="small"
                                  style={{
                                    background: '#ef6603',
                                    color: '#fff',
                                    marginRight: '1rem',
                                    textTransform: 'none',
                                  }}
                                  onClick={this.showAppointment.bind(this, 'Appointment')}
                                >
                                  View Appointment
                                </Button>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                        <Card style={{ height: 'auto', borderRadius: '20px', marginTop: '1em' }}>
                          <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.5em' }}>
                            <Row>
                              <Col md={12}>
                                {undefined !== this.state.type.type && this.state.type.type.length > 0 && (
                                  <Label
                                    style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}
                                    className="noteTitle"
                                  >
                                    {' Note Details'}
                                  </Label>
                                )}
                              </Col>
                            </Row>
                          </CardHeader>

                          <CardBody style={{ padding: '1em', marginTop: '0em' }}>
                            <Row>
                              {this.state.shortDescription && (
                                <>
                                  {' '}
                                  <Col md={5}>
                                    <label>Title</label>
                                  </Col>
                                  <Col md={7}>
                                    {' '}
                                    <span>{this.state.shortDescription}</span>
                                  </Col>
                                </>
                              )}
                            </Row>

                            <Row>
                              {this.state.appointmentOccurance && (
                                <>
                                  {' '}
                                  <Col md={5}>
                                    <label>Occurrence</label>
                                  </Col>
                                  <Col md={7}>
                                    {' '}
                                    <span>{this.state.appointmentOccurance.occurance}</span>
                                  </Col>
                                </>
                              )}
                            </Row>

                            <Row>
                              {this.state.note && (
                                <>
                                  {' '}
                                  <Col md={5}>
                                    <label>Note</label>
                                  </Col>
                                  <Col md={7}>
                                    {' '}
                                    <span>{this.state.note}</span>
                                  </Col>
                                </>
                              )}
                            </Row>
                          </CardBody>
                        </Card>
                      </>
                    ) : (
                      ''
                    )}

                    {this.state.type.type === 'Purchase' ? (
                      <>
                        <Card style={{ height: 'auto%', borderRadius: '20px' }}>
                          <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.5em' }}>
                            <Row>
                              <Col md={12}>
                                {undefined !== this.state.type.type && this.state.type.type.length > 0 && (
                                  <Label
                                    style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}
                                    className="detailTitle"
                                  >
                                    {this.state.type.type + '  Details'}
                                  </Label>
                                )}
                              </Col>
                            </Row>
                          </CardHeader>

                          <CardBody style={{ padding: '1em', marginTop: '0em' }}>
                            <Row>
                              {this.state.purchaseDate && (
                                <>
                                  <Col md={5}>
                                    <label style={{ paddingRight: '0px' }}>Date</label>
                                  </Col>
                                  <Col md={7}>
                                    {' '}
                                    <span style={{ color: '#000' }}>
                                      {Utils.convertUTCDateToUserTimeZoneViewDateFormate(this.state.purchaseDate)}
                                    </span>
                                  </Col>
                                </>
                              )}
                            </Row>
                            <Row>
                              {this.state.purchaseType && (
                                <>
                                  {' '}
                                  <Col md={5}>
                                    <label>Purchase Type</label>
                                  </Col>
                                  <Col md={7}>
                                    {' '}
                                    <span>{this.state.purchaseType}</span>
                                  </Col>
                                </>
                              )}
                            </Row>

                            <Row>
                              {this.state.purchaseDescription && (
                                <>
                                  <Col md={5}>
                                    <label>Description</label>{' '}
                                  </Col>
                                  <Col md={7}>
                                    <span>{this.state.purchaseDescription}</span>
                                  </Col>
                                </>
                              )}
                            </Row>
                            <Row>
                              {this.state.prescribedBy && (
                                <>
                                  {' '}
                                  <Col md={5}>
                                    <label>Prescribed By</label>
                                  </Col>
                                  <Col md={7}>
                                    {' '}
                                    <span>{this.state.prescribedBy}</span>
                                  </Col>
                                </>
                              )}
                            </Row>
                            <Row style={{ marginTop: '1em' }}>
                              <Col md={12} className="viewDetails">
                                <Button
                                  variant="contained"
                                  size="small"
                                  style={{
                                    background: '#ef6603',
                                    color: '#fff',
                                    marginRight: '1rem',
                                    textTransform: 'none',
                                  }}
                                  onClick={this.showAppointment.bind(this, 'Purchase')}
                                >
                                  View Purchase
                                </Button>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                        <Card style={{ height: 'auto', borderRadius: '20px', marginTop: '1em' }}>
                          <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.5em' }}>
                            <Row>
                              <Col md={12}>
                                {undefined !== this.state.type.type && this.state.type.type.length > 0 && (
                                  <Label
                                    style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}
                                    className="noteTitle"
                                  >
                                    {' Note Details'}
                                  </Label>
                                )}
                              </Col>
                            </Row>
                          </CardHeader>

                          <CardBody style={{ padding: '1em', marginTop: '0em' }}>
                            <Row>
                              {this.state.shortDescription && (
                                <>
                                  {' '}
                                  <Col md={5}>
                                    <label>Title</label>
                                  </Col>
                                  <Col md={7}>
                                    {' '}
                                    <span>{this.state.shortDescription}</span>
                                  </Col>
                                </>
                              )}
                            </Row>

                            <Row>
                              {this.state.purchaseOccurance && (
                                <>
                                  {' '}
                                  <Col md={5}>
                                    <label>Occurrence</label>
                                  </Col>
                                  <Col md={7}>
                                    {' '}
                                    <span>{this.state.purchaseOccurance.occurance}</span>
                                  </Col>
                                </>
                              )}
                            </Row>

                            <Row>
                              {this.state.note && (
                                <>
                                  {' '}
                                  <Col md={5}>
                                    <label>Note</label>
                                  </Col>
                                  <Col md={7}>
                                    {' '}
                                    <span>{this.state.note}</span>
                                  </Col>
                                </>
                              )}
                            </Row>
                          </CardBody>
                        </Card>
                      </>
                    ) : (
                      ''
                    )}
                    {this.state.type.type === 'Event' ? (
                      <>
                        <Card style={{ height: 'auto', borderRadius: '20px' }}>
                          <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.5em' }}>
                            <Row>
                              <Col md={12}>
                                {undefined !== this.state.type.type && this.state.type.type.length > 0 && (
                                  <Label
                                    style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}
                                    className="detailTitle"
                                  >
                                    {this.state.type.type + '  Details'}
                                  </Label>
                                )}
                              </Col>
                            </Row>
                          </CardHeader>

                          <CardBody style={{ padding: '1em', marginTop: '0em' }}>
                            <Row>
                              {this.state.date && (
                                <>
                                  <Col md={5}>
                                    <label style={{ paddingRight: '0px' }}>Date :</label>
                                  </Col>
                                  <Col md={7}>
                                    {' '}
                                    <span style={{ color: '#000' }}>
                                      {Utils.convertUTCDateToUserTimeZoneViewDateFormate(this.state.date)}
                                    </span>
                                  </Col>
                                </>
                              )}
                            </Row>
                            <Row>
                              {this.state.eventType && (
                                <>
                                  {' '}
                                  <Col md={5}>
                                    <label>Event Type</label>
                                  </Col>
                                  <Col md={7}>
                                    {' '}
                                    <span>{this.state.eventType}</span>
                                  </Col>
                                </>
                              )}
                            </Row>

                            <Row>
                              {this.state.eventDescription && (
                                <>
                                  <Col md={5}>
                                    <label>Description</label>{' '}
                                  </Col>
                                  <Col md={7}>
                                    <span>{this.state.eventDescription}</span>
                                  </Col>
                                </>
                              )}
                            </Row>
                            <Row>
                              {this.state.location && (
                                <>
                                  {' '}
                                  <Col md={5}>
                                    <label>Location</label>
                                  </Col>
                                  <Col md={7}>
                                    {' '}
                                    <span>{this.state.location}</span>
                                  </Col>
                                </>
                              )}
                            </Row>
                            <Row style={{ marginTop: '1em' }}>
                              <Col md={12} className="viewDetails">
                                <Button
                                  variant="contained"
                                  size="small"
                                  style={{
                                    background: '#ef6603',
                                    color: '#fff',
                                    marginRight: '1rem',
                                    textTransform: 'none',
                                  }}
                                  onClick={this.showAppointment.bind(this, 'Event')}
                                >
                                  View Event
                                </Button>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                        <Card style={{ height: 'auto', borderRadius: '20px', marginTop: '1em' }}>
                          <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.5em' }}>
                            <Row>
                              <Col md={12}>
                                {undefined !== this.state.type.type && this.state.type.type.length > 0 && (
                                  <Label
                                    style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}
                                    className="noteTitle"
                                  >
                                    {'Note Details'}
                                  </Label>
                                )}
                              </Col>
                            </Row>
                          </CardHeader>

                          <CardBody style={{ padding: '1em', marginTop: '0em' }}>
                            <Row>
                              {this.state.shortDescription && (
                                <>
                                  {' '}
                                  <Col md={5}>
                                    <label>Title</label>
                                  </Col>
                                  <Col md={7}>
                                    {' '}
                                    <span>{this.state.shortDescription}</span>
                                  </Col>
                                </>
                              )}
                            </Row>

                            <Row>
                              {this.state.note && (
                                <>
                                  {' '}
                                  <Col md={5}>
                                    <label>Note</label>
                                  </Col>
                                  <Col md={7}>
                                    {' '}
                                    <span>{this.state.note}</span>
                                  </Col>
                                </>
                              )}
                            </Row>
                          </CardBody>
                        </Card>
                      </>
                    ) : (
                      ''
                    )}
                  </Col>

                  <Col md={6} className="communicationWindow" style={{ position: 'relative' }}>
                    <div className="chatbox group-chat" style={{ minHeight: '100%', wordBreak: 'break-all' }}>
                      <div className="chatbox-top">
                        <div className="chat-group-name">
                          <span className="status away"></span>
                          <span className="showTitle" style={{ textTransform: 'capitalize' }}>
                            {this.state.subject}
                          </span>

                          {this.state.isCreatedByUser === true ? (
                            <span style={{ float: 'right', marginRight: '22px', cursor: 'pointer', marginTop: '4px' }}>
                              <Tooltip title="Add/Delete Participant">
                                <PersonAddIcon onClick={(e) => this.addParticipantRequest(e, this.props.data)} />
                              </Tooltip>
                            </span>
                          ) : (
                            <></>
                          )}
                          <span style={{ float: 'right', marginRight: '22px' }} className="showMemberList">
                            {this.showParticipantList()}
                          </span>
                        </div>
                      </div>

                      <div className="chatBox-messages">
                        <ScrollToBottom className="ROOT_CSS">{chatMessageList}</ScrollToBottom>
                      </div>
                      {this.state.getUpdatePermission === true ? (
                        <div className="chat-input-holder">
                          <textarea
                            className="chat-input"
                            value={this.state.sendText}
                            onChange={this.handleSendTextChange.bind(this)}
                            maxLength="512"
                          ></textarea>
                          <button
                            type="submit"
                            value="Send"
                            style={{
                              background: this.state.sendText === '' ? 'rgb(75 114 130)' : '#005476',
                              marginTop: '-0.1em',
                            }}
                            disabled={!this.state.sendText}
                            className="message-send"
                            onClick={(e) => this.sendMessage()}
                          >
                            <SendIcon></SendIcon>
                          </button>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                  </Col>
                  <Col md={2}></Col>
                </Row>{' '}
              </>
            )}
          </>

          {this.state.historyDetails !== null && this.state.historyDetails.length > 0 ? (
            <Modal isOpen={this.state.historyDetailsModal} toggle={this.seenMessageHistoryToggle} size="sm">
              <ModalHeader toggle={this.seenMessageHistoryToggle}>Seen by</ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>{this.showReadMessageParticipantList()}</Col>
                </Row>
              </ModalBody>
            </Modal>
          ) : (
            <>
              <Modal isOpen={this.state.historyDetailsModal} toggle={this.seenMessageHistoryToggle} size="sm">
                <ModalHeader toggle={this.seenMessageHistoryToggle}>Nobody Seen </ModalHeader>
                <ModalBody>
                  <Row>
                    <Col md={12}>{this.showReadMessageParticipantList()}</Col>
                  </Row>
                </ModalBody>
              </Modal>{' '}
            </>
          )}

          <Modal
            isOpen={this.state.appointmentModal}
            toggle={this.appointmentModalToggle}
            size="xl"
            style={{ backgroundColor: '#F2F4F4' }}
            className="appointmentDetail"
          >
            <ModalHeader
              style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}
              toggle={this.appointmentModalToggle}
            >
              <Label style={{ fontWeight: '600', color: '#000' }}> {this.state.type.type + '  Details'}</Label>
            </ModalHeader>
            <ModalBody style={{ backgroundColor: '#F2F4F4' }}>
              <div id="printform" style={{ overflowY: 'scroll' }}>
                {this.state.isAppointment && (
                  <MemberAppointment
                    action="printAppointment"
                    title="Appointment Details"
                    appointmentId={this.state.appointmentId}
                    isPrintView="view_print"
                    pageId="communicationView"
                  />
                )}

                {this.state.isPurchase && (
                  <MyPurchase
                    action="printPurchase"
                    title="Purchase Details"
                    purchaseId={this.state.purchaseId}
                    isPrintView="view_print"
                    pageId="communicationView"
                  />
                )}
                {this.state.isIncident && (
                  <MemberIncident
                    action="printIncident"
                    title="Incident Details"
                    incidentId={this.state.incidentId}
                    isPrintView="view_print"
                    pageId="view"
                  />
                )}
                {this.state.isEvent && (
                  <MemberEvent
                    action="printEvent"
                    title="Event Details"
                    eventId={this.state.eventId}
                    isPrintView="view_print"
                    pageId="view"
                  />
                )}
                <div style={{ textAlign: 'center' }}>
                  <Button
                    type={this.state.type}
                    variant="contained"
                    size="small"
                    style={{ background: '#717373', color: '#fff' }}
                    onClick={this.appointmentModalToggle.bind(this)}
                  >
                    Close
                  </Button>{' '}
                </div>
              </div>
            </ModalBody>
          </Modal>
          <Modal isOpen={this.state.addParticipateModal} toggle={this.addNewParticipateToggle} size="lg">
            <ModalHeader toggle={this.toggle}>
              <b>Add New Participate</b>
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col md={12}>
                  <MaterialTable
                    title="Caregivers' List "
                    icons={UiUtils.getMaterialTableIcons()}
                    data={this.state.noteMemberList}
                    columns={[
                      { title: '#', render: (rowData) => rowData.tableData.id + 1, width: '5%' },
                      { title: 'Name', field: 'name' },
                      {
                        title: 'Select',
                        field: 'imageUrl',
                        width: '10%',
                        render: (rowData) => (
                          <input
                            style={{ height: 18, width: 18, marginLeft: 10 }}
                            type="checkbox"
                            defaultChecked={rowData.checked}
                            name="checkedSacCode"
                            onChange={(e) => this.selectedNewParticipant(e, rowData, 'member')}
                          />
                        ),
                      },
                    ]}
                    options={{
                      sorting: true,
                      actionsColumnIndex: -1,
                      pageSize: this.state.pageSize,
                      paging: this.state.isPaging,
                      headerStyle: { backgroundColor: '#1ABC9C', fontWeight: 'bold', color: '#fff', align: 'left' },
                      rowStyle: {
                        height: '2em',
                        textTransform: 'capitalize',
                      },
                    }}
                  />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <div style={{ textAlign: 'center' }}>
                <Button
                  variant="contained"
                  size="small"
                  style={{ background: '#ef6603', color: '#fff' }}
                  onClick={(e) => this.addNewParticipateInBackend()}
                  type="submit"
                >
                  Update Participant
                </Button>
                &nbsp;&nbsp;
                <CancelIconButton onClick={(e) => this.addNewParticipateToggle()} />
              </div>
            </ModalFooter>
          </Modal>
        </div>
      );
    }
  }

  viewCommunicationWithoutAdvertisement() {
    if (this.state.render === false) {
      return <div />;
    } else {
      /* Separate Out sender and receiver message by matching the senderId with message list senderId */
      let chatMessageList = [];
      this.state.messageList.forEach((message, index) => {
        if (this.state.senderId === message.sender) {
          chatMessageList.push(
            <div className="communication-box-holder">
              <div className="communication-box">
                <div style={{ fontSize: '1.2em' }}>
                  <pre className="view_communication">{message.body}</pre>
                </div>
                <span className="textMsg" style={{ float: 'right', marginTop: '0.2em' }}>
                  {Utils.convertUTCDateToUserTimeZoneDate(message.createdOn)}

                  {message.status !== null && message.status === 'SENT' ? (
                    <>
                      <DoneIcon style={{ fontSize: 'small', marginLeft: '0.5em' }} />
                    </>
                  ) : (
                    <>
                      <DoneAllIcon style={{ fontSize: 'small', color: '#3498DB', marginLeft: '0.5em' }} />
                      <span style={{ fontSize: '0.7em', float: 'right', marginTop: '0.2em', marginLeft: '0.5em' }}>
                        <i
                          className="fa fa-history"
                          title="Seen By"
                          aria-hidden="true"
                          style={{ fontSize: 'small' }}
                          onClick={(e) => this.viewMessageHistoryDetails(e, message.id)}
                        ></i>
                      </span>
                    </>
                  )}
                </span>
              </div>
            </div>
          );
        } else {
          chatMessageList.push(
            <div className="communication-box-holder">
              <div className="communication-box message-partner">
                <div className="message-sender">
                  <a href="#">{message.senderName}</a>
                </div>
                <div>{message.body}</div>
                <span className="textMsg">{Utils.convertUTCDateToUserTimeZoneDate(message.createdOn)}</span>
              </div>
            </div>
          );
        }
      });
      return (
        <div>
          <MenuWithBreadScrum
            backCallback={this.viewCancel.bind(this)}
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            name={this.title}
          />

          {this.state.type.type === 'General' ? (
            <>
              <Row style={{ position: 'relative', margin: '1em' }}>
                <Col md={8} className="communicationWindow" style={{ position: 'relative' }}>
                  <div className="chatbox group-chat" style={{ minHeight: '100%', wordBreak: 'break-all' }}>
                    <div className="chatbox-top">
                      <div className="chat-group-name">
                        <span className="status away"></span>
                        <span className="showTitle" style={{ textTransform: 'capitalize' }}>
                          {this.state.subject}
                        </span>
                        {this.state.isCreatedByUser === true ? (
                          <span style={{ float: 'right', marginRight: '22px', cursor: 'pointer', marginTop: '4px' }}>
                            <Tooltip title="Add/Delete Participant">
                              <PersonAddIcon onClick={(e) => this.addParticipantRequest(e, this.props.data)} />
                            </Tooltip>
                          </span>
                        ) : (
                          <></>
                        )}
                        <span style={{ float: 'right', marginRight: '22px' }} className="showMemberList">
                          {this.showParticipantList()}
                        </span>
                      </div>
                    </div>

                    <div className="chatBox-messages">
                      <ScrollToBottom className="ROOT_CSS">{chatMessageList}</ScrollToBottom>
                    </div>
                    {this.state.getUpdatePermission === true ? (
                      <div className="chat-input-holder">
                        <textarea
                          className="chat-input"
                          value={this.state.sendText}
                          onChange={this.handleSendTextChange.bind(this)}
                          maxLength="512"
                        ></textarea>
                        <button
                          type="submit"
                          value="Send"
                          style={{
                            background: this.state.sendText === '' ? 'rgb(75 114 130)' : '#005476',
                            marginTop: '-0.1em',
                          }}
                          disabled={!this.state.sendText}
                          className="message-send"
                          onClick={(e) => this.sendMessage()}
                        >
                          <SendIcon></SendIcon>
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </Col>
              </Row>
            </>
          ) : (
            <>
              <Row style={{ position: 'relative', margin: '1em' }}>
                <Col md={4}>
                  {this.state.type.type === 'Incident' ? (
                    <>
                      <Card style={{ height: 'auto', borderRadius: '20px' }}>
                        <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.5em' }}>
                          <Row>
                            <Col md={12}>
                              {undefined !== this.state.type.type && this.state.type.type.length > 0 && (
                                <Label
                                  style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}
                                  className="detailTitle"
                                >
                                  {this.state.type.type + '  Details'}
                                </Label>
                              )}
                            </Col>
                          </Row>
                        </CardHeader>

                        <CardBody style={{ padding: '1em', marginTop: '0em' }}>
                          <Row>
                            {this.state.date && (
                              <>
                                <Col md={5}>
                                  <label style={{ paddingRight: '0px' }}>Date :</label>
                                </Col>
                                <Col md={7}>
                                  {' '}
                                  <span style={{ color: '#000' }}>
                                    {Utils.convertUTCDateToUserTimeZoneViewDateFormate(this.state.date)}
                                  </span>
                                </Col>
                              </>
                            )}
                          </Row>
                          <Row>
                            {this.state.incidentType && (
                              <>
                                {' '}
                                <Col md={5}>
                                  <label>Incident Type</label>
                                </Col>
                                <Col md={7}>
                                  {' '}
                                  <span>{this.state.incidentType}</span>
                                </Col>
                              </>
                            )}
                          </Row>

                          <Row>
                            {this.state.incidentDescription && (
                              <>
                                <Col md={5}>
                                  <label>Description</label>{' '}
                                </Col>
                                <Col md={7}>
                                  <span>{this.state.incidentDescription}</span>
                                </Col>
                              </>
                            )}
                          </Row>
                          <Row>
                            {this.state.location && (
                              <>
                                {' '}
                                <Col md={5}>
                                  <label>Location</label>
                                </Col>
                                <Col md={7}>
                                  {' '}
                                  <span>{this.state.location}</span>
                                </Col>
                              </>
                            )}
                          </Row>
                          <Row style={{ marginTop: '1em' }}>
                            <Col md={12} className="viewDetails">
                              <Button
                                variant="contained"
                                size="small"
                                style={{
                                  background: '#ef6603',
                                  color: '#fff',
                                  marginRight: '1rem',
                                  textTransform: 'none',
                                }}
                                onClick={this.showAppointment.bind(this, 'Incident')}
                              >
                                View Incident
                              </Button>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <Card style={{ height: 'auto', borderRadius: '20px', marginTop: '1em' }}>
                        <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.5em' }}>
                          <Row>
                            <Col md={12}>
                              {undefined !== this.state.type.type && this.state.type.type.length > 0 && (
                                <Label
                                  style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}
                                  className="noteTitle"
                                >
                                  {' Note Details'}
                                </Label>
                              )}
                            </Col>
                          </Row>
                        </CardHeader>

                        <CardBody style={{ padding: '1em', marginTop: '0em' }}>
                          <Row>
                            {this.state.shortDescription && (
                              <>
                                {' '}
                                <Col md={5}>
                                  <label>Title</label>
                                </Col>
                                <Col md={7}>
                                  {' '}
                                  <span>{this.state.shortDescription}</span>
                                </Col>
                              </>
                            )}
                          </Row>

                          <Row>
                            {this.state.incidentOccurance && (
                              <>
                                {' '}
                                <Col md={5}>
                                  <label>Occurrence</label>
                                </Col>
                                <Col md={7}>
                                  {' '}
                                  <span>{this.state.incidentOccurance.occurance}</span>
                                </Col>
                              </>
                            )}
                          </Row>

                          <Row>
                            {this.state.note && (
                              <>
                                {' '}
                                <Col md={5}>
                                  <label>Note</label>
                                </Col>
                                <Col md={7}>
                                  {' '}
                                  <span>{this.state.note}</span>
                                </Col>
                              </>
                            )}
                          </Row>
                        </CardBody>
                      </Card>
                    </>
                  ) : (
                    ''
                  )}
                  {this.state.type.type === 'Appointment' ? (
                    <>
                      <Card style={{ height: 'auto', borderRadius: '20px' }}>
                        <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.5em' }}>
                          <Row>
                            <Col md={12}>
                              {undefined !== this.state.type.type && this.state.type.type.length > 0 && (
                                <Label
                                  style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}
                                  className="detailTitle"
                                >
                                  {this.state.type.type + '  Details'}
                                </Label>
                              )}
                            </Col>
                          </Row>
                        </CardHeader>

                        <CardBody style={{ padding: '1em', marginTop: '0em' }}>
                          <Row>
                            {this.state.date && (
                              <>
                                <Col md={5}>
                                  <label style={{ paddingRight: '0px' }}>Date</label>
                                </Col>
                                <Col md={7}>
                                  {' '}
                                  <span style={{ color: '#000' }}>
                                    {Utils.convertUTCDateToUserTimeZoneViewDateFormate(this.state.date)}
                                  </span>
                                </Col>
                              </>
                            )}
                          </Row>
                          <Row>
                            {this.state.appointmentType && (
                              <>
                                {' '}
                                <Col md={5}>
                                  <label>Type</label>
                                </Col>
                                <Col md={7}>
                                  {' '}
                                  <span>{this.state.appointmentType.type}</span>
                                </Col>
                              </>
                            )}
                          </Row>
                          <Row>
                            {this.state.purpose && (
                              <>
                                {' '}
                                <Col md={5}>
                                  <label>Purpose</label>
                                </Col>
                                <Col md={7}>
                                  {' '}
                                  <span>{this.state.purpose}</span>
                                </Col>
                              </>
                            )}
                          </Row>

                          <Row>
                            {this.state.appointmentDescription && (
                              <>
                                <Col md={5}>
                                  <label>Description</label>{' '}
                                </Col>
                                <Col md={7}>
                                  <span>{this.state.appointmentDescription}</span>
                                </Col>
                              </>
                            )}
                          </Row>
                          <Row>
                            {this.state.location && (
                              <>
                                {' '}
                                <Col md={5}>
                                  <label>Location</label>
                                </Col>
                                <Col md={7}>
                                  {' '}
                                  <span>{this.state.location}</span>
                                </Col>
                              </>
                            )}
                          </Row>
                          <Row>
                            {this.state.accompanyName && (
                              <>
                                {' '}
                                <Col md={5}>
                                  <label>Accompany Name</label>
                                </Col>
                                <Col md={7}>
                                  {' '}
                                  <span>{this.state.accompanyName}</span>
                                </Col>
                              </>
                            )}
                          </Row>
                          <Row style={{ marginTop: '1em' }}>
                            <Col md={12} className="viewDetails">
                              <Button
                                variant="contained"
                                size="small"
                                style={{
                                  background: '#ef6603',
                                  color: '#fff',
                                  marginRight: '1rem',
                                  textTransform: 'none',
                                }}
                                onClick={this.showAppointment.bind(this, 'Appointment')}
                              >
                                View Appointment
                              </Button>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <Card style={{ height: 'auto', borderRadius: '20px', marginTop: '1em' }}>
                        <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.5em' }}>
                          <Row>
                            <Col md={12}>
                              {undefined !== this.state.type.type && this.state.type.type.length > 0 && (
                                <Label
                                  style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}
                                  className="noteTitle"
                                >
                                  {' Note Details'}
                                </Label>
                              )}
                            </Col>
                          </Row>
                        </CardHeader>

                        <CardBody style={{ padding: '1em', marginTop: '0em' }}>
                          <Row>
                            {this.state.shortDescription && (
                              <>
                                {' '}
                                <Col md={5}>
                                  <label>Title</label>
                                </Col>
                                <Col md={7}>
                                  {' '}
                                  <span>{this.state.shortDescription}</span>
                                </Col>
                              </>
                            )}
                          </Row>

                          <Row>
                            {this.state.appointmentOccurance && (
                              <>
                                {' '}
                                <Col md={5}>
                                  <label>Occurrence</label>
                                </Col>
                                <Col md={7}>
                                  {' '}
                                  <span>{this.state.appointmentOccurance.occurance}</span>
                                </Col>
                              </>
                            )}
                          </Row>

                          <Row>
                            {this.state.note && (
                              <>
                                {' '}
                                <Col md={5}>
                                  <label>Note</label>
                                </Col>
                                <Col md={7}>
                                  {' '}
                                  <span>{this.state.note}</span>
                                </Col>
                              </>
                            )}
                          </Row>
                        </CardBody>
                      </Card>
                    </>
                  ) : (
                    ''
                  )}

                  {this.state.type.type === 'Purchase' ? (
                    <>
                      <Card style={{ height: 'auto%', borderRadius: '20px' }}>
                        <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.5em' }}>
                          <Row>
                            <Col md={12}>
                              {undefined !== this.state.type.type && this.state.type.type.length > 0 && (
                                <Label
                                  style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}
                                  className="detailTitle"
                                >
                                  {this.state.type.type + '  Details'}
                                </Label>
                              )}
                            </Col>
                          </Row>
                        </CardHeader>

                        <CardBody style={{ padding: '1em', marginTop: '0em' }}>
                          <Row>
                            {this.state.purchaseDate && (
                              <>
                                <Col md={5}>
                                  <label style={{ paddingRight: '0px' }}>Date</label>
                                </Col>
                                <Col md={7}>
                                  {' '}
                                  <span style={{ color: '#000' }}>
                                    {Utils.convertUTCDateToUserTimeZoneViewDateFormate(this.state.purchaseDate)}
                                  </span>
                                </Col>
                              </>
                            )}
                          </Row>
                          <Row>
                            {this.state.purchaseType && (
                              <>
                                {' '}
                                <Col md={5}>
                                  <label>Purchase Type</label>
                                </Col>
                                <Col md={7}>
                                  {' '}
                                  <span>{this.state.purchaseType}</span>
                                </Col>
                              </>
                            )}
                          </Row>

                          <Row>
                            {this.state.purchaseDescription && (
                              <>
                                <Col md={5}>
                                  <label>Description</label>{' '}
                                </Col>
                                <Col md={7}>
                                  <span>{this.state.purchaseDescription}</span>
                                </Col>
                              </>
                            )}
                          </Row>
                          <Row>
                            {this.state.prescribedBy && (
                              <>
                                {' '}
                                <Col md={5}>
                                  <label>Prescribed By</label>
                                </Col>
                                <Col md={7}>
                                  {' '}
                                  <span>{this.state.prescribedBy}</span>
                                </Col>
                              </>
                            )}
                          </Row>
                          <Row style={{ marginTop: '1em' }}>
                            <Col md={12} className="viewDetails">
                              <Button
                                variant="contained"
                                size="small"
                                style={{
                                  background: '#ef6603',
                                  color: '#fff',
                                  marginRight: '1rem',
                                  textTransform: 'none',
                                }}
                                onClick={this.showAppointment.bind(this, 'Purchase')}
                              >
                                View Purchase
                              </Button>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <Card style={{ height: 'auto', borderRadius: '20px', marginTop: '1em' }}>
                        <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.5em' }}>
                          <Row>
                            <Col md={12}>
                              {undefined !== this.state.type.type && this.state.type.type.length > 0 && (
                                <Label
                                  style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}
                                  className="noteTitle"
                                >
                                  {' Note Details'}
                                </Label>
                              )}
                            </Col>
                          </Row>
                        </CardHeader>

                        <CardBody style={{ padding: '1em', marginTop: '0em' }}>
                          <Row>
                            {this.state.shortDescription && (
                              <>
                                {' '}
                                <Col md={5}>
                                  <label>Title</label>
                                </Col>
                                <Col md={7}>
                                  {' '}
                                  <span>{this.state.shortDescription}</span>
                                </Col>
                              </>
                            )}
                          </Row>

                          <Row>
                            {this.state.purchaseOccurance && (
                              <>
                                {' '}
                                <Col md={5}>
                                  <label>Occurrence</label>
                                </Col>
                                <Col md={7}>
                                  {' '}
                                  <span>{this.state.purchaseOccurance.occurance}</span>
                                </Col>
                              </>
                            )}
                          </Row>

                          <Row>
                            {this.state.note && (
                              <>
                                {' '}
                                <Col md={5}>
                                  <label>Note</label>
                                </Col>
                                <Col md={7}>
                                  {' '}
                                  <span>{this.state.note}</span>
                                </Col>
                              </>
                            )}
                          </Row>
                        </CardBody>
                      </Card>
                    </>
                  ) : (
                    ''
                  )}
                  {this.state.type.type === 'Event' ? (
                    <>
                      <Card style={{ height: 'auto', borderRadius: '20px' }}>
                        <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.5em' }}>
                          <Row>
                            <Col md={12}>
                              {undefined !== this.state.type.type && this.state.type.type.length > 0 && (
                                <Label
                                  style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}
                                  className="detailTitle"
                                >
                                  {this.state.type.type + '  Details'}
                                </Label>
                              )}
                            </Col>
                          </Row>
                        </CardHeader>

                        <CardBody style={{ padding: '1em', marginTop: '0em' }}>
                          <Row>
                            {this.state.date && (
                              <>
                                <Col md={5}>
                                  <label style={{ paddingRight: '0px' }}>Date :</label>
                                </Col>
                                <Col md={7}>
                                  {' '}
                                  <span style={{ color: '#000' }}>
                                    {Utils.convertUTCDateToUserTimeZoneViewDateFormate(this.state.date)}
                                  </span>
                                </Col>
                              </>
                            )}
                          </Row>
                          <Row>
                            {this.state.eventType && (
                              <>
                                {' '}
                                <Col md={5}>
                                  <label>Event Type</label>
                                </Col>
                                <Col md={7}>
                                  {' '}
                                  <span>{this.state.eventType}</span>
                                </Col>
                              </>
                            )}
                          </Row>

                          <Row>
                            {this.state.eventDescription && (
                              <>
                                <Col md={5}>
                                  <label>Description</label>{' '}
                                </Col>
                                <Col md={7}>
                                  <span>{this.state.eventDescription}</span>
                                </Col>
                              </>
                            )}
                          </Row>
                          <Row>
                            {this.state.location && (
                              <>
                                {' '}
                                <Col md={5}>
                                  <label>Location</label>
                                </Col>
                                <Col md={7}>
                                  {' '}
                                  <span>{this.state.location}</span>
                                </Col>
                              </>
                            )}
                          </Row>
                          <Row style={{ marginTop: '1em' }}>
                            <Col md={12} className="viewDetails">
                              <Button
                                variant="contained"
                                size="small"
                                style={{
                                  background: '#ef6603',
                                  color: '#fff',
                                  marginRight: '1rem',
                                  textTransform: 'none',
                                }}
                                onClick={this.showAppointment.bind(this, 'Event')}
                              >
                                View Event
                              </Button>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                      <Card style={{ height: 'auto', borderRadius: '20px', marginTop: '1em' }}>
                        <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.5em' }}>
                          <Row>
                            <Col md={12}>
                              {undefined !== this.state.type.type && this.state.type.type.length > 0 && (
                                <Label
                                  style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}
                                  className="noteTitle"
                                >
                                  {' Note Details'}
                                </Label>
                              )}
                            </Col>
                          </Row>
                        </CardHeader>

                        <CardBody style={{ padding: '1em', marginTop: '0em' }}>
                          <Row>
                            {this.state.shortDescription && (
                              <>
                                {' '}
                                <Col md={5}>
                                  <label>Title</label>
                                </Col>
                                <Col md={7}>
                                  {' '}
                                  <span>{this.state.shortDescription}</span>
                                </Col>
                              </>
                            )}
                          </Row>

                          <Row>
                            {this.state.note && (
                              <>
                                {' '}
                                <Col md={5}>
                                  <label>Note</label>
                                </Col>
                                <Col md={7}>
                                  {' '}
                                  <span>{this.state.note}</span>
                                </Col>
                              </>
                            )}
                          </Row>
                        </CardBody>
                      </Card>
                    </>
                  ) : (
                    ''
                  )}
                </Col>
                <Modal isOpen={this.state.addParticipateModal} toggle={this.addNewParticipateToggle} size="lg">
                  <ModalHeader toggle={this.toggle}>
                    <b>Add New Participate</b>
                  </ModalHeader>
                  <ModalBody>
                    <Row>
                      <Col md={12}>
                        <MaterialTable
                          title="Caregivers' List "
                          icons={UiUtils.getMaterialTableIcons()}
                          data={this.state.noteMemberList}
                          columns={[
                            { title: '#', render: (rowData) => rowData.tableData.id + 1, width: '5%' },
                            { title: 'Name', field: 'name' },
                            {
                              title: 'Select',
                              field: 'imageUrl',
                              width: '10%',
                              render: (rowData) => (
                                <input
                                  style={{ height: 18, width: 18, marginLeft: 10 }}
                                  type="checkbox"
                                  defaultChecked={rowData.checked}
                                  name="checkedSacCode"
                                  onChange={(e) => this.selectedNewParticipant(e, rowData, 'member')}
                                />
                              ),
                            },
                          ]}
                          options={{
                            sorting: true,
                            actionsColumnIndex: -1,
                            pageSize: this.state.pageSize,
                            paging: this.state.isPaging,
                            headerStyle: {
                              backgroundColor: '#1ABC9C',
                              fontWeight: 'bold',
                              color: '#fff',
                              align: 'left',
                            },
                            rowStyle: {
                              height: '2em',
                              textTransform: 'capitalize',
                            },
                          }}
                        />
                      </Col>
                    </Row>
                  </ModalBody>
                  <ModalFooter>
                    <div style={{ textAlign: 'center' }}>
                      <Button
                        variant="contained"
                        size="small"
                        style={{ background: '#ef6603', color: '#fff' }}
                        onClick={(e) => this.addNewParticipateInBackend()}
                        type="submit"
                      >
                        Update Participant
                      </Button>
                      &nbsp;&nbsp;
                      <CancelIconButton onClick={(e) => this.addNewParticipateToggle()} />
                    </div>
                  </ModalFooter>
                </Modal>
                <Col md={8} className="communicationWindow" style={{ position: 'relative' }}>
                  <div className="chatbox group-chat" style={{ minHeight: '100%', wordBreak: 'break-all' }}>
                    <div className="chatbox-top">
                      <div className="chat-group-name">
                        <span className="status away"></span>
                        <span className="showTitle" style={{ textTransform: 'capitalize' }}>
                          {this.state.subject}
                        </span>
                        {this.state.isCreatedByUser === true ? (
                          <span style={{ float: 'right', marginRight: '22px', cursor: 'pointer', marginTop: '4px' }}>
                            <Tooltip title="Add/Delete Participant">
                              <PersonAddIcon onClick={(e) => this.addParticipantRequest(e, this.props.data)} />
                            </Tooltip>
                          </span>
                        ) : (
                          <></>
                        )}
                        <span style={{ float: 'right', marginRight: '22px' }} className="showMemberList">
                          {this.showParticipantList()}
                        </span>
                      </div>
                    </div>

                    <div className="chatBox-messages">
                      <ScrollToBottom className="ROOT_CSS">{chatMessageList}</ScrollToBottom>
                    </div>
                    {this.state.getUpdatePermission === true ? (
                      <div className="chat-input-holder">
                        <textarea
                          className="chat-input"
                          value={this.state.sendText}
                          onChange={this.handleSendTextChange.bind(this)}
                          maxLength="512"
                        ></textarea>
                        <button
                          type="submit"
                          value="Send"
                          style={{
                            background: this.state.sendText === '' ? 'rgb(75 114 130)' : '#005476',
                            marginTop: '-0.1em',
                          }}
                          disabled={!this.state.sendText}
                          className="message-send"
                          onClick={(e) => this.sendMessage()}
                        >
                          <SendIcon></SendIcon>
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </Col>
              </Row>{' '}
            </>
          )}

          {this.state.historyDetails !== null && this.state.historyDetails.length > 0 ? (
            <Modal isOpen={this.state.historyDetailsModal} toggle={this.seenMessageHistoryToggle} size="sm">
              <ModalHeader toggle={this.seenMessageHistoryToggle}>Seen by</ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>{this.showReadMessageParticipantList()}</Col>
                </Row>
              </ModalBody>
            </Modal>
          ) : (
            <>
              <Modal isOpen={this.state.historyDetailsModal} toggle={this.seenMessageHistoryToggle} size="sm">
                <ModalHeader toggle={this.seenMessageHistoryToggle}>Nobody Seen </ModalHeader>
                <ModalBody>
                  <Row>
                    <Col md={12}>{this.showReadMessageParticipantList()}</Col>
                  </Row>
                </ModalBody>
              </Modal>{' '}
            </>
          )}

          <Modal
            isOpen={this.state.appointmentModal}
            toggle={this.appointmentModalToggle}
            size="xl"
            style={{ backgroundColor: '#F2F4F4' }}
            className="appointmentDetail"
          >
            <ModalHeader
              style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}
              toggle={this.appointmentModalToggle}
            >
              <Label style={{ fontWeight: '600', color: '#000' }}> {this.state.type.type + '  Details'}</Label>
            </ModalHeader>
            <ModalBody style={{ backgroundColor: '#F2F4F4' }}>
              <div id="printform" style={{ overflowY: 'scroll' }}>
                {this.state.isAppointment && (
                  <MemberAppointment
                    action="printAppointment"
                    title="Appointment Details"
                    appointmentId={this.state.appointmentId}
                    isPrintView="view_print"
                    pageId="communicationView"
                  />
                )}

                {this.state.isPurchase && (
                  <MyPurchase
                    action="printPurchase"
                    title="Purchase Details"
                    purchaseId={this.state.purchaseId}
                    isPrintView="view_print"
                    pageId="view"
                  />
                )}
                {this.state.isIncident && (
                  <MemberIncident
                    action="printIncident"
                    title="Incident Details"
                    incidentId={this.state.incidentId}
                    isPrintView="view_print"
                    pageId="communicationView"
                  />
                )}
                {this.state.isEvent && (
                  <MemberEvent
                    action="printEvent"
                    title="Event Details"
                    eventId={this.state.eventId}
                    isPrintView="view_print"
                    pageId="view"
                  />
                )}
                <div style={{ textAlign: 'center' }}>
                  <Button
                    type={this.state.type}
                    variant="contained"
                    size="small"
                    style={{ background: '#717373', color: '#fff' }}
                    onClick={this.appointmentModalToggle.bind(this)}
                  >
                    Close
                  </Button>{' '}
                </div>
              </div>
            </ModalBody>
          </Modal>
        </div>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return { messageList: state.messageThreadsData.messageThreadsData };
};
export default connect(mapStateToProps)(MemberCommunication);
