import {
  CLEAR_SELECTED_MEMBER_DETAILS,
  GET_SELECTED_MEMBER_DETAILS,
  SET_SELECTED_MEMBER_DETAILS,
} from './selectedMemberDetailsType';

const initialState = {
  selectedMemberData: {},
};

const selectedMemberDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SELECTED_MEMBER_DETAILS:
      return {
        ...state,
      };
    case SET_SELECTED_MEMBER_DETAILS:
      return {
        ...state,
        selectedMemberData: action.payload,
      };

    case CLEAR_SELECTED_MEMBER_DETAILS:
      return {
        ...state,
        selectedMemberData: null,
      };
    default:
      return state;
  }
};

export default selectedMemberDetailsReducer;
