import React, { Component } from 'react';
import store from '../../../redux/store';
import FccAdvertisement from '../fccAdvertisement/FccAdvertisement';
import CommercialsTemplate1 from './commercialsTemplate1/CommercialsTemplate1';
import CommercialsTemplate2 from './commercialsTemplate2/CommercialsTemplate2';
class CommercialsTemplate extends Component {
  commercials = store.getState().commercialsDetails.commercials;

  constructor(props) {
    let str = props.pageId;
    let urlArr = str.split('/');
    let pageId = urlArr[1];
    super(props);
    this.state = {
      commercials: this.commercials !== null ? this.commercials : {},
      pageId: props.pageId,
      render: true,
    };
    console.log(this.state.pageId);
  }

  render() {
    console.log(this.state.commercials);
    let advertisementList = [];
    let advertisementListMapping = [];
    if (this.state.commercials !== {}) {
      advertisementList = this.state.commercials ? this.state.commercials.commercials : [];
      advertisementListMapping = this.state.commercials.commercialPageMappings;
    }
    let advertisementId = -1;
    let myTemplate = {};
    for (let i = 0; i < advertisementListMapping.length; i++) {
      if (advertisementListMapping[i].fccpage.name.toLowerCase() === this.state.pageId.toLowerCase()) {
        advertisementId = advertisementListMapping[i].advertisementId;
      }
    }
    if (advertisementList.length > 0) {
      for (let i = 0; i < advertisementList.length; i++) {
        if (advertisementId !== -1 && advertisementId !== null) {
          if (advertisementList[i].id === advertisementId) {
            myTemplate = advertisementList[i];
          }
        } else {
          myTemplate = null;
        }
      }
    }
    if (myTemplate && myTemplate.template) {
      if (myTemplate.template.templateid === 'PATemplate1') {
        return this.template1(myTemplate);
      } else {
        return this.template2(myTemplate);
      }
    } else {
      return (
        <>
          <FccAdvertisement advertisementType="Horizontal" />
        </>
      );
    }
  }

  template1(myTemplate) {
    return (
      <>
        {' '}
        <CommercialsTemplate1 data={myTemplate} />{' '}
      </>
    );
  }

  template2(myTemplate) {
    return (
      <>
        <CommercialsTemplate2 data={myTemplate} />
      </>
    );
  }
}
export default CommercialsTemplate;
