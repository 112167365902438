import { CLEAR_PROFILE, GET_PROFILE, SET_PROFILE } from './ProfileManagementTypes';
const initialState = {
    profileDetails: {},
    adminProfileDetails: {},
};

const profileManagementReducer = (state = initialState, action) => {
    switch (action.type) {
        case GET_PROFILE:
            return {
                ...state,
            };
        case SET_PROFILE:
            return {
                ...state,
                profileDetails: action.payload,
                adminProfileDetails: action.payload,
            };

        case CLEAR_PROFILE:
            return {
                ...state,
                profileDetails: null,
                adminProfileDetails: null,
            };
        default:
            return state;
    }
};

export default profileManagementReducer;
