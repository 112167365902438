import React, { Component } from 'react';
import { Col, DropdownToggle, Row, UncontrolledDropdown, Modal, ModalBody, Label } from 'reactstrap';
import Fab from '@material-ui/core/Fab';
import CloseIcon from '@material-ui/icons/Close';
class PtsModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      onClose: props.onClose,
    };
  }
  render() {
    return (
      <div>
        <Modal isOpen={this.props.open} toggle={this.state.onClose} size="xl">
          <Row style={{ marginTop: '1em' }}>
            {' '}
            <Col md={11} style={{ textAlign: 'center' }}>
              {' '}
              <Label
                style={{
                  fontWeight: '600',
                  color: '#229954',
                  marginLeft: '0.5em',
                  textTransform: 'capitalize',
                  fontSize: '25px',
                }}
              >
                {this.props.title}
              </Label>
            </Col>
            <Col md={1}>
              <Fab
                aria-label="add"
                style={{
                  float: 'left',
                  fontSize: 'small',
                  height: '15px',
                  width: '35px',
                  background: '#76767d',
                  cursor: 'pointer',
                  color: 'white',
                  backgroundImage: '-webkit-linear-gradient(bottom, #843b07 0%, #f7b571 100%);',
                  border: '2px solid #edf0f1',
                }}
              >
                <CloseIcon onClick={this.state.onClose} />
              </Fab>
            </Col>
          </Row>{' '}
          <ModalBody>
            <Row>
              <Col md={12}>
                {/* <FccHelp></FccHelp> */}
                {/* <iframe
                      src={'https://www.familycarecircle.com/fccdocs/FccUsages.pdf'}
                      style={{ width: '100%', height: '600px' }}
                      frameborder="0"
                      title="indpdf"
                    ></iframe> */}
                {this.props.children}
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </div>
    );
  }
}

export default PtsModal;
