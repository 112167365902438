import {
  CLEAR_CONSOLIDATED_MEMBER_DATA,
  GET_CONSOLIDATED_MEMBER_DATA,
  SET_CONSOLIDATED_MEMBER_DATA,
} from './consolidatedMemberType';
const initialState = {
  consolidatedMemberDetails: {},
};

const consolidatedMemberReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONSOLIDATED_MEMBER_DATA:
      return {
        ...state,
      };
    case SET_CONSOLIDATED_MEMBER_DATA:
      return {
        ...state,
        consolidatedMemberDetails: action.payload,
      };

    case CLEAR_CONSOLIDATED_MEMBER_DATA:
      return {
        ...state,
        consolidatedMemberDetails: null,
      };
    default:
      return state;
  }
};

export default consolidatedMemberReducer;
