import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { Component } from 'react';
import PtsConfirmDialogue from '../ptsAlert/PtsConfirmDialogue';

export class DeleteButton extends Component {
  confirmDialogueRef = React.createRef();
  constructor(props) {
    let domainObject = props.domainObject ? props.domainObject : '';
    super(props);
    this.state = {
      domainObject: domainObject,
      title: props.title ? props.title : 'Delete Confirmation',
      text: props.text ? props.text : domainObject,
      onClick: props.onClick,
      caption: props.caption,
      pageId: this.props.pageId,
    };
  }

  deleteConfirmed() {
    if (this.confirmDialogueRef && this.confirmDialogueRef.current) {
      this.confirmDialogueRef.current.setState({ open: false });
    }
    if (this.state.onClick) {
      this.state.onClick();
    }
  }

  deleteNotConfirmed() {}
  onClick() {
    if (
      this.confirmDialogueRef &&
      this.confirmDialogueRef.current &&
      this.confirmDialogueRef.current.state.open === false
    ) {
      this.confirmDialogueRef.current.handleClickOpen();
    }
  }
  render() {
    this.confirmDialogueRef = React.createRef();
    return (
      <span
        onClick={this.onClick.bind(this)}
        style={{ cursor: 'pointer', fontSize: 'x-larger', marginRight: '0.5em', float: 'right' }}
      >
        <PtsConfirmDialogue
          ref={this.confirmDialogueRef}
          title={this.state.title}
          text={this.state.text}
          open={false}
          pageId={this.props.pageId}
          confirmCallback={this.deleteConfirmed.bind(this)}
          notConfirmCallback={this.deleteNotConfirmed.bind(this)}
        />
        <Tooltip title="Delete">
          <DeleteIcon
            className="deleteIcon"
            id="iconButton"
            style={{
              float: 'right',
              fontSize: 'small',
              height: '2em',
              width: '2em',
              background: 'rgb(19, 98, 105)',
              cursor: 'pointer',
              color: 'white',
              border: '2px solid #edf0f1',
              borderRadius: '50%',
              padding: '2px',
            }}
          />
        </Tooltip>
      </span>
    );
  }
}
export default DeleteButton;
