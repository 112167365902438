import Button from '@material-ui/core/Button';
import { loadStripe } from '@stripe/stripe-js';
import Axios from 'axios';
import React, { useEffect, useReducer } from 'react';
import { Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap';
import FetchServerData from '../../../../provider/FetchServerData';
import Utils from '../../../../provider/Utils';
import store from '../../../../redux/store';
import MenuWithBreadScrum from '../../menu/MenuWithBreadScrum';
const fetchCheckoutSession = async ({ quantity }) => {
  let input = { quantity: quantity };
  Axios.post('/pg/create-checkout-session', input).then((response) => {});
};

const formatPrice = ({ amount, currency, quantity }) => {
  const numberFormat = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    currencyDisplay: 'symbol',
  });
  const parts = numberFormat.formatToParts(amount);
  let zeroDecimalCurrency = true;
  for (let part of parts) {
    if (part.type === 'decimal') {
      zeroDecimalCurrency = false;
    }
  }
  amount = zeroDecimalCurrency ? amount : amount / 100;
  const total = (quantity * amount).toFixed(2);
  return numberFormat.format(total);
};

function reducer(state, action) {
  switch (action.type) {
    case 'useEffectUpdate':
      return {
        ...state,
        ...action.payload,
        price: formatPrice({
          amount: action.payload.unitAmount,
          currency: action.payload.currency,
          quantity: state.quantity,
        }),
      };
    case 'increment':
      return {
        ...state,
        quantity: state.quantity + 1,
        price: formatPrice({
          amount: state.unitAmount,
          currency: state.currency,
          quantity: state.quantity + 1,
        }),
      };
    case 'decrement':
      return {
        ...state,
        quantity: state.quantity - 1,
        price: formatPrice({
          amount: state.unitAmount,
          currency: state.currency,
          quantity: state.quantity - 1,
        }),
      };
    case 'setLoading':
      return { ...state, loading: action.payload.loading };
    case 'setError':
      return { ...state, error: action.payload.error };
    default:
      throw new Error();
  }
}

const Checkout = (props) => {
  let userData = store.getState().userData.userData;
  let selectedPlanData = store.getState().selectedPlanData.selectedPlan;
  let header = store.getState().header.header;
  let title = 'Checkout';
  let breadCrumb = [
    { pageid: '/profileHome', uiname: 'User Profile Details' },
    { pageid: '/homePricing', uiname: 'Pricing' },
    { pageid: '', uiname: 'Checkout' },
  ];

  let featureList = [];
  for (let i = 0; i < selectedPlanData.selectedPlanList.planItems.length; i++) {
    featureList.push(<li>{selectedPlanData.selectedPlanList.planItems[i].name}</li>);
  }

  let firstName = userData.firstName;
  let lastName = userData.lastName;
  let userAddress = {};
  if (userData.address !== null) {
    userAddress = userData.address;
  }
  const [state, dispatch] = useReducer(reducer, {
    planList: [],
    price: null,
    loading: false,
    selectedPlanPrice: selectedPlanData.selectedPlanList.price,
    planDetails: selectedPlanData,
    firstName: firstName,
    lastName: lastName,
    userAddress: userAddress,
    userPhone: userData.phone,
    userEmail: userData.email,
  });

  useEffect(() => {
    async function fetchConfig() {
      console.log(header);
      let input = {
        header: header,
      };
      await FetchServerData.callPostService('/pg/config', input)
        .then((output) => {
          if (output.status === 'SUCCESS') {
            let responseData = output.data;
            loadStripe(responseData.publicKey).then((stripeResp) => {
              dispatch({
                type: 'useEffectUpdate',
                payload: {
                  unitAmount: responseData.unitAmount,
                  currency: responseData.currency,
                  stripe: stripeResp,
                },
              });
            });
          }
        })
        .catch((error) => {});
    }
    fetchConfig();
  }, []);

  const handleClick = async (event) => {
    // Call your backend to create the Checkout session.
    dispatch({ type: 'setLoading', payload: { loading: true } });

    let sessionId = '';

    let input = {
      header: header,
      user: {
        email: state.userEmail,
      },
      order: {
        id: null,
        description: null,
        email: state.userEmail,
        price: state.selectedPlanPrice,
        currency: null,
        status: null,
        date: null,
        orderid: null,
        orderItems: [
          {
            id: null,
            description: selectedPlanData.selectedPlanList.description,
            plan: { id: selectedPlanData.selectedPlanList.id },
          },
        ],
      },
    };

    FetchServerData.callPostService('pg/makePayment', input)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          sessionId = output.data.sessionId;

          const { error } = state.stripe.redirectToCheckout({
            sessionId,
          });
          if (error) {
            dispatch({ type: 'setError', payload: { error } });
            dispatch({ type: 'setLoading', payload: { loading: false } });
          }
        }
      })
      .catch((error) => {});
  };

  return (
    <>
      <div>
        <MenuWithBreadScrum
          breadCrumb={breadCrumb}
          history={props.history}
          name={title}
          domainObject="Checkout"
          headerWidth="home"
        />

        <section id="pricing" className="pricing" style={{ padding: '1em' }}>
          <Row style={{ marginTop: '25px' }}>
            <Col md={6}>
              <Card
                style={{
                  width: '100%',
                  boxShadow:
                    'rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px',
                  minHeight: '19.5em',
                }}
              >
                <CardHeader
                  style={{
                    background: '#2b7d6d',
                    minHeight: '2em',
                    padding: '0.5em',
                  }}
                >
                  <span
                    style={{
                      color: '#fff',
                      fontWeight: 'bold',
                      padding: '0.5em',
                    }}
                  >
                    Order Details
                  </span>
                </CardHeader>
                <CardBody style={{ padding: '1px', marginBottom: '-1.2em' }}>
                  <Row>
                    <Col md={12}>
                      <table className="table table-striped">
                        <tbody>
                          <tr>
                            <td colSpan={2}>
                              <b>{selectedPlanData.selectedPlanName + ' - '}</b>{' '}
                              <b style={{ color: 'blue' }}>{selectedPlanData.selectedPlanList.plantype}</b>
                            </td>
                            <td>
                              <b>{'$' + state.selectedPlanPrice}</b>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <ul>{featureList}</ul>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>

            <Col md={6} style={{ display: 'flex', flexDirection: 'column' }}>
              <Card
                style={{
                  width: '100%',
                  minHeight: '19.5em',
                  boxShadow:
                    'rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px',
                }}
              >
                <CardHeader
                  style={{
                    background: '#2b7d6d',
                    minHeight: '2em',
                    padding: '0.5em',
                  }}
                >
                  <span
                    style={{
                      color: '#fff',
                      fontWeight: 'bold',
                      padding: '0.5em',
                    }}
                  >
                    Billing Details
                  </span>
                </CardHeader>
                <CardBody style={{ minHeight: '8em' }}>
                  <Row md={12}>
                    <Col>
                      <Label style={{ color: '#000' }}>{state.firstName + ' ' + state.lastName}</Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label style={{ color: '#000' }}>{state.userEmail && state.userEmail}</Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Label style={{ color: '#000' }}>
                        {state.userPhone && Utils.displayUsaPhoneNumberFormate(state.userPhone)}
                      </Label>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {
                        <Label style={{ color: '#000' }}>
                          {/* {null !== state.userAddress?.line && undefined !== state.userAddress?.line
                            ? state.userAddress?.line + ','
                            : ''}{' '} */}
                          {Utils.getAddressAsText(state.userAddress ? state.userAddress : '')}
                        </Label>
                      }
                    </Col>
                  </Row>
                  {/* <Row>
                    <Col>
                      {' '}
                      <Label style={{ color: '#000' }}>
                        {null !== state.userAddress?.city && undefined !== state.userAddress?.city
                          ? state.userAddress?.city + ','
                          : ''}{' '}
                      </Label>{' '}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {' '}
                      <Label style={{ color: '#000' }}>
                        {null !== state.userAddress?.state && undefined !== state.userAddress?.state
                          ? state.userAddress?.state.name + ','
                          : ''}{' '}
                        {null !== state.userAddress?.state && undefined !== state.userAddress?.state
                          ? state.userAddress?.state.country.name
                          : ''}
                      </Label>{' '}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {
                        <Label style={{ color: '#000' }}>
                          {state.userAddress?.zipCode && state.userAddress?.zipCode}
                        </Label>
                      }
                    </Col>
                  </Row> */}
                </CardBody>
              </Card>
              <Card
                style={{
                  width: '100%',
                  marginTop: '2em',
                  boxShadow:
                    'rgb(128 128 128) 0px 3px, rgb(118 118 118) 0px 4px, rgb(108 108 108) 0px 5px, rgb(98 98 98) 0px 6px, rgb(88 88 88) 0px 7px, rgb(78 78 78) 0px 8px, rgb(128 128 128) 0px 14px 6px -1px',
                }}
              >
                <CardHeader
                  style={{
                    background: '#2b7d6d',
                    minHeight: '2em',
                    padding: '0.5em',
                  }}
                >
                  <span
                    style={{
                      color: '#fff',
                      fontWeight: 'bold',
                      margin: '0.5em',
                    }}
                  >
                    Payments
                  </span>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <span>
                        <b>Order Total : </b>
                      </span>
                      <span>
                        <b>{'$' + state.selectedPlanPrice}</b>
                      </span>
                    </Col>
                    <Col md={6}>
                      <Button
                        variant="contained"
                        size="small"
                        style={{
                          background: '#f26d21',
                          color: '#fff',
                          borderBottom: '3px solid #636262',
                          borderRadius: '5px',
                          margin: '0 auto',
                          width: '100%',
                        }}
                        onClick={handleClick}
                      >
                        {state.loading || !state.price ? `Pay Now` : `Pay Now`}
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </section>
      </div>
    </>
  );
};

export default Checkout;
