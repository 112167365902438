import { CLEAR_PROFILE, GET_PROFILE, SET_PROFILE } from './ProfileManagementTypes';

const getProfile = () => {
    return {
        type: GET_PROFILE,
    };
};

const setProfile = (data) => {
    return {
        type: SET_PROFILE,
        payload: data,
    };
};

const clearProfile = (data) => {
    return {
        type: CLEAR_PROFILE,
        payload: data,
    };
};
const profileManagementAction = { getProfile, setProfile, clearProfile };

export default profileManagementAction;
