import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AccessAlarmTwoToneIcon from '@material-ui/icons/AccessAlarmTwoTone';
import ChatIcon from '@material-ui/icons/Chat';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import Autocomplete from '@material-ui/lab/Autocomplete';
import MaterialTable from '@material-table/core';
import React, { Component } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Card, CardHeader, Col, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import UiUtils from '../../../provider/UiUtils';
import Utils from '../../../provider/Utils';
import applicationStateAction from '../../../redux/applicationState/applicationStateAction';
import fccPageStateAction from '../../../redux/fccPageState/fccPageStateAction';
import store from '../../../redux/store';
import Advertisement from '../advertisement/Advertisement';
import AddIconButton from '../buttons/AddIconButton';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
import PtsAlert from '../ptsAlert/PtsAlert';
import './MemberEventList.scss';
import BreadCrumbDetailsAction from '../../../redux/breadCrumbDetails/BreadCrumbDetailsAction';
export class MemberEventList extends Component {
  header = store.getState().header.header;
  memberDetails = store.getState().memberData.memberData;
  yearListArray = store.getState().staticData.yearList;
  eventState = store.getState().applicationState.eventState;
  isFreeUser = store.getState().userData.userData.isFreeUser;
  sponsorUser = store.getState().Sponsorship.Sponsorship;
  memberName = store.getState().memberData.memberData.firstname + ' ' + store.getState().memberData.memberData.lastname;
  memberHomeUrl = store.getState().memberHomeUrl.memberHomeUrl.homeUrl;
  displayHelp = store.getState().help.help;
  title = 'Event List';

  breadCrumb = [
    { pageid: this.memberHomeUrl, uiname: this.memberName },
    { pageid: '/member_event', uiname: 'Event List' },
  ];

  monthList = [
    { month: 'All' },
    { month: 'January' },
    { month: 'February' },
    { month: 'March' },
    { month: 'April' },
    { month: 'May' },
    { month: 'June' },
    { month: 'July' },
    { month: 'August' },
    { month: 'September' },
    { month: 'October' },
    { month: 'November' },
    { month: 'December' },
  ];
  eventStateDetails = {};
  constructor(props) {
    super(props);
    this.yearListArray.unshift({ name: 'All', id: 'All' });
    let yearListArray1 = this.yearListArray.reduce((unique, o) => {
      if (!unique.some((obj) => obj.name === o.name && obj.id === o.id)) {
        unique.push(o);
      }
      return unique;
    }, []);

    store.dispatch(fccPageStateAction.setFccPageState({}));

    if (this.eventState) {
      this.eventStateDetails = Object.getOwnPropertyNames(this.eventState);
    }
    // /*current URL path for sponsorship template*/
    let currentLocationPath1 = window.location.href;
    let pathArray = currentLocationPath1.split('/');
    let host = pathArray[4];
    let currentLocationPath = '/' + host;
    store.dispatch(fccPageStateAction.setFccPageState({}));
    if (this.breadCrumb) {
      Utils.getActiveMenu(this.breadCrumb);
      store.dispatch(BreadCrumbDetailsAction.setBreadCrumbDetails(this.breadCrumb));
    }
    if (this.eventStateDetails.length > 0) {
      this.state = {
        eventList: [],
        headerMonth: '',
        headerYear: '',
        anchorEl: null,
        month: this.eventState.eventMonth,
        allAppointmentList: [],
        scheduledEventList: [],
        cancelledEventList: [],
        completedEventList: [],
        render: false,
        yearList: yearListArray1,
        year: this.eventState.eventYear,
        checkbox: false,
        yearCheckbox: false,
        monthView: false,
        yearView: false,
        selectedYear: '',
        selectedMonth: '',
        selectedTab: this.eventState.selectedTab,
        initialPage: this.eventState.initialPage && this.eventState.initialPage > 0 ? this.eventState.initialPage : 0,
        eventId: this.eventState.eventId,
        getCreatePermission: false,
        isFreeUser: this.isFreeUser,
        sponsorUser: this.sponsorUser,
        currentLocationPath: currentLocationPath,
        stepsEnabled: false,
        initialStep: 0,
        pageSize: this.eventState.pageSize && this.eventState.pageSize ? this.eventState.pageSize : 10,
        displayFloatButton: this.displayHelp,
        isScheduledEventListPaging: false,
        isCancelledEventListPaging: false,
        isCompletedEventListPaging: false,
        isAllEventListPaging: false,
      };
    } else {
      this.state = {
        eventList: [],
        headerMonth: '',
        headerYear: '',
        anchorEl: null,
        month: this.monthList[0],
        alleventList: [],
        scheduledEventList: [],
        cancelledEventList: [],
        completedEventList: [],
        render: false,
        yearList: yearListArray1,
        year: yearListArray1[0],
        checkbox: false,
        yearCheckbox: false,
        monthView: false,
        yearView: false,
        selectedYear: '',
        selectedMonth: '',
        selectedTab: '0',
        initialPage: 0,
        eventId: '',
        getCreatePermission: false,
        isFreeUser: this.isFreeUser,
        sponsorUser: this.sponsorUser,
        currentLocationPath: currentLocationPath,
        stepsEnabled: false,
        initialStep: 0,
        pageSize: 10,
        isScheduledEventListPaging: false,
        isCancelledEventListPaging: false,
        isCompletedEventListPaging: false,
        isAllEventListPaging: false,
        displayFloatButton: this.displayHelp,
      };
    }
  }
  async componentDidMount() {
    if (this.eventStateDetails.length > 0) {
      await this.filterDataInBackend();
    } else {
      await this.getEventList();
    }
  }
  handleMonthChange(selectedMonth) {
    this.state.month = selectedMonth;
    this.setState({
      month: selectedMonth,
    });
    if (undefined !== this.state.onChange && null !== this.state.onChange) {
      this.state.onChange(this.state);
    }
  }

  handleYearChange(selectedYear) {
    this.state.year = selectedYear;
    this.setState({
      year: selectedYear,
    });
    if (undefined !== this.state.onChange && null !== this.state.onChange) {
      this.state.onChange(this.state);
    }
  }

  getEventList() {
    const postObject = {
      header: this.header,

      member: {
        id: this.memberDetails.member,
      },
      month: 'All',
      year: 'All',
    };

    FetchServerData.callPostService('/eventms/getFilteredList', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let eventListArr = [];
        eventListArr = output.data.eventList;
        for (let i = 0; i < eventListArr.length; i++) {
          eventListArr[i].sortDate = eventListArr[i].date;
          eventListArr[i].date = Utils.convertUTCDateToUserTimeZoneViewDateFormate(eventListArr[i].date);
        }

        let getDomainObjectPermission = output.data.domainObjectPrivileges;

        let getCreatePermission = false;

        for (let domainObjectKey in getDomainObjectPermission) {
          if (domainObjectKey.toLowerCase() === 'event') {
            let eventPermission = getDomainObjectPermission[domainObjectKey];
            let getPermission = Utils.getDomainObjectRolePermission(eventPermission);

            getCreatePermission = getPermission[0];
          }
        }
        let scheduledEventListArr = [];
        let cancelledEventListArr = [];
        let completedEventListArr = [];
        let isScheduledEventListPaging = false;
        let isCancelledEventListPaging = false;
        let isCompletedEventListPaging = false;
        let isAllEventListPaging = false;
        for (let i = 0; i < eventListArr.length; i++) {
          if (eventListArr[i].status === 'Scheduled') {
            scheduledEventListArr.push(eventListArr[i]);
          } else if (eventListArr[i].status === 'ReScheduled') {
            scheduledEventListArr.push(eventListArr[i]);
          } else if (eventListArr[i].status === 'Cancelled') {
            cancelledEventListArr.push(eventListArr[i]);
          } else {
            completedEventListArr.push(eventListArr[i]);
          }
        }
        for (let i = 0; i < scheduledEventListArr.length; i++) {
          scheduledEventListArr[i].rowNum = i + 1;
        }
        for (let i = 0; i < cancelledEventListArr.length; i++) {
          cancelledEventListArr[i].rowNum = i + 1;
        }
        for (let i = 0; i < completedEventListArr.length; i++) {
          completedEventListArr[i].rowNum = i + 1;
        }

        if (scheduledEventListArr.length > 10) {
          isScheduledEventListPaging = true;
        }
        if (cancelledEventListArr.length > 10) {
          isCancelledEventListPaging = true;
        }
        if (completedEventListArr.length > 10) {
          isCompletedEventListPaging = true;
        }
        if (eventListArr.length > 10) {
          isAllEventListPaging = true;
        }

        this.setState({
          render: true,
          isScheduledEventListPaging: isScheduledEventListPaging,
          isCancelledEventListPaging: isCancelledEventListPaging,
          isCompletedEventListPaging: isCompletedEventListPaging,
          isAllEventListPaging: isAllEventListPaging,
          allEventList: eventListArr,
          scheduledEventList: scheduledEventListArr,
          cancelledEventList: cancelledEventListArr,
          completedEventList: completedEventListArr,
          getCreatePermission: getCreatePermission,
        });
      } else {
      }
    });
  }

  addEvent = (e) => {
    let fccPageState = {
      eventId: '',
      action: 'create',
      title: 'Add EventaddEvent',
      pageId: 'event',
    };
    store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
    this.props.history.push({
      pathname: '/addEvent',
      state: { action: 'create', title: 'Add Event', pageId: 'event', eventId: '' },
    });
  };

  toEventDetails = (e, data) => {
    let eventState = {
      eventMonth: this.state.month,
      eventYear: this.state.year,
      selectedTab: this.state.selectedTab,
      initialPage: this.state.initialPage,
      eventId: data.id,
      pageSize: this.state.pageSize,
    };
    store.dispatch(applicationStateAction.setApplicationState({ eventState }));
    let fccPageState = {
      id: data.id,
      action: 'view',
      title: 'Event Details',
    };
    store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
    this.props.history.push({
      pathname: '/viewEvent',
      state: { action: 'view', title: 'Event Details', eventId: data.id, pageId: 'eventList' },
    });
  };

  handleShowUnreadMessageCountClose = () => this.setState({ anchorEl: null });

  handleShowUnreadMessageCountClick = (event) => this.setState({ anchorEl: event.currentTarget });

  showUnreadMessageCount = (noteCount) => {
    let noteListCount = noteCount.map((requestData, index) => {
      return (
        <>
          <tr style={{ textAlign: 'center' }}>
            <td>{requestData.note}</td>
            <td>{requestData.unreadMessageCount}</td>
          </tr>
        </>
      );
    });
    return noteListCount;
  };
  displayActionTitle() {
    return <h5 className="doctorAppointmentAction">Action</h5>;
  }

  setKey(tab) {
    this.setState({
      selectedTab: tab,
    });
    let eventState = {
      eventMonth: this.state.month,
      eventYear: this.state.year,
      selectedTab: tab,
    };
    store.dispatch(
      applicationStateAction.setApplicationState({
        eventState,
      })
    );
  }

  onChangePage = (event, page) => {
    this.setState({
      initialPage: page,
    });
    let eventState = {
      eventMonth: this.state.month,
      eventYear: this.state.year,
      selectedTab: this.state.selectedTab,
      initialPage: page,
    };
    store.dispatch(
      applicationStateAction.setApplicationState({
        eventState,
      })
    );
  };

  resetData() {
    this.setState({
      selectedTab: this.state.selectedTab,
      year: this.state.yearList[0],
      month: this.monthList[0],
    });

    let eventState = {};
    store.dispatch(applicationStateAction.setApplicationState({ eventState }));
    this.resetFilter();
  }
  resetFilter() {
    const postObject = {
      header: this.header,
      member: {
        id: this.memberDetails.member,
      },
      // memberDetails: {
      //   id: this.memberDetails.member,
      month: 'All',
      year: 'All',
      // },
    };
    FetchServerData.callPostService('/eventms/getFilteredList', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let eventListArr = [];
        eventListArr = output.data.eventList;
        for (let i = 0; i < eventListArr.length; i++) {
          eventListArr[i].sortDate = eventListArr[i].date;
          eventListArr[i].date = Utils.convertUTCDateToUserTimeZoneViewDateFormate(eventListArr[i].date);
        }

        let getDomainObjectPermission = output.data.domainObjectPrivileges;
        let getCreatePermission = false;

        for (let domainObjectKey in getDomainObjectPermission) {
          if (domainObjectKey.toLowerCase() === 'event') {
            let eventPermission = getDomainObjectPermission[domainObjectKey];
            let getPermission = Utils.getDomainObjectRolePermission(eventPermission);
            getCreatePermission = getPermission[0];
          }
        }
        let scheduledEventListArr = [];
        let cancelledEventListArr = [];
        let completedEventListArr = [];

        let isScheduledEventListPaging = false;
        let isCancelledEventListPaging = false;
        let isCompletedEventListPaging = false;
        let isAllEventListPaging = false;
        for (let i = 0; i < eventListArr.length; i++) {
          if (eventListArr[i].status === 'Scheduled') {
            scheduledEventListArr.push(eventListArr[i]);
          } else if (eventListArr[i].status === 'ReScheduled') {
            scheduledEventListArr.push(eventListArr[i]);
          } else if (eventListArr[i].status === 'Cancelled') {
            cancelledEventListArr.push(eventListArr[i]);
          } else {
            completedEventListArr.push(eventListArr[i]);
          }
        }

        for (let i = 0; i < scheduledEventListArr.length; i++) {
          scheduledEventListArr[i].rowNum = i + 1;
        }
        for (let i = 0; i < cancelledEventListArr.length; i++) {
          cancelledEventListArr[i].rowNum = i + 1;
        }
        for (let i = 0; i < completedEventListArr.length; i++) {
          completedEventListArr[i].rowNum = i + 1;
        }

        if (scheduledEventListArr.length > 10) {
          isScheduledEventListPaging = true;
        }
        if (cancelledEventListArr.length > 10) {
          isCancelledEventListPaging = true;
        }
        if (completedEventListArr.length > 10) {
          isCompletedEventListPaging = true;
        }
        if (eventListArr.length > 10) {
          isAllEventListPaging = true;
        }
        this.setState({
          render: true,
          isScheduledEventListPaging: isScheduledEventListPaging,
          isCancelledEventListPaging: isCancelledEventListPaging,
          isCompletedEventListPaging: isCompletedEventListPaging,
          isAllEventListPaging: isAllEventListPaging,

          allEventList: eventListArr,
          scheduledEventList: scheduledEventListArr,
          cancelledEventList: cancelledEventListArr,
          completedEventList: completedEventListArr,

          getCreatePermission: getCreatePermission,
        });
        let eventState = {
          eventMonth: this.state.month,
          eventYear: this.state.year,
          selectedTab: this.state.selectedTab,
          initialPage: this.state.initialPage,
          pageSize: this.state.pageSize,
        };
        store.dispatch(
          applicationStateAction.setApplicationState({
            eventState,
          })
        );
      } else {
      }
    });
  }
  showStatusColor(status) {
    let color = '';
    if (status === 'Accepted') {
      color = '#2ECC71';
    } else if (status === 'Requested') {
      color = '#D4AC0D';
    } else if (status === 'Rejected') {
      color = '#E74C3C';
    } else {
      color = '';
    }
    return color;
  }
  filterDataInBackend() {
    let selectedMonth = '';
    let selectedYear = '';

    if (this.state.month === null) {
      PtsAlert.error('Please Select Specific Month');
    } else if (this.state.year === null) {
      PtsAlert.error('Please Select Specific Year');
    } else {
      if (this.state.month !== null && this.state.month !== undefined) {
        if (Object.keys(this.state.month).length === 0 && this.state.month.constructor === Object) {
          selectedMonth = 'All';
        } else {
          selectedMonth = this.state.month.month;
        }
      } else {
        selectedMonth = 'All';
      }

      if (this.state.year !== null && this.state.year !== undefined) {
        if (Object.keys(this.state.year).length === 0 && this.state.year.constructor === Object) {
          selectedYear = 'All';
        } else {
          selectedYear = this.state.year.name;
        }
      } else {
        selectedYear = 'All';
      }

      const postObject = {
        header: this.header,
        member: {
          id: this.memberDetails.member,
        },
        // memberDetails: {
        //   id: this.memberDetails.member,
        month: selectedMonth,
        year: selectedYear,
        // },
      };
      FetchServerData.callPostService('/eventms/getFilteredList', postObject).then((output) => {
        if (output.status === 'SUCCESS') {
          let eventListArr = [];
          eventListArr = output.data.eventList;
          for (let i = 0; i < eventListArr.length; i++) {
            eventListArr[i].sortDate = eventListArr[i].date;
            eventListArr[i].date = Utils.convertUTCDateToUserTimeZoneViewDateFormate(eventListArr[i].date);
          }

          let getDomainObjectPermission = output.data.domainObjectPrivileges;

          let getCreatePermission = false;

          for (let domainObjectKey in getDomainObjectPermission) {
            if (domainObjectKey.toLowerCase() === 'event') {
              let eventPermission = getDomainObjectPermission[domainObjectKey];
              let getPermission = Utils.getDomainObjectRolePermission(eventPermission);

              getCreatePermission = getPermission[0];
            }
          }
          let scheduledEventListArr = [];
          let cancelledEventListArr = [];
          let completedEventListArr = [];
          let isScheduledEventListPaging = false;
          let isCancelledEventListPaging = false;
          let isCompletedEventListPaging = false;
          let isAllEventListPaging = false;
          for (let i = 0; i < eventListArr.length; i++) {
            if (eventListArr[i].status === 'Scheduled') {
              scheduledEventListArr.push(eventListArr[i]);
            } else if (eventListArr[i].status === 'ReScheduled') {
              scheduledEventListArr.push(eventListArr[i]);
            } else if (eventListArr[i].status === 'Cancelled') {
              cancelledEventListArr.push(eventListArr[i]);
            } else {
              completedEventListArr.push(eventListArr[i]);
            }
          }
          for (let i = 0; i < scheduledEventListArr.length; i++) {
            scheduledEventListArr[i].rowNum = i + 1;
          }
          for (let i = 0; i < cancelledEventListArr.length; i++) {
            cancelledEventListArr[i].rowNum = i + 1;
          }
          for (let i = 0; i < completedEventListArr.length; i++) {
            completedEventListArr[i].rowNum = i + 1;
          }

          if (scheduledEventListArr.length > 10) {
            isScheduledEventListPaging = true;
          }
          if (cancelledEventListArr.length > 10) {
            isCancelledEventListPaging = true;
          }
          if (completedEventListArr.length > 10) {
            isCompletedEventListPaging = true;
          }
          if (eventListArr.length > 10) {
            isAllEventListPaging = true;
          }
          this.setState({
            render: true,
            isScheduledEventListPaging: isScheduledEventListPaging,
            isCancelledEventListPaging: isCancelledEventListPaging,
            isCompletedEventListPaging: isCompletedEventListPaging,
            isAllEventListPaging: isAllEventListPaging,
            allEventList: eventListArr,
            scheduledEventList: scheduledEventListArr,
            cancelledEventList: cancelledEventListArr,
            completedEventList: completedEventListArr,
            getCreatePermission: getCreatePermission,
          });
          let eventState = {
            eventMonth: this.state.month,
            eventYear: this.state.year,
            selectedTab: this.state.selectedTab,
            initialPage: this.state.initialPage,
            pageSize: this.state.pageSize,
          };
          store.dispatch(
            applicationStateAction.setApplicationState({
              eventState,
            })
          );
        } else {
        }
      });
    }
  }
  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }
  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      this.state.scheduledEventList.sort(function compare(a, b) {
        var dateA = new Date(a.sortDate);
        var dateB = new Date(b.sortDate);
        return dateA - dateB;
      });
      return (
        <>
          <MenuWithBreadScrum breadCrumb={this.breadCrumb} history={this.props.history} name={this.title} />
          <Row style={{ margin: '1em' }}>
            <Advertisement pageId="Event" />
          </Row>
          <div className="appointmentList_filter">
            <Card style={{ background: '#fff', margin: '1.2em' }}>
              <CardHeader style={{ padding: '0em 1.2em' }}>
                <Row md={12} style={{ padding: '10px' }}>
                  <Col md={3}>
                    <Autocomplete
                      id="month"
                      options={this.monthList}
                      value={this.state.month}
                      getOptionLabel={(option) => option.month}
                      onChange={(event, value) => {
                        this.handleMonthChange(value);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Month" margin="normal" />
                      )}
                    />
                  </Col>

                  <Col md={3}>
                    <Autocomplete
                      id="year"
                      options={null === this.state.yearList ? [] : this.state.yearList}
                      value={this.state.year}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        this.handleYearChange(value);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Year" margin="normal" />
                      )}
                    />
                  </Col>

                  <Col md={2} className="filter_btn">
                    <Button
                      variant="contained"
                      size="small"
                      className="filterData"
                      startIcon={
                        <span>
                          <i className="fa fa-filter" aria-hidden="true"></i>
                        </span>
                      }
                      style={{
                        background: '#ef6603',
                        color: '#fff',
                        marginTop: '18px',
                        textTransform: 'capitalize',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => this.filterDataInBackend()}
                    >
                      Filter
                    </Button>
                    &nbsp;
                    <Button
                      variant="contained"
                      size="small"
                      className="resetData"
                      startIcon={
                        <span>
                          <i className="fa fa-refresh" aria-hidden="true"></i>
                        </span>
                      }
                      style={{
                        background: '#000',
                        color: '#fff',
                        marginTop: '18px',

                        textTransform: 'capitalize',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => this.resetData()}
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
            </Card>
          </div>
          <Row>
            <Col md={12} style={{ padding: '0em 2.4em 1em 2.4em' }} className="appointmentTab">
              {this.state.getAllPermission === true || this.state.getCreatePermission === true ? (
                <Row style={{ backgroundColor: 'none', margin: '-0.5em -1.3em -2.4em' }}>
                  <Col md={12}>
                    <AddIconButton btnName="Add Event" onClick={(e) => this.addEvent(e)} />
                  </Col>
                </Row>
              ) : (
                <></>
              )}
              <Tabs defaultIndex={this.state.selectedTab} onSelect={(k) => this.setKey(k)}>
                <TabList>
                  <Tab>
                    {' '}
                    <span className="tabUpcoming">Upcoming</span>
                  </Tab>
                  <Tab>
                    <span className="tabCompleted">Completed</span>
                  </Tab>
                  <Tab>
                    <span className="tabCancelled">Cancelled</span>
                  </Tab>
                  <Tab>
                    {' '}
                    <span className="tabAll">All</span>
                  </Tab>
                </TabList>
                <TabPanel>
                  <MaterialTable
                    icons={UiUtils.getMaterialTableIcons()}
                    title=""
                    columns={[
                      {
                        title: '#',
                        filtering: false,
                        render: (rowData) => rowData.tableData.index + 1,
                        width: '5%',
                      },
                      {
                        title: 'Date',
                        type: 'date',
                        field: 'date',
                        filtering: false,
                        width: '25%',
                        customSort: (a, b) => {
                          let aTime = a.sortDate !== null ? new Date(a.sortDate).getTime() : '';
                          let bTime = b.sortDate !== null ? new Date(b.sortDate).getTime() : '';
                          return aTime - bTime;
                        },

                        align: 'left',
                      },
                      { title: 'Event Title', field: 'title', width: '20%' },
                      { title: 'Location', field: 'location', width: '20%' },
                      { title: 'Status', field: 'status', width: '15%', filtering: false },
                      {
                        title: '',
                        render: (rowData) =>
                          rowData.markCompleteCancel === true ? (
                            <>
                              <h6 className="blink_me" style={{ display: 'inline-flex' }}>
                                Mark Complete / Cancel
                              </h6>
                            </>
                          ) : (
                            ''
                          ),
                        width: '15%',
                      },
                    ]}
                    data={this.state.scheduledEventList}
                    options={{
                      sorting: true,
                      search: true,
                      actionsColumnIndex: -1,
                      headerStyle: {
                        backgroundColor: '#1ABC9C',
                        fontWeight: 'bold',
                        color: '#fff',
                        align: 'center',
                      },
                      // paging: this.state.isScheduledEventListPaging,
                      paging: this.state.scheduledEventList.length > this.state.pageSize,
                      pageSize: this.state.pageSize,
                      initialPage: this.state.initialPage,
                      pageSizeOptions: [5, 10, 20, 50, 100],
                      filtering: true,
                      rowStyle: (rowData) => ({
                        backgroundColor: rowData.id === this.state.eventId ? '#FEF5E7' : '#fff',
                        height: '3em',
                      }),
                      tableLayout: 'auto',
                    }}
                    localization={{
                      header: {
                        actions: this.displayActionTitle(),
                      },
                      body: {
                        emptyDataSourceMessage: Utils.communizeTableText(),
                      },
                    }}
                    actions={[
                      (rowData) => ({
                        icon: () =>
                          /*if appointment has message threads then show then button */
                          rowData.hasNotes === true ? (
                            <Badge badgeContent={rowData.unreadMessageCount} color="primary">
                              <ChatIcon
                                className="hasMessageThreads"
                                style={{ fontSize: '25px', color: '#58D68D', marginRight: '0.2em' }}
                              />
                            </Badge>
                          ) : (
                            <div style={{ marginLeft: '1em' }}></div>
                          ),
                        tooltip: 'Message',
                      }),
                      (rowData) => ({
                        icon: () =>
                          /*if appointment has active Reminder  then show then button */
                          rowData.hasReminders === true ? (
                            <Badge badgeContent={rowData.activeReminderCount} color="primary">
                              <AccessAlarmTwoToneIcon
                                className="reminder"
                                style={{ fontSize: '25px', color: '#EF797D' }}
                              />
                            </Badge>
                          ) : (
                            <div style={{ marginLeft: '1em', fontSize: '25px' }}></div>
                          ),
                        tooltip: 'Reminder',
                      }),
                      (rowData) => ({
                        /*if appointment has transportation  then show then button */
                        icon: () =>
                          rowData.transportationCount > 0 ? (
                            <DriveEtaIcon
                              className="transportation"
                              style={{ fontSize: '25px', color: this.showStatusColor(rowData.transportationStatus) }}
                            />
                          ) : (
                            <div style={{ marginLeft: '1em', fontSize: '25px' }}></div>
                          ),
                        tooltip: 'Transportation',
                      }),
                    ]}
                    onRowClick={(event, rowData) => {
                      this.toEventDetails(event, rowData);
                      event.stopPropagation();
                    }}
                    onPageChange={(page, pageSize) => {
                      this.handleMaterialTablePageChange(page, pageSize);
                    }}
                  />
                </TabPanel>
                <TabPanel>
                  <MaterialTable
                    icons={UiUtils.getMaterialTableIcons()}
                    title=""
                    columns={[
                      {
                        title: '#',
                        render: (rowData) => rowData.tableData.index + 1,
                        filtering: false,
                        width: '5%',
                      },

                      {
                        title: 'Date',
                        type: 'date',
                        field: 'date',
                        filtering: false,
                        width: '20%',
                        customSort: (a, b) => {
                          let aTime = a.sortDate !== null ? new Date(a.sortDate).getTime() : '';
                          let bTime = b.sortDate !== null ? new Date(b.sortDate).getTime() : '';
                          return aTime - bTime;
                        },

                        align: 'left',
                      },
                      { title: 'Event Title', field: 'title', width: '20%' },
                      { title: 'Location', field: 'location', width: '20%' },
                      { title: 'Status', field: 'status', width: '20%', filtering: false },
                    ]}
                    data={this.state.completedEventList}
                    options={{
                      sorting: true,
                      actionsColumnIndex: -1,
                      headerStyle: {
                        backgroundColor: '#1ABC9C',
                        fontWeight: 'bold',
                        color: '#fff',
                        align: 'center',
                      },
                      // paging: this.state.isAllEventListPaging,
                      paging: this.state.completedEventList.length > this.state.pageSize,
                      pageSize: this.state.pageSize,
                      initialPage: this.state.initialPage,
                      pageSizeOptions: [5, 10, 20, 50, 100],
                      filtering: true,
                      rowStyle: (rowData) => ({
                        backgroundColor: rowData.id === this.state.eventId ? '#FEF5E7' : '#fff',
                        height: '3em',
                      }),
                    }}
                    localization={{
                      header: {
                        actions: this.displayActionTitle(),
                      },
                      body: {
                        emptyDataSourceMessage: Utils.communizeTableText(),
                      },
                    }}
                    actions={[
                      (rowData) => ({
                        icon: () =>
                          /*if appointment has message threads then show then button */
                          rowData.hasNotes === true ? (
                            <Badge badgeContent={rowData.unreadMessageCount} color="primary">
                              <ChatIcon
                                className="hasMessageThreads"
                                style={{ fontSize: '25px', color: '#58D68D', marginRight: '0.2em' }}
                              />
                            </Badge>
                          ) : (
                            <div style={{ marginLeft: '1em' }}></div>
                          ),
                        tooltip: 'Message',
                      }),
                      (rowData) => ({
                        icon: () =>
                          /*if appointment has active Reminder  then show then button */
                          rowData.hasReminders === true ? (
                            <Badge badgeContent={rowData.activeReminderCount} color="primary">
                              <AccessAlarmTwoToneIcon
                                className="reminder"
                                style={{ fontSize: '25px', color: '#EF797D' }}
                              />
                            </Badge>
                          ) : (
                            <div style={{ marginLeft: '1em', fontSize: '25px' }}></div>
                          ),
                        tooltip: 'Reminder',
                      }),
                      (rowData) => ({
                        /*if appointment has transportation  then show then button */
                        icon: () =>
                          rowData.transportationCount > 0 ? (
                            <DriveEtaIcon
                              className="transportation"
                              style={{ fontSize: '25px', color: this.showStatusColor(rowData.transportationStatus) }}
                            />
                          ) : (
                            <div style={{ marginLeft: '1em', fontSize: '25px' }}></div>
                          ),
                        tooltip: 'Transportation',
                      }),
                    ]}
                    onRowClick={(event, rowData) => {
                      this.toEventDetails(event, rowData);
                      event.stopPropagation();
                    }}
                    onPageChange={(page, pageSize) => {
                      this.handleMaterialTablePageChange(page, pageSize);
                    }}
                  />
                </TabPanel>
                <TabPanel>
                  <MaterialTable
                    icons={UiUtils.getMaterialTableIcons()}
                    title=""
                    columns={[
                      {
                        title: '#',
                        render: (rowData) => rowData.tableData.index + 1,
                        filtering: false,
                        width: '5%',
                      },

                      {
                        title: 'Date',
                        type: 'date',
                        field: 'date',
                        filtering: false,
                        width: '20%',
                        customSort: (a, b) => {
                          let aTime = a.sortDate !== null ? new Date(a.sortDate).getTime() : '';
                          let bTime = b.sortDate !== null ? new Date(b.sortDate).getTime() : '';
                          return aTime - bTime;
                        },

                        align: 'left',
                      },
                      { title: 'Event Title', field: 'title', width: '20%' },
                      { title: 'Location', field: 'location', width: '20%' },
                      { title: 'Status', field: 'status', width: '20%', filtering: false },
                    ]}
                    data={this.state.cancelledEventList}
                    options={{
                      sorting: true,
                      actionsColumnIndex: -1,
                      headerStyle: {
                        backgroundColor: '#1ABC9C',
                        fontWeight: 'bold',
                        color: '#fff',
                        align: 'center',
                      },
                      // paging: this.state.isAllEventListPaging,
                      paging: this.state.cancelledEventList.length > this.state.pageSize,
                      pageSize: this.state.pageSize,
                      initialPage: this.state.initialPage,
                      pageSizeOptions: [5, 10, 20, 50, 100],
                      filtering: true,
                      rowStyle: (rowData) => ({
                        backgroundColor: rowData.id === this.state.eventId ? '#FEF5E7' : '#fff',
                        height: '3em',
                      }),
                    }}
                    localization={{
                      header: {
                        actions: this.displayActionTitle(),
                      },
                      body: {
                        emptyDataSourceMessage: Utils.communizeTableText(),
                      },
                    }}
                    actions={[
                      (rowData) => ({
                        icon: () =>
                          /*if appointment has message threads then show then button */
                          rowData.hasNotes === true ? (
                            <Badge badgeContent={rowData.unreadMessageCount} color="primary">
                              <ChatIcon
                                className="hasMessageThreads"
                                style={{ fontSize: '25px', color: '#58D68D', marginRight: '0.2em' }}
                              />
                            </Badge>
                          ) : (
                            <div style={{ marginLeft: '1em' }}></div>
                          ),
                        tooltip: 'Message',
                      }),
                      (rowData) => ({
                        icon: () =>
                          /*if appointment has active Reminder  then show then button */
                          rowData.hasReminders === true ? (
                            <Badge badgeContent={rowData.activeReminderCount} color="primary">
                              <AccessAlarmTwoToneIcon
                                className="reminder"
                                style={{ fontSize: '25px', color: '#EF797D' }}
                              />
                            </Badge>
                          ) : (
                            <div style={{ marginLeft: '1em', fontSize: '25px' }}></div>
                          ),
                        tooltip: 'Reminder',
                      }),
                      (rowData) => ({
                        /*if appointment has transportation  then show then button */
                        icon: () =>
                          rowData.transportationCount > 0 ? (
                            <DriveEtaIcon
                              className="transportation"
                              style={{ fontSize: '25px', color: this.showStatusColor(rowData.transportationStatus) }}
                            />
                          ) : (
                            <div style={{ marginLeft: '1em', fontSize: '25px' }}></div>
                          ),
                        tooltip: 'Transportation',
                      }),
                    ]}
                    onRowClick={(event, rowData) => {
                      this.toEventDetails(event, rowData);
                      event.stopPropagation();
                    }}
                    onPageChange={(page, pageSize) => {
                      this.handleMaterialTablePageChange(page, pageSize);
                    }}
                  />
                </TabPanel>
                <TabPanel>
                  <MaterialTable
                    icons={UiUtils.getMaterialTableIcons()}
                    title=""
                    columns={[
                      {
                        title: '#',
                        render: (rowData) => rowData.tableData.index + 1,
                        filtering: false,
                        width: '5%',
                      },

                      {
                        title: 'Date',
                        type: 'date',
                        field: 'date',
                        filtering: false,
                        width: '25%',
                        customSort: (a, b) => {
                          let aTime = a.sortDate !== null ? new Date(a.sortDate).getTime() : '';
                          let bTime = b.sortDate !== null ? new Date(b.sortDate).getTime() : '';
                          return aTime - bTime;
                        },

                        align: 'left',
                      },
                      { title: 'Event Title', field: 'title', width: '20%' },
                      { title: 'Location', field: 'location', width: '20%' },
                      { title: 'Status', field: 'status', width: '15%', filtering: false },
                      {
                        title: '',
                        render: (rowData) =>
                          rowData.markCompleteCancel === true ? (
                            <>
                              <h6 className="blink_me">Mark Complete / Cancel</h6>
                            </>
                          ) : (
                            ''
                          ),
                        width: '15%',
                      },
                    ]}
                    data={this.state.allEventList}
                    options={{
                      sorting: true,
                      actionsColumnIndex: -1,
                      headerStyle: {
                        backgroundColor: '#1ABC9C',
                        fontWeight: 'bold',
                        color: '#fff',
                        align: 'center',
                      },
                      // paging: this.state.isAllEventListPaging,
                      paging: this.state.allEventList.length > this.state.pageSize,
                      pageSize: this.state.pageSize,
                      initialPage: this.state.initialPage,
                      pageSizeOptions: [5, 10, 20, 50, 100],
                      filtering: true,
                      rowStyle: (rowData) => ({
                        backgroundColor: rowData.id === this.state.eventId ? '#FEF5E7' : '#fff',
                        height: '3em',
                      }),
                    }}
                    localization={{
                      header: {
                        actions: this.displayActionTitle(),
                      },
                      body: {
                        emptyDataSourceMessage: Utils.communizeTableText(),
                      },
                    }}
                    actions={[
                      (rowData) => ({
                        icon: () =>
                          /*if appointment has message threads then show then button */
                          rowData.hasNotes === true ? (
                            <Badge badgeContent={rowData.unreadMessageCount} color="primary">
                              <ChatIcon
                                className="hasMessageThreads"
                                style={{ fontSize: '25px', color: '#58D68D', marginRight: '0.2em' }}
                              />
                            </Badge>
                          ) : (
                            <div style={{ marginLeft: '1em' }}></div>
                          ),
                        tooltip: 'Message',
                      }),
                      (rowData) => ({
                        icon: () =>
                          /*if appointment has active Reminder  then show then button */
                          rowData.hasReminders === true ? (
                            <Badge badgeContent={rowData.activeReminderCount} color="primary">
                              <AccessAlarmTwoToneIcon
                                className="reminder"
                                style={{ fontSize: '25px', color: '#EF797D' }}
                              />
                            </Badge>
                          ) : (
                            <div style={{ marginLeft: '1em', fontSize: '25px' }}></div>
                          ),
                        tooltip: 'Reminder',
                      }),
                      (rowData) => ({
                        /*if appointment has transportation  then show then button */
                        icon: () =>
                          rowData.transportationCount > 0 ? (
                            <DriveEtaIcon
                              className="transportation"
                              style={{ fontSize: '25px', color: this.showStatusColor(rowData.transportationStatus) }}
                            />
                          ) : (
                            <div style={{ marginLeft: '1em', fontSize: '25px' }}></div>
                          ),
                        tooltip: 'Transportation',
                      }),
                    ]}
                    onRowClick={(event, rowData) => {
                      this.toEventDetails(event, rowData);
                      event.stopPropagation();
                    }}
                    onPageChange={(page, pageSize) => {
                      this.handleMaterialTablePageChange(page, pageSize);
                    }}
                  />
                </TabPanel>
              </Tabs>
            </Col>
          </Row>
        </>
      );
    }
  }
}

export default MemberEventList;
