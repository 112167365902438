import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import AccessAlarmTwoToneIcon from '@material-ui/icons/AccessAlarmTwoTone';
import CloseIcon from '@material-ui/icons/Close';
import PinDropIcon from '@material-ui/icons/PinDrop';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import React, { Component } from 'react';
import Avatar, { ConfigProvider } from 'react-avatar';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Card, CardBody, CardFooter, CardHeader, Col, Label, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import appUserDataAction from '../../../redux/appUserData/appUserDataAction';
import helpDataAction from '../../../redux/help/displayHelpAction';
import selectedPlanAction from '../../../redux/selectedPlan/selectedPlanAction';
import store from '../../../redux/store';
import AddressWithTimezone from '../addressWithTimezone/AddressWithTimezone';
import BillingDetails from '../billingDetails/BillingDetails';
import CancelIconButton from '../buttons/CancelIconButton';
import EditIconButton from '../buttons/EditIconButton';
import SaveButton from '../buttons/SaveButton';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
import PtsAlert from '../ptsAlert/PtsAlert';
import Sponsorship from '../sponsorship/Sponsorship';
import moment from 'moment-timezone';
import HomeIcon from '@material-ui/icons/Home';
import DeleteIcon from '@material-ui/icons/Delete';
import DialogBox from '../dialogBox/DialogBox';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
export class Profile extends Component {
  header = store.getState().header.header;
  memberName = store.getState().memberData.memberData.firstname + ' ' + store.getState().memberData.memberData.lastname;
  memberDetails = store.getState().memberData.memberData;
  isFreeUser = store.getState().userData.userData.isFreeUser;
  displayHelp = store.getState().help.help;
  subscriptionEndDate = store.getState().userData.userDetails.subscriptionEndDate;
  title = 'User Profile';
  breadCrumb = [{ pageid: '', uiname: 'User Profile Details' }];
  addressRef = React.createRef();
  addressRef = {};
  constructor(props) {
    super(props);
    let action = props.action;
    let memberId = props.memberId;
    let title = '';
    let pageId = '';
    if (
      null == memberId &&
      null !== props.history &&
      props.history !== undefined &&
      props.history.location !== null &&
      props.history.location.state !== null &&
      props.history.location.state !== undefined
    ) {
      memberId = props.history.location.state.memberId;
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
      title = props.history.location.state.title;
    }
    if (!memberId) {
      memberId = this.userId;
    }
    this.state = {
      pageId: pageId,
      action: action,
      title: title,
      render: false,
      id: memberId,
      fname: '',
      mname: '',
      lname: '',
      salutation: {},
      description: '',
      dob: '',
      phone: '',
      email: '',
      gender: '',
      designation: '',
      address: {},
      line: '',
      city: '',
      state: '',
      country: '',
      zipCode: '',
      salutationList: [
        { id: '1', salutation: 'Mr' },
        { id: '2', salutation: 'Miss' },
        { id: '3', salutation: 'Mrs' },
      ],
      orderList: [],

      checked: true,
      orderItemsModal: false,
      orderItemList: [],
      memberListModal: false,
      memberList: [],
      pageSize: 5,
      isPaging: false,
      selectedUser: [],
      subscriptionId: '',
      selectedUserList: [],
      numberOfUser: '',
      isDisplayAddress: false,
      isCreateAddress: false,
      isEditAddress: false,
      freeSubscription: {},
      viewProfile: false,
      editProfile: false,
      viewAddress: false,
      editAddress: false,
      isFreeUser: this.isFreeUser,
      userProfileData: {},
      sponsorUser: {},
      timezone: '',
      timeZoneAbbreviation: '',
      stepsEnabled: false,
      initialStep: 0,
      steps: [
        {
          element: '.editDetails',
          intro: 'Edit Profile Details',
        },

        {
          element: '.editAddress',
          intro: 'Edit Address,Time Zone of the User',
        },

        {
          element: '.tabBilling',
          intro: 'Billing Details of the User',
        },
        {
          element: '.tabSponsorship',
          intro: 'Sponsorship Details',
        },
      ],
      BillingSteps: [
        {
          element: '.subscriptionPlan',
          intro: 'User’s Purchased Plan',
        },
        {
          element: '.manualCharge',
          intro: 'You will have to pay again for subscription.',
        },
        {
          element: '.autoCharge',
          intro: 'Payment will be automatically ',
        },
        {
          element: '.cancelSubscription',
          intro: ' Subscription will be cancelled after current subscription end date',
        },
        {
          element: '.caregiver',
          intro: 'User can see list of Caregivers for whom s/he has purchased subscription',
        },
        {
          element: '.addFamilyMemberSubscription',
          intro: 'Add more Caregivers  for this subscription ',
        },
        {
          element: '.addMember',
          intro: 'Add Caregivers ',
        },
        {
          element: '.delete',
          intro: 'Delete Caregivers ',
        },
        {
          element: '.orderHistory',
          intro: 'User’s order history',
        },
      ],
      freeUserBillingSteps: [
        {
          element: '.subscriptionPlan',
          intro: 'User’s Purchased Plan',
        },
        {
          element: '.purchaseSubscription',
          intro: 'Purchase Subscription',
        },
      ],
      sponsorShipSteps: [
        {
          element: '.Sponsor',
          intro: 'Enter Code sent to you registered email-id from Sponsor and enjoy Ad-Free experience.',
        },
      ],
      displayFloatButton: this.displayHelp,
      selectedTab: '0',
      subscriptionExpireModel: false,
      subscriptionExpiringModel: false,

      subscriptionExpireDay: '',
      memberId: '',
      deleteAccountModal: false,
      password: '',
      showPassword: false,
    };
    this.getProfileData();
  }
  toggleSteps = () => {
    this.setState((prevState) => ({ stepsEnabled: !prevState.stepsEnabled }));
  };

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };
  getProfileData() {
    const postObject = {
      header: this.header,
    };

    FetchServerData.callPostService('/memberms/getProfile', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let profileData = {};
        let userBillingData = {};
        let sponsorUser = {};
        console.log('if1');
        if (output.data.member !== null) {
          profileData = output.data.appuser;
          let profileAddress = {};
          let memberId = '';
          if (output.data.member !== null) {
            profileAddress = output.data.member.address;
            memberId = output.data.member.id;
          }
          userBillingData = output.data;
          let frereSubscription = output.data.freeSubscription;
          sponsorUser = output.data.sponsorshipUser;
          let subscriptionExpire = output.data.expiredSubscription;
          let subscriptionExpiring = output.data.expiringSubscription;
          let subscriptionExpireDay = output.data.days;
          let userSubscription = output.data.userSubscription;
          let userSelectedPlan = {};
          if (userSubscription !== null) {
            if (userSubscription.plan !== null) {
              userSelectedPlan = {
                selectedPlanName: userSubscription.plan.name,
                selectedPlanList: userSubscription.plan,
              };
            }
          }
          let salutation = this.state.salutationList[1];
          let orderItem = [];
          let selectedUserList = [];
          let subscriptionId = '';
          let numberOfUser = '';

          let sponsorData = {};
          if (sponsorUser !== null) {
            sponsorData = sponsorUser;
          } else {
            sponsorData = null;
          }

          if (profileAddress === null) {
            this.setState({
              isDisplayAddress: false,
              isCreateAddress: true,
              isEditAddress: false,
            });
          } else {
            this.setState({
              isDisplayAddress: true,
              isCreateAddress: false,
              isEditAddress: true,
            });
          }
          if (profileAddress === null || profileAddress.timezone === null) {
            this.setState({
              timezone: '',
              timeZoneAbbreviation: '',
            });
          } else {
            this.setState({
              timezone: profileAddress && profileAddress.timezone ? profileAddress.timezone.name : '',
              timeZoneAbbreviation:
                profileAddress && profileAddress.timezone ? profileAddress.timezone.abbreviation : '',
            });
          }
          if (profileData.salutation === 'Mr') {
            salutation = this.state.salutationList[0];
          } else if (profileData.salutation === 'Miss') {
            salutation = this.state.salutationList[1];
          } else {
            salutation = this.state.salutationList[2];
          }
          if (subscriptionExpire === true) {
            store.dispatch(selectedPlanAction.setSelectedPlan(userSelectedPlan));

            this.setState({
              subscriptionExpireModel: true,
              subscriptionExpiringModel: false,
            });
          } else {
            this.setState({
              subscriptionExpireModel: false,
              subscriptionExpiringModel: true,
            });
          }
          if (subscriptionExpiring === true) {
            store.dispatch(selectedPlanAction.setSelectedPlan(userSelectedPlan));

            this.setState({
              subscriptionExpiringModel: true,
            });
          } else {
            this.setState({
              subscriptionExpiringModel: false,
            });
          }

          if (profileData) {
            if (profileAddress || frereSubscription) {
              this.setState({
                subscriptionExpireDay: subscriptionExpireDay,
                render: true,
                viewProfile: true,
                editProfile: false,
                viewAddress: true,
                editAddress: false,
                salutation: salutation,
                id: profileData.id,
                fname: profileData.firstName,
                mname: profileData.middleName,
                lname: profileData.lastName,
                dob: profileData.dob,
                email: profileData.email,
                phone: Utils.displayUsaPhoneNumberFormate(profileData.phone),
                memberId: memberId,
                gender: profileData.gender,
                address: profileAddress,
                sponsorUser: sponsorData,
                orderItemList: orderItem,
                subscriptionId: subscriptionId,
                selectedUserList: selectedUserList,
                numberOfUser: numberOfUser,
                frereSubscription: frereSubscription,
                userBillingData: userBillingData,
                userProfileData: profileData,
              });
            } else {
              this.setState({
                render: true,
                subscriptionExpireDay: subscriptionExpireDay,
                viewProfile: true,
                editProfile: false,
                viewAddress: true,
                editAddress: false,
                salutation: salutation,
                id: profileData.id,
                fname: profileData.firstName,
                mname: profileData.middleName,
                lname: profileData.lastName,
                dob: profileData.dob,
                email: profileData.email,
                phone: Utils.displayUsaPhoneNumberFormate(profileData.phone),
                memberId: memberId,
                gender: profileData.gender,
                orderItemList: orderItem,
                sponsorUser: sponsorData,
                address: {},
                subscriptionId: subscriptionId,
                selectedUserList: selectedUserList,
                numberOfUser: numberOfUser,
                userBillingData: userBillingData,
                frereSubscription: {},
                userProfileData: profileData,
              });
            }
          } else {
            this.setState({
              render: false,
            });
          }
        } else {
          console.log('else');
          profileData = output.data.appuser;
          sponsorUser = output.data.sponsorshipUser;
          // sponsorUser = output.data.sponsorUser;
          userBillingData = output.data;
          let sponsorData = {};
          if (sponsorUser !== null) {
            sponsorData = sponsorUser;
          } else {
            sponsorData = null;
          }
          this.setState({
            render: true,
            viewProfile: true,
            editProfile: false,
            viewAddress: false,
            editAddress: false,
            id: profileData.id,
            userBillingData: userBillingData,
            sponsorUser: sponsorData,
            fname: profileData.firstName,
            mname: profileData.middleName,
            lname: profileData.lastName,
            dob: profileData.dob,
            email: profileData.email,
            phone: Utils.displayUsaPhoneNumberFormate(profileData.phone),
          });
        }
      }
    });
  }

  updateProfile(e) {
    this.setState({
      viewProfile: false,
      editProfile: true,
    });
  }

  editProfileCancel(e, memberId) {
    this.setState({
      viewProfile: true,
      editProfile: false,
    });
  }

  cancelEditProfileAddress() {
    this.setState({
      viewAddress: true,
      editAddress: false,
    });
  }

  updateProfileAddress() {
    this.setState({
      viewAddress: false,
      editAddress: true,
    });
  }

  handleFnameChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 128);
    if (res.length < 128) {
      this.setState({ fname: res });
    } else {
      Utils.maxFieldLength(128);
    }
  }

  handleLnameChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 128);
    if (res.length < 128) {
      this.setState({ lname: res });
    } else {
      Utils.maxFieldLength(128);
    }
  }

  handleEmailChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ email: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }

  handleMobileChange({ target: { value } }) {
    this.setState((prevState) => ({
      phone: Utils.convertPhoneNumberToUsaPhoneNumberFormate(value, prevState.phone),
    }));
  }

  handleAddressChange(address) {
    this.setState({ address: address });
  }

  handleSalutationChange(selectedSalutation) {
    if (!selectedSalutation) {
      this.setState({
        salutation: null,
      });
      return;
    }
    this.setState({
      salutation: selectedSalutation,
    });
  }

  updateProfileInBackend = (e) => {
    let mobile = this.state.phone.replace(/[^\d]/g, '');
    const postObject = {
      header: this.header,
      memberVo: {
        id: this.state.id,
        firstName: this.state.fname,
        lastName: this.state.lname,
        email: this.state.email,
        phone: mobile,
        isMemberUpdate: true,
        memberDetailsId: this.state.id,
        salutation: this.state.salutation.salutation,
      },
    };
    FetchServerData.callPostService('/memberms/updateUserProfile', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let memberDetails = output.data;
          let appuserVo = output.data;
          let profileAddress = memberDetails.address;
          store.dispatch(appUserDataAction.setUser({ memberDetails }));
          store.dispatch(appUserDataAction.setUser({ appuserVo }));
          this.setState({
            viewProfile: true,
            editProfile: false,
            address: profileAddress,
          });
          this.props.history.push({
            pathname: '/profile',
            state: { memberId: memberDetails.id },
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  updateProfileAddressInBackend = (e) => {
    const postObject = {
      header: this.header,
      memberVo: {
        id: this.state.memberId,
        // id: this.state.id,
        // memberDetailsId: this.state.id,
        isMemberUpdate: true,

        address: {
          id: this.addressRef.current.state.id,
          line: this.addressRef.current.state.line,
          state: this.addressRef.current.state.state,
          timezone: {
            id: this.addressRef.current.state.timeZone.id,
          },
          city: this.addressRef.current.state.city,
          zipCode: this.addressRef.current.state.zipCode,
        },
      },
    };
    FetchServerData.callPostService('/memberms/updateAddress', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let memberDetails = output.data;
          let appuserVo = output.data;
          let profileAddress = memberDetails.address;
          PtsAlert.success(output.message);
          // store.dispatch(appUserDataAction.setUser({ memberDetails }));
          store.dispatch(appUserDataAction.setUser({ appuserVo }));
          if (profileAddress.timezone === null) {
            this.setState({
              timezone: '',
              timeZoneAbbreviation: '',
              editAddress: false,
              viewAddress: true,
              address: profileAddress,
            });
          } else {
            this.setState({
              timezone: profileAddress.timezone.name,
              timeZoneAbbreviation: profileAddress.timezone.abbreviation,
              editAddress: false,
              viewAddress: true,
              address: profileAddress,
            });
          }

          this.props.history.push({
            pathname: '/profile',
            state: { memberId: memberDetails.id },
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  editCancel(e, memberId) {
    this.props.history.push({
      pathname: '/profile',
      state: { action: 'view', memberId: memberId },
    });
  }

  setKey(tab) {
    this.setState({
      selectedTab: tab,
    });
  }

  closeFloatingButton() {
    this.setState({ displayFloatButton: false });
    let displayFloat = false;

    store.dispatch(helpDataAction.setHelpData(displayFloat));
  }

  handleSubscriptionExpireModelClose = () => {
    this.setState({
      subscriptionExpireModel: false,
    });
  };

  handleSubscriptionExpiringModelClose = () => {
    this.setState({
      subscriptionExpiringModel: false,
    });
  };

  renewSubscriptionPlan = () => {
    this.props.history.push({
      pathname: '/checkout',
    });
  };
  closeAccountModal = (e) => {
    console.log('in');
    this.setState({
      deleteAccountModal: false,
    });
  };

  closeConfirmDeleteAccountModal = (e) => {
    console.log('in');
    this.setState({
      confirmDeleteAccountModal: false,
    });
  };
  deleteAccountConfirmationWindow = (e) => {
    console.log('e', e);

    this.setState({ confirmDeleteAccountModal: true, deleteAccountModal: false });
  };
  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  deleteAccountInBackend = (e) => {
    const header = store.getState().header.header;

    const postObject = {
      header: header,
      appuserVo: {
        email: this.state.email,
        credential: this.state.password,
      },
    };
    FetchServerData.callPostService('/userms/deleteAccount', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          this.setState({ confirmDeleteAccountModal: false });
          PtsAlert.success(output.message);
          this.props.history.push({
            pathname: '/',
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  handleClickOpen = () => {
    this.setState({ deleteAccountModal: true });
  };
  render() {
    if (this.state.action === 'edit') {
      return this.editProfileDetails();
    } else {
      return this.viewProfileDetails();
    }
  }

  viewProfileDetails() {
    if (this.state.render === false) {
      return <div />;
    } else {
      let fname = null !== this.state.fname && undefined !== this.state.fname ? this.state.fname : '';
      let lname = null !== this.state.lname && undefined !== this.state.lname ? this.state.lname : '';
      let userName = fname + ' ' + lname;
      return (
        <>
          <MenuWithBreadScrum breadCrumb={this.breadCrumb} history={this.props.history} name={this.title} />

          <div style={{ padding: '1em' }} className="appointmentTab">
            <Tabs defaultIndex={this.state.selectedTab} onSelect={(k) => this.setKey(k)}>
              <TabList style={{ paddingLeft: '0.5em', borderBottom: 'none' }}>
                <Tab>
                  {' '}
                  <span className="tabUser">User Profile</span>
                </Tab>
                <Tab>
                  <span className="tabBilling">Billing</span>
                </Tab>
                <Tab>
                  <span className="tabSponsorship">Sponsorship</span>
                </Tab>
              </TabList>

              <TabPanel>
                <Steps
                  enabled={this.state.stepsEnabled}
                  steps={this.state.steps}
                  initialStep={this.state.initialStep}
                  onExit={this.onExit}
                />
                <Row style={{ position: 'relative', margin: '0em 1em 1em 0em' }}>
                  <Col md={6} style={{ position: 'relative', padding: '5px' }}>
                    {this.state.viewProfile && (
                      <Card
                        style={{
                          height: '100%',
                          borderRadius: '20px',
                          boxShadow:
                            '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2)',
                        }}
                      >
                        <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                          <Row>
                            <Col md={10}>
                              <Label
                                style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.7em', marginTop: '0.5em' }}
                              >
                                User Profile Details
                              </Label>
                            </Col>
                            <Col md={2}>
                              <EditIconButton onClick={this.updateProfile.bind(this)} />
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          <Row style={{ textAlign: 'left' }}>
                            <Col md={3} style={{ margin: 'auto', textAlign: 'center' }}>
                              <div
                                className="user-panel"
                                style={{ color: '#fff', textAlign: 'center', marginTop: '1em', cursor: 'pointer' }}
                              >
                                <ConfigProvider colors={['#F39C12', '#A569BD', '#FA8072']}>
                                  <Avatar name={userName} round="70px" size="70px" />
                                </ConfigProvider>
                              </div>
                            </Col>

                            <Col md={8}>
                              <Row>
                                <Col md={2}>
                                  <label style={{ paddingRight: '0px' }}>Name:</label>
                                </Col>
                                <Col md={8} style={{ textAlign: 'left', textTransform: 'capitalize' }}>
                                  {' '}
                                  <span style={{ color: '#000' }}>{this.state.fname + ' ' + this.state.lname}</span>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={2}>
                                  <label style={{ paddingRight: '0px' }}>Email:</label>
                                </Col>
                                <Col md={8} style={{ textAlign: 'left' }}>
                                  {' '}
                                  <span style={{ color: '#000' }}>
                                    <a href={'mailto:' + this.state.email} target="_top">
                                      {this.state.email}
                                    </a>
                                  </span>
                                </Col>
                              </Row>
                              <Row>
                                <Col md={2}>
                                  <label style={{ paddingRight: '0px' }}>Phone:</label>
                                </Col>
                                <Col md={8} style={{ textAlign: 'left', textTransform: 'capitalize' }}>
                                  {' '}
                                  <span style={{ color: '#000' }}>
                                    <a href={'tel:' + this.state.phone} target="_top">
                                      {' '}
                                      {Utils.displayUsaPhoneNumberFormate(this.state.phone)}
                                    </a>
                                  </span>
                                </Col>
                              </Row>
                              <Row style={{ marginTop: '0.5em' }}>
                                <Col md={2}></Col>
                                <Col md={8} style={{ textAlign: 'left', textTransform: 'capitalize' }}>
                                  <Button
                                    variant="contained"
                                    size="small"
                                    // type="submit"
                                    style={{ background: '#138D75', color: '#fff' }}
                                    onClick={this.handleClickOpen.bind(this)}
                                  >
                                    Delete Account
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    )}
                    {this.state.editProfile && (
                      <Card style={{ height: '100%', borderRadius: '20px' }}>
                        <>
                          <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                            <Row>
                              <Col md={10}>
                                <Label style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em' }}>
                                  User Profile Details
                                </Label>
                              </Col>
                              <Col md={2}></Col>
                            </Row>
                          </CardHeader>
                          <CardBody>
                            <Row md={12} style={{ padding: '0.5em' }}>
                              <Col md={12}>
                                <Row>
                                  <Col md={6}>
                                    <TextField
                                      margin="normal"
                                      variant="outlined"
                                      required
                                      fullWidth
                                      id="fname"
                                      onChange={this.handleFnameChange.bind(this)}
                                      label="First Name"
                                      name="fname"
                                      inputProps={{ maxLength: 128, style: { textTransform: 'capitalize' } }}
                                      autoFocus
                                      value={this.state.fname}
                                    />
                                  </Col>
                                  <Col md={6}>
                                    <TextField
                                      margin="normal"
                                      variant="outlined"
                                      required
                                      fullWidth
                                      id="lname"
                                      inputProps={{ maxLength: 128, style: { textTransform: 'capitalize' } }}
                                      onChange={this.handleLnameChange.bind(this)}
                                      label="Last Name"
                                      name="lname"
                                      value={this.state.lname}
                                    />
                                  </Col>
                                </Row>
                                <Row>
                                  <Col md={6}>
                                    <TextField
                                      margin="normal"
                                      variant="outlined"
                                      required
                                      fullWidth
                                      id="email"
                                      inputProps={{ maxLength: 64 }}
                                      onChange={this.handleEmailChange.bind(this)}
                                      label="Email"
                                      name="email"
                                      value={this.state.email}
                                    />
                                  </Col>
                                  <Col md={6}>
                                    <TextField
                                      margin="normal"
                                      variant="outlined"
                                      required
                                      fullWidth
                                      id="phone"
                                      onChange={this.handleMobileChange.bind(this)}
                                      label="Phone"
                                      name="phone"
                                      value={this.state.phone}
                                    />
                                  </Col>
                                </Row>
                                <Row></Row>
                              </Col>
                            </Row>
                          </CardBody>
                          <CardFooter>
                            <div style={{ textAlign: 'center' }}>
                              <SaveButton
                                type="submit"
                                onClick={(e) => this.updateProfileInBackend(e, this.state.id)}
                              />
                              &nbsp;
                              <CancelIconButton
                                type="button"
                                onClick={(e) => this.editProfileCancel(e, this.state.id)}
                              />
                            </div>
                          </CardFooter>
                        </>
                      </Card>
                    )}
                  </Col>
                  <Col md={6} style={{ position: 'relative', padding: '5px' }}>
                    {this.state.viewAddress && (
                      <Card
                        style={{
                          height: '100%',
                          borderRadius: '20px',
                          boxShadow:
                            '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2)',
                        }}
                      >
                        <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                          <Row>
                            <Col md={10}>
                              <Label
                                style={{
                                  fontWeight: '600',
                                  color: '#0099f1',
                                  marginLeft: '0.7em',
                                  marginTop: '0.5em',
                                }}
                              >
                                Address
                              </Label>
                            </Col>
                            <Col md={2}>
                              <span className="editAddress" style={{ float: 'right' }}>
                                <EditIconButton onClick={this.updateProfileAddress.bind(this)} />
                              </span>
                            </Col>
                          </Row>
                        </CardHeader>
                        <CardBody className="profileAddress">
                          <Row>
                            <Col md={1}>
                              <Tooltip title="Address" aria-label="add">
                                <HomeIcon style={{ fontSize: '25px' }} />
                              </Tooltip>
                            </Col>
                            <Col md={11} style={{ padding: '0em 0em 0em 1em' }}>
                              <Row>{Utils.getAddressAsText(this.state.address)}</Row>
                            </Col>
                          </Row>
                          <Row style={{ marginTop: '1em' }}>
                            <Col md={1}>
                              <Tooltip title="Time Zone" aria-label="add">
                                <AccessAlarmTwoToneIcon style={{ fontSize: '25px', color: '#5DADE2' }} />
                              </Tooltip>
                            </Col>
                            <Col md={11} style={{ padding: '0em' }}>
                              {this.state.timezone.toString().trim() + ' (' + this.state.timeZoneAbbreviation + ')'}
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    )}
                    {this.state.editAddress && (
                      <Card
                        style={{
                          height: '100%',
                          borderRadius: '20px',
                          boxShadow:
                            '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2)',
                        }}
                      >
                        <>
                          <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                            <Row>
                              <Col md={10}>
                                <Label style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em' }}>
                                  Address
                                </Label>
                              </Col>
                              <Col md={2}></Col>
                            </Row>
                          </CardHeader>
                          <CardBody>
                            {this.state.isCreateAddress && (
                              <AddressWithTimezone
                                ref={this.addressRef}
                                data={this.state.address}
                                onChange={this.handleAddressChange.bind(this)}
                                action="create"
                                pageId="creataccount"
                              />
                            )}
                            {this.state.isEditAddress && (
                              <AddressWithTimezone
                                ref={this.addressRef}
                                data={this.state.address}
                                onChange={this.handleAddressChange.bind(this)}
                                action="create"
                              />
                            )}
                          </CardBody>
                          <CardFooter>
                            <div style={{ textAlign: 'center' }}>
                              <SaveButton
                                type="submit"
                                onClick={(e) => this.updateProfileAddressInBackend(e, this.state.id)}
                              />
                              &nbsp;
                              <CancelIconButton
                                type="button"
                                onClick={(e) => this.cancelEditProfileAddress(e, this.state.id)}
                              />
                            </div>
                          </CardFooter>
                        </>
                      </Card>
                    )}
                  </Col>
                  {/* )} */}
                </Row>
                {this.state.isFreeUser === true && (
                  <>
                    {' '}
                    <Row style={{ position: 'relative', margin: '0em 1em 1em 0em' }}>
                      <Col md={12} style={{ position: 'relative' }}></Col>
                    </Row>
                  </>
                )}{' '}
              </TabPanel>
              <TabPanel>
                {this.state.isFreeUser === true ? (
                  <Steps
                    enabled={this.state.stepsEnabled}
                    steps={this.state.freeUserBillingSteps}
                    initialStep={this.state.initialStep}
                    onExit={this.onExit}
                  />
                ) : (
                  <Steps
                    enabled={this.state.stepsEnabled}
                    steps={this.state.BillingSteps}
                    initialStep={this.state.initialStep}
                    onExit={this.onExit}
                  />
                )}
                <BillingDetails
                  orderListDetails={this.state.userBillingData}
                  freeSubscriptionDetails={this.state.freeSubscription}
                  history={this.props.history}
                />
              </TabPanel>

              <TabPanel className="sponsorship" style={{ padding: '5px 0px' }}>
                <Steps
                  enabled={this.state.stepsEnabled}
                  steps={this.state.sponsorShipSteps}
                  initialStep={this.state.initialStep}
                  onExit={this.onExit}
                />
                <Sponsorship userProfileData={this.state.userProfileData} sponsorUser={this.state.sponsorUser} />
              </TabPanel>
            </Tabs>
          </div>
          <Dialog
            open={this.state.subscriptionExpireModel}
            onClose={this.handleSubscriptionExpireModelClose.bind(this)}
            onHide={this.handleSubscriptionExpireModelClose.bind(this)}
            style={{ padding: '1em' }}
            className="subscriptionModal"
          >
            <DialogContent style={{ textAlign: 'center' }}>
              <Row>
                <Col md={12}>
                  <span className="closeFloatingButton" aria-label="add">
                    <CloseIcon
                      onClick={this.handleSubscriptionExpireModelClose.bind(this)}
                      style={{ float: 'right', fontSize: '15px', cursor: 'pointer' }}
                    />
                  </span>
                </Col>
              </Row>

              <p style={{ fontSize: '18px', padding: '1em 1em 0em 1em' }}>
                Your Subscription has expired. Please Renew it to continue Ad free experience.
              </p>
            </DialogContent>
            <DialogActions style={{ padding: '0em 0em 1em 0em', marginLeft: 'auto', marginRight: 'auto' }}>
              <Button
                variant="contained"
                size="medium"
                style={{ background: '#0f666b', color: '#fff', marginRight: '1rem' }}
                onClick={this.renewSubscriptionPlan.bind(this)}
              >
                Renew Plan
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.subscriptionExpiringModel}
            onClose={this.handleSubscriptionExpiringModelClose.bind(this)}
            onHide={this.handleSubscriptionExpiringModelClose.bind(this)}
            style={{ padding: '1em' }}
            className="subscriptionModal"
          >
            <DialogContent style={{ textAlign: 'center' }}>
              <Row>
                <Col md={12}>
                  <span className="closeFloatingButton" aria-label="add">
                    <CloseIcon
                      onClick={this.handleSubscriptionExpiringModelClose.bind(this)}
                      style={{ float: 'right', fontSize: '15px', cursor: 'pointer' }}
                    />
                  </span>
                </Col>
              </Row>

              <p style={{ fontSize: '18px', padding: '1em' }}>
                Your subscription will expire on {moment(this.state.subscriptionEndDate).format('DD MMM YYYY')}. Please
                renew to use ad-free services.
              </p>
            </DialogContent>
            <DialogActions style={{ padding: '0em 0em 1em 0em', marginLeft: 'auto', marginRight: 'auto' }}>
              <Button
                variant="contained"
                size="medium"
                style={{ background: '#0f666b', color: '#fff', marginRight: '1rem' }}
                onClick={this.renewSubscriptionPlan.bind(this)}
              >
                Renew Plan
              </Button>
            </DialogActions>
          </Dialog>

          <DialogBox
            open={this.state.deleteAccountModal}
            onClose={this.closeAccountModal}
            // onHide={this.closeAccountModal.bind(this)}
            title="Family Care Circle Document"
            ConfirmationClick={this.deleteAccountConfirmationWindow.bind(this)}
          >
            <span style={{ textAlign: 'center' }}>
              <h2 style={{ fontSize: '17px', lineHeight: '1.3em' }}>
                You are about to delete your account permanently. If you are ready to delete, enter your password and
                click CONFIRM. Once you confirm your account for deletion, you will not be able to retrieve any
                information you have added here. You will be deleted from other Family Care Circles.
              </h2>
            </span>
          </DialogBox>
          <DialogBox
            open={this.state.confirmDeleteAccountModal}
            onClose={this.closeConfirmDeleteAccountModal}
            // onHide={this.closeAccountModal.bind(this)}
            ConfirmationClick={this.deleteAccountInBackend.bind(this)}
          >
            <span style={{ textAlign: 'center' }}>
              <h2 style={{ fontSize: '20px' }}>
                Please enter your Password as this is the last step in removing all information about{' '}
                <b style={{ color: '#0f666b' }}>{this.loginUserName}</b>
              </h2>
            </span>
            <Row>
              <Col md={8} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                <TextField
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label="Enter Password"
                  type={this.state.showPassword ? 'text' : 'password'}
                  id="password"
                  autoComplete="off"
                  onChange={this.handlePasswordChange.bind(this)}
                  value={this.state.password}
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={this.handleClickShowPassword.bind(this)}
                        >
                          {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </Col>
            </Row>
          </DialogBox>
        </>
      );
    }
  }
}
export default Profile;
