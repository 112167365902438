import Tooltip from '@material-ui/core/Tooltip';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import store from '../../../../redux/store';
import NavBadge from './NavBadge';
class NavSingleItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getChildMenus = (e, id, pageid) => {
    // this.props.history.push(pageid);
    let toMenuList = store.getState().selectedMemberData.selectedMemberData.menuList;
    // let breadCrumbDetails = store.getState().breadCrumbDetails.breadCrumbDetails;
    //JSON.parse(localStorage.getItem('userMenuArr'));
    if (this.props.onSelect) {
      this.props.onSelect(id);
    }
    let childMenuArray = [];
    for (let i = 0; toMenuList && i < toMenuList.length; i++) {
      if (toMenuList[i].childMenu !== null) {
        if (toMenuList[i].id === id) {
          let obj = {
            menuid: toMenuList[i].menuid,
            icon: toMenuList[i].icon,
            pageid: toMenuList[i].pageid,
            cssclass: toMenuList[i].cssclass,
            step: toMenuList[i].step,
            id: toMenuList[i].id,
          };
          if (toMenuList[i] === id) {
            obj.isActive = true;
            if (this.props.onSelect) {
              this.props.onSelect(toMenuList[i]);
              obj.isActive = true;
            }
          } else {
            obj.isActive = true;
          }
          childMenuArray.push(obj);
          // localStorage.setItem('childMenuArr', JSON.stringify(childMenuArray));
        }
      }
    }
  };
  render() {
    let item = this.props.item;
    let isActive = this.props.isActive;
    let activeMenu = store.getState().activeMenu.activeMenu;
    if (item.id === activeMenu) {
      isActive = true;
    }
    if (item.external) {
      return (
        <>
          <li className="nav-item" id={item.helpClassName} onClick={(e) => this.getChildMenus(e, item.id, item.pageid)}>
            <Tooltip title={item.uiname}>
              <Link
                to={item.pageid}
                activeClassName="active"
                style={{ backgroundColor: '#1b7189' }}
                className={isActive === true ? 'menu-item active' : 'menu-item'}
              >
                <div style={{ display: 'flex', alignItems: 'center', width: '100%', fontSize: '14px' }}>
                  <div style={{ width: '20%' }}>
                    <i className={item.cssclass} style={{ color: '#fff' }}></i>
                  </div>
                  <div style={{ width: '60%' }}>
                    <span className="nav-item-label">{item.uiname}</span>
                  </div>
                  {item.isPremium && (
                    <div style={{ width: '20%' }}>
                      <i class="fa fa-dollar" style={{ fontSize: '20px', color: '#fff' }}></i>
                      {/* <img src={Crown} /> */}
                    </div>
                  )}
                </div>
              </Link>
            </Tooltip>
          </li>
        </>
      );
    } else {
      return (
        <>
          <li className="nav-item" id={item.helpClassName} onClick={(e) => this.getChildMenus(e, item.id, item.pageid)}>
            <Tooltip title={item.uiname}>
              <Link
                to={item.pageid}
                activeClassName="active"
                className={isActive === true ? 'menu-item active' : 'menu-item'}
              >
                <i
                  className={item.cssclass}
                  style={{
                    color: '#fff',
                    marginRight: '10px',
                    verticalAlign: 'middle',
                    position: 'relative',
                    top: '5px',
                    left: '0px',
                    width: '1.25em',
                    fontSize: '1.1em',
                  }}
                ></i>
                <span className="nav-item-label" style={{ marginTop: 'auto', marginBottom: 'auto' }}>
                  {' '}
                  {item.uiname}
                </span>{' '}
                &nbsp;
                {item.isPremium && (
                  <span className="nav-item-label" style={{ margin: 'auto' }}>
                    {' '}
                    <i
                      class="fa fa-dollar"
                      style={{
                        fontSize: '13px',
                        color: '#fff',
                        background: '#17A589',
                        height: '21px',
                        width: '10px',
                        marginRight: 'auto',
                        paddingLeft: '8px',
                        paddingTop: '4px',
                        borderRadius: ' 50%',
                        paddingRight: '15px',
                        border: '1px solid #fff',
                      }}
                    ></i>
                  </span>
                )}
                {item.badge && <NavBadge color={item.badge.variant} text={item.badge.text} />}
                {/* </a> */}
              </Link>
            </Tooltip>
          </li>
        </>
      );
    }
  }
}

export default NavSingleItem;
