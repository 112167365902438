import React, { Component } from 'react';
import { Col, Label, Row } from 'reactstrap';
class GetHelp extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <Row style={{ padding: '2em', border: '1px solid', margin: '1em' }}>
        <Col md={12}>
          <p>How do I get Started?</p>
          <p>
            Congratulations on registering <b style={{ color: '#229954' }}>Family Care Circle (FCC)</b>.{' '}
          </p>
          <p>
            You are now on Homepage of FCC. Here You see your Own Circle saying 'MY Circle' and Circles of other Members
            for whom you are caregiver.{' '}
          </p>
          <ul style={{ padding: '0em 2.5em' }}>
            <li>
              {' '}
              <p>
                {' '}
                You can start Care Circle for your loved one by Clicking <b>Add New Circle</b> Button. You will be part
                of their Care Circle, once they accept Your request.{' '}
              </p>
            </li>

            <li>
              <p>
                When You click on Card saying Your Name, that’s your wellness guide, stating information about your
                appointments, incidents, events, communications and many more things.{' '}
              </p>
              <p>
                Inside Card, you will see Calendar where all your appointments, incidents and events are organized in a
                monthly view. You can create new appointments/incidents/events by clicking on respective date. Please
                add doctor/facility and appointment details. Then you can add Transportation requests, Notes related to
                the appointment, reference appointment (if any).
              </p>
              <p>
                Please add caregivers by sending them requests. Caregivers will be the ones with whom you can
                communicate through app and request transportation for appointments or events.There are 4 different
                profiles for Caregivers depending on the authorities they have got. Authorized Caregiver, Family
                Manager, Family Member, Transporter are the Profiles.{' '}
              </p>
              <p>
                <b style={{ color: '#2B88C7', fontSize: '15px', fontWeight: '200' }}>Authorized Caregiver - </b>
                {/* </h6>
                    <p> */}{' '}
                This role has total responsibility for the Circle.They represent the Member “Center of the Circle” with
                the ability to expand the Circle to other caregivers, assign the roles to other caregivers and delete
                the circle.Typically this role is assigned to one person when the Member needs someone to represent
                them.
              </p>{' '}
              <p>
                <b style={{ color: '#2B88C7', fontSize: '15px', fontWeight: '200' }}>Family Manager - </b>
                This role also has the ability to expand the Circle and has all rights needed to create appointments and
                communicate with others. Typically this role is assigned to a close relative. This role is very similar
                to Authorized Caregiver with the exception of the ability to delete the Circle for the Member.
              </p>{' '}
              <p>
                <b style={{ color: '#2B88C7', fontSize: '15px', fontWeight: '200' }}>Family Member - </b>
                As an important caregiver, they can help manage all appointments. They are restricted from adding others
                to the Circle. Typically this role is assigned to extended relatives or close friends.
              </p>{' '}
              <p>
                <b style={{ color: '#2B88C7', fontSize: '15px', fontWeight: '200' }}>Transporter - </b>
                Can only view Appointments/Events for which they are responsible for transportation. Typically this role
                is assigned to friends that are wanting to help by offering to take the loved one to an appointment.
                This is the most restrictive role.
              </p>
            </li>

            <li>
              <p>
                Caregivers, Communications, Appointments, Doctors, Facilities, Events, Purchases are listed together in
                respective menus. You can add particular in respective menu.
              </p>
            </li>
          </ul>
        </Col>
      </Row>
    );
  }
}

export default GetHelp;
