import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Link from '@material-ui/core/Link';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CancelIcon from '@material-ui/icons/Cancel';
import PrintIcon from '@material-ui/icons/Print';
import React from 'react';
import { CardFooter, CardHeader, Col, Row } from 'reactstrap';
import store from '../../../redux/store';
import PrintIconButton from '../buttons/PrintIconButton';
import './TermsConditions.scss';
export class TermsConditions extends React.Component {
  header = store.getState().header.header;

  constructor(props) {
    super(props);

    this.state = {
      showSuccessAlert: false,
      showFailAlert: false,
      value: 0,

      errorMessageColor: '#000',
      checkbox: false,
    };
  }
  nextPage() {
    if (this.state.checkbox) {
      this.props.history.push('/createAccount');
    } else {
      document.getElementById('acceptTerms').focus();
    }
  }
  toUp() {
    window.scrollTo(0, 0);
  }
  handleClickCheckBox = (e) => {
    if (e.target.checked === 'true') {
      this.setState({
        checkbox: e.target.checked,
        isDisabled: false,
      });
    } else {
      this.setState({
        checkbox: e.target.checked,
        isDisabled: true,
      });
    }
  };

  goToPage(pageid) {
    if (pageid && pageid.length > 0) {
      this.props.history.push({
        pathname: pageid,
        state: { email: this.state.email },
      });
    }
  }
  printContent() {
    let printContents = document.getElementById('printform').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();

    document.body.innerHTML = originalContents;
    this.setState({ render: true });
    document.location.reload(true);
  }
  render() {
    return (
      <div
        id="termsConditionSection"
        style={{ height: '100vh', overflowY: 'scroll', padding: '0px 50px 30px 50px', background: '#fff' }}
      >
        <div style={{ border: '2px solid lightgray', marginTop: ' 10px', padding: '1em' }}>
          <section id="printform" className="contact">
            {/* <CardHeader>
              <Row md={12} style={{ margin: '1em 1em 1em 0em' }}>
                <Col style={{ textAlign: 'center' }}>
                  <span style={{ color: '#169ba2', fontSize: 'large', width: '100%', textAlign: 'center' }}>
                    <b>Family Care Circle - Registration</b>
                  </span>
                </Col>
              </Row>
            </CardHeader> */}
            <Row md={12} style={{ margin: '0.5em' }}>
              <Col style={{ textAlign: 'center' }}>
                <span
                  style={{
                    textAlign: 'center',
                    color: '#000',
                    fontSize: 'large',
                    float: 'center',
                  }}
                >
                  <strong>
                    <u>TERMS & CONDITIONS (V1.0)</u>
                  </strong>
                </span>
                <span
                  style={{
                    textAlign: 'center',
                    color: '#000',
                    fontSize: 'large',
                    float: 'right',
                  }}
                >
                  <PrintIconButton
                    variant="contained"
                    size="small"
                    style={{ float: 'right', background: '#1b7189', color: '#fff', marginRight: '1rem' }}
                    startIcon={<PrintIcon />}
                    onClick={(e) => this.printContent(e)}
                  />
                </span>
              </Col>
            </Row>
            <Row md={12} style={{ margin: '0.5em' }}>
              <span style={{ textAlign: 'center', color: '#000', width: '100%' }}>
                BY USING FAMILY CARE CIRCLE, LLC, YOU ARE CONSENTING TO OUR TERMS & CONDITIONS.
              </span>
            </Row>

            <Row md={12} style={{ margin: '0.5em' }}>
              <ol style={{ padding: '1em' }}>
                <a name="overview"></a>
                <li>
                  <span style={{ fontWeight: 'bold' }}>Overview</span>

                  <br />
                  <ul style={{ padding: '0.5em 0em 0em 2em', listStyle: 'disc', fontSize: '14px' }}>
                    <li>
                      Family Care Circle, LLC (“Company”) is committed to protecting your privacy online. This Privacy
                      Policy describes the personal information we collect through the Family Care Circle Application.
                    </li>
                    <br />
                    <li>
                      Users of the application are referred to as “Members”, “Family Members” and “Contacts” and Company
                      is referred to as “we”, “us”, and “our”. Family Care Circle refers to the users “Family Members”
                      and “Contacts” of the “Member”, in which the information is gathered for better care within the
                      circle of users. Accessing the Family Care Circle application, constitutes use of the application
                      and an acceptance to our Privacy Policy.
                    </li>
                    <br />
                    <li>
                      Within the application, we collect information about healthcare of Members for use by the circle
                      of users (“Family Members” and Contacts”) as approved by the Family Members designated
                      Administrator of the Family Care Circle. This information will be used to improve communication
                      between “Family Members” and “Contacts” in which are sharing care of the member and will only be
                      available to the Members of the Family Care Circle.
                    </li>
                    <br />
                    <li>
                      Use of the application, including all materials presented herein and all online Services provided
                      by Company, is subject to this Terms & Conditions.
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  <a name="information"></a>
                  <span style={{ fontWeight: 'bold' }}>Information We Collect</span>{' '}
                  <ul style={{ padding: '0.5em 0em 0em 2em', listStyle: 'disc', fontSize: '14px' }}>
                    <li>
                      This Site only collects the personal information you voluntarily provide to us, which includes
                      your name, email and phone number in order to communicate between “Family Members” and “Contacts”
                      with the “Members” circle of care givers.
                    </li>
                    <br />

                    <li>
                      This Site also collects the personal and medical appointment information you voluntarily provide
                      as the Administrator or Caregiver of the Member. This includes the members name, email, phone
                      number, medical appointments and notes about the member to help improve communication and care
                      sharing between “Family Members” and limited views for designated “Contacts”.
                    </li>
                    <br />

                    <li>
                      We do share your information with trusted third parties who provide support in running this
                      application including support of the product. All parties will keep your information confidential
                      and will never be shared with unrelated third parties.
                    </li>
                    <br />
                    <li>
                      We may record information relating to your use of the Application in order to help administer the
                      Site and personalize your experience by improving customer service.
                    </li>
                    <br />
                    <li>
                      Additionally, we may send cookies to your phone or computer in order to identify your use of the
                      application and to improve our service. At any point, you may decline the cookies in your Internet
                      settings. However, doing so may prevent use of some of the features of the application.
                    </li>
                    <br />
                    <li>
                      This application may contain links to third party services. Unless otherwise stated, this Terms &
                      Conditions only covers information that we collect from you within this application. Any other
                      link will be covered by the Terms & Conditions of that specific site. You acknowledge and accept
                      that we are not responsible for the terms & contdions or practices of third parties.
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  <a name="security"></a>
                  <span style={{ fontWeight: 'bold' }}> Security</span> <br />
                  <ul style={{ padding: '0.5em 0em 0em 2em', listStyle: 'disc', fontSize: '14px' }}>
                    <li>
                      We do our best to protect your information for any unauthorized access, misuse, or disclosure.
                      However, you acknowledge that the personal information you voluntarily share could be accessed or
                      tampered with by a third party. You agree that we are not responsible for any intercepted
                      information shared through our Application without our knowledge or permission. Additionally, you
                      release us from any and all claims arising out of or related to the use of such intercepted
                      information in any unauthorized manner. You agree to notify us for any breach of security or
                      unauthorized use of your information.
                    </li>
                    <br />
                    <li>
                      To access or use the Application, you must be 18 years old or older and have the requisite power
                      and authority to enter into this Terms & Conditions. We do not knowingly collect or solicit data
                      online from or market online to children under the age of 18.
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  <a name="change"></a>
                  <span style={{ fontWeight: 'bold' }}>Changes to This Policy</span> <br />
                  <ul style={{ padding: '0.5em 0em 0em 2em', listStyle: 'disc', fontSize: '14px' }}>
                    <li>
                      You acknowledge and agree that you have reviewed this Terms & Conditions and that you will
                      continue to review to be aware of any modifications. Any changes to this Policy will be updated on
                      this page.
                    </li>
                  </ul>
                </li>
                <br />
                <li>
                  <a name="ack"></a>
                  <span style={{ fontWeight: 'bold' }}>Acknowledgment</span> <br />
                  <ul style={{ padding: '0.5em 0em 0em 2em', listStyle: 'disc', fontSize: '14px' }}>
                    <li>
                      These Terms & Conditions apply to all site visitors, customers and all other users of the
                      application. By using the application, you agree to this Terms & Conditions, without modification,
                      and acknowledge reading it.
                    </li>
                  </ul>
                </li>

                <li style={{ padding: '1em 0em 0em 0em', listStyle: 'none' }}>
                  {' '}
                  <b>Dated: November 2021</b>
                </li>
              </ol>
            </Row>
          </section>
        </div>
      </div>
    );
  }
}

export default TermsConditions;
