import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ValidatorForm } from 'react-material-ui-form-validator';
import 'react-tabs/style/react-tabs.css';
import { Card, CardBody, CardFooter, CardHeader, Col, Label, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import store from '../../../redux/store';
import CancelIconButton from '../buttons/CancelIconButton';
import CreateButton from '../buttons/CreateButton';
import Location from '../location/Location';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
import PtsAlert from '../ptsAlert/PtsAlert';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { MuiPickersUtilsProvider, DateTimePicker, LocalizationProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';
import Address from '../address/Address';
class Incident extends Component {
  incidentTypeList = store.getState().staticData.incidentTypeList;
  header = store.getState().header.header;
  memberDetails = store.getState().memberData.memberData;
  memberName = store.getState().memberData.memberData.firstname + ' ' + store.getState().memberData.memberData.lastname;
  title = 'Incident Details';
  breadCrumb = [
    { pageid: '/memberDetails', uiname: this.memberName },
    { pageid: '/incidentAppointment', uiname: 'Incident List' },
    { pageid: '', uiname: 'Incident Details' },
  ];
  addressRef = React.createRef();
  addressRef = {};
  locationReferenceList = [];
  incidentReminderRefList = [];
  incidentNoteRefList = [];
  incidentTypeOptionList = [];

  constructor(props) {
    super(props);
    let incidentId = '';
    let action = '';
    let title = '';
    let pageId = '';
    let incidentDetails = {};
    let canRender = false;

    incidentId = props.incidentId;
    action = props.action;

    if (!incidentId && props.history && props.history.location && props.history.location.state) {
      incidentId = props.history.location.state.incidentId;
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
      title = props.history.location.state.title;
      incidentDetails = props.history.location.state.incidentDetails;
    }
    canRender = false;
    if (null !== incidentId && incidentId > 0) {
      this.getIncidentDetails(incidentId);
    } else {
      canRender = true;
    }
    let locationList = [];
    locationList.push(incidentDetails.location);
    let newStartDate = Utils.convertUTCDateToUserTimeZoneDate(incidentDetails.date);
    this.state = {
      id: incidentId,
      incidentType: incidentDetails.type,
      action: action,
      render: canRender,
      showSuccessAlert: false,
      showFailAlert: false,
      startDate: new Date(newStartDate),
      description: '',
      incidentTitle: incidentDetails.title,
      note: incidentDetails.description,
      locationDetails: incidentDetails.location,
      locationList: locationList,
      viewLocationitems: [],
      title: title,
      pageId: pageId,
      locationItemsView: true,
    };
    this.locationReferenceList.push(React.createRef());
  }
  handleIncidentStartDateChange(date) {
    this.setState({ startDate: date });
  }

  handleIncidentTypeChange(e) {
    this.setState({ incidentType: e.target.value });
  }

  handleDescriptionChange(e) {
    this.setState({ note: e.target.value });
  }
  handleIncidentTitleChange(e) {
    this.setState({ incidentTitle: e.target.value });
  }

  cancelCreate() {
    this.props.history.push({
      pathname: '/incidentAppointment',
    });
  }
  addNewLocation(locationData) {
    for (let i = 0; i < this.state.locationList.length; i++) {
      if (
        this.state.locationList[i] &&
        locationData.index === i &&
        this.locationReferenceList[i] &&
        this.locationReferenceList[i].current &&
        this.locationReferenceList[i].current.validateLocation()
      ) {
        this.state.locationList[i] = locationData;
        this.locationReferenceList[i].current.state = locationData;
        this.locationReferenceList[i].current.state.action = 'editLocation';
        this.setState({ render: true });
      }
    }
  }

  updateLocation(locationData) {
    for (let i = 0; i < this.state.locationList.length; i++) {
      if (
        this.state.locationList[i] &&
        locationData.index === i &&
        this.locationReferenceList[i] &&
        this.locationReferenceList[i].current &&
        this.locationReferenceList[i].current.validateLocation()
      ) {
        this.state.locationList[i] = locationData;
        this.locationReferenceList[i].current.state = locationData;
        this.locationReferenceList[i].current.state.action = 'editLocation';
        this.setState({ render: true });
      }
    }
  }

  addIncidentInBackend(e) {
    let locations = [];
    for (let i = 0; i < this.locationReferenceList.length; i++) {
      // if (null !== this.locationReferenceList[i].current) {
      let location = {};
      // location.shortDescription = this.locationReferenceList[i].current.state.shortDescription;
      // location.nickName = this.locationReferenceList[i].current.state.nickName;
      // location.fax = this.locationReferenceList[i].current.state.fax;
      // location.website = this.locationReferenceList[i].current.state.website;
      // location.phone = this.locationReferenceList[i].current.state.phone;
      location.address = {};
      location.address.id = this.addressRef.current.state.id;
      location.address.line = this.addressRef.current.state.line;
      location.address.city = this.addressRef.current.state.city;
      location.address.zipCode = this.addressRef.current.state.zipCode;
      location.address.state = this.addressRef.current.state.state;
      locations[i] = location;
      // }
    }

    let dateTime = Utils.convertUserTimeZoneDateToUTCDate(this.state.startDate);

    const postObject = {
      header: this.header,
      incident: {
        date: dateTime,
        description: this.state.note,
        type: this.state.incidentType,
        title: this.state.incidentTitle,
        member: {
          id: this.memberDetails.member,
        },
        location: locations[0],
        contactList: [],

        reminderList: this.state.reminderItems,
      },
    };
    FetchServerData.callPostService('/incidentms/create', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let incidentData = output.data;
          PtsAlert.success(output.message);
          this.props.history.push({
            pathname: '/viewIncident',
            state: {
              action: 'view',
              title: 'Facility Details',
              incidentdata: incidentData,
              pageId: 'createIncident',
              incidentId: incidentData.incident.id,
            },
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  render() {
    if (this.state.render === false) {
      return '';
    }
    if (this.state.action === 'createSimilarIncident') {
      return this.createIncident();
    }
  }
  handleAddressChange(address) {
    this.setState({ address: address });
  }
  createIncident() {
    let locationItems = [];

    this.state.locationList.forEach((element, index) => {
      if ('delete' !== element.operation) {
        locationItems.push(
          <Col md={12} style={{ padding: '0.5em' }}>
            <Location
              ref={this.locationReferenceList[index]}
              key={index}
              data={element}
              srNo={index}
              action="defaultCreate"
              pageId="incident"
              isButtonHide="true"
              onLocationAdd={this.addNewLocation.bind(this)}
              onUpdate={this.updateLocation.bind(this)}
            />
          </Col>
        );
      }
    });

    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={'Create Incident For' + ' ' + this.memberName}
          domainObject="Incident"
        />
        <ValidatorForm style={{ width: '100%' }} ref="form" onSubmit={this.addIncidentInBackend.bind(this)}>
          <Row style={{ position: 'relative', margin: '1em' }}>
            {' '}
            <Col md={6} style={{ position: 'relative', padding: '5px' }}>
              <Card className="doctorViewCard" style={{ padding: '1em', height: '100%' }}>
                <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                  <Label style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}>{this.state.title}</Label>
                </CardHeader>
                <CardBody>
                  <Row style={{ marginTop: '1em' }}>
                    <Col md={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ThemeProvider theme={materialTheme}>
                          <DateTimePicker
                            value={this.state.startDate}
                            variant="outlined"
                            fullWidth
                            label="Date"
                            id="ptsDateTimePicker"
                            inputVariant="outlined"
                            format="MM/dd/yyyy  h:mm aa"
                            onChange={this.handleIncidentStartDateChange.bind(this)}
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>{' '}
                    </Col>
                    <Col md={6}>
                      <input
                        style={{
                          height: '56px',
                          width: '100%',
                          padding: '1em',
                          borderRadius: '3px',
                          border: '1px solid #c4c4c4',
                        }}
                        type="text"
                        required
                        id="Incident-outlined"
                        list="incidentType"
                        placeholder="Incident Type*"
                        label="Incident Type*"
                        value={this.state.incidentType}
                        onChange={(event, value) => {
                          this.handleIncidentTypeChange(event, value);
                        }}
                      />
                      <datalist id="incidentType">
                        {this.incidentTypeList.map((event, key) => (
                          <option key={event.type} value={event.type} />
                        ))}
                      </datalist>
                    </Col>
                  </Row>
                  <Row style={{ margin: '1.7em -1em' }}>
                    <Col md={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="description"
                        label="Title"
                        value={this.state.incidentTitle}
                        onChange={this.handleIncidentTitleChange.bind(this)}
                        name="name"
                        type="text"
                        className="textField"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <TextareaAutosize
                        aria-label="minimum height"
                        style={{ width: '100%', padding: '10px' }}
                        minRows={5}
                        id="description"
                        value={this.state.note}
                        inputprops={{ maxLength: 256, padding: '10px' }}
                        name="note"
                        onChange={this.handleDescriptionChange.bind(this)}
                        placeholder="Enter Note Details"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} style={{ padding: '5px' }}>
              <Card className="doctorViewCard" style={{ padding: '1em', height: '100%' }}>
                <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                  <Label style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}>Location</Label>
                </CardHeader>
                <CardBody>
                  {/* {locationItems} */}
                  <div className="location_details_view">
                    <div className="panel panel-blue">
                      <div className="panel-heading">
                        <Address
                          ref={this.addressRef}
                          onChange={this.handleAddressChange.bind(this)}
                          data={this.state.address}
                          action="create"
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <CardFooter>
            <div style={{ textAlign: 'center', padding: '0.5em' }}>
              <CreateButton>Create</CreateButton>
              &nbsp;
              <CancelIconButton onClick={(e) => this.cancelCreate()} />
            </div>
          </CardFooter>
        </ValidatorForm>
      </>
    );
  }
}

export default Incident;
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#008181',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: 'white',
        color: '#008181',
      },
    },
  },
});
