import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Badge from '@material-ui/core/Badge';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import 'date-fns';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { Card, CardHeader, Col, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import store from '../../../redux/store';
import AdminMenuWithBreadScrum from '../adminMenu/AdminMenuWithBreadScrum';
import './LoginCount.scss';
class UserLoginCount extends Component {
  header = store.getState().adminHeader.adminHeader;
  title = 'User Statistics';
  breadCrumb = [{ pageid: '', uiname: 'User Statistics' }];
  constructor(props) {
    super(props);
    let date = new Date();
    let startDate = moment(date).format('YYYY-MM-DD');
    let endDate = moment(date).format('YYYY-MM-DD');
    let currentDay = moment(date).format('dddd');
    let currentDayDate = moment(date).format('DD MMM YYYY');

    let weekStartDate = moment(startDate).startOf('week');
    let weekEndDate = moment(endDate).endOf('week');
    let weekDataMap = Utils.getWeekDatesList(endDate, endDate);
    this.MemberCalenderByWeek(weekStartDate, weekEndDate);
    this.state = {
      startDate: startDate,
      endDate: endDate,
      selectedStartDate: startDate,
      selectedEndDate: endDate,
      currentDayDate: currentDayDate,
      currentDay: currentDay,
      dateMap: {},
      render: false,
      filterOptionTypeList: [],
      filterOptionType: {},
      weekDateList: [],
      weekDataMap: weekDataMap,
      consolidatedView: 'weekView',
      weekStartDate: weekStartDate,
      weekEndDate: weekEndDate,
      headerWeekDateList: [],
      startOfWeek: weekStartDate,
      endOfWeek: weekEndDate,
    };
  }

  MemberCalenderByWeek = (weekStartDate, weekEndDate) => {
    let startDate = moment(weekStartDate).format('YYYY-MM-DD');
    let endDate = moment(weekEndDate).format('YYYY-MM-DD');
    const postObject = {
      header: this.header,
      fromDate: startDate,
      toDate: endDate,
    };
    FetchServerData.callPostService('/adminpanelUserAuditLogms/getLogs', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let memberActivityDetails = output.data.userAuditLogList;
          let currentDateMap = {
            0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
            8: [],
            9: [],
            10: [],
            11: [],
            12: [],
            13: [],
            14: [],
            15: [],
            16: [],
            17: [],
            18: [],
            19: [],
            20: [],
            21: [],
            22: [],
            23: [],
          };
          let weekDateList = [];
          let headerWeekDateList = [];
          headerWeekDateList = Utils.getHeaderWeekDatesList(endDate, endDate);
          weekDateList = Utils.getWeekDatesList(endDate, endDate);
          for (let i = 0; i < memberActivityDetails.length; i++) {
            memberActivityDetails[i].createdon = Utils.convertUTCDateToUserLoginTimeZoneViewDateFormate(
              memberActivityDetails[i].createdon
            );
            memberActivityDetails[i].time = Utils.convertUTCDateToUserLoginTimeZoneDate(
              memberActivityDetails[i].createdon
            );
          }

          for (let k in memberActivityDetails) {
            let hour = Utils.getHours(memberActivityDetails[k].createdon);
            let date = Utils.getWeekDate(memberActivityDetails[k].createdon);
            if (weekDateList[date]) {
              weekDateList[date].push(memberActivityDetails[k]);
            }
          }

          this.setState({
            consolidatedView: 'weekView',
            weekStartDate: startDate,
            weekEndDate: endDate,
            headerWeekDateList: headerWeekDateList,
            render: true,
            weekDataMap: weekDateList,
            startOfWeek: startDate,
            endOfWeek: endDate,
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  getNextWeekDate = () => {
    let endDate = moment(this.state.weekEndDate).add(7, 'd').format('YYYY-MM-DD');
    let startOfWeek = moment(endDate).startOf('week').format('YYYY-MM-DD');
    let endOfWeek = moment(endDate).endOf('week').format('YYYY-MM-DD');

    this.setState({
      weekStartDate: startOfWeek,
      weekEndDate: endOfWeek,

      startOfWeek: startOfWeek,
      endOfWeek: endOfWeek,
    });
    this.MemberCalenderByWeek(startOfWeek, endOfWeek);
  };

  getPreviousWeekDate = () => {
    let endDate = moment(this.state.weekStartDate).subtract(7, 'd').format('YYYY-MM-DD');
    let startOfWeek = moment(endDate).startOf('week').format('YYYY-MM-DD');
    let endOfWeek = moment(endDate).endOf('week').format('YYYY-MM-DD');

    this.MemberCalenderByWeek(startOfWeek, endOfWeek);
    this.setState({
      weekStartDate: startOfWeek,
      weekEndDate: endOfWeek,
      startOfWeek: startOfWeek,
      endOfWeek: endOfWeek,
    });
  };

  toWeekView() {
    this.setState({
      consolidatedView: 'weekView',
    });
    let date = new Date();
    let startDate = moment(this.state.startDate).format('YYYY-MM-DD');
    let endDate = moment(this.state.startDate).format('YYYY-MM-DD');
    let weekStartDate = moment(startDate).startOf('week');
    let weekEndDate = moment(startDate).endOf('week');

    this.MemberCalenderByWeek(weekStartDate, weekEndDate);
  }

  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      const { weekEndDate, endOfWeek, startOfWeek } = this.state;
      return (
        <>
          <AdminMenuWithBreadScrum breadCrumb={this.breadCrumb} history={this.props.history} name={this.title} />
          <Row style={{ margin: '1em' }}>
            <Col md={12}>
              <Card style={{ padding: '1em', height: '660px' }}>
                <CardHeader style={{ padding: '0.5em' }}>
                  <Row>
                    <Col md={4}>
                      <Row>
                        <Col md={6}>
                          <Button
                            onClick={(e) => this.toWeekView()}
                            style={{
                              background: this.state.consolidatedView === 'weekView' ? '#005476' : '#707B7C',
                              color: 'white',
                              fontSize: 'x-large',
                              textTransform: 'capitalize',
                              cursor: 'pointer',
                              fontSize: 'x-larger',
                              float: 'left',
                              margin: '0.2em 0.5em 0.3em 0em',
                            }}
                            className="selectDateBtn"
                          >
                            Week
                          </Button>
                        </Col>
                        <Col md={6}>
                          <ArrowBackIosIcon id="previousBtn" onClick={this.getPreviousWeekDate.bind(this)} />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={4} className="currentDayCol" style={{ margin: 'auto' }}>
                      {' '}
                      <h3>
                        {' '}
                        <b>
                          {moment(startOfWeek).format('ddd, DD MMM') +
                            ' - ' +
                            moment(endOfWeek).format('ddd, DD MMM') +
                            ', ' +
                            moment(weekEndDate).format('YYYY')}
                        </b>
                      </h3>
                    </Col>
                    <Col md={4}>
                      <ArrowForwardIosIcon id="nextWeekBtn" onClick={this.getNextWeekDate.bind(this)} />
                      &nbsp;
                    </Col>
                  </Row>
                </CardHeader>

                <TableContainer component={Paper} ref={(el) => (this.componentRef = el)}>
                  <Table stickyHeader aria-label="sticky table" className="weekViewTable">
                    <TableHead style={{ fontFamily: 'bold', background: '#B2BABB' }}>
                      <TableRow>
                        {Object.entries(this.state.weekDataMap).map(([k, v]) => (
                          <>
                            <TableCell
                              align="center"
                              style={{
                                padding: '0px',
                                width: '10%',
                                background: '#1abc9c',
                                borderRight: '1px solid black',
                              }}
                            >
                              <b style={{ color: '#fff' }}>{k}</b>
                            </TableCell>
                          </>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody id="weekBody">
                      <TableRow>
                        {Object.entries(this.state.weekDataMap).map(([k, v]) => (
                          <>
                            <TableCell style={{ borderRight: 'none !important' }}>
                              {v.map((user) => {
                                return (
                                  <div
                                    class="card"
                                    style={
                                      user?.operation === 'Login'
                                        ? { backgroundColor: '#b3e0ff', borderRadius: '10px' }
                                        : { backgroundColor: '#ffb3b3', borderRadius: '10px' }
                                    }
                                  >
                                    <div class="card-body" style={{ padding: '8px' }}>
                                      <div className="row">
                                        <div className="col-sm-9 align-items-start" style={{ fontSize: '12px' }}>
                                          <b>{user.name}</b>
                                        </div>
                                        <div className="col-sm-3 me-4" style={{ fontSize: '12px', textAlign: 'right' }}>
                                          <Badge
                                            badgeContent={user.count}
                                            color={user?.operation === 'Login' ? 'primary' : 'secondary'}
                                            overlap="rectangular"
                                            className="userLoginCount"
                                          ></Badge>
                                        </div>
                                      </div>
                                      <div className="row d-flex">
                                        <div className="col-sm-6 align-items-start" style={{ fontSize: '12px' }}>
                                          {user.operation}
                                        </div>
                                        <div className="col-sm-6" style={{ fontSize: '12px', textAlign: 'right' }}>
                                          {user.time}
                                        </div>
                                      </div>
                                      <div className="row d-flex">
                                        <div className="col-sm-12" style={{ fontSize: '12px', textAlign: 'right' }}>
                                          {`${
                                            user.source === 'W'
                                              ? user.browser !== null
                                                ? user.browser
                                                : ''
                                              : user.mobileos !== null
                                              ? user.mobileos
                                              : ''
                                          } ${user.source !== null ? '(' + user.source + ')' : ''}`}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })}
                            </TableCell>
                          </>
                        ))}
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Col>
          </Row>
        </>
      );
    }
  }
}

export default UserLoginCount;
