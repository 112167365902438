import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import CreateIcon from '@material-ui/icons/Create';
import DateRangeIcon from '@material-ui/icons/DateRange';
import BootstrapTable from 'react-bootstrap-table-next';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import { format } from 'date-fns';
import es from 'date-fns/locale/es';
import moment from 'moment';
import React, { Component } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Card, CardBody, CardHeader, Col, Label, Row, CardFooter } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import store from '../../../redux/store';
import AddIconButton from '../buttons/AddIconButton';
import CancelIconButton from '../buttons/CancelIconButton';
import Reminder from '../reminder/Reminder';
import DescriptionIcon from '@material-ui/icons/Description';
import DeleteButton from '../buttons/DeleteButton';
import EditButton from '../buttons/EditButton';
import PtsAlert from '../ptsAlert/PtsAlert';
import { MuiPickersUtilsProvider, DateTimePicker, LocalizationProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { createMuiTheme } from '@material-ui/core';
registerLocale('es', es);
export default class EventTransportation extends Component {
  countryListArray = store.getState().staticData.countryList;
  accompanyTypeList = store.getState().staticData.accompanyTypeList;
  memberDetails = store.getState().memberData.memberData;
  transportationReminderRefList = [];
  stateListArray = [];
  formRef = React.createRef();
  header = store.getState().header.header;
  constructor(props) {
    super(props);
    // let title = props.title;
    let action = props.action;
    let eventTransportation = props.data;
    let pageId = props.pageId;
    let isButtonHide = false;
    let isButtonShow = true;

    if (
      pageId === 'editMemberAppointments' ||
      pageId === 'addAppointment' ||
      pageId === 'viewEditMemberAppointments' ||
      pageId === 'editEvent'
    ) {
      isButtonHide = true;
    }
    if (props.pageId === 'viewEvent' || props.pageId === 'editEvent') {
      isButtonShow = false;
    }
    let eventTransportationAddress = props.addressData;
    let isViewButtonHide = true;
    if (props.isPrintView === 'view_print') {
      isViewButtonHide = false;
    }
    if (eventTransportationAddress != null) {
      let startDate = '';
      let newStartDate = '';
      // let viewStartDate = '';
      if (props.transportationDate !== undefined) {
        newStartDate = props.transportationDate;
        // let now = new Date(startDate);
        // let date = now.toLocaleDateString() + ' ' + now.toLocaleTimeString();
        // newStartDate = moment(date).format('MM/DD/YYYY hh:mm a');
        // viewStartDate = moment(date).format('DD MMM YYYY hh:mm a');
      }
      this.state = {
        createdByName: '',
        createdDate: '',
        transportationType: this.accompanyTypeList[0].type,
        contactMemberName: '',
        transportationDate: new Date(newStartDate),
        transportationTime: '',
        transportationDesc: '',
        viewContactMemberName: '',
        contactMemberList: [],
        id: eventTransportationAddress.id,
        line: eventTransportationAddress.line,
        state: eventTransportationAddress.state.code,
        stateId: eventTransportationAddress.state.id,
        country: eventTransportationAddress.state.country.code,
        countryId: eventTransportationAddress.state.country.id,
        city: eventTransportationAddress.city,
        zipCode: eventTransportationAddress.zipCode,
        time: '',
        stateClear: false,
        action: this.props.action,
        onChange: this.props.onChange,
        title: props.title,
        render: true,
        startDate: '',
        transportationModal: false,
        transportationReminder: [],
        onTransportationAdd: props.onTransportationAdd,
        isButtonHide: isButtonHide,
        isButtonShow: isButtonShow,
        onTransportationEdit: props.onTransportationEdit,
        onUpdate: props.onUpdate,
        onDelete: props.onDelete,
        tansType: '',
        status: '',
        expanded: true,
        rejectTransportationWindow: false,
        transportationEventList: props.transportationEventList,
        eventTransportationView: true,
        isViewButtonHide: isViewButtonHide,
        getCreatePermission: props.getCreatePermission,
        getDeletePermission: props.getDeletePermission,
        getUpdatePermission: props.getUpdatePermission,
        eventId: props.eventId,
        onSuccess: props.onSuccess,
      };
      this.getStateList(this.state.countryId);
    } else if (eventTransportation != null) {
      this.state = {
        id: eventTransportation.id,
        // transportationType: eventTransportation.accompanyType.type,
        contactMemberName: eventTransportation.accompany,
        viewContactMemberName: eventTransportation.accompanyName,
        accompanyName: eventTransportation.accompanyName,
        transportationDate: eventTransportation.date,
        transportationTime: moment(eventTransportation.time).format('HH:MM'),
        transportationDesc: eventTransportation.description,
        line: eventTransportation.address.line,
        state: eventTransportation.address.state.code,
        stateId: eventTransportation.address.state.id,
        country: eventTransportation.address.state.country.code,
        countryId: eventTransportation.address.state.country.id,
        stateName: eventTransportation.address.state.name,
        countryName: eventTransportation.address.state.country.name,
        city: eventTransportation.address.city,
        zipCode: eventTransportation.address.zipCode,
        createdByName: eventTransportation.createdByName,
        createdDate: eventTransportation.createdOn,
        time: eventTransportation.time,
        contactMemberList: [],
        transportationReminder: [],
        onChange: props.onChange,
        pageId: props.pageId,
        title: props.title,
        action: action,
        render: true,
        startDate: '',
        index: props.srNo,
        transportationModal: false,
        onTransportationAdd: props.onTransportationAdd,
        isButtonHide: isButtonHide,
        isButtonShow: isButtonShow,
        onTransportationEdit: props.onTransportationEdit,
        onUpdate: props.onUpdate,
        onDelete: props.onDelete,
        transType: props.transportationType,
        status: eventTransportation.status.status,
        expanded: true,
        rejectTransportationWindow: false,
        transportationEventList: props.transportationEventList,
        eventTransportationView: true,
        isViewButtonHide: isViewButtonHide,
        getCreatePermission: props.getCreatePermission,
        getDeletePermission: props.getDeletePermission,
        getUpdatePermission: props.getUpdatePermission,
        eventId: props.eventId,
        onSuccess: props.onSuccess,
      };

      this.getStateList(eventTransportation.address.state.country.id);
      this.viewTransportation(this.state.id);
    } else {
      this.state = {
        id: eventTransportation ? eventTransportation.id : '',
        transportationType: this.accompanyTypeList[0].type,
        contactMemberName: '',
        accompanyName: '',
        viewContactMemberName: '',
        transportationDate: format(new Date(), 'yyyy-MM-dd'),
        transportationTime: format(new Date(), 'hh-mm'),
        transportationDesc: '',
        line: '',
        country: '',
        stateId: '',
        countryId: '',
        state: null,
        city: '',
        zipCode: '',
        createdByName: '',
        createdDate: '',
        time: '',
        contactMemberList: [],
        transportationReminder: [],
        onChange: props.onChange,
        pageId: props.pageId,
        action: action,
        title: props.title,
        render: true,
        startDate: '',
        transportationModal: false,
        onTransportationAdd: props.onTransportationAdd,
        isButtonHide: isButtonHide,
        isButtonShow: isButtonShow,
        onTransportationEdit: props.onTransportationEdit,
        onUpdate: props.onUpdate,
        onDelete: props.onDelete,
        transType: '',
        status: '',
        expanded: true,
        rejectTransportationWindow: false,
        transportationEventList: props.transportationEventList,
        eventTransportationView: true,
        isViewButtonHide: isViewButtonHide,
        getCreatePermission: props.getCreatePermission,
        getDeletePermission: props.getDeletePermission,
        getUpdatePermission: props.getUpdatePermission,
        eventId: props.eventId,
        onSuccess: props.onSuccess,
      };
    }
    this.getContactMemberList();
  }

  handleStateChange(e) {
    this.state.state = e.target.value;
    this.setState({ state: e.target.value });
    if (undefined !== this.onChange && null !== this.onChange) {
      this.onChange(this.state);
    }
  }
  handleTypeChange(e) {
    this.setState({ transportationType: e.target.value });
  }

  handleContactMemberChange(e) {
    this.setState({ contactMemberName: e.target.value });
  }

  handleTransportationStartDateChange(date) {
    this.setState({ transportationDate: date });
  }

  handleTransportationTimeChange(e) {
    this.setState({ transportationTime: e.target.value });
  }
  handleTransportationDesChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 256);
    if (res.length < 256) {
      this.setState({ transportationDesc: res });
    } else {
      Utils.maxFieldLength(256);
    }
    // this.setState({ transportationDesc: e.target.value });
  }

  handleLine1Change(e) {
    let val = e.target.value;
    let res = val.substring(0, 128);
    if (res.length < 128) {
      this.setState({ line: res });
    } else {
      Utils.maxFieldLength(128);
    }
    // this.setState({ line: e.target.value });
  }

  handleLine2Change(e) {
    this.setState({ line2: e.target.value });
  }
  handleCityChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 128);
    if (res.length < 128) {
      this.setState({ city: res });
    } else {
      Utils.maxFieldLength(128);
    }
    // this.setState({ city: e.target.value });
  }
  handleZipCodeChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 16);
    if (res.length < 16) {
      this.setState({ zipCode: res });
    } else {
      Utils.maxFieldLength(16);
    }
    // this.setState({ zipCode: e.target.value });
  }

  getStateList(id) {
    const header = store.getState().header.header;

    const postObject = header;
    let url = 'statems/findByCountry/';
    FetchServerData.callPostService(url + id, postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let stateList = output.data;
        this.stateListArray = stateList;
        this.setState({
          render: true,
        });
      } else {
      }
    });
  }

  handleCountryChange(e) {
    let countryCode = e.target.value;
    let selectedCountry = {};
    for (let i = 0; i < this.countryListArray.length; i++) {
      if (this.countryListArray[i].code === countryCode) {
        selectedCountry = this.countryListArray[i];
      }
    }

    if (null === e.target.value || e.target.value === '') {
      this.stateListArray = [];
      this.setState({
        stateClear: !this.state.stateClear,
      });
      return;
    }
    this.state.country = e.target.value;
    if (undefined !== this.onChange && null !== this.onChange) {
      this.onChange(this.state);
    }
    this.setState({
      country: e.target.value,
    });
    let countryId = selectedCountry.id;
    this.getStateList(countryId);
  }

  TransportationToggle = () => {
    this.setState({ transportationModal: !this.state.transportationModal });
  };

  addTransportation(e) {
    if (this.state.onTransportationAdd) {
      this.state.onTransportationAdd(this.state);
    }
  }
  // updateTransportation(e) {
  //   if (this.state.onUpdate) {
  //     this.state.onUpdate(this.state);
  //   }
  // }
  updateTransportation(e) {
    let postObject = {};

    let appointmentTransportationDate = this.state.transportationDate;

    let dateTime = Utils.convertUserTimeZoneDateToUTCDate(appointmentTransportationDate);

    postObject = {
      header: this.header,

      transportation: {
        id: this.state.transportationDataId,
        date: dateTime,

        description: this.state.transportationDesc,
        accompany: this.state.contactMemberName,
        address: {
          line: this.state.line,
          city: this.state.city,
          zipCode: this.state.zipCode,
          state: {
            code: this.state.state.code,
            id: this.state.stateId,
          },
        },
        accompanyType: {
          type: this.state.transportationType,
        },

        event: {
          id: this.state.eventId,
        },
        reminderList: [],
      },
    };
    FetchServerData.callPostService('/eventtransportms/update', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let outputData = output.data;

          PtsAlert.success(output.message);
          for (let i = 0; i < this.state.transportationEventList.length; i++) {
            if (this.state.transportationEventList[i].id === outputData.id) {
              this.state.transportationEventList[i].accompanyName = outputData.accompanyName;
              this.state.transportationEventList[i].date = outputData.date;
              this.state.transportationEventList[i].description = outputData.description;
              this.state.transportationEventList[i].address.line = outputData.address.line;
              this.state.transportationEventList[i].address.city = outputData.address.city;
              this.state.transportationEventList[i].address.zipCode = outputData.address.zipCode;
              this.state.transportationEventList[i].address.state.country.name = outputData.address.state.country.name;
              this.state.transportationEventList[i].address.state.name = outputData.address.state.name;
              this.state.transportationEventList[i].address.state.id = outputData.address.state.id;
            }
          }

          this.setState({
            eventTransportationView: true,
            transportationEventList: this.state.transportationEventList,
          });
        } else {
        }
      })
      .catch((error) => {});
  }
  getContactMemberList() {
    let memberList = [];

    const postObject = {
      header: this.header,
      member: {
        id: this.memberDetails.member,
      },
    };
    let url = '/memberms/getTransportationUserList';
    FetchServerData.callPostService(url, postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let contactMemberListArray = output.data;
        memberList = contactMemberListArray;
        this.setState({
          contactMemberList: memberList,
        });
      } else {
      }
    });
  }

  addTransportationReminder() {
    let items = this.state.transportationReminder;
    let item = {
      action: 'create',
      operation: 'add',
    };
    items.push(item);
    this.setState({
      transportationReminder: items,
    });
  }

  // addNewTransportationReminder = (reminderData) => {
  //   for (let i = 0; i < this.state.transportationReminder.length; i++) {
  //     if (
  //       this.state.transportationReminder[i] &&
  //       reminderData.index === i &&
  //       this.transportationReminderRefList[i] &&
  //       this.transportationReminderRefList[i].current &&
  //       this.transportationReminderRefList[i].current.validateReminder(i)
  //     ) {
  //       this.state.transportationReminder[i] = reminderData;
  //       this.transportationReminderRefList[i].current.state = reminderData;
  //       this.transportationReminderRefList[i].current.state.action = 'view';
  //       this.setState({ render: true });
  //     }
  //   }
  // };

  // transportationUpdateReminder = (reminderData) => {
  //   for (let i = 0; i < this.state.transportationReminder.length; i++) {
  //     if (
  //       this.state.transportationReminder[i] &&
  //       reminderData.index === i &&
  //       this.transportationReminderRefList[i] &&
  //       this.transportationReminderRefList[i].current &&
  //       this.transportationReminderRefList[i].current.validateReminder()
  //     ) {
  //       this.state.transportationReminder[i] = reminderData;
  //       this.transportationReminderRefList[i].current.state = reminderData;
  //       this.transportationReminderRefList[i].current.state.action = 'view';
  //       this.setState({ render: true });
  //     }
  //   }
  // };

  transportationReminderItemDeleted(item) {
    let items = [];
    if (item.operation && 'update' === item.operation) {
      /* Existing Item Deleted, Mark it deleted */
      let index = item.index;

      for (let i = 0; i < this.state.transportationReminder.length; i++) {
        let item = this.state.transportationReminder[i];
        if (this.state.transportationReminder[i].operation === 'update' && index === i) {
          item.operation = 'delete';
        }

        items.push(item);
      }

      this.setState({ transportationReminder: items });
    }
    if (item.operation && 'add' === item.operation) {
      /* Added Item Deleted, Remove from list */
      let index = item.index;
      for (let i = 0; i < this.state.transportationReminder.length; i++) {
        if (this.state.transportationReminder[i].operation === 'add' && index === i) {
          this.transportationReminderRefList.splice(i, 1);
          this.state.transportationReminder.splice(i, 1);

          // continue;
          this.setState({ transportationReminder: this.state.transportationReminder });
        } else {
          items.push(this.state.transportationReminder[i]);
        }
      }
    }
  }

  backToView() {
    this.setState({ eventTransportationView: true });
  }
  /* edit appointment transportation   */
  editAppointmentTransportation = (e, id) => {
    const postObject = {
      header: this.header,
      transportation: {
        id: id,
      },
    };

    FetchServerData.callPostService('/eventtransportms/get', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let transportationData = output.data;
        let startDate = '';
        let newStartDate = '';
        if (transportationData.date !== undefined) {
          startDate = transportationData.date;

          newStartDate = Utils.convertUTCDateToUserTimeZoneDate(transportationData.date);
        }
        if (this.state.transType === 'event') {
          this.getStateList(transportationData.address.state.country.id);
          this.setState({
            transportationEditRow: true,
            transportationId: transportationData.id,
            appointmentTransportation: transportationData,
            transportationDate: new Date(newStartDate),
            transportationTime: transportationData.dateTime,
            status: transportationData.status.status,
            transportationDesc: transportationData.description,
            address: transportationData.address,
            line: transportationData.address.line,
            city: transportationData.address.city,
            pincode: transportationData.address.zipCode,
            state: transportationData.address.state.code,
            stateId: transportationData.address.state.id,
            country: transportationData.address.state.country.code,
            reason: transportationData.reason,
            transportationDataId: id,
            render: true,
            eventTransportationView: false,
          });
        } else {
          if (transportationData.reminderList.length > 0) {
            for (let i = 0; i < transportationData.reminderList.length; i++) {
              /*Set Default Operation as Update */
              transportationData.reminderList[i].operation = 'update';
            }
            this.getContactMemberList(transportationData.accompanyType.type);
            this.getStateList(transportationData.address.state.country.id);
            this.setState({
              transportationEditRow: true,
              transportationId: transportationData.id,
              appointmentTransportation: transportationData,
              transportationType: transportationData.accompanyType.type,
              contactMemberName: transportationData.accompany,
              accompanyName: transportationData.accompanyName,
              transportationDate: new Date(newStartDate),
              transportationTime: transportationData.dateTime,
              status: transportationData.status.status,
              transportationDesc: transportationData.description,
              address: transportationData.address,
              line: transportationData.address.line,
              city: transportationData.address.city,
              pincode: transportationData.address.zipCode,
              state: transportationData.address.state,
              stateId: transportationData.address.state.id,
              country: transportationData.address.state.country,
              transportationReminder: transportationData.reminderList,
              reason: transportationData.reason,
              transportationDataId: id,
              render: true,
              eventTransportationView: false,
            });
          } else {
            this.getContactMemberList(transportationData.accompanyType.type);
            this.getStateList(transportationData.address.state.country.id);
            this.setState({
              transportationEditRow: true,
              transportationId: transportationData.id,
              appointmentTransportation: transportationData,
              transportationType: transportationData.accompanyType.type,
              contactMemberName: transportationData.accompany,
              accompanyName: transportationData.accompanyName,
              transportationDate: new Date(newStartDate),
              transportationTime: transportationData.dateTime,
              transportationDesc: transportationData.description,
              address: transportationData.address,
              line: transportationData.address.line,
              city: transportationData.address.city,
              pincode: transportationData.address.zipCode,
              state: transportationData.address.state,
              stateId: transportationData.address.state.id,
              country: transportationData.address.state.country,
              transportationDataId: id,
              reason: transportationData.reason,
              render: true,
              transportationReminder: [],
              eventTransportationView: false,
            });
          }
        }
      }
    });
  };

  viewTransportation = (e, transportation) => {
    this.setState({});

    const postObject = {
      header: this.header,
      transportation: {
        id: this.state.id,
      },
    };
    FetchServerData.callPostService('eventtransportms/get', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
      }
    });
  };

  // deleteTransportation() {
  //   if (this.state.onDelete) {
  //     this.state.onDelete(this.state);
  //   }
  // }
  deleteTransportation(e, id) {
    const postObject = {
      header: this.header,
      transportation: {
        id: id,
      },
    };

    FetchServerData.callPostService('/eventtransportms/delete', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          for (let i = 0; i < this.state.transportationEventList.length; i++) {
            if (this.state.transportationEventList[i].id === id) {
              this.state.transportationEventList.splice(i, 1);
            }
          }
          this.setState({
            transportationEventList: this.state.transportationEventList,
            sweetAlert: false,
          });
        } else {
        }
      })
      .catch((error) => {});
  }
  handleRejectTransportationWindowClose = () => {
    this.setState({
      rejectTransportationWindow: false,
    });
  };

  handleTransportationRejectReasonChange(e) {
    let val = e.target.value;
    this.setState({ transportationRejectReason: val });
  }
  toRejectTransportationWindow(e, transportationId) {
    this.setState({
      rejectTransportationWindow: true,
      transportationId: transportationId,
    });
  }
  acceptTransportation(e, transportationId) {
    e.stopPropagation();
    const postObject = {
      header: this.header,

      transportationVo: {
        id: transportationId,
        reason: '',
        isApprove: true,
      },
    };
    FetchServerData.callPostService('eventtransportms/acceptTransportationRequest', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let TransportationRequestData = output.data.transportationList;
        // for (let i = 0; i < this.state.transportationAppointmentList.length; i++) {
        //   if (this.state.transportationAppointmentList[i].id === rejectTransportationRequestData.id) {
        //     this.state.transportationAppointmentList[i].status.status = rejectTransportationRequestData.status.status;
        //   }
        // }
        PtsAlert.success(output.message);
        this.setState({
          transportationEventList: TransportationRequestData,
        });
      } else {
      }
    });
  }

  toResendTransportation(e, transportationId) {
    e.stopPropagation();
    const postObject = {
      header: this.header,

      transportation: {
        id: transportationId,
      },
    };
    FetchServerData.callPostService('eventtransportms/resendTransportationRequest', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let TransportationRequestData = output.data.transportationList;
        PtsAlert.success(output.message);
        this.setState({
          transportationEventList: TransportationRequestData,
        });
      } else {
      }
    });
  }

  cancelTransportation(e, transportationId) {
    e.stopPropagation();
    const postObject = {
      header: this.header,

      transportationVo: {
        id: transportationId,
      },
    };
    FetchServerData.callPostService('/eventtransportms/cancelTransportationRequest', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let TransportationRequestData = output.data.transportationList;
        PtsAlert.success(output.message);
        this.setState({
          transportationEventList: TransportationRequestData,
        });
      } else {
      }
    });
  }

  rejectTransportationRequestInBackend = (e) => {
    e.stopPropagation();
    const postObject = {
      header: this.header,

      transportationVo: {
        id: this.state.transportationId,
        reason: this.state.transportationRejectReason,
        isApprove: false,
      },
    };
    FetchServerData.callPostService('eventtransportms/rejectTransportationRequest', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let rejectTransportationRequestData = output.data.transportationList;
        PtsAlert.success(output.message);
        this.setState({
          transportationEventList: rejectTransportationRequestData,
          rejectTransportationWindow: false,
        });
        // this.props.onSuccess(this.state.appointmentId);
      } else {
      }
    });
  };

  addNewReminderToBackend = (transportationId, reminderData) => {
    let postObject = {};
    for (let i = 0; i < this.state.transportationReminder.length; i++) {
      if (
        this.state.transportationReminder[i] &&
        reminderData.index === i &&
        this.transportationReminderRefList[i] &&
        this.transportationReminderRefList[i].current &&
        this.transportationReminderRefList[i].current.validateReminder(i)
      ) {
        this.state.transportationReminder[i] = reminderData;
        this.transportationReminderRefList[i].current.state = reminderData;
        let reminderDateTime = Utils.convertUserTimeZoneDateToUTCDate(
          this.transportationReminderRefList[i].current.state.startDate
        );
        postObject = {
          header: this.header,
          reminder: {
            content: this.transportationReminderRefList[i].current.state.note,
            date: reminderDateTime,
            eventTransportation: {
              id: transportationId,
            },
          },
        };
        FetchServerData.callPostService('/eventtransportms/createReminder', postObject)
          .then((output) => {
            if (output.status === 'SUCCESS') {
              this.state.transportationReminder = [];
              this.transportationReminderRefList[i].current.state.action = 'view';
              let reminders = [];
              PtsAlert.success(output.message);
              if (output.data.reminderList.length > 0) {
                for (let i = 0; i < output.data.reminderList.length; i++) {
                  reminders.push(output.data.reminderList[i]);
                }
              }

              this.setState({
                render: true,
                transportationReminder: reminders,
              });
            } else {
            }
          })
          .catch((error) => {});
      }
    }
  };

  transportationReminderDeleteToBackend = (transportationId, reminderData) => {
    const postObject = {
      header: this.header,
      reminder: {
        id: reminderData.id,
        eventTransportation: {
          id: transportationId,
        },
      },
    };

    FetchServerData.callPostService('eventtransportms/deleteReminder', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          for (let i = 0; i < this.state.transportationReminder.length; i++) {
            if (this.state.transportationReminder[i].id === reminderData.id) {
              this.state.transportationReminder.splice(i, 1);
            }
          }
          this.setState({
            transportationReminder: this.state.transportationReminder,
            sweetAlert: false,
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  updateReminderInBackend = (transportationId, reminderData) => {
    let postObject = {};
    for (let i = 0; i < this.state.transportationReminder.length; i++) {
      if (
        this.state.transportationReminder[i] &&
        reminderData.index === i &&
        this.transportationReminderRefList[i] &&
        this.transportationReminderRefList[i].current &&
        this.transportationReminderRefList[i].current.validateReminder()
      ) {
        this.state.transportationReminder[i] = reminderData;
        this.transportationReminderRefList[i].current.state = reminderData;

        let reminderDateTime = Utils.convertUserTimeZoneDateToUTCDate(
          this.transportationReminderRefList[i].current.state.startDate
        );
        postObject = {
          header: this.header,
          reminder: {
            content: this.transportationReminderRefList[i].current.state.note,
            id: this.transportationReminderRefList[i].current.state.id,
            date: reminderDateTime,
            eventTransportation: {
              id: transportationId,
            },
          },
        };
        FetchServerData.callPostService('eventtransportms/updateReminder', postObject)
          .then((output) => {
            if (output.status === 'SUCCESS') {
              PtsAlert.success(output.message);
              this.state.transportationReminder = [];
              this.setState({
                transportationReminder: output.data.reminderList,
              });
            } else {
            }
          })
          .catch((error) => {});
      }
    }
  };

  render() {
    if (this.state.render === false) {
      return '';
    }
    if (this.state.action === 'create') {
      return this.createEventTransportation();
    } else if (this.state.action === 'edit') {
      return this.editEventTransportation();
    } else if (this.state.action === 'list') {
      return this.eventTransportationList();
    }
    // else {
    //   return this.viewEventTransportation();
    // }
  }

  eventTransportationList() {
    const columns = [
      {
        dataField: 'accompanyName',
        text: 'Accompany',
      },
      {
        dataField: 'date',
        text: 'Date',
        formatter: (cellContent, row) => Utils.convertUTCDateToUserTimeZoneDate(row.date),
      },
      {
        dataField: 'status.status',
        text: 'Status',
      },
      {
        dataField: 'address',
        text: 'Address',
        formatter: (cellContent, row) => Utils.getAddressAsText(row.address),
      },
      {
        formatter: (cellContent, row) =>
          row.showAcceptButton === true ? (
            <>
              {this.state.isViewButtonHide ? (
                <>
                  {this.state.getCreatePermission ||
                  this.state.getUpdatePermission ||
                  this.state.getDeletePermission ? (
                    <>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={(e) => this.acceptTransportation(e, row.id)}
                        style={{ float: 'right', background: '#ef6603', color: '#fff' }}
                      >
                        Accept
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                ''
              )}
            </>
          ) : (
            <span></span>
          ),
      },

      {
        formatter: (cellContent, row) =>
          row.showRejectButton === true ? (
            <>
              {this.state.isViewButtonHide ? (
                <>
                  {this.state.getCreatePermission ||
                  this.state.getUpdatePermission ||
                  this.state.getDeletePermission ? (
                    <>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={(e) => this.toRejectTransportationWindow(e, row.id)}
                      >
                        Reject
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                ''
              )}
            </>
          ) : (
            <span></span>
          ),
      },
      {
        formatter: (cellContent, row) =>
          row.showResendButton === true ? (
            <>
              {this.state.isViewButtonHide ? (
                <>
                  {this.state.getCreatePermission ||
                  this.state.getUpdatePermission ||
                  this.state.getDeletePermission ? (
                    <>
                      <Button
                        size="small"
                        style={{
                          float: 'right',
                          backgroundColor: '#1a7088',
                          color: '#fff',
                          width: '160px',
                        }}
                        variant="contained"
                        onClick={(e) => this.toResendTransportation(e, row.id)}
                      >
                        Resend Request
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                ''
              )}
            </>
          ) : (
            <></>
          ),
      },
      {
        formatter: (cellContent, row) =>
          row.showCancelButton === true ? (
            <>
              {this.state.isViewButtonHide ? (
                <>
                  {this.state.getCreatePermission ||
                  this.state.getUpdatePermission ||
                  this.state.getDeletePermission ? (
                    <>
                      <Button
                        size="small"
                        style={{ float: 'right', backgroundColor: '#3498DB', color: '#fff', width: '150px' }}
                        variant="contained"
                        onClick={(e) => this.cancelTransportation(e, row.id)}
                      >
                        Cancel Request
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                ''
              )}
            </>
          ) : (
            <></>
          ),
      },
    ];

    const products = this.state.transportationEventList;
    const expandRow = {
      onlyOneExpanding: true,
      showExpandColumn: true,
      renderer: (row) => {
        if (this.state.eventTransportationView === true) {
          return this.viewEventTransportation(row, row.transportationId);
        } else {
          return this.editEventTransportation();
        }
      },
      expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
          return <b>-</b>;
        }
        return <b>+</b>;
      },
      expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
          return <i class="fa fa-chevron-down" aria-hidden="true" style={{ color: 'rgba(0, 0, 0, 0.54)' }}></i>;
        }
        return <i class="fa fa-chevron-right" aria-hidden="true" style={{ color: 'rgba(0, 0, 0, 0.54)' }}></i>;
      },
    };
    return (
      <>
        <div className="transportationAppointmentList">
          <BootstrapTable keyField="id" data={products} columns={columns} expandRow={expandRow} />
        </div>
        <Dialog
          open={this.state.rejectTransportationWindow}
          onClose={this.handleRejectTransportationWindowClose.bind(this)}
          onHide={this.close}
          style={{ padding: '1em' }}
        >
          <DialogContent style={{ marginTop: '2em' }}>
            <Row>
              <Col md={12} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                <TextareaAutosize
                  aria-label="minimum height"
                  fullWidth
                  style={{ width: '100%', padding: '5px' }}
                  variant="outlined"
                  value={this.state.transportationRejectReason}
                  onChange={this.handleTransportationRejectReasonChange.bind(this)}
                  minRows={4}
                  placeholder="Reason"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <DescriptionIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </Col>
            </Row>
          </DialogContent>
          <DialogActions style={{ marginTop: '1em', padding: '2em', marginLeft: 'auto', marginRight: 'auto' }}>
            <Button
              variant="contained"
              size="large"
              style={{
                background: '#0f666b',
                color: '#fff',
                marginRight: '1rem',
                width: '9em',
                fontSize: '0.8em',
                padding: '16px',
                height: '2em',
              }}
              startIcon={<SaveIcon />}
              onClick={this.rejectTransportationRequestInBackend.bind(this)}
            >
              Reject
            </Button>
            <Button
              variant="contained"
              size="large"
              style={{
                background: '#717373',
                color: '#fff',
                marginRight: '1rem',
                width: '9em',
                fontSize: '0.8em',
                padding: '16px',
                height: '2em',
              }}
              startIcon={<CloseIcon />}
              onClick={this.handleRejectTransportationWindowClose.bind(this)}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  createEventTransportation() {
    return (
      <>
        <Card
          md={12}
          style={{ borderRadius: '20px', margin: 'auto', boxShadow: 'none', background: 'transparent !important' }}
        >
          <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
            <Row md={12}>
              <Col md={6}>
                {undefined !== this.state.title && this.state.title.length > 0 && (
                  <Label style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}>
                    Transportation Details
                  </Label>
                )}
              </Col>
              <Col md={6}>
                <Label style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}>Address</Label>
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row md={12}>
              <Col md={6}>
                <Row className="dateRow" style={{ marginBottom: '0.9em' }}>
                  <Col md={12}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="appointmentType-label">Accompany By</InputLabel>
                      <Select
                        id="appointmentType"
                        value={this.state.contactMemberName}
                        label="Accompany By"
                        required
                        onChange={(e) => {
                          this.handleContactMemberChange(e);
                        }}
                        startAdornment={
                          <InputAdornment position="start">
                            <CreateIcon />
                          </InputAdornment>
                        }
                      >
                        {this.state.contactMemberList.map((event, keyIndex) => {
                          return (
                            <MenuItem key={keyIndex} value={event.memberId}>
                              {event.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                </Row>

                <Row style={{ marginBottom: '0.9em' }}>
                  <Col md={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <ThemeProvider theme={materialTheme}>
                        <DateTimePicker
                          value={this.state.transportationDate}
                          variant="outlined"
                          fullWidth
                          className="ptsDateTimePicker"
                          label="Date"
                          // margin="normal"
                          inputVariant="outlined"
                          timeInputLabel="Time:"
                          // minDate={subDays(new Date(), 1)}
                          format="MM/dd/yyyy  h:mm aa"
                          style={{ background: '#fff' }}
                          onChange={this.handleTransportationStartDateChange.bind(this)}
                        />
                      </ThemeProvider>
                    </MuiPickersUtilsProvider>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <TextareaAutosize
                      aria-label="minimum height"
                      style={{ width: '100%', padding: '10px' }}
                      minRows={3}
                      required
                      id={'note' + this.state.index}
                      name="note"
                      inputProps={{ maxLength: 2048 }}
                      value={this.state.transportationDesc}
                      onChange={this.handleTransportationDesChange.bind(this)}
                      onPressEnter={this.handleTransportationDesChange.bind(this)}
                      placeholder="Description"
                    />
                  </Col>
                </Row>
              </Col>
              <Col md={6}>
                <Row className="editAddressRow">
                  <Col md={12}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id="line"
                      label="Address"
                      value={this.state.line}
                      inputProps={{ maxLength: 128, style: { textTransform: 'capitalize' } }}
                      onChange={this.handleLine1Change.bind(this)}
                      name="name"
                      type="text"
                      className="textField"
                    />
                  </Col>
                </Row>
                <Row className="editAddressRow" style={{ padding: '14px 0px' }}>
                  <Col md={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="appointmentType-label">Country</InputLabel>
                      <Select
                        id="appointmentType"
                        getOptionLabel={(option) => option.name}
                        defaultValue={this.state.country}
                        label="Country"
                        onChange={(e) => {
                          this.handleCountryChange(e);
                        }}
                      >
                        {this.countryListArray.map((event, keyIndex) => {
                          return (
                            <MenuItem key={keyIndex} value={event.code}>
                              {event.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>

                  <Col md={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="appointmentType-label">State</InputLabel>
                      <Select
                        id="appointmentType"
                        key={this.state.stateClear}
                        defaultValue={this.state.state}
                        getOptionLabel={(option) => option.name}
                        label="State"
                        onChange={(e) => {
                          this.handleStateChange(e);
                        }}
                      >
                        {this.stateListArray.map((event, keyIndex) => {
                          return (
                            <MenuItem key={keyIndex} value={event.code}>
                              {event.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                </Row>
                <Row className="editAddressRow">
                  <Col md={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id="city"
                      label="City"
                      value={this.state.city}
                      inputProps={{ maxLength: 128 }}
                      onChange={this.handleCityChange.bind(this)}
                      name="city"
                      type="text"
                      className="textField"
                    />
                  </Col>

                  <Col md={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id="zipCode"
                      label="Postal Code"
                      value={this.state.zipCode}
                      inputProps={{ maxLength: 16 }}
                      onChange={this.handleZipCodeChange.bind(this)}
                      name="zipCode"
                      className="textField"
                    />
                  </Col>
                </Row>
              </Col>
            </Row>

            <Row style={{ marginLeft: '-2.3em', padding: '13px' }}>
              <Col md={12} style={{ textAlign: 'center' }}>
                <Button
                  variant="contained"
                  size="small"
                  style={{ background: '#ef6603', color: '#fff' }}
                  onClick={(e) => this.addTransportation()}
                >
                  Send Request
                </Button>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </>
    );
  }

  editEventTransportation() {
    return (
      <>
        <Card md={12} style={{ background: '#f4ecf7' }}>
          <CardHeader>
            <Row md={12}>
              <Col md={12}>
                {undefined !== this.state.title && this.state.title.length > 0 && (
                  <Label style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}>
                    Transportation Details
                  </Label>
                )}
              </Col>
            </Row>
          </CardHeader>
          <CardBody>
            <Row md={12}>
              <Col md={6}>
                <form ref={(form) => (this.form = form)}>
                  <Row>
                    <Col md={3}>
                      <Label className="refAppointmentLabel">Acompany</Label>
                    </Col>
                    <Col md={9}>{this.state.accompanyName}</Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      <Label className="refAppointmentLabel">Date</Label>
                    </Col>
                    <Col md={9}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ThemeProvider theme={materialTheme}>
                          <DateTimePicker
                            value={this.state.transportationDate}
                            variant="outlined"
                            fullWidth
                            label="Date"
                            className="ptsDateTimePicker"
                            // margin="normal"
                            inputVariant="outlined"
                            timeInputLabel="Time:"
                            // minDate={subDays(new Date(), 1)}
                            format="MM/dd/yyyy  h:mm aa"
                            style={{ background: '#fff' }}
                            onChange={this.handleTransportationStartDateChange.bind(this)}
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>
                      <Label className="refAppointmentLabel">Description</Label>
                    </Col>
                    <Col md={9}>{this.state.transportationDesc}</Col>
                  </Row>
                </form>
              </Col>
              <Col md={6}>
                <Row>
                  <Col md={12}>{Utils.getAddressAsText(this.state.address)}</Col>
                </Row>
              </Col>
            </Row>

            <Row style={{ padding: '13px' }}>
              <Col
                md={12}
                style={{
                  textAlign: 'center',
                }}
              >
                <Button
                  variant="contained"
                  size="small"
                  style={{ background: '#ef6603', color: '#fff' }}
                  onClick={(e) => this.updateTransportation()}
                >
                  Save
                </Button>
                &nbsp;
                <CancelIconButton type="button" onClick={(e) => this.backToView()} />
              </Col>
            </Row>
          </CardBody>
        </Card>
      </>
    );
  }

  viewEventTransportation(transportation, transindex) {
    this.state.transportationDate = transportation.date;
    this.state.transportationReminder = transportation.reminderList;

    for (let i = 0; i < this.state.transportationReminder.length; i++) {
      if (
        this.state.transportationReminder[i].operation === 'update' ||
        this.state.transportationReminder[i].operation === null
      ) {
        this.state.transportationReminder[i].operation = 'update';
      }
    }
    let transportationReminderItems = [];
    this.state.transportationReminder.forEach((reminder, index) => {
      this.transportationReminderRefList[index] = React.createRef();

      if ('delete' !== reminder.operation) {
        transportationReminderItems.push(
          <Col md={6}>
            <Reminder
              ref={this.transportationReminderRefList[index]}
              key={index}
              reminderDate={this.state.transportationDate}
              data={reminder}
              srNo={index}
              pageId={this.state.pageId}
              action={reminder.action ? reminder.action : 'view'}
              getDeletePermission={this.state.getDeletePermission}
              getUpdatePermission={this.state.getUpdatePermission}
              onReminderAdd={this.addNewReminderToBackend.bind(this, transportation.id)}
              onCancel={this.transportationReminderItemDeleted.bind(this)}
              onUpdate={this.updateReminderInBackend.bind(this, transportation.id)}
              onDelete={this.transportationReminderDeleteToBackend.bind(this, transportation.id)}
            />
          </Col>
        );
      }
    });
    return (
      <>
        <div>
          <Col md={12} className="transportationView">
            <Card>
              <CardHeader style={{ borderBottom: '1px solid #dcdcdc' }}>
                <Row>
                  <Col md={6}>
                    {' '}
                    <Label style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em' }}>
                      Transportation Details
                    </Label>
                  </Col>
                  <Col md={6} style={{ display: 'flex' }}>
                    {this.state.isViewButtonHide && (
                      <>
                        {this.props.getUpdatePermission === true && (
                          <>
                            {transportation.status.status === 'Rejected' ? (
                              ''
                            ) : (
                              <EditButton onClick={(e) => this.editAppointmentTransportation(e, transportation.id)} />
                            )}
                          </>
                        )}

                        {this.props.getDeletePermission === true && (
                          <DeleteButton
                            domainObject="Transportation"
                            onClick={(e) => this.deleteTransportation(e, transportation.id)}
                          />
                        )}
                      </>
                    )}
                  </Col>
                </Row>
              </CardHeader>
              <CardBody>
                <Row>
                  <Col md={6}>
                    <Row>
                      <Col md={3}>
                        <lable className="refAppointmentLabel">Acompany</lable>
                      </Col>
                      <Col md={6}>{transportation.accompanyName}</Col>
                    </Row>
                    <Row>
                      <Col md={3}>
                        <lable className="refAppointmentLabel">Date</lable>
                      </Col>
                      <Col md={6}>{Utils.convertUTCDateToUserTimeZoneDate(transportation.date)}</Col>
                    </Row>
                    <Row>
                      <Col md={3}>
                        <lable className="refAppointmentLabel">Status</lable>
                      </Col>
                      <Col md={9}>{transportation.status.status}&nbsp;</Col>
                    </Row>
                    <Row>
                      <Col md={3}>
                        <lable className="refAppointmentLabel">Reason</lable>
                      </Col>
                      <Col md={9}>{transportation.reason}&nbsp;</Col>
                    </Row>
                    <Row>
                      <Col md={3}>
                        <lable className="refAppointmentLabel">Description</lable>
                      </Col>
                      <Col md={6}>
                        <span style={{ color: '#000' }}>
                          <pre className="view_AppointmentNote"> {transportation.description} </pre>
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={3}>
                        <lable className="refAppointmentLabel">Address</lable>
                      </Col>
                      <Col md={6}>{Utils.getAddressAsText(transportation.address)}</Col>
                    </Row>
                  </Col>
                  <Col md={6}>
                    <Row>
                      <Col md={6}>
                        <Label style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em' }}>Reminders</Label>
                      </Col>
                      <Col md={6}>
                        {this.state.isViewButtonHide && (
                          <AddIconButton
                            btnName="Add Transportation Reminder"
                            key={transportation.id}
                            onClick={this.addTransportationReminder.bind(this, transindex)}
                          />
                        )}
                      </Col>
                    </Row>
                    <Row md={12} style={{ marginBottom: '1em', marginTop: '1em' }}>
                      {transportationReminderItems}
                    </Row>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter>
                <label
                  style={{
                    fontWeight: 'normal',
                    color: '#888888',
                    width: '100%',
                    fontSize: 'small',
                    textAlign: 'left',
                    padding: '6px',
                    margin: '0',
                  }}
                >
                  {transportation.createdByName && 'Created by ' + transportation.createdByName}
                  {transportation.createdOn &&
                    ' on ' + Utils.convertUTCDateToUserTimeZoneDate(transportation.createdOn)}
                </label>
              </CardFooter>
            </Card>
          </Col>
        </div>
      </>
    );
  }
}
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#008181',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: 'white',
        color: '#008181',
      },
    },
  },
});
