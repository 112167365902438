import CloseIcon from '@material-ui/icons/Close';
import React, { Component } from 'react';
import { Col, Modal, ModalBody, Row } from 'reactstrap';
import FetchServerData from '../../../../provider/FetchServerData';
import selectedPlanAction from '../../../../redux/selectedPlan/selectedPlanAction';
import store from '../../../../redux/store';
import '../../contactUs/assets/vendor/animate.css/animate.min.css';
import '../../contactUs/assets/vendor/bootstrap/css/bootstrap.min.css';
import '../../contactUs/assets/vendor/boxicons/css/boxicons.min.css';
import '../../contactUs/assets/vendor/icofont/icofont.min.css';
import '../../contactUs/assets/vendor/line-awesome/css/line-awesome.min.css';
import '../../contactUs/assets/vendor/owl.carousel/assets/owl.carousel.min.css';
import '../../contactUs/assets/vendor/remixicon/remixicon.css';
import '../../contactUs/assets/vendor/venobox/venobox.css';
import FccAdvertisement from '../../fccAdvertisement/FccAdvertisement';
import './Pricing.scss';
import AppleStore from '../../../../assets/images/appleStore.png';
import GooglePlayStore from '../../../../assets/images/googlePlayStore.png';
export default class Pricing extends Component {
  header = store.getState().header.header;
  constructor(props) {
    super(props);
    this.state = {
      businessPromotionForm: false,
      firmName: '',
      firmWebsite: '',
      planList: [],
      planPriceType: '',
      planPrice: '',
      discount: '',
      modal: false,
      planItemsList: [],
      freePlanItemsList: [],
      isOpen: false,
      menuList: false,
      render: false,
    };
    this.getPlanesData();
  }

  getPlanesData = () => {
    FetchServerData.callPostService('planms/geAllPlans').then((output) => {
      if (output.status === 'SUCCESS') {
        let plansData = output.data;
        plansData.sort((a, b) => a.displaySequence - b.displaySequence);
        let monthlyPlanPrice = '';
        let monthlyDiscount = '';
        let planItemsList = [];
        let freePlanItemsList = [];
        let planType = '';
        for (let i = 0; i < plansData.length; i++) {
          plansData[0].monthlyPlanClass = 'plan-inner_free_plan_card_plan_card_bottom';
          plansData[0].monthlyPlanBtnClass = 'plan-inner_free_plan_button_plan_card_bottom';
          plansData[1].monthlyPlanClass = 'plan-inner_basic_plan_card_plan_card_bottom';
          plansData[1].monthlyPlanBtnClass = 'plan-inner_basic_plan_button_plan_card_bottom';

          for (let j = 0; j < plansData[i].planList.length; j++) {
            monthlyPlanPrice = plansData[i].planList[0].price;
            monthlyDiscount = plansData[i].planList[0].discountPercent;
            planType = plansData[i].planList[0].id;
            planItemsList = plansData[i].planList[0].planItems;
            let userSelectedPlan = {
              selectedPlanId: plansData[i].id,
              selectedPlanName: plansData[i].planName,
              selectedPlanList: plansData[i].planList[0],
            };
            store.dispatch(selectedPlanAction.setSelectedPlan(userSelectedPlan));
          }
        }

        this.setState({
          render: true,
          planList: plansData,
          planPriceType: planType,
          planPrice: monthlyPlanPrice,
          discount: monthlyDiscount,
          planItemsList: planItemsList,
          freePlanItemsList: freePlanItemsList,
        });
      } else {
      }
    });
  };

  toBusinessPromotionForm = (planName, e) => {
    if (planName === 'FCC Subscription') {
      this.props.history.push({
        pathname: '/login',
        state: { pageId: 'Pricing' },
      });
    } else if (planName === 'BUSINESS PROMOTION') {
      this.props.history.push({
        pathname: '/BusinessPromotion',
      });
    } else {
      this.props.history.push({
        pathname: '/createAccount',
      });
    }
  };

  handlePlanPriceTypeChange(e, planId) {
    for (let i = 0; i < this.state.planList.length; i++) {
      for (let j = 0; j < this.state.planList[i].planList.length; j++) {
        if (parseInt(e.target.value) === this.state.planList[i].planList[j].id) {
          let userSelectedPlan = {
            selectedPlanId: planId,
            selectedPlanName: this.state.planList[i].planName,
            selectedPlanList: this.state.planList[i].planList[j],
          };
          store.dispatch(selectedPlanAction.setSelectedPlan(userSelectedPlan));

          this.setState({
            render: true,
            planPrice: this.state.planList[i].planList[j].price,
            discount: this.state.planList[i].planList[j].discountPercent,
            planPriceType: e.target.value,
            planItemsList: this.state.planList[i].planList[j].planItems,
          });
        }
      }
    }
    this.setState({
      planPriceType: e.target.value,
    });
  }

  toggle = () => {
    this.setState({
      businessPromotionForm: !this.state.businessPromotionForm,
    });
  };

  displayPlanType(planPriceList) {
    let optionTemplate = planPriceList.map((v) => <option value={v.id}>{v.plantype}</option>);

    return optionTemplate;
  }

  displayPlanPrice(planName) {
    let planItem;
    let planPrice = [];
    for (let i = 0; i < this.state.planList.length; i++) {
      for (let j = 0; j < this.state.planList[i].planList.length; j++) {
        planItem = planPrice.push(
          <span style={{ left: '7px' }}> {'$' + this.state.planList[i].planList[j].price}</span>
        );
      }
    }

    return planItem;
  }

  displayPlanCard() {
    let plan = [];
    for (let i = 0; i < this.state.planList.length; i++) {
      let feature = [];
      let price = [];
      plan.push(
        <>
          <Col md={3} className="pricingCard" style={{ margin: '0px 45px 0px 0px' }}>
            <div className={this.state.planList[i].monthlyPlanClass} data-aos="zoom-in" data-aos-delay={300}>
              <div className="plan-inner">
                <div className="entry-title">
                  <h3 style={{ padding: '7px' }}> {this.state.planList[i].planName}</h3>
                  <div className="price">
                    {this.state.planList[i].planName === 'FCC Subscription' ? (
                      <>
                        <span style={{ left: '11px' }}>
                          {this.state.planPrice === 19.1
                            ? '$' + this.state.planPrice + '0'
                            : '$' + this.state.planPrice}
                        </span>
                      </>
                    ) : (
                      <span style={{ left: '12px' }}> {'$' + '0.00'}</span>
                    )}
                  </div>
                </div>
                <div className="entry-content">
                  <ul style={{ height: '360px' }}>
                    {this.state.planList[i].planName === 'FCC Subscription' ? (
                      <>
                        {/* {this.state.discount !== '0.0' ? ( */}
                        <li>
                          {' '}
                          <b>
                            {this.state.discount === '0.0' ? '' : 'Discount : ' + Math.round(this.state.discount) + '%'}
                          </b>{' '}
                        </li>
                        {/* ) : (
                          <></>
                        )} */}
                      </>
                    ) : (
                      <li>
                        <b style={{ visibility: 'hidden' }}>
                          {this.state.discount === '0.0' ? '' : 'Discount : ' + Math.round(this.state.discount) + '%'}
                        </b>{' '}
                      </li>
                    )}
                    {feature}
                    {this.state.planList[i].planName === 'FCC Subscription' && (
                      <li style={{ padding: '2em' }}>
                        {' '}
                        <select
                          className="planPriceDropDown"
                          value={this.state.planPriceType}
                          onChange={(e) => {
                            this.handlePlanPriceTypeChange(e, this.state.planList[i].id);
                          }}
                        >
                          {this.displayPlanType(this.state.planList[i].planList)}
                        </select>
                      </li>
                    )}
                  </ul>
                </div>
                <Row>
                  <Col md={12} style={{ textAlign: 'center' }}>
                    <div className={this.state.planList[i].monthlyPlanBtnClass}>
                      {this.state.planList[i].planName === 'BUSINESS PROMOTION' ? (
                        <h6 style={{ marginTop: '-0.7em', fontWeight: '400' }}>For more info</h6>
                      ) : (
                        <h6 style={{ marginTop: '1em' }}></h6>
                      )}
                      <a
                        onClick={this.toBusinessPromotionForm.bind(this, this.state.planList[i].planName)}
                        style={{ borderRadius: '10px', cursor: 'pointer' }}
                      >
                        {this.state.planList[i].planName === 'FREE WITH ADS'
                          ? 'Register Now'
                          : [
                              this.state.planList[i].planName === 'FCC Subscription'
                                ? ' Buy Now'
                                : this.state.planList[i].planName === 'BUSINESS PROMOTION'
                                ? 'Contact Us'
                                : 'Register',
                            ]}
                      </a>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        </>
      );

      for (let j = 0; j < this.state.planList[i].planList.length; j++) {
        this.state.planList[i].planList[j].planItems.sort((a, b) => a.displaySequence - b.displaySequence);
        for (let k = 0; k < this.state.planList[i].planList[j].planItems.length; k++) {
          if (this.state.planList[i].planList[j].plantype === 'Monthly') {
            feature.push(<li>{this.state.planList[i].planList[j].planItems[k].name}</li>);
          } else if (this.state.planList[i].planList[j].plantype === null) {
            feature.push(
              <li style={{ borderBottom: '1px solid #e5e5e5' }} className="freeWithAdd">
                {this.state.planList[i].planList[j].planItems[k].name}
              </li>
            );
          }
        }
      }
      for (let j = 0; j < this.state.planList[i].planList.length; j++) {
        if (this.state.planList[i].planList[j].plantype === 'Monthly') {
          price.push(<span style={{ left: '7px' }}> {'$' + this.state.planList[i].planList[j].price}</span>);
        } else if (this.state.planList[i].planList[j].plantype === null) {
          price.push(<span style={{ left: '7px' }}> {'$' + this.state.planList[i].planList[j].price}</span>);
        }
      }
    }

    return plan;
  }
  toggle = () => {
    this.setState((prevState) => ({
      isOpen: !prevState.isOpen,
    }));
  };
  menuListToggle = () => {
    this.setState({
      menuList: !this.state.menuList,
    });
  };

  openMenu() {
    this.setState({
      menuList: true,
    });
  }

  navigateToLink(linkToNavigate) {
    this.props.history.push(linkToNavigate);
  }
  openLoginTab = () => {
    console.log('Opening Login Tab');
    const win = window.open('/#/login', '_blank');
    win.focus();
  };
  scrollToSection(elementID) {
    if (document.getElementById(elementID)) {
      document.getElementById(elementID).scrollIntoView();
    }
  }
  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <div style={{ height: '100vh', overflowY: 'scroll' }}>
            <link
              href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0-alpha.6/css/bootstrap.min.css"
              rel="stylesheet"
            />
            <link href="//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css" rel="stylesheet" />

            {/* =======================================================
      * Template Name: Selecao - v2.3.1
      * Template URL: https://bootstrapmade.com/selecao-bootstrap-template/
      * Author: BootstrapMade.com
      * License: https://bootstrapmade.com/license/
      ======================================================== */}

            {/* ======= Header ======= */}
            <header
              id="header"
              className="fixed-top d-flex align-items-center  header-transparent "
              style={{ background: '#074246' }}
            >
              <div className="container d-flex align-items-center">
                <div className="logo mr-auto">
                  <span style={{ display: 'flex' }}>
                    <img
                      src="../assets/img/fccLogo.png"
                      alt=""
                      style={{ height: '2.8em', width: '3em', marginTop: '-4px' }}
                    />
                    <h1 className="text-light" style={{ paddingLeft: '0.2em' }}>
                      <a href="/">Family Care Circle</a>
                    </h1>
                  </span>
                </div>

                <nav className="nav-menu d-none d-lg-block">
                  <ul>
                    <li>
                      <a style={{ color: '#fff', cursor: 'pointer' }} onClick={() => this.navigateToLink('/')}>
                        Home
                      </a>
                    </li>

                    <li>
                      <a
                        style={{ color: '#fff', cursor: 'pointer' }}
                        onClick={() => this.openLoginTab()}
                        target="_blank"
                      >
                        Login
                      </a>
                    </li>
                    <li className="active">
                      <a style={{ color: '#fff', cursor: 'pointer' }} onClick={() => this.navigateToLink('/pricing')}>
                        Pricing
                      </a>
                    </li>
                    <li>
                      <a
                        style={{ color: '#fff', cursor: 'pointer' }}
                        onClick={() => this.navigateToLink('/BusinessPromotion')}
                      >
                        Sponsorship
                      </a>
                    </li>
                    <li>
                      <a style={{ color: '#fff', cursor: 'pointer' }} onClick={() => this.navigateToLink('/contactUs')}>
                        Contact
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </header>
            <button
              type="button"
              className="mobile-nav-toggle d-lg-none"
              onClick={(e) => this.openMenu()}
              style={{ marginRight: '0.7em' }}
            >
              <i className="fa fa-bars" style={{ fontSize: '1.5em' }}></i>
            </button>
            {/* End Header */}

            <section id="pricing" className="pricing" style={{ padding: '7em 7em 5em 7em' }}>
              <div className="pricing-title" data-aos="zoom-out">
                <h2>Pricing</h2>
                <p style={{ lineHeight: '1.1em' }}>Our Competing Prices</p>
              </div>
              <Row style={{ marginTop: '1.5em' }}>
                <Col md={10}>
                  <Row>
                    <Col md={3}></Col>
                    {this.displayPlanCard()}
                  </Row>
                </Col>
                <Col md={2} style={{ position: 'relative' }}>
                  <FccAdvertisement advertisementType="Vertical" />
                </Col>
              </Row>
              <Modal isOpen={this.state.menuList} toggle={this.menuListToggle} size="sm">
                <Row>
                  <Col md={12}>
                    <CloseIcon
                      onClick={(e) => this.menuListToggle()}
                      style={{ float: 'right', margin: '0.5em 1em 0em 0em' }}
                    />
                  </Col>
                </Row>
                <ModalBody>
                  <nav className="mobileViewNav">
                    <ul>
                      <li>
                        <a onClick={() => this.navigateToLink('/')}>Home</a>
                      </li>

                      <li>
                        <a onClick={() => this.openLoginTab()} target="_blank">
                          Login
                        </a>
                      </li>
                      <li className="active">
                        <a onClick={() => this.navigateToLink('/pricing')}>Pricing</a>
                      </li>
                      <li>
                        <a onClick={() => this.navigateToLink('/BusinessPromotion')}>Sponsorship</a>
                      </li>
                      <li>
                        <a onClick={() => this.navigateToLink('/contactUs')}>Contact</a>
                      </li>
                    </ul>
                  </nav>
                </ModalBody>
              </Modal>
            </section>
            {/* End Pricing Section */}

            {/* ======= Footer ======= */}
            <footer id="contactUsFooter">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <h3>Family Care Circle</h3>
                    <div className="social-links">
                      <a href="#" className="twitter">
                        <i className="fa fa-twitter" />
                      </a>
                      <a href="https://www.facebook.com/FamilyCareCircle" className="facebook" target="_blank">
                        <i className="fa fa-facebook-f" />
                      </a>
                      <a href="#" className="instagram">
                        <i className="fa fa-instagram" />
                      </a>
                      <a href="#" className="google-plus">
                        <i className="fa fa-skype" />
                      </a>
                      <a href="#" className="linkedin">
                        <i className="fa fa-linkedin" />
                      </a>
                    </div>
                    <div className="copyright">
                      © Copyright{' '}
                      <strong>
                        <span> Family Care Circle</span>
                      </strong>
                      . All Rights Reserved
                    </div>
                  </div>

                  <div className="col-lg-3">
                    <a
                      style={{ color: '#fff', cursor: 'pointer' }}
                      href="https://apps.apple.com/us/app/family-care-circle/id1602521967"
                      target="_blank"
                    >
                      <img
                        src={AppleStore}
                        style={{ height: '40px', border: '1px solid white', borderRadius: '5px' }}
                      />
                    </a>
                    &nbsp;&nbsp;&nbsp;
                    <a
                      target="_blank"
                      href="https://play.google.com/store/apps/details?id=com.familycarecircle.fccmobileapp"
                      style={{ color: '#fff', cursor: 'pointer' }}
                    >
                      <img
                        src={GooglePlayStore}
                        style={{ height: '40px', border: '1px solid white', borderRadius: '5px' }}
                      />
                    </a>
                  </div>
                  <div className="col-lg-3">
                    <ul style={{ float: 'right', listStyle: 'none', lineHeight: '3em', margin: '0 100px 0px 0' }}>
                      <li style={{ textAlign: 'left' }}>
                        <i className="fa fa-angle-right" />

                        <a
                          onClick={() => this.navigateToLink('/contactUs')}
                          style={{ color: '#fff', cursor: 'pointer' }}
                        >
                          {' '}
                          Feedback
                        </a>
                      </li>
                      <li style={{ textAlign: 'left' }}>
                        <i className="fa fa-angle-right" />

                        <a
                          onClick={() => this.navigateToLink('/termsConditions')}
                          style={{ color: '#fff', cursor: 'pointer' }}
                        >
                          {' '}
                          Terms &amp; Conditions
                        </a>
                      </li>
                      <li style={{ textAlign: 'left' }}>
                        <i className="fa fa-angle-right" />

                        <a
                          onClick={() => this.navigateToLink('/privacyPolicyWeb')}
                          style={{ color: '#fff', cursor: 'pointer' }}
                        >
                          {' '}
                          Privacy Policy
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </footer>
            {/* End Footer */}
            <a href="#" className="back-to-top">
              <i className="ri-arrow-up-line" />
            </a>
          </div>
        </>
      );
    }
  }
}
