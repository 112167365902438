import React, { Component } from 'react';
import Avatar, { ConfigProvider } from 'react-avatar';
import { Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import store from '../../../redux/store';
import AdminMenuWithBreadScrum from '../adminMenu/AdminMenuWithBreadScrum';

export class AdminProfile extends Component {
  userDetails = store.getState().adminUserDetails.adminUserDetails;
  header = store.getState().adminHeader.adminHeader;
  title = 'Profile Details';
  breadCrumb = [
    // { pageid: '/home', uiname: 'home' },
    { pageid: '', uiname: 'User Profile Details' },
  ];
  constructor(props) {
    super(props);
    let action = props.action;
    let memberId = props.memberId;
    let title = '';
    let pageId = '';
    if (
      null == memberId &&
      null !== props.history &&
      props.history !== undefined &&
      props.history.location !== null &&
      props.history.location.state !== null &&
      props.history.location.state !== undefined
    ) {
      memberId = props.history.location.state.memberId;
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
      title = props.history.location.state.title;
    }

    this.state = {
      pageId: pageId,
      action: action,
      title: title,
      render: true,
      id: memberId,
      fname: '',
      mname: '',
      lname: '',
      salutation: {},
      description: '',
      dob: '',
      phone: '',
      email: '',
      gender: '',
      designation: '',
      address: {},
      line: '',
      city: '',
      state: '',
      country: '',
      zipCode: '',
    };
    this.getProfileData();
  }

  getProfileData() {
    const postObject = {
      header: this.header,
      user: {
        id: this.userDetails.id,
      },
    };

    FetchServerData.callPostService('/adminuserms/getProfile', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let profileData = {};
        profileData = output.data.user;
        this.setState({
          render: true,
          id: profileData.id,
          fname: profileData.firstName,
          mname: profileData.middleName,
          lname: profileData.lastName,
          dob: profileData.dob,
          email: profileData.email,
          phone: Utils.displayUsaPhoneNumberFormate(profileData.phone),

          gender: profileData.gender,
          //   address: profileAddress,
        });
      }
    });
  }

  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      let fname = null !== this.state.fname && undefined !== this.state.fname ? this.state.fname : '';
      let lname = null !== this.state.lname && undefined !== this.state.lname ? this.state.lname : '';
      let userName = fname + ' ' + lname;
      return (
        <>
          <AdminMenuWithBreadScrum breadCrumb={this.breadCrumb} history={this.props.history} name={this.title} />

          <div style={{ padding: '1em' }} className="appointmentTab">
            <Row style={{ position: 'relative', margin: '0em 1em 1em 0em' }}>
              <Col md={12} style={{ position: 'relative', padding: '5px' }}>
                <Card
                  style={{
                    height: '100%',
                    borderRadius: '20px',
                    boxShadow:
                      '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2)',
                  }}
                >
                  <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                    <Row>
                      <Col md={10}>
                        <Label style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.7em', marginTop: '0.5em' }}>
                          User Profile Details
                        </Label>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row style={{ textAlign: 'left' }}>
                      <Col md={3} style={{ margin: 'auto', textAlign: 'center' }}>
                        <div
                          className="user-panel"
                          style={{ color: '#fff', textAlign: 'center', marginTop: '1em', cursor: 'pointer' }}
                        >
                          <ConfigProvider colors={['#F39C12', '#A569BD', '#FA8072']}>
                            <Avatar name={userName} round="70px" size="70px" />
                          </ConfigProvider>
                        </div>
                      </Col>

                      <Col md={8}>
                        <Row>
                          <Col md={2}>
                            <label style={{ paddingRight: '0px' }}>Name:</label>
                          </Col>
                          <Col md={8} style={{ textAlign: 'left', textTransform: 'capitalize' }}>
                            {' '}
                            <span style={{ color: '#000' }}>{this.state.fname + ' ' + this.state.lname}</span>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={2}>
                            <label style={{ paddingRight: '0px' }}>Email:</label>
                          </Col>
                          <Col md={8} style={{ textAlign: 'left' }}>
                            {' '}
                            <span style={{ color: '#000' }}>
                              <a href={'mailto:' + this.state.email} target="_top">
                                {this.state.email}
                              </a>
                            </span>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={2}>
                            <label style={{ paddingRight: '0px' }}>Phone:</label>
                          </Col>
                          <Col md={8} style={{ textAlign: 'left', textTransform: 'capitalize' }}>
                            {' '}
                            <span style={{ color: '#000' }}>
                              <a href={'tel:' + this.state.phone} target="_top">
                                {' '}
                                {Utils.displayUsaPhoneNumberFormate(this.state.phone)}
                              </a>
                            </span>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              {/* )} */}
            </Row>
          </div>
        </>
      );
    }
  }
}

export default AdminProfile;
