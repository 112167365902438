import {
  CLEAR_DOCTOR_FACILITY_STATE,
  GET_DOCTOR_FACILITY_STATE,
  SET_DOCTOR_FACILITY_STATE,
} from './doctorFacilityStateTypes';

const getDoctorFacilityState = () => {
  return {
    type: GET_DOCTOR_FACILITY_STATE,
  };
};

const setDoctorFacilityState = (data) => {
  return {
    type: SET_DOCTOR_FACILITY_STATE,
    payload: data,
  };
};

const clearDoctorFacilityState = (data) => {
  return {
    type: CLEAR_DOCTOR_FACILITY_STATE,
    payload: data,
  };
};
const doctorFacilityStateAction = {
  getDoctorFacilityState,
  setDoctorFacilityState,
  clearDoctorFacilityState,
};
export default doctorFacilityStateAction;
