import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import React, { Component } from 'react';
import AdSense from 'react-adsense';
import { Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import store from '../../../redux/store';
import Utils from '../../../provider/Utils';
import Address from '../../components/address/Address';
import BackButton from '../buttons/BackButton';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
export default class MyRequest extends Component {
  header = store.getState().header.header;
  title = 'Member Details';
  addressRef = React.createRef();
  breadCrumb = [];

  constructor(props) {
    super(props);

    let memberId = props.memberId;
    let familyMemberMemberId = props.familyMemberMemberId;
    let action = props.action;

    let title = '';
    let pageId = '';
    if (!memberId && props.history && props.history.location && props.history.location.state) {
      memberId = props.history.location.state.memberId;
      familyMemberMemberId = props.history.location.state.familyMemberMemberId;
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
      title = props.history.location.state.title;
    }
    let canRender = false;

    if (null !== familyMemberMemberId && familyMemberMemberId > 0) {
      this.getMemberDetails(familyMemberMemberId);
    } else {
      canRender = true;
    }
    this.currentDate = moment().format('DD-MM-YYYY');

    this.state = {
      pageId: pageId,
      id: memberId,
      title: title,
      render: canRender,
      action: action,
      familyMemberMemberId: familyMemberMemberId,
      value: '',
      address: {},
      fname: '',
      mname: '',
      lname: '',
      email: '',
      mobile: '',
      note: '',
      description: '',
      memberData: '',
      status: 'Active',
      selectedRelation: {},
      onChange: this.props.onChange,
      salutationList: [
        { id: '1', salutation: 'Mr' },
        { id: '2', salutation: 'Miss' },
      ],
      salutation: {},
      appuserId: '',
      checked: true,
      isStatus: true,
      memberViewDiv: true,
      memberEditDiv: false,
      AddressViewDiv: true,
      AddressEditDiv: false,
      requestStatus: '',
    };
  }
  getMemberDetails(familyMemberMemberId) {
    const postObject = {
      header: this.header,
      memberVo: {
        familyMemberMemberId: familyMemberMemberId,
      },
    };

    FetchServerData.callPostService('memberms/getMemberByFamilyMemberMemberId', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let memberDetails = output.data;
        let memberAddress = memberDetails.address;
        let salutation = this.state.salutationList[1];

        let appStatus = memberDetails.isActive;
        let memberStatus = '';
        if (appStatus === true) {
          memberStatus = 'Active';
        } else {
          memberStatus = 'Inactive';
        }
        if (memberAddress) {
          this.setState({
            render: true,
            id: memberDetails.id,
            salutation: salutation,
            fname: memberDetails.firstName,
            lname: memberDetails.lastName,
            requestStatus: memberDetails.registrationStatus.status,
            email: memberDetails.email,
            mobile: memberDetails.phone,
            description: memberDetails.description,
            status: memberStatus,
            familyMemberMemberId: memberDetails.familyMemberMemberId,
            isStatus: memberDetails.isActive,
            address: memberAddress,
          });
        } else {
          this.setState({
            render: true,
            id: memberDetails.id,
            salutation: salutation,
            fname: memberDetails.firstName,
            lname: memberDetails.lastName,
            familyMemberMemberId: memberDetails.familyMemberMemberId,
            requestStatus: memberDetails.registrationStatus.status,
            email: memberDetails.email,
            mobile: memberDetails.phone,
            description: memberDetails.description,
            status: memberStatus,
            isStatus: memberDetails.isActive,
            address: {},
          });
        }
      } else {
      }
    });
  }
  viewCancel = () => {
    this.props.history.push({
      pathname: '/home',
    });
  };

  deleteRequestedMember = (e) => {
    const postObject = {
      header: this.header,
      memberVo: {
        familyMemberMemberId: this.state.familyMemberMemberId,
      },
    };
    FetchServerData.callPostService('memberms/deleteMyRequest', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          this.props.history.push({
            pathname: '/home',
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  toSendRequest = (e) => {
    const postObject = {
      header: this.header,
      memberVo: {
        familyMemberMemberId: this.state.familyMemberMemberId,
      },
    };

    FetchServerData.callPostService('/memberms/resendRequest', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          this.props.history.push({
            pathname: '/home',
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  render() {
    if (this.state.action === 'edit') {
      return this.editFamilyMemberDetails();
    } else if (this.state.action === 'view') {
      return this.viewFamilyMember();
    }
  }

  viewFamilyMember() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <>
            <MenuWithBreadScrum
              breadCrumb={this.breadCrumb}
              deleteCallback={this.deleteRequestedMember.bind(this)}
              history={this.props.history}
              domainObject="Member"
              name={this.title}
              headerWidth="home"
            />
          </>

          <div>
            <Row style={{ position: 'relative', margin: '1em' }}>
              <Col md={6} style={{ position: 'relative', padding: '5px' }}>
                <Card style={{ height: '100%', borderRadius: '20px' }}>
                  <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', marginLeft: '0.5em' }}>
                    <Row>
                      <Col md={9}>
                        {undefined !== this.state.title && this.state.title.length > 0 && (
                          <Label style={{ fontWeight: '600', color: '#162c50', marginLeft: '-0.5em' }}>
                            {this.state.title}
                          </Label>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={2}>
                        <label>Name</label>
                      </Col>
                      <Col md={10}>
                        <span style={{ textTransform: 'capitalize' }}>
                          {' '}
                          {this.state.fname + ' ' + this.state.lname}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2}>
                        <label>Email</label>
                      </Col>
                      <Col md={10}>
                        <span> {this.state.email}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2}>
                        <label>Mobile</label>
                      </Col>
                      <Col md={10}>
                        <span>{Utils.displayUsaPhoneNumberFormate(this.state.mobile)}</span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={2}>
                        <label>Status</label>
                      </Col>
                      <Col md={10}>
                        <span>{this.state.status}</span>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={6} style={{ position: 'relative', padding: '5px' }}>
                <Card style={{ height: '100%', borderRadius: '20px' }}>
                  <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                    <Row>
                      <Col md={11}>
                        {undefined !== this.state.title && this.state.title.length > 0 && (
                          <Label style={{ fontWeight: '600', color: '#162c50' }}>Address</Label>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {Utils.getAddressAsText(this.state.address)}
                    {/* <Address data={this.state.address} action="view" /> */}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {this.state.isFreeUser === true && (
              <>
                {' '}
                <Row style={{ position: 'relative', margin: '0em 1em 1em 0em' }}>
                  <Col md={12} style={{ position: 'relative' }}>
                    <AdSense.Google
                      client="ca-pub-1294083358269513"
                      slot="9809786381"
                      style={{ display: 'block', height: '300px', width: '100%' }}
                      format=" "
                      responsive="true"
                    />
                  </Col>
                </Row>
              </>
            )}{' '}
            <div style={{ textAlign: 'center' }}>
              <BackButton
                type="button"
                style={{ backgroundColor: '#162c50', color: '#fff' }}
                variant="contained"
                startIcon={<CloseIcon />}
                onClick={(e) => this.viewCancel()}
              >
                Back
              </BackButton>
            </div>
          </div>
        </>
      );
    }
  }
}
