import { CLEAR_APPLICATION_STATE, GET_APPLICATION_STATE, SET_APPLICATION_STATE } from './applicationStateTypes';

const initialState = {
  memberCommunicationState: {},
  appointmentState: {},
  familyCircleState: {},
  doctorState: {},
  incidentState: {},
  facilityState: {},
  purchaseState: {},
  calendarState: {},
  eventState: {},
  sponsorState: {},
  sponsorshipState: {},
  advertiserState: {},
  paidAdvertisementState: {},
  advertisementState: {},
  commercialState: {},
  profileState: {},
  adminProfileState: {},
  adminUserState: {},
  prescriptionsState: {},
};

const applicationStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_APPLICATION_STATE:
      return {
        ...state,
      };
    case SET_APPLICATION_STATE:
      return {
        ...state,
        memberCommunicationState: action.payload.memberCommunicationState,
        familyCircleState: action.payload.familyCircleState,
        appointmentState: action.payload.appointmentState,
        incidentState: action.payload.incidentState,
        doctorState: action.payload.doctorState,
        facilityState: action.payload.facilityState,
        purchaseState: action.payload.purchaseState,
        calendarState: action.payload.calendarState,
        eventState: action.payload.eventState,
        sponsorState: action.payload.sponsorState,
        sponsorshipState: action.payload.sponsorshipState,
        advertiserState: action.payload.advertiserState,
        paidAdvertisementState: action.payload.paidAdvertisementState,
        advertisementState: action.payload.advertisementState,
        commercialState: action.payload.commercialState,
        emailTemplateState: action.payload.emailTemplateState,
        profileState: action.payload.profileState,
        adminProfileState: action.payload.adminProfileState,
        adminUserState: action.payload.adminUserState,
        prescriptionsState: action.payload.prescriptionsState,
      };

    case CLEAR_APPLICATION_STATE:
      return {
        ...state,
        memberCommunicationState: null,
        appointmentState: null,
        familyCircleState: null,
        doctorState: null,
        incidentState: null,
        facilityState: null,
        purchaseState: null,
        calendarState: null,
        eventState: null,
        sponsorState: null,
        sponsorshipState: null,
        advertiserState: null,
        paidAdvertisementState: null,
        advertisementState: null,
        commercialState: null,
        emailTemplateState: null,
        profileState: null,
        adminProfileState: null,
        adminUserState: null,
        prescriptionsState: null,
      };
    default:
      return state;
  }
};

export default applicationStateReducer;
