import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AccessAlarmTwoToneIcon from '@material-ui/icons/AccessAlarmTwoTone';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ChatIcon from '@material-ui/icons/Chat';
import CloseIcon from '@material-ui/icons/Close';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import EmailIcon from '@material-ui/icons/Email';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LanguageIcon from '@material-ui/icons/Language';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import MaterialTable from '@material-table/core';
import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import Geocode from 'react-geocode';
import { ValidatorForm } from 'react-material-ui-form-validator';
import 'react-tabs/style/react-tabs.css';
import { Card, CardBody, CardFooter, CardHeader, Col, Label, Row } from 'reactstrap';
import doctor from '../../../../src/assets/images/doctor.png';
import FetchServerData from '../../../provider/FetchServerData';
import UiUtils from '../../../provider/UiUtils';
import Utils from '../../../provider/Utils';
import applicationStateAction from '../../../redux/applicationState/applicationStateAction';
import doctorFacilityStateAction from '../../../redux/doctorFacilityApplicationState/doctorFacilityStateAction';
import fccPageStateAction from '../../../redux/fccPageState/fccPageStateAction';
import helpDataAction from '../../../redux/help/displayHelpAction';
import store from '../../../redux/store';
import AddIconButton from '../buttons/AddIconButton';
import BackButton from '../buttons/BackButton';
import CancelIconButton from '../buttons/CancelIconButton';
import CreateButton from '../buttons/CreateButton';
import EditIconButton from '../buttons/EditIconButton';
import SaveButton from '../buttons/SaveButton';
import Location from '../location/Location';
import Map from '../map/Map';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
import PtsAlert from '../ptsAlert/PtsAlert';
import './MemberDoctor.scss';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { TextValidator } from 'react-material-ui-form-validator';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ShareIcon from '@material-ui/icons/Share';
import BreadCrumbDetailsAction from '../../../redux/breadCrumbDetails/BreadCrumbDetailsAction';
export class MemberDoctor extends Component {
  title = 'Doctor Details';
  memberDetails = store.getState().memberData.memberData;
  memberName = store.getState().memberData.memberData.firstname + ' ' + store.getState().memberData.memberData.lastname;
  specializationList = store.getState().staticData.specializationList;
  isFreeUser = store.getState().userData.userData.isFreeUser;
  displayHelp = store.getState().help.help;
  header = store.getState().header.header;
  fccPageState = store.getState().fccPageState.fccPageState;
  doctorAppointmentState = store.getState().doctorFacilityAppointmentState.doctorAppointmentState;
  memberHomeUrl = store.getState().memberHomeUrl.memberHomeUrl.homeUrl;
  doctorAppointmentStateDetails = {};
  breadCrumb = [
    { pageid: this.memberHomeUrl, uiname: this.memberName },
    { pageid: '/doctorAppointment', uiname: 'Doctor List' },
    { pageid: '', uiname: 'Doctor Details' },
  ];

  locationReferenceList = [];
  specializationOptionList = [];
  salutationList = [
    { salutation: 'Dr', id: '1' },
    { salutation: 'Miss', id: '2' },
  ];

  constructor(props) {
    super(props);
    let doctorState = {};
    store.dispatch(
      doctorFacilityStateAction.setDoctorFacilityState({
        doctorState,
      })
    );
    let doctorId = props.doctorId;
    let action = props.action;
    let title = '';
    let pageId = '';
    let AppStartDate = '';
    let doctorDetails = {};
    let initialPage = 0;
    let appointmentId = '';
    let pageSize = 10;
    if (!doctorId && props.history && props.history.location && props.history.location.state) {
      doctorId = props.history.location.state.doctorId;
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
      title = props.history.location.state.title;
      doctorDetails = props.history.location.state.doctorDetails;
      AppStartDate = props.history.location.state.AppStartDate;
    }
    let canRender = false;
    if (null !== doctorId && doctorId > 0) {
      this.getDoctorDetails(doctorId);
    } else {
      canRender = true;
    }
    if (doctorId === undefined) {
      doctorId = this.fccPageState.id;
      action = this.fccPageState.action;
      title = 'Doctor Details';
      if (action !== 'create') {
        this.getDoctorDetails(doctorId);
      }
      // canRender = true;
    }
    if (this.breadCrumb) {
      Utils.getActiveMenu(this.breadCrumb);
      store.dispatch(BreadCrumbDetailsAction.setBreadCrumbDetails(this.breadCrumb));
    }
    if (this.doctorAppointmentState) {
      this.doctorAppointmentStateDetails = Object.getOwnPropertyNames(this.doctorAppointmentState);
    }
    if (this.doctorAppointmentStateDetails.length > 0) {
      initialPage =
        this.doctorAppointmentState.initialPage && this.doctorAppointmentState.initialPage > 0
          ? this.doctorAppointmentState.initialPage
          : 0;
      pageSize =
        this.doctorAppointmentState.pageSize && this.doctorAppointmentState.pageSize
          ? this.doctorAppointmentState.pageSize
          : 10;
      appointmentId = this.doctorAppointmentState.appointmentId;
    } else {
      initialPage = 0;
      appointmentId = '';
      pageSize = 10;
    }
    if (pageId === 'createDoctor') {
      let getDomainObjectPermission = doctorDetails.domainObjectPrivileges;
      let getCreatePermission = false;
      let getReadPermission = false;
      let getUpdatePermission = false;
      let getDeletePermission = false;
      for (let domainObjectKey in getDomainObjectPermission) {
        if (domainObjectKey.toLowerCase() === 'incident') {
          let incidentPermission = getDomainObjectPermission[domainObjectKey];
          let getPermission = Utils.getDomainObjectRolePermission(incidentPermission);

          getCreatePermission = getPermission[0];
          getReadPermission = getPermission[1];
          getUpdatePermission = getPermission[2];
          getDeletePermission = getPermission[3];
        }
      }
      let doctorsAppointment = doctorDetails.doctor.appointmentList;

      window.scrollTo(0, 0);
      let status = doctorDetails.doctor.status.status;
      let doctorStatus = '';
      if (status === 'Active') {
        doctorStatus = true;
      } else {
        doctorStatus = false;
      }
      this.state = {
        render: true,
        viewDoctor: true,

        getUpdatePermission: getUpdatePermission,
        getDeletePermission: getDeletePermission,
        getReadPermission: getReadPermission,
        getCreatePermission: getCreatePermission,
        id: doctorDetails.doctor.id,
        fname: doctorDetails.doctor.firstName,
        lname: doctorDetails.doctor.lastName,
        email: doctorDetails.doctor.email,
        website: doctorDetails.doctor.website,
        websiteuser: doctorDetails.doctor.websiteuser,
        phone: Utils.displayUsaPhoneNumberFormate(doctorDetails.doctor.phone),

        salutation: doctorDetails.doctor.salutation,
        viewSelectedSpecialization: doctorDetails.doctor.specialist,
        specialization: doctorDetails.doctor.specialist,
        locationList: doctorDetails.doctor.doctorLocationList,
        doctorAppointments: doctorsAppointment,
        doctorDetails: doctorDetails.doctor,
        title: title,
        pageId: pageId,
        action: action,
        pageSize: pageSize,
        isPaging: false,
        isStatus: status,
        isFreeUser: this.isFreeUser,
        stepsEnabled: false,
        copied: false,
        initialStep: 0,
        addressLocations: [],
        steps: [
          {
            element: '.editDetails',
            intro: 'Edit/update Doctor Details',
          },

          {
            element: '.addLocation',
            intro: 'Add multiple Location Details',
          },
          {
            element: '.deleteIcon',
            intro: 'Delete This Location',
          },
          {
            element: '.editLocation',
            intro: 'Update This Location',
          },
          {
            element: '.doctorList',
            intro: 'List of all appointments with this Doctor.',
          },
          {
            element: '.newAppointment',
            intro: 'Create new Appointment with this Doctor',
          },
          {
            element: '.doctorAppointmentAction',
            intro:
              '<b>Hierarchy </b>– See hierarchy of appointments. This can be done by adding reference appointments,<br><b> Messages</b> – Messages related to this Appointment, <br><b>Transportation </b>– Transportation requests related to this Appointment,<br> <b>Reminders </b>– Reminders for this Appointment, ',
          },
          {
            element: '.delete',
            intro: 'Delete Doctor Details',
          },
        ],
        displayFloatButton: this.displayHelp,
        showMapView: false,
        showLocationView: true,
        AppStartDate: AppStartDate,
        appointmentId: appointmentId,
        initialPage: initialPage,
        getAppointmentCreatePermission: false,
        getAppointmentAllPermission: false,
        showIncidentPermission: false,
        getIncidentCreatePermission: false,
        getIncidentAllPermission: false,
        showEventPermission: false,
        getEventCreatePermission: false,
        showAppointmentPermission: false,
      };
    } else {
      if (action === 'view' || action === 'edit') {
        this.state = {
          id: doctorId,
          action: action,
          render: canRender,
          salutation: this.salutationList[0].salutation,
          fname: '',
          mname: '',
          lname: '',
          email: '',
          website: '',
          websiteuser: '',
          phone: '',
          description: '',
          title: title,
          pageId: pageId,
          onChange: this.props.onChange,
          address: {},
          location: {},
          locationItems: [],
          specialization: '',
          viewSelectedSpecialization: '',
          locationList: [],
          locationItemsView: true,
          locationItemsCreateDiv: false,
          pageSize: pageSize,
          isPaging: false,
          doctorAppointments: [],
          getUpdatePermission: false,
          getDeletePermission: false,
          getReadPermission: false,
          getCreatePermission: false,
          isFreeUser: this.isFreeUser,
          stepsEnabled: false,
          copied: false,
          initialStep: 0,
          addressLocations: [],
          steps: [
            {
              element: '.editDetails',
              intro: 'Edit/update Doctor Details',
            },

            {
              element: '.addLocation',
              intro: 'Add multiple Location Details',
            },
            {
              element: '.deleteIcon',
              intro: 'Delete This Location',
            },
            {
              element: '.editLocation',
              intro: 'Update This Location',
            },
            {
              element: '.doctorList',
              intro: 'List of all appointments with this Doctor.',
            },
            {
              element: '.newAppointment',
              intro: 'Create new Appointment with this Doctor',
            },
            {
              element: '.doctorAppointmentAction',
              intro:
                '<b>Hierarchy </b>– See hierarchy of appointments. This can be done by adding reference appointments,<br><b> Messages</b> – Messages related to this Appointment, <br><b>Transportation </b>– Transportation requests related to this Appointment,<br> <b>Reminders </b>– Reminders for this Appointment, ',
            },

            {
              element: '.delete',
              intro: 'Delete Doctor Details',
            },
          ],
          displayFloatButton: this.displayHelp,
          showMapView: false,
          showLocationView: true,
          AppStartDate: AppStartDate,
          appointmentId: appointmentId,
          initialPage: initialPage,
          isStatus: true,
          getAppointmentCreatePermission: false,
          getAppointmentAllPermission: false,
          showIncidentPermission: false,
          getIncidentCreatePermission: false,
          getIncidentAllPermission: false,
          showEventPermission: false,
          getEventCreatePermission: false,
          showAppointmentPermission: false,
        };
      } else {
        this.state = {
          id: doctorId,
          action: action,
          render: canRender,
          salutation: this.salutationList[0].salutation,
          fname: '',
          mname: '',
          lname: '',
          email: '',
          website: '',
          websiteuser: '',
          phone: '',
          description: '',
          title: title,
          pageId: pageId,
          onChange: this.props.onChange,
          address: {},
          location: {},
          locationItems: [],
          specialization: '',
          viewSelectedSpecialization: '',
          locationList: [],
          locationItemsView: true,
          locationItemsCreateDiv: false,
          pageSize: pageSize,
          isPaging: false,
          doctorAppointments: [],
          getAllPermission: false,
          getUpdatePermission: false,
          getDeletePermission: false,
          getReadPermission: false,
          getCreatePermission: false,
          isFreeUser: this.isFreeUser,
          stepsEnabled: false,
          copied: false,
          initialStep: 0,
          addressLocations: [],
          steps: [
            {
              element: '.editDetails',
              intro: 'Edit/update Doctor Details',
            },

            {
              element: '.addLocation',
              intro: 'Add multiple Location Details',
            },
            {
              element: '.deleteIcon',
              intro: 'Delete This Location',
            },
            {
              element: '.editLocation',
              intro: 'Update This Location',
            },
            {
              element: '.doctorList',
              intro: 'List of all appointments with this Doctor.',
            },
            {
              element: '.newAppointment',
              intro: 'Create new Appointment with this Doctor',
            },

            {
              element: '.doctorAppointmentAction',
              intro:
                '<b>Hierarchy </b>– See hierarchy of appointments. This can be done by adding reference appointments,<br><b> Messages</b> – Messages related to this Appointment, <br><b>Transportation </b>– Transportation requests related to this Appointment,<br> <b>Reminders </b>– Reminders for this Appointment, ',
            },

            {
              element: '.delete',
              intro: 'Delete Doctor Details',
            },
          ],
          displayFloatButton: this.displayHelp,
          showMapView: false,
          showLocationView: true,
          AppStartDate: AppStartDate,
          appointmentId: appointmentId,
          initialPage: initialPage,
          isStatus: true,
          getAppointmentCreatePermission: false,
          getAppointmentAllPermission: false,
          showIncidentPermission: false,
          getIncidentCreatePermission: false,
          getIncidentAllPermission: false,
          showEventPermission: false,
          getEventCreatePermission: false,
          showAppointmentPermission: false,
        };
        this.addLocation('defaultCreate');
      }
    }
  }

  closeFloatingButton() {
    this.setState({ displayFloatButton: false });
    let displayFloat = false;

    store.dispatch(helpDataAction.setHelpData(displayFloat));
  }

  toggleSteps = () => {
    this.setState((prevState) => ({ stepsEnabled: !prevState.stepsEnabled }));
  };

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };

  onCopy = () => {
    this.setState({ copied: true });
  };

  async getDoctorDetails(doctorId) {
    let id = doctorId;
    const postObject = {
      header: this.header,
      doctor: {
        id: id,
      },
    };

    await FetchServerData.callPostService('doctorms/getDoctorWithAppointments', postObject)
      .then(async (output) => {
        if (output.status === 'SUCCESS') {
          let doctorDetails = output.data.doctor;
          let doctorSpecialist = doctorDetails.specialist;

          let getDomainObjectPermission = output.data.allowedDomainObjects;
          let getDomainObjectPrivileges = output.data.domainObjectPrivileges;

          let getCreatePermission = false;
          let getReadPermission = false;
          let getUpdatePermission = false;
          let getDeletePermission = false;

          for (let domainObjectKey in getDomainObjectPrivileges) {
            if (domainObjectKey.toLowerCase() === 'doctor') {
              let doctorPermission = getDomainObjectPrivileges[domainObjectKey];
              let getPermission = Utils.getDomainObjectRolePermission(doctorPermission);
              getCreatePermission = getPermission[0];
              getReadPermission = getPermission[1];
              getUpdatePermission = getPermission[2];
              getDeletePermission = getPermission[3];
            }
          }

          let isPaging = false;
          let googleLocationAPIKey = Utils.getLocationAPIKey();
          let doctorsAppointment = [];
          if (output.data.doctorAppointmentList) {
            doctorsAppointment = output.data.doctorAppointmentList;
          } else {
            doctorsAppointment = [];
          }
          if (doctorsAppointment.length > 10) {
            isPaging = true;
          }

          let showAppointmentPermission = false;
          for (let domainObjectKey in getDomainObjectPermission) {
            if (domainObjectKey === 'Appointment') {
              let appointmentPermission = getDomainObjectPermission[domainObjectKey];

              showAppointmentPermission = true;
            }
          }
          Geocode.setApiKey(googleLocationAPIKey);
          Geocode.setLanguage('en');
          Geocode.setLocationType('ROOFTOP');

          let locaData1 = [];
          for (let i = 0; i < doctorDetails.doctorLocationList.length; i++) {
            let locdata = await Utils.getLatLongMapData(
              doctorDetails.doctorLocationList[i].shortDescription,
              doctorDetails.doctorLocationList[i].address.line,
              doctorDetails.doctorLocationList[i].address.city,

              doctorDetails.doctorLocationList[i].address.state.country.name,
              doctorDetails.doctorLocationList[i].address.state.name
            );
            // if (locdata !== {}) {
            locaData1.push(locdata);
            // }
          }
          let status = doctorDetails.status.status;
          let doctorStatus = '';
          if (status === 'Active') {
            doctorStatus = true;
          } else {
            doctorStatus = false;
          }

          this.setState({
            render: true,
            viewDoctor: true,
            isPaging: isPaging,
            doctorDetails: doctorDetails,
            fname: doctorDetails.firstName,
            lname: doctorDetails.lastName,
            email: doctorDetails.email,
            website: doctorDetails.website,
            websiteuser: doctorDetails.websiteuser,
            addressLocations: locaData1,
            phone: Utils.displayUsaPhoneNumberFormate(doctorDetails.phone),

            salutation: doctorDetails.salutation,
            viewSelectedSpecialization: doctorSpecialist,
            specialization: doctorSpecialist,
            locationList: doctorDetails.doctorLocationList,
            doctorAppointments: doctorsAppointment,

            getUpdatePermission: getUpdatePermission,
            getDeletePermission: getDeletePermission,
            getReadPermission: getReadPermission,
            getCreatePermission: getCreatePermission,
            isStatus: doctorStatus,
            showAppointmentPermission: showAppointmentPermission,
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  handleFnameChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 128);
    if (res.length < 128) {
      this.setState({ fname: res });
    } else {
      Utils.maxFieldLength(128);
    }
  }

  handleFnameOnBlur(e) {
    this.setState({ fname: e.target.value });
  }

  handleLnameChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 128);
    if (res.length < 128) {
      this.setState({ lname: res });
    } else {
      Utils.maxFieldLength(128);
    }
  }

  handleEmailChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ email: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }

  handleWebsiteChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 4000);
    if (res.length < 4000) {
      this.setState({ website: res });
    } else {
      Utils.maxFieldLength(4000);
    }
  }

  handleWebsiteuserChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 110);
    if (res.length < 110) {
      this.setState({ websiteuser: res });
    } else {
      Utils.maxFieldLength(110);
    }
  }

  closeButton() {
    this.setState({
      showMapView: false,
      showLocationView: true,
    });
  }

  handlePhoneChange({ target: { value } }) {
    this.setState((prevState) => ({
      phone: Utils.convertPhoneNumberToUsaPhoneNumberFormate(value, prevState.phone),
    }));
  }
  handleSpecializationChange(e) {
    this.setState({ specialization: e.target.value });
  }

  handleSalutationChange(e) {
    this.setState({ salutation: e.target.value });
  }

  backToLocationView() {
    this.setState({ locationItemsView: true, locationItemsCreateDiv: false });
  }

  /*  Delete location from doctor  */
  deleteLocation(item) {
    let items = [];
    if (item.operation && 'update' === item.operation) {
      /* Existing Item Deleted, Mark it deleted */
      let index = item.index;

      for (let i = 0; i < this.state.locationList.length; i++) {
        let item = this.state.locationList[i];
        if (this.state.locationList[i].operation === 'update' && index === i) {
          item.operation = 'delete';
        }
        items.push(item);
      }
      this.setState({ locationList: items });
    }
    if (item.operation && 'add' === item.operation) {
      /* Added Item Deleted, Remove from list */
      let index = item.index;
      for (let i = 0; i < this.state.locationList.length; i++) {
        if (this.state.locationList[i].operation === 'add' && index === i) {
          this.locationReferenceList.splice(i, 1);
          // continue;
        } else {
          items.push(this.state.locationList[i]);
        }
      }
      this.setState({ locationList: items });
    }

    /*Go Back to Location list view if all new Locations are deleted */
    let flag = true;
    for (let i = 0; i < items.length; i++) {
      if (items[i].operation === 'add') {
        flag = false;
      }
    }
    if (flag) {
      this.backToLocationView();
    }
  }

  handleLocationChanged(item) {}
  addLocation(action) {
    let items = this.state.locationList;
    let item = {
      action: action,
      operation: 'add',
      doctor: {
        id: this.state.id,
      },
      shortDescription: '',
      nickName: '',
      fax: '',
      website: '',
      phone: this.state.phone,
      address: {
        line: '',
        city: '',
        zipCode: '',
        state: {
          code: '',
        },
      },
    };
    items.push(item);
    this.setState({ locationList: items });
  }

  /*  update  doctor locations  */
  updateLocation(locationData) {
    for (let i = 0; i < this.state.locationList.length; i++) {
      if (
        this.state.locationList[i] &&
        locationData.index === i &&
        this.locationReferenceList[i] &&
        this.locationReferenceList[i].current &&
        this.locationReferenceList[i].current.validateLocation()
      ) {
        this.state.locationList[i] = locationData;
        this.locationReferenceList[i].current.state = locationData;
        this.locationReferenceList[i].current.state.action = 'editLocation';
        this.setState({ render: true });
      }
    }
  }

  /*  Add  doctor locations in backend  */
  addNewLocationInBackend(locationData) {
    let location = {};
    for (let i = 0; i < this.state.locationList.length; i++) {
      if (
        this.state.locationList[i] &&
        locationData.index === i &&
        this.locationReferenceList[i] &&
        this.locationReferenceList[i].current &&
        this.locationReferenceList[i].current.validateLocation()
      ) {
        location.operation = this.locationReferenceList[i].current.state.operation;
        location.shortDescription = this.locationReferenceList[i].current.state.shortDescription;
        location.nickName = this.locationReferenceList[i].current.state.nickName;
        location.fax = this.locationReferenceList[i].current.state.fax;
        location.website = this.locationReferenceList[i].current.state.website;
        location.phone = this.locationReferenceList[i].current.state.phone.replace(/[^\d]/g, '');
        location.address = {};
        location.address.id = this.locationReferenceList[i].current.state.address.id;
        location.address.line = this.locationReferenceList[i].current.state.address.line;
        location.address.city = this.locationReferenceList[i].current.state.address.city;
        location.address.zipCode = this.locationReferenceList[i].current.state.address.zipCode;
        location.address.state = this.locationReferenceList[i].current.state.address.state;
        location.doctor = {};
        location.doctor.id = this.state.id;
        location.doctor.member = {};
        location.doctor.member.id = this.memberDetails.member;
        const postObject = {
          header: this.header,

          doctorLocation: location,
        };
        FetchServerData.callPostService('doctorms/createLocation', postObject).then((output) => {
          if (output.status === 'SUCCESS') {
            let doctorDetails = output.data;
            PtsAlert.success(output.message);
            this.state.locationList = [];
            this.locationReferenceList[i].current.state.action = 'editLocation';
            this.setState({
              locationList: output.data.doctor.doctorLocationList,
            });
          } else {
          }
        });
      }
    }
  }

  /*  Add doctor details  in backend  */
  addDoctorToBackend(e) {
    e.preventDefault();
    let locations = [];
    for (let i = 0; i < this.locationReferenceList.length; i++) {
      if (null !== this.locationReferenceList[i].current) {
        let location = {};

        location.id = this.locationReferenceList[i].current.state.id;
        location.shortDescription = this.locationReferenceList[i].current.state.shortDescription;
        location.nickName = this.locationReferenceList[i].current.state.nickName;
        location.fax = this.locationReferenceList[i].current.state.fax;
        location.website = this.locationReferenceList[i].current.state.website;
        location.phone = this.locationReferenceList[i].current.state.phone.replace(/[^\d]/g, '');
        location.address = {};
        location.address.id = this.locationReferenceList[i].current.state.address.id;
        location.address.line = this.locationReferenceList[i].current.state.address.line;
        location.address.city = this.locationReferenceList[i].current.state.address.city;
        location.address.zipCode = this.locationReferenceList[i].current.state.address.zipCode;
        location.address.state = this.locationReferenceList[i].current.state.address.state;
        locations[i] = location;
      }
    }
    let mobileNumber = this.state.phone.replace(/[^\d]/g, '');
    const postObject = {
      header: this.header,

      doctor: {
        member: {
          id: this.memberDetails.member,
        },
        salutation: this.state.salutation,
        firstName: this.state.fname,
        lastName: this.state.lname,
        email: this.state.email,
        phone: mobileNumber,
        website: this.state.website,
        websiteuser: this.state.websiteuser,

        specialist: this.state.specialization,
        isSelf: true,
        doctorLocationList: locations,
      },
    };
    FetchServerData.callPostService('/doctorms/create', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let doctorDetails = output.data;
        let fccPageState = {
          id: doctorDetails.doctor.id,
          action: 'view',
          title: 'Doctor Details',
        };
        store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
        PtsAlert.success(output.message);
        if (this.state.pageId === 'memberDoctor') {
          this.props.history.push({
            pathname: '/createDoctorAppointment',

            state: {
              action: 'createNewAppointment',
              title: 'Add Appointment',
              pageId: 'viewDoctorAppointment',
              doctor: doctorDetails.doctor,
              startDate: this.state.AppStartDate,
            },
          });
        } else {
          this.props.history.push({
            pathname: '/viewDoctor',
            state: {
              action: 'view',
              title: 'Doctor Details',
              doctorDetails: doctorDetails,
              pageId: 'createDoctor',
              startDate: this.state.AppStartDate,
            },
          });
        }
      } else {
      }
    });
  }

  /*  Update doctor details  in backend  */
  updateDoctorToBackend(e) {
    e.preventDefault();
    let mobileNumber = this.state.phone.replace(/[^\d]/g, '');
    let doctorStatus = {};
    if (this.state.isStatus === true) {
      doctorStatus = { status: 'Active', id: 1 };
    } else {
      doctorStatus = { status: 'Inactive', id: 2 };
    }
    const postObject = {
      header: this.header,
      doctor: {
        member: {
          id: this.memberDetails.member,
        },
        id: this.state.id,
        firstName: this.state.fname,
        lastName: this.state.lname,
        email: this.state.email,
        website: this.state.website,
        websiteuser: this.state.websiteuser,
        phone: mobileNumber,
        salutation: this.state.salutation,
        specialist: this.state.specialization,
        status: doctorStatus,
      },
    };
    FetchServerData.callPostService('doctorms/doctorUpdate', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let doctorDetails = output.data.doctor;
        PtsAlert.success(output.message);
        let doctorSpecialist = doctorDetails.specialist;
        let doctorsAppointment = output.data.doctorAppointmentList;
        let status = doctorDetails.status.status;
        let doctorStatus = '';
        if (status === 'Active') {
          doctorStatus = true;
        } else {
          doctorStatus = false;
        }
        this.setState({
          render: true,
          viewDoctor: true,
          editDoctor: false,

          fname: doctorDetails.firstName,
          lname: doctorDetails.lastName,
          email: doctorDetails.email,
          phone: doctorDetails.phone,
          website: doctorDetails.website,
          websiteuser: doctorDetails.websiteuser,
          salutation: doctorDetails.salutation,
          viewSelectedSpecialization: doctorSpecialist,
          specialization: doctorSpecialist,
          locationList: doctorDetails.doctorLocationList,
          doctorAppointments: doctorsAppointment,
          isStatus: doctorStatus,
        });
      } else {
      }
    });
  }

  /*  Update doctor location  in backend  */
  updateLocationToBackend(locationData) {
    let location = {};
    for (let i = 0; i < this.state.locationList.length; i++) {
      if (
        this.state.locationList[i] &&
        locationData.index === i &&
        this.locationReferenceList[i] &&
        this.locationReferenceList[i].current &&
        this.locationReferenceList[i].current.validateLocation()
      ) {
        location.id = this.locationReferenceList[i].current.state.id;
        location.operation = this.locationReferenceList[i].current.state.operation;
        location.shortDescription = this.locationReferenceList[i].current.state.shortDescription;
        location.nickName = this.locationReferenceList[i].current.state.nickName;
        location.fax = this.locationReferenceList[i].current.state.fax;
        location.website = this.locationReferenceList[i].current.state.website;
        location.phone = this.locationReferenceList[i].current.state.phone.replace(/[^\d]/g, '');
        location.address = {};
        location.address.id = this.locationReferenceList[i].current.state.address.id;
        location.address.line = this.locationReferenceList[i].current.state.address.line;
        location.address.city = this.locationReferenceList[i].current.state.address.city;
        location.address.zipCode = this.locationReferenceList[i].current.state.address.zipCode;
        location.address.state = this.locationReferenceList[i].current.state.address.state;
        location.doctor = {};
        location.doctor.id = this.state.id;

        const postObject = {
          header: this.header,

          doctorLocation: location,
        };
        FetchServerData.callPostService('doctorms/updateLocation', postObject).then((output) => {
          if (output.status === 'SUCCESS') {
            let doctorDetails = output.data.doctor;
            PtsAlert.success(output.message);
            let doctorSpecialist = doctorDetails.specialist;
            let doctorsAppointment = output.data.doctorAppointmentList;
            let status = doctorDetails.status.status;
            let doctorStatus = '';
            if (status === 'Active') {
              doctorStatus = true;
            } else {
              doctorStatus = false;
            }
            this.setState({
              render: true,
              viewDoctor: true,
              editDoctor: false,

              fname: doctorDetails.firstName,
              lname: doctorDetails.lastName,
              email: doctorDetails.email,
              phone: doctorDetails.phone,
              website: doctorDetails.website,
              websiteuser: doctorDetails.websiteuser,
              salutation: doctorDetails.salutation,
              viewSelectedSpecialization: doctorSpecialist,
              specialization: doctorSpecialist,
              locationList: doctorDetails.doctorLocationList,
              doctorAppointments: doctorsAppointment,
              isStatus: doctorStatus,
            });
          } else {
          }
        });
      }
    }
  }

  toAppointmentDetails = (e, data) => {
    let appointmentDoctorState = {
      initialPage: this.state.initialPage,
      appointmentId: data.id,
      pageSize: this.state.pageSize,
    };
    store.dispatch(doctorFacilityStateAction.setDoctorFacilityState(appointmentDoctorState));
    localStorage.setItem('parentAppointmentId', data.id);
    this.props.history.push({
      pathname: '/viewMemberAppointment',
      state: {
        action: 'view',
        title: 'Appointment Details',
        appointmentId: data.id,
        pageId: 'viewDoctor',
        doctorViewId: this.state.id,
      },
    });
  };

  viewCancel() {
    this.props.history.push({
      pathname: '/doctorAppointment',
    });
  }
  showMap() {
    this.setState({
      showMapView: true,
      showLocationView: false,
    });
  }

  createCancel() {
    if (this.state.pageId === 'memberDoctor') {
      this.props.history.push({
        pathname: '/addAppointment',
        state: {
          action: 'create',
          title: 'Add Appointment',
          pageId: 'addAppointment',
          appointmentId: '',
        },
      });
    } else {
      this.props.history.push({
        pathname: '/doctorAppointment',
      });
    }
  }

  editCancel(e, doctorId) {
    this.props.history.push({
      pathname: '/viewDoctor',
      state: { action: 'view', title: 'Doctor Details ', doctorId: doctorId },
    });
  }

  editCancelDoctorDetails(e) {
    this.setState({
      viewDoctor: true,
      editDoctor: false,
    });
  }

  updateDoctor(e) {
    this.setState({
      viewDoctor: false,
      editDoctor: true,
    });
  }

  /*  Delete doctor details  in backend  */
  deleteDoctor = () => {
    const postObject = {
      header: this.header,
      doctor: {
        id: this.state.id,
      },
    };

    FetchServerData.callPostService('/doctorms/delete/', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          this.props.history.push({
            pathname: '/doctorAppointment',
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  deleteLocationToBackend = (e, doctorLocation) => {
    const postObject = {
      header: this.header,
      doctorLocation: {
        id: doctorLocation.id,
        doctor: {
          id: this.state.id,
        },
      },
    };

    FetchServerData.callPostService('doctorms/deleteLocation', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          // let doctorDetails = output.data;
          for (let i = 0; i < this.state.locationList.length; i++) {
            if (this.state.locationList[i].id === doctorLocation.id) {
              this.state.locationList.splice(i, 1);
            }
          }
          this.setState({
            locationList: this.state.locationList,
          });
        } else {
        }
      })
      .catch((error) => {});
  };
  ShareDoctorInfoToBackEnd = () => {
    const postObject = {
      header: this.header,

      doctorSharingInfo: {
        doctorid: this.state.id,
        targetemail: this.state.shareContactEmail,
        facilityid: null,
      },
    };

    FetchServerData.callPostService('/infoSharingMs/create', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          PtsAlert.success(output.message);
          this.setState({ shareDoctorModal: false, shareContactEmail: '' });
        } else {
        }
      })
      .catch((error) => {});
  };
  addAppointment = (e) => {
    store.dispatch(fccPageStateAction.setFccPageState({}));
    this.props.history.push({
      pathname: '/createDoctorAppointment',
      state: {
        action: 'repeatAppointment',
        title: 'Add Appointment',
        pageId: 'viewDoctorAppointment',
        doctor: this.state.doctorDetails,
      },
    });
  };

  MaterialTableTitle(title) {
    return (
      <Typography
        style={{
          fontWeight: 'bold',
          textTransform: 'capitalize',
          color: '#0099f1',
          display: 'flex',
          fontSize: '1em',
        }}
        className="doctorList"
      >
        {/* {text} */}
        {this.state.lname && 'Appointments with Dr.'}
        {' ' + (this.state.fname ? this.state.fname : '') + ' ' + (this.state.lname ? this.state.lname : '')}
      </Typography>
    );
  }
  getMapView(locations) {
    return <Map data={locations}></Map>;
  }

  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }
  handleStatusChange(e) {
    this.setState({ isStatus: !this.state.isStatus });
  }
  handleOpenShareContactModal() {
    this.setState({ shareDoctorModal: true });
  }

  handleCloseShareContactModal() {
    this.setState({ shareDoctorModal: false });
  }
  handleShareContactEmailChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);

    if (res.length < 64) {
      this.setState({ shareContactEmail: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }
  addLocationData() {}
  render() {
    this.locationReferenceList = [];
    this.state.locationList.forEach((element, index) => {
      this.locationReferenceList[index] = React.createRef();
    });
    if (this.state.action === 'create') {
      return this.createDoctorDetails();
    }
    if (this.state.action === 'edit') {
      return this.editDoctorDetails();
    } else if (this.state.action === 'view') {
      return this.viewDoctorDetails();
    }
  }
  /*  create doctor form  */
  createDoctorDetails() {
    let locationItems = [];

    this.state.locationList.forEach((element, index) => {
      if ('delete' !== element.operation) {
        locationItems.push(
          <Col md={12}>
            <Location
              ref={this.locationReferenceList[index]}
              key={index}
              data={element}
              srNo={index}
              action={element.action}
              onDelete={this.deleteLocationToBackend.bind(this)}
              onCancel={this.deleteLocation.bind(this)}
              onLocationAdd={this.addNewLocationInBackend.bind(this)}
              onUpdate={this.updateLocationToBackend.bind(this)}
            />
          </Col>
        );
      }
    });

    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={'Create Doctor For' + ' ' + this.memberName}
          domainObject="Doctor"
        />
        <ValidatorForm style={{ width: '100%' }} ref="form" onSubmit={this.addDoctorToBackend.bind(this)}>
          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={5} style={{ position: 'relative', padding: '5px' }}>
              <Card style={{ height: '100%', borderRadius: '20px' }}>
                <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.5em' }}>
                  <Row>
                    <Col md={9}>
                      {undefined !== this.state.title && this.state.title.length > 0 && (
                        <Label style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.3em' }}>
                          {this.state.title}
                        </Label>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody style={{ padding: '1em', marginTop: '0em' }}>
                  <Row>
                    <Col md={3} style={{ marginTop: '1.2em' }}>
                      <input
                        className="pts_dataList_dropDown"
                        type="text"
                        id="salutation"
                        list="data"
                        placeholder="Dr"
                        label="Dr"
                        value={this.state.salutation}
                        onChange={(event, value) => {
                          this.handleSalutationChange(event, value);
                        }}
                      />
                      <datalist id="data">
                        {this.salutationList.map((event, key) => (
                          <option key={event.id} value={event.salutation} />
                        ))}
                      </datalist>
                    </Col>
                    <Col md={4}>
                      <TextField
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        type="text"
                        id="fname"
                        onChange={this.handleFnameChange.bind(this)}
                        label="First Name"
                        name="fname"
                        inputprops={{ maxLength: 128, style: { textTransform: 'capitalize' } }}
                        value={this.state.fname}
                      />
                    </Col>
                    <Col md={5}>
                      <TextField
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                        id="lname"
                        type="text"
                        onChange={this.handleLnameChange.bind(this)}
                        label="Last Name"
                        name="lname"
                        inputprops={{ maxLength: 128, style: { textTransform: 'capitalize' } }}
                        value={this.state.lname}
                      />
                    </Col>
                  </Row>{' '}
                  <Row style={{ marginTop: '1em' }}>
                    <Col md={12}>
                      <input
                        className="pts_dataList_dropDown"
                        type="text"
                        required
                        id="specialization-outlined"
                        list="specialization"
                        placeholder="Specialization*"
                        label="Specialization*"
                        value={this.state.specialization}
                        onChange={(event, value) => {
                          this.handleSpecializationChange(event, value);
                        }}
                      />
                      <datalist id="specialization">
                        {this.specializationList.map((event, key) => (
                          <option key={event.specialization} value={event.specialization} />
                        ))}
                      </datalist>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <TextField
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        type="tel"
                        id="phone"
                        onChange={this.handlePhoneChange.bind(this)}
                        label="Phone"
                        name="phone"
                        value={this.state.phone}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <TextField
                        margin="normal"
                        variant="outlined"
                        type="email"
                        fullWidth
                        id="email"
                        inputprops={{ maxLength: 64 }}
                        onChange={this.handleEmailChange.bind(this)}
                        label="Email"
                        name="email"
                        value={this.state.email}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <TextField
                        margin="normal"
                        variant="outlined"
                        type="text"
                        fullWidth
                        id="website"
                        inputprops={{ maxLength: 64 }}
                        onChange={this.handleWebsiteChange.bind(this)}
                        label="Doctor Portal"
                        name="website"
                        value={this.state.website}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <TextField
                        margin="normal"
                        variant="outlined"
                        type="text"
                        fullWidth
                        id="websiteuser"
                        inputprops={{ maxLength: 64 }}
                        onChange={this.handleWebsiteuserChange.bind(this)}
                        label="Portal Username"
                        name="websiteuser"
                        value={this.state.websiteuser}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '1em' }}>
                    <Col md={2}>
                      <label> Status: </label>
                    </Col>
                    <Col md={10} className="statusMember">
                      {this.state.isStatus === true && (
                        <Tooltip title="Please click EDIT to change status">
                          <span>
                            <ToggleButton
                              value="Active"
                              className="ToggleButtonActive"
                              onChange={this.handleStatusChange.bind(this)}
                              style={{
                                background: '#27AE60',
                                color: '#fff',
                                height: '25px',
                                textTransform: 'none',
                              }}
                            >
                              Active
                            </ToggleButton>
                          </span>
                        </Tooltip>
                      )}
                      {this.state.isStatus === false && (
                        <Tooltip title="Please click EDIT to change status">
                          <span>
                            <ToggleButton
                              value="Inactive"
                              className="ToggleButtonInActive"
                              onChange={this.handleStatusChange.bind(this)}
                              style={{
                                background: '#c7c7c7',
                                color: '#000',
                                height: '25px',
                                textTransform: 'none',
                              }}
                            >
                              Inactive
                            </ToggleButton>
                          </span>
                        </Tooltip>
                      )}
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={7} style={{ position: 'relative', padding: '5px' }}>
              <Card style={{ height: '100%', borderRadius: '20px' }}>
                <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                  <Row>
                    <Col md={12}>
                      <Label style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}>
                        Locations
                      </Label>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody style={{ padding: '1em', marginTop: '0em' }}>
                  <Row>{locationItems}</Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <div style={{ textAlign: 'center', padding: '0em 0em 1em' }}>
            <CreateButton type="submit">Create</CreateButton>
            &nbsp;
            <CancelIconButton onClick={(e) => this.createCancel()} />
          </div>
        </ValidatorForm>
      </>
    );
  }
  displayActionTitle() {
    return <h5 className="doctorAppointmentAction">Action</h5>;
  }
  /*  view doctor form  */
  viewDoctorDetails() {
    if (this.state.render === false) {
      return <div />;
    } else {
      if (this.state.isFreeUser === true) {
        return this.viewDoctorDetailsWithAdvertisement();
      } else {
        return this.viewDoctorDetailsWithoutAdvertisement();
      }
    }
  }

  viewDoctorDetailsWithAdvertisement() {
    if (this.state.render === false) {
      return <div />;
    } else {
      let locationItems = [];

      this.state.locationList.forEach((element, index) => {
        if ('delete' !== element.operation) {
          locationItems.unshift(
            <Col md={12}>
              <Location
                ref={this.locationReferenceList[index]}
                key={index}
                data={element}
                srNo={index}
                pageId="editDoctor"
                getDeletePermission={this.state.getDeletePermission}
                getUpdatePermission={this.state.getUpdatePermission}
                action={element.action ? element.action : 'editLocation'}
                onDelete={this.deleteLocationToBackend.bind(this, element)}
                onCancel={this.deleteLocation.bind(this)}
                onLocationAdd={this.addNewLocationInBackend.bind(this)}
                onUpdate={this.updateLocationToBackend.bind(this)}
              />
            </Col>
          );
        }
      });

      return (
        <>
          <MenuWithBreadScrum
            backCallback={this.viewCancel.bind(this)}
            deleteCallback={this.deleteDoctor.bind(this)}
            shareCallback={this.handleOpenShareContactModal.bind(this)}
            getDeletePermission={this.state.getDeletePermission}
            getUpdatePermission={this.state.getUpdatePermission}
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            name={this.title + ' For ' + this.memberName}
            domainObject="Doctor"
            pageId="doctor"
          />
          <Steps
            enabled={this.state.stepsEnabled}
            steps={this.state.steps}
            initialStep={this.state.initialStep}
            onExit={this.onExit}
          />
          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={4} style={{ position: 'relative', padding: '5px' }}>
              <Card style={{ height: '100%', borderRadius: '20px' }}>
                {this.state.viewDoctor && (
                  <>
                    <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                      <Row>
                        <Col md={10}>
                          <Label
                            style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                          >
                            Doctor Details
                          </Label>
                        </Col>
                        <Col md={2}>
                          {this.state.getUpdatePermission === true && (
                            <EditIconButton onClick={this.updateDoctor.bind(this)} />
                          )}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <div style={{ padding: '0em 0em 0em 2em' }}>
                          <img src={doctor} className="img-circle" width="30px" height="30px" alt="" />
                          <label style={{ paddingLeft: '1.1em', color: '#23a8c1', textTransform: 'capitalize' }}>
                            {'Dr.' +
                              ' ' +
                              (this.state.fname ? this.state.fname : '') +
                              ' ' +
                              (this.state.lname ? this.state.lname : '')}
                          </label>
                        </div>
                      </Row>
                      {this.state.viewSelectedSpecialization && (
                        <Row style={{ padding: '3px', marginLeft: '0em' }}>
                          <Col md={1}>
                            <Tooltip title="Specialization" aria-label="add">
                              <i
                                className="fa fa-stethoscope"
                                style={{ fontSize: '20px', fontWeight: 'bold', color: 'blue' }}
                              />
                            </Tooltip>
                          </Col>
                          <Col md={11}>{this.state.viewSelectedSpecialization}</Col>
                        </Row>
                      )}
                      {this.state.phone && (
                        <Row style={{ padding: '3px', marginLeft: '0em' }}>
                          <Col md={1}>
                            <Tooltip title={this.state.phone} aria-label="add">
                              <PhoneIcon style={{ fontSize: '20px' }} />
                            </Tooltip>
                          </Col>
                          <Col md={11}>
                            <a href={'tel:' + this.state.phone} target="_top">
                              {Utils.displayUsaPhoneNumberFormate(this.state.phone)}
                            </a>
                          </Col>
                        </Row>
                      )}
                      {this.state.email && (
                        <Row style={{ padding: '3px', marginLeft: '0em' }}>
                          <Col md={1}>
                            <Tooltip title={this.state.email} aria-label="add">
                              <EmailIcon style={{ fontSize: '20px' }} />
                            </Tooltip>
                          </Col>
                          <Col md={11}>
                            <a href={'mailto:' + this.state.email} target="_top">
                              {this.state.email}
                            </a>
                          </Col>
                        </Row>
                      )}
                      {this.state.website && (
                        <Row style={{ padding: '3px', marginLeft: '0em' }}>
                          <Col md={1}>
                            <Tooltip title="website" aria-label="add">
                              <LanguageIcon style={{ fontSize: 'large' }} />
                            </Tooltip>
                          </Col>
                          <Col md={11}>
                            <a
                              href={
                                this.state.website.includes('https://') || this.state.website.includes('http://')
                                  ? this.state.website
                                  : 'http://' + this.state.website
                              }
                              target="blank"
                            >
                              {/* <a href={'http://' + this.state.website} target="blank"> */}
                              {this.state.website}
                            </a>
                          </Col>
                        </Row>
                      )}
                      {this.state.websiteuser && (
                        <Row style={{ padding: '3px', marginLeft: '0em' }}>
                          <Col md={1}>
                            <Tooltip title="Portal Username" aria-label="add">
                              <PersonIcon style={{ fontSize: '20px' }} />
                            </Tooltip>
                          </Col>
                          <Col md={11}>
                            <a href={'webuserto:' + this.state.websiteuser} target="_top">
                              {this.state.websiteuser}
                            </a>
                            <Tooltip title="copy" aria-label="add">
                              <IconButton style={{ marginLeft: '30px' }}>
                                <CopyToClipboard text={this.state.websiteuser} onClick={this.onCopy}>
                                  <FileCopyIcon style={{ color: 'gray', fontSize: '20px' }} />
                                </CopyToClipboard>
                              </IconButton>
                            </Tooltip>
                          </Col>
                        </Row>
                      )}
                      <Row style={{ marginTop: '1em' }}>
                        <Col md={2}>
                          <label> Status</label>
                        </Col>
                        <Col md={10} className="statusMember" style={{ padding: '0em 2em' }}>
                          {this.state.isStatus === true && (
                            <Tooltip title="Please click EDIT to change status">
                              <span>
                                <ToggleButton
                                  value="Active"
                                  className="ToggleButtonActive"
                                  onChange={this.handleStatusChange.bind(this)}
                                  style={{
                                    background: '#27AE60',
                                    color: '#fff',
                                    height: '25px',
                                    textTransform: 'none',
                                  }}
                                  disabled
                                >
                                  Active
                                </ToggleButton>
                              </span>
                            </Tooltip>
                          )}
                          {this.state.isStatus === false && (
                            <Tooltip title="Please click EDIT to change status">
                              <span>
                                <ToggleButton
                                  value="Inactive"
                                  className="ToggleButtonInActive"
                                  onChange={this.handleStatusChange.bind(this)}
                                  style={{
                                    background: '#D7DBDD',
                                    color: '#000',
                                    height: '25px',
                                    textTransform: 'none',
                                  }}
                                  disabled
                                >
                                  Inactive
                                </ToggleButton>
                              </span>
                            </Tooltip>
                          )}
                        </Col>
                      </Row>{' '}
                    </CardBody>
                  </>
                )}
                {this.state.editDoctor && (
                  <ValidatorForm style={{ width: '100%' }} ref="form" onSubmit={this.updateDoctorToBackend.bind(this)}>
                    <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                      <Row>
                        <Col md={9}>
                          {undefined !== this.state.title && this.state.title.length > 0 && (
                            <Label
                              style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                            >
                              Doctor Details
                            </Label>
                          )}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody style={{ padding: '2em', marginTop: '-2em' }}>
                      <Row>
                        <Col md={3} style={{ marginTop: '1.2em' }}>
                          <input
                            className="pts_dataList_dropDown"
                            type="text"
                            // labelId="salutation-label"
                            id="salutation"
                            list="data"
                            placeholder="Dr"
                            label="Dr"
                            value={this.state.salutation}
                            onChange={(event, value) => {
                              this.handleSalutationChange(event, value);
                            }}
                          />
                          <datalist id="data">
                            {this.salutationList.map((event, key) => (
                              <option key={event.id} value={event.salutation} />
                            ))}
                          </datalist>
                        </Col>
                        <Col md={4}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            type="text"
                            id="fname"
                            onChange={this.handleFnameChange.bind(this)}
                            label="First Name"
                            name="fname"
                            inputprops={{ maxLength: 128, style: { textTransform: 'capitalize' } }}
                            value={this.state.fname}
                          />
                        </Col>
                        <Col md={5}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            required
                            fullWidth
                            id="lname"
                            type="text"
                            onChange={this.handleLnameChange.bind(this)}
                            label="Last Name"
                            name="lname"
                            inputprops={{ style: { textTransform: 'capitalize' } }}
                            value={this.state.lname}
                          />
                        </Col>
                      </Row>{' '}
                      <Row style={{ marginTop: '1em' }}>
                        <Col md={12}>
                          <input
                            className="pts_dataList_dropDown"
                            type="text"
                            required
                            // labelId="specialization-outlined-label"
                            id="specialization-outlined"
                            list="specialization"
                            placeholder="Specialization*"
                            label="Specialization*"
                            value={this.state.specialization}
                            onChange={(event, value) => {
                              this.handleSpecializationChange(event, value);
                            }}
                          />
                          <datalist id="specialization">
                            {this.specializationList.map((event, key) => (
                              <option key={event.specialization} value={event.specialization} />
                            ))}
                          </datalist>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            type="tel"
                            id="phone"
                            onChange={this.handlePhoneChange.bind(this)}
                            label="Phone"
                            name="phone"
                            // inputprops={{ maxLength: 10 }}
                            value={this.state.phone}
                          />
                        </Col>
                      </Row>{' '}
                      <Row>
                        <Col md={12}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            type="email"
                            fullWidth
                            id="email"
                            onChange={this.handleEmailChange.bind(this)}
                            label="Email"
                            name="email"
                            value={this.state.email}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            fullWidth
                            id="website"
                            onChange={this.handleWebsiteChange.bind(this)}
                            label="Doctor Portal"
                            name="website"
                            value={this.state.website}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            fullWidth
                            id="websiteuser"
                            onChange={this.handleWebsiteuserChange.bind(this)}
                            label="Portal Username"
                            name="websiteuser"
                            value={this.state.websiteuser}
                          />
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '1em' }}>
                        <Col md={2}>
                          <label> Status</label>
                        </Col>
                        <Col md={10} className="statusMember">
                          {this.state.isStatus === true && (
                            <Tooltip title="Please click EDIT to change status">
                              <span>
                                <ToggleButton
                                  value="Active"
                                  className="ToggleButtonActive"
                                  onChange={this.handleStatusChange.bind(this)}
                                  style={{
                                    background: '#27AE60',
                                    color: '#fff',
                                    height: '25px',
                                    textTransform: 'none',
                                  }}
                                >
                                  Active
                                </ToggleButton>
                              </span>
                            </Tooltip>
                          )}
                          {this.state.isStatus === false && (
                            <Tooltip title="Please click EDIT to change status">
                              <span>
                                <ToggleButton
                                  value="Inactive"
                                  className="ToggleButtonInActive"
                                  onChange={this.handleStatusChange.bind(this)}
                                  style={{
                                    background: '#c7c7c7',
                                    color: '#000',
                                    height: '25px',
                                    textTransform: 'none',
                                  }}
                                >
                                  Inactive
                                </ToggleButton>
                              </span>
                            </Tooltip>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter style={{ textAlign: 'center' }}>
                      <SaveButton type="submit" />
                      &nbsp;
                      <CancelIconButton type="button" onClick={(e) => this.editCancelDoctorDetails(e, this.state.id)} />
                    </CardFooter>
                  </ValidatorForm>
                )}
              </Card>
            </Col>
            <Col md={8} style={{ position: 'relative', padding: '5px' }}>
              {this.state.showMapView && (
                <Row>
                  <Col md={12}>
                    <Card style={{ height: '100%', borderRadius: '20px' }}>
                      <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                        <Row>
                          <Col md={8}>
                            {undefined !== this.state.title && this.state.title.length > 0 && (
                              <Label
                                style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                              >
                                Locations
                              </Label>
                            )}
                          </Col>
                          <Col md={4}>
                            {this.state.getCreatePermission === true && (
                              <span className="addLocation">
                                <Tooltip title="Add Location" aria-label="add">
                                  <AddIconButton
                                    onClick={this.addLocation.bind(this, 'create')}
                                    btnName="Add Location"
                                  />
                                </Tooltip>
                              </span>
                            )}
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Row md={12}>{locationItems}</Row>
                        <Row style={{ margin: '0em' }}>
                          <Col md={12}>
                            <Row md={12}>
                              <Col md={12} style={{ padding: '0em' }}>
                                {' '}
                                <span className="closeFloatingButton" aria-label="add">
                                  {' '}
                                  <CloseIcon
                                    onClick={this.closeButton.bind(this)}
                                    style={{ float: 'right', fontSize: '15px', cursor: 'pointer' }}
                                  />
                                </span>
                              </Col>
                            </Row>{' '}
                            <Row>
                              {/* <Card style={{ width: '100%', padding: '3em' }}>
                                <CardBody> */}
                              <Col md={12}>{this.getMapView(this.state.addressLocations)} </Col>
                              {/* </CardBody>
                              </Card> */}
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>{' '}
                </Row>
              )}
              {this.state.showLocationView && (
                <Card style={{ height: '100%', borderRadius: '20px' }}>
                  <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                    <Row>
                      <Col md={8}>
                        {undefined !== this.state.title && this.state.title.length > 0 && (
                          <Label
                            style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                          >
                            Locations
                          </Label>
                        )}
                      </Col>
                      <Col md={4}>
                        {this.state.getCreatePermission === true && (
                          <span className="addLocation">
                            <Tooltip title="Add Location" aria-label="add">
                              <AddIconButton onClick={this.addLocation.bind(this, 'create')} btnName="Add Location" />
                            </Tooltip>
                          </span>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row md={12}>{locationItems}</Row>
                    {/* <Row md={12} style={{ marginLeft: 'auto' }}>
                      {' '}
                      <Button
                        type={this.state.type ? this.state.type : ''}
                        variant="contained"
                        size="small"
                        style={{ background: '#717373', color: '#fff', marginRight: '1rem', marginBottom: '1em' }}
                        onClick={(e) => this.showMap()}
                      >
                        Show Map
                      </Button>
                    </Row> */}
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
          {this.state.showAppointmentPermission === true ? (
            <Row style={{ position: 'relative', margin: '1em' }}>
              <Col md={12} style={{ position: 'relative', padding: '5px' }}>
                <MaterialTable
                  icons={UiUtils.getMaterialTableIcons()}
                  title={this.MaterialTableTitle()}
                  columns={[
                    {
                      title: '#',
                      render: (rowData) => rowData.tableData.index + 1,
                      filtering: false,
                    },

                    {
                      title: 'Date',
                      type: 'date',
                      field: 'date',

                      render: (rowData) => (
                        <>
                          {
                            Utils.convertUTCDateToUserTimeZoneDate(rowData.date)
                            // moment(rowData.date).format('DD MMM YYYY hh:mm a')
                          }
                        </>
                      ),

                      align: 'left',
                      width: '20%',
                    },
                    { title: 'Type', field: 'type', width: '12%' },
                    {
                      title: 'Appointment',
                      field: 'appointment',

                      width: '20%',
                    },
                    { title: 'Purpose', field: 'purpose', width: '15%' },

                    {
                      title: 'Status',
                      // render: (rowData) => rowData.status,
                      field: 'status',

                      width: '10%',
                    },
                    {
                      title: '',
                      width: '10%',
                      render: (rowData) =>
                        rowData.markCompleteCancel === true ? (
                          <>
                            <h6 className="blink_me">Mark Complete / Cancel</h6>
                          </>
                        ) : (
                          ''
                        ),
                    },
                  ]}
                  data={this.state.doctorAppointments}
                  options={{
                    sorting: true,
                    actionsColumnIndex: -1,
                    headerStyle: { backgroundColor: '#1ABC9C', fontWeight: 'bold', color: '#fff', align: 'center' },
                    rowStyle: (rowData) => ({
                      backgroundColor: rowData.id === this.state.appointmentId ? '#FEF5E7' : '#fff',
                      height: '3em',
                    }),
                    filtering: true,
                    borderRadius: '20px',
                    pageSize: this.state.pageSize,
                    initialPage: this.state.initialPage,
                    pageSizeOptions: [5, 10, 20, 50, 100],
                    paging: this.state.isPaging,
                  }}
                  localization={{
                    header: {
                      actions: this.displayActionTitle(),
                    },
                  }}
                  onPageChange={(page, pageSize) => {
                    this.handleMaterialTablePageChange(page, pageSize);
                  }}
                  actions={[
                    {
                      icon: () =>
                        this.state.getCreatePermission === true && (
                          <>
                            {this.state.isStatus === true && (
                              <span className="newAppointment">
                                <AddIconButton btnName="Add Appointment" />
                              </span>
                            )}
                          </>
                        ),
                      tooltip: 'Add New Appointment',
                      isFreeAction: true,
                      onClick: (event) => this.addAppointment(),
                    },
                    (rowData) => ({
                      icon: () =>
                        /*if appointment has Reference Appointment then show then button */
                        rowData.hasReferenceAppointments === true ? (
                          <>
                            <AccountTreeIcon
                              className="referenceAppointment"
                              style={{ fontSize: '25px', color: '#2E86C1' }}
                            />
                            &nbsp;
                          </>
                        ) : (
                          <div style={{ marginLeft: '1em' }}></div>
                        ),
                      tooltip: 'Reference Appointment',
                    }),
                    (rowData) => ({
                      icon: () =>
                        /*if appointment has message threads then show then button */
                        rowData.hasMessagingThreads === true ? (
                          <>
                            <Badge badgeContent={rowData.unreadMessageCount} color="primary" overlap="rectangular">
                              <ChatIcon
                                className="message"
                                style={{ fontSize: '25px', color: '#58D68D', marginRight: '0.2em' }}
                              />
                            </Badge>
                            &nbsp;
                          </>
                        ) : (
                          <div style={{ marginLeft: '1em' }}></div>
                        ),
                      tooltip: 'Message',
                    }),

                    (rowData) => ({
                      icon: () =>
                        /*if appointment has active Reminder  then show then button */
                        rowData.hasReminders === true ? (
                          <>
                            <Badge badgeContent={rowData.activeReminderCount} color="primary" overlap="rectangular">
                              <AccessAlarmTwoToneIcon
                                className="reminder"
                                style={{ fontSize: '25px', color: '#EF797D' }}
                              />
                            </Badge>
                            &nbsp;
                          </>
                        ) : (
                          <div style={{ marginLeft: '1em' }}></div>
                        ),
                      tooltip: 'Reminder',
                    }),
                    (rowData) => ({
                      icon: () =>
                        /*if appointment has transportation  then show then button */
                        rowData.hasTransportation === true ? (
                          <>
                            <DriveEtaIcon
                              className="transportation"
                              style={{ fontSize: '25px', color: '#E67E22', marginLeft: '0.2em' }}
                            />
                            &nbsp;
                          </>
                        ) : (
                          <div style={{ marginLeft: '1em' }}></div>
                        ),
                      tooltip: 'Transportation',
                    }),
                  ]}
                  onRowClick={(event, rowData) => {
                    this.toAppointmentDetails(event, rowData);
                    event.stopPropagation();
                  }}
                />
              </Col>
            </Row>
          ) : (
            <></>
          )}
          <div style={{ textAlign: 'center', width: '100%', marginTop: '2em', padding: '0em 0em 1em' }}>
            <BackButton
              type="button"
              style={{ backgroundColor: '#162c50', color: '#fff' }}
              variant="contained"
              startIcon={<CloseIcon />}
              onClick={(e) => this.viewCancel()}
            >
              Back
            </BackButton>
          </div>
          <Dialog
            open={this.state.shareDoctorModal}
            onClose={this.handleCloseShareContactModal.bind(this)}
            className="salesDialogue"
          >
            <ValidatorForm style={{ width: '100%' }} ref="form" onSubmit={this.ShareDoctorInfoToBackEnd.bind(this)}>
              <DialogTitle id="scroll-dialog-title" style={{ background: '#1ABC9C', overflowX: 'hidden' }}>
                <span style={{ color: '#fff' }}>Share Contact</span>
              </DialogTitle>
              <DialogContent style={{ textAlign: 'center', padding: '1em', overflow: 'hidden' }}>
                <Row>
                  <Col md={12}>
                    {/* <TextField
                    margin="normal"
                    variant="outlined"
                    type="email"
                    fullWidth
                    id="email"
                    inputprops={{ maxLength: 64 }}
                    value={this.state.shareContactEmail}
                    onChange={this.handleShareContactEmailChange.bind(this)}
                    label="Email"
                    name="email"
                  /> */}
                    <TextValidator
                      fullWidth
                      required
                      margin="normal"
                      variant="outlined"
                      label="Email"
                      type="email"
                      inputProps={{ maxLength: 64 }}
                      value={this.state.shareContactEmail}
                      onChange={this.handleShareContactEmailChange.bind(this)}
                      onBlur={this.props.onBlur}
                      autoComplete="off"
                      validators={['required', 'isEmail']}
                      errorMessages={['this field is required', 'email is not valid']}
                    />
                  </Col>
                </Row>
              </DialogContent>
              <DialogActions>
                <Row style={{ width: '100%', padding: '0em' }}>
                  <Col md={12} style={{ textAlign: 'center' }}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      style={{
                        background: '#ef6603',
                        color: '#fff',
                        marginRight: '1rem',
                      }}
                      startIcon={<ShareIcon />}
                      // onClick={this.ShareDoctorInfoToBackEnd.bind(this)}
                    >
                      Share
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      style={{
                        background: '#717373',
                        color: '#fff',
                        marginRight: '1rem',
                      }}
                      onClick={this.handleCloseShareContactModal.bind(this)}
                    >
                      Close
                    </Button>
                  </Col>
                </Row>
              </DialogActions>
            </ValidatorForm>
          </Dialog>
        </>
      );
    }
  }

  viewDoctorDetailsWithoutAdvertisement() {
    if (this.state.render === false) {
      return <div />;
    } else {
      let locationItems = [];

      this.state.locationList.forEach((element, index) => {
        if ('delete' !== element.operation) {
          locationItems.unshift(
            <Col md={12}>
              <Location
                ref={this.locationReferenceList[index]}
                key={index}
                data={element}
                srNo={index}
                pageId="editDoctor"
                getDeletePermission={this.state.getDeletePermission}
                getUpdatePermission={this.state.getUpdatePermission}
                action={element.action ? element.action : 'editLocation'}
                onDelete={this.deleteLocationToBackend.bind(this, element)}
                onCancel={this.deleteLocation.bind(this)}
                onLocationAdd={this.addNewLocationInBackend.bind(this)}
                onUpdate={this.updateLocationToBackend.bind(this)}
              />
            </Col>
          );
        }
      });

      return (
        <>
          <MenuWithBreadScrum
            backCallback={this.viewCancel.bind(this)}
            deleteCallback={this.deleteDoctor.bind(this)}
            shareCallback={this.handleOpenShareContactModal.bind(this)}
            getDeletePermission={this.state.getDeletePermission}
            getUpdatePermission={this.state.getUpdatePermission}
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            name={this.title + ' For ' + this.memberName}
            domainObject="Doctor"
            pageId="doctor"
          />
          <Steps
            enabled={this.state.stepsEnabled}
            steps={this.state.steps}
            initialStep={this.state.initialStep}
            onExit={this.onExit}
          />
          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={4} style={{ position: 'relative', padding: '5px' }}>
              <Card style={{ height: '100%', borderRadius: '20px' }}>
                {this.state.viewDoctor && (
                  <>
                    <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                      <Row>
                        <Col md={10}>
                          <Label
                            style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                          >
                            Doctor Details
                          </Label>
                        </Col>
                        <Col md={2}>
                          {this.state.getUpdatePermission === true && (
                            <EditIconButton onClick={this.updateDoctor.bind(this)} />
                          )}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <div style={{ padding: '0em 0em 0em 2em' }}>
                          <img src={doctor} className="img-circle" width="30px" height="30px" alt="" />
                          <label style={{ paddingLeft: '1.1em', color: '#23a8c1', textTransform: 'capitalize' }}>
                            {'Dr.' +
                              ' ' +
                              (this.state.fname ? this.state.fname : '') +
                              ' ' +
                              (this.state.lname ? this.state.lname : '')}
                          </label>
                        </div>
                      </Row>
                      {this.state.viewSelectedSpecialization && (
                        <Row style={{ padding: '3px', marginLeft: '0em' }}>
                          <Col md={1}>
                            <Tooltip title="Specialization" aria-label="add">
                              <i
                                className="fa fa-stethoscope"
                                style={{ fontSize: '20px', fontWeight: 'bold', color: 'blue' }}
                              />
                            </Tooltip>
                          </Col>
                          <Col md={11}>{this.state.viewSelectedSpecialization}</Col>
                        </Row>
                      )}
                      {this.state.phone && (
                        <Row style={{ padding: '3px', marginLeft: '0em' }}>
                          <Col md={1}>
                            <Tooltip title={this.state.phone} aria-label="add">
                              <PhoneIcon style={{ fontSize: '20px' }} />
                            </Tooltip>
                          </Col>
                          <Col md={11}>
                            <a href={'tel:' + this.state.phone} target="_top">
                              {Utils.displayUsaPhoneNumberFormate(this.state.phone)}
                            </a>
                            {/* {this.state.phone} */}
                          </Col>
                        </Row>
                      )}
                      {this.state.email && (
                        <Row style={{ padding: '3px', marginLeft: '0em' }}>
                          <Col md={1}>
                            <Tooltip title={this.state.email} aria-label="add">
                              <EmailIcon style={{ fontSize: '20px' }} />
                            </Tooltip>
                          </Col>
                          <Col md={11}>
                            <a href={'mailto:' + this.state.email} target="_top">
                              {this.state.email}{' '}
                            </a>
                          </Col>
                        </Row>
                      )}
                      {this.state.website && (
                        <Row style={{ padding: '3px', marginLeft: '0em' }}>
                          <Col md={1}>
                            <Tooltip title={this.state.website} aria-label="add">
                              <LanguageIcon style={{ fontSize: 'large' }} />
                            </Tooltip>
                          </Col>
                          <Col md={11}>
                            <a
                              href={
                                this.state.website.includes('https://') || this.state.website.includes('http://')
                                  ? this.state.website
                                  : 'http://' + this.state.website
                              }
                              target="_blank"
                            >
                              {this.state.website}
                            </a>
                          </Col>
                        </Row>
                      )}
                      {this.state.websiteuser && (
                        <Row style={{ padding: '3px', marginLeft: '0em' }}>
                          <Col md={1}>
                            <Tooltip title="Portal Username" aria-label="add">
                              <PersonIcon style={{ fontSize: '20px' }} />
                            </Tooltip>
                          </Col>
                          <Col md={11}>
                            <a href={'webuserto:' + this.state.websiteuser} target="_top">
                              {this.state.websiteuser}
                            </a>
                            <Tooltip title="copy" aria-label="add">
                              <IconButton style={{ marginLeft: '30px' }}>
                                <CopyToClipboard text={this.state.websiteuser} onClick={this.onCopy}>
                                  <FileCopyIcon style={{ color: 'gray', fontSize: '20px' }} />
                                </CopyToClipboard>
                              </IconButton>
                            </Tooltip>
                          </Col>
                        </Row>
                      )}
                      <Row style={{ marginTop: '1em' }}>
                        <Col md={2}>
                          <label> Status</label>
                        </Col>
                        <Col md={10} className="statusMember" style={{ padding: '0em 2em' }}>
                          {this.state.isStatus === true && (
                            <Tooltip title="Please click EDIT to change status">
                              <span>
                                <ToggleButton
                                  value="Active"
                                  className="ToggleButtonActive"
                                  onChange={this.handleStatusChange.bind(this)}
                                  style={{
                                    background: '#27AE60',
                                    color: '#fff',
                                    height: '25px',
                                    textTransform: 'none',
                                  }}
                                  disabled
                                >
                                  Active
                                </ToggleButton>
                              </span>
                            </Tooltip>
                          )}
                          {this.state.isStatus === false && (
                            <Tooltip title="Please click EDIT to change status">
                              <span>
                                <ToggleButton
                                  value="Inactive"
                                  className="ToggleButtonInActive"
                                  onChange={this.handleStatusChange.bind(this)}
                                  style={{
                                    background: '#D7DBDD',
                                    color: '#000',
                                    height: '25px',
                                    textTransform: 'none',
                                  }}
                                  disabled
                                >
                                  Inactive
                                </ToggleButton>
                              </span>
                            </Tooltip>
                          )}
                        </Col>
                      </Row>{' '}
                    </CardBody>
                    {/* </Card> */}
                  </>
                )}
                {this.state.editDoctor && (
                  <ValidatorForm style={{ width: '100%' }} ref="form" onSubmit={this.updateDoctorToBackend.bind(this)}>
                    <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                      <Row>
                        <Col md={9}>
                          {undefined !== this.state.title && this.state.title.length > 0 && (
                            <Label
                              style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                            >
                              Doctor Details
                            </Label>
                          )}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody style={{ padding: '2em', marginTop: '-2em' }}>
                      <Row>
                        <Col md={3} style={{ marginTop: '1.2em' }}>
                          <input
                            className="pts_dataList_dropDown"
                            type="text"
                            // labelId="salutation-label"
                            id="salutation"
                            list="data"
                            placeholder="Dr"
                            label="Dr"
                            value={this.state.salutation}
                            onChange={(event, value) => {
                              this.handleSalutationChange(event, value);
                            }}
                          />
                          <datalist id="data">
                            {this.salutationList.map((event, key) => (
                              <option key={event.id} value={event.salutation} />
                            ))}
                          </datalist>
                        </Col>
                        <Col md={4}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            type="text"
                            id="fname"
                            onChange={this.handleFnameChange.bind(this)}
                            label="First Name"
                            name="fname"
                            inputprops={{ maxLength: 128, style: { textTransform: 'capitalize' } }}
                            value={this.state.fname}
                          />
                        </Col>
                        <Col md={5}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            required
                            fullWidth
                            id="lname"
                            type="text"
                            onChange={this.handleLnameChange.bind(this)}
                            label="Last Name"
                            name="lname"
                            inputprops={{ style: { textTransform: 'capitalize' } }}
                            value={this.state.lname}
                          />
                        </Col>
                      </Row>{' '}
                      <Row style={{ marginTop: '1em' }}>
                        <Col md={12}>
                          <input
                            className="pts_dataList_dropDown"
                            type="text"
                            required
                            // labelId="specialization-outlined-label"
                            id="specialization-outlined"
                            list="specialization"
                            placeholder="Specialization*"
                            label="Specialization*"
                            value={this.state.specialization}
                            onChange={(event, value) => {
                              this.handleSpecializationChange(event, value);
                            }}
                          />
                          <datalist id="specialization">
                            {this.specializationList.map((event, key) => (
                              <option key={event.specialization} value={event.specialization} />
                            ))}
                          </datalist>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            type="tel"
                            id="phone"
                            onChange={this.handlePhoneChange.bind(this)}
                            label="Phone"
                            name="phone"
                            // inputprops={{ maxLength: 10 }}
                            value={this.state.phone}
                          />
                        </Col>
                      </Row>{' '}
                      <Row>
                        <Col md={12}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            type="email"
                            fullWidth
                            id="email"
                            onChange={this.handleEmailChange.bind(this)}
                            label="Email"
                            name="email"
                            value={this.state.email}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            fullWidth
                            id="website"
                            onChange={this.handleWebsiteChange.bind(this)}
                            label="Doctor Portal"
                            name="website"
                            value={this.state.website}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            fullWidth
                            id="websiteuser"
                            onChange={this.handleWebsiteuserChange.bind(this)}
                            label="Portal Username"
                            name="websiteuser"
                            value={this.state.websiteuser}
                          />
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '1em' }}>
                        <Col md={2}>
                          <label> Status</label>
                        </Col>
                        <Col md={10} className="statusMember">
                          {this.state.isStatus === true && (
                            <Tooltip title="Please click EDIT to change status">
                              <span>
                                <ToggleButton
                                  value="Active"
                                  className="ToggleButtonActive"
                                  onChange={this.handleStatusChange.bind(this)}
                                  style={{
                                    background: '#27AE60',
                                    color: '#fff',
                                    height: '25px',
                                    textTransform: 'none',
                                  }}
                                >
                                  Active
                                </ToggleButton>
                              </span>
                            </Tooltip>
                          )}
                          {this.state.isStatus === false && (
                            <Tooltip title="Please click EDIT to change status">
                              <span>
                                <ToggleButton
                                  value="Inactive"
                                  className="ToggleButtonInActive"
                                  onChange={this.handleStatusChange.bind(this)}
                                  style={{
                                    background: '#c7c7c7',
                                    color: '#000',
                                    height: '25px',
                                    textTransform: 'none',
                                  }}
                                >
                                  Inactive
                                </ToggleButton>
                              </span>
                            </Tooltip>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter style={{ textAlign: 'center' }}>
                      <SaveButton type="submit" />
                      &nbsp;
                      <CancelIconButton type="button" onClick={(e) => this.editCancelDoctorDetails(e, this.state.id)} />
                    </CardFooter>
                  </ValidatorForm>
                )}
              </Card>
            </Col>
            <Col md={8} style={{ position: 'relative', padding: '5px' }}>
              {this.state.showMapView && (
                <Row>
                  <Col md={12}>
                    <Card style={{ height: '100%', borderRadius: '20px' }}>
                      <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                        <Row>
                          <Col md={8}>
                            {undefined !== this.state.title && this.state.title.length > 0 && (
                              <Label
                                style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                              >
                                Locations
                              </Label>
                            )}
                          </Col>
                          <Col md={4}>
                            {this.state.getCreatePermission === true && (
                              <span className="addLocation">
                                <Tooltip title="Add Location" aria-label="add">
                                  <AddIconButton
                                    onClick={this.addLocation.bind(this, 'create')}
                                    btnName="Add Location"
                                  />
                                </Tooltip>
                              </span>
                            )}
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Row md={12}>{locationItems}</Row>
                        <Row style={{ margin: '0em' }}>
                          <Col md={12}>
                            <Row md={12}>
                              <Col md={12} style={{ padding: '0em' }}>
                                {' '}
                                <span className="closeFloatingButton" aria-label="add">
                                  {' '}
                                  <CloseIcon
                                    onClick={this.closeButton.bind(this)}
                                    style={{ float: 'right', fontSize: '15px', cursor: 'pointer' }}
                                  />
                                </span>
                              </Col>
                            </Row>{' '}
                            <Row>
                              <Col md={12}>{this.getMapView(this.state.addressLocations)} </Col>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>{' '}
                </Row>
              )}
              {this.state.showLocationView && (
                <Card style={{ height: '100%', borderRadius: '20px' }}>
                  <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                    <Row>
                      <Col md={8}>
                        {undefined !== this.state.title && this.state.title.length > 0 && (
                          <Label
                            style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                          >
                            Locations
                          </Label>
                        )}
                      </Col>
                      <Col md={4}>
                        {this.state.getCreatePermission === true && (
                          <span className="addLocation">
                            <Tooltip title="Add Location" aria-label="add">
                              <AddIconButton onClick={this.addLocation.bind(this, 'create')} btnName="Add Location" />
                            </Tooltip>
                          </span>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row md={12}>{locationItems}</Row>
                    {/* <Row md={12} style={{ marginLeft: 'auto' }}>
                      {' '}
                      <Button
                        type={this.state.type ? this.state.type : ''}
                        variant="contained"
                        size="small"
                        style={{ background: '#717373', color: '#fff', marginRight: '1rem', marginBottom: '1em' }}
                        onClick={(e) => this.showMap()}
                      >
                        Show Map
                      </Button>
                    </Row> */}
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
          {this.state.showAppointmentPermission === true ? (
            <Row style={{ position: 'relative', margin: '1em' }}>
              <Col md={12} style={{ position: 'relative', padding: '5px' }}>
                <MaterialTable
                  icons={UiUtils.getMaterialTableIcons()}
                  title={this.MaterialTableTitle()}
                  columns={[
                    {
                      title: '#',
                      render: (rowData) => rowData.tableData.index + 1,
                      filtering: false,
                    },

                    {
                      title: 'Date',
                      type: 'date',
                      field: 'date',

                      render: (rowData) => (
                        <>
                          {
                            Utils.convertUTCDateToUserTimeZoneDate(rowData.date)
                            // moment(rowData.date).format('DD MMM YYYY hh:mm a')
                          }
                        </>
                      ),

                      align: 'left',
                      width: '20%',
                    },
                    { title: 'Type', field: 'type', width: '12%' },
                    {
                      title: 'Appointment',
                      field: 'appointment',

                      width: '20%',
                    },
                    { title: 'Purpose', field: 'purpose', width: '15%' },

                    {
                      title: 'Status',
                      // render: (rowData) => rowData.status,
                      field: 'status',

                      width: '10%',
                    },
                    {
                      title: '',
                      width: '10%',
                      render: (rowData) =>
                        rowData.markCompleteCancel === true ? (
                          <>
                            <h6 className="blink_me">Mark Complete / Cancel</h6>
                          </>
                        ) : (
                          ''
                        ),
                    },
                  ]}
                  data={this.state.doctorAppointments}
                  options={{
                    sorting: true,
                    actionsColumnIndex: -1,
                    headerStyle: { backgroundColor: '#1ABC9C', fontWeight: 'bold', color: '#fff', align: 'center' },
                    rowStyle: (rowData) => ({
                      backgroundColor: rowData.id === this.state.appointmentId ? '#FEF5E7' : '#fff',
                      height: '3em',
                    }),
                    filtering: true,
                    borderRadius: '20px',
                    pageSize: this.state.pageSize,
                    initialPage: this.state.initialPage,
                    pageSizeOptions: [5, 10, 20, 50, 100],
                    paging: this.state.isPaging,
                  }}
                  localization={{
                    header: {
                      actions: this.displayActionTitle(),
                    },
                  }}
                  onPageChange={(page, pageSize) => {
                    this.handleMaterialTablePageChange(page, pageSize);
                  }}
                  actions={[
                    {
                      icon: () =>
                        this.state.getCreatePermission === true && (
                          <>
                            {this.state.isStatus === true && (
                              <span className="newAppointment">
                                <AddIconButton btnName="Add Appointment" />
                              </span>
                            )}
                          </>
                        ),
                      tooltip: 'Add New Appointment',
                      isFreeAction: true,
                      onClick: (event) => this.addAppointment(),
                    },
                    (rowData) => ({
                      icon: () =>
                        /*if appointment has Reference Appointment then show then button */
                        rowData.hasReferenceAppointments === true ? (
                          <>
                            <AccountTreeIcon
                              className="referenceAppointment"
                              style={{ fontSize: '25px', color: '#2E86C1' }}
                            />
                            &nbsp;
                          </>
                        ) : (
                          <div style={{ marginLeft: '1em' }}></div>
                        ),
                      tooltip: 'Reference Appointment',
                    }),
                    (rowData) => ({
                      icon: () =>
                        /*if appointment has message threads then show then button */
                        rowData.hasNotes === true ? (
                          <>
                            <Badge badgeContent={rowData.unreadMessageCount} color="primary" overlap="rectangular">
                              <ChatIcon
                                className="message"
                                style={{ fontSize: '25px', color: '#58D68D', marginRight: '0.2em' }}
                              />
                            </Badge>
                            &nbsp;
                          </>
                        ) : (
                          <div style={{ marginLeft: '1em' }}></div>
                        ),
                      tooltip: 'Message',
                    }),

                    (rowData) => ({
                      icon: () =>
                        /*if appointment has active Reminder  then show then button */
                        rowData.hasReminders === true ? (
                          <>
                            <Badge badgeContent={rowData.activeReminderCount} color="primary" overlap="rectangular">
                              <AccessAlarmTwoToneIcon
                                className="reminder"
                                style={{ fontSize: '25px', color: '#EF797D' }}
                              />
                            </Badge>
                            &nbsp;
                          </>
                        ) : (
                          <div style={{ marginLeft: '1em' }}></div>
                        ),
                      tooltip: 'Reminder',
                    }),
                    (rowData) => ({
                      icon: () =>
                        /*if appointment has transportation  then show then button */
                        rowData.hasTransportation === true ? (
                          <>
                            <DriveEtaIcon
                              className="transportation"
                              style={{ fontSize: '25px', color: '#E67E22', marginLeft: '0.2em' }}
                            />
                            &nbsp;
                          </>
                        ) : (
                          <div style={{ marginLeft: '1em' }}></div>
                        ),
                      tooltip: 'Transportation',
                    }),
                  ]}
                  onRowClick={(event, rowData) => {
                    this.toAppointmentDetails(event, rowData);
                    event.stopPropagation();
                  }}
                />
              </Col>
            </Row>
          ) : (
            <></>
          )}
          <div style={{ textAlign: 'center', width: '100%', marginTop: '2em', padding: '0em 0em 1em' }}>
            <BackButton
              type="button"
              style={{ backgroundColor: '#162c50', color: '#fff' }}
              variant="contained"
              startIcon={<CloseIcon />}
              onClick={(e) => this.viewCancel()}
            >
              Back
            </BackButton>
          </div>
          <Dialog
            open={this.state.shareDoctorModal}
            onClose={this.handleCloseShareContactModal.bind(this)}
            className="salesDialogue"
          >
            <ValidatorForm style={{ width: '100%' }} ref="form" onSubmit={this.ShareDoctorInfoToBackEnd.bind(this)}>
              <DialogTitle id="scroll-dialog-title" style={{ background: '#1ABC9C', overflowX: 'hidden' }}>
                <span style={{ color: '#fff' }}>Share Contact</span>
              </DialogTitle>
              <DialogContent style={{ textAlign: 'center', padding: '1em', overflow: 'hidden' }}>
                <Row>
                  <Col md={12}>
                    {/* <TextField
                    margin="normal"
                    variant="outlined"
                    type="email"
                    fullWidth
                    id="email"
                    inputprops={{ maxLength: 64 }}
                    value={this.state.shareContactEmail}
                    onChange={this.handleShareContactEmailChange.bind(this)}
                    label="Email"
                    name="email"
                  /> */}
                    <TextValidator
                      fullWidth
                      required
                      margin="normal"
                      variant="outlined"
                      label="Email"
                      type="email"
                      inputProps={{ maxLength: 64 }}
                      value={this.state.shareContactEmail}
                      onChange={this.handleShareContactEmailChange.bind(this)}
                      onBlur={this.props.onBlur}
                      autoComplete="off"
                      validators={['required', 'isEmail']}
                      errorMessages={['this field is required', 'email is not valid']}
                    />
                  </Col>
                </Row>
              </DialogContent>
              <DialogActions>
                <Row style={{ width: '100%', padding: '0em' }}>
                  <Col md={12} style={{ textAlign: 'center' }}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      style={{
                        background: '#ef6603',
                        color: '#fff',
                        marginRight: '1rem',
                      }}
                      startIcon={<ShareIcon />}
                      // onClick={this.ShareDoctorInfoToBackEnd.bind(this)}
                    >
                      Share
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      style={{
                        background: '#717373',
                        color: '#fff',
                        marginRight: '1rem',
                      }}
                      onClick={this.handleCloseShareContactModal.bind(this)}
                    >
                      Close
                    </Button>
                  </Col>
                </Row>
              </DialogActions>
            </ValidatorForm>
          </Dialog>
        </>
      );
    }
  }
}

export default MemberDoctor;
