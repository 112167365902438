import AddIconButton from '../../components/buttons/AddIconButton';
import MaterialTable from '@material-table/core';
import moment from 'moment-timezone';
import FetchServerData from '../../../provider/FetchServerData';
import UiUtils from '../../../provider/UiUtils';
import Utils from '../../../provider/Utils';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import store from '../../../redux/store';
import adminFccPageStateAction from '../../../redux/adminPanel/adminFccPageState/adminFccPageStateAction';
import AdminMenuWithBreadScrum from '../adminMenu/AdminMenuWithBreadScrum';
import applicationStateAction from '../../../redux/applicationState/applicationStateAction';

export class AdminSponsorshipList extends Component {
  header = store.getState().adminHeader.adminHeader;
  sponsorDetails = store.getState().adminSponsorDetails.adminSponsorDetails;
  userDetails = store.getState().adminUserDetails.adminUserDetails;
  sponsorshipState = store.getState().applicationState.sponsorshipState;

  title = 'Sponsorship List';
  breadCrumb = [
    { pageid: '/viewSponsor', uiname: 'Sponsor Home' },
    { pageid: '', uiname: 'Sponsorship List' },
  ];
  sponsorBreadCrumb = [{ pageid: '/sponsorshipList', uiname: 'Sponsorship List' }];
  sponsorshipStateDetails = {};
  constructor(props) {
    super(props);
    let action = props.action;
    let pageId = '';
    if (
      null !== props.history &&
      props.history !== undefined &&
      props.history.location !== null &&
      props.history.location.state !== null &&
      props.history.location.state !== undefined
    ) {
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
    }
    store.dispatch(adminFccPageStateAction.setAdminFccPageState());
    let breadCrumb = [];
    if (this.userDetails.isUserLogin === 'sponsor') {
      breadCrumb = this.sponsorBreadCrumb;
    } else {
      breadCrumb = this.breadCrumb;
    }

    if (this.sponsorshipState) {
      this.sponsorshipStateDetails = Object.getOwnPropertyNames(this.sponsorshipState);
    }
    if (this.sponsorshipStateDetails.length > 0) {
      this.state = {
        action: action,
        pageId: pageId,
        isPaging: false,
        initialPage:
          this.sponsorshipStateDetails.initialPage && this.sponsorshipStateDetails.initialPage > 0
            ? this.sponsorshipStateDetails.initialPage
            : 0,
        sponsorshipList: [],
        pageSize:
          this.sponsorshipStateDetails.pageSize && this.sponsorshipStateDetails.pageSize
            ? this.sponsorshipStateDetails.pageSize
            : 10,
        breadCrumb: breadCrumb,
        sponsorshipId: this.sponsorshipState.sponsorshipId,
        render: false,
        getCreatePermission: false,
      };
    } else {
      this.state = {
        action: action,
        pageId: pageId,
        isPaging: false,
        initialPage: 0,
        sponsorshipList: [],
        pageSize: 10,
        breadCrumb: breadCrumb,
        render: false,
        getCreatePermission: false,
      };
    }
    this.getSponsorshipList();
  }

  getSponsorshipList() {
    const postObject = {
      header: this.header,
      sponsor: { id: this.sponsorDetails.id },
    };

    FetchServerData.callPostService('/sponsorshipms/getList', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let sponsorshipList = output.data;
        let getPermission = Utils.getRolePermission(['*']);
        let getCreatePermission = getPermission[1];
        for (let i = 0; i < sponsorshipList.length; i++) {
          sponsorshipList[i].startDate = moment(sponsorshipList[i].startDate).format('DD MMM YYYY ');
          sponsorshipList[i].endDate = moment(sponsorshipList[i].endDate).format('DD MMM YYYY ');
          sponsorshipList[i].starSortDate = sponsorshipList[i].startDate;
          sponsorshipList[i].endSortDate = sponsorshipList[i].endDate;
        }
        let isPaging = false;
        if (sponsorshipList.length > 10) {
          isPaging = true;
        }
        this.setState({
          isPaging: isPaging,
          sponsorshipList: sponsorshipList,
          render: true,
          initialPage: this.state.initialPage,
          pageSize: this.state.pageSize,
          getCreatePermission: getCreatePermission,
        });
      } else {
      }
    });
  }

  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }

  addSponsorship(e) {
    let adminFccPageState = {
      sponsorshipId: '',
      action: 'create',
      title: 'Add Sponsorship',
      pageId: 'Sponsorship',
    };
    store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));

    this.props.history.push({
      pathname: '/addSponsorship',

      state: { action: 'create', title: 'Add Sponsor', sponsorshipId: '' },
    });
  }

  toSponsorDetails(e, data) {
    let sponsorshipState = {
      sponsorshipId: data.id,
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
    };
    store.dispatch(applicationStateAction.setApplicationState({ sponsorshipState }));
    let adminFccPageState = {
      id: data.id,
      action: 'view',
      title: 'Sponsorship Details',
    };
    store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
    this.props.history.push({
      pathname: '/viewSponsorship',
      state: {
        action: 'view',
        title: 'Sponsorship Details',
        sponsorshipId: data.id,
      },
    });
  }
  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          {/* <Header /> */}
          {/* Page content */}
          <AdminMenuWithBreadScrum breadCrumb={this.state.breadCrumb} history={this.props.history} name={this.title} />

          <Row style={{ position: 'relative', margin: '1em 0em' }}>
            <Col md={12}>
              <MaterialTable
                onRowClick={(event, rowData) => {
                  this.toSponsorDetails(event, rowData);
                  event.stopPropagation();
                }}
                icons={UiUtils.getMaterialTableIcons()}
                title=""
                localization={{
                  body: {
                    emptyDataSourceMessage: Utils.communizeTableText(),
                  },
                }}
                columns={[
                  {
                    title: '#',
                    render: (rowData) => rowData.tableData.index + 1,
                    width: '5%',
                    cellStyle: {
                      width: '6%',
                    },
                    align: 'left',
                  },
                  {
                    title: 'Plan Name',
                    field: 'name',
                    filtering: false,
                  },
                  {
                    title: 'Start Date',
                    type: 'date',
                    field: 'startDate',
                    filtering: true,
                    customSort: (a, b) => {
                      let aTime = a.starSortDate !== null ? new Date(a.starSortDate).getTime() : '';
                      let bTime = b.starSortDate !== null ? new Date(b.starSortDate).getTime() : '';
                      return aTime - bTime;
                    },
                    // render: (rowData) => <>{Utils.convertUTCDateToUserTimeZoneDate(rowData.date)}</>,

                    align: 'left',
                  },
                  {
                    title: 'End Date',
                    type: 'date',
                    field: 'endDate',
                    filtering: true,
                    customSort: (a, b) => {
                      let aTime = a.endSortDate !== null ? new Date(a.endSortDate).getTime() : '';
                      let bTime = b.endSortDate !== null ? new Date(b.endSortDate).getTime() : '';
                      return aTime - bTime;
                    },
                    // render: (rowData) => <>{Utils.convertUTCDateToUserTimeZoneDate(rowData.date)}</>,

                    align: 'left',
                  },
                  {
                    title: 'Plan Type',
                    field: 'type',
                    filtering: false,
                  },
                  {
                    title: 'Sponsored Users',
                    field: 'noOfSubscriptions',
                    filtering: false,
                  },
                  {
                    title: 'Status',
                    field: 'status',
                    filtering: false,
                  },
                ]}
                data={this.state.sponsorshipList}
                options={{
                  sorting: true,
                  actionsColumnIndex: -1,
                  initialPage: this.state.initialPage,
                  pageSizeOptions: [5, 10, 20, 50, 100],
                  pageSize: this.state.pageSize,
                  paging: this.state.sponsorshipList.length > this.state.pageSize,
                  headerStyle: {
                    backgroundColor: '#066f72',
                    fontWeight: 'bold',
                    color: '#fff',
                    align: 'left',
                  },
                  search: false,
                  filtering: true,
                  // paging: this.state.isPaging,
                  rowStyle: (rowData) => ({
                    backgroundColor: rowData.id === this.state.sponsorshipId ? '#FEF5E7' : '#fff',
                    height: '3em',
                    textTransform: 'capitalize',
                  }),
                }}
                actions={[
                  {
                    icon: () => (
                      <span className="newDoctor">
                        {' '}
                        {this.state.getCreatePermission && <AddIconButton btnName="Add Sponsorship" />}
                      </span>
                    ),
                    tooltip: 'Add Sponsor',
                    isFreeAction: true,
                    onClick: (event) => this.addSponsorship(),
                  },
                ]}
                onPageChange={(page, pageSize) => {
                  this.handleMaterialTablePageChange(page, pageSize);
                }}
              />
            </Col>
          </Row>
        </>
      );
    }
  }
}
export default AdminSponsorshipList;
