import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { Col, DropdownToggle, Row, UncontrolledDropdown, DropdownMenu, DropdownItem } from 'reactstrap';
import FetchServerData from '../provider/FetchServerData';
import { Header, PageAlert, PageContent } from '../pts';
import Footer1 from '../pts/components/footer/Footer1';
import PtsModal from '../pts/components/modal/PtsModal';
import Page1 from '../pts/components/page/Page1';
import ContextProviders from '../pts/components/utilities/ContextProviders';
import handleKeyAccessibility, { handleClickAccessibility } from '../pts/helpers/handleTabAccessibility';
import applicationStateAction from '../redux/applicationState/applicationStateAction';
import appUserAction from '../redux/appUser/appUserAction';
import consolidatedMemberAction from '../redux/consolidatedMemberDetails/consolidatedMemberAction';
import fccPageStateAction from '../redux/fccPageState/fccPageStateAction';
import helpDataAction from '../redux/help/displayHelpAction';
import memberDataAction from '../redux/memberData/memberDataAction';
import store from '../redux/store';
import routes from '../views';
import './ApplicationLayout.scss';
import memberListAction from '../redux/memberList/memberListAction';
import PremiumFeatureAction from '../redux/premiumFeatureDetails/PremiumFeatureAction';
import ActiveMenuAction from '../redux/activeMenu/ActiveMenuAction';
const MOBILE_SIZE = 992;

export class ApplicationLayout extends Component {
  header = store.getState().header.header;
  loginUserName = store.getState().userData.userData.firstName + ' ' + store.getState().userData.userData.lastName;
  constructor(props) {
    super(props);

    this.state = {
      sidebarCollapsed: false,
      isMobile: window.innerWidth <= MOBILE_SIZE,
      showChat1: true,
      user: this.header.email,
      helpModal: false,
      faqModal: false,
    };
  }

  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ sidebarCollapsed: false, isMobile: true });
    } else {
      this.setState({ isMobile: false });
    }
  };

  componentDidUpdate(prev) {
    if (this.state.isMobile && prev.location.pathname !== this.props.location.pathname) {
      this.toggleSideCollapse();
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    document.addEventListener('keydown', handleKeyAccessibility);
    document.addEventListener('click', handleClickAccessibility);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  toggleSideCollapse = () => {
    this.setState((prevState) => ({ sidebarCollapsed: !prevState.sidebarCollapsed }));
  };

  closeChat = () => {
    this.setState({ showChat1: false });
  };
  termsAndConditionsHandler = (e) => {
    this.props.history.push('/termsconditionhome');
  };

  profileHandler = (e) => {
    this.props.history.push({
      pathname: '/profileHome',
    });
  };
  privacyPolicyHandler = (e) => {
    this.props.history.push({
      pathname: '/privacyPolicy',
    });
  };
  aboutUsHandler = (e) => {
    this.props.history.push({
      pathname: '/aboutUs',
    });
  };
  referFriendHandler = (e) => {
    this.props.history.push({
      pathname: '/referralBonus',
    });
  };
  navigateToLink(linkToNavigate) {
    this.props.history.push(linkToNavigate);
  }

  logoutHandler = (e) => {
    let postObject = {
      header: this.header,
    };
    FetchServerData.callPostService('userms/logout', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        store.dispatch(appUserAction.logout());
        this.props.history.push('/login');
        let displayFloat = true;

        store.dispatch(helpDataAction.setHelpData(displayFloat));
        let calendarState = {};
        store.dispatch(
          applicationStateAction.setApplicationState({
            calendarState,
          })
        );
        store.dispatch(fccPageStateAction.setFccPageState({}));
      } else {
        store.dispatch(appUserAction.logout());
        store.dispatch(fccPageStateAction.setFccPageState({}));
        store.dispatch(memberDataAction.setMember({}));
        store.dispatch(consolidatedMemberAction.setConsolidatedMember({}));
        store.dispatch(memberListAction.setMemberList([]));
        store.dispatch(PremiumFeatureAction.setPremiumFeature({}));
        let calendarState = {};
        store.dispatch(
          applicationStateAction.setApplicationState({
            calendarState,
          })
        );
        store.dispatch(ActiveMenuAction.setActiveMenu(''));
        this.props.history.push('/login');
      }
    });
  };

  helpModelToggle = () => {
    this.setState({
      helpModal: !this.state.helpModal,
    });
  };

  helpModalOpen = () => {
    this.setState({
      helpModal: true,
    });
  };
  faqModalOpen = () => {
    this.props.history.push('/faqHome');
    // this.setState({
    //   faqModal: true,
    // });
  };

  faqModalToggle = () => {
    this.setState({
      faqModal: !this.state.faqModal,
    });
  };
  render() {
    const { sidebarCollapsed } = this.state;
    const sidebarCollapsedClass = sidebarCollapsed ? 'side-menu-collapsed' : '';
    return (
      <>
        <ContextProviders>
          <div className={`app ${sidebarCollapsedClass}`}>
            <PageAlert />
            <div className="app-body">
              <Header
                toggleSidebar={this.toggleSideCollapse}
                isSidebarCollapsed={sidebarCollapsed}
                routes={routes}
                {...this.props}
              >
                <React.Fragment>
                  <UncontrolledDropdown nav inNavbar>
                    <DropdownToggle nav>
                      <span style={{ color: '#fff' }}>
                        {this.loginUserName}&nbsp;
                        <i className="fa fa-caret-down" style={{ color: '#fff', fontSize: '16px' }} />
                      </span>
                    </DropdownToggle>
                    <DropdownMenu right>
                      <DropdownItem onClick={(e) => this.profileHandler(e)}>
                        <Row>
                          <Col md={3}>
                            <i className="fa fa-user-circle-o" id="menuIconStyle" style={{ color: '#ee7061' }} />
                          </Col>
                          <Col md={9}>
                            <span id="menuNameStyle">Profile</span>
                          </Col>
                        </Row>
                      </DropdownItem>
                      <hr style={{ margin: '0em' }} />
                      <DropdownItem onClick={(e) => this.termsAndConditionsHandler(e)}>
                        <Row>
                          <Col md={3}>
                            <i class="fa fa-gavel" aria-hidden="true" style={{ color: '#569e34' }} />
                          </Col>
                          <Col md={9}>
                            <span id="menuNameStyle">T&C</span>
                          </Col>
                        </Row>
                      </DropdownItem>
                      <hr style={{ margin: '0em' }} />
                      <DropdownItem onClick={this.privacyPolicyHandler}>
                        <Row>
                          <Col md={3}>
                            <i className="fa fa-user" id="menuIconStyle" style={{ color: '#4f5d7c' }} />
                          </Col>
                          <Col md={9}>
                            <span id="menuNameStyle">Privacy Policy</span>
                          </Col>
                        </Row>
                      </DropdownItem>
                      <hr style={{ margin: '0em' }} />
                      <DropdownItem onClick={this.helpModalOpen}>
                        <Row>
                          <Col md={3}>
                            <i className="fa fa-info-circle" id="menuIconStyle" style={{ color: '#4f5d7c' }} />
                          </Col>
                          <Col md={9}>
                            <span id="menuNameStyle">Help</span>
                          </Col>
                        </Row>
                      </DropdownItem>

                      <hr style={{ margin: '0em' }} />
                      <DropdownItem onClick={this.faqModalOpen}>
                        <Row>
                          <Col md={3}>
                            <i class="fa fa-question-circle" id="menuIconStyle" style={{ color: '#2aa6c2' }} />
                          </Col>
                          <Col md={9}>
                            <span id="menuNameStyle">FAQ</span>
                          </Col>
                        </Row>
                      </DropdownItem>
                      <hr style={{ margin: '0em' }} />
                      <DropdownItem onClick={(e) => this.referFriendHandler(e)}>
                        <Row>
                          <Col md={3}>
                            <i class="fa fa-user-plus" id="menuIconStyle" style={{ color: '#854ed1' }} />
                          </Col>
                          <Col md={9}>
                            <span id="menuNameStyle">Refer A Friend</span>
                          </Col>
                        </Row>
                      </DropdownItem>
                      <hr style={{ margin: '0em' }} />
                      <DropdownItem onClick={this.aboutUsHandler}>
                        <Row>
                          <Col md={3}>
                            <i className="fa fa-info-circle" id="menuIconStyle" style={{ color: '#4f5d7c' }} />
                          </Col>
                          <Col md={9}>
                            <span id="menuNameStyle">About Us</span>
                          </Col>
                        </Row>
                      </DropdownItem>
                      <hr style={{ margin: '0em' }} />
                      <DropdownItem onClick={(e) => this.logoutHandler(e)}>
                        <Row>
                          <Col md={3}>
                            <i className="fa fa-sign-out" id="menuIconStyle" style={{ color: '#dc4f4e' }} />
                          </Col>
                          <Col md={9}>
                            <span id="menuNameStyle">Logout</span>
                          </Col>
                        </Row>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </React.Fragment>
              </Header>
              <Page1>
                <PageContent style={{ width: '100%', margin: '0', padding: '0' }}>
                  <Switch>
                    {routes.map((page, index) => (
                      <Route path={page.path} component={page.component} key={index} />
                    ))}
                    <Redirect to="/login" />
                  </Switch>
                </PageContent>
              </Page1>
            </div>
            <Footer1>
              <div className="applicationFooter">
                <Row>
                  <Col lg={6}>
                    <span>
                      <ul style={{ listStyle: 'none', display: 'inline-flex', margin: 'auto' }}>
                        <li style={{ textAlign: 'left' }}>
                          <a
                            onClick={() => this.navigateToLink('/termsCondition')}
                            style={{ color: '#fff', cursor: 'pointer' }}
                          >
                            {' '}
                            Terms &amp; Conditions
                          </a>
                        </li>
                        &nbsp;
                        <li style={{ textAlign: 'left' }}>
                          <a
                            onClick={() => this.navigateToLink('/privacyPolicy')}
                            style={{ color: '#fff', cursor: 'pointer' }}
                          >
                            {' '}
                            | &nbsp; Privacy Policy
                          </a>
                        </li>
                        &nbsp;
                        <li style={{ textAlign: 'left' }}>
                          <a
                            onClick={() => this.navigateToLink('/aboutUs')}
                            style={{ color: '#fff', cursor: 'pointer' }}
                          >
                            {' '}
                            |&nbsp; About Us
                          </a>
                        </li>
                      </ul>
                    </span>
                  </Col>
                  <Col lg={6}>
                    <span>Copyright © 2021. All rights reserved.</span>
                  </Col>
                </Row>
              </div>
            </Footer1>
          </div>
        </ContextProviders>

        <PtsModal open={this.state.helpModal} onClose={this.helpModelToggle} title="Family Care Circle Document">
          {/* <iframe
            src={'https://www.familycarecircle.com/fccdocs/FccUsages.pdf'}
            style={{ width: '100%', height: '600px' }}
            frameborder="0"
            title="indpdf"
          ></iframe> */}

          <embed
            src="https://www.familycarecircle.com/fccdocs/FccUsages.pdf#toolbar=0&navpanes=0&scrollbar=0"
            style={{ width: '100%', height: '600px' }}
          />
        </PtsModal>
        <PtsModal open={this.state.faqModal} onClose={this.faqModalToggle} title="Family Care Circle Document">
          {/* <iframe
            src={'https://www.familycarecircle.com/fccdocs/FccFAQ.pdf'}
            style={{ width: '100%', height: '600px' }}
            frameborder="0"
            title="indpdf"
          ></iframe> */}
          <embed
            src="https://www.familycarecircle.com/fccdocs/FccFAQ.pdf#toolbar=0&navpanes=0&scrollbar=0"
            style={{ width: '100%', height: '600px' }}
          />
        </PtsModal>
      </>
    );
  }
}
export default withRouter(ApplicationLayout);
