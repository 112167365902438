import { CLEAR_USER_DATA, GET_USER_DATA, SET_USER_DATA } from './appUserDataTypes';

const getUser = () => {
  return {
    type: GET_USER_DATA,
  };
};

const setUser = (data) => {
  return {
    type: SET_USER_DATA,
    payload: data,
  };
};

const clearUser = (data) => {
  return {
    type: CLEAR_USER_DATA,
    payload: data,
  };
};
const appUserDataAction = { getUser, setUser, clearUser };
export default appUserDataAction;
