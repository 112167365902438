import { GET_STATIC_DATA, SET_STATIC_DATA } from './staticDataTypes';

const initialState = {
  countryList: [],
  relationRoleList: [],
  relationTypeList: [],
  relationWithMemberList: [],
  taskTypeList: [],
  taskOccuranceList: [],
  reminderIntervalList: [],
  facilityTypeList: [],
  eventTypeListArray: [],
  eventOccuranceList: [],
  specializationList: [],
  accompanyTypeList: [],
  incidentTypeList: [],
  incidentOccuranceList: [],
  medicineTypeList: [],
  medicineStatusList: [],
};

const staticDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STATIC_DATA:
      return {
        ...state,
      };
    case SET_STATIC_DATA:
      return {
        ...state,
        countryList: action.payload.countryList,
        relationRoleList: action.payload.relationRoleList,
        relationTypeList: action.payload.relationTypeList,
        relationWithMemberList: action.payload.relationWithMemberList,
        contactTypeList: action.payload.contactTypeList,
        appointmentFrequencyList: action.payload.appointmentFrequencyList,
        appointmentTypeList: action.payload.appointmentTypeList,
        appointmentPurposeList: action.payload.appointmentPurposeList,
        taskTypeList: action.payload.taskTypeList,
        taskOccuranceList: action.payload.taskOccuranceList,
        reminderIntervalList: action.payload.intervalList,
        facilityTypeList: action.payload.facilityTypeList,
        eventTypeListArray: action.payload.eventTypeList,
        eventOccuranceList: action.payload.eventOccuranceList,
        specializationList: action.payload.specializationList,
        accompanyTypeList: action.payload.accompanyTypeList,
        incidentTypeList: action.payload.incidentTypeList,
        incidentOccuranceList: action.payload.incidentOccuranceList,
        profileList: action.payload.profileList,
        purchaseTypeList: action.payload.purchaseTypeList,
        purchaseFrequencyList: action.payload.purchaseFrequencyList,
        purchaseOccuranceList: action.payload.purchaseOccuranceList,
        roleList: action.payload.roleList,
        roleAccessList: action.payload.roleAccessList,
        yearList: action.payload.yearList,
        medicineTypeList: action.payload.medicineTypeList,
        medicineStatusList: action.payload.medicineStatusList,
      };

    default:
      return state;
  }
};

export default staticDataReducer;
