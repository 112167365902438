import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';
import PtsAlert from '../../../components/ptsAlert/PtsAlert';

import FetchServerData from '../../../../provider/FetchServerData';

import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import store from '../../../../redux/store';
import background from '../../../../assets/images/AdTemplate1/background.jpg';
import hotel from '../../../../assets/images/AdTemplate1/hotel-01.jpg';
import nameImage from '../../../../assets/images/AdTemplate1/name-01.jpg';
import '../AdTemplate1/Template1.scss';
class Template1 extends Component {
  // sponsorUser = store.getState().Sponsorship.Sponsorship;
  header = store.getState().adminHeader.adminHeader;
  // advertisementObject = {
  //   heading: "Up To",
  //   subHeading: "40%",
  //   message: "Offer end after 7 days",
  //   website: "",
  // };
  constructor(props) {
    // let pageId = props.pageId;
    let action = props.action;
    let advertisementDetails = props.data;

    super(props);
    this.state = {
      action: action,
      // sponsorUser: this.sponsorUser,
      // pageId: pageId,
      // heading: props.data?props.data.heading:'',
      // subHeading:props.data?props.data.subHeading:'',
      // message:props.data?props.data.message:'',
      website: props.data ? props.data.website : '',
      advertisementId: this.props.advertisementId,
      heading: advertisementDetails ? advertisementDetails.heading : '',
      subHeading: advertisementDetails ? advertisementDetails.subheading : '',
      message: advertisementDetails ? advertisementDetails.message : '',
      website: advertisementDetails ? advertisementDetails.website : '',
      onChange: this.props.onChange,
      imagePosition: '',
      leftImageFile: this.props.data ? this.props.data.leftimage : '',
      rightImageFile: this.props.data ? this.props.data.rightimage : '',
      showRemoveButton: false,
      createHeading: '',
      createSubHeading: '',
      createMessage: '',
      createWebsite: '',
      backgroundImageFile: this.props.data ? this.props.data.backgroundimage : '',
    };
  }

  handleHeadingChange(e) {
    this.setState({ heading: e.target.value });
  }
  handleSubHeadingChange(e) {
    this.setState({ subHeading: e.target.value });
  }
  handleWebsiteChange(e) {
    this.setState({ website: e.target.value });
  }
  handleMessageChange(e) {
    this.setState({ message: e.target.value });
  }

  handleCreateMessageChange(e) {
    this.setState({ createMessage: e.target.value });
  }

  handleCreateSubHeadingChange(e) {
    this.setState({ createSubHeading: e.target.value });
  }

  handleCreateHeadingChange(e) {
    this.setState({ createHeading: e.target.value });
  }
  handleCreatWebsiteChange(e) {
    this.setState({ createWebsite: e.target.value });
  }
  backgroundImageFileHandler(position, e) {
    var file = e.target.files[0];
    let reader = new FileReader();
    this.setState({
      imagePosition: position.toString(),
      selectedFile: e.target.files[0],
    });
    reader.onload = (e) => {
      let image = e.target.result;
      let img = image.split(',');
      this.setImgae = img[1];

      const formData = new FormData();

      // Update the formData object
      formData.append('file', this.state.selectedFile, this.state.selectedFile.name);
      formData.append('sessionid', this.header.sessionid);
      formData.append('sid', this.header.sessionid);
      formData.append('imageposition', position);
      formData.append('uid', this.header.uid);
      formData.append('adid', this.props.advertisementId);
      formData.append('appclient', 'W');

      FetchServerData.callPostService('/sponsorAdvertisementms/uploadImage', formData).then((output) => {
        if (output.status === 'SUCCESS') {
          // let imageDetails = output.data;
          // this.setState({
          //   action: "view",
          // });
          PtsAlert.success(output.message);
        } else {
        }
      });
      this.setState({ backgroundImageFile: this.setImgae });
    };
    reader.readAsDataURL(file);
  }
  editBackgroundImageFileHandler(position, e) {
    var file = e.target.files[0];
    let reader = new FileReader();
    this.setState({
      imagePosition: position.toString(),
      selectedFile: e.target.files[0],
    });
    reader.onload = (e) => {
      let image = e.target.result;
      let img = image.split(',');
      this.setImgae = img[1];

      const formData = new FormData();

      // Update the formData object
      formData.append('file', this.state.selectedFile, this.state.selectedFile.name);
      formData.append('sessionid', this.header.sessionid);
      formData.append('sid', this.header.sessionid);
      formData.append('imageposition', position);
      formData.append('uid', this.header.uid);
      formData.append('adid', this.props.advertisementId);
      formData.append('appclient', 'W');

      FetchServerData.callPostService('/sponsorAdvertisementms/uploadImage', formData).then((output) => {
        if (output.status === 'SUCCESS') {
          // let imageDetails = output.data;
          this.setState({
            action: 'edit',
          });
          PtsAlert.success(output.message);
        } else {
        }
      });
      this.setState({ backgroundImageFile: this.setImgae });
    };
    reader.readAsDataURL(file);
  }

  removeBackgroundImage(position, e) {
    const postObject = {
      header: this.header,
      advertisement: {
        id: this.state.advertisementId,
      },
      position: position,
    };

    FetchServerData.callPostService('/sponsorAdvertisementms/deleteImage', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        this.setState({ backgroundImageFile: '' });
      } else {
      }
    });
  }

  leftImageFileHandler(position, e) {
    var file = e.target.files[0];
    let reader = new FileReader();
    this.setState({
      imagePosition: position.toString(),
      selectedFile: e.target.files[0],
    });
    reader.onload = (e) => {
      let image = e.target.result;
      let img = image.split(',');
      this.setImgae = img[1];

      const formData = new FormData();

      // Update the formData object
      formData.append('file', this.state.selectedFile, this.state.selectedFile.name);
      formData.append('sessionid', this.header.sessionid);
      formData.append('sid', this.header.sessionid);
      formData.append('imageposition', position);
      formData.append('uid', this.header.uid);
      formData.append('adid', this.props.advertisementId);
      formData.append('appclient', 'W');
      // const postObject = {

      //   uid: this.header.userid,
      //   adid:this.props.advertisementId ,
      //   sid:this.header.sessionid,
      //   appclient:'W',
      //   imageid:position,
      //   uploadfile:img[1]

      // };

      FetchServerData.callPostService('/sponsorAdvertisementms/uploadImage', formData).then((output) => {
        if (output.status === 'SUCCESS') {
          // let imageDetails = output.data;
          // if (
          //   this.state.rightImageFile !== "" &&
          //   this.state.rightImageFile !== null
          // ) {
          //   this.setState({
          //     action: "viewTemplate",
          //   });
          // } else {
          // this.setState({
          //   action: "view",
          // });
          // }
          PtsAlert.success(output.message);
        } else {
        }
      });
      this.setState({ leftImageFile: this.setImgae });
    };
    reader.readAsDataURL(file);

    // if (this.state.action === 'edit') {
    // this.updateProfileImageInBackend(position);
    // }
  }

  rightImageFileHandler(position, e) {
    var file = e.target.files[0];
    let reader = new FileReader();
    this.setState({
      imagePosition: position.toString(),
      selectedFile: e.target.files[0],
    });
    reader.onload = (e) => {
      let image = e.target.result;
      let img = image.split(',');
      this.setImgae = img[1];

      const formData = new FormData();

      // Update the formData object
      formData.append('file', this.state.selectedFile, this.state.selectedFile.name);
      formData.append('sessionid', this.header.sessionid);
      formData.append('sid', this.header.sessionid);
      formData.append('imageposition', position);
      formData.append('uid', this.header.uid);
      formData.append('adid', this.props.advertisementId);
      formData.append('appclient', 'W');
      // const postObject = {

      //   uid: this.header.userid,
      //   adid:this.props.advertisementId ,
      //   sid:this.header.sessionid,
      //   appclient:'W',
      //   imageid:position,
      //   uploadfile:img[1]

      // };

      FetchServerData.callPostService('/sponsorAdvertisementms/uploadImage', formData).then((output) => {
        if (output.status === 'SUCCESS') {
          // let imageDetails = output.data;
          // if (
          //   this.state.leftImageFile !== "" &&
          //   this.state.leftImageFile !== null
          // ) {
          //   this.setState({
          //     action: "viewTemplate",
          //   });
          // } else {
          // this.setState({
          //   action: "view",
          // });
          // }
          PtsAlert.success(output.message);
        } else {
        }
      });
      this.setState({ rightImageFile: this.setImgae });
    };
    reader.readAsDataURL(file);
  }

  removeLeftImageLogo(position, e) {
    const postObject = {
      header: this.header,
      advertisement: {
        id: this.state.advertisementId,
      },
      position: position,
    };

    FetchServerData.callPostService('/sponsorAdvertisementms/deleteImage', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        this.setState({ leftImageFile: '' });
      } else {
      }
    });

    // if (this.state.action === 'edit') {
    //   this.updateProfileImageInBackend();
    // }
  }

  removeRightImageLogo(position, e) {
    const postObject = {
      header: this.header,
      advertisement: {
        id: this.state.advertisementId,
      },
      position: position,
    };

    FetchServerData.callPostService('/sponsorAdvertisementms/deleteImage', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        this.setState({ rightImageFile: '' });
      } else {
      }
    });
  }
  render() {
    if (this.state.action === 'create') {
      return this.createTemplate();
    } else if (this.state.action === 'edit') {
      return this.editTemplate();
    } else if (this.state.action === 'preView') {
      return this.preViewTemplate();
    } else if (this.state.action === 'view') {
      return this.viewTemplate();
    } else if (this.state.action === 'viewTemplate') {
      return this.viewTemplateWithImage();
    }
  }

  createTemplate() {
    return (
      <>
        <div
          className="banner-container"
          id="banner-container1"
          style={{
            backgroundImage: `url(${
              // global.config.projectName + "/AdTemplate1/background.jpg"
              background
            })`,
          }}
        >
          <div className="banner">
            <div className="shoe">
              <img
                // src={global.config.projectName + "/hotel-01.jpg"}
                src={hotel}
                alt=""
              />
            </div>
            <div className="content">
              <h4>{this.state.createHeading}</h4>
              <h3>{this.state.createSubHeading}</h3>
              <p>{this.state.createMessage}</p>
              <a href="https://www.clubmahindra.com" className="btn">
                view offer
              </a>
            </div>
            <div className="women">
              <img
                src={nameImage}
                // src={global.config.projectName + "/AdTemplate1/name-01.jpg"}
                alt="name"
              />
            </div>
          </div>
        </div>
        {/* <img src={template1} alt="image not found" style={{width:'761px'}}/> */}
      </>
    );
  }

  preViewTemplate() {
    return (
      <>
        <div
          className="row"
          style={{
            margin: 'auto',
            alignItems: 'center',
            display: 'flex',
            alignContent: 'center',
            textAlign: 'center',

            backgroundImage: `url(${
              // global.config.projectName + "/AdTemplate1/background.jpg"
              background
            })`,
          }}
        >
          <Col md={4}>
            <div className="shoe">
              <img
                src={hotel}
                // src={global.config.projectName + "/AdTemplate1/hotel-01.jpg"}
                alt=""
                style={{ height: '150px', width: '300px' }}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="content">
              <h4>
                <TextField
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  id="heading"
                  style={{ background: '#fff' }}
                  type="text"
                  onChange={this.handleCreateHeadingChange.bind(this)}
                  label="Heading 1"
                  name="heading"
                  inputProps={{
                    maxLength: 128,
                    style: { textTransform: 'none', padding: '0.4em' },
                  }}
                  value={this.state.createHeading}
                />
              </h4>
              <h3>
                {' '}
                <TextField
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  id="heading"
                  style={{ background: '#fff' }}
                  type="text"
                  onChange={this.handleCreateSubHeadingChange.bind(this)}
                  label="Heading 2"
                  name="heading"
                  inputProps={{
                    maxLength: 128,
                    style: { textTransform: 'none', padding: '0.4em' },
                  }}
                  value={this.state.createSubHeading}
                />
              </h3>
              <p>
                <TextareaAutosize
                  aria-label="minimum height"
                  style={{
                    width: '100%',
                    padding: '10px',
                    height: '68px',
                    textTransform: 'none',
                    fontSize: '16px',
                  }}
                  rowsMin={5}
                  id={'createMessage' + this.state.index}
                  value={this.state.createMessage}
                  name="createMessage"
                  inputProps={{
                    maxLength: 256,
                    padding: '10px',
                    whiteSpace: 'pre-line',
                  }}
                  onChange={this.handleCreateMessageChange.bind(this)}
                  onPressEnter={this.handleCreateMessageChange.bind(this)}
                  placeholder="Message"
                  margin="normal"
                />
              </p>
              {/* <a href="https://www.clubmahindra.com" className="btn">
              view offer
            </a> */}
              <TextField
                margin="normal"
                variant="outlined"
                fullWidth
                id="heading"
                type="text"
                onChange={this.handleCreatWebsiteChange.bind(this)}
                label="Website"
                name="heading"
                style={{ background: '#fff' }}
                inputProps={{
                  maxLength: 128,
                  style: { textTransform: 'none', padding: '0.4em' },
                }}
                value={this.state.createWebsite}
              />
            </div>
          </Col>
          <Col md={4}>
            <div className="women">
              <img
                src={nameImage}
                // src={global.config.projectName + "/AdTemplate1/name-01.jpg"}
                alt="name"
                style={{ height: '150px', width: '300px' }}
              />
            </div>
          </Col>
        </div>
        <div style={{ marginTop: '10px' }}>
          <p> Note: Please upload images on next screen.</p>
          <p style={{ marginTop: '-1.2em', marginLeft: '2.7em' }}> Message can contain 256 characters.</p>
        </div>
        {/* </div>
      </div> */}
      </>
    );
  }

  viewTemplateWithImage() {
    return (
      <>
        <div
          className="row"
          style={{
            margin: 'auto',
            alignItems: 'center',
            display: 'flex',
            alignContent: 'center',
            textAlign: 'center',
            backgroundImage: `url(${`data:image/png;base64,${this.state.backgroundImageFile}`})`,
            minHeight: '160px',
          }}
        >
          <div className="shoe" style={{ width: '30%' }}>
            {this.state.leftImageFile !== '' && this.state.leftImageFile !== null ? (
              <Row style={{ marginBottom: '0.5em' }}>
                <Col md={12} className="customer-img" style={{ textAlign: 'center' }}>
                  <img
                    src={`data:image/jpeg;base64,${this.state.leftImageFile}`}
                    style={{ height: '100px', width: '220px' }}
                    alt=""
                  />
                </Col>
              </Row>
            ) : (
              <Row style={{ marginBottom: '0.5em' }}>
                <Col
                  md={12}
                  style={{
                    textAlign: 'center',
                    height: '100px',
                    width: '220px',
                  }}
                ></Col>
              </Row>
            )}
          </div>
          <div className="content" style={{ width: '40%', textAlign: 'center' }}>
            <h1 style={{ color: '#fff' }}>{this.props.data.heading}</h1>
            <h3 style={{ color: '#fff' }}>{this.props.data.subheading}</h3>
            <p style={{ color: '#f4d03f' }}>{this.props.data.message}</p>
            {this.state.website !== null ? (
              <a
                href={
                  this.state.website.includes('https://') || this.state.website.includes('http://')
                    ? this.state.website
                    : 'http://' + this.state.website
                }
                target="blank"
                rel="noopener noreferrer"
              >
                {this.state.website}
              </a>
            ) : (
              ' '
            )}
          </div>
          <div className="women" style={{ width: '30%' }}>
            {this.state.rightImageFile !== '' && this.state.rightImageFile !== null ? (
              <Row style={{ marginBottom: '0.5em' }}>
                <Col md={12} className="customer-img" style={{ textAlign: 'center' }}>
                  <img
                    src={`data:image/jpeg;base64,${this.state.rightImageFile}`}
                    style={{ height: '100px', width: '220px' }}
                    alt=""
                  />
                </Col>
              </Row>
            ) : (
              <Row style={{ marginBottom: '0.5em' }}>
                <Col
                  md={12}
                  style={{
                    textAlign: 'center',
                    height: '100px',
                    width: '220px',
                  }}
                ></Col>
              </Row>
            )}
          </div>
          {/* </div> */}
        </div>
        {/* <p style={{ float: "right" }}>Note:Image size should be 760*100 pixelsl</p> */}
      </>
    );
  }

  viewTemplate() {
    return (
      <>
        <div
          className="row"
          style={{
            margin: 'auto',
            alignItems: 'center',
            display: 'flex',
            alignContent: 'center',
            textAlign: 'center',
            backgroundImage: `url(${`data:image/png;base64,${this.state.backgroundImageFile}`})`,
            minHeight: '160px',
          }}
        >
          <div className="shoe" style={{ width: '30%' }}>
            {this.state.leftImageFile !== null &&
            this.state.leftImageFile !== '' &&
            this.state.leftImageFile !== null ? (
              <Row style={{ marginBottom: '0.5em' }}>
                <Col md={10} className="customer-img" style={{ textAlign: 'center' }}>
                  <img
                    src={`data:image/jpeg;base64,${this.state.leftImageFile}`}
                    style={{ height: '100px', width: '220px' }}
                    alt=""
                  />
                </Col>
                <Col md={2}>
                  <span style={{ float: 'left' }}>
                    <i
                      className="fa fa-times-circle"
                      aria-hidden="true"
                      title="Delete User"
                      onClick={this.removeLeftImageLogo.bind(this, 'L')}
                    ></i>
                  </span>
                </Col>
              </Row>
            ) : (
              <Row style={{ marginBottom: '0.5em' }}>
                <Col
                  md={12}
                  style={{
                    textAlign: 'center',
                    height: '100px',
                    width: '220px',
                    display: 'none',
                  }}
                ></Col>
              </Row>
            )}
            <div className="imgUploadForm" style={{ margin: '1em' }}>
              <div class="upload-btn-wrapper" style={{ float: 'right' }}>
                <button className="fileUploadBtn">Upload a Image 1</button>
                <input type="file" name="myfile" id="file-input" onChange={this.leftImageFileHandler.bind(this, 'L')} />
              </div>
            </div>
          </div>
          <div className="content" style={{ width: '40%', textAlign: 'center' }}>
            <Row>
              <Col md={12}>
                {this.state.backgroundImageFile !== null &&
                this.state.backgroundImageFile !== '' &&
                this.state.backgroundImageFile !== null ? (
                  <span style={{ float: 'right' }}>
                    <i
                      className="fa fa-times-circle"
                      aria-hidden="true"
                      title="Delete User"
                      style={{ color: '#000' }}
                      onClick={this.removeBackgroundImage.bind(this, 'BG')}
                    ></i>
                  </span>
                ) : (
                  ''
                )}

                <div className="imgUploadForm">
                  <div class="upload-btn-wrapper" style={{ float: 'right', padding: '0em 1em' }}>
                    <button className="fileUploadBtn">Upload Background Image</button>
                    <input
                      type="file"
                      name="myfile"
                      id="file-input"
                      onChange={this.backgroundImageFileHandler.bind(this, 'BG')}
                    />
                  </div>
                  &nbsp;
                </div>
              </Col>
            </Row>
            <h1>{this.props.data.heading}</h1>
            <h3>{this.props.data.subheading}</h3>
            <p>{this.props.data.message}</p>
            {this.state.website !== null ? (
              <a
                href={
                  this.state.website.includes('https://') || this.state.website.includes('http://')
                    ? this.state.website
                    : 'http://' + this.state.website
                }
                target="blank"
                rel="noopener noreferrer"
              >
                {this.state.website}
              </a>
            ) : (
              ' '
            )}
          </div>
          <div className="women" style={{ width: '30%' }}>
            {this.state.rightImageFile !== null &&
            this.state.rightImageFile !== '' &&
            this.state.rightImageFile !== null ? (
              <Row style={{ marginBottom: '0.5em' }}>
                <Col md={10} className="customer-img" style={{ textAlign: 'center' }}>
                  <img
                    src={`data:image/jpeg;base64,${this.state.rightImageFile}`}
                    style={{ height: '100px', width: '220px' }}
                    alt=""
                  />
                </Col>
                <Col md={2}>
                  <span style={{ float: 'left' }}>
                    <i
                      className="fa fa-times-circle"
                      aria-hidden="true"
                      title="Delete User"
                      onClick={this.removeRightImageLogo.bind(this, 'R')}
                    ></i>
                  </span>
                </Col>
              </Row>
            ) : (
              <Row style={{ marginBottom: '0.5em' }}>
                <Col
                  md={12}
                  style={{
                    textAlign: 'center',
                    height: '100px',
                    width: '220px',
                    display: 'none',
                  }}
                ></Col>
              </Row>
            )}{' '}
            <div className="imgUploadForm" style={{ margin: '1em' }}>
              <div class="upload-btn-wrapper" style={{ float: 'right' }}>
                <button className="fileUploadBtn">Upload a Image 2</button>
                <input
                  type="file"
                  name="myfile"
                  id="file-input"
                  onChange={this.rightImageFileHandler.bind(this, 'R')}
                />
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
        <p style={{ marginTop: '10px' }}>Note: Image size should be 760*100 pixels.</p>
      </>
    );
  }

  editTemplate() {
    return (
      <>
        <div
          className="row"
          style={{
            margin: 'auto',
            alignItems: 'center',
            display: 'flex',
            alignContent: 'center',
            textAlign: 'center',
            backgroundImage: `url(${`data:image/png;base64,${this.state.backgroundImageFile}`})`,
            minHeight: '160px',
          }}
        >
          <div className="shoe" style={{ width: '30%' }}>
            {this.state.leftImageFile !== null &&
            this.state.leftImageFile !== '' &&
            this.state.leftImageFile !== null ? (
              <Row style={{ marginBottom: '0.5em' }}>
                <Col md={10} className="customer-img" style={{ textAlign: 'center' }}>
                  <img
                    src={`data:image/jpeg;base64,${this.state.leftImageFile}`}
                    style={{ height: '100px', width: '220px' }}
                    alt=""
                  />
                </Col>
                <Col md={2}>
                  <span style={{ float: 'left' }}>
                    <i
                      className="fa fa-times-circle"
                      aria-hidden="true"
                      title="Delete User"
                      onClick={this.removeLeftImageLogo.bind(this, 'L')}
                    ></i>
                  </span>
                </Col>
              </Row>
            ) : (
              <Row style={{ marginBottom: '0.5em' }}>
                <Col
                  md={12}
                  style={{
                    textAlign: 'center',
                    height: '100px',
                    width: '220px',
                    display: 'none',
                  }}
                ></Col>
              </Row>
            )}
            <div className="imgUploadForm" style={{ margin: '1em' }}>
              <div class="upload-btn-wrapper" style={{ float: 'right' }}>
                <button className="fileUploadBtn">Upload a Image 1</button>
                <input type="file" name="myfile" id="file-input" onChange={this.leftImageFileHandler.bind(this, 'L')} />
              </div>
            </div>
          </div>
          <div className="content" style={{ width: '40%' }}>
            <Row style={{ marginTop: '1em' }}>
              <Col md={12}>
                <span style={{ float: 'right' }}>
                  <i
                    className="fa fa-times-circle"
                    aria-hidden="true"
                    title="Delete Image"
                    style={{ color: '#000' }}
                    onClick={this.removeBackgroundImage.bind(this, 'BG')}
                  ></i>
                </span>

                <div className="imgUploadForm">
                  <div class="upload-btn-wrapper" style={{ float: 'right', padding: '0em 1em' }}>
                    <button className="fileUploadBtn">Upload Background Image</button>
                    <input
                      type="file"
                      name="myfile"
                      id="file-input"
                      onChange={this.editBackgroundImageFileHandler.bind(this, 'BG')}
                    />
                  </div>
                  &nbsp;
                </div>
              </Col>
            </Row>
            <h4>
              <TextField
                margin="normal"
                variant="outlined"
                fullWidth
                id="heading"
                type="text"
                onChange={this.handleHeadingChange.bind(this)}
                label="Heading 1"
                name="heading"
                inputProps={{
                  maxLength: 128,
                  style: { textTransform: 'none', padding: '0.4em' },
                }}
                style={{ background: '#fff' }}
                value={this.state.heading}
              />
            </h4>
            <h3>
              {' '}
              <TextField
                margin="normal"
                variant="outlined"
                fullWidth
                id="heading"
                style={{ background: '#fff' }}
                type="text"
                onChange={this.handleSubHeadingChange.bind(this)}
                label="Heading 2"
                name="heading"
                inputProps={{
                  maxLength: 128,
                  style: { textTransform: 'none', padding: '0.4em' },
                }}
                value={this.state.subHeading}
              />
            </h3>
            <p>
              {/* <TextField
                margin="normal"
                variant="outlined"
                fullWidth
                id="heading"
                type="text"
                onChange={this.handleMessageChange.bind(this)}
                label="Text"
                name="heading"
                inputProps={{
                  maxLength: 128,
                  style: { textTransform: 'none', padding: '0.4em' },
                }}
                style={{ background: '#fff' }}
                value={this.state.message}
              /> */}

              <TextareaAutosize
                aria-label="minimum height"
                style={{
                  width: '100%',
                  padding: '10px',
                  height: '80px',
                }}
                rowsMin={5}
                id={'message' + this.state.index}
                value={this.state.message}
                name="message"
                inputProps={{
                  maxLength: 256,
                  padding: '10px',
                  whiteSpace: 'pre-line',
                }}
                onChange={this.handleMessageChange.bind(this)}
                onPressEnter={this.handleMessageChange.bind(this)}
                placeholder="Message"
                margin="normal"
              />
            </p>
            {/* <a href="https://www.clubmahindra.com" className="btn">
              view offer
            </a> */}
            <TextField
              margin="normal"
              variant="outlined"
              fullWidth
              id="heading"
              type="text"
              onChange={this.handleWebsiteChange.bind(this)}
              label="Website"
              name="heading"
              style={{ background: '#fff' }}
              inputProps={{
                maxLength: 128,
                style: { textTransform: 'none', padding: '0.4em' },
              }}
              value={this.state.website}
            />
          </div>
          <div className="women" style={{ width: '30%' }}>
            {this.state.rightImageFile !== null &&
            this.state.rightImageFile !== '' &&
            this.state.rightImageFile !== null ? (
              <Row style={{ marginBottom: '0.5em' }}>
                <Col md={10} className="customer-img" style={{ textAlign: 'center' }}>
                  <img
                    src={`data:image/jpeg;base64,${this.state.rightImageFile}`}
                    style={{ height: '100px', width: '220px' }}
                    alt=""
                  />
                </Col>
                <Col md={2}>
                  <span style={{ float: 'left' }}>
                    <i
                      className="fa fa-times-circle"
                      aria-hidden="true"
                      title="Delete User"
                      onClick={this.removeRightImageLogo.bind(this, 'R')}
                    ></i>
                  </span>
                </Col>
              </Row>
            ) : (
              <Row style={{ marginBottom: '0.5em' }}>
                <Col
                  md={12}
                  style={{
                    textAlign: 'center',
                    height: '100px',
                    width: '220px',
                    display: 'none',
                  }}
                ></Col>
              </Row>
            )}{' '}
            <div className="imgUploadForm" style={{ margin: '1em' }}>
              <div class="upload-btn-wrapper" style={{ float: 'right' }}>
                <button className="fileUploadBtn">Upload a Image 2</button>
                <input
                  type="file"
                  name="myfile"
                  id="file-input"
                  onChange={this.rightImageFileHandler.bind(this, 'R')}
                />
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
        <div style={{ marginTop: '10px' }}>
          <p>Note: Image size should be 760*100 pixels.</p>
          <p style={{ marginTop: '-1.2em', marginLeft: '2.7em' }}> Message can contain 256 characters.</p>
        </div>
      </>
    );
  }
}
export default Template1;
