import dayGridPlugin from '@fullcalendar/daygrid';
import '@fullcalendar/daygrid/main.css';
import interactionPlugin from '@fullcalendar/interaction'; // needed for dayClick
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import '@fullcalendar/timegrid/main.css';
import { format } from 'date-fns';
import $ from 'jquery';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import 'react-tabs/style/react-tabs.css';
import { Card, Col, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import store from '../../../redux/store';

import 'intro.js/introjs.css';
import './LoginCount.scss';

import AdminMenuWithBreadScrum from '../adminMenu/AdminMenuWithBreadScrum';
export default class LoginCount extends Component {
  calendarState = store.getState().applicationState.calendarState;

  title = 'Login Statistics';
  breadCrumb = [{ pageid: '', uiname: 'Login Statistics' }];
  //   breadCrumb = [{ pageid: this.memberHomeUrl, uiname: this.memberName }];
  //   memberDetails = store.getState().memberData.memberData;

  header = store.getState().header.header;
  isFreeUser = store.getState().userData.userData.isFreeUser;
  sponsorUser = store.getState().Sponsorship.Sponsorship;
  displayHelp = store.getState().help.help;
  calendarRef = React.createRef();
  doctorListArray = [];
  newTimeZone = '';
  constructor(props) {
    super(props);
    this.memberDetails = store.getState().memberData.memberData;
    let action = props.action;

    /*current URL path for sponsorship template*/
    let currentLocationUrl = window.location.href;
    let pathArray = currentLocationUrl.split('/');
    let host = pathArray[4];
    let currentLocationPath = '/' + host;

    if (props.history && props.history.location && props.history.location.state) {
      action = props.history.location.state.action;
    }

    let calendarState = {};
    if (this.calendarState) {
      calendarState = Object.getOwnPropertyNames(this.calendarState);
    }
    let timeZone = moment.tz.guess();
    if (timeZone === 'Asia/Calcutta') {
      this.newTimeZone = 'Asia/Kolkata';
    } else {
      this.newTimeZone = timeZone;
    }

    this.d = new Date();
    this.selectedMonth = moment(this.d);
    let pickerStartDate = new Date();
    pickerStartDate.setDate(pickerStartDate.getDate() - 30);
    let pickerEndDate = new Date();
    this.state = {
      sponsorUser: this.sponsorUser,
      currentLocationPath: currentLocationPath,
      //   memberId: this.memberDetails.member,
      weekendsVisible: true,
      currentEvents: [],
      calendarWeekends: true,
      calendarItemList: [],
      appointmentList: [],
      calenderAppointmentList: [],
      calenderIncidentList: [],
      calenderEventList: [],
      modal: false,
      event: [],
      note: '',
      time: '',
      isDoctor: false,
      isOther: false,
      location: '',
      description: '',
      preDescription: '',
      appoiPurposeDes: '',
      frequency: '',
      appoiPurpose: '',
      startDate: format(new Date(), 'yyyy-MM-dd'),
      endDate: format(new Date(), 'yyyy-MM-dd'),
      calenderDateView: '',
      appointmentType: '',
      doctor: '',
      action: action,
      isFreeUser: this.isFreeUser,
      appointmentDate: '',
      currentDateTime: new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000),
      userSelectedDate: format(new Date(), 'yyyy-MM-dd'),
      defaultCalenderView: 'month',
      selectedUserMonth: '',
      selectedMonth: this.selectedMonth,
      incidentCompareDate: '',
      currentCompareDate: new Date(),
      calendarItemListData: [],
      stepsEnabled: false,
      initialStep: 0,
      steps: [
        {
          element: '.user-panel',
          intro: 'Member name who’s card you are viewing',
        },
        {
          element: '#sidebarMenu1',
          intro: 'List of Caregivers’ Circle',
        },
        {
          element: '#sidebarMenu2',
          intro: 'List of Communications',
        },
        {
          element: '#sidebarMenu3',
          intro: 'Calendar showing Appointments and Incidents. You can add Appointments and Incidents from here too.',
        },
        {
          element: '#sidebarMenu4',
          intro: 'List of Appointments. You can add Appointments from here too.',
        },
        {
          element: '#sidebarMenu5',
          intro: 'List of Incidents. You can add Incidents from here too.',
        },
        {
          element: '#sidebarMenu6',
          intro: 'List of Doctors. You can add Doctors here.',
        },
        {
          element: '#sidebarMenu7',
          intro: 'List of Facilities. You can add Facilities here.',
        },
        {
          element: '#sidebarMenu8',
          intro: 'List of Purchases. You can add Purchases here.',
        },
        {
          element: '.fc-today-button',
          intro: 'Current day of current Month',
        },
        {
          element: '.fc-prev-button ',
          intro: 'Move to Previous month',
        },

        {
          element: '.fc-next-button',
          intro: 'Move to next month',
        },
        {
          element: '.fc-dayGridMonth-button',
          intro: 'Monthly View',
        },
        {
          element: '.fc-timeGridWeek-button',
          intro: 'Weekly View',
        },

        {
          element: '.fc-timeGridDay-button',
          intro: 'View for the whole Day',
        },
        {
          element: '.fc-daygrid-day-frame ',
          intro: 'Click to add an Appointment or Incident. Incidents can be added for past dates Only.',
        },
      ],
      displayFloatButton: this.displayHelp,
      getAppointmentCreatePermission: false,
      getAppointmentAllPermission: false,
      showIncidentPermission: false,
      getIncidentCreatePermission: false,
      getIncidentAllPermission: false,
      showEventPermission: false,
      getEventCreatePermission: false,
      getEventAllPermission: false,
      pickerStartDate: pickerStartDate,
      pickerEndDate: pickerEndDate,
    };
    let currentMonth = format(new Date(), 'yyyy-MMM-dd');
    let monYrArr = currentMonth.split('-');
    let month = monYrArr[1];
    let year = monYrArr[0];

    this.checkLoginCountInBackend(pickerStartDate, pickerEndDate);
  }

  toggleSteps = () => {
    this.setState((prevState) => ({ stepsEnabled: !prevState.stepsEnabled }));
  };
  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };

  componentDidMount() {
    let startDate = '';
    /* On click previous button,we get previous month and year and backend API call for selected month and events are displayed on calendar */
    document.querySelector('.fc-prev-button').addEventListener('click', (e) => {
      var lastDayOfNextMonth = moment(this.state.selectedMonth.add(-1, 'M')).endOf('month');

      this.d = lastDayOfNextMonth.format('DD/MMM/YYYY');
      let userSelectedLastOfMonth = lastDayOfNextMonth.format('YYYY-MM-DD');

      let userSelectedStartOfMonth = moment(userSelectedLastOfMonth).startOf('month').format('YYYY-MM-DD');

      let monYrArr = this.d.split('/');
      let month = monYrArr[1];
      let year = monYrArr[2];
      month = month.toUpperCase();
      if (this.state.defaultCalenderView === 'month') {
        this.checkLoginCountInBackend(userSelectedStartOfMonth, userSelectedLastOfMonth);
        startDate = this.calendarRef.current.getApi().view.activeStart - 1;
      } else if (this.state.defaultCalenderView === 'day') {
        startDate = this.calendarRef.current.getApi().view.activeStart - 1;
        startDate = moment(startDate).format('DD/MMM/YYYY');
        let monYrArr = startDate.split('/');
        let day = monYrArr[0];
        let month = monYrArr[1];
        let year = monYrArr[2];
        month = month.slice(0, 3);
        month = month.toUpperCase();
        if (day.toString() === '30' || day.toString() === '31') {
          this.checkLoginCountInBackend(userSelectedStartOfMonth, userSelectedLastOfMonth);
        }
      } else {
        startDate = this.calendarRef.current.getApi().view.activeStart - 1;
        let weekEndDate = moment(startDate).format('DD/MMM/YYYY');
        let weekStartDate = moment(new Date(startDate).setDate(new Date(startDate).getDate() - 6)).format(
          'DD/MMM/YYYY'
        );
        let weekStartDateInp = weekStartDate;
        let weekEndDateInp = weekEndDate;
        this.checkLoginCountInBackend(
          moment(weekStartDateInp).format('YYYY-MM-DD'),
          moment(weekEndDateInp).format('YYYY-MM-DD')
        );
      }
    });
    /* On click next button,we get next month and year and backend API call for selected month and events are displayed on calendar */
    document.querySelector('.fc-next-button').addEventListener('click', (e) => {
      var lastDayOfNextMonth = moment(this.state.selectedMonth.add(1, 'M')).endOf('month');
      this.d = lastDayOfNextMonth.format('DD/MMM/YYYY');
      let userSelectedEndOfMonth = lastDayOfNextMonth.format('YYYY-MM-DD');
      // let calendarState = {
      //   calenderNxtPrvMonth: moment(this.d),
      //   selectedDate: this.d,
      //   userSelectedMonth: userSelectedMonth,
      //   src: 'memberDetails',
      // };
      // store.dispatch(
      //   applicationStateAction.setApplicationState({
      //     calendarState,
      //   })
      // );
      let userSelectedStartOfMonth = moment(userSelectedEndOfMonth).startOf('month').format('YYYY-MM-DD');
      let monYrArr = this.d.split('/');
      let month = monYrArr[1];
      let year = monYrArr[2];
      month = month.toUpperCase();
      if (this.state.defaultCalenderView === 'month') {
        this.checkLoginCountInBackend(userSelectedStartOfMonth, userSelectedEndOfMonth);
        startDate = this.calendarRef.current.getApi().view.activeEnd;
      } else if (this.state.defaultCalenderView === 'day') {
        startDate = this.calendarRef.current.getApi().view.activeEnd;
        startDate = moment(startDate).format('DD/MMM/YYYY');
        let monYrArr = startDate.split('/');
        let day = monYrArr[0];
        let month = monYrArr[1];
        let year = monYrArr[2];
        month = month.slice(0, 3);
        month = month.toUpperCase();

        if (day.toString() === '01') {
          this.checkLoginCountInBackend(userSelectedStartOfMonth, userSelectedEndOfMonth);
        }
      } else {
        startDate = this.calendarRef.current.getApi().view.activeEnd;
        let weekStartDate = moment(startDate).format('DD/MMM/YYYY');
        let weekEndDate = moment(startDate.setDate(startDate.getDate() + 6)).format('DD/MMM/YYYY');
        let weekStartDateInp = weekStartDate;
        let weekEndDateInp = weekEndDate;
        this.checkLoginCountInBackend(
          moment(weekStartDateInp).format('YYYY-MM-DD'),
          moment(weekEndDateInp).format('YYYY-MM-DD')
        );
      }
    });
    /* Here we get access of day month button and we perform some operation on it */
    document.querySelector('.fc-dayGridMonth-button').addEventListener('click', (e) => {
      let text = $('.fc-dayGridMonth-button').text();
      this.d = new Date();
      let datGridSetDate = '';
      if (startDate) {
        datGridSetDate = moment(startDate);
      } else {
        datGridSetDate = moment(this.state.selectedMonth);
      }
      let mmmm = moment(datGridSetDate).format('MMM');
      let yyyy = moment(datGridSetDate).format('YYYY');
      let SelectedStartMonthDate = moment(datGridSetDate).startOf('month').format('YYYY-MM-DD');
      let SelectedEndMonthDate = moment(datGridSetDate).endOf('month').format('YYYY-MM-DD');
      this.setState({
        selectedMonth: datGridSetDate,

        defaultCalenderView: text,
        userSelectedDate: format(new Date(), 'yyyy-MM-dd'),
      });

      this.checkLoginCountInBackend(SelectedStartMonthDate, SelectedEndMonthDate);
    });
    /* Here we get access of day view button and we perform some operation on it */
    document.querySelector('.fc-timeGridDay-button').addEventListener('click', (e) => {
      let text = $('.fc-timeGridDay-button').text();

      if (startDate) {
        this.setState({
          selectedMonth: moment(startDate),
        });
      } else {
        let selectedMonth = this.state.selectedMonth;
        selectedMonth = selectedMonth;
        this.setState({
          selectedMonth: selectedMonth,
        });
      }

      this.setState({
        defaultCalenderView: text,
      });
    });
    /* Here we get access of today button and we perform some operation on it */
    document.querySelector('.fc-today-button').addEventListener('click', (e) => {
      this.d = new Date();
      this.setState({
        selectedMonth: moment(this.d),
      });
    });
  }
  /*Get member calender month data */
  checkLoginCountInBackend = (startDate, endDate) => {
    let selectedStartDate = moment(startDate).format('YYYY-MM-DD');
    let selectedEndDate = moment(endDate).format('YYYY-MM-DD');
    const postObject = {
      header: this.header,

      startDate: selectedStartDate,
      endDate: selectedEndDate,
      appuser: null,
    };
    FetchServerData.callPostService('/adminpanelAppuserms/getLoginCount', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let calenderList = [];
          let events = [];
          calenderList = output.data.loginCountList;
          let title = '';
          let color = '';
          let time = '';
          let doctorFacilityLocation = '';
          let action = '';
          let incidentLocation = '';
          let calenderDateTimeArr = '';
          let status = '';
          let eventLocation = '';

          for (let i = 0; i < calenderList.length; i++) {
            // if (calenderList[i].action === 'Appointment') {
            if (calenderList[i].logindate !== null) {
              let dateTime = calenderList[i].logindate;

              dateTime = moment(dateTime).format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
              let utcCutoff = moment(dateTime);
              calenderDateTimeArr = utcCutoff.tz(this.newTimeZone).format('YYYY-MM-DDTHH:mm:ss');
              // calenderDateTimeArr = moment(calenderList[i].logindate).format('YYYY-MM-DDTHH:mm:ss');

              //   Utils.UserTimeZoneToCalenderMonthWeakDateTimeFormate(calenderList[i].logindate);
              title = calenderList[i].count;
            }

            let calendarObj = {
              id: calenderList[i].id,
              title: title,

              start: calenderDateTimeArr,
            };
            events.push(calendarObj);
          }
          this.setState({
            calendarItemList: events,
            calendarItemListData: calenderList,
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  handleDateClick = (arg) => {
    alert(arg.dateStr);
  };

  /* Here we customize the design of event which are displayed on month view,weekly view and day view*/
  renderEventContent(eventInfo) {
    let eventLocation = '';
    let event = '';

    event = (
      <>
        <span
          style={{
            // borderLeft: '2px solid #EC7063',
            background: '#FADBD8',
            width: '100%',
            color: '#000',
            padding: '0.3em',
            // borderRadius: '50%',
            textAlign: 'center',
            fontSize: '17px',
            cursor: 'default',
          }}
        >
          <b>
            <i>{eventInfo.event.title ? 'Login count -' + eventInfo.event.title : ''}</i>
          </b>
        </span>

        <br></br>
      </>
    );

    return event;
  }
  eventRender(info) {
    if (info.action === 'Appointment') {
    } else {
    }
  }

  render() {
    return (
      <>
        <AdminMenuWithBreadScrum breadCrumb={this.breadCrumb} history={this.props.history} name={this.title} />

        <Row style={{ margin: ' 1em -0em' }}>
          <Col md={12}>
            <Card>
              <FullCalendar
                id="adminPanelCalender"
                ref={this.calendarRef}
                plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                headerToolbar={{
                  left: 'prev, today next',
                  center: 'title',
                  right: 'dayGridMonth, timeGridWeek, timeGridDay',
                }}
                initialView="dayGridMonth"
                editable={true}
                selectable={true}
                selectMirror={true}
                dayMaxEvents={true}
                weekends={this.state.weekendsVisible}
                events={this.state.calendarItemList}
                initialDate={this.state.userSelectedDate}
                // select={this.handleDateSelect}
                eventContent={this.renderEventContent} // custom render function
                // eventClick={this.handleEventClickOnDay}
                eventsSet={this.handleEvents}
                eventRender={this.eventRender}
                eventReceive={() => {}}
              />

              {/* create Modal for Appointment */}
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}
