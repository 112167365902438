import { Col, Row } from 'reactstrap';

const AdminFooter = () => {
  return (
    <>
      <footer className="footer">
        <div className="memberLayoutFooter">
          <Row>
            <Col lg={6}>
              <span>
                <ul
                  style={{
                    listStyle: 'none',
                    display: 'inline-flex',
                    margin: 'auto',
                  }}
                >
                  <li style={{ textAlign: 'left' }}>
                    <a style={{ color: '#fff', cursor: 'pointer' }}> Terms &amp; Conditions</a>
                  </li>
                  &nbsp;
                  <li style={{ textAlign: 'left', cursor: 'pointer' }}>
                    <a style={{ color: '#fff', cursor: 'pointer' }}> | &nbsp; Privacy Policy</a>
                  </li>
                </ul>
              </span>
            </Col>
            <Col lg={6}>
              <span>Copyright © 2021. All rights reserved.</span>
            </Col>
          </Row>
        </div>
      </footer>
    </>
  );
};

export default AdminFooter;
