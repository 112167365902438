import React, { useState } from 'react';

const Marker = ({ text, tooltip, $hover, shortDescription, line, city, country, state, activeUsers }) => {
  const [isActive, setActive] = useState(false);

  const handleClick = () => {};
  const K_SIZE = 40;
  const toggleClass = () => {
    setActive(!isActive);
  };
  const showToggleClass = () => {
    setActive(!isActive);
  };
  return (
    <>
      <div className={$hover ? 'circle hover' : 'circle'} onClick={handleClick}>
        <span className="circleText" title={tooltip}>
          <div className="pin" onClick={showToggleClass}></div>
          <div className="pulse"></div>
          <div>
            <div className={isActive ? 'hidedialogBox' : 'dialogbox'} onClick={toggleClass}>
              <div className="body" style={{ borderRadius: '15px', minWidth: '17em' }}>
                <span className="tip tip-left"></span>
                <div className="message">
                  {shortDescription && (
                    <>
                      <span style={{ fontWeight: 'bold' }}>{shortDescription}</span>
                      <br></br>
                    </>
                  )}
                  {line && (
                    <>
                      <span style={{ fontWeight: 'bold' }}>{line}</span>
                      <br></br>
                    </>
                  )}
                  {city && (
                    <>
                      <span style={{ fontWeight: 'bold' }}>{city}</span>
                      <br></br>
                    </>
                  )}
                  {state && <span style={{ fontWeight: 'bold' }}>{state}</span>}&nbsp;
                  {country && <span style={{ fontWeight: 'bold' }}>{country}</span>}
                </div>
              </div>
            </div>
          </div>
        </span>
      </div>
    </>
  );
};

export default Marker;
