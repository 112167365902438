import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { createMuiTheme } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Tooltip from '@material-ui/core/Tooltip';
import PrintRoundedIcon from '@material-ui/icons/PrintRounded';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ToggleButton from '@material-ui/lab/ToggleButton';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import 'intro.js/introjs.css';
import React, { Component } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import 'react-tabs/style/react-tabs.css';
import ReactToPrint from 'react-to-print';
import { Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import fccPageStateAction from '../../../redux/fccPageState/fccPageStateAction';
import store from '../../../redux/store';
import CancelIconButton from '../buttons/CancelIconButton';
import CreateButton from '../buttons/CreateButton';
import SaveButton from '../buttons/SaveButton';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
import PtsAlert from '../ptsAlert/PtsAlert';
import PtsModal from '../modal/PtsModal';
import PremiumFeatures from '../premiumFeatures/PremiumFeatures';
import Button from '@material-ui/core/Button';
export class PrescriptionsImport extends Component {
  title = 'Prescription Details';
  memberDetails = store.getState().memberData.memberData;
  memberName = store.getState().memberData.memberData.firstname + ' ' + store.getState().memberData.memberData.lastname;
  header = store.getState().header.header;
  fccPageState = store.getState().fccPageState.fccPageState;
  memberHomeUrl = store.getState().memberHomeUrl.memberHomeUrl.homeUrl;
  medicineTypeList = store.getState().staticData.medicineTypeList;
  medicineStatusList = store.getState().staticData.medicineStatusList;
  premiumFeatures = store.getState().userData.premiumFeatures;
  isFreeUser = store.getState().userData.userData.isFreeUser;
  breadCrumb = [
    { pageid: this.memberHomeUrl, uiname: this.memberName },
    { pageid: '/import', uiname: 'Import List' },
    { pageid: '', uiname: 'Prescription Details' },
  ];

  constructor(props) {
    super(props);
    let medicineTypeListArray = this.medicineTypeList.filter((item) => item.type !== 'All');
    let prescriptionId = props.prescriptionId;
    let action = props.action;
    let title = '';
    let pageId = '';
    let prescriptionDetails = {};
    let initialPage = 0;
    let pageSize = 10;
    if (!prescriptionId && props.history && props.history.location && props.history.location.state) {
      prescriptionId = props.history.location.state.prescriptionId;
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
      title = props.history.location.state.title;
      prescriptionDetails = props.history.location.state.prescriptionDetails;
    }
    let canRender = false;
    if (null !== prescriptionId && prescriptionId > 0) {
      this.getPrescriptionsDetails(prescriptionId);
    } else {
      canRender = true;
    }

    if (prescriptionId === undefined) {
      prescriptionId = this.fccPageState.id;
      action = this.fccPageState.action;
      title = 'prescription Details';
      pageId = 'prescriptions';
      if (action !== 'create') {
        this.getPrescriptionsDetails(prescriptionId);
      }
    }
    if (this.premiumFeatures !== null && this.premiumFeatures.status !== null) {
      this.premiumFeaturesStatus = this.premiumFeatures.status.status;
    } else {
      this.premiumFeaturesStatus = '';
    }
    this.state = {
      id: prescriptionId,
      action: action,
      render: canRender,
      pageId: pageId,
      type: {},
      drugName: '',
      instructions: '',
      note: '',
      prescriberDoctor: '',
      doctorList: [],
      pharmacyList: [],
      prescribedDate: new Date(),
      startDate: new Date(),
      endDate: null,
      isStatus: false,
      medicineTypeList: medicineTypeListArray,
      openPremiumModal: false,
    };
    // }
    this.getDoctorList();
    this.getPharmacyList();
  }

  async getPrescriptionsDetails(prescriptionId) {
    const postObject = {
      header: this.header,
      medicine: {
        id: prescriptionId,
        member: { id: this.memberDetails.member },
      },
    };
    await FetchServerData.callPostService('/impMedicinems/get', postObject).then(async (output) => {
      if (output.status === 'SUCCESS') {
        let prescriptionData = output.data.medicine;

        let getDomainObjectPermission = output.data.allowedDomainObjects;
        let getDomainObjectPrivileges = output.data.domainObjectPrivileges;

        let getCreatePermission = false;
        let getReadPermission = false;
        let getUpdatePermission = false;
        let getDeletePermission = false;

        for (let domainObjectKey in getDomainObjectPrivileges) {
          if (Utils.equalsIgnoreCase(domainObjectKey, 'Medicine')) {
            let facilityPermission = getDomainObjectPrivileges[domainObjectKey];
            let getPermission = Utils.getDomainObjectRolePermission(facilityPermission);
            getCreatePermission = getPermission[0];
            getReadPermission = getPermission[1];
            getUpdatePermission = getPermission[2];
            getDeletePermission = getPermission[3];
          }
        }

        let status = prescriptionData.status.status;
        let prescriptionStatus = '';
        if (status === 'Active') {
          prescriptionStatus = true;
        } else {
          prescriptionStatus = false;
        }
        let selectedPrescriber;
        // if (prescriptionData.prescriber !== null) {
        //   selectedPrescriber = {
        //     name: prescriptionData.prescriber.firstName
        //       ? prescriptionData.prescriber.firstName
        //       : '' + ' ' + prescriptionData.prescriber.lastName
        //       ? prescriptionData.prescriber.lastName
        //       : '',
        //     id: prescriptionData.prescriber.id,
        //   };
        // } else {
        //   selectedPrescriber = null;
        // }
        let viewStoppedTakingDate = '';
        let lastDate = null;
        let startDate = new Date();
        let viewStartDate = '';
        if (prescriptionData.endDate) {
          lastDate = new Date(Utils.convertUTCDateToUserTimeZoneDateWithoutTimezoneFormate(prescriptionData.endDate));
          viewStoppedTakingDate = Utils.convertUTCDateToUserTimeZoneDateWithoutTimezoneFormate(
            prescriptionData.endDate
          );
        }
        let prescribedDate = new Date();

        let viewPrescribedDate = '';
        if (prescriptionData.prescribedDate) {
          prescribedDate = Utils.convertUTCDateToUserTimeZoneDateWithoutTimezoneFormate(
            prescriptionData.prescribedDate
          );

          viewPrescribedDate = Utils.convertUTCDateToUserTimeZoneDateWithoutTimezoneFormate(
            prescriptionData.prescribedDate
          );
        }
        if (prescriptionData.startDate) {
          startDate = Utils.convertUTCDateToUserTimeZoneDateWithoutTimezoneFormate(prescriptionData.startDate);

          viewStartDate = Utils.convertUTCDateToUserTimeZoneDateWithoutTimezoneFormate(prescriptionData.startDate);
        }
        this.setState({
          render: true,
          getUpdatePermission: getUpdatePermission,
          getDeletePermission: getDeletePermission,
          getReadPermission: getReadPermission,
          getCreatePermission: getCreatePermission,
          type: prescriptionData.type,
          viewType: prescriptionData.type,
          drugName: prescriptionData.name,
          instructions: prescriptionData.instructions,
          note: prescriptionData.notes,
          prescriberDoctor: prescriptionData.doctorName,
          doctorId: prescriptionData.doctorid,
          // prescriberDoctor: selectedPrescriber,
          prescribedDate: new Date(prescribedDate),
          startDate: new Date(startDate),
          endDate: lastDate,
          viewPrescribedDate: viewPrescribedDate,
          viewStartDate: viewStartDate,
          viewStoppedTakingDate: viewStoppedTakingDate,
          strength: prescriptionData.strength,
          pharmacy: prescriptionData.pharmacy,
          isStatus: prescriptionStatus,
        });
        this.getDoctorList();
        this.getPharmacyList();
      } else {
      }
    });
  }
  handleTypeChange(selectedType) {
    this.setState({
      type: selectedType,
    });
    if (undefined !== this.state.onChange && null !== this.state.onChange) {
      this.state.onChange(this.state);
    }
  }
  handleDrugNameChange(e) {
    this.setState({ drugName: e.target.value });
  }
  handleDrugNameOnBlur(e) {
    let drugName = Utils.camelizeText(e.target.value);
    this.setState({
      drugName: drugName,
    });
  }
  handleInstructionsChange(e) {
    this.setState({
      instructions: e.target.value,
    });
  }
  handleNotesChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 256);
    if (res.length < 256) {
      this.setState({ note: res });
    } else {
      Utils.maxFieldLength(256);
    }
  }

  handleStrengthChange(e) {
    this.setState({
      strength: e.target.value,
    });
  }

  handleDatePrescribedChange(date) {
    this.setState({ prescribedDate: date });
  }
  handleStartDateChange(date) {
    this.setState({ startDate: date });
  }
  handleStoppedTakingDateChange(date) {
    this.setState({ endDate: date });
  }
  handlePharmacyChange(event) {
    const { value } = event.target;
    const pharmacy = this.state.pharmacyList.find((c) => c.name === value);
    this.setState({ pharmacy: value });
  }
  handlePharmacyBlurChange(event) {
    const { value } = event.target;
    const pharmacy = this.state.pharmacyList.find((c) => c.name === value);
    if (pharmacy !== undefined) {
      this.setState({ pharmacy: value, pharmacyId: pharmacy.id });
    } else {
      this.setState({ pharmacy: value, pharmacyId: '' });
    }
  }

  handleDoctorChange(event) {
    const { value } = event.target;
    // find the country with the current selected value
    const doctor = this.state.doctorList.find((c) => c.name === value);
    this.setState({ prescriberDoctor: value });
  }
  handleDoctorBlurChange(event) {
    const { value } = event.target;
    // find the country with the current selected value
    const doctor = this.state.doctorList.find((c) => c.name === value);

    if (doctor !== undefined) {
      // use selected country object here
      this.setState({ prescriberDoctor: value, doctorId: doctor.id });
      // setCountryValue(country.value);
    } else {
      this.setState({ prescriberDoctor: value, doctorId: '' });
    }
  }
  getPharmacyList() {
    const postObject = {
      header: this.header,
      facility: {
        member: {
          id: this.memberDetails.member,
        },
      },
    };
    FetchServerData.callPostService('facilityms/getMemberPharmacyList', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let pharmacyListData = output.data.list;
        this.setState({
          pharmacyList: pharmacyListData,
          render: true,
        });
      } else {
      }
    });
  }
  getDoctorList() {
    const postObject = {
      header: this.header,
      doctor: {
        member: {
          id: this.memberDetails.member,
        },
      },
    };
    FetchServerData.callPostService('/doctorms/getActiveDoctors', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let doctorListData = output.data.doctorList;
        this.setState({
          doctorList: doctorListData,
          render: true,
        });
      } else {
      }
    });
  }
  handleStatusChange(e) {
    this.setState({ isStatus: !this.state.isStatus });
  }

  editCancel() {
    this.setState({
      action: 'view',
    });
  }

  updatePrescriptionToBackend = (e) => {
    e.preventDefault();
    let prescriptionStatus = {};
    let lastDate = null;
    if (this.state.isStatus === true) {
      prescriptionStatus = this.medicineStatusList[0];
    } else {
      prescriptionStatus = this.medicineStatusList[1];
    }
    let prescribedDate = Utils.convertUserTimeZoneDateToUTCDate(
      this.state.prescribedDate ? this.state.prescribedDate : ''
    );
    let startDate = Utils.convertUserTimeZoneDateToUTCDate(this.state.startDate ? this.state.startDate : '');
    if (this.state.endDate) {
      lastDate = Utils.convertUserTimeZoneDateToUTCDate(this.state.endDate);
    }
    // let prescriberDoctorId = null;
    // if (this.state.prescriberDoctor !== null) {
    //   prescriberDoctorId = this.state.prescriberDoctor.id;
    // }
    const postObject = {
      header: this.header,
      medicine: {
        id: this.state.id,
        name: this.state.drugName,
        prescribedDate: prescribedDate,
        startDate: startDate,
        endDate: lastDate,
        instructions: this.state.instructions,
        notes: this.state.note,
        strength: this.state.strength,
        pharmacy: this.state.pharmacy,
        member: {
          id: this.memberDetails.member,
        },
        // prescriber: {
        //   id: prescriberDoctorId,
        // },
        doctorid: this.state.doctorId,
        doctorname: this.state.prescriberDoctor,
        status: prescriptionStatus,
        type: {
          id: this.state.type.id,
        },
      },
    };
    FetchServerData.callPostService('/impMedicinems/update', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let prescriptionDetails = output.data;
          let id = prescriptionDetails.medicine.id;
          this.setState({
            action: 'view',
          });
          this.getPrescriptionsDetails(id);
          PtsAlert.success(output.message);
          let fccPageState = {
            id: prescriptionDetails.medicine.id,
            action: 'view',
            title: 'prescription Details',
          };

          store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
        } else {
        }
      })
      .catch((error) => {});
  };

  deletePrescriptionsInBackend = () => {
    const postObject = {
      header: this.header,
      medicine: {
        id: this.state.id,
        member: { id: this.memberDetails.member },
      },
    };

    FetchServerData.callPostService('/impMedicinems/delete', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        PtsAlert.success(output.message);
        this.props.history.push({
          pathname: '/import',
          sweetAlert: false,
        });
      } else {
      }
    });
  };
  viewCancel() {
    this.props.history.push({
      pathname: '/import',
    });
  }
  createCancel() {
    this.props.history.push({
      pathname: '/import',
    });
  }
  printPreviewPrescription() {
    this.setState({
      action: 'printPrescription',
    });
  }
  editPrescriptions() {
    if (this.isFreeUser === true) {
      console.log('1');
      if (this.premiumFeatures !== null && this.premiumFeaturesStatus === 'Expired') {
        console.log('11');
        this.setState({
          openPremiumModal: true,
        });
      } else if (this.premiumFeatures === null) {
        console.log('12');
        this.setState({
          openPremiumModal: true,
        });
      } else {
        this.setState({
          action: 'edit',
        });
      }
    } else {
      console.log('2');
      this.setState({
        action: 'edit',
      });
    }
  }

  premiumModalToggle = () => {
    this.setState({
      openPremiumModal: !this.state.openPremiumModal,
    });
  };

  render() {
    // if (this.state.action === 'create') {
    //   return this.createPrescriptions();
    // }
    // else
    if (this.state.action === 'edit') {
      return this.editPrescriptionsDetails();
    } else if (this.state.action === 'view') {
      return this.viewPrescriptionsDetails();
    } else if (this.state.action === 'printPrescription') {
      return this.printPrescriptionsDetails();
    }
  }
  cancelPrintView() {
    this.setState({
      action: 'view',
    });
  }

  viewPrescriptionsDetails() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <MenuWithBreadScrum
            backCallback={this.viewCancel.bind(this)}
            editCallback={this.editPrescriptions.bind(this)}
            deleteCallback={this.deletePrescriptionsInBackend.bind(this)}
            printCallback={this.printPreviewPrescription.bind(this)}
            getDeletePermission={this.state.getDeletePermission}
            getUpdatePermission={this.state.getUpdatePermission}
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            name={this.title + ' For ' + this.memberName}
            domainObject="Prescriptions"
          />
          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={12} style={{ padding: '5px' }}>
              <Card style={{ height: '100%', borderRadius: '20px' }}>
                <>
                  {' '}
                  <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                    <Row>
                      <Col md={10}>
                        {/* {undefined !== this.state.title && this.state.title.length > 0 && ( */}
                        <Label style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}>
                          Prescriptions Details
                        </Label>
                        {/* )} */}
                      </Col>
                      <Col md={2}>
                        {/* {this.state.getUpdatePermission && (
                          <EditIconButton onClick={this.editPrescriptions.bind(this)} />
                        )} */}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={4}>
                        <Row>
                          <Col md={5}>
                            <label>Type</label>
                          </Col>
                          <Col md={7}>
                            {' '}
                            <span>{this.state.viewType ? this.state.viewType.type : ''}</span>{' '}
                          </Col>
                        </Row>
                      </Col>
                      <Col md={4}>
                        <Row>
                          <Col md={5}>
                            <label>Drug Name</label>
                          </Col>
                          <Col md={7}>
                            {' '}
                            <span>{this.state.drugName ? this.state.drugName : ''}</span>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={4}>
                        <Row>
                          <Col md={5}>
                            <label>Strength</label>
                          </Col>
                          <Col md={7}>
                            {' '}
                            <span>{this.state.strength ? this.state.strength : ''}</span>{' '}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <Row>
                          <Col md={5}>
                            <label>Prescribed By</label>
                          </Col>
                          <Col md={7}>
                            {' '}
                            <span>{this.state.prescriberDoctor ? this.state.prescriberDoctor : ''}</span>{' '}
                          </Col>
                        </Row>
                      </Col>
                      <Col md={4}>
                        <Row>
                          <Col md={5}>
                            <label>Pharmacy</label>
                          </Col>
                          <Col md={7}>
                            {' '}
                            <span>{this.state.pharmacy ? this.state.pharmacy : ''}</span>{' '}
                          </Col>
                        </Row>
                      </Col>
                      <Col md={4}>
                        <Row>
                          <Col md={5}>
                            <label>Date Prescribed</label>
                          </Col>
                          <Col md={7}>
                            {' '}
                            <span>{this.state.viewPrescribedDate ? this.state.viewPrescribedDate : ''}</span>{' '}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={4}>
                        <Row>
                          <Col md={5}>
                            <label>Start Date</label>
                          </Col>
                          <Col md={7}>
                            {' '}
                            <span>{this.state.viewStartDate ? this.state.viewStartDate : ''}</span>{' '}
                          </Col>
                        </Row>
                      </Col>
                      <Col md={4}>
                        <Row>
                          <Col md={5}>
                            <label>End Date</label>
                          </Col>
                          <Col md={7}>
                            {' '}
                            <span>{this.state.viewStoppedTakingDate ? this.state.viewStoppedTakingDate : ''}</span>{' '}
                          </Col>
                        </Row>
                      </Col>
                      <Col md={4}>
                        <Row>
                          <Col md={5}>
                            <label> Status </label>
                          </Col>
                          <Col md={7} className="statusMember">
                            {this.state.isStatus === true && (
                              <Tooltip title="Please click EDIT to change status">
                                <span>
                                  <ToggleButton
                                    value="Active"
                                    className="ToggleButtonActive"
                                    onChange={this.handleStatusChange.bind(this)}
                                    style={{
                                      background: '#27AE60',
                                      color: '#fff',
                                      height: '25px',
                                      textTransform: 'none',
                                    }}
                                    disabled
                                  >
                                    Active
                                  </ToggleButton>
                                </span>
                              </Tooltip>
                            )}
                            {this.state.isStatus === false && (
                              <Tooltip title="Please click EDIT to change status">
                                <span>
                                  <ToggleButton
                                    value="Inactive"
                                    className="ToggleButtonInActive"
                                    onChange={this.handleStatusChange.bind(this)}
                                    style={{
                                      background: '#D7DBDD',
                                      color: '#000',
                                      height: '25px',
                                      textTransform: 'none',
                                    }}
                                    disabled
                                  >
                                    Not Taking
                                  </ToggleButton>
                                </span>
                              </Tooltip>
                            )}
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '1em' }}>
                      <Col md={6}>
                        <Row>
                          <Col md={3}>
                            <label>Instructions</label>
                          </Col>
                          <Col md={9}>
                            <pre className="view_facilityDesc" style={{ wordBreak: 'break-word' }}>
                              {this.state.instructions ? this.state.instructions : ''}
                            </pre>
                          </Col>
                        </Row>
                      </Col>
                      <Col md={6}>
                        <Row>
                          <Col md={2}>
                            <label>Note</label>
                          </Col>
                          <Col md={10}>
                            {' '}
                            <pre className="view_facilityDesc" style={{ wordBreak: 'break-word' }}>
                              {' '}
                              {this.state.note ? this.state.note : ''}
                            </pre>
                          </Col>
                        </Row>
                      </Col>
                    </Row>{' '}
                  </CardBody>
                </>
              </Card>
            </Col>
          </Row>
          <PtsModal
            open={this.state.openPremiumModal}
            onClose={this.premiumModalToggle}
            // title="Family Care Circle Document"
          >
            <PremiumFeatures
              history={this.props.history}
              pageId="home"
              modalOpen={this.state.openPremiumModal}
              closeModal={this.premiumModalToggle.bind(this)}
            />
          </PtsModal>
        </>
      );
    }
  }
  printPrescriptionsDetails() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          {this.state.action === 'printPrescription' ? (
            <>
              <MenuWithBreadScrum
                backCallback={this.cancelPrintView.bind(this)}
                breadCrumb={this.breadCrumb}
                history={this.props.history}
                name={'Print Preview of Prescriptions' + ' For ' + this.memberName}
                domainObject="Prescriptions"
              />
              <Row style={{ position: 'relative', margin: '1em' }}>
                <Col md={12}>
                  <ReactToPrint
                    trigger={() => (
                      <span style={{ cursor: 'pointer', fontSize: 'x-larger', marginRight: '2em', float: 'right' }}>
                        <Tooltip title="Print">
                          <Button
                            className="add"
                            style={{
                              background: '#867e7e',
                              color: 'white',
                              fontSize: 'x-large',
                              textTransform: 'capitalize',
                              cursor: 'pointer',
                              fontSize: 'x-larger',
                              float: 'right',
                              margin: '0.2em 0.5em 0.3em 0em',
                            }}
                            startIcon={<PrintRoundedIcon />}
                          >
                            Print
                          </Button>
                        </Tooltip>
                      </span>
                    )}
                    content={() => this.componentRef}
                  />
                </Col>
              </Row>
            </>
          ) : (
            ''
          )}
          <div id="printform" style={{ overflowY: 'auto', margin: '1em' }} ref={(el) => (this.componentRef = el)}>
            <Row style={{ position: 'relative', margin: '1em' }}>
              <Col md={12} style={{ padding: '5px' }}>
                <Card style={{ height: '100%', borderRadius: '20px' }}>
                  <>
                    {' '}
                    <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                      <Row style={{ position: 'relative', marginTop: '0.5em' }}>
                        <Col md={12}>
                          {' '}
                          <Label style={{ fontWeight: '600', color: '#000', marginLeft: '0.5em', marginTop: '0.5em' }}>
                            {'Prescriptions Details For ' + this.memberName}
                          </Label>
                        </Col>
                      </Row>
                      {/* <Row style={{ position: 'relative', marginTop: '0.5em' }}>
                        <Col md={10}>
                          <Label
                            style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                          ></Label>

                        </Col>
                        <Col md={2}></Col>
                      </Row> */}
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md={4}>
                          <Row>
                            <Col md={5}>
                              <label>Type</label>
                            </Col>
                            <Col md={7}>
                              {' '}
                              <span>{this.state.viewType ? this.state.viewType.type : ''}</span>{' '}
                            </Col>
                          </Row>
                        </Col>
                        <Col md={4}>
                          <Row>
                            <Col md={5}>
                              <label>Drug Name</label>
                            </Col>
                            <Col md={7}>
                              {' '}
                              <span>{this.state.drugName ? this.state.drugName : ''}</span>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={4}>
                          <Row>
                            <Col md={5}>
                              <label>Strength</label>
                            </Col>
                            <Col md={7}>
                              {' '}
                              <span>{this.state.strength ? this.state.strength : ''}</span>{' '}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <Row>
                            <Col md={5}>
                              <label>Prescribed By</label>
                            </Col>
                            <Col md={7}>
                              {' '}
                              <span>
                                {this.state.prescriberDoctor !== null
                                  ? this.state.prescriberDoctor.name !== null
                                    ? 'Dr' + ' ' + this.state.prescriberDoctor.name
                                    : ''
                                  : ''}
                              </span>{' '}
                            </Col>
                          </Row>
                        </Col>
                        <Col md={4}>
                          <Row>
                            <Col md={5}>
                              <label>Pharmacy</label>
                            </Col>
                            <Col md={7}>
                              {' '}
                              <span>{this.state.pharmacy ? this.state.pharmacy : ''}</span>{' '}
                            </Col>
                          </Row>
                        </Col>
                        <Col md={4}>
                          <Row>
                            <Col md={5}>
                              <label>Date Prescribed</label>
                            </Col>
                            <Col md={7}>
                              {' '}
                              <span>{this.state.viewPrescribedDate ? this.state.viewPrescribedDate : ''}</span>{' '}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>
                          <Row>
                            <Col md={5}>
                              <label>Start Date</label>
                            </Col>
                            <Col md={7}>
                              {' '}
                              <span>{this.state.viewStartDate ? this.state.viewStartDate : ''}</span>{' '}
                            </Col>
                          </Row>
                        </Col>
                        <Col md={4}>
                          <Row>
                            <Col md={5}>
                              <label>End Date</label>
                            </Col>
                            <Col md={7}>
                              {' '}
                              <span>
                                {this.state.viewStoppedTakingDate ? this.state.viewStoppedTakingDate : ''}
                              </span>{' '}
                            </Col>
                          </Row>
                        </Col>
                        <Col md={4}>
                          <Row>
                            <Col md={5}>
                              <label> Status </label>
                            </Col>
                            <Col md={7} className="statusMember">
                              {this.state.isStatus === true && (
                                <>
                                  <Tooltip title="Please click EDIT to change status">
                                    <span>
                                      <ToggleButton
                                        value="Active"
                                        className="ToggleButtonActive"
                                        onChange={this.handleStatusChange.bind(this)}
                                        style={{
                                          background: '#27AE60',
                                          color: '#fff',
                                          height: '25px',
                                          textTransform: 'none',
                                        }}
                                        disabled
                                      >
                                        Active
                                      </ToggleButton>
                                    </span>
                                  </Tooltip>
                                </>
                              )}
                              {this.state.isStatus === false && (
                                <Tooltip title="Please click EDIT to change status">
                                  <span>
                                    <ToggleButton
                                      value="Inactive"
                                      className="ToggleButtonInActive"
                                      onChange={this.handleStatusChange.bind(this)}
                                      style={{
                                        background: '#D7DBDD',
                                        color: '#000',
                                        height: '25px',
                                        textTransform: 'none',
                                      }}
                                      disabled
                                    >
                                      Not Taking
                                    </ToggleButton>
                                  </span>
                                </Tooltip>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '1em' }}>
                        <Col md={6}>
                          <Row>
                            <Col md={3}>
                              <label>Instructions</label>
                            </Col>
                            <Col md={9}>
                              <pre className="view_facilityDesc" style={{ wordBreak: 'break-word' }}>
                                {this.state.instructions ? this.state.instructions : ''}
                              </pre>
                            </Col>
                          </Row>
                        </Col>
                        <Col md={6}>
                          <Row>
                            <Col md={2}>
                              <label>Note</label>
                            </Col>
                            <Col md={10}>
                              {' '}
                              <pre className="view_facilityDesc" style={{ wordBreak: 'break-word' }}>
                                {' '}
                                {this.state.note ? this.state.note : ''}
                              </pre>
                            </Col>
                          </Row>
                        </Col>
                      </Row>{' '}
                    </CardBody>
                  </>
                </Card>
              </Col>
            </Row>
          </div>
        </>
      );
    }
  }
  editPrescriptionsDetails() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <MenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            name={'Edit Prescription For' + ' ' + this.memberName}
            domainObject="Prescription"
          />
          <ValidatorForm style={{ width: '100%' }} ref="form" onSubmit={this.updatePrescriptionToBackend.bind(this)}>
            <Row style={{ position: 'relative', margin: '1em' }}>
              <Col md={12} style={{ position: 'relative', padding: '5px' }}>
                <Card style={{ height: '100%', borderRadius: '20px' }}>
                  <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.5em' }}>
                    <Row>
                      <Col md={9}>
                        {/* {undefined !== this.state.title && this.state.title.length > 0 && ( */}
                        <Label style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.3em' }}>
                          Prescription Details
                        </Label>
                        {/* )} */}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody style={{ padding: '1em', marginTop: '0em' }}>
                    <Row>
                      <Col md={4}>
                        <Autocomplete
                          id="type"
                          options={this.state.medicineTypeList}
                          value={this.state.type}
                          getOptionLabel={(option) => (option.type ? option.type : '')}
                          onChange={(event, value) => {
                            this.handleTypeChange(value);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" label="Type" margin="normal" required />
                          )}
                        />
                      </Col>
                      <Col md={4}>
                        <TextField
                          margin="normal"
                          variant="outlined"
                          fullWidth
                          required
                          type="text"
                          id="drugName"
                          onChange={this.handleDrugNameChange.bind(this)}
                          onBlur={this.handleDrugNameOnBlur.bind(this)}
                          label="Drug Name"
                          name="drugName"
                          inputprops={{ style: { textTransform: 'capitalize' } }}
                          value={this.state.drugName}
                        />
                      </Col>
                      <Col md={4}>
                        <TextField
                          margin="normal"
                          variant="outlined"
                          fullWidth
                          type="text"
                          id="strength"
                          onChange={this.handleStrengthChange.bind(this)}
                          label="Strength"
                          name="strength"
                          value={this.state.strength}
                        />
                      </Col>
                    </Row>{' '}
                    <Row>
                      <Col md={4} style={{ marginTop: '1.2em' }}>
                        {/* <Autocomplete
                          id="Prescriber"
                          options={this.state.doctorList}
                          value={this.state.prescriberDoctor}
                          getOptionLabel={(option) => option.name}
                          onChange={(event, value) => {
                            this.handleDoctorChange(value);
                          }}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" label="Prescribed By" margin="normal" />
                          )}
                        /> */}

                        <input
                          style={{
                            height: '46px',
                            width: '100%',
                            padding: ' 1em',
                            borderRadius: '3px',
                            border: '1px solid rgb(196, 196, 196)',
                          }}
                          list="dropdown__countries"
                          className="header__dropbtn"
                          placeholder="Prescribed By"
                          value={this.state.prescriberDoctor}
                          onChange={this.handleDoctorChange.bind(this)}
                          onBlur={this.handleDoctorBlurChange.bind(this)}
                        />
                        <datalist id="dropdown__countries">
                          {this.state.doctorList.map((country) => (
                            <option key={country.id} value={country.name} />
                          ))}
                        </datalist>
                      </Col>
                      <Col md={4} style={{ marginTop: '1.2em' }}>
                        <input
                          style={{
                            height: '46px',
                            width: '100%',
                            padding: ' 1em',
                            borderRadius: '3px',
                            border: '1px solid rgb(196, 196, 196)',
                          }}
                          list="dropdown__countries1"
                          className="header__dropbtn"
                          value={this.state.pharmacy}
                          placeholder="Pharmacy"
                          onChange={this.handlePharmacyChange.bind(this)}
                          onBlur={this.handlePharmacyBlurChange.bind(this)}
                        />
                        <datalist id="dropdown__countries1">
                          {this.state.pharmacyList.map((pharmacy) => (
                            <option key={pharmacy.id} value={pharmacy.name} />
                          ))}
                        </datalist>
                        {/* <input
                          className="pts_dataList_dropDown"
                          type="text"
                          margin="normal"
                          id="pharmacy-outlined"
                          list="pharmacy"
                          placeholder="Pharmacy"
                          label="Pharmacy"
                          value={this.state.pharmacy}
                          onChange={(event, value) => {
                            this.handlePharmacyChange(event, value);
                          }}
                        />
                        <datalist id="pharmacy">
                          {this.state.pharmacyList.map((event, key) => (
                            <option key={event.name} value={event.name} />
                          ))}
                        </datalist> */}
                      </Col>

                      <Col md={4}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <ThemeProvider theme={materialTheme}>
                            <DatePicker
                              clearable
                              value={this.state.prescribedDate}
                              // variant="inline"
                              margin="normal"
                              label="Date Prescribed"
                              fullWidth
                              variant="outlined"
                              id="ptsDateTimePicker"
                              inputVariant="outlined"
                              format="MM/dd/yyyy"
                              onChange={this.handleDatePrescribedChange.bind(this)}
                            />
                          </ThemeProvider>
                        </MuiPickersUtilsProvider>{' '}
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '0.8em' }}>
                      <Col md={4}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <ThemeProvider theme={materialTheme}>
                            <DatePicker
                              clearable
                              value={this.state.startDate}
                              // variant="inline"
                              margin="normal"
                              label="Start Date"
                              fullWidth
                              variant="outlined"
                              id="ptsDateTimePicker"
                              inputVariant="outlined"
                              format="MM/dd/yyyy"
                              onChange={this.handleStartDateChange.bind(this)}
                            />
                          </ThemeProvider>
                        </MuiPickersUtilsProvider>{' '}
                      </Col>
                      <Col md={4}>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <ThemeProvider theme={materialTheme}>
                            <DatePicker
                              clearable
                              value={this.state.endDate}
                              // variant="inline"
                              margin="normal"
                              label="End Date"
                              placeholder="MM/dd/yyyy"
                              fullWidth
                              variant="outlined"
                              id="ptsDateTimePicker"
                              inputVariant="outlined"
                              format="MM/dd/yyyy"
                              onChange={this.handleStoppedTakingDateChange.bind(this)}
                            />
                          </ThemeProvider>
                        </MuiPickersUtilsProvider>{' '}
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '0.8em' }}>
                      <Col md={6}>
                        <TextareaAutosize
                          aria-label="minimum height"
                          style={{ width: '100%', padding: '10px' }}
                          minRows={5}
                          id="Note"
                          onChange={this.handleInstructionsChange.bind(this)}
                          label="Instructions"
                          inputprops={{ maxLength: 256, padding: '10px' }}
                          name="note"
                          placeholder="Instructions"
                          value={this.state.instructions}
                        />
                      </Col>
                      <Col md={6}>
                        <TextareaAutosize
                          aria-label="minimum height"
                          style={{ width: '100%', padding: '10px' }}
                          minRows={5}
                          id="Note"
                          value={this.state.note}
                          inputprops={{ maxLength: 256, padding: '10px' }}
                          name="note"
                          onChange={this.handleNotesChange.bind(this)}
                          placeholder="Note"
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <div style={{ textAlign: 'center', padding: '0em 0em 1em' }}>
              <SaveButton type="submit" />
              &nbsp;
              <CancelIconButton type="button" onClick={(e) => this.editCancel(e, this.state.id)} />
            </div>
          </ValidatorForm>
        </>
      );
    }
  }
}
export default PrescriptionsImport;
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#008181',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: 'white',
        color: '#008181',
      },
    },
  },
});
