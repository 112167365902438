import TextField from '@material-ui/core/TextField';
import React, { Component } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Facebook, Linkedin, Twitter, Whatsapp } from 'react-social-sharing';
import { Card, Col, Row } from 'reactstrap';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
import PtsAlert from '../ptsAlert/PtsAlert';
import Button from '@material-ui/core/Button';
import './ReferralBonus.css';
import store from '../../../redux/store';
import FetchServerData from '../../../provider/FetchServerData';
import referFriend from '../../../assets/images/referFriend.png';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Advertisement from '../advertisement/Advertisement';
class ReferralBonus extends Component {
  title = 'Refer A Friend';
  breadCrumb = [{ pageid: '/referralBonus', uiname: 'Refer A Friend' }];
  header = store.getState().header.header;
  constructor(props) {
    super(props);

    let str_href = window.location.href;
    let str_hrefArr = str_href.split('/');
    let finalInviteLink = str_hrefArr[0] + '//' + str_hrefArr[2] + '/signup?' + this.referal_code;
    let email = this.email;
    if (props.history && props.history.location && props.history.location.state) {
      email = props.history.location.state.email;
    }

    this.state = {
      inviteLink: finalInviteLink,
      email: email,
      refer_to_email: '',
      inviteModel: false,
    };
    this.getInviteLink();
  }
  copySelectedAccount = () => {
    if (this.state.inviteLink === '') {
      PtsAlert.error('Please copy link.');
      return;
    }
    navigator.clipboard.writeText(this.state.inviteLink);
    PtsAlert.success('Account copied to clipboard!');
  };

  handleReferToEmailChange(e) {
    this.setState({ refer_to_email: e.target.value });
  }

  getInviteLink = async () => {
    const postObject = {
      header: this.header,
    };

    FetchServerData.callPostService('/referralms/getReferralURL', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let finalInviteLink = output.data.referralURL;

          this.setState({
            inviteLink: finalInviteLink,
          });
        }
      })
      .catch((error) => {});
  };

  sendReferralMail = () => {
    const postObject = {
      header: this.header,
      email: this.state.refer_to_email,
    };

    FetchServerData.callPostService('referralms/referFriend', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          this.setState({
            refer_to_email: '',
            inviteModel: true,
          });
        }
      })
      .catch((error) => {});
  };

  handleInviteModelClose = () => {
    this.setState({
      inviteModel: false,
    });
  };
  render() {
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
          headerWidth="home"
        />
        <Row style={{ margin: '1em' }}>
          <Advertisement pageId="CaregiverCircle" />
        </Row>
        <div>
          <Row style={{ padding: '1em 2em' }}>
            <Col md={12}>
              <Card style={{ padding: '2em' }}>
                <h2 style={{ color: '#008181' }}>
                  <b>Spread the word, Share the care.</b>
                </h2>
                <Row>
                  <Col md={8} style={{ padding: '2em 1em' }}>
                    <Row>
                      <Col md={12}>
                        <p style={{ fontSize: '16px' }}>
                          Invite your friends and relatives to the Family of caregivers who takes care of family
                          members. While you enjoy and take benefits of this easy and helpful website, Why keep just for
                          yourself, PASS US ON. Share FCC with the people in your life.
                        </p>

                        <p style={{ fontSize: '16px' }}>
                          We have made it easy, just type email address or share through Facebook, Twitter, LinkedIn,
                          Whatsapp.
                        </p>
                      </Col>
                    </Row>
                    <ValidatorForm ref="form" id="formAuthentication" onSubmit={this.sendReferralMail.bind(this)}>
                      <Row>
                        {' '}
                        <Col md={7} style={{ margin: 'auto' }}>
                          <TextValidator
                            id="email"
                            fullWidth
                            required
                            margin="normal"
                            variant="outlined"
                            placeholder="Enter Email *"
                            type="email"
                            value={this.state.refer_to_email}
                            onChange={this.handleReferToEmailChange.bind(this)}
                            // onBlur={this.props.onBlur}
                            autoComplete="off"
                            validators={['required', 'isEmail']}
                            errorMessages={['this field is required', 'email is not valid']}
                          />{' '}
                        </Col>
                        <Col md={1} style={{ margin: 'auto' }}>
                          {' '}
                          <Button
                            style={{
                              background: '#ef6603',
                              color: '#fff',
                              marginRight: '1rem',
                              float: 'right',
                              textTransform: 'capitalize',
                              height: '45px',
                              width: '80px',
                              marginTop: '0.4em',
                            }}
                            type="submit"
                          >
                            Invite
                          </Button>
                        </Col>
                        <Col md={4} className="iconColumn socialMediaIcons">
                          <Facebook solid small link={this.state.inviteLink} />
                          <Twitter solid small link={this.state.inviteLink} />
                          <Linkedin
                            solid
                            small
                            message="Please Sign Up using following link"
                            link={this.state.inviteLink}
                          />
                          <Whatsapp
                            solid
                            small
                            message="Please Sign Up using following link"
                            link={this.state.inviteLink}
                          />
                        </Col>{' '}
                      </Row>
                    </ValidatorForm>
                  </Col>
                  <Col md={4} style={{ textAlign: 'center' }}>
                    <img src={referFriend} alt="" style={{ height: '265px' }} />
                  </Col>
                </Row>
              </Card>
            </Col>
          </Row>
          <Dialog
            open={this.state.inviteModel}
            onClose={this.handleInviteModelClose.bind(this)}
            onHide={this.handleInviteModelClose.bind(this)}
            style={{ padding: '1em' }}
            className="subscriptionModal"
          >
            <DialogContent style={{ textAlign: 'center', padding: '3em', borderRadius: ' 8px' }}>
              <h3 style={{ color: '#2ECC71' }}>
                <b>Thank You For Helping Us GROW</b>
              </h3>
            </DialogContent>
            <DialogActions style={{ padding: '0em 0em 1em 0em', marginLeft: 'auto', marginRight: 'auto' }}>
              <Button
                variant="contained"
                size="medium"
                style={{ background: '#ef6603', color: '#fff', marginRight: '1rem' }}
                onClick={this.handleInviteModelClose.bind(this)}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </>
    );
  }
}

export default ReferralBonus;
