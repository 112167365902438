import React, { Component } from 'react';
import Avatar, { ConfigProvider } from 'react-avatar';
import store from '../../../redux/store';
import PageAlertContext from '../../components/pageAlert/PageAlertContext';
import './AdminSidebar.scss';
import NavDivider from './components/NavDivider';
import NavDropdownItem from './components/NavDropdownItem';
import NavOverlay from './components/NavOverlay';
import NavSingleItem from './components/NavSingleItem';
import NavSpacer from './components/NavSpacer';
import { connect } from 'react-redux';
import ActiveMenuAction from '../../../redux/activeMenu/ActiveMenuAction';
export class AdminSidebar extends Component {
  memberDetails = store.getState().adminUserDetails.adminUserDetails;

  constructor(props) {
    super(props);
    let memberMenus = this.memberDetails.profile.menus;
    if (this.memberDetails) {
      for (let i = 0; i < this.memberDetails.profile.menus.length; i++) {
        this.memberDetails.profile.menus[i].helpClassName = 'sidebarMenu' + (i + 1);
      }
      let activeMenu = store.getState().activeMenu.activeMenu;
      if (undefined === activeMenu || null === activeMenu) {
        activeMenu = (memberMenus && memberMenus.length) > 0 ? memberMenus[0].id : -1;
      }
      this.state = {
        memberData: this.memberDetails,
        memberMenuList: this.memberDetails.profile.menus,
        activeMenu: activeMenu,
      };
    }
  }
  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      let activeMenu = store.getState().activeMenu.activeMenu;
      this.setState({ activeMenu: activeMenu });
    });
  }
  componentWillUnmount() {
    this.unsubscribe();
  }
  selectedChildMenu = (menuid) => {
    for (let i = 0; i < this.state.memberMenuList.length; i++) {
      if (this.state.memberMenuList[i].id === menuid) {
        store.dispatch(ActiveMenuAction.setActiveMenu(menuid));
        this.setState({ activeMenu: menuid });
      }
    }
  };
  render() {
    const navItems = (items) => {
      if (!this.isEmpty(items)) {
        return items.map((item, index) => itemType(item, index));
      }
    };
    const itemType = (item, index) => {
      if (!this.isEmpty(item)) {
        let isActive = false;
        if (item.id === this.state.activeMenu) {
          isActive = true;
        }
        return (
          item.isactive === true && (
            <NavSingleItem item={item} key={index} onSelect={this.selectedChildMenu.bind(this)} isActive={isActive} />
          )
        );
      }
    };

    return (
      <>
        <PageAlertContext.Consumer>
          {(consumer) => {
            const hasPageAlertClass = consumer.alert ? 'has-alert' : '';
            let firstName =
              null !== this.state.memberData.firstName && undefined !== this.state.memberData.firstName
                ? this.state.memberData.firstName
                : '';
            let lastName =
              null !== this.state.memberData.lastName && undefined !== this.state.memberData.lastName
                ? this.state.memberData.lastName
                : '';
            let memberName = firstName + ' ' + lastName;
            return (
              <div>
                <div
                  className={`app-sidebar ${hasPageAlertClass}`}
                  style={{
                    marginTop: '3.6em',
                    transition: 'width 0.3s linear',
                  }}
                >
                  <div
                    className="user-panel"
                    style={{ color: '#fff', textAlign: 'center', marginTop: '1em', cursor: 'pointer' }}
                  >
                    <span title="Member Profile">
                      <ConfigProvider colors={['#F39C12', '#A569BD', '#FA8072']}>
                        <Avatar name={memberName} round="60px" className="memberIcon" />
                      </ConfigProvider>
                    </span>
                    <p style={{ textTransform: 'capitalize' }}>
                      {this.state.memberData.firstName + ' ' + this.state.memberData.lastName}
                      <br></br>
                    </p>
                  </div>
                  <nav>
                    <ul id="main-menu">
                      {navItems(this.state.memberMenuList)}
                      <NavSpacer />
                    </ul>
                  </nav>
                </div>
                {this.props.isSidebarCollapsed && <NavOverlay onClick={this.props.toggleSidebar} />}
              </div>
            );
          }}
        </PageAlertContext.Consumer>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return { activeMenu: state.activeMenu.activeMenu };
};

export default connect(mapStateToProps)(AdminSidebar);
