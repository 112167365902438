import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import AccessAlarmTwoToneIcon from '@material-ui/icons/AccessAlarmTwoTone';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ChatIcon from '@material-ui/icons/Chat';
import CloseIcon from '@material-ui/icons/Close';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import SaveIcon from '@material-ui/icons/Save';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import React, { Component } from 'react';
import Geocode from 'react-geocode';
import { ValidatorForm } from 'react-material-ui-form-validator';
import 'react-tabs/style/react-tabs.css';
import { Card, CardBody, CardFooter, CardHeader, Col, Label, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import UiUtils from '../../../provider/UiUtils';
import Utils from '../../../provider/Utils';
import doctorFacilityStateAction from '../../../redux/doctorFacilityApplicationState/doctorFacilityStateAction';
import fccPageStateAction from '../../../redux/fccPageState/fccPageStateAction';
import helpDataAction from '../../../redux/help/displayHelpAction';
import store from '../../../redux/store';
import AddIconButton from '../buttons/AddIconButton';
import BackButton from '../buttons/BackButton';
import CancelIconButton from '../buttons/CancelIconButton';
import CreateButton from '../buttons/CreateButton';
import EditIconButton from '../buttons/EditIconButton';
import SaveButton from '../buttons/SaveButton';
import Location from '../location/Location';
import Map from '../map/Map';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
import PtsAlert from '../ptsAlert/PtsAlert';
import MaterialTable from '@material-table/core';
import ToggleButton from '@material-ui/lab/ToggleButton';
import EmailIcon from '@material-ui/icons/Email';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LanguageIcon from '@material-ui/icons/Language';
import PersonIcon from '@material-ui/icons/Person';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import IconButton from '@material-ui/core/IconButton';
import Checkbox from '@material-ui/core/Checkbox';
import './MemberFacility.scss';
import { TextValidator } from 'react-material-ui-form-validator';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import ShareIcon from '@material-ui/icons/Share';
import BreadCrumbDetailsAction from '../../../redux/breadCrumbDetails/BreadCrumbDetailsAction';
export class MemberFacility extends Component {
  facilityTypeList = store.getState().staticData.facilityTypeList;
  header = store.getState().header.header;
  memberDetails = store.getState().memberData.memberData;
  memberName = store.getState().memberData.memberData.firstname + ' ' + store.getState().memberData.memberData.lastname;
  isFreeUser = store.getState().userData.userData.isFreeUser;
  displayHelp = store.getState().help.help;
  locationReferenceList = [];
  fccPageState = store.getState().fccPageState.fccPageState;
  facilityAppointmentState = store.getState().doctorFacilityAppointmentState.facilityAppointmentState;
  memberHomeUrl = store.getState().memberHomeUrl.memberHomeUrl.homeUrl;
  facilityAppointmentStateDetails = {};
  title = 'Facility Details';
  breadCrumb = [
    { pageid: this.memberHomeUrl, uiname: this.memberName },
    { pageid: '/memberFacility', uiname: 'Facility List' },
    { pageid: '', uiname: 'Facility Details' },
  ];
  constructor(props) {
    super(props);
    let facilityState = {};
    store.dispatch(
      doctorFacilityStateAction.setDoctorFacilityState({
        facilityState,
      })
    );
    let action = props.action;
    let title = '';
    let pageId = '';
    let locationId = '';
    let facilityData = {};
    let initialPage = 0;
    let appointmentId = '';
    let pageSize = 10;
    let facilityId = props.facilityId;
    if (!facilityId && props.history && props.history.location && props.history.location.state) {
      facilityId = props.history.location.state.facilityId;
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
      title = props.history.location.state.title;
      facilityData = props.history.location.state.facilityData;
    }
    let canRender = false;
    if (null !== facilityId && facilityId > 0) {
      this.getFacilityDetails(facilityId);
    } else {
      canRender = true;
    }
    let previousButton = false;
    if (pageId === 'viewMemberAppointment') {
      previousButton = true;
    }
    if (facilityId === undefined) {
      facilityId = this.fccPageState.id;
      action = this.fccPageState.action;
      title = 'Facility Details';
      pageId = 'memberFacility';
      locationId = this.fccPageState.locationId;
      if (action !== 'create') {
        this.getFacilityDetails(facilityId);
      }

      // canRender = true;
    }
    if (this.facilityAppointmentState) {
      this.facilityAppointmentStateDetails = Object.getOwnPropertyNames(this.facilityAppointmentState);
    }
    if (this.facilityAppointmentStateDetails.length > 0) {
      initialPage =
        this.facilityAppointmentState.initialPage && this.facilityAppointmentState.initialPage > 0
          ? this.facilityAppointmentState.initialPage
          : 0;
      appointmentId = this.facilityAppointmentState.appointmentId;
      pageSize =
        this.facilityAppointmentState.pageSize && this.facilityAppointmentState.pageSize
          ? this.facilityAppointmentState.pageSize
          : 10;
    } else {
      initialPage = 0;
      appointmentId = '';
      pageSize = 10;
    }
    if (this.breadCrumb) {
      Utils.getActiveMenu(this.breadCrumb);
      store.dispatch(BreadCrumbDetailsAction.setBreadCrumbDetails(this.breadCrumb));
    }
    if (pageId === 'createFacility') {
      let facilityAppointmentList = facilityData.facility.appointmentList;
      let getPermission = Utils.getRolePermission(facilityData.privileges);
      let getAllPermission = getPermission[0];
      let getCreatePermission = getPermission[1];
      let getReadPermission = getPermission[2];
      let getUpdatePermission = getPermission[3];
      let getDeletePermission = getPermission[4];
      let status = facilityData.facility.status.status;
      let doctorStatus = '';
      if (status === 'Active') {
        doctorStatus = true;
      } else {
        doctorStatus = false;
      }
      this.state = {
        viewFacility: true,
        id: facilityData.facility.id,
        getAllPermission: getAllPermission,
        getUpdatePermission: getUpdatePermission,
        getDeletePermission: getDeletePermission,
        getReadPermission: getReadPermission,
        getCreatePermission: getCreatePermission,
        facilityName: facilityData.facility.name,
        facilityType: facilityData.facility.type,
        viewFacilityType: facilityData.facility.type,
        isPharmacyCheck: facilityData.facility.ispharmacy,
        description: facilityData.facility.description,
        locationList: facilityData.facility.facilityLocationList,
        facilityAppointmentList: facilityAppointmentList,
        title: title,
        pageId: pageId,
        action: action,
        render: true,
        previousButton: previousButton,
        pageSize: pageSize,
        isPaging: false,
        facilityDetails: facilityData.facility,
        isFreeUser: this.isFreeUser,
        stepsEnabled: false,
        initialStep: 0,
        steps: [
          {
            element: '.editDetails',
            intro: 'Edit/update Facility Details',
          },

          {
            element: '.addLocation',
            intro: 'Add multiple Location Details',
          },
          {
            element: '.deleteIcon',
            intro: 'Delete this Location',
          },
          {
            element: '.editLocation',
            intro: 'Update this Location',
          },
          {
            element: '.facility_Appointment_List',
            intro: 'List of all appointments with this Facility.',
          },
          {
            element: '.newAppointment',
            intro: 'Create new Appointment with this Facility',
          },
          {
            element: '.facilityAppointmentAction',
            intro:
              '<b>Hierarchy </b>– See hierarchy of appointments. This can be done by adding reference appointments,<br><b> Messages</b> – Messages related to this Appointment, <br><b>Transportation </b>– Transportation requests related to this Appointment,<br> <b>Reminders </b>– Reminders for this Appointment, ',
          },
          {
            element: '.delete',
            intro: 'Delete Facility Details',
          },
        ],
        displayFloatButton: this.displayHelp,
        showMapView: false,
        showLocationView: true,
        appointmentId: appointmentId,
        initialPage: initialPage,
        isStatus: status,
        isPharmacyCheck: false,
        shareContactEmail: '',
        shareDoctorModal: false,
      };
    } else {
      if (action === 'view' || action === 'edit') {
        this.state = {
          id: facilityId,
          action: action,
          render: canRender,
          facilityName: '',
          description: '',
          facilityType: '',
          viewFacilityType: '',
          location: {},
          locationList: [],
          locationItems: [],
          title: title,
          pageId: pageId,
          locationItemsView: true,
          facilityDetails: {},
          previousButton: previousButton,
          facilityAppointmentList: [],
          viewFacility: false,
          pageSize: pageSize,
          isPaging: false,
          getAllPermission: false,
          getUpdatePermission: false,
          getDeletePermission: false,
          getReadPermission: false,
          getCreatePermission: false,
          isFreeUser: this.isFreeUser,
          stepsEnabled: false,
          addressLocations: [],
          initialStep: 0,
          steps: [
            {
              element: '.editDetails',
              intro: 'Edit/update Facility Details',
            },

            {
              element: '.addLocation',
              intro: 'Add multiple Location Details',
            },
            {
              element: '.deleteIcon',
              intro: 'Delete This Location',
            },
            {
              element: '.editLocation',
              intro: 'Update This Location',
            },
            {
              element: '.facility_Appointment_List',
              intro: 'List of all appointments with this Facility.',
            },
            {
              element: '.newAppointment',
              intro: 'Create new Appointment with this Facility',
            },
            {
              element: '.facilityAppointmentAction',
              intro:
                '<b>Hierarchy </b>– See hierarchy of appointments. This can be done by adding reference appointments,<br><b> Messages</b> – Messages related to this Appointment, <br><b>Transportation </b>– Transportation requests related to this Appointment,<br> <b>Reminders </b>– Reminders for this Appointment, ',
            },
            {
              element: '.delete',
              intro: 'Delete Facility Details',
            },
          ],
          displayFloatButton: this.displayHelp,
          showMapView: false,
          showLocationView: true,
          appointmentId: appointmentId,
          initialPage: initialPage,
          isStatus: true,
          website: '',
          websiteuser: '',
          isPharmacyCheck: false,
          shareContactEmail: '',
          shareDoctorModal: false,
        };
      } else {
        this.state = {
          id: facilityId,
          action: action,
          render: canRender,
          facilityName: '',
          description: '',
          facilityType: '',
          viewFacilityType: '',
          location: {},
          locationList: [],
          locationItems: [],
          title: title,
          pageId: pageId,
          locationItemsView: true,
          previousButton: previousButton,
          facilityAppointmentList: [],
          viewFacility: false,
          pageSize: pageSize,
          isPaging: false,
          facilityDetails: {},
          getAllPermission: false,
          getUpdatePermission: false,
          getDeletePermission: false,
          getReadPermission: false,
          getCreatePermission: false,
          isFreeUser: this.isFreeUser,
          addressLocations: [],
          stepsEnabled: false,
          initialStep: 0,
          steps: [
            {
              element: '.editDetails',
              intro: 'Edit/update Facility Details',
            },

            {
              element: '.addLocation',
              intro: 'Add multiple Location Details',
            },
            {
              element: '.deleteIcon',
              intro: 'Delete This Location',
            },
            {
              element: '.editLocation',
              intro: 'Update This Location',
            },
            {
              element: '.facility_Appointment_List',
              intro: 'List of all appointments with this Facility.',
            },
            {
              element: '.newAppointment',
              intro: 'Create new Appointment with this Facility',
            },
            {
              element: '.facilityAppointmentAction',
              intro:
                '<b>Hierarchy </b>– See hierarchy of appointments. This can be done by adding reference appointments,<br><b> Messages</b> – Messages related to this Appointment, <br><b>Transportation </b>– Transportation requests related to this Appointment,<br> <b>Reminders </b>– Reminders for this Appointment, ',
            },
            {
              element: '.delete',
              intro: 'Delete Facility Details',
            },
          ],
          displayFloatButton: this.displayHelp,
          showMapView: false,
          showLocationView: true,
          appointmentId: appointmentId,
          initialPage: initialPage,
          isStatus: true,
          website: '',
          websiteuser: '',
          isPharmacyCheck: false,
          shareContactEmail: '',
          shareDoctorModal: false,
        };
        this.addLocation('defaultCreate');
      }
    }
    window.scrollTo(0, 0);
  }

  closeFloatingButton() {
    this.setState({ displayFloatButton: false });
    let displayFloat = false;

    store.dispatch(helpDataAction.setHelpData(displayFloat));
  }
  showMap() {
    this.setState({
      showMapView: true,
      showLocationView: false,
    });
  }

  closeButton() {
    this.setState({
      showMapView: false,
      showLocationView: true,
    });
  }

  toggleSteps = () => {
    this.setState((prevState) => ({ stepsEnabled: !prevState.stepsEnabled }));
  };

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };
  handleFacilityNameChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ facilityName: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }
  handleFacilityTypeChange(e) {
    this.setState({ facilityType: e.target.value });
  }

  handleDescriptionChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 256);
    if (res.length < 256) {
      this.setState({ description: res });
    } else {
      Utils.maxFieldLength(256);
    }
  }

  async getFacilityDetails(facilityId) {
    const postObject = {
      header: this.header,
      facility: {
        id: facilityId,
      },
    };
    await FetchServerData.callPostService('/facilityms/getFacilityWithAppointments', postObject).then(
      async (output) => {
        if (output.status === 'SUCCESS') {
          let facilityData = output.data.facilityWithAppointment;

          let getDomainObjectPermission = facilityData.allowedDomainObjects;
          let getDomainObjectPrivileges = output.data.domainObjectPrivileges;

          let getCreatePermission = false;
          let getReadPermission = false;
          let getUpdatePermission = false;
          let getDeletePermission = false;

          for (let domainObjectKey in getDomainObjectPrivileges) {
            if (domainObjectKey.toLowerCase() === 'facility') {
              let facilityPermission = getDomainObjectPrivileges[domainObjectKey];
              let getPermission = Utils.getDomainObjectRolePermission(facilityPermission);
              getCreatePermission = getPermission[0];
              getReadPermission = getPermission[1];
              getUpdatePermission = getPermission[2];
              getDeletePermission = getPermission[3];
            }
          }
          let isPaging = false;

          let googleLocationAPIKey = Utils.getLocationAPIKey();

          Geocode.setApiKey(googleLocationAPIKey);
          Geocode.setLanguage('en');
          Geocode.setLocationType('ROOFTOP');
          Geocode.enableDebug();
          let facilityAppointmentList = [];
          if (output.data.facilityWithAppointment.facilityAppointmentList) {
            facilityAppointmentList = output.data.facilityWithAppointment.facilityAppointmentList;
          } else {
            facilityAppointmentList = [];
          }
          if (facilityAppointmentList.length > 10) {
            isPaging = true;
          }

          let showAppointmentPermission = false;
          for (let domainObjectKey in getDomainObjectPermission) {
            if (domainObjectKey === 'Appointment') {
              let appointmentPermission = getDomainObjectPermission[domainObjectKey];

              showAppointmentPermission = true;
            }
          }
          let locaData1 = [];
          for (let i = 0; i < facilityData.facility.facilityLocationList.length; i++) {
            let locdata = await Utils.getLatLongMapData(
              facilityData.facility.facilityLocationList[i].shortDescription,
              facilityData.facility.facilityLocationList[i].address.line,
              facilityData.facility.facilityLocationList[i].address.city,
              facilityData.facility.facilityLocationList[i].address.state.country.name,
              facilityData.facility.facilityLocationList[i].address.state.name,
              i
            );
            locaData1.push(locdata);
          }
          let status = facilityData.facility.status.status;
          let doctorStatus = '';
          if (status === 'Active') {
            doctorStatus = true;
          } else {
            doctorStatus = false;
          }
          this.setState({
            render: true,
            viewFacility: true,
            facilityDetails: facilityData.facility,
            isPaging: isPaging,
            addressLocations: locaData1,
            facilityId: facilityData.facility.id,
            facilityName: facilityData.facility.name,
            facilityType: facilityData.facility.type,
            viewFacilityType: facilityData.facility.type,
            description: facilityData.facility.description,
            website: facilityData.facility.website,
            websiteuser: facilityData.facility.websiteuser,
            locationList: facilityData.facility.facilityLocationList,
            facilityAppointmentList: facilityAppointmentList,
            isPharmacyCheck: facilityData.facility.ispharmacy,
            getUpdatePermission: getUpdatePermission,
            getDeletePermission: getDeletePermission,
            getReadPermission: getReadPermission,
            getCreatePermission: getCreatePermission,
            isStatus: doctorStatus,
            showAppointmentPermission: showAppointmentPermission,
          });
        } else {
        }
      }
    );
  }

  deleteLocation(item) {
    let items = [];
    if (item.operation && 'update' === item.operation) {
      /* Existing Item Deleted, Mark it deleted */
      let index = item.index;

      for (let i = 0; i < this.state.locationList.length; i++) {
        let item = this.state.locationList[i];
        if (this.state.locationList[i].operation === 'update' && index === i) {
          item.operation = 'delete';
        }
        items.push(item);
      }
      this.setState({ locationList: items });
    }
    if (item.operation && 'add' === item.operation) {
      /* Added Item Deleted, Remove from list */
      let index = item.index;
      for (let i = 0; i < this.state.locationList.length; i++) {
        if (this.state.locationList[i].operation === 'add' && index === i) {
          this.locationReferenceList.splice(i, 1);
          // continue;
        } else {
          items.push(this.state.locationList[i]);
        }
      }
      this.setState({ locationList: items });
    }

    /*Go Back to Location list view if all new Locations are deleted */
    let flag = true;
    for (let i = 0; i < items.length; i++) {
      if (items[i].operation === 'add') {
        flag = false;
      }
    }
    if (flag) {
      this.backToLocationView();
    }
  }

  updateLocation(locationData) {
    for (let i = 0; i < this.state.locationList.length; i++) {
      if (
        this.state.locationList[i] &&
        locationData.index === i &&
        this.locationReferenceList[i] &&
        this.locationReferenceList[i].current &&
        this.locationReferenceList[i].current.validateLocation()
      ) {
        this.state.locationList[i] = locationData;
        this.locationReferenceList[i].current.state = locationData;
        this.locationReferenceList[i].current.state.action = 'editLocation';
        this.setState({ render: true });
      }
    }
  }

  handleLocationChanged(item) {}

  addLocation = (action) => {
    let items = this.state.locationList;
    let item = {
      action: action,
      operation: 'add',
      facility: {
        id: this.state.id,
      },
      shortDescription: '',
      nickName: '',
      fax: '',
      website: '',
      phone: '',
      address: {
        line: '',
        city: '',
        zipCode: '',
        state: {
          code: '',
        },
      },
    };
    items.push(item);

    this.setState({ locationList: items });
    this.setState({ locationList: items, locationItemsView: false, locationItemsCreateDiv: true });
  };

  createCancel() {
    if (this.state.pageId === 'addmemberFacility') {
      this.props.history.push({
        pathname: '/addAppointment',
        state: {
          action: 'create',
          title: 'Add Appointment',
          pageId: 'addAppointment',
          appointmentId: '',
        },
      });
    } else {
      this.props.history.push({
        pathname: '/memberFacility',
      });
    }
  }

  viewCancel() {
    this.props.history.push({
      pathname: '/memberFacility',
    });
  }

  backToLocationView() {
    this.setState({ locationItemsView: true, locationItemsCreateDiv: false });
  }

  facilityCancel() {
    this.setState({ facilityViewDiv: true, facilityEditDiv: false });
  }

  locationEditCancel() {
    this.setState({ locationItemsView: true, locationItemsCreateDiv: false });
  }

  addLocationData() {}

  editCancel(e, facilityId) {
    this.props.history.push({
      pathname: '/viewFacility',
      state: { action: 'view', title: 'Facility Details', facilityId: facilityId },
    });
  }

  cancelEditFacilityDetails() {
    this.setState({
      viewFacility: true,
      editFacility: false,
    });
  }

  addFacilityInBackend = (e) => {
    e.preventDefault();

    let locations = [];
    for (let i = 0; i < this.locationReferenceList.length; i++) {
      if (null !== this.locationReferenceList[i].current) {
        let location = {};
        location.id = this.locationReferenceList[i].current.state.id;
        location.shortDescription = this.locationReferenceList[i].current.state.shortDescription;
        location.nickName = this.locationReferenceList[i].current.state.nickName;
        location.fax = this.locationReferenceList[i].current.state.fax;
        location.website = this.locationReferenceList[i].current.state.website;
        location.phone = this.locationReferenceList[i].current.state.phone.replace(/[^\d]/g, '');
        location.address = {};
        location.address.id = this.locationReferenceList[i].current.state.address.id;
        location.address.line = this.locationReferenceList[i].current.state.address.line;
        location.address.city = this.locationReferenceList[i].current.state.address.city;
        location.address.zipCode = this.locationReferenceList[i].current.state.address.zipCode;
        location.address.state = this.locationReferenceList[i].current.state.address.state;
        locations[i] = location;
      }
    }

    const postObject = {
      header: this.header,
      facility: {
        name: this.state.facilityName,
        description: this.state.description,
        website: this.state.website,
        websiteuser: this.state.websiteuser,
        member: {
          id: this.memberDetails.member,
        },
        type: this.state.facilityType,
        facilityLocationList: locations,
        ispharmacy: this.state.isPharmacyCheck,
      },
    };
    FetchServerData.callPostService('/facilityms/create', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let facilityDetails = output.data;

          PtsAlert.success(output.message);
          let fccPageState = {
            id: facilityDetails.facility.id,
            action: 'view',
            title: 'Facility Details',
          };

          if (this.state.pageId === 'addmemberFacility') {
            store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
            this.props.history.push({
              pathname: '/facilityAppointments',
              state: {
                action: 'createNewAppointment',
                title: 'Add Appointment',
                pageId: 'viewMemberFacilitys',
                facility: facilityDetails.facility,
              },
            });
          } else {
            store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
            this.props.history.push({
              pathname: '/viewFacility',
              state: {
                action: 'view',
                title: 'Facility Details',
                facilityData: facilityDetails,
                pageId: 'createFacility',
              },
            });
          }
        } else {
        }
      })
      .catch((error) => {});
  };

  updateFacilityInBackend = (e) => {
    let doctorStatus = {};
    if (this.state.isStatus === true) {
      doctorStatus = { status: 'Active', id: 1 };
    } else {
      doctorStatus = { status: 'Inactive', id: 2 };
    }
    const postObject = {
      header: this.header,
      facility: {
        id: this.state.id,
        name: this.state.facilityName,
        description: this.state.description,
        website: this.state.website,
        websiteuser: this.state.websiteuser,
        member: {
          id: this.memberDetails.member,
        },
        type: this.state.facilityType,
        status: doctorStatus,
        ispharmacy: this.state.isPharmacyCheck,
      },
    };
    FetchServerData.callPostService('/facilityms/update', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let facilityData = output.data.facility;
          PtsAlert.success(output.message);
          let status = facilityData.status.status;
          let doctorStatus = '';
          if (status === 'Active') {
            doctorStatus = true;
          } else {
            doctorStatus = false;
          }
          this.setState({
            render: true,
            viewFacility: true,
            editFacility: false,
            facilityId: facilityData.id,
            facilityName: facilityData.name,
            facilityType: facilityData.type,
            viewFacilityType: facilityData.type,
            description: facilityData.description,
            isStatus: doctorStatus,
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  addNewLocationInBackend(locationData) {
    let location = {};
    for (let i = 0; i < this.state.locationList.length; i++) {
      if (
        this.state.locationList[i] &&
        locationData.index === i &&
        this.locationReferenceList[i] &&
        this.locationReferenceList[i].current &&
        this.locationReferenceList[i].current.validateLocation()
      ) {
        location.operation = this.locationReferenceList[i].current.state.operation;
        location.shortDescription = this.locationReferenceList[i].current.state.shortDescription;
        location.nickName = this.locationReferenceList[i].current.state.nickName;
        location.fax = this.locationReferenceList[i].current.state.fax;
        location.website = this.locationReferenceList[i].current.state.website;
        location.phone = this.locationReferenceList[i].current.state.phone.replace(/[^\d]/g, '');
        location.address = {};
        location.address.id = this.locationReferenceList[i].current.state.address.id;
        location.address.line = this.locationReferenceList[i].current.state.address.line;
        location.address.city = this.locationReferenceList[i].current.state.address.city;
        location.address.zipCode = this.locationReferenceList[i].current.state.address.zipCode;
        location.address.state = this.locationReferenceList[i].current.state.address.state;
        location.facility = {};
        location.facility.id = this.state.id;
        location.facility.member = {};
        location.facility.member.id = this.memberDetails.member;
        const postObject = {
          header: this.header,

          facilityLocation: location,
        };

        FetchServerData.callPostService('facilityms/createLocation', postObject).then((output) => {
          if (output.status === 'SUCCESS') {
            let facilityData = output.data;

            PtsAlert.success(output.message);
            this.state.locationList = [];
            this.locationReferenceList[i].current.state.action = 'editLocation';
            this.setState({
              locationList: output.data.facility.facilityLocationList,
            });
          } else {
          }
        });
      }
    }
  }

  updateLocationToBackend(locationData) {
    let location = {};
    for (let i = 0; i < this.state.locationList.length; i++) {
      if (
        this.state.locationList[i] &&
        locationData.index === i &&
        this.locationReferenceList[i] &&
        this.locationReferenceList[i].current &&
        this.locationReferenceList[i].current.validateLocation()
      ) {
        location.id = this.locationReferenceList[i].current.state.id;
        location.operation = this.locationReferenceList[i].current.state.operation;
        location.shortDescription = this.locationReferenceList[i].current.state.shortDescription;
        location.nickName = this.locationReferenceList[i].current.state.nickName;
        location.fax = this.locationReferenceList[i].current.state.fax;
        location.website = this.locationReferenceList[i].current.state.website;
        location.phone = this.locationReferenceList[i].current.state.phone.replace(/[^\d]/g, '');
        location.address = {};
        location.address.id = this.locationReferenceList[i].current.state.address.id;
        location.address.line = this.locationReferenceList[i].current.state.address.line;
        location.address.city = this.locationReferenceList[i].current.state.address.city;
        location.address.zipCode = this.locationReferenceList[i].current.state.address.zipCode;
        location.address.state = this.locationReferenceList[i].current.state.address.state;
        location.facility = {};
        location.facility.id = this.state.id;

        const postObject = {
          header: this.header,

          facilityLocation: location,
        };
        FetchServerData.callPostService('facilityms/updateLocation', postObject).then((output) => {
          if (output.status === 'SUCCESS') {
            let facilityData = output.data;
            let facilityAppointmentList = output.data.facilityAppointmentList;
            PtsAlert.success(output.message);
            this.getFacilityDetails(this.state.facilityId);
            this.state.locationList = [];
            this.setState({
              locationList: output.data.facility.facilityLocationList,
              facilityAppointmentList: facilityAppointmentList,
            });
          } else {
          }
        });
      }
    }
  }

  deleteFacilityInBackend = () => {
    const postObject = {
      header: this.header,
      facility: {
        id: this.state.id,
      },
    };

    FetchServerData.callPostService('/facilityms/delete/', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        PtsAlert.success(output.message);
        this.props.history.push({
          pathname: '/memberFacility',
          sweetAlert: false,
        });
      } else {
      }
    });
  };

  deleteLocationToBackend = (facilityLocation) => {
    const postObject = {
      header: this.header,
      facilityLocation: {
        id: facilityLocation.id,
        facility: { id: this.state.id },
      },
    };

    FetchServerData.callPostService('facilityms/deleteLocation', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          for (let i = 0; i < this.state.locationList.length; i++) {
            if (this.state.locationList[i].id === facilityLocation.id) {
              this.state.locationList.splice(i, 1);
            }
          }
          this.setState({
            locationList: this.state.locationList,
            sweetAlert: false,
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  updateFacilityDetails() {
    this.setState({
      viewFacility: false,
      editFacility: true,
    });
  }

  toAppointmentDetails = (e, data) => {
    let appointmentFacilityState = {
      initialPage: this.state.initialPage,
      appointmentId: data.id,
      pageSize: this.state.pageSize,
    };
    store.dispatch(doctorFacilityStateAction.setDoctorFacilityState(appointmentFacilityState));
    this.props.history.push({
      pathname: '/viewMemberAppointment',
      state: {
        action: 'view',
        title: 'Appointment Details',
        appointmentId: data.id,
        facilityViewId: this.state.id,
        pageId: 'viewFacility',
      },
    });
  };
  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }
  addAppointment = (e) => {
    store.dispatch(fccPageStateAction.setFccPageState({}));
    this.props.history.push({
      pathname: '/facilityAppointments',
      state: {
        action: 'repeatAppointment',
        title: 'Add Appointment',
        pageId: 'viewMemberFacilitys',
        facility: this.state.facilityDetails,
      },
    });
  };

  displayActionTitle() {
    return <h5 className="facilityAppointmentAction">Action</h5>;
  }

  MaterialTableTitle(title) {
    return (
      <Typography
        style={{
          fontWeight: 'bold',
          textTransform: 'capitalize',
          color: '#0099f1',
          display: 'flex',
        }}
        className="facility_Appointment_List"
      >
        {'Appointments For' + ' ' + (this.state.facilityName ? this.state.facilityName : '')}
      </Typography>
    );
  }

  getMapView(locations) {
    return <Map data={locations}></Map>;
  }

  handleStatusChange(e) {
    this.setState({ isStatus: !this.state.isStatus });
  }
  handleWebsiteChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 4000);
    if (res.length < 4000) {
      this.setState({ website: res });
    } else {
      Utils.maxFieldLength(4000);
    }
  }

  handleWebsiteuserChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 110);
    if (res.length < 110) {
      this.setState({ websiteuser: res });
    } else {
      Utils.maxFieldLength(110);
    }
  }

  ShareDoctorInfoToBackEnd = () => {
    const postObject = {
      header: this.header,

      doctorSharingInfo: {
        doctorid: null,
        targetemail: this.state.shareContactEmail,
        facilityid: this.state.id,
      },
    };

    FetchServerData.callPostService('/infoSharingMs/create', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          PtsAlert.success(output.message);
          this.setState({ shareDoctorModal: false, shareContactEmail: '' });
        } else {
        }
      })
      .catch((error) => {});
  };
  render() {
    this.locationReferenceList = [];
    this.state.locationList.forEach((element, index) => {
      this.locationReferenceList[index] = React.createRef();
    });
    if (this.state.action === 'create') {
      return this.createFacilityDetails();
    } else if (this.state.action === 'view') {
      return this.viewFacilityDetails();
    }
  }
  handleClickCheckBox = (e) => {
    this.setState({
      isPharmacyCheck: e.target.checked,
    });
  };
  handleOpenShareContactModal() {
    this.setState({ shareDoctorModal: true });
  }

  handleCloseShareContactModal() {
    this.setState({ shareDoctorModal: false });
  }
  handleShareContactEmailChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);

    if (res.length < 64) {
      this.setState({ shareContactEmail: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }
  createFacilityDetails() {
    let locationItems = [];

    this.state.locationList.forEach((element, index) => {
      if ('delete' !== element.operation) {
        locationItems.push(
          <Col md={12}>
            <Location
              ref={this.locationReferenceList[index]}
              key={index}
              data={element}
              srNo={index}
              action={element.action}
              onCancel={this.deleteLocation.bind(this)}
              onDelete={this.deleteLocationToBackend.bind(this)}
              onLocationAdd={this.addNewLocationInBackend.bind(this)}
              onUpdate={this.updateLocation.bind(this)}
            />
          </Col>
        );
      }
    });

    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={'Create Facility For' + ' ' + this.memberName}
          domainObject="Facility"
        />
        <ValidatorForm style={{ width: '100%' }} ref="form" onSubmit={this.addFacilityInBackend.bind(this)}>
          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={4} style={{ position: 'relative', padding: '5px' }}>
              <Card style={{ height: '100%', borderRadius: '20px' }}>
                <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.5em' }}>
                  <Row>
                    <Col md={12}>
                      {undefined !== this.state.title && this.state.title.length > 0 && (
                        <Label style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em' }}>
                          {this.state.title}
                        </Label>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody style={{ padding: '1em', marginTop: '0em' }}>
                  <Row>
                    <Col md={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        required
                        margin="normal"
                        id="name"
                        label="Facility Name"
                        value={this.state.facilityName}
                        onChange={this.handleFacilityNameChange.bind(this)}
                        name="name"
                        inputprops={{ maxLength: 64 }}
                        type="text"
                        className="textField"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <input
                        className="pts_dataList_dropDown"
                        type="text"
                        id="demo-simple-select-outlined"
                        list="data"
                        required
                        placeholder="Facility Type*"
                        label="Facility Type*"
                        value={this.state.facilityType}
                        onChange={(event, value) => {
                          this.handleFacilityTypeChange(event, value);
                        }}
                      />
                      <datalist id="data">
                        {this.facilityTypeList.map((event, key) => (
                          <option key={event.type} value={event.type} />
                        ))}
                      </datalist>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '1em' }}>
                    <Col md={12}>
                      <TextareaAutosize
                        aria-label="minimum height"
                        rowsMin={4}
                        id="description"
                        value={this.state.description}
                        inputprops={{ maxLength: 256, padding: '10px' }}
                        name="note"
                        style={{ width: '100%', padding: '10px', border: '0.5px solid #D5D8DC' }}
                        onChange={this.handleDescriptionChange.bind(this)}
                        placeholder="Facility Description"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <TextField
                        margin="normal"
                        variant="outlined"
                        type="text"
                        fullWidth
                        id="website"
                        onChange={this.handleWebsiteChange.bind(this)}
                        label="Facility Portal"
                        name="website"
                        value={this.state.website}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <TextField
                        margin="normal"
                        variant="outlined"
                        type="text"
                        fullWidth
                        id="websiteuser"
                        onChange={this.handleWebsiteuserChange.bind(this)}
                        label="Portal Username"
                        name="websiteuser"
                        value={this.state.websiteuser}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '1em' }}>
                    <Col md={2}>
                      <label> Status</label>
                    </Col>
                    <Col md={10} className="statusMember">
                      {this.state.isStatus === true && (
                        <Tooltip title="Please click EDIT to change status">
                          <span>
                            <ToggleButton
                              value="Active"
                              className="ToggleButtonActive"
                              onChange={this.handleStatusChange.bind(this)}
                              style={{
                                background: '#27AE60',
                                color: '#fff',
                                height: '25px',
                                textTransform: 'none',
                              }}
                            >
                              Active
                            </ToggleButton>
                          </span>
                        </Tooltip>
                      )}
                      {this.state.isStatus === false && (
                        <Tooltip title="Please click EDIT to change status">
                          <span>
                            <ToggleButton
                              value="Inactive"
                              className="ToggleButtonInActive"
                              onChange={this.handleStatusChange.bind(this)}
                              style={{
                                background: '#c7c7c7',
                                color: '#000',
                                height: '25px',
                                textTransform: 'none',
                              }}
                            >
                              Inactive
                            </ToggleButton>
                          </span>
                        </Tooltip>
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <label> Is this Pharmacy ? </label>
                    </Col>
                    <Col md={6}>
                      <span style={{ color: '#162c50', margin: '1.1em 1em' }}>
                        {' '}
                        <Checkbox
                          style={{ color: 'blue' }}
                          checked={this.state.isPharmacyCheck}
                          onChange={this.handleClickCheckBox}
                        />
                      </span>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={8} style={{ position: 'relative', padding: '5px' }}>
              <Card style={{ height: '100%', borderRadius: '20px' }}>
                <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.5em' }}>
                  <Row>
                    <Col md={12}>
                      {undefined !== this.state.title && this.state.title.length > 0 && (
                        <Label style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em' }}>Locations</Label>
                      )}
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody style={{ padding: '1em', marginTop: '0em' }}>
                  <Row style={{ marginTop: '11px' }}>{locationItems} </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <div style={{ textAlign: 'center', padding: '1em' }}>
            <CreateButton>Create</CreateButton>
            &nbsp;
            <CancelIconButton onClick={(e) => this.createCancel()} />
          </div>
        </ValidatorForm>
      </>
    );
  }

  viewFacilityDetails() {
    if (this.state.render === false) {
      return <div />;
    } else {
      if (this.state.isFreeUser === true) {
        return this.viewFacilityDetailsWithAdvertisement();
      } else {
        return this.viewFacilityDetailsWithoutAdvertisement();
      }
    }
  }

  viewFacilityDetailsWithAdvertisement() {
    let locationItems = [];
    if (this.state.render === false) {
      return <div />;
    } else {
      this.state.locationList.forEach((element, index) => {
        if ('delete' !== element.operation) {
          locationItems.unshift(
            <Col md={12}>
              <Location
                ref={this.locationReferenceList[index]}
                key={index}
                data={element}
                srNo={index}
                getDeletePermission={this.state.getDeletePermission}
                getUpdatePermission={this.state.getUpdatePermission}
                pageId="editFacility"
                action={element.action ? element.action : 'editLocation'}
                onCancel={this.deleteLocation.bind(this)}
                onDelete={this.deleteLocationToBackend.bind(this)}
                onLocationAdd={this.addNewLocationInBackend.bind(this)}
                onUpdate={this.updateLocationToBackend.bind(this)}
              />
            </Col>
          );
        }
      });

      return (
        <>
          <MenuWithBreadScrum
            backCallback={this.viewCancel.bind(this)}
            deleteCallback={this.deleteFacilityInBackend.bind(this)}
            shareCallback={this.handleOpenShareContactModal.bind(this)}
            getDeletePermission={this.state.getDeletePermission}
            getUpdatePermission={this.state.getUpdatePermission}
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            name={this.title + ' For ' + this.memberName}
            domainObject="Facility"
          />
          <Steps
            enabled={this.state.stepsEnabled}
            steps={this.state.steps}
            initialStep={this.state.initialStep}
            onExit={this.onExit}
          />
          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={4} style={{ padding: '5px' }}>
              <Card style={{ height: '100%', borderRadius: '20px' }}>
                {this.state.viewFacility && (
                  <>
                    {' '}
                    <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                      <Row>
                        <Col md={10}>
                          {undefined !== this.state.title && this.state.title.length > 0 && (
                            <Label
                              style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                            >
                              Facility Details
                            </Label>
                          )}
                        </Col>
                        <Col md={2}>
                          {this.state.getUpdatePermission && (
                            <EditIconButton onClick={this.updateFacilityDetails.bind(this)} />
                          )}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      {this.state.facilityName && (
                        <Row>
                          <Col md={5}>
                            <label>Facility Name</label>
                          </Col>
                          <Col md={7}>
                            {' '}
                            <span>{this.state.facilityName}</span>
                          </Col>
                        </Row>
                      )}
                      {this.state.viewFacilityType && (
                        <Row>
                          <Col md={5}>
                            <label>Facility Type</label>
                          </Col>
                          <Col md={7}>
                            {' '}
                            <span>{this.state.viewFacilityType}</span>
                          </Col>
                        </Row>
                      )}
                      {this.state.description && (
                        <Row>
                          <Col md={5}>
                            <label>Description</label>
                          </Col>
                          <Col md={7}>
                            <span>
                              <pre className="view_facilityDesc"> {this.state.description}</pre>
                            </span>
                          </Col>
                        </Row>
                      )}
                      {this.state.website && (
                        <Row>
                          <Col md={5}>
                            <label>Facility Portal</label>
                          </Col>
                          <Col md={7}>
                            {' '}
                            <span>
                              <a
                                href={
                                  this.state.website.includes('https://') || this.state.website.includes('http://')
                                    ? this.state.website
                                    : 'http://' + this.state.website
                                }
                                target="blank"
                              >
                                {this.state.website}
                              </a>
                            </span>
                          </Col>
                        </Row>
                      )}
                      {this.state.websiteuser && (
                        <Row>
                          <Col md={5}>
                            <label>Portal Username</label>
                          </Col>
                          <Col md={7}>
                            {' '}
                            <span>
                              <a href={'webuserto:' + this.state.websiteuser} target="_top">
                                {this.state.websiteuser}
                              </a>
                              <Tooltip title="copy" aria-label="add">
                                <IconButton style={{ marginLeft: '30px' }}>
                                  <CopyToClipboard text={this.state.websiteuser} onClick={this.onCopy}>
                                    <FileCopyIcon style={{ color: 'gray', fontSize: '20px' }} />
                                  </CopyToClipboard>
                                </IconButton>
                              </Tooltip>
                            </span>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col md={5}>
                          <label> Status </label>
                        </Col>
                        <Col md={7} className="statusMember">
                          {this.state.isStatus === true && (
                            <Tooltip title="Please click EDIT to change status">
                              <span>
                                <ToggleButton
                                  value="Active"
                                  className="ToggleButtonActive"
                                  onChange={this.handleStatusChange.bind(this)}
                                  style={{
                                    background: '#27AE60',
                                    color: '#fff',
                                    height: '25px',
                                    textTransform: 'none',
                                  }}
                                  disabled
                                >
                                  Active
                                </ToggleButton>
                              </span>
                            </Tooltip>
                          )}
                          {this.state.isStatus === false && (
                            <Tooltip title="Please click EDIT to change status">
                              <span>
                                <ToggleButton
                                  value="Inactive"
                                  className="ToggleButtonInActive"
                                  onChange={this.handleStatusChange.bind(this)}
                                  style={{
                                    background: '#D7DBDD',
                                    color: '#000',
                                    height: '25px',
                                    textTransform: 'none',
                                  }}
                                  disabled
                                >
                                  Inactive
                                </ToggleButton>
                              </span>
                            </Tooltip>
                          )}
                        </Col>
                      </Row>{' '}
                      {/* <Row>
                        <Col md={5}>
                          <span style={{ color: '#162c50', margin: '1.1em 1em' }}>
                            {' '}
                            <Checkbox
                              disabled
                              style={{ color: 'blue' }}
                              checked={this.state.isPharmacyCheck}
                              onChange={this.handleClickCheckBox}
                            />
                          </span>
                        </Col>
                        <Col md={7}>
                          <span> Is this Pharmacy ? </span>
                        </Col>
                      </Row> */}
                      <Row style={{ marginTop: '1em' }}>
                        <Col md={5}>
                          <label> Is this Pharmacy ? </label>
                        </Col>
                        <Col md={7}>
                          {this.state.isPharmacyCheck === true && (
                            <span>
                              <ToggleButton
                                disabled
                                value="Yes"
                                className="ToggleButtonActive"
                                style={{
                                  background: '#27AE60',
                                  color: '#fff',
                                  height: '25px',
                                  textTransform: 'none',
                                }}
                              >
                                Yes
                              </ToggleButton>
                            </span>
                          )}
                          {this.state.isPharmacyCheck === false && (
                            <span>
                              <ToggleButton
                                disabled
                                value="No"
                                className="ToggleButtonInActive"
                                style={{
                                  background: '#c7c7c7',
                                  color: '#000',
                                  height: '25px',
                                  textTransform: 'none',
                                }}
                              >
                                No
                              </ToggleButton>
                            </span>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </>
                )}

                {this.state.editFacility && (
                  <ValidatorForm
                    style={{ width: '100%' }}
                    ref="form"
                    onSubmit={this.updateFacilityInBackend.bind(this)}
                  >
                    <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                      <Row>
                        <Col md={9}>
                          <Label
                            style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                          >
                            Edit Facility Details
                          </Label>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row style={{ marginTop: '1em' }}>
                        <Col md={12}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            required
                            id="name"
                            label="Facility Name"
                            value={this.state.facilityName}
                            inputprops={{ maxLength: 64 }}
                            onChange={this.handleFacilityNameChange.bind(this)}
                            name="name"
                            type="text"
                            className="textField"
                          />
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '1em' }}>
                        <Col md={12}>
                          <input
                            className="pts_dataList_dropDown"
                            type="text"
                            required
                            id="demo-simple-select-outlined"
                            list="data"
                            placeholder="Facility Type*"
                            label="Facility Type*"
                            value={this.state.facilityType}
                            onChange={(event, value) => {
                              this.handleFacilityTypeChange(event, value);
                            }}
                          />
                          <datalist id="data">
                            {this.facilityTypeList.map((event, key) => (
                              <option key={event.type} value={event.type} />
                            ))}
                          </datalist>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '0.5em' }}>
                        <Col md={12}>
                          <TextareaAutosize
                            aria-label="minimum height"
                            rowsMin={4}
                            id="description"
                            value={this.state.description}
                            inputprops={{ maxLength: 256, padding: '10px' }}
                            name="note"
                            onChange={this.handleDescriptionChange.bind(this)}
                            placeholder="Facility Description"
                            style={{ width: '100%', padding: '10px', border: '0.5px solid #D5D8DC' }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            fullWidth
                            id="website"
                            onChange={this.handleWebsiteChange.bind(this)}
                            label="Facility Portal"
                            name="website"
                            value={this.state.website}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            fullWidth
                            id="websiteuser"
                            onChange={this.handleWebsiteuserChange.bind(this)}
                            label="Portal Username"
                            name="websiteuser"
                            value={this.state.websiteuser}
                          />
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '1em' }}>
                        <Col md={2}>
                          <label> Status</label>
                        </Col>
                        <Col md={10} className="statusMember">
                          {this.state.isStatus === true && (
                            <Tooltip title="Please click EDIT to change status">
                              <span>
                                <ToggleButton
                                  value="Active"
                                  className="ToggleButtonActive"
                                  onChange={this.handleStatusChange.bind(this)}
                                  style={{
                                    background: '#27AE60',
                                    color: '#fff',
                                    height: '25px',
                                    textTransform: 'none',
                                  }}
                                >
                                  Active
                                </ToggleButton>
                              </span>
                            </Tooltip>
                          )}
                          {this.state.isStatus === false && (
                            <Tooltip title="Please click EDIT to change status">
                              <span>
                                <ToggleButton
                                  value="Inactive"
                                  className="ToggleButtonInActive"
                                  onChange={this.handleStatusChange.bind(this)}
                                  style={{
                                    background: '#c7c7c7',
                                    color: '#000',
                                    height: '25px',
                                    textTransform: 'none',
                                  }}
                                >
                                  Inactive
                                </ToggleButton>
                              </span>
                            </Tooltip>
                          )}
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '1em' }}>
                        <Col md={6}>
                          <label> Is this Pharmacy ? </label>
                        </Col>
                        <Col md={6} className="statusMember">
                          <span style={{ color: '#162c50', margin: '1.1em 1em' }}>
                            {' '}
                            <Checkbox
                              style={{ color: 'blue' }}
                              checked={this.state.isPharmacyCheck}
                              onChange={this.handleClickCheckBox}
                            />
                          </span>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter style={{ textAlign: 'center' }}>
                      <SaveButton type="submit" />
                      &nbsp;
                      <CancelIconButton
                        type="button"
                        onClick={(e) => this.cancelEditFacilityDetails(e, this.state.id)}
                      />
                    </CardFooter>
                  </ValidatorForm>
                )}
              </Card>
            </Col>
            <Col md={8} style={{ padding: '5px' }}>
              {this.state.showMapView && (
                <Row>
                  <Col md={12}>
                    <Card style={{ height: '100%', borderRadius: '20px' }}>
                      <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                        <Row>
                          <Col md={8}>
                            {undefined !== this.state.title && this.state.title.length > 0 && (
                              <Label
                                style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                              >
                                Locations
                              </Label>
                            )}
                          </Col>
                          <Col md={4}>
                            {this.state.getCreatePermission === true && (
                              <span className="addLocation">
                                <Tooltip title="Add Location" aria-label="add">
                                  <AddIconButton
                                    onClick={this.addLocation.bind(this, 'create')}
                                    btnName="Add Location"
                                  />
                                </Tooltip>
                              </span>
                            )}
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Row md={12}>{locationItems}</Row>
                        <Row style={{ margin: '0em' }}>
                          <Col md={12}>
                            <Row md={12}>
                              <Col md={12} style={{ padding: '0em' }}>
                                {' '}
                                <span className="closeFloatingButton" aria-label="add">
                                  {' '}
                                  <CloseIcon
                                    onClick={this.closeButton.bind(this)}
                                    style={{ float: 'right', fontSize: '15px', cursor: 'pointer' }}
                                  />
                                </span>
                              </Col>
                            </Row>{' '}
                            <Row>
                              <Col md={12}>{this.getMapView(this.state.addressLocations)} </Col>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>{' '}
                </Row>
              )}
              {this.state.showLocationView && (
                <Card style={{ height: '100%', borderRadius: '20px' }}>
                  <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                    <Row>
                      <Col md={8}>
                        {undefined !== this.state.title && this.state.title.length > 0 && (
                          <Label
                            style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                          >
                            Locations
                          </Label>
                        )}
                      </Col>
                      <Col md={4}>
                        {this.state.getCreatePermission === true && (
                          <span className="addLocation">
                            <Tooltip title="Add Location" aria-label="add">
                              <AddIconButton onClick={this.addLocation.bind(this, 'create')} btnName="Add Location" />
                            </Tooltip>
                          </span>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row md={12}>{locationItems}</Row>
                    {/* <Row md={12} style={{ marginLeft: 'auto' }}>
                      {' '}
                      <Button
                        type={this.state.type ? this.state.type : ''}
                        variant="contained"
                        size="small"
                        style={{ background: '#717373', color: '#fff', marginRight: '1rem', marginBottom: '1em' }}
                        onClick={(e) => this.showMap()}
                      >
                        Show Map
                      </Button>
                    </Row> */}
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
          {this.state.showAppointmentPermission === true ? (
            <Row style={{ position: 'relative', margin: '1em 0em' }}>
              <Col md={12}>
                <MaterialTable
                  icons={UiUtils.getMaterialTableIcons()}
                  title={this.MaterialTableTitle()}
                  columns={[
                    {
                      title: '#',
                      render: (rowData) => rowData.tableData.index + 1,
                      filtering: false,
                    },

                    {
                      title: 'Date',
                      type: 'date',
                      field: 'date',

                      render: (rowData) => <>{Utils.convertUTCDateToUserTimeZoneDate(rowData.date)}</>,

                      align: 'left',
                      width: '20%',
                    },
                    { title: 'Type', field: 'type', width: '12%' },
                    {
                      title: 'Appointment',
                      field: 'appointment',

                      width: '20%',
                    },
                    { title: 'Purpose', field: 'purpose', width: '15%' },

                    {
                      title: 'Status',

                      field: 'status',

                      width: '10%',
                    },
                    {
                      title: '',
                      width: '10%',
                      render: (rowData) =>
                        rowData.markCompleteCancel === true ? (
                          <>
                            <h6 className="blink_me">Mark Complete / Cancel</h6>
                          </>
                        ) : (
                          ''
                        ),
                    },
                  ]}
                  data={this.state.facilityAppointmentList}
                  options={{
                    sorting: true,
                    actionsColumnIndex: -1,
                    headerStyle: { backgroundColor: '#1ABC9C', fontWeight: 'bold', color: '#fff', align: 'center' },
                    rowStyle: (rowData) => ({
                      backgroundColor: rowData.id === this.state.appointmentId ? '#FEF5E7' : '#fff',
                      height: '3em',
                    }),
                    pageSize: this.state.pageSize,
                    pageSizeOptions: [5, 10, 20, 50, 100],
                    filtering: true,
                    paging: this.state.isPaging,
                    initialPage: this.state.initialPage,
                  }}
                  localization={{
                    header: {
                      actions: this.displayActionTitle(),
                    },
                  }}
                  actions={[
                    {
                      icon: () =>
                        this.state.getCreatePermission === true && (
                          <>
                            {this.state.isStatus === true && (
                              <span className="newAppointment">
                                <AddIconButton btnName="Add Appointment" />
                              </span>
                            )}
                          </>
                        ),

                      tooltip: 'Add New Appointment',
                      isFreeAction: true,
                      onClick: (event) => this.addAppointment(),
                    },
                    (rowData) => ({
                      icon: () =>
                        /*if appointment has Reference Appointment then show then button */
                        rowData.hasReferenceAppointments === true ? (
                          <>
                            <AccountTreeIcon
                              className="referenceAppointment"
                              style={{ fontSize: '25px', color: '#2E86C1' }}
                            />
                            &nbsp;
                          </>
                        ) : (
                          <div style={{ marginLeft: '1em', fontSize: '25px' }}></div>
                        ),
                      tooltip: 'Reference Appointment',
                    }),
                    (rowData) => ({
                      icon: () =>
                        /*if appointment has message threads then show then button */
                        rowData.hasMessagingThreads === true ? (
                          <>
                            <Badge badgeContent={rowData.unreadMessageCount} color="primary" overlap="rectangular">
                              <ChatIcon className="message" style={{ fontSize: '25px', color: '#58D68D' }} />
                            </Badge>
                            &nbsp;
                          </>
                        ) : (
                          <div style={{ marginLeft: '1em', fontSize: '25px' }}></div>
                        ),
                      tooltip: 'Message',
                    }),

                    (rowData) => ({
                      icon: () =>
                        /*if appointment has active Reminder  then show then button */
                        rowData.hasReminders === true ? (
                          <>
                            <Badge badgeContent={rowData.activeReminderCount} color="primary">
                              <AccessAlarmTwoToneIcon
                                className="reminder"
                                style={{ fontSize: '25px', color: '#EF797D' }}
                              />
                            </Badge>
                            &nbsp;
                          </>
                        ) : (
                          <div style={{ marginLeft: '1em', fontSize: '25px' }}></div>
                        ),
                      tooltip: 'Reminder',
                    }),
                    (rowData) => ({
                      /*if appointment has transportation  then show then button */
                      icon: () =>
                        rowData.hasTransportation === true ? (
                          <>
                            <DriveEtaIcon className="transportation" style={{ fontSize: '25px', color: '#E67E22' }} />
                            &nbsp;
                          </>
                        ) : (
                          <div style={{ marginLeft: '1em', fontSize: '25px' }}></div>
                        ),
                      tooltip: 'Transportation',
                    }),
                  ]}
                  onRowClick={(event, rowData) => {
                    this.toAppointmentDetails(event, rowData);
                    event.stopPropagation();
                  }}
                  onPageChange={(page, pageSize) => {
                    this.handleMaterialTablePageChange(page, pageSize);
                  }}
                />
              </Col>
            </Row>
          ) : (
            <></>
          )}
          <div style={{ textAlign: 'center' }}>
            <BackButton
              type="button"
              style={{ backgroundColor: '#162c50', color: '#fff' }}
              variant="contained"
              startIcon={<CloseIcon />}
              onClick={(e) => this.viewCancel()}
            >
              Back
            </BackButton>
            {this.state.previousButton && (
              <Button
                type="submit"
                style={{ background: '#1b7189', color: '#fff', marginRight: '1rem' }}
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={(e) => this.toAppointmentDetails(e, this.appointmentId)}
              >
                {' '}
                Previous Appointment
              </Button>
            )}
          </div>
          <Dialog
            open={this.state.shareDoctorModal}
            onClose={this.handleCloseShareContactModal.bind(this)}
            className="salesDialogue"
          >
            <ValidatorForm style={{ width: '100%' }} ref="form" onSubmit={this.ShareDoctorInfoToBackEnd.bind(this)}>
              <DialogTitle id="scroll-dialog-title" style={{ background: '#1ABC9C', overflowX: 'hidden' }}>
                <span style={{ color: '#fff' }}>Share Contact</span>
              </DialogTitle>
              <DialogContent style={{ textAlign: 'center', padding: '1em', overflow: 'hidden' }}>
                <Row>
                  <Col md={12}>
                    <TextValidator
                      fullWidth
                      required
                      margin="normal"
                      variant="outlined"
                      label="Email"
                      type="email"
                      inputProps={{ maxLength: 64 }}
                      value={this.state.shareContactEmail}
                      onChange={this.handleShareContactEmailChange.bind(this)}
                      onBlur={this.props.onBlur}
                      autoComplete="off"
                      validators={['required', 'isEmail']}
                      errorMessages={['this field is required', 'email is not valid']}
                    />
                  </Col>
                </Row>
              </DialogContent>
              <DialogActions>
                <Row style={{ width: '100%', padding: '0em' }}>
                  <Col md={12} style={{ textAlign: 'center' }}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      style={{
                        background: '#ef6603',
                        color: '#fff',
                        marginRight: '1rem',
                      }}
                      startIcon={<ShareIcon />}
                    >
                      Share
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      style={{
                        background: '#717373',
                        color: '#fff',
                        marginRight: '1rem',
                      }}
                      onClick={this.handleCloseShareContactModal.bind(this)}
                    >
                      Close
                    </Button>
                  </Col>
                </Row>
              </DialogActions>
            </ValidatorForm>
          </Dialog>
        </>
      );
    }
  }

  viewFacilityDetailsWithoutAdvertisement() {
    let locationItems = [];
    if (this.state.render === false) {
      return <div />;
    } else {
      this.state.locationList.forEach((element, index) => {
        if ('delete' !== element.operation) {
          locationItems.unshift(
            <Col md={12}>
              <Location
                ref={this.locationReferenceList[index]}
                key={index}
                data={element}
                srNo={index}
                getDeletePermission={this.state.getDeletePermission}
                getUpdatePermission={this.state.getUpdatePermission}
                pageId="editFacility"
                action={element.action ? element.action : 'editLocation'}
                onCancel={this.deleteLocation.bind(this)}
                onDelete={this.deleteLocationToBackend.bind(this)}
                onLocationAdd={this.addNewLocationInBackend.bind(this)}
                onUpdate={this.updateLocationToBackend.bind(this)}
              />
            </Col>
          );
        }
      });

      return (
        <>
          <MenuWithBreadScrum
            backCallback={this.viewCancel.bind(this)}
            deleteCallback={this.deleteFacilityInBackend.bind(this)}
            shareCallback={this.handleOpenShareContactModal.bind(this)}
            getDeletePermission={this.state.getDeletePermission}
            getUpdatePermission={this.state.getUpdatePermission}
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            name={this.title + ' For ' + this.memberName}
            domainObject="Facility"
          />
          <Steps
            enabled={this.state.stepsEnabled}
            steps={this.state.steps}
            initialStep={this.state.initialStep}
            onExit={this.onExit}
          />
          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={4} style={{ padding: '5px' }}>
              <Card style={{ height: '100%', borderRadius: '20px' }}>
                {this.state.viewFacility && (
                  <>
                    {' '}
                    <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                      <Row>
                        <Col md={10}>
                          {undefined !== this.state.title && this.state.title.length > 0 && (
                            <Label
                              style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                            >
                              Facility Details
                            </Label>
                          )}
                        </Col>
                        <Col md={2}>
                          {this.state.getUpdatePermission && (
                            <EditIconButton onClick={this.updateFacilityDetails.bind(this)} />
                          )}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      {this.state.facilityName && (
                        <Row>
                          <Col md={5}>
                            <label>Facility Name</label>
                          </Col>
                          <Col md={7}>
                            {' '}
                            <span>{this.state.facilityName}</span>
                          </Col>
                        </Row>
                      )}
                      {this.state.viewFacilityType && (
                        <Row>
                          <Col md={5}>
                            <label>Facility Type</label>
                          </Col>
                          <Col md={7}>
                            {' '}
                            <span>{this.state.viewFacilityType}</span>
                          </Col>
                        </Row>
                      )}
                      {this.state.description && (
                        <Row>
                          <Col md={5}>
                            <label>Description</label>
                          </Col>
                          <Col md={7}>
                            {' '}
                            <span>
                              <pre className="view_facilityDesc"> {this.state.description}</pre>
                            </span>
                          </Col>
                        </Row>
                      )}
                      {this.state.website && (
                        <Row>
                          <Col md={5}>
                            <label>Facility Portal</label>
                          </Col>
                          <Col md={7}>
                            {' '}
                            <span>
                              <a
                                href={
                                  this.state.website.includes('https://') || this.state.website.includes('http://')
                                    ? this.state.website
                                    : 'http://' + this.state.website
                                }
                                target="blank"
                              >
                                {this.state.website}
                              </a>
                            </span>
                          </Col>
                        </Row>
                      )}
                      {this.state.websiteuser && (
                        <Row>
                          <Col md={5}>
                            <label>Portal Username</label>
                          </Col>
                          <Col md={7}>
                            {' '}
                            <span>
                              <a href={'webuserto:' + this.state.websiteuser} target="_top">
                                {this.state.websiteuser}
                              </a>
                              <Tooltip title="copy" aria-label="add">
                                <IconButton style={{ marginLeft: '30px' }}>
                                  <CopyToClipboard text={this.state.websiteuser} onClick={this.onCopy}>
                                    <FileCopyIcon style={{ color: 'gray', fontSize: '20px' }} />
                                  </CopyToClipboard>
                                </IconButton>
                              </Tooltip>
                            </span>
                          </Col>
                        </Row>
                      )}
                      <Row>
                        <Col md={5}>
                          <label> Status</label>
                        </Col>
                        <Col md={7} className="statusMember">
                          {this.state.isStatus === true && (
                            <Tooltip title="Please click EDIT to change status">
                              <span>
                                <ToggleButton
                                  value="Active"
                                  className="ToggleButtonActive"
                                  onChange={this.handleStatusChange.bind(this)}
                                  style={{
                                    background: '#27AE60',
                                    color: '#fff',
                                    height: '25px',
                                    textTransform: 'none',
                                  }}
                                  disabled
                                >
                                  Active
                                </ToggleButton>
                              </span>
                            </Tooltip>
                          )}
                          {this.state.isStatus === false && (
                            <Tooltip title="Please click EDIT to change status">
                              <span>
                                <ToggleButton
                                  value="Inactive"
                                  className="ToggleButtonInActive"
                                  onChange={this.handleStatusChange.bind(this)}
                                  style={{
                                    background: '#D7DBDD',
                                    color: '#000',
                                    height: '25px',
                                    textTransform: 'none',
                                  }}
                                  disabled
                                >
                                  Inactive
                                </ToggleButton>
                              </span>
                            </Tooltip>
                          )}
                        </Col>
                      </Row>{' '}
                      {/* <Row>
                        <Col md={5}>
                          <span style={{ color: '#162c50', margin: '1.1em 1em' }}>
                            {' '}
                            <Checkbox
                              disabled
                              style={{ color: 'blue' }}
                              checked={this.state.isPharmacyCheck}
                              onChange={this.handleClickCheckBox}
                            />
                          </span>
                        </Col>
                        <Col md={7}>
                          <span> Is this Pharmacy ? </span>
                        </Col>
                      </Row> */}
                      <Row style={{ marginTop: '1em' }}>
                        <Col md={5}>
                          <label> Is this Pharmacy ? </label>
                        </Col>
                        <Col md={7}>
                          {this.state.isPharmacyCheck === true && (
                            <span>
                              <ToggleButton
                                disabled
                                value="Yes"
                                className="ToggleButtonActive"
                                style={{
                                  background: '#27AE60',
                                  color: '#fff',
                                  height: '25px',
                                  textTransform: 'none',
                                }}
                              >
                                Yes
                              </ToggleButton>
                            </span>
                          )}
                          {this.state.isPharmacyCheck === false && (
                            <span>
                              <ToggleButton
                                disabled
                                value="No"
                                className="ToggleButtonInActive"
                                style={{
                                  background: '#c7c7c7',
                                  color: '#000',
                                  height: '25px',
                                  textTransform: 'none',
                                }}
                              >
                                No
                              </ToggleButton>
                            </span>
                          )}
                        </Col>
                      </Row>
                    </CardBody>
                  </>
                )}

                {this.state.editFacility && (
                  <ValidatorForm
                    style={{ width: '100%' }}
                    ref="form"
                    onSubmit={this.updateFacilityInBackend.bind(this)}
                  >
                    <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                      <Row>
                        <Col md={9}>
                          <Label
                            style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                          >
                            Edit Facility Details
                          </Label>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row style={{ marginTop: '1em' }}>
                        <Col md={12}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            required
                            id="name"
                            label="Facility Name"
                            value={this.state.facilityName}
                            inputprops={{ maxLength: 64 }}
                            onChange={this.handleFacilityNameChange.bind(this)}
                            name="name"
                            type="text"
                            className="textField"
                          />
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '1em' }}>
                        <Col md={12}>
                          <input
                            className="pts_dataList_dropDown"
                            type="text"
                            required
                            id="demo-simple-select-outlined"
                            list="data"
                            placeholder="Facility Type*"
                            label="Facility Type*"
                            value={this.state.facilityType}
                            onChange={(event, value) => {
                              this.handleFacilityTypeChange(event, value);
                            }}
                          />
                          <datalist id="data">
                            {this.facilityTypeList.map((event, key) => (
                              <option key={event.type} value={event.type} />
                            ))}
                          </datalist>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '1em' }}>
                        <Col md={12}>
                          <TextareaAutosize
                            aria-label="minimum height"
                            rowsMin={4}
                            id="description"
                            value={this.state.description}
                            inputprops={{ maxLength: 256, padding: '10px' }}
                            name="note"
                            onChange={this.handleDescriptionChange.bind(this)}
                            placeholder="Facility Description"
                            style={{ width: '100%', padding: '10px', border: '0.5px solid #D5D8DC' }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            fullWidth
                            id="website"
                            onChange={this.handleWebsiteChange.bind(this)}
                            label="Facility Portal"
                            name="website"
                            value={this.state.website}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            fullWidth
                            id="websiteuser"
                            onChange={this.handleWebsiteuserChange.bind(this)}
                            label="Portal Username"
                            name="websiteuser"
                            value={this.state.websiteuser}
                          />
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '1em' }}>
                        <Col md={2}>
                          <label> Status</label>
                        </Col>
                        <Col md={10} className="statusMember">
                          {this.state.isStatus === true && (
                            <Tooltip title="Please click EDIT to change status">
                              <span>
                                <ToggleButton
                                  value="Active"
                                  className="ToggleButtonActive"
                                  onChange={this.handleStatusChange.bind(this)}
                                  style={{
                                    background: '#27AE60',
                                    color: '#fff',
                                    height: '25px',
                                    textTransform: 'none',
                                  }}
                                >
                                  Active
                                </ToggleButton>
                              </span>
                            </Tooltip>
                          )}
                          {this.state.isStatus === false && (
                            <Tooltip title="Please click EDIT to change status">
                              <span>
                                <ToggleButton
                                  value="Inactive"
                                  className="ToggleButtonInActive"
                                  onChange={this.handleStatusChange.bind(this)}
                                  style={{
                                    background: '#c7c7c7',
                                    color: '#000',
                                    height: '25px',
                                    textTransform: 'none',
                                  }}
                                >
                                  Inactive
                                </ToggleButton>
                              </span>
                            </Tooltip>
                          )}
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '1em' }}>
                        <Col md={6}>
                          <label> Is this Pharmacy ? </label>
                        </Col>
                        <Col md={6} className="statusMember">
                          <span style={{ color: '#162c50', margin: '1.1em 1em' }}>
                            {' '}
                            <Checkbox
                              style={{ color: 'blue' }}
                              checked={this.state.isPharmacyCheck}
                              onChange={this.handleClickCheckBox}
                            />
                          </span>
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter style={{ textAlign: 'center' }}>
                      <SaveButton type="submit" />
                      &nbsp;
                      <CancelIconButton
                        type="button"
                        onClick={(e) => this.cancelEditFacilityDetails(e, this.state.id)}
                      />
                    </CardFooter>
                  </ValidatorForm>
                )}
              </Card>
            </Col>
            <Col md={8} style={{ position: 'relative', padding: '5px' }}>
              {this.state.showMapView && (
                <Row>
                  <Col md={12}>
                    <Card style={{ height: '100%', borderRadius: '20px' }}>
                      <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                        <Row>
                          <Col md={8}>
                            {undefined !== this.state.title && this.state.title.length > 0 && (
                              <Label
                                style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                              >
                                Locations
                              </Label>
                            )}
                          </Col>
                          <Col md={4}>
                            {this.state.getCreatePermission === true && (
                              <span className="addLocation">
                                <Tooltip title="Add Location" aria-label="add">
                                  <AddIconButton
                                    onClick={this.addLocation.bind(this, 'create')}
                                    btnName="Add Location"
                                  />
                                </Tooltip>
                              </span>
                            )}
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Row md={12}>{locationItems}</Row>
                        <Row style={{ margin: '0em' }}>
                          <Col md={12}>
                            <Row md={12}>
                              <Col md={12} style={{ padding: '0em' }}>
                                {' '}
                                <span className="closeFloatingButton" aria-label="add">
                                  {' '}
                                  <CloseIcon
                                    onClick={this.closeButton.bind(this)}
                                    style={{ float: 'right', fontSize: '15px', cursor: 'pointer' }}
                                  />
                                </span>
                              </Col>
                            </Row>{' '}
                            <Row>
                              <Col md={12}>{this.getMapView(this.state.addressLocations)} </Col>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>{' '}
                </Row>
              )}
              {this.state.showLocationView && (
                <Card style={{ height: '100%', borderRadius: '20px' }}>
                  <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                    <Row>
                      <Col md={8}>
                        {undefined !== this.state.title && this.state.title.length > 0 && (
                          <Label
                            style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                          >
                            Locations
                          </Label>
                        )}
                      </Col>
                      <Col md={4}>
                        {this.state.getCreatePermission === true && (
                          <>
                            <span className="addLocation">
                              <Tooltip title="Add Location" aria-label="add">
                                <AddIconButton onClick={this.addLocation.bind(this, 'create')} btnName="Add Location" />
                              </Tooltip>
                            </span>
                          </>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row md={12}>{locationItems}</Row>
                    {/* <Row md={12} style={{ marginLeft: 'auto' }}>
                      {' '}
                      <Button
                        type={this.state.type ? this.state.type : ''}
                        variant="contained"
                        size="small"
                        style={{ background: '#717373', color: '#fff', marginRight: '1rem', marginBottom: '1em' }}
                        onClick={(e) => this.showMap()}
                      >
                        Show Map
                      </Button>
                    </Row> */}
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>
          {this.state.showAppointmentPermission === true ? (
            <Row style={{ position: 'relative', margin: '1em 0em' }}>
              <Col md={12} id="facility_Appointment_List">
                <MaterialTable
                  icons={UiUtils.getMaterialTableIcons()}
                  title={this.MaterialTableTitle()}
                  columns={[
                    {
                      title: '#',
                      render: (rowData) => rowData.tableData.index + 1,
                      filtering: false,
                      width: '5%',
                    },

                    {
                      title: 'Date',
                      type: 'date',
                      field: 'date',

                      render: (rowData) => <>{Utils.convertUTCDateToUserTimeZoneDate(rowData.date)}</>,

                      align: 'left',
                      width: '20%',
                    },
                    { title: 'Type', field: 'type', width: '12%' },
                    {
                      title: 'Appointment',
                      field: 'appointment',

                      width: '20%',
                    },
                    { title: 'Purpose', field: 'purpose', width: '15%' },

                    {
                      title: 'Status',

                      field: 'status',

                      width: '10%',
                    },
                    {
                      title: '',
                      width: '10%',
                      render: (rowData) =>
                        rowData.markCompleteCancel === true ? (
                          <>
                            <h6 className="blink_me">Mark Complete / Cancel</h6>
                          </>
                        ) : (
                          ''
                        ),
                    },
                  ]}
                  data={this.state.facilityAppointmentList}
                  options={{
                    sorting: true,
                    actionsColumnIndex: -1,
                    headerStyle: { backgroundColor: '#1ABC9C', fontWeight: 'bold', color: '#fff', align: 'center' },
                    rowStyle: (rowData) => ({
                      backgroundColor: rowData.id === this.state.appointmentId ? '#FEF5E7' : '#fff',
                      height: '3em',
                    }),
                    pageSize: this.state.pageSize,
                    pageSizeOptions: [5, 10, 20, 50, 100],
                    filtering: true,
                    paging: this.state.isPaging,
                    initialPage: this.state.initialPage,
                  }}
                  localization={{
                    header: {
                      actions: this.displayActionTitle(),
                    },
                  }}
                  actions={[
                    {
                      icon: () =>
                        this.state.getCreatePermission === true && (
                          <>
                            {this.state.isStatus === true && (
                              <span className="newAppointment">
                                <AddIconButton btnName="Add Appointment" />
                              </span>
                            )}
                          </>
                        ),

                      tooltip: 'Add New Appointment',
                      isFreeAction: true,
                      onClick: (event) => this.addAppointment(),
                    },
                    (rowData) => ({
                      icon: () =>
                        /*if appointment has Reference Appointment then show then button */
                        rowData.hasReferenceAppointments === true ? (
                          <>
                            <AccountTreeIcon
                              className="referenceAppointment"
                              style={{ fontSize: '25px', color: '#2E86C1' }}
                            />
                            &nbsp;
                          </>
                        ) : (
                          <div style={{ marginLeft: '1em', fontSize: '25px' }}></div>
                        ),
                      tooltip: 'Reference Appointment',
                    }),
                    (rowData) => ({
                      icon: () =>
                        /*if appointment has message threads then show then button */
                        rowData.hasNotes === true ? (
                          <>
                            <Badge badgeContent={rowData.unreadMessageCount} color="primary" overlap="rectangular">
                              <ChatIcon className="message" style={{ fontSize: '25px', color: '#58D68D' }} />
                            </Badge>
                            &nbsp;
                          </>
                        ) : (
                          <div style={{ marginLeft: '1em', fontSize: '25px' }}></div>
                        ),
                      tooltip: 'Message',
                    }),

                    (rowData) => ({
                      icon: () =>
                        /*if appointment has active Reminder  then show then button */
                        rowData.hasReminders === true ? (
                          <>
                            <Badge badgeContent={rowData.activeReminderCount} color="primary">
                              <AccessAlarmTwoToneIcon
                                className="reminder"
                                style={{ fontSize: '25px', color: '#EF797D' }}
                              />
                            </Badge>
                            &nbsp;
                          </>
                        ) : (
                          <div style={{ marginLeft: '1em', fontSize: '25px' }}></div>
                        ),
                      tooltip: 'Reminder',
                    }),
                    (rowData) => ({
                      /*if appointment has transportation  then show then button */
                      icon: () =>
                        rowData.hasTransportation === true ? (
                          <>
                            <DriveEtaIcon className="transportation" style={{ fontSize: '25px', color: '#E67E22' }} />
                            &nbsp;
                          </>
                        ) : (
                          <div style={{ marginLeft: '1em', fontSize: '25px' }}></div>
                        ),
                      tooltip: 'Transportation',
                    }),
                  ]}
                  onRowClick={(event, rowData) => {
                    this.toAppointmentDetails(event, rowData);
                    event.stopPropagation();
                  }}
                  onPageChange={(page, pageSize) => {
                    this.handleMaterialTablePageChange(page, pageSize);
                  }}
                />
              </Col>
            </Row>
          ) : (
            <></>
          )}
          <div style={{ textAlign: 'center' }}>
            <BackButton
              type="button"
              style={{ backgroundColor: '#162c50', color: '#fff' }}
              variant="contained"
              startIcon={<CloseIcon />}
              onClick={(e) => this.viewCancel()}
            >
              Back
            </BackButton>
            {this.state.previousButton && (
              <Button
                type="submit"
                style={{ background: '#1b7189', color: '#fff', marginRight: '1rem' }}
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={(e) => this.toAppointmentDetails(e, this.appointmentId)}
              >
                {' '}
                Previous Appointment
              </Button>
            )}
          </div>
          <Dialog
            open={this.state.shareDoctorModal}
            onClose={this.handleCloseShareContactModal.bind(this)}
            className="salesDialogue"
          >
            <ValidatorForm style={{ width: '100%' }} ref="form" onSubmit={this.ShareDoctorInfoToBackEnd.bind(this)}>
              <DialogTitle id="scroll-dialog-title" style={{ background: '#1ABC9C', overflowX: 'hidden' }}>
                <span style={{ color: '#fff' }}>Share Contact</span>
              </DialogTitle>
              <DialogContent style={{ textAlign: 'center', padding: '1em', overflow: 'hidden' }}>
                <Row>
                  <Col md={12}>
                    <TextValidator
                      fullWidth
                      required
                      margin="normal"
                      variant="outlined"
                      label="Email"
                      type="email"
                      inputProps={{ maxLength: 64 }}
                      value={this.state.shareContactEmail}
                      onChange={this.handleShareContactEmailChange.bind(this)}
                      onBlur={this.props.onBlur}
                      autoComplete="off"
                      validators={['required', 'isEmail']}
                      errorMessages={['this field is required', 'email is not valid']}
                    />
                  </Col>
                </Row>
              </DialogContent>
              <DialogActions>
                <Row style={{ width: '100%', padding: '0em' }}>
                  <Col md={12} style={{ textAlign: 'center' }}>
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      style={{
                        background: '#ef6603',
                        color: '#fff',
                        marginRight: '1rem',
                      }}
                      startIcon={<ShareIcon />}
                    >
                      Share
                    </Button>
                    <Button
                      type="submit"
                      variant="contained"
                      size="small"
                      style={{
                        background: '#717373',
                        color: '#fff',
                        marginRight: '1rem',
                      }}
                      onClick={this.handleCloseShareContactModal.bind(this)}
                    >
                      Close
                    </Button>
                  </Col>
                </Row>
              </DialogActions>
            </ValidatorForm>
          </Dialog>
        </>
      );
    }
  }
}

export default MemberFacility;
