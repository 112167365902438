import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React, { Component } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap';
import template1 from '../../../assets/images/template1.png';
import template2 from '../../../assets/images/template2.png';
import template3 from '../../../assets/images/template3.png';
import template4 from '../../../assets/images/template4.png';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import adminFccPageStateAction from '../../../redux/adminPanel/adminFccPageState/adminFccPageStateAction';
import store from '../../../redux/store';
import BackButton from '../../components/buttons/BackButton';
import CancelIconButton from '../../components/buttons/CancelIconButton';
import SaveButton from '../../components/buttons/SaveButton';
import PtsAlert from '../../components/ptsAlert/PtsAlert';
import AdminMenuWithBreadScrum from '../adminMenu/AdminMenuWithBreadScrum';
import Template1 from '../sponsorShipTemplate/AdTemplate1/Template1';
import Template2 from '../sponsorShipTemplate/AdTemplate2/Template2';
import Template3 from '../sponsorShipTemplate/AdTemplate3/Template3';
import Template4 from '../sponsorShipTemplate/AdTemplate4/Template4';

class Advertisement extends Component {
  title = 'Sponsor Details';
  addressRef = React.createRef();
  template1Ref = React.createRef();
  template2Ref = React.createRef();
  header = store.getState().adminHeader.adminHeader;
  sponsorDetails = store.getState().adminSponsorDetails.adminSponsorDetails;
  adminFccPageState = store.getState().adminFccPageState.adminFccPageState;
  userDetails = store.getState().adminUserDetails.adminUserDetails;
  breadCrumb = [
    { pageid: '/viewSponsor', uiname: 'Sponsor Home' },
    { pageid: '/advertisementList', uiname: 'Advertisement List' },
    { pageid: '', uiname: 'Advertisement Details' },
  ];
  sponsorBreadCrumb = [
    { pageid: '/advertisementList', uiname: 'Advertisement List' },
    { pageid: '', uiname: 'Advertisement Details' },
  ];
  addressRef = {};
  template1Ref = {};
  template2Ref = {};
  constructor(props) {
    super(props);
    let advertisementId = props.advertisementId;
    let action = props.action;
    let title = '';
    let pageId = '';
    if (!advertisementId && props.history && props.history.location && props.history.location.state) {
      advertisementId = props.history.location.state.advertisementId;
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
      title = props.history.location.state.title;
      // doctorDetails = props.history.location.state.doctorDetails;
    }
    let canRender = false;
    if (null !== advertisementId && advertisementId > 0) {
      this.getAdvertisementDetails(advertisementId);
    } else {
      canRender = true;
    }
    if (advertisementId === undefined) {
      advertisementId = this.adminFccPageState.id;
      action = this.adminFccPageState.action;
      title = this.adminFccPageState.title;
      canRender = true;
      if (action !== 'list') {
        this.getAdvertisementDetails(advertisementId);
      }
    }
    let breadCrumb = [];
    if (this.userDetails.isUserLogin === 'sponsor') {
      breadCrumb = this.sponsorBreadCrumb;
    } else {
      breadCrumb = this.breadCrumb;
    }

    this.state = {
      id: advertisementId,
      email: '',
      password: '',
      showPassword: false,
      action: action,
      pageId: pageId,
      website: '',
      fax: '',
      name: '',
      address: {},
      render: canRender,
      title: title,
      userList: [],
      status: {},
      template1: false,
      Template2: false,
      Template3: false,
      Template4: false,
      templateType: '',
      showTemplate1: false,
      showTemplate2: false,
      showTemplate3: false,
      showTemplate4: false,
      advertisementTemplateDetails: {},
      advertisementName: '',
      showTemplateList: true,
      breadCrumb: breadCrumb,
      getUpdatePermission: false,
      getDeletePermission: false,
    };
  }

  getAdvertisementDetails(advertisementId) {
    // let id = advertisementId;
    const postObject = {
      header: this.header,

      advertisement: {
        id: advertisementId,
        sponsor: this.sponsorDetails.id,
      },
    };

    FetchServerData.callPostService('/sponsorAdvertisementms/get', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let advertisementDetails = output.data;
          let templateDetails = advertisementDetails.template;
          let getPermission = Utils.getRolePermission(['*']);
          let getUpdatePermission = getPermission[3];
          let getDeletePermission = getPermission[4];
          if (templateDetails !== null) {
            if (templateDetails.templateid === 'Template1') {
              this.setState({
                render: true,
                advertisementName: advertisementDetails.uid,
                id: advertisementDetails.id,
                advertisementTemplateDetails: templateDetails,
                showTemplate1: true,
                showTemplate2: false,
                templateType: templateDetails.templateid,
                showTemplate3: false,
                showTemplate4: false,
                getDeletePermission: getDeletePermission,
                getUpdatePermission: getUpdatePermission,
              });
            } else if (templateDetails.templateid === 'Template2') {
              this.setState({
                render: true,
                advertisementName: advertisementDetails.uid,
                id: advertisementDetails.id,
                advertisementTemplateDetails: templateDetails,
                showTemplate1: false,
                showTemplate2: true,
                templateType: templateDetails.templateid,
                showTemplate3: false,
                showTemplate4: false,
                getDeletePermission: getDeletePermission,
                getUpdatePermission: getUpdatePermission,
              });
            } else if (templateDetails.templateid === 'Template3') {
              this.setState({
                render: true,
                advertisementName: advertisementDetails.uid,
                id: advertisementDetails.id,
                advertisementTemplateDetails: templateDetails,
                showTemplate1: false,
                showTemplate2: false,
                templateType: templateDetails.templateid,
                showTemplate3: true,
                showTemplate4: false,
                getDeletePermission: getDeletePermission,
                getUpdatePermission: getUpdatePermission,
              });
            } else if (templateDetails.templateid === 'Template4') {
              this.setState({
                render: true,
                advertisementName: advertisementDetails.uid,
                id: advertisementDetails.id,
                advertisementTemplateDetails: templateDetails,
                showTemplate1: false,
                showTemplate2: false,
                templateType: templateDetails.templateid,
                showTemplate3: false,
                showTemplate4: true,
                getDeletePermission: getDeletePermission,
                getUpdatePermission: getUpdatePermission,
              });
            }
          } else {
            this.setState({
              render: true,
              id: advertisementDetails.id,
              advertisementTemplateDetails: {},
            });
          }
        } else {
        }
      })
      .catch((error) => {});
  }

  handleWebsiteChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ website: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }

  handlePhoneChange({ target: { value } }) {
    this.setState((prevState) => ({
      phone: Utils.convertPhoneNumberToUsaPhoneNumberFormate(value, prevState.phone),
    }));
  }

  handleEmailChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ email: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }

  handleAdminEmailChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ adminEmail: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }

  handlePasswordChange(e) {
    this.setState({
      password: e.target.value,
    });
  }
  handleFnameChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 128);

    if (res.length < 128) {
      this.setState({ fname: res });
    } else {
      Utils.maxFieldLength(128);
    }
  }

  handleLnameChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 128);

    if (res.length < 128) {
      this.setState({ lname: res });
    } else {
      Utils.maxFieldLength(128);
    }
  }

  handleAdvertisementNameChange(e) {
    this.setState({ advertisementName: e.target.value });
  }
  gotToButton() {
    document.getElementById('loginButton').focus();
  }
  gotToPassword() {
    document.getElementById('password').focus();
  }

  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  handleFaxChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ fax: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }

  handleAddressChange(address) {
    this.setState({ address: address });
  }

  handleTemplate1RefChange(advertisementTemplateDetails) {
    this.setState({
      advertisementTemplateDetails: advertisementTemplateDetails,
    });
  }
  handleTemplate2RefChange(advertisementTemplateDetails) {
    this.setState({
      advertisementTemplateDetails: advertisementTemplateDetails,
    });
  }

  viewCancel() {
    this.props.history.push({
      pathname: '/advertisementList',
    });
  }

  viewImagesTemplate() {
    this.template1Ref.current.state.action = 'viewTemplate';
    this.setState({
      action: 'viewTemplate',
      title: 'Advertisement Details',
      // advertisementId: this.state.id,
    });
  }
  createCancel() {
    this.setState({
      action: 'list',
      title: 'Create Advertisement',
    });
  }

  onChangeTypeValue(event) {
    this.setState({
      templateType: event.target.value,
    });
  }

  toViewTemplate(e) {
    if (this.state.templateType === 'Template1') {
      this.setState({
        showTemplate1: true,
        showTemplate2: false,
        showTemplate3: false,
        showTemplate4: false,
        action: 'create',
      });
    } else if (this.state.templateType === 'Template2') {
      this.setState({
        showTemplate1: false,
        showTemplate2: true,
        showTemplate3: false,
        showTemplate4: false,
        action: 'create',
      });
    } else if (this.state.templateType === 'Template3') {
      this.setState({
        showTemplate1: false,
        showTemplate2: false,
        showTemplate3: true,
        showTemplate4: false,
        action: 'create',
      });
    } else if (this.state.templateType === 'Template4') {
      this.setState({
        showTemplate1: false,
        showTemplate2: false,
        showTemplate3: false,
        showTemplate4: true,
        action: 'create',
      });
    }
  }

  editCancel() {
    let adminFccPageState = {
      id: this.state.id,
      action: 'view',
      title: 'view Advertisement',
      pageId: 'Advertisement',
    };
    store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
    this.props.history.push({
      pathname: '/viewAdvertisement',
      state: {
        action: 'viewTemplate',
        title: 'Advertisement Details',
        advertisementId: this.state.id,
      },
    });
  }

  addAdvertisementToBackend(e) {
    e.preventDefault();

    const postObject = {
      header: this.header,
      advertisement: {
        uid: this.state.advertisementName,
        sponsor: this.sponsorDetails.id,

        template: {
          templateid: this.state.templateType,
          heading: this.template1Ref.current.state.createHeading,
          subheading: this.template1Ref.current.state.createSubHeading,
          message: this.template1Ref.current.state.createMessage,
          website: this.template1Ref.current.state.createWebsite,
        },
      },
    };
    FetchServerData.callPostService('/sponsorAdvertisementms/create', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let sponsorDetails = output.data;
        let adminFccPageState = {
          id: sponsorDetails.id,
          action: 'view',
        };
        store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
        PtsAlert.success(output.message);
        this.props.history.push({
          pathname: '/viewAdvertisement',
          state: {
            action: 'view',
            title: 'Sponsor Details',
            advertisementId: sponsorDetails.id,
          },
        });
      } else {
      }
    });
  }

  updateAdvertisementToBackend(e) {
    e.preventDefault();
    const postObject = {
      header: this.header,
      advertisement: {
        id: this.state.id,
        uid: this.state.advertisementName,
        sponsor: this.sponsorDetails.id,

        template: {
          templateid: this.state.templateType,
          heading: this.template1Ref.current.state.heading,
          subheading: this.template1Ref.current.state.subHeading,
          message: this.template1Ref.current.state.message,
          website: this.template1Ref.current.state.website,
        },
      },
    };
    FetchServerData.callPostService('/sponsorAdvertisementms/update', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let AdvertisementDetails = output.data;

        PtsAlert.success(output.message);
        let adminFccPageState = {
          id: this.state.id,
          action: 'view',
          title: 'view Advertisement',
          pageId: 'Advertisement',
        };
        store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
        this.props.history.push({
          pathname: '/viewAdvertisement',
          state: {
            action: 'viewTemplate',
            title: 'Sponsor Details',
            advertisementId: AdvertisementDetails.id,
          },
        });
      } else {
      }
    });
  }

  editAdvertisementDetails(e) {
    let adminFccPageState = {
      id: this.state.id,
      action: 'edit',
      title: 'Edit Advertisement',
      pageId: 'Advertisement',
    };
    store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
    this.props.history.push({
      pathname: '/editAdvertisement',
      state: {
        action: 'edit',
        title: 'Advertisement Details',
        advertisementId: this.state.id,
      },
    });
  }

  deleteAdvertisementToBackend = () => {
    const postObject = {
      header: this.header,
      advertisement: {
        id: this.state.id,
      },
    };

    FetchServerData.callPostService('/sponsorAdvertisementms/delete', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          this.props.history.push({
            pathname: '/advertisementList',
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  // updateReferenceAppointmentReminder = (reminderData) => {
  //   for (let i = 0; i < this.state.referenceAppointmentReminderItems.length; i++) {
  //     if (
  //       this.state.referenceAppointmentReminderItems[i] &&
  //       reminderData.index === i &&
  //       this.template1Ref[i] &&
  //       this.template1Ref[i].current &&
  //       this.template1Ref[i].current.validateReminder()
  //     ) {
  //       this.state.referenceAppointmentReminderItems[i] = reminderData;
  //       this.appointmentReminderRefList[i].current.state = reminderData;
  //       this.appointmentReminderRefList[i].current.state.action = 'view';
  //       this.setState({ render: true });
  //     }
  //   }
  // };
  render() {
    if (this.state.action === 'list') {
      return this.advertisementList();
    } else if (this.state.action === 'create') {
      return this.createAdvertisement();
    } else if (this.state.action === 'edit') {
      return this.editAdvertisement();
    } else if (this.state.action === 'view') {
      return this.viewAdvertisement();
    } else if (this.state.action === 'viewTemplate') {
      return this.viewAdvertisementWithImage();
    }
  }
  advertisementList() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <AdminMenuWithBreadScrum
            backCallback={this.viewCancel.bind(this)}
            deleteCallback={this.deleteAdvertisementToBackend.bind(this)}
            breadCrumb={this.state.breadCrumb}
            history={this.props.history}
            name={'Create Advertisement'}
            domainObject="Advertisement"
          />

          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={12} style={{ position: 'relative', padding: '5px' }}>
              <Card className="formCard">
                <div onChange={this.onChangeTypeValue.bind(this)} value={this.state.templateType}>
                  <Row style={{ margin: '21px 0px' }}>
                    <Col md={9}>
                      <img
                        // src={global.config.projectName + "/template1.png"}
                        src={template1}
                        alt=""
                        style={{ width: '761px' }}
                      />
                    </Col>
                    <Col md={3} style={{ margin: 'auto' }}>
                      <input type="radio" value="Template1" name="type" id="Template1" /> Template1
                    </Col>
                  </Row>
                  <Row style={{ margin: '21px 0px' }}>
                    <Col md={9}>
                      <img
                        // src={global.config.projectName + "/template2.png"}
                        src={template2}
                        alt=""
                        style={{ width: '761px' }}
                      />
                    </Col>
                    <Col md={3} style={{ margin: 'auto' }}>
                      <input id="Template2" type="radio" value="Template2" name="type" /> Template2
                    </Col>
                  </Row>
                  <Row style={{ margin: '21px 0px' }}>
                    <Col md={9}>
                      <img
                        // src={global.config.projectName + "/template3.png"}
                        src={template3}
                        alt=""
                        style={{ width: '761px' }}
                      />
                    </Col>
                    <Col md={3} style={{ margin: 'auto' }}>
                      <input id="Template3" type="radio" value="Template3" name="type" /> Template3
                    </Col>
                  </Row>
                  <Row style={{ margin: '21px 0px' }}>
                    <Col md={9}>
                      <img
                        // src={global.config.projectName + "/template4.png"}
                        src={template4}
                        alt=""
                        style={{ width: '761px' }}
                      />
                    </Col>
                    <Col md={3} style={{ margin: 'auto' }}>
                      <input id="Template4" type="radio" value="Template4" name="type" /> Template4
                    </Col>
                  </Row>
                </div>
                <div style={{ textAlign: 'right', padding: '0em 12em 1em' }}>
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      background: '#717373',
                      color: '#fff',
                      marginRight: '1rem',
                    }}
                    // onClick={this.toViewTemplate()}
                    onClick={(e) => this.toViewTemplate(e)}
                  >
                    Next
                  </Button>
                </div>
              </Card>
            </Col>
          </Row>
        </>
      );
    }
  }

  createAdvertisement() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <AdminMenuWithBreadScrum
            backCallback={this.viewCancel.bind(this)}
            deleteCallback={this.deleteAdvertisementToBackend.bind(this)}
            breadCrumb={this.state.breadCrumb}
            history={this.props.history}
            name={'Create Advertisement'}
            domainObject="Advertisement"
          />

          <ValidatorForm style={{ width: '100%' }} ref="form" onSubmit={this.addAdvertisementToBackend.bind(this)}>
            <Row style={{ position: 'relative', margin: '1em' }}>
              <Col md={12} style={{ position: 'relative', padding: '5px' }}>
                {this.state.showTemplate1 === true ? (
                  <>
                    <Card className="formCard">
                      <Row style={{ position: 'relative', margin: '1em' }}>
                        <Col md={4} style={{ marginLeft: 'auto', marginRight: ' auto' }}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            required
                            fullWidth
                            id="advertisementName"
                            type="text"
                            onChange={this.handleAdvertisementNameChange.bind(this)}
                            label="Advertisement Name"
                            name="advertisementName"
                            inputProps={{
                              maxLength: 128,
                              style: { textTransform: 'capitalize' },
                            }}
                            style={{ background: '#fff' }}
                            value={this.state.advertisementName}
                          />
                        </Col>
                      </Row>
                      <Row style={{ position: 'relative', margin: '1em' }}>
                        <Col md={12}>
                          <Template1
                            action="preView"
                            ref={this.template1Ref}
                            onChange={this.handleTemplate1RefChange.bind(this)}
                            data={this.state.advertisementTemplateDetails}
                            advertisementId={this.state.id}
                          />
                        </Col>
                      </Row>
                    </Card>
                  </>
                ) : (
                  <span style={{ display: 'none' }}></span>
                )}
                {this.state.showTemplate2 === true ? (
                  <>
                    <Card className="formCard">
                      <Row style={{ position: 'relative', margin: '1em' }}>
                        <Col md={4} style={{ marginLeft: 'auto', marginRight: ' auto' }}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            required
                            fullWidth
                            id="advertisementName"
                            type="text"
                            onChange={this.handleAdvertisementNameChange.bind(this)}
                            label="Advertisement Name"
                            name="advertisementName"
                            inputProps={{
                              maxLength: 128,
                              style: { textTransform: 'capitalize' },
                            }}
                            style={{ background: '#fff' }}
                            value={this.state.advertisementName}
                          />
                        </Col>
                      </Row>
                      <Row style={{ position: 'relative', margin: '1em' }}>
                        <Col md={12}>
                          <Template2
                            action="preView"
                            ref={this.template1Ref}
                            onChange={this.handleTemplate1RefChange.bind(this)}
                            data={this.state.advertisementTemplateDetails}
                            advertisementId={this.state.id}
                          />
                        </Col>
                      </Row>
                    </Card>
                  </>
                ) : (
                  <span style={{ display: 'none' }}></span>
                )}
                {this.state.showTemplate3 === true ? (
                  <>
                    <Card className="formCard">
                      <Row style={{ position: 'relative', margin: '1em' }}>
                        <Col md={4} style={{ marginLeft: 'auto', marginRight: ' auto' }}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            required
                            fullWidth
                            id="advertisementName"
                            type="text"
                            onChange={this.handleAdvertisementNameChange.bind(this)}
                            label="Advertisement Name"
                            name="advertisementName"
                            inputProps={{
                              maxLength: 128,
                              style: { textTransform: 'capitalize' },
                            }}
                            style={{ background: '#fff' }}
                            value={this.state.advertisementName}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{
                          position: 'relative',
                          marginTop: '-2em',
                          marginBottom: '-4em',
                          marginLeft: '1em',
                          marginRight: '1em',
                        }}
                      >
                        <Col md={12}>
                          <Template3
                            action="preView"
                            ref={this.template1Ref}
                            onChange={this.handleTemplate1RefChange.bind(this)}
                            data={this.state.advertisementTemplateDetails}
                            advertisementId={this.state.id}
                          />
                        </Col>
                      </Row>
                    </Card>
                  </>
                ) : (
                  <span style={{ display: 'none' }}></span>
                )}
                {this.state.showTemplate4 === true ? (
                  <>
                    <Card className="formCard">
                      <Row style={{ position: 'relative', margin: '1em' }}>
                        <Col md={4} style={{ marginLeft: 'auto', marginRight: ' auto' }}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            required
                            fullWidth
                            id="advertisementName"
                            type="text"
                            onChange={this.handleAdvertisementNameChange.bind(this)}
                            label="Advertisement Name"
                            name="advertisementName"
                            inputProps={{
                              maxLength: 128,
                              style: { textTransform: 'capitalize' },
                            }}
                            style={{ background: '#fff' }}
                            value={this.state.advertisementName}
                          />
                        </Col>
                      </Row>
                      <Row style={{ position: 'relative', margin: '1em' }}>
                        <Col md={12}>
                          <Template4
                            action="preView"
                            ref={this.template1Ref}
                            onChange={this.handleTemplate1RefChange.bind(this)}
                            data={this.state.advertisementTemplateDetails}
                            advertisementId={this.state.id}
                          />
                        </Col>
                      </Row>
                    </Card>
                  </>
                ) : (
                  <span style={{ display: 'none' }}></span>
                )}
              </Col>
            </Row>
            {/* </CardBody> */}
            <div style={{ textAlign: 'center', padding: '0em 0em 1em' }}>
              <Button
                type="submit"
                variant="contained"
                size="small"
                style={{
                  background: '#ef6603',
                  color: '#fff',
                  marginRight: '1rem',
                }}
                startIcon={<ArrowForwardIcon />}
              >
                Next
              </Button>
              &nbsp;
              <CancelIconButton onClick={(e) => this.createCancel()} />
            </div>
          </ValidatorForm>
        </>
      );
    }
  }

  viewAdvertisementWithImage() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <AdminMenuWithBreadScrum
            backCallback={this.viewCancel.bind(this)}
            deleteCallback={this.deleteAdvertisementToBackend.bind(this)}
            editCallback={this.editAdvertisementDetails.bind(this)}
            breadCrumb={this.state.breadCrumb}
            getDeletePermission={this.state.getDeletePermission}
            getUpdatePermission={this.state.getUpdatePermission}
            history={this.props.history}
            name={'Advertisement Details'}
            domainObject="Advertisement"
          />

          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={12} className="sponsorDetailColumn" style={{ position: 'relative', padding: '5px' }}>
              <Card style={{ height: '100%' }}>
                <>
                  <CardHeader className="cardHeader">
                    <Row>
                      <Col md={10}>
                        <Label className="cardHeaderTitle">
                          {/* Advertisement Details */}
                          {this.state.advertisementName}
                        </Label>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {this.state.showTemplate1 === true ? (
                      <>
                        <Template1
                          ref={this.template1Ref}
                          action="viewTemplate"
                          data={this.state.advertisementTemplateDetails}
                          advertisementId={this.state.id}
                        />
                      </>
                    ) : (
                      <span style={{ display: 'none' }}></span>
                    )}
                    {this.state.showTemplate2 === true ? (
                      <>
                        {' '}
                        <Template2
                          ref={this.template1Ref}
                          action="viewTemplate"
                          data={this.state.advertisementTemplateDetails}
                          advertisementId={this.state.id}
                        />
                      </>
                    ) : (
                      <span style={{ display: 'none' }}></span>
                    )}
                    {this.state.showTemplate3 === true ? (
                      <>
                        {' '}
                        <Template3
                          ref={this.template1Ref}
                          action="viewTemplate"
                          data={this.state.advertisementTemplateDetails}
                          advertisementId={this.state.id}
                        />
                      </>
                    ) : (
                      <span style={{ display: 'none' }}></span>
                    )}
                    {this.state.showTemplate4 === true ? (
                      <>
                        {' '}
                        <Template4
                          ref={this.template1Ref}
                          action="viewTemplate"
                          data={this.state.advertisementTemplateDetails}
                          advertisementId={this.state.id}
                        />
                      </>
                    ) : (
                      <span style={{ display: 'none' }}></span>
                    )}
                  </CardBody>
                </>
              </Card>
            </Col>
          </Row>

          <div className="sponsorDetailFooter">
            <BackButton
              type="button"
              style={{ backgroundColor: '#162c50', color: '#fff' }}
              variant="contained"
              onClick={(e) => this.viewCancel()}
            >
              Back
            </BackButton>
          </div>
        </>
      );
    }
  }

  viewAdvertisement() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <AdminMenuWithBreadScrum
            // backCallback={this.viewCancel.bind(this)}
            deleteCallback={this.deleteAdvertisementToBackend.bind(this)}
            editCallback={this.editAdvertisementDetails.bind(this)}
            breadCrumb={this.state.breadCrumb}
            getDeletePermission={this.state.getDeletePermission}
            getUpdatePermission={this.state.getUpdatePermission}
            history={this.props.history}
            name={'Advertisement Details'}
            domainObject="Advertisement"
          />

          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={12} className="sponsorDetailColumn" style={{ position: 'relative', padding: '5px' }}>
              <Card style={{ height: '100%' }}>
                <>
                  <CardHeader className="cardHeader">
                    <Row>
                      <Col md={10}>
                        <Label className="cardHeaderTitle">
                          {/* Advertisement Details */}
                          {this.state.advertisementName}
                        </Label>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {this.state.showTemplate1 === true ? (
                      <>
                        <Template1
                          action="view"
                          ref={this.template1Ref}
                          data={this.state.advertisementTemplateDetails}
                          advertisementId={this.state.id}
                        />
                      </>
                    ) : (
                      <span style={{ display: 'none' }}></span>
                    )}
                    {this.state.showTemplate2 === true ? (
                      <>
                        {' '}
                        <Template2
                          action="view"
                          ref={this.template1Ref}
                          data={this.state.advertisementTemplateDetails}
                          advertisementId={this.state.id}
                        />
                      </>
                    ) : (
                      <span style={{ display: 'none' }}></span>
                    )}
                    {this.state.showTemplate3 === true ? (
                      <>
                        {' '}
                        <Template3
                          action="view"
                          ref={this.template1Ref}
                          data={this.state.advertisementTemplateDetails}
                          advertisementId={this.state.id}
                        />
                      </>
                    ) : (
                      <span style={{ display: 'none' }}></span>
                    )}
                    {this.state.showTemplate4 === true ? (
                      <>
                        {' '}
                        <Template4
                          action="view"
                          ref={this.template1Ref}
                          data={this.state.advertisementTemplateDetails}
                          advertisementId={this.state.id}
                        />
                      </>
                    ) : (
                      <span style={{ display: 'none' }}></span>
                    )}
                  </CardBody>
                </>
              </Card>
            </Col>
          </Row>

          <div className="sponsorDetailFooter">
            <Button
              type="button"
              style={{
                background: '#ef6603',
                color: '#fff',
                marginRight: '1rem',
              }}
              variant="contained"
              onClick={(e) => this.viewImagesTemplate()}
            >
              Next
            </Button>
          </div>
        </>
      );
    }
  }

  editAdvertisement() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <AdminMenuWithBreadScrum
            breadCrumb={this.state.breadCrumb}
            history={this.props.history}
            name={'Edit Advertisement'}
            domainObject="Advertisement"
          />
          <ValidatorForm style={{ width: '100%' }} ref="form" onSubmit={this.updateAdvertisementToBackend.bind(this)}>
            <Row style={{ position: 'relative', margin: '1em' }}>
              <Col md={12} style={{ position: 'relative', padding: '5px' }}>
                <Card className="formCard">
                  {this.state.showTemplate1 === true ? (
                    <>
                      <Row style={{ position: 'relative', margin: '1em' }}>
                        <Col md={4}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            required
                            fullWidth
                            id="advertisementName"
                            type="text"
                            onChange={this.handleAdvertisementNameChange.bind(this)}
                            label="Advertisement Name"
                            name="advertisementName"
                            inputProps={{
                              maxLength: 128,
                              style: { textTransform: 'capitalize' },
                            }}
                            style={{ background: '#fff' }}
                            value={this.state.advertisementName}
                          />
                        </Col>
                      </Row>
                      <Row style={{ position: 'relative', margin: '1em' }}>
                        <Col md={12}>
                          <Template1
                            action="edit"
                            ref={this.template1Ref}
                            advertisementId={this.state.id}
                            onChange={this.handleTemplate1RefChange.bind(this)}
                            data={this.state.advertisementTemplateDetails}
                          />
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <span style={{ display: 'none' }}></span>
                  )}
                  {this.state.showTemplate2 === true ? (
                    <>
                      <Row style={{ position: 'relative', margin: '1em' }}>
                        <Col md={4}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            required
                            fullWidth
                            id="advertisementName"
                            type="text"
                            onChange={this.handleAdvertisementNameChange.bind(this)}
                            label="Advertisement Name"
                            name="advertisementName"
                            inputProps={{
                              maxLength: 128,
                              style: { textTransform: 'capitalize' },
                            }}
                            style={{ background: '#fff' }}
                            value={this.state.advertisementName}
                          />
                        </Col>
                      </Row>
                      <Row style={{ position: 'relative', margin: '1em' }}>
                        <Col md={12}>
                          <Template2
                            action="edit"
                            ref={this.template1Ref}
                            advertisementId={this.state.id}
                            onChange={this.handleTemplate1RefChange.bind(this)}
                            data={this.state.advertisementTemplateDetails}
                          />
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <span style={{ display: 'none' }}></span>
                  )}
                  {this.state.showTemplate3 === true ? (
                    <>
                      <Row style={{ position: 'relative', margin: '1em' }}>
                        <Col md={4}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            required
                            fullWidth
                            id="advertisementName"
                            type="text"
                            onChange={this.handleAdvertisementNameChange.bind(this)}
                            label="Advertisement Name"
                            name="advertisementName"
                            inputProps={{
                              maxLength: 128,
                              style: { textTransform: 'capitalize' },
                            }}
                            style={{ background: '#fff' }}
                            value={this.state.advertisementName}
                          />
                        </Col>
                      </Row>
                      <Row
                        style={{
                          position: 'relative',
                          marginTop: '-2em',
                          marginBottom: '-4em',
                          marginLeft: '1em',
                          marginRight: '1em',
                        }}
                      >
                        <Col md={12}>
                          <Template3
                            action="edit"
                            ref={this.template1Ref}
                            advertisementId={this.state.id}
                            onChange={this.handleTemplate1RefChange.bind(this)}
                            data={this.state.advertisementTemplateDetails}
                          />
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <span style={{ display: 'none' }}></span>
                  )}

                  {this.state.showTemplate4 === true ? (
                    <>
                      <Row style={{ position: 'relative', margin: '1em' }}>
                        <Col md={4}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            required
                            fullWidth
                            id="advertisementName"
                            type="text"
                            onChange={this.handleAdvertisementNameChange.bind(this)}
                            label="Advertisement Name"
                            name="advertisementName"
                            inputProps={{
                              maxLength: 128,
                              style: { textTransform: 'capitalize' },
                            }}
                            style={{ background: '#fff' }}
                            value={this.state.advertisementName}
                          />
                        </Col>
                      </Row>
                      <Row style={{ position: 'relative', margin: '1em' }}>
                        <Col md={12}>
                          <Template4
                            action="edit"
                            ref={this.template1Ref}
                            advertisementId={this.state.id}
                            onChange={this.handleTemplate1RefChange.bind(this)}
                            data={this.state.advertisementTemplateDetails}
                          />
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <span style={{ display: 'none' }}></span>
                  )}
                </Card>
              </Col>
            </Row>
            {/* </CardBody> */}

            <div style={{ textAlign: 'center', padding: '0em 0em 1em' }}>
              <SaveButton id="loginButton" type="submit">
                Create
              </SaveButton>
              &nbsp;
              <CancelIconButton onClick={(e) => this.editCancel()} />
            </div>
          </ValidatorForm>
        </>
      );
    }
  }
}

export default Advertisement;
