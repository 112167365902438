/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React, { Component } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import Button from '@material-ui/core/Button';
import adminFccPageStateAction from '../../../redux/adminPanel/adminFccPageState/adminFccPageStateAction';
import fccLogo from '../../../assets/images/fccLogo.png';
import FetchServerData from '../../../provider/FetchServerData';
import adminAppUserDataAction from '../../../redux/adminPanel/appUserData/adminAppUserDataAction';
import adminHeaderAction from '../../../redux/adminPanel/header/adminHeaderAction';
import adminStaticDataAction from '../../../redux/adminPanel/staticData/adminStaticDataAction';
import store from '../../../redux/store';
import './AdminSponsorLogin.scss';
import sponsorDetailsAction from '../../../redux/adminPanel/sponsorDetails/sponsorDetailsAction';

export class AdminSponsorLogin extends Component {
  constructor(props) {
    super(props);
    let action = props.action;
    let pageId = '';
    if (
      null !== props.history &&
      props.history !== undefined &&
      props.history.location !== null &&
      props.history.location.state !== null &&
      props.history.location.state !== undefined
    ) {
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
    }
    this.state = {
      email: '',
      password: '',
      showPassword: false,
      action: action,
      pageId: pageId,
    };
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  handlePasswordChange(e) {
    this.setState({
      password: e.target.value,
    });
  }

  gotToButton() {
    document.getElementById('loginButton').focus();
  }
  gotToPassword() {
    document.getElementById('password').focus();
  }

  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  goToPage() {
    this.props.history.push({
      pathname: '/APSponsorForget',
      state: { email: this.state.email },
    });
  }

  processLoginSuccess(output_data) {
    this.setState({
      showSuccessAlert: true,
      showSuccessLoader: false,
    });
    let headerObj = output_data.header;
    store.dispatch(adminHeaderAction.setAdminHeader(headerObj));
    let header = headerObj;

    this.loadDashboardPage(header, output_data);
    this.props.history.push({
      pathname: '/viewSponsor',
      state: { notification: true, pageId: 'sponsorLoginPage' },
    });
  }

  async loadDashboardPage(header, output) {
    await this.setStaticData(header)
      .then((data) => {})
      .catch((error) => {
        this.setState({
          errorColor: '#FF0000',
          errorMessage: error,
        });
      });
    /* Update Token Once User Login successfully */
    this.props.history.push({
      pathname: '/viewSponsor',
      state: { notification: true, pageId: 'sponsorLoginPage' },
    });
  }

  async setStaticData(header) {
    return new Promise(function (accept, reject) {
      FetchServerData.callPostService('/staticms/getAll', header)
        .then((output) => {
          if (output.status === 'SUCCESS') {
            store.dispatch(adminFccPageStateAction.setAdminFccPageState());
            store.dispatch(adminStaticDataAction.setAdminStaticData(output.data));
            accept(1);
          } else {
            reject(output.message);
          }
        })
        .catch((error) => {
          reject('Cannot Fetch Data From Server, Error:' + error.message);
        });
    });
  }

  loginAccountInBackend() {
    const postObject = {
      header: { appclient: 'W' },
      sponsorUser: {
        email: this.state.email,
        credential: this.state.password,
      },
    };
    FetchServerData.callPostService('/sponsoruserms/login', postObject)
      .then((response) => {
        let output = response;
        if (output.status === 'SUCCESS') {
          let sponsorDetails = output.data.user;
          // store.dispatch(adminAppUserDataAction.setAdminUser(output.data.sponsorUser));
          output.data.user.isUserLogin = 'sponsor';
          store.dispatch(sponsorDetailsAction.setSponsor(sponsorDetails.sponsor));
          store.dispatch(adminAppUserDataAction.setAdminUser(output.data.user));
          this.processLoginSuccess(output.data);
          this.props.history.push({
            pathname: '/viewSponsor',
            state: {
              action: 'view',
              title: 'Sponsor Details',
              pageId: 'sponsorLoginPage',
              sponsorId: sponsorDetails.sponsor.id,
            },
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  render() {
    return (
      <>
        <Col md={12}>
          <Row>
            <Col md={6} style={{ marginTop: '10em' }}>
              <img alt="" src={fccLogo} style={{ height: '468px', width: '468px' }} />
            </Col>
            <Col md={6} style={{ marginTop: '11em' }}>
              <Card className="shadow border-0">
                <CardHeader className="bg-transparent">
                  <div className="adminLoginHeader">
                    <p>
                      <b>Family Care Circle</b>
                    </p>
                    <p> Sponsor Login </p>
                  </div>
                </CardHeader>
                <CardBody className="loginCardBody">
                  <ValidatorForm
                    ref="form"
                    onSubmit={this.loginAccountInBackend.bind(this)}
                    style={{ width: '100%', padding: '1em' }}
                  >
                    <Row>
                      <Col md={12}>
                        <TextValidator
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="email"
                          value={this.state.email}
                          label="Email "
                          name="email"
                          type="text"
                          autoComplete="email"
                          autoFocus
                          style={{ width: '100%' }}
                          validators={['required', 'isEmail']}
                          errorMessages={['this field is required', 'email is not valid']}
                          onChange={this.handleEmailChange.bind(this)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          value={this.state.password}
                          name="password"
                          label="Password"
                          type={this.state.showPassword ? 'text' : 'password'}
                          id="password"
                          autoComplete="current-password"
                          onChange={this.handlePasswordChange.bind(this)}
                          InputProps={{
                            // <-- This is where the toggle button is added.
                            endAdornment: (
                              <InputAdornment position="end" onFocus={this.gotToButton.bind(this)}>
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowPassword.bind(this)}
                                >
                                  {this.state.showPassword ? <VisibilityOff /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Col>
                    </Row>
                    <div className="loginCardFooter">
                      <Button id="loginButton" type="submit" variant="contained">
                        Log In
                      </Button>
                      <Row className="forgetPasswordRow">
                        <Col className="text-center" md="12">
                          <Link className="passwordLink" onClick={(e) => this.goToPage()}>
                            Forgot password ?
                          </Link>
                        </Col>
                      </Row>
                    </div>
                  </ValidatorForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}
export default AdminSponsorLogin;
