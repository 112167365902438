import MaterialTable from '@material-table/core';
import { createMuiTheme } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import PrintRoundedIcon from '@material-ui/icons/PrintRounded';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import ReactToPrint from 'react-to-print';
import { Card, CardHeader, CardBody, Col, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import UiUtils from '../../../provider/UiUtils';
import Utils from '../../../provider/Utils';
import applicationStateAction from '../../../redux/applicationState/applicationStateAction';
import fccPageStateAction from '../../../redux/fccPageState/fccPageStateAction';
import store from '../../../redux/store';
import Advertisement from '../advertisement/Advertisement';
import AddIconButton from '../buttons/AddIconButton';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
import PremiumFeatures from '../premiumFeatures/PremiumFeatures';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import PtsModal from '../modal/PtsModal';
import appUserDataAction from '../../../redux/appUserData/appUserDataAction';
import PtsAlert from '../ptsAlert/PtsAlert';
import BreadCrumbDetailsAction from '../../../redux/breadCrumbDetails/BreadCrumbDetailsAction';
export class PrescriptionsList extends Component {
  title = 'Prescriptions List';
  PrintTitle = 'Print Preview of Prescription List';
  header = store.getState().header.header;
  memberDetails = store.getState().memberData.memberData;
  memberName = store.getState().memberData.memberData.firstname + ' ' + store.getState().memberData.memberData.lastname;
  prescriptionsState = store.getState().applicationState.prescriptionsState;
  isFreeUser = store.getState().userData.userData.isFreeUser;
  sponsorUser = store.getState().Sponsorship.Sponsorship;
  memberHomeUrl = store.getState().memberHomeUrl.memberHomeUrl.homeUrl;
  medicineTypeList = store.getState().staticData.medicineTypeList;
  premiumFeatures = store.getState().userData.premiumFeatures;
  breadCrumb = [
    { pageid: this.memberHomeUrl, uiname: this.memberName },
    { pageid: '/prescriptionsList', uiname: 'Prescriptions List' },
  ];
  // printViewBreadCrumb = [
  //   { pageid: this.memberHomeUrl, uiname: this.memberName },
  //   { pageid: '', uiname: 'Prescriptions List' },
  // ];
  prescriptionsStateDetails = {};

  constructor(props) {
    super(props);

    if (this.prescriptionsState) {
      this.prescriptionsStateDetails = Object.getOwnPropertyNames(this.prescriptionsState);
    }
    /*current URL path for sponsorship template*/
    if (this.medicineTypeList.length > 0) {
      this.medicineTypeList.unshift({ type: 'All', id: 'All' });
    }

    let medicineTypeListArray = this.medicineTypeList.reduce((unique, o) => {
      if (!unique.some((obj) => obj.type === o.type && obj.id === o.id)) {
        unique.push(o);
      }
      return unique;
    }, []);
    if (this.premiumFeatures !== null && this.premiumFeatures.status !== null) {
      this.premiumFeaturesStatus = this.premiumFeatures.status.status;
    } else {
      this.premiumFeaturesStatus = '';
    }
    let currentLocationPath1 = window.location.href;
    let pathArray = currentLocationPath1.split('/');
    let host = pathArray[4];
    let currentLocationPath = '/' + host;
    store.dispatch(fccPageStateAction.setFccPageState({}));
    if (this.breadCrumb) {
      Utils.getActiveMenu(this.breadCrumb);
      store.dispatch(BreadCrumbDetailsAction.setBreadCrumbDetails(this.breadCrumb));
    }
    if (this.prescriptionsStateDetails.length > 0) {
      this.state = {
        pageSize:
          this.prescriptionsState.pageSize && this.prescriptionsState.pageSize ? this.prescriptionsState.pageSize : 10,
        isPaging: false,
        render: false,
        initialPage:
          this.prescriptionsState.initialPage && this.prescriptionsState.initialPage > 0
            ? this.prescriptionsState.initialPage
            : 0,
        prescriptionId: this.prescriptionsState.prescriptionId,
        selectedTab: this.prescriptionsState.selectedTab,
        drugName: this.prescriptionsState.drugName,
        prescribedDate: this.prescriptionsState.prescribedDate,
        type: this.prescriptionsState.type,
        pharmacy: this.prescriptionsState.pharmacy,
        prescriberDoctor: this.prescriptionsState.prescriberDoctor,
        getAllPermission: false,
        getUpdatePermission: false,
        getDeletePermission: false,
        getReadPermission: false,
        getCreatePermission: false,
        isFreeUser: this.isFreeUser,
        sponsorUser: this.sponsorUser,
        currentLocationPath: currentLocationPath,
        stepsEnabled: false,
        initialStep: 0,
        allPrescriptionList: [],
        activePrescriptionList: [],
        notTakingPrescriptionList: [],
        notTakingPrescriptionListPaging: false,
        activePrescriptionListPaging: false,
        doctorList: [],
        pharmacyList: [],
        medicineTypeList: medicineTypeListArray,
        view: 'list',
        openPremiumModal: false,
        confirmBox: false,
        prescriptionList: [],
      };
    } else {
      this.state = {
        pageSize: 10,
        isPaging: false,
        initialPage: 0,
        render: false,
        medicineTypeList: medicineTypeListArray,
        getCreatePermission: false,
        allPrescriptionList: [],
        activePrescriptionList: [],
        notTakingPrescriptionList: [],
        selectedTab: 0,
        notTakingPrescriptionListPaging: false,
        activePrescriptionListPaging: false,
        doctorList: [],
        pharmacyList: [],
        drugName: null,
        prescribedDate: null,
        type: medicineTypeListArray[0],
        pharmacy: {
          id: null,
          name: 'All',
          locations: 'All',
        },
        prescriberDoctor: {
          name: 'All',
          id: null,
          phone: 'All',
          salution: null,
          specialist: 'All',
          locations: 'All',
        },
        view: 'list',
        openPremiumModal: false,
        confirmBox: false,
        prescriptionList: [],
      };
    }
    // this.getPrescriptionsList();
  }
  async componentDidMount() {
    if (this.prescriptionsStateDetails.length > 0) {
      await this.getPharmacyList();
      await this.getDoctorList();
      await this.filterDataInBackend();
    } else {
      await this.getPrescriptionsList();
      await this.getPharmacyList();
      await this.getDoctorList();
    }
  }
  getPharmacyList() {
    const postObject = {
      header: this.header,
      facility: {
        member: {
          id: this.memberDetails.member,
        },
      },
    };
    FetchServerData.callPostService('facilityms/getMemberPharmacyList', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let pharmacyListData = output.data.list;
        if (pharmacyListData.length > 0) {
          pharmacyListData.unshift({ name: 'All', id: 'All' });
        }
        let pharmacyListArray = pharmacyListData.reduce((unique, o) => {
          if (!unique.some((obj) => obj.name === o.name && obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []);

        this.setState({
          pharmacyList: pharmacyListArray,
          render: true,
        });
      } else {
      }
    });
  }
  getDoctorList() {
    const postObject = {
      header: this.header,
      doctor: {
        member: {
          id: this.memberDetails.member,
        },
      },
    };
    FetchServerData.callPostService('doctorms/getActiveDoctors', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let doctorListData = output.data.doctorList;
        if (doctorListData.length > 0) {
          doctorListData.unshift({ name: 'All', id: 'All' });
        }
        let doctorListArray = doctorListData.reduce((unique, o) => {
          if (!unique.some((obj) => obj.name === o.name && obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []);
        this.setState({
          doctorList: doctorListArray,
          render: true,
        });
      } else {
      }
    });
  }
  getPrescriptionsList() {
    const postObject = {
      header: this.header,

      member: {
        id: this.memberDetails.member,
      },
    };

    FetchServerData.callPostService('/medicinems/getFilteredList', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let prescriptionList = output.data.medicineList;
        for (let i = 0; i < prescriptionList.length; i++) {
          if (prescriptionList[i].startDate) {
            prescriptionList[i].startDateSortDate = prescriptionList[i].startDate;
            prescriptionList[i].startDate = Utils.convertUTCDateToUserTimeZoneDateWithoutTimezoneFormate(
              prescriptionList[i].startDate
            );
          }
          if (prescriptionList[i].endDate) {
            prescriptionList[i].lastDateSortDate = prescriptionList[i].endDate;
            prescriptionList[i].endDate = Utils.convertUTCDateToUserTimeZoneDateWithoutTimezoneFormate(
              prescriptionList[i].endDate
            );
          }
        }

        let activePrescriptionListArr = [];
        let notTakingPrescriptionListArr = [];

        let getDomainObjectPermission = output.data.domainObjectPrivileges;
        let getCreatePermission = false;

        for (let domainObjectKey in getDomainObjectPermission) {
          if (Utils.equalsIgnoreCase(domainObjectKey, 'Medicine')) {
            let prescriptionPermission = getDomainObjectPermission[domainObjectKey];

            let getPermission = Utils.getDomainObjectRolePermission(prescriptionPermission);
            getCreatePermission = getPermission[0];
          }
        }
        let isPaging = false;
        let activePrescriptionListPaging = false;
        let notTakingPrescriptionListPaging = false;
        for (let i = 0; i < prescriptionList.length; i++) {
          if (prescriptionList[i].status === 'Active') {
            activePrescriptionListArr.push(prescriptionList[i]);
          } else if (prescriptionList[i].status === 'NotTaking') {
            notTakingPrescriptionListArr.push(prescriptionList[i]);
          }
        }
        if (prescriptionList.length > 10) {
          isPaging = true;
        }
        if (activePrescriptionListArr.length > 10) {
          activePrescriptionListPaging = true;
        }
        if (notTakingPrescriptionListArr.length > 10) {
          notTakingPrescriptionListPaging = true;
        }

        this.setState({
          isPaging: isPaging,
          prescriptionList: prescriptionList,
          render: true,
          view: 'list',
          initialPage: this.state.initialPage,
          getCreatePermission: getCreatePermission,
          activePrescriptionList: activePrescriptionListArr,
          notTakingPrescriptionList: notTakingPrescriptionListArr,
          notTakingPrescriptionListPaging: notTakingPrescriptionListPaging,
          activePrescriptionListPaging: activePrescriptionListPaging,
        });
      } else {
      }
    });
  }
  setKey(tab) {
    this.setState({
      selectedTab: tab,
    });

    let prescriptionsState = {
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
      selectedTab: tab,
    };
    store.dispatch(
      applicationStateAction.setApplicationState({
        prescriptionsState,
      })
    );
  }
  onChangePage(page) {
    this.setState({
      initialPage: page,
    });
    let prescriptionsState = {
      initialPage: page,
      prescriptionId: this.state.prescriptionId,
    };
    store.dispatch(
      applicationStateAction.setApplicationState({
        prescriptionsState,
      })
    );
  }
  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }
  addPrescription(e) {
    if (this.isFreeUser === true) {
      if (this.premiumFeatures !== null && this.premiumFeaturesStatus === 'Expired') {
        this.setState({
          openPremiumModal: true,
        });
      } else if (this.premiumFeatures === null) {
        this.setState({
          openPremiumModal: true,
        });
      } else {
        let fccPageState = {
          id: '',
          action: 'create',
          title: 'Add Prescription',
        };
        store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
        this.props.history.push({
          pathname: '/addPrescription',
          state: { action: 'create', title: 'Add Prescription', prescriptionId: '' },
        });
      }
    } else {
      let fccPageState = {
        id: '',
        action: 'create',
        title: 'Add Prescription',
      };
      store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
      this.props.history.push({
        pathname: '/addPrescription',
        state: { action: 'create', title: 'Add Prescription', prescriptionId: '' },
      });
    }
  }
  toPrescriptionDetails = (e, data) => {
    let prescriptionsState = {
      initialPage: this.state.initialPage,
      prescriptionId: data.id,
      pageSize: this.state.pageSize,
      selectedTab: this.state.selectedTab,
      drugName: this.state.drugName,
      prescribedDate: this.state.prescribedDate,
      type: this.state.type,
      pharmacy: this.state.pharmacy,
      prescriberDoctor: this.state.prescriberDoctor,
    };
    store.dispatch(
      applicationStateAction.setApplicationState({
        prescriptionsState,
      })
    );
    let fccPageState = {
      id: data.id,
      action: 'view',
      title: 'prescriptions Details',
      pageId: 'prescriptions',
    };
    store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
    this.props.history.push({
      pathname: '/viewPrescriptions',
      state: {
        action: 'view',
        title: 'prescriptions Details',
        prescriptionId: data.id,
        pageId: 'prescriptions',
      },
    });
  };
  handleDrugNameChange(e) {
    this.setState({
      drugName: e.target.value,
    });
  }

  handleDoctorChange(prescriberDoctor) {
    this.setState({
      prescriberDoctor: prescriberDoctor,
    });
    if (undefined !== this.state.onChange && null !== this.state.onChange) {
      this.state.onChange(this.state);
    }
  }

  handlePharmacyChange(pharmacy) {
    this.setState({
      pharmacy: pharmacy,
    });
    if (undefined !== this.state.onChange && null !== this.state.onChange) {
      this.state.onChange(this.state);
    }
  }
  handleTypeChange(selectedType) {
    this.setState({
      type: selectedType,
    });
    if (undefined !== this.state.onChange && null !== this.state.onChange) {
      this.state.onChange(this.state);
    }
  }

  handleDatePrescribedChange(date) {
    this.setState({ prescribedDate: date });
  }

  filterDataInBackend() {
    let pharmacyName = null;
    let prescriptionType = null;
    let prescribedDateFormate = null;
    let selectedDoctor = null;
    console.log(this.state.pharmacy);
    if (this.state.pharmacy !== 'All') {
      pharmacyName = this.state.pharmacy?.name;
    } else {
      pharmacyName = null;
    }
    if (this.state.type?.type !== 'All') {
      prescriptionType = this.state.type?.type;
    } else {
      prescriptionType = null;
    }
    if (this.state.prescribedDate) {
      prescribedDateFormate = moment(this.state.prescribedDate).format('YYYY-MM-DD');
    }
    if (this.state.prescriberDoctor?.name !== 'All') {
      selectedDoctor = this.state.prescriberDoctor;
    } else {
      selectedDoctor = {
        name: null,
        id: null,
      };
    }
    const postObject = {
      header: this.header,

      member: {
        id: this.memberDetails.member,
      },
      name: this.state.drugName,
      pharmacy: pharmacyName,
      prescribedBy: selectedDoctor,
      type: {
        type: prescriptionType,
      },
      prescribedDate: prescribedDateFormate,
      endDate: null,
      strength: null,
    };

    FetchServerData.callPostService('/medicinems/getFilteredList', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let prescriptionList = output.data.medicineList;
        for (let i = 0; i < prescriptionList.length; i++) {
          if (prescriptionList[i].startDate) {
            prescriptionList[i].startDateSortDate = prescriptionList[i].startDate;
            prescriptionList[i].startDate = Utils.convertUTCDateToUserTimeZoneDateWithoutTimezoneFormate(
              prescriptionList[i].startDate
            );
          }
          if (prescriptionList[i].endDate) {
            prescriptionList[i].lastDateSortDate = prescriptionList[i].endDate;
            prescriptionList[i].endDate = Utils.convertUTCDateToUserTimeZoneDateWithoutTimezoneFormate(
              prescriptionList[i].endDate
            );
          }
        }

        let activePrescriptionListArr = [];
        let notTakingPrescriptionListArr = [];

        let getDomainObjectPermission = output.data.domainObjectPrivileges;
        let getCreatePermission = false;

        for (let domainObjectKey in getDomainObjectPermission) {
          if (Utils.equalsIgnoreCase(domainObjectKey, 'Medicine')) {
            let prescriptionPermission = getDomainObjectPermission[domainObjectKey];

            let getPermission = Utils.getDomainObjectRolePermission(prescriptionPermission);
            getCreatePermission = getPermission[0];
          }
        }
        let isPaging = false;
        let activePrescriptionListPaging = false;
        let notTakingPrescriptionListPaging = false;
        for (let i = 0; i < prescriptionList.length; i++) {
          if (prescriptionList[i].status === 'Active') {
            activePrescriptionListArr.push(prescriptionList[i]);
          } else if (prescriptionList[i].status === 'NotTaking') {
            notTakingPrescriptionListArr.push(prescriptionList[i]);
          }
        }
        if (prescriptionList.length > 10) {
          isPaging = true;
        }
        if (activePrescriptionListArr.length > 10) {
          activePrescriptionListPaging = true;
        }
        if (notTakingPrescriptionListArr.length > 10) {
          notTakingPrescriptionListPaging = true;
        }

        this.setState({
          isPaging: isPaging,
          prescriptionList: prescriptionList,
          render: true,
          view: 'list',
          initialPage: this.state.initialPage,
          getCreatePermission: getCreatePermission,
          activePrescriptionList: activePrescriptionListArr,
          notTakingPrescriptionList: notTakingPrescriptionListArr,
          notTakingPrescriptionListPaging: notTakingPrescriptionListPaging,
          activePrescriptionListPaging: activePrescriptionListPaging,
        });
      } else {
      }
    });
  }

  resetData() {
    this.setState({
      selectedTab: this.state.selectedTab,
      drugName: '',
      prescribedDate: null,
      type: this.state.medicineTypeList[0],
      pharmacy: this.state.pharmacyList[0],
      prescriberDoctor: this.state.doctorList[0],
    });

    let prescriptionsState = {};
    store.dispatch(applicationStateAction.setApplicationState({ prescriptionsState }));
    this.getPrescriptionsList();
  }
  // printData() {
  //   this.setState({ view: 'print' });
  // }
  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      if (this.state.view === 'print') {
        return this.PrintView();
      } else if (this.state.view === 'list') {
        return this.listView();
      }
    }
  }
  printViewData = () => {
    this.setState({ view: 'print' });
  };
  backCall() {
    this.setState({ view: 'list' });
  }

  premiumModalToggle = () => {
    this.setState({
      openPremiumModal: !this.state.openPremiumModal,
    });
  };
  startTrialPeriod() {
    const header = store.getState().header.header;

    const postObject = {
      header: header,
    };
    FetchServerData.callPostService('/userPremiumFeatureMs/startTrial', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          PtsAlert.success(output.message);
          let premiumFeatureTrialinfo = output.data.premiumFeatureTrialinfo;

          this.setState({
            render: this.toResendRequestInBackend,
            openPremiumModal: false,
            confirmBox: true,
            isFreeUser: output.data.isFreeUser,
            endDate: premiumFeatureTrialinfo.endDate,
          });
          // this.getHomeData();
          let userData = {
            appuserVo: output.data,
          };
          store.dispatch(appUserDataAction.setUser(userData));
          this.premiumFeatures = output.data.premiumFeatureTrialinfo;

          // store.dispatch(PremiumFeatureAction.setPremiumFeature(output.data.premiumFeatureTrialinfo));
        } else {
        }
      })
      .catch((error) => {});
  }
  printData() {
    let printContents = document.getElementById('printform').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();

    document.body.innerHTML = originalContents;
    this.setState({ render: true });
    document.location.reload(true);
  }
  handleConfirmBoxClose = () => {
    this.setState({
      confirmBox: false,
    });
  };
  handleConfirmBox = () => {
    this.setState({
      confirmBox: false,
    });
    this.getPrescriptionsList();
  };
  purchaseSubscription = () => {
    this.props.history.push({
      pathname: '/homePricing',
    });
  };
  showFirstPremiumFeatures() {
    return (
      <>
        <Row style={{ margin: '1em' }}>
          <Col md={12}>
            <Card
              style={{
                height: '100%',
                borderRadius: '20px',
                boxShadow:
                  '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2)',
              }}
            >
              <CardHeader
                style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', textAlign: 'center', color: '#000' }}
              >
                <h3>
                  <b>Premium Features</b>
                </h3>
              </CardHeader>
              <CardBody>
                <Row style={{ textAlign: 'left', padding: '15px' }}>
                  <Col md={12} style={{ margin: 'auto' }}>
                    <p>This feature is part of our premium package and is available for subscribed users.</p>

                    <p style={{ lineHeight: '2em' }}>
                      The premium package includes the following:
                      <ul style={{ marginLeft: '1.5em' }}>
                        <li>
                          Consolidated View – Available on the home screen quick view of appointments across all the
                          circles you are enrolled in.
                        </li>
                        <li>
                          Prescriptions – Enter all of your medicines and vitamins to have readily available for your
                          next office visit.
                        </li>
                        <li>Ad Free experience – Ads are suppressed on all screens.</li>
                        <li>Unlimited Family Circles – Create or participate in as many Circles as needed.</li>
                        <li>Unlimited Appointments per month – Track as many appointments as needed.</li>
                      </ul>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Button
                      variant="contained"
                      size="small"
                      // type="submit"
                      style={{ background: '#138D75', color: '#fff', marginLeft: '3em' }}
                      onClick={this.startTrialPeriod.bind(this)}
                    >
                      Start A 30 day Trial Period
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      className="purchaseSubscription"
                      variant="contained"
                      size="small"
                      style={{
                        background: '#f26d21',
                        color: '#fff',
                        borderBottom: '3px solid #636262',
                        borderRadius: '5px',
                        margin: '50px auto',
                      }}
                      onClick={(e) => this.purchaseSubscription(e)}
                    >
                      Purchase Subscription
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
  listView() {
    return (
      <>
        <MenuWithBreadScrum breadCrumb={this.breadCrumb} history={this.props.history} name={this.title} />
        <Row style={{ margin: '1em' }}>
          <Advertisement pageId="Prescriptions" />
        </Row>
        <div className="appointmentList_filter">
          <Card style={{ background: '#fff', margin: '1.2em' }}>
            <CardHeader style={{ padding: '0em 1.2em' }}>
              <Row md={12} style={{ padding: '10px' }}>
                <Col md={2}>
                  <Autocomplete
                    id="type"
                    // options={this.state.medicineTypeList}
                    options={null === this.state.medicineTypeList ? [] : this.state.medicineTypeList}
                    value={this.state.type}
                    getOptionLabel={(option) => (option.type ? option.type : '')}
                    onChange={(event, value) => {
                      this.handleTypeChange(value);
                    }}
                    renderInput={(params) => <TextField {...params} variant="outlined" label="Type" margin="normal" />}
                  />
                </Col>
                <Col md={3}>
                  <TextField
                    style={{ marginTop: '0.8em' }}
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    type="text"
                    id="drugName"
                    onChange={this.handleDrugNameChange.bind(this)}
                    label="Drug Name"
                    name="drugName"
                    inputprops={{ style: { textTransform: 'capitalize' } }}
                    value={this.state.drugName}
                  />
                </Col>

                <Col md={3}>
                  <Autocomplete
                    id="Prescriber"
                    options={this.state.doctorList}
                    value={this.state.prescriberDoctor}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      this.handleDoctorChange(value);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Prescribed By" margin="normal" />
                    )}
                  />
                </Col>
                <Col md={2}>
                  <Autocomplete
                    id="pharmacy"
                    options={null === this.state.pharmacyList ? [] : this.state.pharmacyList}
                    value={this.state.pharmacy}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, value) => {
                      this.handlePharmacyChange(value);
                    }}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" label="Pharmacy" margin="normal" />
                    )}
                  />
                </Col>

                <Col md={2} className="filter_btn">
                  <Button
                    variant="contained"
                    size="small"
                    className="filterData"
                    startIcon={
                      <span>
                        <i className="fa fa-filter" aria-hidden="true"></i>
                      </span>
                    }
                    style={{
                      background: '#ef6603',
                      color: '#fff',
                      marginTop: '18px',
                      textTransform: 'capitalize',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => this.filterDataInBackend()}
                  >
                    Filter
                  </Button>
                  &nbsp;
                  <Button
                    variant="contained"
                    size="small"
                    className="resetData"
                    startIcon={
                      <span>
                        <i className="fa fa-refresh" aria-hidden="true"></i>
                      </span>
                    }
                    style={{
                      background: '#000',
                      color: '#fff',
                      marginTop: '18px',

                      textTransform: 'capitalize',
                      cursor: 'pointer',
                    }}
                    onClick={(e) => this.resetData()}
                  >
                    Reset
                  </Button>
                </Col>
              </Row>
            </CardHeader>
          </Card>
        </div>
        <div style={{ padding: '1em' }} className="doctor_List">
          <Row style={{ backgroundColor: 'none', margin: '-0.5em -1.3em -2.4em' }}>
            <Col md={12}>
              {this.state.getCreatePermission === true ? (
                <>
                  <AddIconButton btnName="Add Prescription" onClick={(e) => this.addPrescription(e)} />

                  <Button
                    className="add"
                    style={{
                      background: '#005476',
                      color: 'white',
                      fontSize: 'x-large',
                      textTransform: 'capitalize',
                      cursor: 'pointer',
                      fontSize: 'x-larger',
                      float: 'right',
                      margin: '0.2em 0.5em 0.3em 0em',
                    }}
                    startIcon={<PrintRoundedIcon />}
                    onClick={(e) => this.printViewData()}
                  >
                    Print Preview
                  </Button>
                </>
              ) : (
                <ReactToPrint
                  trigger={() => (
                    <span style={{ cursor: 'pointer', fontSize: 'x-larger', float: 'right' }}>
                      <Tooltip title="Print">
                        <Button
                          className="add"
                          style={{
                            background: '#005476',
                            color: 'white',
                            fontSize: 'x-large',
                            textTransform: 'capitalize',
                            cursor: 'pointer',
                            fontSize: 'x-larger',
                            float: 'right',
                            margin: '0.2em 0.5em 0.3em 0em',
                          }}
                          startIcon={<PrintRoundedIcon />}
                          onClick={(e) => this.printViewData()}
                        >
                          Print
                        </Button>
                      </Tooltip>
                    </span>
                  )}
                  content={() => this.componentRef}
                />
              )}
            </Col>
          </Row>
          <Tabs defaultIndex={this.state.selectedTab} onSelect={(k) => this.setKey(k)}>
            <TabList>
              <Tab>
                {' '}
                <span className="tabCompleted">Active</span>{' '}
              </Tab>
              <Tab>
                <span className="tabCancelled">Not Taking</span>
              </Tab>
              <Tab>
                {' '}
                <span className="tabUpcoming">All</span>
              </Tab>
            </TabList>
            <TabPanel>
              {/* <div id="printform" style={{ overflowY: 'scroll' }} ref={(el) => (this.componentRef = el)}> */}
              <MaterialTable
                className="prescriptionList"
                onRowClick={(event, rowData) => {
                  this.toPrescriptionDetails(event, rowData);
                  event.stopPropagation();
                }}
                icons={UiUtils.getMaterialTableIcons()}
                title=""
                localization={{
                  body: {
                    emptyDataSourceMessage: Utils.communizeTableText(),
                  },
                }}
                columns={[
                  {
                    title: '#',
                    render: (rowData) => rowData.tableData.index + 1,
                    width: '5%',
                    // field: 'id',
                    align: 'left',
                  },
                  {
                    title: 'Type',
                    field: 'type',

                    width: '10%',
                  },
                  {
                    title: 'Drug Name',
                    field: 'name',
                    width: '20%',
                  },

                  // {
                  //   title: 'Strength',
                  //   field: 'strength',
                  //   width: '10%',
                  // },
                  {
                    title: 'Prescribed By',
                    field: 'doctorname',
                    width: '15%',
                    filtering: false,
                  },
                  {
                    title: 'Pharmacy',
                    field: 'pharmacy',
                    width: '10%',
                  },
                  {
                    title: 'Start Date ',
                    type: 'date',
                    field: 'startDate',
                    filtering: false,
                    customSort: (a, b) => {
                      let aTime = a.startDateSortDate !== null ? new Date(a.startDateSortDate).getTime() : '';
                      let bTime = b.startDateSortDate !== null ? new Date(b.startDateSortDate).getTime() : '';
                      return aTime - bTime;
                    },

                    width: '15%',
                  },

                  {
                    title: 'Stopped Taking',
                    type: 'date',
                    field: 'endDate',
                    filtering: false,
                    customSort: (a, b) => {
                      let aTime = a.lastDateSortDate !== null ? new Date(a.lastDateSortDate).getTime() : '';
                      let bTime = b.lastDateSortDate !== null ? new Date(b.lastDateSortDate).getTime() : '';
                      return aTime - bTime;
                    },

                    width: '15%',
                  },
                ]}
                data={this.state.activePrescriptionList}
                options={{
                  sorting: true,
                  actionsColumnIndex: -1,
                  initialPage: this.state.initialPage,
                  pageSize: this.state.pageSize,
                  headerStyle: {
                    backgroundColor: '#1ABC9C',
                    fontWeight: 'bold',
                    color: '#fff',
                    align: 'left',
                    textAlign: 'left',
                  },
                  pageSizeOptions: [5, 10, 20, 50, 100],
                  filtering: true,
                  // paging: this.state.activePrescriptionListPaging,
                  paging: this.state.activePrescriptionList.length > this.state.pageSize,
                  rowStyle: (rowData) => ({
                    backgroundColor: rowData.id === this.state.prescriptionId ? '#FEF5E7' : '#fff',
                    height: '3em',
                    textTransform: 'capitalize',
                  }),
                }}
                actions={[]}
                onPageChange={(page, pageSize) => {
                  this.handleMaterialTablePageChange(page, pageSize);
                }}
              />
              {/* </div> */}
            </TabPanel>
            <TabPanel>
              {/* <div id="printform" style={{ overflowY: 'scroll' }} ref={(el) => (this.componentRef = el)}> */}
              <MaterialTable
                className="prescriptionList"
                onRowClick={(event, rowData) => {
                  this.toPrescriptionDetails(event, rowData);
                  event.stopPropagation();
                }}
                icons={UiUtils.getMaterialTableIcons()}
                title=""
                localization={{
                  body: {
                    emptyDataSourceMessage: Utils.communizeTableText(),
                  },
                }}
                columns={[
                  {
                    title: '#',
                    render: (rowData) => rowData.tableData.index + 1,
                    width: '5%',
                    // field: 'id',
                    align: 'left',
                  },
                  {
                    title: 'Type',
                    field: 'type',

                    width: '10%',
                  },
                  {
                    title: 'Drug Name',
                    field: 'name',
                    width: '20%',
                  },

                  // {
                  //   title: 'Strength',
                  //   field: 'strength',
                  //   width: '10%',
                  // },
                  {
                    title: 'Prescribed By',
                    field: 'doctorname',
                    width: '15%',
                    filtering: false,
                  },
                  {
                    title: 'Pharmacy',
                    field: 'pharmacy',
                    width: '10%',
                  },
                  {
                    title: 'Start Date ',
                    type: 'date',
                    field: 'startDate',
                    filtering: false,
                    customSort: (a, b) => {
                      let aTime = a.startDateSortDate !== null ? new Date(a.startDateSortDate).getTime() : '';
                      let bTime = b.startDateSortDate !== null ? new Date(b.startDateSortDate).getTime() : '';
                      return aTime - bTime;
                    },

                    width: '15%',
                  },

                  {
                    title: 'Stopped Taking',
                    type: 'date',
                    field: 'endDate',
                    filtering: false,
                    customSort: (a, b) => {
                      let aTime = a.lastDateSortDate !== null ? new Date(a.lastDateSortDate).getTime() : '';
                      let bTime = b.lastDateSortDate !== null ? new Date(b.lastDateSortDate).getTime() : '';
                      return aTime - bTime;
                    },

                    width: '15%',
                  },
                ]}
                data={this.state.notTakingPrescriptionList}
                options={{
                  sorting: true,
                  actionsColumnIndex: -1,
                  initialPage: this.state.initialPage,
                  pageSize: this.state.pageSize,
                  headerStyle: {
                    backgroundColor: '#1ABC9C',
                    fontWeight: 'bold',
                    color: '#fff',
                    align: 'left',
                    textAlign: 'left',
                  },
                  pageSizeOptions: [5, 10, 20, 50, 100],
                  filtering: true,
                  paging: this.state.notTakingPrescriptionList.length > this.state.pageSize,
                  // paging: this.state.notTakingPrescriptionListPaging,
                  rowStyle: (rowData) => ({
                    backgroundColor: rowData.id === this.state.prescriptionId ? '#FEF5E7' : '#fff',
                    height: '3em',
                    textTransform: 'capitalize',
                  }),
                }}
                actions={[]}
                onPageChange={(page, pageSize) => {
                  this.handleMaterialTablePageChange(page, pageSize);
                }}
              />
              {/* </div> */}
            </TabPanel>
            <TabPanel>
              {/* <div id="printform" style={{ overflowY: 'scroll' }} ref={(el) => (this.componentRef = el)}> */}
              <MaterialTable
                className="prescriptionList"
                onRowClick={(event, rowData) => {
                  this.toPrescriptionDetails(event, rowData);
                  event.stopPropagation();
                }}
                icons={UiUtils.getMaterialTableIcons()}
                title=""
                localization={{
                  body: {
                    emptyDataSourceMessage: Utils.communizeTableText(),
                  },
                }}
                columns={[
                  {
                    title: '#',
                    render: (rowData) => rowData.tableData.index + 1,
                    width: '5%',
                    // field: 'id',
                    align: 'left',
                  },
                  {
                    title: 'Type',
                    field: 'type',

                    width: '10%',
                  },
                  {
                    title: 'Drug Name',
                    field: 'name',
                    width: '20%',
                  },

                  // {
                  //   title: 'Strength',
                  //   field: 'strength',
                  //   width: '10%',
                  // },
                  {
                    title: 'Prescribed By',
                    field: 'doctorname',
                    width: '15%',
                    filtering: false,
                  },
                  {
                    title: 'Pharmacy',
                    field: 'pharmacy',
                    width: '10%',
                  },
                  {
                    title: 'Start Date ',
                    type: 'date',
                    field: 'startDate',
                    filtering: false,
                    customSort: (a, b) => {
                      let aTime = a.startDateSortDate !== null ? new Date(a.startDateSortDate).getTime() : '';
                      let bTime = b.startDateSortDate !== null ? new Date(b.startDateSortDate).getTime() : '';
                      return aTime - bTime;
                    },

                    width: '15%',
                  },

                  {
                    title: 'Stopped Taking',
                    type: 'date',
                    field: 'endDate',
                    filtering: false,
                    customSort: (a, b) => {
                      let aTime = a.lastDateSortDate !== null ? new Date(a.lastDateSortDate).getTime() : '';
                      let bTime = b.lastDateSortDate !== null ? new Date(b.lastDateSortDate).getTime() : '';
                      return aTime - bTime;
                    },

                    width: '15%',
                  },
                ]}
                data={this.state.prescriptionList}
                options={{
                  sorting: true,
                  actionsColumnIndex: -1,
                  initialPage: this.state.initialPage,
                  pageSize: this.state.pageSize,
                  headerStyle: {
                    backgroundColor: '#1ABC9C',
                    fontWeight: 'bold',
                    color: '#fff',
                    align: 'left',
                    textAlign: 'left',
                  },
                  pageSizeOptions: [5, 10, 20, 50, 100],
                  filtering: true,
                  paging: this.state.prescriptionList.length > this.state.pageSize,
                  // paging: this.state.isPaging,
                  rowStyle: (rowData) => ({
                    backgroundColor: rowData.id === this.state.prescriptionId ? '#FEF5E7' : '#fff',
                    height: '3em',
                    textTransform: 'capitalize',
                  }),
                }}
                actions={[]}
                onPageChange={(page, pageSize) => {
                  this.handleMaterialTablePageChange(page, pageSize);
                }}
              />
              {/* </div> */}
            </TabPanel>
          </Tabs>
        </div>
        <PtsModal
          open={this.state.openPremiumModal}
          onClose={this.premiumModalToggle}
          // title="Family Care Circle Document"
        >
          {Utils.PremiumFeatures(this.premiumFeatures) === 1 ? (
            this.showFirstPremiumFeatures()
          ) : (
            <PremiumFeatures
              history={this.props.history}
              pageId="home"
              isFreeUser={this.state.isFreeUser}
              modalOpen={this.state.openPremiumModal}
              closeModal={this.premiumModalToggle.bind(this)}
            />
          )}
          {/* <PremiumFeatures
            history={this.props.history}
            pageId="home"
            modalOpen={this.state.openPremiumModal}
            closeModal={this.premiumModalToggle.bind(this)}
          /> */}
        </PtsModal>
        <Dialog
          open={this.state.confirmBox}
          onClose={this.handleConfirmBoxClose.bind(this)}
          onHide={this.close}
          style={{ padding: '1em' }}
        >
          <DialogContent style={{ marginTop: '1em' }}>
            <Row>
              <Col md={12} style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                <p style={{ lineHeight: '2em', fontSize: '18px' }}>
                  Your trial period has started.
                  <br /> You can enjoy all premium functionality till{' '}
                  {moment(this.state.endDate ? this.state.endDate : '').format('MMM DD, YYYY')}.
                </p>
              </Col>
            </Row>
          </DialogContent>
          <DialogActions style={{ marginTop: '1em', padding: '2em', marginLeft: 'auto', marginRight: 'auto' }}>
            <Button
              variant="contained"
              size="large"
              style={{ background: '#0f666b', color: '#fff', marginRight: '1rem' }}
              // startIcon={<SaveIcon />}
              onClick={(e) => this.handleConfirmBox(e)}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
  PrintView() {
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.PrintTitle}
          // backCallback={this.backCall.bind(this)}
        />
        {/* <Row style={{ margin: '1em' }}>
          <Advertisement pageId="Prescriptions" />
        </Row> */}

        <div style={{ padding: '1em' }} className="doctor_List">
          <Row>
            <Col md={12}>
              <Button
                className="add"
                style={{
                  background: '#717373',
                  color: '#fff',
                  fontSize: 'x-large',
                  textTransform: 'capitalize',
                  cursor: 'pointer',
                  fontSize: 'x-larger',
                  float: 'left',
                  margin: '0.2em 0.5em 0.3em 0em',
                }}
                startIcon={<ArrowBackIcon />}
                onClick={(e) => this.backCall()}
              >
                Back
              </Button>
            </Col>
          </Row>
          <Row style={{ backgroundColor: 'none', margin: '-0.5em -1.3em -2.4em' }}>
            <Col md={12}>
              {this.state.getCreatePermission === true ? (
                <>
                  {/* <AddIconButton btnName="Add Prescription" onClick={(e) => this.addPrescription(e)} /> */}
                  <ReactToPrint
                    trigger={() => (
                      <span style={{ cursor: 'pointer', fontSize: 'x-larger', float: 'right' }}>
                        <Tooltip title="Print">
                          <Button
                            className="add"
                            style={{
                              background: '#005476',
                              color: 'white',
                              fontSize: 'x-large',
                              textTransform: 'capitalize',
                              cursor: 'pointer',
                              fontSize: 'x-larger',
                              float: 'right',
                              margin: '0.2em 0.5em 0.3em 0em',
                            }}
                            startIcon={<PrintRoundedIcon />}
                            // onClick={(e) => this.printData()}
                          >
                            Print
                          </Button>
                        </Tooltip>
                      </span>
                    )}
                    content={() => this.componentRef}
                  />
                </>
              ) : (
                <ReactToPrint
                  trigger={() => (
                    <span style={{ cursor: 'pointer', fontSize: 'x-larger', float: 'right' }}>
                      <Tooltip title="Print">
                        <Button
                          className="add"
                          style={{
                            background: '#005476',
                            color: 'white',
                            fontSize: 'x-large',
                            textTransform: 'capitalize',
                            cursor: 'pointer',
                            fontSize: 'x-larger',
                            float: 'right',
                            margin: '0.2em 0.5em 0.3em 0em',
                          }}
                          startIcon={<PrintRoundedIcon />}
                          // onClick={(e) => this.printData()}
                        >
                          Print
                        </Button>
                      </Tooltip>
                    </span>
                  )}
                  content={() => this.componentRef}
                />
              )}
            </Col>
          </Row>
          <Tabs defaultIndex={this.state.selectedTab} onSelect={(k) => this.setKey(k)}>
            <TabList>
              <Tab>
                {' '}
                <span className="tabCompleted">Active</span>{' '}
              </Tab>
              <Tab>
                <span className="tabCancelled">Not Taking</span>
              </Tab>
              <Tab>
                {' '}
                <span className="tabUpcoming">All</span>
              </Tab>
            </TabList>
            <TabPanel>
              <div id="printform" style={{ overflowY: 'scroll' }} ref={(el) => (this.componentRef = el)}>
                <MaterialTable
                  className="prescriptionList"
                  onRowClick={(event, rowData) => {
                    this.toPrescriptionDetails(event, rowData);
                    event.stopPropagation();
                  }}
                  icons={UiUtils.getMaterialTableIcons()}
                  title={this.memberName}
                  localization={{
                    body: {
                      emptyDataSourceMessage: Utils.communizeTableText(),
                    },
                  }}
                  columns={[
                    {
                      title: '#',
                      render: (rowData) => rowData.tableData.index + 1,
                      width: '5%',

                      align: 'left',
                      filtering: false,
                    },
                    {
                      title: 'Type',
                      field: 'type',
                      filtering: false,
                      width: '10%',
                    },
                    {
                      title: 'Drug Name',
                      field: 'name',
                      width: '20%',
                      filtering: false,
                    },

                    {
                      title: 'Prescribed By',
                      field: 'doctorname',
                      width: '15%',
                      filtering: false,
                    },
                    {
                      title: 'Pharmacy',
                      field: 'pharmacy',
                      width: '10%',
                      filtering: false,
                    },
                    {
                      title: 'Start Date ',
                      type: 'date',
                      field: 'startDate',
                      filtering: false,
                      customSort: (a, b) => {
                        let aTime = a.startDateSortDate !== null ? new Date(a.startDateSortDate).getTime() : '';
                        let bTime = b.startDateSortDate !== null ? new Date(b.startDateSortDate).getTime() : '';
                        return aTime - bTime;
                      },

                      width: '15%',
                    },

                    {
                      title: 'Stopped Taking',
                      type: 'date',
                      field: 'endDate',
                      filtering: false,
                      customSort: (a, b) => {
                        let aTime = a.lastDateSortDate !== null ? new Date(a.lastDateSortDate).getTime() : '';
                        let bTime = b.lastDateSortDate !== null ? new Date(b.lastDateSortDate).getTime() : '';
                        return aTime - bTime;
                      },

                      width: '15%',
                    },
                  ]}
                  data={this.state.activePrescriptionList}
                  options={{
                    sorting: true,
                    actionsColumnIndex: -1,
                    search: false,
                    initialPage: this.state.initialPage,
                    pageSize: this.state.pageSize,
                    headerStyle: {
                      backgroundColor: '#1ABC9C',
                      fontWeight: 'bold',
                      color: '#fff',
                      align: 'left',
                      textAlign: 'left',
                    },
                    pageSizeOptions: [5, 10, 20, 50, 100],
                    paging: this.state.activePrescriptionList.length > this.state.pageSize,
                    // paging: this.state.activePrescriptionListPaging,
                    rowStyle: (rowData) => ({
                      backgroundColor: rowData.id === this.state.prescriptionId ? '#FEF5E7' : '#fff',
                      height: '3em',
                      textTransform: 'capitalize',
                    }),
                  }}
                  actions={[]}
                  onPageChange={(page, pageSize) => {
                    this.handleMaterialTablePageChange(page, pageSize);
                  }}
                />
              </div>
            </TabPanel>
            <TabPanel>
              <div id="printform" style={{ overflowY: 'scroll' }} ref={(el) => (this.componentRef = el)}>
                <MaterialTable
                  onRowClick={(event, rowData) => {
                    this.toPrescriptionDetails(event, rowData);
                    event.stopPropagation();
                  }}
                  icons={UiUtils.getMaterialTableIcons()}
                  title={this.memberName}
                  className="prescriptionList"
                  localization={{
                    body: {
                      emptyDataSourceMessage: Utils.communizeTableText(),
                    },
                  }}
                  columns={[
                    {
                      title: '#',
                      render: (rowData) => rowData.tableData.index + 1,
                      width: '5%',
                      // field: 'id',
                      align: 'left',
                      filtering: false,
                    },
                    {
                      title: 'Type',
                      field: 'type',
                      filtering: false,
                      width: '10%',
                    },
                    {
                      title: 'Drug Name',
                      field: 'name',
                      width: '20%',
                      filtering: false,
                    },

                    {
                      title: 'Prescribed By',
                      field: 'doctorname',
                      width: '15%',
                      filtering: false,
                      filtering: false,
                    },
                    {
                      title: 'Pharmacy',
                      field: 'pharmacy',
                      width: '10%',
                      filtering: false,
                    },
                    {
                      title: 'Start Date ',
                      type: 'date',
                      field: 'startDate',
                      filtering: false,
                      customSort: (a, b) => {
                        let aTime = a.startDateSortDate !== null ? new Date(a.startDateSortDate).getTime() : '';
                        let bTime = b.startDateSortDate !== null ? new Date(b.startDateSortDate).getTime() : '';
                        return aTime - bTime;
                      },

                      width: '15%',
                    },

                    {
                      title: 'Stopped Taking',
                      type: 'date',
                      field: 'endDate',
                      filtering: false,
                      customSort: (a, b) => {
                        let aTime = a.lastDateSortDate !== null ? new Date(a.lastDateSortDate).getTime() : '';
                        let bTime = b.lastDateSortDate !== null ? new Date(b.lastDateSortDate).getTime() : '';
                        return aTime - bTime;
                      },

                      width: '15%',
                    },
                  ]}
                  data={this.state.notTakingPrescriptionList}
                  options={{
                    sorting: true,
                    search: false,
                    actionsColumnIndex: -1,
                    initialPage: this.state.initialPage,
                    pageSize: this.state.pageSize,
                    filtering: false,
                    headerStyle: {
                      backgroundColor: '#1ABC9C',
                      fontWeight: 'bold',
                      color: '#fff',
                      align: 'left',
                      textAlign: 'left',
                    },
                    pageSizeOptions: [5, 10, 20, 50, 100],
                    filtering: true,
                    // paging: this.state.notTakingPrescriptionListPaging,
                    paging: this.state.notTakingPrescriptionList.length > this.state.pageSize,
                    rowStyle: (rowData) => ({
                      backgroundColor: rowData.id === this.state.prescriptionId ? '#FEF5E7' : '#fff',
                      height: '3em',
                      textTransform: 'capitalize',
                    }),
                  }}
                  actions={[]}
                  onPageChange={(page, pageSize) => {
                    this.handleMaterialTablePageChange(page, pageSize);
                  }}
                />
              </div>
            </TabPanel>
            <TabPanel>
              <div id="printform" style={{ overflowY: 'scroll' }} ref={(el) => (this.componentRef = el)}>
                <MaterialTable
                  onRowClick={(event, rowData) => {
                    this.toPrescriptionDetails(event, rowData);
                    event.stopPropagation();
                  }}
                  icons={UiUtils.getMaterialTableIcons()}
                  title={this.memberName}
                  localization={{
                    body: {
                      emptyDataSourceMessage: Utils.communizeTableText(),
                    },
                  }}
                  className="prescriptionList"
                  columns={[
                    {
                      title: '#',
                      render: (rowData) => rowData.tableData.index + 1,
                      width: '5%',
                      // field: 'id',
                      align: 'left',
                      filtering: false,
                    },
                    {
                      title: 'Type',
                      field: 'type',

                      width: '10%',
                      filtering: false,
                    },
                    {
                      title: 'Drug Name',
                      field: 'name',
                      width: '20%',
                      filtering: false,
                    },

                    {
                      title: 'Prescribed By',
                      field: 'doctorname',
                      width: '15%',
                      filtering: false,
                    },
                    {
                      title: 'Pharmacy',
                      field: 'pharmacy',
                      width: '10%',
                      filtering: false,
                    },
                    {
                      title: 'Start Date ',
                      type: 'date',
                      field: 'startDate',
                      filtering: false,
                      customSort: (a, b) => {
                        let aTime = a.startDateSortDate !== null ? new Date(a.startDateSortDate).getTime() : '';
                        let bTime = b.startDateSortDate !== null ? new Date(b.startDateSortDate).getTime() : '';
                        return aTime - bTime;
                      },

                      width: '15%',
                    },

                    {
                      title: 'Stopped Taking',
                      type: 'date',
                      field: 'endDate',
                      filtering: false,
                      customSort: (a, b) => {
                        let aTime = a.lastDateSortDate !== null ? new Date(a.lastDateSortDate).getTime() : '';
                        let bTime = b.lastDateSortDate !== null ? new Date(b.lastDateSortDate).getTime() : '';
                        return aTime - bTime;
                      },

                      width: '15%',
                    },
                  ]}
                  data={this.state.prescriptionList}
                  options={{
                    sorting: true,
                    actionsColumnIndex: -1,
                    initialPage: this.state.initialPage,
                    pageSize: this.state.pageSize,
                    search: false,

                    headerStyle: {
                      backgroundColor: '#1ABC9C',
                      fontWeight: 'bold',
                      color: '#fff',
                      align: 'left',
                      textAlign: 'left',
                    },
                    pageSizeOptions: [5, 10, 20, 50, 100],
                    filtering: true,
                    paging: this.state.prescriptionList.length > this.state.pageSize,
                    // paging: this.state.isPaging,
                    rowStyle: (rowData) => ({
                      backgroundColor: rowData.id === this.state.prescriptionId ? '#FEF5E7' : '#fff',
                      height: '3em',
                      textTransform: 'capitalize',
                    }),
                  }}
                  actions={[]}
                  onPageChange={(page, pageSize) => {
                    this.handleMaterialTablePageChange(page, pageSize);
                  }}
                />
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </>
    );
  }
}
export default PrescriptionsList;
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#008181',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: 'white',
        color: '#008181',
      },
    },
  },
});
