import {
  CLEAR_SELECTED_MEMBER_DETAILS,
  GET_SELECTED_MEMBER_DETAILS,
  SET_SELECTED_MEMBER_DETAILS,
} from './selectedMemberDetailsType';

const getSelectedMember = () => {
  return {
    type: GET_SELECTED_MEMBER_DETAILS,
  };
};

const setSelectedMember = (data) => {
  return {
    type: SET_SELECTED_MEMBER_DETAILS,
    payload: data,
  };
};

const clearSelectedMember = (data) => {
  return {
    type: CLEAR_SELECTED_MEMBER_DETAILS,
    payload: data,
  };
};
const selectedMemberDetailsAction = { getSelectedMember, setSelectedMember, clearSelectedMember };

export default selectedMemberDetailsAction;
