import { Component } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Card, CardBody, Col, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import store from '../../../redux/store';
import PtsAlert from '../ptsAlert/PtsAlert';
import UiUtils from '../../../provider/UiUtils';
import MaterialTable from '@material-table/core';
import Utils from '../../../provider/Utils';
import applicationStateAction from '../../../redux/applicationState/applicationStateAction';
import fccPageStateAction from '../../../redux/fccPageState/fccPageStateAction';
import './ImportList.css';
import Button from '@material-ui/core/Button';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
import FCCImportDoctorExcelTemplate from '../../../assets/FCCImportDoctorExcelTemplate.xlsx';
import FCCImportFacilityExcelTemplate from '../../../assets/FCCImportFacilityExcelTemplate.xlsx';
import FCCImportPrescriptionExcelTemplate from '../../../assets/FCCImportPrescriptionExcelTemplate.xlsx';
import Advertisement from '../advertisement/Advertisement';
import BreadCrumbDetailsAction from '../../../redux/breadCrumbDetails/BreadCrumbDetailsAction';
class ImportList extends Component {
  title = 'Import List';
  memberDetails = store.getState().memberData.memberData;
  facilityState = store.getState().applicationState.facilityState;
  isFreeUser = store.getState().userData.userData.isFreeUser;
  premiumFeatures = store.getState().userData.premiumFeatures;
  prescriptionsState = store.getState().applicationState.prescriptionsState;
  memberName = store.getState().memberData.memberData.firstname + ' ' + store.getState().memberData.memberData.lastname;
  header = store.getState().header.header;
  memberHomeUrl = store.getState().memberHomeUrl.memberHomeUrl.homeUrl;
  breadCrumb = [
    { pageid: this.memberHomeUrl, uiname: this.memberName },
    { pageid: '/import', uiname: 'Import List' },
  ];
  constructor(props) {
    super(props);
    store.dispatch(applicationStateAction.setApplicationState({}));
    let facilityState = {};
    if (this.facilityState) {
      facilityState = Object.getOwnPropertyNames(this.facilityState);
    }
    let prescriptionsStateDetails = {};
    if (this.prescriptionsState) {
      prescriptionsStateDetails = Object.getOwnPropertyNames(this.prescriptionsState);
    }
    if (this.breadCrumb) {
      Utils.getActiveMenu(this.breadCrumb);
      store.dispatch(BreadCrumbDetailsAction.setBreadCrumbDetails(this.breadCrumb));
    }
    if (facilityState.length > 0) {
      this.state = {
        selectedTab: this.facilityState ? this.facilityState.selectedTab : '0',
        doctorList: [],
        facilityList: [],
        pageSize: this.facilityState.pageSize && this.facilityState.pageSize ? this.facilityState.pageSize : 10,
        isPaging: false,
        selectedFacilityList: [],
        selectedCheck: false,
        responseArray: [],
        selectedFile: '',
        selectedDoctorList: [],
        initialPage:
          this.facilityState.initialPage && this.facilityState.initialPage > 0 ? this.facilityState.initialPage : 0,
        prescriptionList: [],
        selectedPrescriptionList: [],
      };
    } else if (prescriptionsStateDetails.length > 0) {
      this.state = {
        selectedTab: this.prescriptionsState ? this.prescriptionsState.selectedTab : '0',
        doctorList: [],
        facilityList: [],
        pageSize:
          this.prescriptionsState.pageSize && this.prescriptionsState.pageSize ? this.prescriptionsState.pageSize : 10,
        isPaging: false,
        selectedFacilityList: [],
        selectedCheck: false,
        responseArray: [],
        selectedFile: '',
        selectedDoctorList: [],
        initialPage:
          this.prescriptionsState.initialPage && this.prescriptionsState.initialPage > 0
            ? this.prescriptionsState.initialPage
            : 0,
        prescriptionList: [],
        selectedPrescriptionList: [],
      };
    } else {
      this.state = {
        selectedTab: 0,
        doctorList: [],
        facilityList: [],
        pageSize: 10,
        isPaging: false,
        selectedFacilityList: [],
        selectedCheck: false,
        selectedDoctorList: [],
        initialPage: 0,
        prescriptionList: [],
        selectedPrescriptionList: [],
      };

      this.getDoctorList();
    }
    if (this.state.selectedTab === 1) {
      this.getFacilityList();
    } else if (this.state.selectedTab === 2) {
      this.getPrescriptionList();
    }
  }
  setKey = (tab) => {
    if (tab === 0) {
      this.getDoctorList();
    } else if (tab === 1) {
      this.getFacilityList();
    } else if (tab === 2) {
      this.getPrescriptionList();
    }
    this.setState({
      selectedTab: tab,
    });
  };
  doctorFileHandler(e) {
    var file = e.target.files[0];
    let reader = new FileReader();
    this.setState({
      // imagePosition: position.toString(),
      selectedFile: e.target.files[0],
      responseArray: [],
    });
    reader.onload = (e) => {
      let file = e.target.result;
      let file1 = file.split(',');
      this.setFile = file1[1];

      const formData = new FormData();

      // Update the formData object
      formData.append('file', this.state.selectedFile, this.state.selectedFile.name);
      formData.append('sessionid', this.header.sessionid);
      formData.append('sid', this.header.sessionid);
      // formData.append('imageposition', position);
      formData.append('uid', this.header.uid);
      formData.append('adid', this.props.advertisementId);
      formData.append('appclient', 'W');

      this.setState({ leftImageFile: this.setFile });
    };
    reader.readAsDataURL(file);
  }
  uploadDoctorFile(e) {
    e.preventDefault();
    if (!this.state.selectedFile) {
      PtsAlert.error('Please Select The File');
      return;
    }
    const formData = new FormData();

    // Update the formData object
    formData.append('file', this.state.selectedFile, this.state.selectedFile.name);

    formData.append('sid', this.header.sessionid);

    formData.append('uid', this.header.uid);
    formData.append('memberid', this.memberDetails.member);
    formData.append('appclient', 'W');
    FetchServerData.callPostService('/importExport/importDoctors', formData).then((output) => {
      if (output.status === 'SUCCESS') {
        let doctorList = output.data.doctorList;
        for (let i = 0; i < doctorList.length; i++) {
          if (doctorList[i].isduplicate && doctorList[i].isduplicate === true) {
            doctorList[i].status = 'duplicate';
          } else if (doctorList[i].isexistsinmain && doctorList[i].isexistsinmain === true) {
            doctorList[i].status = 'existsinmain';
          }
        }
        this.setState({
          selectedFile: '',
          doctorList: doctorList,
        });
        PtsAlert.success(output.message);
        this.resetFile();
      } else {
      }
    });
  }
  resetFile() {
    document.getElementsByName('file')[0].value = null;
  }

  facilityFileHandler(e) {
    var file = e.target.files[0];
    let reader = new FileReader();
    this.setState({
      // imagePosition: position.toString(),
      selectedFile: e.target.files[0],
      responseArray: [],
    });
    reader.onload = (e) => {
      let file = e.target.result;
      let file1 = file.split(',');
      this.setFile = file1[1];

      const formData = new FormData();

      // Update the formData object
      formData.append('file', this.state.selectedFile, this.state.selectedFile.name);
      formData.append('sessionid', this.header.sessionid);
      formData.append('sid', this.header.sessionid);
      // formData.append('imageposition', position);
      formData.append('uid', this.header.uid);
      formData.append('adid', this.props.advertisementId);
      formData.append('appclient', 'W');

      this.setState({ leftImageFile: this.setFile });
    };
    reader.readAsDataURL(file);
  }

  uploadFacilityFile(e) {
    e.preventDefault();
    if (!this.state.selectedFile) {
      PtsAlert.error('Please Select The File');
      return false;
    }
    const formData = new FormData();
    // Update the formData object
    formData.append('file', this.state.selectedFile, this.state.selectedFile.name);
    formData.append('sid', this.header.sessionid);

    formData.append('uid', this.header.uid);
    formData.append('memberid', this.memberDetails.member);
    formData.append('appclient', 'W');
    FetchServerData.callPostService('/importExport/importFacilities', formData).then((output) => {
      if (output.status === 'SUCCESS') {
        let facilityList = output.data.facilityList;
        for (let i = 0; i < facilityList.length; i++) {
          if (facilityList[i].isduplicate && facilityList[i].isduplicate === true) {
            facilityList[i].status = 'duplicate';
          } else if (facilityList[i].isexistsinmain && facilityList[i].isexistsinmain === true) {
            facilityList[i].status = 'existsinmain';
          }
        }
        this.setState({
          selectedFile: '',
          facilityList: facilityList,
        });
        PtsAlert.success(output.message);
        this.resetFile();
      } else {
      }
    });
  }
  prescriptionFileHandler(e) {
    var file = e.target.files[0];
    let reader = new FileReader();
    this.setState({
      // imagePosition: position.toString(),
      selectedFile: e.target.files[0],
      responseArray: [],
    });
    reader.onload = (e) => {
      let file = e.target.result;
      let file1 = file.split(',');
      this.setFile = file1[1];

      const formData = new FormData();

      // Update the formData object
      formData.append('file', this.state.selectedFile, this.state.selectedFile.name);
      formData.append('sessionid', this.header.sessionid);
      formData.append('sid', this.header.sessionid);
      // formData.append('imageposition', position);
      formData.append('uid', this.header.uid);
      formData.append('adid', this.props.advertisementId);
      formData.append('appclient', 'W');

      this.setState({ leftImageFile: this.setFile });
    };
    reader.readAsDataURL(file);
  }
  uploadPrescriptionFile(e) {
    e.preventDefault();
    if (!this.state.selectedFile) {
      PtsAlert.error('Please Select The File');
      return false;
    }
    const formData = new FormData();
    // Update the formData object
    formData.append('file', this.state.selectedFile, this.state.selectedFile.name);
    formData.append('sid', this.header.sessionid);

    formData.append('uid', this.header.uid);
    formData.append('memberid', this.memberDetails.member);
    formData.append('appclient', 'W');
    FetchServerData.callPostService('/importExport/importMedicines', formData).then((output) => {
      if (output.status === 'SUCCESS') {
        let prescriptionList = output.data.medicineList;
        for (let i = 0; i < prescriptionList.length; i++) {
          if (prescriptionList[i].startDate) {
            prescriptionList[i].startDateSortDate = prescriptionList[i].startDate;
            prescriptionList[i].startDate = Utils.convertUTCDateToUserTimeZoneDateWithoutTimezoneFormate(
              prescriptionList[i].startDate
            );
          }
          if (prescriptionList[i].endDate) {
            prescriptionList[i].lastDateSortDate = prescriptionList[i].endDate;
            prescriptionList[i].endDate = Utils.convertUTCDateToUserTimeZoneDateWithoutTimezoneFormate(
              prescriptionList[i].endDate
            );
          }
        }
        for (let i = 0; i < prescriptionList.length; i++) {
          if (prescriptionList[i].isduplicate && prescriptionList[i].isduplicate === true) {
            prescriptionList[i].status = 'duplicate';
          } else if (prescriptionList[i].isexistsinmain && prescriptionList[i].isexistsinmain === true) {
            prescriptionList[i].status = 'isexistsinmain';
          }
        }

        this.setState({
          selectedFile: '',
          prescriptionList: prescriptionList,
        });
        PtsAlert.success(output.message);
        this.resetFile();
      } else {
      }
    });
  }
  getDoctorList() {
    const postObject = {
      header: this.header,
      doctor: {
        member: {
          id: this.memberDetails.member,
        },
      },
    };

    FetchServerData.callPostService('/impDoctorms/getMemberDoctors', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let doctorList = output.data.doctorList;
        // let getPermission = Utils.getRolePermission(output.data.privileges);
        // let getCreatePermission = getPermission[1];
        let isPaging = false;
        if (doctorList.length > 10) {
          isPaging = true;
        }

        for (let i = 0; i < doctorList.length; i++) {
          if (doctorList[i].isduplicate && doctorList[i].isduplicate === true) {
            doctorList[i].status = 'duplicate';
          } else if (doctorList[i].isexistsinmain && doctorList[i].isexistsinmain === true) {
            doctorList[i].status = 'existsinmain';
          }
        }

        this.setState({
          isPaging: isPaging,
          doctorList: doctorList,
          render: true,
          // initialPage: this.state.initialPage,
          // getCreatePermission: getCreatePermission,
        });
      } else {
      }
    });
  }

  getFacilityList() {
    const postObject = {
      header: this.header,
      facility: {
        member: {
          id: this.memberDetails.member,
        },
      },
    };

    FetchServerData.callPostService('/impFacilityms/getMemberFacilities', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let facilityListArr = output.data.list;
        let getPermission = Utils.getRolePermission(output.data.privileges);
        let getCreatePermission = getPermission[1];
        // let isPaging = false;
        // if (facilityListArr.length > 10) {
        //   isPaging = true;
        // }
        for (let i = 0; i < facilityListArr.length; i++) {
          if (facilityListArr[i].isduplicate && facilityListArr[i].isduplicate === true) {
            facilityListArr[i].status = 'duplicate';
          } else if (facilityListArr[i].isexistsinmain && facilityListArr[i].isexistsinmain === true) {
            facilityListArr[i].status = 'existsinmain';
          }
        }
        this.setState({
          // isPaging: isPaging,
          facilityList: facilityListArr,
          getCreatePermission: getCreatePermission,
          render: true,
        });
      } else {
      }
    });
  }
  getPrescriptionList() {
    const postObject = {
      header: this.header,
      // facility: {
      member: {
        id: this.memberDetails.member,
      },
      // },
    };

    FetchServerData.callPostService('/impMedicinems/getMemberMedicines', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let prescriptionListArr = output.data.medicineList;
        let getPermission = Utils.getRolePermission(output.data.privileges);
        let getCreatePermission = getPermission[1];
        // let isPaging = false;
        // if (facilityListArr.length > 10) {
        //   isPaging = true;
        // }
        for (let i = 0; i < prescriptionListArr.length; i++) {
          if (prescriptionListArr[i].startDate) {
            prescriptionListArr[i].startDateSortDate = prescriptionListArr[i].startDate;
            prescriptionListArr[i].startDate = Utils.convertUTCDateToUserTimeZoneDateWithoutTimezoneFormate(
              prescriptionListArr[i].startDate
            );
          }
          if (prescriptionListArr[i].endDate) {
            prescriptionListArr[i].lastDateSortDate = prescriptionListArr[i].endDate;
            prescriptionListArr[i].endDate = Utils.convertUTCDateToUserTimeZoneDateWithoutTimezoneFormate(
              prescriptionListArr[i].endDate
            );
          }
        }
        for (let i = 0; i < prescriptionListArr.length; i++) {
          if (prescriptionListArr[i].isduplicate && prescriptionListArr[i].isduplicate === true) {
            prescriptionListArr[i].status = 'duplicate';
          } else if (prescriptionListArr[i].isexistsinmain && prescriptionListArr[i].isexistsinmain === true) {
            prescriptionListArr[i].status = 'existsinmain';
          }
        }
        this.setState({
          // isPaging: isPaging,
          prescriptionList: prescriptionListArr,
          getCreatePermission: getCreatePermission,
          render: true,
        });
      } else {
      }
    });
  }

  toDoctorDetails = (e, data) => {
    let doctorState = {
      initialPage: this.state.initialPage,
      doctorId: data.id,
      pageSize: this.state.pageSize,
    };
    store.dispatch(
      applicationStateAction.setApplicationState({
        doctorState,
      })
    );
    let fccPageState = {
      id: data.id,
      action: 'view',
      title: 'Doctor Details',
    };
    store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
    this.props.history.push({
      pathname: '/viewDoctorImport',
      state: { action: 'view', title: 'Doctor Details', doctorId: data.id },
    });
  };
  toFacilityDetails = (e, data) => {
    let facilityState = {
      initialPage: this.state.initialPage,
      facilityId: data.id,
      selectedTab: this.state.selectedTab,
      pageSize: this.state.pageSize,
    };
    store.dispatch(
      applicationStateAction.setApplicationState({
        facilityState,
      })
    );
    let fccPageState = {
      id: data.id,
      action: 'view',
      title: 'Facility Details',
      pageId: 'memberFacility',
      locationId: data.locationId,
      selectedTab: this.state.selectedTab,
    };
    store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
    this.props.history.push({
      pathname: '/viewFacilityImport',
      state: {
        action: 'view',
        title: 'Facility Details',
        facilityId: data.id,
        pageId: 'memberFacility',
        locationId: data.locationId,
      },
    });
  };

  selectRow(rowData, e) {
    let facilityObj = {};
    let facilityListArray = [];
    for (let i = 0; i < rowData.length; i++) {
      facilityObj = rowData[i].id;
      facilityListArray.push(facilityObj);

      this.setState({
        selectedFacilityList: facilityListArray,
      });
    }
  }

  selectPrescriptionRow(rowData, e) {
    let prescriptionObj = {};
    let prescriptionListArray = [];
    for (let i = 0; i < rowData.length; i++) {
      prescriptionObj = rowData[i].id;
      prescriptionListArray.push(prescriptionObj);

      this.setState({
        selectedPrescriptionList: prescriptionListArray,
      });
    }
  }
  selectDoctorRow(rowData, e) {
    let doctorObj = {};
    let doctorListArray = [];
    for (let i = 0; i < rowData.length; i++) {
      doctorObj = rowData[i].id;
      doctorListArray.push(doctorObj);

      this.setState({
        selectedDoctorList: doctorListArray,
      });
    }
  }
  bulkImportPrescriptionList() {
    const postObject = {
      header: this.header,

      memberId: this.memberDetails.member,
      medicineList: this.state.selectedPrescriptionList,
    };

    FetchServerData.callPostService('/impMedicinems/moveToMain', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        PtsAlert.success(output.message);
        this.getPrescriptionList();
      } else {
      }
    });
  }

  bulkDeletePrescriptionList() {
    const postObject = {
      header: this.header,

      memberId: this.memberDetails.member,
      medicineList: this.state.selectedPrescriptionList,
    };

    FetchServerData.callPostService('/impMedicinems/deleteMultiple', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        PtsAlert.success(output.message);
        this.getPrescriptionList();
      } else {
      }
    });
  }
  bulkImportFacilityList() {
    const postObject = {
      header: this.header,

      memberId: this.memberDetails.member,
      facilityList: this.state.selectedFacilityList,
    };

    FetchServerData.callPostService('/impFacilityms/moveToMain', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        PtsAlert.success(output.message);
        this.getFacilityList();
      } else {
      }
    });
  }

  bulkDeleteFacilityList() {
    const postObject = {
      header: this.header,

      memberId: this.memberDetails.member,
      facilityList: this.state.selectedFacilityList,
    };

    FetchServerData.callPostService('/impFacilityms/deleteMultiple', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        PtsAlert.success(output.message);
        this.getFacilityList();
      } else {
      }
    });
  }

  bulkImportDoctorList() {
    const postObject = {
      header: this.header,

      memberId: this.memberDetails.member,
      doctorList: this.state.selectedDoctorList,
    };

    FetchServerData.callPostService('/impDoctorms/moveToMain', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        PtsAlert.success(output.message);
        this.getDoctorList();
      } else {
      }
    });
  }

  bulkDeleteDoctorList() {
    const postObject = {
      header: this.header,

      memberId: this.memberDetails.member,
      doctorList: this.state.selectedDoctorList,
    };

    FetchServerData.callPostService('/impDoctorms/deleteMultiple', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        PtsAlert.success(output.message);
        this.getDoctorList();
      } else {
      }
    });
  }
  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }

  toPrescriptionDetails = (e, data) => {
    let prescriptionsState = {
      initialPage: this.state.initialPage,
      prescriptionId: data.id,
      pageSize: this.state.pageSize,
      selectedTab: this.state.selectedTab,
      drugName: this.state.drugName,
      prescribedDate: this.state.prescribedDate,
      type: this.state.type,
      pharmacy: this.state.pharmacy,
      prescriberDoctor: this.state.prescriberDoctor,
    };
    store.dispatch(
      applicationStateAction.setApplicationState({
        prescriptionsState,
      })
    );
    let fccPageState = {
      id: data.id,
      action: 'view',
      title: 'prescriptions Details',
      pageId: 'prescriptions',
    };
    store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
    this.props.history.push({
      pathname: '/viewPrescriptionsImport',
      state: {
        action: 'view',
        title: 'prescriptions Details',
        prescriptionId: data.id,
        pageId: 'prescriptions',
      },
    });
  };
  render() {
    console.log(this.isFreeUser, 'this.state.isFreeUser');
    if (this.isFreeUser === false) {
      return this.showSubscribeView();
    } else {
      return this.showFreeView();
    }
  }
  showFreeView() {
    return (
      <div>
        <MenuWithBreadScrum breadCrumb={this.breadCrumb} history={this.props.history} name={this.title} />
        <Row style={{ margin: '1em' }}>
          <Advertisement pageId="Import" />
        </Row>
        <Row style={{ marginTop: '1em' }}>
          <Col md={12} style={{ padding: '0em 2.4em 1em 2.4em' }} className="importTab">
            <Tabs defaultIndex={this.state.selectedTab} onSelect={(k) => this.setKey(k)}>
              <TabList>
                {/* <Tab>Doctor Import</Tab>
                <Tab>Facility Import</Tab> */}
                <Tab>Doctor Import</Tab>
                <Tab>Facility Import</Tab>
              </TabList>
              <TabPanel style={{ background: 'lightsteelblue' }}>
                <Row>
                  <Col md={12}>
                    <Card style={{ margin: '1em', padding: '1em' }}>
                      <CardBody>
                        <Row>
                          <Col md={6}>
                            <span>Upload Excel file containing Doctor details</span>
                          </Col>
                          <Col md={6}>
                            <span>
                              <u>
                                {' '}
                                <a
                                  href={FCCImportDoctorExcelTemplate}
                                  download="FCCImportDoctorExcelTemplate"
                                  target="_blank"
                                  style={{ float: 'right' }}
                                >
                                  Download Sample Excel File for Doctor Import
                                </a>
                              </u>
                            </span>
                          </Col>
                        </Row>
                        <div style={{ border: '1px solid gray', padding: '1em', marginTop: '0.5em' }}>
                          <input
                            type="file"
                            name="file"
                            multiple
                            onChange={this.doctorFileHandler.bind(this)}
                            required
                          />
                          {/* <div className="imgPreview">{imagePreview}</div> */}
                        </div>
                        <div style={{ textAlign: 'center', marginTop: '1em' }}>
                          {this.state.selectedFile ? (
                            <button className="btn btn-primary" onClick={this.uploadDoctorFile.bind(this)}>
                              {' '}
                              Upload
                            </button>
                          ) : (
                            <button className="btn btn-primary" onClick={this.uploadDoctorFile.bind(this)} disabled>
                              {' '}
                              Upload
                            </button>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <MaterialTable
                      style={{ margin: '1em', padding: '1em' }}
                      onRowClick={(event, rowData) => {
                        this.toDoctorDetails(event, rowData);
                        event.stopPropagation();
                      }}
                      icons={UiUtils.getMaterialTableIcons()}
                      title=""
                      localization={{
                        body: {
                          emptyDataSourceMessage: 'Please Upload files to get started.',
                        },
                      }}
                      columns={[
                        {
                          title: '#',
                          render: (rowData) => rowData.tableData.index + 1,
                          width: '5%',
                          align: 'left',
                        },
                        {
                          title: 'Name',
                          field: 'doctorName',
                          // render: (rowData) =>
                          //   rowData.doctorName === null ? '' + '' + rowData.doctorName : rowData.doctorName,
                          // customFilterAndSearch: (term, rowData) =>
                          //   rowData.doctorName.toUpperCase().indexOf(term.toUpperCase()) !== -1,
                          width: '25%',
                        },
                        { title: ' Specialization', field: 'specialist', width: '25%' },
                        { title: ' Location', field: 'locations', width: '25%' },

                        {
                          title: ' Status',
                          field: 'status',
                          filtering: false,
                          width: '20%',
                          //  (rowData) =>(rowData.isduplicate && rowData.isduplicate === true)? "duplicate" : (rowData.isexistsinmain && rowData.isexistsinmain === true)?'existsinmain' : '',
                        },
                      ]}
                      data={this.state.doctorList}
                      onSelectionChange={(e, rows) => {
                        this.selectDoctorRow(e, rows);
                      }}
                      onPageChange={(page, pageSize) => {
                        this.handleMaterialTablePageChange(page, pageSize);
                      }}
                      options={{
                        selection: true,
                        showTextRowsSelected: false,

                        sorting: true,
                        actionsColumnIndex: -1,
                        initialPage: this.state.initialPage,
                        pageSize: this.state.pageSize,
                        headerStyle: { backgroundColor: '#1ABC9C', fontWeight: 'bold', color: '#fff', align: 'left' },
                        pageSizeOptions: [5, 10, 20, 50, 100],
                        filtering: true,
                        // paging: this.state.isPaging,
                        paging: this.state.doctorList.length > this.state.pageSize,
                        rowStyle: (rowData) => ({
                          // backgroundColor: rowData.id === this.state.doctorId ? '#FEF5E7' : '#fff',
                          // backgroundColor: rowData.isduplicate === true ? '#FCF3CF' : rowData.isexistsinmain === true ? '#D6EAF8' : '#fff',
                          backgroundColor:
                            rowData.isduplicate && rowData.isduplicate === true
                              ? '#FCF3CF'
                              : rowData.isexistsinmain && rowData.isexistsinmain === true
                              ? '#D6EAF8'
                              : '#fff',
                          height: '3em',
                          textTransform: 'capitalize',
                        }),
                      }}
                    />
                  </Col>
                </Row>
                <div style={{ textAlign: 'center', padding: '1em' }}>
                  <Button
                    type="button"
                    style={{ backgroundColor: '#162c50', color: '#fff' }}
                    variant="contained"
                    onClick={(e) => this.bulkImportDoctorList()}
                  >
                    Add to Doctor List
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    type="button"
                    style={{ backgroundColor: '#162c50', color: '#fff' }}
                    variant="contained"
                    onClick={(e) => this.bulkDeleteDoctorList()}
                  >
                    Delete selected
                  </Button>
                </div>
              </TabPanel>
              <TabPanel style={{ background: 'lightsteelblue' }}>
                <Row>
                  <Col md={12}>
                    <Card style={{ margin: '1em', padding: '1em' }}>
                      <CardBody>
                        <Row>
                          <Col md={6}>
                            <span>Upload Excel file containing Facility details</span>
                          </Col>
                          <Col md={6}>
                            <span>
                              <u>
                                {' '}
                                <a
                                  href={FCCImportFacilityExcelTemplate}
                                  download="FCCImportFacilityExcelTemplate"
                                  target="_blank"
                                  style={{ float: 'right' }}
                                >
                                  Download Sample Excel File for Facility Import
                                </a>
                              </u>
                            </span>
                          </Col>
                        </Row>
                        <div style={{ border: '1px solid gray', padding: '1em', marginTop: '0.5em' }}>
                          <input type="file" name="file" onChange={this.facilityFileHandler.bind(this)} />

                          {/* <div className="imgPreview">{imagePreview}</div> */}
                        </div>
                        <div style={{ textAlign: 'center', marginTop: '1em' }}>
                          {this.state.selectedFile ? (
                            <button className="btn btn-primary" onClick={this.uploadFacilityFile.bind(this)}>
                              {' '}
                              Upload
                            </button>
                          ) : (
                            <button className="btn btn-primary" onClick={this.uploadFacilityFile.bind(this)} disabled>
                              {' '}
                              Upload
                            </button>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <MaterialTable
                      style={{ margin: '1em', padding: '1em' }}
                      onRowClick={(event, rowData) => {
                        this.toFacilityDetails(event, rowData);
                        event.stopPropagation();
                      }}
                      icons={UiUtils.getMaterialTableIcons()}
                      title=""
                      localization={{
                        body: {
                          emptyDataSourceMessage: 'Please Upload files to get started.',
                        },
                      }}
                      columns={[
                        {
                          title: '#',
                          render: (rowData) => rowData.tableData.index + 1,
                          width: '5%',
                          align: 'left',
                        },

                        {
                          title: 'Name',
                          field: 'name',
                          align: 'left',
                          width: '20%',
                        },
                        { title: 'Type', field: 'type', align: 'left', width: '20%' },
                        { title: 'Description', field: 'description', align: 'left', width: '20%' },
                        { title: 'Location', field: 'locations', align: 'left', width: '25%' },

                        {
                          title: ' Status',
                          field: 'status',
                          filtering: false,
                          width: '10%',
                          // render: (rowData) =>(rowData.isduplicate && rowData.isduplicate === true)? "duplicate" : (rowData.isexistsinmain && rowData.isexistsinmain === true)?'existsinmain' : '',
                        },
                      ]}
                      data={this.state.facilityList}
                      onSelectionChange={(e, rows) => {
                        this.selectRow(e, rows);
                      }}
                      onPageChange={(page, pageSize) => {
                        this.handleMaterialTablePageChange(page, pageSize);
                      }}
                      options={{
                        selection: true,

                        showTextRowsSelected: false,
                        sorting: true,
                        actionsColumnIndex: -1,
                        pageSize: this.state.pageSize,
                        headerStyle: { backgroundColor: '#1ABC9C', fontWeight: 'bold', color: '#fff', align: 'left' },
                        pageSizeOptions: [5, 10, 20, 50, 100],
                        filtering: true,
                        paging: this.state.facilityList.length > this.state.pageSize,
                        // paging: this.state.isPaging,
                        initialPage: this.state.initialPage,
                        rowStyle: (rowData) => ({
                          // backgroundColor: rowData.id === this.state.facilityId ? '#FEF5E7' : '#fff',
                          backgroundColor:
                            rowData.isduplicate && rowData.isduplicate === true
                              ? '#FCF3CF'
                              : rowData.isexistsinmain && rowData.isexistsinmain === true
                              ? '#D6EAF8'
                              : '#fff',
                          height: '3em',
                          textTransform: 'capitalize',
                        }),
                      }}
                    />
                  </Col>
                </Row>
                <div style={{ textAlign: 'center', padding: '1em' }}>
                  <Button
                    type="button"
                    style={{ backgroundColor: '#162c50', color: '#fff' }}
                    variant="contained"
                    onClick={(e) => this.bulkImportFacilityList()}
                  >
                    Add to Facility List
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    type="button"
                    style={{ backgroundColor: '#162c50', color: '#fff' }}
                    variant="contained"
                    onClick={(e) => this.bulkDeleteFacilityList()}
                  >
                    Delete selected
                  </Button>
                </div>
              </TabPanel>
            </Tabs>
          </Col>
        </Row>
      </div>
    );
  }

  showSubscribeView() {
    return (
      <div>
        <MenuWithBreadScrum breadCrumb={this.breadCrumb} history={this.props.history} name={this.title} />
        <Row style={{ margin: '1em' }}>
          <Advertisement pageId="Import" />
        </Row>
        <Row style={{ marginTop: '1em' }}>
          <Col md={12} style={{ padding: '0em 2.4em 1em 2.4em' }} className="importTab">
            <Tabs defaultIndex={this.state.selectedTab} onSelect={(k) => this.setKey(k)}>
              <TabList>
                <Tab>Doctor Import</Tab>
                <Tab>Facility Import</Tab>

                <Tab>Prescription Import</Tab>
              </TabList>
              <TabPanel style={{ background: 'lightsteelblue' }}>
                <Row>
                  <Col md={12}>
                    <Card style={{ margin: '1em', padding: '1em' }}>
                      <CardBody>
                        <Row>
                          <Col md={6}>
                            <span>Upload Excel file containing Doctor details</span>
                          </Col>
                          <Col md={6}>
                            <span>
                              <u>
                                {' '}
                                <a
                                  href={FCCImportDoctorExcelTemplate}
                                  download="FCCImportDoctorExcelTemplate"
                                  target="_blank"
                                  style={{ float: 'right' }}
                                >
                                  Download Sample Excel File for Doctor Import
                                </a>
                              </u>
                            </span>
                          </Col>
                        </Row>
                        <div style={{ border: '1px solid gray', padding: '1em', marginTop: '0.5em' }}>
                          <input
                            type="file"
                            name="file"
                            multiple
                            onChange={this.doctorFileHandler.bind(this)}
                            required
                          />
                          {/* <div className="imgPreview">{imagePreview}</div> */}
                        </div>
                        <div style={{ textAlign: 'center', marginTop: '1em' }}>
                          {this.state.selectedFile ? (
                            <button className="btn btn-primary" onClick={this.uploadDoctorFile.bind(this)}>
                              {' '}
                              Upload
                            </button>
                          ) : (
                            <button className="btn btn-primary" onClick={this.uploadDoctorFile.bind(this)} disabled>
                              {' '}
                              Upload
                            </button>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <MaterialTable
                      style={{ margin: '1em', padding: '1em' }}
                      onRowClick={(event, rowData) => {
                        this.toDoctorDetails(event, rowData);
                        event.stopPropagation();
                      }}
                      icons={UiUtils.getMaterialTableIcons()}
                      title=""
                      localization={{
                        body: {
                          emptyDataSourceMessage: 'Please Upload files to get started.',
                        },
                      }}
                      columns={[
                        {
                          title: '#',
                          render: (rowData) => rowData.tableData.index + 1,
                          width: '5%',
                          align: 'left',
                        },
                        {
                          title: 'Name',
                          field: 'doctorName',
                          // render: (rowData) =>
                          //   rowData.doctorName === null ? '' + '' + rowData.doctorName : rowData.doctorName,
                          // customFilterAndSearch: (term, rowData) =>
                          //   rowData.doctorName.toUpperCase().indexOf(term.toUpperCase()) !== -1,
                          width: '25%',
                        },
                        { title: ' Specialization', field: 'specialist', width: '25%' },
                        { title: ' Location', field: 'locations', width: '25%' },

                        {
                          title: ' Status',
                          field: 'status',
                          filtering: false,
                          width: '20%',
                          //  (rowData) =>(rowData.isduplicate && rowData.isduplicate === true)? "duplicate" : (rowData.isexistsinmain && rowData.isexistsinmain === true)?'existsinmain' : '',
                        },
                      ]}
                      data={this.state.doctorList}
                      onSelectionChange={(e, rows) => {
                        this.selectDoctorRow(e, rows);
                      }}
                      onPageChange={(page, pageSize) => {
                        this.handleMaterialTablePageChange(page, pageSize);
                      }}
                      options={{
                        selection: true,
                        showTextRowsSelected: false,

                        sorting: true,
                        actionsColumnIndex: -1,
                        initialPage: this.state.initialPage,
                        pageSize: this.state.pageSize,
                        headerStyle: { backgroundColor: '#1ABC9C', fontWeight: 'bold', color: '#fff', align: 'left' },
                        pageSizeOptions: [5, 10, 20, 50, 100],
                        filtering: true,
                        paging: this.state.doctorList.length > this.state.pageSize,
                        // paging: this.state.isPaging,
                        rowStyle: (rowData) => ({
                          // backgroundColor: rowData.id === this.state.doctorId ? '#FEF5E7' : '#fff',
                          // backgroundColor: rowData.isduplicate === true ? '#FCF3CF' : rowData.isexistsinmain === true ? '#D6EAF8' : '#fff',
                          backgroundColor:
                            rowData.isduplicate && rowData.isduplicate === true
                              ? '#FCF3CF'
                              : rowData.isexistsinmain && rowData.isexistsinmain === true
                              ? '#D6EAF8'
                              : '#fff',
                          height: '3em',
                          textTransform: 'capitalize',
                        }),
                      }}
                    />
                  </Col>
                </Row>
                <div style={{ textAlign: 'center', padding: '1em' }}>
                  <Button
                    type="button"
                    style={{ backgroundColor: '#162c50', color: '#fff' }}
                    variant="contained"
                    onClick={(e) => this.bulkImportDoctorList()}
                  >
                    Add to Doctor List
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    type="button"
                    style={{ backgroundColor: '#162c50', color: '#fff' }}
                    variant="contained"
                    onClick={(e) => this.bulkDeleteDoctorList()}
                  >
                    Delete selected
                  </Button>
                </div>
              </TabPanel>
              <TabPanel style={{ background: 'lightsteelblue' }}>
                <Row>
                  <Col md={12}>
                    <Card style={{ margin: '1em', padding: '1em' }}>
                      <CardBody>
                        <Row>
                          <Col md={6}>
                            <span>Upload Excel file containing Facility details</span>
                          </Col>
                          <Col md={6}>
                            <span>
                              <u>
                                {' '}
                                <a
                                  href={FCCImportFacilityExcelTemplate}
                                  download="FCCImportFacilityExcelTemplate"
                                  target="_blank"
                                  style={{ float: 'right' }}
                                >
                                  Download Sample Excel File for Facility Import
                                </a>
                              </u>
                            </span>
                          </Col>
                        </Row>
                        <div style={{ border: '1px solid gray', padding: '1em', marginTop: '0.5em' }}>
                          <input type="file" name="file" onChange={this.facilityFileHandler.bind(this)} />

                          {/* <div className="imgPreview">{imagePreview}</div> */}
                        </div>
                        <div style={{ textAlign: 'center', marginTop: '1em' }}>
                          {this.state.selectedFile ? (
                            <button className="btn btn-primary" onClick={this.uploadFacilityFile.bind(this)}>
                              {' '}
                              Upload
                            </button>
                          ) : (
                            <button className="btn btn-primary" onClick={this.uploadFacilityFile.bind(this)} disabled>
                              {' '}
                              Upload
                            </button>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <MaterialTable
                      style={{ margin: '1em', padding: '1em' }}
                      onRowClick={(event, rowData) => {
                        this.toFacilityDetails(event, rowData);
                        event.stopPropagation();
                      }}
                      icons={UiUtils.getMaterialTableIcons()}
                      title=""
                      localization={{
                        body: {
                          emptyDataSourceMessage: 'Please Upload files to get started.',
                        },
                      }}
                      columns={[
                        {
                          title: '#',
                          render: (rowData) => rowData.tableData.index + 1,
                          width: '5%',
                          align: 'left',
                        },

                        {
                          title: 'Name',
                          field: 'name',
                          align: 'left',
                          width: '20%',
                        },
                        { title: 'Type', field: 'type', align: 'left', width: '20%' },
                        { title: 'Description', field: 'description', align: 'left', width: '20%' },
                        { title: 'Location', field: 'locations', align: 'left', width: '25%' },

                        {
                          title: ' Status',
                          field: 'status',
                          filtering: false,
                          width: '10%',
                          // render: (rowData) =>(rowData.isduplicate && rowData.isduplicate === true)? "duplicate" : (rowData.isexistsinmain && rowData.isexistsinmain === true)?'existsinmain' : '',
                        },
                      ]}
                      data={this.state.facilityList}
                      onSelectionChange={(e, rows) => {
                        this.selectRow(e, rows);
                      }}
                      onPageChange={(page, pageSize) => {
                        this.handleMaterialTablePageChange(page, pageSize);
                      }}
                      options={{
                        selection: true,

                        showTextRowsSelected: false,
                        sorting: true,
                        actionsColumnIndex: -1,
                        pageSize: this.state.pageSize,
                        headerStyle: { backgroundColor: '#1ABC9C', fontWeight: 'bold', color: '#fff', align: 'left' },
                        pageSizeOptions: [5, 10, 20, 50, 100],
                        filtering: true,
                        paging: this.state.facilityList.length > this.state.pageSize,
                        // paging: this.state.isPaging,
                        initialPage: this.state.initialPage,
                        rowStyle: (rowData) => ({
                          // backgroundColor: rowData.id === this.state.facilityId ? '#FEF5E7' : '#fff',
                          backgroundColor:
                            rowData.isduplicate && rowData.isduplicate === true
                              ? '#FCF3CF'
                              : rowData.isexistsinmain && rowData.isexistsinmain === true
                              ? '#D6EAF8'
                              : '#fff',
                          height: '3em',
                          textTransform: 'capitalize',
                        }),
                      }}
                    />
                  </Col>
                </Row>
                <div style={{ textAlign: 'center', padding: '1em' }}>
                  <Button
                    type="button"
                    style={{ backgroundColor: '#162c50', color: '#fff' }}
                    variant="contained"
                    onClick={(e) => this.bulkImportFacilityList()}
                  >
                    Add to Facility List
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    type="button"
                    style={{ backgroundColor: '#162c50', color: '#fff' }}
                    variant="contained"
                    onClick={(e) => this.bulkDeleteFacilityList()}
                  >
                    Delete selected
                  </Button>
                </div>
              </TabPanel>

              <TabPanel style={{ background: 'lightsteelblue' }}>
                <Row>
                  <Col md={12}>
                    <Card style={{ margin: '1em', padding: '1em' }}>
                      <CardBody>
                        <Row>
                          <Col md={6}>
                            <span>Upload Excel file containing Prescription details</span>
                          </Col>
                          <Col md={6}>
                            <span>
                              <u>
                                {' '}
                                <a
                                  href={FCCImportPrescriptionExcelTemplate}
                                  download="FCCImportPrescriptionExcelTemplate"
                                  target="_blank"
                                  style={{ float: 'right' }}
                                >
                                  Download Sample Excel File for Prescription Import
                                </a>
                              </u>
                            </span>
                          </Col>
                        </Row>
                        <div style={{ border: '1px solid gray', padding: '1em', marginTop: '0.5em' }}>
                          <input type="file" name="file" onChange={this.prescriptionFileHandler.bind(this)} />

                          {/* <div className="imgPreview">{imagePreview}</div> */}
                        </div>
                        <div style={{ textAlign: 'center', marginTop: '1em' }}>
                          {this.state.selectedFile ? (
                            <button className="btn btn-primary" onClick={this.uploadPrescriptionFile.bind(this)}>
                              {' '}
                              Upload
                            </button>
                          ) : (
                            <button
                              className="btn btn-primary"
                              onClick={this.uploadPrescriptionFile.bind(this)}
                              disabled
                            >
                              {' '}
                              Upload
                            </button>
                          )}
                        </div>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <MaterialTable
                      style={{ margin: '1em', padding: '1em' }}
                      onRowClick={(event, rowData) => {
                        this.toPrescriptionDetails(event, rowData);
                        event.stopPropagation();
                      }}
                      icons={UiUtils.getMaterialTableIcons()}
                      title=""
                      localization={{
                        body: {
                          emptyDataSourceMessage: 'Please Upload files to get started.',
                        },
                      }}
                      columns={[
                        {
                          title: '#',
                          render: (rowData) => rowData.tableData.index + 1,
                          width: '5%',

                          align: 'left',
                          filtering: false,
                        },
                        {
                          title: 'Type',
                          field: 'type',
                          filtering: false,
                          width: '10%',
                        },
                        {
                          title: 'Drug Name',
                          field: 'name',
                          width: '20%',
                          filtering: false,
                        },

                        {
                          title: 'Prescribed By',
                          field: 'doctorname',
                          width: '15%',
                          filtering: false,
                        },
                        {
                          title: 'Pharmacy',
                          field: 'pharmacy',
                          width: '10%',
                          filtering: false,
                        },
                        {
                          title: 'Start Date ',
                          type: 'date',
                          field: 'startDate',
                          filtering: false,
                          customSort: (a, b) => {
                            let aTime = a.startDateSortDate !== null ? new Date(a.startDateSortDate).getTime() : '';
                            let bTime = b.startDateSortDate !== null ? new Date(b.startDateSortDate).getTime() : '';
                            return aTime - bTime;
                          },

                          width: '10%',
                        },

                        {
                          title: 'Stopped Taking',
                          type: 'date',
                          field: 'endDate',
                          filtering: false,
                          customSort: (a, b) => {
                            let aTime = a.lastDateSortDate !== null ? new Date(a.lastDateSortDate).getTime() : '';
                            let bTime = b.lastDateSortDate !== null ? new Date(b.lastDateSortDate).getTime() : '';
                            return aTime - bTime;
                          },

                          width: '13%',
                        },
                        {
                          title: ' Status',
                          field: 'status',
                          filtering: false,
                          width: '7%',
                          //  (rowData) =>(rowData.isduplicate && rowData.isduplicate === true)? "duplicate" : (rowData.isexistsinmain && rowData.isexistsinmain === true)?'existsinmain' : '',
                        },
                      ]}
                      data={this.state.prescriptionList}
                      onSelectionChange={(e, rows) => {
                        this.selectPrescriptionRow(e, rows);
                      }}
                      onPageChange={(page, pageSize) => {
                        this.handleMaterialTablePageChange(page, pageSize);
                      }}
                      options={{
                        selection: true,

                        showTextRowsSelected: false,
                        sorting: true,
                        actionsColumnIndex: -1,
                        pageSize: this.state.pageSize,
                        headerStyle: { backgroundColor: '#1ABC9C', fontWeight: 'bold', color: '#fff', align: 'left' },
                        pageSizeOptions: [5, 10, 20, 50, 100],
                        filtering: true,
                        paging: this.state.prescriptionList.length > this.state.pageSize,
                        // paging: this.state.isPaging,
                        initialPage: this.state.initialPage,
                        rowStyle: (rowData) => ({
                          // backgroundColor: rowData.id === this.state.facilityId ? '#FEF5E7' : '#fff',
                          backgroundColor:
                            rowData.isduplicate && rowData.isduplicate === true
                              ? '#FCF3CF'
                              : rowData.isexistsinmain && rowData.isexistsinmain === true
                              ? '#D6EAF8'
                              : '#fff',
                          height: '3em',
                          textTransform: 'capitalize',
                        }),
                      }}
                    />
                  </Col>
                </Row>
                <div style={{ textAlign: 'center', padding: '1em' }}>
                  <Button
                    type="button"
                    style={{ backgroundColor: '#162c50', color: '#fff' }}
                    variant="contained"
                    onClick={(e) => this.bulkImportPrescriptionList()}
                  >
                    Add to Prescription List
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    type="button"
                    style={{ backgroundColor: '#162c50', color: '#fff' }}
                    variant="contained"
                    onClick={(e) => this.bulkDeletePrescriptionList()}
                  >
                    Delete selected
                  </Button>
                </div>
              </TabPanel>
            </Tabs>
          </Col>
        </Row>
      </div>
    );
  }
}

export default ImportList;
