import Button from '@material-ui/core/Button';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import PrintRoundedIcon from '@material-ui/icons/PrintRounded';
import es from 'date-fns/locale/es';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import moment from 'moment';
import React, { Component } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Geocode from 'react-geocode';
import { ValidatorForm } from 'react-material-ui-form-validator';
import 'react-tabs/style/react-tabs.css';
import { Card, CardBody, CardFooter, CardHeader, Col, Label, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import fccAddressStateAction from '../../../redux/fccAddressState/fccAddressStateAction';
import fccPageStateAction from '../../../redux/fccPageState/fccPageStateAction';
import helpDataAction from '../../../redux/help/displayHelpAction';
import store from '../../../redux/store';
import AddIconButton from '../buttons/AddIconButton';
import BackButton from '../buttons/BackButton';
import CancelIconButton from '../buttons/CancelIconButton';
import CreateButton from '../buttons/CreateButton';
import EditIconButton from '../buttons/EditIconButton';
import SaveButton from '../buttons/SaveButton';
import Location from '../location/Location';
import Map from '../map/Map';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
import PtsAlert from '../ptsAlert/PtsAlert';
import IncidentNote from './IncidentNote';
import './MemberIncident.scss';
import ReactToPrint from 'react-to-print';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { MuiPickersUtilsProvider, DateTimePicker, LocalizationProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';
import Address from '../address/Address';
import ShortLocation from '../shortLocation/ShortLocation';
import BreadCrumbDetailsAction from '../../../redux/breadCrumbDetails/BreadCrumbDetailsAction';
registerLocale('es', es);

export default class MemberIncident extends Component {
  incidentTypeList = store.getState().staticData.incidentTypeList;
  header = store.getState().header.header;
  memberDetails = store.getState().memberData.memberData;
  memberName = store.getState().memberData.memberData.firstname + ' ' + store.getState().memberData.memberData.lastname;
  isFreeUser = store.getState().userData.userData.isFreeUser;
  displayHelp = store.getState().help.help;
  fccPageState = store.getState().fccPageState.fccPageState;
  memberHomeUrl = store.getState().memberHomeUrl.memberHomeUrl.homeUrl;
  title = 'Incident Details';
  breadCrumb = [
    { pageid: this.memberHomeUrl, uiname: this.memberName },
    { pageid: '/incidentAppointment', uiname: 'Incident List' },
    { pageid: '', uiname: 'Incident Details' },
  ];
  addressRef = React.createRef();
  addressRef = {};
  locationReferenceList = [];
  incidentReminderRefList = [];
  incidentNoteRefList = [];
  incidentTypeOptionList = [];

  constructor(props) {
    super(props);
    let calenderStartDate = new Date();
    let incidentId = '';
    let action = '';
    let title = '';
    let pageId = props.pageId;
    let isButtonHide = false;
    let isEditButtonHide = true;
    let isViewButtonHide = true;
    let isPrintView = props.isPrintView;
    let canRender = false;
    let incidentData = '';
    let incidentDataId = '';
    let incidentNoteId = '';
    let str = window.location.href;
    let createdIncidentData = {};
    let urlArr = str.split('?');
    let incidentVo = urlArr[1];
    if (incidentVo) {
      let incidentDataVo = incidentVo.split('=');
      incidentData = incidentDataVo[1].split('&');
      incidentDataId = incidentData[0];
      incidentNoteId = incidentDataVo[2];
      incidentNoteId = incidentNoteId.split('&');
      incidentNoteId = incidentNoteId[0];

      action = 'view';
      this.getIncidentDetails(incidentDataId);
    } else {
      incidentId = props.incidentId;
      action = props.action;

      if (!incidentId && props.history && props.history.location && props.history.location.state) {
        incidentId = props.history.location.state.incidentId;
        action = props.history.location.state.action;
        pageId = props.history.location.state.pageId;
        title = props.history.location.state.title;
        createdIncidentData = props.history.location.state.incidentdata;
        if (props.history.location.state.startDate) {
          calenderStartDate = new Date(moment(props.history.location.state.startDate));
        }
      }
      isButtonHide = false;
      isEditButtonHide = true;
      canRender = false;
      if (null !== incidentId && incidentId > 0) {
        this.getIncidentDetails(incidentId);
      } else {
        canRender = true;
      }
    }
    if (isPrintView === 'view_print') {
      isViewButtonHide = false;
    }

    if (incidentId === undefined) {
      incidentId = this.fccPageState.id;
      action = this.fccPageState.action;
      if (action !== 'create') {
        this.getIncidentDetails(incidentId);
      }
    }
    if (this.breadCrumb) {
      Utils.getActiveMenu(this.breadCrumb);
      store.dispatch(BreadCrumbDetailsAction.setBreadCrumbDetails(this.breadCrumb));
    }
    if (pageId === 'createIncident') {
      let date = createdIncidentData.incident.date;
      let newStartDate = Utils.convertUTCDateToUserTimeZoneDate(date);
      let viewStartDate = Utils.convertUTCDateToUserTimeZoneViewDateFormate(date);

      let getDomainObjectPermission = createdIncidentData.domainObjectPrivileges;

      let getCreatePermission = false;
      let getReadPermission = false;
      let getUpdatePermission = false;
      let getDeletePermission = false;
      let showIncidentPermission = false;
      let showIncidentNotePermission = false;
      let getIncidentNoteCreatePermission = false;
      let getIncidentNoteReadPermission = false;
      let getIncidentNoteUpdatePermission = false;
      let getIncidentNoteDeletePermission = false;

      for (let domainObjectKey in getDomainObjectPermission) {
        if (domainObjectKey.toLowerCase() === 'incident') {
          let incidentPermission = getDomainObjectPermission[domainObjectKey];
          let getPermission = Utils.getDomainObjectRolePermission(incidentPermission);

          getCreatePermission = getPermission[0];
          getReadPermission = getPermission[1];
          getUpdatePermission = getPermission[2];
          getDeletePermission = getPermission[3];

          showIncidentPermission = true;
          getCreatePermission = getCreatePermission;
          getReadPermission = getReadPermission;
          getUpdatePermission = getUpdatePermission;
          getDeletePermission = getDeletePermission;
        } else if (domainObjectKey === 'incidentnote') {
          let incidentNotePermission = getDomainObjectPermission[domainObjectKey];

          if (incidentNotePermission.length > 0 && incidentNotePermission !== null) {
            showIncidentNotePermission = true;
          }
          let getPermission = Utils.getDomainObjectRolePermission(incidentNotePermission);
          getCreatePermission = getPermission[0];
          getReadPermission = getPermission[1];
          getUpdatePermission = getPermission[2];
          getDeletePermission = getPermission[3];

          getIncidentNoteCreatePermission = getCreatePermission;
          getIncidentNoteReadPermission = getReadPermission;
          getIncidentNoteUpdatePermission = getUpdatePermission;
          getIncidentNoteDeletePermission = getDeletePermission;
        }
      }
      let locationList = [];
      locationList.push(createdIncidentData.incident.location);
      this.state = {
        incidentNoteList: [],

        getUpdatePermission: getUpdatePermission,
        getDeletePermission: getDeletePermission,
        getReadPermission: getReadPermission,
        getCreatePermission: getCreatePermission,
        id: createdIncidentData.incident.id,
        incidentData: createdIncidentData,
        incidentType: createdIncidentData.incident.type,
        viewIncidentType: createdIncidentData.incident.type,
        type: createdIncidentData.incident.type.type,
        incidentStartDate: viewStartDate,
        startDate: new Date(newStartDate),
        timeView: createdIncidentData.incident.dateTime,
        locationDetails: createdIncidentData.incident.location,
        address: createdIncidentData.incident.location,
        locationList: locationList,
        render: true,
        incidentTitle: createdIncidentData.incident.title,
        note: createdIncidentData.incident.description,
        isEditButtonHide: true,
        isButtonHide: false,
        title: title,
        isPrintView: isPrintView,
        isViewButtonHide: isViewButtonHide,
        pageId: pageId,
        action: action,
        incidentDetails: createdIncidentData.incident,
        isFreeUser: this.isFreeUser,
        noteId: incidentNoteId,
        stepsEnabled: false,
        initialStep: 0,
        steps: [
          {
            element: '.editDetails',
            intro: 'Edit/update Incident Details',
          },
          {
            element: '.noteTitle',
            intro:
              'Notes can be added, edited, deleted. Communication for this Note is started by adding Note and clicking on Messaging icon.',
          },
          {
            element: '.addNote',
            intro: 'Add Notes. User can add multiple Notes. ',
          },
          {
            element: '.print',
            intro: 'Print Incident Details',
          },
          {
            element: '.createSimilar',
            intro: 'create Similar Incident ',
          },
          {
            element: '.delete',
            intro: ' Delete Incident Details',
          },
        ],
        stepsWithNote: [
          {
            element: '.editDetails',
            intro: 'Edit/update Incident Details',
          },
          {
            element: '.noteTitle',
            intro:
              'Notes can be added, edited, deleted. Communication for this Note is started by adding Note and clicking on Messaging icon.',
          },
          {
            element: '.addNote',
            intro: 'Add Notes. User can add multiple Notes. ',
          },
          {
            element: '.deleteIcon',
            intro: 'Delete Note ',
          },
          {
            element: '.editIncidentNote',
            intro: 'Edit Note ',
          },

          {
            element: '.startCommunicationNote',
            intro: 'Start new Communication Thread',
          },
          {
            element: '.createdBy',
            intro: 'Check who has created this note at what date and time.',
          },
          {
            element: '.print',
            intro: 'Print Incident Details',
          },
          {
            element: '.createSimilar',
            intro: 'create Similar Incident ',
          },
          {
            element: '.delete',
            intro: ' Delete Incident Details',
          },
        ],
        displayFloatButton: this.displayHelp,
        showMapView: false,
        showLocationView: true,
        showIncidentNotePermission: showIncidentNotePermission,
        getIncidentNoteCreatePermission: getIncidentNoteCreatePermission,
        getIncidentNoteReadPermission: getIncidentNoteReadPermission,
        getIncidentNoteUpdatePermission: getIncidentNoteUpdatePermission,
        getIncidentNoteDeletePermission: getIncidentNoteDeletePermission,
      };
    } else {
      this.state = {
        id: incidentId,
        noteId: incidentNoteId,
        action: action,
        render: canRender,
        showSuccessAlert: false,
        showFailAlert: false,
        startDate: new Date(calenderStartDate),

        description: '',
        reminderItems: [],
        locationList: [
          {
            operation: 'add',
            action: 'defaultCreate',
            nickName: '',
            shortDescription: '',
            fax: '',
            website: '',
            phone: '',
            address: {
              line: '',
              city: '',
              zipCode: '',
              state: {
                code: '',
              },
            },
          },
        ],
        viewLocationitems: [],
        title: title,
        pageId: pageId,
        locationItemsView: true,
        createFacilityTypeModal: false,
        locationItemsCreateDiv: false,
        facilityEditDiv: false,
        facilityViewDiv: true,
        sweetAlert: false,
        isButtonHide: isButtonHide,
        isEditButtonHide: isEditButtonHide,
        contactList: [],
        memberList: [],
        incidentData: '',
        locationDetails: {},
        contactDetails: {},
        incidentReminderList: [],
        incidentNoteCreateList: [],
        incidentNoteList: [],
        noteAlert: false,
        isMember: true,
        isContact: false,
        contactListId: '',
        incidentType: '',
        viewIncidentType: '',
        incidentTitle: '',
        isPrintView: isPrintView,
        isViewButtonHide: isViewButtonHide,

        getUpdatePermission: false,
        getDeletePermission: false,
        getReadPermission: false,
        getCreatePermission: false,
        isFreeUser: this.isFreeUser,
        incidentDetails: {},
        stepsEnabled: false,
        initialStep: 0,
        steps: [
          {
            element: '.editDetails',
            intro: 'Edit/update Incident Details',
          },
          {
            element: '.noteTitle',
            intro:
              'Notes can be added, edited, deleted. Communication for this Note is started by adding Note and clicking on Messaging icon.',
          },

          {
            element: '.addNote',
            intro: 'Add Notes. User can add multiple Notes. ',
          },
          {
            element: '.print',
            intro: 'Print Incident Details',
          },
          {
            element: '.createSimilar',
            intro: 'create Similar Incident ',
          },

          {
            element: '.delete',
            intro: ' Delete Incident Details',
          },
        ],
        stepsWithNote: [
          {
            element: '.editDetails',
            intro: 'Edit/update Incident Details',
          },
          {
            element: '.noteTitle',
            intro:
              'Notes can be added, edited, deleted. Communication for this Note is started by adding Note and clicking on Messaging icon.',
          },
          {
            element: '.addNote',
            intro: 'Add Notes. User can add multiple Notes. ',
          },
          {
            element: '.deleteIcon',
            intro: 'Delete Note ',
          },
          {
            element: '.editIncidentNote',
            intro: 'Edit Note ',
          },

          {
            element: '.startCommunicationNote',
            intro: 'Start new Communication Thread',
          },
          {
            element: '.createdBy',
            intro: 'Check who has created this note at what date and time.',
          },
          {
            element: '.print',
            intro: 'Print Incident Details',
          },
          {
            element: '.createSimilar',
            intro: 'create Similar Incident ',
          },
          {
            element: '.delete',
            intro: ' Delete Incident Details',
          },
        ],

        displayFloatButton: this.displayHelp,
        showMapView: false,
        showLocationView: true,
        showIncidentNotePermission: false,
        getIncidentNoteCreatePermission: false,
        getIncidentNoteReadPermission: false,
        getIncidentNoteUpdatePermission: false,
        getIncidentNoteDeletePermission: false,
      };
    }
  }

  closeFloatingButton() {
    this.setState({ displayFloatButton: false });
    let displayFloat = false;

    store.dispatch(helpDataAction.setHelpData(displayFloat));
  }

  toggleSteps = () => {
    this.setState((prevState) => ({ stepsEnabled: !prevState.stepsEnabled }));
  };

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };

  handleIncidentStartDateChange(date) {
    this.setState({ startDate: date });
  }

  handleIncidentTypeChange(e) {
    this.setState({ incidentType: e.target.value });
  }

  handleDescriptionChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 256);
    if (res.length < 256) {
      this.setState({ note: res });
    } else {
      Utils.maxFieldLength(256);
    }
  }

  handleIncidentTitleChange(e) {
    this.setState({ incidentTitle: e.target.value });
  }
  async getIncidentDetails(incidentId) {
    let id = incidentId;
    const postObject = {
      header: this.header,
      incident: {
        id: id,
      },
    };

    await FetchServerData.callPostService('incidentms/getIncident', postObject).then(async (output) => {
      if (output.status === 'SUCCESS') {
        let incidentData = output.data.incident;

        let getDomainObjectPermission = output.data.domainObjectPrivileges;

        let getCreatePermission = false;
        let getReadPermission = false;
        let getUpdatePermission = false;
        let getDeletePermission = false;

        for (let domainObjectKey in getDomainObjectPermission) {
          if (domainObjectKey.toLowerCase() === 'incident') {
            let incidentPermission = getDomainObjectPermission[domainObjectKey];
            let getPermission = Utils.getDomainObjectRolePermission(incidentPermission);

            getCreatePermission = getPermission[0];
            getReadPermission = getPermission[1];
            getUpdatePermission = getPermission[2];
            getDeletePermission = getPermission[3];
            this.setState({
              showIncidentPermission: true,
              getCreatePermission: getCreatePermission,
              getReadPermission: getReadPermission,
              getUpdatePermission: getUpdatePermission,
              getDeletePermission: getDeletePermission,
            });
          } else if (domainObjectKey.toLowerCase() === 'incidentnote') {
            let incidentNotePermission = getDomainObjectPermission[domainObjectKey];
            let showIncidentNotePermission = false;
            if (incidentNotePermission.length > 0 && incidentNotePermission !== null) {
              showIncidentNotePermission = true;
            }
            let getPermission = Utils.getDomainObjectRolePermission(incidentNotePermission);
            getCreatePermission = getPermission[0];
            getReadPermission = getPermission[1];
            getUpdatePermission = getPermission[2];
            getDeletePermission = getPermission[3];
            this.setState({
              showIncidentNotePermission: showIncidentNotePermission,
              getIncidentNoteCreatePermission: getCreatePermission,
              getIncidentNoteReadPermission: getReadPermission,
              getIncidentNoteUpdatePermission: getUpdatePermission,
              getIncidentNoteDeletePermission: getDeletePermission,
            });
          }
        }
        let isMember = '';
        let locationDetails = [];
        let date = incidentData.date;
        let newStartDate = Utils.convertUTCDateToUserTimeZoneDate(date);
        let viewStartDate = Utils.convertUTCDateToUserTimeZoneViewDateFormate(date);
        let googleLocationAPIKey = Utils.getLocationAPIKey();
        for (let i = 0; i < incidentData.noteList.length; i++) {
          incidentData.noteList[i].action = 'view';
        }
        this.state.locationList = [];
        this.state.locationList.push(incidentData.location);
        store.dispatch(fccAddressStateAction.setFccAddressState(incidentData.location.address));
        Geocode.setApiKey(googleLocationAPIKey);
        Geocode.setLanguage('en');
        Geocode.setLocationType('ROOFTOP');
        Geocode.enableDebug();

        locationDetails.push(incidentData.location);
        let locaData1 = [];

        for (let i = 0; i < locationDetails.length; i++) {
          let locdata = await Utils.getLatLongMapData(
            locationDetails[i].shortDescription,
            locationDetails[i].address.line,
            locationDetails[i].address.city,
            locationDetails[i].address.state.country.name,
            locationDetails[i].address.state.name,
            i
          );
          locaData1.push(locdata);
        }

        if (incidentData.location !== null) {
          this.setState({
            render: true,
            incidentDetails: incidentData,
            incidentData: incidentData,
            incidentType: incidentData.type,
            viewIncidentType: incidentData.type,
            type: incidentData.type.type,
            incidentStartDate: viewStartDate,
            description: incidentData.description,
            startDate: new Date(newStartDate),
            timeView: incidentData.dateTime,
            locationDetails: incidentData.location,
            address: incidentData.location,
            incidentNoteList: incidentData.noteList,
            addressLocations: locaData1,
            locationList: this.state.locationList,
            noteListRow: true,
            isMember: isMember,
            incidentTitle: incidentData.title,
            note: incidentData.description,
          });
        } else {
          this.setState({
            render: true,
            incidentDetails: incidentData,
            incidentData: incidentData,
            incidentType: incidentData.type,
            viewIncidentType: incidentData.type,
            type: incidentData.type.type,
            incidentStartDate: viewStartDate,
            description: incidentData.description,
            startDate: new Date(newStartDate),
            timeView: incidentData.dateTime,
            locationDetails: {},
            incidentNoteList: incidentData.noteList,
            locationList: this.state.locationList,
            noteListRow: true,
            isMember: isMember,
            incidentTitle: incidentData.title,
            note: incidentData.description,
          });
        }
      } else {
      }
    });
  }

  cancelCreate() {
    if (this.state.pageId === 'memberdeatils') {
      this.props.history.push({
        pathname: '/memberDetails',
      });
    } else {
      this.props.history.push({
        pathname: '/incidentAppointment',
      });
    }
  }
  cancelView() {
    if (this.state.pageId === 'memberdeatils') {
      this.props.history.push({
        pathname: '/memberDetails',
      });
    } else {
      this.props.history.push({
        pathname: '/incidentAppointment',
      });
    }
  }
  cancelPrintView() {
    this.setState({
      action: 'view',
    });
  }
  editCancel(e, eventId) {
    this.setState({
      isEditButtonHide: true,
      isButtonHide: false,
    });
  }

  deleteAlert(e, facilityId) {
    this.setState({
      sweetAlert: true,
    });
  }

  cancelDelete(e, facilityId) {
    this.setState({
      sweetAlert: false,
      reminderSweetAlert: false,
      noteAlert: false,
    });
  }

  addNewLocation(locationData) {
    for (let i = 0; i < this.state.locationList.length; i++) {
      if (
        this.state.locationList[i] &&
        locationData.index === i &&
        this.locationReferenceList[i] &&
        this.locationReferenceList[i].current &&
        this.locationReferenceList[i].current.validateLocation()
      ) {
        this.state.locationList[i] = locationData;
        this.locationReferenceList[i].current.state = locationData;
        this.locationReferenceList[i].current.state.action = 'editLocation';
        this.setState({ render: true });
      }
    }
  }

  updateLocation(locationData) {
    for (let i = 0; i < this.state.locationList.length; i++) {
      if (
        this.state.locationList[i] &&
        locationData.index === i &&
        this.locationReferenceList[i] &&
        this.locationReferenceList[i].current &&
        this.locationReferenceList[i].current.validateLocation()
      ) {
        this.state.locationList[i] = locationData;
        this.locationReferenceList[i].current.state = locationData;
        this.locationReferenceList[i].current.state.action = 'editLocation';
        this.setState({ render: true });
      }
    }
  }

  addNotes() {
    let notes = this.state.incidentNoteList;
    let note = {
      action: 'create',
      operation: 'add',
      shortDescription: this.state.incidentTitle,
      incident: {
        id: this.state.id,
      },
      occurance: {
        occurance: '',
      },
    };

    notes.push(note);
    console.log(this.state.incidentNoteList);
    this.setState({ incidentNoteList: notes });
  }
  editNote(type, note) {}

  updateIncidentNote(e, key, note) {
    if (this.incidentNoteRefList[key] && this.incidentNoteRefList[key].current) {
      const postObject = {
        header: this.header,
        note: {
          id: this.incidentNoteRefList[key].current.state.id,
          note: this.incidentNoteRefList[key].current.state.note,
          incident: {
            id: this.state.id,
          },
          occurance: {
            occurance: this.incidentNoteRefList[key].current.state.occurrence,
          },
          shortDescription: this.incidentNoteRefList[key].current.state.shortDescription,
        },
      };
      FetchServerData.callPostService('incidentms/updateNote', postObject)
        .then((output) => {
          if (output.status === 'SUCCESS') {
            PtsAlert.success(output.message);
            this.state.incidentNoteList = [];
            this.setState({
              incidentNoteList: output.data.noteList,
            });
          } else {
          }
        })
        .catch((error) => {});
    }
  }

  addIncidentNotesInBackend(note) {
    for (let i = 0; i < this.state.incidentNoteList.length; i++) {
      if (
        this.state.incidentNoteList[i] &&
        note.index === i &&
        this.incidentNoteRefList[i] &&
        this.incidentNoteRefList[i].current
      ) {
        let noteObj = {
          incident: {
            id: this.state.id,
          },
          occurance: {
            occurance: this.incidentNoteRefList[i].current.state.occurrence,
          },
          note: this.incidentNoteRefList[i].current.state.note,
          shortDescription: this.incidentNoteRefList[i].current.state.shortDescription,
        };
        const postObject = {
          header: this.header,
          note: noteObj,
        };
        FetchServerData.callPostService('incidentms/createNote', postObject)
          .then((output) => {
            if (output.status === 'SUCCESS') {
              let noteListData = output.data;
              // this.state.incidentNoteList = [];
              this.state.incidentNoteList = [];
              this.incidentNoteRefList[i].current.state.action = 'view';
              this.setState({
                incidentNoteList: noteListData.noteList,
              });
            } else {
            }
          })
          .catch((error) => {});
      }
    }
  }

  backToNoteList() {
    this.setState({ createNoteListRow: false, noteListRow: true });
  }

  incidentNoteRemove(item) {
    let items = [];
    if (item.operation && 'update' === item.operation) {
      /* Existing Item Deleted, Mark it deleted */
      let index = item.index;

      for (let i = 0; i < this.state.incidentNoteList.length; i++) {
        let item = this.state.incidentNoteList[i];
        if (this.state.incidentNoteList[i].operation === 'update' && index === i) {
          item.operation = 'delete';
        }

        items.push(item);
      }

      this.setState({ incidentNoteList: items });
    }
    if (item.operation && 'add' === item.operation) {
      /* Added Item Deleted, Remove from list */
      let index = item.index;
      for (let i = 0; i < this.state.incidentNoteList.length; i++) {
        if (this.state.incidentNoteList[i].operation === 'add' && index === i) {
          this.incidentNoteRefList.splice(i, 1);
          // continue;
        } else {
          items.push(this.state.incidentNoteList[i]);
        }
      }
      this.setState({ incidentNoteList: items });
    }

    /*Go Back to Location list view if all new Locations are deleted */
    let flag = true;
    for (let i = 0; i < items.length; i++) {
      if (items[i].operation === 'add') {
        flag = false;
      }
    }
    if (flag) {
      this.backToNoteList();
    }
  }

  addIncidentInBackend(e) {
    // let locations = [];
    // for (let i = 0; i < this.locationReferenceList.length; i++) {
    //   if (null !== this.locationReferenceList[i].current) {
    //     let location = {};
    //     location.id = this.locationReferenceList[i].current.state.id;
    //     location.shortDescription = this.locationReferenceList[i].current.state.shortDescription;
    //     location.nickName = this.locationReferenceList[i].current.state.nickName;
    //     location.fax = this.locationReferenceList[i].current.state.fax;
    //     location.website = this.locationReferenceList[i].current.state.website;
    //     location.phone = this.locationReferenceList[i].current.state.phone;
    //     location.address = {};
    //     location.address.id = this.locationReferenceList[i].current.state.address.id;
    //     location.address.line = this.locationReferenceList[i].current.state.address.line;
    //     location.address.city = this.locationReferenceList[i].current.state.address.city;
    //     location.address.zipCode = this.locationReferenceList[i].current.state.address.zipCode;
    //     location.address.state = this.locationReferenceList[i].current.addressRef.current.state.state;
    //     locations[i] = location;
    //   }
    // }
    let locations = [];

    // for (let i = 0; i < this.locationReferenceList.length; i++) {
    //   if (null !== this.locationReferenceList[i].current) {
    let location = {};
    location.shortDescription = this.addressRef.current.state.shortDescription;
    location.nickName = this.addressRef.current.state.nickName;
    location.address = {};
    location.address.id = this.addressRef.current.state.id;
    location.address.line = this.addressRef.current.state.line;
    location.address.city = this.addressRef.current.state.city;
    location.address.zipCode = this.addressRef.current.state.zipCode;
    location.address.state = this.addressRef.current.state.state;
    // locations[i] = location;
    //   }
    // }
    let dateTime = Utils.convertUserTimeZoneDateToUTCDate(this.state.startDate);

    const postObject = {
      header: this.header,
      incident: {
        date: dateTime,
        description: this.state.note,
        type: this.state.incidentType,
        title: this.state.incidentTitle,
        member: {
          id: this.memberDetails.member,
        },
        location: location,
        contactList: [],

        reminderList: this.state.reminderItems,
      },
    };
    FetchServerData.callPostService('/incidentms/create', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let incidentdata = output.data;

          PtsAlert.success(output.message);
          let fccPageState = {
            id: incidentdata.incident.id,
            action: 'view',
          };
          store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
          if (this.state.pageId === 'memberdeatils') {
            this.props.history.push({
              pathname: '/memberDetails',
            });
          } else {
            this.props.history.push({
              pathname: '/viewIncident',
              state: {
                action: 'view',
                title: 'Facility Details',
                incidentdata: incidentdata,
                pageId: 'createIncident',
              },
            });
          }
        } else {
        }
      })
      .catch((error) => {});
  }

  backToLocationView() {
    this.setState({ locationItemsView: true, locationItemsCreateDiv: false });
  }

  updateIncidentInBackend(e) {
    let locations = [];

    // for (let i = 0; i < this.locationReferenceList.length; i++) {
    //   if (null !== this.locationReferenceList[i].current) {
    let location = {};
    // location.id = this.locationReferenceList[i].current.state.id;
    // location.shortDescription = this.locationReferenceList[i].current.state.shortDescription;
    // location.nickName = this.locationReferenceList[i].current.state.nickName;
    // location.fax = this.locationReferenceList[i].current.state.fax;
    // location.website = this.locationReferenceList[i].current.state.website;
    // location.phone = this.locationReferenceList[i].current.state.phone;
    location.shortDescription = this.addressRef.current.state.shortDescription;
    location.nickName = this.addressRef.current.state.nickName;
    location.address = {};
    location.address.id = this.addressRef.current.state.id;
    location.address.line = this.addressRef.current.state.line;
    location.address.city = this.addressRef.current.state.city;
    location.address.zipCode = this.addressRef.current.state.zipCode;
    location.address.state = this.addressRef.current.state.state;
    // locations[i] = location;
    //   }
    // }

    // let dateTime = Utils.convertUserTimeZoneDateToUTCDate(this.state.startDate);
    const postObject = {
      header: this.header,
      incident: {
        id: this.state.id,
        date: Utils.convertUserTimeZoneDateToUTCDate(this.state.startDate),
        description: this.state.note,
        type: this.state.incidentType,
        title: this.state.incidentTitle,
        member: {
          id: this.memberDetails.member,
        },
        location: location,
        contactList: [],

        reminderList: this.state.incidentReminderList,
      },
    };
    FetchServerData.callPostService('/incidentms/update', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let incidentData = output.data;

          let name = '';
          let email = '';
          let phone = '';
          let isContact = '';
          let isMember = '';

          let date = incidentData.date;
          let newStartDate = Utils.convertUTCDateToUserTimeZoneDate(date);
          let viewStartDate = Utils.convertUTCDateToUserTimeZoneViewDateFormate(date);
          for (let i = 0; i < incidentData.noteList.length; i++) {
            incidentData.noteList[i].action = 'view';
          }
          this.state.locationList = [];
          this.state.locationList.push(incidentData.location);
          this.getIncidentDetails(this.state.id);
          if (incidentData.location !== null) {
            this.setState({
              incidentData: incidentData,
              incidentType: incidentData.type,
              viewIncidentType: incidentData.type,
              type: incidentData.type.type,
              incidentStartDate: viewStartDate,
              startDate: new Date(newStartDate),
              timeView: incidentData.dateTime,
              locationDetails: incidentData.location,
              address: incidentData.location,
              incidentNoteList: incidentData.noteList,
              render: true,
              incidentTitle: incidentData.title,
              note: incidentData.description,
              isEditButtonHide: true,
              isButtonHide: false,
            });
          } else {
            this.setState({
              incidentData: incidentData,
              incidentType: incidentData.type,
              viewIncidentType: incidentData.type,
              type: incidentData.type.type,
              incidentStartDate: viewStartDate,
              description: incidentData.description,
              startDate: new Date(newStartDate),
              timeView: incidentData.dateTime,
              locationDetails: {},
              contactDetails: incidentData.contactList[0],
              line: '',
              city: '',
              zipCode: '',
              state: '',
              country: '',
              contactName: name,
              contactPhone: phone,
              contactEmail: email,
              incidentNoteList: incidentData.noteList,
              locationList: this.state.locationList,
              render: true,
              noteListRow: true,
              isContact: isContact,
              isMember: isMember,
              incidentTitle: incidentData.title,
              note: incidentData.description,
              isEditButtonHide: true,
              isButtonHide: false,
            });
          }
          PtsAlert.success(output.message);
        } else {
        }
      })
      .catch((error) => {});
    // }
  }

  deleteIncident() {
    let id = this.state.id;
    const postObject = {
      header: this.header,
      incident: {
        id: id,
      },
    };

    FetchServerData.callPostService('/incidentms/delete/', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        this.props.history.push({
          pathname: '/incidentAppointment',
          sweetAlert: false,
        });
      } else {
      }
    });
  }

  deleteIncidentNote(e, note) {
    let id = note.id;
    const postObject = {
      header: this.header,
      note: {
        id: id,
      },
    };

    FetchServerData.callPostService('incidentms/deleteNote', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        for (let i = 0; i < this.state.incidentNoteList.length; i++) {
          if (this.state.incidentNoteList[i].id === id) {
            this.state.incidentNoteList.splice(i, 1);
          }
        }

        this.setState({
          incidentNoteList: this.state.incidentNoteList,
          noteAlert: false,
        });
      } else {
      }
    });
  }

  editIncidentDetails(e) {
    this.setState({
      isEditButtonHide: false,
      isButtonHide: true,
    });
  }

  repeatIncident() {
    this.props.history.push({
      pathname: '/createSimilarIncident',
      state: { action: 'createSimilarIncident', title: 'Add Incident', incidentDetails: this.state.incidentDetails },
    });
  }

  showMap() {
    this.setState({
      showMapView: true,
      showLocationView: false,
    });
  }

  closeButton() {
    this.setState({
      showMapView: false,
      showLocationView: true,
    });
  }
  getMapView(locations) {
    return <Map data={locations}></Map>;
  }

  render() {
    this.incidentNoteRefList = [];
    for (let i = 0; this.state.incidentNoteList && i < this.state.incidentNoteList.length; i++) {
      this.incidentNoteRefList[i] = React.createRef();
    }

    this.incidentTypeRef = React.createRef();
    this.locationReferenceList = [];
    this.state.locationList.forEach((element, index) => {
      this.locationReferenceList[index] = React.createRef();
    });
    if (this.state.render === false) {
      return <div />;
    } else if (this.state.action === 'create') {
      return this.createIncident();
    } else if (this.state.action === 'edit') {
      return this.editIncident();
    } else if (this.state.action === 'view') {
      return this.viewIncident();
    } else if (this.state.action === 'printIncident') {
      return this.printIncident();
    }
  }
  printPreviewIncident() {
    this.setState({
      action: 'printIncident',
    });
  }

  printIncidentContent() {
    let printContents = document.getElementById('printform').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();

    document.body.innerHTML = originalContents;
    this.setState({
      action: 'printIncident',
      render: true,
    });
    document.location.reload(true);
  }
  handleAddressChange(address) {
    this.setState({ address: address });
  }
  createIncident() {
    let locationItems = [];

    this.state.locationList.forEach((element, index) => {
      if ('delete' !== element.operation) {
        locationItems.push(
          <Col md={12} style={{ padding: '0.5em' }}>
            <Location
              ref={this.locationReferenceList[index]}
              key={index}
              data={element}
              srNo={index}
              action={element.action}
              pageId="incident"
              isButtonHide="true"
              onLocationAdd={this.addNewLocation.bind(this)}
              onUpdate={this.updateLocation.bind(this)}
            />
          </Col>
        );
      }
    });

    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={'Add Incident For' + ' ' + this.memberName}
          domainObject="Incident"
        />
        <ValidatorForm style={{ width: '100%' }} ref="form" onSubmit={this.addIncidentInBackend.bind(this)}>
          <Row style={{ position: 'relative', margin: '1em' }}>
            {' '}
            <Col md={6} style={{ position: 'relative', padding: '5px' }}>
              <Card className="doctorViewCard" style={{ padding: '1em', height: '100%' }}>
                <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                  <Label style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}>{this.state.title}</Label>
                </CardHeader>
                <CardBody>
                  <Row style={{ marginTop: '1em', marginBottom: '-1em' }}>
                    <Col md={6} style={{ marginBottom: '1em' }}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ThemeProvider theme={materialTheme}>
                          <DateTimePicker
                            value={this.state.startDate}
                            // variant="inline"
                            fullWidth
                            variant="outlined"
                            label="Date"
                            id="ptsDateTimePicker"
                            inputVariant="outlined"
                            format="MM/dd/yyyy  h:mm aa"
                            onChange={this.handleIncidentStartDateChange.bind(this)}
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>{' '}
                    </Col>
                    <Col md={6}>
                      <input
                        className="pts_dataList_dropDown"
                        type="text"
                        required
                        id="Incident-outlined"
                        list="incidentType"
                        placeholder="Incident Type*"
                        label="Incident Type*"
                        value={this.state.incidentType}
                        onChange={(event, value) => {
                          this.handleIncidentTypeChange(event, value);
                        }}
                      />
                      <datalist id="incidentType">
                        {this.incidentTypeList.map((event, key) => (
                          <option key={event.type} value={event.type} />
                        ))}
                      </datalist>
                    </Col>
                  </Row>
                  <Row style={{ margin: '1.1em -1em' }}>
                    <Col md={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="description"
                        label="Title"
                        required
                        value={this.state.incidentTitle}
                        onChange={this.handleIncidentTitleChange.bind(this)}
                        name="name"
                        type="text"
                        className="textField"
                        InputProps={{}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <TextareaAutosize
                        aria-label="minimum height"
                        style={{ width: '100%', padding: '10px' }}
                        minRows={5}
                        id="description"
                        value={this.state.note}
                        inputprops={{ maxLength: 256, padding: '10px' }}
                        name="note"
                        onChange={this.handleDescriptionChange.bind(this)}
                        placeholder="Description"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} style={{ padding: '5px' }}>
              <Card className="doctorViewCard" style={{ padding: '1em', height: '100%' }}>
                <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                  <Label style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}>Location</Label>
                </CardHeader>
                <CardBody>
                  {/* {locationItems} */}
                  <div className="location_details_view">
                    <div className="panel panel-blue">
                      <div className="panel-heading">
                        {/* <ShortLocation
                          ref={this.addressRef}
                          onChange={this.handleAddressChange.bind(this)}
                          data={this.state.address}
                          pageId="incident"
                          isButtonHide="true"
                          action="create"
                        /> */}
                        <ShortLocation
                          ref={this.addressRef}
                          onChange={this.handleAddressChange.bind(this)}
                          data={this.state.address}
                          action="create"
                          // pageId="incident"
                          // isButtonHide="true"
                        />
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <CardFooter>
            <div style={{ textAlign: 'center', padding: '0.5em' }}>
              <CreateButton>Add</CreateButton>
              &nbsp;
              <CancelIconButton onClick={(e) => this.cancelCreate()} />
            </div>
          </CardFooter>
        </ValidatorForm>
      </>
    );
  }

  viewIncident() {
    if (this.state.isFreeUser === true) {
      return this.viewIncidentWithAdvertisement();
    } else {
      return this.viewIncidentWithoutAdvertisement();
    }
  }

  viewIncidentWithAdvertisement() {
    let taskNotes = [];
    this.state.incidentNoteList.forEach((note, index) => {
      if ('delete' !== note.operation) {
        taskNotes.unshift(
          <Col md={6} style={{ marginTop: '1em' }}>
            <IncidentNote
              ref={this.incidentNoteRefList[index]}
              key={index}
              data={note}
              srno={index}
              getCreatePermission={this.state.getIncidentNoteCreatePermission}
              getDeletePermission={this.state.getIncidentNoteDeletePermission}
              getUpdatePermission={this.state.getIncidentNoteUpdatePermission}
              action={note.action ? note.action : 'view'}
              noteId={this.state.noteId}
              pageId={this.state.pageId}
              incidentDataId={this.state.id}
              isPrintView={this.state.isPrintView}
              onNoteEdit={this.editNote.bind(this, note)}
              onNoteDelete={this.deleteIncidentNote.bind(this, note)}
              onNoteUpdate={this.updateIncidentNote.bind(this, note, index)}
              addNote={this.addIncidentNotesInBackend.bind(this)}
              onDelete={this.incidentNoteRemove.bind(this)}
              onSuccess={this.getIncidentDetails.bind(this)}
            />
          </Col>
        );
      }
    });
    return (
      <>
        {this.state.isViewButtonHide && (
          <>
            {this.state.isEditButtonHide && (
              <MenuWithBreadScrum
                backCallback={this.cancelView.bind(this)}
                deleteCallback={this.deleteIncident.bind(this)}
                printCallback={this.printPreviewIncident.bind(this)}
                createSimilarCallback={this.repeatIncident.bind(this)}
                getDeletePermission={this.state.getDeletePermission}
                getCreatePermission={this.state.getCreatePermission}
                getUpdatePermission={this.state.getUpdatePermission}
                getAllPermission={this.state.getAllPermission}
                breadCrumb={this.breadCrumb}
                history={this.props.history}
                name={this.title + ' For ' + this.memberName}
                domainObject="Incident"
              />
            )}
          </>
        )}
        {this.state.isButtonHide && (
          <MenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            name={'Edit Incident For' + ' ' + this.memberName}
            domainObject="Incident"
          />
        )}

        {this.state.incidentNoteList.length > 0 ? (
          <Steps
            enabled={this.state.stepsEnabled}
            steps={this.state.stepsWithNote}
            initialStep={this.state.initialStep}
            onExit={this.onExit}
          />
        ) : (
          <Steps
            enabled={this.state.stepsEnabled}
            steps={this.state.steps}
            initialStep={this.state.initialStep}
            onExit={this.onExit}
          />
        )}
        <Row style={{ position: 'relative', margin: '1em' }}>
          <Col md={12}>
            {this.state.isEditButtonHide && (
              <Row style={{ marginBottom: '1em' }}>
                <Col md={5}>
                  <Card style={{ borderRadius: '20px', height: '100%', position: 'relative' }}>
                    <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                      <Row>
                        <Col md={10}>
                          <label style={{ color: '#EC7063' }}>{this.state.incidentTitle}</label>
                        </Col>
                        <Col md={2}>
                          {this.state.isViewButtonHide && (
                            <>
                              {this.state.getUpdatePermission === true && (
                                <EditIconButton onClick={(e) => this.editIncidentDetails(e, 'edit')} />
                              )}
                            </>
                          )}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md={12}>
                          <Row style={{ padding: '5px 0px' }}>
                            <Col md={3}>
                              <span style={{ color: '#000' }}>
                                <strong>Date</strong>
                              </span>
                            </Col>
                            <Col md={8}>
                              <span style={{ color: '#000' }}>{this.state.incidentStartDate}</span>
                            </Col>
                          </Row>
                          <Row style={{ padding: '5px 0px' }}>
                            <Col md={3}>
                              <span style={{ color: '#000' }}>
                                <strong>Type</strong>
                              </span>
                            </Col>
                            <Col md={8}>
                              <span style={{ color: '#000' }}>{this.state.viewIncidentType}</span>
                            </Col>
                          </Row>
                          <Row style={{ padding: '5px 0px' }}>
                            <Col md={3}>
                              <span style={{ color: '#000' }}>
                                <strong>Description</strong>
                              </span>
                            </Col>
                            <Col md={8}>
                              <span style={{ color: '#000' }}>
                                <pre className="view_incidentNote" style={{ marginLeft: '-1.9px' }}>
                                  {this.state.note}
                                </pre>
                              </span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={7}>
                  {this.state.showMapView && (
                    <Row>
                      <Col md={12}>
                        <Card style={{ height: '100%', borderRadius: '20px', position: 'relative' }}>
                          <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                            <Row>
                              <Col md={10}>
                                <Label style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}>
                                  Location Details
                                </Label>
                              </Col>
                              <Col md={2}></Col>
                            </Row>
                          </CardHeader>
                          <CardBody>
                            <Row>
                              <Col md={12}>
                                <ShortLocation action="view" pageId="incident" data={this.state.address} />
                                {/* <Location action="view" pageId="incident" data={this.state.locationDetails} /> */}
                              </Col>
                            </Row>
                            <Row style={{ margin: '0em' }}>
                              <Col md={12}>
                                <Row md={12}>
                                  <Col md={12} style={{ padding: '0em' }}>
                                    {' '}
                                    <span className="closeFloatingButton" aria-label="add">
                                      {' '}
                                      <CloseIcon
                                        onClick={this.closeButton.bind(this)}
                                        style={{ float: 'right', fontSize: '15px', cursor: 'pointer' }}
                                      />
                                    </span>
                                  </Col>
                                </Row>{' '}
                                <Row>
                                  <Col md={12}>{this.getMapView(this.state.addressLocations)} </Col>
                                </Row>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>{' '}
                    </Row>
                  )}
                  {this.state.showLocationView && (
                    <Card style={{ height: '100%', borderRadius: '20px' }}>
                      <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                        <Row>
                          <Col md={10}>
                            <Label style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}>
                              Location Details
                            </Label>
                          </Col>
                          <Col md={2}></Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md={12}>
                            <ShortLocation action="view" pageId="incident" data={this.state.address} />
                            {/* <Location action="view" pageId="incident" data={this.state.locationDetails} /> */}
                          </Col>
                        </Row>
                        <Row md={12} style={{ marginLeft: 'auto' }}>
                          {' '}
                          {/* <Button
                            type={this.state.type ? this.state.type : ''}
                            variant="contained"
                            size="small"
                            style={{ background: '#717373', color: '#fff', marginRight: '1rem', marginBottom: '1em' }}
                            onClick={(e) => this.showMap()}
                          >
                            Show Map
                          </Button> */}
                        </Row>
                      </CardBody>
                    </Card>
                  )}
                </Col>
              </Row>
            )}
          </Col>
          {this.state.isButtonHide && this.editIncident()}
          {this.state.showIncidentNotePermission === true ? (
            <>
              <Col md={12} style={{ marginTop: '1em' }}>
                <Card style={{ borderRadius: '20px', height: ' 100%' }}>
                  <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                    <Row>
                      <Col md={9}>
                        <Label
                          style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}
                          className="noteTitle"
                        >
                          Notes
                        </Label>
                      </Col>
                      <Col md={3} style={{ textAlign: 'end' }}>
                        {this.state.isViewButtonHide && (
                          <>
                            {this.state.getIncidentNoteCreatePermission === true && (
                              <span className="addNote" style={{ float: 'right' }}>
                                <Tooltip title="Add Notes" aria-label="add">
                                  <AddIconButton onClick={this.addNotes.bind(this)} btnName="Add Note" />
                                </Tooltip>
                              </span>
                            )}
                          </>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>{taskNotes}</Row>
                  </CardBody>
                </Card>
              </Col>
            </>
          ) : (
            <></>
          )}
        </Row>
        {this.state.isViewButtonHide && (
          <>
            <div style={{ textAlign: 'center', padding: '0em 0em 1em' }}>
              <BackButton
                type="button"
                style={{ backgroundColor: '#162c50', color: '#fff' }}
                variant="contained"
                startIcon={<CloseIcon />}
                onClick={(e) => this.cancelView()}
              >
                Back
              </BackButton>
            </div>
          </>
        )}
      </>
    );
  }

  viewIncidentWithoutAdvertisement() {
    let taskNotes = [];
    this.state.incidentNoteList.forEach((note, index) => {
      if ('delete' !== note.operation) {
        taskNotes.unshift(
          <Col md={6} style={{ marginTop: '1em' }}>
            <IncidentNote
              ref={this.incidentNoteRefList[index]}
              key={index}
              data={note}
              srno={index}
              getCreatePermission={this.state.getIncidentNoteCreatePermission}
              getDeletePermission={this.state.getIncidentNoteDeletePermission}
              getUpdatePermission={this.state.getIncidentNoteUpdatePermission}
              action={note.action ? note.action : 'view'}
              noteId={this.state.noteId}
              pageId={this.state.pageId}
              incidentDataId={this.state.id}
              isPrintView={this.state.isPrintView}
              onNoteEdit={this.editNote.bind(this, note)}
              onNoteDelete={this.deleteIncidentNote.bind(this, note)}
              onNoteUpdate={this.updateIncidentNote.bind(this, note, index)}
              addNote={this.addIncidentNotesInBackend.bind(this)}
              onDelete={this.incidentNoteRemove.bind(this)}
              onSuccess={this.getIncidentDetails.bind(this)}
            />
          </Col>
        );
      }
    });
    return (
      <>
        {this.state.isViewButtonHide && (
          <>
            {this.state.isEditButtonHide && (
              <MenuWithBreadScrum
                backCallback={this.cancelView.bind(this)}
                deleteCallback={this.deleteIncident.bind(this)}
                createSimilarCallback={this.repeatIncident.bind(this)}
                printCallback={this.printPreviewIncident.bind(this)}
                getDeletePermission={this.state.getDeletePermission}
                getCreatePermission={this.state.getCreatePermission}
                getAllPermission={this.state.getAllPermission}
                breadCrumb={this.breadCrumb}
                history={this.props.history}
                name={this.title + ' For ' + this.memberName}
                domainObject="Incident"
              />
            )}
          </>
        )}
        {this.state.isButtonHide && (
          <MenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            name={'Edit Incident For' + ' ' + this.memberName}
            domainObject="Incident"
          />
        )}
        {this.state.incidentNoteList.length > 0 ? (
          <Steps
            enabled={this.state.stepsEnabled}
            steps={this.state.stepsWithNote}
            initialStep={this.state.initialStep}
            onExit={this.onExit}
          />
        ) : (
          <Steps
            enabled={this.state.stepsEnabled}
            steps={this.state.steps}
            initialStep={this.state.initialStep}
            onExit={this.onExit}
          />
        )}
        <Row style={{ position: 'relative', margin: '1em' }}>
          <Col md={12}>
            {this.state.isEditButtonHide && (
              <Row>
                <Col md={5} style={{ position: 'relative' }}>
                  <Card style={{ borderRadius: '20px', height: '100%', position: 'relative' }}>
                    <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                      <Row>
                        <Col md={10}>
                          <label style={{ color: '#EC7063' }}>{this.state.incidentTitle}</label>
                        </Col>
                        <Col md={2}>
                          {this.state.isViewButtonHide && (
                            <>
                              {this.state.getUpdatePermission === true && (
                                <EditIconButton onClick={(e) => this.editIncidentDetails(e, 'edit')} />
                              )}
                            </>
                          )}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md={12}>
                          <Row style={{ padding: '5px 0px' }}>
                            <Col md={3}>
                              <span style={{ color: '#000' }}>
                                <strong>Date</strong>
                              </span>
                            </Col>
                            <Col md={8}>
                              <span style={{ color: '#000' }}>{this.state.incidentStartDate}</span>
                            </Col>
                          </Row>
                          <Row style={{ padding: '5px 0px' }}>
                            <Col md={3}>
                              <span style={{ color: '#000' }}>
                                <strong>Type</strong>
                              </span>
                            </Col>
                            <Col md={8}>
                              <span style={{ color: '#000' }}>{this.state.viewIncidentType}</span>
                            </Col>
                          </Row>
                          <Row style={{ padding: '5px 0px' }}>
                            <Col md={3}>
                              <span style={{ color: '#000' }}>
                                <strong>Description</strong>
                              </span>
                            </Col>
                            <Col md={8}>
                              <span style={{ color: '#000' }}>
                                <pre className="view_incidentNote" style={{ marginLeft: '-1.9px' }}>
                                  {this.state.note}
                                </pre>
                              </span>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
                <Col md={7}>
                  {this.state.showMapView && (
                    <Row>
                      <Col md={12}>
                        <Card style={{ height: '100%', borderRadius: '20px', position: 'relative' }}>
                          <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                            <Row>
                              <Col md={10}>
                                <Label style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}>
                                  Location Details
                                </Label>
                              </Col>
                              <Col md={2}></Col>
                            </Row>
                          </CardHeader>
                          <CardBody>
                            <Row>
                              <Col md={12}>
                                <ShortLocation action="view" pageId="incident" data={this.state.address} />
                                {/* <Location action="view" pageId="incident" data={this.state.locationDetails} /> */}
                              </Col>
                            </Row>
                            <Row style={{ margin: '0em' }}>
                              <Col md={12}>
                                <Row md={12}>
                                  <Col md={12} style={{ padding: '0em' }}>
                                    {' '}
                                    <span className="closeFloatingButton" aria-label="add">
                                      {' '}
                                      <CloseIcon
                                        onClick={this.closeButton.bind(this)}
                                        style={{ float: 'right', fontSize: '15px', cursor: 'pointer' }}
                                      />
                                    </span>
                                  </Col>
                                </Row>{' '}
                                <Row>
                                  <Col md={12}>{this.getMapView(this.state.addressLocations)} </Col>
                                </Row>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>{' '}
                    </Row>
                  )}
                  {this.state.showLocationView && (
                    <Card style={{ borderRadius: '20px', height: '100%' }}>
                      <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                        <Row>
                          <Col md={10}>
                            <Label style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}>
                              Location Details
                            </Label>
                          </Col>
                          <Col md={2}></Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md={12}>
                            <ShortLocation action="view" pageId="incident" data={this.state.address} />
                            {/* <Location action="view" pageId="incident" data={this.state.locationDetails} /> */}
                          </Col>
                        </Row>
                        <Row md={12} style={{ marginLeft: 'auto' }}>
                          {' '}
                          {/* <Button
                            type={this.state.type ? this.state.type : ''}
                            variant="contained"
                            size="small"
                            style={{ background: '#717373', color: '#fff', marginRight: '1rem', marginBottom: '1em' }}
                            onClick={(e) => this.showMap()}
                          >
                            Show Map
                          </Button> */}
                        </Row>
                      </CardBody>
                    </Card>
                  )}
                </Col>
              </Row>
            )}
          </Col>
          {this.state.isButtonHide && this.editIncident()}

          {/* <Row> */}
          {this.state.showIncidentNotePermission === true ? (
            <>
              <Col md={12} style={{ marginTop: '1em' }}>
                <Card style={{ borderRadius: '20px', height: ' 100%' }}>
                  <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                    <Row>
                      <Col md={9}>
                        <Label
                          style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}
                          className="noteTitle"
                        >
                          Notes
                        </Label>
                      </Col>
                      <Col md={3} style={{ textAlign: 'end' }}>
                        {this.state.isViewButtonHide && (
                          <>
                            {this.state.getIncidentNoteCreatePermission === true && (
                              <span className="addNote" style={{ float: 'right' }}>
                                <Tooltip title="Add Notes" aria-label="add">
                                  <AddIconButton onClick={this.addNotes.bind(this)} btnName="Add Note" />
                                </Tooltip>
                              </span>
                            )}
                          </>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>

                  <CardBody>
                    <Row>{taskNotes}</Row>
                  </CardBody>
                </Card>
              </Col>
            </>
          ) : (
            <></>
          )}
        </Row>
        {this.state.isViewButtonHide && (
          <>
            <div style={{ textAlign: 'center', padding: '0em 0em 1em' }}>
              <BackButton
                type="button"
                style={{ backgroundColor: '#162c50', color: '#fff' }}
                variant="contained"
                startIcon={<CloseIcon />}
                onClick={(e) => this.cancelView()}
              >
                Back
              </BackButton>
            </div>
          </>
        )}
      </>
    );
  }

  editIncident() {
    let locationItems = [];

    this.state.locationList.forEach((element, index) => {
      if ('delete' !== element.operation) {
        locationItems.push(
          <Col md={12} style={{ padding: '0.5em' }}>
            <Location
              ref={this.locationReferenceList[index]}
              key={index}
              data={element}
              srNo={index}
              pageId="incident"
              action={element.action ? element.action : 'edit'}
              onLocationAdd={this.addNewLocation.bind(this)}
              onUpdate={this.updateLocation.bind(this)}
            />
          </Col>
        );
      }
    });

    return (
      <>
        <Col md={12}>
          {' '}
          <Card className="doctorViewCard">
            <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
              <Label style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}>Incident Details</Label>
            </CardHeader>
            <CardBody>
              <Row style={{ marginTop: '1em', marginBottom: '-1em' }}>
                <Col md={6}>
                  <Row style={{ marginBottom: '-1em' }}>
                    <Col md={6} style={{ marginBottom: '1em' }}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ThemeProvider theme={materialTheme}>
                          <DateTimePicker
                            value={this.state.startDate}
                            variant="outlined"
                            fullWidth
                            id="ptsDateTimePicker"
                            inputVariant="outlined"
                            format="MM/dd/yyyy  h:mm aa"
                            onChange={this.handleIncidentStartDateChange.bind(this)}
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>{' '}
                    </Col>
                    <Col md={6}>
                      <input
                        className="pts_dataList_dropDown"
                        type="text"
                        id="demo-simple-select-outlined"
                        list="data"
                        required
                        placeholder="Incident Type*"
                        label="Incident Type*"
                        value={this.state.incidentType}
                        onChange={(event, value) => {
                          this.handleIncidentTypeChange(event, value);
                        }}
                      />
                      <datalist id="data">
                        {this.incidentTypeList.map((event, key) => (
                          <option key={event.type} value={event.type} />
                        ))}
                      </datalist>
                    </Col>
                  </Row>
                  <Row style={{ margin: '1.7em -1em' }}>
                    <Col md={12}>
                      <TextField
                        fullWidth
                        variant="outlined"
                        id="description"
                        label="Title"
                        required
                        value={this.state.incidentTitle}
                        onChange={this.handleIncidentTitleChange.bind(this)}
                        name="name"
                        type="text"
                        className="textField"
                        InputProps={{}}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <TextareaAutosize
                        aria-label="minimum height"
                        style={{ width: '100%', padding: '10px' }}
                        minRows={5}
                        id="description"
                        value={this.state.note}
                        inputprops={{ maxLength: 256, padding: '10px' }}
                        name="note"
                        onChange={this.handleDescriptionChange.bind(this)}
                        placeholder="Description"
                      />
                    </Col>
                  </Row>
                </Col>
                <Col md={6} className="locationRow">
                  {/* {locationItems} */}
                  <div className="location_details_view">
                    <div className="panel panel-blue">
                      <div className="panel-heading">
                        <ShortLocation
                          ref={this.addressRef}
                          onChange={this.handleAddressChange.bind(this)}
                          data={this.state.address}
                          action="edit"
                        />
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </CardBody>
            <CardFooter>
              <div style={{ textAlign: 'center', padding: '0em 0em 1em' }}>
                <SaveButton type="submit" onClick={(e) => this.updateIncidentInBackend(e)} />
                &nbsp;
                <CancelIconButton type="button" onClick={(e) => this.editCancel(e, this.state.id)} />
              </div>
            </CardFooter>
          </Card>
        </Col>
      </>
    );
  }

  printIncident() {
    let taskNotes = [];
    this.state.incidentNoteList.forEach((note, index) => {
      if ('delete' !== note.operation) {
        taskNotes.unshift(
          <Col md={6} style={{ marginTop: '1em' }}>
            <IncidentNote
              ref={this.incidentNoteRefList[index]}
              key={index}
              data={note}
              srno={index}
              getCreatePermission={this.state.getIncidentNoteCreatePermission}
              getDeletePermission={this.state.getIncidentNoteDeletePermission}
              getUpdatePermission={this.state.getIncidentNoteUpdatePermission}
              action={note.action ? note.action : 'view'}
              noteId={this.state.noteId}
              pageId={this.state.pageId}
              isPrintView="view_print"
              onNoteEdit={this.editNote.bind(this, note)}
              onNoteDelete={this.deleteIncidentNote.bind(this, note)}
              onNoteUpdate={this.updateIncidentNote.bind(this, note, index)}
              addNote={this.addIncidentNotesInBackend.bind(this)}
              onDelete={this.incidentNoteRemove.bind(this)}
            />
          </Col>
        );
      }
    });
    return (
      <>
        {this.state.isPrintView === 'view_print' ? (
          <>
            <Row md={12} className={this.state.headerClassName}>
              <Col md={4}>
                <h5 style={{ textTransform: 'capitalize' }}>
                  <b>{this.title + ' For ' + this.memberName}</b>
                </h5>
              </Col>
              <Col md={8} style={{ textTransform: 'capitalize' }}></Col>
            </Row>
          </>
        ) : (
          <MenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            name={'Print Preview of Incident Of' + ' ' + this.memberName}
            domainObject="Incident"
            backCallback={this.cancelPrintView.bind(this)}
          />
        )}
        {this.state.isPrintView === 'view_print' ? (
          <></>
        ) : (
          <Row>
            <Col md={12}>
              <ReactToPrint
                trigger={() => (
                  <span style={{ cursor: 'pointer', fontSize: 'x-larger', marginRight: '2em', float: 'right' }}>
                    <Tooltip title="Print">
                      <Button
                        className="add"
                        style={{
                          background: '#867e7e',
                          color: 'white',
                          fontSize: 'x-large',
                          textTransform: 'capitalize',
                          cursor: 'pointer',
                          fontSize: 'x-larger',
                          float: 'right',
                          margin: '0.2em 0.5em 0.3em 0em',
                        }}
                        startIcon={<PrintRoundedIcon />}
                      >
                        Print
                      </Button>
                    </Tooltip>
                  </span>
                )}
                content={() => this.componentRef}
              />
            </Col>
          </Row>
        )}
        <div id="printform" style={{ overflowY: 'scroll', margin: '1em' }} ref={(el) => (this.componentRef = el)}>
          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={12}>
              {this.state.isEditButtonHide && (
                <Row>
                  <Col md={6}>
                    <Card style={{ borderRadius: '20px', height: '100%' }}>
                      <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                        <Row style={{ position: 'relative', marginTop: '0.5em' }}>
                          <Col md={12}>
                            {' '}
                            <label style={{ color: '#000' }}>{'Incident of ' + this.memberName}</label>
                          </Col>
                        </Row>
                        <Row>
                          <Col md={12}>
                            <label style={{ color: '#EC7063' }}>{this.state.incidentTitle}</label>
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md={12}>
                            <Row style={{ padding: '5px 0px' }}>
                              <Col md={3}>
                                <span style={{ color: '#000' }}>
                                  <strong>Date</strong>
                                </span>
                              </Col>
                              <Col md={8}>
                                <span style={{ color: '#000' }}>{this.state.incidentStartDate}</span>
                              </Col>
                            </Row>
                            <Row style={{ padding: '5px 0px' }}>
                              <Col md={3}>
                                <span style={{ color: '#000' }}>
                                  <strong>Type</strong>
                                </span>
                              </Col>
                              <Col md={8}>
                                <span style={{ color: '#000' }}>{this.state.viewIncidentType}</span>
                              </Col>
                            </Row>
                            <Row style={{ padding: '5px 0px' }}>
                              <Col md={3}>
                                <span style={{ color: '#000' }}>
                                  <strong>Description</strong>
                                </span>
                              </Col>
                              <Col md={8}>
                                <span style={{ color: '#000' }}>
                                  <pre className="view_incidentNote" style={{ marginLeft: '-1.9px' }}>
                                    {this.state.note}
                                  </pre>
                                </span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={6}>
                    <Card style={{ borderRadius: '20px', height: '100%' }}>
                      <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                        <Row>
                          <Col md={10}>
                            <Label style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}>
                              Location Details
                            </Label>
                          </Col>
                          <Col md={2}></Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md={12}>
                            <ShortLocation action="view" pageId="incident" data={this.state.address} />
                            {/* <Location pageId="incident" action="view" data={this.state.locationDetails} /> */}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              )}
            </Col>
            {this.state.showIncidentNotePermission === true ? (
              <>
                <Col md={12} style={{ marginTop: '1em' }}>
                  <Card style={{ borderRadius: '20px', height: ' 100%' }}>
                    <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                      <Row>
                        <Col md={12}>
                          <Label
                            style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}
                            className="noteTitle"
                          >
                            Notes
                          </Label>
                        </Col>
                      </Row>
                    </CardHeader>
                    {this.state.noteListRow && (
                      <CardBody>
                        <Row>{taskNotes}</Row>
                      </CardBody>
                    )}
                  </Card>
                </Col>
              </>
            ) : (
              <></>
            )}
          </Row>
        </div>
      </>
    );
  }
}
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#008181',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: 'white',
        color: '#008181',
      },
    },
  },
});
