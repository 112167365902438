import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import AccessAlarmTwoToneIcon from '@material-ui/icons/AccessAlarmTwoTone';
import ChatIcon from '@material-ui/icons/Chat';
import CloseIcon from '@material-ui/icons/Close';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import MaterialTable from '@material-table/core';
import React, { Component } from 'react';
import { Container } from 'react-floating-action-button';
import { Card, CardHeader, Col, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import UiUtils from '../../../provider/UiUtils';
import Utils from '../../../provider/Utils';
import applicationStateAction from '../../../redux/applicationState/applicationStateAction';
import fccPageStateAction from '../../../redux/fccPageState/fccPageStateAction';

import helpDataAction from '../../../redux/help/displayHelpAction';
import store from '../../../redux/store';
import Advertisement from '../advertisement/Advertisement';
import AddIconButton from '../buttons/AddIconButton';
import FccAdvertisement from '../fccAdvertisement/FccAdvertisement';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
import SponsorShipTemplate from '../sponsorShipTemplate/SponsorShipTemplate';
import './MyPurchasesList.scss';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import PtsAlert from '../ptsAlert/PtsAlert';
import BreadCrumbDetailsAction from '../../../redux/breadCrumbDetails/BreadCrumbDetailsAction';
class MyPurchasesList extends Component {
  title = 'Purchase List';
  header = store.getState().header.header;
  memberDetails = store.getState().memberData.memberData;
  memberName = store.getState().memberData.memberData.firstname + ' ' + store.getState().memberData.memberData.lastname;
  purchaseState = store.getState().applicationState.purchaseState;
  isFreeUser = store.getState().userData.userData.isFreeUser;
  sponsorUser = store.getState().Sponsorship.Sponsorship;
  displayHelp = store.getState().help.help;
  yearListArray = store.getState().staticData.yearList;
  memberHomeUrl = store.getState().memberHomeUrl.memberHomeUrl.homeUrl;
  breadCrumb = [
    { pageid: this.memberHomeUrl, uiname: this.memberName },
    { pageid: '/memberPurchase', uiname: 'Purchases List' },
  ];
  monthList = [
    { month: 'All' },
    { month: 'January' },
    { month: 'February' },
    { month: 'March' },
    { month: 'April' },
    { month: 'May' },
    { month: 'June' },
    { month: 'July' },
    { month: 'August' },
    { month: 'September' },
    { month: 'October' },
    { month: 'November' },
    { month: 'December' },
  ];
  constructor(props) {
    super(props);
    this.yearListArray.unshift({ name: 'All', id: 'All' });
    let yearListArray1 = this.yearListArray.reduce((unique, o) => {
      if (!unique.some((obj) => obj.name === o.name && obj.id === o.id)) {
        unique.push(o);
      }
      return unique;
    }, []);
    let calendarState = {};
    store.dispatch(
      applicationStateAction.setApplicationState({
        calendarState,
      })
    );
    let purchaseState = {};
    if (this.purchaseState) {
      purchaseState = Object.getOwnPropertyNames(this.purchaseState);
    }

    store.dispatch(fccPageStateAction.setFccPageState({}));
    /*current URL path for sponsorship template*/
    let currentLocationPath1 = window.location.href;
    let pathArray = currentLocationPath1.split('/');
    let host = pathArray[4];
    let currentLocationPath = '/' + host;
    if (this.breadCrumb) {
      Utils.getActiveMenu(this.breadCrumb);
      store.dispatch(BreadCrumbDetailsAction.setBreadCrumbDetails(this.breadCrumb));
    }
    if (purchaseState.length > 0) {
      this.state = {
        purchaseList: [],
        pageSize: this.purchaseState.pageSize && this.purchaseState.pageSize ? this.purchaseState.pageSize : 10,
        isPaging: false,
        render: false,
        initialPage:
          this.purchaseState.initialPage && this.purchaseState.initialPage > 0 ? this.purchaseState.initialPage : 0,
        purchaseId: this.purchaseState.purchaseId,
        getAllPermission: false,
        getCreatePermission: false,
        getReadPermission: false,
        isFreeUser: this.isFreeUser,
        sponsorUser: this.sponsorUser,
        currentLocationPath: currentLocationPath,
        stepsEnabled: false,
        initialStep: 0,
        steps: [
          {
            element: '.newPurchase',
            intro: 'Add New Purchase by filling in details. ',
          },
          {
            element: '.MuiTableRow-hover',
            intro: 'Click any row from the list to view/update details of Purchase.',
          },

          {
            element: '.purchaseAction',
            intro:
              '<b>Messages</b> – Messages related to this Purchase.<br> <b>Reminders</b> – Reminders for this purchase. You can set future reminders here.',
          },
        ],
        displayFloatButton: this.displayHelp,
        yearList: yearListArray1,
        year: yearListArray1[0],
        month: this.monthList[0],
      };
    } else {
      this.state = {
        purchaseList: [],
        pageSize: 10,
        isPaging: false,
        render: false,
        initialPage: 0,
        purchaseId: '',
        getAllPermission: false,
        getCreatePermission: false,
        getReadPermission: false,
        isFreeUser: this.isFreeUser,
        sponsorUser: this.sponsorUser,
        currentLocationPath: currentLocationPath,
        stepsEnabled: false,
        initialStep: 0,
        steps: [
          {
            element: '.newPurchase',
            intro: 'Add New Purchase by filling in details. ',
          },
          {
            element: '.MuiTableRow-hover',
            intro: 'Click any row from the list to view/update details of Purchase.',
          },
          {
            element: '.purchaseAction',
            intro:
              'Messages – Messages related to this Purchase.<br> Reminders – Reminders for this purchase. You can set future reminders here.',
          },
        ],
        displayFloatButton: this.displayHelp,
        yearList: yearListArray1,
        year: yearListArray1[0],
        month: this.monthList[0],
      };
    }
  }

  closeFloatingButton() {
    this.setState({ displayFloatButton: false });
    let displayFloat = false;

    store.dispatch(helpDataAction.setHelpData(displayFloat));
  }

  componentDidMount() {
    const postObject = {
      header: this.header,
      purchase: {
        member: {
          id: this.memberDetails.member,
        },
      },
    };

    FetchServerData.callPostService('purchasems/getFilteredList', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let purchaseListData = output.data.list;

        for (let i = 0; i < purchaseListData.length; i++) {
          purchaseListData[i].sortDate = purchaseListData[i].date;
          purchaseListData[i].date = Utils.convertUTCDateToUserTimeZoneViewDateFormate(purchaseListData[i].date);
        }

        let getDomainObjectPermission = output.data.domainObjectPrivileges;

        let getCreatePermission = false;

        for (let domainObjectKey in getDomainObjectPermission) {
          if (domainObjectKey.toLowerCase() === 'purchase') {
            let incidentPermission = getDomainObjectPermission[domainObjectKey];
            let getPermission = Utils.getDomainObjectRolePermission(incidentPermission);

            getCreatePermission = getPermission[0];
          }
        }
        let isPaging = false;
        if (purchaseListData.length > 10) {
          isPaging = true;
        }
        this.setState({
          isPaging: isPaging,
          purchaseList: purchaseListData,
          render: true,

          getCreatePermission: getCreatePermission,
        });
      } else {
      }
    });
  }

  addPurchase = (e) => {
    let fccPageState = {
      purchaseId: '',
      action: 'create',
      title: 'Purchase Details',
      pageId: 'add_purchase',
    };
    store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
    this.props.history.push({
      pathname: '/addPurchase',
      state: { action: 'create', title: 'Purchase Details ', pageId: 'add_purchase', purchaseId: '' },
    });
  };

  toPurchaseDetails = (e, data) => {
    let purchaseState = {
      purchaseId: data.id,
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
    };
    store.dispatch(
      applicationStateAction.setApplicationState({
        purchaseState,
      })
    );
    let fccPageState = {
      id: data.id,
      action: 'view',
    };
    store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
    this.props.history.push({
      pathname: '/viewPurchase',
      state: { action: 'view', title: 'Purchase Details', purchaseId: data.id, pageId: 'view_purchase' },
    });
  };

  onChangePage(page) {
    this.setState({
      initialPage: page,
    });
    let purchaseState = {
      initialPage: page,
      purchaseId: this.state.purchaseId,
    };
    store.dispatch(
      applicationStateAction.setApplicationState({
        purchaseState,
      })
    );
  }

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };

  toggleSteps = () => {
    this.setState((prevState) => ({ stepsEnabled: !prevState.stepsEnabled }));
  };

  displayActionTitle() {
    return <h5 className="purchaseAction">Action</h5>;
  }

  toReminder(data) {
    this.props.history.push({
      pathname: '/viewPurchase',
      state: { action: 'view', title: 'Purchase Details', purchaseId: data.id, reminderId: '#reminderSection' },
    });
  }
  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }
  handleMonthChange(selectedMonth) {
    this.state.month = selectedMonth;
    this.setState({
      month: selectedMonth,
    });
    if (undefined !== this.state.onChange && null !== this.state.onChange) {
      this.state.onChange(this.state);
    }
  }

  handleYearChange(selectedYear) {
    this.state.year = selectedYear;
    this.setState({
      year: selectedYear,
    });
    if (undefined !== this.state.onChange && null !== this.state.onChange) {
      this.state.onChange(this.state);
    }
  }
  resetData() {
    this.setState({
      selectedTab: this.state.selectedTab,
      year: this.state.yearList[0],
      month: this.monthList[0],
    });

    let eventState = {};
    store.dispatch(applicationStateAction.setApplicationState({ eventState }));
    this.resetFilter();
  }

  filterDataInBackend() {
    let selectedMonth = '';
    let selectedYear = '';

    if (this.state.month === null) {
      PtsAlert.error('Please Select Specific Month');
    } else if (this.state.year === null) {
      PtsAlert.error('Please Select Specific Year');
    } else {
      if (this.state.month !== null && this.state.month !== undefined) {
        if (Object.keys(this.state.month).length === 0 && this.state.month.constructor === Object) {
          selectedMonth = 'All';
        } else {
          selectedMonth = this.state.month.month;
        }
      } else {
        selectedMonth = 'All';
      }

      if (this.state.year !== null && this.state.year !== undefined) {
        if (Object.keys(this.state.year).length === 0 && this.state.year.constructor === Object) {
          selectedYear = 'All';
        } else {
          selectedYear = this.state.year.name;
        }
      } else {
        selectedYear = 'All';
      }

      const postObject = {
        header: this.header,

        month: selectedMonth,
        year: selectedYear,
        purchase: {
          member: {
            id: this.memberDetails.member,
          },
        },
      };
      FetchServerData.callPostService('purchasems/getFilteredList', postObject).then((output) => {
        if (output.status === 'SUCCESS') {
          let purchaseListData = output.data.list;
          for (let i = 0; i < purchaseListData.length; i++) {
            purchaseListData[i].sortDate = purchaseListData[i].date;
            purchaseListData[i].date = Utils.convertUTCDateToUserTimeZoneViewDateFormate(purchaseListData[i].date);
          }

          let getDomainObjectPermission = output.data.domainObjectPrivileges;
          let getCreatePermission = false;

          for (let domainObjectKey in getDomainObjectPermission) {
            if (domainObjectKey.toLowerCase() === 'purchase') {
              let incidentPermission = getDomainObjectPermission[domainObjectKey];
              let getPermission = Utils.getDomainObjectRolePermission(incidentPermission);
              getCreatePermission = getPermission[0];
            }
          }
          let isPaging = false;
          if (purchaseListData.length > 10) {
            isPaging = true;
          }
          this.setState({
            isPaging: isPaging,
            purchaseList: purchaseListData,
            render: true,
            getCreatePermission: getCreatePermission,
          });
        } else {
        }
      });
    }
  }
  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <MenuWithBreadScrum breadCrumb={this.breadCrumb} history={this.props.history} name={this.title} />
          <Row style={{ margin: '1em' }}>
            <Advertisement pageId="Purchases" />
          </Row>

          <Steps
            enabled={this.state.stepsEnabled}
            steps={this.state.steps}
            initialStep={this.state.initialStep}
            onExit={this.onExit}
          />
          <div className="appointmentList_filter">
            <Card style={{ background: '#fff', margin: '1.2em' }}>
              <CardHeader style={{ padding: '0em 1.2em' }}>
                <Row md={12} style={{ padding: '10px' }}>
                  <Col md={3}>
                    <Autocomplete
                      id="month"
                      options={this.monthList}
                      value={this.state.month}
                      getOptionLabel={(option) => option.month}
                      onChange={(event, value) => {
                        this.handleMonthChange(value);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Month" margin="normal" />
                      )}
                    />
                  </Col>

                  <Col md={3}>
                    <Autocomplete
                      id="year"
                      options={null === this.state.yearList ? [] : this.state.yearList}
                      value={this.state.year}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        this.handleYearChange(value);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Year" margin="normal" />
                      )}
                    />
                  </Col>

                  <Col md={2} className="filter_btn">
                    <Button
                      variant="contained"
                      size="small"
                      className="filterData"
                      startIcon={
                        <span>
                          <i className="fa fa-filter" aria-hidden="true"></i>
                        </span>
                      }
                      style={{
                        background: '#ef6603',
                        color: '#fff',
                        marginTop: '18px',
                        textTransform: 'capitalize',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => this.filterDataInBackend()}
                    >
                      Filter
                    </Button>
                    &nbsp;
                    <Button
                      variant="contained"
                      size="small"
                      className="resetData"
                      startIcon={
                        <span>
                          <i className="fa fa-refresh" aria-hidden="true"></i>
                        </span>
                      }
                      style={{
                        background: '#000',
                        color: '#fff',
                        marginTop: '18px',

                        textTransform: 'capitalize',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => this.resetData()}
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
            </Card>
          </div>
          <div style={{ padding: '1em' }} className="purchase_List">
            <MaterialTable
              icons={UiUtils.getMaterialTableIcons()}
              title=""
              columns={[
                { title: '#', render: (rowData) => rowData.tableData.index + 1, width: '5%' },
                {
                  title: 'Date',
                  type: 'date',
                  field: 'date',
                  filtering: false,
                  customSort: (a, b) => {
                    let aTime = a.sortDate !== null ? new Date(a.sortDate).getTime() : '';
                    let bTime = b.sortDate !== null ? new Date(b.sortDate).getTime() : '';
                    return aTime - bTime;
                  },

                  align: 'left',
                  width: '30%',
                },
                { title: 'Type', field: 'type', align: 'left', width: '35%' },

                { title: 'Prescribed By', field: 'doctor', align: 'left', width: '30%' },
              ]}
              data={this.state.purchaseList}
              options={{
                sorting: true,
                actionsColumnIndex: -1,
                pageSize: this.state.pageSize,

                headerStyle: { backgroundColor: '#1ABC9C', fontWeight: 'bold', color: '#fff', align: 'left' },
                pageSizeOptions: [5, 10, 20, 50, 100],
                filtering: true,
                // paging: this.state.isPaging,
                paging: this.state.purchaseList.length > this.state.pageSize,
                initialPage: this.state.initialPage,
                rowStyle: (rowData) => ({
                  height: '3em',
                  backgroundColor: rowData.id === this.state.purchaseId ? '#FEF5E7' : '#fff',
                }),
              }}
              localization={{
                header: {
                  actions: this.displayActionTitle(),
                },
                body: {
                  emptyDataSourceMessage: Utils.communizeTableText(),
                },
              }}
              actions={[
                {
                  icon: () =>
                    this.state.getCreatePermission === true ? (
                      <span className="newPurchase">
                        {' '}
                        <AddIconButton btnName="Add Purchase" />
                      </span>
                    ) : (
                      ''
                    ),
                  tooltip: 'Add Purchase',
                  isFreeAction: true,
                  onClick: (event) => this.addPurchase(),
                },

                (rowData) => ({
                  icon: () =>
                    /*if Purchase  has Message Threads then show then button */
                    rowData.hasNotes === true ? (
                      <Badge badgeContent={rowData.unreadMessageCount} color="primary" overlap="rectangular">
                        <ChatIcon className="message" style={{ fontSize: '25px', color: '#58D68D' }} />
                      </Badge>
                    ) : (
                      <div style={{ marginLeft: '1em' }}></div>
                    ),

                  tooltip: 'Message',
                }),

                (rowData) => ({
                  disableClick: true,
                  icon: () =>
                    /*if Purchase has Reminders then show then button */
                    rowData.hasReminders === true ? (
                      <div style={{ marginLeft: '0em' }}>
                        <Badge
                          badgeContent={rowData.activeReminderCount}
                          color="primary"
                          overlap="rectangular"
                          className="reminderBadge"
                        >
                          <AccessAlarmTwoToneIcon
                            className="reminder"
                            style={{ fontSize: '25px', color: '#EF797D', marginRight: '0.2em' }}
                          />
                        </Badge>
                      </div>
                    ) : (
                      // </a>
                      <div style={{ marginLeft: '1em' }}></div>
                    ),
                  tooltip: 'Reminder',
                }),
              ]}
              onRowClick={(event, rowData) => {
                this.toPurchaseDetails(event, rowData);
                event.stopPropagation();
              }}
              onPageChange={(page, pageSize) => {
                this.handleMaterialTablePageChange(page, pageSize);
              }}
            />
          </div>
        </>
      );
    }
  }
}

export default MyPurchasesList;
