import Badge from '@material-ui/core/Badge';
import ChatIcon from '@material-ui/icons/Chat';
import 'intro.js/introjs.css';
import MaterialTable from '@material-table/core';
import React, { Component } from 'react';
import Avatar from 'react-avatar';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Row, Col } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import UiUtils from '../../../provider/UiUtils';
import Utils from '../../../provider/Utils';
import applicationStateAction from '../../../redux/applicationState/applicationStateAction';
import fccPageStateAction from '../../../redux/fccPageState/fccPageStateAction';
import helpDataAction from '../../../redux/help/displayHelpAction';
import store from '../../../redux/store';
import Advertisement from '../advertisement/Advertisement';
import AddIconButton from '../buttons/AddIconButton';
import FccAdvertisement from '../fccAdvertisement/FccAdvertisement';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
import SponsorShipTemplate from '../sponsorShipTemplate/SponsorShipTemplate';
import './MemberCommunicationList.scss';
import memberListAction from '../../../redux/memberList/memberListAction';
import BreadCrumbDetailsAction from '../../../redux/breadCrumbDetails/BreadCrumbDetailsAction';
export class MemberCommunicationList extends Component {
  title = 'Communication List';
  header = store.getState().header.header;
  memberDetails = store.getState().memberData.memberData;
  memberName = store.getState().memberData.memberData.firstname + ' ' + store.getState().memberData.memberData.lastname;
  memberCommunicationState = store.getState().applicationState.memberCommunicationState;
  isFreeUser = store.getState().userData.userData.isFreeUser;
  sponsorUser = store.getState().Sponsorship.Sponsorship;
  memberHomeUrl = store.getState().memberHomeUrl.memberHomeUrl.homeUrl;
  displayHelp = store.getState().help.help;
  memberNamesList = store.getState().memberList.memberList;
  breadCrumb = [
    { pageid: this.memberHomeUrl, uiname: this.memberName },
    { pageid: '/memberCommunication', uiname: 'Communication List' },
  ];
  constructor(props) {
    super(props);
    let calendarState = {};

    store.dispatch(
      applicationStateAction.setApplicationState({
        calendarState,
      })
    );
    let memberCommunicationState = {};
    if (this.memberCommunicationState) {
      memberCommunicationState = Object.getOwnPropertyNames(this.memberCommunicationState);
    }

    /*current URL path for sponsorship template*/
    let currentLocationPath1 = window.location.href;
    let pathArray = currentLocationPath1.split('/');
    let host = pathArray[4];
    let currentLocationPath = '/' + host;

    if (memberCommunicationState.length > 0) {
      this.state = {
        communicationList: [],
        appointmentCommunicationList: [],
        incidentCommunicationList: [],
        generalCommunicationList: [],
        purchaseCommunicationList: [],
        selectedTab: this.memberCommunicationState.selectedTab,
        initialPage:
          this.memberCommunicationState.initialPage && this.memberCommunicationState.initialPage > 0
            ? this.memberCommunicationState.initialPage
            : 0,
        threadId: this.memberCommunicationState.threadId,
        getCreatePermission: false,
        render: false,
        isFreeUser: this.isFreeUser,
        sponsorUser: this.sponsorUser,
        currentLocationPath: currentLocationPath,
        stepsEnabled: false,
        initialStep: 0,

        displayFloatButton: this.displayHelp,
        isAppointmentListPaging: false,
        isIncidentListPaging: false,
        isGeneralListPaging: false,
        isPurchaseListPaging: false,
        isEventListPaging: false,
        isCommunicationPaging: false,
        pageSize:
          this.memberCommunicationState.pageSize && this.memberCommunicationState.pageSize
            ? this.memberCommunicationState.pageSize
            : 10,
        getAppointmentCreatePermission: false,
        getAppointmentAllPermission: false,
        showIncidentPermission: false,
        getIncidentCreatePermission: false,
        getIncidentAllPermission: false,
        showEventPermission: false,
        getEventCreatePermission: false,
        getEventAllPermission: false,
        showAppointmentPermission: false,
      };
      this.getCommunicationList();
    } else {
      this.state = {
        communicationList: [],
        appointmentCommunicationList: [],
        incidentCommunicationList: [],
        generalCommunicationList: [],
        purchaseCommunicationList: [],
        selectedTab: 0,
        initialPage: 0,
        threadId: '',
        getCreatePermission: false,
        render: false,
        isFreeUser: this.isFreeUser,
        sponsorUser: this.sponsorUser,
        currentLocationPath: currentLocationPath,
        stepsEnabled: false,
        initialStep: 0,

        displayFloatButton: this.displayHelp,
        isAppointmentListPaging: false,
        isIncidentListPaging: false,
        isGeneralListPaging: false,
        isPurchaseListPaging: false,
        isEventListPaging: false,
        isCommunicationPaging: false,
        pageSize: 10,
        getAppointmentCreatePermission: false,
        getAppointmentAllPermission: false,
        showIncidentPermission: false,
        getIncidentCreatePermission: false,
        getIncidentAllPermission: false,
        showEventPermission: false,
        getEventCreatePermission: false,
        showAppointmentPermission: false,
        showPurchasePermission: false,
        getPurchaseCreatePermission: false,
        getPurchaseAllPermission: false,
      };
      this.getCommunicationList();
    }
  }

  getCommunicationList() {
    const postObject = {
      header: this.header,
      member: {
        id: this.memberDetails.member,
      },
    };

    FetchServerData.callPostService('messageThreadms/getMemberThreads', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let communicationData = output.data.threadList;
        // let getPermission = Utils.getRolePermission(output.data.privileges);
        for (let i = 0; i < communicationData.length; i++) {
          communicationData[i].sortDate = communicationData[i].updatedOn;
          communicationData[i].updatedOn = Utils.convertUTCDateToUserTimeZoneViewDateFormate(
            communicationData[i].updatedOn
          );
        }
        // let getCreatePermission = getPermission[1];
        let getDomainObjectPrivileges = output.data.domainObjectPrivileges;
        // let getAllPermission = false;
        let getCreatePermission = false;

        for (let domainObjectKey in getDomainObjectPrivileges) {
          // let keyPermission = domainObjectKey;
          if (domainObjectKey.toLowerCase() === 'messagethread') {
            let communicationPermission = getDomainObjectPrivileges[domainObjectKey];
            let getPermission = Utils.getDomainObjectRolePermission(communicationPermission);
            getCreatePermission = getPermission[0];
          }
        }
        let appointmentList = [];
        let incidentList = [];
        let generalList = [];
        let purchaseList = [];
        let eventList = [];
        let isAppointmentListPaging = false;
        let isIncidentListPaging = false;
        let isGeneralListPaging = false;
        let isPurchaseListPaging = false;
        let isCommunicationPaging = false;
        let isEventListPaging = false;

        let getDomainObjectPermission = output.data.allowedDomainObjects;
        for (let domainObjectKey in getDomainObjectPermission) {
          // let keyPermission = domainObjectKey;
          if (domainObjectKey === 'Appointment') {
            let appointmentPermission = getDomainObjectPermission[domainObjectKey];
            let getAppointmentPermission = Utils.getRolePermission(appointmentPermission);
            let getAppointmentAllPermission = getAppointmentPermission[0];
            let getAppointmentCreatePermission = getAppointmentPermission[1];
            this.setState({
              showAppointmentPermission: true,
              getAppointmentCreatePermission: getAppointmentCreatePermission,
              getAppointmentAllPermission: getAppointmentAllPermission,
            });
          } else if (domainObjectKey === 'Incident') {
            let incidentPermission = getDomainObjectPermission[domainObjectKey];
            let getIncidentPermission = Utils.getRolePermission(incidentPermission);
            let getIncidentAllPermission = getIncidentPermission[0];
            let getIncidentCreatePermission = getIncidentPermission[1];
            this.setState({
              showIncidentPermission: true,
              getIncidentCreatePermission: getIncidentCreatePermission,
              getIncidentAllPermission: getIncidentAllPermission,
            });
          } else if (domainObjectKey === 'Event') {
            let eventPermission = getDomainObjectPermission[domainObjectKey];
            let getEventPermission = Utils.getRolePermission(eventPermission);
            let getEventAllPermission = getEventPermission[0];
            let getEventCreatePermission = getEventPermission[1];
            this.setState({
              showEventPermission: true,
              getEventCreatePermission: getEventCreatePermission,
              getEventAllPermission: getEventAllPermission,
            });
          } else if (domainObjectKey === 'Event') {
            let eventPermission = getDomainObjectPermission[domainObjectKey];
            let getEventPermission = Utils.getRolePermission(eventPermission);
            let getEventAllPermission = getEventPermission[0];
            let getEventCreatePermission = getEventPermission[1];
            this.setState({
              showEventPermission: true,
              getEventCreatePermission: getEventCreatePermission,
              getEventAllPermission: getEventAllPermission,
            });
          } else if (domainObjectKey === 'Purchase') {
            let purchasePermission = getDomainObjectPermission[domainObjectKey];
            let getPurchaseThreadPermission = Utils.getRolePermission(purchasePermission);
            let getPurchaseAllPermission = getPurchaseThreadPermission[0];
            let getPurchaseCreatePermission = getPurchaseThreadPermission[1];
            this.setState({
              showPurchasePermission: true,
              getPurchaseCreatePermission: getPurchaseCreatePermission,
              getPurchaseAllPermission: getPurchaseAllPermission,
            });
          }
        }
        communicationData.sort(function compare(a, b) {
          var dateA = new Date(a.updatedOn);
          var dateB = new Date(b.updatedOn);
          return dateB - dateA;
        });
        for (let i = 0; i < communicationData.length; i++) {
          if (communicationData[i].type.type === 'Appointment') {
            appointmentList.push(communicationData[i]);
          } else if (communicationData[i].type.type === 'Incident') {
            incidentList.push(communicationData[i]);
          } else if (communicationData[i].type.type === 'General') {
            generalList.push(communicationData[i]);
          } else if (communicationData[i].type.type === 'Purchase') {
            purchaseList.push(communicationData[i]);
          } else if (communicationData[i].type.type === 'Event') {
            eventList.push(communicationData[i]);
          }
        }
        for (let i = 0; i < appointmentList.length; i++) {
          appointmentList[i].rowNum1 = i + 1;
        }
        for (let i = 0; i < incidentList.length; i++) {
          incidentList[i].rowNum2 = i + 1;
        }
        for (let i = 0; i < generalList.length; i++) {
          generalList[i].rowNum3 = i + 1;
        }
        for (let i = 0; i < purchaseList.length; i++) {
          purchaseList[i].rowNum4 = i + 1;
        }
        for (let i = 0; i < eventList.length; i++) {
          eventList[i].rowNum4 = i + 1;
        }
        for (let i = 0; i < communicationData.length; i++) {
          communicationData[i].rowNum = i + 1;
        }
        this.appointmentList = appointmentList;
        this.incidentList = incidentList;
        this.generalList = generalList;
        this.purchaseList = purchaseList;
        this.eventList = eventList;
        this.communicationData = communicationData;

        if (appointmentList.length > 10) {
          isAppointmentListPaging = true;
        }
        if (incidentList.length > 10) {
          isIncidentListPaging = true;
        }
        if (generalList.length > 10) {
          isGeneralListPaging = true;
        }
        if (purchaseList.length > 10) {
          isPurchaseListPaging = true;
        }
        if (eventList.length > 10) {
          isEventListPaging = true;
        }
        if (communicationData.length > 10) {
          isCommunicationPaging = true;
        }

        this.setState({
          getCreatePermission: getCreatePermission,
          isAppointmentListPaging: isAppointmentListPaging,
          isIncidentListPaging: isIncidentListPaging,
          isGeneralListPaging: isGeneralListPaging,
          isPurchaseListPaging: isPurchaseListPaging,
          isEventListPaging: isEventListPaging,
          isCommunicationPaging: isCommunicationPaging,
          render: true,
        });
      } else {
      }
    });
  }

  setKey(tab) {
    this.setState({
      selectedTab: tab,
    });
    let memberCommunicationState = {
      selectedTab: tab,
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
      threadId: this.state.threadId,
    };
    store.dispatch(
      applicationStateAction.setApplicationState({
        memberCommunicationState,
      })
    );
  }
  onChangePage(page) {
    this.setState({
      initialPage: page,
    });
    let memberCommunicationState = {
      selectedTab: this.state.selectedTab,
      initialPage: page,
      threadId: this.state.threadId,
    };
    store.dispatch(
      applicationStateAction.setApplicationState({
        memberCommunicationState,
      })
    );
  }

  addThread(e) {
    let fccPageState = {
      id: '',
      action: 'create',
      title: 'New Communication Thread',
    };
    store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
    this.props.history.push({
      pathname: '/addMessageThread',
      state: { action: 'create', title: 'New Communication Thread', threadId: '' },
    });
  }

  toMessageWindow = (e, data) => {
    let memberCommunicationState = {
      selectedTab: this.state.selectedTab,
      initialPage: this.state.initialPage,
      threadId: data.id,
      pageSize: this.state.pageSize,
    };
    store.dispatch(
      applicationStateAction.setApplicationState({
        memberCommunicationState,
      })
    );
    let fccPageState = {
      id: data.id,
      action: 'view',
      title: 'Communication Details',
    };
    store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
    this.props.history.push({
      pathname: '/viewCommunication',
      state: { action: 'view', threadId: data.id },
    });
  };

  participantList(participant) {
    const colours = ['#F39C12', '#A569BD', '#2ECC71', '#EC7063', '#5DADE2'];
    // let memberNameList = [];
    participant.map((data) => {
      let fullName = data.name;
      if (this.memberNamesList.includes(fullName) === false) {
        this.memberNamesList.push(fullName);
        store.dispatch(memberListAction.setMemberList(this.memberNamesList));
      }
    });

    let participantList = participant.map((member, index) => {
      return <>{Utils.getMemberName(member.name, this.memberNamesList.indexOf(member.name) % 26)}</>;
    });
    return participantList;
  }

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };

  toggleSteps = () => {
    this.setState((prevState) => ({ stepsEnabled: !prevState.stepsEnabled }));
  };
  closeFloatingButton() {
    this.setState({ displayFloatButton: false });
    let displayFloat = false;

    store.dispatch(helpDataAction.setHelpData(displayFloat));
  }
  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }
  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <MenuWithBreadScrum breadCrumb={this.breadCrumb} history={this.props.history} name={this.title} />
          <Row style={{ margin: '1em' }}>
            {' '}
            <Advertisement pageId="Communication" />
          </Row>

          <Row style={{ padding: '1em 2em' }}>
            <Col md={12}>
              <Row style={{ backgroundColor: 'none', margin: '-0.5em -1.3em -2.4em' }}>
                <Col md={12}>
                  {this.state.getAllPermission === true || this.state.getCreatePermission === true ? (
                    <AddIconButton btnName="Add Communication" onClick={(e) => this.addThread(e)} />
                  ) : (
                    ''
                  )}
                </Col>
              </Row>
              <div className="communicationTab" style={{ width: '100%' }}>
                <Tabs defaultIndex={this.state.selectedTab} onSelect={(k) => this.setKey(k)}>
                  <TabList>
                    <Tab>
                      {' '}
                      <span className="tabAll">All</span>
                    </Tab>
                    <Tab>
                      {' '}
                      <span className="tabGeneral">General</span>
                    </Tab>
                    {this.state.showAppointmentPermission === true ? (
                      <Tab>
                        <span className="tabAppointment">Appointment</span>
                      </Tab>
                    ) : (
                      <></>
                    )}
                    {this.state.showIncidentPermission ? (
                      <Tab>
                        <span className="tabIncident">Incident</span>
                      </Tab>
                    ) : (
                      <></>
                    )}
                    {this.state.showPurchasePermission ? (
                      <Tab>
                        <span className="tabPurchase">Purchase</span>
                      </Tab>
                    ) : (
                      <></>
                    )}
                    {this.state.showEventPermission === true ? (
                      <Tab>
                        <span className="tabPurchase">Event</span>
                      </Tab>
                    ) : (
                      <></>
                    )}
                  </TabList>

                  <TabPanel>
                    <MaterialTable
                      onRowClick={(event, rowData) => {
                        this.toMessageWindow(event, rowData);
                        event.stopPropagation();
                      }}
                      icons={UiUtils.getMaterialTableIcons()}
                      title=""
                      localization={{
                        body: {
                          emptyDataSourceMessage: Utils.communizeTableText(),
                        },
                      }}
                      columns={[
                        {
                          title: '#',
                          render: (rowData) => rowData.tableData.index + 1,
                          align: 'left',
                          filtering: false,
                          width: '5%',
                        },
                        {
                          title: 'Date',
                          type: 'date',
                          width: '25%',
                          field: 'updatedOn',
                          filtering: false,
                          customSort: (a, b) => {
                            let aTime = a.sortDate !== null ? new Date(a.sortDate).getTime() : '';
                            let bTime = b.sortDate !== null ? new Date(b.sortDate).getTime() : '';
                            return aTime - bTime;
                          },

                          align: 'left',
                        },

                        {
                          title: 'Title',
                          width: '25%',
                          field: 'subject',
                          cellStyle: {
                            wordBreak: 'break-all',
                          },
                        },
                        { title: 'Type', field: 'type.type', width: '5%' },
                        {
                          title: 'Participant List',
                          render: (rowData) => this.participantList(rowData.participantDetailsList),
                          width: '45%',
                          cellStyle: {
                            wordBreak: 'break-all',
                          },
                        },
                      ]}
                      data={this.communicationData}
                      options={{
                        sorting: true,
                        actionsColumnIndex: -1,
                        pageSize: this.state.pageSize,
                        // paging: this.state.isCommunicationPaging,
                        paging: this.communicationData.length > this.state.pageSize,
                        initialPage: this.state.initialPage,
                        pageSizeOptions: [5, 10, 20, 50, 100],
                        headerStyle: { backgroundColor: '#1ABC9C', fontWeight: 'bold', color: '#fff', align: 'left' },
                        filtering: true,
                        rowStyle: (rowData) => ({
                          backgroundColor: rowData.id === this.state.threadId ? '#FEF5E7' : '#fff',
                          height: '3em',
                        }),
                      }}
                      actions={[
                        (rowData) => ({
                          icon: () => (
                            <Badge badgeContent={rowData.unreadMessageCount} color="primary" overlap="rectangular">
                              <ChatIcon className="message" style={{ color: '#58D68D' }} />
                            </Badge>
                          ),
                          tooltip: 'Message',
                        }),
                      ]}
                      onPageChange={(page, pageSize) => {
                        this.handleMaterialTablePageChange(page, pageSize);
                      }}
                    />
                  </TabPanel>
                  <TabPanel>
                    <MaterialTable
                      onRowClick={(event, rowData) => {
                        this.toMessageWindow(event, rowData);
                        event.stopPropagation();
                      }}
                      icons={UiUtils.getMaterialTableIcons()}
                      title=""
                      localization={{
                        body: {
                          emptyDataSourceMessage: Utils.communizeTableText(),
                        },
                      }}
                      columns={[
                        {
                          title: '#',
                          render: (rowData) => rowData.tableData.index + 1,
                          filtering: false,
                          align: 'left',
                          width: '5%',
                        },
                        {
                          title: 'Date',
                          type: 'date',
                          field: 'updatedOn',
                          filtering: false,
                          customSort: (a, b) => {
                            let aTime = a.sortDate !== null ? new Date(a.sortDate).getTime() : '';
                            let bTime = b.sortDate !== null ? new Date(b.sortDate).getTime() : '';
                            return aTime - bTime;
                          },
                          width: '25%',

                          align: 'left',
                        },
                        {
                          title: 'Title',
                          field: 'subject',
                          width: '25%',
                          cellStyle: {
                            wordBreak: 'break-all',
                          },
                        },
                        { title: 'Type', field: 'type.type', width: '5%' },

                        {
                          title: 'Participant List',
                          render: (rowData) => this.participantList(rowData.participantDetailsList),
                          width: '45%',
                          cellStyle: {
                            wordBreak: 'break-all',
                          },
                        },
                      ]}
                      data={this.generalList}
                      options={{
                        sorting: true,
                        actionsColumnIndex: -1,
                        pageSize: this.state.pageSize,
                        initialPage: this.state.initialPage,
                        pageSizeOptions: [5, 10, 20, 50, 100],
                        // paging: this.state.isGeneralListPaging,
                        paging: this.generalList.length > this.state.pageSize,
                        headerStyle: { backgroundColor: '#1ABC9C', fontWeight: 'bold', color: '#fff', align: 'left' },

                        filtering: true,
                        rowStyle: (rowData) => ({
                          backgroundColor: rowData.id === this.state.threadId ? '#FEF5E7' : '#fff',
                          height: '3em',
                        }),
                      }}
                      actions={[
                        {
                          icon: () => <></>,
                          tooltip: 'New Communication Thread',
                          isFreeAction: true,
                          onClick: (event) => this.addThread(),
                        },
                        (rowData) => ({
                          icon: () => (
                            <Badge badgeContent={rowData.unreadMessageCount} color="primary" overlap="rectangular">
                              <ChatIcon className="message" style={{ color: '#58D68D' }} />
                            </Badge>
                          ),
                          tooltip: 'Message',
                        }),
                      ]}
                      onPageChange={(page, pageSize) => {
                        this.handleMaterialTablePageChange(page, pageSize);
                      }}
                    />
                  </TabPanel>
                  {this.state.showAppointmentPermission === true ? (
                    <TabPanel>
                      <MaterialTable
                        onRowClick={(event, rowData) => {
                          this.toMessageWindow(event, rowData);
                          event.stopPropagation();
                        }}
                        icons={UiUtils.getMaterialTableIcons()}
                        title=""
                        localization={{
                          body: {
                            emptyDataSourceMessage: Utils.communizeTableText(),
                          },
                        }}
                        columns={[
                          {
                            title: '#',
                            render: (rowData) => rowData.tableData.index + 1,
                            filtering: false,
                            align: 'left',
                            width: '5%',
                          },
                          {
                            title: 'Date',
                            type: 'date',
                            field: 'updatedOn',
                            filtering: false,
                            customSort: (a, b) => {
                              let aTime = a.sortDate !== null ? new Date(a.sortDate).getTime() : '';
                              let bTime = b.sortDate !== null ? new Date(b.sortDate).getTime() : '';
                              return aTime - bTime;
                            },
                            width: '25%',
                            align: 'left',
                          },
                          {
                            title: 'Title',
                            field: 'subject',
                            width: '25%',
                            cellStyle: {
                              wordBreak: 'break-all',
                            },
                          },
                          { title: 'Type', field: 'appointmentType.type', width: '5%' },
                          {
                            title: 'Participant List',
                            render: (rowData) => this.participantList(rowData.participantDetailsList),
                            width: '45%',
                            cellStyle: {
                              wordBreak: 'break-all',
                            },
                          },
                        ]}
                        data={this.appointmentList}
                        options={{
                          sorting: true,
                          actionsColumnIndex: -1,
                          pageSize: this.state.pageSize,
                          initialPage: this.state.initialPage,
                          pageSizeOptions: [5, 10, 20, 50, 100],
                          // paging: this.state.isAppointmentListPaging,
                          paging: this.appointmentList.length > this.state.pageSize,
                          headerStyle: { backgroundColor: '#1ABC9C', fontWeight: 'bold', color: '#fff', align: 'left' },
                          filtering: true,
                          rowStyle: (rowData) => ({
                            backgroundColor: rowData.id === this.state.threadId ? '#FEF5E7' : '#fff',
                            height: '3em',
                          }),
                        }}
                        actions={[
                          (rowData) => ({
                            icon: () => (
                              <Badge badgeContent={rowData.unreadMessageCount} color="primary" overlap="rectangular">
                                <ChatIcon className="message" style={{ color: '#58D68D' }} />
                              </Badge>
                            ),
                            tooltip: 'Message',
                          }),
                        ]}
                        onPageChange={(page, pageSize) => {
                          this.handleMaterialTablePageChange(page, pageSize);
                        }}
                      />
                    </TabPanel>
                  ) : (
                    <></>
                  )}
                  {this.state.showIncidentPermission ? (
                    <TabPanel>
                      <MaterialTable
                        onRowClick={(event, rowData) => {
                          this.toMessageWindow(event, rowData);
                          event.stopPropagation();
                        }}
                        icons={UiUtils.getMaterialTableIcons()}
                        title=""
                        localization={{
                          body: {
                            emptyDataSourceMessage: Utils.communizeTableText(),
                          },
                        }}
                        columns={[
                          {
                            title: '#',
                            render: (rowData) => rowData.tableData.index + 1,
                            filtering: false,
                            align: 'left',
                            width: '5%',
                          },
                          {
                            title: 'Date',
                            type: 'date',
                            field: 'updatedOn',
                            filtering: false,
                            customSort: (a, b) => {
                              let aTime = a.sortDate !== null ? new Date(a.sortDate).getTime() : '';
                              let bTime = b.sortDate !== null ? new Date(b.sortDate).getTime() : '';
                              return aTime - bTime;
                            },
                            width: '25%',
                            align: 'left',
                          },
                          {
                            title: 'Title',
                            field: 'subject',
                            width: '25%',
                            cellStyle: {
                              wordBreak: 'break-all',
                            },
                          },
                          { title: 'Type', field: 'type.type', width: '5%' },

                          {
                            title: 'Participant List',
                            render: (rowData) => this.participantList(rowData.participantDetailsList),

                            width: '45%',
                            cellStyle: {
                              wordBreak: 'break-all',
                            },
                          },
                        ]}
                        data={this.incidentList}
                        options={{
                          sorting: true,
                          actionsColumnIndex: -1,
                          pageSize: this.state.pageSize,
                          initialPage: this.state.initialPage,
                          pageSizeOptions: [5, 10, 20, 50, 100],
                          // paging: this.state.isIncidentListPaging,
                          paging: this.incidentList.length > this.state.pageSize,
                          headerStyle: { backgroundColor: '#1ABC9C', fontWeight: 'bold', color: '#fff', align: 'left' },

                          filtering: true,
                          rowStyle: (rowData) => ({
                            backgroundColor: rowData.id === this.state.threadId ? '#FEF5E7' : '#fff',
                            height: '3em',
                          }),
                        }}
                        actions={[
                          (rowData) => ({
                            icon: () => (
                              <Badge badgeContent={rowData.unreadMessageCount} color="primary" overlap="rectangular">
                                <ChatIcon className="message" style={{ color: '#58D68D' }} />
                              </Badge>
                            ),
                            tooltip: 'Message',
                          }),
                        ]}
                        onPageChange={(page, pageSize) => {
                          this.handleMaterialTablePageChange(page, pageSize);
                        }}
                      />
                    </TabPanel>
                  ) : (
                    <></>
                  )}
                  {this.state.showPurchasePermission ? (
                    <TabPanel>
                      <MaterialTable
                        onRowClick={(event, rowData) => {
                          this.toMessageWindow(event, rowData);
                          event.stopPropagation();
                        }}
                        icons={UiUtils.getMaterialTableIcons()}
                        title=""
                        localization={{
                          body: {
                            emptyDataSourceMessage: Utils.communizeTableText(),
                          },
                        }}
                        columns={[
                          {
                            title: '#',
                            render: (rowData) => rowData.tableData.index + 1,
                            filtering: false,
                            align: 'left',
                            width: '5%',
                          },
                          {
                            title: 'Date',
                            type: 'date',
                            field: 'updatedOn',
                            filtering: false,
                            customSort: (a, b) => {
                              let aTime = a.sortDate !== null ? new Date(a.sortDate).getTime() : '';
                              let bTime = b.sortDate !== null ? new Date(b.sortDate).getTime() : '';
                              return aTime - bTime;
                            },
                            width: '25%',

                            align: 'left',
                          },
                          {
                            title: 'Title',
                            field: 'subject',
                            width: '25%',
                            cellStyle: {
                              wordBreak: 'break-all',
                            },
                          },
                          {
                            title: 'Type',
                            field: 'type.type',
                            width: '5%',
                          },

                          {
                            title: 'Participant List',
                            render: (rowData) => this.participantList(rowData.participantDetailsList),
                            width: '45%',
                            cellStyle: {
                              wordBreak: 'break-all',
                            },
                          },
                        ]}
                        data={this.purchaseList}
                        options={{
                          sorting: true,
                          actionsColumnIndex: -1,
                          pageSize: this.state.pageSize,
                          initialPage: this.state.initialPage,
                          pageSizeOptions: [5, 10, 20, 50, 100],
                          // paging: this.state.isPurchaseListPaging,
                          paging: this.purchaseList.length > this.state.pageSize,
                          headerStyle: { backgroundColor: '#1ABC9C', fontWeight: 'bold', color: '#fff', align: 'left' },

                          filtering: true,
                          rowStyle: (rowData) => ({
                            backgroundColor: rowData.id === this.state.threadId ? '#FEF5E7' : '#fff',
                            height: '3em',
                          }),
                        }}
                        actions={[
                          (rowData) => ({
                            icon: () => (
                              <Badge badgeContent={rowData.unreadMessageCount} color="primary" overlap="rectangular">
                                <ChatIcon className="message" style={{ color: '#58D68D' }} />
                              </Badge>
                            ),
                            tooltip: 'Message',
                          }),
                        ]}
                        onPageChange={(page, pageSize) => {
                          this.handleMaterialTablePageChange(page, pageSize);
                        }}
                      />
                    </TabPanel>
                  ) : (
                    <></>
                  )}
                  {this.state.showEventPermission === true ? (
                    <TabPanel>
                      <MaterialTable
                        onRowClick={(event, rowData) => {
                          this.toMessageWindow(event, rowData);
                          event.stopPropagation();
                        }}
                        icons={UiUtils.getMaterialTableIcons()}
                        title=""
                        localization={{
                          body: {
                            emptyDataSourceMessage: Utils.communizeTableText(),
                          },
                        }}
                        columns={[
                          {
                            title: '#',
                            render: (rowData) => rowData.tableData.index + 1,
                            filtering: false,
                            align: 'left',
                            width: '5%',
                          },
                          {
                            title: 'Date',
                            type: 'date',
                            field: 'updatedOn',
                            filtering: false,
                            customSort: (a, b) => {
                              let aTime = a.sortDate !== null ? new Date(a.sortDate).getTime() : '';
                              let bTime = b.sortDate !== null ? new Date(b.sortDate).getTime() : '';
                              return aTime - bTime;
                            },
                            width: '25%',
                            align: 'left',
                          },
                          {
                            title: 'Title',
                            field: 'subject',
                            width: '25%',
                            cellStyle: {
                              wordBreak: 'break-all',
                            },
                          },
                          { title: 'Type', field: 'type.type', width: '5%' },

                          {
                            title: 'Participant List',
                            render: (rowData) => this.participantList(rowData.participantDetailsList),
                            width: '45%',
                            cellStyle: {
                              wordBreak: 'break-all',
                            },
                          },
                        ]}
                        data={this.eventList}
                        options={{
                          sorting: true,
                          actionsColumnIndex: -1,
                          pageSize: this.state.pageSize,
                          initialPage: this.state.initialPage,
                          pageSizeOptions: [5, 10, 20, 50, 100],
                          // paging: this.state.isEventListPaging,
                          headerStyle: { backgroundColor: '#1ABC9C', fontWeight: 'bold', color: '#fff', align: 'left' },
                          paging: this.eventList.length > this.state.pageSize,
                          filtering: true,
                          rowStyle: (rowData) => ({
                            backgroundColor: rowData.id === this.state.threadId ? '#FEF5E7' : '#fff',
                            height: '3em',
                          }),
                        }}
                        actions={[
                          (rowData) => ({
                            icon: () => (
                              <Badge badgeContent={rowData.unreadMessageCount} color="primary" overlap="rectangular">
                                <ChatIcon className="message" style={{ color: '#58D68D' }} />
                              </Badge>
                            ),
                            tooltip: 'Message',
                          }),
                        ]}
                        onPageChange={(page, pageSize) => {
                          this.handleMaterialTablePageChange(page, pageSize);
                        }}
                      />
                    </TabPanel>
                  ) : (
                    <></>
                  )}
                </Tabs>
              </div>
            </Col>
          </Row>
        </>
      );
    }
  }
}

export default MemberCommunicationList;
