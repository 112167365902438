import {
  CLEAR_COMMUNICATION_THREAD,
  GET_COMMUNICATION_THREAD,
  SET_COMMUNICATION_THREAD,
} from './communicationThreadsTypes';

const initialState = {
  messageThreadsData: [],
};

const communicationThreadsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMMUNICATION_THREAD:
      return {
        ...state,
      };
    case SET_COMMUNICATION_THREAD:
      return {
        ...state,
        messageThreadsData: action.payload,
      };

    case CLEAR_COMMUNICATION_THREAD:
      return {
        ...state,
        messageThreadsData: null,
      };
    default:
      return state;
  }
};

export default communicationThreadsReducer;
