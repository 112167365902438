import React, { Component } from 'react';
import './CommercialsTemplate1.scss';
class CommercialsTemplate1 extends Component {
  // sponsorUser = store.getState().Sponsorship.Sponsorship;

  constructor(props) {
    // let pageId = props.pageId;
    let action = props.action;
    let advertisementDetails = props.data;
    super(props);
    this.state = {
      action: action,

      heading: advertisementDetails ? advertisementDetails.template.heading : '',
      subHeading: advertisementDetails ? advertisementDetails.template.subheading : '',
      message: advertisementDetails ? advertisementDetails.template.message : '',
      website: advertisementDetails ? advertisementDetails.template.website : '',
      action: this.props.action,
      onChange: this.props.onChange,
      imagePosition: '',
      leftImageFile: this.props.data ? this.props.data.leftimage : '',
      rightImageFile: this.props.data ? this.props.data.rightimage : '',
      showRemoveButton: false,
      backgroundImageFile: this.props.data ? this.props.data.template.backgroundimage : '',

      render: true,
    };
  }

  render() {
    return (
      <>
        {this.state.backgroundImageFile !== '' ? (
          <>
            <header
              id="Ad01-header"
              style={{
                display: 'block',
                textAlign: 'center',
                color: ' #fff',
                width: '100%',
                backgroundImage: `url(${`data:image/png;base64,${this.state.backgroundImageFile}`})`,
                overflowy: 'scroll',
                overflowx: 'hidden',
                minHeight: '120px',
              }}
              className="templateBackground"
            >
              <h3 id="Ad01-h1"> {this.state.heading}</h3>

              <a
                href={this.props.data.template.website}
                target="_blank"
                style={{
                  textTransform: 'lowercase',
                  cursor: 'pointer',
                }}
              >
                {this.props.data.template.website}
              </a>
            </header>
          </>
        ) : (
          <header id="Ad01-header">
            <h3 id="Ad01-h1"> {this.state.heading}</h3>
            <h4 id="Ad01-h2">{this.state.subHeading}</h4>
            <p id="Ad01-p" style={{ marginBottom: '0em' }}>
              {this.state.message}
            </p>
            <a
              href={
                this.props.data.template.includes('https://') || this.props.data.template.includes('http://')
                  ? this.props.data.template
                  : 'http://' + this.props.data.template
              }
              target="_blank"
              style={{
                textTransform: 'lowercase',
                cursor: 'pointer',
              }}
            >
              {this.props.data.template.website}
            </a>
          </header>
        )}
      </>
    );
  }
}
export default CommercialsTemplate1;
