import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import SaveIcon from '@material-ui/icons/Save';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
class PtsConfirmDialogue extends Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      title: props.title,
      text: props.text,
      confirmCallback: props.confirmCallback,
      notConfirmCallback: props.notConfirmCallback,
      pageId: this.props.pageId,
    };
  }

  handleClickOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleConfirmYes = () => {
    if (this.state.confirmCallback) {
      this.state.confirmCallback();
    } else if (this.props.confirmCallback) {
      this.props.confirmCallback();
    }
    this.setState({ open: false });
  };
  handleConfirmNo = () => {
    this.handleClose();
  };
  render() {
    if (this.state.pageId === 'caregiver') {
      return this.showCaregiverDeleteModal();
    } else {
      return this.showDeleteModal();
    }
  }

  showCaregiverDeleteModal() {
    return (
      <Dialog
        open={this.state.open}
        onClose={this.handleClose.bind(this)}
        // onHide={this.close}
        style={{ padding: '1em' }}
      >
        <DialogContent>
          <Row style={{ marginRight: 'auto', marginLeft: 'auto' }}>
            <Col md={12} style={{ textAlign: 'center' }}>
              <ErrorOutlineIcon
                style={{
                  border: '2px solid #fff',
                  borderRadius: '25%',
                  background: '#066f72 !important',
                  color: 'red',
                  fontSize: '50px',
                }}
              />
            </Col>
          </Row>
          <span style={{ textAlign: 'center' }}>
            <h5>{this.state.text}</h5>
          </span>
        </DialogContent>
        <DialogActions style={{ marginTop: '1em', padding: '2em', marginLeft: 'auto', marginRight: 'auto' }}>
          <Button
            variant="contained"
            size="large"
            style={{ background: '#0f666b', color: '#fff', marginRight: '1rem' }}
            startIcon={<SaveIcon />}
            onClick={this.handleConfirmYes.bind(this)}
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            size="large"
            style={{ background: '#717373', color: '#fff', marginRight: '1rem' }}
            startIcon={<CloseIcon />}
            onClick={this.handleConfirmNo.bind(this)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
  showDeleteModal() {
    return (
      <Dialog
        open={this.state.open}
        onClose={this.handleClose.bind(this)}
        // onHide={this.close}
        style={{ padding: '1em' }}
      >
        <DialogContent>
          <Row style={{ marginRight: 'auto', marginLeft: 'auto' }}>
            <Col md={12} style={{ textAlign: 'center' }}>
              <ErrorOutlineIcon
                style={{
                  border: '2px solid #fff',
                  borderRadius: '25%',
                  background: '#066f72 !important',
                  color: 'red',
                  fontSize: '50px',
                }}
              />
            </Col>
          </Row>
          {this.props.pageId === 'home' ? (
            <h5 style={{ color: '#0f666b', fontWeight: 'bold', fontSize: '25px' }}>{this.state.text}</h5>
          ) : (
            <span style={{ textAlign: 'center' }}>
              <h2 style={{ fontSize: '20px' }}>Are you sure about deleting</h2>

              <h5 style={{ color: '#0f666b', fontWeight: 'bold', fontSize: '25px' }}>{this.state.text + '?'}</h5>

              <h2 style={{ fontSize: '20px' }}>It cannot be recovered once deleted.</h2>
            </span>
          )}
        </DialogContent>
        <DialogActions style={{ marginTop: '1em', padding: '2em', marginLeft: 'auto', marginRight: 'auto' }}>
          <Button
            variant="contained"
            size="large"
            style={{ background: '#0f666b', color: '#fff', marginRight: '1rem' }}
            startIcon={<SaveIcon />}
            onClick={this.handleConfirmYes.bind(this)}
          >
            Confirm
          </Button>
          <Button
            variant="contained"
            size="large"
            style={{ background: '#717373', color: '#fff', marginRight: '1rem' }}
            startIcon={<CloseIcon />}
            onClick={this.handleConfirmNo.bind(this)}
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default PtsConfirmDialogue;
