import AddIconButton from '../../components/buttons/AddIconButton';
import MaterialTable from '@material-table/core';
import FetchServerData from '../../../provider/FetchServerData';
import UiUtils from '../../../provider/UiUtils';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import store from '../../../redux/store';
import adminFccPageStateAction from '../../../redux/adminPanel/adminFccPageState/adminFccPageStateAction';
import AdminMenuWithBreadScrum from '../adminMenu/AdminMenuWithBreadScrum';
import applicationStateAction from '../../../redux/applicationState/applicationStateAction';
import Utils from '../../../provider/Utils';

export class PaidAdvertisementList extends Component {
  header = store.getState().adminHeader.adminHeader;
  // sponsorDetails = store.getState().adminSponsorDetails.adminSponsorDetails;
  paidAdvertiserDetails = store.getState().paidAdvertiserDetails.paidAdvertiserDetails;
  userDetails = store.getState().adminUserDetails.adminUserDetails;
  paidAdvertisementState = store.getState().applicationState.paidAdvertisementState;
  title = 'Commercial List';
  breadCrumb = [
    { pageid: '/viewAdvertiser', uiname: 'Paid Advertiser Home' },
    { pageid: '', uiname: 'Commercial List' },
  ];
  sponsorBreadCrumb = [{ pageid: '/paidAdvertisementList', uiname: 'Commercial List' }];
  paidAdvertisementStateDetails = {};
  constructor(props) {
    super(props);
    let calendarState = {};
    store.dispatch(
      applicationStateAction.setApplicationState({
        calendarState,
      })
    );
    let action = props.action;
    let pageId = '';
    if (
      null !== props.history &&
      props.history !== undefined &&
      props.history.location !== null &&
      props.history.location.state !== null &&
      props.history.location.state !== undefined
    ) {
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
    }
    store.dispatch(adminFccPageStateAction.setAdminFccPageState());
    if (this.paidAdvertisementState) {
      this.paidAdvertisementStateDetails = Object.getOwnPropertyNames(this.paidAdvertisementState);
    }
    let breadCrumb = [];
    if (this.userDetails.isUserLogin === 'paidAdvertiser') {
      breadCrumb = this.sponsorBreadCrumb;
    } else {
      breadCrumb = this.breadCrumb;
    }
    if (this.paidAdvertisementStateDetails.length > 0) {
      this.state = {
        email: '',
        password: '',
        showPassword: false,
        action: action,
        pageId: pageId,
        isPaging: false,
        initialPage:
          this.paidAdvertisementState.initialPage && this.paidAdvertisementState.initialPage > 0
            ? this.paidAdvertisementState.initialPage
            : 0,
        advertisementList: [],
        pageSize:
          this.paidAdvertisementState.pageSize && this.paidAdvertisementState.pageSize
            ? this.paidAdvertisementState.pageSize
            : 10,
        breadCrumb: breadCrumb,
        paidAdvertisementId: this.paidAdvertisementState.paidAdvertisementId,
        render: false,
        getCreatePermission: false,
      };
    } else {
      this.state = {
        email: '',
        password: '',
        showPassword: false,
        action: action,
        pageId: pageId,
        isPaging: false,
        initialPage: 0,
        advertisementList: [],
        pageSize: 10,
        breadCrumb: breadCrumb,
        render: false,
        getCreatePermission: false,
      };
    }

    this.getAdvertisementList();
  }

  getAdvertisementList() {
    const postObject = {
      header: this.header,
      paidAdvertiser: {
        id: this.paidAdvertiserDetails.id,
      },
    };

    FetchServerData.callPostService('/paidadvertiseradsms/getPaidAdvertiserAds', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let advertisementList = output.data;
        let getPermission = Utils.getRolePermission(['*']);
        let getCreatePermission = getPermission[1];
        let isPaging = false;
        if (advertisementList.length > 10) {
          isPaging = true;
        }

        this.setState({
          isPaging: isPaging,
          advertisementList: advertisementList,
          render: true,
          initialPage: this.state.initialPage,
          pageSize: this.state.pageSize,
          getCreatePermission: getCreatePermission,
        });
      } else {
      }
    });
  }

  addAdvertisement(e) {
    let adminFccPageState = {
      advertisementId: '',
      action: 'list',
      title: 'Add Advertisement',
      pageId: 'Advertisement',
    };
    store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));

    this.props.history.push({
      pathname: '/addPaidAdvertisement',
      state: { action: 'list', title: 'Add Sponsor', advertisementId: '' },
    });
  }

  toAdvertisementDetails(e, data) {
    let paidAdvertisementState = {
      paidAdvertisementId: data.id,
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
    };
    store.dispatch(applicationStateAction.setApplicationState({ paidAdvertisementState }));
    let adminFccPageState = {
      id: data.id,
      action: 'view',
      title: 'Advertisement Details',
    };
    store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
    this.props.history.push({
      pathname: '/viewPaidAdvertisement',
      state: {
        action: 'viewTemplate',
        title: 'Sponsor Details',
        advertisementId: data.id,
      },
    });
  }
  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }
  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          {/* <Header /> */}
          {/* Page content */}
          <AdminMenuWithBreadScrum breadCrumb={this.state.breadCrumb} history={this.props.history} name={this.title} />

          <Row style={{ position: 'relative', margin: '1em 0em', maxWidth: '100%' }}>
            <Col md={12}>
              <MaterialTable
                onRowClick={(event, rowData) => {
                  this.toAdvertisementDetails(event, rowData);
                  event.stopPropagation();
                }}
                icons={UiUtils.getMaterialTableIcons()}
                title=""
                localization={{
                  body: {
                    emptyDataSourceMessage: Utils.communizeTableText(),
                  },
                }}
                columns={[
                  {
                    title: '#',
                    render: (rowData) => rowData.tableData.index + 1,

                    width: '5%',

                    align: 'left',
                  },
                  {
                    title: 'Name',
                    field: 'uid',

                    width: '92%',

                    align: 'left',
                    filtering: false,
                  },
                ]}
                data={this.state.advertisementList}
                options={{
                  sorting: true,
                  actionsColumnIndex: -1,
                  initialPage: this.state.initialPage,
                  pageSize: this.state.pageSize,
                  paging: this.state.advertisementList.length > this.state.pageSize,
                  pageSizeOptions: [5, 10, 20, 50, 100],
                  headerStyle: {
                    backgroundColor: '#066f72',
                    fontWeight: 'bold',
                    color: '#fff',
                    align: 'left',
                  },

                  filtering: true,
                  // paging: this.state.isPaging,
                  rowStyle: (rowData) => ({
                    backgroundColor: rowData.id === this.state.paidAdvertisementId ? '#FEF5E7' : '#fff',
                    height: '3em',
                    textTransform: 'capitalize',
                  }),
                  tableLayout: 'auto',
                }}
                actions={[
                  {
                    icon: () => (
                      <span className="newDoctor">
                        {' '}
                        {this.state.getCreatePermission && <AddIconButton btnName="Add Advertisement" />}
                      </span>
                    ),
                    tooltip: 'Add Advertisement',
                    isFreeAction: true,
                    onClick: (event) => this.addAdvertisement(),
                  },
                ]}
                onPageChange={(page, pageSize) => {
                  this.handleMaterialTablePageChange(page, pageSize);
                }}
              />
            </Col>
          </Row>
        </>
      );
    }
  }
}
export default PaidAdvertisementList;
