import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
// import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import store from '../../../redux/store';
import BackButton from '../../components/buttons/BackButton';
import CancelIconButton from '../../components/buttons/CancelIconButton';
import CreateButton from '../../components/buttons/CreateButton';
import EditIconButton from '../../components/buttons/EditIconButton';
import SaveButton from '../../components/buttons/SaveButton';
import PtsAlert from '../../components/ptsAlert/PtsAlert';
import adminFccPageStateAction from '../../../redux/adminPanel/adminFccPageState/adminFccPageStateAction';
import AdminMenuWithBreadScrum from '../adminMenu/AdminMenuWithBreadScrum';
import PageAdvertisementMappingList from '../adminSponsorship/PageAdvertisementMappingList';

class PaidCommercials extends Component {
  title = 'Commercial plan Details';
  addressRef = React.createRef();
  header = store.getState().adminHeader.adminHeader;
  paidAdvertiserDetails = store.getState().paidAdvertiserDetails.paidAdvertiserDetails;
  adminFccPageState = store.getState().adminFccPageState.adminFccPageState;
  userDetails = store.getState().adminUserDetails.adminUserDetails;
  adminBreadCrumb = [
    { pageid: '/viewAdvertiser', uiname: 'Paid Advertiser Home' },
    { pageid: '/paidCommercialsList', uiname: 'Commercial plan List' },
    { pageid: '', uiname: 'Commercial plan Details' },
  ];
  breadCrumb = [
    { pageid: '/paidCommercialsList', uiname: 'Commercial plan List' },
    { pageid: '', uiname: 'Commercial plan Details' },
  ];
  typeListArray = [
    {
      id: 1,
      type: 'Private',
    },
    {
      id: 2,
      type: 'Public',
    },
  ];
  addressRef = {};
  constructor(props) {
    super(props);
    let commercialId = props.commercialId;
    let action = props.action;
    let title = '';
    let pageId = '';
    let commercialsDetails = {};
    if (
      !commercialId &&
      props.history &&
      commercialsDetails &&
      props.history.location &&
      props.history.location.state
    ) {
      commercialId = props.history.location.state.commercialId;
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
      title = props.history.location.state.title;
      commercialsDetails = props.history.location.state.commercialsDetails;
    }
    let canRender = false;
    let breadCrumb = [];
    if (this.userDetails.isUserLogin === 'paidAdvertiser') {
      breadCrumb = this.breadCrumb;
    } else {
      breadCrumb = this.adminBreadCrumb;
    }
    if (null !== commercialId && commercialId > 0) {
      this.getCommercialDetails(commercialId);
    } else {
      canRender = true;
    }

    if (commercialId === undefined) {
      commercialId = this.adminFccPageState.id;
      action = this.adminFccPageState.action;
      title = this.adminFccPageState.title;

      if (action !== 'create') {
        this.getCommercialDetails(commercialId);
      }

      // canRender = true;
    }
    if (pageId === 'createCommercials') {
      let newStartDate = new Date(commercialsDetails.startDate);
      let newEndDate = new Date(commercialsDetails.endDate);
      this.state = {
        id: commercialsDetails.id,
        action: action,
        pageId: pageId,
        startDate: newStartDate,
        endDate: newEndDate,
        viewStartDate: commercialsDetails.startDate,
        viewEndDate: commercialsDetails.endDate,
        // activeUser: commercialsDetails.noOfSubscriptions,
        sponsorName: commercialsDetails.name,
        type: commercialsDetails.sponsorshipType,
        sponsorshipStatus: commercialsDetails.sponsorshipStatus.status,
        // codeprefix: commercialsDetails.codeprefix,
        sponsoredUserList: [],
        pageAdvertisementList: [],
        showEdit: false,
        showView: true,
        allChecked: false,
        selectedTab: '0',
        openEmailModal: false,
        selectedSponsorUserList: [],
        breadCrumb: breadCrumb,
        render: canRender,
        isStatus: true,
        status: {},
      };
    } else {
      this.state = {
        id: commercialId,
        action: action,
        pageId: pageId,
        endDate: '',
        startDate: '',
        type: this.typeListArray[0],
        address: {},
        render: canRender,
        title: title,
        sponsorName: '',
        sponsorshipStatus: '',
        // codeprefix: '',
        viewStartDate: '',
        viewEndDate: '',
        sponsoredUserList: [],
        showEdit: false,
        showView: true,
        pageAdvertisementList: [],
        pageList: [],
        allChecked: false,
        selectedTab: '0',
        openEmailModal: false,
        selectedSponsorUserList: [],
        breadCrumb: breadCrumb,
        advertisementList: [],
        isStatus: true,
        status: {},
      };
    }
    this.getAdvertisementMappingList();
  }
  getAdvertisementMappingList() {
    const postObject = {
      header: this.header,
      paidAdvertiser: {
        id: this.paidAdvertiserDetails.id,
      },
    };

    FetchServerData.callPostService('/paidadvertiseradsms/getPaidAdvertiserAds', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let advertisementList = output.data;
        if (advertisementList !== []) {
          advertisementList.unshift({
            uid: 'None',
            sponsorer: '',

            templateid: '',
            createdOn: '2022-03-11T12:56:08.000+00:00',
            id: -1,
          });
        }
        let advertisementListArray = advertisementList.reduce((unique, o) => {
          if (!unique.some((obj) => obj.name === o.name && obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []);

        this.setState({
          advertisementList: advertisementListArray,
          // advertisement:advertisementList[0],
          render: true,
        });
      } else {
      }
    });
  }
  getCommercialDetails(commercialId) {
    const postObject = {
      header: this.header,
      sponsorship: {
        id: commercialId,
      },
    };

    FetchServerData.callPostService('/paidadvertisersponsorshipms/get', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let commercialsDetails = output.data;
          let newStartDate = new Date(commercialsDetails.startDate);
          let newEndDate = new Date(commercialsDetails.endDate);
          //   let newStartDate = Utils.convertUTCDateToUserTimeZoneDate(commercialsDetails.startDate);
          let getPermission = Utils.getRolePermission(['*']);
          let getUpdatePermission = getPermission[3];
          let getDeletePermission = getPermission[4];
          let userStatus = commercialsDetails;
          let sponsorshipStatus = '';
          if (userStatus === null) {
            sponsorshipStatus = false;
          } else {
            if (userStatus.sponsorshipStatus.status === 'Active') {
              sponsorshipStatus = true;
            } else {
              sponsorshipStatus = false;
            }
          }
          this.setState({
            render: true,
            id: commercialsDetails.id,
            startDate: newStartDate,
            endDate: newEndDate,
            viewStartDate: commercialsDetails.startDate,
            viewEndDate: commercialsDetails.endDate,
            // activeUser: commercialsDetails.noOfSubscriptions,
            sponsorName: commercialsDetails.name,
            type: commercialsDetails.sponsorshipType,
            sponsorshipStatus: commercialsDetails.sponsorshipStatus.status,
            totalshowcount: commercialsDetails.totalshowcount,
            // sponsoredUserList: commercialsDetails.sponsoredUserList,
            // pageAdvertisementList: commercialsDetails.pageAdvertisementMappingList,
            pageList: commercialsDetails.pageList,
            isStatus: sponsorshipStatus,
            getDeletePermission: getDeletePermission,
            getUpdatePermission: getUpdatePermission,
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  getAdvertisementList() {
    const postObject = {
      header: this.header,
      mapping: {
        paidadvertiserSponsorship: this.state.id,
      },
    };
    FetchServerData.callPostService(
      '/paidAdvertiserSporshipAdvertisementMappingms/getSponsorshipAdvetisementList',
      postObject
    ).then((output) => {
      if (output.status === 'SUCCESS') {
        let commercialsDetails = output.data;
        this.setState({
          render: true,
          pageAdvertisementList: commercialsDetails,
          pageList: commercialsDetails.pageList,
        });
      } else {
      }
    });
  }

  handleStartDateChange(date) {
    this.setState({ startDate: date });
  }
  handleEndDateChange(date) {
    this.setState({ endDate: date });
  }
  handleStatusChange(e) {
    this.setState({ isStatus: !this.state.isStatus });
  }

  handleTypeChange(selectedType) {
    this.state.type = selectedType;
    this.setState({ type: selectedType });
    if (undefined !== this.onChange && null !== this.onChange) {
      this.onChange(this.state);
    }
  }

  handleActiveUserChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ activeUser: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }

  handleSponsorNameChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ sponsorName: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }

  handleCodePrefixChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ codeprefix: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }

  createCancel() {
    this.props.history.push({
      pathname: '/paidCommercialsList',
    });
  }
  editCancel() {
    let adminFccPageState = {
      id: this.state.id,
      action: 'view',
      title: 'view Sponsorship',
      pageId: 'Sponsorship',
    };
    store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
    this.props.history.push({
      pathname: '/viewCommercials',
      state: {
        action: 'view',
        title: 'Commercial Details',
        commercialId: this.state.id,
      },
    });
  }

  viewCancel() {
    this.props.history.push({
      pathname: '/paidCommercialsList',
    });
  }

  handleEmailChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ email: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }

  editUserDetails(action) {
    if (action === 'editRow') {
      this.setState({
        showEdit: true,
        showView: false,
      });
    } else {
      this.setState({
        showEdit: false,
        showView: true,
      });
    }
  }

  addCommercialsToBackend(e) {
    e.preventDefault();
    let startDateTime = Utils.adminPanelConvertUserTimeZoneDateToUTCDate(this.state.startDate);
    let endDateTime = Utils.adminPanelConvertUserTimeZoneDateToUTCDate(this.state.endDate);
    const postObject = {
      header: this.header,

      sponsorship: {
        id: null,

        startDate: startDateTime,
        endDate: endDateTime,
        name: this.state.sponsorName,
        paidadvertiser: {
          id: this.paidAdvertiserDetails.id,
        },
        sponsorshipType: { type: this.state.type.type },
      },
    };
    FetchServerData.callPostService('/paidadvertisersponsorshipms/create', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let commercialsDetails = output.data;
        PtsAlert.success(output.message);
        let adminFccPageState = {
          id: commercialsDetails.id,
          action: 'view',
        };
        store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));

        this.props.history.push({
          pathname: '/viewCommercials',
          state: {
            action: 'view',
            title: 'Commercial Details',
            commercialsDetails: commercialsDetails,
            pageId: 'createCommercials',
          },
        });
      } else {
      }
    });
  }

  updateSponsorshipToBackend(e) {
    e.preventDefault();
    let sponsorshipStatus;
    if (this.state.isStatus === true) {
      sponsorshipStatus = 'Active';
    } else {
      sponsorshipStatus = 'Inactive';
    }
    let startDateTime = Utils.adminPanelConvertUserTimeZoneDateToUTCDate(this.state.startDate);
    let endDateTime = Utils.adminPanelConvertUserTimeZoneDateToUTCDate(this.state.endDate);
    const postObject = {
      header: this.header,
      sponsorship: {
        id: this.state.id,
        // codeprefix: this.state.codeprefix,
        // noOfSubscriptions: this.state.activeUser,
        name: this.state.sponsorName,
        startDate: startDateTime,
        endDate: endDateTime,
        paidadvertiser: {
          id: this.paidAdvertiserDetails.id,
        },
        sponsorshipType: { type: this.state.type.type },
        sponsorshipStatus: {
          status: sponsorshipStatus,
        },
      },
    };
    FetchServerData.callPostService('/paidadvertisersponsorshipms/update', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let commercialsDetails = output.data;
        PtsAlert.success(output.message);
        let adminFccPageState = {
          id: commercialsDetails.id,
          action: 'view',
        };
        store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
        this.props.history.push({
          pathname: '/viewCommercials',
          state: {
            action: 'view',
            title: 'Commercial Details',
            commercialId: commercialsDetails.id,
          },
        });
      } else {
      }
    });
  }

  editSponsorToBackend(e) {
    let adminFccPageState = {
      id: this.state.id,
      action: 'edit',
      title: 'edit Sponsorship',
      pageId: 'Sponsorship',
    };
    store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
    this.props.history.push({
      pathname: '/editCommercials',
      state: {
        action: 'edit',
        title: 'Sponsor Details',
        commercialId: this.state.id,
      },
    });
  }

  deleteCommercialToBackend = () => {
    const postObject = {
      header: this.header,
      sponsorship: {
        id: this.state.id,
      },
    };

    FetchServerData.callPostService('/paidadvertisersponsorshipms/delete', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          this.props.history.push({
            pathname: '/paidCommercialsList',
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  updateFccAdvertisementInBackend(e, key, note) {
    if (this.advertisementMappingRefList[key] && this.advertisementMappingRefList[key].current) {
      const postObject = {
        header: this.header,

        mapping: {
          id: this.advertisementMappingRefList[key].current.state.mappingId,
          fccpage: {
            id: this.advertisementMappingRefList[key].current.state.fccPageId,
          },
          advertisementId: this.advertisementMappingRefList[key].current.state.advertisement,
          paidadvertiserSponsorship: this.paidAdvertiserDetails.id,
        },
      };
      FetchServerData.callPostService('/paidAdvertiserSporshipAdvertisementMappingms/update', postObject)
        .then((output) => {
          if (output.status === 'SUCCESS') {
            let Advertisement = output.data;
            // this.state.incidentNoteList = [];
            this.getAdvertisementList();
            for (let i = 0; i < this.state.pageAdvertisementList.length; i++) {
              if (this.state.pageAdvertisementList[i].id === Advertisement.id) {
                this.advertisementMappingRefList[i].current.state.fccPage = Advertisement.fccpage.name;
                this.advertisementMappingRefList[i].current.state.advertisement = Advertisement.advertisementId;
                this.advertisementMappingRefList[i].current.state.viewAdvertisement = Advertisement.uid;
                this.advertisementMappingRefList[i].current.state.action = 'list';
                this.advertisementMappingRefList[i].current.state.render = true;
              }
            }

            PtsAlert.success(output.message);
          } else {
          }
        })
        .catch((error) => {});
    }
  }

  setKey(tab) {
    this.setState({
      selectedTab: tab,
    });
  }

  render() {
    this.sponsoredUserRefList = [];
    for (let i = 0; this.state.sponsoredUserList && i < this.state.sponsoredUserList.length; i++) {
      this.sponsoredUserRefList[i] = React.createRef();
    }
    this.advertisementMappingRefList = [];
    for (let i = 0; this.state.pageAdvertisementList && i < this.state.pageAdvertisementList.length; i++) {
      this.advertisementMappingRefList[i] = React.createRef();
    }
    if (this.state.action === 'create') {
      return this.createCommercial();
    }
    if (this.state.action === 'edit') {
      return this.editCommercial();
    } else if (this.state.action === 'view') {
      return this.viewCommercial();
    }
  }

  // Event to get selected rows(Optional)

  createCommercial() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <AdminMenuWithBreadScrum
            backCallback={this.viewCancel.bind(this)}
            breadCrumb={this.state.breadCrumb}
            history={this.props.history}
            name={'Create Commercial Plan'}
            domainObject="Sponsorship"
          />

          <ValidatorForm style={{ width: '100%' }} ref="form" onSubmit={this.addCommercialsToBackend.bind(this)}>
            <Row style={{ position: 'relative', margin: '1em' }}>
              <Col md={12} style={{ position: 'relative', padding: '5px' }}>
                <Card className="formCard">
                  <CardHeader className="cardHeader">
                    <Row>
                      <Col md={9}>
                        {/* {undefined !== this.state.title &&
                          this.state.title.length > 0 && ( */}
                        <Label className="cardHeaderTitle">Commercial Details</Label>
                        {/* )} */}
                      </Col>
                    </Row>
                  </CardHeader>{' '}
                  <CardBody>
                    <Row>
                      <Col md={6}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          label="Name"
                          value={this.state.sponsorName}
                          onChange={this.handleSponsorNameChange.bind(this)}
                          name="sponsorName"
                          inputProps={{ maxLength: 64 }}
                          type="text"
                          margin="normal"
                          className="textField"
                        />
                      </Col>
                      <Col md={6}>
                        {/* <Autocomplete
                          id="type"
                          options={this.typeListArray}
                          value={this.state.type}
                          getOptionLabel={(option) => option.type}
                          //   defaultValue={this.state.state}
                          onChange={(event, value) => {
                            this.handleTypeChange(value);
                          }}
                          inputProps={{
                            style: { textTransform: 'capitalize' },
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Type" variant="outlined" required margin="normal" />
                          )}
                        /> */}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <DatePicker
                          selected={this.state.startDate}
                          onChange={this.handleStartDateChange.bind(this)}
                          dateFormat="MM/dd/yyyy"
                          placeholderText="Start Date*"
                          required
                          className="dateTimeInput"
                          minDate={new Date()}
                        />
                      </Col>

                      <Col md={6}>
                        <DatePicker
                          selected={this.state.endDate}
                          onChange={this.handleEndDateChange.bind(this)}
                          dateFormat="MM/dd/yyyy"
                          placeholderText="End Date*"
                          required
                          className="dateTimeInput"
                          minDate={new Date()}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* </CardBody> */}

            <div style={{ textAlign: 'center', padding: '0em 0em 1em' }}>
              <CreateButton id="loginButton" type="submit">
                Create
              </CreateButton>
              &nbsp;
              <CancelIconButton onClick={(e) => this.createCancel()} />
            </div>
          </ValidatorForm>
        </>
      );
    }
  }

  viewCommercial() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          {' '}
          <AdminMenuWithBreadScrum
            backCallback={this.viewCancel.bind(this)}
            deleteCallback={this.deleteCommercialToBackend.bind(this)}
            getDeletePermission={this.state.getDeletePermission}
            breadCrumb={this.state.breadCrumb}
            history={this.props.history}
            name={this.title}
            domainObject="Sponsorship"
          />
          <Row style={{ marginTop: '1em' }}>
            <Col md={12} style={{ padding: '0em 2.4em 1em 2.4em' }} className="appointmentTab">
              <Tabs defaultIndex={this.state.selectedTab} onSelect={(k) => this.setKey(k)}>
                <TabList style={{ paddingLeft: '0.5em', borderBottom: 'none' }}>
                  <Tab>
                    {' '}
                    <span className="tabUser">Commercial Details</span>
                  </Tab>

                  <Tab onClick={() => this.getAdvertisementList()}>
                    <span className="tabSponsorship">Advertisement List</span>
                  </Tab>
                </TabList>
                <TabPanel>
                  <Card style={{ height: '100%', margin: '0.3em 0.3em' }}>
                    <>
                      <CardHeader
                        className="cardHeader"
                        style={{
                          background: '#1ABC9C',
                          color: '#fff',
                          borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
                          padding: '8px',
                        }}
                      >
                        <Row>
                          <Col md={10}>
                            <Label>
                              <b style={{ color: '#fff' }}> Commercial Details</b>
                            </Label>
                          </Col>
                          <Col md={2}>
                            {this.state.sponsorshipStatus !== 'Expired'
                              ? this.state.getUpdatePermission && (
                                  <EditIconButton onClick={this.editSponsorToBackend.bind(this)} />
                                )
                              : ''}
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md={6}>
                            <Row>
                              <Col md={4}>
                                <label>Sponsorship Name:</label>
                              </Col>
                              <Col
                                md={8}
                                style={{
                                  textAlign: 'left',
                                  textTransform: 'capitalize',
                                }}
                              >
                                {' '}
                                <span style={{ color: '#000', marginLeft: '-3.5em' }}>{this.state.sponsorName}</span>
                              </Col>
                            </Row>

                            <Row>
                              <Col md={4}>
                                <label style={{ paddingRight: '0px' }}>Start Date:</label>
                              </Col>
                              <Col
                                md={8}
                                style={{
                                  textAlign: 'left',
                                  textTransform: 'capitalize',
                                }}
                              >
                                {' '}
                                <span style={{ color: '#000', marginLeft: '-3.5em' }}>
                                  {moment(this.state.viewStartDate).format('DD MMM YYYY')}
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <label style={{ paddingRight: '0px' }}>Ads Shown:</label>
                              </Col>
                              <Col
                                md={8}
                                style={{
                                  textAlign: 'left',
                                  textTransform: 'capitalize',
                                }}
                              >
                                {' '}
                                <span style={{ color: '#000', marginLeft: '-3.5em' }}>{this.state.totalshowcount}</span>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={6}>
                            <Row>
                              <Col md={4}>
                                <label style={{ paddingRight: '0px' }}> Status:</label>
                              </Col>
                              <Col md={8} style={{ textAlign: 'left' }}>
                                {' '}
                                <span style={{ color: '#000', marginLeft: '-8em' }}>
                                  {this.state.sponsorshipStatus}
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <label style={{ paddingRight: '0px' }}>End Date:</label>
                              </Col>
                              <Col md={8} style={{ textAlign: 'left' }}>
                                {' '}
                                <span style={{ color: '#000', marginLeft: '-8em' }}>
                                  {moment(this.state.viewEndDate).format('DD MMM YYYY')}
                                </span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </>
                  </Card>
                </TabPanel>

                <TabPanel>
                  <Row style={{ position: 'relative' }} className="sponsorDetailColumn">
                    <Col md={12}>
                      <Card style={{ height: '100%' }}>
                        <CardHeader
                          style={{
                            background: '#1ABC9C',
                            color: '#fff',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
                            padding: '8px',
                          }}
                        >
                          <b> Advertisement List</b>
                        </CardHeader>

                        <CardBody style={{ padding: '0' }}>
                          <Table aria-label="simple table" className="orderHistory">
                            <TableHead style={{ fontFamily: 'bold' }}>
                              <TableRow>
                                <TableCell align="left">
                                  <b>#</b>
                                </TableCell>
                                <TableCell align="left">
                                  <b>Page</b>
                                </TableCell>
                                <TableCell align="left">
                                  <b>Advertisement</b>
                                </TableCell>
                                <TableCell align="left">
                                  <b>Action</b>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.pageAdvertisementList.map((user, index) => {
                                return (
                                  <PageAdvertisementMappingList
                                    ref={this.advertisementMappingRefList[index]}
                                    key={index}
                                    data={user}
                                    srno={index}
                                    action={user.action ? user.action : 'list'}
                                    advertisementList={this.state.advertisementList}
                                    pageList={this.state.pageList}
                                    onRowUpdate={this.updateFccAdvertisementInBackend.bind(this, user, index)}
                                  />
                                );
                              })}
                            </TableBody>
                          </Table>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPanel>
              </Tabs>
            </Col>
          </Row>
          <div className="sponsorDetailFooter">
            <BackButton
              type="button"
              style={{ backgroundColor: '#162c50', color: '#fff' }}
              variant="contained"
              onClick={this.viewCancel.bind(this)}
            >
              Back
            </BackButton>
          </div>
        </>
      );
    }
  }

  editCommercial() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <AdminMenuWithBreadScrum
            backCallback={this.editCancel.bind(this)}
            breadCrumb={this.state.breadCrumb}
            history={this.props.history}
            name={'Edit Commercial Plan'}
            domainObject="Sponsorship"
          />
          <ValidatorForm style={{ width: '100%' }} ref="form" onSubmit={this.updateSponsorshipToBackend.bind(this)}>
            <Row style={{ position: 'relative', margin: '1em' }}>
              <Col md={12} style={{ position: 'relative', padding: '5px' }}>
                <Card className="formCard">
                  <CardHeader className="cardHeader">
                    <Row>
                      <Col md={9}>
                        <Label className="cardHeaderTitle">Sponsorship Info</Label>
                      </Col>
                    </Row>
                  </CardHeader>{' '}
                  <CardBody>
                    <Row>
                      <Col md={6}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          label="Name"
                          value={this.state.sponsorName}
                          onChange={this.handleSponsorNameChange.bind(this)}
                          name="sponsorName"
                          inputProps={{ maxLength: 64 }}
                          type="text"
                          margin="normal"
                          className="textField"
                        />
                      </Col>
                      <Col md={6} style={{ marginTop: '25px' }}>
                        {/* <Autocomplete
                          id="type"
                          disabled
                          options={this.typeListArray}
                          value={this.state.type}
                          getOptionLabel={(option) => option.type}
                          //   defaultValue={this.state.state}
                          onChange={(event, value) => {
                            this.handleTypeChange(value);
                          }}
                          inputProps={{
                            style: { textTransform: 'capitalize' },
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Type" variant="outlined" required margin="normal" />
                          )}
                        /> */}
                        <label style={{ paddingRight: '0px' }}> Status:</label>{' '}
                        <span style={{ color: '#000', textAlign: 'left', paddingLeft: '5px' }}>
                          {this.state.sponsorshipStatus}
                        </span>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <DatePicker
                          selected={this.state.startDate}
                          onChange={this.handleStartDateChange.bind(this)}
                          dateFormat="MM/dd/yyyy"
                          placeholderText="Start Date*"
                          required
                          className="dateTimeInput"
                          minDate={new Date()}
                        />
                      </Col>

                      <Col md={6}>
                        <DatePicker
                          selected={this.state.endDate}
                          onChange={this.handleEndDateChange.bind(this)}
                          dateFormat="MM/dd/yyyy"
                          placeholderText="End Date*"
                          required
                          className="dateTimeInput"
                          minDate={new Date()}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* </CardBody> */}

            <div style={{ textAlign: 'center', padding: '0em 0em 1em' }}>
              <SaveButton id="loginButton" type="submit">
                Save
              </SaveButton>
              &nbsp;
              <CancelIconButton onClick={(e) => this.editCancel()} />
            </div>
          </ValidatorForm>
        </>
      );
    }
  }
}

export default PaidCommercials;
