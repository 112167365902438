import Button from '@material-ui/core/Button';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import EmailIcon from '@material-ui/icons/Email';
import PinDropIcon from '@material-ui/icons/PinDrop';
import React from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Col, Modal, ModalBody, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import PtsAlert from '../ptsAlert/PtsAlert';
import './assets/vendor/animate.css/animate.min.css';
import './assets/vendor/bootstrap/css/bootstrap.min.css';
import './assets/vendor/boxicons/css/boxicons.min.css';
import './assets/vendor/icofont/icofont.min.css';
import './assets/vendor/line-awesome/css/line-awesome.min.css';
import './assets/vendor/owl.carousel/assets/owl.carousel.min.css';
import './assets/vendor/remixicon/remixicon.css';
import './assets/vendor/venobox/venobox.css';
import './ContactUs.scss';
import FccAdvertisement from '../fccAdvertisement/FccAdvertisement';
import AppleStore from '../../../assets/images/appleStore.png';
import GooglePlayStore from '../../../assets/images/googlePlayStore.png';
export default class ContactUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      name: '',
      phone: '',
      yourCompany: '',
      message: '',
      subject: '',
      menuList: false,
    };
    window.scrollTo(0, 0);
  }
  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }

  handlePhoneChange(e) {
    this.setState({ phone: e.target.value });
  }

  handleCompanyChange(e) {
    this.setState({ yourCompany: e.target.value });
  }

  handleMessageChange(e) {
    this.setState({ message: e.target.value });
  }

  handleSubjectChange(e) {
    this.setState({ subject: e.target.value });
  }

  addContactToBackend(e) {
    const postObject = {
      name: this.state.name,
      email: this.state.email,
      companyName: this.state.yourCompany,
      message: this.state.message,
      phone: this.state.phone,
      subject: this.state.subject,
    };
    FetchServerData.callPostService('/staticms/sendEnquiry', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        PtsAlert.success(output.message);

        this.setState({
          email: '',
          name: '',
          phone: '',
          yourCompany: '',
          message: '',
          subject: '',
        });
      } else {
      }
    });
  }

  menuListToggle = () => {
    this.setState({
      menuList: !this.state.menuList,
    });
  };

  openMenu() {
    this.setState({
      menuList: true,
    });
  }

  navigateToLink(linkToNavigate) {
    this.props.history.push(linkToNavigate);
  }
  openLoginTab = () => {
    const win = window.open('/#/login', '_blank');
    win.focus();
  };
  scrollToSection(elementID) {
    if (document.getElementById(elementID)) {
      document.getElementById(elementID).scrollIntoView();
    }
  }

  render() {
    return (
      <>
        {' '}
        <div style={{ height: '100vh', overflowY: 'scroll' }}>
          <link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0-alpha.6/css/bootstrap.min.css" rel="stylesheet" />
          <link href="//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css" rel="stylesheet" />

          {/* =======================================================
  * Template Name: Selecao - v2.3.1
  * Template URL: https://bootstrapmade.com/selecao-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== */}

          {/* ======= Header ======= */}
          <header
            id="header"
            className="fixed-top d-flex align-items-center  header-transparent "
            style={{ background: '#074246' }}
          >
            <div className="container d-flex align-items-center">
              <div className="logo mr-auto">
                <span style={{ display: 'flex' }}>
                  <img
                    src="../assets/img/fccLogo.png"
                    alt=""
                    style={{ height: '2.8em', width: '3em', marginTop: '-4px' }}
                  />
                  <h1 className="text-light" style={{ paddingLeft: '0.2em' }}>
                    <a href="/">Family Care Circle</a>
                  </h1>
                </span>
              </div>
              <nav className="nav-menu d-none d-lg-block">
                <ul>
                  <li>
                    <a style={{ color: '#fff', cursor: 'pointer' }} onClick={() => this.navigateToLink('/')}>
                      Home
                    </a>
                  </li>

                  <li>
                    <a style={{ color: '#fff', cursor: 'pointer' }} onClick={() => this.openLoginTab()} target="_blank">
                      Login
                    </a>
                  </li>
                  <li>
                    <a style={{ color: '#fff', cursor: 'pointer' }} onClick={() => this.navigateToLink('/pricing')}>
                      Pricing
                    </a>
                  </li>
                  <li>
                    <a
                      style={{ color: '#fff', cursor: 'pointer' }}
                      onClick={() => this.navigateToLink('/BusinessPromotion')}
                    >
                      Sponsorship
                    </a>
                  </li>
                  <li className="active">
                    <a style={{ color: '#fff', cursor: 'pointer' }} onClick={() => this.navigateToLink('/contactUs')}>
                      Contact
                    </a>
                  </li>
                </ul>
              </nav>
              {/* .nav-menu */}
            </div>
          </header>
          <button
            type="button"
            className="mobile-nav-toggle d-lg-none"
            onClick={(e) => this.openMenu()}
            style={{ marginRight: '0.7em' }}
          >
            <i className="fa fa-bars" style={{ fontSize: '1.5em' }}></i>
          </button>
          {/* End Header */}

          <section id="contact" className="contact" style={{ padding: ' 60px 60px 0px 60px' }}>
            <Row>
              <Col md={10} style={{ padding: '3em' }}>
                <div className="container">
                  <div className="contactSection-title" data-aos="zoom-out">
                    <h2>Contact</h2>
                    <p style={{ lineHeight: '1.1em' }}>Contact Us</p>
                  </div>
                  <div className="row mt-5">
                    <div className="col-lg-4" data-aos="fade-right">
                      <div className="info">
                        <div className="address">
                          <PinDropIcon className="contactIfo_icon" />
                          <h4>Location:</h4>
                          <p>PO Box #285</p>
                          <p>Pensacola,&nbsp;FL&nbsp;32591</p>
                        </div>
                        <div className="email">
                          <EmailIcon className="contactIfo_icon" />
                          <h4>Email:</h4>
                          <p>Support@FamilyCareCircle.com</p>
                        </div>
                        {/* <div className="phone">
                        <i className="icofont-phone" />
                        <h4>Call:</h4>
                        <p>850-377-9082</p>
                      </div> */}
                      </div>
                    </div>
                    <div className="col-lg-8 mt-5 mt-lg-0" data-aos="fade-left">
                      <ValidatorForm ref="form" onSubmit={this.addContactToBackend.bind(this)}>
                        <div className="form-row">
                          <div className="col-md-6 form-group">
                            <TextField
                              margin="normal"
                              variant="outlined"
                              type="text"
                              fullWidth
                              required
                              id="name"
                              onChange={this.handleNameChange.bind(this)}
                              label="Your Name"
                              name="name"
                              autoComplete="off"
                              value={this.state.name}
                            />
                          </div>
                          <div className="col-md-6 form-group">
                            <TextValidator
                              margin="normal"
                              variant="outlined"
                              type="email"
                              fullWidth
                              required
                              id="email"
                              onChange={this.handleEmailChange.bind(this)}
                              label="Email"
                              name="email"
                              autoComplete="off"
                              validators={['required', 'isEmail']}
                              errorMessages={['this field is required', 'email is not valid']}
                              value={this.state.email}
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-md-6 form-group">
                            <TextValidator
                              margin="normal"
                              variant="outlined"
                              type="text"
                              required
                              fullWidth
                              id="phone"
                              onChange={this.handlePhoneChange.bind(this)}
                              label="Phone"
                              name="phone"
                              validators={['required', 'isNumber']}
                              errorMessages={['this field is required', 'phone is not valid']}
                              autoComplete="off"
                              value={this.state.phone}
                            />
                          </div>
                          <div className="col-md-6 form-group">
                            <TextField
                              margin="normal"
                              variant="outlined"
                              type="text"
                              fullWidth
                              autoComplete="off"
                              id="yourCompany"
                              onChange={this.handleCompanyChange.bind(this)}
                              label="Your Company"
                              name="yourCompany"
                              value={this.state.yourCompany}
                            />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="col-md-6 form-group">
                            <TextField
                              margin="normal"
                              variant="outlined"
                              type="text"
                              fullWidth
                              id="subject"
                              autoComplete="off"
                              required
                              onChange={this.handleSubjectChange.bind(this)}
                              label="Subject"
                              name="subject"
                              value={this.state.subject}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <TextareaAutosize
                            aria-label="minimum height"
                            minRows={3}
                            margin="normal"
                            variant="outlined"
                            style={{ width: '100%', padding: '1em' }}
                            value={this.state.message}
                            name="message"
                            autoComplete="off"
                            required
                            fullWidth
                            onChange={this.handleMessageChange.bind(this)}
                            placeholder="Message"
                          />
                        </div>

                        <div className="text-center">
                          <Button
                            type="submit"
                            style={{ background: '#ef6603', borderRadius: '50px', border: 'none', color: '#fff' }}
                          >
                            Send Message
                          </Button>
                        </div>
                      </ValidatorForm>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={2} className="advertiseColumn" style={{ padding: '2em 0em', position: 'relative' }}>
                <FccAdvertisement advertisementType="Vertical" />
              </Col>
            </Row>
            <Modal isOpen={this.state.menuList} toggle={this.menuListToggle} size="sm">
              <Row>
                <Col md={12}>
                  <CloseIcon
                    onClick={(e) => this.menuListToggle()}
                    style={{ float: 'right', margin: '0.5em 1em 0em 0em' }}
                  />
                </Col>
              </Row>
              <ModalBody>
                <nav className="mobileViewNav">
                  <ul>
                    <li>
                      <a onClick={() => this.navigateToLink('/')}>Home</a>
                    </li>

                    <li>
                      <a onClick={() => this.openLoginTab()} target="_blank">
                        Login
                      </a>
                    </li>
                    <li>
                      <a onClick={() => this.navigateToLink('/pricing')}>Pricing</a>
                    </li>
                    <li>
                      <a onClick={() => this.navigateToLink('/BusinessPromotion')}>Sponsorship</a>
                    </li>
                    <li className="active">
                      <a onClick={() => this.navigateToLink('/contactUs')}>Contact</a>
                    </li>
                  </ul>
                </nav>
              </ModalBody>
            </Modal>
          </section>

          {/* ======= Footer ======= */}
          <footer id="contactUsFooter">
            <div className="container">
              <div className="row">
                <div className="col-lg-6">
                  <h3>Family Care Circle</h3>
                  <div className="social-links">
                    <a href="#" className="twitter">
                      <i className="fa fa-twitter" />
                    </a>
                    <a href="https://www.facebook.com/FamilyCareCircle" className="facebook" target="_blank">
                      <i className="fa fa-facebook-f" />
                    </a>
                    <a href="#" className="instagram">
                      <i className="fa fa-instagram" />
                    </a>
                    <a href="#" className="google-plus">
                      <i className="fa fa-skype" />
                    </a>
                    <a href="#" className="linkedin">
                      <i className="fa fa-linkedin" />
                    </a>
                  </div>
                  <div className="copyright">
                    © Copyright{' '}
                    <strong>
                      <span>Family Care Circle</span>
                    </strong>
                    . All Rights Reserved
                  </div>
                </div>
                <div className="col-lg-3">
                  <a
                    style={{ color: '#fff', cursor: 'pointer' }}
                    href="https://apps.apple.com/us/app/family-care-circle/id1602521967"
                    target="_blank"
                  >
                    <img src={AppleStore} style={{ height: '40px', border: '1px solid white', borderRadius: '5px' }} />
                  </a>
                  &nbsp;&nbsp;&nbsp;
                  <a
                    target="_blank"
                    href="https://play.google.com/store/apps/details?id=com.familycarecircle.fccmobileapp"
                    style={{ color: '#fff', cursor: 'pointer' }}
                  >
                    <img
                      src={GooglePlayStore}
                      style={{ height: '40px', border: '1px solid white', borderRadius: '5px' }}
                    />
                  </a>
                </div>
                <div className="col-lg-3">
                  <ul style={{ float: 'right', listStyle: 'none', lineHeight: '3em', margin: '0 100px 0px 0' }}>
                    <li style={{ textAlign: 'left' }}>
                      <i className="fa fa-angle-right" />

                      <a onClick={() => this.navigateToLink('/contactUs')} style={{ color: '#fff', cursor: 'pointer' }}>
                        {' '}
                        Feedback
                      </a>
                    </li>
                    <li style={{ textAlign: 'left' }}>
                      <i className="fa fa-angle-right" />

                      <a
                        onClick={() => this.navigateToLink('/termsConditions')}
                        style={{ color: '#fff', cursor: 'pointer' }}
                      >
                        {' '}
                        Terms &amp; Conditions
                      </a>
                    </li>
                    <li style={{ textAlign: 'left' }}>
                      <i className="fa fa-angle-right" />

                      <a
                        onClick={() => this.navigateToLink('/privacyPolicyWeb')}
                        style={{ color: '#fff', cursor: 'pointer' }}
                      >
                        {' '}
                        Privacy Policy
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </footer>
          {/* End Footer */}
          <a href="#" className="back-to-top">
            <i className="ri-arrow-up-line" />
          </a>
        </div>
      </>
    );
  }
}
