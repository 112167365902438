import { GET_ADMIN_HEADER, SET_ADMIN_HEADER } from './adminHeaderTypes';

const getAdminHeader = () => {
  return {
    type: GET_ADMIN_HEADER,
  };
};

const setAdminHeader = (data) => {
  return {
    type: SET_ADMIN_HEADER,
    payload: data,
  };
};
const adminHeaderAction = { getAdminHeader, setAdminHeader };

export default adminHeaderAction;
