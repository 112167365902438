import TextField from '@material-ui/core/TextField';
import ToggleButton from '@material-ui/lab/ToggleButton';
import React, { Component } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import 'react-quill/dist/quill.bubble.css';
import { Card, CardBody, Col, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import adminFccPageStateAction from '../../../redux/adminPanel/adminFccPageState/adminFccPageStateAction';
import store from '../../../redux/store';
import BackButton from '../../components/buttons/BackButton';
import CancelIconButton from '../../components/buttons/CancelIconButton';
import SaveButton from '../../components/buttons/SaveButton';
import PtsAlert from '../../components/ptsAlert/PtsAlert';
import AdminMenuWithBreadScrum from '../adminMenu/AdminMenuWithBreadScrum';
import './TermsConditionTemplate.scss';
import Quill from './quill/Quill';
import Tooltip from './tooltip/Tooltip';
class TermsConditionTemplate extends Component {
  title = 'Terms And Conditions Template Details';
  breadCrumb = [
    { pageid: '/aptermsConditionsList', uiname: 'Terms And Conditions Template List' },
    { pageid: '', uiname: 'Terms And Conditions Template Details' },
  ];
  header = store.getState().adminHeader.adminHeader;

  adminFccPageState = store.getState().adminFccPageState.adminFccPageState;
  constructor(props) {
    super(props);
    let termsConditionTemplateId = props.termsConditionTemplateId;
    let action = props.action;
    let title = '';
    let pageId = '';
    if (!termsConditionTemplateId && props.history && props.history.location && props.history.location.state) {
      termsConditionTemplateId = props.history.location.state.termsConditionTemplateId;
      action = props.history.location.state.action;
    }
    if (null !== termsConditionTemplateId && termsConditionTemplateId > 0) {
      this.getTemplateDetails(termsConditionTemplateId);
    }

    if (termsConditionTemplateId === undefined) {
      // if (pageId === 'termsConditionList') {
      termsConditionTemplateId = this.adminFccPageState.id;
      action = this.adminFccPageState.action;
      title = this.adminFccPageState.title;
      // }
      if (action !== 'create') {
        this.getTemplateDetails(termsConditionTemplateId);
      }
    }
    this.state = {
      action: action,
      message: '',

      version: '',
      anchorEl: null,

      id: termsConditionTemplateId,
      variableList: [],
      description: '',
      viewEmailUid: '',
      copied: false,
      render: false,
      getUpdatePermission: false,
      getDeletePermission: false,
      isStatus: true,
      createdByName: '',
      updatedByName: '',
    };
  }

  getTemplateDetails(termsConditionTemplateId) {
    let postObject = {
      header: this.header,
      termsConditions: { id: termsConditionTemplateId },
    };
    FetchServerData.callPostService('/apTermsConditions/get', postObject)
      .then((response) => {
        let output = response;
        if (output.status === 'SUCCESS') {
          let templateDetails = output.data.termsConditions;

          let getPermission = Utils.getRolePermission(output.data.privilegeList);
          let getUpdatePermission = getPermission[3];
          let getDeletePermission = getPermission[4];
          let templateStatus = '';

          this.setState({
            render: true,
            id: templateDetails.id,
            message: templateDetails.data,
            isStatus: templateDetails.isactive,
            version: templateDetails.version,
            createdByName: templateDetails.createdbyname,
            updatedByName: templateDetails.updatedbyname,
            getUpdatePermission: getUpdatePermission,
            getDeletePermission: getDeletePermission,
          });
        }
      })
      .catch((error) => {});
  }

  addTermsConditionTemplateInBackend() {
    let postObject = {
      header: this.header,

      termsConditions: {
        data: this.state.message,
        isactive: this.state.isStatus,
        version: this.state.version,
      },
    };
    FetchServerData.callPostService('/apTermsConditions/create', postObject)
      .then((response) => {
        let output = response;
        if (output.status === 'SUCCESS') {
          PtsAlert.success(output.message);
          let templateDetails = output.data.termsConditions;
          this.props.history.push({
            pathname: '/viewTermsCondition',
            state: {
              action: 'view',
              title: 'Terms And Conditions Template Details',
              termsConditionTemplateId: templateDetails.id,
            },
          });
        }
      })
      .catch((error) => {});
  }
  updateTermsConditionTemplateInBackend() {
    let postObject = {
      header: this.header,

      termsConditions: {
        data: this.state.message,
        isactive: this.state.isStatus,
        version: this.state.version,
        id: this.state.id,
      },
    };
    FetchServerData.callPostService('/apTermsConditions/update', postObject)
      .then((response) => {
        let output = response;
        if (output.status === 'SUCCESS') {
          PtsAlert.success(output.message);
          let templateDetails = output.data.termsConditions;
          this.props.history.push({
            pathname: '/viewTermsCondition',
            state: {
              action: 'view',
              title: 'Terms And Conditions Template Details',
              termsConditionTemplateId: templateDetails.id,
            },
          });
        }
      })
      .catch((error) => {});
  }

  deleteTermsConditionToBackend = () => {
    const postObject = {
      header: this.header,
      termsConditions: {
        id: this.state.id,
      },
    };

    FetchServerData.callPostService('/apTermsConditions/delete', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          this.props.history.push({
            pathname: '/aptermsConditionsList',
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  clearTemplate(e) {
    this.setState({
      message: '',
      version: '',
    });
  }

  handleMessageChange(value) {
    this.setState({ message: value });
  }

  handleClose(event) {
    this.setState({ anchorEl: null });
  }
  cancelEdit() {
    this.props.history.push({
      pathname: '/viewTermsCondition',
      state: {
        action: 'view',
        title: 'Terms And Conditions Template Details',
        termsConditionTemplateId: this.state.id,
        pageId: 'termsConditionList',
      },
    });
  }

  cancelCreate() {
    this.props.history.push({
      pathname: '/aptermsConditionsList',
    });
  }

  viewCancel() {
    this.props.history.push({
      pathname: '/aptermsConditionsList',
    });
  }

  editTermsCondition() {
    let adminFccPageState = {
      id: this.state.id,
      action: 'edit',
      title: 'Email Template Details',
      pageId: 'termsConditionList',
    };
    store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
    this.props.history.push({
      pathname: '/editTermsCondition',
      state: {
        action: 'edit',
        title: 'Terms And Conditions Template Details',
        termsConditionTemplateId: this.state.id,
      },
    });
  }
  handleVersionChange(e) {
    this.setState({
      version: e.target.value,
    });
  }

  handleStatusChange(e) {
    this.setState({ isStatus: !this.state.isStatus });
  }
  render() {
    if (this.state.action === 'create') {
      return this.createTermsConditionTemplate();
    } else if (this.state.action === 'edit') {
      return this.editTermsConditionTemplate();
    } else {
      return this.viewTermsConditionTemplate();
    }
  }
  createTermsConditionTemplate() {
    const open = Boolean(this.state.anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
      <>
        <AdminMenuWithBreadScrum breadCrumb={this.breadCrumb} history={this.props.history} name={this.title} />
        <Row style={{ position: 'relative', margin: '1em' }}>
          <Col md={12} style={{ position: 'relative', padding: '5px' }}>
            <ValidatorForm
              ref="form"
              style={{ width: '100%' }}
              onSubmit={this.addTermsConditionTemplateInBackend.bind(this)}
            >
              <Card>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col md={2} className="labelColumn">
                          <label id="notificationFormLabel">
                            Version <span className="asterisk">*</span>
                          </label>
                        </Col>
                        <Col md={6} style={{ margin: '-0.8em' }}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            type="text"
                            required
                            className="form-control"
                            id="name"
                            name="name"
                            placeholder="Enter Version"
                            autoComplete="off"
                            margin="normal"
                            value={this.state.version}
                            onChange={this.handleVersionChange.bind(this)}
                            // inputProps={{
                            //   style: { textTransform: 'capitalize' },
                            // }}
                          />
                        </Col>
                        <Col md={4}></Col>
                      </Row>

                      <Row>
                        <Col md={2} className="labelColumn">
                          <label id="notificationFormLabel">Body</label>
                        </Col>
                        <Col md={10} style={{ marginTop: '2em' }}>
                          {this.state.variableList.length > 0 && (
                            <p style={{ marginLeft: '0.2em' }}>
                              The following variables has a significance role in composing the email. Please do not
                              change it.
                            </p>
                          )}

                          <Quill
                            text={this.state.message}
                            onChange={this.handleMessageChange.bind(this)}
                            className="templateMessageBox"
                            theme="snow"
                            readOnly={false}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>

                <div style={{ textAlign: 'center', padding: '0.5em' }}>
                  <SaveButton type="submit" />
                  &nbsp;
                  <CancelIconButton onClick={(e) => this.cancelCreate()} />
                </div>
              </Card>
            </ValidatorForm>
          </Col>
        </Row>
      </>
    );
  }

  viewTermsConditionTemplate() {
    const open = Boolean(this.state.anchorEl);
    const id = open ? 'simple-popover' : undefined;
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <AdminMenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            backCallback={this.viewCancel.bind(this)}
            name={this.title}
            editCallback={this.editTermsCondition.bind(this)}
            deleteCallback={this.deleteTermsConditionToBackend.bind(this)}
            getDeletePermission={this.state.getDeletePermission}
            getUpdatePermission={this.state.getUpdatePermission}
          />
          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={12} className="sponsorDetailColumn" style={{ position: 'relative', padding: '5px' }}>
              <Card style={{ height: '100%' }}>
                {/* <CardHeader className="cardHeader">
                  <Row>
                    <Col md={10}>
                      <Label className="cardHeaderTitle">
                        Terms And Conditions Template Details
                      </Label>
                    </Col>
                  </Row>
                </CardHeader> */}
                <CardBody>
                  <Row>
                    <Col md={3}>
                      <Row>
                        <Col md={3}>
                          <label>Version:</label>
                        </Col>
                        <Col md={9}>{this.state.version}</Col>{' '}
                      </Row>
                    </Col>
                    <Col md={3}>
                      <Row>
                        <Col md={2}>
                          <label> Status</label>
                        </Col>
                        <Col md={10}>
                          {this.state.isStatus === true && (
                            <Tooltip title="Please click EDIT to change status">
                              <span>
                                <ToggleButton
                                  value="Active"
                                  className="ToggleButtonActive"
                                  onChange={this.handleStatusChange.bind(this)}
                                  style={{
                                    background: '#27AE60',
                                    color: '#fff',
                                    height: '25px',
                                    textTransform: 'none',
                                  }}
                                  disabled
                                >
                                  Active
                                </ToggleButton>
                              </span>
                            </Tooltip>
                          )}
                          {this.state.isStatus === false && (
                            <Tooltip title="Please click EDIT to change status">
                              <span>
                                <ToggleButton
                                  value="Inactive"
                                  className="ToggleButtonInActive"
                                  onChange={this.handleStatusChange.bind(this)}
                                  style={{
                                    background: '#c7c7c7',
                                    color: '#000',
                                    height: '25px',
                                    textTransform: 'none',
                                  }}
                                  disabled
                                >
                                  Inactive
                                </ToggleButton>
                              </span>
                            </Tooltip>
                          )}
                        </Col>
                      </Row>
                    </Col>
                    <Col md={3}>
                      <Row>
                        <Col md={5}>
                          <label>Created By:</label>
                        </Col>
                        <Col md={7}>{this.state.createdByName ? this.state.createdByName : ''}</Col>{' '}
                      </Row>
                    </Col>
                    <Col md={3}>
                      <Row>
                        <Col md={6}>
                          <label>Updated By:</label>
                        </Col>
                        <Col md={6}>{this.state.updatedByName ? this.state.updatedByName : ''}</Col>{' '}
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2} className="labelColumn">
                      <label> Body:</label>
                    </Col>
                    <Col md={10}>
                      {' '}
                      {this.state.variableList.length > 0 && (
                        <p style={{ marginLeft: '0.2em' }}>
                          The following variables has a significance role in composing the email. Please do not change
                          it.
                        </p>
                      )}
                      <Row style={{ marginTop: '1em' }}>
                        <Col md={12}>
                          <div style={{ border: '1px solid black' }}>
                            <Quill
                              text={this.state.message}
                              onChange={this.handleMessageChange.bind(this)}
                              className="templateMessageBox"
                              theme="bubble"
                              readOnly={true}
                            />
                          </div>
                          {/* <div dangerouslySetInnerHTML={{ __html: this.state.message }} className="emailBody" /> */}
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div className="sponsorDetailFooter">
            <BackButton
              type="button"
              style={{ backgroundColor: '#162c50', color: '#fff' }}
              variant="contained"
              onClick={(e) => this.viewCancel()}
            >
              Back
            </BackButton>
          </div>
        </>
      );
    }
  }
  editTermsConditionTemplate() {
    const open = Boolean(this.state.anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
      <>
        <AdminMenuWithBreadScrum breadCrumb={this.breadCrumb} history={this.props.history} name={this.title} />
        <Row style={{ position: 'relative', margin: '1em' }}>
          <Col md={12} style={{ position: 'relative', padding: '5px' }}>
            <ValidatorForm
              ref="form"
              style={{ width: '100%' }}
              onSubmit={this.updateTermsConditionTemplateInBackend.bind(this)}
            >
              <Card>
                <CardBody>
                  <Row>
                    <Col md={12}>
                      <Row>
                        <Col md={2} className="labelColumn">
                          <label id="notificationFormLabel">
                            Version <span className="asterisk">*</span>
                          </label>
                        </Col>
                        <Col md={6} style={{ margin: '-0.8em' }}>
                          <TextField
                            variant="outlined"
                            fullWidth
                            type="text"
                            required
                            className="form-control"
                            id="name"
                            name="name"
                            placeholder="Enter Version"
                            autoComplete="off"
                            margin="normal"
                            value={this.state.version}
                            onChange={this.handleVersionChange.bind(this)}
                          />
                        </Col>
                        <Col md={4} style={{ marginTop: 'auto' }}>
                          <Row>
                            <Col md={2}>
                              <label> Status</label>
                            </Col>
                            <Col md={10} className="statusMember">
                              {this.state.isStatus === true && (
                                <Tooltip title="Please click EDIT to change status">
                                  <span>
                                    <ToggleButton
                                      value="Active"
                                      className="ToggleButtonActive"
                                      onChange={this.handleStatusChange.bind(this)}
                                      style={{
                                        background: '#27AE60',
                                        color: '#fff',
                                        height: '25px',
                                        textTransform: 'none',
                                      }}
                                    >
                                      Active
                                    </ToggleButton>
                                  </span>
                                </Tooltip>
                              )}
                              {this.state.isStatus === false && (
                                <Tooltip title="Please click EDIT to change status">
                                  <span>
                                    <ToggleButton
                                      value="Inactive"
                                      className="ToggleButtonInActive"
                                      onChange={this.handleStatusChange.bind(this)}
                                      style={{
                                        background: '#c7c7c7',
                                        color: '#000',
                                        height: '25px',
                                        textTransform: 'none',
                                      }}
                                    >
                                      Inactive
                                    </ToggleButton>
                                  </span>
                                </Tooltip>
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>

                      <Row>
                        <Col md={2} className="labelColumn">
                          <label id="notificationFormLabel">Body</label>
                        </Col>
                        <Col md={10} style={{ marginTop: '2em' }}>
                          {this.state.variableList.length > 0 && (
                            <p style={{ marginLeft: '0.2em' }}>
                              The following variables has a significance role in composing the email. Please do not
                              change it.
                            </p>
                          )}

                          <Quill
                            text={this.state.message}
                            onChange={this.handleMessageChange.bind(this)}
                            className="templateMessageBox"
                            theme="snow"
                            readOnly={false}
                          />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>

                <div style={{ textAlign: 'center', padding: '0.5em' }}>
                  <SaveButton type="submit" />
                  &nbsp;
                  <CancelIconButton onClick={(e) => this.cancelEdit()} />
                </div>
              </Card>
            </ValidatorForm>
          </Col>
        </Row>
      </>
    );
  }
}

export default TermsConditionTemplate;
