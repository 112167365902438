import { CLEAR_COMMERCIALS, GET_COMMERCIALS, SET_COMMERCIALS } from './CommercialsTypes';

const getCommercials = () => {
  return {
    type: GET_COMMERCIALS,
  };
};

const setCommercials = (data) => {
  return {
    type: SET_COMMERCIALS,
    payload: data,
  };
};

const clearCommercials = (data) => {
  return {
    type: CLEAR_COMMERCIALS,
    payload: data,
  };
};
const CommercialsAction = { getCommercials, setCommercials, clearCommercials };

export default CommercialsAction;
