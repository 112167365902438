import { CLEAR_ACTIVE_MENU, GET_ACTIVE_MENU, SET_ACTIVE_MENU } from './ActiveMenuType';

const getActiveMenu = () => {
  return {
    type: GET_ACTIVE_MENU,
  };
};

const setActiveMenu = (data) => {
  return {
    type: SET_ACTIVE_MENU,
    payload: data,
  };
};

const clearActiveMenu = (data) => {
  return {
    type: CLEAR_ACTIVE_MENU,
    payload: data,
  };
};
const ActiveMenuAction = { getActiveMenu, setActiveMenu, clearActiveMenu };
export default ActiveMenuAction;
