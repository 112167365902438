import React, { Component } from 'react';
import { Button, Card, CardHeader, CardBody, Row, Col } from 'reactstrap';
import './AdminSponsorForgetPassword.scss';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { TextValidator } from 'react-material-ui-form-validator';
import Link from '@material-ui/core/Link';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import FetchServerData from '../../../provider/FetchServerData';
import fccLogo from '../../../assets/images/fccLogo.png';

export class AdminSponsorForgetPassword extends Component {
  constructor(props) {
    super(props);
    let action = props.action;
    let pageId = '';
    if (
      null !== props.history &&
      props.history !== undefined &&
      props.history.location !== null &&
      props.history.location.state !== null &&
      props.history.location.state !== undefined
    ) {
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
    }
    this.state = {
      email: '',
      password: '',
      showPassword: false,
      action: action,
      pageId: pageId,
    };
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  handlePasswordChange(e) {
    this.setState({
      password: e.target.value,
    });
  }

  gotToButton() {
    document.getElementById('loginButton').focus();
  }
  gotToPassword() {
    document.getElementById('password').focus();
  }

  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  forgetPasswordInBackend = (e) => {
    e.preventDefault();

    let email = '';
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.state.email)) {
      email = this.state.email;
    }
    const postObject = {
      header: {},
      sponsorUser: {
        email: email,
      },
    };
    FetchServerData.callPostService('/sponsoruserms/forgetPassword', postObject)
      .then((response) => {
        let output = response;
        if (output.status === 'SUCCESS') {
          this.props.history.push({
            pathname: '/APSponsorReset',
            state: { email: this.state.email },
          });
        } else {
        }
      })
      .catch((error) => {});
  };
  goToPage() {
    this.props.history.push({
      pathname: '/APSponsorLogin',
      state: { email: this.state.email },
    });
  }
  render() {
    return (
      <>
        <Col md={12}>
          <Row>
            <Col md={6} style={{ marginTop: '10em' }}>
              <img alt="" src={fccLogo} style={{ height: '468px', width: '468px' }} />
            </Col>
            <Col md={6} style={{ marginTop: '11em' }}>
              <Card className="shadow border-0">
                <CardHeader className="bg-transparent">
                  <div className="authLayoutHeader">
                    <p>
                      <b>Family Care Circle</b>
                    </p>
                    <p> Sponsor Login </p>
                  </div>
                </CardHeader>
                <CardBody className="loginCardBody">
                  <ValidatorForm
                    ref="form"
                    onSubmit={this.forgetPasswordInBackend.bind(this)}
                    style={{ width: '100%', padding: '1em' }}
                  >
                    <Row>
                      <Col md={12}>
                        <h3>Forgot password</h3>
                        <p>confirm your email we'll send you verification code.</p>
                      </Col>
                    </Row>

                    <Row>
                      <Col md={12}>
                        <TextValidator
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="email"
                          value={this.state.email}
                          label="Email "
                          name="email"
                          type="text"
                          autoComplete="email"
                          autoFocus
                          style={{ width: '100%' }}
                          validators={['required', 'isEmail']}
                          errorMessages={['this field is required', 'email is not valid']}
                          onChange={this.handleEmailChange.bind(this)}
                        />
                      </Col>
                    </Row>

                    <div className="loginCardFooter">
                      {
                        <Button className="ptsButton" type="submit" variant="contained" color="primary">
                          Confirm
                        </Button>
                      }
                      <Row className="forgetPasswordRow">
                        <Col className="text-center" md="12">
                          <Link
                            //   className="passwordLink"
                            onClick={(e) => this.goToPage()}
                            style={{ cursor: 'pointer' }}
                          >
                            <ArrowBackIcon />
                            Back to login page
                          </Link>
                        </Col>
                      </Row>
                    </div>
                  </ValidatorForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

export default AdminSponsorForgetPassword;
