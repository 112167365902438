import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React, { Component } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import store from '../../../redux/store';
import BackButton from '../../components/buttons/BackButton';
import CancelIconButton from '../../components/buttons/CancelIconButton';
import SaveButton from '../../components/buttons/SaveButton';

import PtsAlert from '../../components/ptsAlert/PtsAlert';
import PATemplate1 from '../paidAdvertisement/PATemplate1';
import PATemplate2 from '../paidAdvertisement/PATemplate2';
import template1 from '../../../assets/images/template5.png';
import template2 from '../../../assets/images/template6.jpg';
import adminFccPageStateAction from '../../../redux/adminPanel/adminFccPageState/adminFccPageStateAction';
import AdminMenuWithBreadScrum from '../adminMenu/AdminMenuWithBreadScrum';

class PaidAdvertisement extends Component {
  title = 'Commercial Details';

  addressRef = React.createRef();
  template1Ref = React.createRef();
  template2Ref = React.createRef();
  header = store.getState().adminHeader.adminHeader;
  paidAdvertiserDetails = store.getState().paidAdvertiserDetails.paidAdvertiserDetails;
  adminFccPageState = store.getState().adminFccPageState.adminFccPageState;
  userDetails = store.getState().adminUserDetails.adminUserDetails;
  breadCrumb = [
    // { pageid: '/home', uiname: 'home' },
    { pageid: '/advertisementList', uiname: 'Commercial List' },
    { pageid: '', uiname: 'Commercial Details' },
  ];
  adminBreadCrumb = [
    { pageid: '/viewAdvertiser', uiname: 'Paid Advertiser Home' },
    { pageid: '/paidAdvertisementList', uiname: 'Commercial List' },
    { pageid: '', uiname: 'Commercial Details' },
  ];
  addressRef = {};
  template1Ref = {};
  template2Ref = {};
  constructor(props) {
    super(props);
    let advertisementId = props.advertisementId;
    let action = props.action;
    let title = '';
    let pageId = '';
    // let advertisementDetails = {};
    if (!advertisementId && props.history && props.history.location && props.history.location.state) {
      advertisementId = props.history.location.state.advertisementId;
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
      title = props.history.location.state.title;
      // doctorDetails = props.history.location.state.doctorDetails;
    }
    let canRender = false;
    if (null !== advertisementId && advertisementId > 0) {
      this.getAdvertisementDetails(advertisementId);
    } else {
      canRender = true;
    }
    let breadCrumb = [];
    if (this.userDetails.isUserLogin === 'paidAdvertiser') {
      breadCrumb = this.breadCrumb;
    } else {
      breadCrumb = this.adminBreadCrumb;
    }
    if (advertisementId === undefined) {
      advertisementId = this.adminFccPageState.id;
      action = this.adminFccPageState.action;
      title = this.adminFccPageState.title;
      canRender = true;
      if (action !== 'list') {
        this.getAdvertisementDetails(advertisementId);
      }
    }
    this.state = {
      id: advertisementId,
      email: '',
      password: '',
      showPassword: false,
      action: action,
      pageId: pageId,
      website: '',
      fax: '',
      name: '',
      address: {},
      render: canRender,
      title: title,
      userList: [],
      status: {},
      template1: false,
      PATemplate2: false,
      templateType: '',
      showPATemplate1: false,
      showPATemplate2: false,
      advertisementTemplateDetails: {},
      advertisementName: '',
      showTemplateList: true,
      breadCrumb: breadCrumb,
    };
  }

  getAdvertisementDetails(advertisementId) {
    // let id = advertisementId;
    const postObject = {
      header: this.header,

      advertisement: {
        id: advertisementId,
        paidadvertiser: this.paidAdvertiserDetails.id,
      },
    };

    FetchServerData.callPostService('/paidadvertiseradsms/get', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let advertisementDetails = output.data;
          let templateDetails = advertisementDetails.template;
          let getPermission = Utils.getRolePermission(['*']);
          let getUpdatePermission = getPermission[3];
          let getDeletePermission = getPermission[4];
          if (templateDetails !== null) {
            if (templateDetails.templateid === 'PATemplate1') {
              this.setState({
                render: true,
                advertisementName: advertisementDetails.uid,
                id: advertisementDetails.id,
                advertisementTemplateDetails: templateDetails,
                showPATemplate1: true,
                showPATemplate2: false,
                templateType: templateDetails.templateid,
                getUpdatePermission: getUpdatePermission,
                getDeletePermission: getDeletePermission,
              });
            } else if (templateDetails.templateid === 'PATemplate2') {
              this.setState({
                render: true,
                advertisementName: advertisementDetails.uid,
                id: advertisementDetails.id,
                advertisementTemplateDetails: templateDetails,
                showPATemplate1: false,
                showPATemplate2: true,
                templateType: templateDetails.templateid,
                getUpdatePermission: getUpdatePermission,
                getDeletePermission: getDeletePermission,
              });
            }
          } else {
            this.setState({
              render: true,
              id: advertisementDetails.id,
              advertisementTemplateDetails: {},
            });
          }
        } else {
        }
      })
      .catch((error) => {});
  }

  handleWebsiteChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ website: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }

  handlePhoneChange({ target: { value } }) {
    this.setState((prevState) => ({
      phone: Utils.convertPhoneNumberToUsaPhoneNumberFormate(value, prevState.phone),
    }));
  }

  handleEmailChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ email: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }

  handleAdminEmailChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ adminEmail: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }

  handlePasswordChange(e) {
    this.setState({
      password: e.target.value,
    });
  }
  handleFnameChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 128);

    if (res.length < 128) {
      this.setState({ fname: res });
    } else {
      Utils.maxFieldLength(128);
    }
  }

  handleLnameChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 128);

    if (res.length < 128) {
      this.setState({ lname: res });
    } else {
      Utils.maxFieldLength(128);
    }
  }

  handleAdvertisementNameChange(e) {
    this.setState({ advertisementName: e.target.value });
  }
  gotToButton() {
    document.getElementById('loginButton').focus();
  }
  gotToPassword() {
    document.getElementById('password').focus();
  }

  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  handleFaxChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ fax: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }

  handleAddressChange(address) {
    this.setState({ address: address });
  }

  handlePATemplate1RefChange(advertisementTemplateDetails) {
    this.setState({
      advertisementTemplateDetails: advertisementTemplateDetails,
    });
  }
  handlePATemplate2RefChange(advertisementTemplateDetails) {
    this.setState({
      advertisementTemplateDetails: advertisementTemplateDetails,
    });
  }

  // createCancel() {
  //   this.props.history.push({
  //     pathname: "/viewAdvertisement",
  //     state: {
  //       action: "view",
  //       advertisementId: this.state.id,
  //     },
  //   });
  // }

  viewCancel() {
    this.props.history.push({
      pathname: '/paidAdvertisementList',
    });
  }

  createCancel() {
    this.setState({
      action: 'list',
      title: 'Create Advertisement',
    });
  }

  onChangeTypeValue(event) {
    this.setState({
      templateType: event.target.value,
    });
  }

  toViewTemplate(e) {
    if (this.state.templateType === 'PATemplate1') {
      this.setState({
        showPATemplate1: true,
        showPATemplate2: false,
        action: 'create',
      });
    } else if (this.state.templateType === 'PATemplate2') {
      this.setState({
        showPATemplate1: false,
        showPATemplate2: true,
        action: 'create',
      });
    }
  }

  editCancel() {
    let adminFccPageState = {
      id: this.state.id,
      action: 'view',
      title: 'view Advertisement',
      pageId: 'Advertisement',
    };
    store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
    this.props.history.push({
      pathname: '/viewPaidAdvertisement',
      state: {
        action: 'viewTemplate',
        title: 'Advertisement Details',
        advertisementId: this.state.id,
      },
    });
  }

  addAdvertisementToBackend(e) {
    e.preventDefault();

    const postObject = {
      header: this.header,
      advertisement: {
        uid: this.state.advertisementName,
        paidadvertiser: this.paidAdvertiserDetails.id,

        template: {
          templateid: this.state.templateType,
          heading: this.template1Ref.current.state.createHeading,
          website: this.template1Ref.current.state.createWebsite,
        },
      },
    };
    FetchServerData.callPostService('/paidadvertiseradsms/create', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let paidAdvertiserDetails = output.data;
        let adminFccPageState = {
          id: paidAdvertiserDetails.id,
          action: 'view',
        };
        store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
        PtsAlert.success(output.message);
        this.props.history.push({
          pathname: '/viewPaidAdvertisement',
          state: {
            action: 'view',
            title: 'Sponsor Details',
            advertisementId: paidAdvertiserDetails.id,
          },
        });
      } else {
      }
    });
  }

  updateAdvertisementToBackend(e) {
    e.preventDefault();
    const postObject = {
      header: this.header,
      advertisement: {
        id: this.state.id,
        uid: this.state.advertisementName,
        paidadvertiser: this.paidAdvertiserDetails.id,

        template: {
          templateid: this.state.templateType,
          heading: this.template1Ref.current.state.heading,
          // subheading: this.template1Ref.current.state.subHeading,
          // message: this.template1Ref.current.state.message,
          website: this.template1Ref.current.state.website,
        },
      },
    };
    FetchServerData.callPostService('/paidadvertiseradsms/update', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let AdvertisementDetails = output.data;

        PtsAlert.success(output.message);
        let adminFccPageState = {
          id: this.state.id,
          action: 'view',
          title: 'view Advertisement',
          pageId: 'Advertisement',
        };
        store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
        this.props.history.push({
          pathname: '/viewPaidAdvertisement',
          state: {
            action: 'viewTemplate',
            title: 'Sponsor Details',
            advertisementId: AdvertisementDetails.id,
          },
        });
      } else {
      }
    });
  }

  editAdvertisementDetails(e) {
    let adminFccPageState = {
      id: this.state.id,
      action: 'edit',
      title: 'Edit Commercial',
      pageId: 'Advertisement',
    };
    store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
    this.props.history.push({
      pathname: '/editPaidAdvertisement',
      state: {
        action: 'edit',
        title: 'Advertisement Details',
        advertisementId: this.state.id,
      },
    });
  }

  deleteAdvertisementToBackend = () => {
    const postObject = {
      header: this.header,
      advertisement: {
        id: this.state.id,
        paidadvertiser: this.paidAdvertiserDetails.id,
        uid: this.state.advertisementName,
        template: {
          templateid: this.state.templateType,
        },
      },
    };

    FetchServerData.callPostService('/paidadvertiseradsms/delete', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          this.props.history.push({
            pathname: '/paidAdvertisementList',
            advertisementId: this.state.id,
          });
        } else {
        }
      })
      .catch((error) => {});
  };
  render() {
    if (this.state.action === 'list') {
      return this.advertisementList();
      // return this.createAdvertisement();
    } else if (this.state.action === 'create') {
      return this.createAdvertisement();
    } else if (this.state.action === 'edit') {
      return this.editAdvertisement();
    } else if (this.state.action === 'view') {
      return this.viewAdvertisement();
    } else if (this.state.action === 'viewTemplate') {
      return this.viewAdvertisementWithImage();
    }
  }

  viewImagesTemplate() {
    this.template1Ref.current.state.action = 'viewTemplate';
    this.setState({
      action: 'viewTemplate',
      title: 'Advertisement Details',
      // advertisementId: this.state.id,
    });
  }
  advertisementList() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <AdminMenuWithBreadScrum
            backCallback={this.viewCancel.bind(this)}
            deleteCallback={this.deleteAdvertisementToBackend.bind(this)}
            getDeletePermission={this.state.getDeletePermission}
            breadCrumb={this.state.breadCrumb}
            history={this.props.history}
            name={'Create Commercial'}
            domainObject="Advertisement"
          />

          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={12} style={{ position: 'relative', padding: '5px' }}>
              <Card className="formCard">
                <div onChange={this.onChangeTypeValue.bind(this)} value={this.state.templateType}>
                  <Row style={{ margin: '21px 0px' }}>
                    <Col md={9}>
                      <img
                        // src={global.config.projectName + "/template1.png"}
                        src={template1}
                        alt=""
                        style={{ width: '761px' }}
                      />
                    </Col>
                    <Col md={3} style={{ margin: 'auto' }}>
                      <input type="radio" value="PATemplate1" name="type" id="PATemplate1" /> Template1
                    </Col>
                  </Row>
                  <Row style={{ margin: '21px 0px' }}>
                    <Col md={9}>
                      <img
                        // src={global.config.projectName + "/template4.png"}
                        src={template2}
                        alt=""
                        style={{ width: '761px' }}
                      />
                    </Col>
                    <Col md={3} style={{ margin: 'auto' }}>
                      <input id="PATemplate2" type="radio" value="PATemplate2" name="type" /> Template2
                    </Col>
                  </Row>
                </div>
                <div style={{ textAlign: 'right', padding: '0em 12em 1em' }}>
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      background: '#717373',
                      color: '#fff',
                      marginRight: '1rem',
                    }}
                    // onClick={this.toViewTemplate()}
                    onClick={(e) => this.toViewTemplate(e)}
                  >
                    Next
                  </Button>
                </div>
              </Card>
            </Col>
          </Row>
          {/* <div style={{ textAlign: "center", padding: "0em 0em 1em" }}>

            <Button
            variant="contained"
            size="small"
            style={{ background: '#717373', color: '#fff', marginRight: '1rem' }}
            // onClick={this.toViewTemplate()}
            onClick={(e) => this.toViewTemplate(e)}
            >
            Next
            </Button>
          </div> */}
        </>
      );
    }
  }

  createAdvertisement() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <AdminMenuWithBreadScrum
            backCallback={this.viewCancel.bind(this)}
            deleteCallback={this.deleteAdvertisementToBackend.bind(this)}
            breadCrumb={this.state.breadCrumb}
            history={this.props.history}
            name={'Create Commercial'}
            domainObject="Advertisement"
          />

          <ValidatorForm style={{ width: '100%' }} ref="form" onSubmit={this.addAdvertisementToBackend.bind(this)}>
            <Row style={{ position: 'relative', margin: '1em' }}>
              <Col md={12} style={{ position: 'relative', padding: '5px' }}>
                {this.state.showPATemplate1 === true ? (
                  <>
                    <Card className="formCard">
                      <Row style={{ position: 'relative', margin: '1em' }}>
                        <Col md={4} style={{ marginLeft: 'auto', marginRight: ' auto' }}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            required
                            fullWidth
                            id="advertisementName"
                            type="text"
                            onChange={this.handleAdvertisementNameChange.bind(this)}
                            label="Advertisement Name"
                            name="advertisementName"
                            // inputProps={{
                            //   maxLength: 128,
                            //   style: { textTransform: 'capitalize' },
                            // }}
                            style={{ background: '#fff' }}
                            value={this.state.advertisementName}
                          />
                        </Col>
                      </Row>
                      <Row style={{ position: 'relative', margin: '1em' }}>
                        <Col md={12}>
                          <PATemplate1
                            action="preView"
                            ref={this.template1Ref}
                            onChange={this.handlePATemplate1RefChange.bind(this)}
                            data={this.state.advertisementTemplateDetails}
                            advertisementId={this.state.id}
                          />
                        </Col>
                      </Row>
                    </Card>
                  </>
                ) : (
                  <span style={{ display: 'none' }}></span>
                )}
                {this.state.showPATemplate2 === true ? (
                  <>
                    <Card className="formCard">
                      <Row style={{ position: 'relative', margin: '1em' }}>
                        <Col md={4} style={{ marginLeft: 'auto', marginRight: ' auto' }}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            required
                            fullWidth
                            id="advertisementName"
                            type="text"
                            onChange={this.handleAdvertisementNameChange.bind(this)}
                            label="Advertisement Name"
                            name="advertisementName"
                            // inputProps={{
                            //   maxLength: 128,
                            //   style: { textTransform: 'capitalize' },
                            // }}
                            style={{ background: '#fff' }}
                            value={this.state.advertisementName}
                          />
                        </Col>
                      </Row>
                      <Row style={{ position: 'relative', margin: '1em' }}>
                        <Col md={12}>
                          <PATemplate2
                            action="preView"
                            ref={this.template1Ref}
                            onChange={this.handlePATemplate1RefChange.bind(this)}
                            data={this.state.advertisementTemplateDetails}
                            advertisementId={this.state.id}
                          />
                        </Col>
                      </Row>
                    </Card>
                  </>
                ) : (
                  <span style={{ display: 'none' }}></span>
                )}
              </Col>
            </Row>
            {/* </CardBody> */}
            <div style={{ textAlign: 'center', padding: '0em 0em 1em' }}>
              <Button
                type="submit"
                variant="contained"
                size="small"
                style={{
                  background: '#ef6603',
                  color: '#fff',
                  marginRight: '1rem',
                }}
                startIcon={<ArrowForwardIcon />}
              >
                Next
              </Button>
              &nbsp;
              <CancelIconButton onClick={(e) => this.createCancel()} />
            </div>
          </ValidatorForm>
        </>
      );
    }
  }

  viewAdvertisementWithImage() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <AdminMenuWithBreadScrum
            backCallback={this.viewCancel.bind(this)}
            deleteCallback={this.deleteAdvertisementToBackend.bind(this)}
            editCallback={this.editAdvertisementDetails.bind(this)}
            getDeletePermission={this.state.getDeletePermission}
            getUpdatePermission={this.state.getUpdatePermission}
            breadCrumb={this.state.breadCrumb}
            history={this.props.history}
            name={'Commercial Details'}
            domainObject="Advertisement"
          />

          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={12} className="sponsorDetailColumn" style={{ position: 'relative', padding: '5px' }}>
              <Card style={{ height: '100%' }}>
                <>
                  <CardHeader className="cardHeader">
                    <Row>
                      <Col md={10}>
                        <Label className="cardHeaderTitle">
                          {/* Advertisement Details */}
                          {this.state.advertisementName}
                        </Label>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {this.state.showPATemplate1 === true ? (
                      <>
                        <PATemplate1
                          action="viewTemplate"
                          ref={this.template1Ref}
                          data={this.state.advertisementTemplateDetails}
                          advertisementId={this.state.id}
                        />
                      </>
                    ) : (
                      <span style={{ display: 'none' }}></span>
                    )}
                    {this.state.showPATemplate2 === true ? (
                      <>
                        {' '}
                        <PATemplate2
                          action="viewTemplate"
                          ref={this.template1Ref}
                          data={this.state.advertisementTemplateDetails}
                          advertisementId={this.state.id}
                        />
                      </>
                    ) : (
                      <span style={{ display: 'none' }}></span>
                    )}
                  </CardBody>
                </>
              </Card>
            </Col>
          </Row>

          <div className="sponsorDetailFooter">
            <BackButton
              type="button"
              style={{ backgroundColor: '#162c50', color: '#fff' }}
              variant="contained"
              onClick={(e) => this.viewCancel()}
            >
              Back
            </BackButton>
          </div>
        </>
      );
    }
  }

  viewAdvertisement() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <AdminMenuWithBreadScrum
            backCallback={this.viewCancel.bind(this)}
            deleteCallback={this.deleteAdvertisementToBackend.bind(this)}
            editCallback={this.editAdvertisementDetails.bind(this)}
            getDeletePermission={this.state.getDeletePermission}
            getUpdatePermission={this.state.getUpdatePermission}
            breadCrumb={this.state.breadCrumb}
            history={this.props.history}
            name={'Commercial Details'}
            domainObject="Advertisement"
          />

          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={12} className="sponsorDetailColumn" style={{ position: 'relative', padding: '5px' }}>
              <Card style={{ height: '100%' }}>
                <>
                  <CardHeader className="cardHeader">
                    <Row>
                      <Col md={10}>
                        <Label className="cardHeaderTitle">
                          {/* Advertisement Details */}
                          {this.state.advertisementName}
                        </Label>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {this.state.showPATemplate1 === true ? (
                      <>
                        <PATemplate1
                          action="view"
                          ref={this.template1Ref}
                          data={this.state.advertisementTemplateDetails}
                          advertisementId={this.state.id}
                        />
                      </>
                    ) : (
                      <span style={{ display: 'none' }}></span>
                    )}
                    {this.state.showPATemplate2 === true ? (
                      <>
                        {' '}
                        <PATemplate2
                          action="view"
                          ref={this.template1Ref}
                          data={this.state.advertisementTemplateDetails}
                          advertisementId={this.state.id}
                        />
                      </>
                    ) : (
                      <span style={{ display: 'none' }}></span>
                    )}
                  </CardBody>
                </>
              </Card>
            </Col>
          </Row>

          {/* <div className="sponsorDetailFooter">
            <BackButton
              type="button"
              style={{ backgroundColor: '#162c50', color: '#fff' }}
              variant="contained"
              onClick={(e) => this.viewCancel()}
            >
              Back
            </BackButton>
          </div> */}
          <div className="sponsorDetailFooter">
            <Button
              type="button"
              style={{
                background: '#ef6603',
                color: '#fff',
                marginRight: '1rem',
              }}
              variant="contained"
              onClick={(e) => this.viewImagesTemplate()}
            >
              Next
            </Button>
          </div>
        </>
      );
    }
  }

  editAdvertisement() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <AdminMenuWithBreadScrum
            breadCrumb={this.state.breadCrumb}
            history={this.props.history}
            name={'Edit Commercial'}
            domainObject="Advertisement"
          />
          <ValidatorForm style={{ width: '100%' }} ref="form" onSubmit={this.updateAdvertisementToBackend.bind(this)}>
            <Row style={{ position: 'relative', margin: '1em' }}>
              <Col md={12} style={{ position: 'relative', padding: '5px' }}>
                <Card className="formCard">
                  {this.state.showPATemplate1 === true ? (
                    <>
                      <Row style={{ position: 'relative', margin: '1em' }}>
                        <Col md={4}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            required
                            fullWidth
                            id="advertisementName"
                            type="text"
                            onChange={this.handleAdvertisementNameChange.bind(this)}
                            label="Advertisement Name"
                            name="advertisementName"
                            inputProps={{
                              maxLength: 128,
                              style: { textTransform: 'capitalize' },
                            }}
                            style={{ background: '#fff' }}
                            value={this.state.advertisementName}
                          />
                        </Col>
                      </Row>
                      <Row style={{ position: 'relative', margin: '1em' }}>
                        <Col md={12}>
                          <PATemplate1
                            action="edit"
                            ref={this.template1Ref}
                            advertisementId={this.state.id}
                            onChange={this.handlePATemplate1RefChange.bind(this)}
                            data={this.state.advertisementTemplateDetails}
                          />
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <span style={{ display: 'none' }}></span>
                  )}
                  {this.state.showPATemplate2 === true ? (
                    <>
                      <Row style={{ position: 'relative', margin: '1em' }}>
                        <Col md={4}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            required
                            fullWidth
                            id="advertisementName"
                            type="text"
                            onChange={this.handleAdvertisementNameChange.bind(this)}
                            label="Advertisement Name"
                            name="advertisementName"
                            inputProps={{
                              maxLength: 128,
                              style: { textTransform: 'capitalize' },
                            }}
                            style={{ background: '#fff' }}
                            value={this.state.advertisementName}
                          />
                        </Col>
                      </Row>
                      <Row style={{ position: 'relative', margin: '1em' }}>
                        <Col md={12}>
                          <PATemplate2
                            action="edit"
                            ref={this.template1Ref}
                            advertisementId={this.state.id}
                            onChange={this.handlePATemplate1RefChange.bind(this)}
                            data={this.state.advertisementTemplateDetails}
                          />
                        </Col>
                      </Row>
                    </>
                  ) : (
                    <span style={{ display: 'none' }}></span>
                  )}
                </Card>
              </Col>
            </Row>
            {/* </CardBody> */}

            <div style={{ textAlign: 'center', padding: '0em 0em 1em' }}>
              <SaveButton id="loginButton" type="submit">
                Create
              </SaveButton>
              &nbsp;
              <CancelIconButton onClick={(e) => this.editCancel()} />
            </div>
          </ValidatorForm>
        </>
      );
    }
  }
}

export default PaidAdvertisement;
