import React, { Component } from 'react';
import PageAlertContext from '../pageAlert/PageAlertContext';
import NavDivider from './components/NavDivider';
import NavDropdownItem from './components/NavDropdownItem';
import NavOverlay from './components/NavOverlay';
import NavSingleItem from './components/NavSingleItem';
import NavSpacer from './components/NavSpacer';

export default class SidebarNav extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    const navItems = (items) => {
      return items.map((item, index) => itemType(item, index));
    };

    const itemType = (item, index) => {
      if (item.children) {
        return <NavDropdownItem key={index} item={item} isSidebarCollapsed={this.props.isSidebarCollapsed} />;
      } else if (item.divider) {
        return <NavDivider key={index} />;
      } else {
        return <NavSingleItem item={item} key={index} />;
      }
    };

    return (
      <PageAlertContext.Consumer>
        {(consumer) => {
          const hasPageAlertClass = consumer.alert ? 'has-alert' : '';
          return (
            <div>
              <div
                className={`app-sidebar ${hasPageAlertClass}`}
                style={{
                  marginTop: '3.7em',

                  transition: 'width 0.3s linear',
                }}
              >
                <nav>
                  <ul id="main-menu">
                    {navItems(this.props.nav.top)}
                    <NavSpacer />
                    {navItems(this.props.nav.bottom)}
                  </ul>
                </nav>
              </div>
              {this.props.isSidebarCollapsed && <NavOverlay onClick={this.props.toggleSidebar} />}
            </div>
          );
        }}
      </PageAlertContext.Consumer>
    );
  }
}
