import firebase from 'firebase/app';
import 'firebase/messaging';
import appUserAction from '../redux/appUser/appUserAction';
import communicationThreadsAction from '../redux/communicationThreads/communicationThreadsAction';
import store from '../redux/store';
const firebaseConfig = {
  apiKey: 'AIzaSyCO2k7R-kotRfWaMGRP31t1A1va_ozGJkk',
  authDomain: 'fcc-a4c51.firebaseapp.com',
  projectId: 'fcc-a4c51',
  storageBucket: 'fcc-a4c51.appspot.com',
  messagingSenderId: '521569837847',
  appId: '1:521569837847:web:1e072a50aee1fbfbe30d52',
  measurementId: 'G-YVZKJ91DB5',
};
// const apiKey = 'BMD5vtY9_xCOEeOkgJ1goRnoOtL3ymk3Sp6plkqWUex0onIkyv-kKY0kNm4rMNIeYwd-UNAdUopFxWbn2ItV8lg';
firebase.initializeApp(firebaseConfig);
export const requestFirebaseNotificationPermission = () =>
  new Promise((resolve, reject) => {
    if (firebase) {
      const messaging = firebase.messaging();
      /* Get registration token. Initially this makes a network call, once retrieved
       subsequent calls to getToken will return from cache. */
      messaging
        .getToken({
          vapidKey: 'BDHkAuwpPoyL4kSv5U7K0Vsqv4EUFnUWhxFcyo_AucE7clRNzRcqPZpl77jLuNrYYYvhyyFFGxjaCMgWUTfC7gM',
        })
        .then((currentToken) => {
          if (currentToken) {
            /*Save Token to Store*/
            store.dispatch(appUserAction.setFCMToken(currentToken));

            // ...
            if (!Notification) {
              alert('FCC Uses Desktop Notification, It is not enabled in your browser! Please enable it');
              return;
            }
            if (Notification.permission !== 'granted') {
              Notification.requestPermission();
            }
            resolve(currentToken);
          } else {
            // Show permission request UI
            reject('No registration token available. Request permission to generate one.');
            // ...
          }
        })
        .catch((err) => {
          reject(err);
          // ...
        });
    }
  });

export const onMessageListener = () => {
  if (firebase && firebase.messaging) {
    firebase.messaging().onMessage((payload) => {
      let notificationTitle = payload.notification.title;

      let notificationOptions = {
        body: payload.notification.body,
        icon: '/FCC.png',
        // image: '/firebase-logo.png',
        click_action: 'https://familycarecircle.com/',
      };
      var notification = new Notification(notificationTitle, notificationOptions);
      let memberId = payload.data.MemberId;
      let appointmentId = payload.data.DomainObjectId;
      let noteId = payload.data.SubDomainObjectId;
      let messageType = payload.data.MessageType;
      let threadId = payload.data.MsgThreadId;
      let messageData = [];
      messageData.push(payload.data);
      store.dispatch(communicationThreadsAction.setCommunicationThreads(messageData));

      notification.addEventListener('click', function () {
        if (messageType === 'Appointment') {
          window.open(
            'https://familycarecircle.com/#/viewMemberAppointment?appointmentId=' +
              appointmentId +
              '&noteId=' +
              noteId +
              '&memberId=' +
              memberId +
              '',
            '_self'
          );
          // window.open(
          //   'http://localhost:8000/#/viewMemberAppointment?appointmentId=' +
          //     appointmentId +
          //     '&noteId=' +
          //     noteId +
          //     '&memberId=' +
          //     memberId +
          //     '',
          //   '_blank'
          // );
        } else if (messageType === 'Appointment Reminder') {
          window.open(
            'https://familycarecircle.com/#/viewMemberAppointment?appointmentId=' +
              appointmentId +
              '&noteId=' +
              noteId +
              '&memberId=' +
              memberId +
              '',
            '_self'
          );
          // window.open(
          //   'http://localhost:8000/#/viewMemberAppointment?appointmentId=' +
          //     appointmentId +
          //     '&noteId=' +
          //     noteId +
          //     '&memberId=' +
          //     memberId +
          //     '',
          //   '_blank'
          // );
        } else if (messageType === 'General') {
          window.open(
            'https://familycarecircle.com/#/viewCommunication?threadId=' +
              threadId +
              '&threadId=' +
              noteId +
              '&memberId=' +
              memberId +
              '',
            '_self'
          );
          // window.open(
          //   'http://localhost:8000/viewCommunication?threadId=' +
          //     threadId +
          //     '&threadId=' +
          //     noteId +
          //     '&memberId=' +
          //     memberId +
          //     '',
          //   '_blank'
          // );
        } else if (messageType === 'Event') {
          window.open(
            'https://familycarecircle.com/#/viewEvent?eventId=' +
              threadId +
              '&threadId=' +
              noteId +
              '&memberId=' +
              memberId +
              '',
            '_self'
          );
          // window.open(
          //   'http://localhost:8000/viewEvent?eventId=' +
          //     threadId +
          //     '&noteId=' +
          //     noteId +
          //     '&memberId=' +
          //     memberId +
          //     '',
          //   '_blank'
          // );
        } else if (messageType === 'Purchase') {
          window.open(
            'https://familycarecircle.com/#/viewPurchase?purchaseId=' +
              appointmentId +
              '&threadId=' +
              noteId +
              '&memberId=' +
              memberId +
              '',
            '_self'
          );
          // window.open(
          //   'http://localhost:8000/viewPurchase?purchaseId=' +
          //     appointmentId +
          //     '&noteId=' +
          //     noteId +
          //     '&memberId=' +
          //     memberId +
          //     '',
          //   '_blank'
          // );
        } else if (messageType === 'Member Request') {
          window.open(
            'https://familycarecircle.com/#/home',

            '_self'
          );
          // window.open(
          //   'http://localhost:8000/viewPurchase?purchaseId=' +
          //     appointmentId +
          //     '&noteId=' +
          //     noteId +
          //     '&memberId=' +
          //     memberId +
          //     '',
          //   '_blank'
          // );
        } else {
          window.open(
            'https://familycarecircle.com/#/viewIncident?incidentId=' +
              appointmentId +
              '&noteId=' +
              noteId +
              '&memberId=' +
              memberId +
              '',
            '_self'
          );
          // window.open(
          //   'http://localhost:8000/viewIncident?incidentId=' +
          //     appointmentId +
          //     '&noteId=' +
          //     noteId +
          //     '&memberId=' +
          //     memberId +
          //     '',
          //   '_self'
          // );
        }
      });

      return payload;
    });
  }
};

export const registerServiceWorker = () => {
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker
      .register('firebase-messaging-sw.js')
      .then(function (registration) {
        return registration.scope;
      })
      .catch(function (err) {
        return err;
      });
  }
};

export default {
  requestFirebaseNotificationPermission,
  onMessageListener,
  registerServiceWorker,
};
