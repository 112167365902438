import {
  CLEAR_CONSOLIDATED_MEMBER_DATA,
  GET_CONSOLIDATED_MEMBER_DATA,
  SET_CONSOLIDATED_MEMBER_DATA,
} from './consolidatedMemberType';

const getConsolidatedMember = () => {
  return {
    type: GET_CONSOLIDATED_MEMBER_DATA,
  };
};

const setConsolidatedMember = (data) => {
  return {
    type: SET_CONSOLIDATED_MEMBER_DATA,
    payload: data,
  };
};

const clearConsolidatedMember = (data) => {
  return {
    type: CLEAR_CONSOLIDATED_MEMBER_DATA,
    payload: data,
  };
};
const consolidatedMemberAction = { getConsolidatedMember, setConsolidatedMember, clearConsolidatedMember };

export default consolidatedMemberAction;
