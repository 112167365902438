import React, { Component } from 'react';
import { Card, CardBody, CardTitle, Col, Row } from 'reactstrap';
import './AdminDashboardNav.scss';

export class AdminDashboardNav extends Component {
  constructor(props) {
    super(props);
    let action = props.action;
    let pageId = '';
    if (
      null !== props.history &&
      props.history !== undefined &&
      props.history.location !== null &&
      props.history.location.state !== null &&
      props.history.location.state !== undefined
    ) {
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
    }
    this.state = {
      email: '',
      password: '',
      showPassword: false,
      action: action,
      pageId: pageId,
    };
  }
  render() {
    return (
      <>
        <div className="header" style={{ marginTop: '1em' }}>
          {/* <Container fluid> */}
          <div className="header-body">
            {/* Card stats */}

            <Row style={{ margin: '0em 1.3em 1em 0em' }}>
              <Col md={2} style={{ marginRight: '3em' }}>
                <Card
                  className="card-stats mb-4 mb-xl-0 "
                  style={{ background: '#feaa85', width: '250px', height: '96px' }}
                >
                  <CardBody className="navCarBody">
                    <Row>
                      <div className="col">
                        <CardTitle tag="p" className="font-weight-bold text-white" style={{ fontSize: '17px' }}>
                          Total Users
                        </CardTitle>
                        <span className="h4 font-weight-bold  text-white">
                          {this.props.data ? this.props.data.totalusers : ''}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-danger text-white rounded-circle shadow" id="iconStyle">
                          {' '}
                          <i className="fa fa-pie-chart" id="headerIcon" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={2} style={{ marginRight: '3em' }}>
                <Card
                  className="card-stats mb-4 mb-xl-0"
                  style={{ background: '#0bd891', width: '250px', height: '96px' }}
                >
                  <CardBody className="navCarBody">
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="p"
                          className="font-weight-bold text-white text-white"
                          style={{ fontSize: '17px' }}
                        >
                          Caregiver Circles
                        </CardTitle>
                        <span className="h4 font-weight-bold text-white">
                          {' '}
                          {this.props.data ? this.props.data.activecircles : ''}{' '}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div className="icon icon-shape bg-info text-white rounded-circle shadow" id="iconStyle">
                          <i className="fa fa-bar-chart" id="headerIcon" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={2} style={{ marginRight: '3em' }}>
                <Card
                  className="card-stats mb-4 mb-xl-0"
                  style={{ background: '#fe5f72', width: '250px', height: '96px' }}
                >
                  <CardBody className="navCarBody">
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="p"
                          className="font-weight-bold text-white text-white"
                          style={{ fontSize: '17px' }}
                        >
                          Free Users
                        </CardTitle>
                        <span className="h4 font-weight-bold text-white">
                          {this.props.data ? this.props.data.freeusers : ''}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div
                          className="icon icon-shape bg-yellow text-white rounded-circle shadow"
                          id="iconStyle"
                          style={{ background: '#ffd600' }}
                        >
                          <i className="fa fa-users" id="headerIcon" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={2} style={{ marginRight: '3em' }}>
                <Card
                  className="card-stats mb-4 mb-xl-0"
                  style={{ background: '#01acaf', width: '250px', height: '96px' }}
                >
                  <CardBody className="navCarBody">
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="p"
                          className="font-weight-bold text-white text-white"
                          style={{ fontSize: '17px' }}
                        >
                          Subscribed Users
                        </CardTitle>
                        <span className="h4 font-weight-bold text-white">
                          {this.props.data ? this.props.data.subscribedusers : ''}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div
                          className="icon icon-shape text-white rounded-circle shadow"
                          id="iconStyle"
                          style={{ background: '#fb6340' }}
                        >
                          <i className="fa fa-percent" id="headerIcon" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={2} style={{ marginRight: '3em' }}>
                <Card
                  className="card-stats mb-4 mb-xl-0"
                  style={{ background: '#b4b416ab', width: '250px', height: '96px' }}
                >
                  <CardBody className="navCarBody">
                    <Row>
                      <div className="col">
                        <CardTitle
                          tag="p"
                          className="font-weight-bold text-white text-white"
                          style={{ fontSize: '17px' }}
                        >
                          Sponsored Users
                        </CardTitle>
                        <span className="h4 font-weight-bold text-white">
                          {this.props.data ? this.props.data.sponsoredusers : ''}
                        </span>
                      </div>
                      <Col className="col-auto">
                        <div
                          className="icon icon-shape  text-white rounded-circle shadow"
                          id="iconStyle"
                          style={{ background: '#8E44AD' }}
                        >
                          <i className="fa fa-globe" id="headerIcon" />
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}

export default AdminDashboardNav;
