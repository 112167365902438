import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import store from '../../../redux/store';
import FccAdvertisement from '../fccAdvertisement/FccAdvertisement';
import Template1 from '../sponsorShipTemplate/AdTemplate1/Template1';
import Template2 from '../sponsorShipTemplate/AdTemplate2/Template2';
import Template3 from '../sponsorShipTemplate/AdTemplate3/Template3';
import Template4 from '../sponsorShipTemplate/AdTemplate4/Template4';
import './SponsorShipTemplate.scss';
class SponsorShipTemplate extends Component {
  sponsorship = store.getState().Sponsorship.SponsorshipDetails;

  constructor(props) {
    let str = props.pageId;
    let urlArr = str.split('/');
    let pageId = urlArr[1];
    super(props);
    this.state = {
      sponsorship: this.sponsorship,
      pageId: props.pageId,
      render: true,
    };
  }

  render() {
    let advertisementList = this.state.sponsorship ? this.state.sponsorship.advertisementList : [];
    let advertisementListMapping = this.state.sponsorship.pageAdvertisementMappingList;
    let advertisementId = -1;
    let myTemplate = {};
    for (let i = 0; i < advertisementListMapping.length; i++) {
      if (advertisementListMapping[i].fccpage.name.toLowerCase() === this.state.pageId.toLowerCase()) {
        advertisementId = advertisementListMapping[i].advertisementId;
      }
    }
    if (advertisementList.length > 0) {
      for (let i = 0; i < advertisementList.length; i++) {
        if (advertisementId !== -1 && advertisementId !== null) {
          if (advertisementList[i].id === advertisementId) {
            myTemplate = advertisementList[i];
          }
        } else {
          myTemplate = null;
        }
      }
    }
    if (myTemplate && myTemplate.template) {
      if (myTemplate.template.templateid === 'Template1') {
        return this.template1(myTemplate);
      } else if (myTemplate.template.templateid === 'Template2') {
        return this.template2(myTemplate);
      } else if (myTemplate.template.templateid === 'Template3') {
        return this.template3(myTemplate);
      } else if (myTemplate.template.templateid === 'Template4') {
        return this.template4(myTemplate);
      } else {
        return this.template5();
      }
    } else {
      return (
        <>
          <FccAdvertisement advertisementType="Horizontal" />
        </>
      );
    }
  }

  template1(myTemplate) {
    return (
      <>
        {' '}
        <Template1 data={myTemplate} />{' '}
      </>
    );
  }

  template2(myTemplate) {
    return (
      <>
        <Template2 data={myTemplate} />
      </>
    );
  }

  template3(myTemplate) {
    return (
      <>
        <Template3 data={myTemplate} />
      </>
    );
  }

  template4(myTemplate) {
    return (
      <>
        <Template4 data={myTemplate} />
      </>
    );
  }

  template5() {
    return (
      <>
        <Row>
          <Col md={12}></Col>
        </Row>
      </>
    );
  }
}
export default SponsorShipTemplate;
