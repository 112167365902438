import { GET_ADMIN_STATIC_DATA, SET_ADMIN_STATIC_DATA } from './adminStaticDataTypes';

const initialState = {
  adminCountryList: [],
};

const adminStaticDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMIN_STATIC_DATA:
      return {
        ...state,
      };
    case SET_ADMIN_STATIC_DATA:
      return {
        ...state,
        adminCountryList: action.payload.countryList,
      };

    default:
      return state;
  }
};

export default adminStaticDataReducer;
