import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import store from '../../../../redux/store';
import '../AdTemplate4/Template4.scss';
class Template4 extends Component {
  // sponsorUser = store.getState().Sponsorship.Sponsorship;
  header = store.getState().header.header;
  advertisementObject = {
    heading: 'Heading',
    subHeading: 'Subheading',
    message: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
    website: '',
  };
  constructor(props) {
    // let pageId = props.pageId;
    let action = props.action;
    let advertisementDetails = props.data;
    super(props);
    this.state = {
      action: action,
      sponsorUser: this.sponsorUser,
      // pageId: pageId,
      heading: advertisementDetails ? advertisementDetails.template.heading : '',
      subHeading: advertisementDetails ? advertisementDetails.template.subheading : '',
      message: advertisementDetails ? advertisementDetails.template.message : '',
      website: advertisementDetails ? advertisementDetails.template.website : '',

      action: this.props.action,
      onChange: this.props.onChange,
      imagePosition: '',
      leftImageFile: this.props.data ? this.props.data.template.leftimage : '',
      backgroundImageFile: this.props.data ? this.props.data.template.backgroundimage : '',
      showRemoveButton: false,
    };
  }

  render() {
    return (
      <>
        {this.state.backgroundImageFile !== '' ? (
          <>
            {this.state.website !== '' ? (
              <div
                className="row"
                style={{
                  margin: 'auto',
                  alignItems: 'center',
                  display: 'flex',
                  alignContent: 'center',
                  textAlign: 'center',
                }}
              >
                <a
                  // href={this.state.website}
                  href={this.state.website.includes('https://') || this.state.website.includes('http://')
                    ? this.state.website : 'http://' + this.state.website}
                  target="_blank"
                  style={{ display: 'flex', textAlign: 'center', margin: 'auto' }}
                >
                  <img
                    src={`data:image/png;base64,${this.state.backgroundImageFile}`}
                    width="100%"
                    // maxHeight="100px"
                    style={{
                      maxHeight: '100px',
                      // maxwidth: '1100px',
                      display: 'block',
                    }}
                  />
                </a>
              </div>
            ) : (
              <div
                className="row"
                style={{
                  margin: 'auto',
                  alignItems: 'center',
                  display: 'flex',
                  alignContent: 'center',
                  textAlign: 'center',
                }}
              >
                <img
                  src={`data:image/png;base64,${this.state.backgroundImageFile}`}
                  width="100%"
                  // maxHeight="100px"
                  style={{
                    maxHeight: '100px',
                    // maxwidth: '1100px',
                    display: 'block',
                  }}
                />
              </div>
            )}
          </>
        ) : (
          <>
            {this.state.website !== '' ? (
              <div
                className="row"
                style={{
                  margin: 'auto',
                  alignItems: 'center',
                  display: 'flex',
                  alignContent: 'center',
                  textAlign: 'center',
                }}
              >
                <a
                  // href={this.state.website}
                  href={this.state.website.includes('https://') || this.state.website.includes('http://')
                  ? this.state.website : 'http://' + this.state.website}
                  target="_blank"
                  style={{ display: 'flex', textAlign: 'center', margin: 'auto' }}
                ></a>
              </div>
            ) : (
              <div
                className="row"
                style={{
                  margin: 'auto',
                  alignItems: 'center',
                  display: 'flex',
                  alignContent: 'center',
                  textAlign: 'center',
                }}
              >
                <a
                  href={this.state.website}
                  target="_blank"
                  style={{ display: 'flex', textAlign: 'center', margin: 'auto' }}
                ></a>
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

export default Template4;
