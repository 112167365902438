import Button from '@material-ui/core/Button';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import MaterialTable from '@material-table/core';
import React, { Component } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { Row, Col, Modal, ModalBody, ModalFooter } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import UiUtils from '../../../provider/UiUtils';
import Utils from '../../../provider/Utils';
import applicationStateAction from '../../../redux/applicationState/applicationStateAction';
import fccPageStateAction from '../../../redux/fccPageState/fccPageStateAction';
import helpDataAction from '../../../redux/help/displayHelpAction';
import store from '../../../redux/store';
import Advertisement from '../advertisement/Advertisement';
import AddIconButton from '../buttons/AddIconButton';
import FccAdvertisement from '../fccAdvertisement/FccAdvertisement';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
import PtsAlert from '../ptsAlert/PtsAlert';
import SponsorShipTemplate from '../sponsorShipTemplate/SponsorShipTemplate';
import Checkbox from '@material-ui/core/Checkbox';
import Fab from '@material-ui/core/Fab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import './FamilyCircleList.scss';
import ConsentForm from '../consentForm/ConsentForm';
import BreadCrumbDetailsAction from '../../../redux/breadCrumbDetails/BreadCrumbDetailsAction';
export class FamilyCircleList extends Component {
  title = 'My Caregiver Circle';
  memberName = store.getState().memberData.memberData.firstname + ' ' + store.getState().memberData.memberData.lastname;
  familyCircle = store.getState().applicationState.familyCircleState;
  sponsorUser = store.getState().Sponsorship.Sponsorship;
  memberHomeUrl = store.getState().memberHomeUrl.memberHomeUrl.homeUrl;
  header = store.getState().header.header;
  isFreeUser = store.getState().userData.userData.isFreeUser;

  breadCrumb = [
    { pageid: this.memberHomeUrl, uiname: this.memberName },
    { pageid: '/familyCircle', uiname: 'Caregiver Circle List' },
  ];
  memberDetails = store.getState().memberData.memberData;
  memberId = store.getState().userData.userData.memberId;
  userId = store.getState().userData.userData.memberId;
  displayHelp = store.getState().help.help;

  constructor(props) {
    super(props);
    let calendarState = {};
    store.dispatch(
      applicationStateAction.setApplicationState({
        calendarState,
      })
    );
    let familyCircle = {};
    if (this.familyCircle) {
      familyCircle = Object.getOwnPropertyNames(this.familyCircle);
    }
    // if (this.breadCrumb) {
    //   Utils.getActiveMenu(this.breadCrumb);
    //   store.dispatch(BreadCrumbDetailsAction.setBreadCrumbDetails(this.breadCrumb));
    // }
    store.dispatch(fccPageStateAction.setFccPageState({}));
    /*current URL path for sponsorship template*/
    let currentLocationUrl = window.location.href;
    let pathArray = currentLocationUrl.split('/');
    let host = pathArray[4];
    let currentLocationPath = '/' + host;

    if (familyCircle.length > 0) {
      this.state = {
        familyCircleList: [],
        memberId: this.memberDetails.member,
        render: false,
        verifiedFamilyMemberList: [],
        unRegisterFamilyMemberList: [],
        rejectedFamilyMemberList: [],
        requestedFamilyMemberList: [],
        pageSize: this.familyCircle.pageSize && this.familyCircle.pageSize ? this.familyCircle.pageSize : 0,
        isUnregisterPaging: false,
        selectedTab: this.familyCircle.selectedTab,
        initialPage:
          this.familyCircle.initialPage && this.familyCircle.initialPage > 0 ? this.familyCircle.initialPage : 0,
        membershipId: this.familyCircle.membershipId,
        getAllPermission: false,
        getCreatePermission: false,
        getReadPermission: false,
        isFreeUser: this.isFreeUser,
        sponsorUser: this.sponsorUser,
        currentLocationPath: currentLocationPath,
        isAllPaging: false,
        isVerifiedPaging: false,
        isRequestPaging: false,
        isRejectPaging: false,
        stepsEnabled: false,
        initialStep: 0,
        steps: [
          {
            element: '.tabAll',
            intro: 'List of all Caregivers. Clicking on name, you can see details of that caregiver. ',
          },
          {
            element: '.tabAccepted',
            intro: 'List of the Caregivers who are already registered to FCC and have “Accepted” your request.',
          },
          {
            element: '.tabRequested',
            intro: 'List of the Caregivers who have not yet responded to your request.',
          },
          {
            element: '.tabRejected ',
            intro: ' List of the Caregivers who have rejected your request.',
          },
          {
            element: '.tabNotYet ',
            intro: 'List of the Caregivers who have not yet registered on FCC',
          },
          {
            element: '.MuiTableRow-hover',
            intro:
              'Click on the row to view/update details of Caregiver. You can update details or delete Caregiver here.',
          },
          {
            element: '.caregiver',
            intro: 'Add Caregiver',
          },
          {
            element: '.resendAction',
            intro:
              'Resend Request – <br>' +
              '' +
              ' Resend request to join your Caregiver circle for those who have rejected/not yet accepted your earlier request',
          },
        ],
        displayFloatButton: this.displayHelp,
        consentFormModal: false,
        rejectRequest: false,
        acceptRequest: true,
      };
      this.getFamilyCircleList();
    } else {
      this.state = {
        familyCircleList: [],
        render: false,
        memberId: this.memberDetails.member,
        verifiedFamilyMemberList: [],
        unRegisterFamilyMemberList: [],
        rejectedFamilyMemberList: [],
        requestedFamilyMemberList: [],
        pageSize: 10,
        isUnregisterPaging: false,
        selectedTab: 0,
        membershipId: '',
        initialPage: 0,
        getAllPermission: false,
        getCreatePermission: false,
        getReadPermission: false,
        isFreeUser: this.isFreeUser,
        sponsorUser: this.sponsorUser,
        currentLocationPath: currentLocationPath,
        isAllPaging: false,
        isVerifiedPaging: false,
        isRequestPaging: false,
        isRejectPaging: false,
        stepsEnabled: false,
        initialStep: 0,
        steps: [
          {
            element: '.tabAll',
            intro: 'List of all Caregivers. Clicking on name, you can see details of that caregiver. ',
          },
          {
            element: '.tabAccepted',
            intro: 'List of the Caregivers who are already registered to FCC and have “Accepted” your request.',
          },
          {
            element: '.tabRequested',
            intro: 'List of the Caregivers who have not yet responded to your request.',
          },
          {
            element: '.tabRejected ',
            intro: ' List of the Caregivers who have rejected your request.',
          },
          {
            element: '.tabNotYet ',
            intro: 'List of the Caregivers who have not yet registered on FCC',
          },
          {
            element: '.MuiTableRow-hover',
            intro:
              'Click on the row to view/update details of Caregiver. You can update details or delete Caregiver here.',
          },
          {
            element: '.caregiver',
            intro: 'Add Caregiver',
          },

          {
            element: '.resendAction',
            intro:
              'Resend Request – <br>' +
              '' +
              ' Resend request to join your Caregiver circle for those who have rejected/not yet accepted your earlier request',
          },
        ],
        displayFloatButton: this.displayHelp,
        consentFormModal: false,
        rejectRequest: false,
        acceptRequest: true,
      };
      this.getFamilyCircleList();
    }
  }

  getFamilyCircleList() {
    const header = store.getState().header.header;
    const postObject = {
      header: header,
      familyMember: {
        memberId: this.memberDetails.member,
      },
    };
    FetchServerData.callPostService('/caregiverms/getMemberCaregivers', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let familyCircleListArr = output.data.familyMemberList;

        let getDomainObjectPrivileges = output.data.domainObjectPrivileges;
        let getCreatePermission = false;

        for (let domainObjectKey in getDomainObjectPrivileges) {
          // let keyPermission = domainObjectKey;
          if (domainObjectKey.toLowerCase() === 'caregiver') {
            let familyMemberPermission = getDomainObjectPrivileges[domainObjectKey];
            let getPermission = Utils.getDomainObjectRolePermission(familyMemberPermission);
            getCreatePermission = getPermission[0];
          }
        }
        let verifiedFamilyMember = [];
        let unRegisterFamilyMember = [];
        let rejectedFamilyMember = [];
        let requestedFamilyMember = [];
        let isUnregisterPaging = false;
        let isAllPaging = false;
        let isVerifiedPaging = false;
        let isRequestPaging = false;
        let isRejectPaging = false;
        for (let i = 0; i < familyCircleListArr.length; i++) {
          familyCircleListArr[i].rowNum = i + 1;
          if (familyCircleListArr[i].registrationStatus === 'Accepted') {
            verifiedFamilyMember.push(familyCircleListArr[i]);
          } else if (familyCircleListArr[i].registrationStatus === 'Not Yet Registered') {
            unRegisterFamilyMember.push(familyCircleListArr[i]);
          } else if (familyCircleListArr[i].registrationStatus === 'Rejected') {
            rejectedFamilyMember.push(familyCircleListArr[i]);
          } else {
            requestedFamilyMember.push(familyCircleListArr[i]);
          }
        }
        if (verifiedFamilyMember.length > 10) {
          isVerifiedPaging = true;
        }
        if (unRegisterFamilyMember.length > 10) {
          isUnregisterPaging = true;
        }
        if (rejectedFamilyMember.length > 10) {
          isRejectPaging = true;
        }
        if (requestedFamilyMember.length > 10) {
          isRequestPaging = true;
        }
        if (familyCircleListArr.length > 10) {
          isAllPaging = true;
        }
        this.setState({
          isUnregisterPaging: isUnregisterPaging,
          isAllPaging: isAllPaging,
          isVerifiedPaging: isVerifiedPaging,
          isRequestPaging: isRequestPaging,
          isRejectPaging: isRejectPaging,
          verifiedFamilyMemberList: verifiedFamilyMember,
          unRegisterFamilyMemberList: unRegisterFamilyMember,
          rejectedFamilyMemberList: rejectedFamilyMember,
          requestedFamilyMemberList: requestedFamilyMember,
          familyCircleList: familyCircleListArr,
          initialPage: this.state.initialPage,
          render: true,
          getCreatePermission: getCreatePermission,
        });
      } else {
      }
    });
  }

  toggleSteps = () => {
    this.setState((prevState) => ({ stepsEnabled: !prevState.stepsEnabled }));
  };

  toFamilyMemberDetails = (e, data) => {
    if (data.registrationStatus !== 'Not Yet Registered') {
      let familyCircleState = {
        selectedTab: this.state.selectedTab,
        initialPage: this.state.initialPage,
        membershipId: data.id,
        pageSize: this.state.pageSize,
      };
      store.dispatch(
        applicationStateAction.setApplicationState({
          familyCircleState,
        })
      );
      let fccPageState = {
        id: data.id,
        action: 'view',
        title: 'FamilyCircle Details',
      };
      store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
      this.props.history.push({
        pathname: '/viewFamilyMember',
        state: {
          action: 'view',
          title: 'Caregiver  Details',
          membershipId: data.id,
          familyMemberData: data,
          familyMemberStatus: data.registrationStatus,
        },
      });
    } else {
      let familyCircleState = {
        selectedTab: this.state.selectedTab,
        initialPage: this.state.initialPage,
        membershipId: data.id,
        pageSize: this.state.pageSize,
      };
      store.dispatch(
        applicationStateAction.setApplicationState({
          familyCircleState,
        })
      );
      this.props.history.push({
        pathname: '/viewFamilyMember',
        state: {
          action: 'unRegisterView',
          title: 'Caregiver  Details',
          membershipId: data.id,
          familyMemberData: data,
          familyMemberStatus: data.registrationStatus,
        },
      });
    }
  };

  toSendRequest = (e, familyMemberId) => {
    const postObject = {
      header: this.header,
      memberVo: {
        id: this.memberDetails.member,
        familyMemberMemberId: familyMemberId,
      },
    };

    FetchServerData.callPostService('/memberms/resendRequest', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          PtsAlert.success(output.message);
          this.getFamilyCircleList();
          this.props.history.push({
            pathname: '/familyCircle',
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  addFamilyMember = (e) => {
    this.props.history.push({
      pathname: '/addFamilyMember',
      state: {
        action: 'create',
        title: 'Add Caregiver ',
        pageId: 'addfamilyMember',
      },
    });
  };
  setKey(tab) {
    this.setState({
      selectedTab: tab,
    });
    let familyCircleState = {
      selectedTab: tab,
      initialPage: this.state.initialPage,
      membershipId: this.state.membershipId,
    };
    store.dispatch(applicationStateAction.setApplicationState({ familyCircleState }));
  }
  onTablePageChange(page) {
    this.setState({
      initialPage: page,
    });
    let familyCircleState = {
      selectedTab: this.state.selectedTab,
      initialPage: page,
      membershipId: this.state.membershipId,
    };
    store.dispatch(
      applicationStateAction.setApplicationState({
        familyCircleState,
      })
    );
  }

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };

  closeFloatingButton() {
    this.setState({ displayFloatButton: false });
    let displayFloat = false;

    store.dispatch(helpDataAction.setHelpData(displayFloat));
  }

  displayActionTitle() {
    return <h5 className="resendAction">Action</h5>;
  }

  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }
  showActionButton(rowData) {
    console.log(rowData);
    return (
      <>
        <span style={{ display: 'flex' }}>
          {rowData.requestRaisedby === 'M' && rowData.showResendRequest === true ? (
            <>
              <Button
                type="submit"
                className="resendRequest"
                style={{
                  backgroundColor: '#1a7088',
                  color: '#fff',
                  fontSize: 'xx-small',
                  textTransform: 'capitalize',
                  height: '3.5em',
                  width: '11em',
                  padding: '0em',
                }}
                variant="contained"
                onClick={(e) => this.toSendRequest(e, rowData.id)}
              >
                Resend Request
              </Button>
              &nbsp; &nbsp;
            </>
          ) : (
            ' '
          )}
          {rowData.requestRaisedby === 'FM' &&
          rowData.memberStatus === 'Inactive' &&
          rowData.registrationStatus !== 'Rejected' ? (
            <>
              <Button
                size="small"
                variant="contained"
                value={this.state.acceptRequest}
                onClick={(e) => this.acceptConsentForm(e, rowData.id)}
                style={{
                  backgroundColor: '#27AE60',
                  color: '#fff',
                  fontSize: 'small',
                  height: '2.5em',
                  width: '7em',
                  padding: '0.5em',
                }}
              >
                Accept
              </Button>
              &nbsp; &nbsp;
              <Button
                size="small"
                variant="contained"
                value={this.state.rejectRequest}
                onClick={(e) => this.rejectMemberRequestInBackend(rowData.id)}
                style={{
                  backgroundColor: '#EC7063',
                  color: '#fff',
                  fontSize: 'small',
                  height: '2.5em',
                  width: '7em',
                  padding: '0.5em',
                }}
              >
                Reject
              </Button>
            </>
          ) : (
            ' '
          )}
        </span>
        {/* </Col>
        </Row> */}
      </>
    );
  }

  consentFormToggle = () => {
    this.setState({
      consentFormModal: !this.state.consentFormModal,
    });
  };
  acceptConsentForm = (e, familyMemberMemberId) => {
    this.setState({
      consentFormModal: true,
      familyMemberMemberId: familyMemberMemberId,
    });
  };

  rejectMemberRequestInBackend(id) {
    let requestMemberId = id;
    const postObject = {
      header: this.header,
      memberVo: {
        familyMemberMemberId: requestMemberId,
        isActive: this.state.rejectRequest,
      },
    };
    FetchServerData.callPostService('memberms/rejectMembershipRequest', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        PtsAlert.success(output.message);

        this.setState({
          render: true,
          consentFormModal: false,
        });
        this.getFamilyCircleList();
      } else {
      }
    });
  }

  acceptMemberRequestInBackend(e, id) {
    let requestMemberId = id;
    if (this.state.checkbox) {
      const postObject = {
        header: this.header,
        memberVo: {
          familyMemberMemberId: requestMemberId,
          isActive: this.state.acceptRequest,
        },
      };
      FetchServerData.callPostService('memberms/acceptMembershipRequest', postObject).then((output) => {
        if (output.status === 'SUCCESS') {
          let memberData = output.data;
          PtsAlert.success(output.message);

          this.setState({
            render: true,
            consentFormModal: false,
          });

          this.getFamilyCircleList();
        } else {
        }
      });
    } else {
      document.getElementById('acceptTerms').focus();
    }
  }

  handleClickCheckBox = (e) => {
    if (e.target.checked === 'true') {
      this.setState({
        checkbox: e.target.checked,
        isDisabled: false,
      });
    } else {
      this.setState({
        checkbox: e.target.checked,
        isDisabled: true,
      });
    }
  };
  render() {
    if (this.state.render === false) {
      return <div styles={{ minHeight: '100vh' }}></div>;
    } else {
      return (
        <>
          <MenuWithBreadScrum breadCrumb={this.breadCrumb} history={this.props.history} name={this.title} />
          <Row style={{ margin: '1em' }}>
            <Advertisement pageId="CaregiverCircle" />
          </Row>

          <Steps
            enabled={this.state.stepsEnabled}
            steps={this.state.steps}
            initialStep={this.state.initialStep}
            onExit={this.onExit}
          />
          <Row style={{ padding: '1em 2em' }}>
            <Col md={12} className="familyCircleTab" style={{ width: '100%' }}>
              {this.state.getAllPermission === true || this.state.getCreatePermission === true ? (
                <Row style={{ backgroundColor: 'none', margin: '-0.5em -1.3em -2.4em' }}>
                  <Col md={12}>
                    <AddIconButton btnName="Add Caregiver" onClick={(e) => this.addFamilyMember(e)} />
                  </Col>
                </Row>
              ) : (
                <></>
              )}
              <Tabs defaultIndex={this.state.selectedTab} onSelect={(k) => this.setKey(k)}>
                <TabList>
                  <Tab>
                    {' '}
                    <span className="tabAll"> All</span>
                  </Tab>

                  <Tab>
                    <span className="tabAccepted"> Accepted</span>
                  </Tab>

                  <Tab>
                    <span className="tabRequested">Requested</span>
                  </Tab>

                  <Tab>
                    <span className="tabRejected">Rejected</span>
                  </Tab>

                  <Tab>
                    <span className="tabNotYet">Not Yet Registered</span>
                  </Tab>
                </TabList>
                <TabPanel>
                  <MaterialTable
                    onRowClick={(event, rowData) => {
                      this.toFamilyMemberDetails(event, rowData);
                      event.stopPropagation();
                    }}
                    icons={UiUtils.getMaterialTableIcons()}
                    title=""
                    localization={{
                      body: {
                        emptyDataSourceMessage: Utils.communizeTableText(),
                      },
                    }}
                    columns={[
                      { title: '#', field: 'rowNum', width: '5%', filtering: false },
                      {
                        title: 'Name',
                        field: 'name',
                        cellStyle: { textTransform: 'capitalize', textAlign: 'left' },
                        headerStyle: { textAlign: 'left' },
                        width: '20%',
                      },

                      {
                        title: 'Email',
                        field: 'email',
                        cellStyle: { textTransform: 'none' },
                        headerStyle: { textAlign: 'left' },
                        width: '25%',
                      },
                      {
                        title: 'Phone',
                        render: (rowData) =>
                          rowData.phone !== null ? Utils.displayUsaPhoneNumberFormate(rowData.phone) : '',
                        width: '10%',
                      },
                      { title: 'Profile', field: 'role', width: '10%' },
                      {
                        title: 'Caregiver Status',
                        field: 'memberStatus',
                        width: '10%',
                        filtering: false,
                      },

                      {
                        title: this.displayActionTitle(),
                        disableClick: true,
                        sorting: false,
                        width: '20%',
                        render: (rowData) => this.showActionButton(rowData),
                        /*if status is requested or rejected and member Id is equal to login user id then show the button*/
                      },
                    ]}
                    data={this.state.familyCircleList}
                    options={{
                      sorting: true,
                      actionsColumnIndex: -1,

                      pageSize: this.state.pageSize,
                      initialPage: this.state.initialPage,
                      pageSizeOptions: [5, 10, 20, 50, 100],
                      headerStyle: {
                        backgroundColor: '#1ABC9C',
                        fontWeight: 'bold',
                        color: '#fff',
                        textAlign: 'center',
                      },

                      filtering: true,
                      rowStyle: (rowData) => ({
                        backgroundColor: rowData.id === this.state.membershipId ? '#FEF5E7' : '#fff',
                        height: '3em',
                        textTransform: 'capitalize',
                      }),
                      // paging: this.state.isAllPaging,
                      paging: this.state.familyCircleList.length > this.state.pageSize,
                    }}
                    actions={[]}
                    onPageChange={(page, pageSize) => {
                      this.handleMaterialTablePageChange(page, pageSize);
                    }}
                  />
                </TabPanel>
                <TabPanel>
                  <MaterialTable
                    onRowClick={(event, rowData) => {
                      this.toFamilyMemberDetails(event, rowData);
                      event.stopPropagation();
                    }}
                    icons={UiUtils.getMaterialTableIcons()}
                    title=""
                    localization={{
                      body: {
                        emptyDataSourceMessage: Utils.communizeTableText(),
                      },
                    }}
                    columns={[
                      {
                        title: '#',
                        render: (rowData) => rowData.tableData.index + 1,
                        width: '5%',
                      },
                      {
                        title: 'Name',
                        field: 'name',
                        cellStyle: { textTransform: 'capitalize', textAlign: 'left' },
                        headerStyle: { textAlign: 'left' },
                        width: '20%',
                      },

                      {
                        title: 'Email',
                        field: 'email',
                        cellStyle: { textTransform: 'none' },
                        headerStyle: { textAlign: 'left' },
                        width: '25%',
                      },

                      {
                        title: 'Phone',
                        render: (rowData) =>
                          rowData.phone !== null ? Utils.displayUsaPhoneNumberFormate(rowData.phone) : '',
                        width: '20%',
                      },
                      { title: 'Profile', field: 'role', width: '15%' },
                      {
                        title: 'Caregiver Status',
                        field: 'memberStatus',
                        width: '20%',
                      },
                    ]}
                    data={this.state.verifiedFamilyMemberList}
                    options={{
                      sorting: true,
                      actionsColumnIndex: -1,
                      pageSize: this.state.pageSize,
                      initialPage: this.state.initialPage,
                      pageSizeOptions: [5, 10, 20, 50, 100],
                      headerStyle: {
                        backgroundColor: '#1ABC9C',
                        fontWeight: 'bold',
                        color: '#fff',
                        textAlign: 'center',
                      },

                      filtering: true,
                      rowStyle: (rowData) => ({
                        backgroundColor: rowData.id === this.state.membershipId ? '#FEF5E7' : '#fff',
                        height: '3em',
                        textTransform: 'capitalize',
                      }),
                      paging: this.state.verifiedFamilyMemberList.length > this.state.pageSize,
                      // paging: this.state.isVerifiedPaging,
                    }}
                    actions={[]}
                    onPageChange={(page, pageSize) => {
                      this.handleMaterialTablePageChange(page, pageSize);
                    }}
                  />
                </TabPanel>
                <TabPanel>
                  <MaterialTable
                    onRowClick={(event, rowData) => {
                      this.toFamilyMemberDetails(event, rowData);
                      event.stopPropagation();
                    }}
                    icons={UiUtils.getMaterialTableIcons()}
                    title=""
                    localization={{
                      body: {
                        emptyDataSourceMessage: Utils.communizeTableText(),
                      },
                    }}
                    columns={[
                      {
                        title: '#',
                        render: (rowData) => rowData.tableData.index + 1,
                        width: '5%',
                      },
                      {
                        title: 'Name',
                        field: 'name',
                        cellStyle: { textTransform: 'capitalize', textAlign: 'left' },
                        headerStyle: { textAlign: 'left' },
                        width: '20%',
                      },

                      {
                        title: 'Email',
                        field: 'email',
                        cellStyle: { textTransform: 'none' },
                        headerStyle: { textAlign: 'left' },
                        width: '25%',
                      },

                      {
                        title: 'Phone',
                        render: (rowData) =>
                          rowData.phone !== null ? Utils.displayUsaPhoneNumberFormate(rowData.phone) : '',
                        width: '20%',
                      },
                      { title: 'Profile', field: 'role', width: '10%' },
                      {
                        title: 'Caregiver Status',
                        field: 'memberStatus',
                        width: '10%',
                      },

                      {
                        title: 'Action',
                        disableClick: true,
                        sorting: false,
                        render: (rowData) => this.showActionButton(rowData),
                        /*if status is requested or rejected and member Id is equal to login user id then show the button*/

                        width: '15%',
                      },
                    ]}
                    data={this.state.requestedFamilyMemberList}
                    options={{
                      sorting: true,
                      actionsColumnIndex: -1,
                      pageSize: this.state.pageSize,
                      initialPage: this.state.initialPage,
                      pageSizeOptions: [5, 10, 20, 50, 100],
                      headerStyle: {
                        backgroundColor: '#1ABC9C',
                        fontWeight: 'bold',
                        color: '#fff',
                        textAlign: 'center',
                      },

                      filtering: true,
                      rowStyle: (rowData) => ({
                        backgroundColor: rowData.id === this.state.membershipId ? '#FEF5E7' : '#fff',
                        height: '3em',
                        textTransform: 'capitalize',
                      }),
                      // paging: this.state.isRequestPaging,
                      paging: this.state.requestedFamilyMemberList.length > this.state.pageSize,
                    }}
                    actions={[]}
                    onPageChange={(page, pageSize) => {
                      this.handleMaterialTablePageChange(page, pageSize);
                    }}
                  />
                </TabPanel>
                <TabPanel>
                  <MaterialTable
                    onRowClick={(event, rowData) => {
                      this.toFamilyMemberDetails(event, rowData);
                      event.stopPropagation();
                    }}
                    icons={UiUtils.getMaterialTableIcons()}
                    title=""
                    localization={{
                      body: {
                        emptyDataSourceMessage: Utils.communizeTableText(),
                      },
                    }}
                    columns={[
                      {
                        title: '#',
                        render: (rowData) => rowData.tableData.index + 1,
                        width: '5%',
                      },
                      {
                        title: 'Name',
                        field: 'name',
                        cellStyle: { textTransform: 'capitalize', textAlign: 'left' },
                        headerStyle: { textAlign: 'left' },
                        width: '20%',
                      },

                      {
                        title: 'Email',
                        field: 'email',
                        cellStyle: { textTransform: 'none' },
                        headerStyle: { textAlign: 'left' },
                        width: '25%',
                      },

                      {
                        title: 'Phone',
                        render: (rowData) =>
                          rowData.phone !== null ? Utils.displayUsaPhoneNumberFormate(rowData.phone) : '',
                        width: '20%',
                      },
                      { title: 'Profile', field: 'role', width: '10%' },
                      {
                        title: 'Caregiver Status',
                        field: 'memberStatus',
                        width: '10%',
                      },

                      {
                        title: 'Action',
                        disableClick: true,
                        sorting: false,
                        render: (rowData) => this.showActionButton(rowData),
                        /*if status is requested or rejected and member Id is equal to login user id then show the button*/

                        width: '15%',
                      },
                    ]}
                    data={this.state.rejectedFamilyMemberList}
                    options={{
                      sorting: true,
                      actionsColumnIndex: -1,
                      pageSize: this.state.pageSize,
                      initialPage: this.state.initialPage,
                      pageSizeOptions: [5, 10, 20, 50, 100],
                      headerStyle: {
                        backgroundColor: '#1ABC9C',
                        fontWeight: 'bold',
                        color: '#fff',
                        textAlign: 'center',
                      },

                      filtering: true,
                      rowStyle: (rowData) => ({
                        backgroundColor: rowData.id === this.state.membershipId ? '#FEF5E7' : '#fff',
                        height: '3em',
                        textTransform: 'capitalize',
                      }),
                      // paging: this.state.isRejectPaging,
                      paging: this.state.rejectedFamilyMemberList.length > this.state.pageSize,
                    }}
                    actions={[]}
                    onPageChange={(page, pageSize) => {
                      this.handleMaterialTablePageChange(page, pageSize);
                    }}
                  />
                </TabPanel>
                <TabPanel>
                  <MaterialTable
                    onRowClick={(event, rowData) => {
                      this.toFamilyMemberDetails(event, rowData);
                      event.stopPropagation();
                    }}
                    icons={UiUtils.getMaterialTableIcons()}
                    title=""
                    localization={{
                      body: {
                        emptyDataSourceMessage: Utils.communizeTableText(),
                      },
                    }}
                    columns={[
                      {
                        title: '#',
                        render: (rowData) => rowData.tableData.index + 1,
                        width: '5%',
                      },
                      {
                        title: 'Name',
                        field: 'name',
                        cellStyle: { textTransform: 'capitalize', textAlign: 'left' },
                        headerStyle: { textAlign: 'left' },
                        width: '20%',
                      },

                      {
                        title: 'Email',
                        field: 'email',
                        cellStyle: { textTransform: 'none' },
                        headerStyle: { textAlign: 'left' },
                        width: '25%',
                      },

                      {
                        title: 'Phone',
                        render: (rowData) =>
                          rowData.phone !== null ? Utils.displayUsaPhoneNumberFormate(rowData.phone) : '',
                        width: '20%',
                      },
                      { title: 'Profile', field: 'role', width: '10%' },
                      {
                        title: 'Caregiver Status',
                        field: 'memberStatus',
                        width: '10%',
                      },

                      {
                        title: 'Action',
                        disableClick: true,
                        sorting: false,
                        render: (rowData) => this.showActionButton(rowData),
                        /*if status is requested or rejected and member Id is equal to login user id then show the button*/

                        width: '15%',
                      },
                    ]}
                    data={this.state.unRegisterFamilyMemberList}
                    options={{
                      sorting: true,
                      actionsColumnIndex: -1,
                      pageSize: this.state.pageSize,
                      initialPage: this.state.initialPage,
                      pageSizeOptions: [5, 10, 20, 50, 100],
                      headerStyle: {
                        backgroundColor: '#1ABC9C',
                        fontWeight: 'bold',
                        color: '#fff',
                        textAlign: 'center',
                      },

                      filtering: true,
                      rowStyle: (rowData) => ({
                        backgroundColor: rowData.id === this.state.membershipId ? '#FEF5E7' : '#fff',
                        height: '3em',
                        textTransform: 'capitalize',
                      }),
                      // paging: this.state.isUnregisterPaging,
                      paging: this.state.unRegisterFamilyMemberList.length > this.state.pageSize,
                    }}
                    actions={[]}
                    onPageChange={(page, pageSize) => {
                      this.handleMaterialTablePageChange(page, pageSize);
                    }}
                  />
                </TabPanel>
              </Tabs>
            </Col>
          </Row>

          <Modal isOpen={this.state.consentFormModal} toggle={this.consentFormToggle} size="xl">
            <Row style={{ marginTop: '1em' }}>
              <Col md={11}></Col>
              <Col md={1}>
                <Fab
                  aria-label="add"
                  style={{
                    float: 'left',
                    fontSize: 'small',
                    height: '15px',
                    width: '35px',
                    background: '#76767d',
                    cursor: 'pointer',
                    color: 'white',

                    border: '2px solid #edf0f1',
                  }}
                  id="myRequestIcon"
                >
                  <CloseIcon onClick={(e) => this.consentFormToggle()} />
                </Fab>
              </Col>
            </Row>
            <ModalBody>
              <Row>
                <Col md={12}>
                  <ConsentForm></ConsentForm>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Row md={12} style={{ margin: '0.5em', alignItems: 'center', width: '100%' }}>
                <Col md={6} style={{ textAlign: 'center' }}>
                  <FormControlLabel
                    id="acceptTerms"
                    control={
                      <Checkbox
                        style={{ color: 'black' }}
                        checked={this.state.checkbox}
                        onChange={this.handleClickCheckBox}
                      />
                    }
                    label="I accept the consent form"
                    style={{ color: '#162c50', fontWeight: 'bold' }}
                  />
                </Col>
                <Col md={6} style={{ textAlign: 'center' }}>
                  {this.state.checkbox && this.state.checkbox === true && (
                    <Button
                      variant="contained"
                      size="small"
                      style={{
                        background: '#ef6603',
                        color: '#fff',
                      }}
                      startIcon={<ArrowForwardIcon />}
                      onClick={(e) => this.acceptMemberRequestInBackend(e, this.state.familyMemberMemberId)}
                    >
                      Accept
                    </Button>
                  )}
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    variant="contained"
                    size="small"
                    style={{ backgroundColor: '#777777', color: '#fff' }}
                    startIcon={<CancelIcon />}
                    onClick={(e) => this.consentFormToggle()}
                  >
                    Decline
                  </Button>
                </Col>
              </Row>
            </ModalFooter>
          </Modal>
        </>
      );
    }
  }
}

export default FamilyCircleList;
