import { CLEAR_PAID_ADVERTISER, GET_PAID_ADVERTISER, SET_PAID_ADVERTISER } from './paidAdvertiserDetailsType';
const initialState = {
  paidAdvertiserDetails: {},
};

const paidAdvertiserDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_PAID_ADVERTISER:
      return {
        ...state,
      };
    case SET_PAID_ADVERTISER:
      return {
        ...state,
        paidAdvertiserDetails: action.payload,
      };

    case CLEAR_PAID_ADVERTISER:
      return {
        ...state,
        paidAdvertiserDetails: null,
      };
    default:
      return state;
  }
};

export default paidAdvertiserDetailsReducer;
