import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import Utils from '../../../../provider/Utils';
import '../AdTemplate1/Template1.scss';
class Template1 extends Component {
  constructor(props) {
    // let pageId = props.pageId;
    let action = props.action;
    let advertisementDetails = props.data;
    console.log('this.props.data.leftimage', props.data);
    super(props);
    this.state = {
      action: action,

      heading: advertisementDetails ? advertisementDetails.template.heading : '',
      subHeading: advertisementDetails ? advertisementDetails.template.subheading : '',
      message: advertisementDetails ? advertisementDetails.template.message : '',
      website: advertisementDetails ? advertisementDetails.template.website : '',
      action: this.props.action,
      onChange: this.props.onChange,
      imagePosition: '',
      leftImageFile: this.props.data ? this.props.data.leftimage : '',
      rightImageFile: this.props.data ? this.props.data.rightimage : '',
      showRemoveButton: false,
      backgroundImageFile: this.props.data ? this.props.data.template.backgroundimage : '',

      render: true,
    };
  }

  render() {
    return (
      <div
        className="banner-container"
        id="banner-container1"
        style={{
          display: 'flex',
          width: '100%',

          overflowy: 'scroll',
          overflowx: 'hidden',
        }}
      >
        <div
          className="banner"
          style={{
            display: 'flex',
            backgroundImage: `url(${`data:image/png;base64,${this.state.backgroundImageFile}`})`,
            minHeight: '135px',
          }}
        >
          <div className="shoe" style={{ width: '20%' }}>
            {this.props.data.template.leftimage !== '' && this.props.data.template.leftimage !== null ? (
              <Row style={{ marginBottom: '0.5em' }}>
                <Col md={12} className="customer-img">
                  <img
                    src={`data:image/jpeg;base64,${this.props.data.template.leftimage}`}
                    style={{ height: '50px', width: '50%' }}
                    alt=""
                  />
                </Col>
              </Row>
            ) : (
              <Row style={{ marginBottom: '0.5em', display: 'none' }}>
                <Col md={12} className="customer-img">
                  <img
                    src={`data:image/jpeg;base64,${this.props.data.template.leftimage}`}
                    style={{ height: '100px', width: '100%', display: 'none' }}
                    alt=""
                  />
                </Col>
              </Row>
            )}
          </div>
          <div className="content" style={{ width: '60%' }}>
            <h4>{this.props.data.template.heading}</h4>
            <h3>{this.props.data.template.subheading}</h3>
            <p style={{ color: '#F4D03F' }}>{this.props.data.template.message}</p>
            <a
              href={this.props.data.template.website}
              target="_blank"
              style={{ color: '#1700ffa8', textTransform: 'lowercase', cursor: 'pointer' }}
            >
              {this.props.data.template.website}
            </a>
          </div>

          <div className="women" style={{ width: '20%' }}>
            {/* <img src={nameImage} alt="name" /> */}
            {this.props.data.template.rightimage !== '' && this.props.data.template.rightimage !== null ? (
              <Row style={{ marginBottom: '0.5em' }}>
                <Col md={12} className="customer-img">
                  <img
                    src={`data:image/jpeg;base64,${this.props.data.template.rightimage}`}
                    style={{ height: '50px', width: '50%' }}
                    alt=""
                  />
                </Col>
              </Row>
            ) : (
              <Row style={{ marginBottom: '0.5em', display: 'none' }}>
                <Col md={12} className="customer-img">
                  <img
                    src={`data:image/jpeg;base64,${this.props.data.template.rightimage}`}
                    style={{ height: '100px', width: '100%', display: 'none' }}
                    alt=""
                  />
                </Col>
              </Row>
            )}
          </div>
        </div>
      </div>
    );
  }
}
export default Template1;
