import Fab from '@material-ui/core/Fab';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import React from 'react';
import { Col, Modal, ModalBody, Row } from 'reactstrap';
import './assets/vendor/animate.css/animate.min.css';
import './assets/vendor/bootstrap/css/bootstrap.min.css';
import './assets/vendor/boxicons/css/boxicons.min.css';
import './assets/vendor/icofont/icofont.min.css';
import './assets/vendor/line-awesome/css/line-awesome.min.css';
import './assets/vendor/owl.carousel/assets/owl.carousel.min.css';
import './assets/vendor/remixicon/remixicon.css';
import './assets/vendor/venobox/venobox.css';
import CloseIcon from '@material-ui/icons/Close';
import PrintIconButton from '../buttons/PrintIconButton';
import Button from '@material-ui/core/Button';
import PrintIcon from '@material-ui/icons/Print';
import ReactToPrint from 'react-to-print';
import PrintRoundedIcon from '@material-ui/icons/PrintRounded';
import Tooltip from '@material-ui/core/Tooltip';
import Quill from './quill/Quill';
import FetchServerData from '../../../provider/FetchServerData';
export default class PrivacyPolicyWeb extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      menuList: false,
      render: false,
    };
    window.scrollTo(0, 0);
    this.getTemplateDetails();
  }
  toUp() {
    window.scrollTo(0, 0);
  }

  navigateToLink(linkToNavigate) {
    this.props.history.push(linkToNavigate);
  }
  openLoginTab = () => {
    const win = window.open('/#/login', '_blank');
    win.focus();
  };

  scrollToSection(elementID) {
    if (document.getElementById(elementID)) {
      document.getElementById(elementID).scrollIntoView();
    }
  }

  menuListToggle = () => {
    this.setState({
      menuList: !this.state.menuList,
    });
  };

  openMenu() {
    this.setState({
      menuList: true,
    });
  }
  printContent() {
    let printContents = document.getElementById('policySection').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();

    document.body.innerHTML = originalContents;
    this.setState({ render: true });
    document.location.reload(true);
  }

  getTemplateDetails() {
    let postObject = {};
    FetchServerData.callPostService('/apPrivacyPolicy/geActive', postObject)
      .then((response) => {
        let output = response;
        if (output.status === 'SUCCESS') {
          let templateDetails = output.data.privacyPolicy.data;
          this.setState({ templateDetails: templateDetails, render: true });
        }
      })
      .catch((error) => {});
  }
  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <div style={{ height: '100vh', overflowY: 'scroll' }}>
            <link
              href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0-alpha.6/css/bootstrap.min.css"
              rel="stylesheet"
            />
            <link href="//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css" rel="stylesheet" />
            {/* =======================================================
  * Template Name: Selecao - v2.3.1
  * Template URL: https://bootstrapmade.com/selecao-bootstrap-template/
  * Author: BootstrapMade.com
  * License: https://bootstrapmade.com/license/
  ======================================================== */}

            {/* ======= Header ======= */}
            <header id="header" className="fixed-top d-flex align-items-center  header-transparent ">
              <div className="container d-flex align-items-center">
                <div className="logo mr-auto">
                  <span style={{ display: 'flex' }}>
                    <img
                      src="../assets/img/fccLogo.png"
                      alt=""
                      style={{ height: '2.8em', width: '3em', marginTop: '-4px' }}
                    />
                    <h1 className="text-light" style={{ paddingLeft: '0.2em' }}>
                      <a href="home.html">Family Care Circle</a>
                    </h1>
                  </span>
                </div>
                <nav className="nav-menu d-none d-lg-block">
                  <ul>
                    <li>
                      <a style={{ color: '#fff', cursor: 'pointer' }} onClick={() => this.navigateToLink('/')}>
                        Home
                      </a>
                    </li>

                    <li>
                      <a
                        style={{ color: '#fff', cursor: 'pointer' }}
                        onClick={() => this.openLoginTab()}
                        target="_blank"
                      >
                        Login
                      </a>
                    </li>
                    <li>
                      <a style={{ color: '#fff', cursor: 'pointer' }} onClick={() => this.navigateToLink('/pricing')}>
                        Pricing
                      </a>
                    </li>
                    <li>
                      <a
                        style={{ color: '#fff', cursor: 'pointer' }}
                        onClick={() => this.navigateToLink('/BusinessPromotion')}
                      >
                        Sponsorship
                      </a>
                    </li>
                    <li>
                      <a style={{ color: '#fff', cursor: 'pointer' }} onClick={() => this.navigateToLink('/contactUs')}>
                        Contact
                      </a>
                    </li>
                  </ul>
                </nav>
                {/* .nav-menu */}
              </div>
            </header>
            <button
              type="button"
              class="mobile-nav-toggle d-lg-none"
              onClick={(e) => this.openMenu()}
              style={{ marginRight: '0.7em' }}
            >
              <i class="fa fa-bars" style={{ fontSize: '1.5em' }}></i>
            </button>
            {/* End Header */}

            <section id="policySection" className="contact" style={{ padding: '100px 50px 20px 50px' }}>
              <Row md={12} style={{ margin: '0.5em' }}>
                <Col style={{ textAlign: 'center' }}>
                  <ReactToPrint
                    trigger={() => (
                      <span style={{ cursor: 'pointer', fontSize: 'x-larger', marginRight: '2em', float: 'right' }}>
                        {/* <Tooltip title="Print">
                          <PrintRoundedIcon
                            style={{
                              borderRadius: '25%',
                              background: '#867e7e',
                              color: 'white',
                              padding: '3px',
                              fontSize: 'x-large',
                              width: '30px',
                              minHeight: '30px',
                            }}
                          />
                        </Tooltip> */}
                        <Tooltip title="Print">
                          <Button
                            className="add"
                            style={{
                              background: '#867e7e',
                              color: 'white',
                              fontSize: 'x-large',
                              textTransform: 'capitalize',
                              cursor: 'pointer',
                              fontSize: 'x-larger',
                              float: 'right',
                              margin: '0.2em 0.5em 0.3em 0em',
                            }}
                            startIcon={<PrintRoundedIcon />}
                          >
                            Print
                          </Button>
                        </Tooltip>
                      </span>
                    )}
                    content={() => this.componentRef}
                  />
                </Col>
              </Row>
              <div ref={(el) => (this.componentRef = el)}>
                <Quill
                  text={this.state.templateDetails}
                  className="templateMessageBox"
                  theme="bubble"
                  readOnly={true}
                />
                {/* <Row md={12} style={{ margin: '0.5em' }}>
                <Col style={{ textAlign: 'center' }}>
                  <span
                    style={{
                      textAlign: 'center',
                      color: '#000',
                      fontSize: 'large',

                      float: 'center',
                    }}
                  >
                    <strong>
                      <h3
                        style={{
                          color: '#000',
                        }}
                      >
                        <u>Privacy Policy</u>
                      </h3>
                    </strong>
                  </span>

                </Col>
              </Row>

              <Row md={12} style={{ margin: '0.5em' }}>
                <ul style={{ padding: '1em' }}>
                  <p>
                    At FamilyCareCircle.com, accessible from{' '}
                    <a href="https://familycarecircle.com">https://familycarecircle.com</a>, one of our main priorities
                    is the privacy of our visitors. This Privacy Policy document contains types of information that is
                    collected and recorded by FamilyCareCircle.com and how we use it.
                  </p>
                  <p>
                    FamilyCareCircle.com respects the privacy of visitors to this site. This Privacy Policy applies only
                    to our online activities and is valid for visitors to our website with regards to the information
                    that they shared and/or collect in{' '}
                    <a href="https://familycarecircle.com">https://familycarecircle.com</a>. This policy is not
                    applicable to any information collected offline or via channels other than this website. We have no
                    control over the privacy practices of other sites to which you may link from here.
                  </p>
                  <p>
                    If you have additional questions or require more information about our Privacy Policy, do not
                    hesitate to contact us.
                  </p>
                  <br />
                  <li>
                    <a name="consent"></a>
                    <span style={{ fontWeight: 'bold' }}>Consent</span>
                    <br />
                    <p>By using our website, you hereby consent to our Privacy Policy and agree to its terms.</p>
                  </li>
                  <br />

                  <li>
                    <a name="information"></a>
                    <span style={{ fontWeight: 'bold' }}>Information We Collect</span>
                    <br />
                    <p>
                      FamilyCareCircle.com collects only the personal information that you voluntarily and knowingly
                      provide to us. In order to use certain of our services, we require your email address and a
                      password. Any other personal information you choose to provide is purely voluntary and will not
                      affect your use of this site.
                    </p>
                    <p>
                      When you register for an Account, we may ask for your contact information, including items such as
                      name, address, email address, and telephone number.
                    </p>
                    <p>
                      If you contact us directly, we may receive additional information about you such as your name,
                      email address, phone number, the contents of the message and/or attachments you may send us, and
                      any other information you may choose to provide.
                    </p>
                  </li>
                  <br />
                  <li>
                    <a name="ack"></a>
                    <span style={{ fontWeight: 'bold' }}> How We Use Personal Information</span> <br />
                    <p>
                      {' '}
                      FamilyCareCircle.com and its affiliates may store and use your personal information for any of the
                      following purposes:
                      <ul style={{ paddingLeft: '2em' }}>
                        <li>Provide, operate, and maintain our website</li>
                        <li>Improve, personalize, and expand our website</li>
                        <li>Understand and analyse how you use our website</li>
                        <li>Develop new products, services, features, and functionality</li>
                        <li>
                          Communicate with you, either directly or through one of our partners, including for customer
                          service, to provide you with updates and other information relating to the website, and for
                          marketing and promotional purposes
                        </li>
                        <li>Send you email</li>
                        <li>Find and prevent fraud</li>
                        <li>To protect against unauthorized access to our site.</li>
                      </ul>
                      <br />
                      FAMILYCARECIRCLE.COM WILL NOT SELL OR LEASE YOUR PERSONAL INFORMATION TO ANY THIRD PARTY
                    </p>
                  </li>
                  <br />
                  <li>
                    <a name="logfiles"></a>
                    <span style={{ fontWeight: 'bold' }}>Log Files</span> <br />
                    <p>
                      <a href="https://familycarecircle.com">https://familycarecircle.com</a> follows a standard
                      procedure of using log files. These files log visitors when they visit websites. All hosting
                      companies do this and a part of hosting services' analytics. The information collected by log
                      files include internet protocol (IP) addresses, browser type, Internet Service Provider (ISP),
                      date and time stamp, referring/exit pages, and possibly the number of clicks. These are not linked
                      to any information that is personally identifiable. The purpose of the information is for
                      analysing trends, administering the site, tracking users' movement on the website, and gathering
                      demographic information.
                    </p>
                  </li>
                  <br />
                  <li>
                    <a name="ack"></a>
                    <span style={{ fontWeight: 'bold' }}>Cookies and Statistical Information</span> <br />
                    <p>
                      Like many other web sites, FamilyCareCircle.com uses “cookies” to facilitate automated activity,
                      to determine how visitors use our web site and to determine which features to add to our web site.
                      These cookies are used to store information including visitors' preferences, and the pages on the
                      website that the visitor accessed or visited. The information is used to optimize the users'
                      experience by customizing our web page content based on visitors' browser type and/or other
                      information.
                    </p>
                  </li>
                  <br />
                  <li>
                    <a name="ack"></a>
                    <span style={{ fontWeight: 'bold' }}> Google DoubleClick DART Cookie </span> <br />
                    <p>
                      Google is one of a third-party vendors on our site. It also uses cookies, known as DART cookies,
                      to serve ads to our site visitors based upon their visit to www.website.com and other sites on the
                      internet. However, visitors may choose to decline the use of DART cookies by visiting the Google
                      ad and content network Privacy Policy at the following URL –{' '}
                      <a href="https://policies.google.com/technologies/ads">
                        https://policies.google.com/technologies/ads
                      </a>
                    </p>
                  </li>
                  <br />
                  <li>
                    <a name="ack"></a>
                    <span style={{ fontWeight: 'bold' }}> Advertising Partners Privacy Policies </span> <br />
                    <p>
                      {' '}
                      Third-party ad servers or ad networks uses technologies like cookies, JavaScript, or Web Beacons
                      that are used in their respective advertisements and links that appear on{' '}
                      <a href="https://familycarecircle.com">https://familycarecircle.com</a>, which are sent directly
                      to users' browser. They automatically receive your IP address when this occurs. These technologies
                      are used to measure the effectiveness of their advertising campaigns and/or to personalize the
                      advertising content that you see on websites that you visit.
                    </p>
                    <p>
                      Note that <a href="https://familycarecircle.com">https://familycarecircle.com</a> has no access to
                      or control over these cookies that are used by third-party advertisers.
                    </p>
                  </li>
                  <br />
                  <li>
                    <a name="ack"></a>
                    <span style={{ fontWeight: 'bold' }}>Compliant with Network Advertising Initiative (NAI)</span>{' '}
                    <br />
                    <p>
                      <a href="https://familycarecircle.com">https://familycarecircle.com</a>'s Privacy Policy does not
                      apply to other advertisers or websites. Thus, we are advising you to consult the respective
                      Privacy Policies of these third-party ad servers for more detailed information. It may include
                      their practices and instructions about how to opt-out of certain options.
                    </p>
                    <p>
                      You can choose to disable cookies through your individual browser options. To know more detailed
                      information about cookie management with specific web browsers, it can be found at the browsers'
                      respective websites.
                    </p>
                  </li>
                  <br />
                  <li>
                    <a name="ack"></a>
                    <span style={{ fontWeight: 'bold' }}>
                      CCPA Privacy Rights (Do Not Sell My Personal Information)
                    </span>{' '}
                    <br />
                    <p>
                      {' '}
                      Under the CCPA, among other rights, California consumers have the right to:
                      <ul style={{ paddingLeft: '2em' }}>
                        <li>
                          {' '}
                          Request that a business that collects a consumer's personal data disclose the categories and
                          specific pieces of personal data that a business has collected about consumers.
                        </li>
                        <li>
                          Request that a business delete any personal data about the consumer that a business has
                          collected.
                        </li>
                        <li>
                          Request that a business that sells a consumer's personal data, not sell the consumer's
                          personal data.
                        </li>
                        <li>
                          If you make a request, we have one month to respond to you. If you would like to exercise any
                          of these rights, please contact us.
                        </li>
                      </ul>
                    </p>
                  </li>
                  <br />
                  <li>
                    <a name="ack"></a>
                    <span style={{ fontWeight: 'bold' }}>GDPR Data Protection Rights</span> <br />
                    <p>
                      {' '}
                      We would like to make sure you are fully aware of all of your data protection rights. Every user
                      is entitled to the following:
                      <ul style={{ paddingLeft: '2em' }}>
                        <li>
                          The right to access – You have the right to request copies of your personal data. We may
                          charge you a small fee for this service.
                        </li>
                        <li>
                          The right to rectification – You have the right to request that we correct any information you
                          believe is inaccurate. You also have the right to request that we complete the information you
                          believe is incomplete.
                        </li>
                        <li>
                          The right to erasure – You have the right to request that we erase your personal data, under
                          certain conditions.
                        </li>
                        <li>
                          The right to restrict processing – You have the right to request that we restrict the
                          processing of your personal data, under certain conditions.
                        </li>
                        <li>
                          The right to object to processing – You have the right to object to our processing of your
                          personal data, under certain conditions.
                        </li>
                        <li>
                          The right to data portability – You have the right to request that we transfer the data that
                          we have collected to another organization, or directly to you, under certain conditions.
                        </li>
                        <li>
                          If you make a request, we have one month to respond to you. If you would like to exercise any
                          of these rights, please contact us.
                        </li>
                      </ul>
                    </p>
                  </li>
                  <br />
                  <li>
                    <a name="ack"></a>
                    <span style={{ fontWeight: 'bold' }}> Payments </span> <br />
                    <p>
                      We may provide paid products and/or services within Service. In that case, we use third-party
                      services for payment processing (e.g. payment processors).
                    </p>
                    <p>
                      We will not store or collect your payment card details. That information is provided directly to
                      our third-party payment processors whose use of your personal information is governed by their
                      Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the
                      PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American
                      Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.
                    </p>
                  </li>
                  <br />
                  <li>
                    <a name="ack"></a>
                    <span style={{ fontWeight: 'bold' }}> Changes </span> <br />
                    <p>
                      From time to time, FamilyCareCircle.com may change this privacy policy. To keep up-to-date on any
                      changes, please check this site periodically. Changes to this Privacy Policy are effective when
                      they are posted on this page. If at any time you have a question about this privacy policy, please
                      Contact Us with your request.
                    </p>
                  </li>
                  <br />
                  <li>
                    <a name="ack"></a>
                    <span style={{ fontWeight: 'bold' }}> Contact Us </span> <br />
                    <p>
                      If you have any questions about this Privacy Policy, please contact us by email:{' '}
                      <a href="mailto">Support@FamilyCareCircle.com</a>.
                    </p>
                  </li>

                </ul>
              </Row> */}
              </div>
              <Row>
                <Col md={12}>
                  <span style={{ float: 'right' }}>
                    <a onClick={() => this.scrollToSection('policySection')} style={{ textDecoration: 'none' }}>
                      <Fab
                        aria-label="add"
                        style={{
                          float: 'right',
                          fontSize: 'small',
                          height: '15px',
                          width: '35px',
                          background: '#ef6603',
                          cursor: 'pointer',
                          color: 'white',
                          backgroundImage: '-webkit-linear-gradient(bottom, #843b07 0%, #f7b571 100%);',
                          border: '2px solid #edf0f1',
                        }}
                      >
                        <ArrowUpwardIcon />
                      </Fab>{' '}
                    </a>
                  </span>
                </Col>
              </Row>
              <Modal isOpen={this.state.menuList} toggle={this.menuListToggle} size="sm">
                <Row>
                  <Col md={12}>
                    <CloseIcon
                      onClick={(e) => this.menuListToggle()}
                      style={{ float: 'right', margin: '0.5em 1em 0em 0em' }}
                    />
                  </Col>
                </Row>
                <ModalBody>
                  <nav className="mobileViewNav">
                    <ul>
                      <li>
                        <a onClick={() => this.navigateToLink('/')}>Home</a>
                      </li>

                      <li>
                        <a onClick={() => this.openLoginTab()} target="_blank">
                          Login
                        </a>
                      </li>
                      <li>
                        <a onClick={() => this.navigateToLink('/pricing')}>Pricing</a>
                      </li>
                      <li>
                        <a onClick={() => this.navigateToLink('/BusinessPromotion')}>Sponsorship</a>
                      </li>
                      <li className="active">
                        <a onClick={() => this.navigateToLink('/contactUs')}>Contact</a>
                      </li>
                    </ul>
                  </nav>
                </ModalBody>
              </Modal>
            </section>

            {/* ======= Footer ======= */}
            <footer id="contactUsFooter">
              <div className="container">
                <div className="row">
                  <div className="col-lg-6">
                    <h3>Family Care Circle</h3>
                    <div className="social-links">
                      <a href="#" className="twitter">
                        <i className="fa fa-twitter" />
                      </a>
                      <a href="https://www.facebook.com/FamilyCareCircle" className="facebook" target="_blank">
                        <i className="fa fa-facebook-f" />
                      </a>
                      <a href="#" className="instagram">
                        <i className="fa fa-instagram" />
                      </a>
                      <a href="#" className="google-plus">
                        <i className="fa fa-skype" />
                      </a>
                      <a href="#" className="linkedin">
                        <i className="fa fa-linkedin" />
                      </a>
                    </div>
                    <div className="copyright">
                      © Copyright{' '}
                      <strong>
                        <span>Family Care Circle</span>
                      </strong>
                      . All Rights Reserved
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <ul style={{ float: 'right', listStyle: 'none', lineHeight: '3em', margin: '0 100px 0px 0' }}>
                      <li style={{ textAlign: 'left' }}>
                        <i className="fa fa-angle-right" />

                        <a
                          onClick={() => this.navigateToLink('/contactUs')}
                          style={{ color: '#fff', cursor: 'pointer' }}
                        >
                          {' '}
                          Feedback
                        </a>
                      </li>
                      <li style={{ textAlign: 'left' }}>
                        <i className="fa fa-angle-right" />

                        <a
                          onClick={() => this.navigateToLink('/termsConditions')}
                          style={{ color: '#fff', cursor: 'pointer' }}
                        >
                          {' '}
                          Terms &amp; Conditions
                        </a>
                      </li>
                      <li style={{ textAlign: 'left' }}>
                        <i className="fa fa-angle-right" />

                        <a
                          onClick={() => this.navigateToLink('/privacyPolicyWeb')}
                          style={{ color: '#fff', cursor: 'pointer' }}
                        >
                          {' '}
                          Privacy Policy
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </footer>
            {/* End Footer */}
            <a href="#" className="back-to-top">
              <i className="ri-arrow-up-line" />
            </a>
          </div>
        </>
      );
    }
  }
}
