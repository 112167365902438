import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './registerServiceWorker';

ReactDOM.render(
  <>
    <App />
    {/* <div id="iAmUniqueLoaderFCC" className="ptsloaderHide"></div> */}
    <div id="iAmUniqueModalAT" className="ptsmodal">
      <div id="iAmUniqueLoaderAT" className="ptsloader"></div>
    </div>
  </>,
  document.getElementById('app')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
