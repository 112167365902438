import Tooltip from '@material-ui/core/Tooltip';
import React, { Component } from 'react';
import NavBadge from './NavBadge';
import store from '../../../../redux/store';
import { Link } from 'react-router-dom';
class NavSingleItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  getChildMenus = (e, id, pageid) => {
    let toMenuList = [];
    // this.props.history.push(pageid);
    // let toMenuList = store.getState().adminUserDetails.adminUserDetails.profile.menus;
    let userLogin = store.getState().adminUserDetails.adminUserDetails.isUserLogin;
    if (userLogin === 'sponsor') {
      toMenuList = [
        {
          uiname: 'Sponsor Home',
          pageid: '/viewSponsor',
          icon: 'fa fa-home text-white',

          id: 1,
        },
        {
          uiname: 'Advertisement',
          pageid: '/advertisementList',
          icon: 'fa fa-adn text-white',
          id: 2,
        },
        {
          uiname: 'Sponsorship Plans',
          pageid: '/sponsorshipList',
          icon: 'fa fa-usd text-white',
          id: 3,
        },
        {
          uiname: 'Help',
          pageid: '/help',
          icon: 'fa fa-question-circle',
          id: 4,
        },
      ];
    } else if (userLogin === 'paidAdvertiser') {
      toMenuList = [
        {
          uiname: 'Advertiser Home',
          pageid: '/viewAdvertiser',
          icon: 'fa fa-home text-white',
          id: 1,
        },
        {
          uiname: 'Commercials',
          pageid: '/paidAdvertisementList',
          icon: 'fa fa-adn text-white',
          id: 2,
        },
        {
          uiname: 'Commercial Plans',
          pageid: '/paidCommercialsList',
          icon: 'fa fa-usd text-white',
          id: 3,
        },
        {
          uiname: 'Help',
          pageid: '/paidHelp',
          icon: 'fa fa-question-circle',
          id: 4,
        },
      ];
    } else {
      toMenuList = store.getState().adminUserDetails.adminUserDetails.profile.menus;
    }
    if (this.props.onSelect) {
      this.props.onSelect(id);
    }
    let childMenuArray = [];
    for (let i = 0; toMenuList && i < toMenuList.length; i++) {
      if (toMenuList[i].childMenu !== null) {
        console.log(toMenuList[i].id, '===', id);
        if (toMenuList[i].id === id) {
          let obj = {
            menuid: toMenuList[i].menuid,
            icon: toMenuList[i].icon,
            pageid: toMenuList[i].pageid,
            cssclass: toMenuList[i].cssclass,
            step: toMenuList[i].step,
            id: toMenuList[i].id,
          };
          if (toMenuList[i] === id) {
            obj.isActive = true;
            if (this.props.onSelect) {
              this.props.onSelect(toMenuList[i]);
              obj.isActive = true;
            }
          } else {
            obj.isActive = true;
          }
          childMenuArray.push(obj);
          // localStorage.setItem('childMenuArr', JSON.stringify(childMenuArray));
        }
      }
    }
  };
  render() {
    let item = this.props.item;
    let isActive = this.props.isActive;
    let activeMenu = store.getState().activeMenu.activeMenu;
    if (item.id === activeMenu) {
      isActive = true;
    }
    if (item.external) {
      return (
        <li className="nav-item" id={item.helpClassName} onClick={(e) => this.getChildMenus(e, item.id, item.pageid)}>
          <Tooltip title={item.name}>
            <Link
              to={item.pageid}
              activeClassName="active"
              style={{ backgroundColor: '#1b7189' }}
              className={isActive === true ? 'menu-item active' : 'menu-item'}
            >
              <div style={{ display: 'flex', alignItems: 'center', width: '100%', fontSize: '14px' }}>
                <div style={{ width: '20%' }}>
                  <i className={item.icon} style={{ color: '#fff' }}></i>
                </div>
                <div style={{ width: '80%' }}>
                  <span className="nav-item-label">{item.uiname}</span>
                </div>
              </div>
            </Link>
          </Tooltip>
        </li>
      );
    } else {
      return (
        <li className="nav-item" id={item.helpClassName} onClick={(e) => this.getChildMenus(e, item.id, item.pageid)}>
          <Tooltip title={item.uiname}>
            <Link
              to={item.pageid}
              activeClassName="active"
              className={isActive === true ? 'menu-item active' : 'menu-item'}
            >
              <i
                className={item.icon}
                style={{
                  color: '#fff',
                  marginRight: '10px',
                  verticalAlign: 'middle',
                  position: 'relative',
                  top: '5px',
                  left: '0px',
                  width: '1.25em',
                  fontSize: '1.1em',
                }}
              ></i>
              <span className="nav-item-label">{item.uiname}</span>
              {item.badge && <NavBadge color={item.badge.variant} text={item.badge.text} />}
            </Link>
          </Tooltip>
        </li>
      );
    }
  }
}

export default NavSingleItem;
