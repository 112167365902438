import { CLEAR_APPOINTMENT_STATE, GET_APPOINTMENT_STATE, SET_APPOINTMENT_STATE } from './appointmentStateTypes';

const initialState = {
  appointmentState: {},
};

const appointmentStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_APPOINTMENT_STATE:
      return {
        ...state,
      };
    case SET_APPOINTMENT_STATE:
      return {
        ...state,
        appointmentState: action.payload,
      };

    case CLEAR_APPOINTMENT_STATE:
      return {
        ...state,
        appointmentState: null,
      };
    default:
      return state;
  }
};

export default appointmentStateReducer;
