import TextField from '@material-ui/core/TextField';
// import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityIcon from '@material-ui/icons/Visibility';
// import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
// import { IconButton, InputAdornment } from "@mui/material";
import { IconButton, InputAdornment } from '@material-ui/core';
import FetchServerData from '../../../provider/FetchServerData';
import React, { Component } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Button, Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import './AdminResetPassword.scss';
import fccLogo from '../../../assets/images/fccLogo.png';

export class AdminResetPassword extends Component {
  constructor(props) {
    super(props);
    let action = props.action;
    let email = props.email;
    let isDisabled = false;
    let pageId = '';
    if (
      null !== props.history &&
      props.history !== undefined &&
      props.history.location !== null &&
      props.history.location.state !== null &&
      props.history.location.state !== undefined
    ) {
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
      email = props.history.location.state.email;
    }
    if (email && email.length > 0) {
      isDisabled = true;
    }
    this.state = {
      email: email,
      code: '',
      action: action,
      pageId: pageId,
      password: '',
      isEmailDisabled: isDisabled,
    };
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }
  handlePasswordChange(e) {
    this.setState({
      password: e.target.value,
    });
  }

  gotToButton() {
    document.getElementById('loginButton').focus();
  }
  gotToPassword() {
    document.getElementById('password').focus();
  }

  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }
  handleVerificationCodeChange(e) {
    this.setState({ code: e.target.value });
  }

  goToPage() {
    this.props.history.push({
      pathname: '/APForget',
      state: { email: this.state.email },
    });
  }

  resetPasswordInBackend = (e) => {
    e.preventDefault();

    let emailPhone = '';
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.state.email)) {
      emailPhone = this.state.email;
    }
    const postObject = {
      header: {},
      appuser: {
        email: emailPhone,
        credential: this.state.password,
        verificationcode: this.state.code,
      },
    };
    FetchServerData.callPostService('/adminpanelAppuserms/resetPassword', postObject)
      .then((response) => {
        let output = response;
        console.log(output);
        if (output.status === 'SUCCESS') {
          this.props.history.push({
            pathname: '/APLogin',
          });
        } else {
        }
      })
      .catch((error) => {});
  };
  render() {
    return (
      <>
        <Col md={12}>
          <Row>
            <Col md={6} style={{ marginTop: '10em' }}>
              <img alt="" src={fccLogo} style={{ height: '468px', width: '468px' }} />
            </Col>
            <Col md={6} style={{ marginTop: '11em' }}>
              <Card className="shadow border-0">
                <CardHeader className="bg-transparent">
                  <div className="adminLoginHeader">
                    <p>
                      <b>Family Care Circle</b>
                    </p>
                    <p> Admin Panel</p>
                  </div>
                </CardHeader>
                <CardBody className="loginCardBody">
                  <ValidatorForm
                    ref="form"
                    onSubmit={this.resetPasswordInBackend.bind(this)}
                    style={{ width: '100%', padding: '1em' }}
                  >
                    <Row>
                      <Col md={12}>
                        <h3>Reset Password</h3>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        {this.state.isEmailDisabled && this.state.isEmailDisabled === true && (
                          <TextField
                            margin="normal"
                            variant="outlined"
                            required
                            fullWidth
                            disabled
                            id="email"
                            type="email"
                            onChange={this.handleEmailChange.bind(this)}
                            label="Email / Phone Number"
                            name="email"
                            autoFocus
                            value={this.state.email}
                            autoComplete="off"
                          />
                        )}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <TextField
                          margin="normal"
                          variant="outlined"
                          required
                          value={this.state.code}
                          onChange={this.handleVerificationCodeChange.bind(this)}
                          fullWidth
                          id="code"
                          label="Verification Code"
                          name="code"
                          autoFocus
                          autoComplete="off"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          value={this.state.password}
                          name="password"
                          label="Password"
                          type={this.state.showPassword ? 'text' : 'password'}
                          id="password"
                          autoComplete="current-password"
                          onChange={this.handlePasswordChange.bind(this)}
                          InputProps={{
                            // <-- This is where the toggle button is added.
                            endAdornment: (
                              <InputAdornment position="end" onFocus={this.gotToButton.bind(this)}>
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowPassword.bind(this)}
                                >
                                  {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />{' '}
                      </Col>
                    </Row>
                    <div className="loginCardFooter">
                      {/* <Button className="my-4" color="login" type="button">
                  Sign in
                </Button> */}

                      <Button id="loginButton" type="submit" variant="contained" color="primary">
                        Reset
                      </Button>
                    </div>
                  </ValidatorForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}

export default AdminResetPassword;
