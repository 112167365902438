import AdminDashboard from '../pts/adminPanel/adminDashboard/AdminDashboard';
import AdminForgetPassword from '../pts/adminPanel/adminForgetPassword/AdminForgetPassword';
import AdminResetPassword from '../pts/adminPanel/adminForgetPassword/AdminResetPassword';
import AdminLogin from '../pts/adminPanel/adminLogin/AdminLogin';
import AdminProfile from '../pts/adminPanel/adminProfile/AdminProfile';
import AdminSponsorshipList from '../pts/adminPanel/adminSponsorship/AdminSponsorshipList';
import Sponsor from '../pts/adminPanel/sponsor/Sponsor';
import SponsorList from '../pts/adminPanel/sponsor/SponsorList';
import Appointment from '../pts/components/appointment/Appointment';
import { MemberAppointment } from '../pts/components/appointment/MemberAppointment';
import MemberAppointmentList from '../pts/components/appointment/MemberAppointmentList';
import appointmentHierarchy from '../pts/components/appointmentHierarchy/appointmentHierarchy';
import BillingDetailsHome from '../pts/components/billingDetails/BillingDetailsHome';
import MemberCommunication from '../pts/components/communication/MemberCommunication';
// import MemberCommunication from '../pts/components/communication/MemberCommunication';
import { MemberCommunicationList } from '../pts/components/communication/MemberCommunicationList';
import ContactUs from '../pts/components/contactUs/ContactUs';
import MyRequest from '../pts/components/dashboard/MyRequest';
import MemberDoctor from '../pts/components/doctor/MemberDoctor';
import MemberDoctorList from '../pts/components/doctor/MemberDoctorList';
import Event from '../pts/components/events/Event';
import MemberEvent from '../pts/components/events/MemberEvent';
import MemberEventList from '../pts/components/events/MemberEventList';
import MemberFacility from '../pts/components/facility/MemberFacility';
import MemberFacilityList from '../pts/components/facility/MemberFacilityList';
import FamilyCircle from '../pts/components/familyCircle/FamilyCircle';
import FamilyCircleList from '../pts/components/familyCircle/FamilyCircleList';
import { AddFamilyMember } from '../pts/components/familyMember/AddFamilyMember';
import FccHome from '../pts/components/fccHome/FccHome';
import DoctorImport from '../pts/components/import/DoctorImport';
import FacilityImport from '../pts/components/import/FacilityImport';
import ImportList from '../pts/components/import/ImportList';
import Incident from '../pts/components/incident/Incident';
import MemberIncident from '../pts/components/incident/MemberIncident';
import MemberIncidentList from '../pts/components/incident/MemberIncidentList';
import Login from '../pts/components/login/Login';
import { AddMember } from '../pts/components/member/AddMember';
import MemberDetails from '../pts/components/member/MemberDetails';
import MemberHome from '../pts/components/member/memberHome/MemberHome';
import MemberProfile from '../pts/components/member/MemberProfile';
import { messaging } from '../pts/components/messaging/messaging';
import Canceled from '../pts/components/paymentGateway/canceled/Canceled';
import Checkout from '../pts/components/paymentGateway/checkout/Checkout';
import BusinessPromotion from '../pts/components/paymentGateway/pricing/BusinessPromotion/BusinessPromotion';
import PlanDetails from '../pts/components/paymentGateway/pricing/PlanDetails';
import Pricing from '../pts/components/paymentGateway/pricing/Pricing';
import PricingHome from '../pts/components/paymentGateway/pricing/PricingHome';
import RenewPlan from '../pts/components/paymentGateway/renewPlan/RenewPlan';
import Success from '../pts/components/paymentGateway/success/Success';
import UpgradePlan from '../pts/components/paymentGateway/upgradePlan/UpgradePlan';
import PrivacyPolicy from '../pts/components/privacyPolicy/PrivacyPolicy';
import PrivacyPolicyWeb from '../pts/components/privacyPolicy/PrivacyPolicyWeb';
import Profile from '../pts/components/profile/Profile';
import ProfileHome from '../pts/components/profile/ProfileHome';
import CreateSimilarPurchase from '../pts/components/purchases/CreateSimilarPurchase';
import MyPurchase from '../pts/components/purchases/MyPurchase';
import MyPurchasesList from '../pts/components/purchases/MyPurchasesList';
import ForgotPassword from '../pts/components/registration/ForgotPassword';
import Registration from '../pts/components/registration/Registration';
import ResetPassword from '../pts/components/registration/ResetPassword';
import { TermsConditions } from '../pts/components/registration/TermsConditions';
import Verification from '../pts/components/registration/Verification';
import ReminderTimer from '../pts/components/reminderTimer/ReminderTimer';
// import Sponsorship from '../pts/components/sponsorship/Sponsorship';
import AdminSponsorLogin from '../pts/adminPanel/adminSponsorLogin/AdminSponsorLogin';
import AdminSponsorship from '../pts/adminPanel/adminSponsorship/AdminSponsorship';
import Advertisement from '../pts/adminPanel/advertisement/Advertisement';
import AdvertisementList from '../pts/adminPanel/advertisement/AdvertisementList';
import Help from '../pts/adminPanel/help/Help';
import ReferralBonus from '../pts/components/refrealBonus/ReferralBonus';
import { TermsCondition } from '../pts/components/termsCondition/TermsCondition';
import TermsConditionsWebsite from '../pts/components/termsCondition/TermsConditionWebsite';
import updateCredential from './pages/updateCredential';
import VerificationRequest from './pages/VerificationRequest';
import Verify from './pages/Verify';

import AdminPaidAdvertisementForgetPassword from '../pts/adminPanel/adminPaidAdvertisementForgetPassword/AdminPaidAdvertisementForgetPassword';
import AdminPaidAdvertisementResetPassword from '../pts/adminPanel/adminPaidAdvertisementForgetPassword/AdminPaidAdvertisementResetPassword';
import AdminPaidAdvertisementLogin from '../pts/adminPanel/adminPaidAdvertisementLogin/AdminPaidAdvertisementLogin';
import AdminSponsorForgetPassword from '../pts/adminPanel/AdminSponsorForgetPassword/AdminSponsorForgetPassword';
import AdminSponsorResetPassword from '../pts/adminPanel/AdminSponsorForgetPassword/AdminSponsorResetPassword';

import AdminProfileManagement from '../pts/adminPanel/adminProfileManagement/AdminProfileManagement';
import AdminProfileManagementList from '../pts/adminPanel/adminProfileManagement/AdminProfileManagementList';
import AdminUser from '../pts/adminPanel/adminUser/AdminUser';
import AdminUserList from '../pts/adminPanel/adminUser/AdminUserList';
import EmailTemplate from '../pts/adminPanel/emailTemplate/EmailTemplate';
import EmailTemplateList from '../pts/adminPanel/emailTemplate/EmailTemplateList';
import FccProfileManagement from '../pts/adminPanel/fccProfileManagement/FccProfileManagement';
import FccProfileManagementList from '../pts/adminPanel/fccProfileManagement/FccProfileManagementList';
import LoginCount from '../pts/adminPanel/loginCount/LoginCount';
import PaidAdvertisement from '../pts/adminPanel/paidAdvertisement/PaidAdvertisement';
import PaidAdvertisementList from '../pts/adminPanel/paidAdvertisement/PaidAdvertisementList';
import PaidAdvertiser from '../pts/adminPanel/paidAdvertiser/PaidAdvertiser';
import PaidAdvertiserList from '../pts/adminPanel/paidAdvertiser/PaidAdvertiserList';
import PaidCommercials from '../pts/adminPanel/paidCommercials/PaidCommercials';
import PaidCommercialsList from '../pts/adminPanel/paidCommercials/PaidCommercialsList';
import PrivacyPolicyTemplate from '../pts/adminPanel/privacyPolicyTemplate/PrivacyPolicyTemplate';
import PrivacyPolicyTemplateList from '../pts/adminPanel/privacyPolicyTemplate/PrivacyPolicyTemplateList';
import TermsConditionTemplate from '../pts/adminPanel/terms&ConditionTemplate/TermsConditionTemplate';
import TermsConditionList from '../pts/adminPanel/terms&ConditionTemplate/TermsConditionTemplateList';
import ConsolidatedView from '../pts/components/consolidatedView/ConsolidatedView';
import FrequentlyAskedQuestion from '../pts/components/fccFAQ/FrequentlyAskedQuestion';
import FrequentlyAskedQuestionHome from '../pts/components/fccFAQ/FrequentlyAskedQuestionHome';
import PrescriptionsImport from '../pts/components/import/PrescriptionsImport';
import PremiumFeatures from '../pts/components/premiumFeatures/PremiumFeatures';
import Prescriptions from '../pts/components/prescriptions/Prescriptions';
import PrescriptionsList from '../pts/components/prescriptions/PrescriptionsList';
import PtsAboutUs from '../pts/components/ptsAboutUs/PtsAboutUs';
import UserLoginCount from '../pts/adminPanel/loginCount/UserLoginCount';

// import contact from './pages/website/contact';
// import product from './pages/website/product';
// import UseCase from './pages/website/UseCase';

// See React Router documentation for details: https://reacttraining.com/react-router/web/api/Route
const pageList = [
  {
    name: 'MemberHome',
    path: '/home',
    component: MemberHome,
  },
  {
    name: 'MemberDetails',
    path: '/memberDetails',
    component: MemberDetails,
  },
  {
    name: 'Messaging',
    path: '/messaging',
    component: messaging,
  },
  {
    name: 'Appointments',
    path: '/memberAppointment',
    component: MemberAppointmentList,
  },
  {
    name: 'Appointments',
    path: '/addAppointment',
    component: MemberAppointment,
  },
  {
    name: 'Appointments',
    path: '/viewMemberAppointment',
    component: MemberAppointment,
  },
  {
    name: 'Appointments',
    path: '/viewEditMemberAppointments',
    component: MemberAppointment,
  },
  {
    name: 'Appointments',
    path: '/editMemberAppointments',
    component: MemberAppointment,
  },
  {
    name: 'FamilyCircle',
    path: '/familyCircle',
    component: FamilyCircleList,
  },
  {
    name: 'FamilyCircle',
    path: '/viewFamilyMember',
    component: FamilyCircle,
  },

  {
    name: 'AddMember',
    path: '/addMember',
    component: AddMember,
  },
  {
    name: 'AddFamilyMember',
    path: '/addFamilyMember',
    component: AddFamilyMember,
  },
  {
    name: 'Doctor',
    path: '/doctorAppointment',
    component: MemberDoctorList,
  },
  {
    name: 'Doctor',
    path: '/addDoctor',
    component: MemberDoctor,
  },
  {
    name: 'Doctor',
    path: '/viewDoctor',
    component: MemberDoctor,
  },
  {
    name: 'Doctor',
    path: '/editDoctorAppointment',
    component: MemberDoctor,
  },

  {
    name: 'Login',
    path: '/login',
    component: Login,
  },

  {
    name: 'ForgotPassword',
    path: '/forgotPassword',
    component: ForgotPassword,
  },
  {
    name: 'Registration',
    path: '/createAccount',
    component: Registration,
  },
  {
    name: 'Verification',
    path: '/verification',
    component: Verification,
  },
  {
    name: 'VerificationRequest',
    path: '/verificationRequest',
    component: VerificationRequest,
  },
  {
    name: 'Verify',
    path: '/verify',
    component: Verify,
  },
  {
    name: 'UpdateCredential',
    path: '/updateCredential',
    component: updateCredential,
  },
  {
    name: 'ResetPassword',
    path: '/resetpassword',
    component: ResetPassword,
  },
  {
    name: 'MemberCommunicationList',
    path: '/memberCommunication',
    component: MemberCommunicationList,
  },
  {
    name: 'MemberCommunication',
    path: '/viewCommunication',
    component: MemberCommunication,
  },
  {
    name: 'AddMessageThread',
    path: '/addMessageThread',
    component: MemberCommunication,
  },

  {
    name: 'Event',
    path: '/member_event',
    component: MemberEventList,
  },
  {
    name: 'Event',
    path: '/addEvent',
    component: MemberEvent,
  },
  {
    name: 'Event',
    path: '/viewEvent',
    component: MemberEvent,
  },
  {
    name: 'editEvent',
    path: '/editEvent',
    component: MemberEvent,
  },
  {
    name: 'Incident',
    path: '/incidentAppointment',
    component: MemberIncidentList,
  },
  {
    name: 'Incident',
    path: '/addIncident',
    component: MemberIncident,
  },
  {
    name: 'Incident',
    path: '/viewIncident',
    component: MemberIncident,
  },
  {
    name: 'Incident',
    path: '/incidentEdit',
    component: MemberIncident,
  },
  // {
  //   name: 'UseCase',
  //   path: '/usecase',
  //   component: UseCase,
  // },
  // {
  //   name: 'Product',
  //   path: '/product',
  //   component: product,
  // },
  {
    name: 'memberFacility',
    path: '/memberFacility',
    component: MemberFacilityList,
  },
  {
    name: 'MemberFacility',
    path: '/addFacility',
    component: MemberFacility,
  },
  {
    name: 'ViewMemberFacility',
    path: '/viewFacility',
    component: MemberFacility,
  },
  {
    name: 'EditMemberFacility',
    path: '/editMemberFacility',
    component: MemberFacility,
  },

  {
    name: 'ReminderTime',
    path: '/remindertime',
    component: ReminderTimer,
  },
  {
    name: 'termsConditions',
    path: '/termsConditionsRegistration',
    component: TermsConditions,
  },

  {
    name: 'termsConditionHome',
    path: '/termsconditionhome',
    component: TermsCondition,
  },
  {
    name: 'termsCondition',
    path: '/termsCondition',
    component: TermsCondition,
  },
  {
    name: 'TermsConditionsWebsite',
    path: '/termsConditions',
    component: TermsConditionsWebsite,
  },
  {
    name: 'profile',
    path: '/profile',
    component: Profile,
  },
  {
    name: 'profile',
    path: '/profileHome',
    component: ProfileHome,
  },
  {
    name: 'editProfileHome',
    path: '/editProfileHome',
    component: ProfileHome,
  },
  {
    name: 'editProfile',
    path: '/editProfile',
    component: Profile,
  },
  {
    name: 'MemberProfile',
    path: '/memberProfile',
    component: MemberProfile,
  },
  {
    name: 'MemberProfile',
    path: '/editMemberProfile',
    component: MemberProfile,
  },
  {
    name: 'MyRequest',
    path: '/viewMember',
    component: MyRequest,
  },
  {
    name: 'contactUs',
    path: '/contactUs',
    component: ContactUs,
  },
  {
    name: 'createSimilarAppointment',
    path: '/createSimilarAppointment',
    component: Appointment,
  },
  {
    name: 'doctorAppointment',
    path: '/createDoctorAppointment',
    component: Appointment,
  },
  {
    name: 'facilityAppointments',
    path: '/facilityAppointments',
    component: Appointment,
  },
  {
    name: 'createSimilarIncident',
    path: '/createSimilarIncident',
    component: Incident,
  },
  {
    name: 'appointmentHierarchy',
    path: '/appointmentHierarchy',
    component: appointmentHierarchy,
  },
  {
    name: 'Pricing',
    path: '/pricing',
    component: Pricing,
  },
  {
    name: 'PlanDetails',
    path: '/planDetails',
    component: PlanDetails,
  },
  {
    name: 'create Similar Event',
    path: '/createSimilarEvent',
    component: Event,
  },

  {
    name: 'Checkout',
    path: '/checkout',
    component: Checkout,
  },
  {
    name: 'UpgradePlan',
    path: '/upgradePlan',
    component: UpgradePlan,
  },
  {
    name: 'UpgradePlan',
    path: '/renewPlan',
    component: RenewPlan,
  },
  {
    name: 'Success',
    path: '/success',
    component: Success,
  },
  {
    name: 'Canceled',
    path: '/canceled',
    component: Canceled,
  },
  {
    name: 'MyPurchasesList',
    path: '/memberPurchase',
    component: MyPurchasesList,
  },
  {
    name: 'Create Similar Purchase',
    path: '/createSimilarPurchase',
    component: CreateSimilarPurchase,
  },
  {
    name: 'Add Purchase',
    path: '/addPurchase',
    component: MyPurchase,
  },
  {
    name: 'View Purchase',
    path: '/viewPurchase',
    component: MyPurchase,
  },
  {
    name: 'Edit Purchase',
    path: '/edit_purchase',
    component: MyPurchase,
  },
  {
    name: 'Purchase_pricing',
    path: '/homePricing',
    component: PricingHome,
  },

  {
    name: 'BusinessPromotion',
    path: '/BusinessPromotion',
    component: BusinessPromotion,
  },
  {
    name: 'Billing Details',
    path: '/billingDetailsHome',
    component: BillingDetailsHome,
  },
  {
    name: 'Privacy Policy',
    path: '/privacyPolicy',
    component: PrivacyPolicy,
  },
  {
    name: 'Privacy Policy Web',
    path: '/privacyPolicyWeb',
    component: PrivacyPolicyWeb,
  },
  { name: 'Import List', path: '/import', component: ImportList },
  { name: 'facility Import ', path: '/viewFacilityImport', component: FacilityImport },
  { name: 'facility Import ', path: '/viewDoctorImport', component: DoctorImport },
  { name: 'Prescriptions Import ', path: '/viewPrescriptionsImport', component: PrescriptionsImport },
  {
    name: 'FccHome',
    path: '/fcc',
    component: FccHome,
  },
  {
    name: 'ConsolidatedView',
    path: '/consolidatedView',
    component: ConsolidatedView,
  },
  {
    name: 'AdminLogin',
    path: '/APLogin',
    component: AdminLogin,
  },
  {
    name: 'AdminDashboard',
    path: '/ApHome',
    component: AdminDashboard,
  },
  {
    name: 'ReferralBonus',
    path: '/referralBonus',
    component: ReferralBonus,
  },

  {
    name: 'AdminForgetPassword',
    path: '/APForget',
    component: AdminForgetPassword,
  },
  {
    name: 'AdminResetPassword',
    path: '/APReset',
    component: AdminResetPassword,
  },
  {
    name: 'AdminProfile',
    path: '/adminUserProfile',
    component: AdminProfile,
  },
  {
    name: 'Sponsor',
    path: '/addSponsor',
    component: Sponsor,
  },
  {
    name: 'SponsorList',
    path: '/sponsorList',
    component: SponsorList,
  },
  {
    name: 'Sponsor',
    path: '/viewSponsor',
    component: Sponsor,
  },
  {
    name: 'Sponsor',
    path: '/editSponsor',
    component: Sponsor,
  },
  {
    name: 'AdminSponsorshipList',
    path: '/sponsorshipList',
    component: AdminSponsorshipList,
  },
  {
    name: 'AdminSponsorship',
    path: '/addSponsorship',
    component: AdminSponsorship,
  },
  {
    name: 'AdminSponsorship',
    path: '/viewSponsorship',
    component: AdminSponsorship,
  },
  {
    name: 'AdminSponsorship',
    path: '/editSponsorship',
    component: AdminSponsorship,
  },
  {
    name: 'AdvertisementList',
    path: '/advertisementList',
    component: AdvertisementList,
  },
  {
    name: 'Advertisement',
    path: '/addAdvertisement',
    component: Advertisement,
  },
  {
    name: ' Advertisement',
    path: '/viewAdvertisement',
    component: Advertisement,
  },
  {
    name: ' Advertisement',
    path: '/editAdvertisement',
    component: Advertisement,
  },
  {
    name: ' Help ',
    path: '/help',
    component: Help,
  },
  {
    name: 'AdminSponsorLogin',
    path: '/APSponsorLogin',
    component: AdminSponsorLogin,
  },
  {
    name: 'AdminSponsorForgetPassword',
    path: '/APSponsorForget',
    component: AdminSponsorForgetPassword,
  },
  {
    name: 'AdminSponsorResetPassword',
    path: '/APSponsorReset',
    component: AdminSponsorResetPassword,
  },
  {
    name: 'AdminPaidAdvertisementLogin',
    path: '/APPaidAdvertiserLogin',
    component: AdminPaidAdvertisementLogin,
  },
  {
    name: 'AdminPaidAdvertisementForgetPassword',
    path: '/APPaidAdvertiserForget',
    component: AdminPaidAdvertisementForgetPassword,
  },
  {
    name: 'AdminPaidAdvertisementResetPassword',
    path: '/APPaidAdvertiserReset',
    component: AdminPaidAdvertisementResetPassword,
  },
  {
    name: 'Paid AdvertiserList',
    path: '/paidAdvertiserList',
    component: PaidAdvertiserList,
  },
  {
    name: 'Add Advertiser',
    path: '/addAdvertiser',
    component: PaidAdvertiser,
  },
  {
    name: 'View Advertiser',
    path: '/viewAdvertiser',
    component: PaidAdvertiser,
  },
  {
    name: 'Edit Advertiser',
    path: '/editAdvertiser',
    component: PaidAdvertiser,
  },
  {
    name: 'Paid Commercials List',
    path: '/paidCommercialsList',
    component: PaidCommercialsList,
  },
  {
    name: 'Edit Advertiser',
    path: '/addCommercials',
    component: PaidCommercials,
  },
  {
    name: 'View Commercials',
    path: '/viewCommercials',
    component: PaidCommercials,
  },
  {
    name: 'Edit Advertiser',
    path: '/editCommercials',
    component: PaidCommercials,
  },
  {
    name: 'PaidAdvertisementList',
    path: '/paidAdvertisementList',
    component: PaidAdvertisementList,
  },
  {
    name: 'PaidAdvertisement',
    path: '/addPaidAdvertisement',
    component: PaidAdvertisement,
  },
  {
    name: 'PaidAdvertisement',
    path: '/viewPaidAdvertisement',
    component: PaidAdvertisement,
  },
  {
    name: 'PaidAdvertisement',
    path: '/editPaidAdvertisement',
    component: PaidAdvertisement,
  },
  {
    name: 'Email Template List',
    path: '/emailTemplateList',
    component: EmailTemplateList,
  },
  {
    name: 'View Email Template',
    path: '/viewEmailTemplate',
    component: EmailTemplate,
  },
  {
    name: 'Edit Email Template',
    path: '/editEmailTemplate',
    component: EmailTemplate,
  },
  {
    name: 'Fcc Profile Management List',
    path: '/fccProfileList',
    component: FccProfileManagementList,
  },
  {
    name: 'Add Profile Management',
    path: '/addProfileManagement',
    component: FccProfileManagement,
  },
  {
    name: 'View Profile Management',
    path: '/viewProfileManagement',
    component: FccProfileManagement,
  },
  {
    name: 'Edit Profile Management',
    path: '/editProfileManagement',
    component: FccProfileManagement,
  },
  {
    name: 'Admin Profile Management List',
    path: '/apSetting',
    component: AdminProfileManagementList,
  },
  {
    name: 'Add Admin Profile Management',
    path: '/addApProfileManagement',
    component: AdminProfileManagement,
  },
  {
    name: 'View Admin Profile Management',
    path: '/viewApProfileManagement',
    component: AdminProfileManagement,
  },
  {
    name: 'Edit Admin Profile Management',
    path: '/editApProfileManagement',
    component: AdminProfileManagement,
  },
  {
    name: 'Admin Uaser List',
    path: '/apUserList',
    component: AdminUserList,
  },
  {
    name: 'Add Admin Uaser',
    path: '/addAdminUser',
    component: AdminUser,
  },
  {
    name: 'View Admin Uaser',
    path: '/viewAdminUser',
    component: AdminUser,
  },
  {
    name: 'Edit Admin Uaser',
    path: '/editAdminUser',
    component: AdminUser,
  },
  {
    name: 'Help',
    path: '/paidHelp',
    component: Help,
  },
  {
    name: 'FrequentlyAskedQuestion',
    path: '/faq',
    component: FrequentlyAskedQuestion,
  },
  {
    name: 'FrequentlyAskedQuestionHome',
    path: '/faqHome',
    component: FrequentlyAskedQuestionHome,
  },
  {
    name: 'PtsAboutUs',
    path: '/aboutUs',
    component: PtsAboutUs,
  },

  {
    name: 'LoginCount',
    path: '/LoginCount',
    component: LoginCount,
  },
  {
    name: 'UserLoginCount',
    path: '/userLoginCount',
    component: UserLoginCount,
  },
  {
    name: 'PrescriptionsList',
    path: '/prescriptionsList',
    component: PrescriptionsList,
  },
  {
    name: 'addPrescription',
    path: '/addPrescription',
    component: Prescriptions,
  },
  {
    name: 'ViewPrescription',
    path: '/viewPrescriptions',
    component: Prescriptions,
  },
  {
    name: 'PremiumFeatures',
    path: '/premiumFeatures',
    component: PremiumFeatures,
  },
  {
    name: 'termsConditionTemplateList',
    path: '/aptermsConditionsList',
    component: TermsConditionList,
  },
  {
    name: 'termsConditionTemplate',
    path: '/addTermsCondition',
    component: TermsConditionTemplate,
  },
  {
    name: 'termsConditionTemplate',
    path: '/viewTermsCondition',
    component: TermsConditionTemplate,
  },
  {
    name: 'termsConditionTemplate',
    path: '/editTermsCondition',
    component: TermsConditionTemplate,
  },
  {
    name: 'PrivacyPolicyTemplateList',
    path: '/privacyPolicyList',
    component: PrivacyPolicyTemplateList,
  },
  {
    name: 'PrivacyPolicyTemplate',
    path: '/addPrivacyPolicy',
    component: PrivacyPolicyTemplate,
  },
  {
    name: 'termsConditionTemplate',
    path: '/viewPrivacyPolicy',
    component: PrivacyPolicyTemplate,
  },
  {
    name: 'termsConditionTemplate',
    path: '/editPrivacyPolicy',
    component: PrivacyPolicyTemplate,
  },
];

export default pageList;
