import MaterialTable from '@material-table/core';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import UiUtils from '../../../provider/UiUtils';
import Utils from '../../../provider/Utils';
import adminFccPageStateAction from '../../../redux/adminPanel/adminFccPageState/adminFccPageStateAction';
import applicationStateAction from '../../../redux/applicationState/applicationStateAction';
import store from '../../../redux/store';
import AdminMenuWithBreadScrum from '../adminMenu/AdminMenuWithBreadScrum';
import AddIconButton from '../../components/buttons/AddIconButton';
import moment from 'moment-timezone';

class TermsConditionTemplateList extends Component {
  title = 'Terms And Conditions Template List';
  breadCrumb = [{ pageid: '', uiname: 'Terms And Conditions Template List' }];
  header = store.getState().adminHeader.adminHeader;
  termsConditionState = store.getState().applicationState.termsConditionState;
  emailTemplateDetails = {};
  constructor(props) {
    super(props);
    store.dispatch(applicationStateAction.setApplicationState({}));
    store.dispatch(adminFccPageStateAction.setAdminFccPageState());
    if (this.termsConditionState) {
      this.emailTemplateDetails = Object.getOwnPropertyNames(this.termsConditionState);
    }
    if (this.emailTemplateDetails.length > 0) {
      this.state = {
        termsConditionTemplateList: [],
        isPaging: false,
        initialPage:
          this.termsConditionState.initialPage && this.termsConditionState.initialPage > 0
            ? this.termsConditionState.initialPage
            : 0,
        pageSize:
          this.termsConditionState.pageSize && this.termsConditionState.pageSize
            ? this.termsConditionState.pageSize
            : 10,
        termsConditionTemplateId: this.termsConditionState.termsConditionTemplateId,
        render: false,
      };
    } else {
      this.state = {
        termsConditionTemplateList: [],
        isPaging: false,
        initialPage: 0,
        pageSize: 10,
        termsConditionTemplateId: '',
        render: false,
      };
    }
    this.getEmailTemplateList();
  }
  getEmailTemplateList() {
    const postObject = {
      header: this.header,
    };

    FetchServerData.callPostService('/apTermsConditions/getList', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let termsConditionTemplateList = output.data.termsConditionsList;
        let getPermission = Utils.getRolePermission(output.data.privilegeList);
        let getCreatePermission = getPermission[1];
        let isPaging = false;
        if (termsConditionTemplateList.length > 10) {
          isPaging = true;
        }
        this.setState({
          isPaging: isPaging,
          termsConditionTemplateList: termsConditionTemplateList,
          render: true,
          initialPage: this.state.initialPage,
          pageSize: this.state.pageSize,
          getCreatePermission: getCreatePermission,
        });
      } else {
      }
    });
  }
  toEmailTemplateDetails(e, data) {
    let termsConditionState = {
      termsConditionTemplateId: data.id,
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
    };
    store.dispatch(applicationStateAction.setApplicationState({ termsConditionState }));
    let adminFccPageState = {
      id: data.id,
      action: 'view',
      title: 'Email Template Details',
      pageId: 'termsConditionList',
    };
    store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
    this.props.history.push({
      pathname: '/viewTermsCondition',
      state: {
        action: 'view',
        title: 'Terms Conditions Template Details',
        termsConditionTemplateId: data.id,
        pageId: 'termsConditionList',
      },
    });
  }

  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }

  addTermsCondition(e) {
    let adminFccPageState = {
      id: '',
      action: 'create',
      title: 'Terms Conditions',
      pageId: 'termsConditionList',
    };
    store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
    this.props.history.push({
      pathname: '/addTermsCondition',
      state: {
        action: 'create',
        title: 'Add Terms Conditions',
        termsConditionTemplateId: '',
        pageId: 'termsConditionList',
      },
    });
  }
  render() {
    return (
      <>
        <AdminMenuWithBreadScrum breadCrumb={this.breadCrumb} history={this.props.history} name={this.title} />
        <Row style={{ position: 'relative', margin: '1em 0em', maxWidth: '100%' }}>
          <Col md={12}>
            <MaterialTable
              onRowClick={(event, rowData) => {
                this.toEmailTemplateDetails(event, rowData);
                event.stopPropagation();
              }}
              icons={UiUtils.getMaterialTableIcons()}
              title=""
              localization={{
                body: {
                  emptyDataSourceMessage: Utils.communizeTableText(),
                },
              }}
              columns={[
                {
                  title: '#',
                  render: (rowData) => rowData.tableData.index + 1,

                  width: '5%',
                  align: 'left',
                },
                {
                  title: 'Version',
                  field: 'version',
                  width: '5%',
                  filtering: false,
                },
                {
                  title: 'created Name',
                  field: 'createdbyname',
                  width: '20%',
                  filtering: false,
                },
                {
                  title: 'Updated Name',
                  field: 'updatedbyname',
                  width: '20%',
                  filtering: false,
                },
                {
                  title: 'Created Date',
                  render: (rowData) => moment(rowData.createdon).format('DD-MMM-YYYY'),
                  // field: 'createdon',
                  width: '25%',
                },
                {
                  title: 'Updated Date',
                  render: (rowData) => (rowData.updatedon ? moment(rowData.updatedon).format('DD-MMM-YYYY') : ''),
                  // field: 'createdon',
                  width: '25%',
                },
                {
                  title: 'Status',
                  render: (rowData) => (rowData.isactive === true ? 'Active' : 'InActive'),
                  width: '30%',
                  // cellStyle: { textTransform: 'none' },
                },
              ]}
              data={this.state.termsConditionTemplateList}
              onPageChange={(page, pageSize) => {
                this.handleMaterialTablePageChange(page, pageSize);
              }}
              options={{
                sorting: true,
                actionsColumnIndex: -1,
                initialPage: this.state.initialPage,
                pageSize: this.state.pageSize,
                paging: this.state.termsConditionTemplateList.length > this.state.pageSize,
                headerStyle: {
                  backgroundColor: '#066f72',
                  fontWeight: 'bold',
                  color: '#fff',
                  align: 'left',
                },
                pageSizeOptions: [5, 10, 20, 50, 100],
                filtering: true,
                // paging: this.state.isPaging,
                rowStyle: (rowData) => ({
                  backgroundColor: rowData.id === this.state.termsConditionTemplateId ? '#FEF5E7' : '#fff',
                  height: '3em',
                  textTransform: 'capitalize',
                }),

                tableLayout: 'auto',
              }}
              actions={[
                {
                  icon: () => (
                    <span className="newDoctor">
                      {' '}
                      {this.state.getCreatePermission && <AddIconButton btnName="Add T&C Template" />}
                    </span>
                  ),
                  tooltip: 'Add Terms Conditions Template',
                  isFreeAction: true,
                  onClick: (event) => this.addTermsCondition(),
                },
              ]}
            />
          </Col>
        </Row>
      </>
    );
  }
}

export default TermsConditionTemplateList;
