import React, { Component } from 'react';
import { Card, CardBody, CardFooter, CardHeader, Col, Label, Row } from 'reactstrap';
import ptsLogo from '../../../assets/images/ptsLogo.png';
import fccLogo from '../../../assets/images/fccLogo.png';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
class PtsAboutUs extends Component {
  title = 'About Us';
  breadCrumb = [];
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
          headerWidth="home"
        />
        <Row style={{ padding: '1em' }}>
          <Col md={12}>
            <Card style={{ padding: '1em', textAlign: 'center' }}>
              <CardBody>
                <Row>
                  <Col md={12}>
                    <h3 style={{ color: '#2471A3' }}>
                      <b>
                        <u>Family Care Circle</u>
                      </b>
                    </h3>
                  </Col>
                </Row>
                <Row>
                  <Col md={12} style={{ textAlign: 'center', margin: 'auto' }}>
                    <img src={fccLogo} alt="" style={{ height: '100px' }} />
                    <br />
                    <a href="https://www.familycarecircle.com/" target="_blank" style={{ cursor: 'pointer' }}>
                      www.familycarecircle.com
                    </a>
                  </Col>
                </Row>
                <p style={{ marginTop: '2em', fontSize: '20px' }}>
                  Caregiving Made Simple.
                  <br /> Everything you need to stay organized and focus on giving the best care for your loved one.
                </p>
                <br />

                <Row>
                  <Col md={12} style={{ textAlign: 'center' }}>
                    <h5 style={{ color: '#17A589' }}>
                      <b>Powered By</b>
                    </h5>
                    <p style={{ color: '#ef6603' }}>
                      <b>Proto Technology Solutions</b>
                    </p>
                    <img src={ptsLogo} alt="" style={{ height: '100px' }} />
                    <br />
                    <a href="https://prototsolutions.com/" target="_blank" style={{ cursor: 'pointer' }}>
                      www.prototsolutions.com
                    </a>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
}

export default PtsAboutUs;
