import React, { Component } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import ContextProviders from '../pts/components/utilities/ContextProviders';
import handleKeyAccessibility, { handleClickAccessibility } from '../pts/helpers/handleTabAccessibility';
import routes from '../views';

const MOBILE_SIZE = 992;

export class WebsiteLayout extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sidebarCollapsed: false,
      isMobile: window.innerWidth <= MOBILE_SIZE,
      props: this.props,
    };
  }

  componentDidUpdate(prev) {
    if (this.state.isMobile && prev.location.pathname !== this.props.location.pathname) {
      this.toggleSideCollapse();
    }
  }

  toggleSideCollapse = () => {
    this.setState((prevState) => ({ sidebarCollapsed: !prevState.sidebarCollapsed }));
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    document.addEventListener('keydown', handleKeyAccessibility);
    document.addEventListener('click', handleClickAccessibility);
  }
  render() {
    // const { sidebarCollapsed } = this.state;

    return (
      <ContextProviders>
        <Switch>
          {routes.map((page, key) => (
            <Route path={page.path} component={page.component} key={key} />
          ))}
        </Switch>
      </ContextProviders>
    );
  }
}
export default withRouter(WebsiteLayout);
