import { CLEAR_SPONSOR_DATA, GET_SPONSOR_DATA, SET_SPONSOR_DATA } from './sponsorDetailsType';

const getSponsor = () => {
  return {
    type: GET_SPONSOR_DATA,
  };
};

const setSponsor = (data) => {
  return {
    type: SET_SPONSOR_DATA,
    payload: data,
  };
};

const clearSponsor = (data) => {
  return {
    type: CLEAR_SPONSOR_DATA,
    payload: data,
  };
};
const sponsorDetailsAction = { getSponsor, setSponsor, clearSponsor };

export default sponsorDetailsAction;
