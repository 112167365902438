import {
  CLEAR_DOCTOR_FACILITY_STATE,
  GET_DOCTOR_FACILITY_STATE,
  SET_DOCTOR_FACILITY_STATE,
} from './doctorFacilityStateTypes';
const initialState = {
  doctorAppointmentState: {},

  facilityAppointmentState: {},
};

const doctorFacilityStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DOCTOR_FACILITY_STATE:
      return {
        ...state,
      };
    case SET_DOCTOR_FACILITY_STATE:
      return {
        ...state,

        doctorAppointmentState: action.payload,
        facilityAppointmentState: action.payload,
      };

    case CLEAR_DOCTOR_FACILITY_STATE:
      return {
        ...state,
        doctorAppointmentState: null,
        facilityAppointmentState: null,
      };
    default:
      return state;
  }
};

export default doctorFacilityStateReducer;
