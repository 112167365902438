import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React, { Component } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';
import vibeLogo from '../../../assets/images/fccLogo.png';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import appUserDataAction from '../../../redux/appUserData/appUserDataAction';
import fccPageStateAction from '../../../redux/fccPageState/fccPageStateAction';
import headerAction from '../../../redux/header/headerAction';
import helpDataAction from '../../../redux/help/displayHelpAction';
import SponsorshipAction from '../../../redux/sponsorship/SponsorshipAction';
import staticDataAction from '../../../redux/staticData/staticDataAction';
import store from '../../../redux/store';
import AppleStore from '../../../assets/images/appleStore.png';
import GooglePlayStore from '../../../assets/images/googlePlayStore.png';
import fccAddressStateAction from '../../../redux/fccAddressState/fccAddressStateAction';
import CommercialsAction from '../../../redux/commercials/CommercialsAction';
import selectedPlanAction from '../../../redux/selectedPlan/selectedPlanAction';
import moment from 'moment-timezone';
// import PremiumFeatureAction from '../../../redux/premiumFeatureDetails/PremiumFeatureAction';
export class Login extends Component {
  constructor(props) {
    super(props);
    let action = props.action;
    let pageId = '';
    let email = '';
    let browserName = Utils.getBrowserInfo();
    if (
      null !== props.history &&
      props.history !== undefined &&
      props.history.location !== null &&
      props.history.location.state !== null &&
      props.history.location.state !== undefined
    ) {
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
      email = props.history.location.state.email;
    }
    this.state = {
      email: email,
      password: '',
      showPassword: false,
      action: action,
      pageId: pageId,
      browserName: browserName,
      rememberMe: false,
    };
    this.isMobile();
  }
  s;
  isMobile() {
    // credit to Timothy Huang for this regex test:
    // https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
    if (/ Android | webOS | iPhone | iPad | iPod | BlackBerry | IEMobile | Opera Mini /i.test(navigator.userAgent)) {
      // is mobile..

      return true;
    } else {
      return false;
    }
  }

  handlePasswordChange(e) {
    this.setState({
      password: e.target.value,
    });
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  processLoginSuccess(output_data) {
    this.setState({
      showSuccessAlert: true,
      showSuccessLoader: false,
    });
    Utils.setFCMTokenForUser();

    let headerObj = output_data.header;
    let timeZone = moment.tz.guess();
    let newTimeZone = '';
    if (timeZone === 'Asia/Calcutta') {
      newTimeZone = 'Asia/Kolkata';
    } else {
      newTimeZone = timeZone;
    }

    headerObj['timezone'] = newTimeZone;
    store.dispatch(headerAction.setHeader(headerObj));
    let header = headerObj;
    if (this.state.pageId === 'Pricing') {
      this.props.history.push({
        pathname: '/checkout',
      });
    } else {
      this.loadDashboardPage(header, output_data);
    }
  }

  showMobileIcon() {
    return (
      <>
        <a
          style={{ color: '#fff', cursor: 'pointer' }}
          href="https://apps.apple.com/us/app/family-care-circle/id1602521967"
          target="_blank"
        >
          <img src={AppleStore} style={{ height: '3em' }} />
        </a>
        &nbsp;&nbsp;
        <a
          target="_blank"
          href="https://play.google.com/store/apps/details?id=com.familycarecircle.fccmobileapp"
          style={{ color: '#fff', cursor: 'pointer' }}
        >
          <img src={GooglePlayStore} style={{ height: '3.5em' }} />
        </a>
      </>
    );
  }

  async loadDashboardPage(header, output) {
    await this.setStaticData(header)
      .then((data) => {})
      .catch((error) => {
        this.setState({
          errorColor: '#FF0000',
          errorMessage: error,
        });
      });
    /* Update Token Once User Login successfully */
    Utils.setFCMTokenForUser();
    this.props.history.push({
      pathname: '/home',
      state: { notification: true, pageId: 'loginPage' },
    });
  }

  async setStaticData(header) {
    return new Promise(function (accept, reject) {
      FetchServerData.callPostService('/staticms/getAll', header)
        .then((output) => {
          if (output.status === 'SUCCESS') {
            store.dispatch(staticDataAction.setStaticData(output.data));
            accept(1);
          } else {
            reject(output.message);
          }
        })
        .catch((error) => {
          reject('Cannot Fetch Data From Server, Error:' + error.message);
        });
    });
  }

  goToPage(pageid) {
    if (pageid && pageid.length > 0) {
      this.props.history.push({
        pathname: pageid,
        state: { email: this.state.email },
      });
    }
  }

  backToHome() {
    this.props.history.push({
      pathname: '/',
    });
  }

  loginAccountInBackend = (e) => {
    e.preventDefault();

    let emailPhone = '';
    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.state.email)) {
      emailPhone = this.state.email;
    } else {
      emailPhone = this.state.email.replace(/[^\d]/g, '');
    }
    const postObject = {
      header: {
        appclient: 'W',
        browser: this.state.browserName,
      },
      appuserVo: {
        emailOrPhone: emailPhone,
        credential: this.state.password,
        rememberMe: this.state.rememberMe,
      },
    };
    FetchServerData.callPostService('/userms/login', postObject)
      .then((response) => {
        if (Utils.equalsIgnoreCase(response.status, 'SUCCESS')) {
          let output = response.data;

          let userData = output.appuserVo;
          let userDetails = output;
          let Sponsorship = output.sponsorUser;
          let commercialsDetails = output.commercialsDetails;
          let displayFloat = true;

          store.dispatch(helpDataAction.setHelpData(displayFloat));
          store.dispatch(appUserDataAction.setUser(output));
          store.dispatch(appUserDataAction.setUser(output));
          store.dispatch(SponsorshipAction.setSponsorship(output));
          store.dispatch(CommercialsAction.setCommercials(commercialsDetails));
          // store.dispatch(PremiumFeatureAction.setPremiumFeature(userData.premiumFeatureTrialinfo));
          // store.dispatch(SponsorshipAction.setSponsorship(Sponsorship));
          store.dispatch(fccPageStateAction.setFccPageState({}));
          store.dispatch(fccAddressStateAction.setFccAddressState({}));
          let userSubscription = output.userSubscription;
          let userSelectedPlan = {};
          if (userSubscription !== null) {
            if (userSubscription.plan !== null) {
              userSelectedPlan = {
                selectedPlanName: userSubscription.plan.name,
                selectedPlanList: userSubscription.plan,
              };
            }
            store.dispatch(selectedPlanAction.setSelectedPlan(userSelectedPlan));
          }
          this.processLoginSuccess(output);
        } else if (Utils.equalsIgnoreCase(response.status, 'FAIL')) {
          if (response.errorCode && Utils.equalsIgnoreCase(response.errorCode, 'RVF_101')) {
            /* Verification is Not Yet done, Go To Verification Page */
            this.props.history.push({
              pathname: '/verification',
              state: { email: this.state.email, url: '/login' },
            });
          }
        }
      })
      .catch((error) => {});
  };

  handleRememberMeChange(e) {
    this.setState({ rememberMe: e.target.checked });
  }
  gotToButton() {
    document.getElementById('loginButton').focus();
  }
  gotToPassword() {
    document.getElementById('password').focus();
  }
  render() {
    return (
      <>
        <div md={12} style={{ padding: '1em', display: 'flex', margin: '2em 2em 1em 2em' }}>
          <Card
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              margin: 'auto',
              padding: '1em',
            }}
          >
            <CardHeader>
              <Row>
                <img src={vibeLogo} alt="" style={{ height: '50px', width: '55px' }} />
              </Row>
              <Row style={{ color: '#169ba2', fontSize: 'large' }}>
                <b>Sign In</b>
              </Row>
            </CardHeader>

            <ValidatorForm
              ref="form"
              onSubmit={this.loginAccountInBackend.bind(this)}
              style={{ width: '100%', padding: '1em' }}
            >
              <CardBody>
                <Row md={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    id="email"
                    value={this.state.email}
                    label="Email"
                    name="email"
                    type="text"
                    autoComplete="email"
                    autoFocus
                    onChange={this.handleEmailChange.bind(this)}
                  />
                </Row>
                <Row md={12}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    value={this.state.password}
                    name="password"
                    label="Password"
                    type={this.state.showPassword ? 'text' : 'password'}
                    id="password"
                    autoComplete="current-password"
                    onChange={this.handlePasswordChange.bind(this)}
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end" onFocus={this.gotToButton.bind(this)}>
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword.bind(this)}
                          >
                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Row>
                <Row md={12}>
                  <Button
                    id="loginButton"
                    type="submit"
                    variant="contained"
                    color="primary"
                    startIcon={<ExitToAppIcon />}
                    style={{ padding: '0.5em', backgroundColor: '#ef6603', color: '#fff', margin: 'auto' }}
                  >
                    Sign In
                  </Button>
                </Row>
                <Row md={12} style={{ alignItems: 'center', display: 'block', marginTop: '1em', marginBottom: '2em' }}>
                  <Col md={6}>
                    <Link
                      style={{ float: 'left', color: 'blue', fontWeight: '400', cursor: 'pointer' }}
                      onClick={(e) => this.backToHome()}
                    >
                      Back to home
                    </Link>
                  </Col>
                  <Col md={6} style={{ display: 'contents' }}>
                    <Link
                      style={{ float: 'right', color: 'blue', fontWeight: '400', cursor: 'pointer' }}
                      onClick={(e) => this.goToPage('/forgotPassword')}
                    >
                      Reset password?
                    </Link>
                  </Col>
                </Row>
              </CardBody>
              <CardFooter style={{ paddingLeft: '0px', paddingRight: '0px' }}>
                <Row md={12}>
                  <Col md={12} style={{ textAlign: 'center' }}>
                    {/* <span style={{ color: 'black', float: 'left' }}>
                      <Link
                        style={{ color: 'blue', fontWeight: '400', cursor: 'pointer' }}
                        onClick={(e) => this.backToHome()}
                      >
                        Back to home
                      </Link>
                    </span> */}
                    <span style={{ color: 'black', float: 'right' }}>
                      <Link
                        style={{ color: 'blue', fontWeight: '400', cursor: 'pointer' }}
                        onClick={(e) => this.goToPage('/createAccount')}
                      >
                        Register Now
                      </Link>
                    </span>
                  </Col>
                </Row>
              </CardFooter>
            </ValidatorForm>
          </Card>
        </div>
        {this.isMobile() === true && (
          <Row>
            <Col md={12} style={{ textAlign: 'center' }}>
              <p style={{ fontSize: '17px', color: '#fff' }}>
                <b>Use mobile apps for better User experience</b> &nbsp;&nbsp;
                {this.showMobileIcon()}{' '}
              </p>
              {/* {this.showMobileIcon()} */}
            </Col>
          </Row>
        )}
      </>
    );
  }
}
export default Login;
