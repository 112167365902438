import MaterialTable from '@material-table/core';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import AccessAlarmTwoToneIcon from '@material-ui/icons/AccessAlarmTwoTone';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ChatIcon from '@material-ui/icons/Chat';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import React, { Component } from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Card, CardHeader, Col, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import UiUtils from '../../../provider/UiUtils';
import Utils from '../../../provider/Utils';
import applicationStateAction from '../../../redux/applicationState/applicationStateAction';
import fccPageStateAction from '../../../redux/fccPageState/fccPageStateAction';
import helpDataAction from '../../../redux/help/displayHelpAction';
import store from '../../../redux/store';
import Advertisement from '../advertisement/Advertisement';
import AddIconButton from '../buttons/AddIconButton';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
import PtsAlert from '../ptsAlert/PtsAlert';
import './MemberAppointmentList.scss';
import BreadCrumbDetailsAction from '../../../redux/breadCrumbDetails/BreadCrumbDetailsAction';

export class MemberAppointmentList extends Component {
  title = 'Appointment List';
  memberName = store.getState().memberData.memberData.firstname + ' ' + store.getState().memberData.memberData.lastname;
  appointmentState = store.getState().applicationState.appointmentState;
  yearListArray = store.getState().staticData.yearList;
  isFreeUser = store.getState().userData.userData.isFreeUser;
  sponsorUser = store.getState().Sponsorship.Sponsorship;
  displayHelp = store.getState().help.help;
  memberHomeUrl = store.getState().memberHomeUrl.memberHomeUrl.homeUrl;
  breadCrumb = [
    { pageid: this.memberHomeUrl, uiname: this.memberName },
    { pageid: '/memberAppointment', uiname: 'Appointment List' },
  ];
  memberDetails = store.getState().memberData.memberData;
  header = store.getState().header.header;
  appointmentTypeListArray = [
    { type: 'All', description: null, id: 1 },
    { type: 'Doctor Appointment', description: null, id: 2 },
    { type: 'Facility Appointment', description: null, id: 3 },
  ];
  monthList = [
    { month: 'All' },
    { month: 'January' },
    { month: 'February' },
    { month: 'March' },
    { month: 'April' },
    { month: 'May' },
    { month: 'June' },
    { month: 'July' },
    { month: 'August' },
    { month: 'September' },
    { month: 'October' },
    { month: 'November' },
    { month: 'December' },
  ];
  appointmentStateDetails = {};
  constructor(props) {
    super(props);
    let calendarState = {};
    store.dispatch(
      applicationStateAction.setApplicationState({
        calendarState,
      })
    );
    if (this.yearListArray !== []) {
      this.yearListArray.unshift({ name: 'All', id: 'All' });
    }
    let yearListArray1 = this.yearListArray.reduce((unique, o) => {
      if (!unique.some((obj) => obj.name === o.name && obj.id === o.id)) {
        unique.push(o);
      }
      return unique;
    }, []);

    if (this.appointmentState) {
      this.appointmentStateDetails = Object.getOwnPropertyNames(this.appointmentState);
    }
    /*current URL path for sponsorship template*/
    let currentLocationPath1 = window.location.href;
    let pathArray = currentLocationPath1.split('/');
    let host = pathArray[4];
    let currentLocationPath = '/' + host;
    store.dispatch(fccPageStateAction.setFccPageState({}));
    if (this.breadCrumb) {
      Utils.getActiveMenu(this.breadCrumb);
      store.dispatch(BreadCrumbDetailsAction.setBreadCrumbDetails(this.breadCrumb));
    }
    if (this.appointmentStateDetails.length > 0) {
      this.state = {
        appointmentList: [],
        headerMonth: '',
        headerYear: '',
        anchorEl: null,
        doctor: this.appointmentState.doctor,
        type: this.appointmentState.appointmentType,
        month: this.appointmentState.appointmentMonth,
        allAppointmentList: [],
        scheduledAppointmentList: [],
        cancelledAppointmentList: [],
        completedAppointmentList: [],
        render: false,
        isDoctor: false,
        facility: {},
        doctorLocationList: [
          {
            name: 'All',
            doctorId: 'All',
            locationId: null,
            facilityId: 'All',
            type: 'ALL',
          },
        ],
        facilityLocationList: [],
        appointmentTypeList: this.appointmentTypeListArray,
        yearList: yearListArray1,
        year: this.appointmentState.appointmentYear,
        checkbox: false,
        yearCheckbox: false,
        monthView: false,
        yearView: false,
        selectedYear: '',
        selectedMonth: '',
        selectedTab: this.appointmentState.selectedTab,
        initialPage:
          this.appointmentState.initialPage && this.appointmentState.initialPage > 0
            ? this.appointmentState.initialPage
            : 0,
        appointmentId: this.appointmentState.appointmentId,
        getCreatePermission: false,
        isFreeUser: this.isFreeUser,
        sponsorUser: this.sponsorUser,
        currentLocationPath: currentLocationPath,
        stepsEnabled: false,
        initialStep: 0,
        pageSize:
          this.appointmentState.pageSize && this.appointmentState.pageSize ? this.appointmentState.pageSize : 10,
        steps: [
          {
            element: '.tabUpcoming',
            intro: 'List of future Appointments and some of past Appointments that needs to Mark Complete or Cancel',
          },
          {
            element: '.tabCompleted',
            intro: 'List of Completed Appointments',
          },
          {
            element: '.tabCancelled ',
            intro: 'List of Cancelled Appointments',
          },
          {
            element: '.tabAll',
            intro: 'List of All Appointments',
          },
          {
            element: '.newAppointment',
            intro: 'Add New Appointment',
          },
          {
            element: '.MuiTableRow-hover',
            intro: 'Click any row from the list to view/update details of Appointment.',
          },
          {
            element: '.doctorAppointmentAction',
            intro:
              '<b>Hierarchy</b> – See hierarchy of appointments. This can be done by adding reference appointments,<br> <b>Messages</b> – Messages related to this Appointment, <br><b>Transportation</b> – Transportation requests related to this Appointment,<br> <b>Reminders</b> – Reminders for this Appointment',
          },

          {
            element: '.filterData',
            intro: 'Filter Appointments by Appointment Type, Doctor/Facility Name or Month and Year.',
          },
          {
            element: '.resetData',
            intro: 'Reset Filter choices to view all appointments.',
          },
        ],
        displayFloatButton: this.displayHelp,
        isScheduledAppointmentListPaging: false,
        isCancelledAppointmentListPaging: false,
        isCompletedAppointmentListPaging: false,
        isAllAppointmentListPaging: false,
      };
    } else {
      this.state = {
        appointmentList: [],
        headerMonth: '',
        headerYear: '',
        anchorEl: null,
        doctor: {
          name: 'All',
          doctorId: 'All',
          locationId: null,
          facilityId: 'All',
          type: 'ALL',
        },
        type: this.appointmentTypeListArray[0],
        month: this.monthList[0],
        allAppointmentList: [],
        scheduledAppointmentList: [],
        cancelledAppointmentList: [],
        completedAppointmentList: [],
        render: false,
        isDoctor: false,
        facility: {},
        doctorLocationList: [
          {
            name: 'All',
            doctorId: 'All',
            locationId: null,
            facilityId: 'All',
            type: 'ALL',
          },
        ],
        facilityLocationList: [],
        appointmentTypeList: this.appointmentTypeListArray,
        yearList: yearListArray1,
        year: yearListArray1[0],
        checkbox: false,
        yearCheckbox: false,
        monthView: false,
        yearView: false,
        selectedYear: '',
        selectedMonth: '',
        selectedTab: 0,
        initialPage: 0,
        appointmentId: '',
        getCreatePermission: false,
        isFreeUser: this.isFreeUser,
        sponsorUser: this.sponsorUser,
        currentLocationPath: currentLocationPath,
        stepsEnabled: false,
        initialStep: 0,
        pageSize: 10,
        isScheduledAppointmentListPaging: false,
        isCancelledAppointmentListPaging: false,
        isCompletedAppointmentListPaging: false,
        isAllAppointmentListPaging: false,
        steps: [
          {
            element: '.tabUpcoming',
            intro: 'List of future Appointments and some of past Appointments that needs to Mark Complete or Cancel',
          },
          {
            element: '.tabCompleted',
            intro: 'List of Completed Appointments',
          },
          {
            element: '.tabCancelled ',
            intro: 'List of Cancelled Appointments',
          },
          {
            element: '.tabAll',
            intro: 'List of All Appointments',
          },
          {
            element: '.newAppointment',
            intro: 'Add New Appointment',
          },
          {
            element: '.MuiTableRow-hover',
            intro: 'Click any row from the list to view/update details of Appointment.',
          },
          {
            element: '.doctorAppointmentAction',
            intro:
              '<b>Hierarchy</b> – See hierarchy of appointments. This can be done by adding reference appointments,<br> <b>Messages</b> – Messages related to this Appointment, <br><b>Transportation</b> – Transportation requests related to this Appointment,<br> <b>Reminders</b> – Reminders for this Appointment',
          },

          {
            element: '.filterData',
            intro: 'Filter Appointments by Appointment Type, Doctor/Facility Name or Month and Year.',
          },
          {
            element: '.resetData',
            intro: 'Reset Filter choices to view all appointments.',
          },
        ],
        displayFloatButton: this.displayHelp,
      };
    }
  }
  async componentDidMount() {
    if (this.appointmentStateDetails.length > 0) {
      await this.getMemberDoctorByType();
      await this.filterDataInBackend();
    } else {
      await this.getMemberDoctorByType();
      await this.getAppointmentList();
    }
  }
  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };

  toggleSteps = () => {
    this.setState((prevState) => ({ stepsEnabled: !prevState.stepsEnabled }));
  };

  async getAppointmentList() {
    const postObject = {
      header: this.header,
      memberDetails: {
        id: this.memberDetails.member,
        month: 'All',
        year: 'All',
        type: 'All',
        doctorId: 'All',
        facilityId: 'All',
      },
    };

    await FetchServerData.callPostService('appointmentms/getFilteredList', postObject).then(async (output) => {
      if (output.status === 'SUCCESS') {
        let appointmentListArr = [];
        appointmentListArr = output.data.list;
        for (let i = 0; i < appointmentListArr.length; i++) {
          appointmentListArr[i].sortDate = appointmentListArr[i].date;
          appointmentListArr[i].date = Utils.convertUTCDateToUserTimeZoneViewDateFormate(appointmentListArr[i].date);
        }
        let getDomainObjectPermission = output.data.domainObjectPrivileges;
        let getCreatePermission = false;

        for (let domainObjectKey in getDomainObjectPermission) {
          if (domainObjectKey.toLowerCase() === 'appointment') {
            let appointmentPermission = getDomainObjectPermission[domainObjectKey];
            let getPermission = Utils.getDomainObjectRolePermission(appointmentPermission);
            getCreatePermission = getPermission[0];
          }
        }
        let scheduledAppointmentListArr = [];
        let cancelledAppointmentListArr = [];
        let completedAppointmentListArr = [];
        let isScheduledAppointmentListPaging = false;
        let isCancelledAppointmentListPaging = false;
        let isCompletedAppointmentListPaging = false;
        let isAllAppointmentListPaging = false;
        for (let i = 0; i < appointmentListArr.length; i++) {
          if (appointmentListArr[i].status === 'Scheduled') {
            scheduledAppointmentListArr.push(appointmentListArr[i]);
          } else if (appointmentListArr[i].status === 'ReScheduled') {
            scheduledAppointmentListArr.push(appointmentListArr[i]);
          } else if (appointmentListArr[i].status === 'Cancelled') {
            cancelledAppointmentListArr.push(appointmentListArr[i]);
          } else {
            completedAppointmentListArr.push(appointmentListArr[i]);
          }
        }
        for (let i = 0; i < scheduledAppointmentListArr.length; i++) {
          scheduledAppointmentListArr[i].rowNum = i + 1;
        }
        for (let i = 0; i < cancelledAppointmentListArr.length; i++) {
          cancelledAppointmentListArr[i].rowNum = i + 1;
        }
        for (let i = 0; i < completedAppointmentListArr.length; i++) {
          completedAppointmentListArr[i].rowNum = i + 1;
        }

        if (scheduledAppointmentListArr.length > 10) {
          isScheduledAppointmentListPaging = true;
        }
        if (cancelledAppointmentListArr.length > 10) {
          isCancelledAppointmentListPaging = true;
        }
        if (completedAppointmentListArr.length > 10) {
          isCompletedAppointmentListPaging = true;
        }
        if (appointmentListArr.length > 10) {
          isAllAppointmentListPaging = true;
        }

        this.setState({
          render: true,
          isScheduledAppointmentListPaging: isScheduledAppointmentListPaging,
          isCancelledAppointmentListPaging: isCancelledAppointmentListPaging,
          isCompletedAppointmentListPaging: isCompletedAppointmentListPaging,
          isAllAppointmentListPaging: isAllAppointmentListPaging,
          allAppointmentList: appointmentListArr,
          scheduledAppointmentList: scheduledAppointmentListArr,
          cancelledAppointmentList: cancelledAppointmentListArr,
          completedAppointmentList: completedAppointmentListArr,
          getCreatePermission: getCreatePermission,
        });
      } else {
      }
    });
  }

  addAppointment = (e) => {
    let fccPageState = {
      action: 'create',
      title: 'Add Appointment',
      pageId: 'addAppointment',
      appointmentId: '',
    };
    store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
    this.props.history.push({
      pathname: '/addAppointment',
      state: {
        action: 'create',
        title: 'Add Appointment',
        pageId: 'addAppointment',
        appointmentId: '',
      },
    });
  };

  displayActionTitle() {
    return <h5 className="doctorAppointmentAction">Action</h5>;
  }

  toAppointmentDetails = (e, data) => {
    let appointmentState = {
      appointmentType: this.state.type,
      appointmentMonth: this.state.month,
      doctor: this.state.doctor,
      appointmentYear: this.state.year,
      selectedTab: this.state.selectedTab,
      initialPage: this.state.initialPage,
      appointmentId: data.id,
      pageSize: this.state.pageSize,
    };
    store.dispatch(
      applicationStateAction.setApplicationState({
        appointmentState,
      })
    );
    let fccPageState = {
      id: data.id,
      action: 'view',
    };
    store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
    this.props.history.push({
      pathname: '/viewMemberAppointment',
      state: {
        action: 'view',
        title: 'Appointment Details',
        appointmentId: data.id,
        pageId: 'memberAppointment',
      },
    });
  };
  handleShowUnreadMessageCountClose = () => this.setState({ anchorEl: null });

  handleShowUnreadMessageCountClick = (event) => this.setState({ anchorEl: event.currentTarget });

  showUnreadMessageCount = (noteCount) => {
    let noteListCount =
      noteCount === null
        ? ''
        : noteCount.map((requestData, index) => {
            return (
              <>
                <tr>
                  <td>{requestData.note}</td>
                  <td>{requestData.unreadMessageCount}</td>
                </tr>
              </>
            );
          });
    return noteListCount;
  };

  handleDoctorChange(selectedDoctor) {
    this.state.doctor = selectedDoctor;
    this.setState({
      doctor: selectedDoctor,
    });
    if (undefined !== this.state.onChange && null !== this.state.onChange) {
      this.state.onChange(this.state);
    }
  }

  handleTypeChange(selectedType) {
    this.state.type = selectedType;
    if (selectedType === null) {
      if (undefined !== this.state.onChange && null !== this.state.onChange) {
        this.state.onChange(this.state);
      }
      this.setState({
        type: selectedType,
      });
    } else {
      this.getMemberDoctorByType();
    }
  }

  /*get doctor list*/
  async getMemberDoctorByType() {
    const postObject = {
      header: this.header,

      doctor: {
        member: {
          id: this.memberDetails.member,
        },
      },
      appointmentType: this.state.type.type,
    };

    await FetchServerData.callPostService('appointmentms/getMemberDoctorsFacilitiesByType', postObject).then(
      async (output) => {
        if (output.status === 'SUCCESS') {
          let doctorLocationList = output.data;

          doctorLocationList.unshift({
            name: 'All',
            doctorId: 'All',
            locationId: null,
            facilityId: 'All',
            type: 'ALL',
          });
          let doctorLocationListArray = doctorLocationList.reduce((unique, o) => {
            if (
              !unique.some(
                (obj) =>
                  obj.type === o.type &&
                  obj.doctorId === o.doctorId &&
                  obj.facilityId === o.facilityId &&
                  obj.name === o.name
              )
            ) {
              unique.push(o);
            }
            return unique;
          }, []);
          this.setState({
            doctorLocationList: doctorLocationListArray,
          });
        } else {
        }
      }
    );
  }

  async filterDataInBackend() {
    let selectedMonth = '';
    let selectedYear = '';
    let selectedDoctorId = '';
    let selectedFacilityId = '';
    if (this.state.month === null) {
      PtsAlert.error('Please Select Specific Month');
    } else if (this.state.year === null) {
      PtsAlert.error('Please Select Specific Year');
    } else {
      if (this.state.month !== null && this.state.month !== undefined) {
        if (Object.keys(this.state.month).length === 0 && this.state.month.constructor === Object) {
          selectedMonth = 'All';
        } else {
          selectedMonth = this.state.month.month;
        }
      } else {
        selectedMonth = 'All';
      }

      if (this.state.year !== null && this.state.year !== undefined) {
        if (Object.keys(this.state.year).length === 0 && this.state.year.constructor === Object) {
          selectedYear = 'All';
        } else {
          selectedYear = this.state.year.name;
        }
      } else {
        selectedYear = 'All';
      }

      if (this.state.doctor !== null && this.state.doctor !== undefined) {
        selectedDoctorId = this.state.doctor.doctorId;
      } else {
        selectedDoctorId = '';
      }
      if (this.state.doctor !== null && this.state.doctor !== undefined) {
        selectedFacilityId = this.state.doctor.facilityId;
      } else {
        selectedFacilityId = '';
      }
      const postObject = {
        header: this.header,

        memberDetails: {
          id: this.memberDetails.member,
          month: selectedMonth,
          year: selectedYear,
          type: this.state.type.type,
          doctorId: selectedDoctorId,
          facilityId: selectedFacilityId,
        },
      };
      await FetchServerData.callPostService('appointmentms/getFilteredList', postObject).then(async (output) => {
        if (output.status === 'SUCCESS') {
          let appointmentListArr = [];
          appointmentListArr = output.data.list;
          for (let i = 0; i < appointmentListArr.length; i++) {
            appointmentListArr[i].sortDate = appointmentListArr[i].date;
            appointmentListArr[i].date = Utils.convertUTCDateToUserTimeZoneViewDateFormate(appointmentListArr[i].date);
          }
          let getDomainObjectPermission = output.data.domainObjectPrivileges;
          // let getAllPermission = false;
          let getCreatePermission = false;

          for (let domainObjectKey in getDomainObjectPermission) {
            // let keyPermission = domainObjectKey;
            if (domainObjectKey.toLowerCase() === 'appointment') {
              let appointmentPermission = getDomainObjectPermission[domainObjectKey];
              let getPermission = Utils.getDomainObjectRolePermission(appointmentPermission);
              // getAllPermission = getPermission[0];
              getCreatePermission = getPermission[0];
            }
          }
          let scheduledAppointmentListArr = [];
          let cancelledAppointmentListArr = [];
          let completedAppointmentListArr = [];
          let isScheduledAppointmentListPaging = false;
          let isCancelledAppointmentListPaging = false;
          let isCompletedAppointmentListPaging = false;
          let isAllAppointmentListPaging = false;
          for (let i = 0; i < appointmentListArr.length; i++) {
            if (appointmentListArr[i].status === 'Scheduled') {
              scheduledAppointmentListArr.push(appointmentListArr[i]);
            } else if (appointmentListArr[i].status === 'ReScheduled') {
              scheduledAppointmentListArr.push(appointmentListArr[i]);
            } else if (appointmentListArr[i].status === 'Cancelled') {
              cancelledAppointmentListArr.push(appointmentListArr[i]);
            } else {
              completedAppointmentListArr.push(appointmentListArr[i]);
            }
          }

          for (let i = 0; i < scheduledAppointmentListArr.length; i++) {
            scheduledAppointmentListArr[i].rowNum = i + 1;
          }
          for (let i = 0; i < cancelledAppointmentListArr.length; i++) {
            cancelledAppointmentListArr[i].rowNum = i + 1;
          }
          for (let i = 0; i < completedAppointmentListArr.length; i++) {
            completedAppointmentListArr[i].rowNum = i + 1;
          }

          if (scheduledAppointmentListArr.length > 10) {
            isScheduledAppointmentListPaging = true;
          }
          if (cancelledAppointmentListArr.length > 10) {
            isCancelledAppointmentListPaging = true;
          }
          if (completedAppointmentListArr.length > 10) {
            isCompletedAppointmentListPaging = true;
          }
          if (appointmentListArr.length > 10) {
            isAllAppointmentListPaging = true;
          }
          this.setState({
            render: true,
            isScheduledAppointmentListPaging: isScheduledAppointmentListPaging,
            isCancelledAppointmentListPaging: isCancelledAppointmentListPaging,
            isCompletedAppointmentListPaging: isCompletedAppointmentListPaging,
            isAllAppointmentListPaging: isAllAppointmentListPaging,
            allAppointmentList: appointmentListArr,
            scheduledAppointmentList: scheduledAppointmentListArr,
            cancelledAppointmentList: cancelledAppointmentListArr,
            completedAppointmentList: completedAppointmentListArr,
            getCreatePermission: getCreatePermission,
          });
          let appointmentState = {
            appointmentType: this.state.type,
            appointmentMonth: this.state.month,
            doctor: this.state.doctor,
            appointmentYear: this.state.year,
            selectedTab: this.state.selectedTab,
            initialPage: this.state.initialPage,
            pageSize: this.state.pageSize,
          };
          store.dispatch(
            applicationStateAction.setApplicationState({
              appointmentState,
            })
          );
        } else {
        }
      });
    }
  }

  filterResetData() {
    const postObject = {
      header: this.header,
      memberDetails: {
        id: this.memberDetails.member,
        month: 'All',
        year: 'All',
        type: 'All',
        doctorId: 'All',
        facilityId: 'All',
      },
    };
    FetchServerData.callPostService('appointmentms/getFilteredList', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let appointmentListArr = [];
        appointmentListArr = output.data.list;
        for (let i = 0; i < appointmentListArr.length; i++) {
          appointmentListArr[i].sortDate = appointmentListArr[i].date;
          appointmentListArr[i].date = Utils.convertUTCDateToUserTimeZoneViewDateFormate(appointmentListArr[i].date);
        }
        let getDomainObjectPermission = output.data.domainObjectPrivileges;
        let getCreatePermission = false;

        for (let domainObjectKey in getDomainObjectPermission) {
          if (domainObjectKey.toLowerCase() === 'appointment') {
            let appointmentPermission = getDomainObjectPermission[domainObjectKey];
            let getPermission = Utils.getDomainObjectRolePermission(appointmentPermission);
            getCreatePermission = getPermission[0];
          }
        }
        let scheduledAppointmentListArr = [];
        let cancelledAppointmentListArr = [];
        let completedAppointmentListArr = [];
        let isScheduledAppointmentListPaging = false;
        let isCancelledAppointmentListPaging = false;
        let isCompletedAppointmentListPaging = false;
        let isAllAppointmentListPaging = false;
        for (let i = 0; i < appointmentListArr.length; i++) {
          if (appointmentListArr[i].status === 'Scheduled') {
            scheduledAppointmentListArr.push(appointmentListArr[i]);
          } else if (appointmentListArr[i].status === 'ReScheduled') {
            scheduledAppointmentListArr.push(appointmentListArr[i]);
          } else if (appointmentListArr[i].status === 'Cancelled') {
            cancelledAppointmentListArr.push(appointmentListArr[i]);
          } else {
            completedAppointmentListArr.push(appointmentListArr[i]);
          }
        }

        for (let i = 0; i < scheduledAppointmentListArr.length; i++) {
          scheduledAppointmentListArr[i].rowNum = i + 1;
        }
        for (let i = 0; i < cancelledAppointmentListArr.length; i++) {
          cancelledAppointmentListArr[i].rowNum = i + 1;
        }
        for (let i = 0; i < completedAppointmentListArr.length; i++) {
          completedAppointmentListArr[i].rowNum = i + 1;
        }

        if (scheduledAppointmentListArr.length > 10) {
          isScheduledAppointmentListPaging = true;
        }
        if (cancelledAppointmentListArr.length > 10) {
          isCancelledAppointmentListPaging = true;
        }
        if (completedAppointmentListArr.length > 10) {
          isCompletedAppointmentListPaging = true;
        }
        if (appointmentListArr.length > 10) {
          isAllAppointmentListPaging = true;
        }
        this.setState({
          render: true,
          isScheduledAppointmentListPaging: isScheduledAppointmentListPaging,
          isCancelledAppointmentListPaging: isCancelledAppointmentListPaging,
          isCompletedAppointmentListPaging: isCompletedAppointmentListPaging,
          isAllAppointmentListPaging: isAllAppointmentListPaging,
          allAppointmentList: appointmentListArr,
          scheduledAppointmentList: scheduledAppointmentListArr,
          cancelledAppointmentList: cancelledAppointmentListArr,
          completedAppointmentList: completedAppointmentListArr,
          getCreatePermission: getCreatePermission,
        });
        let appointmentState = {
          appointmentType: this.state.type,
          appointmentMonth: this.state.month,
          doctor: this.state.doctor,
          appointmentYear: this.state.year,
          selectedTab: this.state.selectedTab,
          initialPage: this.state.initialPage,
          pageSize: this.state.pageSize,
        };
        store.dispatch(
          applicationStateAction.setApplicationState({
            appointmentState,
          })
        );
      } else {
      }
    });
  }
  handleMonthChange(selectedMonth) {
    this.state.month = selectedMonth;

    if (undefined !== this.state.onChange && null !== this.state.onChange) {
      this.state.onChange(this.state);
    }
    this.setState({
      month: selectedMonth,
    });
  }
  handleYearChange(selectedYear) {
    this.state.year = selectedYear;
    this.setState({
      year: selectedYear,
    });
    if (undefined !== this.state.onChange && null !== this.state.onChange) {
      this.state.onChange(this.state);
    }
  }

  /*select tab function*/
  setKey(tab) {
    this.setState({
      selectedTab: tab,
    });
    let appointmentState = {
      appointmentType: this.state.type,
      appointmentMonth: this.state.month,
      doctor: this.state.doctor,
      appointmentYear: this.state.year,
      selectedTab: tab,
    };
    store.dispatch(
      applicationStateAction.setApplicationState({
        appointmentState,
      })
    );
  }

  onChangePage = (event, page) => {
    this.setState({
      initialPage: page,
    });
    let appointmentState = {
      appointmentType: this.state.type,
      appointmentMonth: this.state.month,
      doctor: this.state.doctor,
      appointmentYear: this.state.year,
      selectedTab: this.state.selectedTab,
      initialPage: page,
    };
    store.dispatch(
      applicationStateAction.setApplicationState({
        appointmentState,
      })
    );
  };
  resetData() {
    this.setState({
      selectedTab: this.state.selectedTab,
      type: this.state.appointmentTypeList[0],
      doctor: {
        name: 'All',
        doctorId: 'All',
        locationId: null,
        facilityId: 'All',
        type: 'ALL',
      },

      year: this.state.yearList[0],
      month: this.monthList[0],
    });
    let appointmentState = {};
    store.dispatch(applicationStateAction.setApplicationState({ appointmentState }));
    this.filterResetData();
  }
  getSerialNumber(rowData) {
    return 1;
  }

  getAppointmentStatus(rowData) {
    let appointmentStatus =
      rowData.markCompleteCancel === true ? (
        <>
          <h6 className="blink_me">Mark Completed</h6>
          <h6 className="blink_me">Mark Cancel</h6>
        </>
      ) : (
        ''
      );
    return appointmentStatus;
  }
  closeFloatingButton() {
    this.setState({ displayFloatButton: false });
    let displayFloat = false;

    store.dispatch(helpDataAction.setHelpData(displayFloat));
  }

  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }
  showStatusColor(status) {
    let color = '';
    if (status === 'Accepted') {
      color = '#2ECC71';
    } else if (status === 'Requested') {
      color = '#D4AC0D';
    } else if (status === 'Rejected') {
      color = '#E74C3C';
    } else {
      color = '';
    }
    return color;
  }
  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      this.state.scheduledAppointmentList.sort(function compare(a, b) {
        var dateA = new Date(a.sortDate);
        var dateB = new Date(b.sortDate);
        return dateA - dateB;
      });

      return (
        <>
          <MenuWithBreadScrum breadCrumb={this.breadCrumb} history={this.props.history} name={this.title} />
          <Row style={{ margin: '1em' }}>
            <Advertisement pageId="Appointments" />
          </Row>

          <Steps
            enabled={this.state.stepsEnabled}
            steps={this.state.steps}
            initialStep={this.state.initialStep}
            onExit={this.onExit}
          />
          <div className="appointmentList_filter">
            <Card style={{ background: '#fff', margin: '1.2em' }}>
              <CardHeader style={{ padding: '0em 1.2em' }}>
                <Row md={12} style={{ padding: '10px' }}>
                  <Col md={3}>
                    <Autocomplete
                      id="month"
                      options={null === this.state.appointmentTypeList ? [] : this.state.appointmentTypeList}
                      value={this.state.type}
                      getOptionLabel={(option) => option.type}
                      onChange={(event, value) => {
                        this.handleTypeChange(value);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Appointment Type" margin="normal" />
                      )}
                    />
                  </Col>
                  <Col md={3}>
                    <Autocomplete
                      id="doctor"
                      options={null === this.state.doctorLocationList ? [] : this.state.doctorLocationList}
                      value={this.state.doctor}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        this.handleDoctorChange(value);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Doctor/Facility" margin="normal" />
                      )}
                    />
                  </Col>

                  <Col md={2}>
                    <Autocomplete
                      id="month"
                      options={this.monthList}
                      value={this.state.month}
                      getOptionLabel={(option) => option.month}
                      onChange={(event, value) => {
                        this.handleMonthChange(value);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Month" margin="normal" />
                      )}
                    />
                  </Col>

                  <Col md={2}>
                    <Autocomplete
                      id="year"
                      options={null === this.state.yearList ? [] : this.state.yearList}
                      value={this.state.year}
                      getOptionLabel={(option) => option.name}
                      onChange={(event, value) => {
                        this.handleYearChange(value);
                      }}
                      renderInput={(params) => (
                        <TextField {...params} variant="outlined" label="Year" margin="normal" />
                      )}
                    />
                  </Col>

                  <Col md={2} className="filter_btn">
                    <Button
                      variant="contained"
                      size="small"
                      className="filterData"
                      startIcon={
                        <span>
                          <i className="fa fa-filter" aria-hidden="true"></i>
                        </span>
                      }
                      style={{
                        background: '#ef6603',
                        color: '#fff',
                        marginTop: '18px',
                        textTransform: 'capitalize',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => this.filterDataInBackend()}
                    >
                      Filter
                    </Button>
                    &nbsp;
                    <Button
                      variant="contained"
                      size="small"
                      className="resetData"
                      startIcon={
                        <span>
                          <i className="fa fa-refresh" aria-hidden="true"></i>
                        </span>
                      }
                      style={{
                        background: '#000',
                        color: '#fff',
                        marginTop: '18px',

                        textTransform: 'capitalize',
                        cursor: 'pointer',
                      }}
                      onClick={(e) => this.resetData()}
                    >
                      Reset
                    </Button>
                  </Col>
                </Row>
              </CardHeader>
            </Card>
          </div>

          <Row>
            <Col md={12} style={{ padding: '0em 2.4em 1em 2.4em' }} className="appointmentTab">
              {this.state.getAllPermission === true || this.state.getCreatePermission === true ? (
                <Row style={{ backgroundColor: 'none', margin: '-0.5em -1.3em -2.4em' }}>
                  <Col md={12}>
                    <AddIconButton btnName="Add Appointment" onClick={(e) => this.addAppointment(e)} />
                  </Col>
                </Row>
              ) : (
                <></>
              )}
              <Tabs defaultIndex={this.state.selectedTab} onSelect={(k) => this.setKey(k)}>
                <TabList>
                  <Tab>
                    {' '}
                    <span className="tabUpcoming">Upcoming</span>
                  </Tab>
                  <Tab>
                    <span className="tabCompleted">Completed</span>
                  </Tab>
                  <Tab>
                    <span className="tabCancelled">Cancelled</span>
                  </Tab>
                  <Tab>
                    {' '}
                    <span className="tabAll">All</span>
                  </Tab>
                </TabList>
                <TabPanel>
                  <MaterialTable
                    icons={UiUtils.getMaterialTableIcons()}
                    title=""
                    columns={[
                      {
                        title: '#',
                        filtering: false,
                        width: '3%',
                        render: (rowData) => rowData.tableData.index + 1,
                      },

                      {
                        title: 'Date',
                        type: 'date',
                        field: 'date',
                        filtering: false,
                        customSort: (a, b) => {
                          let aTime = a.sortDate !== null ? new Date(a.sortDate).getTime() : '';
                          let bTime = b.sortDate !== null ? new Date(b.sortDate).getTime() : '';
                          return aTime - bTime;
                        },

                        align: 'left',
                        width: '20%',
                      },
                      { title: 'Type', field: 'type', width: '12%' },
                      {
                        title: 'Appointment',
                        field: 'appointment',

                        width: '20%',
                      },
                      { title: 'Purpose', field: 'purpose', width: '15%' },

                      {
                        title: 'Status',
                        field: 'status',
                        filtering: false,
                        width: '10%',
                        filtering: false,
                      },
                      {
                        title: '',
                        width: '10%',
                        render: (rowData) =>
                          rowData.markCompleteCancel === true ? (
                            <>
                              <h6 className="blink_me">Mark Complete / Cancel</h6>
                            </>
                          ) : (
                            ''
                          ),
                      },
                    ]}
                    data={this.state.scheduledAppointmentList}
                    options={{
                      sorting: true,
                      search: true,

                      actionsColumnIndex: -1,
                      headerStyle: {
                        backgroundColor: '#1ABC9C',
                        fontWeight: 'bold',
                        color: '#fff',
                        align: 'left',
                        textAlign: 'left',
                      },
                      pageSizeOptions: [5, 10, 20, 50, 100],
                      // paging: this.state.isScheduledAppointmentListPaging,
                      paging: this.state.scheduledAppointmentList.length > this.state.pageSize,
                      pageSize: this.state.pageSize,
                      initialPage: this.state.initialPage,

                      filtering: true,
                      rowStyle: (rowData) => ({
                        backgroundColor: rowData.id === this.state.appointmentId ? '#FEF5E7' : '#fff',
                        height: '3em',
                      }),
                      tableLayout: 'auto',
                    }}
                    localization={{
                      header: {
                        actions: this.displayActionTitle(),
                      },
                      body: {
                        emptyDataSourceMessage: Utils.communizeTableText(),
                      },
                    }}
                    onPageChange={(page, pageSize) => {
                      this.handleMaterialTablePageChange(page, pageSize);
                    }}
                    actions={[
                      (rowData) => ({
                        icon: () =>
                          /*if appointment has Reference Appointment then show then button */
                          rowData.hasReferenceAppointments === true ? (
                            <>
                              <AccountTreeIcon
                                className="referenceAppointment"
                                style={{ fontSize: '25px', color: '#2E86C1' }}
                              />
                            </>
                          ) : (
                            <div style={{ marginLeft: '1em', fontSize: '1.5rem' }}></div>
                          ),
                        tooltip: 'Reference Appointment',
                      }),
                      (rowData) => ({
                        icon: () =>
                          /*if appointment has message threads then show then button */
                          rowData.hasNotes === true ? (
                            <>
                              <Badge badgeContent={rowData.unreadMessageCount} color="primary" overlap="rectangular">
                                <ChatIcon className="message" style={{ fontSize: '25px', color: '#58D68D' }} />
                              </Badge>
                              &nbsp;
                            </>
                          ) : (
                            <div style={{ marginLeft: '1.4em', fontSize: '1.5rem' }}></div>
                          ),
                        tooltip: 'Message',
                      }),

                      (rowData) => ({
                        icon: () =>
                          /*if appointment has active Reminder  then show then button */
                          rowData.hasReminders === true ? (
                            <>
                              <Badge badgeContent={rowData.activeReminderCount} color="primary">
                                <AccessAlarmTwoToneIcon
                                  className="reminder"
                                  style={{ fontSize: '25px', color: '#EF797D' }}
                                />
                              </Badge>
                              &nbsp;
                            </>
                          ) : (
                            <div style={{ marginLeft: '1.4em', fontSize: '1.5rem' }}></div>
                          ),
                        tooltip: 'Reminder',
                      }),
                      (rowData) => ({
                        /*if appointment has transportation  then show then button */
                        icon: () =>
                          rowData.hasTransportation === true ? (
                            <>
                              <DriveEtaIcon
                                className="transportation"
                                style={{ fontSize: '25px', color: this.showStatusColor(rowData.transportationStatus) }}
                              />
                              &nbsp;
                            </>
                          ) : (
                            <div style={{ marginLeft: '1em', fontSize: '25px' }}></div>
                          ),
                        tooltip: 'Transportation',
                      }),
                    ]}
                    onRowClick={(event, rowData) => {
                      this.toAppointmentDetails(event, rowData);
                      event.stopPropagation();
                    }}
                  />
                </TabPanel>
                <TabPanel>
                  <MaterialTable
                    icons={UiUtils.getMaterialTableIcons()}
                    title=""
                    columns={[
                      { title: '#', field: 'rowNum', filtering: false, width: '5%' },

                      {
                        title: 'Date',
                        type: 'date',
                        field: 'date',
                        filtering: false,
                        customSort: (a, b) => {
                          let aTime = a.sortDate !== null ? new Date(a.sortDate).getTime() : '';
                          let bTime = b.sortDate !== null ? new Date(b.sortDate).getTime() : '';
                          return aTime - bTime;
                        },
                        width: '20%',
                        align: 'left',
                      },
                      { title: 'Type', field: 'type', width: '20%' },
                      {
                        title: 'Appointment',
                        field: 'appointment',

                        width: '20%',
                      },
                      { title: 'Purpose', field: 'purpose', width: '20%' },

                      { title: 'Status', field: 'status', width: '20%', filtering: false },
                    ]}
                    data={this.state.completedAppointmentList}
                    options={{
                      sorting: true,
                      actionsColumnIndex: -1,
                      headerStyle: {
                        backgroundColor: '#1ABC9C',
                        fontWeight: 'bold',
                        color: '#fff',
                        align: 'center',
                        textAlign: 'left',
                      },
                      pageSizeOptions: [5, 10, 20, 50, 100],
                      // paging: this.state.isCompletedAppointmentListPaging,
                      paging: this.state.completedAppointmentList.length > this.state.pageSize,
                      pageSize: this.state.pageSize,
                      initialPage: this.state.initialPage,
                      filtering: true,
                      rowStyle: (rowData) => ({
                        backgroundColor: rowData.id === this.state.appointmentId ? '#FEF5E7' : '#fff',
                        height: '3em',
                      }),
                    }}
                    localization={{
                      header: {
                        actions: this.displayActionTitle(),
                      },
                      body: {
                        emptyDataSourceMessage: Utils.communizeTableText(),
                      },
                    }}
                    onPageChange={(page, pageSize) => {
                      this.handleMaterialTablePageChange(page, pageSize);
                    }}
                    actions={[
                      (rowData) => ({
                        icon: () =>
                          /*if appointment has Reference Appointment then show then button */
                          rowData.hasReferenceAppointments === true ? (
                            <>
                              <AccountTreeIcon
                                className="referenceAppointment"
                                style={{ fontSize: '25px', color: '#2E86C1' }}
                              />
                            </>
                          ) : (
                            <div style={{ marginLeft: '1em', fontSize: '1.5rem' }}></div>
                          ),
                        tooltip: 'Reference Appointment',
                      }),
                      (rowData) => ({
                        icon: () =>
                          /*if appointment has message threads then show then button */
                          rowData.hasNotes === true ? (
                            <>
                              <Badge badgeContent={rowData.unreadMessageCount} color="primary" overlap="rectangular">
                                <ChatIcon
                                  className="message"
                                  style={{ fontSize: '25px', color: '#58D68D', marginRight: '0.2em' }}
                                />
                              </Badge>
                              &nbsp;
                            </>
                          ) : (
                            <div style={{ marginLeft: '1.4em', fontSize: '1.5rem' }}></div>
                          ),
                        tooltip: 'Message',
                      }),

                      (rowData) => ({
                        icon: () =>
                          /*if appointment has active Reminder  then show then button */
                          rowData.hasReminders === true ? (
                            <>
                              <Badge badgeContent={rowData.activeReminderCount} color="primary" overlap="rectangular">
                                <AccessAlarmTwoToneIcon
                                  className="reminder"
                                  style={{ fontSize: '25px', color: '#EF797D' }}
                                />
                              </Badge>
                              &nbsp;
                            </>
                          ) : (
                            <div style={{ marginLeft: '1.4em', fontSize: '1.5rem' }}></div>
                          ),
                        tooltip: 'Reminder',
                      }),
                      (rowData) => ({
                        icon: () =>
                          /*if appointment has transportation  then show then button */
                          rowData.hasTransportation === true ? (
                            <>
                              <DriveEtaIcon
                                className="transportation"
                                style={{
                                  fontSize: '25px',
                                  color: this.showStatusColor(rowData.transportationStatus),
                                  marginLeft: '0.2em',
                                }}
                              />
                              &nbsp;
                            </>
                          ) : (
                            <div style={{ marginLeft: '1em' }}></div>
                          ),
                        tooltip: 'Transportation',
                      }),
                    ]}
                    onRowClick={(event, rowData) => {
                      this.toAppointmentDetails(event, rowData);
                      event.stopPropagation();
                    }}
                  />
                </TabPanel>
                <TabPanel>
                  <MaterialTable
                    icons={UiUtils.getMaterialTableIcons()}
                    title=""
                    columns={[
                      { title: '#', field: 'rowNum', filtering: false, width: '5%' },

                      {
                        title: 'Date',
                        type: 'date',
                        field: 'date',
                        filtering: false,
                        customSort: (a, b) => {
                          let aTime = a.sortDate !== null ? new Date(a.sortDate).getTime() : '';
                          let bTime = b.sortDate !== null ? new Date(b.sortDate).getTime() : '';
                          return aTime - bTime;
                        },
                        width: '20%',
                        align: 'left',
                      },
                      { title: 'Type', field: 'type', width: '20%' },
                      {
                        title: 'Appointment',
                        field: 'details',

                        width: '20%',
                      },
                      { title: 'Purpose', field: 'purpose', width: '20%' },

                      { title: 'Status', field: 'status', width: '20%', filtering: false },
                    ]}
                    data={this.state.cancelledAppointmentList}
                    options={{
                      sorting: true,
                      actionsColumnIndex: -1,
                      headerStyle: {
                        backgroundColor: '#1ABC9C',
                        fontWeight: 'bold',
                        color: '#fff',
                        align: 'center',
                        textAlign: 'left',
                      },
                      pageSizeOptions: [5, 10, 20, 50, 100],
                      pageSize: this.state.pageSize,
                      initialPage: this.state.initialPage,
                      paging: this.state.cancelledAppointmentList.length > this.state.pageSize,
                      // paging: this.state.isCancelledAppointmentListPaging,
                      filtering: true,
                      rowStyle: (rowData) => ({
                        backgroundColor: rowData.id === this.state.appointmentId ? '#FEF5E7' : '#fff',
                        height: '3em',
                      }),
                    }}
                    localization={{
                      header: {
                        actions: this.displayActionTitle(),
                      },
                      body: {
                        emptyDataSourceMessage: Utils.communizeTableText(),
                      },
                    }}
                    onPageChange={(page, pageSize) => {
                      this.handleMaterialTablePageChange(page, pageSize);
                    }}
                    actions={[
                      (rowData) => ({
                        /*if appointment has Reference Appointment then show then button */
                        icon: () =>
                          rowData.hasReferenceAppointments === true ? (
                            <>
                              <AccountTreeIcon
                                className="referenceAppointment"
                                style={{ fontSize: '25px', color: '#2E86C1' }}
                              />
                            </>
                          ) : (
                            <div style={{ marginLeft: '1em', fontSize: '1.5rem' }}></div>
                          ),
                        tooltip: 'Reference Appointment',
                      }),
                      (rowData) => ({
                        icon: () =>
                          /*if appointment has message threads then show then button */
                          rowData.hasNotes === true ? (
                            <>
                              <Badge badgeContent={rowData.unreadMessageCount} color="primary" overlap="rectangular">
                                <ChatIcon
                                  className="message"
                                  style={{ fontSize: '25px', color: '#58D68D', marginRight: '0.2em' }}
                                />
                              </Badge>
                              &nbsp;
                            </>
                          ) : (
                            <>
                              <div style={{ marginLeft: '1.4em', fontSize: '1.5rem' }}></div>
                            </>
                          ),
                        tooltip: 'Message',
                      }),

                      (rowData) => ({
                        icon: () =>
                          /*if appointment has active Reminder  then show then button */
                          rowData.hasReminders === true ? (
                            <>
                              <Badge badgeContent={rowData.activeReminderCount} color="primary" overlap="rectangular">
                                <AccessAlarmTwoToneIcon
                                  className="reminder"
                                  style={{ fontSize: '25px', color: '#EF797D' }}
                                />
                              </Badge>
                              &nbsp;
                            </>
                          ) : (
                            <>
                              <div style={{ marginLeft: '1.4em', fontSize: '1.5rem' }}></div>
                            </>
                          ),
                        tooltip: 'Reminder',
                      }),
                      (rowData) => ({
                        icon: () =>
                          /*if appointment has transportation  then show then button */
                          rowData.hasTransportation === true ? (
                            <>
                              <DriveEtaIcon
                                className="transportation"
                                style={{
                                  fontSize: '25px',
                                  color: this.showStatusColor(rowData.transportationStatus),
                                  marginLeft: '0.2em',
                                }}
                              />
                              &nbsp;
                            </>
                          ) : (
                            <div style={{ marginLeft: '1em' }}></div>
                          ),
                        tooltip: 'Transportation',
                      }),
                    ]}
                    onRowClick={(event, rowData) => {
                      this.toAppointmentDetails(event, rowData);
                      event.stopPropagation();
                    }}
                  />
                </TabPanel>
                <TabPanel>
                  <MaterialTable
                    icons={UiUtils.getMaterialTableIcons()}
                    title=""
                    columns={[
                      {
                        title: '#',
                        render: (rowData) => rowData.tableData.index + 1,
                        filtering: false,
                        width: '3%',
                      },
                      {
                        title: 'Date',
                        type: 'date',
                        field: 'date',
                        filtering: false,
                        width: '20%',
                        customSort: (a, b) => {
                          let aTime = a.sortDate !== null ? new Date(a.sortDate).getTime() : '';
                          let bTime = b.sortDate !== null ? new Date(b.sortDate).getTime() : '';
                          return aTime - bTime;
                        },

                        align: 'left',
                      },
                      { title: 'Type', field: 'type', width: '12%' },
                      {
                        title: 'Appointment',
                        field: 'appointment',

                        width: '20%',
                      },
                      { title: 'Purpose', field: 'purpose', width: '15%' },

                      {
                        title: 'Status',
                        filtering: false,
                        field: 'status',
                        width: '10%',
                        filtering: false,
                      },
                      {
                        title: '',
                        width: '10%',
                        render: (rowData) =>
                          rowData.markCompleteCancel === true ? (
                            <>
                              <h6 className="blink_me">Mark Complete / Cancel</h6>
                            </>
                          ) : (
                            ''
                          ),
                      },
                    ]}
                    data={this.state.allAppointmentList}
                    options={{
                      sorting: true,
                      search: true,
                      actionsColumnIndex: -1,
                      headerStyle: {
                        backgroundColor: '#1ABC9C',
                        fontWeight: 'bold',
                        color: '#fff',
                        align: 'center',
                        textAlign: 'left',
                      },
                      pageSizeOptions: [5, 10, 20, 50, 100],
                      // paging: this.state.isAllAppointmentListPaging,
                      paging: this.state.allAppointmentList.length > this.state.pageSize,
                      pageSize: this.state.pageSize,
                      initialPage: this.state.initialPage,
                      filtering: true,
                      rowStyle: (rowData) => ({
                        backgroundColor: rowData.id === this.state.appointmentId ? '#FEF5E7' : '#fff',
                        height: '3em',
                      }),
                    }}
                    localization={{
                      header: {
                        actions: this.displayActionTitle(),
                      },
                      body: {
                        emptyDataSourceMessage: Utils.communizeTableText(),
                      },
                    }}
                    actions={[
                      (rowData) => ({
                        /*if appointment has Reference Appointment then show then button */
                        icon: () =>
                          rowData.hasReferenceAppointments === true ? (
                            <>
                              <AccountTreeIcon
                                className="referenceAppointment"
                                style={{ fontSize: '25px', color: '#2E86C1' }}
                              />
                            </>
                          ) : (
                            <div style={{ marginLeft: '1em', fontSize: '1.5rem' }}></div>
                          ),
                        tooltip: 'Reference Appointment',
                      }),
                      (rowData) => ({
                        /*if appointment has Message Threads then show then button */
                        icon: () =>
                          rowData.hasNotes === true ? (
                            <>
                              <Badge badgeContent={rowData.unreadMessageCount} color="primary" overlap="rectangular">
                                <ChatIcon
                                  className="message"
                                  style={{ fontSize: '25px', color: '#58D68D', marginRight: '0.2em' }}
                                />
                              </Badge>
                              &nbsp;
                            </>
                          ) : (
                            <>
                              <div style={{ marginLeft: '1.4em', fontSize: '1.5rem' }}></div>
                            </>
                          ),
                        tooltip: 'Message',
                      }),

                      (rowData) => ({
                        icon: () =>
                          /*if appointment has  Reminders then show then button */
                          rowData.hasReminders === true ? (
                            <>
                              <Badge badgeContent={rowData.activeReminderCount} color="primary" overlap="rectangular">
                                <AccessAlarmTwoToneIcon
                                  className="reminder"
                                  style={{ fontSize: '25px', color: '#EF797D' }}
                                />
                              </Badge>
                              &nbsp;
                            </>
                          ) : (
                            <>
                              <div style={{ marginLeft: '1.4em', fontSize: '1.5rem' }}></div>
                            </>
                          ),
                        tooltip: 'Reminder',
                      }),
                      (rowData) => ({
                        /*if appointment has transportation then show then button */
                        icon: () =>
                          rowData.hasTransportation === true ? (
                            <>
                              <DriveEtaIcon
                                className="transportation"
                                style={{
                                  fontSize: '25px',
                                  color: this.showStatusColor(rowData.transportationStatus),
                                  marginLeft: '0.2em',
                                }}
                              />
                              &nbsp;
                            </>
                          ) : (
                            <div style={{ marginLeft: '1em' }}></div>
                          ),
                        tooltip: 'Transportation',
                      }),
                    ]}
                    onRowClick={(event, rowData) => {
                      this.toAppointmentDetails(event, rowData);
                      event.stopPropagation();
                    }}
                    onPageChange={(page, pageSize) => {
                      this.handleMaterialTablePageChange(page, pageSize);
                    }}
                  />
                </TabPanel>
              </Tabs>
            </Col>
          </Row>
        </>
      );
    }
  }
}

export default MemberAppointmentList;
