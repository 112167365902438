import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import HomeIcon from '@material-ui/icons/Home';
import SaveIcon from '@material-ui/icons/Save';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { format } from 'date-fns';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import 'react-tabs/style/react-tabs.css';
import { Breadcrumb, BreadcrumbItem, Card, CardBody, CardFooter, CardHeader, Col, Label, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import store from '../../../redux/store';
import PtsAlert from '../ptsAlert/PtsAlert';
import './messaging.scss';
export class messaging extends Component {
  contactTypeListArray = store.getState().staticData.contactTypeList;

  doctorListArray = [];
  locationReferenceList = [];
  constructor(props) {
    super(props);

    let memberId = localStorage.getItem('memberDetailsId');

    let contactId = props.contactId;

    let canRender = false;
    if (null !== contactId && contactId > 0) {
      this.getContacteDetails(contactId);
    } else {
      canRender = true;
    }
    this.state = {
      id: contactId,
      action: 'create',
      render: canRender,
      showSuccessAlert: false,
      showFailAlert: false,
      type: '',
      subject: '',
      mname: '',
      message: '',
      dob: format(new Date(), 'yyyy-MM-dd'),
      email: '',
      mobile: '',
      description: '',
      title: 'Messaging',
      appuserId: '',
      memberId: memberId,
      onChange: this.props.onChange,
      contactType: {},
      address: {},
      participant: [],
      sweetAlert: false,
      participantList: [
        { id: '1', participant: 'Male' },
        { id: '2', participant: 'Female' },
        { id: '3', participant: 'Other' },
      ],
      typeList: [
        { id: '1', type: 'Mr' },
        { id: '2', type: 'Miss' },
      ],
      locationItemsCreateDiv: false,
      locationItemsView: true,
      location: {},
      locationitems: [],
      viewLocationitems: [],
      contactEditDiv: false,
      contactViewDiv: true,
    };
  }

  locationItemChanged(item) {}
  handleParticipantChange(selectedparticipant) {
    this.setState({ participant: selectedparticipant });
  }
  handleSubjectChange(e) {
    this.setState({ subject: e.target.value });
  }

  handleMnameChange(e) {
    this.setState({ mname: e.target.value });
  }
  handleMessageChange(e) {
    this.setState({ message: e.target.value });
  }
  handleDobChange(e) {
    this.setState({ dob: e.target.value });
  }
  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }
  handleMobileChange(e) {
    this.setState({ mobile: e.target.value });
  }
  handleDescriptionChange(e) {
    this.setState({ description: e.target.value });
  }

  handleAddressChange(inpuAddress) {
    this.setState({ address: inpuAddress });
  }

  handleTypeChange(selectedtype) {
    this.setState({
      type: selectedtype,
    });
  }
  handleContactTypeChange(selectedContact) {
    this.setState({
      contactType: selectedContact,
    });
  }
  editContactItem() {
    this.setState({ contactEditDiv: true, contactViewDiv: false });
  }
  updateContact = (e, contactId) => {
    this.props.history.push({
      pathname: '/editMemberContacts',
      state: { action: 'edit', title: 'Edit Contact ', contactId: contactId },
    });
  };

  viewCancel = () => {
    this.props.history.push({
      pathname: '/memberContact',
    });
  };

  editCancel = (e, contactId) => {
    this.props.history.push({
      pathname: '/viewMemberContacts',
      state: { action: 'view', title: 'Contact Details ', contactId: contactId },
    });
  };

  createCancel = () => {
    this.props.history.push({
      pathname: '/memberContact',
    });
  };

  locationCancel = () => {
    this.setState({ locationItemsView: true, locationItemsCreateDiv: false });
  };
  backToLocationView = () => {
    this.setState({ locationItemsView: true, locationItemsCreateDiv: false });
  };
  LocationCancelChanged = () => {
    this.setState({ locationItemsView: true, locationItemsCreateDiv: false });
  };
  locationEditCancel = () => {
    this.setState({ locationItemsView: true, locationItemsCreateDiv: false });
  };
  addLocationData = () => {};
  deleteAlert = (e, contactId) => {
    this.setState({
      sweetAlert: true,
    });
  };

  cancelDelete = (e, contactId) => {
    this.setState({
      sweetAlert: false,
    });
  };

  deleteContact = () => {
    const header = store.getState().header.header;
    let id = this.state.id;
    // let memberId = localStorage.getItem('memberDetailsId');
    const postObject = {
      header: header,
    };
    let url = '/contactms/delete/';
    FetchServerData.callPostService(url + id, postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          this.props.history.push({
            pathname: '/memberContact',
            sweetAlert: false,
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  formValidation(type) {
    let subject = this.state.subject;
    let message = this.state.message;
    let MessageType = this.state.type;
    let participant = this.state.participant;
    if (null === subject || subject.length <= 0) {
      //   alert('Enter First subject');
      PtsAlert.error('Enter First subject');
      return false;
    } else if (null === message || message.length <= 0) {
      //   alert('Enter Last Message');
      PtsAlert.error('Enter Last Message');
      return false;
    } else if (null === MessageType || MessageType.length <= 0) {
      //   alert('Select Type');
      PtsAlert.error('Select Type');
      return false;
    } else if (null === participant || participant.length <= 0) {
      //   alert('Select  Participant');
      PtsAlert.error('Select  Participant');
      return false;
    } else {
      this.ContactInBackend(type);
    }
  }
  ContactInBackend = (type) => {
    if (type === 'Create') {
      for (let i = 0; i < this.locationReferenceList.length; i++) {
        if (null !== this.locationReferenceList[i].current) {
          this.state.locationitems[i].shortDescription = this.locationReferenceList[i].current.state.shortDescription;
          this.state.locationitems[i].nickName = this.locationReferenceList[i].current.state.nickName;
          this.state.locationitems[i].fax = this.locationReferenceList[i].current.state.fax;
          this.state.locationitems[i].website = this.locationReferenceList[i].current.state.website;
          this.state.locationitems[i].phone = this.locationReferenceList[i].current.state.phone;
          this.state.locationitems[i].address.line = this.locationReferenceList[i].current.state.line;
          this.state.locationitems[i].address.city = this.locationReferenceList[i].current.state.city;
          this.state.locationitems[i].address.zipCode = this.locationReferenceList[i].current.state.zipCode;
          this.state.locationitems[i].address.state = this.locationReferenceList[i].current.state.state;
        }
      }
      const header = store.getState().header.header;
      const postObject = {
        header: header,
        contactVo: {
          firstName: this.state.subject,
          lastName: this.state.message,
          email: this.state.email,
          phone: this.state.mobile,
          dob: this.state.dob,
          type: this.state.type.type,
          participant: this.state.participant.participant,
          description: this.state.description,
          // type: {
          //   type: this.state.contactType.type,
          // },
          member: {
            id: this.state.memberId,
          },
          locationList: this.state.locationitems,
        },
      };
      FetchServerData.callPostService('/contactms/create', postObject)
        .then((output) => {
          if (output.status === 'SUCCESS') {
            // let contactDetails = output.data;

            this.props.history.push({
              pathname: '/memberContact',
            });
          } else {
          }
        })
        .catch((error) => {});
    } else {
      for (let i = 0; i < this.locationReferenceList.length; i++) {
        if (null !== this.locationReferenceList[i].current) {
          this.state.locationitems[i].shortDescription = this.locationReferenceList[i].current.state.shortDescription;
          this.state.locationitems[i].nickName = this.locationReferenceList[i].current.state.nickName;
          this.state.locationitems[i].fax = this.locationReferenceList[i].current.state.fax;
          this.state.locationitems[i].website = this.locationReferenceList[i].current.state.website;
          this.state.locationitems[i].phone = this.locationReferenceList[i].current.state.phone;
          this.state.locationitems[i].address.line = this.locationReferenceList[i].current.state.line;
          this.state.locationitems[i].address.city = this.locationReferenceList[i].current.state.city;
          this.state.locationitems[i].address.zipCode = this.locationReferenceList[i].current.state.zipCode;
          this.state.locationitems[i].address.state = this.locationReferenceList[i].current.state.state;
        }
      }
      const header = store.getState().header.header;
      const postObject = {
        header: header,
        contactVo: {
          id: this.state.id,
          firstName: this.state.subject,
          lastName: this.state.message,
          email: this.state.email,
          phone: this.state.mobile,
          dob: this.state.dob,
          type: this.state.type.type,
          participant: this.state.participant.participant,
          description: this.state.description,

          // type: {
          //   type: this.state.contactType.type,
          // },
          appuser: {
            id: this.state.appuserId,
          },
          member: {
            id: this.state.memberId,
          },
          locationList: this.state.locationitems,
        },
      };
      FetchServerData.callPostService('/contactms/update', postObject)
        .then((output) => {
          if (output.status === 'SUCCESS') {
            let contactDetails = output.data;

            this.props.history.push({
              pathname: '/viewMemberContacts',
              state: { action: 'view', title: 'Contact Details', contactId: contactDetails.id },
            });
          } else {
          }
        })
        .catch((error) => {});
    }
  };

  render() {
    if (this.state.action === 'create') {
      return this.createContact();
    }
    if (this.state.action === 'edit') {
      return this.editContact();
    } else if (this.state.action === 'view') {
      return this.viewContact();
    }
  }

  createContact() {
    return (
      <>
        <div
          md={12}
          style={{
            marginTop: '-28px',
            marginRight: '-31px',
            marginLeft: '-31px',
            // minHeight: '2.5em',
            backgroundColor: 'white',
            alignItems: 'flex-end',
            // marginBottom:'1em'
          }}
        >
          <CardHeader>
            <Row>
              <Col md={8}>
                {undefined !== this.state.title && this.state.title.length > 0 && (
                  <Label style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}>{this.state.title}</Label>
                )}
              </Col>
              <Col md={4}>
                <Breadcrumb>
                  <BreadcrumbItem>
                    <NavLink to="/home">
                      <HomeIcon />
                    </NavLink>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <NavLink to="/memberdeatils">Member Home</NavLink>
                  </BreadcrumbItem>
                  <BreadcrumbItem>
                    <NavLink to="/memberContact">Contact List</NavLink>
                  </BreadcrumbItem>

                  <BreadcrumbItem active={true} style={{ backgroundColor: '#fff' }}>
                    <a href="/memberContacts">Contact Create</a>
                  </BreadcrumbItem>
                </Breadcrumb>
              </Col>
            </Row>
          </CardHeader>
        </div>

        <Row style={{ marginTop: '2em' }}>
          {' '}
          <Col md={12}>
            {' '}
            <Card className="doctorViewCard" style={{ height: 'auto', padding: '1em' }}>
              <CardHeader style={{ textAlign: 'center', color: '#2c60bf' }}>
                <Row style={{ marginLeft: '0.5em' }}>Message Create</Row>
              </CardHeader>
              <CardBody>
                <form ref={(form) => (this.form = form)}>
                  <Row style={{ marginTop: '1em' }}>
                    <Col md={3}>
                      <TextField
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                        id="subject"
                        onChange={this.handleSubjectChange.bind(this)}
                        label="Subject"
                        name="subject"
                        autoComplete="subject"
                        autoFocus
                        value={this.state.subject}
                      />
                    </Col>
                    <Col md={3} className="dropdownCol">
                      <Autocomplete
                        required
                        id="type"
                        options={this.state.typeList}
                        value={this.state.type}
                        getOptionLabel={(option) => option.type}
                        onChange={(event, value) => {
                          this.handleTypeChange(value);
                        }}
                        renderInput={(params) => <TextField {...params} required variant="outlined" label="Type" />}
                      />
                    </Col>
                    <Col md={3} className="dropdownCol">
                      <Autocomplete
                        multiple
                        required
                        id="tags-outlined"
                        options={this.contactTypeListArray}
                        getOptionLabel={(option) => option.type}
                        defaultValue={this.state.participant}
                        filterSelectedOptions
                        onChange={(event, value) => {
                          this.handleParticipantChange(value);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} required variant="outlined" label="Participant" />
                        )}
                      />
                    </Col>
                    <Col md={3}>
                      <TextField
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                        id="message"
                        onChange={this.handleMessageChange.bind(this)}
                        label="Message"
                        name="message"
                        autoComplete="message"
                        autoFocus
                        value={this.state.message}
                      />
                    </Col>
                  </Row>
                </form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <CardFooter>
          <div style={{ textAlign: 'center' }}>
            <Button
              type="submit"
              style={{ backgroundColor: '#162c50', color: '#fff' }}
              variant="contained"
              startIcon={<SaveIcon />}
              onClick={(e) => this.formValidation('Create')}
            >
              Create{' '}
            </Button>
            &nbsp;
            <Button
              type="submit"
              style={{ backgroundColor: '#162c50', color: '#fff' }}
              variant="contained"
              startIcon={<CloseIcon />}
              onClick={(e) => this.createCancel(e)}
            >
              Cancel
            </Button>
          </div>
        </CardFooter>
      </>
    );
  }

  viewContact() {}

  editContact() {}
}
