import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { Col, Label, Row } from 'reactstrap';
import Utils from '../../../provider/Utils';
export class Member extends Component {
  constructor(props) {
    super(props);
    let memberId = props.memberId;
    let action = props.action;
    if (!memberId && props.history && props.history.location && props.history.location.state) {
      memberId = props.history.location.state.memberId;
      action = props.history.location.state.action;
    }
    let memberData = props.data;
    if (action === 'view' || action === 'edit') {
      this.state = {
        id: null === memberData.id ? '' : memberData.id,
        salutation: null === memberData.salutation ? '' : memberData.salutation,
        fname: null === memberData.firstName ? '' : memberData.firstName,
        lname: null === memberData.lastName ? '' : memberData.lastName,
        email: null === memberData.email ? '' : memberData.email,
        mobile: null === memberData.phone ? '' : memberData.phone,
        description: null === memberData.description ? '' : memberData.description,
        action: action,
        onChange: this.props.onChange,
        render: false,
      };
    } else {
      this.state = {
        id: memberId,
        address: {},
        action: action,
        render: false,
        fname: '',
        mname: '',
        lname: '',
        email: '',
        mobile: '',
        note: '',
        description: '',
        memberData: '',
        salutation: {},
        onChange: this.props.onChange,
      };
    }
  }

  render() {
    if (this.state.action === 'view') {
      return this.viewMember();
    }
  }

  viewMember() {
    return (
      <div>
        <Row>
          <Col md={12}>
            {undefined !== this.props.title && this.props.title.length > 0 && (
              <Row style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                <Col>
                  <Label style={{ fontWeight: '600', color: '#1b7189' }}>{this.props.title}</Label>
                </Col>
              </Row>
            )}
            <Row style={{ marginTop: '0.5em' }}>
              <Col md={3}>
                <label>Name</label>
              </Col>
              <Col md={9} style={{ textTransform: 'capitalize' }}>
                {this.state.fname + ' ' + this.state.lname}
              </Col>
            </Row>

            <Row>
              <Col md={3}>
                <label>Email</label>
              </Col>
              <Col md={9}>
                <a href={'mailto:' + this.state.email} target="_top">
                  {this.state.email}
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}
export default withRouter(Member);
