import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';

import FetchServerData from '../../../../provider/FetchServerData';

import store from '../../../../redux/store';
import PtsAlert from '../../../components/ptsAlert/PtsAlert';
import template1 from '../../../../assets/images/AdTemplate3/imgjumbo04.jpg';

class Template3 extends Component {
  // sponsorUser = store.getState().Sponsorship.Sponsorship;
  header = store.getState().adminHeader.adminHeader;
  advertisementObject = {
    heading: 'Heading2222',
    subHeading: 'Subheading22222222',
    message: 'saepe placeat eius cum ducimus sunt, libero consequatur nemo voluptas sapiente possimus.',
    website: '',
  };
  constructor(props) {
    // let pageId = props.pageId;
    let action = props.action;
    let advertisementDetails = props.data;

    super(props);
    this.state = {
      action: action,
      sponsorUser: this.sponsorUser,
      // pageId: pageId,
      advertisementId: this.props.advertisementId,
      heading: advertisementDetails ? advertisementDetails.heading : '',
      subHeading: advertisementDetails ? advertisementDetails.subheading : '',
      message: advertisementDetails ? advertisementDetails.message : '',
      website: advertisementDetails ? advertisementDetails.website : '',
      createHeading: advertisementDetails ? advertisementDetails.heading : this.advertisementObject.heading,
      createSubHeading: advertisementDetails ? advertisementDetails.subheading : this.advertisementObject.subHeading,
      createMessage: advertisementDetails ? advertisementDetails.message : this.advertisementObject.message,
      createWebsite: advertisementDetails ? advertisementDetails.website : this.advertisementObject.website,
      onChange: this.props.onChange,
      imagePosition: '',
      leftImageFile: this.props.data ? this.props.data.leftimage : '',
      backgroundImageFile: this.props.data ? this.props.data.backgroundimage : '',

      showRemoveButton: false,
    };
  }

  handleHeadingChange(e) {
    this.setState({ heading: e.target.value });
  }
  handleSubHeadingChange(e) {
    this.setState({ subHeading: e.target.value });
  }
  handleWebsiteChange(e) {
    this.setState({ website: e.target.value });
  }
  handleCreatWebsiteChange(e) {
    this.setState({ createWebsite: e.target.value });
  }
  handleMessageChange(e) {
    this.setState({ message: e.target.value });
  }

  handleCreateMessageChange(e) {
    this.setState({ createMessage: e.target.value });
  }

  handleCreateSubHeadingChange(e) {
    this.setState({ createSubHeading: e.target.value });
  }

  handleCreateHeadingChange(e) {
    this.setState({ createHeading: e.target.value });
  }
  backgroundImageFileHandler(position, e) {
    var file = e.target.files[0];
    let reader = new FileReader();
    this.setState({
      imagePosition: position.toString(),
      selectedFile: e.target.files[0],
    });
    reader.onload = (e) => {
      let image = e.target.result;
      let img = image.split(',');
      this.setImgae = img[1];

      const formData = new FormData();

      // Update the formData object
      formData.append('file', this.state.selectedFile, this.state.selectedFile.name);
      formData.append('sessionid', this.header.sessionid);
      formData.append('sid', this.header.sessionid);
      formData.append('imageposition', position);
      formData.append('uid', this.header.uid);
      formData.append('adid', this.props.advertisementId);
      formData.append('appclient', 'W');

      FetchServerData.callPostService('/sponsorAdvertisementms/uploadImage', formData).then((output) => {
        if (output.status === 'SUCCESS') {
          // let imageDetails = output.data;
          // this.setState({
          //   action: "viewTemplate",
          // });
          PtsAlert.success(output.message);
        } else {
        }
      });
      this.setState({ backgroundImageFile: this.setImgae });
    };
    reader.readAsDataURL(file);
  }
  editBackgroundImageFileHandler(position, e) {
    var file = e.target.files[0];
    let reader = new FileReader();
    this.setState({
      imagePosition: position.toString(),
      selectedFile: e.target.files[0],
    });
    reader.onload = (e) => {
      let image = e.target.result;
      let img = image.split(',');
      this.setImgae = img[1];

      const formData = new FormData();

      // Update the formData object
      formData.append('file', this.state.selectedFile, this.state.selectedFile.name);
      formData.append('sessionid', this.header.sessionid);
      formData.append('sid', this.header.sessionid);
      formData.append('imageposition', position);
      formData.append('uid', this.header.uid);
      formData.append('adid', this.props.advertisementId);
      formData.append('appclient', 'W');

      FetchServerData.callPostService('/sponsorAdvertisementms/uploadImage', formData).then((output) => {
        if (output.status === 'SUCCESS') {
          // let imageDetails = output.data;
          this.setState({
            action: 'edit',
          });
          PtsAlert.success(output.message);
        } else {
        }
      });
      this.setState({ backgroundImageFile: this.setImgae });
    };
    reader.readAsDataURL(file);
  }

  removeBackgroundImage(position, e) {
    const postObject = {
      header: this.header,
      advertisement: {
        id: this.state.advertisementId,
      },
      position: position,
    };

    FetchServerData.callPostService('/sponsorAdvertisementms/deleteImage', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        this.setState({ backgroundImageFile: '' });
      } else {
      }
    });
  }
  render() {
    if (this.state.action === 'create') {
      return this.createTemplate();
    } else if (this.state.action === 'edit') {
      return this.editTemplate();
    } else if (this.state.action === 'preView') {
      return this.preViewTemplate();
    } else if (this.state.action === 'view') {
      return this.viewTemplate();
    } else if (this.state.action === 'viewTemplate') {
      return this.viewTemplateWithImage();
    }
  }

  createTemplate() {
    return (
      <section id="Ad-jumbo-02">
        {/*//////////////////////////////////////////*/}
        <div
          className="img-jumbo2 banner_section_1  "
          style={{
            backgroundImage: `url(${
              // global.config.projectName + "/AdTemplate3/imgjumbo04.jpg"
              template1
            })`,
            backgroundRepeat: 'no-repeat',
            width: ' 100%',
            backgroundSize: 'cover',
          }}
        >
          <div className="container mt-0 ">
            <div className="row">
              <div className="col-lg-12 col-md-12 col-ms-4 col-xs-4">
                <div className="container-jumbo">
                  {/* <h1 className="text-white">Heading2222</h1> */}
                  <Row>
                    <Col md={6}>
                      <h1 className="text-white">Heading2222</h1>
                    </Col>
                    <Col md={6} style={{ textAlign: 'right' }}>
                      <a href={this.state.website}>{this.state.website}</a>
                    </Col>
                  </Row>
                  <h3 className="text-white">Subheading22222222</h3>
                  <p className="text-white">
                    saepe placeat eius cum ducimus sunt, libero consequatur nemo voluptas sapiente possimus.
                  </p>
                  {/* <p className="text-white">
                    <a href={this.state.website}>{this.state.website}</a>
                  </p> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
  viewTemplate() {
    return (
      <>
        <section id="Ad-jumbo-02">
          {this.state.backgroundImageFile !== '' ? (
            <>
              <div
                className="img-jumbo2 banner_section_1"
                style={{
                  backgroundImage: `url(${`data:image/png;base64,${this.state.backgroundImageFile}`})`,
                  backgroundRepeat: 'no-repeat',
                  width: ' 100%',
                  backgroundSize: 'cover',
                }}
              >
                <span style={{ float: 'right' }}>
                  <i
                    className="fa fa-times-circle"
                    aria-hidden="true"
                    title="Delete User"
                    style={{ color: '#000' }}
                    onClick={this.removeBackgroundImage.bind(this, 'BG')}
                  ></i>
                </span>
                <div className="imgUploadForm">
                  <div class="upload-btn-wrapper" style={{ float: 'right', padding: '0em 1em' }}>
                    <button className="fileUploadBtn">Upload Background file</button>
                    <input
                      type="file"
                      name="myfile"
                      id="file-input"
                      onChange={this.backgroundImageFileHandler.bind(this, 'BG')}
                    />
                  </div>
                  &nbsp;
                </div>

                <div className="container mt-0 ">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-ms-4 col-xs-4">
                      <div className="container-jumbo">
                        {/* <h1 className="text-black">{this.state.heading}</h1> */}
                        <Row>
                          <Col md={6}>
                            <h1 className="text-white">{this.state.heading}</h1>
                          </Col>
                          <Col md={6} style={{ textAlign: 'right' }}>
                            {/* <a href={this.state.website}>
                              {this.state.website}
                            </a> */}
                            {this.state.website !== null ? (
                              <a
                                href={
                                  this.state.website.includes('https://') || this.state.website.includes('http://')
                                    ? this.state.website
                                    : 'http://' + this.state.website
                                }
                                target="blank"
                                rel="noopener noreferrer"
                              >
                                {this.state.website}
                              </a>
                            ) : (
                              ' '
                            )}
                          </Col>
                        </Row>
                        <h3 className="text-black">{this.state.subHeading}</h3>
                        <p className="text-black">{this.state.message}</p>
                        {/* <p className="text-black">
                          <a href={this.state.website}>{this.state.website}</a>
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="img-jumbo2 banner_section_1  ">
              <Row>
                <Col md={12}>
                  <span style={{ float: 'right' }}>
                    <i
                      className="fa fa-times-circle"
                      aria-hidden="true"
                      title="Delete User"
                      style={{ color: '#000' }}
                      onClick={this.removeBackgroundImage.bind(this)}
                    ></i>
                  </span>
                  <div className="imgUploadForm">
                    <div class="upload-btn-wrapper" style={{ float: 'right', padding: '0em 1em' }}>
                      <button className="fileUploadBtn">Upload Background file</button>
                      <input
                        type="file"
                        name="myfile"
                        id="file-input"
                        onChange={this.backgroundImageFileHandler.bind(this, 'BG')}
                      />
                    </div>
                    &nbsp;
                  </div>
                </Col>
              </Row>
              <div className="container mt-0 ">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-ms-4 col-xs-4">
                    <div className="container-jumbo">
                      <Row>
                        <Col md={6}>
                          <h1 className="text-white">{this.state.heading}</h1>
                        </Col>
                        <Col md={6} style={{ textAlign: 'right' }}>
                          {/* <a href={this.state.website}>{this.state.website}</a> */}
                          {this.state.website !== null ? (
                            <a
                              href={
                                this.state.website.includes('https://') || this.state.website.includes('http://')
                                  ? this.state.website
                                  : 'http://' + this.state.website
                              }
                              target="blank"
                              rel="noopener noreferrer"
                            >
                              {this.state.website}
                            </a>
                          ) : (
                            ' '
                          )}
                        </Col>
                      </Row>
                      <h3 className="text-black">{this.state.subHeading}</h3>
                      <p className="text-black">{this.state.message}</p>
                      {/* <p className="text-black">
                        <a href={this.state.website}>{this.state.website}</a>
                      </p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          <p style={{ marginTop: '10px' }}>Note: Image size should be 760*100 pixels.</p>
        </section>
      </>
    );
  }

  preViewTemplate() {
    return (
      <>
        <section id="Ad-jumbo-02">
          {/*//////////////////////////////////////////*/}
          <div
            className="img-jumbo2 banner_section_1  "
            style={{
              backgroundImage: `url(${
                // global.config.projectName + "/AdTemplate3/imgjumbo04.jpg"
                template1
              })`,
              backgroundRepeat: 'no-repeat',
              width: ' 100%',
              backgroundSize: 'cover',
            }}
          >
            <div className="container mt-0 ">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-ms-4 col-xs-4">
                  <div className="container-jumbo">
                    <div className="textRow">
                      <h1 className="text-white">
                        <TextField
                          margin="normal"
                          variant="outlined"
                          fullWidth
                          id="heading"
                          style={{ background: '#fff' }}
                          type="text"
                          onChange={this.handleCreateHeadingChange.bind(this)}
                          label="Heading 1"
                          name="heading"
                          inputProps={{
                            maxLength: 128,
                            style: { textTransform: 'capitalize' },
                          }}
                          value={this.state.createHeading}
                        />
                      </h1>
                      <h3 className="text-white">
                        <TextField
                          margin="normal"
                          variant="outlined"
                          fullWidth
                          id="heading"
                          style={{ background: '#fff' }}
                          type="text"
                          onChange={this.handleCreateSubHeadingChange.bind(this)}
                          label="Heading 2"
                          name="heading"
                          inputProps={{
                            maxLength: 128,
                            style: { textTransform: 'capitalize' },
                          }}
                          value={this.state.createSubHeading}
                        />
                      </h3>
                      <p className="text-white">
                        <TextareaAutosize
                          aria-label="minimum height"
                          style={{
                            width: '100%',
                            padding: '10px',
                            height: '80px',
                            fontSize: '16px',
                          }}
                          rowsMin={5}
                          id={'createMessage' + this.state.index}
                          value={this.state.createMessage}
                          name="createMessage"
                          inputProps={{
                            maxLength: 256,
                            padding: '10px',
                            whiteSpace: 'pre-line',
                          }}
                          onChange={this.handleCreateMessageChange.bind(this)}
                          onPressEnter={this.handleCreateMessageChange.bind(this)}
                          placeholder="Message"
                          margin="normal"
                        />
                      </p>

                      <TextField
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        id="heading"
                        type="text"
                        onChange={this.handleCreatWebsiteChange.bind(this)}
                        label="Website"
                        name="heading"
                        style={{ background: '#fff' }}
                        inputProps={{
                          maxLength: 128,
                        }}
                        value={this.state.createWebsite}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: '10px' }}>
            <p> Note: Please upload images on next screen.</p>
            <p style={{ marginTop: '-1.2em', marginLeft: '2.7em' }}> Message can contain 256 characters.</p>
          </div>
        </section>
      </>
    );
  }

  viewTemplateWithImage() {
    return (
      <>
        <section id="Ad-jumbo-02">
          {this.state.backgroundImageFile !== '' ? (
            <>
              <div
                className="img-jumbo2 banner_section_1"
                style={{
                  backgroundImage: `url(${`data:image/png;base64,${this.state.backgroundImageFile}`})`,
                  backgroundRepeat: 'no-repeat',
                  width: ' 100%',
                  backgroundSize: 'cover',
                }}
              >
                <div className="container mt-0 ">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-ms-4 col-xs-4">
                      <div className="container-jumbo">
                        <Row>
                          <Col md={6}>
                            <h1 className="text-white">{this.state.heading}</h1>
                          </Col>
                          <Col md={6} style={{ textAlign: 'right' }}>
                            {/* <a href={this.state.website}>
                              {this.state.website}
                            </a> */}
                            {this.state.website !== null ? (
                              <a
                                href={
                                  this.state.website.includes('https://') || this.state.website.includes('http://')
                                    ? this.state.website
                                    : 'http://' + this.state.website
                                }
                                target="blank"
                                rel="noopener noreferrer"
                              >
                                {this.state.website}
                              </a>
                            ) : (
                              ' '
                            )}
                          </Col>
                        </Row>
                        {/* <h1 className="text-white">{this.state.heading}</h1> */}
                        <h3 className="text-white">{this.state.subHeading}</h3>
                        <p className="text-white">{this.state.message}</p>
                        {/* <p className="text-white">
                          <a href={this.state.website}>{this.state.website}</a>
                        </p> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div className="img-jumbo2 banner_section_1  ">
              <div className="container mt-0 ">
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-ms-4 col-xs-4">
                    <div className="container-jumbo">
                      <Row>
                        <Col md={6}>
                          <h1 className="text-white">{this.state.heading}</h1>
                        </Col>
                        <Col md={6} style={{ textAlign: 'right' }}>
                          {this.state.website !== null ? (
                            <a
                              href={
                                this.state.website.includes('https://') || this.state.website.includes('http://')
                                  ? this.state.website
                                  : 'http://' + this.state.website
                              }
                              target="blank"
                              rel="noopener noreferrer"
                            >
                              {this.state.website}
                            </a>
                          ) : (
                            ' '
                          )}
                        </Col>
                      </Row>
                      <h3 className="text-white">{this.state.subHeading}</h3>
                      <p className="text-white">{this.state.message}</p>
                      {/* <button className="btn btn-secondary">Contact Us</button> */}
                      {/* <p className="text-white"></p> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </section>
        {/* <p style={{ float: 'right' }}>Note: Image size should be 760*100 pixels.</p> */}
      </>
    );
  }

  editTemplate() {
    return (
      <>
        <section id="Ad-jumbo-02">
          {/*//////////////////////////////////////////*/}
          <div
            className="img-jumbo2 banner_section_1  "
            style={{
              backgroundImage: `url(${`data:image/png;base64,${this.state.backgroundImageFile}`})`,
              backgroundRepeat: 'no-repeat',
              width: ' 100%',
              backgroundSize: 'cover',
            }}
          >
            <span style={{ float: 'right' }}>
              <i
                className="fa fa-times-circle"
                aria-hidden="true"
                title="Delete User"
                style={{ color: '#fff' }}
                onClick={this.removeBackgroundImage.bind(this, 'BG')}
              ></i>
            </span>
            <div className="imgUploadForm">
              <div class="upload-btn-wrapper" style={{ float: 'right', padding: '0em 1em' }}>
                <button className="fileUploadBtn">Upload Background file</button>
                <input
                  type="file"
                  name="myfile"
                  id="file-input"
                  onChange={this.editBackgroundImageFileHandler.bind(this, 'BG')}
                />
              </div>
              &nbsp;
            </div>
            <div className="container mt-0 ">
              <div className="row">
                <div className="col-lg-12 col-md-12 col-ms-4 col-xs-4">
                  <div className="container-jumbo">
                    <div className="textRow">
                      <h1 className="text-white">
                        <TextField
                          margin="normal"
                          variant="outlined"
                          fullWidth
                          id={'heading' + this.state.index}
                          style={{ background: '#fff' }}
                          type="text"
                          onChange={this.handleHeadingChange.bind(this)}
                          label="Heading 1"
                          name="heading"
                          inputProps={{
                            maxLength: 128,
                            style: { textTransform: 'capitalize' },
                          }}
                          value={this.state.heading}
                        />
                      </h1>
                      <h3 className="text-white">
                        <TextField
                          margin="normal"
                          variant="outlined"
                          fullWidth
                          id={'subHeading' + this.state.index}
                          style={{ background: '#fff' }}
                          type="text"
                          onChange={this.handleSubHeadingChange.bind(this)}
                          label="Heading 2"
                          name="heading"
                          inputProps={{
                            maxLength: 128,
                            style: { textTransform: 'capitalize' },
                          }}
                          value={this.state.subHeading}
                        />
                      </h3>
                      <p className="text-white">
                        {' '}
                        <TextareaAutosize
                          aria-label="minimum height"
                          style={{
                            width: '100%',
                            padding: '10px',
                            height: '80px',
                            fontSize: '16px',
                          }}
                          rowsMin={5}
                          id={'message' + this.state.index}
                          value={this.state.message}
                          name="message"
                          inputProps={{
                            maxLength: 256,
                            padding: '10px',
                            whiteSpace: 'pre-line',
                          }}
                          onChange={this.handleMessageChange.bind(this)}
                          onPressEnter={this.handleMessageChange.bind(this)}
                          placeholder="Message"
                          margin="normal"
                        />
                      </p>
                      {/* <button className="btn btn-secondary">Contact Us</button> */}

                      <TextField
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        id="heading"
                        type="text"
                        onChange={this.handleWebsiteChange.bind(this)}
                        label="Website"
                        name="heading"
                        style={{ background: '#fff' }}
                        inputProps={{
                          maxLength: 128,
                        }}
                        value={this.state.website}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style={{ marginTop: '10px' }}>
            <p> Note: Image size should be 760*100 pixels. </p>
            <p style={{ marginTop: '-1.2em', marginLeft: '2.7em' }}> Message can contain 256 characters.</p>
          </div>
        </section>
      </>
    );
  }
}

export default Template3;
