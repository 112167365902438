import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { Col, DropdownToggle, Row, UncontrolledDropdown, DropdownMenu, DropdownItem } from 'reactstrap';
import Logo from '../assets/images/vibe-logo.svg';
import FetchServerData from '../provider/FetchServerData';
import { Footer, Header, Page, PageAlert, PageContent } from '../pts';
import MemberSidebarNav from '../pts/components/memberSidebar/MemberSidebarNav';
import PtsModal from '../pts/components/modal/PtsModal';
import ContextProviders from '../pts/components/utilities/ContextProviders';
import handleKeyAccessibility, { handleClickAccessibility } from '../pts/helpers/handleTabAccessibility';
import applicationStateAction from '../redux/applicationState/applicationStateAction';
import consolidatedMemberAction from '../redux/consolidatedMemberDetails/consolidatedMemberAction';
import fccPageStateAction from '../redux/fccPageState/fccPageStateAction';
import helpDataAction from '../redux/help/displayHelpAction';
import memberDataAction from '../redux/memberData/memberDataAction';
import selectedMemberDetailsAction from '../redux/selectedMemberDetails/selectedMemberDetailsAction';
import store from '../redux/store';
import routes from '../views';
import nav from '../_nav';
import './MemberDetailLayout.scss';
import memberHomeUrlAction from '../redux/memberHomeUrl/memberHomeUrlAction';
import memberListAction from '../redux/memberList/memberListAction';
import PremiumFeatureAction from '../redux/premiumFeatureDetails/PremiumFeatureAction';
import ActiveMenuAction from '../redux/activeMenu/ActiveMenuAction';

const MOBILE_SIZE = 992;
// const MOBILE_SIZE = 768;

export class MemberDetailLayout extends Component {
  memberDetails = store.getState().memberData.memberData;
  loginUserName = store.getState().userData.userData.firstName + ' ' + store.getState().userData.userData.lastName;
  consolidatedMemberDetails = store.getState().consolidatedMemberDetails.consolidatedMemberDetails;
  header = store.getState().header.header;
  memberData = {};
  consolidatedMemberData = {};
  constructor(props) {
    super(props);
    let pageId = '';
    let memberDetailsId = '';
    let memberDetailsID = '';
    if (props.history && props.history.location && props.history.location.state) {
      pageId = props.history.location.state.pageId;
    }
    if (this.consolidatedMemberDetails) {
      this.consolidatedMemberData = Object.getOwnPropertyNames(this.consolidatedMemberDetails);
    }
    if (this.consolidatedMemberData.length > 0) {
      memberDetailsID = this.consolidatedMemberDetails.memberid;
      this.getMemberMenuList(memberDetailsID);
    } else {
      if (
        pageId !== 'home' ||
        pageId !== 'memberAppointment' ||
        pageId !== 'addAppointment' ||
        pageId !== 'viewMemberAppointment' ||
        pageId !== 'viewEditMemberAppointments' ||
        pageId !== 'editMemberAppointments'
      ) {
        let str = window.location.href;
        let urlArr = str.split('?');
        let appointmentVo = urlArr[1];
        if (appointmentVo) {
          let appointmentDataVo = appointmentVo.split('&');
          memberDetailsId = appointmentDataVo[2].split('=');

          memberDetailsID = memberDetailsId[1];
        }
        if (memberDetailsID) {
          this.editFamilyMemberHandle(memberDetailsID);
        } else {
          memberDetailsID = this.memberDetails.member;
          this.getMemberMenuList(memberDetailsID);
        }
      } else {
        memberDetailsID = this.memberDetails.member;
        this.getMemberMenuList(memberDetailsID);
      }
    }

    if (window.innerWidth > 992) {
      this.state = {
        sidebarCollapsed: true,
        memberDetailsID: memberDetailsID,
        isMobile: window.innerWidth <= MOBILE_SIZE,
        showChat1: true,
        memberMenuListArray: [],
        memberInfo: '',
        render: false,
        user: this.header.email,
        props: this.props,
        helpModal: false,
        faqModal: false,
      };
    } else {
      this.state = {
        sidebarCollapsed: true,
        memberDetailsID: memberDetailsID,
        isMobile: window.innerWidth <= MOBILE_SIZE,
        showChat1: true,
        memberMenuListArray: [],
        memberInfo: '',
        render: false,
        user: this.header.email,
        props: this.props,
        helpModal: false,
        faqModal: false,
      };
    }
  }

  editFamilyMemberHandle = (memberDetailsID) => {
    const postObject = {
      header: this.header,
      member: {
        id: memberDetailsID,
      },
    };

    FetchServerData.callPostService('/memberms/get/', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let familyData = output.data;

          this.memberData = {
            id: familyData.id,
            salutation: familyData.salutation,
            firstName: familyData.firstName,
            lastName: familyData.lastName,
            gender: null,
            email: null,
            phone: null,
            relation: 'Self',
            role: null,
            isContact: false,
            appuserId: null,
            familyMemberMemberId: null,
            status: null,
            requestRaisedBy: null,
            address: familyData.address,
          };

          store.dispatch(memberDataAction.setMember(this.memberData));
          this.getMemberMenuList(memberDetailsID);
        } else {
        }
      })
      .catch((error) => {});
  };

  getMemberMenuList(memberDetailsID) {
    const postObject = {
      header: this.header,
      member: {
        id: memberDetailsID,
      },
    };
    FetchServerData.callPostService('/memberms/getMemberWithMenus', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let memberData = output.data;
          store.dispatch(selectedMemberDetailsAction.setSelectedMember(memberData.member));
          this.setState({
            memberInfo: memberData,
            render: true,
          });
          let menuList = memberData.member.menuList;
          let menuId = 'MyCalendar';
          var chek = menuList.find((c) => c.menuid === menuId);

          //or:
          if (chek) {
            let memberHomeUrl = {
              homeUrl: '/memberDetails',
            };
            store.dispatch(memberHomeUrlAction.setMemberHomeUrl(memberHomeUrl));
          } else {
            for (let i = 0; i < menuList.length; i++) {
              if (menuList[i].menuid !== 'MyCalendar') {
                let memberHomeUrl = {
                  homeUrl: menuList[0].pageid,
                };
                store.dispatch(memberHomeUrlAction.setMemberHomeUrl(memberHomeUrl));
              }
            }
          }
        } else {
        }
      })
      .catch((error) => {});
  }

  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ sidebarCollapsed: false, isMobile: true });
    } else {
      this.setState({ sidebarCollapsed: true, isMobile: true });
    }
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    document.addEventListener('keydown', handleKeyAccessibility);
    document.addEventListener('click', handleClickAccessibility);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  toggleSideCollapse = () => {
    if (this.state.sidebarCollapsed === true) {
      this.setState({
        sidebarCollapsed: false,
      });
    } else {
      this.setState({
        sidebarCollapsed: true,
      });
    }
  };

  closeChat = () => {
    this.setState({ showChat1: false });
  };

  termsAndConditionsHandler = (e) => {
    this.props.history.push('/termsCondition');
  };
  privacyPolicyHandler = (e) => {
    this.props.history.push({
      pathname: '/privacyPolicy',
    });
  };
  profileHandler = (e) => {
    this.props.history.push({
      pathname: '/profileHome',
    });
  };
  referFriendHandler = (e) => {
    this.props.history.push({
      pathname: '/referralBonus',
    });
  };
  aboutUsHandler = (e) => {
    this.props.history.push({
      pathname: '/aboutUs',
    });
  };
  logoutHandler = (e) => {
    let postObject = {
      header: this.header,
    };
    FetchServerData.callPostService('userms/logout', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        this.props.history.push('/login');
        let displayFloat = true;
        store.dispatch(fccPageStateAction.setFccPageState({}));
        store.dispatch(helpDataAction.setHelpData(displayFloat));
        store.dispatch(memberDataAction.setMember({}));
        store.dispatch(consolidatedMemberAction.setConsolidatedMember({}));
        store.dispatch(memberListAction.setMemberList([]));
        store.dispatch(PremiumFeatureAction.setPremiumFeature({}));
        store.dispatch(ActiveMenuAction.setActiveMenu(''));
        let calendarState = {};
        store.dispatch(
          applicationStateAction.setApplicationState({
            calendarState,
          })
        );
      } else {
      }
    });
  };

  navigateToLink(linkToNavigate) {
    this.props.history.push(linkToNavigate);
  }

  helpModelToggle = () => {
    this.setState({
      helpModal: !this.state.helpModal,
    });
  };

  helpModalOpen = () => {
    this.setState({
      helpModal: true,
    });
  };
  faqModalOpen = () => {
    this.props.history.push('/faq');
  };

  faqModalToggle = () => {
    this.setState({
      faqModal: !this.state.faqModal,
    });
  };
  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      const { sidebarCollapsed } = this.state;
      const sidebarCollapsedClass = !sidebarCollapsed ? 'side-menu-collapsed' : '';
      let memberNavBar = (
        <MemberSidebarNav
          nav={nav}
          logo={Logo}
          logoText="FCC"
          isSidebarCollapsed={this.state.sidebarCollapsed}
          toggleSidebar={this.toggleSideCollapse.bind(this)}
          {...this.props}
          data={this.state.memberInfo}
        ></MemberSidebarNav>
      );
      return (
        <>
          <ContextProviders>
            <div className={`app ${sidebarCollapsedClass}`}>
              <PageAlert />
              <div className="app-body">
                <Header
                  toggleSidebar={this.toggleSideCollapse.bind(this)}
                  isSidebarCollapsed={this.state.sidebarCollapsed}
                  routes={routes}
                  history={this.props.history}
                  {...this.props}
                >
                  <React.Fragment>
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle nav>
                        <span style={{ color: '#fff' }}>
                          {this.loginUserName}&nbsp;
                          <i className="fa fa-caret-down" style={{ color: '#fff', fontSize: '16px' }} />
                        </span>
                      </DropdownToggle>
                      <DropdownMenu right>
                        <DropdownItem onClick={(e) => this.profileHandler(e)}>
                          <Row>
                            <Col md={3}>
                              <i className="fa fa-user-circle-o" id="menuIconStyle" style={{ color: '#ee7061' }} />
                            </Col>
                            <Col md={9}>
                              <span id="menuNameStyle">Profile</span>
                            </Col>
                          </Row>
                        </DropdownItem>
                        <hr style={{ margin: '0em' }} />
                        <DropdownItem onClick={(e) => this.termsAndConditionsHandler(e)}>
                          <Row>
                            <Col md={3}>
                              <i class="fa fa-gavel" aria-hidden="true" style={{ color: '#569e34' }} />
                            </Col>
                            <Col md={9}>
                              <span id="menuNameStyle">T&C</span>
                            </Col>
                          </Row>
                        </DropdownItem>
                        <hr style={{ margin: '0em' }} />
                        <DropdownItem onClick={this.privacyPolicyHandler}>
                          <Row>
                            <Col md={3}>
                              <i className="fa fa-user" id="menuIconStyle" style={{ color: '#4f5d7c' }} />
                            </Col>
                            <Col md={9}>
                              <span id="menuNameStyle">Privacy Policy</span>
                            </Col>
                          </Row>
                        </DropdownItem>
                        <hr style={{ margin: '0em' }} />
                        <DropdownItem onClick={this.helpModalOpen}>
                          <Row>
                            <Col md={3}>
                              <i className="fa fa-info-circle" id="menuIconStyle" style={{ color: '#4f5d7c' }} />
                            </Col>
                            <Col md={9}>
                              <span id="menuNameStyle">Help</span>
                            </Col>
                          </Row>
                        </DropdownItem>

                        <hr style={{ margin: '0em' }} />
                        <DropdownItem onClick={this.faqModalOpen}>
                          <Row>
                            <Col md={3}>
                              <i class="fa fa-question-circle" id="menuIconStyle" style={{ color: '#2aa6c2' }} />
                            </Col>
                            <Col md={9}>
                              <span id="menuNameStyle">FAQ</span>
                            </Col>
                          </Row>
                        </DropdownItem>
                        <hr style={{ margin: '0em' }} />
                        <DropdownItem onClick={(e) => this.referFriendHandler(e)}>
                          <Row>
                            <Col md={3}>
                              <i class="fa fa-user-plus" id="menuIconStyle" style={{ color: '#854ed1' }} />
                            </Col>
                            <Col md={9}>
                              <span id="menuNameStyle">Refer A Friend</span>
                            </Col>
                          </Row>
                        </DropdownItem>
                        <hr style={{ margin: '0em' }} />
                        <DropdownItem onClick={this.aboutUsHandler}>
                          <Row>
                            <Col md={3}>
                              <i className="fa fa-info-circle" id="menuIconStyle" style={{ color: '#4f5d7c' }} />
                            </Col>
                            <Col md={9}>
                              <span id="menuNameStyle">About Us</span>
                            </Col>
                          </Row>
                        </DropdownItem>
                        <hr style={{ margin: '0em' }} />
                        <DropdownItem onClick={(e) => this.logoutHandler(e)}>
                          <Row>
                            <Col md={3}>
                              <i className="fa fa-sign-out" id="menuIconStyle" style={{ color: '#dc4f4e' }} />
                            </Col>
                            <Col md={9}>
                              <span id="menuNameStyle">Logout</span>
                            </Col>
                          </Row>
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  </React.Fragment>
                </Header>

                <Page>
                  {memberNavBar}
                  <PageContent>
                    <Switch>
                      {routes.map((page, index) => (
                        <Route path={page.path} component={page.component} key={index} />
                      ))}
                      <Redirect to="/login" />
                    </Switch>
                  </PageContent>
                </Page>
              </div>

              <Footer>
                <div className="memberLayoutFooter">
                  <Row>
                    <Col lg={6}>
                      <span>
                        <ul style={{ listStyle: 'none', display: 'inline-flex', margin: 'auto' }}>
                          <li style={{ textAlign: 'left' }}>
                            <a
                              onClick={() => this.navigateToLink('/termsCondition')}
                              style={{ color: '#fff', cursor: 'pointer' }}
                            >
                              {' '}
                              Terms &amp; Conditions
                            </a>
                          </li>
                          &nbsp;
                          <li style={{ textAlign: 'left', cursor: 'pointer' }}>
                            <a
                              onClick={() => this.navigateToLink('/privacyPolicy')}
                              style={{ color: '#fff', cursor: 'pointer' }}
                            >
                              {' '}
                              | &nbsp; Privacy Policy
                            </a>
                          </li>
                          &nbsp;
                          <li style={{ textAlign: 'left' }}>
                            <a
                              onClick={() => this.navigateToLink('/aboutUs')}
                              style={{ color: '#fff', cursor: 'pointer' }}
                            >
                              {' '}
                              | &nbsp; About Us
                            </a>
                          </li>
                        </ul>
                      </span>
                    </Col>
                    <Col lg={6}>
                      <span>Copyright © 2021. All rights reserved.</span>
                    </Col>
                  </Row>
                </div>
              </Footer>
            </div>
          </ContextProviders>

          <PtsModal open={this.state.helpModal} onClose={this.helpModelToggle} title="Family Care Circle Document">
            {/* <iframe
              src={'https://www.familycarecircle.com/fccdocs/FccUsages.pdf'}
              style={{ width: '100%', height: '600px' }}
              frameborder="0"
              title="indpdf"
            ></iframe> */}
            <embed
              src="https://www.familycarecircle.com/fccdocs/FccUsages.pdf#toolbar=0&navpanes=0&scrollbar=0"
              style={{ width: '100%', height: '600px' }}
            />
          </PtsModal>
          <PtsModal open={this.state.faqModal} onClose={this.faqModalToggle} title="Family Care Circle Document">
            {/* <iframe
              src={'https://www.familycarecircle.com/fccdocs/FccFAQ.pdf'}
              style={{ width: '100%', height: '600px' }}
              frameborder="0"
              title="indpdf"
            ></iframe> */}
            <embed
              src="https://www.familycarecircle.com/fccdocs/FccFAQ.pdf#toolbar=0&navpanes=0&scrollbar=0"
              style={{ width: '100%', height: '600px' }}
            />
          </PtsModal>
        </>
      );
    }
  }
}
export default withRouter(MemberDetailLayout);
