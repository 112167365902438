import { CLEAR_MEMBER_DATA, GET_MEMBER_DATA, SET_MEMBER_DATA } from './memberDataTypes';

const getMember = () => {
  return {
    type: GET_MEMBER_DATA,
  };
};

const setMember = (data) => {
  return {
    type: SET_MEMBER_DATA,
    payload: data,
  };
};

const clearMember = (data) => {
  return {
    type: CLEAR_MEMBER_DATA,
    payload: data,
  };
};
const memberDataAction = { getMember, setMember, clearMember };

export default memberDataAction;
