import { CLEAR_APPOINTMENT_DATA, GET_APPOINTMENT_DATA, SET_APPOINTMENT_DATA } from './appointmentDataTypes';

const initialState = {
  appointmentData: {},
};

const appointmentDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_APPOINTMENT_DATA:
      return {
        ...state,
      };
    case SET_APPOINTMENT_DATA:
      return {
        ...state,
        appointmentData: action.payload,
      };

    case CLEAR_APPOINTMENT_DATA:
      return {
        ...state,
        appointmentData: null,
      };
    default:
      return state;
  }
};

export default appointmentDataReducer;
