import Fab from '@material-ui/core/Fab';
import TableCell from '@material-ui/core/TableCell';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import React, { Component } from 'react';
import Utils from '../../../provider/Utils';
import PtsAlert from '../../components/ptsAlert/PtsAlert';

class SponsorUser extends Component {
  constructor(props) {
    super(props);
    let action = props.action;
    this.state = {
      id: props.data.id,
      code: props.data.code,
      email: props.data.email,
      phone: props.data.phone,
      status: props.data.userStatus ? props.data.userStatus.status : '',
      index: props.key,
      srNo: props.srno,
      onRowEdit: props.onRowEdit,
      onRowUpdate: props.onRowUpdate,
      onCancel: props.onCancel,
      action: action,
      render: true,
      checked: false,
      sponsorUserListArray: [],
    };
  }
  editUserDetails(e) {
    this.setState({ action: 'edit' });
  }

  handleCodeChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ code: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }
  handleEmailChange(e) {
    let val = e.target.value;

    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ email: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }

  handlePhoneChange({ target: { value } }) {
    this.setState((prevState) => ({
      phone: Utils.convertPhoneNumberToUsaPhoneNumberFormate(value, prevState.phone),
    }));
  }
  updateNote(e) {
    if (this.validateLocation() === false) {
      return;
    }
    if (this.state.onRowUpdate) {
      this.setState({ action: 'list' });
      this.state.onRowUpdate(this.state.key);
    }
  }

  backToViewAction() {
    this.setState({ action: 'list', email: '' });
  }

  handleChange(e, rowData) {
    const { checked } = e.target;
    let sponsorUserList = this.state.sponsorUserListArray;
    let memberObj = {};
    if (checked === true) {
      memberObj = {
        user: {
          email: rowData.email,
        },
      };
      sponsorUserList.push(memberObj);

      this.setState({
        sponsorUserListArray: sponsorUserList,
      });
    } else {
      for (let i = 0; i < sponsorUserList.length; i++) {
        if (sponsorUserList[i].email === rowData.email) {
          sponsorUserList.splice(i, 1);
        }
      }
      this.setState({
        sponsorUserListArray: sponsorUserList,
      });
    }
  }
  validateLocation() {
    if (!/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.state.email)) {
      document.getElementById('email' + this.state.index).focus();
      PtsAlert.error('email is not valid');
      return false;
    }
    return true;
  }
  render() {
    if (this.state.render === false) {
      return '';
    }
    if (this.state.action === 'list') {
      return this.sponsorUserList();
    } else if (this.state.action === 'edit') {
      return this.editSponsorUser();
    }
  }

  sponsorUserList() {
    return (
      <>
        {/* {this.state.sponsoredUserList && ( */}
        <>
          <TableCell component="th" scope="row" width="80px" align="left">
            {this.state.srNo + 1}
          </TableCell>{' '}
          <TableCell width="200px" align="left">{this.state.code}</TableCell>
          <TableCell width="350px" align="left">{this.state.email}</TableCell>
          <TableCell width="300px" align="left">{this.state.phone}</TableCell>
          <TableCell width="200px" align="left">{this.state.status}</TableCell>
          <TableCell align="left">
            {' '}
            <EditIcon
              style={{
                borderRadius: '25%',
                background: '#867e7e',
                color: 'white',
                fontSize: 'x-large',
                width: '30px',
                minHeight: '30px',
                padding: '3px',
              }}
              onClick={(e) => this.editUserDetails(e, this.props.data)}
            />
          </TableCell>
        </>

        {/* )} */}
      </>
    );
  }

  editSponsorUser() {
    return (
      <>
        <>
          <TableCell component="th" scope="row" align="left">
            {this.state.srNo + 1}
          </TableCell>{' '}
          <TableCell width="200px" align="left">{this.state.code}</TableCell>
          <TableCell align="left">
            {' '}
            <TextField
              fullWidth
              variant="outlined"
              id={'email' + this.state.index}
              label="Email"
              value={this.state.email}
              onChange={this.handleEmailChange.bind(this)}
              name="name"
              type="text"
              margin="normal"
              className="textField"
              style={{ background: '#fff' }}
            />
          </TableCell>
          <TableCell align="left">
            {' '}
            <TextField
              fullWidth
              variant="outlined"
              id={'phone' + this.state.index}
              label="Phone"
              value={this.state.phone}
              onChange={this.handlePhoneChange.bind(this)}
              name="name"
              type="text"
              margin="normal"
              className="textField"
              style={{ background: '#fff' }}
            />
          </TableCell>
          <TableCell align="left"> {this.state.status}</TableCell>
          <TableCell align="left">
            <Tooltip title="Create">
              <Fab
                aria-label="add"
                style={{
                  fontSize: 'small',
                  height: '15px',
                  width: '35px',
                  background: '#76767d',
                  cursor: 'pointer',
                  color: 'white',
                  backgroundImage: '-webkit-linear-gradient(bottom, #843b07 0%, #f7b571 100%);',
                  border: '2px solid #edf0f1',
                }}
                onClick={this.updateNote.bind(this)}
              >
                {' '}
                <CheckIcon style={{ cursor: 'pointer' }} />
              </Fab>
            </Tooltip>
            &nbsp;&nbsp;
            <Tooltip title="Cancel">
              <Fab
                aria-label="add"
                style={{
                  fontSize: 'small',
                  height: '15px',
                  width: '35px',
                  background: '#76767d',
                  cursor: 'pointer',
                  color: 'white',
                  backgroundImage: '-webkit-linear-gradient(bottom, #843b07 0%, #f7b571 100%);',
                  border: '2px solid #edf0f1',
                }}
                onClick={(e) => this.backToViewAction(e)}
              >
                <CloseIcon style={{ cursor: 'pointer' }} />
              </Fab>
            </Tooltip>
          </TableCell>
        </>

        {/* )} */}
      </>
    );
  }
}

export default SponsorUser;
