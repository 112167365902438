import { CLEAR_APPLICATION_STATE, GET_APPLICATION_STATE, SET_APPLICATION_STATE } from './applicationStateTypes';

const getApplicationState = () => {
  return {
    type: GET_APPLICATION_STATE,
  };
};

const setApplicationState = (data) => {
  return {
    type: SET_APPLICATION_STATE,
    payload: data,
  };
};

const clearApplicationState = (data) => {
  return {
    type: CLEAR_APPLICATION_STATE,
    payload: data,
  };
};
const applicationStateAction = { getApplicationState, setApplicationState, clearApplicationState };
export default applicationStateAction;
