import {
  CLEAR_ADMIN_FCC_PAGE_STATE,
  GET_ADMIN_FCC_PAGE_STATE,
  SET_ADMIN_FCC_PAGE_STATE,
} from './adminFccPageStateType';
const initialState = {
  adminFccPageState: {},
};

const adminFccPageStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMIN_FCC_PAGE_STATE:
      return {
        ...state,
      };
    case SET_ADMIN_FCC_PAGE_STATE:
      return {
        ...state,
        adminFccPageState: action.payload,
      };

    case CLEAR_ADMIN_FCC_PAGE_STATE:
      return {
        ...state,
        adminFccPageState: null,
      };
    default:
      return state;
  }
};

export default adminFccPageStateReducer;
