import { CLEAR_PAID_ADVERTISER, GET_PAID_ADVERTISER, SET_PAID_ADVERTISER } from './paidAdvertiserDetailsType';

const getPaidAdvertiser = () => {
  return {
    type: GET_PAID_ADVERTISER,
  };
};

const setPaidAdvertiser = (data) => {
  return {
    type: SET_PAID_ADVERTISER,
    payload: data,
  };
};

const clearPaidAdvertiser = (data) => {
  return {
    type: CLEAR_PAID_ADVERTISER,
    payload: data,
  };
};
const paidAdvertiserDetailsAction = { getPaidAdvertiser, setPaidAdvertiser, clearPaidAdvertiser };

export default paidAdvertiserDetailsAction;
