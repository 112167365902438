import MaterialTable from '@material-table/core';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import UiUtils from '../../../provider/UiUtils';
import Utils from '../../../provider/Utils';
import applicationStateAction from '../../../redux/applicationState/applicationStateAction';
import fccPageStateAction from '../../../redux/fccPageState/fccPageStateAction';
import helpDataAction from '../../../redux/help/displayHelpAction';
import store from '../../../redux/store';
import Advertisement from '../advertisement/Advertisement';
import AddIconButton from '../buttons/AddIconButton';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import './MemberFacilityList.scss';
import BreadCrumbDetailsAction from '../../../redux/breadCrumbDetails/BreadCrumbDetailsAction';
export class MemberFacilityList extends Component {
  title = 'Facility List';
  header = store.getState().header.header;
  memberDetails = store.getState().memberData.memberData;
  memberName = store.getState().memberData.memberData.firstname + ' ' + store.getState().memberData.memberData.lastname;
  facilityState = store.getState().applicationState.facilityState;
  isFreeUser = store.getState().userData.userData.isFreeUser;
  sponsorUser = store.getState().Sponsorship.Sponsorship;
  memberHomeUrl = store.getState().memberHomeUrl.memberHomeUrl.homeUrl;
  displayHelp = store.getState().help.help;
  breadCrumb = [
    { pageid: this.memberHomeUrl, uiname: this.memberName },
    { pageid: '/memberFacility', uiname: 'Facility List' },
  ];
  constructor(props) {
    super(props);
    let calendarState = {};
    store.dispatch(
      applicationStateAction.setApplicationState({
        calendarState,
      })
    );
    let facilityState = {};
    if (this.facilityState) {
      facilityState = Object.getOwnPropertyNames(this.facilityState);
    }
    /*current URL path for sponsorship template*/
    let currentLocationPath1 = window.location.href;
    let pathArray = currentLocationPath1.split('/');
    let host = pathArray[4];
    let currentLocationPath = '/' + host;
    store.dispatch(fccPageStateAction.setFccPageState({}));
    if (this.breadCrumb) {
      Utils.getActiveMenu(this.breadCrumb);
      store.dispatch(BreadCrumbDetailsAction.setBreadCrumbDetails(this.breadCrumb));
    }
    if (facilityState.length > 0) {
      this.state = {
        facilityList: [],
        pageSize: this.facilityState.pageSize && this.facilityState.pageSize ? this.facilityState.pageSize : 10,
        isPaging: false,
        render: false,
        initialPage:
          this.facilityState.initialPage && this.facilityState.initialPage > 0 ? this.facilityState.initialPage : 0,
        facilityId: this.facilityState.facilityId,
        selectedTab: this.facilityState.selectedTab,
        getAllPermission: false,
        getUpdatePermission: false,
        getDeletePermission: false,
        getReadPermission: false,
        getCreatePermission: false,
        isFreeUser: this.isFreeUser,
        sponsorUser: this.sponsorUser,
        currentLocationPath: currentLocationPath,
        stepsEnabled: false,
        initialStep: 0,
        steps: [
          {
            element: '.newFacility',
            intro: 'Add New Facility by filling in details.',
          },
          {
            element: '.MuiTableRow-hover',
            intro: 'Click any row from the list to view/update details of Facility.',
          },
        ],
        displayFloatButton: this.displayHelp,
        activeFacilityList: [],
        inActiveFacilityList: [],
        inActiveFacilityListPaging: false,
        activeFacilityPaging: false,
      };
      this.getFacilityList();
    } else {
      this.state = {
        facilityList: [],
        pageSize: 10,
        isPaging: false,
        render: false,
        initialPage: 0,
        facilityId: '',
        getAllPermission: false,
        getUpdatePermission: false,
        getDeletePermission: false,
        getReadPermission: false,
        getCreatePermission: false,
        isFreeUser: this.isFreeUser,
        sponsorUser: this.sponsorUser,
        currentLocationPath: currentLocationPath,
        stepsEnabled: false,
        initialStep: 0,
        steps: [
          {
            element: '.newFacility',
            intro: 'Add New Facility by filling in details.',
          },
          {
            element: '.MuiTableRow-hover',
            intro: 'Click any row from the list to view/update details of Facility.',
          },
        ],
        displayFloatButton: this.displayHelp,
        activeFacilityList: [],
        inActiveFacilityList: [],
        inActiveFacilityListPaging: false,
        activeFacilityPaging: false,
        selectedTab: 0,
      };
      this.getFacilityList();
    }
  }

  getFacilityList() {
    const postObject = {
      header: this.header,
      facility: {
        member: {
          id: this.memberDetails.member,
        },
      },
    };

    FetchServerData.callPostService('facilityms/getMemberFacilities', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let facilityListArr = output.data.list;

        let getDomainObjectPermission = output.data.domainObjectPrivileges;

        let getCreatePermission = false;

        for (let domainObjectKey in getDomainObjectPermission) {
          if (domainObjectKey.toLowerCase() === 'facility') {
            let doctorPermission = getDomainObjectPermission[domainObjectKey];
            let getPermission = Utils.getDomainObjectRolePermission(doctorPermission);
            getCreatePermission = getPermission[0];
          }
        }
        let activeFacilityListArr = [];
        let inActiveFacilityListArr = [];
        let isPaging = false;
        let activeFacilityListPaging = false;
        let inActiveFacilityListPaging = false;
        for (let i = 0; i < facilityListArr.length; i++) {
          if (facilityListArr[i].status === 'Active') {
            activeFacilityListArr.push(facilityListArr[i]);
          } else if (facilityListArr[i].status === 'InActive') {
            inActiveFacilityListArr.push(facilityListArr[i]);
          }
        }
        if (facilityListArr.length > 10) {
          isPaging = true;
        }
        if (activeFacilityListArr.length > 10) {
          activeFacilityListPaging = true;
        }
        if (inActiveFacilityListArr.length > 10) {
          inActiveFacilityListPaging = true;
        }
        this.setState({
          isPaging: isPaging,
          facilityList: facilityListArr,
          getCreatePermission: getCreatePermission,
          render: true,
          activeFacilityList: activeFacilityListArr,
          inActiveFacilityList: inActiveFacilityListArr,
          inActiveFacilityListPaging: inActiveFacilityListPaging,
          activeFacilityPaging: activeFacilityListPaging,
        });
      } else {
      }
    });
  }

  addFacility = (e) => {
    let fccPageState = {
      facilityId: '',
      action: 'create',
      title: 'Add Facility',
      pageId: 'memberFacility',
    };
    store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
    this.props.history.push({
      pathname: '/addFacility',
      state: { action: 'create', title: 'Add Facility', pageId: 'memberFacility', facilityId: '' },
    });
  };

  toFacilityDetails = (e, data) => {
    let facilityState = {
      initialPage: this.state.initialPage,
      facilityId: data.id,
      pageSize: this.state.pageSize,
      selectedTab: this.state.selectedTab,
    };
    store.dispatch(
      applicationStateAction.setApplicationState({
        facilityState,
      })
    );
    let fccPageState = {
      id: data.id,
      action: 'view',
      title: 'Facility Details',
      pageId: 'memberFacility',
      locationId: data.locationId,
    };
    store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
    this.props.history.push({
      pathname: '/viewFacility',
      state: {
        action: 'view',
        title: 'Facility Details',
        facilityId: data.id,
        pageId: 'memberFacility',
        locationId: data.locationId,
      },
    });
  };

  onChangePage(page) {
    this.setState({
      initialPage: page,
    });
    let facilityState = {
      initialPage: page,
      facilityId: this.state.facilityId,
    };
    store.dispatch(
      applicationStateAction.setApplicationState({
        facilityState,
      })
    );
  }

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };

  toggleSteps = () => {
    this.setState((prevState) => ({ stepsEnabled: !prevState.stepsEnabled }));
  };

  closeFloatingButton() {
    this.setState({ displayFloatButton: false });
    let displayFloat = false;

    store.dispatch(helpDataAction.setHelpData(displayFloat));
  }
  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }

  setKey(tab) {
    this.setState({
      selectedTab: tab,
    });

    let facilityState = {
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
      selectedTab: tab,
    };
    store.dispatch(
      applicationStateAction.setApplicationState({
        facilityState,
      })
    );
  }
  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <MenuWithBreadScrum breadCrumb={this.breadCrumb} history={this.props.history} name={this.title} />
          <Row style={{ margin: '1em' }}>
            <Advertisement pageId="Facilities" />
          </Row>

          <Steps
            enabled={this.state.stepsEnabled}
            steps={this.state.steps}
            initialStep={this.state.initialStep}
            onExit={this.onExit}
          />
          <div style={{ margin: '1em' }} className="facilityList">
            <Row style={{ backgroundColor: 'none', margin: '-0.5em -1.3em -2.4em' }}>
              <Col md={12}>
                {this.state.getAllPermission === true || this.state.getCreatePermission === true ? (
                  <AddIconButton btnName="Add Facility" onClick={(e) => this.addFacility(e)} />
                ) : (
                  ''
                )}
              </Col>
            </Row>
            <Tabs defaultIndex={this.state.selectedTab} onSelect={(k) => this.setKey(k)}>
              <TabList>
                <Tab>
                  <span className="tabCompleted">Active</span>
                </Tab>
                <Tab>
                  <span className="tabCancelled">InActive</span>
                </Tab>
                <Tab>
                  {' '}
                  <span className="tabUpcoming">All</span>
                </Tab>
              </TabList>

              <TabPanel>
                <MaterialTable
                  onRowClick={(event, rowData) => {
                    this.toFacilityDetails(event, rowData);
                    event.stopPropagation();
                  }}
                  icons={UiUtils.getMaterialTableIcons()}
                  title=""
                  localization={{
                    body: {
                      emptyDataSourceMessage: Utils.communizeTableText(),
                    },
                  }}
                  columns={[
                    {
                      title: '#',
                      render: (rowData) => rowData.tableData.index + 1,
                      width: '5%',
                      align: 'left',
                    },
                    { title: 'Name', field: 'name', align: 'left', width: '20%' },
                    { title: 'Type', field: 'type', align: 'left', width: '20%' },
                    { title: 'Description', field: 'description', align: 'left', width: '20%' },
                    { title: 'Location', field: 'locations', align: 'left', width: '30%' },
                    {
                      title: 'Status',
                      field: 'status',
                      width: '5%',
                      align: 'left',
                      filtering: false,
                    },
                  ]}
                  data={this.state.activeFacilityList}
                  options={{
                    sorting: true,
                    actionsColumnIndex: -1,
                    pageSize: this.state.pageSize,
                    headerStyle: { backgroundColor: '#1ABC9C', fontWeight: 'bold', color: '#fff', align: 'left' },
                    pageSizeOptions: [5, 10, 20, 50, 100],
                    filtering: true,
                    // paging: this.state.activeFacilityPaging,
                    paging: this.state.activeFacilityList.length > this.state.pageSize,
                    initialPage: this.state.initialPage,
                    rowStyle: (rowData) => ({
                      backgroundColor: rowData.id === this.state.facilityId ? '#FEF5E7' : '#fff',
                      height: '3em',
                      textTransform: 'capitalize',
                    }),
                  }}
                  actions={[]}
                  onPageChange={(page, pageSize) => {
                    this.handleMaterialTablePageChange(page, pageSize);
                  }}
                />
              </TabPanel>
              <TabPanel>
                <MaterialTable
                  onRowClick={(event, rowData) => {
                    this.toFacilityDetails(event, rowData);
                    event.stopPropagation();
                  }}
                  icons={UiUtils.getMaterialTableIcons()}
                  title=""
                  localization={{
                    body: {
                      emptyDataSourceMessage: Utils.communizeTableText(),
                    },
                  }}
                  columns={[
                    {
                      title: '#',
                      render: (rowData) => rowData.tableData.index + 1,
                      width: '5%',
                      align: 'left',
                    },
                    { title: 'Name', field: 'name', align: 'left', width: '20%' },
                    { title: 'Type', field: 'type', align: 'left', width: '20%' },
                    { title: 'Description', field: 'description', align: 'left', width: '20%' },
                    { title: 'Location', field: 'locations', align: 'left', width: '30%' },
                    {
                      title: 'Status',
                      field: 'status',
                      width: '5%',
                      align: 'left',
                      filtering: false,
                    },
                  ]}
                  data={this.state.inActiveFacilityList}
                  options={{
                    sorting: true,
                    actionsColumnIndex: -1,
                    pageSize: this.state.pageSize,
                    headerStyle: { backgroundColor: '#1ABC9C', fontWeight: 'bold', color: '#fff', align: 'left' },
                    pageSizeOptions: [5, 10, 20, 50, 100],
                    filtering: true,
                    // paging: this.state.inActiveFacilityListPaging,
                    paging: this.state.inActiveFacilityList.length > this.state.pageSize,
                    initialPage: this.state.initialPage,
                    rowStyle: (rowData) => ({
                      backgroundColor: rowData.id === this.state.facilityId ? '#FEF5E7' : '#fff',
                      height: '3em',
                      textTransform: 'capitalize',
                    }),
                  }}
                  actions={[]}
                  onPageChange={(page, pageSize) => {
                    this.handleMaterialTablePageChange(page, pageSize);
                  }}
                />
              </TabPanel>
              <TabPanel>
                <MaterialTable
                  onRowClick={(event, rowData) => {
                    this.toFacilityDetails(event, rowData);
                    event.stopPropagation();
                  }}
                  icons={UiUtils.getMaterialTableIcons()}
                  title=""
                  localization={{
                    body: {
                      emptyDataSourceMessage: Utils.communizeTableText(),
                    },
                  }}
                  columns={[
                    {
                      title: '#',
                      render: (rowData) => rowData.tableData.index + 1,
                      width: '5%',
                      align: 'left',
                    },
                    { title: 'Name', field: 'name', align: 'left', width: '20%' },
                    { title: 'Type', field: 'type', align: 'left', width: '20%' },
                    { title: 'Description', field: 'description', align: 'left', width: '20%' },
                    { title: 'Location', field: 'locations', align: 'left', width: '30%' },
                    {
                      title: 'Status',
                      field: 'status',
                      width: '5%',
                      align: 'left',
                      filtering: false,
                    },
                  ]}
                  data={this.state.facilityList}
                  options={{
                    sorting: true,
                    actionsColumnIndex: -1,
                    pageSize: this.state.pageSize,
                    headerStyle: { backgroundColor: '#1ABC9C', fontWeight: 'bold', color: '#fff', align: 'left' },
                    pageSizeOptions: [5, 10, 20, 50, 100],
                    filtering: true,
                    // paging: this.state.isPaging,
                    paging: this.state.facilityList.length > this.state.pageSize,
                    initialPage: this.state.initialPage,
                    rowStyle: (rowData) => ({
                      backgroundColor: rowData.id === this.state.facilityId ? '#FEF5E7' : '#fff',
                      height: '3em',
                      textTransform: 'capitalize',
                    }),
                  }}
                  actions={[]}
                  onPageChange={(page, pageSize) => {
                    this.handleMaterialTablePageChange(page, pageSize);
                  }}
                />
              </TabPanel>
            </Tabs>
          </div>
        </>
      );
    }
  }
}

export default MemberFacilityList;
