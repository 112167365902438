import UiUtils from '../../../provider/UiUtils';
import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import MaterialTable from '@material-table/core';
import AddIconButton from '../../components/buttons/AddIconButton';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import AdminMenuWithBreadScrum from '../../adminPanel/adminMenu/AdminMenuWithBreadScrum';
import store from '../../../redux/store';
import adminFccPageStateAction from '../../../redux/adminPanel/adminFccPageState/adminFccPageStateAction';
import applicationStateAction from '../../../redux/applicationState/applicationStateAction';
import BreadCrumbDetailsAction from '../../../redux/breadCrumbDetails/BreadCrumbDetailsAction';

export class PaidAdvertiserList extends Component {
  title = 'Paid Advertiser List';
  breadCrumb = [{ pageid: '/paidAdvertiserList', uiname: 'Paid Advertiser List' }];
  header = store.getState().adminHeader.adminHeader;
  advertiserState = store.getState().applicationState.advertiserState;
  advertiserStateDetails = {};
  constructor(props) {
    super(props);
    let action = props.action;
    let pageId = '';
    if (
      null !== props.history &&
      props.history !== undefined &&
      props.history.location !== null &&
      props.history.location.state !== null &&
      props.history.location.state !== undefined
    ) {
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
    }
    store.dispatch(adminFccPageStateAction.setAdminFccPageState());
    if (this.advertiserState) {
      this.advertiserStateDetails = Object.getOwnPropertyNames(this.advertiserState);
    }
    if (this.breadCrumb) {
      Utils.getAdminPanelActiveMenu(this.breadCrumb);
      store.dispatch(BreadCrumbDetailsAction.setBreadCrumbDetails(this.breadCrumb));
    }
    if (this.advertiserStateDetails.length > 0) {
      this.state = {
        email: '',
        password: '',
        showPassword: false,
        action: action,
        pageId: pageId,
        isPaging: false,
        initialPage:
          this.advertiserState.initialPage && this.advertiserState.initialPage > 0
            ? this.advertiserState.initialPage
            : 0,
        paidAdvertiserList: [],
        pageSize: this.advertiserState.pageSize && this.advertiserState.pageSize ? this.advertiserState.pageSize : 10,
        advertiserId: this.advertiserState.advertiserId,
        render: false,
        getCreatePermission: false,
      };
    } else {
      this.state = {
        email: '',
        password: '',
        showPassword: false,
        action: action,
        pageId: pageId,
        isPaging: false,
        initialPage: 0,
        paidAdvertiserList: [],
        pageSize: 10,
        render: false,
        getCreatePermission: false,
      };
    }
    this.getPaidAdvertiserList();
  }

  getPaidAdvertiserList() {
    const postObject = {
      header: this.header,
    };

    FetchServerData.callPostService('/paidadvertiserms/getAll', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let paidAdvertiserList = output.data.paidAdvertiserList;
        let getPermission = Utils.getRolePermission(output.data.privilegeList);
        let getCreatePermission = getPermission[1];
        let isPaging = false;
        if (paidAdvertiserList.length > 10) {
          isPaging = true;
        }
        this.setState({
          isPaging: isPaging,
          paidAdvertiserList: paidAdvertiserList,
          render: true,
          initialPage: this.state.initialPage,
          pageSize: this.state.pageSize,
          getCreatePermission: getCreatePermission,
        });
      } else {
      }
    });
  }

  addPaidAdvertiser(e) {
    let adminFccPageState = {
      id: '',
      action: 'create',
      title: 'Add Advertiser',
      pageId: 'paidAdvertiserList',
    };
    store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
    this.props.history.push({
      pathname: '/addAdvertiser',
      state: { action: 'create', title: 'Add Paid Advertiser', advertiserId: '', pageId: 'paidAdvertiserList' },
    });
  }
  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }
  toPaidAdvertiserDetails(e, data) {
    let advertiserState = {
      advertiserId: data.id,
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
    };
    store.dispatch(applicationStateAction.setApplicationState({ advertiserState }));
    let adminFccPageState = {
      id: data.id,
      action: 'view',
      title: 'Advertiser Details',
      pageId: 'paidAdvertiserList',
      advertiserName: data.name,
    };
    store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
    this.props.history.push({
      pathname: '/viewAdvertiser',
      state: { action: 'view', title: 'Paid Advertiser Details', advertiserId: data.id, pageId: 'paidAdvertiserList' },
    });
  }
  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          {/* <Header /> */}
          {/* Page content */}
          <AdminMenuWithBreadScrum breadCrumb={this.breadCrumb} history={this.props.history} name={this.title} />

          <Row style={{ position: 'relative', margin: '1em 0em', maxWidth: '100%' }}>
            <Col md={12}>
              <MaterialTable
                onRowClick={(event, rowData) => {
                  this.toPaidAdvertiserDetails(event, rowData);
                  event.stopPropagation();
                }}
                icons={UiUtils.getMaterialTableIcons()}
                title=""
                localization={{
                  body: {
                    emptyDataSourceMessage: Utils.communizeTableText(),
                  },
                }}
                columns={[
                  {
                    title: '#',
                    render: (rowData) => rowData.tableData.index + 1,
                    cellStyle: {
                      width: '6%',
                    },
                    align: 'left',
                  },
                  {
                    title: 'Name',
                    field: 'name',
                    cellStyle: {
                      width: '19%',
                    },
                  },
                  {
                    title: 'Website',
                    field: 'website',
                    cellStyle: { textTransform: 'none', width: '20%' },
                  },

                  {
                    title: 'Phone',
                    render: (rowData) => Utils.displayUsaPhoneNumberFormate(rowData.phone),
                    field: 'phone',
                    filtering: false,
                    cellStyle: {
                      width: '10%',
                    },
                  },

                  { title: 'Location', field: 'address', width: '25%' },
                  {
                    title: 'Status',
                    field: 'status',
                    align: 'left',
                    width: '10%',
                    filtering: false,
                  },
                  {
                    title: 'Active Plan',
                    field: 'activeSponsorshipCount',
                    align: 'center',
                    width: '10%',
                    // cellStyle: {
                    //   width: '15%',
                    // },
                    headerStyle: { textAlign: 'center' },
                    filtering: false,
                  },
                ]}
                data={this.state.paidAdvertiserList}
                options={{
                  sorting: true,
                  actionsColumnIndex: -1,
                  initialPage: this.state.initialPage,
                  pageSizeOptions: [5, 10, 20, 50, 100],
                  paging: this.state.paidAdvertiserList.length > this.state.pageSize,
                  pageSize: this.state.pageSize,
                  headerStyle: {
                    backgroundColor: '#066f72',
                    fontWeight: 'bold',
                    color: '#fff',
                    align: 'left',
                  },

                  filtering: true,
                  // paging: this.state.isPaging,
                  rowStyle: (rowData) => ({
                    backgroundColor: rowData.id === this.state.advertiserId ? '#FEF5E7' : '#fff',
                    height: '3em',
                    textTransform: 'capitalize',
                  }),
                  tableLayout: 'auto',
                }}
                actions={[
                  {
                    icon: () => (
                      <span className="newDoctor">
                        {' '}
                        {this.state.getCreatePermission && <AddIconButton btnName="Add Paid Advertiser" />}
                      </span>
                    ),
                    tooltip: 'Add Paid Advertiser',
                    isFreeAction: true,
                    onClick: (event) => this.addPaidAdvertiser(),
                  },
                ]}
                onPageChange={(page, pageSize) => {
                  this.handleMaterialTablePageChange(page, pageSize);
                }}
              />
            </Col>
          </Row>
        </>
      );
    }
  }
}
export default PaidAdvertiserList;
