import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import MaterialTable from '@material-table/core';
import { Component } from 'react';
import { Row, Col } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import UiUtils from '../../../provider/UiUtils';
import Utils from '../../../provider/Utils';
import applicationStateAction from '../../../redux/applicationState/applicationStateAction';
import fccPageStateAction from '../../../redux/fccPageState/fccPageStateAction';
import helpDataAction from '../../../redux/help/displayHelpAction';
import store from '../../../redux/store';
import Advertisement from '../advertisement/Advertisement';
import AddIconButton from '../buttons/AddIconButton';
import FccAdvertisement from '../fccAdvertisement/FccAdvertisement';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
import SponsorShipTemplate from '../sponsorShipTemplate/SponsorShipTemplate';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import './MemberDoctorList.scss';
import BreadCrumbDetailsAction from '../../../redux/breadCrumbDetails/BreadCrumbDetailsAction';
export class MemberDoctorList extends Component {
  title = 'Doctor List';
  header = store.getState().header.header;
  memberDetails = store.getState().memberData.memberData;
  memberName = store.getState().memberData.memberData.firstname + ' ' + store.getState().memberData.memberData.lastname;
  doctorState = store.getState().applicationState.doctorState;
  isFreeUser = store.getState().userData.userData.isFreeUser;
  sponsorUser = store.getState().Sponsorship.Sponsorship;
  memberHomeUrl = store.getState().memberHomeUrl.memberHomeUrl.homeUrl;
  displayHelp = store.getState().help.help;
  breadCrumb = [
    { pageid: this.memberHomeUrl, uiname: this.memberName },
    { pageid: '/doctorAppointment', uiname: 'Doctor List' },
  ];

  constructor(props) {
    super(props);
    let calendarState = {};
    store.dispatch(
      applicationStateAction.setApplicationState({
        calendarState,
      })
    );
    let doctorState = {};
    if (this.doctorState) {
      doctorState = Object.getOwnPropertyNames(this.doctorState);
    }
    store.dispatch(fccPageStateAction.setFccPageState({}));
    if (this.breadCrumb) {
      Utils.getActiveMenu(this.breadCrumb);
      store.dispatch(BreadCrumbDetailsAction.setBreadCrumbDetails(this.breadCrumb));
    }
    /*current URL path for sponsorship template*/
    let currentLocationPath1 = window.location.href;
    let pathArray = currentLocationPath1.split('/');
    let host = pathArray[4];
    let currentLocationPath = '/' + host;
    if (doctorState.length > 0) {
      this.state = {
        doctorList: [],
        pageSize: this.doctorState.pageSize && this.doctorState.pageSize ? this.doctorState.pageSize : 10,
        isPaging: false,
        initialPage:
          this.doctorState.initialPage && this.doctorState.initialPage > 0 ? this.doctorState.initialPage : 0,
        doctorId: this.doctorState.doctorId,
        selectedTab: this.doctorState.selectedTab,
        render: false,
        getCreatePermission: false,
        isFreeUser: this.isFreeUser,
        sponsorUser: this.sponsorUser,
        currentLocationPath: currentLocationPath,
        stepsEnabled: false,
        initialStep: 0,
        steps: [
          {
            element: '.newDoctor',
            intro: 'Add New Doctor by filling in details.',
          },
          {
            element: '.MuiTableRow-hover',
            intro: 'Click any row from the list to view/update details of Doctor.',
          },
        ],
        displayFloatButton: this.displayHelp,
        allDoctorList: [],
        activeDoctorList: [],
        inActiveDoctorList: [],
        inActiveDoctorListPaging: false,
        activeDoctorListPaging: false,
      };
      this.getDoctorList();
    } else {
      this.state = {
        doctorList: [],
        pageSize: 10,
        isPaging: false,
        initialPage: 0,
        render: false,
        doctorId: '',
        getCreatePermission: false,
        isFreeUser: this.isFreeUser,
        sponsorUser: this.sponsorUser,
        currentLocationPath: currentLocationPath,
        stepsEnabled: false,
        initialStep: 0,
        steps: [
          {
            element: '.newDoctor',
            intro: 'Add New Doctor by filling in details.',
          },
          {
            element: '.MuiTableRow-hover',
            intro: 'Click any row from the list to view/update details of Doctor.',
          },
        ],
        displayFloatButton: this.displayHelp,
        allDoctorList: [],
        activeDoctorList: [],
        inActiveDoctorList: [],
        selectedTab: 0,
        inActiveDoctorListPaging: false,
        activeDoctorListPaging: false,
      };
      this.getDoctorList();
    }
  }
  closeFloatingButton() {
    this.setState({ displayFloatButton: false });
    let displayFloat = false;

    store.dispatch(helpDataAction.setHelpData(displayFloat));
  }
  getDoctorList() {
    const postObject = {
      header: this.header,
      doctor: {
        member: {
          id: this.memberDetails.member,
        },
      },
    };

    FetchServerData.callPostService('doctorms/getMemberDoctors', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let doctorList = output.data.list;
        let allDoctorListArr = [];
        let activeDoctorListArr = [];
        let inActiveDoctorListArr = [];

        let getDomainObjectPermission = output.data.domainObjectPrivileges;
        let getCreatePermission = false;

        for (let domainObjectKey in getDomainObjectPermission) {
          if (domainObjectKey.toLowerCase() === 'doctor') {
            let doctorPermission = getDomainObjectPermission[domainObjectKey];
            let getPermission = Utils.getDomainObjectRolePermission(doctorPermission);
            getCreatePermission = getPermission[0];
          }
        }
        let isPaging = false;
        let activeDoctorListPaging = false;
        let inActiveDoctorListPaging = false;
        for (let i = 0; i < doctorList.length; i++) {
          if (doctorList[i].status === 'Active') {
            activeDoctorListArr.push(doctorList[i]);
          } else if (doctorList[i].status === 'InActive') {
            inActiveDoctorListArr.push(doctorList[i]);
          }
        }
        if (doctorList.length > 10) {
          isPaging = true;
        }
        if (activeDoctorListArr.length > 10) {
          activeDoctorListPaging = true;
        }
        if (inActiveDoctorListArr.length > 10) {
          inActiveDoctorListPaging = true;
        }

        this.setState({
          isPaging: isPaging,
          doctorList: doctorList,
          render: true,
          initialPage: this.state.initialPage,
          getCreatePermission: getCreatePermission,
          activeDoctorList: activeDoctorListArr,
          inActiveDoctorList: inActiveDoctorListArr,
          inActiveDoctorListPaging: inActiveDoctorListPaging,
          activeDoctorListPaging: activeDoctorListPaging,
        });
      } else {
      }
    });
  }

  addDoctor(e) {
    let fccPageState = {
      id: '',
      action: 'create',
      title: 'Add Doctor',
    };
    store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
    this.props.history.push({
      pathname: '/addDoctor',
      state: { action: 'create', title: 'Add Doctor', doctorId: '' },
    });
  }

  toDoctorDetails(e, data) {
    let doctorState = {
      initialPage: this.state.initialPage,
      doctorId: data.id,
      pageSize: this.state.pageSize,
      selectedTab: this.state.selectedTab,
    };
    store.dispatch(
      applicationStateAction.setApplicationState({
        doctorState,
      })
    );
    let fccPageState = {
      id: data.id,
      action: 'view',
      title: 'Doctor Details',
    };
    store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
    this.props.history.push({
      pathname: '/viewDoctor',
      state: { action: 'view', title: 'Doctor Details', doctorId: data.id },
    });
  }

  onChangePage(page) {
    this.setState({
      initialPage: page,
    });
    let doctorState = {
      initialPage: page,
      doctorId: this.state.doctorId,
    };
    store.dispatch(
      applicationStateAction.setApplicationState({
        doctorState,
      })
    );
  }
  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };

  toggleSteps = () => {
    this.setState((prevState) => ({ stepsEnabled: !prevState.stepsEnabled }));
  };
  setKey(tab) {
    this.setState({
      selectedTab: tab,
    });

    let doctorState = {
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
      selectedTab: tab,
    };
    store.dispatch(
      applicationStateAction.setApplicationState({
        doctorState,
      })
    );
  }

  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <MenuWithBreadScrum breadCrumb={this.breadCrumb} history={this.props.history} name={this.title} />
          <Row style={{ margin: '1em' }}>
            <Advertisement pageId="Doctors" />
          </Row>

          <Steps
            enabled={this.state.stepsEnabled}
            steps={this.state.steps}
            initialStep={this.state.initialStep}
            onExit={this.onExit}
          />

          <div style={{ padding: '1em' }} className="doctor_List">
            <Row style={{ backgroundColor: 'none', margin: '-0.5em -1.3em -2.4em' }}>
              <Col md={12}>
                {this.state.getAllPermission === true || this.state.getCreatePermission === true ? (
                  <AddIconButton btnName="Add Doctor" onClick={(e) => this.addDoctor(e)} />
                ) : (
                  ''
                )}
              </Col>
            </Row>
            <Tabs defaultIndex={this.state.selectedTab} onSelect={(k) => this.setKey(k)}>
              <TabList>
                <Tab>
                  {' '}
                  <span className="tabCompleted">Active</span>{' '}
                </Tab>
                <Tab>
                  <span className="tabCancelled">InActive</span>
                </Tab>
                <Tab>
                  {' '}
                  <span className="tabUpcoming">All</span>
                </Tab>
              </TabList>
              <TabPanel>
                <MaterialTable
                  onRowClick={(event, rowData) => {
                    this.toDoctorDetails(event, rowData);
                    event.stopPropagation();
                  }}
                  icons={UiUtils.getMaterialTableIcons()}
                  title=""
                  localization={{
                    body: {
                      emptyDataSourceMessage: Utils.communizeTableText(),
                    },
                  }}
                  columns={[
                    {
                      title: '#',
                      render: (rowData) => rowData.tableData.index + 1,
                      width: '5%',
                      // field: 'id',
                      align: 'left',
                    },
                    {
                      title: 'Name',
                      field: 'doctorName',

                      width: '30%',
                    },
                    {
                      title: ' Specialization',
                      field: 'specialist',
                      width: '30%',
                    },
                    {
                      title: ' Location',
                      field: 'locations',
                      width: '30%',
                    },
                    {
                      title: 'Status',
                      field: 'status',
                      width: '5%',
                      filtering: false,
                    },
                  ]}
                  data={this.state.activeDoctorList}
                  options={{
                    sorting: true,
                    actionsColumnIndex: -1,
                    initialPage: this.state.initialPage,
                    pageSize: this.state.pageSize,
                    headerStyle: {
                      backgroundColor: '#1ABC9C',
                      fontWeight: 'bold',
                      color: '#fff',
                      align: 'left',
                      textAlign: 'left',
                    },
                    pageSizeOptions: [5, 10, 20, 50, 100],
                    filtering: true,
                    // paging: this.state.activeDoctorListPaging,
                    paging: this.state.activeDoctorList.length > this.state.pageSize,
                    rowStyle: (rowData) => ({
                      backgroundColor: rowData.id === this.state.doctorId ? '#FEF5E7' : '#fff',
                      height: '3em',
                      textTransform: 'capitalize',
                    }),
                  }}
                  actions={[]}
                  onPageChange={(page, pageSize) => {
                    this.handleMaterialTablePageChange(page, pageSize);
                  }}
                />
              </TabPanel>
              <TabPanel>
                <MaterialTable
                  onRowClick={(event, rowData) => {
                    this.toDoctorDetails(event, rowData);
                    event.stopPropagation();
                  }}
                  icons={UiUtils.getMaterialTableIcons()}
                  title=""
                  localization={{
                    body: {
                      emptyDataSourceMessage: Utils.communizeTableText(),
                    },
                  }}
                  columns={[
                    {
                      title: '#',
                      render: (rowData) => rowData.tableData.index + 1,
                      width: '5%',
                      // field: 'id',
                      align: 'left',
                    },
                    {
                      title: 'Name',
                      field: 'doctorName',

                      width: '30%',
                    },
                    {
                      title: ' Specialization',
                      field: 'specialist',
                      width: '30%',
                    },
                    {
                      title: ' Location',
                      field: 'locations',
                      width: '30%',
                    },
                    {
                      title: 'Status',
                      field: 'status',
                      width: '5%',
                      filtering: false,
                    },
                  ]}
                  data={this.state.inActiveDoctorList}
                  options={{
                    sorting: true,
                    actionsColumnIndex: -1,
                    initialPage: this.state.initialPage,
                    pageSize: this.state.pageSize,
                    headerStyle: {
                      backgroundColor: '#1ABC9C',
                      fontWeight: 'bold',
                      color: '#fff',
                      align: 'left',
                      textAlign: 'left',
                    },
                    pageSizeOptions: [5, 10, 20, 50, 100],
                    filtering: true,
                    paging: this.state.inActiveDoctorList.length > this.state.pageSize,
                    // paging: this.state.inActiveDoctorListPaging,
                    rowStyle: (rowData) => ({
                      backgroundColor: rowData.id === this.state.doctorId ? '#FEF5E7' : '#fff',
                      height: '3em',
                      textTransform: 'capitalize',
                    }),
                  }}
                  actions={[]}
                  onPageChange={(page, pageSize) => {
                    this.handleMaterialTablePageChange(page, pageSize);
                  }}
                />
              </TabPanel>
              <TabPanel>
                <MaterialTable
                  onRowClick={(event, rowData) => {
                    this.toDoctorDetails(event, rowData);
                    event.stopPropagation();
                  }}
                  icons={UiUtils.getMaterialTableIcons()}
                  title=""
                  localization={{
                    body: {
                      emptyDataSourceMessage: Utils.communizeTableText(),
                    },
                  }}
                  columns={[
                    {
                      title: '#',
                      render: (rowData) => rowData.tableData.index + 1,
                      width: '5%',

                      align: 'left',
                    },
                    {
                      title: 'Name',
                      field: 'doctorName',

                      width: '30%',
                    },
                    {
                      title: ' Specialization',
                      field: 'specialist',
                      width: '30%',
                    },
                    {
                      title: ' Location',
                      field: 'locations',
                      width: '30%',
                    },
                    {
                      title: 'Status',
                      field: 'status',
                      width: '5%',
                      filtering: false,
                    },
                  ]}
                  data={this.state.doctorList}
                  options={{
                    sorting: true,
                    actionsColumnIndex: -1,
                    initialPage: this.state.initialPage,
                    pageSize: this.state.pageSize,
                    headerStyle: {
                      backgroundColor: '#1ABC9C',
                      fontWeight: 'bold',
                      color: '#fff',
                      align: 'left',
                      textAlign: 'left',
                    },
                    pageSizeOptions: [5, 10, 20, 50, 100],
                    filtering: true,
                    // paging: this.state.isPaging,
                    paging: this.state.doctorList.length > this.state.pageSize,
                    rowStyle: (rowData) => ({
                      backgroundColor: rowData.id === this.state.doctorId ? '#FEF5E7' : '#fff',
                      height: '3em',
                      textTransform: 'capitalize',
                    }),
                  }}
                  actions={[]}
                  onPageChange={(page, pageSize) => {
                    this.handleMaterialTablePageChange(page, pageSize);
                  }}
                />
              </TabPanel>
            </Tabs>
          </div>
        </>
      );
    }
  }
}

export default MemberDoctorList;
