import Button from '@material-ui/core/Button';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Card, CardBody, CardHeader, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import store from '../../../redux/store';
import BackButton from '../../components/buttons/BackButton';
import CancelIconButton from '../../components/buttons/CancelIconButton';
import CreateButton from '../../components/buttons/CreateButton';
import EditIconButton from '../../components/buttons/EditIconButton';
import SaveButton from '../../components/buttons/SaveButton';
import PtsAlert from '../../components/ptsAlert/PtsAlert';
import './AdminSponsorship.scss';
import PageAdvertisementMappingList from './PageAdvertisementMappingList';
import SponsorUser from './SponsorUser';
import adminFccPageStateAction from '../../../redux/adminPanel/adminFccPageState/adminFccPageStateAction';
import AdminMenuWithBreadScrum from '../adminMenu/AdminMenuWithBreadScrum';
import { Scrollbars } from 'react-custom-scrollbars';
class AdminSponsorship extends Component {
  title = 'Sponsorship Details';
  addressRef = React.createRef();
  header = store.getState().adminHeader.adminHeader;
  sponsorDetails = store.getState().adminSponsorDetails.adminSponsorDetails;
  adminFccPageState = store.getState().adminFccPageState.adminFccPageState;
  userDetails = store.getState().adminUserDetails.adminUserDetails;
  breadCrumb = [
    { pageid: '/viewSponsor', uiname: 'Sponsor Home' },
    { pageid: '/sponsorshipList', uiname: 'Sponsorship List' },
    { pageid: '', uiname: 'Sponsorship Details' },
  ];
  sponsorBreadCrumb = [
    { pageid: '/sponsorshipList', uiname: 'Sponsorship List' },
    { pageid: '', uiname: 'Sponsorship Details' },
  ];
  typeListArray = [
    {
      id: 1,
      type: 'Private',
    },
    {
      id: 2,
      type: 'Public',
    },
  ];
  addressRef = {};
  constructor(props) {
    super(props);
    let sponsorshipId = props.sponsorshipId;
    let action = props.action;
    let title = '';
    let pageId = '';
    let sponsorshipDetails = {};
    if (
      !sponsorshipId &&
      props.history &&
      sponsorshipDetails &&
      props.history.location &&
      props.history.location.state
    ) {
      sponsorshipId = props.history.location.state.sponsorshipId;
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
      title = props.history.location.state.title;
      sponsorshipDetails = props.history.location.state.sponsorDetails;
    }
    let canRender = false;
    let breadCrumb = [];
    if (this.userDetails.isUserLogin === 'sponsor') {
      breadCrumb = this.sponsorBreadCrumb;
    } else {
      breadCrumb = this.breadCrumb;
    }
    if (null !== sponsorshipId && sponsorshipId > 0) {
      this.getSponsorDetails(sponsorshipId);
    } else {
      canRender = true;
    }

    if (sponsorshipId === undefined) {
      sponsorshipId = this.adminFccPageState.id;
      action = this.adminFccPageState.action;
      title = this.adminFccPageState.title;

      if (action !== 'create') {
        this.getSponsorDetails(sponsorshipId);
      }

      // canRender = true;
    }
    if (pageId === 'createSponsorship') {
      let newStartDate = new Date(sponsorshipDetails.startDate);
      let newEndDate = new Date(sponsorshipDetails.endDate);
      this.state = {
        id: sponsorshipDetails.id,
        action: action,
        pageId: pageId,
        startDate: newStartDate,
        endDate: newEndDate,
        viewStartDate: sponsorshipDetails.startDate,
        viewEndDate: sponsorshipDetails.endDate,
        activeUser: sponsorshipDetails.noOfSubscriptions,
        sponsorName: sponsorshipDetails.name,
        type: sponsorshipDetails.sponsorshipType,
        sponsorshipStatus: sponsorshipDetails.sponsorshipStatus.status,
        codeprefix: sponsorshipDetails.codeprefix,
        sponsoredUserList: [],
        pageAdvertisementList: [],
        showEdit: false,
        showView: true,
        allChecked: false,
        selectedTab: '0',
        openEmailModal: false,
        selectedSponsorUserList: [],
        checkAll: false,
        MasterChecked: false,
        SelectedList: [],
        render: canRender,
        breadCrumb: breadCrumb,
        isStatus: true,
        status: {},
        getUpdatePermission: false,
        getDeletePermission: false,
      };
    } else {
      this.state = {
        id: sponsorshipId,
        action: action,
        pageId: pageId,
        endDate: '',
        startDate: '',
        type: this.typeListArray[0],
        address: {},
        render: canRender,
        title: title,
        sponsorName: '',
        sponsorshipStatus: '',
        codeprefix: '',
        // totalshowcount: '',
        viewStartDate: '',
        viewEndDate: '',
        sponsoredUserList: [],
        showEdit: false,
        showView: true,
        pageAdvertisementList: [],
        pageList: [],
        allChecked: false,
        selectedTab: '0',
        openEmailModal: false,
        selectedSponsorUserList: [],
        checkAll: false,
        MasterChecked: false,
        SelectedList: [],
        advertisementList: [],
        breadCrumb: breadCrumb,
        isStatus: true,
        status: {},
        getUpdatePermission: false,
        getDeletePermission: false,
      };
    }
    this.getAdvertisementMappingList();
  }
  getAdvertisementMappingList() {
    const postObject = {
      header: this.header,
      sponsor: { id: this.sponsorDetails.id },
    };

    FetchServerData.callPostService('/sponsorAdvertisementms/getSponsorerAds', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let advertisementList = output.data;
        if (advertisementList !== []) {
          advertisementList.unshift({
            uid: 'None',
            sponsorer: '',

            templateid: '',
            createdOn: '2022-03-11T12:56:08.000+00:00',
            id: -1,
          });
        }
        let advertisementListArray = advertisementList.reduce((unique, o) => {
          if (!unique.some((obj) => obj.name === o.name && obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []);

        this.setState({
          advertisementList: advertisementListArray,
          // advertisement:advertisementList[0],
          render: true,
        });
      } else {
      }
    });
  }
  getSponsorDetails(sponsorshipId) {
    const postObject = {
      header: this.header,
      sponsorship: {
        id: sponsorshipId,
      },
    };

    FetchServerData.callPostService('/sponsorshipms/get', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let sponsorshipDetails = output.data;
          let newStartDate = new Date(sponsorshipDetails.startDate);
          let newEndDate = new Date(sponsorshipDetails.endDate);
          //   let newStartDate = Utils.convertUTCDateToUserTimeZoneDate(sponsorshipDetails.startDate);
          let getPermission = Utils.getRolePermission(['*']);
          let getUpdatePermission = getPermission[3];
          let getDeletePermission = getPermission[4];
          let userStatus = sponsorshipDetails;
          let sponsorshipStatus = '';
          if (userStatus === null) {
            sponsorshipStatus = false;
          } else {
            if (userStatus.sponsorshipStatus.status === 'Active') {
              sponsorshipStatus = true;
            } else {
              sponsorshipStatus = false;
            }
          }
          this.setState({
            render: true,
            id: sponsorshipDetails.id,
            startDate: newStartDate,
            endDate: newEndDate,
            viewStartDate: sponsorshipDetails.startDate,
            viewEndDate: sponsorshipDetails.endDate,
            activeUser: sponsorshipDetails.noOfSubscriptions,
            sponsorName: sponsorshipDetails.name,
            type: sponsorshipDetails.sponsorshipType,
            sponsorshipStatus: sponsorshipDetails.sponsorshipStatus.status,
            codeprefix: sponsorshipDetails.codeprefix,
            totalshowcount: sponsorshipDetails.totalshowcount,
            // sponsoredUserList: sponsorshipDetails.sponsoredUserList,
            // pageAdvertisementList: sponsorshipDetails.pageAdvertisementMappingList,
            pageList: sponsorshipDetails.pageList,
            isStatus: sponsorshipStatus,
            getDeletePermission: getDeletePermission,
            getUpdatePermission: getUpdatePermission,
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  getSponsorUser() {
    const postObject = {
      header: this.header,
      sponsorshipUser: {
        sponsorship: {
          id: this.state.id,
        },
      },
    };
    FetchServerData.callPostService('/sponsorshipUserms/getSponsorshipAppusers', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let sponsorshipDetails = output.data;
          this.setState({
            render: true,
            sponsoredUserList: sponsorshipDetails,
            pageList: sponsorshipDetails.pageList,
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  getAdvertisementList() {
    const postObject = {
      header: this.header,
      mapping: {
        sponsorship: this.state.id,
      },
    };
    FetchServerData.callPostService('/sponsorAdvertisementMappingms/getSponsorshipAdvetisementList', postObject).then(
      (output) => {
        if (output.status === 'SUCCESS') {
          let sponsorshipDetails = output.data;
          this.setState({
            render: true,
            pageAdvertisementList: sponsorshipDetails,
            pageList: sponsorshipDetails.pageList,
          });
        } else {
        }
      }
    );
  }

  handleStartDateChange(date) {
    this.setState({ startDate: date });
  }
  handleEndDateChange(date) {
    this.setState({ endDate: date });
  }
  handleStatusChange(e) {
    this.setState({ isStatus: !this.state.isStatus });
  }
  handleTypeChange(selectedType) {
    this.state.type = selectedType;
    this.setState({ type: selectedType });
    if (undefined !== this.onChange && null !== this.onChange) {
      this.onChange(this.state);
    }
  }

  handleActiveUserChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ activeUser: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }

  handleSponsorNameChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ sponsorName: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }

  handleCodePrefixChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ codeprefix: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }

  createCancel() {
    this.props.history.push({
      pathname: '/sponsorshipList',
    });
  }
  editCancel() {
    let adminFccPageState = {
      id: this.state.id,
      action: 'view',
      title: 'view Sponsorship',
      pageId: 'Sponsorship',
    };
    store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
    this.props.history.push({
      pathname: '/viewSponsorship',
      state: {
        action: 'view',
        title: 'Sponsorship Details',
        sponsorshipId: this.state.id,
      },
    });
  }

  viewCancel() {
    this.props.history.push({
      pathname: '/sponsorshipList',
    });
  }

  handleEmailChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ email: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }

  editUserDetails(action) {
    if (action === 'editRow') {
      this.setState({
        showEdit: true,
        showView: false,
      });
    } else {
      this.setState({
        showEdit: false,
        showView: true,
      });
    }
  }

  addSponsorshipToBackend(e) {
    e.preventDefault();
    let startDateTime = Utils.adminPanelConvertUserTimeZoneDateToUTCDate(this.state.startDate);
    let endDateTime = Utils.adminPanelConvertUserTimeZoneDateToUTCDate(this.state.endDate);
    const postObject = {
      header: this.header,

      sponsorship: {
        id: null,
        codeprefix: this.state.codeprefix,
        noOfSubscriptions: this.state.activeUser,
        startDate: startDateTime,
        endDate: endDateTime,
        name: this.state.sponsorName,
        sponsor: {
          id: this.sponsorDetails.id,
        },
        sponsorshipType: { type: this.state.type.type },
      },
    };
    FetchServerData.callPostService('/sponsorshipms/create', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let sponsorDetails = output.data;
        PtsAlert.success(output.message);
        let adminFccPageState = {
          id: sponsorDetails.id,
          action: 'view',
        };
        store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));

        this.props.history.push({
          pathname: '/viewSponsorship',
          state: {
            action: 'view',
            title: 'Sponsorship Details',
            sponsorDetails: sponsorDetails,
            pageId: 'createSponsorship',
          },
        });
      } else {
      }
    });
  }

  updateSponsorshipToBackend(e) {
    e.preventDefault();
    let startDateTime = Utils.adminPanelConvertUserTimeZoneDateToUTCDate(this.state.startDate);
    let endDateTime = Utils.adminPanelConvertUserTimeZoneDateToUTCDate(this.state.endDate);
    let sponsorshipStatus;
    if (this.state.isStatus === true) {
      sponsorshipStatus = 'Active';
    } else {
      sponsorshipStatus = 'Inactive';
    }
    const postObject = {
      header: this.header,

      sponsorship: {
        id: this.state.id,
        codeprefix: this.state.codeprefix,
        noOfSubscriptions: this.state.activeUser,
        startDate: startDateTime,
        endDate: endDateTime,
        name: this.state.sponsorName,
        sponsor: {
          id: this.sponsorDetails.id,
        },
        sponsorshipType: { type: this.state.type.type },
        sponsorshipStatus: {
          status: sponsorshipStatus,
        },
      },
    };
    FetchServerData.callPostService('/sponsorshipms/update', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let sponsorshipDetails = output.data;
        PtsAlert.success(output.message);
        let adminFccPageState = {
          id: sponsorshipDetails.id,
          action: 'view',
        };
        store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
        this.props.history.push({
          pathname: '/viewSponsorship',
          state: {
            action: 'view',
            title: 'Sponsorship Details',
            sponsorshipId: sponsorshipDetails.id,
          },
        });
      } else {
      }
    });
  }

  editSponsorToBackend(e) {
    let adminFccPageState = {
      id: this.state.id,
      action: 'edit',
      title: 'edit Sponsorship',
      pageId: 'Sponsorship',
    };
    store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
    this.props.history.push({
      pathname: '/editSponsorship',
      state: {
        action: 'edit',
        title: 'Sponsor Details',
        sponsorshipId: this.state.id,
      },
    });
  }

  deleteSponsorToBackend = () => {
    // let startDateTime = Utils.adminPanelConvertUserTimeZoneDateToUTCDate(this.state.startDate);
    // let endDateTime = Utils.adminPanelConvertUserTimeZoneDateToUTCDate(this.state.endDate);

    const postObject = {
      header: this.header,
      sponsorship: {
        id: this.state.id,
      },
    };

    FetchServerData.callPostService('/sponsorshipms/delete', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          this.props.history.push({
            pathname: '/sponsorshipList',
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  updateSponsorUser(e, key, note) {
    if (this.sponsoredUserRefList[key] && this.sponsoredUserRefList[key].current) {
      // let email = '';
      // if (
      //   !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
      //     this.sponsoredUserRefList[key].current.state.email
      //   )
      // ) {
      //   PtsAlert.error('Email is not valid');
      // }
      const postObject = {
        header: this.header,
        sponsorshipUser: {
          id: this.sponsoredUserRefList[key].current.state.id,
          code: this.sponsoredUserRefList[key].current.state.code,

          email: this.sponsoredUserRefList[key].current.state.email,
          phone: this.sponsoredUserRefList[key].current.state.phone,
        },
      };
      FetchServerData.callPostService('/sponsorshipUserms/update', postObject)
        .then((output) => {
          if (output.status === 'SUCCESS') {
            let sponsorUser = output.data;
            // this.state.incidentNoteList = [];

            for (let i = 0; i < this.state.sponsoredUserList.length; i++) {
              if (this.state.sponsoredUserList[i].id === sponsorUser.id) {
                this.state.sponsoredUserList[i].code = sponsorUser.code;
                this.state.sponsoredUserList[i].email = sponsorUser.email;
                this.state.sponsoredUserList[i].status = sponsorUser.userStatus.status;
                this.sponsoredUserRefList[i].current.state.action = 'list';
                this.sponsoredUserRefList[i].current.state.render = true;
              }
            }

            PtsAlert.success(output.message);
          } else {
          }
        })
        .catch((error) => {});
    }
  }

  updateFccAdvertisementInBackend(e, key, note) {
    if (this.advertisementMappingRefList[key] && this.advertisementMappingRefList[key].current) {
      const postObject = {
        header: this.header,

        mapping: {
          id: this.advertisementMappingRefList[key].current.state.mappingId,
          fccpage: {
            id: this.advertisementMappingRefList[key].current.state.fccPageId,
          },
          advertisementId: this.advertisementMappingRefList[key].current.state.advertisement,
          sponsorship: this.sponsorDetails.id,
        },
      };
      FetchServerData.callPostService('/sponsorAdvertisementMappingms/update', postObject)
        .then((output) => {
          if (output.status === 'SUCCESS') {
            let Advertisement = output.data;
            // this.state.incidentNoteList = [];
            this.state.pageAdvertisementList.push(Advertisement);
            this.getAdvertisementList();
            for (let i = 0; i < this.state.pageAdvertisementList.length; i++) {
              if (this.state.pageAdvertisementList[i].id === Advertisement.id) {
                this.advertisementMappingRefList[i].current.state.fccPage = Advertisement.fccpage.name;
                this.advertisementMappingRefList[i].current.state.advertisement = Advertisement.advertisementId;
                this.advertisementMappingRefList[i].current.state.viewAdvertisement = Advertisement.uid;
                this.advertisementMappingRefList[i].current.state.action = 'list';
                this.advertisementMappingRefList[i].current.state.render = true;
              }
            }

            PtsAlert.success(output.message);
          } else {
          }
        })
        .catch((error) => {});
    }
  }

  toSendRequest = () => {
    this.setState({
      openEmailModal: true,
    });
  };
  setKey(tab) {
    this.setState({
      selectedTab: tab,
    });
  }

  toggle = () => {
    {
      this.state.sponsoredUserList.map((user, index) => (user.selected = false));
    }
    this.setState({
      openEmailModal: !this.state.openEmailModal,
      MasterChecked: false,
    });
  };
  render() {
    this.sponsoredUserRefList = [];
    for (let i = 0; this.state.sponsoredUserList && i < this.state.sponsoredUserList.length; i++) {
      this.sponsoredUserRefList[i] = React.createRef();
    }
    this.advertisementMappingRefList = [];
    for (let i = 0; this.state.pageAdvertisementList && i < this.state.pageAdvertisementList.length; i++) {
      this.advertisementMappingRefList[i] = React.createRef();
    }
    if (this.state.action === 'create') {
      return this.createSponsor();
    }
    if (this.state.action === 'edit') {
      return this.editSponsor();
    } else if (this.state.action === 'view') {
      return this.viewSponsor();
    }
  }
  selectedParticipant(e, rowData, type) {
    const { checked } = e.target;
    let selectedUserList = this.state.selectedSponsorUserList;
    let memberObj = {};
    if (checked === true) {
      memberObj = {
        email: rowData.email,
        id: rowData.id,
      };
      selectedUserList.push(memberObj);

      this.setState({
        selectedSponsorUserList: selectedUserList,
      });
    } else {
      for (let i = 0; i < selectedUserList.length; i++) {
        if (selectedUserList[i].id === rowData.id) {
          selectedUserList.splice(i, 1);
        }
      }
      this.setState({
        selectedSponsorUserList: selectedUserList,
      });
    }
  }

  selectedAllUser(e, rowData, type) {
    const { checked } = e.target;
    let selectedUserList = this.state.selectedSponsorUserList;
    let memberObj = {};
    if (checked === true) {
      for (let i = 0; i < selectedUserList.length; i++) {
        memberObj = {
          email: rowData.email,
          id: rowData.id,
        };
        selectedUserList.push(memberObj);
      }
      this.setState({
        selectedSponsorUserList: selectedUserList,
      });
    } else {
      for (let i = 0; i < selectedUserList.length; i++) {
        if (selectedUserList[i].id === rowData.id) {
          selectedUserList.splice(i, 1);
        }
      }
      this.setState({
        selectedSponsorUserList: selectedUserList,
      });
    }
  }

  // Select/ UnSelect Table rows
  onMasterCheck(e) {
    let tempList = this.state.sponsoredUserList;
    // Check/ UnCheck All Items
    tempList.map((user) => {
      if (user.email !== null) {
        user.selected = e.target.checked;
      }
      return user;
    });

    //Update State
    this.setState({
      MasterChecked: e.target.checked,
      sponsoredUserList: tempList,
      SelectedList: this.state.sponsoredUserList.filter((e) => e.selected),
    });
  }

  // Update List Item's state and Master Checkbox State
  onItemCheck(e, item) {
    let tempList = this.state.sponsoredUserList;
    tempList.map((user) => {
      if (user.id === item.id && user.email !== null) {
        user.selected = e.target.checked;
      }
      return user;
    });

    //To Control Master Checkbox State
    const totalItems = this.state.sponsoredUserList.length;
    const totalCheckedItems = tempList.filter((e) => e.selected).length;
    // Update State
    this.setState({
      MasterChecked: totalItems === totalCheckedItems,
      sponsoredUserList: tempList,
      SelectedList: this.state.sponsoredUserList.filter((e) => e.selected),
    });
  }

  // Event to get selected rows(Optional)

  sendEmailToBackend = (e) => {
    let userEmailList = [];
    for (let i = 0; i < this.state.SelectedList.length; i++) {
      if (this.state.SelectedList[i].email !== null) {
        userEmailList.push(this.state.SelectedList[i].email);
      }
    }

    const postObject = {
      header: this.header,
      sponsorship: {
        id: this.state.id,
      },
      emailidList: userEmailList,
    };
    FetchServerData.callPostService('/sponsorshipms/sendEmailToUsers', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          PtsAlert.success(output.message);
          {
            this.state.sponsoredUserList.map((user, index) => (user.selected = false));
          }
          this.setState({
            openEmailModal: false,
            MasterChecked: false,
          });
        } else {
        }
      })

      .catch((error) => {});
  };

  createSponsor() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <AdminMenuWithBreadScrum
            backCallback={this.viewCancel.bind(this)}
            breadCrumb={this.state.breadCrumb}
            history={this.props.history}
            name={'Create Sponsorship'}
            domainObject="Sponsorship"
          />

          <ValidatorForm style={{ width: '100%' }} ref="form" onSubmit={this.addSponsorshipToBackend.bind(this)}>
            <Row style={{ position: 'relative', margin: '1em' }}>
              <Col md={12} style={{ position: 'relative', padding: '5px' }}>
                <Card className="formCard">
                  <CardHeader className="cardHeader">
                    <Row>
                      <Col md={9}>
                        {/* {undefined !== this.state.title &&
                          this.state.title.length > 0 && ( */}
                        <Label className="cardHeaderTitle">SponsorShip Info</Label>
                        {/* )} */}
                      </Col>
                    </Row>
                  </CardHeader>{' '}
                  <CardBody>
                    <Row>
                      <Col md={6}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          label="Name"
                          value={this.state.sponsorName}
                          onChange={this.handleSponsorNameChange.bind(this)}
                          name="sponsorName"
                          inputProps={{ maxLength: 64 }}
                          type="text"
                          margin="normal"
                          className="textField"
                        />
                      </Col>
                      <Col md={6}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          label="Number Of User"
                          value={this.state.activeUser}
                          onChange={this.handleActiveUserChange.bind(this)}
                          name="activeUser"
                          inputProps={{ maxLength: 64 }}
                          type="number"
                          margin="normal"
                          className="textField"
                        />{' '}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <DatePicker
                          selected={this.state.startDate}
                          onChange={this.handleStartDateChange.bind(this)}
                          dateFormat="MM/dd/yyyy"
                          placeholderText="Start Date*"
                          required
                          className="dateTimeInput"
                          minDate={new Date()}
                        />
                      </Col>

                      <Col md={6}>
                        <DatePicker
                          selected={this.state.endDate}
                          onChange={this.handleEndDateChange.bind(this)}
                          dateFormat="MM/dd/yyyy"
                          placeholderText="End Date*"
                          required
                          className="dateTimeInput"
                          minDate={new Date()}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <Autocomplete
                          id="type"
                          options={this.typeListArray}
                          value={this.state.type}
                          getOptionLabel={(option) => option.type}
                          //   defaultValue={this.state.state}
                          onChange={(event, value) => {
                            this.handleTypeChange(value);
                          }}
                          inputProps={{
                            style: { textTransform: 'capitalize' },
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Type" variant="outlined" required margin="normal" />
                          )}
                        />
                      </Col>
                      <Col md={6}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          label="Code prefix"
                          value={this.state.codeprefix}
                          onChange={this.handleCodePrefixChange.bind(this)}
                          name="codeprefix"
                          inputProps={{ maxLength: 64 }}
                          type="text"
                          margin="normal"
                          className="textField"
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* </CardBody> */}

            <div style={{ textAlign: 'center', padding: '0em 0em 1em' }}>
              <CreateButton id="loginButton" type="submit">
                Create
              </CreateButton>
              &nbsp;
              <CancelIconButton onClick={(e) => this.createCancel()} />
            </div>
          </ValidatorForm>
        </>
      );
    }
  }

  viewSponsor() {
    if (this.state.render === false) {
      return <div />;
    } else {
      let sponsoredUserList = [];
      this.state.sponsoredUserList.forEach((user, index) => {
        if ('delete' !== user.operation) {
          sponsoredUserList.push(
            <TableRow>
              <SponsorUser
                ref={this.sponsoredUserRefList[index]}
                key={index}
                data={user}
                srno={index}
                action={user.action ? user.action : 'list'}
                allChecked={this.state.allChecked}
                onRowUpdate={this.updateSponsorUser.bind(this, user, index)}
              />
            </TableRow>
          );
        }
      });
      return (
        <>
          {' '}
          <AdminMenuWithBreadScrum
            backCallback={this.viewCancel.bind(this)}
            deleteCallback={this.deleteSponsorToBackend.bind(this)}
            getDeletePermission={this.state.getDeletePermission}
            getUpdatePermission={this.state.getUpdatePermission}
            breadCrumb={this.state.breadCrumb}
            history={this.props.history}
            name={this.title}
            domainObject="Sponsorship"
          />
          <Row style={{ marginTop: '1em' }}>
            <Col md={12} style={{ padding: '0em 2.4em 1em 2.4em' }} className="appointmentTab">
              <Tabs defaultIndex={this.state.selectedTab} onSelect={(k) => this.setKey(k)}>
                <TabList style={{ paddingLeft: '0.5em', borderBottom: 'none' }}>
                  <Tab>
                    {' '}
                    <span className="tabUser">Sponsorship Details</span>
                  </Tab>
                  <Tab onClick={() => this.getSponsorUser()}>
                    <span className="tabBilling">Sponsored Users</span>
                  </Tab>
                  <Tab onClick={() => this.getAdvertisementList()}>
                    <span className="tabSponsorship">Advertisement List</span>
                  </Tab>
                </TabList>
                <TabPanel>
                  <Card style={{ height: '100%', margin: '0.3em 0.3em' }}>
                    <>
                      <CardHeader
                        className="cardHeader"
                        style={{
                          background: '#1ABC9C',
                          color: '#fff',
                          borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
                          padding: '8px',
                        }}
                      >
                        <Row>
                          <Col md={10}>
                            <Label>
                              <b style={{ color: '#fff' }}> Sponsorship Details</b>
                            </Label>
                          </Col>
                          <Col md={2}>
                            {this.state.sponsorshipStatus !== 'Expired' ? (
                              <EditIconButton onClick={this.editSponsorToBackend.bind(this)} />
                            ) : (
                              ''
                            )}
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Row>
                          <Col md={6}>
                            <Row>
                              <Col md={4}>
                                <label style={{ paddingRight: '0px' }}>Sponsorship Name:</label>
                              </Col>
                              <Col
                                md={8}
                                style={{
                                  textAlign: 'left',
                                  textTransform: 'capitalize',
                                }}
                              >
                                {' '}
                                <span style={{ color: '#000', marginLeft: '-3.5em' }}>{this.state.sponsorName}</span>
                              </Col>
                            </Row>

                            <Row>
                              <Col md={4}>
                                <label style={{ paddingRight: '0px' }}>Start Date:</label>
                              </Col>
                              <Col
                                md={8}
                                style={{
                                  textAlign: 'left',
                                  textTransform: 'capitalize',
                                }}
                              >
                                {' '}
                                <span style={{ color: '#000', marginLeft: '-3.5em' }}>
                                  {moment(this.state.viewStartDate).format('DD MMM YYYY')}
                                </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <label style={{ paddingRight: '0px' }}>Type:</label>
                              </Col>
                              <Col
                                md={8}
                                style={{
                                  textAlign: 'left',
                                  textTransform: 'capitalize',
                                }}
                              >
                                {' '}
                                <span style={{ color: '#000', marginLeft: '-3.5em' }}>{this.state.type.type}</span>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <label style={{ paddingRight: '0px' }}> Status:</label>
                              </Col>
                              <Col md={8} style={{ textAlign: 'left' }}>
                                {' '}
                                <span style={{ color: '#000', marginLeft: '-3.5em' }}>
                                  {this.state.sponsorshipStatus}
                                </span>
                              </Col>
                            </Row>
                          </Col>
                          <Col md={6}>
                            <Row>
                              <Col md={4}>
                                <label style={{ paddingRight: '0px' }}>Number of Users:</label>
                              </Col>
                              <Col
                                md={8}
                                style={{
                                  textAlign: 'left',
                                  textTransform: 'capitalize',
                                }}
                              >
                                {' '}
                                <span style={{ color: '#000', marginLeft: '-4em' }}>{this.state.activeUser}</span>
                              </Col>
                            </Row>

                            <Row>
                              <Col md={4}>
                                <label style={{ paddingRight: '0px' }}>End Date:</label>
                              </Col>
                              <Col md={8} style={{ textAlign: 'left' }}>
                                {' '}
                                <span style={{ color: '#000', marginLeft: '-4em' }}>
                                  {moment(this.state.viewEndDate).format('DD MMM YYYY')}
                                </span>
                              </Col>
                            </Row>

                            <Row>
                              <Col md={4}>
                                <label style={{ paddingRight: '0px' }}>
                                  {this.state.type.type === 'Public' ? 'Public Prefix:' : 'Prefix:'}
                                </label>
                              </Col>
                              <Col
                                md={8}
                                style={{
                                  textAlign: 'left',
                                  textTransform: 'capitalize',
                                }}
                              >
                                {' '}
                                <span style={{ color: '#000', marginLeft: '-4em' }}>{this.state.codeprefix}</span>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <label style={{ paddingRight: '0px' }}>Ads shown:</label>
                              </Col>
                              <Col
                                md={8}
                                style={{
                                  textAlign: 'left',
                                  textTransform: 'capitalize',
                                }}
                              >
                                {' '}
                                <span style={{ color: '#000', marginLeft: '-4em' }}>{this.state.totalshowcount}</span>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </CardBody>
                    </>
                  </Card>
                </TabPanel>
                <TabPanel>
                  <Row style={{ position: 'relative' }} className="sponsorDetailColumn">
                    <Col md={12}>
                      <Card style={{ height: '100%' }}>
                        <CardHeader
                          style={{
                            background: '#1ABC9C',
                            color: '#fff',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
                            padding: '8px',
                          }}
                        >
                          <Row>
                            <Col md={10}>
                              <b> Sponsored Users</b>
                            </Col>
                            <Col md={2}>
                              {' '}
                              <Button
                                type="submit"
                                variant="contained"
                                size="small"
                                style={{
                                  background: '#ef6603',
                                  color: '#fff',
                                  marginRight: '1rem',
                                  textTransform: 'capitalize',
                                  float: 'right',
                                }}
                                onClick={(e) => this.toSendRequest(e)}
                              >
                                Send Email
                              </Button>
                            </Col>
                          </Row>
                        </CardHeader>

                        <CardBody style={{ padding: '0' }}>
                          <Table aria-label="simple table" className="orderHistory">
                            <TableHead style={{ fontFamily: 'bold' }}>
                              <TableRow>
                                <TableCell align="left">
                                  <b>#</b>
                                </TableCell>
                                <TableCell align="left">
                                  <b>Code</b>
                                </TableCell>
                                <TableCell align="left">
                                  <b>Email</b>
                                </TableCell>
                                <TableCell align="left">
                                  <b>Phone</b>
                                </TableCell>
                                <TableCell align="left">
                                  <b>Status</b>
                                </TableCell>
                                <TableCell align="left">
                                  <b>Action</b>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>{sponsoredUserList} </TableBody>
                          </Table>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPanel>{' '}
                <TabPanel>
                  <Row style={{ position: 'relative' }} className="sponsorDetailColumn">
                    <Col md={12}>
                      <Card style={{ height: '100%' }}>
                        <CardHeader
                          style={{
                            background: '#1ABC9C',
                            color: '#fff',
                            borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
                            padding: '8px',
                          }}
                        >
                          <b> Advertisement List</b>
                        </CardHeader>

                        <CardBody style={{ padding: '0' }}>
                          <Table aria-label="simple table" className="orderHistory">
                            <TableHead style={{ fontFamily: 'bold' }}>
                              <TableRow>
                                <TableCell align="left">
                                  <b>#</b>
                                </TableCell>
                                <TableCell align="left">
                                  <b>Page</b>
                                </TableCell>
                                <TableCell align="left">
                                  <b>Advertisement</b>
                                </TableCell>
                                <TableCell align="left">
                                  <b>Action</b>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              {this.state.pageAdvertisementList.map((user, index) => {
                                return (
                                  <PageAdvertisementMappingList
                                    ref={this.advertisementMappingRefList[index]}
                                    key={index}
                                    data={user}
                                    srno={index}
                                    action={user.action ? user.action : 'list'}
                                    advertisementList={this.state.advertisementList}
                                    pageList={this.state.pageList}
                                    onRowUpdate={this.updateFccAdvertisementInBackend.bind(this, user, index)}
                                  />
                                );
                              })}
                            </TableBody>
                          </Table>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </TabPanel>
              </Tabs>
            </Col>
          </Row>
          <div className="sponsorDetailFooter">
            <BackButton
              type="button"
              style={{ backgroundColor: '#162c50', color: '#fff' }}
              variant="contained"
              onClick={this.viewCancel.bind(this)}
            >
              Back
            </BackButton>
          </div>
          <Modal isOpen={this.state.openEmailModal} toggle={this.toggle} size="lg">
            <ModalHeader toggle={this.toggle}>
              <h3>
                <b>Sponsored User List</b>
              </h3>
            </ModalHeader>
            <ModalBody>
              <Row>
                <Col md={12}>
                  <Scrollbars style={{ height: '450px' }}>
                    <Table aria-label="simple table">
                      <TableHead className="sponsoredUserList">
                        <TableRow>
                          <TableCell>#</TableCell>
                          <TableCell>Code</TableCell>
                          <TableCell>Email</TableCell>
                          <TableCell>
                            <input
                              type="checkbox"
                              className="form-check-input"
                              checked={this.state.MasterChecked}
                              id="mastercheck"
                              onChange={(e) => this.onMasterCheck(e)}
                              style={{ marginTop: '-0.3rem' }}
                            />
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {this.state.sponsoredUserList.map((user, index) => (
                          <TableRow key={user.id} className={user.selected ? 'selected' : ''}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>{user.code}</TableCell>
                            <TableCell>{user.email}</TableCell>
                            <TableCell scope="row">
                              <input
                                type="checkbox"
                                checked={user.selected}
                                className="form-check-input"
                                id="rowcheck{user.id}"
                                onChange={(e) => this.onItemCheck(e, user)}
                                style={{ marginTop: '-0.3rem' }}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </Scrollbars>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Col style={{ textAlign: 'center' }}>
                <Button
                  variant="contained"
                  size="small"
                  style={{ background: '#ef6603', color: '#fff' }}
                  onClick={(e) => this.sendEmailToBackend()}
                  type="submit"
                >
                  Send Email
                </Button>
                &nbsp; &nbsp;
                <CancelIconButton onClick={(e) => this.toggle()} />
              </Col>
            </ModalFooter>
          </Modal>
        </>
      );
    }
  }

  editSponsor() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <AdminMenuWithBreadScrum
            backCallback={this.editCancel.bind(this)}
            breadCrumb={this.state.breadCrumb}
            history={this.props.history}
            name={this.title}
            domainObject="Sponsorship"
          />
          <ValidatorForm style={{ width: '100%' }} ref="form" onSubmit={this.updateSponsorshipToBackend.bind(this)}>
            <Row style={{ position: 'relative', margin: '1em' }}>
              <Col md={12} style={{ position: 'relative', padding: '5px' }}>
                <Card className="formCard">
                  <CardHeader className="cardHeader">
                    <Row>
                      <Col md={9}>
                        <Label className="cardHeaderTitle">Sponsorship Info</Label>
                      </Col>
                    </Row>
                  </CardHeader>{' '}
                  <CardBody>
                    <Row>
                      <Col md={6}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          label="Name"
                          value={this.state.sponsorName}
                          onChange={this.handleSponsorNameChange.bind(this)}
                          name="sponsorName"
                          inputProps={{ maxLength: 64 }}
                          type="text"
                          margin="normal"
                          className="textField"
                        />
                      </Col>
                      <Col md={6}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          label="Number Of User"
                          value={this.state.activeUser}
                          onChange={this.handleActiveUserChange.bind(this)}
                          name="activeUser"
                          inputProps={{ maxLength: 64 }}
                          type="number"
                          margin="normal"
                          className="textField"
                        />{' '}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        <DatePicker
                          selected={this.state.startDate}
                          onChange={this.handleStartDateChange.bind(this)}
                          dateFormat="MM/dd/yyyy"
                          placeholderText="Start Date*"
                          required
                          className="dateTimeInput"
                          minDate={new Date()}
                        />
                      </Col>

                      <Col md={6}>
                        <DatePicker
                          selected={this.state.endDate}
                          onChange={this.handleEndDateChange.bind(this)}
                          dateFormat="MM/dd/yyyy"
                          placeholderText="End Date*"
                          required
                          className="dateTimeInput"
                          minDate={new Date()}
                        />
                      </Col>
                    </Row>

                    <Row>
                      <Col md={6}>
                        <Autocomplete
                          id="type"
                          disabled
                          options={this.typeListArray}
                          value={this.state.type}
                          getOptionLabel={(option) => option.type}
                          //   defaultValue={this.state.state}
                          onChange={(event, value) => {
                            this.handleTypeChange(value);
                          }}
                          inputProps={{
                            style: { textTransform: 'capitalize' },
                          }}
                          renderInput={(params) => (
                            <TextField {...params} label="Type" variant="outlined" required margin="normal" />
                          )}
                        />
                      </Col>
                      <Col md={6}>
                        <TextField
                          required
                          fullWidth
                          variant="outlined"
                          label="Code prefix"
                          value={this.state.codeprefix}
                          onChange={this.handleCodePrefixChange.bind(this)}
                          name="codeprefix"
                          inputProps={{ maxLength: 64 }}
                          type="text"
                          margin="normal"
                          className="textField"
                        />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '6px' }}>
                      <Col md={12}>
                        <label style={{ paddingRight: '0px' }}> Status:</label>{' '}
                        <span style={{ color: '#000', textAlign: 'left', paddingLeft: '5px' }}>
                          {this.state.sponsorshipStatus}
                        </span>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* </CardBody> */}

            <div style={{ textAlign: 'center', padding: '0em 0em 1em' }}>
              <SaveButton id="loginButton" type="submit">
                Save
              </SaveButton>
              &nbsp;
              <CancelIconButton onClick={(e) => this.editCancel()} />
            </div>
          </ValidatorForm>
        </>
      );
    }
  }
}

export default AdminSponsorship;
