import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { Component } from 'react';
import { Col, Label, Row } from 'reactstrap';
import Utils from '../../../provider/Utils';
import store from '../../../redux/store';
import './ShortLocation.scss';
import PtsAlert from '../ptsAlert/PtsAlert';
import FetchServerData from '../../../provider/FetchServerData';
import Tooltip from '@material-ui/core/Tooltip';
import PinDropIcon from '@material-ui/icons/PinDrop';
import LocationPinIcon from '../buttons/LocationPinIcon';
export class ShortLocation extends Component {
  header = store.getState().header.header;
  countryListArray = store.getState().staticData.countryList;
  userData = store.getState().userData.userData;
  memberDetails = store.getState().selectedMemberData.selectedMemberData;
  countryOptionList = [];
  stateOptionList = [];
  stateListArray = [];
  constructor(props) {
    super(props);
    let isButtonHide = true;
    let isShowField = false;
    let isDeleteButtonHide = true;
    let isHideNickNameAddress = true;
    if (props.pageId === 'incident') {
      isButtonHide = false;
      isShowField = true;
      isHideNickNameAddress = false;
    }
    if (props.pageId === 'event') {
      isHideNickNameAddress = false;
    }

    if (props.pageId === 'editMemberIncident') {
      isButtonHide = false;
    }
    if (this.props.pageId === 'creataccount') {
      this.getCountryList();
      for (let i = 0; i < this.countryListArray.length; i++) {
        if (Utils.equalsIgnoreCase(this.countryListArray[i].namecode, 'United States')) {
          this.selectedCountry = {
            namecode: this.countryListArray[i].namecode,
            name: this.countryListArray[i].name,
            code: this.countryListArray[i].code,
            isoCode: this.countryListArray[i].isoCode,
            description: this.countryListArray[i].description,
            id: this.countryListArray[i].id,
          };
          this.setStatesForCountry(this.selectedCountry);
        }
      }

      this.state = {
        id: '',
        line: '',
        country: this.selectedCountry,
        state: null,
        city: '',
        zipCode: '',
        action: this.props.action,
        onChange: this.props.onChange,
        render: true,
        shortDescription: '',
        nickName: '',
        isButtonHide: isButtonHide,
        isShowField: isShowField,
      };
    } else {
      let shortAddress = props.data;

      if (shortAddress) {
        this.state = {
          shortDescription: shortAddress?.shortDescription,
          nickName: shortAddress?.nickName,
          id: shortAddress?.address?.id,
          line: shortAddress?.address?.line,
          state: shortAddress?.address?.state,
          selectedState: shortAddress?.address?.state,
          country: shortAddress?.address?.state?.country,
          city: shortAddress?.address?.city,
          zipCode: shortAddress?.address?.zipCode,

          stateClear: false,
          action: this.props.action,
          onChange: this.props.onChange,
          render: true,
          isButtonHide: isButtonHide,
          isShowField: isShowField,
        };
      } else {
        if (this.memberDetails.address && this.memberDetails.address.state) {
          this.selectedCountry = {
            id:
              this.memberDetails.address && this.memberDetails.address.state && this.memberDetails.address.state.country
                ? this.memberDetails.address.state.country.id
                : '',
            namecode:
              this.memberDetails.address && this.memberDetails.address.state && this.memberDetails.address.state.country
                ? this.memberDetails.address.state.country.namecode
                : '',
          };
          this.setStatesForCountry(this.selectedCountry);
          this.state = {
            id: '',
            line: '',
            country: this.memberDetails.address.state.country ? this.memberDetails.address.state.country : '',
            state: this.memberDetails.address.state ? this.memberDetails.address.state : '',
            city: '',
            zipCode: '',
            action: this.props.action,
            onChange: this.props.onChange,
            render: true,
            shortDescription: '',
            nickName: '',
            isButtonHide: isButtonHide,
            isShowField: isShowField,
          };
        } else {
          this.getCountryList();
          for (let i = 0; i < this.countryListArray.length; i++) {
            if (Utils.equalsIgnoreCase(this.countryListArray[i].namecode, 'United States')) {
              this.selectedCountry = {
                namecode: this.countryListArray[i].namecode,
                name: this.countryListArray[i].name,
                code: this.countryListArray[i].code,
                isoCode: this.countryListArray[i].isoCode,
                description: this.countryListArray[i].description,
                id: this.countryListArray[i].id,
              };
              this.setStatesForCountry(this.selectedCountry);
            }
          }

          this.state = {
            id: '',
            line: '',
            country: this.selectedCountry,
            state: null,
            city: '',
            zipCode: '',
            action: this.props.action,
            onChange: this.props.onChange,
            render: true,
            shortDescription: '',
            nickName: '',
            isButtonHide: isButtonHide,
            isShowField: isShowField,
          };
        }
      }
    }

    if (this.state.country !== null && undefined !== this.state.country) {
      this.handleCountryChange(this.state.country);
    } else {
    }
  }

  setStatesForCountry(selectedCountry) {
    this.setState({ country: selectedCountry, selectedCountry: selectedCountry });
  }

  handleStateChange(selectedState) {
    this.state.state = selectedState;
    this.setState({ state: selectedState });
    if (undefined !== this.onChange && null !== this.onChange) {
      this.onChange(this.state);
    }
  }

  handleLine1Change(e) {
    let val = e.target.value;
    let res = val.substring(0, 128);
    if (res.length < 128) {
      this.setState({ line: res });
    } else {
      Utils.maxFieldLength(128);
    }
  }

  handleLine2Change(e) {
    this.setState({ line2: e.target.value });
  }

  handleCityChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 128);
    if (res.length < 128) {
      this.setState({ city: res });
    } else {
      Utils.maxFieldLength(128);
    }
  }

  handlePinCodeChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 16);
    if (res.length < 16) {
      this.setState({ zipCode: res });
    } else {
      Utils.maxFieldLength(16);
    }
  }

  handleCountryChange(selectedCountry) {
    if (null === selectedCountry || selectedCountry === '') {
      this.stateListArray = [];
      this.setState({
        stateClear: !this.state.stateClear,
        state: null,
      });
      return;
    }
    this.state.country = selectedCountry;
    if (undefined !== this.onChange && null !== this.onChange) {
      this.onChange(this.state);
    }

    this.setState({
      country: selectedCountry,
      state: null,
    });

    const header = store.getState().header.header;
    const postObject = header;
    let url = 'statems/findByCountry/';
    FetchServerData.callPostService(url + selectedCountry.id, postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let stateList = output.data;
        this.stateListArray = stateList;
        this.setState({
          render: true,
        });
      } else {
      }
    });
  }

  getCountryList() {
    FetchServerData.callPostService('/countryms/getAll').then((output_data) => {
      if (output_data.status === 'SUCCESS') {
        this.countryListArray = output_data.data;
        this.setState({
          render: true,
        });
      } else {
      }
    });
  }

  validateAddress() {
    if (Utils.isNullOrEmpty(this.state.country)) {
      document.getElementById('country').focus();
      PtsAlert.error('Please Select Country');

      return false;
    }
    if (Utils.isNullOrEmpty(this.state.state)) {
      document.getElementById('state').focus();
      PtsAlert.error('Please Select State');
      return false;
    }

    return true;
  }
  handleNicknameChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 10);
    // this.state.address = this.addressRef.current.state;
    this.setState({ nickName: res, shortDescription: val });
  }
  handleShortNameChange(e) {
    let val = e.target.value;

    let res = val.substring(0, 10);
    this.setState({ nickName: res });
  }
  render() {
    if (this.state.render === false) {
      return <div />;
    }
    this.countryRef = React.createRef();
    this.stateRef = React.createRef();
    if (this.props.action === 'create') {
      return this.createAddress();
    } else if (this.props.action === 'edit') {
      return this.editAddress();
    } else if (this.props.action === 'createAddress') {
      return this.profileAddress();
    } else {
      return this.viewAddress();
    }
  }

  openGoogleMapUrl(e) {
    let line = this.props.data.address.line ? this.props.data.address.line : '';
    let city = this.props.data.address.city ? this.props.data.address.city : '';
    let state = this.props.data.address.state.name ? this.props.data.address.state.name : '';
    let country = this.props.data.address.state.country.name ? this.props.data.address.state.country.name : '';
    let address = `${line} ${city} ${state} ${country}`;
    var url = 'https://www.google.com/maps/dir/?api=1&travelmode=driving&dir_action=navigate&destination=' + address;
    if (!url) {
      console.log("Can't handle url: " + url);
    } else {
      return window.open(url, '_blank');
    }
  }

  editAddress() {
    return (
      <>
        {undefined !== this.props.title && this.props.title.length > 0 && (
          <Row>
            <Col>
              <Label style={{ fontWeight: '600', color: '#1b7189', marginLeft: '0.5em' }}>{this.props.title}</Label>
            </Col>
          </Row>
        )}
        <Row>
          <Col md={6}>
            {this.state.isButtonHide && (
              <TextField
                fullWidth
                variant="outlined"
                id={'Description' + this.state.index}
                label="Location Name "
                value={this.state.shortDescription}
                onChange={this.handleNicknameChange.bind(this)}
                name="Description"
                type="text"
                margin="normal"
                className="textField"
                inputprops={{ maxLength: 64, style: { textTransform: 'capitalize' } }}
              />
            )}

            {this.state.isShowField && (
              <TextField
                fullWidth
                variant="outlined"
                id={'Description' + this.state.index}
                label=" Location Description "
                value={this.state.shortDescription}
                onChange={this.handleNicknameChange.bind(this)}
                name="Description"
                type="text"
                margin="normal"
                className="textField"
                inputprops={{ maxLength: 64, style: { textTransform: 'capitalize' } }}
              />
            )}
          </Col>

          <Col md={6}>
            <TextField
              fullWidth
              variant="outlined"
              id={'shortName' + this.state.index}
              label="Short Name "
              value={this.state.nickName}
              onChange={this.handleShortNameChange.bind(this)}
              name="shortName"
              type="text"
              margin="normal"
              className="textField"
              inputprops={{ maxLength: 256, style: { textTransform: 'capitalize' } }}
            />
          </Col>
        </Row>
        <Row className="editAddressRow">
          <Col md={12}>
            <TextField
              fullWidth
              variant="outlined"
              id="line"
              margin="normal"
              label="Address"
              value={this.state.line}
              onChange={this.handleLine1Change.bind(this)}
              inputProps={{ maxLength: 128, style: { textTransform: 'capitalize' } }}
              name="name"
              onBlur={() => {
                this.props.onChange(this.state);
              }}
              type="text"
              className="textField"
            />
          </Col>
        </Row>
        <Row className="editAddressRow">
          <Col md={6} className="editAddressCol">
            <Autocomplete
              id="country"
              options={this.countryListArray}
              value={this.state.country}
              defaultValue={this.state.country}
              getOptionLabel={(option) => option.namecode}
              onChange={(event, value) => {
                this.handleCountryChange(value);
              }}
              inputProps={{ style: { textTransform: 'capitalize' } }}
              renderInput={(params) => (
                <TextField {...params} label="Country" variant="outlined" required margin="normal" />
              )}
            />
          </Col>

          <Col md={6} className="editAddressCol">
            <Autocomplete
              id="state"
              options={this.stateListArray}
              value={this.state.state}
              // getOptionLabel={(option) => option.name + ' ' + '(' + option.code + ')'}
              getOptionLabel={(option) => option.namecode}
              defaultValue={this.state.state}
              onChange={(event, value) => {
                this.handleStateChange(value);
              }}
              inputProps={{ style: { textTransform: 'capitalize' } }}
              renderInput={(params) => (
                <TextField {...params} label="State" variant="outlined" required margin="normal" />
              )}
            />
          </Col>
        </Row>
        <Row className="editAddressRow">
          <Col md={6} className="editAddressCol">
            <TextField
              fullWidth
              variant="outlined"
              id="city"
              margin="normal"
              label="City"
              value={this.state.city}
              onChange={this.handleCityChange.bind(this)}
              name="city"
              inputProps={{ maxLength: 128, style: { textTransform: 'capitalize' } }}
              className="textField"
              onBlur={() => {
                this.props.onChange(this.state);
              }}
            />
          </Col>

          <Col md={6} className="editAddressCol">
            <TextField
              fullWidth
              variant="outlined"
              id="zipCode"
              margin="normal"
              label="Postal Code"
              value={this.state.zipCode}
              onChange={this.handlePinCodeChange.bind(this)}
              name="zipCode"
              inputProps={{ maxLength: 16 }}
              className="textField"
              onBlur={() => {
                this.props.onChange(this.state);
              }}
            />
          </Col>
        </Row>
      </>
    );
  }

  viewAddress() {
    let stateName =
      null !== this.state.state && undefined !== this.state.state && '' !== this.state.state
        ? this.state.state.namecode + ','
        : '';
    let countryName =
      null !== this.state.country && undefined !== this.state.country && '' !== this.state.country
        ? this.state.country.namecode
        : '';
    let statecountryName = stateName + countryName;
    return (
      <>
        <div className="location_details_view">
          <div className="panel panel-blue">
            <div className="panel-heading">
              <Row>
                <Col md={10}>
                  <span style={{ color: '#E67E22', padding: '5px 0px' }}>
                    {this.props.data?.shortDescription ? <strong>{this.props.data.shortDescription}</strong> : ''}
                  </span>
                </Col>
                <Col md={2}>
                  {' '}
                  <span>
                    <LocationPinIcon style={{ fontSize: 'large' }} onClick={(e) => this.openGoogleMapUrl(e)} />

                    {/* <EditButton onClick={(e) => this.editLocationItem(e)} /> */}
                  </span>
                </Col>
              </Row>
              <Row style={{ padding: '3px 3px 3px 0px', textTransform: 'capitalize' }}>
                <Col md={1}>
                  {/* <Tooltip title="Address" aria-label="add">
                    <PinDropIcon style={{ fontSize: 'large' }} />
                  </Tooltip> */}
                </Col>
                <Col md={11}>{Utils.getAddressAsText(this.props.data?.address)}</Col>
              </Row>
            </div>
          </div>
        </div>
      </>
    );
  }

  profileAddress() {
    this.countryTextFieldRef = React.createRef();
    return (
      <form ref={this.formRef}>
        <Row>
          <Col md={6}>
            {this.state.isButtonHide && (
              <TextField
                fullWidth
                variant="outlined"
                id={'Description' + this.state.index}
                label="Location Name "
                value={this.state.shortDescription}
                onChange={this.handleNicknameChange.bind(this)}
                name="Description"
                type="text"
                margin="normal"
                className="textField"
                inputprops={{ maxLength: 64, style: { textTransform: 'capitalize' } }}
              />
            )}

            {this.state.isShowField && (
              <TextField
                fullWidth
                variant="outlined"
                id={'Description' + this.state.index}
                label=" Location Description "
                value={this.state.shortDescription}
                onChange={this.handleNicknameChange.bind(this)}
                name="Description"
                type="text"
                margin="normal"
                className="textField"
                inputprops={{ maxLength: 64, style: { textTransform: 'capitalize' } }}
              />
            )}
          </Col>

          <Col md={6}>
            <TextField
              fullWidth
              variant="outlined"
              id={'shortName' + this.state.index}
              label="Short Name "
              value={this.state.nickName}
              onChange={this.handleShortNameChange.bind(this)}
              name="shortName"
              type="text"
              margin="normal"
              className="textField"
              inputprops={{ maxLength: 256, style: { textTransform: 'capitalize' } }}
            />
          </Col>
        </Row>
        <Row className="editAddressRow">
          <Col md={12}>
            <TextField
              fullWidth
              variant="outlined"
              id="line"
              label="Address"
              margin="normal"
              value={this.state.line}
              onChange={this.handleLine1Change.bind(this)}
              onBlur={() => {
                this.props.onChange(this.state);
              }}
              inputProps={{ maxLength: 128, style: { textTransform: 'capitalize' } }}
              name="name"
              type="text"
              className="textField"
              errorMessages={['Max 12th requrired']}
            />
          </Col>
        </Row>
        <Row className="editAddressRow">
          <Col md={6}>
            <Autocomplete
              id="country"
              options={this.countryListArray}
              value={this.state.country}
              defaultValue={this.state.country}
              getOptionLabel={(option) => option.namecode}
              onChange={(event, value) => {
                this.handleCountryChange(value);
              }}
              inputProps={{ style: { textTransform: 'capitalize' } }}
              renderInput={(params) => <TextField {...params} label="Country" variant="outlined" margin="normal" />}
            />
          </Col>

          <Col md={6}>
            <Autocomplete
              id="state"
              options={this.stateListArray}
              value={this.state.state}
              getOptionLabel={(option) => option.namecode}
              defaultValue={this.state.state}
              onChange={(event, value) => {
                this.handleStateChange(value);
              }}
              inputProps={{ style: { textTransform: 'capitalize' } }}
              renderInput={(params) => <TextField {...params} label="State" variant="outlined" margin="normal" />}
            />
          </Col>
        </Row>
        <Row className="editAddressRow">
          <Col md={6}>
            <TextField
              fullWidth
              variant="outlined"
              id="city"
              label="City"
              margin="normal"
              value={this.state.city}
              onChange={this.handleCityChange.bind(this)}
              onBlur={() => {
                this.props.onChange(this.state);
              }}
              name="city"
              inputProps={{ maxLength: 128, style: { textTransform: 'capitalize' } }}
              type="text"
              className="textField"
            />
          </Col>

          <Col md={6}>
            <TextField
              fullWidth
              variant="outlined"
              id="zipCode"
              margin="normal"
              label="Postal Code"
              value={this.state.zipCode}
              onChange={this.handlePinCodeChange.bind(this)}
              inputProps={{ maxLength: 16 }}
              onBlur={() => {
                this.props.onChange(this.state);
              }}
              name="zipCode"
              className="textField"
            />
          </Col>
        </Row>
      </form>
    );
  }
  createAddress() {
    this.countryTextFieldRef = React.createRef();
    return (
      <form ref={this.formRef}>
        {undefined !== this.props.title && this.props.title.length > 0 && (
          <Row>
            <Col>
              <Label style={{ fontWeight: '600', color: '#1b7189', marginLeft: '0.5em' }}>{this.props.title}</Label>
            </Col>
          </Row>
        )}
        <Row>
          <Col md={6}>
            {this.state.isButtonHide && (
              <TextField
                fullWidth
                variant="outlined"
                id={'Description' + this.state.index}
                label="Location Name "
                value={this.state.shortDescription}
                onChange={this.handleNicknameChange.bind(this)}
                name="Description"
                type="text"
                margin="normal"
                className="textField"
                inputprops={{ maxLength: 64, style: { textTransform: 'capitalize' } }}
              />
            )}

            {this.state.isShowField && (
              <TextField
                fullWidth
                variant="outlined"
                id={'Description' + this.state.index}
                label=" Location Description "
                value={this.state.shortDescription}
                onChange={this.handleNicknameChange.bind(this)}
                name="Description"
                type="text"
                margin="normal"
                className="textField"
                inputprops={{ maxLength: 64, style: { textTransform: 'capitalize' } }}
              />
            )}
          </Col>

          <Col md={6}>
            <TextField
              fullWidth
              variant="outlined"
              id={'shortName' + this.state.index}
              label="Short Name "
              value={this.state.nickName}
              onChange={this.handleShortNameChange.bind(this)}
              name="shortName"
              type="text"
              margin="normal"
              className="textField"
              inputprops={{ maxLength: 256, style: { textTransform: 'capitalize' } }}
            />
          </Col>
        </Row>
        <Row className="editAddressRow">
          <Col md={12}>
            <TextField
              fullWidth
              variant="outlined"
              id="line"
              label="Address"
              margin="normal"
              value={this.state.line}
              onChange={this.handleLine1Change.bind(this)}
              onBlur={() => {
                this.props.onChange(this.state);
              }}
              inputProps={{ maxLength: 128, style: { textTransform: 'capitalize' } }}
              name="name"
              type="text"
              className="textField"
              errorMessages={['Max 12th required']}
            />
          </Col>
        </Row>
        <Row className="editAddressRow">
          <Col md={6}>
            <Autocomplete
              id="country"
              options={this.countryListArray}
              value={this.state.country}
              defaultValue={this.state.country}
              getOptionLabel={(option) => option.namecode}
              onChange={(event, value) => {
                this.handleCountryChange(value);
              }}
              inputProps={{ style: { textTransform: 'capitalize' } }}
              renderInput={(params) => (
                <TextField {...params} label="Country" variant="outlined" required margin="normal" />
              )}
            />
          </Col>

          <Col md={6}>
            <Autocomplete
              id="state"
              options={this.stateListArray}
              value={this.state.state}
              getOptionLabel={(option) => option.namecode}
              defaultValue={this.state.state}
              onChange={(event, value) => {
                this.handleStateChange(value);
              }}
              inputProps={{ style: { textTransform: 'capitalize' } }}
              renderInput={(params) => (
                <TextField {...params} label="State" variant="outlined" required margin="normal" />
              )}
            />
          </Col>
        </Row>
        <Row className="editAddressRow">
          <Col md={6}>
            <TextField
              fullWidth
              variant="outlined"
              id="city"
              label="City"
              margin="normal"
              value={this.state.city}
              onChange={this.handleCityChange.bind(this)}
              onBlur={() => {
                this.props.onChange(this.state);
              }}
              name="city"
              inputProps={{ maxLength: 128, style: { textTransform: 'capitalize' } }}
              type="text"
              className="textField"
            />
          </Col>

          <Col md={6}>
            <TextField
              fullWidth
              variant="outlined"
              id="zipCode"
              margin="normal"
              label="Postal Code"
              value={this.state.zipCode}
              onChange={this.handlePinCodeChange.bind(this)}
              inputProps={{ maxLength: 16 }}
              onBlur={() => {
                this.props.onChange(this.state);
              }}
              name="zipCode"
              className="textField"
            />
          </Col>
        </Row>
      </form>
    );
  }
}

export default ShortLocation;
