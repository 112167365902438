import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';
import FetchServerData from '../../provider/FetchServerData';
import PtsAlert from '../../pts/components/ptsAlert/PtsAlert';
import store from '../../redux/store';

export default class Verify extends Component {
  constructor(props) {
    super(props);
    let action = props.action;
    if (
      null !== props.history &&
      props.history !== undefined &&
      props.history.location !== null &&
      props.history.location.state !== null &&
      props.history.location.state !== undefined
    ) {
      action = props.history.location.state.action;
    }
    let str = window.location.href;
    let urlArr = str.split('?');
    let url = urlArr[1].split('=');

    let urlMember = url[1].split('&');
    let urlMemberId = urlMember[0];
    let urlfamilyMember = url[2].split('&');
    let urlfamilyMemberId = urlfamilyMember[0];
    let urlisMember1 = url[3].split('&');
    let urlisMember = urlisMember1[0];
    let urlisContact = url[4].split('&');
    let urlContactId = urlisContact[0];
    this.state = {
      email: '',
      code: '',
      member: '',
      showPassword: false,
      memberId: urlMemberId,
      familyMember: urlfamilyMemberId,
      isMember: urlisMember,
      contactMemberId: urlContactId,
      action: action,
    };
  }

  createCancel = () => {
    this.props.history.push({
      pathname: '/login',
    });
  };
  handleVerificationCodeChange(e) {
    this.setState({ code: e.target.value });
  }
  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }
  handleMemberChange(e) {
    this.setState({ member: e.target.value });
  }
  verifyInBackend = () => {
    const header = store.getState().header.header;
    let familyMemberId = this.state.familyMember;
    if (this.state.familyMember === 'null') {
      familyMemberId = 0;
    }
    const postObject = {
      header: header,
      appuserVo: {
        verificationCode: this.state.code,
      },
      memberId: this.state.memberId,
      familyMemberId: familyMemberId,
      contactId: this.state.contactMemberId,
      isMember: this.state.isMember,
    };
    FetchServerData.callPostService('/userms/verify', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let appuserStatus = output.data.appuserStatus.status;
        PtsAlert.success(output.message);
        if (appuserStatus === 'Register') {
          this.props.history.push({
            pathname: '/login',
          });
        } else {
          this.props.history.push({
            pathname: '/updateCredential',
          });
        }
      } else {
      }
    });
  };
  render() {
    return (
      <>
        <Col md={3}></Col>
        <Col md={6}>
          <Card class="formCard" style={{ marginTop: '64px' }}>
            <Row md={12} style={{ marginTop: '2em' }}>
              <Col>
                <CardHeader style={{ width: '100%', borderBottom: '1px solid #dcdcdc' }}>
                  <h4 style={{ color: '#169ba2' }}>
                    <b>Verification Account</b>
                  </h4>
                </CardHeader>
              </Col>
            </Row>
            <CardBody>
              <form style={{ width: '100%' }}>
                <Row>
                  <Col md={12}>
                    {/* <label>
                      <b>Please check your email for OTP message .Enter OTP.</b>
                    </label> */}
                  </Col>
                </Row>
                {/* <Row>
                  <Col md={8}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="email"
                      onChange={this.handleEmailChange.bind(this)}
                      label="Email"
                      name="email"
                      autoComplete="email"
                      value={this.state.email}
                    />
                  </Col>
                </Row> */}
                <Row>
                  <Col md={8}>
                    <TextField
                      margin="normal"
                      variant="outlined"
                      required
                      value={this.state.code}
                      onChange={this.handleVerificationCodeChange.bind(this)}
                      fullWidth
                      id="code"
                      label="Enter Code"
                      name="code"
                      autoComplete="code"
                      autoFocus
                    />
                  </Col>
                </Row>
                {/* <Row>
                  <Col md={8}>
                    <TextField
                      variant="outlined"
                      required
                      fullWidth
                      id="member"
                      onChange={this.handleMemberChange.bind(this)}
                      label="Member"
                      name="member"
                      autoComplete="member"
                      value={this.state.member}
                    />
                  </Col>
                </Row> */}
              </form>
            </CardBody>
            <Row md={12} style={{ marginTop: '2em' }}>
              <Col>
                <CardFooter style={{ width: '100%' }}>
                  {/* <div style={{ textAlign: 'left' }}>
                    <Link href="/login" variant="body2">
                      Didn't get a OTP Resend?
                    </Link>
                  </div> */}
                  <div style={{ textAlign: 'right' }}>
                    <Button
                      type="submit"
                      style={{ backgroundColor: '#1a7088', color: '#fff' }}
                      variant="contained"
                      onClick={(e) => this.verifyInBackend()}
                      startIcon={<CheckCircleIcon />}
                    >
                      Verify
                    </Button>
                    &nbsp;
                    <NavLink
                      style={{ backgroundColor: '#777777', color: '#fff' }}
                      to="/login"
                      activeClassName="selectedLink"
                      strict
                    >
                      <Button
                        onClick={(e) => this.createCancel()}
                        style={{ backgroundColor: '#777777', color: '#fff' }}
                        startIcon={<CancelIcon />}
                      >
                        Cancel
                      </Button>
                    </NavLink>
                  </div>
                </CardFooter>
              </Col>
            </Row>
          </Card>
        </Col>
      </>
    );
  }
}
