import React from 'react';
import PageAlertContext from '../pageAlert/PageAlertContext';

const Page = ({ children }) => {
  return (
    <PageAlertContext.Consumer>
      {(context) => {
        const hasPageAlertClass = context.alert ? 'has-alert' : '';
        return (
          <>
            <div id="page-content" style={{ marginTop: '3em' }} className={`${hasPageAlertClass}`}>
              {/* <div style={{backgroundColor:'#ffff',height:'1em'}}>

          </div> */}
              {children}
            </div>
          </>
        );
      }}
    </PageAlertContext.Consumer>
  );
};

export default Page;
