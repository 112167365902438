import Tooltip from '@material-ui/core/Tooltip';
import React, { Component } from 'react';
import Avatar, { ConfigProvider } from 'react-avatar';
import store from '../../../redux/store';
import PageAlertContext from '../pageAlert/PageAlertContext';
import NavDivider from './components/NavDivider';
import NavDropdownItem from './components/NavDropdownItem';
import NavOverlay from './components/NavOverlay';
import NavSingleItem from './components/NavSingleItem';
import NavSpacer from './components/NavSpacer';
import './MemberSidebarNav.scss';
import memberListAction from '../../../redux/memberList/memberListAction';
import Utils from '../../../provider/Utils';
import { connect } from 'react-redux';
import ActiveMenuAction from '../../../redux/activeMenu/ActiveMenuAction';
export class MemberSidebarNav extends Component {
  memberDetails = store.getState().memberData.memberData;
  memberNamesList = store.getState().memberList.memberList;
  constructor(props) {
    super(props);
    let memberInfo = props.data;
    let memberMenus = memberInfo.member.menuList;
    let versionMenu = memberInfo.version;
    for (let i = 0; i < memberMenus.length; i++) {
      memberMenus[i].helpClassName = 'sidebarMenu' + (i + 1);
    }
    let activeMenu = store.getState().activeMenu.activeMenu;
    if (undefined === activeMenu || null === activeMenu) {
      activeMenu = (memberMenus && memberMenus.length) > 0 ? memberMenus[0].id : -1;
    }
    this.state = {
      memberData: memberInfo,
      memberMenuList: memberMenus,
      version: versionMenu,
      activeMenu: activeMenu,
    };
  }
  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }
  componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      let activeMenu = store.getState().activeMenu.activeMenu;
      this.setState({ activeMenu: activeMenu });
    });
  }
  componentWillUnmount() {
    this.unsubscribe();
  }
  toMemberDetails = (e) => {
    this.props.history.push('/memberProfile');
  };
  selectedChildMenu = (menuid) => {
    for (let i = 0; i < this.state.memberMenuList.length; i++) {
      if (this.state.memberMenuList[i].id === menuid) {
        store.dispatch(ActiveMenuAction.setActiveMenu(menuid));
        this.setState({ activeMenu: menuid });
      }
    }
  };
  showProfileIcon(memberName) {
    let fullName = memberName;

    if (this.memberNamesList.includes(fullName) === false) {
      this.memberNamesList.push(fullName);
      store.dispatch(memberListAction.setMemberList(this.memberNamesList));
    }

    return <>{Utils.getMemberProfileName(fullName, this.memberNamesList.indexOf(fullName) % 26)}</>;
    return fullName;
  }
  render() {
    const navItems = (items) => {
      // return items.map((item, index) => itemType(item, index));
      if (!this.isEmpty(items)) {
        return items.map((item, index) => itemType(item, index));
      }
    };

    const itemType = (item, index) => {
      if (!this.isEmpty(item)) {
        let isActive = false;
        if (item.id === this.state.activeMenu) {
          isActive = true;
        }
        return (
          <NavSingleItem item={item} key={index} onSelect={this.selectedChildMenu.bind(this)} isActive={isActive} />
        );
      }
    };

    return (
      <>
        <PageAlertContext.Consumer>
          {(consumer) => {
            const hasPageAlertClass = consumer.alert ? 'has-alert' : '';
            let firstName =
              null !== this.state.memberData.member.firstName && undefined !== this.state.memberData.member.firstName
                ? this.state.memberData.member.firstName
                : '';
            let lastName =
              null !== this.state.memberData.member.lastName && undefined !== this.state.memberData.member.lastName
                ? this.state.memberData.member.lastName
                : '';
            let memberName = firstName + ' ' + lastName;
            return (
              <div>
                <div
                  className={`app-sidebar ${hasPageAlertClass}`}
                  style={{
                    marginTop: '3.6em',
                    transition: 'width 0.3s linear',
                  }}
                >
                  <div
                    className="user-panel"
                    style={{ color: '#fff', textAlign: 'center', marginTop: '1em', cursor: 'pointer' }}
                    onClick={(e) => this.toMemberDetails(e)}
                  >
                    <span title="Member Profile">{this.showProfileIcon(memberName)}</span>
                    <p style={{ textTransform: 'capitalize' }}>
                      {this.state.memberData.member.firstName + ' ' + this.state.memberData.member.lastName}
                      <br></br>
                    </p>
                  </div>
                  <nav>
                    <ul id="main-menu">
                      {navItems(this.state.memberMenuList)}

                      <NavSpacer />

                      <li className="nav-item">
                        <Tooltip title={this.state.version.number}>
                          <div
                            style={{
                              display: 'flex',
                              width: '100%',
                              textDecoration: 'none',
                              padding: '13px 15px',
                              color: '#fff',
                              borderColor: 'transparent',
                            }}
                          >
                            <span className="nav-item-label">{this.state.version.number}</span>
                          </div>
                        </Tooltip>
                      </li>
                    </ul>
                  </nav>
                </div>
                {this.props.isSidebarCollapsed && <NavOverlay onClick={this.props.toggleSidebar} />}
              </div>
            );
          }}
        </PageAlertContext.Consumer>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return { activeMenu: state.activeMenu.activeMenu };
};
export default connect(mapStateToProps)(MemberSidebarNav);
