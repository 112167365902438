import { CLEAR_APPOINTMENT_DATA, GET_APPOINTMENT_DATA, SET_APPOINTMENT_DATA } from './appointmentDataTypes';

const getAppointment = () => {
  return {
    type: GET_APPOINTMENT_DATA,
  };
};

const setAppointment = (data) => {
  return {
    type: SET_APPOINTMENT_DATA,
    payload: data,
  };
};

const clearAppointment = (data) => {
  return {
    type: CLEAR_APPOINTMENT_DATA,
    payload: data,
  };
};
const appointmentDataAction = { getAppointment, setAppointment, clearAppointment };
export default appointmentDataAction;
