import { Card } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';
import AddCommentIcon from '@material-ui/icons/AddComment';
import CreateIcon from '@material-ui/icons/Create';
import DoneIcon from '@material-ui/icons/Done';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer';
import SendIcon from '@material-ui/icons/Send';
import MaterialTable from 'material-table';
import moment from 'moment';
import React, { Component } from 'react';
import Avatar from 'react-avatar';
import ScrollToBottom from 'react-scroll-to-bottom';
import { CardBody, CardHeader, Col, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import UiUtils from '../../../provider/UiUtils';
import Utils from '../../../provider/Utils';
import store from '../../../redux/store';
import CancelIconButton from '../buttons/CancelIconButton';
import DeleteButton from '../buttons/DeleteButton';
import EditButton from '../buttons/EditButton';
import PtsAlert from '../ptsAlert/PtsAlert';
import Tooltip from '@material-ui/core/Tooltip';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import './appointmentNotes.scss';
import memberListAction from '../../../redux/memberList/memberListAction';
export default class AppointmentNote extends Component {
  taskOccuranceList = store.getState().staticData.taskOccuranceList;
  memberDetails = store.getState().memberData.memberData;
  header = store.getState().header.header;
  memberNamesList = store.getState().memberList.memberList;
  originalState = null;
  messageListRef = [];
  constructor(props) {
    super(props);

    let action = props.action;

    let expanded = false;
    let isNoteConversation = false;
    let note = props.data;

    /* Open note chat window dynamically when user come from note notification by matching note id from url and note list id*/
    if (props.noteId) {
      if (props.noteId.toString() === note.id.toString()) {
        expanded = true;
        isNoteConversation = true;
        this.viewNoteDetails('e', note.id, false);
      }
    }

    let pageId = props.pageId;

    let isButtonHide = false;

    if (pageId === 'editMemberAppointments') {
      isButtonHide = true;
    }
    let isViewButtonHide = true;
    if (props.isPrintView === 'view_print') {
      isViewButtonHide = false;
    }
    let isButtonShow = false;
    let isThreadButtonHide = true;
    let appointmentThreadList = props.data.messageThread;
    let unreadMessageCount = '';
    let unreadMessageCountShow = false;

    if (action === 'view' || action === 'edit') {
      if (appointmentThreadList !== {}) {
        if (appointmentThreadList !== undefined) {
          unreadMessageCount = props.data.unreadMessageCount;
        }
        isButtonShow = false;
        isThreadButtonHide = true;
        // isCreatedByUser = appointmentThreadList.isCreatedByUser;
      } else {
        isButtonShow = true;
        isThreadButtonHide = false;
        //  isCreatedByUser = appointmentThreadList.isCreatedByUser;
        unreadMessageCount = props.data.unreadMessageCount;
      }

      if (unreadMessageCount === 0) {
        unreadMessageCountShow = false;
      } else {
        unreadMessageCountShow = true;
      }
    }
    console.log(note);
    this.state = {
      id: note.id,
      note: note.note,
      title: note.shortDescription,
      occurrence: note.occurance.occurance,
      appointmentOccurance: note.appointmentOccurance,
      operation: note.operation,
      date: moment(note.createdOn).format('DD MMM YYYY'),
      index: props.srno,
      createdBy: note.createdByName,
      createdDate: note.createdOn,
      updatedDate: note.updatedOn,
      time: note.dateTime,
      onDelete: props.onDelete,
      onChange: props.onChange,
      onNoteEdit: props.onNoteEdit,
      onNoteDelete: props.onNoteDelete,
      onNoteUpdate: props.onNoteUpdate,
      onNoteMsg: props.onNoteMsg,
      addNote: props.addNote,
      action: action,
      render: true,
      expanded: expanded,
      startDate: note.createdOn,
      isButtonHide: isButtonHide,
      isButtonShow: isButtonShow,
      isThreadButtonHide: isThreadButtonHide,
      key: props.key,
      participantList: [],
      noteId: note.id,
      messageList: [],
      senderId: '',
      threadId: '',
      sendText: '',
      historyDetailsModal: false,
      historyDetails: [],
      unreadMessageCount: unreadMessageCount,
      unreadMessageCountShow: unreadMessageCountShow,
      anchorEl: '',
      participantMemberList: [],
      isNoteConversation: isNoteConversation,
      isViewButtonHide: isViewButtonHide,
      getDeletePermission: props.getDeletePermission,
      getUpdatePermission: props.getUpdatePermission,
      getCreatePermission: props.getCreatePermission,
      viewMessage: false,
      createThread: false,
      threadLength: 0,
      pageSize: 10,
      isPaging: false,
      hasMsgThread: note.hasMsgThread,
      isMsgThreadParticipant: note.isMsgThreadParticipant,
      addParticipateModal: false,
      isCreatedByUser: false,
    };
  }

  componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      let newThreadId = '';
      let receiveMessageList = this.state.messageList;
      let newCommunictionData = store.getState().messageThreadsData.messageThreadsData;

      for (let i = 0; i < newCommunictionData.length; i++) {
        newThreadId = newCommunictionData[i].MsgThreadId;
        // if (this.state.threadId.toString() === newThreadId.toString()) {
        if (this.state.threadId === newThreadId) {
          let receivedMessageObj = {
            SubDomainObjectName: '',
            senderName: newCommunictionData[i].MsgCreatedBy,
            DomainObjectId: '',
            SubDomainObjectId: '',
            MsgDate: newCommunictionData[i].MsgDate,
            MsgThreadId: newCommunictionData[i].MsgThreadId,
            MessageType: newCommunictionData[i].MessageType,
            DomainObjectName: '',
            MemberId: newCommunictionData[i].MemberId,
            MsgId: newCommunictionData[i].MsgId,
            body: newCommunictionData[i].MsgContent,
            sender: {
              id: 0,
            },
          };
          receiveMessageList.push(receivedMessageObj);
          this.setState({
            messageList: receiveMessageList,
          });
        }
      }
    });
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  handleSubjectChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ subject: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }

  handleMessageChange(e) {
    this.setState({ message: e.target.value });
  }
  handleSendTextChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 512);
    this.setState({ expanded: true });
    if (res.length < 512) {
      this.setState({ sendText: res, expanded: true });
    } else {
      Utils.maxFieldLength(512);
    }
  }

  addNote(e) {
    if (this.validateNoteDetails() === false) {
      return false;
    }
    if (this.state.addNote) {
      this.setState({ action: 'create' });
      this.state.addNote(this.state);
    }
  }

  handleDescriptionChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 2048);
    if (res.length < 2048) {
      this.setState({ note: res });
    } else {
      Utils.maxFieldLength(2048);
    }
  }
  handleTitleChange(e) {
    this.setState({ title: e.target.value });
  }

  handleTaskOccurrenceChange(e) {
    this.setState({ occurrence: e.target.value });
  }

  appointmentNoteRemove(e) {
    if (undefined !== this.state.onDelete && null !== this.state.onDelete) {
      this.state.onDelete(this.state);
    }
  }

  editNote(e, id) {
    this.setState({ action: 'edit', render: true });
  }

  updateAppointmentNotes(e) {
    if (undefined !== this.state.onNoteUpdate && null !== this.state.onNoteUpdate) {
      this.state.onNoteUpdate(this.state);
      this.setState({ action: 'view' });
    }
  }

  raiseAppointmentNoteRequest = (e, noteDetails) => {
    const postObject = {
      header: this.header,
      member: {
        id: this.memberDetails.member,
      },
      messageThreadType: {
        type: 'Appointment',
      },
    };
    let url = '/memberms/getMessageThreadParticipants';
    FetchServerData.callPostService(url, postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let contactList = [];
        let memberList = [];
        let participantList = [];
        if (output.data.contactList) {
          contactList = output.data.contactList;
        }
        if (output.data) {
          memberList = output.data;
          for (let i = 0; i < memberList.length; i++) {
            if (memberList[i].isMember === true) {
              memberList[i].checked = true;
              let participantMemberObj = {
                user: {
                  id: memberList[i].id,
                },
              };
              participantList.push(participantMemberObj);
            } else {
              memberList[i].checked = false;
            }
          }
        }

        let isPaging = false;
        if (memberList.length > 10) {
          isPaging = true;
        }
        this.setState({
          isPaging: isPaging,
          noteContactList: contactList,
          noteMemberList: memberList,
          addMessageModal: true,
          noteId: this.state.id,
          participantList: participantList,
          subject: noteDetails.shortDescription,
        });
      } else {
      }
    });
  };
  addParticipantRequest = (e, noteDetails) => {
    const postObject = {
      header: this.header,
      member: {
        id: this.memberDetails.member,
      },
      messageThreadType: {
        type: 'Appointment',
      },
    };
    let url = '/memberms/getMessageThreadParticipants';
    FetchServerData.callPostService(url, postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let contactList = [];
        let memberList = [];
        let participantList = [];
        if (output.data.contactList) {
          contactList = output.data.contactList;
        }
        if (output.data) {
          memberList = output.data;
          for (let i = 0; i < memberList.length; i++) {
            for (let j = 0; j < this.state.participantMemberList.length; j++) {
              if (this.state.participantMemberList[j].user.id === memberList[i].id) {
                memberList[i].checked = true;

                let memberObj = {
                  user: {
                    id: memberList[i].id,
                  },
                  operation: 'ADD',
                };
                participantList.push(memberObj);
              }
            }
          }
        }
        let isPaging = false;
        if (memberList.length > 10) {
          isPaging = true;
        }
        this.setState({
          isPaging: isPaging,
          noteContactList: contactList,
          noteMemberList: memberList,
          addParticipateModal: true,
          noteId: this.state.id,
          participantList: participantList,
          subject: noteDetails.shortDescription,
        });
      } else {
      }
    });
  };
  addMessage = (e) => {
    const postObject = {
      header: this.header,
      messageThread: {
        subject: this.state.subject,
        member: this.memberDetails.member,
        noteId: this.state.id,
        type: {
          type: 'Appointment',
        },
        participantList: this.state.participantList,
        appointmentNote: {
          id: this.props.data.id,
        },
        messageList: [],
      },
    };
    FetchServerData.callPostService('/messageThreadms/create', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          PtsAlert.success(output.message);
          this.viewNoteDetails(null, output.data.id, null);
          this.props.onSuccess(this.props.appoinmentId);
          this.setState({
            addMessageModal: false,
            createThread: false,
            viewMessage: true,
            threadLength: 1,
            isNoteConversation: true,
          });
        } else {
        }
      })
      .catch((error) => {});
  };
  addNewParticipateInBackend = (e) => {
    const postObject = {
      header: this.header,
      messageThread: {
        id: this.state.threadId,
        type: {
          type: 'Appointment',
        },

        participantList: this.state.participantList,
      },
    };
    FetchServerData.callPostService('/messageThreadms/updateParticipants', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          PtsAlert.success(output.message);
          this.viewNoteDetails(null, output.data.id, null);
          this.setState({
            addParticipateModal: false,
            createThread: false,
            viewMessage: true,
            threadLength: 1,
            isNoteConversation: true,
          });
        } else {
        }
      })
      .catch((error) => {});
  };
  toggle = () => {
    this.setState({
      addMessageModal: !this.state.addMessageModal,
    });
  };
  addNewParticipateToggle = () => {
    this.setState({
      addParticipateModal: !this.state.addParticipateModal,
    });
  };
  historyMessageToggle = () => {
    this.setState({
      historyDetailsModal: !this.state.historyDetailsModal,
    });
  };

  deleteAppointmentNote(e, id) {
    this.setState({ id: id });
    if (undefined !== this.state.onNoteDelete && null !== this.state.onNoteDelete) {
      this.state.onNoteDelete(this.state);
    }
  }

  selectedParticipant(e, rowData, type) {
    const { checked } = e.target;

    let participantList = this.state.participantList;
    let contactObj = {};
    let memberObj = {};
    if (checked === true) {
      if (type === 'contact') {
        contactObj = {
          contact: {
            id: rowData.id,
          },
        };
        participantList.push(contactObj);
      } else {
        memberObj = {
          user: {
            id: rowData.id,
          },
        };
        participantList.push(memberObj);
      }

      this.setState({
        participantList: participantList,
      });
    } else {
      for (let i = 0; i < participantList.length; i++) {
        if (participantList[i].user.id === rowData.id) {
          participantList.splice(i, 1);
        }
      }
      this.setState({
        participantList: participantList,
      });
    }
  }

  selectedNewParticipant(e, rowData, type) {
    const { checked } = e.target;
    let participantList = this.state.participantList;
    let contactObj = {};
    let memberObj = {};
    if (checked === true) {
      if (type === 'contact') {
        contactObj = {
          contact: {
            id: rowData.id,
          },
        };
        participantList.push(contactObj);
      } else {
        memberObj = {
          user: {
            id: rowData.id,
          },
          operation: 'ADD',
        };
        participantList.push(memberObj);
      }

      this.setState({
        participantList: participantList,
      });
    } else {
      for (let i = 0; i < participantList.length; i++) {
        if (participantList[i].user.id === rowData.id) {
          participantList.splice(i, 1);
        }
      }
      this.setState({
        participantList: participantList,
      });
    }
  }
  backToViewAction() {
    this.setState({ action: 'view' });
  }
  validateNoteDetails(index) {
    if (!this.state.title) {
      document.getElementById('shortDescription' + this.state.index).focus();
      PtsAlert.error('Please Enter Note Note Title');

      return false;
    }

    return true;
  }
  sendMessage() {
    const postObject = {
      header: this.header,
      messageThread: {
        id: this.state.threadId,
        messageList: [
          {
            body: this.state.sendText,
            operation: 'Add',
          },
        ],
      },
    };
    FetchServerData.callPostService('/messageThreadms/update', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let receiveMessage = this.state.messageList;

          let message = output.data.messageThread.messageList[0];
          receiveMessage.push(message);
          this.setState({
            sendText: '',
            messageList: receiveMessage,
          });
          this.viewNoteDetails(null, this.state.id, null);
        } else {
        }
      })
      .catch((error) => {});
  }
  viewNoteConversation = (e, isNoteConversation, noteId) => {
    if (isNoteConversation === false) {
      this.viewNoteDetails(null, noteId, null);
      this.setState({
        isNoteConversation: true,
      });
    } else {
      this.setState({
        isNoteConversation: false,
      });
    }
  };
  viewNoteDetails = (e, noteId) => {
    let sender = this.header.appuserId;
    const postObject = {
      header: this.header,
      appointmentNote: {
        id: this.props.data.id,
      },
    };
    FetchServerData.callPostService('appointmentms/getNote', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let appointmentNoteData = output.data;
        let messageThread = appointmentNoteData.messageThread;
        let participantMemberList = [];
        let threadId = '';

        let unreadMessageCount = '';
        let isButtonShow = false;
        let isThreadButtonHide = true;
        let unreadMessageCountShow = false;
        let subject = '';
        if (messageThread !== {}) {
          unreadMessageCount = messageThread.unreadMessageCount;
          subject = messageThread.subject;
          this.messageListRef = messageThread.messageList;
          participantMemberList = messageThread.participantList;
          threadId = messageThread.id;
          isButtonShow = false;
          isThreadButtonHide = true;
          unreadMessageCountShow = true;
        } else {
          isButtonShow = true;
          isThreadButtonHide = false;
          unreadMessageCountShow = false;
        }
        if (appointmentNoteData.unreadMessageCount === 0 && unreadMessageCount === null) {
          unreadMessageCountShow = false;
        } else {
          unreadMessageCountShow = true;
        }
        this.setState({
          isNoteConversation: true,
          unreadMessageCount: unreadMessageCount,
          messageList: this.messageListRef,

          participantMemberList: participantMemberList,
          senderId: sender,
          threadId: threadId,
          subject: subject,
          isButtonShow: isButtonShow,
          isThreadButtonHide: isThreadButtonHide,
          unreadMessageCountShow: unreadMessageCountShow,
          hasMsgThread: appointmentNoteData.hasMsgThread,
          isMsgThreadParticipant: appointmentNoteData.isMsgThreadParticipant,
          isCreatedByUser: messageThread.isCreatedByUser,
        });
      } else {
      }
    });
  };

  /* Call backend service for read message participant List */
  viewMessageHistoryDetails = (e, messageId) => {
    let id = messageId;

    const postObject = {
      header: this.header,
      message: {
        id: id,
      },
    };
    FetchServerData.callPostService('messagems/readMessageUsers', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let historyDetails = output.data;

        this.setState({
          historyDetailsModal: true,
          historyDetails: historyDetails,
        });
      } else {
      }
    });
  };

  /* show messaging thread participant List */
  showParticipantList() {
    const colours = ['#F39C12', '#A569BD', '#2ECC71', '#EC7063', '#5DADE2'];

    this.state.participantMemberList.map((data) => {
      let fullName = data.participantName;
      if (this.memberNamesList.includes(fullName) === false) {
        this.memberNamesList.push(fullName);
        store.dispatch(memberListAction.setMemberList(this.memberNamesList));
      }
    });

    let participantList = this.state.participantMemberList.map((member, index) => {
      return (
        <>{Utils.getMemberName(member.participantName, this.memberNamesList.indexOf(member.participantName) % 26)}</>
      );
    });
    return participantList;
  }

  handleClick = (event) => this.setState({ anchorEl: event.currentTarget });
  handleClose = () => this.setState({ anchorEl: null });

  /* Read message participant List */
  showReadMessageParticipantList() {
    this.state.historyDetails.map((data) => {
      let fullName = data.name;
      if (this.memberNamesList.includes(fullName) === false) {
        this.memberNamesList.push(fullName);
        store.dispatch(memberListAction.setMemberList(this.memberNamesList));
      }
    });

    let participantList = this.state.historyDetails.map((member, index) => {
      return (
        <>
          <List
            style={{
              backgroundColor: index % 2 === 0 ? '#01c770' : '#00aded',
              paddingTop: '0em',
              borderBottom: '0px',
            }}
          >
            <ListItem style={{ padding: '1em' }}>
              <ListItemAvatar>
                {Utils.getMemberName(member.name, this.memberNamesList.indexOf(member.name) % 26)}
              </ListItemAvatar>
              <span style={{ color: '#fff', padding: '1em' }}>{member.name}</span>{' '}
            </ListItem>{' '}
          </List>
          <Divider style={{ borderBottom: '8px solid #fff' }} />
        </>
      );
    });
    return participantList;
  }

  render() {
    this.messageListRef = React.createRef();
    if (this.state.render === false) {
      return '';
    }
    if (this.state.action === 'create') {
      return this.createAppointmentNote();
    } else if (this.state.action === 'edit') {
      return this.editAppointmentNote();
    } else if (this.state.action === 'PrintPreviewNote') {
      return this.viewPrintPreviewAppointmentNote();
    } else {
      return this.viewAppointmentNote();
    }
  }

  createAppointmentNote() {
    return (
      <Card
        style={{
          backgroundColor: '#FCF3CF',
          marginBottom: ' 1em !important',
        }}
      >
        <CardHeader style={{ marginTop: ' -0.8em' }}>
          <Row></Row>
        </CardHeader>
        <CardBody style={{ marginBottom: '-9px', marginTop: '-1.2em' }}>
          <Row style={{ padding: '10px' }}>
            <Col md={6}>
              <TextField
                fullWidth
                variant="outlined"
                id={'shortDescription' + this.state.index}
                label="Title"
                value={this.state.title}
                inputprops={{ maxLength: 256, background: '#fff' }}
                onChange={this.handleTitleChange.bind(this)}
                name="name"
                type="text"
                className="textField"
                style={{ background: '#fff' }}
              />
            </Col>
            <Col md={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="occurrence-label">Occurrence</InputLabel>
                <Select
                  // labelId="occurrence-label"
                  id={'occurrence' + this.state.index}
                  value={this.state.occurrence}
                  label="occurrence"
                  required
                  style={{ background: '#fff' }}
                  onChange={(e) => {
                    this.handleTaskOccurrenceChange(e);
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <CreateIcon />
                    </InputAdornment>
                  }
                >
                  {this.taskOccuranceList.map((event, keyIndex) => {
                    return (
                      <MenuItem key={keyIndex} value={event.occurance}>
                        {event.occurance + ' For ' + this.props.data.appointmentOccurance}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Col>
          </Row>

          <Row style={{ padding: '10px' }}>
            <Col md={12}>
              <TextareaAutosize
                aria-label="minimum height"
                style={{ width: '100%', padding: '10px' }}
                minRows={3}
                required
                id={'note' + this.state.index}
                value={this.state.note}
                name="note"
                inputprops={{ maxLength: 2048 }}
                onChange={this.handleDescriptionChange.bind(this)}
                // onPressEnter={this.handleDescriptionChange.bind(this)}
                placeholder="Enter Note Details"
              />
            </Col>
          </Row>

          <Row>
            <Row style={{ marginTop: '0.5em', marginLeft: 'auto', marginRight: 'auto' }}>
              <i
                className="fa fa-check"
                onClick={this.addNote.bind(this)}
                id="appointmentNoteButton"
                aria-hidden="true"
              ></i>
              &nbsp;
              <i
                className="fa fa-remove"
                onClick={this.appointmentNoteRemove.bind(this)}
                aria-hidden="true"
                id="appointmentNoteButton"
              ></i>
            </Row>
          </Row>
        </CardBody>
      </Card>
    );
  }

  editAppointmentNote() {
    return (
      <Card
        style={{
          backgroundColor: '#FCF3CF',
          marginBottom: ' 1em !important',
        }}
      >
        <CardHeader style={{ marginTop: ' -0.8em' }}>
          <Row></Row>
        </CardHeader>
        <CardBody style={{ marginBottom: '-9px', marginTop: '-1.2em' }}>
          <Row style={{ padding: '10px' }}>
            <Col md={6}>
              <TextField
                fullWidth
                variant="outlined"
                id={'shortDescription' + this.state.index}
                label="Title"
                value={this.state.title}
                inputprops={{ maxLength: 256, background: '#fff' }}
                onChange={this.handleTitleChange.bind(this)}
                name="name"
                type="text"
                className="textField"
                style={{ background: '#fff' }}
              />
            </Col>
            <Col md={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel id="occurrence-label">Occurrence</InputLabel>
                <Select
                  // labelId="occurrence-label"
                  id={'occurrence' + this.state.index}
                  style={{ background: '#fff' }}
                  value={this.state.occurrence}
                  label="occurrence"
                  required
                  onChange={(e) => {
                    this.handleTaskOccurrenceChange(e);
                  }}
                  startAdornment={
                    <InputAdornment position="start">
                      <CreateIcon />
                    </InputAdornment>
                  }
                >
                  {this.taskOccuranceList.map((event, keyIndex) => {
                    return (
                      <MenuItem key={keyIndex} value={event.occurance}>
                        {event.occurance + ' For ' + this.state.appointmentOccurance}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Col>
          </Row>

          <Row style={{ padding: '10px' }}>
            <Col md={12}>
              <TextareaAutosize
                aria-label="minimum height"
                style={{ width: '100%', padding: '10px' }}
                minRows={3}
                required
                id={'note' + this.state.index}
                value={this.state.note}
                name="note"
                inputprops={{ maxLength: 2048 }}
                onChange={this.handleDescriptionChange.bind(this)}
                // onPressEnter={this.handleDescriptionChange.bind(this)}
                placeholder="Enter Note Details"
              />
            </Col>
          </Row>

          <Row>
            <Row style={{ marginTop: '0.5em', marginLeft: 'auto', marginRight: 'auto' }}>
              <i
                className="fa fa-check"
                onClick={this.updateAppointmentNotes.bind(this)}
                id="appointmentNoteButton"
                aria-hidden="true"
              ></i>
              &nbsp;
              <i
                className="fa fa-remove"
                onClick={this.backToViewAction.bind(this)}
                aria-hidden="true"
                id="appointmentNoteButton"
              ></i>
            </Row>
          </Row>
        </CardBody>
      </Card>
    );
  }

  viewAppointmentNote() {
    this.state.id = this.props.data.id;

    let messageList = [];

    /* Separate Out sender and receiver message by matching the senderId with message list senderId */
    this.state.messageList.forEach((message, index) => {
      if (this.state.senderId === message.sender) {
        messageList.push(
          <div className="message-box-holder">
            <div className="message-box">
              <div style={{ fontSize: '1.2em' }}>
                <pre className="view_AppointmentCommunication">{message.body}</pre>
              </div>

              <span className="textMsg" style={{ float: 'right', marginTop: '0.2em' }}>
                {Utils.getUtcToLocalDate(message.createdOn)}
                {message.status !== null && message.status === 'SENT' ? (
                  <>
                    <DoneIcon style={{ fontSize: 'small', marginLeft: '0.5em' }} />
                  </>
                ) : (
                  <>
                    <DoneAllIcon style={{ fontSize: 'small', color: '#3498DB', marginLeft: '0.5em' }} />
                    <span style={{ fontSize: '0.7em', float: 'right', marginTop: '0.2em', marginLeft: '0.5em' }}>
                      <i
                        className="fa fa-history"
                        title="Seen By"
                        aria-hidden="true"
                        style={{ fontSize: 'small' }}
                        onClick={(e) => this.viewMessageHistoryDetails(e, message.id)}
                      ></i>
                    </span>
                  </>
                )}
              </span>
            </div>
          </div>
        );
      } else {
        messageList.push(
          <div className="message-box-holder">
            <div className="message-box message-partner">
              <div className="message-sender">
                <a href="#">{message.senderName}</a>
              </div>
              <div>{message.body}</div>
              <span className="textMsg">{Utils.getUtcToLocalDate(message.createdOn)}</span>
            </div>
          </div>
        );
      }
    });
    return (
      <>
        <div className="note_details_view">
          <div className="panel panel-yellow">
            <div className="panel-heading">
              <Row>
                <Col md={7}>
                  <span style={{ color: '#566573', padding: '5px 3px' }}>
                    <strong>{this.state.title}</strong>
                  </span>
                </Col>

                <>
                  <Col md={5}>
                    {this.state.isViewButtonHide && (
                      <>
                        {this.state.getCreatePermission === true ? (
                          <>
                            {this.state.hasMsgThread === true ? (
                              <></>
                            ) : (
                              <Tooltip title="Create Message Thread">
                                <AddCommentIcon
                                  className="appCommunication"
                                  style={{
                                    float: 'right',
                                    fontSize: 'small',
                                    height: '2em',
                                    width: '2em',
                                    background: 'rgb(19, 98, 105)',
                                    cursor: 'pointer',
                                    color: 'white',
                                    border: '2px solid #edf0f1',
                                    borderRadius: '50%',
                                    padding: '2px',
                                    marginLeft: '0.3em',
                                    marginTop: '-0.1em',
                                  }}
                                  onClick={(e) => this.raiseAppointmentNoteRequest(e, this.props.data)}
                                  id="iconButton"
                                ></AddCommentIcon>
                              </Tooltip>
                            )}
                          </>
                        ) : (
                          <></>
                        )}
                        {/* )} */}
                        {this.state.isMsgThreadParticipant === true &&
                          (this.state.hasMsgThread === true ? (
                            <>
                              {this.state.unreadMessageCountShow === true ? (
                                <span className="unreadMessageCount" title="Unread Message Count ">
                                  <p style={{ color: '#fff' }}>{this.state.unreadMessageCount}</p>
                                </span>
                              ) : (
                                <></>
                              )}
                              <Tooltip title="Chat">
                                <QuestionAnswerIcon
                                  className="appCommunication"
                                  style={{
                                    float: 'right',
                                    fontSize: 'small',
                                    height: '2em',
                                    width: '2em',
                                    background: 'rgb(19, 98, 105)',
                                    cursor: 'pointer',
                                    color: 'white',
                                    border: '2px solid #edf0f1',
                                    borderRadius: '50%',
                                    padding: '2px',
                                    marginLeft: '0.3em',
                                    marginTop: '-0.1em',
                                  }}
                                  id="iconButton"
                                  onClick={(e) =>
                                    this.viewNoteConversation(e, this.state.isNoteConversation, this.state.id)
                                  }
                                />
                              </Tooltip>
                            </>
                          ) : (
                            <></>
                          ))}
                        {this.state.getUpdatePermission === true && (
                          <EditButton
                            style={{
                              float: 'right',
                              fontSize: 'small',
                              height: '2em',
                              width: '2em',
                              background: 'rgb(19, 98, 105)',
                              cursor: 'pointer',
                              color: 'white',
                              border: '2px solid #edf0f1',
                              borderRadius: '50%',
                              padding: '2px',
                            }}
                            onClick={(e) => this.editNote(e, this.props.data.id)}
                            id="iconButton"
                          />
                        )}
                        {this.state.hasMsgThread === true ? (
                          <></>
                        ) : (
                          <>
                            {this.state.getDeletePermission === true && (
                              <>
                                <DeleteButton
                                  domainObject="Appointment Note"
                                  onClick={(e) => this.deleteAppointmentNote(e, this.props.data.id)}
                                />
                              </>
                            )}
                          </>
                        )}
                      </>
                    )}
                  </Col>
                </>
              </Row>
            </div>
            <div className="panel-body">
              <pre
                className="view_AppointmentNote"
                style={{ color: '#000', padding: '9px 14px', textAlign: 'justify' }}
              >
                {' '}
                {this.state.note}
              </pre>
            </div>
            <div className="panel-footer" style={{ padding: '0.2em' }}>
              <Row style={{ display: 'inline-flex', width: '100%' }}>
                <Col md={6}>
                  <label
                    style={{
                      fontWeight: 'normal',
                      color: '#888888',

                      fontSize: 'xx-small',
                      textAlign: 'left',
                    }}
                    className="createdBy"
                  >
                    {this.props.data.createdByName && 'Created by ' + this.props.data.createdByName}
                    {this.props.data.createdOn && ' on ' + Utils.getUtcToLocalDate(this.props.data.createdOn)}
                  </label>
                </Col>
                <Col md={6} style={{ textAlign: 'right' }}>
                  <label
                    style={{
                      fontWeight: 'normal',
                      color: '#888888',
                      fontSize: 'xx-small',
                      textAlign: 'right',
                      padding: '0',
                    }}
                  >
                    {this.state.occurrence && '' + this.state.occurrence}
                  </label>
                </Col>
              </Row>
            </div>
            {this.state.isNoteConversation && (
              <Row style={{ marginTop: '1em' }}>
                <Col md={12} style={{ padding: '1em 2em' }}>
                  <div className="chatbox1 group-chat">
                    <div className="chatbox-top">
                      <div className="chat-group-name">
                        <span className="status away"></span>
                        <span style={{ textTransform: 'capitalize', wordBreak: 'break-all' }}>
                          {this.state.subject}
                        </span>

                        {this.state.isCreatedByUser === true ? (
                          <span style={{ float: 'right', marginRight: '22px', cursor: 'pointer', marginTop: '4px' }}>
                            <Tooltip title="Add/Delete Participant">
                              <PersonAddIcon onClick={(e) => this.addParticipantRequest(e, this.props.data)} />
                            </Tooltip>
                          </span>
                        ) : (
                          <></>
                        )}

                        <span style={{ float: 'right', marginRight: '22px' }}>{this.showParticipantList()}</span>
                      </div>
                    </div>

                    <div className="chat-messages">
                      <ScrollToBottom className="ROOT_CSS">{messageList}</ScrollToBottom>
                    </div>
                    {this.state.getUpdatePermission === true ? (
                      <div className="chat-input-holder">
                        <textarea
                          className="chat-input"
                          value={this.state.sendText}
                          onChange={this.handleSendTextChange.bind(this)}
                          maxLength="512"
                        ></textarea>
                        <button
                          type="submit"
                          style={{ background: this.state.sendText === '' ? 'rgb(75 114 130)' : '#005476' }}
                          disabled={!this.state.sendText}
                          value="Send"
                          className="message-send"
                          onClick={(e) => this.sendMessage()}
                        >
                          <SendIcon></SendIcon>
                        </button>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </div>
        {/* create Modal for Add Message Thread */}
        <Modal isOpen={this.state.addMessageModal} toggle={this.toggle} size="lg">
          <ModalHeader toggle={this.toggle}>
            <b>New Communication Thread</b>
          </ModalHeader>
          <ModalBody>
            <Row style={{ marginTop: '-1em' }}>
              <Col md={8}>
                <TextField
                  margin="normal"
                  variant="outlined"
                  required
                  fullWidth
                  id="subject"
                  inputprops={{ maxLength: 64 }}
                  onChange={this.handleSubjectChange.bind(this)}
                  label="Subject"
                  name="subject"
                  autoFocus
                  value={this.state.subject}
                />
              </Col>

              <Col md={6}></Col>
            </Row>
            <Row>
              <Col md={12}>
                <MaterialTable
                  title="Caregivers' List "
                  icons={UiUtils.getMaterialTableIcons()}
                  data={this.state.noteMemberList}
                  columns={[
                    { title: '#', render: (rowData) => rowData.tableData.id + 1, width: '5%' },
                    { title: 'Name', field: 'name' },
                    {
                      title: 'Select',
                      field: 'imageUrl',
                      width: '10%',
                      render: (rowData) => (
                        <input
                          style={{ height: 18, width: 18, marginLeft: 10 }}
                          type="checkbox"
                          defaultChecked={rowData.checked}
                          name="checkedSacCode"
                          onChange={(e) => this.selectedParticipant(e, rowData, 'member')}
                        />
                      ),
                    },
                  ]}
                  localization={{
                    body: {
                      emptyDataSourceMessage: 'Please add caregivers to get started',
                    },
                  }}
                  options={{
                    sorting: true,
                    actionsColumnIndex: -1,
                    pageSize: this.state.pageSize,
                    paging: this.state.isPaging,
                    headerStyle: { backgroundColor: '#1ABC9C', fontWeight: 'bold', color: '#fff', align: 'left' },
                    rowStyle: {
                      height: '2em',
                      textTransform: 'capitalize',
                    },
                  }}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div style={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                size="small"
                style={{ background: '#ef6603', color: '#fff' }}
                onClick={(e) => this.addMessage()}
                type="submit"
              >
                Start Messaging
              </Button>
              &nbsp;&nbsp;
              <CancelIconButton onClick={(e) => this.toggle()} />
            </div>
          </ModalFooter>
        </Modal>
        <Modal isOpen={this.state.addParticipateModal} toggle={this.addNewParticipateToggle} size="lg">
          <ModalHeader toggle={this.addNewParticipateToggle}>
            <b>Add New Participate</b>
          </ModalHeader>
          <ModalBody>
            <Row>
              <Col md={12}>
                <MaterialTable
                  title="Caregivers' List "
                  icons={UiUtils.getMaterialTableIcons()}
                  data={this.state.noteMemberList}
                  columns={[
                    { title: '#', render: (rowData) => rowData.tableData.id + 1, width: '5%' },
                    { title: 'Name', field: 'name' },
                    {
                      title: 'Select',
                      field: 'imageUrl',
                      width: '10%',
                      render: (rowData) => (
                        <input
                          style={{ height: 18, width: 18, marginLeft: 10 }}
                          type="checkbox"
                          defaultChecked={rowData.checked}
                          name="checkedSacCode"
                          onChange={(e) => this.selectedNewParticipant(e, rowData, 'member')}
                        />
                      ),
                    },
                  ]}
                  options={{
                    sorting: true,
                    actionsColumnIndex: -1,
                    pageSize: this.state.pageSize,
                    paging: this.state.isPaging,
                    headerStyle: { backgroundColor: '#1ABC9C', fontWeight: 'bold', color: '#fff', align: 'left' },
                    rowStyle: {
                      height: '2em',
                      textTransform: 'capitalize',
                    },
                  }}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div style={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                size="small"
                style={{ background: '#ef6603', color: '#fff' }}
                onClick={(e) => this.addNewParticipateInBackend()}
                type="submit"
              >
                Update Participant
              </Button>
              &nbsp;&nbsp;
              <CancelIconButton onClick={(e) => this.addNewParticipateToggle()} />
            </div>
          </ModalFooter>
        </Modal>
        {/* create Modal for  message history details */}
        {this.state.historyDetails !== null && this.state.historyDetails.length > 0 ? (
          <Modal isOpen={this.state.historyDetailsModal} toggle={this.historyMessageToggle} size="sm">
            <ModalHeader toggle={this.historyMessageToggle}>Seen by</ModalHeader>
            <ModalBody>
              <Row>
                <Col md={12}>{this.showReadMessageParticipantList()}</Col>
              </Row>
            </ModalBody>
          </Modal>
        ) : (
          <>
            <Modal isOpen={this.state.historyDetailsModal} toggle={this.historyMessageToggle} size="sm">
              <ModalHeader toggle={this.historyMessageToggle}>Nobody Seen </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>{this.showReadMessageParticipantList()}</Col>
                </Row>
              </ModalBody>
            </Modal>{' '}
          </>
        )}
      </>
    );
  }

  /* Print Preview  Functionality*/
  viewPrintPreviewAppointmentNote() {
    let messageList = [];
    /* Separate Out sender and receiver message by matching the senderId with message list senderId */
    this.state.messageList.forEach((message, index) => {
      if (this.state.senderId === message.sender) {
        messageList.push(
          <div className="message-box-holder">
            <div className="message-box">
              <div style={{ fontSize: '1.2em' }}>
                <pre className="view_AppointmentCommunication">{message.body}</pre>
              </div>

              <span className="textMsg" style={{ float: 'right', marginTop: '0.2em' }}>
                {Utils.getUtcToLocalDate(message.createdOn)}
                {message.status !== null && message.status === 'SENT' ? (
                  <>
                    <DoneIcon style={{ fontSize: 'small', marginLeft: '0.5em' }} />
                  </>
                ) : (
                  <>
                    <DoneAllIcon style={{ fontSize: 'small', color: '#3498DB', marginLeft: '0.5em' }} />
                    <span style={{ fontSize: '0.7em', float: 'right', marginTop: '0.2em', marginLeft: '0.5em' }}>
                      <i
                        className="fa fa-history"
                        title="Seen By"
                        aria-hidden="true"
                        style={{ fontSize: 'small' }}
                        onClick={(e) => this.viewMessageHistoryDetails(e, message.id)}
                      ></i>
                    </span>
                  </>
                )}
              </span>
            </div>
          </div>
        );
      } else {
        messageList.push(
          <div className="message-box-holder">
            <div className="message-box message-partner">
              <div className="message-sender">
                <a href="#">{message.senderName}</a>
              </div>
              <div>{message.body}</div>
              <span className="textMsg">{Utils.getUtcToLocalDate(message.createdOn)}</span>
            </div>
          </div>
        );
      }
    });

    return (
      <>
        <div className="note_details_view">
          <div className="panel panel-yellow">
            <div className="panel-heading">
              <Row>
                <Col md={7}>
                  <span style={{ color: '#566573', padding: '5px 0px' }}>
                    <strong>{this.state.title}</strong>
                  </span>
                </Col>

                <>
                  <Col md={5}></Col>
                </>
              </Row>
            </div>
            <div className="panel-body">
              <pre
                className="view_AppointmentNote"
                style={{ color: '#000', padding: '9px 14px', textAlign: 'justify' }}
              >
                {' '}
                {this.state.note}
              </pre>
            </div>
            <div className="panel-footer">
              <label
                className="createdBy"
                style={{
                  fontWeight: 'normal',
                  color: '#888888',
                  width: 'auto',
                  fontSize: 'xx-small',
                  textAlign: 'left',
                  padding: '0',
                  margin: '0',
                  marginLeft: '0em',
                }}
              >
                {this.state.createdBy && 'Created by ' + this.state.createdBy}
                {this.state.createdDate && ' on ' + Utils.getUtcToLocalDate(this.state.createdDate)}
              </label>
            </div>
            {this.state.isNoteConversation && (
              <Row style={{ marginTop: '1em' }}>
                <Col md={12} style={{ padding: '1em 2em' }}>
                  <div className="chatbox1 group-chat">
                    <div className="chatbox-top">
                      <div className="chat-group-name">
                        <span className="status away"></span>
                        <span style={{ textTransform: 'capitalize', wordBreak: 'break-all' }}>
                          {this.state.subject}
                        </span>
                        <span style={{ float: 'right', marginRight: '22px' }}>
                          <Tooltip title="Participant List">
                            <PeopleAltIcon onClick={this.handleClick} />
                          </Tooltip>
                          <Menu
                            id="simple-menu"
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={Boolean(this.state.anchorEl)}
                            onClose={this.handleClose}
                            anchorOrigin={{
                              vertical: 'bottom',
                              horizontal: 'center',
                            }}
                            PaperProps={{
                              style: {
                                marginLeft: '-73px',
                              },
                            }}
                          >
                            <table className="table table-striped ">
                              <tbody>{this.showParticipantList()}</tbody>
                            </table>
                          </Menu>
                        </span>
                      </div>
                    </div>

                    <div className="chat-messages">
                      <ScrollToBottom className="ROOT_CSS">{messageList}</ScrollToBottom>
                    </div>
                    {this.state.getUpdatePermission === true && (
                      <div className="chat-input-holder">
                        <textarea
                          className="chat-input"
                          value={this.state.sendText}
                          onChange={this.handleSendTextChange.bind(this)}
                          maxLength="512"
                        ></textarea>
                        <button
                          type="submit"
                          style={{ background: this.state.sendText === '' ? 'rgb(75 114 130)' : '#005476' }}
                          disabled={!this.state.sendText}
                          value="Send"
                          className="message-send"
                          onClick={(e) => this.sendMessage()}
                        >
                          <SendIcon></SendIcon>
                        </button>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            )}
          </div>
        </div>
        {/* create Modal for Add Message Thread */}
        <Modal isOpen={this.state.addMessageModal} toggle={this.toggle} size="lg">
          <ModalHeader toggle={this.toggle}>
            <b>New Communication Thread</b>
          </ModalHeader>
          <ModalBody>
            <Row style={{ marginTop: '-1em' }}>
              <Col md={8}>
                <TextField
                  margin="normal"
                  variant="outlined"
                  required
                  fullWidth
                  id="subject"
                  inputprops={{ maxLength: 64 }}
                  onChange={this.handleSubjectChange.bind(this)}
                  label="Subject"
                  name="subject"
                  autoFocus
                  value={this.state.subject}
                />
              </Col>

              <Col md={6}></Col>
            </Row>
            <Row>
              <Col md={12}>
                <MaterialTable
                  title="Caregivers' List "
                  icons={UiUtils.getMaterialTableIcons()}
                  data={this.state.noteMemberList}
                  columns={[
                    { title: '#', render: (rowData) => rowData.tableData.id + 1, width: '5%' },
                    { title: 'Name', field: 'name' },
                    {
                      title: 'Select',
                      field: 'imageUrl',
                      width: '10%',
                      render: (rowData) => (
                        <input
                          style={{ height: 18, width: 18, marginLeft: 10 }}
                          type="checkbox"
                          defaultChecked={rowData.checked}
                          name="checkedSacCode"
                          onChange={(e) => this.selectedParticipant(e, rowData, 'member')}
                        />
                      ),
                    },
                  ]}
                  options={{
                    sorting: true,
                    actionsColumnIndex: -1,
                    pageSize: this.state.pageSize,
                    paging: this.state.isPaging,
                    headerStyle: { backgroundColor: '#1ABC9C', fontWeight: 'bold', color: '#fff', align: 'left' },
                    rowStyle: {
                      height: '2em',
                      textTransform: 'capitalize',
                    },
                  }}
                />
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <div style={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                size="small"
                style={{ background: '#ef6603', color: '#fff' }}
                onClick={(e) => this.addMessage()}
                type="submit"
              >
                Start Messaging
              </Button>
              &nbsp;&nbsp;
              <CancelIconButton onClick={(e) => this.toggle()} />
            </div>
          </ModalFooter>
        </Modal>

        {/* create Modal for  message history details */}
        {this.state.historyDetails !== null && this.state.historyDetails.length > 0 ? (
          <Modal isOpen={this.state.historyDetailsModal} toggle={this.historyMessageToggle} size="sm">
            <ModalHeader toggle={this.historyMessageToggle}>Seen by</ModalHeader>
            <ModalBody>
              <Row>
                <Col md={12}>{this.showReadMessageParticipantList()}</Col>
              </Row>
            </ModalBody>
          </Modal>
        ) : (
          <>
            <Modal isOpen={this.state.historyDetailsModal} toggle={this.historyMessageToggle} size="sm">
              <ModalHeader toggle={this.historyMessageToggle}>Nobody Seen </ModalHeader>
              <ModalBody>
                <Row>
                  <Col md={12}>{this.showReadMessageParticipantList()}</Col>
                </Row>
              </ModalBody>
            </Modal>{' '}
          </>
        )}
      </>
    );
  }
}
