import { CLEAR_SPONSOR_DATA, GET_SPONSOR_DATA, SET_SPONSOR_DATA } from './sponsorDetailsType';
const initialState = {
  adminSponsorDetails: {},
};

const sponsorDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SPONSOR_DATA:
      return {
        ...state,
      };
    case SET_SPONSOR_DATA:
      return {
        ...state,
        adminSponsorDetails: action.payload,
      };

    case CLEAR_SPONSOR_DATA:
      return {
        ...state,
        adminSponsorDetails: null,
      };
    default:
      return state;
  }
};

export default sponsorDetailsReducer;
