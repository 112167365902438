import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import CreateIcon from '@material-ui/icons/Create';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
// import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import fccPageStateAction from '../../../redux/fccPageState/fccPageStateAction';
import store from '../../../redux/store';
import CancelIconButton from '../buttons/CancelIconButton';
import CreateButton from '../buttons/CreateButton';
import Location from '../location/Location';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
import PtsAlert from '../ptsAlert/PtsAlert';
import Reminder from '../reminder/Reminder';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { ThemeProvider } from '@material-ui/styles';
import { createMuiTheme } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import { DatePicker, MuiPickersUtilsProvider, DateTimePicker, LocalizationProvider } from '@material-ui/pickers';
export default class Appointment extends Component {
  appointmentTypeListArray = store.getState().staticData.appointmentTypeList;
  appointmentPurposeListData = store.getState().staticData.appointmentPurposeList;
  memberDetails = store.getState().memberData.memberData;
  memberName = store.getState().memberData.memberData.firstname + ' ' + store.getState().memberData.memberData.lastname;
  breadCrumb = [
    { pageid: '/memberDetails', uiname: this.memberName },
    { pageid: '/memberAppointment', uiname: 'Appointment List' },
    { pageid: '', uiname: 'Member Appointment' },
  ];
  header = store.getState().header.header;
  originalState = null;
  appointmentReminderRefList = [];
  constructor(props) {
    super(props);

    let action = props.action;
    let title = '';
    let appointmentId = '';
    let pageId = '';
    let createSimilarAppointmentDetails = {};
    let appointmentType = '';
    let isDisable = false;
    let doctorDetails = {};
    let facility = {};

    if (!appointmentId && props.history && props.history.location && props.history.location.state) {
      createSimilarAppointmentDetails = props.history.location.state.createSimilarAppointmentDetails;

      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
      title = props.history.location.state.title;
      doctorDetails = props.history.location.state.doctor;
      facility = props.history.location.state.facility;
    }
    if (pageId === 'viewDoctorAppointment') {
      appointmentType = this.appointmentTypeListArray[0].type;

      isDisable = true;
      if (doctorDetails.id) {
        this.getNewDoctorDetails(doctorDetails.id);
      }
    } else if (pageId === 'viewMemberFacilitys') {
      appointmentType = this.appointmentTypeListArray[1].type;

      isDisable = true;
      if (facility.id) {
        this.getNewFacilityDetails(facility.id);
      }
    } else {
      let appointmentId = createSimilarAppointmentDetails.id;
      if (null !== appointmentId && appointmentId > 0) {
      }
    }
    let newStartDate = '';
    if (createSimilarAppointmentDetails) {
      newStartDate = Utils.convertUTCDateToUserTimeZoneDate(createSimilarAppointmentDetails.date);
    } else {
      newStartDate = new Date();
    }
    if (pageId === 'viewDoctorAppointment') {
      this.state = {
        isDoctor: true,
        isFacility: false,
        appointmentType: appointmentType,
        startDate: new Date(newStartDate),
        appoiPurpose: '',

        newDoctor: {},
        facilityLocation: {},
        newFacilityLocation: {},
        description: '',
        reminderItems: [],
        action: action,
        render: true,
        doctorLocationList: [],
        facilityLocationList: [],
        selectedDoctorLocation: {},
        title: title,
        pageId: pageId,
        isDisable: isDisable,
        appointmentTypeListArray: this.appointmentTypeListArray,
        isDoctorLocation: false,
        facility: facility,
        doctorDetails: doctorDetails,
        sameDateAppointmentWindow: false,
      };
    } else if (pageId === 'viewMemberFacilitys') {
      this.state = {
        isDoctor: false,
        isFacility: true,
        appointmentType: appointmentType,
        startDate: new Date(newStartDate),
        appoiPurpose: '',
        doctor: {},
        newDoctor: '',
        facilityLocation: {},
        newFacilityLocation: {},
        description: '',
        reminderItems: [],
        action: action,
        render: true,
        doctorLocationList: [],
        facilityLocationList: [],
        selectedDoctorLocation: {},
        title: title,
        pageId: pageId,
        isDisable: isDisable,
        appointmentTypeListArray: this.appointmentTypeListArray,
        isDoctorLocation: false,
        facility: facility,
        doctorDetails: doctorDetails,
        sameDateAppointmentWindow: false,
      };
    } else {
      if (createSimilarAppointmentDetails.type.type === 'Doctor Appointment') {
        this.state = {
          id: createSimilarAppointmentDetails.id,
          isDoctor: true,
          isFacility: false,
          appointmentType: createSimilarAppointmentDetails.type.type,
          startDate: new Date(newStartDate),
          appoiPurpose: createSimilarAppointmentDetails.purpose,
          doctor: createSimilarAppointmentDetails.doctorLocation.doctor,
          newDoctor: createSimilarAppointmentDetails.doctorLocation.doctor,
          facilityLocation: {},
          newFacilityLocation: {},
          description: '',
          reminderItems: [],
          action: action,
          render: true,
          doctorLocationList: [],
          facilityLocationList: [],
          selectedDoctorLocation: {},
          title: title,
          pageId: pageId,
          isDisable: isDisable,
          appointmentTypeListArray: this.appointmentTypeListArray,
          isDoctorLocation: false,
          facility: facility,
          doctorDetails: doctorDetails,
          sameDateAppointmentWindow: false,
        };
        this.getDoctorDetails(
          createSimilarAppointmentDetails.doctorLocation.doctor.id,
          createSimilarAppointmentDetails.doctorLocation.id
        );
      } else {
        this.state = {
          id: createSimilarAppointmentDetails.id,
          isDoctor: false,
          isFacility: true,
          appointmentType: createSimilarAppointmentDetails.type.type,
          startDate: new Date(newStartDate),
          appoiPurpose: createSimilarAppointmentDetails.purpose,
          doctor: {},
          newDoctor: {},
          facilityLocation: createSimilarAppointmentDetails.facilityLocation.facility,
          newFacilityLocation: createSimilarAppointmentDetails.facilityLocation,
          description: '',
          reminderItems: [],
          action: action,
          render: true,
          doctorLocationList: [],
          facilityLocationList: [],
          selectedDoctorLocation: {},
          title: title,
          pageId: pageId,
          isDisable: isDisable,
          appointmentTypeListArray: this.appointmentTypeListArray,
          isDoctorLocation: false,
          facility: facility,
          doctorDetails: doctorDetails,
          sameDateAppointmentWindow: false,
        };
        this.getFacilityDetails(
          createSimilarAppointmentDetails.facilityLocation.facility.id,
          createSimilarAppointmentDetails.facilityLocation.id
        );
      }
    }
    this.originalState = this.state;
  }

  getNewDoctorDetails(doctorId) {
    let id = doctorId;
    const postObject = {
      header: this.header,
      // doctor: {
      //   id: id,
      // },
      doctor: {
        member: {
          id: this.memberDetails.member,
        },
      },
    };

    FetchServerData.callPostService('/appointmentms/getMemberDoctorLocations', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let doctorDetails = output.data;
          let doctorLocationList = output.data;
          if (doctorDetails.length > 0) {
            for (let i = 0; doctorDetails.length; i++) {
              if (doctorDetails[i].doctorId === doctorId) {
                this.setState({
                  render: false,
                  isDoctor: true,
                  isDisable: true,
                  doctor: doctorDetails[i],
                  newDoctor: doctorDetails[i],
                  doctorLocationList: doctorLocationList,
                  selectedDoctorLocation: doctorDetails,
                });
              }
              this.getDoctorLocation(doctorDetails[i]);
            }
          }
        } else {
        }
      })
      .catch((error) => {});
  }
  getDoctorDetails(doctorId, locationId) {
    let id = doctorId;
    const postObject = {
      header: this.header,
      // doctor: {
      //   id: id,
      // },
      doctor: {
        member: {
          id: this.memberDetails.member,
        },
      },
    };

    FetchServerData.callPostService('/appointmentms/getMemberDoctorLocations', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let doctorDetails = output.data;
          let doctorLocationList = output.data;
          let selectedDoctorList = [];
          for (let i = 0; doctorDetails.length; i++) {
            if (doctorDetails[i].locationId === locationId) {
              this.setState({
                render: false,
                isDoctor: true,
                isDisable: true,
                doctor: doctorDetails[i],
                newDoctor: doctorDetails[i],
                doctorLocationList: doctorLocationList,
                selectedDoctorLocation: doctorDetails,
              });
              this.getDoctorLocation(doctorDetails[i]);
            }
          }
        } else {
        }
      })
      .catch((error) => {});
  }
  getDoctorLocation(doctorDetails) {
    const postObject = {
      header: this.header,
      doctorLocation: {
        id: doctorDetails.locationId,
        doctor: {
          id: doctorDetails.doctorId,
        },
      },
    };
    FetchServerData.callPostService('/doctorms/getLocation', postObject).then((output) => {
      if (Utils.equalsIgnoreCase(output.status, 'SUCCESS')) {
        let doctorLocation = output.data;
        this.setState({
          selectedDoctorLocation: doctorLocation,
          render: true,
          isDoctorLocation: true,
        });
      } else if (Utils.equalsIgnoreCase(output.status, 'FAIL')) {
      }
    });
  }
  getNewFacilityDetails(facilityId) {
    let id = facilityId;
    const postObject = {
      header: this.header,
      facility: {
        id: id,
      },
    };

    FetchServerData.callPostService('facilityms/getFacilityLocations', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let facilityDetails = output.data;
          let facilityLocationList = output.data;
          if (facilityDetails.length > 0) {
            for (let i = 0; facilityDetails.length; i++) {
              if (facilityDetails[i].facilityId === facilityId) {
                this.setState({
                  render: false,
                  isFacility: true,
                  isDisable: true,
                  selectedDoctorLocation: facilityDetails,
                  facilityLocationList: facilityLocationList,
                  facilityLocation: facilityDetails[i],
                  newFacilityLocation: facilityDetails[i],
                });
                this.getFacilityLocation(facilityDetails[i]);
              }
            }
          }
        } else {
        }
      })
      .catch((error) => {});
  }
  getFacilityDetails(doctorId, locationId) {
    let id = doctorId;
    const postObject = {
      header: this.header,
      facility: {
        id: id,
      },
    };

    FetchServerData.callPostService('facilityms/getFacilityLocations', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let facilityDetails = output.data;
          let facilityLocationList = output.data;
          for (let i = 0; facilityDetails.length; i++) {
            if (facilityDetails[i].locationId === locationId) {
              this.setState({
                render: false,
                isFacility: true,
                isDisable: true,
                selectedDoctorLocation: facilityDetails,
                facilityLocationList: facilityLocationList,
                facilityLocation: facilityDetails[i],
                newFacilityLocation: facilityDetails[i],
              });
              this.getFacilityLocation(facilityDetails[i]);
            }
          }
        } else {
        }
      })
      .catch((error) => {});
  }
  getFacilityLocation(facilityDetails) {
    const postObject = {
      header: this.header,
      facilityLocation: {
        id: facilityDetails.locationId,
        facility: {
          id: facilityDetails.facilityId,
        },
      },
    };
    FetchServerData.callPostService('/facilityms/getLocation', postObject).then((output) => {
      if (Utils.equalsIgnoreCase(output.status, 'SUCCESS')) {
        let facilityLocation = output.data;

        this.setState({
          selectedDoctorLocation: facilityLocation,
          render: true,
          isDoctorLocation: true,
        });
      } else if (Utils.equalsIgnoreCase(output.status, 'FAIL')) {
      }
    });
  }
  getAppointmentDetails(appointmentData) {
    let viewStartDate = Utils.getUtcToLocalDate(appointmentData.date);

    if (appointmentData.type.type === 'Doctor Appointment') {
      this.setState({
        render: true,
        isDoctor: true,
        isFacility: false,
        viewFacilityCard: false,
        viewDoctorCard: true,
        isAppointmentDoctorCardView: true,
        isAppointmentFacilityCardView: false,
        appointmentDoctor: appointmentData.doctorLocation.doctor,
        doctor: appointmentData.doctorLocation.doctor,
        newDoctor: appointmentData.doctorLocation,
        appoinmentId: appointmentData.id,
        appoiPurpose: appointmentData.purpose,
        appointmentStartDate: viewStartDate,
        appointmentType: appointmentData.type.type,
      });
    } else {
      this.setState({
        render: true,
        isDoctor: false,
        isFacility: true,
        viewFacilityCard: true,
        viewDoctorCard: false,
        isAppointmentDoctorCardView: false,
        isAppointmentFacilityCardView: true,
        appoinmentId: appointmentData.id,
        appointmentStartDate: viewStartDate,
        appointmentType: appointmentData.type.type,
        appoiPurpose: appointmentData.purpose,
        facilityLocation: appointmentData.facilityLocation.facility,
        newFacilityLocation: appointmentData.facilityLocation,
      });
    }
  }

  addReminder(type) {
    if (this.state.startDate === '') {
      PtsAlert.error('Please Enter Date');
    } else {
      let items = this.state.reminderItems;
      let item = {
        action: 'create',
        operation: 'add',
      };
      items.push(item);
      this.appointmentReminderRefList.push(React.createRef());

      this.setState({ reminderItems: items });
    }
  }
  addNewReminder = (reminderData) => {
    for (let i = 0; i < this.state.reminderItems.length; i++) {
      if (
        this.state.reminderItems[i] &&
        reminderData.index === i &&
        this.appointmentReminderRefList[i] &&
        this.appointmentReminderRefList[i].current &&
        this.appointmentReminderRefList[i].current.validateReminder(i)
      ) {
        this.state.reminderItems[i] = reminderData;
        this.appointmentReminderRefList[i].current.state = reminderData;
        this.appointmentReminderRefList[i].current.state.action = 'view';
        this.setState({ render: true });
      }
    }
  };

  reminderItemDeleted(item) {
    let items = [];
    if (item.operation && 'update' === item.operation) {
      /* Existing Item Deleted, Mark it deleted */
      let index = item.index;

      for (let i = 0; i < this.state.reminderItems.length; i++) {
        let item = this.state.reminderItems[i];
        if (this.state.reminderItems[i].operation === 'update' && index === i) {
          item.operation = 'delete';
        }

        items.push(item);
      }

      this.setState({ reminderItems: items });
    }
    if (item.operation && 'add' === item.operation) {
      /* Added Item Deleted, Remove from list */
      let index = item.index;
      for (let i = 0; i < this.state.reminderItems.length; i++) {
        if (this.state.reminderItems[i].operation === 'add' && index === i) {
          this.appointmentReminderRefList.splice(i, 1);
          // continue;
        } else {
          items.push(this.state.reminderItems[i]);
        }
      }
      this.setState({ reminderItems: items });
    }
  }

  updateReminder = (reminderData) => {
    for (let i = 0; i < this.state.reminderItems.length; i++) {
      if (
        this.state.reminderItems[i] &&
        reminderData.index === i &&
        this.appointmentReminderRefList[i] &&
        this.appointmentReminderRefList[i].current &&
        this.appointmentReminderRefList[i].current.validateReminder()
      ) {
        this.state.reminderItems[i] = reminderData;
        this.appointmentReminderRefList[i].current.state = reminderData;
        this.appointmentReminderRefList[i].current.state.action = 'view';
        this.setState({ render: true });
      }
    }
  };

  handleAppointmentTypeChange(e) {
    if (e.target.value === 'Doctor Appointment') {
      this.setState({
        isDoctor: true,
        isFacility: false,

        appointmentType: e.target.value,
      });
      this.getDoctorLocationList();
    } else {
      this.setState({
        isDoctor: false,
        isFacility: true,
        appointmentType: e.target.value,
      });
      this.getFacilityLocationList();
    }
  }

  handleStartDateChange(data) {
    this.setState({ startDate: data });
  }

  handleAppoiPurposeChange(e) {
    if (e.target.value === 'Other') {
      this.setState({
        isOther: true,
        appoiPurpose: e.target.value,
      });
    } else {
      this.setState({
        isOther: false,
        appoiPurpose: e.target.value,
      });
    }
  }

  handleDoctorChange(doctor) {
    if (doctor.name === 'Add New Doctor') {
      this.props.history.push({
        pathname: '/addDoctor',
        state: { action: 'create', title: 'Add Doctor', pageId: 'memberDoctor', doctorId: '' },
      });
    } else {
      this.setState({ doctor: doctor, isDoctorLocation: false });
      if (doctor) {
        this.getDoctorLocation(doctor);
      }

      this.state.doctor = doctor;
      if (undefined !== this.state.onChange && null !== this.state.onChange) {
        this.state.onChange(this.state);
      }
    }
  }
  // handleDoctorChange(e) {
  //   this.setState({ doctor: e.target.value });
  // }
  handleNewDoctorChange(doctor) {
    if (doctor.name === 'Add New Doctor') {
      this.props.history.push({
        pathname: '/addDoctor',
        state: { action: 'create', title: 'Add Doctor', pageId: 'memberDoctor', doctorId: '' },
      });
    } else {
      this.setState({ newDoctor: doctor, isDoctorLocation: false });
      if (doctor) {
        this.getDoctorLocation(doctor.locationId, doctor.doctorId);
      }

      this.state.newDoctor = doctor;
      if (undefined !== this.state.onChange && null !== this.state.onChange) {
        this.state.onChange(this.state);
      }
    }
  }

  handleFacilityLocationChange(facility) {
    if (facility.name === 'Add New Facility') {
      this.props.history.push({
        pathname: '/addFacility',
        state: { action: 'create', title: 'Add Facility', pageId: 'addmemberFacility', facilityId: '' },
      });
    } else {
      this.setState({ facilityLocation: facility, isDoctorLocation: false });
      if (facility) {
        this.getFacilityLocation(facility);
      }
      this.state.facilityLocation = facility;
      if (undefined !== this.state.onChange && null !== this.state.onChange) {
        this.state.onChange(this.state);
      }
    }
  }
  // handleFacilityLocationChange(e) {
  //   this.setState({ facilityLocation: e.target.value });
  // }
  handleNewFacilityLocationChange(facility) {
    if (facility.name === 'Add New Facility') {
      this.props.history.push({
        pathname: '/addFacility',
        state: { action: 'create', title: 'Add Facility', pageId: 'addmemberFacility', facilityId: '' },
      });
    } else {
      this.setState({ newFacilityLocation: facility, isDoctorLocation: false });
      if (facility) {
        this.getFacilityLocation(facility.locationId, facility.facilityId);
      }
      this.state.newFacilityLocation = facility;
      if (undefined !== this.state.onChange && null !== this.state.onChange) {
        this.state.onChange(this.state);
      }
    }
  }

  handleDescriptionChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 256);
    if (res.length < 256) {
      this.setState({ description: res });
    } else {
      Utils.maxFieldLength(256);
    }
  }

  getDoctorLocationList() {
    const postObject = {
      header: this.header,
      doctor: {
        member: {
          id: this.memberDetails.member,
        },
      },
    };

    FetchServerData.callPostService('doctorms/getMemberDoctorLocations', postObject).then((output) => {
      if (Utils.equalsIgnoreCase(output.status, 'SUCCESS')) {
        let doctorLocationList = output.data;
        if (doctorLocationList !== []) {
          doctorLocationList.unshift({ name: 'Add New Doctor', id: 'Add New Doctor' });
        }
        let doctorLocationListArray = doctorLocationList.reduce((unique, o) => {
          if (!unique.some((obj) => obj.name === o.name && obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []);
        this.setState({
          isDoctor: true,

          isFacility: false,
          doctorLocationList: doctorLocationListArray,
        });
      } else if (Utils.equalsIgnoreCase(output.status, 'FAIL')) {
        if (output.errorCode && Utils.equalsIgnoreCase(output.errorCode, 'DOC_100')) {
          /* doctor is Not created, Go To doctor create Page */
          this.setState({
            isCreateDoctor: true,
            isCreateFacility: false,
          });
        }
      }
    });
  }

  getFacilityLocationList() {
    const postObject = {
      header: this.header,
      facility: {
        member: {
          id: this.memberDetails.member,
        },
      },
    };
    FetchServerData.callPostService('/facilityms/getMemberFacilityLocations/', postObject).then((output) => {
      if (Utils.equalsIgnoreCase(output.status, 'SUCCESS')) {
        let facilityLocationList = output.data;
        if (facilityLocationList !== []) {
          facilityLocationList.unshift({ name: 'Add New Facility', id: 'Add New Facility' });
        }
        let facilityLocationListArray = facilityLocationList.reduce((unique, o) => {
          if (!unique.some((obj) => obj.name === o.name && obj.id === o.id)) {
            unique.push(o);
          }
          return unique;
        }, []);
        this.setState({
          isFacility: true,
          isDoctor: false,

          facilityLocationList: facilityLocationListArray,
        });
      } else if (Utils.equalsIgnoreCase(output.status, 'FAIL')) {
        if (output.errorCode && Utils.equalsIgnoreCase(output.errorCode, 'FOC_100')) {
          /* facility is Not created, Go To facility create Page */
          this.setState({
            isCreateFacility: true,
            isCreateDoctor: false,
          });
        }
      }
    });
  }

  createCancel = () => {
    if (this.state.pageId === 'viewMemberFacilitys') {
      this.props.history.push({
        pathname: '/viewFacility',
        state: {
          action: 'view',
          title: 'Facility Details',
          facilityId: this.state.facility.id,
          pageId: '',
          locationId: '',
        },
      });
    } else if (this.state.pageId === 'viewDoctorAppointment') {
      this.props.history.push({
        pathname: '/viewDoctor',
        state: { action: 'view', title: 'Doctor Details', doctorId: this.state.doctorDetails.id },
      });
    } else {
      this.props.history.push({
        pathname: '/memberAppointment',
      });
    }
  };
  /* add  Appointment Data In Backend */
  addAppointmentInBackend(e) {
    e.preventDefault();
    let id = '';
    let url = '/appointmentms/create';
    if (this.state.pageId === 'rescheduledAppointment') {
      id = this.state.id;
      url = '/appointmentms/reschedule';
    }
    let dateTime = Utils.convertUserTimeZoneDateToUTCDate(this.state.startDate);

    const postObject = {
      header: this.header,
      appointment: {
        date: dateTime,
        id: id,
        description: this.state.description,
        appointmentPreNote: this.state.preDescription,
        appointmentPurposeDescription: this.state.appoiPurposeDes,

        type: {
          type: this.state.appointmentType,
        },
        member: {
          id: this.memberDetails.member,
        },
        frequency: {
          frequency: this.state.frequency,
        },

        facilityLocation: {
          id: this.state.facilityLocation.locationId,
        },
        doctorLocation: {
          id: this.state.doctor.locationId,
        },
        reminderList: this.state.reminderItems,
        purpose: this.state.appoiPurpose,
      },
    };

    FetchServerData.callPostService(url, postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let outputData = output.data;
          let appointmentDetails = outputData.appointmentWithPreviousAppointment.appointment;

          PtsAlert.success(outputData.message);
          let fccPageState = {
            id: appointmentDetails.id,
            action: 'view',
          };
          store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
          this.props.history.push({
            pathname: '/viewMemberAppointment',
            state: {
              appointmentData: outputData,
              pageId: 'createAppointment',
              action: 'view',
            },
          });
        } else if (Utils.equalsIgnoreCase(output.status, 'FAIL')) {
          if (output.errorCode && Utils.equalsIgnoreCase(output.errorCode, 'DuplicateAppointmentEvent')) {
            // PtsAlert.warn(output.message);
            // this.warningMessage(output.message)
            this.setState({
              sameDateAppointmentWindow: true,
              WarningMessage: output.message,
            });
          }
        } else {
        }
      })
      .catch((error) => {});
  }
  addSameAppointmentInBackend(e) {
    e.preventDefault();
    let parentId = localStorage.getItem('parentAppointmentId');

    let dateTime = Utils.convertUserTimeZoneDateToUTCDate(this.state.startDate);

    const postObject = {
      header: this.header,
      appointment: {
        date: dateTime,
        forceCreateOnSameDate: true,
        description: this.state.description,
        appointmentPreNote: this.state.preDescription,
        appointmentPurposeDescription: this.state.appoiPurposeDes,

        type: {
          type: this.state.appointmentType,
        },
        member: {
          id: this.memberDetails.member,
        },
        frequency: {
          frequency: this.state.frequency,
        },

        facilityLocation: {
          id: this.state.facilityLocation.locationId,
        },
        doctorLocation: {
          id: this.state.doctor.locationId,
        },
        parentAppointment: {
          id: parentId,
        },
        reminderList: [],
        purpose: this.state.appoiPurpose,
      },
    };

    FetchServerData.callPostService('/appointmentms/create', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let outputData = output.data;
          let appointmentDetails = outputData.appointmentWithPreviousAppointment.appointment;

          PtsAlert.success(outputData.message);
          let fccPageState = {
            id: appointmentDetails.id,
            action: 'view',
          };
          store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
          this.props.history.push({
            pathname: '/viewMemberAppointment',
            state: {
              appointmentData: outputData,
              pageId: 'createAppointment',
              action: 'view',
            },
          });
          this.setState({
            sameDateAppointmentWindow: false,
          });
        } else {
        }
      })
      .catch((error) => {});
  }
  handleSameDateAppointmentCreateWindowClose = () => {
    this.setState({
      sameDateAppointmentWindow: false,
    });
  };
  addNewAppointmentInBackend(e) {
    e.preventDefault();
    let id = '';
    let url = '/appointmentms/create';
    if (this.state.pageId === 'rescheduledAppointment') {
      id = this.state.id;
      url = '/appointmentms/reschedule';
    }
    let dateTime = Utils.convertUserTimeZoneDateToUTCDate(this.state.startDate);

    const postObject = {
      header: this.header,
      appointment: {
        date: dateTime,
        id: id,
        description: this.state.description,
        appointmentPreNote: this.state.preDescription,
        appointmentPurposeDescription: this.state.appoiPurposeDes,

        type: {
          type: this.state.appointmentType,
        },
        member: {
          id: this.memberDetails.member,
        },
        frequency: {
          frequency: this.state.frequency,
        },

        facilityLocation: {
          id: this.state.newFacilityLocation.locationId,
        },
        doctorLocation: {
          id: this.state.newDoctor.locationId,
        },
        reminderList: this.state.reminderItems,
        purpose: this.state.appoiPurpose,
      },
    };

    FetchServerData.callPostService(url, postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let outputData = output.data;
          let appointmentDetails = outputData.appointmentWithPreviousAppointment.appointment;

          PtsAlert.success(outputData.message);
          let fccPageState = {
            id: appointmentDetails.id,
            action: 'view',
          };
          store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
          this.props.history.push({
            pathname: '/viewMemberAppointment',
            state: {
              appointmentData: outputData,
              pageId: 'createAppointment',
              action: 'view',
            },
          });
        } else {
        }
      })
      .catch((error) => {});
  }
  breadCrumbMenu() {
    if (this.state.pageId === 'viewDoctorAppointment' || this.state.pageId === 'viewMemberFacilitys') {
      return (
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={' Appointment For' + ' ' + this.memberName}
          domainObject="Appointment"
        />
      );
    } else if (this.state.pageId === 'rescheduledAppointment') {
      return (
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={'Rescheduled Appointment For' + ' ' + this.memberName}
          domainObject="Appointment"
        />
      );
    } else {
      return (
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={'Create Similar Appointment For' + ' ' + this.memberName}
          domainObject="Appointment"
        />
      );
    }
  }
  render() {
    if (this.state.render === false) {
      return '';
    }

    if (this.state.action === 'repeatAppointment') {
      return this.createAppointment();
    } else if (this.state.action === 'createNewAppointment') {
      return this.createNewAppointment();
    } else {
      return this.viewAppointment();
    }
  }

  createAppointment() {
    if (this.state.render === false) {
      return <div />;
    } else {
      let reminderList = [];
      this.state.reminderItems.forEach((element, index) => {
        if ('delete' !== element.operation) {
          reminderList.push(
            <Col md={6} style={{ marginTop: '1em' }}>
              <Reminder
                ref={this.appointmentReminderRefList[index]}
                key={index}
                data={element}
                srNo={index}
                reminderDate={this.state.startDate}
                pageId={this.state.pageId}
                action="create"
                onReminderAdd={this.addNewReminder.bind(this)}
                onDelete={this.reminderItemDeleted.bind(this)}
                onUpdate={this.updateReminder.bind(this)}
              />
            </Col>
          );
        }
      });
      return (
        <>
          {this.breadCrumbMenu()}

          <ValidatorForm style={{ width: '100%' }} ref="form" onSubmit={this.addAppointmentInBackend.bind(this)}>
            <Row style={{ position: 'relative', margin: '1em' }}>
              <Col md={6} style={{ position: 'relative', padding: '5px' }}>
                <Card md={12} style={{ height: '100%', borderRadius: '20px' }}>
                  <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                    {undefined !== this.state.title && this.state.title.length > 0 && (
                      <Label
                        style={{
                          fontWeight: '600',
                          color: '#162c50',
                          marginLeft: '0.5em',
                        }}
                      >
                        {this.state.title}
                      </Label>
                    )}
                  </CardHeader>
                  <CardBody style={{ padding: '2em' }}>
                    <Row md={12} style={{ padding: '0.5em' }}>
                      <div md={12} style={{ width: '100%' }}>
                        <Row className="dateRow" style={{ marginBottom: '0.9em' }}>
                          <Col md={6}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel id="appointmentType-label">Appointment Type*</InputLabel>
                              <Select
                                disabled={this.state.isDisable === true ? 'disabled' : ''}
                                required
                                id="appointmentType"
                                value={this.state.appointmentType}
                                label="Appointment Type*"
                                onChange={(e) => {
                                  this.handleAppointmentTypeChange(e);
                                }}
                                startAdornment={
                                  <InputAdornment position="start">
                                    <CreateIcon />
                                  </InputAdornment>
                                }
                              >
                                {this.state.appointmentTypeListArray.map((event, keyIndex) => {
                                  return (
                                    <MenuItem key={keyIndex} value={event.type}>
                                      {event.type}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Col>
                          {this.state.isDoctor && (
                            <>
                              <Col md={6} style={{ marginTop: '-1em' }}>
                                <Autocomplete
                                  id="Role"
                                  options={this.state.doctorLocationList}
                                  value={this.state.doctor}
                                  getOptionLabel={(option) => option.name}
                                  onChange={(event, value) => {
                                    this.handleDoctorChange(value);
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Doctor" margin="normal" required />
                                  )}
                                />

                                {this.state.isCreateDoctor && (
                                  <span
                                    onClick={this.toDoctorCreate.bind(this)}
                                    style={{ cursor: 'pointer', color: '#162c50' }}
                                  >
                                    <a style={{ cursor: 'pointer', color: '#3498DB' }}>Create Doctor</a>
                                  </span>
                                )}
                              </Col>
                            </>
                          )}

                          {this.state.isFacility && (
                            <>
                              <Col md={6} style={{ marginTop: '-1em' }}>
                                <Autocomplete
                                  id="Role"
                                  options={this.state.facilityLocationList}
                                  value={this.state.facilityLocation}
                                  getOptionLabel={(option) => option.name}
                                  onChange={(event, value) => {
                                    this.handleFacilityLocationChange(value);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      label="Facility"
                                      margin="normal"
                                      required
                                    />
                                  )}
                                />

                                {this.state.isCreateFacility && (
                                  <span
                                    onClick={this.toFacilityCreate.bind(this)}
                                    style={{ cursor: 'pointer', color: '#162c50' }}
                                  >
                                    <a style={{ cursor: 'pointer', color: '#3498DB' }}>Create Facility</a>
                                  </span>
                                )}
                              </Col>
                            </>
                          )}
                        </Row>
                        <Row style={{ marginBottom: '0.9em' }}>
                          <Col md={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <ThemeProvider theme={materialTheme}>
                                <DateTimePicker
                                  value={this.state.startDate}
                                  variant="outlined"
                                  label="Date"
                                  fullWidth
                                  inputVariant="outlined"
                                  format="MM/dd/yyyy  h:mm aa"
                                  onChange={this.handleStartDateChange.bind(this)}
                                />
                              </ThemeProvider>
                            </MuiPickersUtilsProvider>
                          </Col>
                          <Col md={6}>
                            <input
                              className="pts_dataList_dropDown"
                              type="text"
                              required
                              id="demo-simple-select-outlined"
                              list="data"
                              placeholder="Appointment Purpose*"
                              label="Appointment Purpose*"
                              value={this.state.appoiPurpose}
                              onChange={(event, value) => {
                                this.handleAppoiPurposeChange(event, value);
                              }}
                            />
                            <datalist id="data">
                              {this.appointmentPurposeListData.map((event, key) => (
                                <option key={event.purpose} value={event.purpose} />
                              ))}
                            </datalist>
                          </Col>
                        </Row>

                        <Row style={{ marginBottom: '0.9em' }}>
                          <Col md={12}>
                            <TextareaAutosize
                              aria-label="minimum height"
                              style={{ width: '100%', padding: '10px' }}
                              rowsMin={3}
                              id={'note' + this.state.index}
                              name="note"
                              value={this.state.description}
                              inputProps={{ maxLength: 256 }}
                              onChange={this.handleDescriptionChange.bind(this)}
                              placeholder="Description"
                            />
                          </Col>
                        </Row>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={6} style={{ position: 'relative', padding: '5px' }}>
                <Card
                  md={12}
                  style={{
                    height: '100%',
                    borderRadius: '20px',
                  }}
                >
                  <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                    <Row>
                      <Col md={11}>
                        <Label
                          style={{
                            marginLeft: '0.5em',
                          }}
                          className="appointment_section_header"
                        >
                          Location Details
                        </Label>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {this.state.isDoctorLocation && (
                      <Row style={{ marginTop: '1em' }}>
                        <Col md={12}>
                          <Location data={this.state.selectedDoctorLocation} action="simpleView" />
                        </Col>
                      </Row>
                    )}
                  </CardBody>
                </Card>
                {/* <br></br> */}
              </Col>
            </Row>
            <div style={{ textAlign: 'center' }}>
              <CreateButton>Create</CreateButton>
              &nbsp;
              <CancelIconButton onClick={(e) => this.createCancel()} />
            </div>
          </ValidatorForm>
          <Dialog
            open={this.state.sameDateAppointmentWindow}
            onClose={this.handleSameDateAppointmentCreateWindowClose.bind(this)}
            onHide={this.close}
            style={{ padding: '1em' }}
          >
            <DialogContent style={{ marginTop: '2em' }}>
              <Row>
                <Col md={12} style={{ textAlign: 'center' }}>
                  <h5>{this.state.WarningMessage}</h5>
                  <br />
                  {this.state.WarningMessage ? <h5>Do you still want to continue to create?</h5> : ''}
                </Col>
              </Row>
            </DialogContent>
            <DialogActions style={{ marginTop: '0em', padding: '1em', marginLeft: 'auto', marginRight: 'auto' }}>
              <Button
                variant="contained"
                size="large"
                style={{ background: '#0f666b', color: '#fff', marginRight: '1rem' }}
                startIcon={<SaveIcon />}
                onClick={this.addSameAppointmentInBackend.bind(this)}
              >
                Ok
              </Button>
              <Button
                variant="contained"
                size="large"
                style={{ background: '#717373', color: '#fff', marginRight: '1rem' }}
                startIcon={<CloseIcon />}
                onClick={this.handleSameDateAppointmentCreateWindowClose.bind(this)}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    }
  }

  createNewAppointment() {
    if (this.state.render === false) {
      return <div />;
    } else {
      let reminderList = [];
      this.state.reminderItems.forEach((element, index) => {
        if ('delete' !== element.operation) {
          reminderList.push(
            <Col md={6} style={{ marginTop: '1em' }}>
              <Reminder
                ref={this.appointmentReminderRefList[index]}
                key={index}
                data={element}
                srNo={index}
                reminderDate={this.state.startDate}
                pageId={this.state.pageId}
                action="create"
                onReminderAdd={this.addNewReminder.bind(this)}
                onDelete={this.reminderItemDeleted.bind(this)}
                onUpdate={this.updateReminder.bind(this)}
              />
            </Col>
          );
        }
      });
      return (
        <>
          {this.breadCrumbMenu()}

          <ValidatorForm style={{ width: '100%' }} ref="form" onSubmit={this.addNewAppointmentInBackend.bind(this)}>
            <Row style={{ position: 'relative', margin: '1em' }}>
              <Col md={6} style={{ position: 'relative', padding: '5px' }}>
                <Card md={12} style={{ height: '100%', borderRadius: '20px' }}>
                  <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                    {undefined !== this.state.title && this.state.title.length > 0 && (
                      <Label
                        style={{
                          fontWeight: '600',
                          color: '#162c50',
                          marginLeft: '0.5em',
                        }}
                      >
                        {this.state.title}
                      </Label>
                    )}
                  </CardHeader>
                  <CardBody style={{ padding: '2em' }}>
                    <Row md={12} style={{ padding: '0.5em' }}>
                      <div md={12} style={{ width: '100%' }}>
                        <Row className="dateRow" style={{ marginBottom: '0.9em' }}>
                          <Col md={6}>
                            <FormControl variant="outlined" fullWidth>
                              <InputLabel id="appointmentType-label">Appointment Type*</InputLabel>
                              <Select
                                required
                                id="appointmentType"
                                value={this.state.appointmentType}
                                label="Appointment Type*"
                                onChange={(e) => {
                                  this.handleAppointmentTypeChange(e);
                                }}
                                startAdornment={
                                  <InputAdornment position="start">
                                    <CreateIcon />
                                  </InputAdornment>
                                }
                              >
                                {this.state.appointmentTypeListArray.map((event, keyIndex) => {
                                  return (
                                    <MenuItem key={keyIndex} value={event.type}>
                                      {event.type}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </FormControl>
                          </Col>
                          {this.state.isDoctor && (
                            <>
                              <Col md={6} style={{ marginTop: '-1em' }}>
                                <Autocomplete
                                  id="Role"
                                  options={this.state.doctorLocationList}
                                  value={this.state.doctor}
                                  getOptionLabel={(option) => option.name}
                                  onChange={(event, value) => {
                                    this.handleDoctorChange(value);
                                  }}
                                  renderInput={(params) => (
                                    <TextField {...params} variant="outlined" label="Doctor" margin="normal" required />
                                  )}
                                />

                                {this.state.isCreateDoctor && (
                                  <span
                                    onClick={this.toDoctorCreate.bind(this)}
                                    style={{ cursor: 'pointer', color: '#162c50' }}
                                  >
                                    <a style={{ cursor: 'pointer', color: '#3498DB' }}>Create Doctor</a>
                                  </span>
                                )}
                              </Col>
                            </>
                          )}

                          {this.state.isFacility && (
                            <>
                              <Col md={6} style={{ marginTop: '-1em' }}>
                                <Autocomplete
                                  id="Role"
                                  options={this.state.facilityLocationList}
                                  value={this.state.facilityLocation}
                                  getOptionLabel={(option) => option.name}
                                  onChange={(event, value) => {
                                    this.handleFacilityLocationChange(value);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      variant="outlined"
                                      label="Facility"
                                      margin="normal"
                                      required
                                    />
                                  )}
                                />

                                {this.state.isCreateFacility && (
                                  <span
                                    onClick={this.toFacilityCreate.bind(this)}
                                    style={{ cursor: 'pointer', color: '#162c50' }}
                                  >
                                    <a style={{ cursor: 'pointer', color: '#3498DB' }}>Create Facility</a>
                                  </span>
                                )}
                              </Col>
                            </>
                          )}
                        </Row>
                        <Row style={{ marginBottom: '0.9em' }}>
                          <Col md={6}>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                              <ThemeProvider theme={materialTheme}>
                                <DateTimePicker
                                  value={this.state.startDate}
                                  variant="outlined"
                                  fullWidth
                                  label="Date"
                                  // margin="normal"
                                  inputVariant="outlined"
                                  format="MM/dd/yyyy  h:mm aa"
                                  onChange={this.handleStartDateChange.bind(this)}
                                />
                              </ThemeProvider>
                            </MuiPickersUtilsProvider>
                          </Col>
                          <Col md={6}>
                            <input
                              className="pts_dataList_dropDown"
                              type="text"
                              required
                              id="demo-simple-select-outlined"
                              list="data"
                              placeholder="Appointment Purpose*"
                              label="Appointment Purpose*"
                              value={this.state.appoiPurpose}
                              onChange={(event, value) => {
                                this.handleAppoiPurposeChange(event, value);
                              }}
                            />
                            <datalist id="data">
                              {this.appointmentPurposeListData.map((event, key) => (
                                <option key={event.purpose} value={event.purpose} />
                              ))}
                            </datalist>
                          </Col>
                        </Row>

                        <Row style={{ marginBottom: '0.9em' }}>
                          <Col md={12}>
                            <TextareaAutosize
                              aria-label="minimum height"
                              style={{ width: '100%', padding: '10px' }}
                              rowsMin={3}
                              id={'note' + this.state.index}
                              name="note"
                              value={this.state.description}
                              inputProps={{ maxLength: 256 }}
                              onChange={this.handleDescriptionChange.bind(this)}
                              placeholder="Description"
                            />
                          </Col>
                        </Row>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={6} style={{ position: 'relative', padding: '5px' }}>
                <Card
                  md={12}
                  style={{
                    height: '100%',
                    borderRadius: '20px',
                  }}
                >
                  <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                    <Row>
                      <Col md={11}>
                        <Label
                          style={{
                            marginLeft: '0.5em',
                          }}
                          className="appointment_section_header"
                        >
                          Location Details
                        </Label>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {this.state.isDoctorLocation && (
                      <Row style={{ marginTop: '1em' }}>
                        <Col md={12}>
                          <Location data={this.state.selectedDoctorLocation} action="simpleView" />
                        </Col>
                      </Row>
                    )}
                  </CardBody>
                </Card>
                {/* <br></br> */}
              </Col>
            </Row>
            <div style={{ textAlign: 'center' }}>
              <CreateButton>Create</CreateButton>
              &nbsp;
              <CancelIconButton onClick={(e) => this.createCancel()} />
            </div>
          </ValidatorForm>
        </>
      );
    }
  }

  viewAppointment() {
    return (
      <>
        <Card
          style={{
            marginTop: '1.6em',
            borderRadius: '20px',
            height: '100%',
          }}
        >
          <Row>
            <Col md={9}>
              <h1>Home</h1>
            </Col>
          </Row>
        </Card>
      </>
    );
  }
}
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#008181',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: 'white',
        color: '#005476',
      },
    },
  },
});
