import { CLEAR_SPONSOR_USER, GET_SPONSOR_USER, SET_SPONSOR_USER } from './sponsorUserType';
const initialState = {
  adminSponsorUser: {},
};

const sponsorUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SPONSOR_USER:
      return {
        ...state,
      };
    case SET_SPONSOR_USER:
      return {
        ...state,
        adminSponsorUser: action.payload,
      };

    case CLEAR_SPONSOR_USER:
      return {
        ...state,
        adminSponsorUser: null,
      };
    default:
      return state;
  }
};

export default sponsorUserReducer;
