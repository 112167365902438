import React from 'react';

export default function SidebarToggleButton({ isSidebarCollapsed, toggleSidebar }) {
  const chevronClassName = isSidebarCollapsed ? 'is-collapsed' : 'is-not-collapsed';
  const screenReaderLabel = isSidebarCollapsed ? 'Expand Sidebar Navigation' : 'Collapse Sidebar Navigation';
  return (
    <span
      style={{ fontSize: '0.1rem', cursor: 'pointer' }}
      onClick={toggleSidebar}
      className={`m-r sidebar-toggle ${chevronClassName}`}
      aria-label={screenReaderLabel}
    >
      <i className="fa fa-bars" style={{ fontSize: '25px', color: '#fff' }}></i>
    </span>
  );
}
