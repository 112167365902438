import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import Utils from '../../../../provider/Utils';
import '../../contactUs/assets/vendor/animate.css/animate.min.css';
import '../../contactUs/assets/vendor/bootstrap/css/bootstrap.min.css';
import '../../contactUs/assets/vendor/boxicons/css/boxicons.min.css';
import '../../contactUs/assets/vendor/icofont/icofont.min.css';
import '../../contactUs/assets/vendor/line-awesome/css/line-awesome.min.css';
import '../../contactUs/assets/vendor/owl.carousel/assets/owl.carousel.min.css';
import '../../contactUs/assets/vendor/remixicon/remixicon.css';
import '../../contactUs/assets/vendor/venobox/venobox.css';
import './PlanDetails.scss';

class PlanDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      businessPromotionForm: false,
      firmName: '',
      firmWebsite: '',
      planList: [],
    };
  }
  handleFirmNameChange(e) {
    this.setState({ firmName: e.target.value });
  }
  handleFirmWebsiteChange(e) {
    this.setState({ firmWebsite: e.target.value });
  }

  render() {
    return (
      <div>
        <meta charSet="utf-8" />
        <meta content="width=device-width, initial-scale=1.0" name="viewport" />
        <title>Family Care Circle</title>
        <meta content name="description" />
        <meta content name="keywords" />
        {/* Favicons */}
        <link href="assets/img/FCC.png" rel="icon" />
        <link href="assets/img/distance.png" rel="distance" />
        <link href="assets/img/group.png" rel="group" />
        <link href="assets/img/location.png" rel="location" />
        <link href="assets/img/security2.png" rel="security" />
        <link href="https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0-alpha.6/css/bootstrap.min.css" rel="stylesheet" />
        {/*Bootstrap CSS JS*/}
        {/*fonts*/}
        <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/animate.css/3.5.2/animate.min.css" />
        {/* Google Fonts */}
        <link
          href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Raleway:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i"
          rel="stylesheet"
        />

        <link href="//netdna.bootstrapcdn.com/font-awesome/3.2.1/css/font-awesome.css" rel="stylesheet" />
        {/* =======================================================
      * Template Name: Selecao - v2.3.1
      * Template URL: https://bootstrapmade.com/selecao-bootstrap-template/
      * Author: BootstrapMade.com
      * License: https://bootstrapmade.com/license/
      ======================================================== */}

        {/* ======= Header ======= */}
        <header id="header" className="fixed-top d-flex align-items-center  header-transparent ">
          <div className="container d-flex align-items-center">
            <div className="logo mr-auto">
              <span style={{ display: 'flex' }}>
                <img src="../assets/img/FCC.png" alt="" style={{ height: '2.3rem', width: '3em' }} />
                <h1 className="text-light" style={{ paddingLeft: '0.2em' }}>
                  <a href="home.html">Family Care Circle</a>
                </h1>
              </span>
            </div>
            <nav className="nav-menu d-none d-lg-block">
              <ul>
                <li>
                  <a href="/">Home</a>
                </li>
                <li>
                  <a href="/fcc#about">About</a>
                </li>
                <li>
                  <a href="/fcc#services">Services</a>
                </li>
                <li>
                  <a href="/termsConditionsRegistration">Register</a>
                </li>
                <li>
                  <a href="/login">Login</a>
                </li>
                <li className="active">
                  <a href="/home.html#pricing">Pricing</a>
                </li>
                <li>
                  <a href="/contactUs">Contact</a>
                </li>
              </ul>
            </nav>
            {/* .nav-menu */}
          </div>
        </header>
        {/* End Header */}
        <section id="pricing" className="pricing" style={{ padding: '7em' }}>
          <div className="container">
            <div className="planDetailsTitle" data-aos="zoom-out" style={{ textAlign: 'center' }}>
              <h2 style={{ color: '#ef6603', fontSize: '22px' }}>
                <b>Plan Details</b>
              </h2>
            </div>
            <Row style={{ marginTop: '25px' }}>
              <Col md={10} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                <Card
                  style={{
                    marginTop: '1em',
                    boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
                  }}
                >
                  <CardHeader className="plan_detail_header">
                    <Row>
                      {' '}
                      <Col md={12}>
                        <h5 style={{ textAlign: 'center' }}>
                          {' '}
                          <b>Business Promotion Form</b>
                        </h5>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody style={{ background: '#F7F9F9' }}>
                    <Row>
                      <Col mad={12}>
                        <h5>
                          <b>Please select a package:</b>
                        </h5>
                        <ul style={{ padding: '0px 0px 0px 25px', listStyle: 'disc', marginTop: '1.5em' }}>
                          <li>
                            <Row>
                              <Col md={5} className="packageSelection">
                                <p style={{ lineHeight: '1px' }}>Silver Pack ($100)</p>
                                <p>Sponsor 25 Family Care Circles</p>
                              </Col>
                              <Col md={4}>
                                <input type="radio" name="rName" />
                              </Col>
                            </Row>
                          </li>
                          <hr style={{ background: '#D5DBDB' }}></hr>
                          <li>
                            <Row>
                              <Col md={5} className="packageSelection">
                                <p style={{ lineHeight: '1px' }}>Gold Pack ($500)</p>
                                <p>Sponsor 500 Family Care Circles</p>
                              </Col>
                              <Col md={4}>
                                <input type="radio" name="rName" />
                              </Col>
                            </Row>
                          </li>
                          <hr style={{ background: '#D5DBDB' }}></hr>
                          <li>
                            <Row>
                              <Col md={5} className="packageSelection">
                                <p style={{ lineHeight: '1px' }}>Platinum Pack ($1000)</p>
                                <p>Sponsor 2500 Family Care Circles</p>
                              </Col>
                              <Col md={4}>
                                <input type="radio" name="rName" />
                              </Col>
                            </Row>
                          </li>
                        </ul>
                        <Row>
                          <Col md={6}>
                            <Row>
                              <Col md={12}>
                                <TextField
                                  margin="normal"
                                  variant="outlined"
                                  type="text"
                                  fullWidth
                                  required
                                  id="firmName"
                                  onChange={this.handleFirmNameChange.bind(this)}
                                  label="Firm Name"
                                  name="name"
                                  autoComplete="off"
                                  value={this.state.firmName}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12}>
                                <TextField
                                  margin="normal"
                                  variant="outlined"
                                  type="text"
                                  fullWidth
                                  required
                                  id="website"
                                  onChange={this.handleFirmWebsiteChange.bind(this)}
                                  label="Firm’s Website "
                                  name="name"
                                  autoComplete="off"
                                  value={this.state.firmWebsite}
                                />
                              </Col>
                            </Row>
                          </Col>
                          <Col md={6}>
                            <Row>
                              <Col md={12}>
                                <TextField
                                  margin="normal"
                                  variant="outlined"
                                  type="text"
                                  fullWidth
                                  required
                                  id="email"
                                  label="Firm’s Email "
                                  name="name"
                                  autoComplete="off"
                                  value={this.state.firmEmail}
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col md={12}>
                                <TextField
                                  margin="normal"
                                  variant="outlined"
                                  type="text"
                                  fullWidth
                                  required
                                  id="name"
                                  label="Firm’s Phone "
                                  name="name"
                                  autoComplete="off"
                                  value={this.state.firmPhone}
                                />
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <div style={{ textAlign: 'center' }}>
              <Button
                variant="contained"
                style={{ background: '#ef6603', color: '#fff', textTransform: 'none' }}
                type="submit"
              >
                Buy
              </Button>
            </div>
          </div>
        </section>
        {/* ======= Footer ======= */}
        <footer id="contactUsFooter">
          <div className="container">
            <h3>Family Care Circle</h3>
            <div className="social-links">
              <a href="#" className="twitter">
                <i className="bx bxl-twitter" />
              </a>
              <a href="#" className="facebook">
                <i className="bx bxl-facebook" />
              </a>
              <a href="#" className="instagram">
                <i className="bx bxl-instagram" />
              </a>
              <a href="#" className="google-plus">
                <i className="bx bxl-skype" />
              </a>
              <a href="#" className="linkedin">
                <i className="bx bxl-linkedin" />
              </a>
            </div>
            <div className="copyright">
              © Copyright{' '}
              <strong>
                <span>Family Care Circle</span>
              </strong>
              . All Rights Reserved
            </div>
          </div>
        </footer>
        {/* End Footer */}
        <a href="#" className="back-to-top">
          <i className="ri-arrow-up-line" />
        </a>
      </div>
    );
  }
}

export default PlanDetails;
