import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import FetchServerData from '../../provider/FetchServerData';
import PtsAlert from '../../pts/components/ptsAlert/PtsAlert';
import store from '../../redux/store';
export default class VerificationRequest extends Component {
  constructor(props) {
    super(props);
    let action = props.action;
    if (
      null !== props.history &&
      props.history !== undefined &&
      props.history.location !== null &&
      props.history.location.state !== null &&
      props.history.location.state !== undefined
    ) {
      action = props.history.location.state.action;
    }
    let str = window.location.href;
    let urlArr = str.split('?');
    let url = urlArr[1].split('=');
    let urlMember = url[1].split('&');
    let urlMemberId = urlMember[0];
    let urlfamilyMember = url[2].split('&');
    let urlfamilyMemberId = urlfamilyMember[0];
    let urlisMember1 = url[3].split('&');
    let urlisMember = urlisMember1[0];

    this.state = {
      email: '',
      code: '',
      showPassword: false,
      action: action,
      member: urlMemberId,
      familyMember: urlfamilyMemberId,
      isMember: urlisMember,
    };
  }
  handleVerificationCodeChange(e) {
    this.setState({ code: e.target.value });
  }
  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }
  verificationRequestInBackend = () => {
    const header = store.getState().header.header;
    let familyMemberId = this.state.familyMember;
    if (this.state.familyMember === 'null') {
      familyMemberId = 0;
    }
    const postObject = {
      header: header,
      memberId: this.state.member,
      familyMemberId: familyMemberId,
      isMember: this.state.isMember,
      contactId: this.state.contactMember,
    };
    FetchServerData.callPostService('/userms/verificationRequest', postObject).then(output => {
      if (output.status === 'SUCCESS') {
        PtsAlert.success(output.message);
        this.props.history.push({
          pathname: '/login',
        });
      } else {
      }
    });
  };
  render() {
    return (
      <>
        <Col md={3}></Col>
        <Col md={6}>
          <Card class="formCard" style={{ marginTop: '64px' }}>
            <Row md={12} style={{ marginTop: '2em' }}>
              <Col>
                <CardHeader style={{ width: '100%', borderBottom: '1px solid #dcdcdc' }}>
                  <h4 style={{ color: '#169ba2' }}>
                    <b>Verification Request</b>
                  </h4>
                </CardHeader>
              </Col>
            </Row>
            <CardBody>
              <div style={{ textAlign: 'left' }}>
                <Button
                  type="submit"
                  style={{ backgroundColor: '#1a7088', color: '#fff' }}
                  variant="contained"
                  onClick={e => this.verificationRequestInBackend()}
                  startIcon={<CheckCircleIcon />}
                >
                  Verification Request
                </Button>
              </div>
            </CardBody>
            <Row md={12} style={{ marginTop: '2em' }}>
              <Col></Col>
            </Row>
          </Card>
        </Col>
      </>
    );
  }
}
