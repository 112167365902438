import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap';
import './EmailTemplate.scss';
import Quill from './quill/Quill';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ValidatorForm } from 'react-material-ui-form-validator';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import store from '../../../redux/store';
import BackButton from '../../components/buttons/BackButton';
import CancelIconButton from '../../components/buttons/CancelIconButton';
import SaveButton from '../../components/buttons/SaveButton';
import PtsAlert from '../../components/ptsAlert/PtsAlert';
import AdminMenuWithBreadScrum from '../adminMenu/AdminMenuWithBreadScrum';
import Tooltip from './tooltip/Tooltip';
class EmailTemplate extends Component {
  title = 'Email Template';
  breadCrumb = [
    { pageid: '/emailTemplateList', uiname: 'Email Template List' },
    { pageid: '', uiname: 'Email Template' },
  ];
  header = store.getState().adminHeader.adminHeader;
  adminFccPageState = store.getState().adminFccPageState.adminFccPageState;
  constructor(props) {
    super(props);
    let emailTemplateId = props.emailTemplateId;
    let action = props.action;
    if (!emailTemplateId && props.history && props.history.location && props.history.location.state) {
      emailTemplateId = props.history.location.state.emailTemplateId;
      action = props.history.location.state.action;
    }
    if (null !== emailTemplateId && emailTemplateId > 0) {
      this.getTemplateDetails(emailTemplateId);
    }
    if (emailTemplateId === undefined) {
      emailTemplateId = this.adminFccPageState.id;
      action = this.adminFccPageState.action;
      // title = 'Email Template Details';
      if (action !== 'create') {
        this.getTemplateDetails(emailTemplateId);
      }
    }
    this.state = {
      action: action,
      message: '',
      fromName: '',
      replyToEmail: '',
      subject: '',
      anchorEl: null,
      emailTypeList: [],
      emailType: null,
      selectedEmailFlag: 0,
      id: emailTemplateId,
      variableList: [],
      description: '',
      viewEmailUid: '',
      copied: false,
      render: false,
      getUpdatePermission: false,
      getDeletePermission: false,
    };
  }

  handleEmailTypeChange(selectedEmailType) {
    this.state.emailType = selectedEmailType;
    this.setState({ emailType: selectedEmailType });
    if (undefined !== this.onChange && null !== this.onChange) {
      this.onChange(this.state);
    }
    this.getTemplateDetails(selectedEmailType.id);
  }

  getTemplateDetails(emailTemplateId) {
    let postObject = {
      header: this.header,
      template: { id: emailTemplateId },
    };
    FetchServerData.callPostService('/emailTemplatems/get', postObject)
      .then((response) => {
        let output = response;
        if (output.status === 'SUCCESS') {
          let templateDetails = output.data.template;
          this.getEmailTemplateList(templateDetails.uid);
          let getPermission = Utils.getRolePermission(output.data.privileges);
          let getUpdatePermission = getPermission[3];
          let getDeletePermission = getPermission[4];
          this.setState({
            render: true,
            id: templateDetails.id,
            viewEmailUid: templateDetails.uid,
            message: templateDetails.content,
            fromName: templateDetails.from_email,
            variableList: templateDetails.variableList,
            subject: templateDetails.subject,
            description: templateDetails.description,
            getUpdatePermission: getUpdatePermission,
            getDeletePermission: getDeletePermission,
          });
        }
      })
      .catch((error) => {});
  }

  getEmailTemplateList(templateUid) {
    let postObject = {
      header: this.header,
    };
    FetchServerData.callPostService('/emailTemplatems/getList', postObject)
      .then((response) => {
        let output = response;
        if (output.status === 'SUCCESS') {
          let emailTypeListData = output.data;
          for (let i = 0; emailTypeListData.length; i++) {
            if (emailTypeListData[i].uid === templateUid) {
              this.setState({
                emailType: emailTypeListData[i],
                emailTypeList: emailTypeListData,
              });
            }
          }
        }
      })
      .catch((error) => {});
  }

  updateNotificationTemplateInBackend() {
    let postObject = {
      header: this.header,
      template: {
        uid: this.state.viewEmailUid,
        from_email: this.state.fromName,
        subject: this.state.subject,
        content: this.state.message,
        variableList: this.state.variableList,
        description: this.state.description,
        id: this.state.id,
      },
    };
    FetchServerData.callPostService('/emailTemplatems/update', postObject)
      .then((response) => {
        let output = response;
        if (output.status === 'SUCCESS') {
          PtsAlert.success(output.message);
          let templateDetails = output.data.template;
          this.props.history.push({
            pathname: '/viewEmailTemplate',
            state: {
              action: 'view',
              title: 'Email Template Details',
              emailTemplateId: templateDetails.id,
            },
          });
        }
      })
      .catch((error) => {});
  }

  handleSubjectChange(e) {
    this.setState({
      subject: e.target.value,
    });
  }
  clearTemplate(e) {
    this.setState({
      message: '',
      fromName: '',
      replyToEmail: '',
      subject: '',

      emailType: null,
    });
  }

  handleReplyToEmailChange(e) {
    this.setState({
      replyToEmail: e.target.value,
    });
  }

  handleMessageChange(value) {
    this.setState({ message: value });
  }

  handleClose(event) {
    this.setState({ anchorEl: null });
  }
  cancelCreate() {
    this.props.history.push({
      pathname: '/viewEmailTemplate',
      state: { action: 'view', title: 'Email Template Details', emailTemplateId: this.state.id, pageId: 'sponsorList' },
    });
  }

  copySelected(string, e) {
    if (string === '') {
      PtsAlert.error('Please select an account to copy.');
      return;
    }
    // navigator.clipboard.writeText(string);
    PtsAlert.success(string + ' Copied');
  }
  onCopy = (e) => {
    // navigator.clipboard.writeText(this.state.websiteuser)
    this.setState({ copied: true });
  };
  showVariableList() {
    return this.state.variableList.map((data, index) => {
      if (this.state.render === false) {
        return <div />;
      } else {
        let splitList = data.split('-');
        let selectVariable = splitList[0];
        return (
          <>
            <li>
              {/* <Tooltip title={selectVariable}> */}
              <Tooltip title={selectVariable} aria-label="add">
                <IconButton style={{ marginLeft: '30px' }}>
                  {/* <i class="fa fa-clone" aria-hidden="true" onClick={this.copySelected.bind(this, selectVariable)} /> */}
                  <CopyToClipboard text={selectVariable} onClick={this.onCopy}>
                    <FileCopyIcon style={{ color: 'gray', fontSize: '20px' }} />
                  </CopyToClipboard>
                </IconButton>
              </Tooltip>
              &nbsp;
              {/* </Tooltip> */}
              {data}
            </li>
          </>
        );
      }
    });
  }
  handleDescriptionChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 256);
    if (res.length < 256) {
      this.setState({ description: res });
    } else {
      Utils.maxFieldLength(256);
    }
  }
  editEmail() {
    this.props.history.push({
      pathname: '/editEmailTemplate',
      state: {
        action: 'edit',
        title: 'Email Template Details',
        emailTemplateId: this.state.id,
      },
    });
  }
  viewCancel() {
    this.props.history.push({
      pathname: '/emailTemplateList',
    });
  }
  render() {
    if (this.state.action === 'edit') {
      return this.editEmailTemplate();
    } else {
      return this.viewEmailTemplate();
    }
  }
  viewEmailTemplate() {
    const open = Boolean(this.state.anchorEl);
    const id = open ? 'simple-popover' : undefined;
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <AdminMenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            backCallback={this.viewCancel.bind(this)}
            name={this.title}
            editCallback={this.editEmail.bind(this)}
            getDeletePermission={this.state.getDeletePermission}
            getUpdatePermission={this.state.getUpdatePermission}
          />
          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={12} className="sponsorDetailColumn" style={{ position: 'relative', padding: '5px' }}>
              <Card style={{ height: '100%' }}>
                <CardHeader className="cardHeader">
                  <Row>
                    <Col md={10}>
                      <Label className="cardHeaderTitle">
                        {/* Advertisement Details */}
                        Email Template Details
                      </Label>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={2} className="labelColumn">
                      <label>Email Type:</label>
                    </Col>
                    <Col md={10}>{this.state.viewEmailUid}</Col>
                  </Row>
                  <Row>
                    <Col md={2} className="labelColumn">
                      <label>Subject:</label>
                    </Col>
                    <Col md={10}>{this.state.subject}</Col>
                  </Row>
                  <Row>
                    <Col md={2} className="labelColumn">
                      <label>Description:</label>
                    </Col>
                    <Col md={10}>
                      {' '}
                      <pre className="view_AppointmentNote" style={{ marginLeft: '-1.9px' }}>
                        {' '}
                        {this.state.description}
                      </pre>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2} className="labelColumn">
                      <label> Email Body:</label>
                    </Col>
                    <Col md={10}>
                      {' '}
                      {this.state.variableList.length > 0 && (
                        <p style={{ marginLeft: '0.2em' }}>
                          The following variables has a significance role in composing the email. Please do not change
                          it.
                        </p>
                      )}
                      <Row style={{ lineHeight: '1.8em', marginLeft: '0.2em' }}>
                        <Col md={12} style={{ display: 'flex' }}>
                          <ul style={{ listStyle: 'none' }}>{this.showVariableList()}</ul>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <div dangerouslySetInnerHTML={{ __html: this.state.message }} className="emailBody" />
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div className="sponsorDetailFooter">
            <BackButton
              type="button"
              style={{ backgroundColor: '#162c50', color: '#fff' }}
              variant="contained"
              onClick={(e) => this.viewCancel()}
            >
              Back
            </BackButton>
          </div>
        </>
      );
    }
  }
  editEmailTemplate() {
    const open = Boolean(this.state.anchorEl);
    const id = open ? 'simple-popover' : undefined;
    return (
      <>
        <AdminMenuWithBreadScrum breadCrumb={this.breadCrumb} history={this.props.history} name={this.title} />
        <Row style={{ position: 'relative', margin: '1em' }}>
          <Col md={12} style={{ position: 'relative', padding: '5px' }}>
            <ValidatorForm
              ref="form"
              style={{ width: '100%' }}
              onSubmit={this.updateNotificationTemplateInBackend.bind(this)}
            >
              <Card>
                <CardBody>
                  <Row>
                    <Col md={2} className="labelColumn">
                      <label>Email Type:</label>
                    </Col>
                    <Col md={10}>{this.state.viewEmailUid}</Col>
                  </Row>

                  <Row>
                    <Col md={2} className="labelColumn">
                      <label id="notificationFormLabel">
                        Subject <span className="asterisk">*</span>
                      </label>
                    </Col>
                    <Col md={10}>
                      <TextField
                        variant="outlined"
                        fullWidth
                        type="text"
                        required
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Enter Subject"
                        autoComplete="off"
                        margin="normal"
                        value={this.state.subject}
                        onChange={this.handleSubjectChange.bind(this)}
                        // inputProps={{
                        //   style: { textTransform: 'capitalize' },
                        // }}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={2} className="labelColumn">
                      <label id="notificationFormLabel">
                        Description <span className="asterisk">*</span>
                      </label>
                    </Col>
                    <Col md={10}>
                      <TextareaAutosize
                        aria-label="minimum height"
                        style={{ width: '100%', padding: '10px', marginTop: '2em' }}
                        rowsMin={3}
                        id={'note' + this.state.index}
                        name="note"
                        value={this.state.description}
                        inputProps={{ maxLength: 256 }}
                        onChange={this.handleDescriptionChange.bind(this)}
                        placeholder="Description"
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Row>
                      <Col md={2} className="labelColumn">
                        <label id="notificationFormLabel">Body</label>
                      </Col>
                      <Col md={10} style={{ marginTop: '2em' }}>
                        {this.state.variableList.length > 0 && (
                          <p style={{ marginLeft: '0.2em' }}>
                            The following variables has a significance role in composing the email. Please do not change
                            it.
                          </p>
                        )}
                        <Row style={{ lineHeight: '1.8em', marginLeft: '0.2em' }}>
                          <Col md={12} style={{ display: 'flex' }}>
                            <ul style={{ listStyle: 'none' }}>{this.showVariableList()}</ul>
                          </Col>
                        </Row>
                        <Quill
                          text={this.state.message}
                          onChange={this.handleMessageChange.bind(this)}
                          className="templateMessageBox"
                        />
                      </Col>
                    </Row>
                  </Row>
                </CardBody>

                <div style={{ textAlign: 'center', padding: '0.5em' }}>
                  <SaveButton type="submit" />
                  &nbsp;
                  <CancelIconButton onClick={(e) => this.cancelCreate()} />
                </div>
              </Card>
            </ValidatorForm>
          </Col>
        </Row>
      </>
    );
  }
}

export default EmailTemplate;
