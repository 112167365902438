import { CLEAR_COMMERCIALS, GET_COMMERCIALS, SET_COMMERCIALS } from './CommercialsTypes';

const initialState = {
  commercials: {},
  //   commercialsDetails: {},
};

const CommercialsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_COMMERCIALS:
      return {
        ...state,
      };
    case SET_COMMERCIALS:
      return {
        ...state,

        commercials: action.payload,
        // commercialsDetails: action.payload.sponsorship,
      };

    case CLEAR_COMMERCIALS:
      return {
        ...state,
        commercials: null,
      };
    default:
      return state;
  }
};

export default CommercialsReducer;
