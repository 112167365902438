import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import DeleteIcon from '@material-ui/icons/Delete';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import PinDropIcon from '@material-ui/icons/PinDrop';
import SaveIcon from '@material-ui/icons/Save';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import React, { Component } from 'react';
import Avatar, { ConfigProvider } from 'react-avatar';
import { Container } from 'react-floating-action-button';
import { Card, CardBody, CardHeader, CardFooter, Col, Label, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import helpDataAction from '../../../redux/help/displayHelpAction';
import store from '../../../redux/store';
import AddressWithTimezone from '../addressWithTimezone/AddressWithTimezone';
import CancelIconButton from '../buttons/CancelIconButton';
import EditIconButton from '../buttons/EditIconButton';
import SaveButton from '../buttons/SaveButton';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
import AccessAlarmTwoToneIcon from '@material-ui/icons/AccessAlarmTwoTone';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { TextValidator } from 'react-material-ui-form-validator';
import memberListAction from '../../../redux/memberList/memberListAction';
export class MemberProfile extends Component {
  memberDetails = store.getState().memberData.memberData;
  memberName = store.getState().memberData.memberData.firstname + ' ' + store.getState().memberData.memberData.lastname;
  header = store.getState().header.header;
  isFreeUser = store.getState().userData.userData.isFreeUser;
  loginUserName = store.getState().userData.userData.firstName + ' ' + store.getState().userData.userData.lastName;
  selectedMemberData = store.getState().selectedMemberData.selectedMemberData;
  userData = store.getState().userData.userData;
  displayHelp = store.getState().help.help;
  memberHomeUrl = store.getState().memberHomeUrl.memberHomeUrl.homeUrl;
  memberNamesList = store.getState().memberList.memberList;
  title = 'Member Profile';
  breadCrumb = [
    { pageid: this.memberHomeUrl, uiname: this.memberName },
    { pageid: '', uiname: 'Member Profile Details' },
  ];
  addressRef = React.createRef();
  addressRef = {};
  constructor(props) {
    super(props);
    let action = props.action;
    let memberId = props.memberId;
    let title = '';
    let pageId = '';
    if (
      null == memberId &&
      null !== props.history &&
      props.history !== undefined &&
      props.history.location !== null &&
      props.history.location.state !== null &&
      props.history.location.state !== undefined
    ) {
      memberId = props.history.location.state.memberId;
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
      title = props.history.location.state.title;
    }

    this.state = {
      pageId: pageId,
      action: action,
      title: title,
      render: false,
      id: memberId,
      fname: '',
      mname: '',
      lname: '',
      description: '',
      phone: '',
      email: '',
      designation: '',
      address: {},
      line: '',
      city: '',
      state: '',
      country: '',
      zipCode: '',
      open: false,
      confirmWindowOpen: false,
      password: '',
      showPassword: false,
      isFreeUser: this.isFreeUser,
      stepsEnabled: false,
      initialStep: 0,
      steps: [
        {
          element: '.delete',
          intro: 'Delete Purchase  Details ',
        },
      ],
      displayFloatButton: this.displayHelp,
      viewProfile: true,
      editProfile: false,
      viewAddress: true,
      editAddress: false,
      isCreateAddress: false,
      isEditAddress: false,
      showEditButton: false,
      timezone: '',
      timeZoneAbbreviation: '',
      authorizedCaregiver: false,
      isAuthorizedCaregiver: '',
      isSelf: false,
      memberWithoutEmail: false,
    };
    this.getMemberDetails();
  }
  toggleSteps = () => {
    this.setState((prevState) => ({ stepsEnabled: !prevState.stepsEnabled }));
  };

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };
  handleFnameChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 128);
    if (res.length < 128) {
      this.setState({ fname: res });
    } else {
      Utils.maxFieldLength(128);
    }
  }

  handleLnameChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 128);
    if (res.length < 128) {
      this.setState({ lname: res });
    } else {
      Utils.maxFieldLength(128);
    }
  }

  handleEmailChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ email: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }

  handlePhoneChange({ target: { value } }) {
    this.setState((prevState) => ({
      phone: Utils.convertPhoneNumberToUsaPhoneNumberFormate(value, prevState.phone),
    }));
  }
  handleAddressChange(address) {
    this.setState({ address: address });
  }

  getMemberDetails() {
    const postObject = {
      header: this.header,
      member: {
        id: this.memberDetails.member,
      },
    };

    FetchServerData.callPostService('/memberms/getMemberDetails', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let profileData = output.data;
        let profileAddress = profileData.address;
        if ((profileAddress === null || profileAddress.state) === null) {
          this.setState({
            isCreateAddress: true,
            isEditAddress: false,
          });
        } else {
          this.setState({
            isCreateAddress: false,
            isEditAddress: true,
          });
        }

        if (profileData.isAuthorizedCaregiver === true) {
          this.setState({
            showEditButton: true,
          });
        } else {
          this.setState({
            showEditButton: false,
          });
        }
        if (profileAddress === null || profileAddress.timezone === null) {
          this.setState({
            timezone: '',
            timeZoneAbbreviation: '',
          });
        } else {
          this.setState({
            timezone: profileAddress.timezone.name,
            timeZoneAbbreviation: profileAddress.timezone.abbreviation,
          });
        }
        let showNoEmailText = false;
        let showUpdateEmail = false;
        if (profileData.memberWithoutEmail === true) {
          showNoEmailText = true;
          showUpdateEmail = false;
        }
        let email = '';
        if (profileData.memberWithoutEmail === true) {
          email = '';
        } else {
          email = profileData.email;
        }
        if (profileAddress !== '' && profileAddress !== null) {
          this.setState({
            render: true,
            viewProfile: true,
            editProfile: false,
            id: profileData.id,
            fname: profileData.firstName,
            mname: profileData.middleName,
            lname: profileData.lastName,
            email: email,
            isAuthorizedCaregiver: profileData.isAuthorizedCaregiver,
            viewAddress: true,
            editAddress: false,
            phone: Utils.displayUsaPhoneNumberFormate(profileData.phone),
            address: profileAddress,
            isSelf: profileData.isSelfMember,
            memberWithoutEmail: profileData.memberWithoutEmail,
            showNoEmailText: showNoEmailText,
            showUpdateEmail: showUpdateEmail,
          });
        } else {
          this.setState({
            render: true,
            viewProfile: true,
            editProfile: false,
            id: profileData.id,
            fname: profileData.firstName,
            mname: profileData.middleName,
            lname: profileData.lastName,
            email: email,
            isAuthorizedCaregiver: profileData.isAuthorizedCaregiver,
            phone: Utils.displayUsaPhoneNumberFormate(profileData.phone),
            address: null,
            viewAddress: true,
            editAddress: false,
            isSelf: profileData.isSelfMember,
            memberWithoutEmail: profileData.memberWithoutEmail,
            showNoEmailText: showNoEmailText,
            showUpdateEmail: showUpdateEmail,
          });
        }
      }
    });
  }

  updateProfileInBackend = (e) => {
    const postObject = {
      header: this.header,
      memberVo: {
        id: this.state.id,
        firstName: this.state.fname,
        lastName: this.state.lname,
        email: this.state.email,
        phone: this.state.phone.replace(/[^\d]/g, ''),
        isMemberUpdate: true,
        // memberDetailsId: this.memberDetails.member,
      },
    };
    FetchServerData.callPostService('/memberms/updateMemberForAuthorisedCaregiver', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          this.setState({
            viewProfile: true,
            editProfile: false,
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  updateEmailProfileInBackend = (e) => {
    const postObject = {
      header: this.header,
      memberVo: {
        id: this.state.id,
        firstName: this.state.fname,
        lastName: this.state.lname,
        email: this.state.email,
        phone: this.state.phone.replace(/[^\d]/g, ''),
        isMemberUpdate: true,
        // memberDetailsId: this.memberDetails.member,
      },
    };
    FetchServerData.callPostService('/memberms/updateMemberForAuthorisedCaregiver', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          this.setState({
            viewProfile: true,
            editProfile: false,
            showUpdateEmail: false,
            showNoEmailText: true,
            showUpdateEmail: false,
          });
          this.getMemberDetails(this.memberDetails.member);
        } else {
        }
      })
      .catch((error) => {});
  };
  cancelEmailProfile(e) {
    this.setState({
      showNoEmailText: true,
      showUpdateEmail: false,
    });
  }
  updateProfileAddressInBackend = (e) => {
    const postObject = {
      header: this.header,
      memberVo: {
        id: this.state.id,
        // memberDetailsId: this.memberDetails.member,
        isMemberUpdate: true,

        address: {
          id: this.addressRef.current.state.id,
          line: this.addressRef.current.state.line,
          state: this.addressRef.current.state.state,
          timezone: {
            id: this.addressRef.current.state.timeZone.id,
          },
          city: this.addressRef.current.state.city,
          zipCode: this.addressRef.current.state.zipCode,
        },
      },
    };
    FetchServerData.callPostService('/memberms/updateAddressForAuthorisedCaregiver', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let memberDetails = output.data;
          let profileAddress = memberDetails.address;
          if (profileAddress.timezone === null) {
            this.setState({
              timezone: '',
              timeZoneAbbreviation: '',
              editAddress: false,
              viewAddress: true,
              address: profileAddress,
            });
          } else {
            this.setState({
              timezone: profileAddress.timezone.name,
              timeZoneAbbreviation: profileAddress.timezone.abbreviation,
              editAddress: false,
              viewAddress: true,
              address: profileAddress,
            });
          }
          // this.setState({ viewAddress: true, editAddress: false });
          this.getMemberDetails(this.memberDetails.member);
        } else {
        }
      })
      .catch((error) => {});
  };

  editCancel(e, memberId) {
    this.props.history.push({
      pathname: '/memberProfile',
      state: { action: 'view', memberId: memberId },
    });
  }

  toDeleteMemberConfirmationWindow = (e, selectedMember) => {
    this.setState({ confirmWindowOpen: true, open: false, isCaregiver: selectedMember });
  };
  toDeleteAuthorizedConfirmationWindow = () => {
    this.setState({ confirmWindowOpen: true, authorizedCaregiver: false });
  };

  handleConfirmWindowClose = () => {
    this.setState({ confirmWindowOpen: !this.state.confirmWindowOpen });
  };

  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }

  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  handleClose = () => {
    this.setState({ open: !this.state.open });
  };
  handleAuthorizedClose = () => {
    this.setState({ authorizedCaregiver: !this.state.authorizedCaregiver });
  };
  handleOpenAuthorized = () => {
    this.setState({ authorizedCaregiver: true });
  };
  handleClickOpen = () => {
    this.setState({ open: true });
  };
  confirmMemberDeleteInBackend = (e) => {
    const header = store.getState().header.header;

    const postObject = {
      header: header,
      appuserVo: {
        credential: this.state.password,
      },
    };
    FetchServerData.callPostService('userms/checkValidCredential', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          this.setState({ confirmWindowOpen: false });
          if (this.state.isCaregiver === 'caregiver') {
            this.deleteMemberInBackend();
          } else {
            this.memberDelete();
          }
        } else {
        }
      })
      .catch((error) => {});
  };

  memberDelete = () => {
    if (this.state.isAuthorizedCaregiver === true) {
      this.deleteAuthorizedInBackend();
    } else if (this.state.isSelf === true) {
      this.deleteSelfMemberInBackend();
    }
  };
  deleteSelfMemberInBackend = (e) => {
    const header = store.getState().header.header;
    const postObject = {
      header: header,
      memberVo: {
        memberDetailsId: this.memberDetails.member,
        familyMemberMemberId: this.memberDetails.familyMemberMemberId,
      },
    };
    FetchServerData.callPostService('/memberms/deleteMember', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          this.setState({ open: false });
          this.props.history.push({
            pathname: '/home',
          });
        } else {
        }
      })
      .catch((error) => {});
  };
  deleteMemberInBackend = (e) => {
    const header = store.getState().header.header;
    const postObject = {
      header: header,
      familyMember: {
        id: this.selectedMemberData?.familyMemberMemberId,
        memberId: this.state.id,
      },
    };
    FetchServerData.callPostService('/caregiverms/delete', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          this.setState({ open: false });
          this.props.history.push({
            pathname: '/home',
          });
        } else {
        }
      })
      .catch((error) => {});
  };
  deleteAuthorizedInBackend = (e) => {
    const header = store.getState().header.header;
    const postObject = {
      header: header,
      memberVo: {
        id: this.memberDetails.member,
        // familyMemberMemberId: this.memberDetails.familyMemberMemberId,
      },
    };
    FetchServerData.callPostService('/memberms/deleteMemberForAuthorisedCaregiver', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          this.setState({ authorizedCaregiver: false });
          this.props.history.push({
            pathname: '/home',
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  closeFloatingButton() {
    this.setState({ displayFloatButton: false });
    let displayFloat = false;

    store.dispatch(helpDataAction.setHelpData(displayFloat));
  }
  updateProfile() {
    this.setState({
      viewProfile: false,
      editProfile: true,
    });
  }

  updateAddress() {
    this.setState({
      viewAddress: false,
      editAddress: true,
    });
  }
  editProfileCancel() {
    this.setState({
      viewProfile: true,
      editProfile: false,
    });
  }
  cancelEditAddress() {
    this.setState({
      viewAddress: true,
      editAddress: false,
    });
  }

  render() {
    if (this.state.action === 'edit') {
      return this.editMemberProfile();
    } else {
      return this.viewMemberProfile();
    }
  }
  updateEmail(e) {
    this.setState({
      showNoEmailText: false,
      showUpdateEmail: true,
      email: '',
    });
  }
  showProfileIcon(memberName) {
    let fullName = memberName;

    if (this.memberNamesList.includes(fullName) === false) {
      this.memberNamesList.push(fullName);
      store.dispatch(memberListAction.setMemberList(this.memberNamesList));
    }

    return <>{Utils.getMemberProfileName(fullName, this.memberNamesList.indexOf(fullName) % 26)}</>;
    return fullName;
  }

  viewMemberProfile() {
    if (this.state.render === false) {
      return <div />;
    } else {
      let firstName = null !== this.state.fname && undefined !== this.state.fname ? this.state.fname : '';
      let lastName = null !== this.state.lname && undefined !== this.state.lname ? this.state.lname : '';
      let memberName = firstName + ' ' + lastName;
      return (
        <>
          <MenuWithBreadScrum breadCrumb={this.breadCrumb} history={this.props.history} name={this.title} />
          <Steps
            enabled={this.state.stepsEnabled}
            steps={this.state.steps}
            initialStep={this.state.initialStep}
            onExit={this.onExit}
          />
          <Row
            md={12}
            style={{
              font: 'scan',
              margin: '-1em auto auto',
              marginTop: '0.5em',
            }}
          >
            <Col md={11} style={{ float: 'left', fontSize: 'large', alignSelf: 'center' }}></Col>
            <Col md={1} style={{ float: 'right', margin: 'auto' }}></Col>
          </Row>
          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={7} style={{ position: 'relative', padding: '5px' }}>
              {this.state.viewProfile && (
                <>
                  <Card
                    style={{
                      height: '100%',
                      borderRadius: '20px',
                      boxShadow:
                        '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2)',
                    }}
                  >
                    <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                      <Row>
                        <Col md={10}>
                          <Label
                            style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.7em', marginTop: '0.5em' }}
                          >
                            Member Profile Details
                          </Label>
                        </Col>
                        <Col md={2}>
                          {this.state.showEditButton && <EditIconButton onClick={this.updateProfile.bind(this)} />}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row style={{ textAlign: 'left' }}>
                        <Col md={3} style={{ margin: 'auto', textAlign: 'center' }}>
                          <div
                            className="user-panel"
                            style={{ color: '#fff', textAlign: 'center', marginTop: '1em', cursor: 'pointer' }}
                          >
                            {this.showProfileIcon(memberName)}
                          </div>
                        </Col>

                        <Col md={8} style={{ margin: '0.5em 0em -2em 0em' }}>
                          <Row>
                            <Col md={2}>
                              {' '}
                              <label>Name</label>
                            </Col>
                            <Col md={10} style={{ textAlign: 'left', textTransform: 'capitalize' }}>
                              {' '}
                              {this.state.fname + ' ' + this.state.lname}
                            </Col>
                          </Row>
                          {this.state.memberWithoutEmail === false ? (
                            <>
                              <Row>
                                <Col md={2}>
                                  <label>Email</label>
                                </Col>{' '}
                                <Col md={10} style={{ textAlign: 'left' }}>
                                  {undefined !== this.state.email ? (
                                    <a href={'mailto:' + this.state.email} target="_top">
                                      {this.state.email}
                                    </a>
                                  ) : (
                                    ''
                                  )}
                                </Col>
                              </Row>
                            </>
                          ) : (
                            <Row>
                              <Col md={2}>
                                <label>Email</label>
                              </Col>{' '}
                              <Col md={10} style={{ textAlign: 'left' }}>
                                {this.state.showNoEmailText && (
                                  <>
                                    <div style={{ marginTop: '-0.3em' }}>
                                      <span>No Email</span>&nbsp;
                                      <span>
                                        {' '}
                                        <Button
                                          type={this.state.type}
                                          variant="contained"
                                          size="small"
                                          style={{ background: '#717373', color: '#fff', marginRight: '1rem' }}
                                          onClick={(e) => this.updateEmail(e)}
                                        >
                                          Update Email
                                        </Button>
                                      </span>
                                    </div>
                                  </>
                                )}
                                {this.state.showUpdateEmail && (
                                  <>
                                    <ValidatorForm
                                      style={{ width: '100%' }}
                                      ref="form"
                                      onSubmit={this.updateEmailProfileInBackend.bind(this)}
                                    >
                                      <div style={{ marginTop: '-0.9em' }}>
                                        <span>
                                          <TextField
                                            margin="normal"
                                            variant="outlined"
                                            required
                                            fullWidth
                                            id="email"
                                            inputProps={{ maxLength: 64 }}
                                            onChange={this.handleEmailChange.bind(this)}
                                            label="Email"
                                            name="email"
                                            value={this.state.email}
                                          />
                                        </span>
                                        <span>
                                          {' '}
                                          <Button
                                            type="submit"
                                            variant="contained"
                                            size="small"
                                            style={{ background: '#ef6603', color: '#fff', marginRight: '1rem' }}
                                          >
                                            Update
                                          </Button>
                                          <Button
                                            type={this.state.type}
                                            variant="contained"
                                            size="small"
                                            style={{ background: '#717373', color: '#fff', marginRight: '1rem' }}
                                            onClick={(e) => this.cancelEmailProfile(e)}
                                          >
                                            Cancel
                                          </Button>
                                        </span>
                                      </div>
                                    </ValidatorForm>
                                  </>
                                )}
                              </Col>
                            </Row>
                          )}
                          <Row>
                            <Col md={2}>
                              <label>Phone</label>
                            </Col>
                            <Col md={10} style={{ textAlign: 'left' }}>
                              {undefined !== this.state.phone ? (
                                <a href={'tel:' + this.state.phone} target="_top">
                                  {Utils.displayUsaPhoneNumberFormate(this.state.phone)}
                                </a>
                              ) : (
                                ''
                              )}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </>
              )}
              {this.state.editProfile && (
                <Card style={{ height: '100%', borderRadius: '20px' }}>
                  <>
                    <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                      <Row>
                        <Col md={12}>
                          <Label style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em' }}>
                            Member Profile Details
                          </Label>
                        </Col>
                      </Row>
                    </CardHeader>
                    <ValidatorForm
                      style={{ width: '100%' }}
                      ref="form"
                      onSubmit={this.updateProfileInBackend.bind(this)}
                    >
                      <CardBody>
                        <Row md={12} style={{ padding: '0.5em' }}>
                          <Col md={12}>
                            <Row>
                              <Col md={6}>
                                <TextField
                                  margin="normal"
                                  variant="outlined"
                                  required
                                  fullWidth
                                  id="fname"
                                  onChange={this.handleFnameChange.bind(this)}
                                  label="First Name"
                                  name="fname"
                                  inputProps={{ maxLength: 128, style: { textTransform: 'capitalize' } }}
                                  autoFocus
                                  value={this.state.fname}
                                />
                              </Col>
                              <Col md={6}>
                                <TextField
                                  margin="normal"
                                  variant="outlined"
                                  required
                                  fullWidth
                                  id="lname"
                                  inputProps={{ maxLength: 128, style: { textTransform: 'capitalize' } }}
                                  onChange={this.handleLnameChange.bind(this)}
                                  label="Last Name"
                                  name="lname"
                                  value={this.state.lname}
                                />
                              </Col>
                            </Row>

                            <Row>
                              <Col md={6}>
                                <TextValidator
                                  fullWidth
                                  required
                                  margin="normal"
                                  variant="outlined"
                                  label="Email"
                                  type="email"
                                  inputProps={{ maxLength: 64 }}
                                  value={this.state.email}
                                  onChange={this.handleEmailChange.bind(this)}
                                  onBlur={this.props.onBlur}
                                  autoComplete="off"
                                  validators={['required', 'isEmail']}
                                  errorMessages={['this field is required', 'email is not valid']}
                                />
                              </Col>

                              <Col md={6}>
                                <TextField
                                  margin="normal"
                                  variant="outlined"
                                  fullWidth
                                  id="phone"
                                  onChange={this.handlePhoneChange.bind(this)}
                                  label="Phone"
                                  name="phone"
                                  value={this.state.phone}
                                />
                              </Col>
                            </Row>
                            <Row></Row>
                          </Col>
                        </Row>
                      </CardBody>
                      <CardFooter>
                        <div style={{ textAlign: 'center' }}>
                          <SaveButton type="submit" />
                          &nbsp;
                          <CancelIconButton type="button" onClick={(e) => this.editProfileCancel(e, this.state.id)} />
                        </div>
                      </CardFooter>
                    </ValidatorForm>
                  </>
                </Card>
              )}
            </Col>
            <Col md={5} style={{ position: 'relative', padding: '5px' }}>
              {this.state.viewAddress && (
                <Card
                  style={{
                    height: '100%',
                    borderRadius: '20px',
                    boxShadow:
                      '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2)',
                  }}
                >
                  <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                    <Row>
                      <Col md={10}>
                        <Label style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.7em', marginTop: '0.5em' }}>
                          Address
                        </Label>
                      </Col>
                      <Col md={2}>
                        {' '}
                        {this.state.showEditButton && <EditIconButton onClick={this.updateAddress.bind(this)} />}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={1}>
                        <Tooltip title="Address" aria-label="add">
                          <PinDropIcon style={{ fontSize: '25px' }} />
                        </Tooltip>
                      </Col>
                      <Col md={11}>
                        <Row>{Utils.getAddressAsText(this.state.address)}</Row>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '1em' }}>
                      <Col md={1}>
                        <Tooltip title="Time Zone" aria-label="add">
                          <AccessAlarmTwoToneIcon style={{ fontSize: '25px', color: '#5DADE2' }} />
                        </Tooltip>
                      </Col>
                      <Col md={11} style={{ padding: '0em' }}>
                        {this.state.timezone.toString().trim() + ' (' + this.state.timeZoneAbbreviation + ')'}
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              )}
              {this.state.editAddress && (
                <Card
                  style={{
                    height: '100%',
                    borderRadius: '20px',
                    boxShadow:
                      '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2)',
                  }}
                >
                  <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                    <Row>
                      <Col md={12}>
                        <Label style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.7em', marginTop: '0.5em' }}>
                          Address
                        </Label>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {this.state.isCreateAddress && (
                      <AddressWithTimezone
                        ref={this.addressRef}
                        data={this.state.address}
                        onChange={this.handleAddressChange.bind(this)}
                        action="create"
                        pageId="creataccount"
                        isMember="MemberProfile"
                      />
                    )}
                    {this.state.isEditAddress && (
                      <AddressWithTimezone
                        ref={this.addressRef}
                        data={this.state.address}
                        onChange={this.handleAddressChange.bind(this)}
                        action="create"
                        isMember="MemberProfile"
                      />
                    )}
                  </CardBody>
                  <CardFooter>
                    <div style={{ textAlign: 'center' }}>
                      <SaveButton type="submit" onClick={(e) => this.updateProfileAddressInBackend(e, this.state.id)} />
                      &nbsp;
                      <CancelIconButton type="button" onClick={(e) => this.cancelEditAddress(e)} />
                    </div>
                  </CardFooter>
                </Card>
              )}
            </Col>
          </Row>
          {this.state.viewProfile && (
            <Row>
              <Col md={12} style={{ textAlign: 'center' }}>
                {this.state.isSelf === false && (
                  <Button
                    variant="contained"
                    size="small"
                    // type="submit"
                    style={{ background: '#138D75', color: '#fff', textTransform: 'capitalize' }}
                    onClick={this.handleClickOpen.bind(this)}
                  >
                    Remove self from circle
                  </Button>
                )}
                &nbsp;
                {this.state.isAuthorizedCaregiver && (
                  <Button
                    variant="contained"
                    size="small"
                    // type="submit"
                    style={{ background: '#ef6603', color: '#fff', textTransform: 'capitalize' }}
                    onClick={this.handleOpenAuthorized.bind(this, 'Authorized')}
                  >
                    Delete Circle
                  </Button>
                )}
                &nbsp;
                {this.state.isSelf && (
                  <Button
                    variant="contained"
                    size="small"
                    // type="submit"
                    style={{ background: '#138D75', color: '#fff', textTransform: 'capitalize' }}
                    onClick={this.handleOpenAuthorized.bind(this, 'self')}
                  >
                    Delete Circle
                  </Button>
                )}
              </Col>
            </Row>
          )}
          {this.state.isFreeUser === true && (
            <>
              {' '}
              <Row style={{ position: 'relative', margin: '0em 1em 1em 0em' }}>
                <Col md={12} style={{ position: 'relative' }}></Col>
              </Row>
            </>
          )}{' '}
          <Dialog
            open={this.state.open}
            onClose={this.handleClose.bind(this)}
            onHide={this.close}
            style={{ padding: '1em' }}
          >
            <DialogContent>
              <Row style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                <Col md={12} style={{ textAlign: 'center' }}>
                  <ErrorOutlineIcon
                    style={{
                      border: '2px solid #fff',
                      borderRadius: '25%',
                      background: '#066f72 !important',
                      color: 'red',
                      fontSize: '50px',
                    }}
                  />
                </Col>
              </Row>
              {this.state.isAuthorizedCaregiver === true ? (
                <span style={{ textAlign: 'center' }}>
                  <h2 style={{ fontSize: '17px', lineHeight: '1.3em' }}>
                    Dear {this.loginUserName}
                    <br></br>
                    You are about to remove yourself from Circle of{' '}
                    <b style={{ color: '#0f666b' }}>{this.memberName}</b>.<br></br> Once removed, you cannot view or
                    monitor Appointments, Incidents, Purchases, Notes and Communications associated with{' '}
                    <b style={{ color: '#0f666b' }}>{this.memberName}</b>.<br></br>
                    Please pass the responsibility on to another Family Member by updating their role to Authorized
                    caregiver if you are the only Authorized caregiver of{' '}
                    <b style={{ color: '#0f666b' }}>{this.memberName}</b>
                  </h2>
                </span>
              ) : (
                <span style={{ textAlign: 'center' }}>
                  <h2 style={{ fontSize: '17px', lineHeight: '1.3em' }}>
                    Dear <b style={{ color: '#0f666b' }}>{this.loginUserName}</b>
                    <br></br>
                    You are about to remove yourself from Circle of{' '}
                    <b style={{ color: '#0f666b' }}>{this.memberName}</b>.<br></br>Once removed, you cannot view or
                    monitor Appointments, Incidents, Purchases, Notes and Communications associated with{' '}
                    <b style={{ color: '#0f666b' }}>{this.memberName}</b>.<br></br>
                    Do you want to continue?
                  </h2>
                </span>
              )}
            </DialogContent>
            <DialogActions style={{ marginTop: '1em', padding: '2em', marginLeft: 'auto', marginRight: 'auto' }}>
              <Button
                variant="contained"
                size="large"
                style={{ background: '#0f666b', color: '#fff', marginRight: '1rem' }}
                startIcon={<SaveIcon />}
                onClick={(e) => this.toDeleteMemberConfirmationWindow(e, 'caregiver')}
              >
                Confirm
              </Button>
              <Button
                variant="contained"
                size="large"
                style={{ background: '#717373', color: '#fff', marginRight: '1rem' }}
                startIcon={<CloseIcon />}
                onClick={this.handleClose.bind(this)}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.authorizedCaregiver}
            onClose={this.handleAuthorizedClose.bind(this)}
            onHide={this.close}
            style={{ padding: '1em' }}
          >
            <DialogContent>
              <Row style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                <Col md={12} style={{ textAlign: 'center' }}>
                  <ErrorOutlineIcon
                    style={{
                      border: '2px solid #fff',
                      borderRadius: '25%',
                      background: '#066f72 !important',
                      color: 'red',
                      fontSize: '50px',
                    }}
                  />
                </Col>
              </Row>

              <span style={{ textAlign: 'center' }}>
                <h2 style={{ fontSize: '17px', lineHeight: '1.3em' }}>
                  You are about to delete all Family Care Information about{' '}
                  <b style={{ color: '#0f666b' }}>{this.memberName}</b>. All Appointments, Incidents, Purchases, Notes
                  and Communications associated with <b style={{ color: '#0f666b' }}>{this.memberName}</b> will be
                  permanently removed.<br></br> Are you sure, you wish to continue as this information cannot be
                  recovered once deleted?
                </h2>
              </span>
            </DialogContent>
            <DialogActions style={{ marginTop: '1em', padding: '2em', marginLeft: 'auto', marginRight: 'auto' }}>
              <Button
                variant="contained"
                size="large"
                style={{ background: '#0f666b', color: '#fff', marginRight: '1rem' }}
                startIcon={<SaveIcon />}
                onClick={(e) => this.toDeleteAuthorizedConfirmationWindow(e)}
              >
                Confirm
              </Button>
              <Button
                variant="contained"
                size="large"
                style={{ background: '#717373', color: '#fff', marginRight: '1rem' }}
                startIcon={<CloseIcon />}
                onClick={this.handleAuthorizedClose.bind(this)}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.confirmWindowOpen}
            onClose={this.handleConfirmWindowClose.bind(this)}
            onHide={this.close}
            style={{ padding: '1em' }}
          >
            <DialogContent style={{ marginTop: '2em' }}>
              <span style={{ textAlign: 'center' }}>
                <h2 style={{ fontSize: '20px' }}>
                  Please enter your Password as this is the last step in removing all information about{' '}
                  <b style={{ color: '#0f666b' }}>{this.memberName}</b>
                </h2>
              </span>
              <Row>
                <Col md={8} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                  <TextField
                    variant="outlined"
                    margin="normal"
                    required
                    fullWidth
                    name="password"
                    label="Enter Password"
                    type={this.state.showPassword ? 'text' : 'password'}
                    id="password"
                    autoComplete="off"
                    onChange={this.handlePasswordChange.bind(this)}
                    value={this.state.password}
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={this.handleClickShowPassword.bind(this)}
                          >
                            {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                  />
                </Col>
              </Row>
            </DialogContent>
            <DialogActions style={{ marginTop: '1em', padding: '2em', marginLeft: 'auto', marginRight: 'auto' }}>
              <Button
                variant="contained"
                size="large"
                style={{ background: '#0f666b', color: '#fff', marginRight: '1rem' }}
                startIcon={<SaveIcon />}
                onClick={(e) => this.confirmMemberDeleteInBackend(e)}
              >
                Confirm
              </Button>
              <Button
                variant="contained"
                size="large"
                style={{ background: '#717373', color: '#fff', marginRight: '1rem' }}
                startIcon={<CloseIcon />}
                onClick={this.handleConfirmWindowClose.bind(this)}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    }
  }
}
export default MemberProfile;
