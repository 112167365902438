// import MaterialTable from 'material-table';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import UiUtils from '../../../provider/UiUtils';
import Utils from '../../../provider/Utils';
import adminFccPageStateAction from '../../../redux/adminPanel/adminFccPageState/adminFccPageStateAction';
import applicationStateAction from '../../../redux/applicationState/applicationStateAction';
import store from '../../../redux/store';
import AddIconButton from '../../components/buttons/AddIconButton';
import AdminMenuWithBreadScrum from '../adminMenu/AdminMenuWithBreadScrum';
import MaterialTable from '@material-table/core';
import PtsAlert from '../../components/ptsAlert/PtsAlert';
export class FccProfileManagementList extends Component {
  title = 'Profile Management List';
  breadCrumb = [{ pageid: '', uiname: 'FCC Profile Management List' }];
  header = store.getState().adminHeader.adminHeader;
  profileState = store.getState().applicationState.profileState;
  profileStateDetails = {};
  constructor(props) {
    super(props);
    let action = props.action;
    let pageId = '';
    if (
      null !== props.history &&
      props.history !== undefined &&
      props.history.location !== null &&
      props.history.location.state !== null &&
      props.history.location.state !== undefined
    ) {
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
    }
    store.dispatch(adminFccPageStateAction.setAdminFccPageState({}));

    let profileApplicationState = {};
    if (this.profileState) {
      profileApplicationState = Object.getOwnPropertyNames(this.profileState);
    }
    if (profileApplicationState.length > 0) {
      this.state = {
        email: '',
        password: '',
        showPassword: false,
        action: action,
        pageId: pageId,
        isPaging: false,
        initialPage:
          this.profileState.initialPage && this.profileState.initialPage > 0 ? this.profileState.initialPage : 0,
        profileList: [],
        pageSize: this.profileState.pageSize && this.profileState.pageSize ? this.profileState.pageSize : 10,
        profileId: this.profileState.profileId,
        render: false,
        getCreatePermission: false,
        getUpdatePermission: false,
        getDeletePermission: false,
      };
    } else {
      this.state = {
        email: '',
        password: '',
        showPassword: false,
        action: action,
        pageId: pageId,
        isPaging: false,
        initialPage: 0,
        profileList: [],
        pageSize: 10,
        render: false,
        getCreatePermission: false,
        getUpdatePermission: false,
        getDeletePermission: false,
      };
    }
    this.getProfileManagementList();
  }
  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }
  getProfileManagementList() {
    const postObject = {
      header: this.header,
    };

    FetchServerData.callPostService('/profilems/getList', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let profileList = output.data.profileList;
        let getPermission = Utils.getRolePermission(output.data.privilegeList);
        let getCreatePermission = getPermission[1];
        let getUpdatePermission = getPermission[3];
        let getDeletePermission = getPermission[4];
        let isPaging = false;
        if (profileList.length > 10) {
          isPaging = true;
        }
        this.setState({
          isPaging: isPaging,
          profileList: profileList,
          render: true,
          initialPage: this.state.initialPage,
          pageSize: this.state.pageSize,
          getCreatePermission: getCreatePermission,
          getUpdatePermission: getUpdatePermission,
          getDeletePermission: getDeletePermission,
        });
      } else {
      }
    });
  }

  toProfileDetails(e, rowData) {
    let profileState = {
      profileId: rowData.id,
    };
    store.dispatch(applicationStateAction.setApplicationState({ profileState }));
    this.props.history.push({
      pathname: '/viewProfileManagement',
      state: { action: 'view', profileId: rowData.id, name: rowData.name },
    });
  }

  addProfile(e) {
    let adminFccPageState = {
      id: '',
      action: 'create',
      title: 'Add Profile',
      pageId: 'profileList',
    };
    store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
    this.props.history.push({
      pathname: '/addProfileManagement',
      state: { action: 'create', title: 'Add Profile', profileId: '', pageId: 'profileList' },
    });
  }
  editProfile(e, data) {
    let profileState = {
      profileId: data.id,
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
    };
    store.dispatch(applicationStateAction.setApplicationState({ profileState }));
    let adminFccPageState = {
      id: data.id,
      action: 'edit',
      title: 'Edit Profile',
      pageId: 'profileList',
    };
    store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
    this.props.history.push({
      pathname: '/editProfileManagement',
      state: { action: 'edit', title: 'Edit Profile', profileId: data.id, pageId: 'profileList' },
    });
  }

  deleteProfileToBackend = (e, data) => {
    const postObject = {
      header: this.header,
      profile: {
        id: data.id,
      },
    };
    FetchServerData.callPostService('/profilems/delete', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        PtsAlert.success(output.message);
        let profileList = output.data.profileList;
        let isPaging = false;
        if (profileList.length > this.state.pageSize) {
          isPaging = true;
        }
        this.setState({
          profileList: profileList,
          isPaging: isPaging,
        });
      } else {
      }
    });
  };

  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          {/* <Header /> */}
          {/* Page content */}
          <AdminMenuWithBreadScrum breadCrumb={this.breadCrumb} history={this.props.history} name={this.title} />

          <Row style={{ position: 'relative', margin: '1em 0em', maxWidth: '100%' }}>
            <Col md={12}>
              <MaterialTable
                onRowClick={(event, rowData) => {
                  this.toProfileDetails(event, rowData);
                  event.stopPropagation();
                }}
                icons={UiUtils.getMaterialTableIcons()}
                title=""
                localization={{
                  body: {
                    emptyDataSourceMessage: Utils.communizeTableText(),
                  },
                }}
                columns={[
                  {
                    title: '#',
                    render: (rowData) => rowData.tableData.index + 1,
                    width: '5%',
                    align: 'left',
                    cellStyle: {
                      width: '6%',
                    },
                  },
                  {
                    title: 'Fcc Profile',
                    field: 'name',
                    align: 'left',
                    cellStyle: {
                      width: '76%',
                    },
                  },
                  {
                    title: 'Status',
                    field: 'status',
                  },
                ]}
                data={this.state.profileList}
                onPageChange={(page, pageSize) => {
                  this.handleMaterialTablePageChange(page, pageSize);
                }}
                options={{
                  sorting: true,
                  actionsColumnIndex: -1,
                  pageSize: this.state.pageSize,
                  pageSizeOptions: [5, 10, 20, 50, 100],
                  paging: this.state.profileList.length > this.state.pageSize,
                  initialPage: this.state.initialPage,
                  headerStyle: {
                    backgroundColor: '#066f72',
                    fontWeight: 'bold',
                    color: '#fff',
                    align: 'left',
                  },

                  filtering: false,
                  // paging: this.state.isPaging,
                  rowStyle: (rowData) => ({
                    backgroundColor: rowData.id === this.state.profileId ? '#FEF5E7' : '#fff',
                    height: '3em',
                    textTransform: 'capitalize',
                  }),
                  tableLayout: 'auto',
                }}
                actions={[
                  {
                    icon: () => (
                      <span className="newDoctor">
                        {' '}
                        {this.state.getCreatePermission && <AddIconButton btnName="Add Profile" />}
                      </span>
                    ),
                    tooltip: 'Add Profile',
                    isFreeAction: true,
                    onClick: (event) => this.addProfile(),
                  },
                ]}
              />
            </Col>
          </Row>
        </>
      );
    }
  }
}
export default FccProfileManagementList;
