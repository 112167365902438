import { CLEAR_HELP_DATA, GET_HELP_DATA, SET_HELP_DATA } from './displayHelpTypes';

const initialState = {
  help: true,
};

const displayHelpReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HELP_DATA:
      return {
        ...state,
      };
    case SET_HELP_DATA:
      return {
        ...state,
        help: action.payload,
      };

    case CLEAR_HELP_DATA:
      return {
        ...state,
        help: null,
      };
    default:
      return state;
  }
};

export default displayHelpReducer;
