import { CLEAR_MEMBER_Home_URL, GET_MEMBER_Home_URL, SET_MEMBER_Home_URL } from './memberHomeUrlTypes';

const getMemberHomeUrl = () => {
  return {
    type: GET_MEMBER_Home_URL,
  };
};

const setMemberHomeUrl = (data) => {
  return {
    type: SET_MEMBER_Home_URL,
    payload: data,
  };
};

const clearMemberHomeUrl = (data) => {
  return {
    type: CLEAR_MEMBER_Home_URL,
    payload: data,
  };
};
const memberHomeUrlAction = { getMemberHomeUrl, setMemberHomeUrl, clearMemberHomeUrl };

export default memberHomeUrlAction;
