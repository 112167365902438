import { CLEAR_SELECTED_PLAN, GET_SELECTED_PLAN, SET_SELECTED_PLAN } from './selectedPlanType';

const getSelectedPlan = () => {
  return {
    type: GET_SELECTED_PLAN,
  };
};

const setSelectedPlan = (data) => {
  return {
    type: SET_SELECTED_PLAN,
    payload: data,
  };
};

const clearSelectedPlan = (data) => {
  return {
    type: CLEAR_SELECTED_PLAN,
    payload: data,
  };
};
const selectedPlanAction = { getSelectedPlan, setSelectedPlan, clearSelectedPlan };

export default selectedPlanAction;
