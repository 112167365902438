/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import React, { Component } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import Button from '@material-ui/core/Button';

import fccLogo from '../../../assets/images/fccLogo.png';
import FetchServerData from '../../../provider/FetchServerData';
import adminAppUserDataAction from '../../../redux/adminPanel/appUserData/adminAppUserDataAction';
import adminHeaderAction from '../../../redux/adminPanel/header/adminHeaderAction';
import adminStaticDataAction from '../../../redux/adminPanel/staticData/adminStaticDataAction';
import store from '../../../redux/store';
import './AdminLogin.scss';
import Utils from '../../../provider/Utils';

export class AdminLogin extends Component {
  constructor(props) {
    super(props);
    let action = props.action;
    let pageId = '';
    let browserName = Utils.getBrowserInfo();
    if (
      null !== props.history &&
      props.history !== undefined &&
      props.history.location !== null &&
      props.history.location.state !== null &&
      props.history.location.state !== undefined
    ) {
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
    }

    this.state = {
      email: '',
      password: '',
      showPassword: false,
      action: action,
      pageId: pageId,
      browserName: browserName,
    };
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  handlePasswordChange(e) {
    this.setState({
      password: e.target.value,
    });
  }

  gotToButton() {
    document.getElementById('loginButton').focus();
  }
  gotToPassword() {
    document.getElementById('password').focus();
  }

  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  goToPage() {
    this.props.history.push({
      pathname: '/APForget',
      state: { email: this.state.email },
    });
  }

  processLoginSuccess(output_data) {
    this.setState({
      showSuccessAlert: true,
      showSuccessLoader: false,
    });
    let headerObj = output_data.header;
    store.dispatch(adminHeaderAction.setAdminHeader(headerObj));
    let header = headerObj;
    this.loadDashboardPage(header, output_data);
  }

  async loadDashboardPage(header, output) {
    let menus = output.appuser.profile.menus;
    await this.setStaticData(header)
      .then((data) => {})
      .catch((error) => {
        this.setState({
          errorColor: '#FF0000',
          errorMessage: error,
        });
      });
    /* Update Token Once User Login successfully */
    for (let i = 0; i < menus.length; i++) {
      const menu = menus[i];
      if (menu.isactive) {
        this.props.history.push({
          pathname: menu.pageid,
          state: { notification: true, pageId: 'loginPage' },
        });
        break;
      }
    }
  }

  async setStaticData(header) {
    return new Promise(function (accept, reject) {
      FetchServerData.callPostService('/staticms/getAll', header)
        .then((output) => {
          if (output.status === 'SUCCESS') {
            store.dispatch(adminStaticDataAction.setAdminStaticData(output.data));
            accept(1);
          } else {
            reject(output.message);
          }
        })
        .catch((error) => {
          reject('Cannot Fetch Data From Server, Error:' + error.message);
        });
    });
  }

  loginAccountInBackend() {
    // e.preventDefault();
    // let emailPhone = '';
    // if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(this.state.email)) {
    //   emailPhone = this.state.email;
    // }
    const postObject = {
      header: { appclient: 'W', browser: this.state.browserName },
      appuser: {
        email: this.state.email,
        credential: this.state.password,
      },
    };
    FetchServerData.callPostService('/adminpanelAppuserms/login', postObject)
      .then((response) => {
        let output = response;
        if (output.status === 'SUCCESS') {
          output.data.appuser.isUserLogin = 'admin';
          store.dispatch(adminAppUserDataAction.setAdminUser(output.data.appuser));
          this.processLoginSuccess(output.data);
        } else {
        }
      })
      .catch((error) => {});
  }

  render() {
    return (
      <>
        <Col md={12}>
          <Row>
            <Col md={6} style={{ marginTop: '10em' }}>
              <img alt="" src={fccLogo} style={{ height: '468px', width: '468px' }} />
            </Col>
            <Col md={6} style={{ marginTop: '11em' }}>
              <Card className="shadow border-0">
                <CardHeader className="bg-transparent">
                  <div className="adminLoginHeader">
                    <p>
                      <b>Family Care Circle</b>
                    </p>
                    <p> Admin Panel</p>
                  </div>
                </CardHeader>
                <CardBody className="loginCardBody">
                  <ValidatorForm
                    ref="form"
                    onSubmit={this.loginAccountInBackend.bind(this)}
                    style={{ width: '100%', padding: '1em' }}
                  >
                    <Row>
                      <Col md={12}>
                        <TextValidator
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="email"
                          value={this.state.email}
                          label="Email "
                          name="email"
                          type="text"
                          autoComplete="email"
                          autoFocus
                          style={{ width: '100%' }}
                          validators={['required', 'isEmail']}
                          errorMessages={['this field is required', 'email is not valid']}
                          onChange={this.handleEmailChange.bind(this)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          value={this.state.password}
                          name="password"
                          label="Password"
                          type={this.state.showPassword ? 'text' : 'password'}
                          id="password"
                          autoComplete="current-password"
                          onChange={this.handlePasswordChange.bind(this)}
                          InputProps={{
                            // <-- This is where the toggle button is added.
                            endAdornment: (
                              <InputAdornment position="end" onFocus={this.gotToButton.bind(this)}>
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowPassword.bind(this)}
                                >
                                  {this.state.showPassword ? <VisibilityOff /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Col>
                    </Row>
                    <div className="loginCardFooter">
                      <Button id="loginButton" type="submit" variant="contained">
                        Log In
                      </Button>
                      <Row className="forgetPasswordRow">
                        <Col className="text-center" md="12">
                          <Link className="passwordLink" onClick={(e) => this.goToPage()}>
                            Forgot password ?
                          </Link>
                        </Col>
                      </Row>
                    </div>
                  </ValidatorForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </>
    );
  }
}
export default AdminLogin;
