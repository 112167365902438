import { CLEAR_USER_SUBSCRIBE_PLAN, GET_USER_SUBSCRIBE_PLAN, SET_USER_SUBSCRIBE_PLAN } from './userSubscribePlanType';

const initialState = {
  userSubscribePlan: {},
};

const userSubscribePlanReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_SUBSCRIBE_PLAN:
      return {
        ...state,
      };
    case SET_USER_SUBSCRIBE_PLAN:
      return {
        ...state,
        userSubscribePlan: action.payload,
      };

    case CLEAR_USER_SUBSCRIBE_PLAN:
      return {
        ...state,
        userSubscribePlan: null,
      };
    default:
      return state;
  }
};

export default userSubscribePlanReducer;
