import { CLEAR_FCC_PAGE_STATE, GET_FCC_PAGE_STATE, SET_FCC_PAGE_STATE } from './fccPageStateType';

const getFccPageState = () => {
  return {
    type: GET_FCC_PAGE_STATE,
  };
};

const setFccPageState = (data) => {
  return {
    type: SET_FCC_PAGE_STATE,
    payload: data,
  };
};

const clearFccPageState = (data) => {
  return {
    type: CLEAR_FCC_PAGE_STATE,
    payload: data,
  };
};
const fccPageStateAction = { getFccPageState, setFccPageState, clearFccPageState };
export default fccPageStateAction;
