import React, { Component } from 'react';
import Avatar, { ConfigProvider } from 'react-avatar';
import store from '../../../redux/store';
import PageAlertContext from '../../components/pageAlert/PageAlertContext';
import './AdminSidebar.scss';
import NavDivider from './components/NavDivider';
import NavDropdownItem from './components/NavDropdownItem';
import NavOverlay from './components/NavOverlay';
import NavSingleItem from './components/NavSingleItem';
import NavSpacer from './components/NavSpacer';
import ActiveMenuAction from '../../../redux/activeMenu/ActiveMenuAction';
import { connect } from 'react-redux';
export class SponsorSidebar extends Component {
  userDetails = store.getState().adminUserDetails.adminUserDetails;
  sponsorDetails = store.getState().adminSponsorDetails.adminSponsorDetails;
  adminFccPageState = store.getState().adminFccPageState.adminFccPageState;
  constructor(props) {
    super(props);
    let memberInfo = props.data;
    let userLogin = store.getState().adminUserDetails.adminUserDetails.isUserLogin;
    let memberMenus = [];
    let obj = {
      uiname: 'Sponsor Home',
      pageid: '/viewSponsor',
      icon: 'fa fa-home text-white',

      // id: 1,
    };
    if (userLogin === 'sponsor') {
      memberMenus = [
        {
          uiname: 'Sponsor Home',
          pageid: '/viewSponsor',
          icon: 'fa fa-home text-white',

          id: 1,
        },
        {
          uiname: 'Advertisement',
          pageid: '/advertisementList',
          icon: 'fa fa-adn text-white',
          id: 2,
        },
        {
          uiname: 'Sponsorship Plans',
          pageid: '/sponsorshipList',
          icon: 'fa fa-usd text-white',
          id: 3,
        },
        {
          uiname: 'Help',
          pageid: '/help',
          icon: 'fa fa-question-circle',
          id: 4,
        },
      ];
    } else {
      memberMenus = [
        // {
        //   uiname: 'Sponsor Home',
        //   pageid: '/viewSponsor',
        //   icon: 'fa fa-home text-white',

        //   id: 1,
        // },
        {
          uiname: 'Advertisement',
          pageid: '/advertisementList',
          icon: 'fa fa-adn text-white',
          id: 2,
        },
        {
          uiname: 'Sponsorship Plans',
          pageid: '/sponsorshipList',
          icon: 'fa fa-usd text-white',
          id: 3,
        },
        {
          uiname: 'Help',
          pageid: '/help',
          icon: 'fa fa-question-circle',
          id: 4,
        },
      ];
      let toUserMenuList = store.getState().adminUserDetails.adminUserDetails.profile.menus;
      for (let i = 0; toUserMenuList && i < toUserMenuList.length; i++) {
        obj.id = toUserMenuList[1].id;
      }
      memberMenus.unshift(obj);
    }

    let SponsorUser = '';
    if (this.userDetails.isUserLogin === 'admin') {
      if (this.adminFccPageState !== undefined) {
        SponsorUser = this.adminFccPageState.sponsorName;
      } else {
        SponsorUser = this.sponsorDetails.name;
      }
    } else if (this.userDetails.isUserLogin === 'sponsor') {
      SponsorUser = this.userDetails.sponsor.name;
    }
    for (let i = 0; i < memberMenus.length; i++) {
      memberMenus[i].helpClassName = 'sidebarMenu' + (i + 1);
    }
    let activeMenu = store.getState().activeMenu.activeMenu;
    if (undefined === activeMenu || null === activeMenu) {
      activeMenu = (memberMenus && memberMenus.length) > 0 ? memberMenus[0].id : -1;
    }
    this.state = {
      memberData: memberInfo,
      memberMenuList: memberMenus,
      SponsorUser: SponsorUser,
      activeMenu: activeMenu,
    };
  }

  toMemberDetails = (e) => {
    this.props.history.push('/memberProfile');
  };
  isEmpty(obj) {
    for (var key in obj) {
      if (obj.hasOwnProperty(key)) return false;
    }
    return true;
  }

  componentDidMount() {
    this.unsubscribe = store.subscribe(() => {
      let activeMenu = store.getState().activeMenu.activeMenu;
      this.setState({ activeMenu: activeMenu });
    });
  }
  componentWillUnmount() {
    this.unsubscribe();
  }
  selectedChildMenu = (menuid) => {
    for (let i = 0; i < this.state.memberMenuList.length; i++) {
      if (this.state.memberMenuList[i].id === menuid) {
        store.dispatch(ActiveMenuAction.setActiveMenu(menuid));
        this.setState({ activeMenu: menuid });
      }
    }
  };
  render() {
    const navItems = (items) => {
      // return items.map((item, index) => itemType(item, index));
      if (!this.isEmpty(items)) {
        return items.map((item, index) => itemType(item, index));
      }
    };
    const itemType = (item, index) => {
      if (!this.isEmpty(item)) {
        let isActive = false;
        if (item.id === this.state.activeMenu) {
          isActive = true;
        }
        return (
          <NavSingleItem item={item} key={index} onSelect={this.selectedChildMenu.bind(this)} isActive={isActive} />
        );
      }
    };

    return (
      <>
        <PageAlertContext.Consumer>
          {(consumer) => {
            const hasPageAlertClass = consumer.alert ? 'has-alert' : '';

            return (
              <div>
                <div
                  className={`app-sidebar ${hasPageAlertClass}`}
                  style={{
                    marginTop: '3.6em',
                    transition: 'width 0.3s linear',
                  }}
                >
                  <div
                    className="user-panel"
                    style={{ color: '#fff', textAlign: 'center', marginTop: '1em', cursor: 'pointer' }}
                    onClick={(e) => this.toMemberDetails(e)}
                  >
                    <span title="Member Profile">
                      <ConfigProvider colors={['#F39C12', '#A569BD', '#FA8072']}>
                        <Avatar
                          name={this.state.SponsorUser ? this.state.SponsorUser : ''}
                          round="60px"
                          className="memberIcon"
                        />
                      </ConfigProvider>
                    </span>

                    <p style={{ textTransform: 'capitalize' }}>
                      <br></br>
                      {this.state.SponsorUser ? this.state.SponsorUser : ''}
                    </p>
                  </div>
                  <nav>
                    <ul id="main-menu">
                      {navItems(this.state.memberMenuList)}

                      <NavSpacer />
                    </ul>
                  </nav>
                </div>
                {this.props.isSidebarCollapsed && <NavOverlay onClick={this.props.toggleSidebar} />}
              </div>
            );
          }}
        </PageAlertContext.Consumer>
      </>
    );
  }
}
const mapStateToProps = (state) => {
  return { activeMenu: state.activeMenu.activeMenu };
};

export default connect(mapStateToProps)(SponsorSidebar);
