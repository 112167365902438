import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';

import FetchServerData from '../../../../provider/FetchServerData';

import store from '../../../../redux/store';
import PtsAlert from '../../../components/ptsAlert/PtsAlert';
import '../AdTemplate2/Template2.scss';
import template1 from '../../../../assets/images/AdTemplate2/bgAd01.jpg';

class Template2 extends Component {
  // sponsorUser = store.getState().Sponsorship.Sponsorship;
  header = store.getState().adminHeader.adminHeader;
  advertisementObject = {
    heading: 'Heading',
    subHeading: 'Subheading',
    message: 'Lorem, ipsum dolor sit amet consectetur adipisicing elit.',
    website: '',
  };
  constructor(props) {
    // let pageId = props.pageId;
    let action = props.action;
    let advertisementDetails = props.data;
    super(props);
    console.log('this.state', action);
    this.state = {
      action: action,
      sponsorUser: this.sponsorUser,
      // pageId: pageId,
      advertisementId: this.props.advertisementId,
      heading: advertisementDetails ? advertisementDetails.heading : '',
      subHeading: advertisementDetails ? advertisementDetails.subheading : '',
      message: advertisementDetails ? advertisementDetails.message : '',
      website: advertisementDetails ? advertisementDetails.website : '',
      createHeading: advertisementDetails ? advertisementDetails.heading : this.advertisementObject.heading,
      createSubHeading: advertisementDetails ? advertisementDetails.subheading : this.advertisementObject.subHeading,
      createMessage: advertisementDetails ? advertisementDetails.message : this.advertisementObject.message,
      createWebsite: advertisementDetails ? advertisementDetails.website : this.advertisementObject.website,
      onChange: this.props.onChange,
      imagePosition: '',
      leftImageFile: this.props.data ? this.props.data.leftimage : '',
      backgroundImageFile: this.props.data ? this.props.data.backgroundimage : '',
      showRemoveButton: false,
      OnSuccess: this.props.OnSuccess,
    };
  }
  // OnSuccess(e) {
  //   if (undefined !== this.state.OnSuccess && null !== this.state.OnSuccess) {
  //     this.state.OnSuccess(this.state);
  //   }
  // }
  handleHeadingChange(e) {
    this.setState({ heading: e.target.value });
  }
  handleSubHeadingChange(e) {
    this.setState({ subHeading: e.target.value });
  }
  handleWebsiteChange(e) {
    this.setState({ website: e.target.value });
  }
  handleCreatWebsiteChange(e) {
    this.setState({ createWebsite: e.target.value });
  }
  handleMessageChange(e) {
    this.setState({ message: e.target.value });
  }

  handleCreateMessageChange(e) {
    this.setState({ createMessage: e.target.value });
  }

  handleCreateSubHeadingChange(e) {
    this.setState({ createSubHeading: e.target.value });
  }

  handleCreateHeadingChange(e) {
    this.setState({ createHeading: e.target.value });
  }

  backgroundImageFileHandler(position, e) {
    var file = e.target.files[0];
    let reader = new FileReader();
    this.setState({
      imagePosition: position.toString(),
      selectedFile: e.target.files[0],
    });
    reader.onload = (e) => {
      let image = e.target.result;
      let img = image.split(',');
      this.setImgae = img[1];

      const formData = new FormData();

      // Update the formData object
      formData.append('file', this.state.selectedFile, this.state.selectedFile.name);
      formData.append('sessionid', this.header.sessionid);
      formData.append('sid', this.header.sessionid);
      formData.append('imageposition', position);
      formData.append('uid', this.header.uid);
      formData.append('adid', this.props.advertisementId);
      formData.append('appclient', 'W');

      FetchServerData.callPostService('/sponsorAdvertisementms/uploadImage', formData).then((output) => {
        if (output.status === 'SUCCESS') {
          // let imageDetails = output.data;

          // this.setState({
          //   action: 'viewTemplate',
          // });
          PtsAlert.success(output.message);
        } else {
        }
      });
      this.setState({ backgroundImageFile: this.setImgae });
    };
    reader.readAsDataURL(file);
  }
  editBackgroundImageFileHandler(position, e) {
    var file = e.target.files[0];
    let reader = new FileReader();
    this.setState({
      imagePosition: position.toString(),
      selectedFile: e.target.files[0],
    });
    reader.onload = (e) => {
      let image = e.target.result;
      let img = image.split(',');
      this.setImgae = img[1];

      const formData = new FormData();

      // Update the formData object
      formData.append('file', this.state.selectedFile, this.state.selectedFile.name);
      formData.append('sessionid', this.header.sessionid);
      formData.append('sid', this.header.sessionid);
      formData.append('imageposition', position);
      formData.append('uid', this.header.uid);
      formData.append('adid', this.props.advertisementId);
      formData.append('appclient', 'W');

      FetchServerData.callPostService('/sponsorAdvertisementms/uploadImage', formData).then((output) => {
        if (output.status === 'SUCCESS') {
          // let imageDetails = output.data;
          this.setState({
            action: 'edit',
          });
          PtsAlert.success(output.message);
        } else {
        }
      });
      this.setState({ backgroundImageFile: this.setImgae });
    };
    reader.readAsDataURL(file);
  }

  removeBackgroundImage(position, e) {
    const postObject = {
      header: this.header,
      advertisement: {
        id: this.state.advertisementId,
      },
      position: position,
    };

    FetchServerData.callPostService('/sponsorAdvertisementms/deleteImage', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        this.setState({ backgroundImageFile: '' });
      } else {
      }
    });
  }
  render() {
    console.log(this.state.action);
    if (this.state.action === 'create') {
      return this.createTemplate();
    } else if (this.state.action === 'edit') {
      return this.editTemplate();
    } else if (this.state.action === 'preView') {
      return this.preViewTemplate();
    } else if (this.state.action === 'view') {
      return this.viewTemplate();
    } else if (this.state.action === 'viewTemplate') {
      return this.viewTemplateWithImage();
    }
  }

  createTemplate() {
    return (
      <header
        id="Ad01-header"
        style={{
          backgroundImage: `url(${
            // global.config.projectName + "/AdTemplate2/bgAd01.jpg"
            template1
          })`,
        }}
      >
        <h1 id="Ad01-h1"> {this.state.createHeading}</h1>
        <h2 id="Ad01-h2">{this.state.createSubHeading}</h2>
        <p id="Ad01-p">{this.state.createMessage}</p>
        <p style={{ textAlign: 'center' }}>
          <a href={this.state.createWebsite}>Contact Us</a>
        </p>
      </header>
    );
  }

  viewTemplate() {
    return (
      <>
        {this.state.backgroundImageFile !== '' ? (
          <>
            <Row style={{ marginBottom: '0.5em' }}>
              <Col md={12} className="customer-img">
                <header
                  id="Ad01-header"
                  style={{
                    backgroundImage: `url(${`data:image/png;base64,${this.state.backgroundImageFile}`})`,
                  }}
                >
                  <Row>
                    <Col md={12}>
                      <span style={{ float: 'right' }}>
                        <i
                          className="fa fa-times-circle"
                          aria-hidden="true"
                          title="Delete User"
                          style={{ color: '#fff' }}
                          onClick={this.removeBackgroundImage.bind(this, 'BG')}
                        ></i>
                      </span>

                      <div className="imgUploadForm">
                        <div class="upload-btn-wrapper" style={{ float: 'right', padding: '0em 1em' }}>
                          <button className="fileUploadBtn">Upload Background file</button>
                          <input
                            type="file"
                            name="myfile"
                            id="file-input"
                            onChange={this.backgroundImageFileHandler.bind(this, 'BG')}
                          />
                        </div>
                        &nbsp;
                      </div>
                    </Col>
                  </Row>
                  <h1 id="Ad01-h1"> {this.state.heading}</h1>
                  <h2 id="Ad01-h2">{this.state.subHeading}</h2>
                  <p id="Ad01-p">{this.state.message}</p>
                  <p style={{ textAlign: 'center' }}>
                    {this.state.website !== null ? (
                      <a
                        href={
                          this.state.website.includes('https://') || this.state.website.includes('http://')
                            ? this.state.website
                            : 'http://' + this.state.website
                        }
                        target="blank"
                        rel="noopener noreferrer"
                      >
                        {this.state.website}
                      </a>
                    ) : (
                      ' '
                    )}
                  </p>
                  {/* {Utils.base64ToImage(this.state.backgroundImageFile)} */}
                </header>
              </Col>{' '}
            </Row>
          </>
        ) : (
          <Row style={{ marginBottom: '0.5em' }}>
            <Col md={12} className="customer-img">
              {' '}
              <header id="Ad01-header">
                <h1 id="Ad01-h1"> {this.state.heading}</h1>
                <h2 id="Ad01-h2">{this.state.subHeading}</h2>
                <p id="Ad01-p">{this.state.message}</p>
                <p style={{ textAlign: 'center' }}>
                  {this.state.website !== null ? (
                    <a
                      href={
                        this.state.website.includes('https://') || this.state.website.includes('http://')
                          ? this.state.website
                          : 'http://' + this.state.website
                      }
                      target="blank"
                      rel="noopener noreferrer"
                    >
                      {this.state.website}
                    </a>
                  ) : (
                    ' '
                  )}
                </p>
              </header>
            </Col>
          </Row>
        )}
        <p style={{ marginTop: '10px' }}>Note: Image size should be 760*100 pixels.</p>
      </>
    );
  }

  viewTemplateWithImage() {
    return (
      <>
        {this.state.backgroundImageFile !== '' ? (
          <>
            <Row style={{ marginBottom: '0.5em' }}>
              <Col md={12} className="customer-img">
                <header
                  id="Ad01-header"
                  style={{
                    backgroundImage: `url(${`data:image/png;base64,${this.state.backgroundImageFile}`})`,
                  }}
                >
                  <h1 id="Ad01-h1"> {this.state.heading}</h1>
                  <h2 id="Ad01-h2">{this.state.subHeading}</h2>
                  <p id="Ad01-p">{this.state.message}</p>
                  <p style={{ textAlign: 'center' }}>
                    {this.state.website !== null ? (
                      <a
                        href={
                          this.state.website.includes('https://') || this.state.website.includes('http://')
                            ? this.state.website
                            : 'http://' + this.state.website
                        }
                        target="blank"
                        rel="noopener noreferrer"
                      >
                        {this.state.website}
                      </a>
                    ) : (
                      ' '
                    )}
                  </p>
                  {/* {Utils.base64ToImage(this.state.backgroundImageFile)} */}
                </header>
              </Col>{' '}
            </Row>
          </>
        ) : (
          <Row style={{ marginBottom: '0.5em' }}>
            <Col md={12} className="customer-img">
              {' '}
              <header id="Ad01-header">
                <h1 id="Ad01-h1"> {this.state.heading}</h1>
                <h2 id="Ad01-h2">{this.state.subHeading}</h2>
                <p id="Ad01-p">{this.state.message}</p>
                <p style={{ textAlign: 'center' }}>
                  {this.state.website !== null ? (
                    <a
                      href={
                        this.state.website.includes('https://') || this.state.website.includes('http://')
                          ? this.state.website
                          : 'http://' + this.state.website
                      }
                      target="blank"
                      rel="noopener noreferrer"
                    >
                      {this.state.website}
                    </a>
                  ) : (
                    ' '
                  )}
                </p>
              </header>
            </Col>
          </Row>
        )}
        {/* <p style={{ float: "right" }}>Note:Image size should be 760*100 pixels.</p> */}
      </>
    );
  }

  preViewTemplate() {
    return (
      <>
        <header
          style={{
            backgroundImage: `url(${
              // global.config.projectName + "/AdTemplate2/bgAd01.jpg"
              template1
            })`,
          }}
        >
          <Row id="Ad01-header" className="textRow">
            <Col md={12}>
              <h2 id="Ad01-h2">
                {' '}
                <Col md={6} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                  {' '}
                  <TextField
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    id="heading"
                    style={{ background: '#fff' }}
                    type="text"
                    onChange={this.handleCreateHeadingChange.bind(this)}
                    label="Heading"
                    name="heading"
                    inputProps={{
                      maxLength: 128,
                      style: { textTransform: 'capitalize' },
                    }}
                    value={this.state.createHeading}
                  />
                </Col>
              </h2>
              <h2 id="Ad01-h2">
                <Col md={6} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    fullWidth
                    id="heading"
                    style={{ background: '#fff' }}
                    type="text"
                    onChange={this.handleCreateSubHeadingChange.bind(this)}
                    label="Subheading"
                    name="heading"
                    inputProps={{
                      maxLength: 128,
                      style: { textTransform: 'capitalize' },
                    }}
                    value={this.state.createSubHeading}
                  />
                </Col>
              </h2>
              <p id="Ad01-p">
                <TextareaAutosize
                  aria-label="minimum height"
                  style={{ width: '100%', padding: '10px', height: '80px', fontSize: '16px' }}
                  rowsMin={5}
                  id={'createMessage' + this.state.index}
                  value={this.state.createMessage}
                  name="createMessage"
                  inputProps={{
                    maxLength: 256,
                    padding: '10px',
                    whiteSpace: 'pre-line',
                  }}
                  onChange={this.handleCreateMessageChange.bind(this)}
                  onPressEnter={this.handleCreateMessageChange.bind(this)}
                  placeholder="Message"
                  margin="normal"
                />
              </p>
              <p style={{ textAlign: 'center' }}>
                {/* <a href={this.state.createWebsite}>Contact Us</a> */}
                <TextField
                  margin="normal"
                  variant="outlined"
                  fullWidth
                  id="heading"
                  type="text"
                  onChange={this.handleCreatWebsiteChange.bind(this)}
                  label="Website"
                  name="heading"
                  style={{ background: '#fff' }}
                  inputProps={{
                    maxLength: 128,
                  }}
                  value={this.state.createWebsite}
                />
              </p>
            </Col>
          </Row>
        </header>
        <div style={{ marginTop: '10px' }}>
          <p> Note: Please upload images on next screen.</p>
          <p style={{ marginTop: '-1.2em', marginLeft: '2.7em' }}> Message can contain 256 characters.</p>
        </div>
      </>
    );
  }

  editTemplate() {
    return (
      <>
        <header
          id="Ad01-header"
          style={{
            backgroundImage: `url(${`data:image/png;base64,${this.state.backgroundImageFile}`})`,
          }}
        >
          <Row>
            <Col md={12}>
              <span style={{ float: 'right' }}>
                <i
                  className="fa fa-times-circle"
                  aria-hidden="true"
                  title="Delete User"
                  style={{ color: '#fff' }}
                  onClick={this.removeBackgroundImage.bind(this, 'BG')}
                ></i>
              </span>

              <div className="imgUploadForm">
                <div class="upload-btn-wrapper" style={{ float: 'right', padding: '0em 1em' }}>
                  <button className="fileUploadBtn">Upload Background file</button>
                  <input
                    type="file"
                    name="myfile"
                    id="file-input"
                    onChange={this.editBackgroundImageFileHandler.bind(this, 'BG')}
                  />
                </div>
                &nbsp;
              </div>
            </Col>
          </Row>
          <h1 id="Ad01-h1">
            {' '}
            <Col md={6} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              {' '}
              <TextField
                margin="normal"
                variant="outlined"
                fullWidth
                id={'heading' + this.state.index}
                style={{ background: '#fff' }}
                type="text"
                onChange={this.handleHeadingChange.bind(this)}
                label="Heading"
                name="heading"
                inputProps={{
                  maxLength: 128,
                  style: { textTransform: 'capitalize' },
                }}
                value={this.state.heading}
              />
            </Col>
          </h1>
          <h2 id="Ad01-h2">
            <Col md={6} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              <TextField
                margin="normal"
                variant="outlined"
                fullWidth
                id={'subHeading' + this.state.index}
                style={{ background: '#fff' }}
                type="text"
                onChange={this.handleSubHeadingChange.bind(this)}
                label="Subheading"
                name="Subheading"
                inputProps={{
                  maxLength: 128,
                  style: { textTransform: 'capitalize' },
                }}
                value={this.state.subHeading}
              />
            </Col>
          </h2>
          <p id="Ad01-p">
            <TextareaAutosize
              aria-label="minimum height"
              style={{ width: '100%', padding: '10px', height: '80px', fontSize: '16px' }}
              rowsMin={5}
              id={'message' + this.state.index}
              value={this.state.message}
              name="message"
              inputProps={{
                maxLength: 256,
                padding: '10px',
                whiteSpace: 'pre-line',
              }}
              onChange={this.handleMessageChange.bind(this)}
              onPressEnter={this.handleMessageChange.bind(this)}
              placeholder="Message"
              margin="normal"
            />
          </p>
          <p style={{ textAlign: 'center' }}>
            {/* <a href={this.state.createWebsite}>Contact Us</a> */}
            <TextField
              margin="normal"
              variant="outlined"
              fullWidth
              id="heading"
              type="text"
              onChange={this.handleWebsiteChange.bind(this)}
              label="Website"
              name="heading"
              style={{ background: '#fff' }}
              inputProps={{
                maxLength: 128,
              }}
              value={this.state.website}
            />
          </p>
        </header>
        <div style={{ marginTop: '10px' }}>
          <p> Note: Image size should be 760*100 pixels. </p>
          <p style={{ marginTop: '-1.2em', marginLeft: '2.7em' }}> Message can contain 256 characters.</p>
        </div>
      </>
    );
  }
}

export default Template2;
