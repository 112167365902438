import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import React, { Component } from 'react';
import PtsConfirmDialogue from '../ptsAlert/PtsConfirmDialogue';
import Button from '@material-ui/core/Button';
export class DeleteIconButton extends Component {
  confirmDialogueRef = React.createRef();
  constructor(props) {
    let domainObject = props.domainObject ? props.domainObject : '';
    super(props);
    this.state = {
      domainObject: domainObject,
      title: props.title ? props.title : 'Delete Confirmation',
      text: props.text ? props.text : domainObject,
      onClick: props.onClick,
      caption: props.caption,
      pageId: this.props.pageId,
    };
  }

  deleteConfirmed() {
    if (this.confirmDialogueRef && this.confirmDialogueRef.current) {
      this.confirmDialogueRef.current.setState({ open: false });
    }
    if (this.state.onClick) {
      this.state.onClick();
    }
  }

  deleteNotConfirmed() {}
  onClick() {
    if (
      this.confirmDialogueRef &&
      this.confirmDialogueRef.current &&
      this.confirmDialogueRef.current.state.open === false
    ) {
      this.confirmDialogueRef.current.handleClickOpen();
    }
  }
  render() {
    this.confirmDialogueRef = React.createRef();
    return (
      <span
        onClick={this.onClick.bind(this)}
        style={{ cursor: 'pointer', fontSize: 'x-larger', marginRight: '0.5em', float: 'right', display: 'flex' }}
      >
        <PtsConfirmDialogue
          ref={this.confirmDialogueRef}
          title={this.state.title}
          text={this.state.text}
          open={false}
          pageId={this.props.pageId}
          confirmCallback={this.deleteConfirmed.bind(this)}
          notConfirmCallback={this.deleteNotConfirmed.bind(this)}
        />

        <Button
          className="add"
          onClick={this.onClick.bind(this)}
          style={{
            background: '#867e7e',
            color: 'white',
            fontSize: 'x-large',
            textTransform: 'capitalize',
            cursor: 'pointer',
            fontSize: 'x-larger',
            float: 'right',
            margin: '0.2em 0.5em 0.3em 0em',
          }}
          startIcon={<DeleteIcon />}
        >
          Delete
        </Button>
      </span>
    );
  }
}
export default DeleteIconButton;
