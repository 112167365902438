// import { Card, CardHeader } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import React, { Component } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { withRouter } from 'react-router-dom';
import { CardBody, CardHeader, Card, CardFooter, Col, Row, Label } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import store from '../../../redux/store';
import Address from '../address/Address';
import AddressWithTimezone from '../addressWithTimezone/AddressWithTimezone';
import CancelIconButton from '../buttons/CancelIconButton';
import SaveButton from '../buttons/SaveButton';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
import PtsAlert from '../ptsAlert/PtsAlert';
import { Member } from './Member';
import { NewMember } from './NewMember';

export class AddMember extends Component {
  title = 'Create a Family Care Circle for a loved one or Friend';
  breadCrumb = [];
  relationWithMemberList = store.getState().staticData.relationWithMemberList;
  header = store.getState().header.header;
  addressRef = React.createRef();
  newMemberRef = [];
  constructor(props) {
    super(props);
    this.breadCrumb.push({ uiname: 'Add Member' });
    let memberId = props.memberId;
    let action = props.action;
    let title = '';
    let pageId = '';
    let pathUrl = '';
    if (!memberId && props.history && props.history.location && props.history.location.state) {
      memberId = props.history.location.state.memberId;
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
      title = props.history.location.state.title;
      pathUrl = props.history.location.state.pathUrl;
    }

    this.state = {
      render: false,
      id: memberId,
      title: title,
      pathUrl: pathUrl,
      action: action,
      pageId: pageId,
      memberData: '',
      selectedRelation: {},
      onChange: this.props.onChange,
      salutation: {},
      newMember: {},
      selfDetails: {},
      selfData: {},
      selfAddressDetails: {},
      selectedMemberData: {},
      selectedMemberAddress: {},
      existingMember: false,
      existingMemberData: '',
      searchMemberId: '',
      relation: {},
      searchMemberEmail: '',
      addMember: true,
      isMemberExisting: false,
      requestSendModel: false,
      AuthorizedModel: false,
      AuthorizedWithoutEmailModel: false,
      isSelectedMember: '',
      showQuestionCard: true,
      showAddMemberCardWithoutEmail: false,
      showAddMemberCard: false,
    };
    this.getMemberDetails();
  }

  handleAddressChange(inputAddress) {
    this.setState({ address: inputAddress });
  }
  handleNewMemberChange(selectedData) {
    this.setState({ newMember: selectedData });
  }
  createCancel = () => {
    this.props.history.push({
      pathname: '/home',
    });
  };

  nextPage() {
    if (this.state.checkbox) {
      this.props.history.push('/createAccount');
    } else {
      document.getElementById('acceptTerms').focus();
    }
  }

  getMemberDetails = () => {
    var inputObject = {
      header: this.header,
    };
    FetchServerData.callPostService('/regms/getRegistration', inputObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let memberDetails = output.data;

          this.setState({
            render: true,
            selfDetails: memberDetails,
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  componentDidMount() {
    window.addEventListener('load', this.searchExistingMemberDetails);
  }

  /* search  existing member by phone number or email then this api call*/
  searchExistingMemberDetails = () => {
    let searchMember = '';
    let searchEmailPhone = '';
    if (this.newMemberRef && this.newMemberRef.current && this.newMemberRef.current.state.email === '') {
      searchMember = this.newMemberRef.current.state.mobile;
    } else if (this.newMemberRef && this.newMemberRef.current && this.newMemberRef.current.state.email) {
      searchMember = this.newMemberRef.current.state.email;
    }

    if (/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(searchMember)) {
      searchEmailPhone = searchMember;
    } else {
      searchEmailPhone = searchMember.replace(/[^\d]/g, '');
    }
    if (searchEmailPhone !== '') {
      const postObject = {
        member: {
          email: searchEmailPhone,
        },
        header: this.header,
      };

      FetchServerData.callPostService('memberms/findByEmailOrPhone', postObject).then((output) => {
        if (output.status === 'SUCCESS') {
          let searchData = output.data;

          if (null === searchData) {
            this.setState({
              render: true,
              addMember: true,
            });
          } else {
            this.state.newMember.fname = searchData.firstName;
            this.state.newMember.lname = searchData.lastName;
            this.state.newMember.email = searchData.email;

            this.setState({
              render: true,
              addMember: false,
              isMemberExisting: true,
              selectedMemberData: searchData,
              searchMemberId: searchData.id,
              searchMemberEmail: searchData.email,
              firstName: searchData.firstName,
              lastName: searchData.lastName,
              email: searchData.email,
              selectedMemberAddress: searchData.address,
              searchMember: true,
            });
          }
        } else {
        }
      });
    }
  };

  /* add member in backend then this api call*/

  addMemberInBackend = (e) => {
    e.preventDefault();
    let postObject = {};

    if (this.state.isSelectedMember === 'Authorized') {
      if (this.state.isMemberExisting === true) {
        this.setState({
          AuthorizedModel: true,
        });
      } else {
        if (this.addressRef && this.addressRef.current) {
          if (this.addressRef.current.validateAddress() === true) {
            this.state.newMember.fname = this.newMemberRef.current.state.fname;
            this.state.newMember.lname = this.newMemberRef.current.state.lname;
            this.state.newMember.email = this.newMemberRef.current.state.email;
            this.setState({
              AuthorizedModel: true,
            });
          }
        }
      }
    } else {
      if (this.state.isMemberExisting === true) {
        // if(this.state.searchMemberId === null)
        postObject = {
          header: this.header,

          memberVo: {
            id: this.state.searchMemberId,
          },
        };

        FetchServerData.callPostService('/memberms/create', postObject)
          .then((output) => {
            if (output.status === 'SUCCESS') {
              PtsAlert.success(output.message);
              let memberData = output.data;
              this.setState({
                firstName: memberData.memberFname,
                lastName: memberData.memberLname,
                email: memberData.memberEmail,
                requestSendModel: true,
              });
            } else {
            }
          })
          .catch((error) => {});
      } else {
        if (this.addressRef && this.addressRef.current) {
          if (this.addressRef.current.validateAddress() === true) {
            this.state.newMember.fname = this.newMemberRef.current.state.fname;
            this.state.newMember.lname = this.newMemberRef.current.state.lname;
            this.state.newMember.email = this.newMemberRef.current.state.email;
            let mobile = this.newMemberRef.current.state.mobile.replace(/[^\d]/g, '');
            postObject = {
              header: this.header,

              memberVo: {
                id: this.state.searchMemberId,
                firstName: this.state.newMember.fname,
                lastName: this.state.newMember.lname,
                email: this.state.newMember.email,
                phone: mobile,
                description: this.state.newMember.description,

                address: {
                  line: this.addressRef.current.state.line,
                  state: this.addressRef.current.state.state,
                  timezone: {
                    id: this.addressRef.current.state.timeZone.id,
                  },
                  city: this.addressRef.current.state.city,
                  zipCode: this.addressRef.current.state.zipCode,
                },
              },
            };
            FetchServerData.callPostService('/memberms/create', postObject)
              .then((output) => {
                if (output.status === 'SUCCESS') {
                  PtsAlert.success(output.message);
                  let memberData = output.data;
                  this.setState({
                    firstName: memberData.memberFname,
                    lastName: memberData.memberLname,
                    email: memberData.memberEmail,
                    requestSendModel: true,
                  });
                } else {
                }
              })
              .catch((error) => {});
          }
        }
      }
    }
  };

  addMemberWithoutEmailInBackend = (e) => {
    e.preventDefault();
    let postObject = {};

    if (this.addressRef && this.addressRef.current) {
      if (this.addressRef.current.validateAddress() === true) {
        this.state.newMember.fname = this.newMemberRef.current.state.fname;
        this.state.newMember.lname = this.newMemberRef.current.state.lname;
        this.state.newMember.email = this.newMemberRef.current.state.email;
        let mobile = this.newMemberRef.current.state.mobile.replace(/[^\d]/g, '');
        postObject = {
          header: this.header,

          memberVo: {
            id: this.state.searchMemberId,
            firstName: this.state.newMember.fname,
            lastName: this.state.newMember.lname,
            email: null,
            phone: mobile,
            description: this.state.newMember.description,

            address: {
              line: this.addressRef.current.state.line,
              state: this.addressRef.current.state.state,
              timezone: {
                id: this.addressRef.current.state.timeZone.id,
              },
              city: this.addressRef.current.state.city,
              zipCode: this.addressRef.current.state.zipCode,
            },
          },
        };
        FetchServerData.callPostService('/memberms/createMemberWithoutEmailForAuthorisedCaregiver', postObject)
          .then((output) => {
            if (output.status === 'SUCCESS') {
              PtsAlert.success(output.message);
              let memberData = output.data;
              this.setState({
                firstName: memberData.memberFname,
                lastName: memberData.memberLname,
              });
              this.props.history.push({
                pathname: '/home',
              });
            } else {
            }
          })
          .catch((error) => {});
      }
    }
  };

  /* add self as  member in  then this api call*/
  addMemberSelfInBackend = () => {
    const postObject = {
      header: this.header,
    };
    FetchServerData.callPostService('/memberms/createSelfMember', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let selfData = output.data;
          PtsAlert.success(output.message);
          this.setState({
            render: true,
            selfDetails: selfData,
          });
          this.props.history.push({
            pathname: '/home',
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  render() {
    if (this.state.render === false) {
      return <div />;
    }

    if (this.state.action === 'edit') {
      return this.addExistingMember();
    } else if (this.state.action === 'view') {
      return this.addSelfAsMember();
    }
  }
  handleRequestModelClose = () => {
    this.setState({
      requestSendModel: false,
    });
  };
  handleAuthorizedModelClose = () => {
    this.setState({
      AuthorizedModel: false,
    });
  };
  handleAuthorizedWithoutEmailModelClose = () => {
    this.setState({
      AuthorizedWithoutEmailModel: false,
    });
  };

  addMemberWithoutEmailModal = () => {
    if (this.addressRef && this.addressRef.current) {
      if (this.addressRef.current.validateAddress() === true) {
        this.setState({
          AuthorizedWithoutEmailModel: true,
        });
      }
    }
  };
  ToDashboard = () => {
    this.props.history.push({
      pathname: '/home',
    });
  };

  addAuthorizedMember(e) {
    let postObject = {};
    if (this.state.isMemberExisting === true) {
      // if(this.state.searchMemberId === null)
      postObject = {
        header: this.header,
        memberVo: {
          id: this.state.searchMemberId,
        },
      };
      FetchServerData.callPostService('/memberms/createMemberForAuthorisedCaregiver', postObject)
        .then((output) => {
          if (output.status === 'SUCCESS') {
            PtsAlert.success(output.message);
            let memberData = output.data;
            this.setState({
              firstName: memberData.memberFname,
              lastName: memberData.memberLname,
              email: memberData.memberEmail,
            });
            this.props.history.push({
              pathname: '/home',
            });
          } else {
          }
        })
        .catch((error) => {});
    } else {
      if (this.addressRef && this.addressRef.current) {
        if (this.addressRef.current.validateAddress() === true) {
          let mobile = this.newMemberRef.current.state.mobile.replace(/[^\d]/g, '');

          postObject = {
            header: this.header,
            memberVo: {
              id: this.state.searchMemberId,
              firstName: this.state.newMember.fname,
              lastName: this.state.newMember.lname,
              email: this.state.newMember.email,
              phone: mobile,
              address: {
                line: this.addressRef.current.state.line,
                state: this.addressRef.current.state.state,
                timezone: {
                  id: this.addressRef.current.state.timeZone.id,
                },
                city: this.addressRef.current.state.city,
                zipCode: this.addressRef.current.state.zipCode,
              },
            },
          };
          FetchServerData.callPostService('/memberms/createMemberForAuthorisedCaregiver', postObject)
            .then((output) => {
              if (output.status === 'SUCCESS') {
                PtsAlert.success(output.message);
                let memberData = output.data;
                this.setState({
                  firstName: memberData.memberFname,
                  lastName: memberData.memberLname,
                  email: memberData.memberEmail,
                });
                this.props.history.push({
                  pathname: '/home',
                });
              } else {
              }
            })
            .catch((error) => {});
        }
      }
    }
  }
  memberHasEmail() {
    this.setState({ showAddMemberCard: true, showAddMemberCardWithoutEmail: false });
  }

  memberHasNoEmail() {
    this.setState({ showAddMemberCard: false, showAddMemberCardWithoutEmail: true });
  }
  /* if create new member or add  existing member then this function call*/
  addExistingMember() {
    if (this.state.render === false) {
      return <div />;
    } else {
      this.newMemberRef = React.createRef();
      return (
        <>
          <MenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            name={this.title}
            headerWidth="home"
          />
          <div style={{ margin: '1em' }}>
            {this.state.showQuestionCard && (
              <Row style={{ position: 'relative' }}>
                <Col md={12}>
                  <Card style={{ borderRadius: '20px', height: '100%' }}>
                    <CardHeader style={{ borderBottom: '1px solid lightgrey' }}>
                      <Label style={{ fontWeight: '600', color: '#1b7189' }}>Create New Circle</Label>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <Col md={12}>
                          <h5>
                            Creating a circle with an email address allows the member to be an active participant in the
                            circle. If no email is available, you will represent the member as the authorized caregiver.
                            At any point, you will have the ability to add an email address by updating the member’s
                            profile.{' '}
                          </h5>
                          <br />
                          <Row>
                            <Col md={5}>
                              {' '}
                              <h5>Does your loved one or friend (future FCC member) have an email address?</h5>
                            </Col>

                            <Col md={7}>
                              {' '}
                              <Button
                                variant="contained"
                                size="small"
                                style={{
                                  background: this.state.showAddMemberCard === true ? '#ef6603' : '#717373',
                                  color: '#fff',
                                  marginRight: '1rem',
                                }}
                                onClick={(e) => this.memberHasEmail()}
                              >
                                Yes
                              </Button>
                              <Button
                                variant="contained"
                                size="small"
                                style={{
                                  background: this.state.showAddMemberCardWithoutEmail === true ? '#ef6603' : '#717373',
                                  color: '#fff',
                                  marginRight: '1rem',
                                }}
                                onClick={(e) => this.memberHasNoEmail()}
                              >
                                NO
                              </Button>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            )}
            {this.state.showAddMemberCard && (
              <ValidatorForm ref="form" onSubmit={this.addMemberInBackend.bind(this)}>
                {this.state.addMember && (
                  <Row style={{ position: 'relative', marginTop: '1em' }}>
                    <Col md={6} style={{ position: 'relative' }}>
                      <Card style={{ borderRadius: '20px', height: '100%' }}>
                        <CardBody>
                          <NewMember
                            title="Member Details"
                            ref={this.newMemberRef}
                            data={this.state.newMember}
                            pageId="addMember"
                            pathUrl={this.state.pathUrl}
                            onChange={this.handleNewMemberChange.bind(this)}
                            onBlur={this.searchExistingMemberDetails.bind(this)}
                            action="create"
                          ></NewMember>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6} style={{ position: 'relative' }}>
                      <Card style={{ borderRadius: '20px', height: '100%' }}>
                        <CardBody>
                          <Row style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                            <Col md={12}>
                              <Label style={{ fontWeight: '600', color: '#1b7189', marginLeft: '0.5em' }}>
                                Address
                              </Label>
                            </Col>
                          </Row>
                          <AddressWithTimezone
                            ref={this.addressRef}
                            data={this.state.address}
                            onChange={this.handleAddressChange.bind(this)}
                            action="create"
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )}

                {this.state.searchMember && (
                  <>
                    <Row style={{ padding: '1em' }}>
                      <Col md={6} style={{ position: 'relative', padding: '5px' }}>
                        <Card style={{ borderRadius: '20px', height: '100%' }}>
                          <CardBody>
                            <Member title="Member Details" action="view" data={this.state.selectedMemberData}></Member>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </>
                )}

                <CardFooter style={{ width: '100%', textAlign: 'center' }}>
                  <div>
                    {this.state.isMemberExisting === true ? (
                      ''
                    ) : (
                      <Button
                        variant="contained"
                        size="small"
                        type="submit"
                        style={{ background: '#138D75', color: '#fff' }}
                        onClick={() => (this.state.isSelectedMember = 'Authorized')}
                      >
                        I AM AN AUTHORIZED CAREGIVER
                      </Button>
                    )}
                    &nbsp;&nbsp;
                    <Button
                      variant="contained"
                      size="small"
                      type="submit"
                      style={{ background: '#ef6603', color: '#fff' }}
                      onClick={() => (this.state.isSelectedMember = 'Member')}
                    >
                      SEND REQUEST TO MEMBER
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      variant="contained"
                      size="small"
                      style={{ background: '#717373', color: '#fff', marginRight: '1rem' }}
                      onClick={(e) => this.createCancel()}
                    >
                      Cancel
                    </Button>
                  </div>
                </CardFooter>
              </ValidatorForm>
            )}

            {this.state.showAddMemberCardWithoutEmail && (
              <ValidatorForm ref="form" onSubmit={this.addMemberWithoutEmailModal.bind(this)}>
                {this.state.addMember && (
                  <Row style={{ position: 'relative', marginTop: '1em' }}>
                    <Col md={6} style={{ position: 'relative' }}>
                      <Card style={{ borderRadius: '20px', height: '100%' }}>
                        <CardBody>
                          <NewMember
                            title="Member Details"
                            ref={this.newMemberRef}
                            data={this.state.newMember}
                            pageId="addMember"
                            pathUrl={this.state.pathUrl}
                            onChange={this.handleNewMemberChange.bind(this)}
                            onBlur={this.searchExistingMemberDetails.bind(this)}
                            action="withoutEmail"
                          ></NewMember>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md={6} style={{ position: 'relative' }}>
                      <Card style={{ borderRadius: '20px', height: '100%' }}>
                        <CardBody>
                          <Row style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                            <Col md={12}>
                              <Label style={{ fontWeight: '600', color: '#1b7189', marginLeft: '0.5em' }}>
                                Address
                              </Label>
                            </Col>
                          </Row>
                          <AddressWithTimezone
                            ref={this.addressRef}
                            data={this.state.address}
                            onChange={this.handleAddressChange.bind(this)}
                            action="create"
                          />
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                )}

                {this.state.searchMember && (
                  <>
                    <Row style={{ padding: '1em' }}>
                      <Col md={6} style={{ position: 'relative', padding: '5px' }}>
                        <Card style={{ borderRadius: '20px', height: '100%' }}>
                          <CardBody>
                            <Member title="Member Details" action="view" data={this.state.selectedMemberData}></Member>
                          </CardBody>
                        </Card>
                      </Col>
                    </Row>
                  </>
                )}

                <CardFooter style={{ width: '100%', textAlign: 'center' }}>
                  <div>
                    <Button
                      variant="contained"
                      size="small"
                      type="submit"
                      style={{ background: '#138D75', color: '#fff' }}
                      onClick={() => (this.state.isSelectedMember = 'Member')}
                    >
                      I AM AN AUTHORIZED CAREGIVER
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      variant="contained"
                      size="small"
                      style={{ background: '#717373', color: '#fff', marginRight: '1rem' }}
                      onClick={(e) => this.createCancel()}
                    >
                      Cancel
                    </Button>
                  </div>
                </CardFooter>
              </ValidatorForm>
            )}
          </div>
          <Dialog
            open={this.state.requestSendModel}
            onClose={this.handleRequestModelClose.bind(this)}
            onHide={this.close}
            style={{ padding: '1em' }}
            className="requestModal"
          >
            <DialogContent style={{ marginTop: '2em' }}>
              <span>
                <p style={{ fontSize: '17px' }}>
                  A Family Care Circle request has been sent to{' '}
                  <b style={{ color: '#3498DB' }}>{this.state.newMember.email}</b>
                  .&nbsp;Due to the security reasons, we will need to wait for
                  <b style={{ color: '#3498DB' }}>
                    {' ' + this.state.newMember.fname + ' ' + this.state.newMember.lname + ' '}
                  </b>{' '}
                  to register and accept the invite to become a member.
                  <br />
                  <br />
                  Please reach out to
                  <b style={{ color: '#3498DB' }}>
                    {' ' + this.state.newMember.fname + ' ' + this.state.newMember.lname}{' '}
                  </b>{' '}
                  and help them through this process. Once they have registered and accepted, as an administrator you
                  will be able to add other Family Members to the Circle or begin to track doctor appointments
                  immediately. You may also wish to add any historical notes about previous appointments.
                  <br />
                  <br />
                  While you wait for{' '}
                  <b style={{ color: '#3498DB' }}>
                    {' ' + this.state.newMember.fname + ' ' + this.state.newMember.lname}{' '}
                  </b>{' '}
                  to accept the request, you can create a Family Care Circle for yourself or start a request to create a
                  Family Care Circle for another loved one.
                </p>
              </span>
            </DialogContent>
            <DialogActions style={{ padding: '0em 0em 1em 0em', marginLeft: 'auto', marginRight: 'auto' }}>
              <Button
                variant="contained"
                size="large"
                style={{ background: '#717373', color: '#fff', marginRight: '1rem' }}
                onClick={this.ToDashboard.bind(this)}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.AuthorizedModel}
            onClose={this.handleAuthorizedModelClose.bind(this)}
            onHide={this.close}
            style={{ padding: '1em' }}
            className="requestModal"
          >
            <DialogContent style={{ marginTop: '2em' }}>
              <span>
                <p style={{ fontSize: '17px' }}>
                  As the Authorized Care Giver, you will have administrative rights to govern all activity associated
                  with the creation of the Family Care Circle. FCC understands the sensitive nature of this information
                  and with your help, this information will only be shared with the caregivers you approve on behalf of{' '}
                  <b style={{ color: '#3498DB' }}>
                    {' ' + this.state.newMember.fname + ' ' + this.state.newMember.lname}
                  </b>
                  .<br></br> We have also sent a message to{' '}
                  <b style={{ color: '#3498DB' }}>{this.state.newMember.email}</b> suggesting they register to help
                  establish the information to build the best Family Care Circle.
                  <br></br>Welcome to Family Care Circle.
                </p>
              </span>
            </DialogContent>
            <DialogActions style={{ padding: '0em 0em 1em 0em', marginLeft: 'auto', marginRight: 'auto' }}>
              <Button
                variant="contained"
                size="large"
                style={{ background: '#717373', color: '#fff', marginRight: '1rem' }}
                onClick={this.addAuthorizedMember.bind(this)}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>

          <Dialog
            open={this.state.AuthorizedWithoutEmailModel}
            onClose={this.handleAuthorizedWithoutEmailModelClose.bind(this)}
            onHide={this.close}
            style={{ padding: '1em' }}
            className="requestModal"
          >
            <DialogContent style={{ marginTop: '2em' }}>
              <span>
                <p style={{ fontSize: '17px' }}>
                  As the Authorized Care Giver, you will have administrative rights to govern all activity associated
                  with the creation of the Family Care Circle. FCC understands the sensitive nature of this information
                  and with your help, this information will only be shared with the caregivers you approve on behalf of{' '}
                  <b style={{ color: '#3498DB', textTransform: 'capitalize' }}>
                    {' ' + this.state.newMember.fname + ' ' + this.state.newMember.lname}
                  </b>
                  .<br></br>
                </p>
              </span>
            </DialogContent>
            <DialogActions style={{ padding: '0em 0em 1em 0em', marginLeft: 'auto', marginRight: 'auto' }}>
              <Button
                variant="contained"
                size="large"
                style={{ background: '#717373', color: '#fff', marginRight: '1rem' }}
                onClick={this.addMemberWithoutEmailInBackend.bind(this)}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    }
  }

  /* if create self as member then this function call*/

  addSelfAsMember() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <MenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            name={this.title}
            headerWidth="home"
          />
          <div style={{ margin: '1em' }}>
            <ValidatorForm ref="form" onSubmit={this.addMemberSelfInBackend.bind(this)}>
              <Card style={{ borderRadius: '20px' }}>
                <CardBody>
                  <Row>
                    <Col md={8}>
                      <Member title="Member Details" action="view" data={this.state.selfDetails}></Member>
                    </Col>
                    <Col md={4}></Col>
                  </Row>
                </CardBody>

                <CardFooter style={{ width: '100%' }}>
                  <div style={{ margin: 'auto 15em' }}>
                    <Button
                      variant="contained"
                      size="small"
                      type="submit"
                      style={{ background: '#ef6603', color: '#fff' }}
                    >
                      Create
                    </Button>
                    &nbsp;
                    <CancelIconButton onClick={(e) => this.createCancel()} />
                  </div>
                </CardFooter>
              </Card>
            </ValidatorForm>
          </div>
        </>
      );
    }
  }
}
export default withRouter(AddMember);
