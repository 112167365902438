import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import MaterialTable from '@material-table/core';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import AccessAlarmTwoToneIcon from '@material-ui/icons/AccessAlarmTwoTone';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import GamepadIcon from '@material-ui/icons/Gamepad';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import 'date-fns';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { Card, CardHeader, Col, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import consolidatedMemberAction from '../../../redux/consolidatedMemberDetails/consolidatedMemberAction';
import store from '../../../redux/store';
import Advertisement from '../advertisement/Advertisement';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
import CalenderEventCard from './CalenderEventCard';
import './ConsolidatedView.scss';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import Badge from '@material-ui/core/Badge';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import ChatIcon from '@material-ui/icons/Chat';
import DriveEtaIcon from '@material-ui/icons/DriveEta';
import PrintRoundedIcon from '@material-ui/icons/PrintRounded';
import ReactToPrint from 'react-to-print';
class ConsolidatedView extends Component {
  header = store.getState().header.header;
  title = 'Consolidated View';
  loginUserName = store.getState().userData.userData.firstName + ' ' + store.getState().userData.userData.lastName;
  consolidatedMemberDetails = store.getState().consolidatedMemberDetails.consolidatedMemberDetails;
  consolidatedMemberData = {};
  // breadCrumb = [];
  constructor(props) {
    super(props);

    if (this.consolidatedMemberDetails) {
      this.consolidatedMemberData = Object.getOwnPropertyNames(this.consolidatedMemberDetails);
    }
    if (this.consolidatedMemberData.length > 0) {
      let startDate = moment(this.consolidatedMemberDetails.consolidatedDetails.currentDayDate).format('YYYY-MM-DD');
      let endDate = moment(this.consolidatedMemberDetails.consolidatedDetails.currentDayDate).format('YYYY-MM-DD');
      let weekDateList = Utils.getWeekDatesList(startDate, endDate);
      let headerWeekDateList = Utils.getHeaderWeekDatesList(startDate, endDate);
      let weekStartDate = moment(this.consolidatedMemberDetails.consolidatedDetails.weekStartDate).format('YYYY-MM-DD');
      let weekEndDate = moment(this.consolidatedMemberDetails.consolidatedDetails.weekEndDate).format('YYYY-MM-DD');
      let startOfWeek = moment(weekStartDate).startOf('week').format('YYYY-MM-DD');
      let endOfWeek = moment(weekEndDate).endOf('week').format('YYYY-MM-DD');
      let consolidatedView = this.consolidatedMemberDetails.consolidatedDetails.consolidatedView;
      let weekDataMap = Utils.getWeekDatesList(endDate, endDate);
      if (consolidatedView === 'weekView') {
        this.MemberCalenderByWeek(startOfWeek, endOfWeek);
      } else {
        this.MemberCalenderByDay(startDate, endDate);
      }

      let consolidatedViewDetails = {
        consolidatedDetails: {
          pageId: 'home',
          currentDayDate: this.consolidatedMemberDetails.consolidatedDetails.currentDayDate,
          currentDay: this.consolidatedMemberDetails.consolidatedDetails.currentDay,
          consolidatedView: this.consolidatedMemberDetails.consolidatedDetails.consolidatedView,
        },
      };
      let DetailObj = { ...this.consolidatedMemberDetails, ...consolidatedViewDetails };

      store.dispatch(consolidatedMemberAction.setConsolidatedMember(DetailObj));

      this.state = {
        startDate: this.consolidatedMemberDetails.consolidatedDetails.currentDayDate,
        endDate: this.consolidatedMemberDetails.consolidatedDetails.currentDayDate,
        selectedStartDate: this.consolidatedMemberDetails.consolidatedDetails.currentDayDate,
        selectedEndDate: this.consolidatedMemberDetails.consolidatedDetails.currentDayDate,
        currentDayDate: this.consolidatedMemberDetails.consolidatedDetails.currentDayDate,
        currentDay: this.consolidatedMemberDetails.consolidatedDetails.currentDay,
        dateMap: {},

        render: false,
        filterOptionTypeList: [],
        weekDateList: weekDateList,
        consolidatedView: this.consolidatedMemberDetails.consolidatedDetails.consolidatedView,
        weekStartDate: weekStartDate,
        weekEndDate: weekEndDate,
        weekDataMap: weekDataMap,
        headerWeekDateList: headerWeekDateList,
        startOfWeek: startOfWeek,
        endOfWeek: endOfWeek,
      };
    } else {
      let date = new Date();
      let startDate = moment(date).format('YYYY-MM-DD');
      let endDate = moment(date).format('YYYY-MM-DD');
      let currentDay = moment(date).format('dddd');
      let currentDayDate = moment(date).format('DD MMM YYYY');

      let weekStartDate = moment(startDate).startOf('week');
      let weekEndDate = moment(endDate).endOf('week');
      let weekDataMap = Utils.getWeekDatesList(endDate, endDate);
      this.MemberCalenderByWeek(weekStartDate, weekEndDate);
      this.state = {
        startDate: startDate,
        endDate: endDate,
        selectedStartDate: startDate,
        selectedEndDate: endDate,
        currentDayDate: currentDayDate,
        currentDay: currentDay,

        dateMap: {},
        render: false,
        filterOptionTypeList: [],
        filterOptionType: {},
        weekDateList: [],
        weekDataMap: weekDataMap,
        consolidatedView: 'weekView',
        weekStartDate: weekStartDate,
        weekEndDate: weekEndDate,
        headerWeekDateList: [],
        startOfWeek: weekStartDate,
        endOfWeek: weekEndDate,
      };
    }
    this.getFilterOption();
  }

  getFilterOption = () => {
    const postObject = {
      header: this.header,
    };
    FetchServerData.callPostService('/homems/getFilterOptions', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let filterOptionTypeList = output.data.filterOptionTypes;
          if (filterOptionTypeList !== []) {
            filterOptionTypeList.unshift('All');
          }
          let filterOption = filterOptionTypeList.reduce((unique, o) => {
            if (!unique.some((obj) => obj === o)) {
              unique.push(o);
            }
            return unique;
          }, []);
          this.setState({
            render: true,
            filterOptionTypeList: filterOption,
            filterOptionType: filterOption[0],
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  DayViewFilterInBackend = () => {
    const postObject = {
      header: this.header,

      fromdate: this.state.startDate,
      todate: this.state.endDate,
      type: this.state.filterOptionType,
    };
    FetchServerData.callPostService('/homems/getFilteredConsolidatedMemberDetails', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let memberActivityDetails = output.data.memberActivityList;
          let currentDateMap = {
            0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
            8: [],
            9: [],
            10: [],
            11: [],
            12: [],
            13: [],
            14: [],
            15: [],
            16: [],
            17: [],
            18: [],
            19: [],
            20: [],
            21: [],
            22: [],
            23: [],
          };
          let dateList = Utils.getDayDatesList(this.state.startDate);
          for (let i = 0; i < memberActivityDetails.length; i++) {
            memberActivityDetails[i].date = Utils.convertConsolidatedUTCDateToUserTimeZoneDate(
              memberActivityDetails[i].date
            );
          }

          let newMemberActivityList = [];
          let dayDateList = [];
          let filterDate = this.state.startDate;
          newMemberActivityList = memberActivityDetails.filter((val) =>
            moment(val.date).format('YYYY-MM-DD').includes(filterDate)
          );

          for (let currentDateKey in currentDateMap) {
            dayDateList[currentDateKey] = JSON.parse(JSON.stringify(dateList));
          }

          for (let key in newMemberActivityList) {
            let hour = Utils.getHours(newMemberActivityList[key].date);
            let date = Utils.getDate(newMemberActivityList[key].date);

            dayDateList[hour][date].push(newMemberActivityList[key]);
          }

          let selectedStartDate = moment(this.state.startDate).format('YYYY-MM-DD');
          let selectedDay = moment(this.state.startDate).format('dddd');

          this.setState({
            render: true,
            dateMap: dayDateList,
            currentDay: selectedDay,
            currentDayDate: selectedStartDate,
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  resetData() {
    let date = new Date();
    let startDate = moment(date).format('YYYY-MM-DD');
    let endDate = moment(date).format('YYYY-MM-DD');
    let currentDay = moment(date).format('dddd');
    let currentDayDate = moment(date).format('DD MMM YYYY');

    let weekStartDate = moment(date).startOf('isoWeek');
    let weekEndDate = moment(date).endOf('isoWeek');

    this.MemberCalenderByDay(startDate, endDate);

    this.setState({
      filterOptionType: this.state.filterOptionTypeList[0],
      currentDay: currentDay,
      currentDayDate: currentDayDate,
      selectedDate: new Date(),
    });
  }

  resetWeekData() {
    let date = new Date();
    let startDate = moment(date).format('YYYY-MM-DD');
    let endDate = moment(date).format('YYYY-MM-DD');
    let currentDay = moment(date).format('dddd');
    let currentDayDate = moment(date).format('DD MMM YYYY');

    let weekStartDate = moment(startDate).startOf('week');
    let weekEndDate = moment(endDate).endOf('week');
    this.MemberCalenderByWeek(weekStartDate, weekEndDate);
    this.setState({
      filterOptionType: this.state.filterOptionTypeList[0],
      currentDay: currentDay,
      currentDayDate: currentDayDate,
      selectedDate: new Date(),
      selectedWeekDate: new Date(),

      startOfWeek: weekStartDate,
      endOfWeek: weekEndDate,
    });
  }
  MemberCalenderByDay = (startDate, endDate) => {
    let dayStartDate = moment(startDate).format('YYYY-MM-DD');
    let dayEndDate = moment(endDate).format('YYYY-MM-DD');
    const postObject = {
      header: this.header,

      fromdate: dayStartDate,
      todate: dayEndDate,
    };
    FetchServerData.callPostService('/homems/getConsolidatedMemberDetails', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let memberActivityDetails = output.data.memberActivityList;

          let currentDateMap = {
            0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
            8: [],
            9: [],
            10: [],
            11: [],
            12: [],
            13: [],
            14: [],
            15: [],
            16: [],
            17: [],
            18: [],
            19: [],
            20: [],
            21: [],
            22: [],
            23: [],
          };
          let dateList = Utils.getDayDatesList(dayStartDate);
          for (let i = 0; i < memberActivityDetails.length; i++) {
            memberActivityDetails[i].date = Utils.convertConsolidatedUTCDateToUserTimeZoneDate(
              memberActivityDetails[i].date
            );
          }

          let newMemberActivityList = [];
          let dayDateList = {};
          let filterDate = dayStartDate;
          newMemberActivityList = memberActivityDetails.filter((val) =>
            moment(val.date).format('YYYY-MM-DD').includes(filterDate)
          );

          for (let currentDateKey in currentDateMap) {
            dayDateList[currentDateKey] = JSON.parse(JSON.stringify(dateList));
          }

          for (let key in newMemberActivityList) {
            let hour = Utils.getHours(newMemberActivityList[key].date);
            let date = Utils.getDate(newMemberActivityList[key].date);

            dayDateList[hour][date].push(newMemberActivityList[key]);
          }

          this.setState({
            render: true,
            dateMap: dayDateList,
            currentDayDate: moment(startDate).format('DD MMM YYYY'),
          });
        } else {
        }
      })
      .catch((error) => {});
  };
  MemberCalenderByWeek = (weekStartDate, weekEndDate) => {
    let startDate = moment(weekStartDate).format('YYYY-MM-DD');
    let endDate = moment(weekEndDate).format('YYYY-MM-DD');
    const postObject = {
      header: this.header,

      fromdate: startDate,
      todate: endDate,
    };
    FetchServerData.callPostService('/homems/getConsolidatedMemberDetails', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let memberActivityDetails = output.data.memberActivityList;
          let currentDateMap = {
            0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
            8: [],
            9: [],
            10: [],
            11: [],
            12: [],
            13: [],
            14: [],
            15: [],
            16: [],
            17: [],
            18: [],
            19: [],
            20: [],
            21: [],
            22: [],
            23: [],
          };
          let weekDateList = [];
          let headerWeekDateList = [];
          headerWeekDateList = Utils.getHeaderWeekDatesList(endDate, endDate);
          weekDateList = Utils.getWeekDatesList(endDate, endDate);

          for (let i = 0; i < memberActivityDetails.length; i++) {
            memberActivityDetails[i].date = Utils.convertConsolidatedUTCDateToUserTimeZoneDate(
              memberActivityDetails[i].date
            );
          }

          for (let k in memberActivityDetails) {
            let hour = Utils.getHours(memberActivityDetails[k].date);
            let date = Utils.getWeekDate(memberActivityDetails[k].date);
            if (weekDateList[date]) {
              weekDateList[date].push(memberActivityDetails[k]);
            }
          }

          this.setState({
            weekStartDate: startDate,
            weekEndDate: endDate,
            headerWeekDateList: headerWeekDateList,
            render: true,
            weekDataMap: weekDateList,
            startOfWeek: startDate,
            endOfWeek: endDate,
          });
        } else {
        }
      })
      .catch((error) => {});
  };
  weekViewFilterInBackend = () => {
    let startDate = moment(this.state.weekStartDate).startOf('week').format('YYYY-MM-DD');
    let endDate = moment(this.state.weekEndDate).endOf('week').format('YYYY-MM-DD');
    const postObject = {
      header: this.header,

      fromdate: startDate,
      todate: endDate,
      type: this.state.filterOptionType,
    };
    FetchServerData.callPostService('/homems/getFilteredConsolidatedMemberDetails', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let memberActivityDetails = output.data.memberActivityList;

          let currentDateMap = {
            0: [],
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
            8: [],
            9: [],
            10: [],
            11: [],
            12: [],
            13: [],
            14: [],
            15: [],
            16: [],
            17: [],
            18: [],
            19: [],
            20: [],
            21: [],
            22: [],
            23: [],
          };

          let headerWeekDateList = [];
          let weekDateList = [];
          headerWeekDateList = Utils.getHeaderWeekDatesList(endDate, endDate);
          weekDateList = Utils.getWeekDatesList(endDate, endDate);

          for (let i = 0; i < memberActivityDetails.length; i++) {
            memberActivityDetails[i].date = Utils.convertConsolidatedUTCDateToUserTimeZoneDate(
              memberActivityDetails[i].date
            );
          }

          for (let k in memberActivityDetails) {
            let hour = Utils.getHours(memberActivityDetails[k].date);
            let date = Utils.getWeekDate(memberActivityDetails[k].date);
            if (weekDateList[date]) {
              weekDateList[date].push(memberActivityDetails[k]);
            }
          }
          let startOfWeek = moment(this.state.weekStartDate).startOf('week').format('YYYY-MM-DD');
          let endOfWeek = moment(this.state.weekEndDate).endOf('week').format('YYYY-MM-DD');
          this.setState({
            render: true,
            consolidatedView: 'weekView',
            headerWeekDateList: headerWeekDateList,
            startOfWeek: startOfWeek,
            weekDataMap: weekDateList,
            endOfWeek: endOfWeek,
          });
        } else {
        }
      })
      .catch((error) => {});
  };
  toClassicView() {
    this.props.history.push({
      pathname: '/home',
    });
  }

  setDate = (newDate) => {
    const date = newDate || new Date();
    let nextStartDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    let nextEndDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    let nextDay = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    let selectedDay = moment(nextDay).format('dddd');

    this.MemberCalenderByDay(nextStartDate, nextEndDate);
    this.setState({
      currentDayDate: date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate(),
      currentDay: selectedDay,
      selectedStartDate: nextStartDate,
      selectedEndDate: nextEndDate,

      dateMap: {
        0: [],
      },
    });
  };
  getNextDate = () => {
    const { currentDayDate } = this.state;

    const currentDayInMilli = new Date(currentDayDate).getTime();
    const oneDay = 1000 * 60 * 60 * 24;
    const nextDayInMilli = currentDayInMilli + oneDay;
    const nextDate = new Date(nextDayInMilli);

    this.setDate(nextDate);
  };

  getPreviousDate = () => {
    const { currentDayDate } = this.state;

    const currentDayInMilli = new Date(currentDayDate).getTime();
    const oneDay = 1000 * 60 * 60 * 24;
    const previousDayInMilli = currentDayInMilli - oneDay;
    const previousDate = new Date(previousDayInMilli);

    this.setDate(previousDate);
  };
  getNextWeekDate = () => {
    // this.state.weekDateList.length = 0;
    // this.state.headerWeekDateList.length = 0;

    // let firstDay = moment(this.state.selectedWeekDate).format('YYYY-MM-DD');
    // let nextWeekStartDate = new Date(firstDay.getTime() + 7 * 24 * 60 * 60 * 1000);
    // let weekEndOfDate = new Date(nextWeekStartDate.getTime() + 7 * 24 * 60 * 60 * 1000);
    // let oneDay = 1000 * 60 * 60 * 24;
    // let previousDayInMilli = weekEndOfDate - oneDay;
    // let nextWeekEndDate = new Date(previousDayInMilli);
    let endDate = moment(this.state.weekEndDate).add(7, 'd').format('YYYY-MM-DD');
    let startOfWeek = moment(endDate).startOf('week').format('YYYY-MM-DD');
    let endOfWeek = moment(endDate).endOf('week').format('YYYY-MM-DD');

    this.setState({
      weekStartDate: startOfWeek,
      weekEndDate: endOfWeek,

      startOfWeek: startOfWeek,
      endOfWeek: endOfWeek,
    });
    this.MemberCalenderByWeek(startOfWeek, endOfWeek);
  };

  getPreviousWeekDate = () => {
    let endDate = moment(this.state.weekStartDate).subtract(7, 'd').format('YYYY-MM-DD');
    let startOfWeek = moment(endDate).startOf('week').format('YYYY-MM-DD');
    let endOfWeek = moment(endDate).endOf('week').format('YYYY-MM-DD');

    this.MemberCalenderByWeek(startOfWeek, endOfWeek);
    this.setState({
      weekStartDate: startOfWeek,
      weekEndDate: endOfWeek,

      startOfWeek: startOfWeek,
      endOfWeek: endOfWeek,
    });
  };
  handleSelectedDateChange(date) {
    let selectedStartDate = moment(date).format('YYYY-MM-DD');
    let selectedEndDate = moment(date).format('YYYY-MM-DD');
    let selectedDay = moment(date).format('dddd');
    this.setState({
      selectedDate: date,

      startDate: selectedStartDate,
      endDate: selectedEndDate,
      weekStartDate: selectedStartDate,
      weekEndDate: selectedEndDate,
    });
  }

  handleSelectedWeakDateChange(date) {
    let selectedStartDate = moment(date).format('YYYY-MM-DD');
    let selectedEndDate = moment(date).format('YYYY-MM-DD');
    let selectedDay = moment(date).format('dddd');

    this.setState({
      selectedWeekDate: date,

      weekStartDate: selectedStartDate,
      weekEndDate: selectedEndDate,
      dateMap: {
        0: [],
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
        8: [],
        9: [],
        10: [],
        11: [],
        12: [],
        13: [],
        14: [],
        15: [],
        16: [],
        17: [],
        18: [],
        19: [],
        20: [],
        21: [],
        22: [],
        23: [],
      },
    });
  }

  handleFilterTypeChange(selectedType) {
    this.state.filterOptionType = selectedType;

    if (undefined !== this.state.onChange && null !== this.state.onChange) {
      this.state.onChange(this.state);
    }
    this.setState({
      filterOptionType: selectedType,
    });
  }
  toWeekView() {
    this.setState({
      consolidatedView: 'weekView',
    });
    store.dispatch(consolidatedMemberAction.setConsolidatedMember({}));
    let date = new Date();
    let startDate = moment(this.state.startDate).format('YYYY-MM-DD');
    let endDate = moment(this.state.startDate).format('YYYY-MM-DD');
    let weekStartDate = moment(startDate).startOf('week');
    let weekEndDate = moment(startDate).endOf('week');

    this.MemberCalenderByWeek(weekStartDate, weekEndDate);
  }
  toDayView() {
    this.setState({
      consolidatedView: 'dayView',
    });
    store.dispatch(consolidatedMemberAction.setConsolidatedMember({}));
    let date = new Date();
    let startDate = moment(date).format('YYYY-MM-DD');
    let endDate = moment(date).format('YYYY-MM-DD');

    this.MemberCalenderByDay(this.state.startDate, this.state.endDate);
  }

  downloadWeekExcelFromBackend() {
    const postObject = {
      header: this.header,
      fromDate: this.state.startOfWeek,
      toDate: this.state.endOfWeek,
    };

    FetchServerData.callPostService('reportms/consolidatedReport', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        const byteArray = new Buffer(output.data.reportData.replace(/^[\w\d;:\/]+base64\,/g, ''), 'base64');

        const outputFilename = `${Date.now()}.xlsx`;
        const url = URL.createObjectURL(new Blob([byteArray]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
      } else {
      }
    });
  }

  downloadDayExcelFromBackend() {
    let startDate = moment(this.state.selectedStartDate).format('YYYY-MM-DD');
    let endDate = moment(this.state.selectedEndDate).format('YYYY-MM-DD');
    const postObject = {
      header: this.header,
      fromDate: startDate,
      toDate: endDate,
    };

    FetchServerData.callPostService('reportms/consolidatedReport', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        const byteArray = new Buffer(output.data.reportData.replace(/^[\w\d;:\/]+base64\,/g, ''), 'base64');

        const outputFilename = `${Date.now()}.xlsx`;
        const url = URL.createObjectURL(new Blob([byteArray]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', outputFilename);
        document.body.appendChild(link);
        link.click();
      } else {
      }
    });
  }

  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      if (this.state.consolidatedView === 'dayView') {
        return this.dayView();
      } else if (this.state.consolidatedView === 'weekView') {
        return this.WeekView();
      } else if (this.state.consolidatedView === 'printWeekView') {
        return this.printWeekView();
      } else if (this.state.consolidatedView === 'printDayView') {
        return this.printDayView();
      }
    }
  }

  showWeekViewCard(week) {
    const { currentDayDate, currentDay } = this.state;
    for (let i = 0; i < week.length; i++) {
      return (
        <>
          <CalenderEventCard
            memberActivityDetails={week[i]}
            history={this.props.history}
            currentDayDate={currentDayDate}
            currentDay={currentDay}
            consolidatedView="weekView"
            weekStartDate={this.state.weekStartDate}
            weekEndDate={this.state.weekEndDate}
          />
        </>
      );
    }
  }
  printDayViewData = () => {
    this.setState({ consolidatedView: 'printDayView' });
  };
  backToDayView = () => {
    this.setState({ consolidatedView: 'dayView' });
  };
  backToWeekView = () => {
    this.setState({ consolidatedView: 'weekView' });
  };
  printWeekViewData = () => {
    this.setState({ consolidatedView: 'printWeekView' });
  };
  showDayViewCard(day) {
    const { currentDayDate, currentDay } = this.state;

    return this.state.memberList?.map((ChidData, index) => {
      return (
        <>
          <CalenderEventCard
            memberActivityDetails={ChidData}
            history={this.props.history}
            currentDayDate={currentDayDate}
            currentDay={currentDay}
            consolidatedView="dayView"
          />
        </>
      );
    });
  }
  dayView() {
    if (this.state.render === false) {
      return <div />;
    } else {
      const { currentDayDate, currentDay } = this.state;
      return (
        <>
          <MenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            name={this.title}
            headerWidth="home"
            pageId="consolated"
          />
          <Row style={{ margin: '1em' }}>
            <Col md={10}>
              <Advertisement pageId="Home" />
            </Col>
            <Col md={2}>
              <Button onClick={(e) => this.toClassicView()} id="classicViewBtn" startIcon={<GamepadIcon />}>
                Classic View
              </Button>
            </Col>
          </Row>
          <Card style={{ margin: '1em 2em' }}>
            <CardHeader style={{ padding: '0.5em' }}>
              {' '}
              <Row>
                <Col md={12}>
                  <Row md={12} style={{ padding: '0px 5px' }}>
                    <Col md={4}>
                      <Autocomplete
                        id="month"
                        options={null === this.state.filterOptionTypeList ? [] : this.state.filterOptionTypeList}
                        value={this.state.filterOptionType}
                        getOptionLabel={(option) => option}
                        onChange={(event, value) => {
                          this.handleFilterTypeChange(value);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" label="Select Type" margin="normal" />
                        )}
                      />
                    </Col>
                    <Col md={4}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          fullWidth
                          variant="inline"
                          margin="normal"
                          inputVariant="outlined"
                          format="MM/dd/yyyy"
                          label="Select Date"
                          views={['year', 'month', 'date']}
                          value={this.state.selectedDate}
                          onChange={this.handleSelectedDateChange.bind(this)}
                          autoOk
                        />
                      </MuiPickersUtilsProvider>
                    </Col>
                    <Col md={4} style={{ margin: 'auto' }}>
                      <Button
                        variant="contained"
                        id="filterBtn"
                        startIcon={
                          <span>
                            <i className="fa fa-filter" aria-hidden="true"></i>
                          </span>
                        }
                        onClick={(e) => this.DayViewFilterInBackend()}
                      >
                        Filter
                      </Button>
                      &nbsp; &nbsp;
                      <Button
                        variant="contained"
                        size="small"
                        id="resetBtn"
                        startIcon={
                          <span>
                            <i className="fa fa-refresh" aria-hidden="true"></i>
                          </span>
                        }
                        onClick={(e) => this.resetData()}
                      >
                        Reset
                      </Button>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardHeader>
          </Card>
          <Row style={{ margin: '1em' }}>
            <Col md={12}>
              <Card style={{ padding: '1em' }}>
                <CardHeader style={{ padding: '0.5em' }}>
                  <Row>
                    <Col md={5}>
                      <Row>
                        <Col md={6}>
                          <Button
                            onClick={(e) => this.toDayView()}
                            style={{
                              background: this.state.consolidatedView === 'dayView' ? '#005476' : '#707B7C',
                              color: 'white',
                              fontSize: 'x-large',
                              textTransform: 'capitalize',
                              cursor: 'pointer',
                              fontSize: 'x-larger',
                              float: 'left',
                              margin: '0.2em 0.5em 0.3em 0em',
                            }}
                            className="selectDateBtn"
                          >
                            Day
                          </Button>
                          <Button
                            onClick={(e) => this.toWeekView()}
                            style={{
                              background: this.state.consolidatedView === 'weekView' ? '#005476' : '#707B7C',
                              color: 'white',
                              fontSize: 'x-large',
                              textTransform: 'capitalize',
                              cursor: 'pointer',
                              fontSize: 'x-larger',
                              float: 'left',
                              margin: '0.2em 0.5em 0.3em 0em',
                            }}
                            className="selectDateBtn"
                          >
                            Week
                          </Button>
                        </Col>
                        <Col md={6}>
                          <ArrowBackIosIcon id="previousBtn" onClick={this.getPreviousDate.bind(this)} />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={2} className="currentDayCol" style={{ margin: 'auto' }}>
                      {' '}
                      <h3>
                        {' '}
                        <b>{moment(currentDayDate).format('DD MMM YYYY')}</b>
                      </h3>
                    </Col>
                    <Col md={5}>
                      <ArrowForwardIosIcon id="nextWeekBtn" onClick={this.getNextDate.bind(this)} />
                      &nbsp;
                      <Button
                        variant="contained"
                        size="small"
                        id="excelBtn"
                        startIcon={<i class="fa fa-download"></i>}
                        onClick={(e) => this.downloadDayExcelFromBackend()}
                      >
                        Download Excel
                      </Button>
                      &nbsp; &nbsp;
                      {/* <ReactToPrint
                        trigger={() => (
                          <Button variant="contained" size="small" id="printBtn" startIcon={<PrintRoundedIcon />}>
                            Print Preview
                          </Button>
                        )}
                        content={() => this.componentRef}
                      />{' '} */}
                      <Button
                        variant="contained"
                        size="small"
                        id="printBtn"
                        startIcon={<PrintRoundedIcon />}
                        onClick={(e) => this.printDayViewData()}
                      >
                        Print Preview
                      </Button>
                      &nbsp;
                    </Col>
                  </Row>
                </CardHeader>
                <TableContainer
                  component={Paper}
                  ref={(el) => (this.componentRef = el)}
                  style={{ padding: '1.5em 0em', boxShadow: 'none' }}
                >
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    className="consolidatedDayView"
                    style={{ border: '1px solid' }}
                  >
                    <TableHead style={{ fontFamily: 'bold', background: '#B2BABB' }}>
                      <TableRow>
                        <TableCell align="left" style={{ borderLeft: 'none' }}>
                          <h5>Date</h5>
                        </TableCell>
                        <TableCell align="left">
                          <h5>Member</h5>
                        </TableCell>
                        <TableCell align="left">
                          <h5>Type</h5>
                        </TableCell>
                        <TableCell align="left">
                          <h5>Title</h5>
                        </TableCell>
                        <TableCell align="left">
                          <h5>Address</h5>
                        </TableCell>
                        <TableCell align="left">
                          <h5>Status</h5>
                        </TableCell>
                        <TableCell align="left" width="10%">
                          <h5>Action</h5>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {Object.entries(this.state.dateMap).map(([k, v]) => (
                        <>
                          {Object.entries(v).map(([k1, v1]) => (
                            <>
                              {v1 ? (
                                <>
                                  {v1?.map((ChidData) => (
                                    <>
                                      <CalenderEventCard
                                        memberActivityDetails={ChidData}
                                        history={this.props.history}
                                        currentDayDate={currentDayDate}
                                        currentDay={currentDay}
                                        consolidatedView="dayView"
                                      />
                                    </>
                                  ))}
                                </>
                              ) : (
                                <TableRow style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                                  <TableCell></TableCell>
                                </TableRow>
                              )}
                            </>
                          ))}
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Col>
          </Row>
        </>
      );
    }
  }
  printDayView() {
    if (this.state.render === false) {
      return <div />;
    } else {
      const { currentDayDate, currentDay } = this.state;
      return (
        <>
          <MenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            name={this.title}
            headerWidth="home"
            pageId="consolated"
          />
          <Row style={{ margin: '1em' }}>
            <Col md={10}>
              <Advertisement pageId="Home" />
            </Col>
            <Col md={2}>
              <Button onClick={(e) => this.toClassicView()} id="classicViewBtn" startIcon={<GamepadIcon />}>
                Classic View
              </Button>
            </Col>
          </Row>

          <Row style={{ margin: '1em' }}>
            <Col md={12}>
              <Card style={{ padding: '1em' }}>
                <CardHeader style={{ padding: '0.5em' }}>
                  <Row>
                    <Col md={6}>
                      {' '}
                      <Button
                        className="add"
                        style={{
                          background: '#717373',
                          color: '#fff',
                          fontSize: 'x-large',
                          textTransform: 'capitalize',
                          cursor: 'pointer',
                          fontSize: 'x-larger',
                          float: 'left',
                          margin: '0.2em 0.5em 0.3em 0em',
                        }}
                        startIcon={<ArrowBackIcon />}
                        onClick={(e) => this.backToDayView()}
                      >
                        Back
                      </Button>
                    </Col>

                    <Col md={6}>
                      <ReactToPrint
                        trigger={() => (
                          <Button variant="contained" size="small" id="printBtn" startIcon={<PrintRoundedIcon />}>
                            Print
                          </Button>
                        )}
                        content={() => this.componentRef}
                      />
                      &nbsp;
                    </Col>
                  </Row>
                </CardHeader>
                <div ref={(el) => (this.componentRef = el)}>
                  <CardHeader style={{ padding: '1em' }}>
                    <Row>
                      <Col md={12} style={{}}>
                        {' '}
                        <h4 style={{ color: '#000' }}>
                          {' '}
                          <b>{this.loginUserName}</b>
                        </h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className="currentDayCol" style={{ margin: 'auto' }}>
                        {' '}
                        <h3>
                          {' '}
                          <b>{moment(currentDayDate).format('DD MMM YYYY')}</b>
                        </h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <TableContainer component={Paper} style={{ padding: '1em 1em', boxShadow: 'none' }}>
                    <Table
                      stickyHeader
                      aria-label="sticky table"
                      className="consolidatedDayView"
                      style={{ border: '1px solid' }}
                    >
                      <TableHead style={{ fontFamily: 'bold', background: '#B2BABB' }}>
                        <TableRow>
                          <TableCell align="left" style={{ borderLeft: 'none' }}>
                            <h5>Date</h5>
                          </TableCell>
                          <TableCell align="left">
                            <h5>Member</h5>
                          </TableCell>
                          <TableCell align="left">
                            <h5>Type</h5>
                          </TableCell>
                          <TableCell align="left">
                            <h5>Title</h5>
                          </TableCell>
                          <TableCell align="left">
                            <h5>Address</h5>
                          </TableCell>
                          <TableCell align="left">
                            <h5>Status</h5>
                          </TableCell>
                          <TableCell align="left" width="10%">
                            <h5>Action</h5>
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {Object.entries(this.state.dateMap).map(([k, v]) => (
                          <>
                            {Object.entries(v).map(([k1, v1]) => (
                              <>
                                {v1 ? (
                                  <>
                                    {v1?.map((ChidData) => (
                                      <>
                                        <CalenderEventCard
                                          memberActivityDetails={ChidData}
                                          history={this.props.history}
                                          currentDayDate={currentDayDate}
                                          currentDay={currentDay}
                                          consolidatedView="dayView"
                                        />
                                      </>
                                    ))}
                                  </>
                                ) : (
                                  <TableRow style={{ borderBottom: '1px solid rgba(224, 224, 224, 1)' }}>
                                    <TableCell></TableCell>
                                  </TableRow>
                                )}
                              </>
                            ))}
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Card>
            </Col>
          </Row>
        </>
      );
    }
  }
  changeRowColor(data) {
    let color = '';
    if (data.type === 'Event') {
      color = '#D6EAF8';
    } else if (data.type === 'Incident') {
      color = '#FADBD8';
    } else if (data.type === 'Doctor Appointment') {
      color = '#e6dab8';
    } else if (data.type === 'Facility Appointment') {
      color = '#D4EFDF';
    }
    return color;
  }
  displayActionTitle() {
    return <h5 className="doctorAppointmentAction">Action</h5>;
  }
  showDate(k) {
    let date = moment(k).format('dd-mm-yyyy');
  }
  WeekView() {
    if (this.state.render === false) {
      return <div />;
    } else {
      const {
        currentDayDate,
        currentDay,
        weekDateList,
        weekStartDate,
        weekEndDate,
        headerWeekDateList,
        endOfWeek,
        startOfWeek,
      } = this.state;
      return (
        <>
          <MenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            name={this.title}
            headerWidth="home"
            pageId="consolated"
          />
          <Row style={{ margin: '1em' }}>
            <Col md={10}>
              <Advertisement pageId="Home" />
            </Col>
            <Col md={2}>
              <Button onClick={(e) => this.toClassicView()} id="classicViewBtn" startIcon={<GamepadIcon />}>
                Classic View
              </Button>
            </Col>
          </Row>
          <Card style={{ margin: '1em 2em' }}>
            <CardHeader style={{ padding: '0.5em' }}>
              {' '}
              <Row>
                <Col md={12}>
                  <Row md={12} style={{ padding: '10px 5px' }}>
                    <Col md={4}>
                      <Autocomplete
                        id="month"
                        options={null === this.state.filterOptionTypeList ? [] : this.state.filterOptionTypeList}
                        value={this.state.filterOptionType}
                        getOptionLabel={(option) => option}
                        onChange={(event, value) => {
                          this.handleFilterTypeChange(value);
                        }}
                        renderInput={(params) => (
                          <TextField {...params} variant="outlined" label="Select Type" margin="normal" />
                        )}
                      />
                    </Col>
                    <Col md={4}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <DatePicker
                          fullWidth
                          variant="inline"
                          margin="normal"
                          inputVariant="outlined"
                          format="MM/dd/yyyy"
                          label="Select Date"
                          views={['year', 'month', 'date']}
                          value={this.state.selectedDate}
                          onChange={this.handleSelectedDateChange.bind(this)}
                          autoOk
                        />
                      </MuiPickersUtilsProvider>
                    </Col>
                    <Col md={4} style={{ margin: 'auto' }}>
                      <Button
                        variant="contained"
                        // size="small"
                        id="filterBtn"
                        startIcon={
                          <span>
                            <i className="fa fa-filter" aria-hidden="true"></i>
                          </span>
                        }
                        onClick={(e) => this.weekViewFilterInBackend()}
                      >
                        Filter
                      </Button>
                      &nbsp; &nbsp;
                      <Button
                        variant="contained"
                        size="small"
                        id="resetBtn"
                        startIcon={
                          <span>
                            <i className="fa fa-refresh" aria-hidden="true"></i>
                          </span>
                        }
                        onClick={(e) => this.resetWeekData()}
                      >
                        Reset
                      </Button>
                      &nbsp; &nbsp;
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardHeader>
          </Card>
          <Row style={{ margin: '1em' }}>
            <Col md={12}>
              <Card style={{ padding: '1em' }}>
                <CardHeader style={{ padding: '0.5em' }}>
                  <Row>
                    <Col md={4}>
                      <Row>
                        <Col md={6}>
                          <Button
                            onClick={(e) => this.toDayView()}
                            style={{
                              background: this.state.consolidatedView === 'dayView' ? '#005476' : '#707B7C',
                              color: 'white',
                              fontSize: 'x-large',
                              textTransform: 'capitalize',
                              cursor: 'pointer',
                              fontSize: 'x-larger',
                              float: 'left',
                              margin: '0.2em 0.5em 0.3em 0em',
                            }}
                            className="selectDateBtn"
                          >
                            Day
                          </Button>
                          <Button
                            onClick={(e) => this.toWeekView()}
                            style={{
                              background: this.state.consolidatedView === 'weekView' ? '#005476' : '#707B7C',
                              color: 'white',
                              fontSize: 'x-large',
                              textTransform: 'capitalize',
                              cursor: 'pointer',
                              fontSize: 'x-larger',
                              float: 'left',
                              margin: '0.2em 0.5em 0.3em 0em',
                            }}
                            className="selectDateBtn"
                          >
                            Week
                          </Button>
                        </Col>
                        <Col md={6}>
                          <ArrowBackIosIcon id="previousBtn" onClick={this.getPreviousWeekDate.bind(this)} />
                        </Col>
                      </Row>
                    </Col>
                    <Col md={4} className="currentDayCol" style={{ margin: 'auto' }}>
                      {' '}
                      <h3>
                        {' '}
                        <b>
                          {moment(startOfWeek).format('ddd, DD MMM') +
                            ' - ' +
                            moment(endOfWeek).format('ddd, DD MMM') +
                            ', ' +
                            moment(weekEndDate).format('YYYY')}
                        </b>
                      </h3>
                    </Col>
                    <Col md={4}>
                      <ArrowForwardIosIcon id="nextWeekBtn" onClick={this.getNextWeekDate.bind(this)} />
                      &nbsp;
                      <Button
                        variant="contained"
                        size="small"
                        id="excelBtn"
                        startIcon={<i class="fa fa-download"></i>}
                        onClick={(e) => this.downloadWeekExcelFromBackend()}
                      >
                        Download Excel
                      </Button>
                      &nbsp; &nbsp;
                      {/* <ReactToPrint
                        trigger={() => (
                          <Button variant="contained" size="small" id="printBtn" startIcon={<PrintRoundedIcon />}>
                            print
                          </Button>
                        )}
                        content={() => this.componentRef}
                      />{' '} */}
                      <Button
                        variant="contained"
                        size="small"
                        id="printBtn"
                        startIcon={<PrintRoundedIcon />}
                        onClick={(e) => this.printWeekViewData()}
                      >
                        Print Preview
                      </Button>
                      &nbsp;
                    </Col>
                  </Row>
                </CardHeader>

                <TableContainer
                  component={Paper}
                  ref={(el) => (this.componentRef = el)}
                  style={{ padding: '1.5em 0em', boxShadow: 'none' }}
                >
                  <Table stickyHeader aria-label="sticky table" className="weekViewTable">
                    <TableHead style={{ fontFamily: 'bold', background: '#B2BABB' }}>
                      <TableRow>
                        <TableCell align="left" style={{ width: '5%', background: '#B2BABB' }}>
                          <b> Date</b>
                        </TableCell>
                        <TableCell align="left" style={{ padding: '0px' }} id="weekCell">
                          <MaterialTable
                            id="weekTableHead"
                            title=""
                            columns={[
                              {
                                title: 'Date-Time',

                                align: 'left',
                                width: '15%',
                              },
                              { title: 'Member', width: '10%' },
                              { title: 'Type', width: '15%' },
                              {
                                title: 'Purpose',

                                width: '15%',
                              },
                              { title: 'Address', width: '20%' },

                              {
                                title: 'Status',

                                align: 'center',
                                width: '10%',
                              },
                            ]}
                            data={this.state.completedAppointmentList}
                            localization={{
                              header: {
                                actions: this.displayActionTitle(),
                              },
                              body: {
                                emptyDataSourceMessage: <></>,
                              },
                            }}
                            options={{
                              headerStyle: {
                                backgroundColor: '#B2BABB',
                                fontWeight: 'bold',
                                textAlign: 'center',
                              },

                              sorting: true,
                              toolbar: false,
                              actionsColumnIndex: -1,
                              paging: false,
                              rowStyle: (rowData) => ({
                                height: '0px !important',
                              }),
                            }}
                            actions={[
                              (rowData) => ({
                                /*if appointment has Reference Appointment then show then button */
                                icon: () =>
                                  rowData.hasReferenceAppointments === true ? (
                                    <>
                                      <AccountTreeIcon
                                        className="referenceAppointment"
                                        style={{ fontSize: '25px', color: '#2E86C1' }}
                                      />
                                      &nbsp;
                                    </>
                                  ) : (
                                    <div style={{ marginLeft: '1em' }}></div>
                                  ),
                                tooltip: 'Reference Appointment',
                              }),
                              (rowData) => ({
                                icon: () =>
                                  /*if appointment has message threads then show then button */
                                  rowData.hasMessagingThreads === true ? (
                                    <>
                                      <Badge
                                        badgeContent={rowData.unreadMessageCount}
                                        color="primary"
                                        overlap="rectangular"
                                      >
                                        <ChatIcon
                                          className="message"
                                          style={{ fontSize: '25px', color: '#58D68D', marginRight: '0.2em' }}
                                        />
                                      </Badge>
                                      &nbsp;
                                    </>
                                  ) : (
                                    <div style={{ marginLeft: '1em' }}></div>
                                  ),
                                tooltip: 'Message',
                              }),

                              (rowData) => ({
                                icon: () =>
                                  /*if appointment has active Reminder  then show then button */
                                  rowData.hasReminders === true ? (
                                    <>
                                      <Badge
                                        badgeContent={rowData.activeReminderCount}
                                        color="primary"
                                        overlap="rectangular"
                                      >
                                        <AccessAlarmTwoToneIcon
                                          className="reminder"
                                          style={{ fontSize: '25px', color: '#EF797D' }}
                                        />
                                      </Badge>
                                      &nbsp;
                                    </>
                                  ) : (
                                    <div style={{ marginLeft: '1em' }}></div>
                                  ),
                                tooltip: 'Reminder',
                              }),
                              (rowData) => ({
                                icon: () =>
                                  /*if appointment has transportation  then show then button */
                                  rowData.hasTransportation === true ? (
                                    <>
                                      <DriveEtaIcon
                                        className="transportation"
                                        style={{ fontSize: '25px', color: '#E67E22', marginLeft: '0.2em' }}
                                      />
                                      &nbsp;
                                    </>
                                  ) : (
                                    <div style={{ marginLeft: '1em' }}></div>
                                  ),
                                tooltip: 'Transportation',
                                iconProps: { display: 'contents' },
                              }),
                            ]}
                          />
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody id="weekBody">
                      {Object.entries(this.state.weekDataMap).map(([k, v]) => (
                        <>
                          <TableRow>
                            <TableCell style={{ borderRight: 'none !important' }}>
                              <b>{k}</b>{' '}
                            </TableCell>

                            {v.length > 0 ? (
                              <TableCell>
                                <CalenderEventCard
                                  memberActivityDetails={v}
                                  history={this.props.history}
                                  currentDayDate={currentDayDate}
                                  currentDay={currentDay}
                                  consolidatedView="weekView"
                                  weekStartDate={weekStartDate}
                                  weekEndDate={weekEndDate}
                                />
                              </TableCell>
                            ) : (
                              <TableCell></TableCell>
                            )}
                          </TableRow>
                        </>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Card>
            </Col>
          </Row>
        </>
      );
    }
  }
  printWeekView() {
    if (this.state.render === false) {
      return <div />;
    } else {
      const {
        currentDayDate,
        currentDay,
        weekDateList,
        weekStartDate,
        weekEndDate,
        headerWeekDateList,
        endOfWeek,
        startOfWeek,
      } = this.state;
      return (
        <>
          <MenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            name={this.title}
            headerWidth="home"
            pageId="consolated"
          />
          <Row style={{ margin: '1em' }}>
            <Col md={10}>
              <Advertisement pageId="Home" />
            </Col>
            <Col md={2}>
              <Button onClick={(e) => this.toClassicView()} id="classicViewBtn" startIcon={<GamepadIcon />}>
                Classic View
              </Button>
            </Col>
          </Row>

          <Row style={{ margin: '1em' }}>
            <Col md={12}>
              <Card style={{ padding: '1em' }}>
                <CardHeader style={{ padding: '0.5em' }}>
                  <Row>
                    <Col md={6}>
                      {' '}
                      <Button
                        className="add"
                        style={{
                          background: '#717373',
                          color: '#fff',
                          fontSize: 'x-large',
                          textTransform: 'capitalize',
                          cursor: 'pointer',
                          fontSize: 'x-larger',
                          float: 'left',
                          margin: '0.2em 0.5em 0.3em 0em',
                        }}
                        startIcon={<ArrowBackIcon />}
                        onClick={(e) => this.backToWeekView()}
                      >
                        Back
                      </Button>
                    </Col>

                    <Col md={6}>
                      <ReactToPrint
                        trigger={() => (
                          <Button variant="contained" size="small" id="printBtn" startIcon={<PrintRoundedIcon />}>
                            Print
                          </Button>
                        )}
                        content={() => this.componentRef}
                      />{' '}
                      &nbsp;
                    </Col>
                  </Row>
                </CardHeader>
                <div ref={(el) => (this.componentRef = el)}>
                  <CardHeader style={{ padding: '1em 1em' }}>
                    <Row>
                      <Col md={12}>
                        {' '}
                        <h4 style={{ color: '#000' }}>
                          {' '}
                          <b>{this.loginUserName}</b>
                        </h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12} className="currentDayCol" style={{ margin: 'auto' }}>
                        {' '}
                        <h3>
                          {' '}
                          <b>
                            {moment(startOfWeek).format('ddd, DD MMM') +
                              ' - ' +
                              moment(endOfWeek).format('ddd, DD MMM') +
                              ', ' +
                              moment(weekEndDate).format('YYYY')}
                          </b>
                        </h3>
                      </Col>
                    </Row>
                  </CardHeader>
                  <TableContainer component={Paper} style={{ padding: '1em 1em', boxShadow: 'none' }}>
                    <Table stickyHeader aria-label="sticky table" className="weekViewTable">
                      <TableHead style={{ fontFamily: 'bold', background: '#B2BABB' }}>
                        <TableRow>
                          <TableCell align="left" style={{ width: '5%', background: '#B2BABB' }}>
                            <b> Date</b>
                          </TableCell>
                          <TableCell align="left" style={{ padding: '0px' }} id="weekCell">
                            <MaterialTable
                              id="weekTableHead"
                              title=""
                              columns={[
                                {
                                  title: 'Date-Time',

                                  align: 'left',
                                  width: '15%',
                                },
                                { title: 'Member', width: '10%' },
                                { title: 'Type', width: '15%' },
                                {
                                  title: 'Purpose',

                                  width: '15%',
                                },
                                { title: 'Address', width: '20%' },

                                {
                                  title: 'Status',

                                  align: 'center',
                                  width: '10%',
                                },
                              ]}
                              data={this.state.completedAppointmentList}
                              localization={{
                                header: {
                                  actions: this.displayActionTitle(),
                                },
                                body: {
                                  emptyDataSourceMessage: <></>,
                                },
                              }}
                              options={{
                                headerStyle: {
                                  backgroundColor: '#B2BABB',
                                  fontWeight: 'bold',
                                  textAlign: 'center',
                                },

                                sorting: true,
                                toolbar: false,
                                actionsColumnIndex: -1,
                                paging: false,
                                rowStyle: (rowData) => ({
                                  height: '0px !important',
                                }),
                              }}
                              actions={[
                                (rowData) => ({
                                  /*if appointment has Reference Appointment then show then button */
                                  icon: () =>
                                    rowData.hasReferenceAppointments === true ? (
                                      <>
                                        <AccountTreeIcon
                                          className="referenceAppointment"
                                          style={{ fontSize: '25px', color: '#2E86C1' }}
                                        />
                                        &nbsp;
                                      </>
                                    ) : (
                                      <div style={{ marginLeft: '1em' }}></div>
                                    ),
                                  tooltip: 'Reference Appointment',
                                }),
                                (rowData) => ({
                                  icon: () =>
                                    /*if appointment has message threads then show then button */
                                    rowData.hasMessagingThreads === true ? (
                                      <>
                                        <Badge
                                          badgeContent={rowData.unreadMessageCount}
                                          color="primary"
                                          overlap="rectangular"
                                        >
                                          <ChatIcon
                                            className="message"
                                            style={{ fontSize: '25px', color: '#58D68D', marginRight: '0.2em' }}
                                          />
                                        </Badge>
                                        &nbsp;
                                      </>
                                    ) : (
                                      <div style={{ marginLeft: '1em' }}></div>
                                    ),
                                  tooltip: 'Message',
                                }),

                                (rowData) => ({
                                  icon: () =>
                                    /*if appointment has active Reminder  then show then button */
                                    rowData.hasReminders === true ? (
                                      <>
                                        <Badge
                                          badgeContent={rowData.activeReminderCount}
                                          color="primary"
                                          overlap="rectangular"
                                        >
                                          <AccessAlarmTwoToneIcon
                                            className="reminder"
                                            style={{ fontSize: '25px', color: '#EF797D' }}
                                          />
                                        </Badge>
                                        &nbsp;
                                      </>
                                    ) : (
                                      <div style={{ marginLeft: '1em' }}></div>
                                    ),
                                  tooltip: 'Reminder',
                                }),
                                (rowData) => ({
                                  icon: () =>
                                    /*if appointment has transportation  then show then button */
                                    rowData.hasTransportation === true ? (
                                      <>
                                        <DriveEtaIcon
                                          className="transportation"
                                          style={{ fontSize: '25px', color: '#E67E22', marginLeft: '0.2em' }}
                                        />
                                        &nbsp;
                                      </>
                                    ) : (
                                      <div style={{ marginLeft: '1em' }}></div>
                                    ),
                                  tooltip: 'Transportation',
                                  iconProps: { display: 'contents' },
                                }),
                              ]}
                            />
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody id="weekBody">
                        {Object.entries(this.state.weekDataMap).map(([k, v]) => (
                          <>
                            <TableRow>
                              <TableCell style={{ borderRight: 'none !important' }}>
                                <b>{k}</b>{' '}
                              </TableCell>

                              {v.length > 0 ? (
                                <TableCell>
                                  <CalenderEventCard
                                    memberActivityDetails={v}
                                    history={this.props.history}
                                    currentDayDate={currentDayDate}
                                    currentDay={currentDay}
                                    consolidatedView="weekView"
                                    weekStartDate={weekStartDate}
                                    weekEndDate={weekEndDate}
                                  />
                                </TableCell>
                              ) : (
                                <TableCell></TableCell>
                              )}
                            </TableRow>
                          </>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </Card>
            </Col>
          </Row>
        </>
      );
    }
  }
}

export default ConsolidatedView;
