import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { Component } from 'react';
import { Col, Label, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import store from '../../../redux/store';
import PtsAlert from '../ptsAlert/PtsAlert';
class AddressWithTimezone extends Component {
  header = store.getState().header.header;
  countryListArray = store.getState().staticData.countryList;
  userData = store.getState().userData.userData;
  memberDetails = store.getState().selectedMemberData.selectedMemberData;
  countryOptionList = [];
  stateOptionList = [];
  stateListArray = [];
  timeZoneListArray = [];
  constructor(props) {
    super(props);
    if (this.props.pageId === 'creataccount') {
      this.getCountryList();
      for (let i = 0; i < this.countryListArray.length; i++) {
        if (Utils.equalsIgnoreCase(this.countryListArray[i].namecode, 'United States')) {
          this.selectedCountry = {
            namecode: this.countryListArray[i].namecode,
            name: this.countryListArray[i].name,
            code: this.countryListArray[i].code,
            isoCode: this.countryListArray[i].isoCode,
            description: this.countryListArray[i].description,
            id: this.countryListArray[i].id,
          };
          this.setStatesForCountry(this.selectedCountry);
        }
      }

      this.state = {
        id: '',
        line: '',
        country: this.selectedCountry,
        state: null,
        timeZone: null,
        city: '',
        zipCode: '',
        action: this.props.action,
        onChange: this.props.onChange,
        render: true,
      };
    } else {
      let address = props.data;
      if (address && address.state) {
        this.state = {
          id: address.id ? address.id : '',
          line: address.line ? address.line : '',
          state: address.state ? address.state : '',
          timeZone: address.timezone ? address.timezone : null,
          selectedState: address.state ? address.state : '',
          country: address.state ? address.state.country : '',
          city: address.city ? address.city : '',
          zipCode: address.zipCode ? address.zipCode : '',
          stateClear: false,
          action: this.props.action,
          onChange: this.props.onChange,
          render: true,
        };
      } else {
        if (this.userData.address !== null && this.userData.address.state !== null) {
          this.selectedCountry = {
            id:
              this.userData.address && this.userData.address.state && this.userData.address.state.country
                ? this.userData.address.state.country.id
                : '',
            namecode:
              this.userData.address && this.userData.address.state && this.userData.address.state.country
                ? this.userData.address.state.country.namecode
                : '',
          };
          this.setStatesForCountry(this.selectedCountry);
          this.state = {
            id: '',
            line: '',
            country: this.userData.address.state.country ? this.userData.address.state.country : '',
            state: this.userData.address.state ? this.userData.address.state : null,
            timeZone: this.userData.address ? this.userData.address.timezone : '',
            city: '',
            zipCode: '',
            action: this.props.action,
            onChange: this.props.onChange,
            render: true,
          };
        } else if (
          this.userData.address.timezone !== null &&
          this.userData.address !== null &&
          this.userData.address.state === null
        ) {
          this.getCountryList();
          for (let i = 0; i < this.countryListArray.length; i++) {
            if (Utils.equalsIgnoreCase(this.countryListArray[i].namecode, 'United States')) {
              this.selectedCountry = {
                namecode: this.countryListArray[i].namecode,
                name: this.countryListArray[i].name,
                code: this.countryListArray[i].code,
                isoCode: this.countryListArray[i].isoCode,
                description: this.countryListArray[i].description,
                id: this.countryListArray[i].id,
              };
              this.setStatesForCountry(this.selectedCountry);
            }
          }

          this.state = {
            id: '',
            line: '',
            country: this.selectedCountry,
            state: this.userData.address.state ? this.userData.address.state : null,
            timeZone: this.userData.address.timezone ? this.userData.address.timezone : null,
            city: '',
            zipCode: '',
            action: this.props.action,
            onChange: this.props.onChange,
            render: true,
          };
        } else {
          this.getCountryList();
          for (let i = 0; i < this.countryListArray.length; i++) {
            if (Utils.equalsIgnoreCase(this.countryListArray[i].namecode, 'United States')) {
              this.selectedCountry = {
                namecode: this.countryListArray[i].namecode,
                name: this.countryListArray[i].name,
                code: this.countryListArray[i].code,
                isoCode: this.countryListArray[i].isoCode,
                description: this.countryListArray[i].description,
                id: this.countryListArray[i].id,
              };
              this.setStatesForCountry(this.selectedCountry);
            }
          }

          this.state = {
            id: '',
            line: '',
            country: this.selectedCountry,
            state: null,
            timeZone: null,
            city: '',
            zipCode: '',
            action: this.props.action,
            onChange: this.props.onChange,
            render: true,
          };
        }
        // }
      }
    }

    if (this.state.country !== null && undefined !== this.state.country) {
      this.handleCountryChange(this.state.country);
    } else {
    }
  }

  setStatesForCountry(selectedCountry) {
    this.setState({ country: selectedCountry, selectedCountry: selectedCountry });
  }

  handleStateChange(selectedState) {
    this.state.state = selectedState;
    this.setState({ state: selectedState });
    if (undefined !== this.onChange && null !== this.onChange) {
      this.onChange(this.state);
    }
  }

  handleTimeZoneChange(selectedTimezone) {
    this.state.timeZone = selectedTimezone;
    this.setState({ timeZone: selectedTimezone });
    if (undefined !== this.onChange && null !== this.onChange) {
      this.onChange(this.state);
    }
  }

  handleLine1Change(e) {
    let val = e.target.value;
    let res = val.substring(0, 128);
    if (res.length < 128) {
      this.setState({ line: res });
    } else {
      Utils.maxFieldLength(128);
    }
  }

  handleLine2Change(e) {
    this.setState({ line2: e.target.value });
  }

  handleCityChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 128);
    if (res.length < 128) {
      this.setState({ city: res });
    } else {
      Utils.maxFieldLength(128);
    }
  }

  handlePinCodeChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 16);
    if (res.length < 16) {
      this.setState({ zipCode: res });
    } else {
      Utils.maxFieldLength(16);
    }
  }

  handleCountryChange(selectedCountry) {
    if (null === selectedCountry || selectedCountry === '') {
      this.stateListArray = [];
      this.timeZoneListArray = [];
      this.setState({
        stateClear: !this.state.stateClear,
      });
      return;
    }
    this.state.country = selectedCountry;
    if (undefined !== this.onChange && null !== this.onChange) {
      this.onChange(this.state);
    }

    this.setState({
      country: selectedCountry,
      state: '',
      timeZone: null,
    });

    const postObject = {
      country: { id: selectedCountry.id },
    };

    let url = '/timezonems/getForCountry';
    FetchServerData.callPostService(url, postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let stateTimezoneList = output.data;
        this.stateListArray = stateTimezoneList.stateList;
        this.timeZoneListArray = stateTimezoneList.timeZoneList;
        this.setState({
          render: true,
        });
      } else {
      }
    });
  }

  getCountryList() {
    FetchServerData.callPostService('/countryms/getAll').then((output_data) => {
      if (output_data.status === 'SUCCESS') {
        this.countryListArray = output_data.data;
        this.setState({
          render: true,
        });
      } else {
      }
    });
  }

  validateAddress() {
    if (Utils.isNullOrEmpty(this.state.country)) {
      document.getElementById('country').focus();
      PtsAlert.error('Please Select Country');

      return false;
    }
    if (Utils.isNullOrEmpty(this.state.state)) {
      document.getElementById('state').focus();
      PtsAlert.error('Please Select State');
      return false;
    }
    if (Utils.isNullOrEmpty(this.state.timeZone)) {
      document.getElementById('timeZone').focus();
      PtsAlert.error('Please Select TimeZone');
      return false;
    }

    return true;
  }

  render() {
    if (this.state.render === false) {
      return <div />;
    }
    this.countryRef = React.createRef();
    this.stateRef = React.createRef();
    if (this.props.action === 'create') {
      return this.createAddress();
    } else if (this.props.action === 'edit') {
      return this.editAddress();
    } else {
      return this.viewAddress();
    }
  }

  editAddress() {
    return (
      <>
        {undefined !== this.props.title && this.props.title.length > 0 && (
          <Row>
            <Col>
              <Label style={{ fontWeight: '600', color: '#1b7189', marginLeft: '0.5em' }}>{this.props.title}</Label>
            </Col>
          </Row>
        )}
        <Row className="editAddressRow">
          <Col md={12}>
            <TextField
              fullWidth
              variant="outlined"
              id="line"
              label="Address"
              margin="normal"
              value={this.state.line}
              onChange={this.handleLine1Change.bind(this)}
              onBlur={() => {
                this.props.onChange(this.state);
              }}
              inputProps={{ maxLength: 128, style: { textTransform: 'capitalize' } }}
              name="name"
              type="text"
              className="textField"
              errorMessages={['Max 12th required']}
            />
          </Col>
        </Row>
        <Row className="editAddressRow">
          <Col md={6}>
            <Autocomplete
              id="country"
              options={this.countryListArray}
              value={this.state.country}
              defaultValue={this.state.country}
              getOptionLabel={(option) => option.namecode}
              onChange={(event, value) => {
                this.handleCountryChange(value);
              }}
              inputProps={{ style: { textTransform: 'capitalize' } }}
              renderInput={(params) => (
                <TextField {...params} label="Country" variant="outlined" required margin="normal" />
              )}
            />
          </Col>

          <Col md={6}>
            <Autocomplete
              id="state"
              options={this.stateListArray}
              value={this.state.state}
              getOptionLabel={(option) => option.namecode}
              defaultValue={this.state.state}
              onChange={(event, value) => {
                this.handleStateChange(value);
              }}
              inputProps={{ style: { textTransform: 'capitalize' } }}
              renderInput={(params) => (
                <TextField {...params} label="State" variant="outlined" required margin="normal" />
              )}
            />
          </Col>
        </Row>
        <Row className="editAddressRow">
          <Col md={6}>
            <TextField
              fullWidth
              variant="outlined"
              id="city"
              label="City"
              margin="normal"
              value={this.state.city}
              onChange={this.handleCityChange.bind(this)}
              onBlur={() => {
                this.props.onChange(this.state);
              }}
              name="city"
              inputProps={{ maxLength: 128, style: { textTransform: 'capitalize' } }}
              type="text"
              className="textField"
            />
          </Col>

          <Col md={6}>
            <TextField
              fullWidth
              variant="outlined"
              id="zipCode"
              margin="normal"
              label="Postal Code"
              value={this.state.zipCode}
              onChange={this.handlePinCodeChange.bind(this)}
              inputProps={{ maxLength: 16 }}
              onBlur={() => {
                this.props.onChange(this.state);
              }}
              name="zipCode"
              className="textField"
            />
          </Col>
        </Row>
        <Row className="editAddressRow">
          <Col md={6}>
            <Autocomplete
              id="timeZone"
              required
              options={this.timeZoneListArray}
              value={this.state.timeZone}
              getOptionLabel={(option) => option.name + ' (' + option.abbreviation + ')'}
              defaultValue={this.state.timeZone}
              onChange={(event, value) => {
                this.handleTimeZoneChange(value);
              }}
              inputProps={{ style: { textTransform: 'capitalize' } }}
              renderInput={(params) => (
                <TextField {...params} label="Time Zone" variant="outlined" required margin="normal" />
              )}
            />
          </Col>
        </Row>
      </>
    );
  }

  viewAddress() {
    let stateName = null !== this.state.state && undefined !== this.state.state ? this.state.state.namecode : '';
    let countryName =
      null !== this.state.country && undefined !== this.state.country ? this.state.country.namecode : '';
    let statecountryName = stateName + ',' + countryName;
    return (
      <>
        <Row md={12} className="addressRow">
          <Col>
            <Label style={{ color: '#000' }}>{undefined !== this.state.line ? this.state.line : ''}</Label>
          </Col>
        </Row>
        <Row className="addressRow">
          <Col>
            <Label style={{ color: '#000' }}>{undefined !== this.state.city ? this.state.city : ''}</Label>
          </Col>
        </Row>
        <Row className="addressRow">
          <Col>
            <Label style={{ color: '#000' }}>{statecountryName} </Label>
          </Col>
        </Row>
        <Row className="addressRow">
          <Col>
            <Label style={{ color: '#000' }}>{this.state.timeZone.name}</Label>
          </Col>
        </Row>
        <Row className="addressRow">
          <Col>
            <Label style={{ color: '#000' }}>{this.state.zipCode}</Label>
          </Col>
        </Row>
      </>
    );
  }

  createAddress() {
    this.countryTextFieldRef = React.createRef();
    return (
      <form ref={this.formRef}>
        {undefined !== this.props.title && this.props.title.length > 0 && (
          <Row>
            <Col>
              <Label style={{ fontWeight: '600', color: '#1b7189', marginLeft: '0.5em' }}>{this.props.title}</Label>
            </Col>
          </Row>
        )}
        <Row className="editAddressRow">
          <Col md={12}>
            <TextField
              fullWidth
              variant="outlined"
              id="line"
              label="Address"
              margin="normal"
              value={this.state.line}
              onChange={this.handleLine1Change.bind(this)}
              onBlur={() => {
                this.props.onChange(this.state);
              }}
              inputProps={{ maxLength: 128, style: { textTransform: 'capitalize' } }}
              name="name"
              type="text"
              className="textField"
              errorMessages={['Max 12th required']}
            />
          </Col>
        </Row>
        <Row className="editAddressRow">
          <Col md={6}>
            <Autocomplete
              id="country"
              options={this.countryListArray}
              value={this.state.country}
              defaultValue={this.state.country}
              getOptionLabel={(option) => option.namecode}
              onChange={(event, value) => {
                this.handleCountryChange(value);
              }}
              inputProps={{ style: { textTransform: 'capitalize' } }}
              renderInput={(params) => (
                <TextField {...params} label="Country" variant="outlined" required margin="normal" />
              )}
            />
          </Col>

          <Col md={6}>
            <Autocomplete
              id="state"
              options={this.stateListArray}
              value={this.state.state}
              getOptionLabel={(option) => option.namecode}
              defaultValue={this.state.state}
              onChange={(event, value) => {
                this.handleStateChange(value);
              }}
              inputProps={{ style: { textTransform: 'capitalize' } }}
              renderInput={(params) => (
                <TextField {...params} label="State" variant="outlined" required margin="normal" />
              )}
            />
          </Col>
        </Row>
        <Row className="editAddressRow">
          <Col md={6}>
            <TextField
              fullWidth
              variant="outlined"
              id="city"
              label="City"
              margin="normal"
              value={this.state.city}
              onChange={this.handleCityChange.bind(this)}
              onBlur={() => {
                this.props.onChange(this.state);
              }}
              name="city"
              inputProps={{ maxLength: 128, style: { textTransform: 'capitalize' } }}
              type="text"
              className="textField"
            />
          </Col>

          <Col md={6}>
            <TextField
              fullWidth
              variant="outlined"
              id="zipCode"
              margin="normal"
              label="Postal Code"
              value={this.state.zipCode}
              onChange={this.handlePinCodeChange.bind(this)}
              inputProps={{ maxLength: 16 }}
              onBlur={() => {
                this.props.onChange(this.state);
              }}
              name="zipCode"
              className="textField"
            />
          </Col>
        </Row>
        <Row className="editAddressRow">
          <Col md={6}>
            <Autocomplete
              id="timeZone"
              required
              options={this.timeZoneListArray}
              value={this.state.timeZone}
              getOptionLabel={(option) => option.name + ' (' + option.abbreviation + ')'}
              defaultValue={this.state.timeZone}
              onChange={(event, value) => {
                this.handleTimeZoneChange(value);
              }}
              inputProps={{ style: { textTransform: 'capitalize' } }}
              renderInput={(params) => (
                <TextField {...params} label="Time Zone" variant="outlined" required margin="normal" />
              )}
            />
          </Col>
        </Row>
      </form>
    );
  }
}

export default AddressWithTimezone;
