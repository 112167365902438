import Tooltip from '@material-ui/core/Tooltip';
import EditIcon from '@material-ui/icons/Edit';
import React, { Component } from 'react';

export class EditButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onClick: props.onClick,
      caption: props.caption,
    };
  }

  onClick() {
    if (this.state.onClick) {
      this.state.onClick();
    }
  }
  render() {
    return (
      <span
        className="edit"
        onClick={this.onClick.bind(this)}
        style={{ cursor: 'pointer', fontSize: 'x-larger', float: 'right', marginRight: '3px' }}
      >
        <Tooltip title="Edit">
          <EditIcon
            id="iconButton"
            className="editLocation"
            style={{
              float: 'right',
              fontSize: 'small',

              height: '2em',
              width: '2em',
              background: 'rgb(19, 98, 105)',
              cursor: 'pointer',
              color: 'white',
              border: '2px solid #edf0f1',
              borderRadius: '50%',
              padding: '2px',
            }}
          />
        </Tooltip>
      </span>
    );
  }
}
export default EditButton;
