import Button from '@material-ui/core/Button';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';
import CloseIcon from '@material-ui/icons/Close';
import SaveIcon from '@material-ui/icons/Save';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';
import React, { Component } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import 'react-tabs/style/react-tabs.css';
import { Card, CardBody, CardFooter, CardHeader, Col, Label, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import fccPageStateAction from '../../../redux/fccPageState/fccPageStateAction';
import helpDataAction from '../../../redux/help/displayHelpAction';
import store from '../../../redux/store';
import BackButton from '../buttons/BackButton';
import CancelIconButton from '../buttons/CancelIconButton';
import EditIconButton from '../buttons/EditIconButton';
import SaveButton from '../buttons/SaveButton';
import Location from '../location/Location';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
import PtsAlert from '../ptsAlert/PtsAlert';
// import './facilityImport.scss';
export class FacilityImport extends Component {
  facilityTypeList = store.getState().staticData.facilityTypeList;
  header = store.getState().header.header;
  memberDetails = store.getState().memberData.memberData;
  memberName = store.getState().memberData.memberData.firstname + ' ' + store.getState().memberData.memberData.lastname;
  isFreeUser = store.getState().userData.userData.isFreeUser;
  displayHelp = store.getState().help.help;
  locationReferenceList = [];
  fccPageState = store.getState().fccPageState.fccPageState;
  memberHomeUrl = store.getState().memberHomeUrl.memberHomeUrl.homeUrl;
  title = 'Facility Details';
  breadCrumb = [
    { pageid: this.memberHomeUrl, uiname: this.memberName },
    { pageid: '/memberFacility', uiname: 'Facility List' },
    { pageid: '', uiname: 'Facility Details' },
  ];
  constructor(props) {
    super(props);

    let action = props.action;
    let title = '';
    let pageId = '';
    let locationId = '';
    let facilityData = {};
    let facilityId = props.facilityId;
    if (!facilityId && props.history && props.history.location && props.history.location.state) {
      facilityId = props.history.location.state.facilityId;
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
      title = props.history.location.state.title;
      facilityData = props.history.location.state.facilityData;
    }
    let canRender = false;
    if (null !== facilityId && facilityId > 0) {
      this.getFacilityDetails(facilityId);
    } else {
      canRender = true;
    }
    let previousButton = false;
    if (pageId === 'viewMemberAppointment') {
      previousButton = true;
    }
    if (facilityId === undefined) {
      facilityId = this.fccPageState.id;
      action = this.fccPageState.action;
      title = 'Facility Details';
      pageId = 'memberFacility';
      locationId = this.fccPageState.locationId;
      if (action !== 'create') {
        this.getFacilityDetails(facilityId);
      }

      // canRender = true;
    }

    if (action === 'view' || action === 'edit') {
      this.state = {
        id: facilityId,
        action: action,
        render: canRender,
        facilityName: '',
        description: '',
        facilityType: '',
        viewFacilityType: '',
        location: {},
        locationList: [],
        locationItems: [],
        title: title,
        pageId: pageId,
        locationItemsView: true,
        facilityDetails: {},
        previousButton: previousButton,
        facilityAppointmentList: [],
        viewFacility: false,
        pageSize: 10,
        isPaging: false,
        getAllPermission: false,
        getUpdatePermission: false,
        getDeletePermission: false,
        getReadPermission: false,
        getCreatePermission: false,
        isFreeUser: this.isFreeUser,
        stepsEnabled: false,
        addressLocations: [],
        initialStep: 0,
        steps: [
          {
            element: '.editDetails',
            intro: 'Edit/update Facility Details',
          },

          {
            element: '.addLocation',
            intro: 'Add multiple Location Details',
          },
          {
            element: '.deleteIcon',
            intro: 'Delete This Location',
          },
          {
            element: '.editLocation',
            intro: 'Update This Location',
          },
          {
            element: '.facility_Appointment_List',
            intro: 'List of all appointments with this Facility.',
          },
          {
            element: '.newAppointment',
            intro: 'Create new Appointment with this Facility',
          },
          {
            element: '.facilityAppointmentAction',
            intro:
              '<b>Hierarchy </b>– See hierarchy of appointments. This can be done by adding reference appointments,<br><b> Messages</b> – Messages related to this Appointment, <br><b>Transportation </b>– Transportation requests related to this Appointment,<br> <b>Reminders </b>– Reminders for this Appointment, ',
          },
          {
            element: '.delete',
            intro: 'Delete Facility Details',
          },
        ],
        displayFloatButton: this.displayHelp,
        showMapView: false,
        showLocationView: true,
      };
    } else {
      this.state = {
        id: facilityId,
        action: action,
        render: canRender,
        facilityName: '',
        description: '',
        facilityType: '',
        viewFacilityType: '',
        location: {},
        locationList: [],
        locationItems: [],
        title: title,
        pageId: pageId,
        locationItemsView: true,
        previousButton: previousButton,
        facilityAppointmentList: [],
        viewFacility: false,
        pageSize: 10,
        isPaging: false,
        facilityDetails: {},
        getAllPermission: false,
        getUpdatePermission: false,
        getDeletePermission: false,
        getReadPermission: false,
        getCreatePermission: false,
        isFreeUser: this.isFreeUser,
        addressLocations: [],
        stepsEnabled: false,
        initialStep: 0,
        steps: [
          {
            element: '.editDetails',
            intro: 'Edit/update Facility Details',
          },

          {
            element: '.addLocation',
            intro: 'Add multiple Location Details',
          },
          {
            element: '.deleteIcon',
            intro: 'Delete This Location',
          },
          {
            element: '.editLocation',
            intro: 'Update This Location',
          },
          {
            element: '.facility_Appointment_List',
            intro: 'List of all appointments with this Facility.',
          },
          {
            element: '.newAppointment',
            intro: 'Create new Appointment with this Facility',
          },
          {
            element: '.facilityAppointmentAction',
            intro:
              '<b>Hierarchy </b>– See hierarchy of appointments. This can be done by adding reference appointments,<br><b> Messages</b> – Messages related to this Appointment, <br><b>Transportation </b>– Transportation requests related to this Appointment,<br> <b>Reminders </b>– Reminders for this Appointment, ',
          },
          {
            element: '.delete',
            intro: 'Delete Facility Details',
          },
        ],
        displayFloatButton: this.displayHelp,
        showMapView: false,
        showLocationView: true,
      };
      this.addLocation('defaultCreate');
    }

    window.scrollTo(0, 0);
  }

  closeFloatingButton() {
    this.setState({ displayFloatButton: false });
    let displayFloat = false;

    store.dispatch(helpDataAction.setHelpData(displayFloat));
  }

  closeButton() {
    this.setState({
      showMapView: false,
      showLocationView: true,
    });
  }

  toggleSteps = () => {
    this.setState((prevState) => ({ stepsEnabled: !prevState.stepsEnabled }));
  };

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };
  handleFacilityNameChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ facilityName: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }
  handleFacilityTypeChange(e) {
    this.setState({ facilityType: e.target.value });
  }

  handleDescriptionChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 256);
    if (res.length < 256) {
      this.setState({ description: res });
    } else {
      Utils.maxFieldLength(256);
    }
  }

  async getFacilityDetails(facilityId) {
    const postObject = {
      header: this.header,
      facility: {
        id: facilityId,
      },
    };
    await FetchServerData.callPostService('/impFacilityms/get', postObject).then(async (output) => {
      if (output.status === 'SUCCESS') {
        let facilityData = output.data;
        // let facilityAppointmentList = output.data.facilityAppointmentList;

        let isPaging = false;
        let getPermission = Utils.getRolePermission(['*']);

        let getAllPermission = getPermission[0];
        let getCreatePermission = getPermission[1];
        let getReadPermission = getPermission[2];
        let getUpdatePermission = getPermission[3];
        let getDeletePermission = getPermission[4];
        // if (facilityAppointmentList.length > 10) {
        //   isPaging = true;
        // }

        this.setState({
          render: true,
          viewFacility: true,
          facilityDetails: facilityData,
          isPaging: isPaging,
          facilityId: facilityData.id,
          facilityName: facilityData.name,
          facilityType: facilityData.type,
          viewFacilityType: facilityData.type,
          description: facilityData.description,
          locationList: facilityData.facilityLocationList,
          // facilityAppointmentList: facilityAppointmentList,
          getAllPermission: getAllPermission,
          getUpdatePermission: getUpdatePermission,
          getDeletePermission: getDeletePermission,
          getReadPermission: getReadPermission,
          getCreatePermission: getCreatePermission,
        });
      } else {
      }
    });
  }

  deleteLocation(item) {
    let items = [];
    if (item.operation && 'update' === item.operation) {
      /* Existing Item Deleted, Mark it deleted */
      let index = item.index;

      for (let i = 0; i < this.state.locationList.length; i++) {
        let item = this.state.locationList[i];
        if (this.state.locationList[i].operation === 'update' && index === i) {
          item.operation = 'delete';
        }
        items.push(item);
      }
      this.setState({ locationList: items });
    }
    if (item.operation && 'add' === item.operation) {
      /* Added Item Deleted, Remove from list */
      let index = item.index;
      for (let i = 0; i < this.state.locationList.length; i++) {
        if (this.state.locationList[i].operation === 'add' && index === i) {
          this.locationReferenceList.splice(i, 1);
          // continue;
        } else {
          items.push(this.state.locationList[i]);
        }
      }
      this.setState({ locationList: items });
    }

    /*Go Back to Location list view if all new Locations are deleted */
    let flag = true;
    for (let i = 0; i < items.length; i++) {
      if (items[i].operation === 'add') {
        flag = false;
      }
    }
    if (flag) {
      this.backToLocationView();
    }
  }

  updateLocation(locationData) {
    for (let i = 0; i < this.state.locationList.length; i++) {
      if (
        this.state.locationList[i] &&
        locationData.index === i &&
        this.locationReferenceList[i] &&
        this.locationReferenceList[i].current &&
        this.locationReferenceList[i].current.validateLocation()
      ) {
        this.state.locationList[i] = locationData;
        this.locationReferenceList[i].current.state = locationData;
        this.locationReferenceList[i].current.state.action = 'editLocation';
        this.setState({ render: true });
      }
    }
  }

  handleLocationChanged(item) {}

  viewCancel() {
    this.props.history.push({
      pathname: '/import',
    });
  }

  backToLocationView() {
    this.setState({ locationItemsView: true, locationItemsCreateDiv: false });
  }

  facilityCancel() {
    this.setState({ facilityViewDiv: true, facilityEditDiv: false });
  }

  locationEditCancel() {
    this.setState({ locationItemsView: true, locationItemsCreateDiv: false });
  }

  addLocationData() {}

  editCancel(e, facilityId) {
    this.props.history.push({
      pathname: '/viewFacility',
      state: { action: 'view', title: 'Facility Details', facilityId: facilityId },
    });
  }

  cancelEditFacilityDetails() {
    this.setState({
      viewFacility: true,
      editFacility: false,
    });
  }

  addFacilityInBackend = (e) => {
    e.preventDefault();

    let locations = [];
    for (let i = 0; i < this.locationReferenceList.length; i++) {
      if (null !== this.locationReferenceList[i].current) {
        let location = {};
        location.id = this.locationReferenceList[i].current.state.id;
        location.shortDescription = this.locationReferenceList[i].current.state.shortDescription;
        location.nickName = this.locationReferenceList[i].current.state.nickName;
        location.fax = this.locationReferenceList[i].current.state.fax;
        location.website = this.locationReferenceList[i].current.state.website;
        location.phone = this.locationReferenceList[i].current.state.phone.replace(/[^\d]/g, '');
        location.address = {};
        location.address.id = this.locationReferenceList[i].current.state.address.id;
        location.address.line = this.locationReferenceList[i].current.state.address.line;
        location.address.city = this.locationReferenceList[i].current.state.address.city;
        location.address.zipCode = this.locationReferenceList[i].current.state.address.zipCode;
        location.address.state = this.locationReferenceList[i].current.state.address.state;
        locations[i] = location;
      }
    }

    const postObject = {
      header: this.header,
      facility: {
        name: this.state.facilityName,
        description: this.state.description,
        member: {
          id: this.memberDetails.member,
        },
        type: this.state.facilityType,
        facilityLocationList: locations,
      },
    };
    FetchServerData.callPostService('/facilityms/create', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let facilityDetails = output.data;

          PtsAlert.success(output.message);
          let fccPageState = {
            id: facilityDetails.facility.id,
            action: 'view',
            title: 'Facility Details',
          };

          if (this.state.pageId === 'addmemberFacility') {
            store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
            this.props.history.push({
              pathname: '/facilityAppointments',
              state: {
                action: 'createNewAppointment',
                title: 'Add Appointment',
                pageId: 'viewMemberFacilitys',
                facility: facilityDetails.facility,
              },
              // pathname: '/addAppointment',
              // state: {
              //   action: 'create',
              //   title: 'Add Appointment',
              //   pageId: 'addAppointment',
              //   appointmentId: '',
              // },
            });
          } else {
            store.dispatch(fccPageStateAction.setFccPageState(fccPageState));
            this.props.history.push({
              pathname: '/viewFacility',
              state: {
                action: 'view',
                title: 'Facility Details',
                facilityData: facilityDetails,
                pageId: 'createFacility',
              },
            });
          }
        } else {
        }
      })
      .catch((error) => {});
  };

  updateFacilityInBackend = (e) => {
    const postObject = {
      header: this.header,
      facility: {
        id: this.state.id,
        name: this.state.facilityName,
        description: this.state.description,
        member: {
          id: this.memberDetails.member,
        },
        type: this.state.facilityType,
      },
    };
    FetchServerData.callPostService('/impFacilityms/update', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let facilityData = output.data;
          PtsAlert.success(output.message);

          this.setState({
            render: true,
            viewFacility: true,
            editFacility: false,
            facilityId: facilityData.id,
            facilityName: facilityData.name,
            facilityType: facilityData.type,
            viewFacilityType: facilityData.type,
            description: facilityData.description,
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  addNewLocationInBackend(locationData) {
    let location = {};
    for (let i = 0; i < this.state.locationList.length; i++) {
      if (
        this.state.locationList[i] &&
        locationData.index === i &&
        this.locationReferenceList[i] &&
        this.locationReferenceList[i].current &&
        this.locationReferenceList[i].current.validateLocation()
      ) {
        location.operation = this.locationReferenceList[i].current.state.operation;
        location.shortDescription = this.locationReferenceList[i].current.state.shortDescription;
        location.nickName = this.locationReferenceList[i].current.state.nickName;
        location.fax = this.locationReferenceList[i].current.state.fax;
        location.website = this.locationReferenceList[i].current.state.website;
        location.phone = this.locationReferenceList[i].current.state.phone.replace(/[^\d]/g, '');
        location.address = {};
        location.address.id = this.locationReferenceList[i].current.state.address.id;
        location.address.line = this.locationReferenceList[i].current.state.address.line;
        location.address.city = this.locationReferenceList[i].current.state.address.city;
        location.address.zipCode = this.locationReferenceList[i].current.state.address.zipCode;
        location.address.state = this.locationReferenceList[i].current.state.address.state;
        location.facility = {};
        location.facility.id = this.state.id;
        location.facility.member = {};
        location.facility.member.id = this.memberDetails.member;
        const postObject = {
          header: this.header,

          facilityLocation: location,
        };

        FetchServerData.callPostService('facilityms/locationCreate', postObject).then((output) => {
          if (output.status === 'SUCCESS') {
            let facilityData = output.data;

            PtsAlert.success(output.message);
            this.state.locationList = [];
            this.locationReferenceList[i].current.state.action = 'editLocation';
            this.setState({
              locationList: output.data.facility.facilityLocationList,
            });
          } else {
          }
        });
      }
    }
  }

  updateLocationToBackend(locationData) {
    let location = {};
    for (let i = 0; i < this.state.locationList.length; i++) {
      if (
        this.state.locationList[i] &&
        locationData.index === i &&
        this.locationReferenceList[i] &&
        this.locationReferenceList[i].current &&
        this.locationReferenceList[i].current.validateLocation()
      ) {
        location.id = this.locationReferenceList[i].current.state.id;
        location.operation = this.locationReferenceList[i].current.state.operation;
        location.shortDescription = this.locationReferenceList[i].current.state.shortDescription;
        location.nickName = this.locationReferenceList[i].current.state.nickName;
        location.fax = this.locationReferenceList[i].current.state.fax;
        location.website = this.locationReferenceList[i].current.state.website;
        location.phone = this.locationReferenceList[i].current.state.phone.replace(/[^\d]/g, '');
        location.address = {};
        location.address.id = this.locationReferenceList[i].current.state.address.id;
        location.address.line = this.locationReferenceList[i].current.state.address.line;
        location.address.city = this.locationReferenceList[i].current.state.address.city;
        location.address.zipCode = this.locationReferenceList[i].current.state.address.zipCode;
        location.address.state = this.locationReferenceList[i].current.state.address.state;
        location.facility = {};
        location.facility.id = this.state.id;

        const postObject = {
          header: this.header,

          facilityLocation: location,
        };
        FetchServerData.callPostService('/impFacilityLocationms/update', postObject).then((output) => {
          if (output.status === 'SUCCESS') {
            let facilityData = output.data;

            let facilityLocation = [];
            facilityLocation.push(facilityData);
            PtsAlert.success(output.message);
            this.state.locationList = [];
            this.setState({
              locationList: facilityLocation,
            });
          } else {
          }
        });
      }
    }
  }

  deleteFacilityInBackend = () => {
    const postObject = {
      header: this.header,
      facility: {
        id: this.state.id,
      },
    };

    FetchServerData.callPostService('/impFacilityms/delete', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        PtsAlert.success(output.message);
        this.props.history.push({
          pathname: '/import',
          sweetAlert: false,
        });
      } else {
      }
    });
  };

  deleteLocationToBackend = (facilityLocation) => {
    const postObject = {
      header: this.header,
      facilityLocation: {
        id: facilityLocation.id,
        facility: { id: this.state.id },
      },
    };

    FetchServerData.callPostService('facilityms/locationDelete', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          for (let i = 0; i < this.state.locationList.length; i++) {
            if (this.state.locationList[i].id === facilityLocation.id) {
              this.state.locationList.splice(i, 1);
            }
          }
          this.setState({
            locationList: this.state.locationList,
            sweetAlert: false,
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  updateFacilityDetails() {
    this.setState({
      viewFacility: false,
      editFacility: true,
    });
  }

  render() {
    this.locationReferenceList = [];
    this.state.locationList.forEach((element, index) => {
      this.locationReferenceList[index] = React.createRef();
    });
    if (this.state.action === 'view') {
      return this.viewFacilityDetails();
    }
  }

  viewFacilityDetails() {
    if (this.state.render === false) {
      return <div />;
    } else {
      if (this.state.isFreeUser === true) {
        return this.viewFacilityDetailsWithAdvertisement();
      } else {
        return this.viewFacilityDetailsWithoutAdvertisement();
      }
    }
  }

  viewFacilityDetailsWithAdvertisement() {
    let locationItems = [];
    if (this.state.render === false) {
      return <div />;
    } else {
      this.state.locationList.forEach((element, index) => {
        if ('delete' !== element.operation) {
          locationItems.unshift(
            <Col md={12}>
              <Location
                ref={this.locationReferenceList[index]}
                key={index}
                data={element}
                srNo={index}
                getDeletePermission={this.state.getDeletePermission}
                getUpdatePermission={this.state.getUpdatePermission}
                pageId="editFacility"
                action={element.action ? element.action : 'editLocation'}
                onCancel={this.deleteLocation.bind(this)}
                onDelete={this.deleteLocationToBackend.bind(this)}
                onLocationAdd={this.addNewLocationInBackend.bind(this)}
                onUpdate={this.updateLocationToBackend.bind(this)}
                isImport="import"
              />
            </Col>
          );
        }
      });

      return (
        <>
          <MenuWithBreadScrum
            backCallback={this.viewCancel.bind(this)}
            deleteCallback={this.deleteFacilityInBackend.bind(this)}
            getDeletePermission={this.state.getDeletePermission}
            getUpdatePermission={this.state.getUpdatePermission}
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            name={this.title + ' For ' + this.memberName}
            domainObject="Facility"
          />
          <Steps
            enabled={this.state.stepsEnabled}
            steps={this.state.steps}
            initialStep={this.state.initialStep}
            onExit={this.onExit}
          />
          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={4} style={{ padding: '5px' }}>
              <Card style={{ height: '100%', borderRadius: '20px' }}>
                {this.state.viewFacility && (
                  <>
                    {' '}
                    <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                      <Row>
                        <Col md={10}>
                          {undefined !== this.state.title && this.state.title.length > 0 && (
                            <Label
                              style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                            >
                              Facility Details
                            </Label>
                          )}
                        </Col>
                        <Col md={2}>
                          {this.state.getUpdatePermission && (
                            <EditIconButton onClick={this.updateFacilityDetails.bind(this)} />
                          )}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      {this.state.facilityName && (
                        <Row>
                          <Col md={4}>
                            <label>Facility Name</label>
                          </Col>
                          <Col md={7}>
                            {' '}
                            <span>{this.state.facilityName}</span>
                          </Col>
                        </Row>
                      )}
                      {this.state.viewFacilityType && (
                        <Row>
                          <Col md={4}>
                            <label>Facility Type</label>
                          </Col>
                          <Col md={7}>
                            {' '}
                            <span>{this.state.viewFacilityType}</span>
                          </Col>
                        </Row>
                      )}
                      {this.state.description && (
                        <Row>
                          <Col md={4}>
                            <label>Description</label>
                          </Col>
                          <Col md={7}>
                            <span>
                              <pre className="view_facilityDesc"> {this.state.description}</pre>
                            </span>
                          </Col>
                        </Row>
                      )}
                    </CardBody>
                  </>
                )}

                {this.state.editFacility && (
                  <ValidatorForm
                    style={{ width: '100%' }}
                    ref="form"
                    onSubmit={this.updateFacilityInBackend.bind(this)}
                  >
                    <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                      <Row>
                        <Col md={9}>
                          <Label
                            style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                          >
                            Edit Facility Details
                          </Label>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row style={{ marginTop: '1em' }}>
                        <Col md={12}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            required
                            id="name"
                            label="Facility Name"
                            value={this.state.facilityName}
                            inputprops={{ maxLength: 64 }}
                            onChange={this.handleFacilityNameChange.bind(this)}
                            name="name"
                            type="text"
                            className="textField"
                          />
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '1em' }}>
                        <Col md={12}>
                          <input
                            className="pts_dataList_dropDown"
                            type="text"
                            required
                            // labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            list="data"
                            placeholder="Facility Type*"
                            label="Facility Type*"
                            value={this.state.facilityType}
                            onChange={(event, value) => {
                              this.handleFacilityTypeChange(event, value);
                            }}
                          />
                          <datalist id="data">
                            {this.facilityTypeList.map((event, key) => (
                              <option key={event.type} value={event.type} />
                            ))}
                          </datalist>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '0.5em' }}>
                        <Col md={12}>
                          <TextareaAutosize
                            aria-label="minimum height"
                            rowsMin={4}
                            id="description"
                            value={this.state.description}
                            inputprops={{ maxLength: 256, padding: '10px' }}
                            name="note"
                            onChange={this.handleDescriptionChange.bind(this)}
                            onPressEnter={this.handleDescriptionChange.bind(this)}
                            placeholder="Facility Description"
                            style={{ width: '100%', padding: '10px', border: '0.5px solid #D5D8DC' }}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter style={{ textAlign: 'center' }}>
                      <SaveButton type="submit" />
                      &nbsp;
                      <CancelIconButton
                        type="button"
                        onClick={(e) => this.cancelEditFacilityDetails(e, this.state.id)}
                      />
                    </CardFooter>
                  </ValidatorForm>
                )}
              </Card>
            </Col>
            <Col md={8} style={{ padding: '5px' }}>
              {this.state.showMapView && (
                <Row>
                  <Col md={12}>
                    <Card style={{ height: '100%', borderRadius: '20px' }}>
                      <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                        <Row>
                          <Col md={12}>
                            {undefined !== this.state.title && this.state.title.length > 0 && (
                              <Label
                                style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                              >
                                Locations
                              </Label>
                            )}
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Row md={12}>{locationItems}</Row>
                        <Row style={{ margin: '0em' }}>
                          <Col md={12}>
                            <Row md={12}>
                              <Col md={12} style={{ padding: '0em' }}>
                                {' '}
                                <span className="closeFloatingButton" aria-label="add">
                                  {' '}
                                  <CloseIcon
                                    onClick={this.closeButton.bind(this)}
                                    style={{ float: 'right', fontSize: '15px', cursor: 'pointer' }}
                                  />
                                </span>
                              </Col>
                            </Row>{' '}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>{' '}
                </Row>
              )}
              {this.state.showLocationView && (
                <Card style={{ height: '100%', borderRadius: '20px' }}>
                  <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                    <Row>
                      <Col md={12}>
                        {undefined !== this.state.title && this.state.title.length > 0 && (
                          <Label
                            style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                          >
                            Locations
                          </Label>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row md={12}>{locationItems}</Row>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>

          <div style={{ textAlign: 'center' }}>
            <BackButton
              type="button"
              style={{ backgroundColor: '#162c50', color: '#fff' }}
              variant="contained"
              startIcon={<CloseIcon />}
              onClick={(e) => this.viewCancel()}
            >
              Back
            </BackButton>
            {this.state.previousButton && (
              <Button
                type="submit"
                style={{ background: '#1b7189', color: '#fff', marginRight: '1rem' }}
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={(e) => this.toAppointmentDetails(e, this.appointmentId)}
              >
                {' '}
                Previous Appointment
              </Button>
            )}
          </div>
        </>
      );
    }
  }

  viewFacilityDetailsWithoutAdvertisement() {
    let locationItems = [];
    if (this.state.render === false) {
      return <div />;
    } else {
      this.state.locationList.forEach((element, index) => {
        if ('delete' !== element.operation) {
          locationItems.unshift(
            <Col md={12}>
              <Location
                ref={this.locationReferenceList[index]}
                key={index}
                data={element}
                srNo={index}
                getDeletePermission={this.state.getDeletePermission}
                getUpdatePermission={this.state.getUpdatePermission}
                pageId="editFacility"
                action={element.action ? element.action : 'editLocation'}
                onCancel={this.deleteLocation.bind(this)}
                onDelete={this.deleteLocationToBackend.bind(this)}
                onLocationAdd={this.addNewLocationInBackend.bind(this)}
                onUpdate={this.updateLocationToBackend.bind(this)}
                isImport="import"
              />
            </Col>
          );
        }
      });

      return (
        <>
          <MenuWithBreadScrum
            backCallback={this.viewCancel.bind(this)}
            deleteCallback={this.deleteFacilityInBackend.bind(this)}
            getDeletePermission={this.state.getDeletePermission}
            getUpdatePermission={this.state.getUpdatePermission}
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            name={this.title + ' For ' + this.memberName}
            domainObject="Facility"
          />
          <Steps
            enabled={this.state.stepsEnabled}
            steps={this.state.steps}
            initialStep={this.state.initialStep}
            onExit={this.onExit}
          />
          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={4} style={{ padding: '5px' }}>
              <Card style={{ height: '100%', borderRadius: '20px' }}>
                {this.state.viewFacility && (
                  <>
                    {' '}
                    <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                      <Row>
                        <Col md={10}>
                          {undefined !== this.state.title && this.state.title.length > 0 && (
                            <Label
                              style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                            >
                              Facility Details
                            </Label>
                          )}
                        </Col>
                        <Col md={2}>
                          {this.state.getUpdatePermission && (
                            <EditIconButton onClick={this.updateFacilityDetails.bind(this)} />
                          )}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      {this.state.facilityName && (
                        <Row>
                          <Col md={4}>
                            <label>Facility Name</label>
                          </Col>
                          <Col md={7}>
                            {' '}
                            <span>{this.state.facilityName}</span>
                          </Col>
                        </Row>
                      )}
                      {this.state.viewFacilityType && (
                        <Row>
                          <Col md={4}>
                            <label>Facility Type</label>
                          </Col>
                          <Col md={7}>
                            {' '}
                            <span>{this.state.viewFacilityType}</span>
                          </Col>
                        </Row>
                      )}
                      {this.state.description && (
                        <Row>
                          <Col md={4}>
                            <label>Description</label>
                          </Col>
                          <Col md={7}>
                            {' '}
                            <span>
                              <pre className="view_facilityDesc"> {this.state.description}</pre>
                            </span>
                          </Col>
                        </Row>
                      )}
                    </CardBody>
                    {/* </Card> */}
                  </>
                )}

                {this.state.editFacility && (
                  <ValidatorForm
                    style={{ width: '100%' }}
                    ref="form"
                    onSubmit={this.updateFacilityInBackend.bind(this)}
                  >
                    <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                      <Row>
                        <Col md={9}>
                          <Label
                            style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                          >
                            Edit Facility Details
                          </Label>
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row style={{ marginTop: '1em' }}>
                        <Col md={12}>
                          <TextField
                            fullWidth
                            variant="outlined"
                            required
                            id="name"
                            label="Facility Name"
                            value={this.state.facilityName}
                            inputprops={{ maxLength: 64 }}
                            onChange={this.handleFacilityNameChange.bind(this)}
                            name="name"
                            type="text"
                            className="textField"
                          />
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '1em' }}>
                        <Col md={12}>
                          <input
                            className="pts_dataList_dropDown"
                            type="text"
                            required
                            // labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            list="data"
                            placeholder="Facility Type*"
                            label="Facility Type*"
                            value={this.state.facilityType}
                            onChange={(event, value) => {
                              this.handleFacilityTypeChange(event, value);
                            }}
                          />
                          <datalist id="data">
                            {this.facilityTypeList.map((event, key) => (
                              <option key={event.type} value={event.type} />
                            ))}
                          </datalist>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: '1em' }}>
                        <Col md={12}>
                          <TextareaAutosize
                            aria-label="minimum height"
                            rowsMin={4}
                            id="description"
                            value={this.state.description}
                            inputprops={{ maxLength: 256, padding: '10px' }}
                            name="note"
                            onChange={this.handleDescriptionChange.bind(this)}
                            onPressEnter={this.handleDescriptionChange.bind(this)}
                            placeholder="Facility Description"
                            style={{ width: '100%', padding: '10px', border: '0.5px solid #D5D8DC' }}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter style={{ textAlign: 'center' }}>
                      <SaveButton type="submit" />
                      &nbsp;
                      <CancelIconButton
                        type="button"
                        onClick={(e) => this.cancelEditFacilityDetails(e, this.state.id)}
                      />
                    </CardFooter>
                  </ValidatorForm>
                )}
              </Card>
            </Col>
            <Col md={8} style={{ position: 'relative', padding: '5px' }}>
              {this.state.showMapView && (
                <Row>
                  <Col md={12}>
                    <Card style={{ height: '100%', borderRadius: '20px' }}>
                      <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                        <Row>
                          <Col md={12}>
                            {undefined !== this.state.title && this.state.title.length > 0 && (
                              <Label
                                style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                              >
                                Locations
                              </Label>
                            )}
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Row md={12}>{locationItems}</Row>
                        <Row style={{ margin: '0em' }}>
                          <Col md={12}>
                            <Row md={12}>
                              <Col md={12} style={{ padding: '0em' }}>
                                {' '}
                                <span className="closeFloatingButton" aria-label="add">
                                  {' '}
                                  <CloseIcon
                                    onClick={this.closeButton.bind(this)}
                                    style={{ float: 'right', fontSize: '15px', cursor: 'pointer' }}
                                  />
                                </span>
                              </Col>
                            </Row>{' '}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>{' '}
                </Row>
              )}
              {this.state.showLocationView && (
                <Card style={{ height: '100%', borderRadius: '20px' }}>
                  <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                    <Row>
                      <Col md={12}>
                        {undefined !== this.state.title && this.state.title.length > 0 && (
                          <Label
                            style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                          >
                            Locations
                          </Label>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row md={12}>{locationItems}</Row>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>

          <div style={{ textAlign: 'center' }}>
            <BackButton
              type="button"
              style={{ backgroundColor: '#162c50', color: '#fff' }}
              variant="contained"
              startIcon={<CloseIcon />}
              onClick={(e) => this.viewCancel()}
            >
              Back
            </BackButton>
            {this.state.previousButton && (
              <Button
                type="submit"
                style={{ background: '#1b7189', color: '#fff', marginRight: '1rem' }}
                variant="contained"
                startIcon={<SaveIcon />}
                onClick={(e) => this.toAppointmentDetails(e, this.appointmentId)}
              >
                {' '}
                Previous Appointment
              </Button>
            )}
          </div>
        </>
      );
    }
  }
}

export default FacilityImport;
