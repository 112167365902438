import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import moment from 'moment';
import React, { Component } from 'react';
import 'react-tabs/style/react-tabs.css';
import { Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import FetchServerData from '../../../../provider/FetchServerData';
import Utils from '../../../../provider/Utils';
import selectedPlanAction from '../../../../redux/selectedPlan/selectedPlanAction';
import store from '../../../../redux/store';
import MenuWithBreadScrum from '../../menu/MenuWithBreadScrum';
import PtsAlert from '../../ptsAlert/PtsAlert';
import './RenewPlan.scss';
class RenewPlan extends Component {
  title = 'Upgrade Plan';
  breadCrumb = [
    { pageid: '/profileHome', uiname: 'User Profile Details' },
    { pageid: '', uiname: 'Upgrade Plan' },
  ];
  userEmail = store.getState().userData.userData.email;
  header = store.getState().header.header;
  selectedPlanData = store.getState().userSubscribePlan.userSubscribePlan.selectedPlan;
  newSelectedPlanData = store.getState().selectedPlanData.selectedPlan.selectedPlanList;
  constructor(props) {
    super(props);

    this.state = {
      planList: [],
      planPriceType: '',
      planPrice: this.selectedPlanData.price,
      modal: false,
      planItemsList: [],
      freePlanItemsList: [],
      discount: this.selectedPlanData.discountPercent,
      userEmail: this.userEmail,
      cards: [],
      isToAnnualtBtn: false,
      showModal: false,
      cardNumber: '',
      expMonth: '',
      expYear: '',
      cvv: '',
      name: '',
      selectedPlan: this.selectedPlanData,
      newSelectedPlan: this.newSelectedPlanData,
      monthList: [
        // <option value=''>Month</option>
        { value: '01', month: 'January' },
        { value: '02', month: 'February' },
        { value: '03', month: 'March' },
        { value: '04', month: 'April' },
        { value: '05', month: 'May' },
        { value: '06', month: 'June' },
        { value: '07', month: 'July' },
        { value: '08', month: 'August' },
        { value: '09', month: 'September' },
        { value: '10', month: 'October' },
        { value: '11', month: 'November' },
        { value: '12', month: 'December' },
      ],
    };
    // this.getPlanesData();
  }
  componentDidMount() {
    this.getCardList();
  }

  getCardList() {
    let obj = {
      header: this.header,
      email: this.state.userEmail,
    };
    FetchServerData.callPostService('/cardms/getlist', obj)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let data = output.data;
          //   this.getPlanesData();
          if (data !== null) {
            this.setState({
              cards: data,
            });
          }
        }
      })
      .catch((error) => {});
  }

  getPlanesData = () => {
    FetchServerData.callPostService('planms/geAllPlans').then((output) => {
      if (output.status === 'SUCCESS') {
        let plansData = output.data;

        let monthlyPlanPrice = '';
        let monthlyDiscount = '';
        let planItemsList = [];
        let freePlanItemsList = [];
        let userSelectedPlan = {};
        let planType = '';
        for (let i = 0; i < plansData.length; i++) {
          plansData[0].monthlyPlanClass = 'plan-inner_free_plan_card_plan_card_bottom';
          plansData[0].monthlyPlanBtnClass = 'plan-inner_free_plan_button_plan_card_bottom';
          plansData[1].monthlyPlanClass = 'plan-inner_basic_plan_card_plan_card_bottom';
          plansData[1].monthlyPlanBtnClass = 'plan-inner_basic_plan_button_plan_card_bottom';

          if (this.state.selectedPlan.description === 'Basic Monthly') {
            plansData[i].planList.splice(0, 1);
            for (let j = 0; j < plansData[i].planList.length; j++) {
              monthlyPlanPrice = plansData[i].planList[0].price;
              monthlyDiscount = plansData[i].planList[0].discountPercent;
              planType = plansData[i].planList[0].id;
              planItemsList = plansData[i].planList[0].planItems;
              userSelectedPlan = {
                selectedPlanId: plansData[i].id,
                selectedPlanName: plansData[i].planName,
                selectedPlanList: plansData[i].planList[0],
              };
              store.dispatch(selectedPlanAction.setSelectedPlan(userSelectedPlan));
            }
          } else if (this.state.selectedPlan.description === 'Basic Quarterly') {
            plansData[i].planList.splice(0, 2);
            for (let j = 0; j < plansData[i].planList.length; j++) {
              monthlyPlanPrice = plansData[i].planList[0].price;
              monthlyDiscount = plansData[i].planList[0].discountPercent;
              planType = plansData[i].planList[0].id;
              planItemsList = plansData[i].planList[0].planItems;
              userSelectedPlan = {
                selectedPlanId: plansData[i].id,
                selectedPlanName: plansData[i].planName,
                selectedPlanList: plansData[i].planList[0],
              };
              store.dispatch(selectedPlanAction.setSelectedPlan(userSelectedPlan));
            }
          } else if (this.state.selectedPlan.description === 'Basic Half-Yearly') {
            plansData[i].planList.splice(0, 3);
            for (let j = 0; j < plansData[i].planList.length; j++) {
              monthlyPlanPrice = plansData[i].planList[0].price;
              monthlyDiscount = plansData[i].planList[0].discountPercent;
              planType = plansData[i].planList[0].id;
              planItemsList = plansData[i].planList[0].planItems;
              userSelectedPlan = {
                selectedPlanId: plansData[i].id,
                selectedPlanName: plansData[i].planName,
                selectedPlanList: plansData[i].planList[0],
              };
              store.dispatch(selectedPlanAction.setSelectedPlan(userSelectedPlan));
            }
          }
        }
        this.setState({
          newSelectedPlan: userSelectedPlan.selectedPlanList,
          planList: plansData,
          planPriceType: planType,
          planPrice: monthlyPlanPrice,
          planItemsList: planItemsList,
          freePlanItemsList: freePlanItemsList,
          discount: monthlyDiscount,
        });
      } else {
      }
    });
  };

  toBusinessPromotionForm = (planName, e) => {
    if (planName === 'FCC Subscription') {
      this.props.history.push({
        pathname: '/checkout',
      });
    } else {
      this.props.history.push({
        pathname: '/termsConditionsRegistration',
      });
    }
  };

  cancel() {
    this.props.history.push({
      pathname: '/profileHome',
    });
  }

  handlePlanPriceTypeChange(e, planId) {
    for (let i = 0; i < this.state.planList.length; i++) {
      for (let j = 0; j < this.state.planList[i].planList.length; j++) {
        if (parseInt(e.target.value) === this.state.planList[i].planList[j].id) {
          let userSelectedPlan = {
            selectedPlanId: planId,
            selectedPlanName: this.state.planList[i].planName,
            selectedPlanList: this.state.planList[i].planList[j],
          };
          store.dispatch(selectedPlanAction.setSelectedPlan(userSelectedPlan));
          this.setState({
            newSelectedPlan: userSelectedPlan.selectedPlanList,
            planPrice: this.state.planList[i].planList[j].price,
            discount: this.state.planList[i].planList[j].discountPercent,
            planPriceType: e.target.value,
            planItemsList: this.state.planList[i].planList[j].planItems,
          });
        }
      }
    }
    this.setState({
      planPriceType: e.target.value,
    });
  }
  addNewcard() {
    this.setState({
      showModal: true,
    });
  }

  toggle = () => {
    this.setState({ showModal: !this.state.showModal });
  };
  displayPlanType(planPriceList) {
    let optionTemplate = planPriceList.map((v) => <option value={v.id}>{v.plantype}</option>);

    return optionTemplate;
  }
  handleCardNumberChange(e) {
    let cardNumber = '';
    if (String(e.target.value).match(/\d{4}(?=\d{2,3})|\d+/g)) {
      cardNumber = String(e.target.value)
        .match(/\d{4}(?=\d{1,3})|\d+/g)
        .join('-');
      this.setState({ cardNumber: cardNumber });
    } else {
      cardNumber = '';
    }
    this.setState({ cardNumber: cardNumber });
  }

  handleExpiaryMonthChange(selectedMonth) {
    this.state.expMonth = selectedMonth;

    this.setState({
      expMonth: selectedMonth,
    });
    if (undefined !== this.state.onChange && null !== this.state.onChange) {
      this.state.onChange(this.state);
    }
  }
  handleYearMonthsChange(e) {
    this.setState({
      expYear: e.target.value,
    });
  }
  handleCvvChange(e) {
    this.setState({
      cvv: e.target.value,
    });
  }
  handleNameChange(e) {
    this.setState({
      name: e.target.value,
    });
  }
  handleEmailChange(e) {
    this.setState({
      email: e.target.value,
    });
  }

  cardHandleChange(card) {
    this.setState({
      isToAnnualtBtn: true,
      selectedCard: card,
    });
  }

  addCardToBackend() {
    let base64Cvc = Utils.toBase64(this.state.cvv);
    const postObject = {
      header: this.header,
      email: this.state.userEmail,
      card: {
        number: String(this.state.cardNumber).replace(/-/g, ''),
        exp_month: this.state.expMonth.value,
        exp_year: this.state.expYear,
        country: 'US',
        cvc: base64Cvc,
        name: this.state.name,
      },
    };
    FetchServerData.callPostService('/cardms/createCard', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          PtsAlert.success(output.message);
          this.getCardList();
          this.setState({
            showModal: false,
          });
        }
      })
      .catch((error) => {});
  }

  renewPlan() {
    const postObject = {
      header: this.header,
      email: this.state.userEmail,
      subscriptionId: '',
      paymentMethodId: this.state.selectedCard.paymentMethodId,
      plan: {
        id: this.state.newSelectedPlan.id,
        plantype: this.state.newSelectedPlan.plantype,
      },
    };

    FetchServerData.callPostService('/pg/renewSubscription', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          this.props.history.push({
            pathname: '/profileHome',
          });
        }
      })
      .catch((error) => {});
  }

  displayPlanCard() {
    let plan = [];
    let feature = [];
    let price = [];
    plan.push(
      <>
        {this.state.selectedPlan.name === 'FCC Subscription' ? (
          <Col md={3} style={{ margin: 'auto' }}>
            <div className="plan-inner_free_plan_card_plan_card_bottom" data-aos="zoom-in" data-aos-delay={300}>
              <div className="plan-inner">
                <div className="entry-title">
                  <h3 style={{ padding: '7px' }}> {this.state.selectedPlan.name}</h3>
                  <div className="price">
                    {this.state.selectedPlan.name === 'FCC Subscription' ? (
                      <>
                        <span style={{ left: '12px' }}>
                          {this.state.planPrice === 19.1
                            ? '$' + this.state.planPrice + '0'
                            : '$' + this.state.planPrice}
                        </span>
                      </>
                    ) : (
                      <span style={{ left: '12px' }}> {'$' + '0.00'}</span>
                    )}
                  </div>
                </div>
                <div className="entry-content">
                  <ul style={{ height: '350px' }}>
                    {this.state.selectedPlan.name === 'FCC Subscription' ? (
                      <>
                        <li>
                          {' '}
                          <b>
                            {this.state.discount === '0.0' ? '' : 'Discount : ' + Math.round(this.state.discount) + '%'}
                          </b>
                        </li>
                      </>
                    ) : (
                      ''
                    )}
                    {feature}
                    <li style={{ padding: '2em' }}>
                      <h4>
                        <b>{this.state.selectedPlan.plantype}</b>
                      </h4>
                    </li>
                  </ul>
                </div>
                <Row>
                  <Col md={12} style={{ textAlign: 'center' }}>
                    <div></div>
                  </Col>
                </Row>
              </div>
            </div>
          </Col>
        ) : (
          ''
        )}
      </>
    );
    for (let j = 0; j < this.state.selectedPlan.planItems.length; j++) {
      feature.push(<li>{this.state.selectedPlan.planItems[j].name}</li>);
    }

    return plan;
  }
  render() {
    if (this.state.render === false) {
      return <></>;
    } else {
      let userCards = this.state.cards.map((card, i) => {
        return (
          <>
            <Row>
              <Col md={2} style={{ margin: 'auto' }}>
                <span>
                  <input
                    type="radio"
                    name="card"
                    style={{ height: '25px', width: '25px' }}
                    value={card.paymentMethodId}
                    onChange={this.cardHandleChange.bind(this, card)}
                  ></input>
                </span>
              </Col>
              <Col md={10}>
                <div className="CI-Card">
                  <div className="card">
                    <div
                      className="card__front card__part"
                      style={
                        i % 2
                          ? {
                              background:
                                'linear-gradient(to right bottom, #fd8369, #fc7870, #f96e78, #f56581, #ee5d8a)',
                            }
                          : { background: '#2980B9' }
                      }
                    >
                      <div className="chips svg"></div>
                      <div className="paywave svg"></div>
                      <p className="card_numer">
                        <span> XXXX XXXX XXXX {card.number}</span>
                      </p>
                      <div className="card__space-75">
                        <span className="card__label">Card holder</span>
                        <p className="card__info"> {card.name ? card.name : 'XXXX XXXX'}</p>
                      </div>
                      <div className="card__space-25">
                        <span className="card__label">Expires</span>
                        <p className="card__info">
                          {card.exp_month + '/' + moment(card.exp_year, 'yyyy').format('YY')}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <br></br>
          </>
        );
      });
      return (
        <div>
          <MenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            name={this.title}
            domainObject="Upgrade Plan"
            headerWidth="home"
          />
          <Row
            style={{
              borderBottom: '1px solid #ABB2B9',
              marginTop: '2em',
            }}
          >
            <Col md={12}>
              <Button
                style={{
                  background: '#ef6603',
                  color: '#fff',
                  borderBottom: '3px solid #636262',

                  float: 'left',
                  webkitBorderRadius: '10px',
                  fontSize: '0.8em',
                  float: 'left',
                  margin: 'auto 5px 0.5em 3em',
                  marginRight: '5px',
                }}
                onClick={(e) => this.cancel()}
              >
                Cancel
              </Button>
              <Button
                style={{
                  float: 'right',
                  webkitBorderRadius: '10px',
                  fontSize: '0.8em',
                  float: 'right',
                  margin: 'auto 3em 0.5em auto',
                  background: '#ef6603',
                  color: '#fff',
                  borderBottom: '3px solid #636262',
                }}
                onClick={(e) => this.addNewcard()}
              >
                Add New Card
              </Button>
            </Col>
          </Row>
          <Row>
            <Col md={12} className="Cmp_reports"></Col>
          </Row>

          <Row style={{ padding: '1em', color: '#fff' }}>
            <Col md={6}>{this.displayPlanCard()}</Col>
            <Col md={6}>
              {userCards}
              <br></br>
              <div style={{ textAlign: 'center' }}>
                {this.state.isToAnnualtBtn === true && (
                  <Button
                    style={{
                      webkitBorderRadius: '10px',
                      fontSize: '0.8em',

                      margin: 'auto 5px 0.5em auto',
                      marginRight: '5px',
                      background: '#ef6603',
                      color: '#fff',
                      borderBottom: '3px solid #636262',
                    }}
                    onClick={this.renewPlan.bind(this)}
                  >
                    Pay With Card
                  </Button>
                )}
              </div>
            </Col>
          </Row>

          <Modal isOpen={this.state.showModal} toggle={this.toggle} size="md" className="modalContain">
            <ModalHeader toggle={this.toggle}>Add New Card</ModalHeader>
            <ModalBody>
              <form action="#">
                <div className="" style={{ marginLeft: '0px !important' }}>
                  <div className="row pull-center">
                    <div className="col-md-12">
                      <div className="row card"></div>
                      <br />
                      <Row>
                        <Col md={12}>
                          <TextField
                            name="Credit Card Number"
                            variant="outlined"
                            value={this.state.cardNumber}
                            onChange={this.handleCardNumberChange.bind(this)}
                            inputProps={{
                              style: { textTransform: 'capitalize' },
                            }}
                            required
                            fullWidth
                            id="name"
                            label="Credit Card Number"
                            autoComplete="off"
                          />
                        </Col>
                      </Row>
                      <br></br>
                      <Row>
                        <Col md={6}>
                          <Autocomplete
                            id="state"
                            options={this.state.monthList}
                            getOptionLabel={(option) => option.month}
                            // defaultValue={this.state.expMonth}
                            value={this.state.expMonth}
                            onChange={(event, value) => {
                              this.handleExpiaryMonthChange(value);
                            }}
                            inputProps={{
                              style: {
                                textTransform: 'capitalize',
                              },
                            }}
                            renderInput={(params) => <TextField {...params} label="Month" variant="outlined" />}
                          />
                        </Col>

                        <Col md={6}>
                          <TextField
                            name="Expiary Year"
                            variant="outlined"
                            required
                            value={this.state.expYear}
                            onChange={this.handleYearMonthsChange.bind(this)}
                            inputProps={{
                              style: { textTransform: 'capitalize' },
                            }}
                            fullWidth
                            id="name"
                            label="Expiration Year"
                            autoComplete="off"
                          />
                        </Col>
                      </Row>
                      <br></br>
                      <Row>
                        <Col md={6}>
                          <TextField
                            name="CVV"
                            variant="outlined"
                            required
                            value={this.state.cvv}
                            onChange={this.handleCvvChange.bind(this)}
                            inputProps={{
                              style: { textTransform: 'capitalize' },
                            }}
                            fullWidth
                            id="name"
                            label="CVC"
                            autoComplete="off"
                          />
                        </Col>
                      </Row>
                      <br></br>
                      <Row>
                        <Col md={12}>
                          <TextField
                            name="Name"
                            variant="outlined"
                            value={this.state.name}
                            onChange={this.handleNameChange.bind(this)}
                            inputProps={{
                              style: { textTransform: 'capitalize' },
                            }}
                            required
                            fullWidth
                            id="name"
                            label="Name"
                            autoComplete="off"
                          />
                        </Col>
                      </Row>
                      <br></br>

                      <div className="row ">
                        <div className="col-md-12 text-right">
                          <Button
                            style={{
                              background: '#f58d51',

                              webkitBorderRadius: '10px',

                              margin: 'auto',
                              marginRight: '5px',
                              borderBottom: '2px solid #636262',
                            }}
                            onClick={(e) => this.addCardToBackend()}
                          >
                            Add
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </ModalBody>
          </Modal>
        </div>
      );
    }
  }
}
export default RenewPlan;
