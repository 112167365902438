import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import UiUtils from '../../../provider/UiUtils';
import Utils from '../../../provider/Utils';
import adminFccPageStateAction from '../../../redux/adminPanel/adminFccPageState/adminFccPageStateAction';
import applicationStateAction from '../../../redux/applicationState/applicationStateAction';
import store from '../../../redux/store';
import AddIconButton from '../../components/buttons/AddIconButton';
import AdminMenuWithBreadScrum from '../adminMenu/AdminMenuWithBreadScrum';
import MaterialTable from '@material-table/core';
import AppUserProfileAction from '../../../redux/adminPanel/adminProfile/UserProfileAction';
export class AdminUserList extends Component {
  title = 'Admin User List';
  breadCrumb = [{ pageid: '', uiname: 'Admin User List' }];
  header = store.getState().adminHeader.adminHeader;
  adminUserState = store.getState().applicationState.adminUserState;
  adminUserStateDetails = {};
  constructor(props) {
    super(props);
    let action = props.action;
    let pageId = '';
    if (
      null !== props.history &&
      props.history !== undefined &&
      props.history.location !== null &&
      props.history.location.state !== null &&
      props.history.location.state !== undefined
    ) {
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
    }
    store.dispatch(adminFccPageStateAction.setAdminFccPageState({}));
    let adminUserStateDetails = {};
    if (this.adminUserState) {
      adminUserStateDetails = Object.getOwnPropertyNames(this.adminUserState);
    }
    if (adminUserStateDetails.length > 0) {
      this.state = {
        email: '',
        password: '',
        showPassword: false,
        action: action,
        pageId: pageId,
        isPaging: false,
        initialPage:
          this.adminUserState.initialPage && this.adminUserState.initialPage > 0 ? this.adminUserState.initialPage : 0,
        adminUserList: [],
        UserProfileList: [],
        pageSize: this.adminUserState.pageSize && this.adminUserState.pageSize ? this.adminUserState.pageSize : 10,
        userId: this.adminUserState.userId,
        render: false,
        getCreatePermission: false,
      };
    } else {
      this.state = {
        email: '',
        password: '',
        showPassword: false,
        action: action,
        pageId: pageId,
        isPaging: false,
        initialPage: 0,
        adminUserList: [],
        UserProfileList: [],
        pageSize: 10,
        render: false,
        getCreatePermission: false,
      };
    }
    this.getAdminUserList();
    this.getAdminProfileList();
  }
  getAdminProfileList() {
    const postObject = {
      header: this.header,
    };

    FetchServerData.callPostService('/apProfilems/getList', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let UserProfileList = output.data.profileList;
        store.dispatch(AppUserProfileAction.setUserProfile(UserProfileList));
        this.setState({
          render: true,
          UserProfileList: UserProfileList,
        });
      } else {
      }
    });
  }

  getAdminUserList() {
    const postObject = {
      header: this.header,
    };

    FetchServerData.callPostService('/adminpanelAppuserms/getList', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let adminUserList = output.data.appusers;
        let getPermission = Utils.getRolePermission(output.data.privilegeList);
        let getCreatePermission = getPermission[1];
        let isPaging = false;
        if (adminUserList.length > 10) {
          isPaging = true;
        }
        this.setState({
          render: true,
          isPaging: isPaging,
          pageSize: this.state.pageSize,
          initialPage: this.state.initialPage,
          adminUserList: adminUserList,
          getCreatePermission: getCreatePermission,
        });
      } else {
      }
    });
  }

  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }

  addAdminUser(e) {
    let adminFccPageState = {
      id: '',
      action: 'create',
      title: 'Add Admin User',
      pageId: 'adminUserList',
    };
    store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
    this.props.history.push({
      pathname: '/addAdminUser',
      state: { action: 'create', title: 'Add Admin User', userId: '', pageId: 'adminUserList' },
    });
  }

  toAdminUserDetails(e, data) {
    let adminUserState = {
      userId: data.id,
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
    };
    store.dispatch(applicationStateAction.setApplicationState({ adminUserState }));
    let adminFccPageState = {
      id: data.id,
      action: 'view',
      title: 'Admin User Details',
      pageId: 'adminUserList',
      adminUserName: data.name,
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
    };
    store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
    this.props.history.push({
      pathname: '/viewAdminUser',
      state: { action: 'view', title: 'Admin User Details', userId: data.id, pageId: 'adminUserList' },
    });
  }
  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <AdminMenuWithBreadScrum breadCrumb={this.breadCrumb} history={this.props.history} name={this.title} />
          <Row style={{ position: 'relative', margin: '1em 0em', maxWidth: '100%' }}>
            <Col md={12}>
              <MaterialTable
                onRowClick={(event, rowData) => {
                  this.toAdminUserDetails(event, rowData);
                  event.stopPropagation();
                }}
                icons={UiUtils.getMaterialTableIcons()}
                title=""
                localization={{
                  body: {
                    emptyDataSourceMessage: Utils.communizeTableText(),
                  },
                }}
                columns={[
                  {
                    title: '#',
                    render: (rowData) => rowData.tableData.index + 1,
                    width: '5%',
                    align: 'left',
                    cellStyle: {
                      width: '6%',
                    },
                  },
                  {
                    title: 'Name',
                    field: 'name',
                    cellStyle: {
                      width: '18%',
                    },
                  },
                  {
                    title: 'Email',
                    field: 'email',
                    cellStyle: { textTransform: 'none', width: '15%' },
                  },

                  {
                    title: 'Phone',
                    render: (rowData) => Utils.displayUsaPhoneNumberFormate(rowData.phone),
                    field: 'phone',
                    cellStyle: {
                      width: '12%',
                    },
                  },
                  {
                    title: 'Profile',
                    field: 'profilename',

                    cellStyle: {
                      width: '5%',
                    },
                  },

                  {
                    title: 'Status',
                    field: 'status',
                    align: 'left',
                    cellStyle: {
                      width: '5%',
                    },
                  },
                ]}
                data={this.state.adminUserList}
                onPageChange={(page, pageSize) => {
                  this.handleMaterialTablePageChange(page, pageSize);
                }}
                options={{
                  sorting: true,
                  actionsColumnIndex: -1,
                  pageSize: this.state.pageSize,
                  pageSizeOptions: [5, 10, 20, 50, 100],
                  paging: this.state.adminUserList.length > this.state.pageSize,
                  initialPage: this.state.initialPage,
                  headerStyle: {
                    backgroundColor: '#066f72',
                    fontWeight: 'bold',
                    color: '#fff',
                    align: 'left',
                  },

                  filtering: false,
                  // paging: this.state.isPaging,
                  rowStyle: (rowData) => ({
                    backgroundColor: rowData.id === this.state.userId ? '#FEF5E7' : '#fff',
                    height: '3em',
                    textTransform: 'capitalize',
                  }),
                  tableLayout: 'auto',
                }}
                actions={[
                  {
                    icon: () => (
                      <span className="newDoctor">
                        {' '}
                        {this.state.getCreatePermission && <AddIconButton btnName="Add Admin User" />}
                      </span>
                    ),
                    tooltip: 'Add Sponsor',
                    isFreeAction: true,
                    onClick: (event) => this.addAdminUser(),
                  },
                ]}
              />
            </Col>
          </Row>
        </>
      );
    }
  }
}
export default AdminUserList;
