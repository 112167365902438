import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import EmailIcon from '@material-ui/icons/Email';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import LanguageIcon from '@material-ui/icons/Language';
import PersonIcon from '@material-ui/icons/Person';
import PhoneIcon from '@material-ui/icons/Phone';
import 'intro.js/introjs.css';
import React, { Component } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { ValidatorForm } from 'react-material-ui-form-validator';
import 'react-tabs/style/react-tabs.css';
import { Card, CardBody, CardFooter, CardHeader, Col, Label, Row } from 'reactstrap';
import doctor from '../../../../src/assets/images/doctor.png';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import helpDataAction from '../../../redux/help/displayHelpAction';
import store from '../../../redux/store';
import BackButton from '../buttons/BackButton';
import CancelIconButton from '../buttons/CancelIconButton';
import EditIconButton from '../buttons/EditIconButton';
import SaveButton from '../buttons/SaveButton';
import Location from '../location/Location';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
import PtsAlert from '../ptsAlert/PtsAlert';

export class DoctorImport extends Component {
  title = 'Doctor Details';
  memberDetails = store.getState().memberData.memberData;
  memberName = store.getState().memberData.memberData.firstname + ' ' + store.getState().memberData.memberData.lastname;
  specializationList = store.getState().staticData.specializationList;
  isFreeUser = store.getState().userData.userData.isFreeUser;
  displayHelp = store.getState().help.help;
  header = store.getState().header.header;
  fccPageState = store.getState().fccPageState.fccPageState;
  memberHomeUrl = store.getState().memberHomeUrl.memberHomeUrl.homeUrl;
  breadCrumb = [
    { pageid: this.memberHomeUrl, uiname: this.memberName },
    { pageid: '/doctorAppointment', uiname: 'Doctor List' },
    { pageid: '', uiname: 'Doctor Details' },
  ];

  locationReferenceList = [];
  specializationOptionList = [];
  salutationList = [
    { salutation: 'Dr', id: '1' },
    { salutation: 'Miss', id: '2' },
  ];

  constructor(props) {
    super(props);

    let doctorId = props.doctorId;
    let action = props.action;
    let title = '';
    let pageId = '';
    let doctorDetails = {};
    if (!doctorId && props.history && props.history.location && props.history.location.state) {
      doctorId = props.history.location.state.doctorId;
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
      title = props.history.location.state.title;
      doctorDetails = props.history.location.state.doctorDetails;
    }
    let canRender = false;
    if (null !== doctorId && doctorId > 0) {
      this.getDoctorDetails(doctorId);
    } else {
      canRender = true;
    }

    if (doctorId === undefined) {
      doctorId = this.fccPageState.id;
      action = this.fccPageState.action;
      title = 'Doctor Details';
      if (action !== 'create') {
        this.getDoctorDetails(doctorId);
      }
      // canRender = true;
    }

    if (action === 'view' || action === 'edit') {
      this.state = {
        id: doctorId,
        action: action,
        render: canRender,
        salutation: this.salutationList[0].salutation,
        fname: '',
        mname: '',
        lname: '',
        email: '',
        website: '',
        websiteuser: '',
        phone: '',
        description: '',
        title: title,
        pageId: pageId,
        onChange: this.props.onChange,
        address: {},
        location: {},
        locationItems: [],
        specialization: '',
        viewSelectedSpecialization: '',
        locationList: [],
        locationItemsView: true,
        locationItemsCreateDiv: false,
        pageSize: 10,
        isPaging: false,
        doctorAppointments: [],
        getAllPermission: false,
        getUpdatePermission: false,
        getDeletePermission: false,
        getReadPermission: false,
        getCreatePermission: false,
        isFreeUser: this.isFreeUser,
        stepsEnabled: false,
        initialStep: 0,
        addressLocations: [],

        displayFloatButton: this.displayHelp,
        showMapView: false,
        showLocationView: true,
      };
    } else {
      this.state = {
        id: doctorId,
        action: action,
        render: canRender,
        salutation: this.salutationList[0].salutation,
        fname: '',
        mname: '',
        lname: '',
        email: '',
        website: '',
        websiteuser: '',
        phone: '',
        description: '',
        title: title,
        pageId: pageId,
        onChange: this.props.onChange,
        address: {},
        location: {},
        locationItems: [],
        specialization: '',
        viewSelectedSpecialization: '',
        locationList: [],
        locationItemsView: true,
        locationItemsCreateDiv: false,
        pageSize: 10,
        isPaging: false,
        doctorAppointments: [],
        getAllPermission: false,
        getUpdatePermission: false,
        getDeletePermission: false,
        getReadPermission: false,
        getCreatePermission: false,
        isFreeUser: this.isFreeUser,
        stepsEnabled: false,
        initialStep: 0,
        addressLocations: [],

        displayFloatButton: this.displayHelp,
        showMapView: false,
        showLocationView: true,
      };
    }
  }

  closeFloatingButton() {
    this.setState({ displayFloatButton: false });
    let displayFloat = false;

    store.dispatch(helpDataAction.setHelpData(displayFloat));
  }

  toggleSteps = () => {
    this.setState((prevState) => ({ stepsEnabled: !prevState.stepsEnabled }));
  };

  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };

  // async componentDidMount() {
  //   await this.getDoctorDetails(this.state.id);
  // }
  async getDoctorDetails(doctorId) {
    let id = doctorId;
    const postObject = {
      header: this.header,
      doctor: {
        id: id,
      },
    };

    await FetchServerData.callPostService('/impDoctorms/get', postObject)
      .then(async (output) => {
        if (output.status === 'SUCCESS') {
          let doctorDetails = output.data;
          let doctorSpecialist = doctorDetails.specialist;
          // let doctorsAppointment = output.data.doctorAppointmentList;
          let getPermission = Utils.getRolePermission(['*']);

          let getAllPermission = getPermission[0];
          let getCreatePermission = getPermission[1];
          let getReadPermission = getPermission[2];
          let getUpdatePermission = getPermission[3];
          let getDeletePermission = getPermission[4];
          let isPaging = false;
          // if (doctorsAppointment.length > 10) {
          //   isPaging = true;
          // }

          this.setState({
            render: true,
            viewDoctor: true,
            isPaging: isPaging,
            doctorDetails: doctorDetails,
            fname: doctorDetails.firstName,
            lname: doctorDetails.lastName,
            email: doctorDetails.email,
            website: doctorDetails.website,
            websiteuser: doctorDetails.websiteuser,
            phone: Utils.displayUsaPhoneNumberFormate(doctorDetails.phone),
            // phone: doctorDetails.phone,
            salutation: doctorDetails.salutation,
            viewSelectedSpecialization: doctorSpecialist,
            specialization: doctorSpecialist,
            locationList: doctorDetails.doctorLocationList,
            // doctorAppointments: doctorsAppointment,
            getAllPermission: getAllPermission,
            getUpdatePermission: getUpdatePermission,
            getDeletePermission: getDeletePermission,
            getReadPermission: getReadPermission,
            getCreatePermission: getCreatePermission,
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  handleFnameChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 128);
    if (res.length < 128) {
      this.setState({ fname: res });
    } else {
      Utils.maxFieldLength(128);
    }
  }

  handleFnameOnBlur(e) {
    this.setState({ fname: e.target.value });
  }

  handleLnameChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 128);
    if (res.length < 128) {
      this.setState({ lname: res });
    } else {
      Utils.maxFieldLength(128);
    }
  }

  handleEmailChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ email: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }

  handleWebsiteChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 4000);
    if (res.length < 4000) {
      this.setState({ website: res });
    } else {
      Utils.maxFieldLength(4000);
    }
  }

  handleWebsiteuserChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 110);
    if (res.length < 110) {
      this.setState({ websiteuser: res });
    } else {
      Utils.maxFieldLength(110);
    }
  }

  closeButton() {
    this.setState({
      showMapView: false,
      showLocationView: true,
    });
  }

  handlePhoneChange({ target: { value } }) {
    this.setState((prevState) => ({
      phone: Utils.convertPhoneNumberToUsaPhoneNumberFormate(value, prevState.phone),
    }));
  }
  handleSpecializationChange(e) {
    this.setState({ specialization: e.target.value });
  }

  handleSalutationChange(e) {
    this.setState({ salutation: e.target.value });
  }

  backToLocationView() {
    this.setState({ locationItemsView: true, locationItemsCreateDiv: false });
  }

  /*  Delete location from doctor  */
  deleteLocation(item) {
    let items = [];
    if (item.operation && 'update' === item.operation) {
      /* Existing Item Deleted, Mark it deleted */
      let index = item.index;

      for (let i = 0; i < this.state.locationList.length; i++) {
        let item = this.state.locationList[i];
        if (this.state.locationList[i].operation === 'update' && index === i) {
          item.operation = 'delete';
        }
        items.push(item);
      }
      this.setState({ locationList: items });
    }
    if (item.operation && 'add' === item.operation) {
      /* Added Item Deleted, Remove from list */
      let index = item.index;
      for (let i = 0; i < this.state.locationList.length; i++) {
        if (this.state.locationList[i].operation === 'add' && index === i) {
          this.locationReferenceList.splice(i, 1);
          // continue;
        } else {
          items.push(this.state.locationList[i]);
        }
      }
      this.setState({ locationList: items });
    }

    /*Go Back to Location list view if all new Locations are deleted */
    let flag = true;
    for (let i = 0; i < items.length; i++) {
      if (items[i].operation === 'add') {
        flag = false;
      }
    }
    if (flag) {
      this.backToLocationView();
    }
  }

  handleLocationChanged(item) {}

  /*  update  doctor locations  */
  updateLocation(locationData) {
    for (let i = 0; i < this.state.locationList.length; i++) {
      if (
        this.state.locationList[i] &&
        locationData.index === i &&
        this.locationReferenceList[i] &&
        this.locationReferenceList[i].current &&
        this.locationReferenceList[i].current.validateLocation()
      ) {
        this.state.locationList[i] = locationData;
        this.locationReferenceList[i].current.state = locationData;
        this.locationReferenceList[i].current.state.action = 'editLocation';
        this.setState({ render: true });
      }
    }
  }

  /*  Add  doctor locations in backend  */
  addNewLocationInBackend(locationData) {
    // e.preventDefault();

    let location = {};
    for (let i = 0; i < this.state.locationList.length; i++) {
      if (
        this.state.locationList[i] &&
        locationData.index === i &&
        this.locationReferenceList[i] &&
        this.locationReferenceList[i].current &&
        this.locationReferenceList[i].current.validateLocation()
      ) {
        location.operation = this.locationReferenceList[i].current.state.operation;
        location.shortDescription = this.locationReferenceList[i].current.state.shortDescription;
        location.nickName = this.locationReferenceList[i].current.state.nickName;
        location.fax = this.locationReferenceList[i].current.state.fax;
        location.website = this.locationReferenceList[i].current.state.website;
        location.phone = this.locationReferenceList[i].current.state.phone.replace(/[^\d]/g, '');
        location.address = {};
        location.address.id = this.locationReferenceList[i].current.state.address.id;
        location.address.line = this.locationReferenceList[i].current.state.address.line;
        location.address.city = this.locationReferenceList[i].current.state.address.city;
        location.address.zipCode = this.locationReferenceList[i].current.state.address.zipCode;
        location.address.state = this.locationReferenceList[i].current.state.address.state;
        location.doctor = {};
        location.doctor.id = this.state.id;
        location.doctor.member = {};
        location.doctor.member.id = this.memberDetails.member;
        const postObject = {
          header: this.header,

          doctorLocation: location,
        };
        FetchServerData.callPostService('doctorms/locationCreate', postObject).then((output) => {
          if (output.status === 'SUCCESS') {
            let doctorDetails = output.data;
            PtsAlert.success(output.message);
            this.state.locationList = [];
            this.locationReferenceList[i].current.state.action = 'editLocation';
            this.setState({
              locationList: output.data.doctor.doctorLocationList,
            });
          } else {
          }
        });
      }
    }
  }

  /*  Update doctor details  in backend  */
  updateDoctorToBackend(e) {
    e.preventDefault();
    let mobileNumber = this.state.phone.replace(/[^\d]/g, '');
    const postObject = {
      header: this.header,
      doctor: {
        member: {
          id: this.memberDetails.member,
        },
        id: this.state.id,
        firstName: this.state.fname,
        lastName: this.state.lname,
        email: this.state.email,
        website: this.state.website,
        websiteuser: this.state.websiteuser,
        phone: mobileNumber,
        salutation: this.state.salutation,

        specialist: this.state.specialization,
      },
    };
    FetchServerData.callPostService('/impDoctorms/update', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let doctorDetails = output.data.doctor;
        PtsAlert.success(output.message);
        let doctorSpecialist = doctorDetails.specialist;
        let doctorsAppointment = output.data.appointmentList;

        this.setState({
          render: true,
          viewDoctor: true,
          editDoctor: false,

          fname: doctorDetails.firstName,
          lname: doctorDetails.lastName,
          email: doctorDetails.email,
          website: this.state.website,
          websiteuser: this.state.websiteuser,
          phone: doctorDetails.phone,
          salutation: doctorDetails.salutation,
          viewSelectedSpecialization: doctorSpecialist,
          specialization: doctorSpecialist,
          locationList: doctorDetails.doctorLocationList,
          doctorAppointments: doctorsAppointment,
        });
      } else {
      }
    });
  }

  /*  Update doctor location  in backend  */
  updateLocationToBackend(locationData) {
    let location = {};
    for (let i = 0; i < this.state.locationList.length; i++) {
      if (
        this.state.locationList[i] &&
        locationData.index === i &&
        this.locationReferenceList[i] &&
        this.locationReferenceList[i].current &&
        this.locationReferenceList[i].current.validateLocation()
      ) {
        location.id = this.locationReferenceList[i].current.state.id;
        location.operation = this.locationReferenceList[i].current.state.operation;
        location.shortDescription = this.locationReferenceList[i].current.state.shortDescription;
        location.nickName = this.locationReferenceList[i].current.state.nickName;
        location.fax = this.locationReferenceList[i].current.state.fax;
        location.website = this.locationReferenceList[i].current.state.website;
        location.phone = this.locationReferenceList[i].current.state.phone.replace(/[^\d]/g, '');
        location.address = {};
        location.address.id = this.locationReferenceList[i].current.state.address.id;
        location.address.line = this.locationReferenceList[i].current.state.address.line;
        location.address.city = this.locationReferenceList[i].current.state.address.city;
        location.address.zipCode = this.locationReferenceList[i].current.state.address.zipCode;
        location.address.state = this.locationReferenceList[i].current.state.address.state;
        location.doctor = {};
        location.doctor.id = this.state.id;

        const postObject = {
          header: this.header,

          doctorLocation: location,
        };
        FetchServerData.callPostService('/impDoctorLocationms/update', postObject).then((output) => {
          if (output.status === 'SUCCESS') {
            let doctorDetails = output.data;

            let doctorLocation = [];
            doctorLocation.push(doctorDetails);
            PtsAlert.success(output.message);
            this.setState({
              locationList: doctorLocation,
            });
          } else {
          }
        });
      }
    }
  }

  viewCancel() {
    this.props.history.push({
      pathname: '/import',
    });
  }
  showMap() {
    this.setState({
      showMapView: true,
      showLocationView: false,
    });
  }

  editCancel(e, doctorId) {
    this.props.history.push({
      pathname: '/viewDoctor',
      state: { action: 'view', title: 'Doctor Details ', doctorId: doctorId },
    });
  }

  editCancelDoctorDetails(e) {
    this.setState({
      viewDoctor: true,
      editDoctor: false,
    });
  }

  updateDoctor(e) {
    this.setState({
      viewDoctor: false,
      editDoctor: true,
    });
  }

  /*  Delete doctor details  in backend  */
  deleteDoctor = () => {
    const postObject = {
      header: this.header,
      doctor: {
        id: this.state.id,
      },
    };

    FetchServerData.callPostService('/impDoctorms/delete', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          this.props.history.push({
            pathname: '/import',
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  deleteLocationToBackend = (e, doctorLocation) => {
    const postObject = {
      header: this.header,
      doctorLocation: {
        id: doctorLocation.id,
        doctor: {
          id: this.state.id,
        },
      },
    };

    FetchServerData.callPostService('doctorms/locationDelete', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          // let doctorDetails = output.data;
          for (let i = 0; i < this.state.locationList.length; i++) {
            if (this.state.locationList[i].id === doctorLocation.id) {
              this.state.locationList.splice(i, 1);
            }
          }
          this.setState({
            locationList: this.state.locationList,
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  addLocationData() {}
  render() {
    this.locationReferenceList = [];
    this.state.locationList.forEach((element, index) => {
      this.locationReferenceList[index] = React.createRef();
    });

    if (this.state.action === 'edit') {
      return this.editDoctorDetails();
    } else if (this.state.action === 'view') {
      return this.viewDoctorDetails();
    }
  }

  /*  view doctor form  */
  viewDoctorDetails() {
    if (this.state.render === false) {
      return <div />;
    } else {
      if (this.state.isFreeUser === true) {
        return this.viewDoctorDetailsWithAdvertisement();
      } else {
        return this.viewDoctorDetailsWithoutAdvertisement();
      }
    }
  }

  viewDoctorDetailsWithAdvertisement() {
    if (this.state.render === false) {
      return <div />;
    } else {
      let locationItems = [];

      this.state.locationList.forEach((element, index) => {
        if ('delete' !== element.operation) {
          locationItems.unshift(
            <Col md={12}>
              <Location
                ref={this.locationReferenceList[index]}
                key={index}
                data={element}
                srNo={index}
                pageId="editDoctor"
                getDeletePermission={this.state.getDeletePermission}
                getUpdatePermission={this.state.getUpdatePermission}
                action={element.action ? element.action : 'editLocation'}
                onDelete={this.deleteLocationToBackend.bind(this, element)}
                onCancel={this.deleteLocation.bind(this)}
                onLocationAdd={this.addNewLocationInBackend.bind(this)}
                onUpdate={this.updateLocationToBackend.bind(this)}
                isImport="import"
              />
            </Col>
          );
        }
      });

      return (
        <>
          <MenuWithBreadScrum
            backCallback={this.viewCancel.bind(this)}
            deleteCallback={this.deleteDoctor.bind(this)}
            getDeletePermission={this.state.getDeletePermission}
            getUpdatePermission={this.state.getUpdatePermission}
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            name={this.title + ' For ' + this.memberName}
            domainObject="Doctor"
          />

          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={4} style={{ position: 'relative', padding: '5px' }}>
              <Card style={{ height: '100%', borderRadius: '20px' }}>
                {this.state.viewDoctor && (
                  <>
                    <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                      <Row>
                        <Col md={10}>
                          <Label
                            style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                          >
                            Doctor Details
                          </Label>
                        </Col>
                        <Col md={2}>
                          {this.state.getUpdatePermission === true && (
                            <EditIconButton onClick={this.updateDoctor.bind(this)} />
                          )}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <div style={{ padding: '0em 0em 0em 2em' }}>
                          <img src={doctor} className="img-circle" width="30px" height="30px" alt="" />
                          <label style={{ paddingLeft: '1.1em', color: '#23a8c1', textTransform: 'capitalize' }}>
                            {'Dr.' +
                              ' ' +
                              (this.state.fname ? this.state.fname : '') +
                              ' ' +
                              (this.state.lname ? this.state.lname : '')}
                          </label>
                        </div>
                      </Row>

                      {this.state.viewSelectedSpecialization && (
                        <Row style={{ padding: '3px', marginLeft: '0em' }}>
                          <Col md={1}>
                            <Tooltip title="Specialization" aria-label="add">
                              <i
                                className="fa fa-stethoscope"
                                style={{ fontSize: '20px', fontWeight: 'bold', color: 'blue' }}
                              />
                            </Tooltip>
                          </Col>
                          <Col md={11}>{this.state.viewSelectedSpecialization}</Col>
                        </Row>
                      )}
                      {this.state.phone && (
                        <Row style={{ padding: '3px', marginLeft: '0em' }}>
                          <Col md={1}>
                            <Tooltip title={this.state.phone} aria-label="add">
                              <PhoneIcon style={{ fontSize: '20px' }} />
                            </Tooltip>
                          </Col>
                          <Col md={11}>
                            <a href={'tel:' + this.state.phone} target="_top">
                              {Utils.displayUsaPhoneNumberFormate(this.state.phone)}
                            </a>
                            {/* {this.state.phone} */}
                          </Col>
                        </Row>
                      )}
                      {this.state.email && (
                        <Row style={{ padding: '3px', marginLeft: '0em' }}>
                          <Col md={1}>
                            <Tooltip title={this.state.email} aria-label="add">
                              <EmailIcon style={{ fontSize: '20px' }} />
                            </Tooltip>
                          </Col>
                          <Col md={11}>
                            <a href={'mailto:' + this.state.email} target="_top">
                              {this.state.email}
                            </a>
                          </Col>
                        </Row>
                      )}
                      {this.state.website && (
                        <Row style={{ padding: '3px', marginLeft: '0em' }}>
                          <Col md={1}>
                            <Tooltip title="website" aria-label="add">
                              <LanguageIcon style={{ fontSize: 'large' }} />
                            </Tooltip>
                          </Col>
                          <Col md={11}>
                            <a
                              href={
                                this.state.website.includes('https://') || this.state.website.includes('http://')
                                  ? this.state.website
                                  : 'http://' + this.state.website
                              }
                              target="blank"
                            >
                              {this.state.website}
                            </a>
                          </Col>
                        </Row>
                      )}
                      {this.state.websiteuser && (
                        <Row style={{ padding: '3px', marginLeft: '0em' }}>
                          <Col md={1}>
                            <Tooltip title="Portal Username" aria-label="add">
                              <PersonIcon style={{ fontSize: '20px' }} />
                            </Tooltip>
                          </Col>
                          <Col md={11}>
                            <a href={'webuserto:' + this.state.websiteuser} target="_top">
                              {this.state.websiteuser}
                            </a>
                            <Tooltip title="copy" aria-label="add">
                              <IconButton style={{ marginLeft: '30px' }}>
                                <CopyToClipboard text={this.state.websiteuser} onClick={this.onCopy}>
                                  <FileCopyIcon style={{ color: 'gray', fontSize: '20px' }} />
                                </CopyToClipboard>
                              </IconButton>
                            </Tooltip>
                          </Col>
                        </Row>
                      )}
                    </CardBody>
                  </>
                )}
                {this.state.editDoctor && (
                  <ValidatorForm style={{ width: '100%' }} ref="form" onSubmit={this.updateDoctorToBackend.bind(this)}>
                    <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                      <Row>
                        <Col md={9}>
                          {undefined !== this.state.title && this.state.title.length > 0 && (
                            <Label
                              style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                            >
                              Doctor Details
                            </Label>
                          )}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody style={{ padding: '2em', marginTop: '-2em' }}>
                      <Row>
                        <Col md={3} style={{ marginTop: '1.2em' }}>
                          <input
                            className="pts_dataList_dropDown"
                            type="text"
                            // labelId="salutation-label"
                            id="salutation"
                            list="data"
                            placeholder="Dr"
                            label="Dr"
                            value={this.state.salutation}
                            onChange={(event, value) => {
                              this.handleSalutationChange(event, value);
                            }}
                          />
                          <datalist id="data">
                            {this.salutationList.map((event, key) => (
                              <option key={event.id} value={event.salutation} />
                            ))}
                          </datalist>
                        </Col>
                        <Col md={4}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            type="text"
                            id="fname"
                            onChange={this.handleFnameChange.bind(this)}
                            label="First Name"
                            name="fname"
                            inputprops={{ maxLength: 128, style: { textTransform: 'capitalize' } }}
                            value={this.state.fname}
                          />
                        </Col>
                        <Col md={5}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            required
                            fullWidth
                            id="lname"
                            type="text"
                            onChange={this.handleLnameChange.bind(this)}
                            label="Last Name"
                            name="lname"
                            inputprops={{ style: { textTransform: 'capitalize' } }}
                            value={this.state.lname}
                          />
                        </Col>
                      </Row>{' '}
                      <Row style={{ marginTop: '1em' }}>
                        <Col md={12}>
                          <input
                            className="pts_dataList_dropDown"
                            type="text"
                            required
                            // labelId="specialization-outlined-label"
                            id="specialization-outlined"
                            list="specialization"
                            placeholder="Specialization*"
                            label="Specialization*"
                            value={this.state.specialization}
                            onChange={(event, value) => {
                              this.handleSpecializationChange(event, value);
                            }}
                          />
                          <datalist id="specialization">
                            {this.specializationList.map((event, key) => (
                              <option key={event.specialization} value={event.specialization} />
                            ))}
                          </datalist>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            type="tel"
                            id="phone"
                            onChange={this.handlePhoneChange.bind(this)}
                            label="Phone"
                            name="phone"
                            // inputprops={{ maxLength: 10 }}
                            value={this.state.phone}
                          />
                        </Col>
                      </Row>{' '}
                      <Row>
                        <Col md={12}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            type="email"
                            fullWidth
                            id="email"
                            onChange={this.handleEmailChange.bind(this)}
                            label="Email"
                            name="email"
                            value={this.state.email}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            fullWidth
                            id="website"
                            onChange={this.handleWebsiteChange.bind(this)}
                            label="Doctor Portal"
                            name="website"
                            value={this.state.website}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            fullWidth
                            id="websiteuser"
                            onChange={this.handleWebsiteuserChange.bind(this)}
                            label="Portal Username"
                            name="websiteuser"
                            value={this.state.websiteuser}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter style={{ textAlign: 'center' }}>
                      <SaveButton type="submit" />
                      &nbsp;
                      <CancelIconButton type="button" onClick={(e) => this.editCancelDoctorDetails(e, this.state.id)} />
                    </CardFooter>
                  </ValidatorForm>
                )}
              </Card>
            </Col>
            <Col md={8} style={{ position: 'relative', padding: '5px' }}>
              {this.state.showMapView && (
                <Row>
                  <Col md={12}>
                    <Card style={{ height: '100%', borderRadius: '20px' }}>
                      <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                        <Row>
                          <Col md={12}>
                            {undefined !== this.state.title && this.state.title.length > 0 && (
                              <Label
                                style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                              >
                                Locations
                              </Label>
                            )}
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Row md={12}>{locationItems}</Row>
                        <Row style={{ margin: '0em' }}>
                          <Col md={12}>
                            <Row md={12}>
                              <Col md={12} style={{ padding: '0em' }}>
                                {' '}
                                <span className="closeFloatingButton" aria-label="add">
                                  {' '}
                                  <CloseIcon
                                    onClick={this.closeButton.bind(this)}
                                    style={{ float: 'right', fontSize: '15px', cursor: 'pointer' }}
                                  />
                                </span>
                              </Col>
                            </Row>{' '}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>{' '}
                </Row>
              )}
              {this.state.showLocationView && (
                <Card style={{ height: '100%', borderRadius: '20px' }}>
                  <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                    <Row>
                      <Col md={12}>
                        {undefined !== this.state.title && this.state.title.length > 0 && (
                          <Label
                            style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                          >
                            Locations
                          </Label>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row md={12}>{locationItems}</Row>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>

          <div style={{ textAlign: 'center', width: '100%', marginTop: '2em', padding: '0em 0em 1em' }}>
            <BackButton
              type="button"
              style={{ backgroundColor: '#162c50', color: '#fff' }}
              variant="contained"
              startIcon={<CloseIcon />}
              onClick={(e) => this.viewCancel()}
            >
              Back
            </BackButton>
          </div>
        </>
      );
    }
  }

  viewDoctorDetailsWithoutAdvertisement() {
    if (this.state.render === false) {
      return <div />;
    } else {
      let locationItems = [];

      this.state.locationList.forEach((element, index) => {
        if ('delete' !== element.operation) {
          locationItems.unshift(
            <Col md={12}>
              <Location
                ref={this.locationReferenceList[index]}
                key={index}
                data={element}
                srNo={index}
                pageId="editDoctor"
                getDeletePermission={this.state.getDeletePermission}
                getUpdatePermission={this.state.getUpdatePermission}
                action={element.action ? element.action : 'editLocation'}
                onDelete={this.deleteLocationToBackend.bind(this, element)}
                onCancel={this.deleteLocation.bind(this)}
                onLocationAdd={this.addNewLocationInBackend.bind(this)}
                onUpdate={this.updateLocationToBackend.bind(this)}
                isImport="import"
              />
            </Col>
          );
        }
      });

      return (
        <>
          <MenuWithBreadScrum
            backCallback={this.viewCancel.bind(this)}
            deleteCallback={this.deleteDoctor.bind(this)}
            getDeletePermission={this.state.getDeletePermission}
            getUpdatePermission={this.state.getUpdatePermission}
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            name={this.title + ' For ' + this.memberName}
            domainObject="Doctor"
          />

          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={4} style={{ position: 'relative', padding: '5px' }}>
              <Card style={{ height: '100%', borderRadius: '20px' }}>
                {this.state.viewDoctor && (
                  <>
                    <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                      <Row>
                        <Col md={10}>
                          <Label
                            style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                          >
                            Doctor Details
                          </Label>
                        </Col>
                        <Col md={2}>
                          {this.state.getUpdatePermission === true && (
                            <EditIconButton onClick={this.updateDoctor.bind(this)} />
                          )}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row>
                        <div style={{ padding: '0em 0em 0em 2em' }}>
                          <img src={doctor} className="img-circle" width="30px" height="30px" alt="" />
                          <label style={{ paddingLeft: '1.1em', color: '#23a8c1', textTransform: 'capitalize' }}>
                            {'Dr.' +
                              ' ' +
                              (this.state.fname ? this.state.fname : '') +
                              ' ' +
                              (this.state.lname ? this.state.lname : '')}
                          </label>
                        </div>
                      </Row>

                      {this.state.viewSelectedSpecialization && (
                        <Row style={{ padding: '3px', marginLeft: '0em' }}>
                          <Col md={1}>
                            <Tooltip title="Specialization" aria-label="add">
                              <i
                                className="fa fa-stethoscope"
                                style={{ fontSize: '20px', fontWeight: 'bold', color: 'blue' }}
                              />
                            </Tooltip>
                          </Col>
                          <Col md={11}>{this.state.viewSelectedSpecialization}</Col>
                        </Row>
                      )}
                      {this.state.phone && (
                        <Row style={{ padding: '3px', marginLeft: '0em' }}>
                          <Col md={1}>
                            <Tooltip title={this.state.phone} aria-label="add">
                              <PhoneIcon style={{ fontSize: '20px' }} />
                            </Tooltip>
                          </Col>
                          <Col md={11}>
                            <a href={'tel:' + this.state.phone} target="_top">
                              {Utils.displayUsaPhoneNumberFormate(this.state.phone)}
                            </a>
                            {/* {this.state.phone} */}
                          </Col>
                        </Row>
                      )}
                      {this.state.email && (
                        <Row style={{ padding: '3px', marginLeft: '0em' }}>
                          <Col md={1}>
                            <Tooltip title={this.state.email} aria-label="add">
                              <EmailIcon style={{ fontSize: '20px' }} />
                            </Tooltip>
                          </Col>
                          <Col md={11}>
                            <a href={'mailto:' + this.state.email} target="_top">
                              {this.state.email}{' '}
                            </a>
                          </Col>
                        </Row>
                      )}
                      {this.state.website && (
                        <Row style={{ padding: '3px', marginLeft: '0em' }}>
                          <Col md={1}>
                            <Tooltip title="website" aria-label="add">
                              <LanguageIcon style={{ fontSize: 'large' }} />
                            </Tooltip>
                          </Col>
                          <Col md={11}>
                            <a
                              href={
                                this.state.website.includes('https://') || this.state.website.includes('http://')
                                  ? this.state.website
                                  : 'http://' + this.state.website
                              }
                              target="blank"
                            >
                              {this.state.website}
                            </a>
                          </Col>
                        </Row>
                      )}
                      {this.state.websiteuser && (
                        <Row style={{ padding: '3px', marginLeft: '0em' }}>
                          <Col md={1}>
                            <Tooltip title="Portal Username" aria-label="add">
                              <PersonIcon style={{ fontSize: '20px' }} />
                            </Tooltip>
                          </Col>
                          <Col md={11}>
                            <a href={'webuserto:' + this.state.websiteuser} target="_top">
                              {this.state.websiteuser}
                            </a>
                            <Tooltip title="copy" aria-label="add">
                              <IconButton style={{ marginLeft: '30px' }}>
                                <CopyToClipboard text={this.state.websiteuser} onClick={this.onCopy}>
                                  <FileCopyIcon style={{ color: 'gray', fontSize: '20px' }} />
                                </CopyToClipboard>
                              </IconButton>
                            </Tooltip>
                          </Col>
                        </Row>
                      )}
                    </CardBody>
                    {/* </Card> */}
                  </>
                )}
                {this.state.editDoctor && (
                  <ValidatorForm style={{ width: '100%' }} ref="form" onSubmit={this.updateDoctorToBackend.bind(this)}>
                    <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                      <Row>
                        <Col md={9}>
                          {undefined !== this.state.title && this.state.title.length > 0 && (
                            <Label
                              style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                            >
                              Doctor Details
                            </Label>
                          )}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody style={{ padding: '2em', marginTop: '-2em' }}>
                      <Row>
                        <Col md={3} style={{ marginTop: '1.2em' }}>
                          <input
                            className="pts_dataList_dropDown"
                            type="text"
                            // labelId="salutation-label"
                            id="salutation"
                            list="data"
                            placeholder="Dr"
                            label="Dr"
                            value={this.state.salutation}
                            onChange={(event, value) => {
                              this.handleSalutationChange(event, value);
                            }}
                          />
                          <datalist id="data">
                            {this.salutationList.map((event, key) => (
                              <option key={event.id} value={event.salutation} />
                            ))}
                          </datalist>
                        </Col>
                        <Col md={4}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            type="text"
                            id="fname"
                            onChange={this.handleFnameChange.bind(this)}
                            label="First Name"
                            name="fname"
                            inputprops={{ maxLength: 128, style: { textTransform: 'capitalize' } }}
                            value={this.state.fname}
                          />
                        </Col>
                        <Col md={5}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            required
                            fullWidth
                            id="lname"
                            type="text"
                            onChange={this.handleLnameChange.bind(this)}
                            label="Last Name"
                            name="lname"
                            inputprops={{ style: { textTransform: 'capitalize' } }}
                            value={this.state.lname}
                          />
                        </Col>
                      </Row>{' '}
                      <Row style={{ marginTop: '1em' }}>
                        <Col md={12}>
                          <input
                            className="pts_dataList_dropDown"
                            type="text"
                            required
                            // labelId="specialization-outlined-label"
                            id="specialization-outlined"
                            list="specialization"
                            placeholder="Specialization*"
                            label="Specialization*"
                            value={this.state.specialization}
                            onChange={(event, value) => {
                              this.handleSpecializationChange(event, value);
                            }}
                          />
                          <datalist id="specialization">
                            {this.specializationList.map((event, key) => (
                              <option key={event.specialization} value={event.specialization} />
                            ))}
                          </datalist>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            fullWidth
                            type="tel"
                            id="phone"
                            onChange={this.handlePhoneChange.bind(this)}
                            label="Phone"
                            name="phone"
                            // inputprops={{ maxLength: 10 }}
                            value={this.state.phone}
                          />
                        </Col>
                      </Row>{' '}
                      <Row>
                        <Col md={12}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            type="email"
                            fullWidth
                            id="email"
                            onChange={this.handleEmailChange.bind(this)}
                            label="Email"
                            name="email"
                            value={this.state.email}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            fullWidth
                            id="website"
                            onChange={this.handleWebsiteChange.bind(this)}
                            label="Doctor Portal"
                            name="website"
                            value={this.state.website}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12}>
                          <TextField
                            margin="normal"
                            variant="outlined"
                            type="text"
                            fullWidth
                            id="websiteuser"
                            onChange={this.handleWebsiteuserChange.bind(this)}
                            label="Portal Username"
                            name="websiteuser"
                            value={this.state.websiteuser}
                          />
                        </Col>
                      </Row>
                    </CardBody>
                    <CardFooter style={{ textAlign: 'center' }}>
                      <SaveButton type="submit" />
                      &nbsp;
                      <CancelIconButton type="button" onClick={(e) => this.editCancelDoctorDetails(e, this.state.id)} />
                    </CardFooter>
                  </ValidatorForm>
                )}
              </Card>
            </Col>
            <Col md={8} style={{ position: 'relative', padding: '5px' }}>
              {this.state.showMapView && (
                <Row>
                  <Col md={12}>
                    <Card style={{ height: '100%', borderRadius: '20px' }}>
                      <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                        <Row>
                          <Col md={12}>
                            {undefined !== this.state.title && this.state.title.length > 0 && (
                              <Label
                                style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                              >
                                Locations
                              </Label>
                            )}
                          </Col>
                        </Row>
                      </CardHeader>
                      <CardBody>
                        <Row md={12}>{locationItems}</Row>
                        <Row style={{ margin: '0em' }}>
                          <Col md={12}>
                            <Row md={12}>
                              <Col md={12} style={{ padding: '0em' }}>
                                {' '}
                                <span className="closeFloatingButton" aria-label="add">
                                  {' '}
                                  <CloseIcon
                                    onClick={this.closeButton.bind(this)}
                                    style={{ float: 'right', fontSize: '15px', cursor: 'pointer' }}
                                  />
                                </span>
                              </Col>
                            </Row>{' '}
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>{' '}
                </Row>
              )}
              {this.state.showLocationView && (
                <Card style={{ height: '100%', borderRadius: '20px' }}>
                  <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0.3em' }}>
                    <Row>
                      <Col md={12}>
                        {undefined !== this.state.title && this.state.title.length > 0 && (
                          <Label
                            style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em', marginTop: '0.5em' }}
                          >
                            Locations
                          </Label>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row md={12}>{locationItems}</Row>
                  </CardBody>
                </Card>
              )}
            </Col>
          </Row>

          <div style={{ textAlign: 'center', width: '100%', marginTop: '2em', padding: '0em 0em 1em' }}>
            <BackButton
              type="button"
              style={{ backgroundColor: '#162c50', color: '#fff' }}
              variant="contained"
              startIcon={<CloseIcon />}
              onClick={(e) => this.viewCancel()}
            >
              Back
            </BackButton>
          </div>
        </>
      );
    }
  }
}

export default DoctorImport;
