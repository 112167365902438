// import MaterialTable from 'material-table';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import UiUtils from '../../../provider/UiUtils';
import Utils from '../../../provider/Utils';
import adminFccPageStateAction from '../../../redux/adminPanel/adminFccPageState/adminFccPageStateAction';
import applicationStateAction from '../../../redux/applicationState/applicationStateAction';
import store from '../../../redux/store';
import AddIconButton from '../../components/buttons/AddIconButton';
import AdminMenuWithBreadScrum from '../adminMenu/AdminMenuWithBreadScrum';
import MaterialTable from '@material-table/core';
import BreadCrumbDetailsAction from '../../../redux/breadCrumbDetails/BreadCrumbDetailsAction';
export class SponsorList extends Component {
  title = 'Sponsor List';
  breadCrumb = [{ pageid: '/sponsorList', uiname: 'Sponsor List' }];
  header = store.getState().adminHeader.adminHeader;
  sponsorState = store.getState().applicationState.sponsorState;
  sponsorStateDetails = {};
  constructor(props) {
    super(props);
    let calendarState = {};
    store.dispatch(
      applicationStateAction.setApplicationState({
        calendarState,
      })
    );
    let action = props.action;
    let pageId = '';
    if (
      null !== props.history &&
      props.history !== undefined &&
      props.history.location !== null &&
      props.history.location.state !== null &&
      props.history.location.state !== undefined
    ) {
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
    }
    store.dispatch(adminFccPageStateAction.setAdminFccPageState({}));

    let sponsorApplicationState = {};
    if (this.sponsorState) {
      sponsorApplicationState = Object.getOwnPropertyNames(this.sponsorState);
    }
    if (this.breadCrumb) {
      Utils.getAdminPanelActiveMenu(this.breadCrumb);
      store.dispatch(BreadCrumbDetailsAction.setBreadCrumbDetails(this.breadCrumb));
    }
    if (sponsorApplicationState.length > 0) {
      this.state = {
        email: '',
        password: '',
        showPassword: false,
        action: action,
        pageId: pageId,
        isPaging: false,
        initialPage:
          this.sponsorState.initialPage && this.sponsorState.initialPage > 0 ? this.sponsorState.initialPage : 0,
        sponsorList: [],
        pageSize: this.sponsorState.pageSize && this.sponsorState.pageSize ? this.sponsorState.pageSize : 10,
        sponsorId: this.sponsorState.sponsorId,
        render: false,
      };
    } else {
      this.state = {
        email: '',
        password: '',
        showPassword: false,
        action: action,
        pageId: pageId,
        isPaging: false,
        initialPage: 0,
        sponsorList: [],
        pageSize: 10,
        render: false,
      };
    }
    this.getSponsorList();
  }
  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }
  getSponsorList() {
    const postObject = {
      header: this.header,
    };

    FetchServerData.callPostService('/sponsorms/getAll', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let sponsorList = output.data.sponsorList;
        let getPermission = Utils.getRolePermission(output.data.privilegeList);
        let getCreatePermission = getPermission[1];
        let isPaging = false;
        if (sponsorList.length > 10) {
          isPaging = true;
        }
        this.setState({
          isPaging: isPaging,
          sponsorList: sponsorList,
          render: true,
          initialPage: this.state.initialPage,
          pageSize: this.state.pageSize,
          getCreatePermission: getCreatePermission,
        });
      } else {
      }
    });
  }

  addSponsor(e) {
    let adminFccPageState = {
      id: '',
      action: 'create',
      title: 'Add Sponsor',
      pageId: 'sponsorList',
    };
    store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
    this.props.history.push({
      pathname: '/addSponsor',
      state: { action: 'create', title: 'Add Sponsor', sponsorId: '', pageId: 'sponsorList' },
    });
  }

  toSponsorDetails(e, data) {
    let sponsorState = {
      sponsorId: data.id,
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
    };
    store.dispatch(applicationStateAction.setApplicationState({ sponsorState }));
    let adminFccPageState = {
      id: data.id,
      action: 'view',
      title: 'Sponsor Details',
      pageId: 'sponsorList',
      sponsorName: data.name,
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
    };
    store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
    this.props.history.push({
      pathname: '/viewSponsor',
      state: { action: 'view', title: 'Sponsor Details', sponsorId: data.id, pageId: 'sponsorList' },
    });
  }
  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          {/* <Header /> */}
          {/* Page content */}
          <AdminMenuWithBreadScrum breadCrumb={this.breadCrumb} history={this.props.history} name={this.title} />

          <Row style={{ position: 'relative', margin: '1em 0em', maxWidth: '100%' }}>
            <Col md={12}>
              <MaterialTable
                onRowClick={(event, rowData) => {
                  this.toSponsorDetails(event, rowData);
                  event.stopPropagation();
                }}
                icons={UiUtils.getMaterialTableIcons()}
                title=""
                localization={{
                  body: {
                    emptyDataSourceMessage: Utils.communizeTableText(),
                  },
                }}
                columns={[
                  {
                    title: '#',
                    render: (rowData) => rowData.tableData.index + 1,
                    width: '5%',
                    align: 'left',
                    cellStyle: {
                      width: '6%',
                    },
                  },
                  {
                    title: 'Name',
                    field: 'name',
                    cellStyle: {
                      width: '18%',
                    },
                  },
                  {
                    title: 'Website',
                    field: 'website',
                    cellStyle: { textTransform: 'none', width: '15%' },
                  },

                  {
                    title: 'Phone',
                    render: (rowData) => Utils.displayUsaPhoneNumberFormate(rowData.phone),
                    field: 'phone',
                    filtering: false,
                    cellStyle: {
                      width: '12%',
                    },
                  },

                  {
                    title: 'Location',
                    field: 'address',
                    width: '25%',
                    cellStyle: {
                      width: '32%',
                    },
                  },
                  {
                    title: 'Status',
                    field: 'status',
                    align: 'left',
                    cellStyle: {
                      width: '5%',
                    },
                    filtering: false,
                  },
                  {
                    title: 'Active Plan',
                    field: 'activeSponsorshipCount',
                    align: 'center',
                    headerStyle: { align: 'center' },
                    width: '10%',
                    // align: 'left',
                    filtering: false,
                  },
                ]}
                data={this.state.sponsorList}
                onPageChange={(page, pageSize) => {
                  this.handleMaterialTablePageChange(page, pageSize);
                }}
                options={{
                  sorting: true,
                  actionsColumnIndex: -1,
                  paging: this.state.sponsorList.length > this.state.pageSize,
                  pageSize: this.state.pageSize,
                  pageSizeOptions: [5, 10, 20, 50, 100],
                  initialPage: this.state.initialPage,
                  headerStyle: {
                    backgroundColor: '#066f72',
                    fontWeight: 'bold',
                    color: '#fff',
                    align: 'left',
                  },

                  filtering: true,
                  // paging: this.state.isPaging,
                  rowStyle: (rowData) => ({
                    backgroundColor: rowData.id === this.state.sponsorId ? '#FEF5E7' : '#fff',
                    height: '3em',
                    textTransform: 'capitalize',
                  }),
                  tableLayout: 'auto',
                }}
                actions={[
                  {
                    icon: () => (
                      <span className="newDoctor">
                        {' '}
                        {this.state.getCreatePermission && <AddIconButton btnName="Add Sponsor" />}
                      </span>
                    ),
                    tooltip: 'Add Sponsor',
                    isFreeAction: true,
                    onClick: (event) => this.addSponsor(),
                  },
                ]}
              />
            </Col>
          </Row>
        </>
      );
    }
  }
}
export default SponsorList;
