import React from 'react';

// import { Tooltip, Zoom } from '@mui/material';
import Tooltip from '@material-ui/core/Tooltip';
class CustomTooltip extends React.Component {
  render() {
    return (
      <Tooltip
        // TransitionComponent={Zoom}
        arrow
        className={this.props.className}
        title={this.props.title}
        id={this.props.id}
        placement={this.props.placement}
        open={this.props.open && this.props.open}
      >
        {this.props.children}
      </Tooltip>
    );
  }
}

export default CustomTooltip;
