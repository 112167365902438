import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Tooltip from '@material-ui/core/Tooltip';
import CloseIcon from '@material-ui/icons/Close';
import EditIcon from '@material-ui/icons/Edit';
import EmailIcon from '@material-ui/icons/Email';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import MailIcon from '@material-ui/icons/Mail';
import PhoneIcon from '@material-ui/icons/Phone';
import PrintRoundedIcon from '@material-ui/icons/PrintRounded';
import SaveIcon from '@material-ui/icons/Save';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { format } from 'date-fns';
import 'intro.js/introjs.css';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import CancelScheduleSendIcon from '@material-ui/icons/CancelScheduleSend';
import OfflinePinIcon from '@material-ui/icons/OfflinePin';
import Geocode from 'react-geocode';
import { ValidatorForm } from 'react-material-ui-form-validator';
import 'react-tabs/style/react-tabs.css';
import { Card, CardBody, CardFooter, CardHeader, Col, Label, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import doctor from '../../../../src/assets/images/doctor.png';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import appointmentDataAction from '../../../redux/appointmentData/appointmentDataAction';
import fccPageStateAction from '../../../redux/fccPageState/fccPageStateAction';
import helpDataAction from '../../../redux/help/displayHelpAction';
import store from '../../../redux/store';
import AddIconButton from '../buttons/AddIconButton';
import BackButton from '../buttons/BackButton';
import CancelIconButton from '../buttons/CancelIconButton';
import CreateButton from '../buttons/CreateButton';
import SaveButton from '../buttons/SaveButton';
import Location from '../location/Location';
import Map from '../map/Map';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
import PtsAlert from '../ptsAlert/PtsAlert';
import Reminder from '../reminder/Reminder';
import AppointmentFacility from './AppointmentFacility';
import './MemberAppointment.scss';
import ReferenceAppointment from './ReferenceAppointment';
import Transportation from './Transportation';
import AppointmentNote from './appointmentNote';

import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { createMuiTheme } from '@material-ui/core';
import Fab from '@material-ui/core/Fab';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import ReactToPrint from 'react-to-print';
// registerLocale('es', es);
export class MemberAppointment extends Component {
  frequencyTypeListArray = store.getState().staticData.appointmentFrequencyList;
  appointmentTypeListArray = store.getState().staticData.appointmentTypeList;
  appointmentPurposeListData = store.getState().staticData.appointmentPurposeList;
  taskTypeList = store.getState().staticData.taskTypeList;
  taskOccuranceList = store.getState().staticData.taskOccuranceList;
  accompanyTypeList = store.getState().staticData.accompanyTypeList;
  countryListArray = store.getState().staticData.countryList;
  memberName = store.getState().memberData.memberData.firstname + ' ' + store.getState().memberData.memberData.lastname;
  header = store.getState().header.header;
  memberDetails = store.getState().memberData.memberData;
  isFreeUser = store.getState().userData.userData.isFreeUser;
  displayHelp = store.getState().help.help;
  fccPageState = store.getState().fccPageState.fccPageState;
  memberHomeUrl = store.getState().memberHomeUrl.memberHomeUrl.homeUrl;
  userEmail = store.getState().userData.userData.email;
  title = 'Member Appointment';
  breadCrumb = [
    { pageid: this.memberHomeUrl, uiname: this.memberName },
    { pageid: '/memberAppointment', uiname: 'Appointment List' },
    { pageid: '', uiname: 'Member Appointment' },
  ];

  stateListArray = [];
  doctorListArray = [];
  itemReferenceList = [];
  appointmentNoteRefList = [];
  noteListArray = [];
  appointmentTaskFacilityList = [];
  appointmentFacilityList = [];
  facilityReminderReferenceList = [];
  appointmentReminderRefList = [];
  facilityTaskReminderRefList = [];
  appointmentReminderCreateRefList = [];
  appointmentTaskReminderCreateRefList = [];
  transportationReminderRefList = [];
  viewTransportationReminderRefList = [];
  facilityTransportationRef = {};
  facilityTransportationReminderRefList = [];
  appointmentTransportationRef = [];
  appointmentTaskFacilityRef = [];
  appointmentTaskReminderRefList = [];
  appointmentTaskReminderRefList1 = [];
  referenceAppointmentRef = {};
  constructor(props) {
    super(props);
    // store.dispatch(CalenderSyncDetailsAction.setCalenderSyncDetails({}));
    let createdAppointmentData = {};
    let calenderStartDate = new Date();
    let appointmentId = '';
    let action = '';
    let title = '';
    let pageId = props.pageId;
    let isPrintView = props.isPrintView;
    let isButtonHide = false;
    let isViewButtonHide = true;
    let isEditButtonHide = true;
    let appointmentData = '';
    let appointmentDataId = '';
    let appointmentNoteId = '';
    let canRender = false;
    let isTransportationChecked = true;
    let doctorViewId = '';
    let facilityViewId = '';
    let str = window.location.href;
    let urlArr = str.split('?');
    let appointmentVo = urlArr[1];
    let doctorLocationList = [];
    let facilityLocationList = [];
    if (appointmentVo) {
      let appointmentDataVo = appointmentVo.split('=');
      appointmentData = appointmentDataVo[1].split('&');
      appointmentDataId = appointmentData[0];
      appointmentNoteId = appointmentDataVo[2];
      appointmentNoteId = appointmentNoteId.split('&');
      appointmentNoteId = appointmentNoteId[0];
      action = 'view';
      this.getAppointmentDetails(appointmentDataId);
    } else {
      appointmentId = props.appointmentId;
      action = props.action;
      if (!appointmentId && props.history && props.history.location && props.history.location.state) {
        appointmentId = props.history.location.state.appointmentId;
        action = props.history.location.state.action;
        pageId = props.history.location.state.pageId;
        title = props.history.location.state.title;
        createdAppointmentData = props.history.location.state.appointmentData;
        doctorViewId = props.history.location.state.doctorViewId;
        facilityViewId = props.history.location.state.facilityViewId;
        if (props.history.location.state.startDate) {
          calenderStartDate = new Date(moment(props.history.location.state.startDate));
        }
      } else if (props.history && props.history.location && props.history.location.state) {
        action = props.history.location.state.action;
        pageId = props.history.location.state.pageId;
      }
      if (pageId === 'editMemberAppointments') {
        isButtonHide = true;
        isEditButtonHide = false;
      }

      if (props.isPrintView === 'view_print') {
        isViewButtonHide = false;
      }
      if (pageId === 'addAppointment') {
        action = 'create';
      }

      if (null !== appointmentId && appointmentId > 0) {
        this.getAppointmentDetails(appointmentId);
      } else {
        canRender = true;
      }
    }
    if (appointmentId === undefined) {
      appointmentId = this.fccPageState.id;
      action = this.fccPageState.action;
      if (action !== 'create') {
        this.getAppointmentDetails(appointmentId);
      }
    }
    doctorLocationList.unshift({ name: 'Add New Doctor', id: 'Add New Doctor' });

    let doctorLocationListArray = doctorLocationList.reduce((unique, o) => {
      if (!unique.some((obj) => obj.name === o.name && obj.id === o.id)) {
        unique.push(o);
      }
      return unique;
    }, []);

    facilityLocationList.unshift({ name: 'Add New Facility', id: 'Add New Facility' });

    let facilityLocationListArray = facilityLocationList.reduce((unique, o) => {
      if (!unique.some((obj) => obj.name === o.name && obj.id === o.id)) {
        unique.push(o);
      }
      return unique;
    }, []);
    if (pageId === 'createAppointment') {
      let appointmentData = createdAppointmentData.appointmentWithPreviousAppointment.appointment;
      if (appointmentData) {
        isTransportationChecked = appointmentData.isTransportation;
      }

      let getDomainObjectPermission = createdAppointmentData.domainObjectPrivileges;
      let getCreatePermission = false;
      let getReadPermission = false;
      let getUpdatePermission = false;
      let getDeletePermission = false;
      let showAppointmentPermission = false;
      let showAppointmentNotePermission = false;
      let getAppointmentNoteCreatePermission = false;
      let getAppointmentNoteReadPermission = false;
      let getAppointmentNoteUpdatePermission = false;
      let getAppointmentNoteDeletePermission = false;
      let showAppointmentTransportationPermission = false;
      let getAppointmentTransportationCreatePermission = false;
      let getAppointmentTransportationReadPermission = false;
      let getAppointmentTransportationUpdatePermission = false;
      let getAppointmentTransportationDeletePermission = false;
      for (let domainObjectKey in getDomainObjectPermission) {
        if (domainObjectKey.toLowerCase() === 'appointment') {
          let appointmentPermission = getDomainObjectPermission[domainObjectKey];
          let getPermission = Utils.getDomainObjectRolePermission(appointmentPermission);
          getCreatePermission = getPermission[0];
          getReadPermission = getPermission[1];
          getUpdatePermission = getPermission[2];
          getDeletePermission = getPermission[3];

          showAppointmentPermission = true;
          getCreatePermission = getCreatePermission;
          getReadPermission = getReadPermission;
          getUpdatePermission = getUpdatePermission;
          getDeletePermission = getDeletePermission;
        } else if (domainObjectKey.toLowerCase() === 'appointmentnote') {
          let appointmentNotePermission = getDomainObjectPermission[domainObjectKey];

          if (appointmentNotePermission.length > 0 && appointmentNotePermission !== null) {
            showAppointmentNotePermission = true;
          }
          let getPermission = Utils.getDomainObjectRolePermission(appointmentNotePermission);
          getCreatePermission = getPermission[0];
          getReadPermission = getPermission[1];
          getUpdatePermission = getPermission[2];
          getDeletePermission = getPermission[3];

          getAppointmentNoteCreatePermission = getCreatePermission;
          getAppointmentNoteReadPermission = getReadPermission;
          getAppointmentNoteUpdatePermission = getUpdatePermission;
          getAppointmentNoteDeletePermission = getDeletePermission;
        } else if (domainObjectKey.toLowerCase() === 'appointmenttransportation') {
          let appointmentTransportationPermission = getDomainObjectPermission[domainObjectKey];

          if (appointmentTransportationPermission.length > 0 && appointmentTransportationPermission !== null) {
            showAppointmentTransportationPermission = true;
          }
          let getPermission = Utils.getDomainObjectRolePermission(appointmentTransportationPermission);
          getCreatePermission = getPermission[0];
          getReadPermission = getPermission[1];
          getUpdatePermission = getPermission[2];
          getDeletePermission = getPermission[3];

          getAppointmentTransportationCreatePermission = getCreatePermission;
          getAppointmentTransportationReadPermission = getReadPermission;
          getAppointmentTransportationUpdatePermission = getUpdatePermission;
          getAppointmentTransportationDeletePermission = getDeletePermission;
        }
      }

      let previousAppointmentId = createdAppointmentData.appointmentWithPreviousAppointment.previousAppointmentId;
      let newStartDate = Utils.convertUTCDateToUserTimeZoneDate(appointmentData.date);
      let date = new Date(appointmentData.date).toISOString();
      let viewStartDate = Utils.convertUTCDateToUserTimeZoneViewDateFormate(appointmentData.date);

      let doctorView = false;
      let getPreviousAppointment = true;

      if (previousAppointmentId === null) {
        getPreviousAppointment = false;
      }
      if (appointmentData.type.type === 'Doctor Appointment') {
        this.state = {
          viewFacilityCard: false,
          id: appointmentData.id,
          action: 'view',
          viewDoctorCard: true,
          isAppointmentDoctorCardView: true,
          isAppointmentFacilityCardView: false,
          createSimilarAppointmentDetails: appointmentData,
          appointmentDoctor: appointmentData.doctorLocation.doctor,
          appoinmentId: appointmentData.id,
          startDate: new Date(newStartDate),
          appointmentStartDate: viewStartDate,
          preApptId: previousAppointmentId,
          timeView: appointmentData.dateTime,
          appointmentType: appointmentData.type.type,
          render: true,
          isDoctor: true,
          preDescription: appointmentData.appointmentPreNote,
          description: appointmentData.description,
          appoiPurpose: appointmentData.purpose,
          appointmentNoteList: appointmentData.noteList,
          appointmentTaskList: [],
          reminderItems: [],
          expenseitems: [],
          isEditButtonHide: isEditButtonHide,
          doctorRow: doctorView,
          doctor: appointmentData.doctorLocation.doctor,
          doctorLocation: appointmentData.doctorLocation,
          appointmentStatus: appointmentData.status.status,
          previousButton: getPreviousAppointment,
          appointmentTaskFacilityList: this.appointmentTaskFacilityList,
          locationWebsite: appointmentData.doctorLocation.website,
          locationPhone: Utils.displayUsaPhoneNumberFormate(appointmentData.doctorLocation.phone),
          locationFax: appointmentData.doctorLocation.fax,
          locationCity: appointmentData.doctorLocation.address.city,
          locationLine: appointmentData.doctorLocation.address.line,
          locationState: appointmentData.doctorLocation.address.state.name,
          locationCountry: appointmentData.doctorLocation.address.state.country.name,
          locationZipCode: appointmentData.doctorLocation.address.zipCode,
          facilityTransportationAddress: appointmentData.doctorLocation.address,
          appointmentTransportationAddress: appointmentData.doctorLocation.address,
          transportationList: appointmentData.transportationList,
          locationNickName: appointmentData.doctorLocation.nickName,
          line: appointmentData.doctorLocation.address.line,
          city: appointmentData.doctorLocation.address.city,
          state: appointmentData.doctorLocation.address.state.code,
          country: appointmentData.doctorLocation.address.state.country.code,
          pincode: appointmentData.doctorLocation.address.zipCode,
          referenceAppointmentReminderItems: [],
          getUpdatePermission: getUpdatePermission,
          getDeletePermission: getDeletePermission,
          getReadPermission: getReadPermission,
          getCreatePermission: getCreatePermission,
          isViewButtonHide: isViewButtonHide,
          isTransportationChecked: isTransportationChecked,
          doctorViewId: doctorViewId,
          facilityViewId: facilityViewId,
          currentCompareDateTime: new Date(),
          openCancelAppointment: false,
          stepsEnabled: false,
          initialStep: 0,
          displayFloatButton: this.displayHelp,
          showMapView: false,
          showLocationView: true,
          showCreateFacilityModal: false,
          showCreateDoctorModal: false,
          showAppointmentNotePermission: showAppointmentNotePermission,
          getAppointmentNoteCreatePermission: getAppointmentNoteCreatePermission,
          getAppointmentNoteReadPermission: getAppointmentNoteReadPermission,
          getAppointmentNoteUpdatePermission: getAppointmentNoteUpdatePermission,
          getAppointmentNoteDeletePermission: getAppointmentNoteDeletePermission,
          showAppointmentTransportationPermission: showAppointmentTransportationPermission,
          getAppointmentTransportationCreatePermission: getAppointmentTransportationCreatePermission,
          getAppointmentTransportationReadPermission: getAppointmentTransportationReadPermission,
          getAppointmentTransportationUpdatePermission: getAppointmentTransportationUpdatePermission,
          getAppointmentTransportationDeletePermission: getAppointmentTransportationDeletePermission,
        };
      } else {
        this.state = {
          action: 'view',
          id: appointmentData.id,
          createSimilarAppointmentDetails: appointmentData,
          appoinmentId: appointmentData.id,
          startDate: new Date(newStartDate),
          appointmentStartDate: viewStartDate,
          preApptId: previousAppointmentId,
          timeView: appointmentData.dateTime,
          appointmentType: appointmentData.type.type,
          render: true,
          isDoctor: false,
          isFacility: true,
          description: appointmentData.description,
          appoiPurpose: appointmentData.purpose,
          appointmentNoteList: appointmentData.noteList,
          appointmentTaskList: [],
          reminderItems: [],
          expenseitems: [],
          viewFacilityCard: true,
          viewDoctorCard: false,
          isAppointmentDoctorCardView: false,
          isAppointmentFacilityCardView: true,
          facilityLocation: appointmentData.facilityLocation.facility,
          facilityName: appointmentData.facilityLocation.facility.name,
          viewFacilityType: appointmentData.facilityLocation.facility.type,
          facilityDesc: appointmentData.facilityLocation.facility.description,
          appointmentStatus: appointmentData.status.status,
          previousButton: getPreviousAppointment,
          appointmentTaskFacilityList: this.appointmentTaskFacilityList,
          facilityAppointmentLocation: appointmentData.facilityLocation,
          appointmentTransportationAddress: appointmentData.facilityLocation.address,
          transportationList: appointmentData.transportationList,
          referenceAppointmentReminderItems: [],
          isEditButtonHide: isEditButtonHide,
          getUpdatePermission: getUpdatePermission,
          getDeletePermission: getDeletePermission,
          getReadPermission: getReadPermission,
          getCreatePermission: getCreatePermission,
          isViewButtonHide: isViewButtonHide,
          isTransportationChecked: isTransportationChecked,
          doctorViewId: doctorViewId,
          facilityViewId: facilityViewId,
          currentCompareDateTime: new Date(),
          openCancelAppointment: false,
          stepsEnabled: false,
          initialStep: 0,
          displayFloatButton: this.displayHelp,
          showMapView: false,
          showLocationView: true,
          showCreateFacilityModal: false,
          showCreateDoctorModal: false,
          showAppointmentNotePermission: showAppointmentNotePermission,
          getAppointmentNoteCreatePermission: getAppointmentNoteCreatePermission,
          getAppointmentNoteReadPermission: getAppointmentNoteReadPermission,
          getAppointmentNoteUpdatePermission: getAppointmentNoteUpdatePermission,
          getAppointmentNoteDeletePermission: getAppointmentNoteDeletePermission,
          showAppointmentTransportationPermission: showAppointmentTransportationPermission,
          getAppointmentTransportationCreatePermission: getAppointmentTransportationCreatePermission,
          getAppointmentTransportationReadPermission: getAppointmentTransportationReadPermission,
          getAppointmentTransportationUpdatePermission: getAppointmentTransportationUpdatePermission,
          getAppointmentTransportationDeletePermission: getAppointmentTransportationDeletePermission,
        };
      }
    } else {
      this.state = {
        id: appointmentId,
        action: action,
        render: canRender,
        appoinmentId: '',
        noteId: appointmentNoteId,
        preApptId: '',
        startDate: new Date(calenderStartDate),
        time: format(new Date(), 'hh-mm'),
        appointmentType: '',
        frequency: '',
        preDescription: '',
        description: '',
        appoiPurpose: '',
        location: '',
        title: title,
        pageId: pageId,
        doctor: {},
        memberId: this.memberDetails.member,
        appointmentNoteList: [],
        childAppointmentList: [],
        reminderItems: [],
        facilityTaskReminderItems: [],
        transportationReminder: [],
        appointmentNoteId: '',
        taskNote: '',
        taskType: '',
        appointmentReference: '',
        taskOccurrence: '',
        appointmentTaskList: [],
        appointmentReminderList: [],
        createAppointmentModal: false,
        appointmentTaskReminderList: [],
        appointmentTaskId: '',
        noteDate: '',
        previousButton: true,
        appointmentDoctor: {},
        taskReminderListDiv: true,
        appointmentNoteCreateList: [],
        taskNoteListRow: true,
        taskCreateNoteListRow: false,
        appointmentTaskFacilityList: [],
        doctorLocationList: doctorLocationListArray,
        isDoctorLocation: false,
        taskFacilityEditRow: false,
        isButtonHide: isButtonHide,
        isViewButtonHide: isViewButtonHide,
        isEditButtonHide: isEditButtonHide,
        isPrintView: isPrintView,
        facilityReminderRow: true,
        selectedAppointments: [],
        transportationModal: false,
        isTransportationChecked: isTransportationChecked,
        address: {},
        transportationType: '',
        line: '',
        country: this.defaultCountry,
        state: null,
        city: '',
        pincode: '',
        transportationList: [],
        transportationDesc: '',
        transportationStartDate: format(new Date(), 'yyyy-MM-dd'),
        transportationTime: format(new Date(), 'hh-mm'),
        contactMemberName: '',
        appointmentAlert: false,
        facilityId: '',
        facilityTransportationList: [],
        facilityTransportationAddress: {},
        appointmentTransportation: {},
        appointmentTransportationAddress: {},
        facility: {},
        isFacility: false,
        facilityLocationList: facilityLocationListArray,
        facilityLocation: {},
        viewDoctorCard: false,
        facilityName: '',
        facilityDesc: '',
        facilityAppointmentLocation: [],
        viewFacilityType: '',
        doctorLocation: '',
        isAppointmentDoctorCardView: false,
        isAppointmentFacilityCardView: false,
        isCreateDoctor: false,
        isCreateFacility: false,
        referenceAppointmentStartDate: new Date(),
        referenceAppointmentType: '',
        referenceAppointmentDescription: '',
        referenceAppointmentAppoiPurpose: '',
        referenceAppointmentDoctor: '',
        referenceAppointmentReminderItems: [],
        isDoctorReferenceAppointment: false,
        isFacilityReferenceAppointment: false,
        selectedDoctorLocation: '',
        listModal: false,
        currentDateTime: new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000),
        currentCompareDateTime: new Date(),
        createSimilarAppointmentDetails: {},
        getUpdatePermission: false,
        getDeletePermission: false,
        getReadPermission: false,
        getCreatePermission: false,
        isFreeUser: this.isFreeUser,
        doctorViewId: doctorViewId,
        facilityViewId: facilityViewId,
        openCancelAppointment: false,
        stepsEnabled: false,
        initialStep: 0,
        displayFloatButton: this.displayHelp,
        showMapView: false,
        showLocationView: true,
        showCreateFacilityModal: false,
        showCreateDoctorModal: false,
        showAppointmentNotePermission: false,
        getAppointmentNoteCreatePermission: false,
        getAppointmentNoteReadPermission: false,
        getAppointmentNoteUpdatePermission: false,
        getAppointmentNoteDeletePermission: false,
        showAppointmentTransportationPermission: false,
        getAppointmentTransportationCreatePermission: false,
        getAppointmentTransportationReadPermission: false,
        getAppointmentTransportationUpdatePermission: false,
        getAppointmentTransportationDeletePermission: false,
      };
    }

    this.d = new Date();
    this.selectedMonth = moment(this.d);
  }

  toggleSteps = () => {
    this.setState((prevState) => ({ stepsEnabled: !prevState.stepsEnabled }));
  };
  closeFloatingButton() {
    this.setState({ displayFloatButton: false });
    let displayFloat = false;

    store.dispatch(helpDataAction.setHelpData(displayFloat));
  }
  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };
  /* Appointment details data */
  async getAppointmentDetails(appointmentId) {
    this.noteListArray = [];
    this.appointmentTaskFacilityList = [];
    this.appointmentFacilityList = [];

    let id = appointmentId;
    const postObject = {
      header: this.header,
      appointment: {
        id: id,
      },
    };

    await FetchServerData.callPostService('/appointmentms/getAppointmentWithPreviousAppointment/', postObject).then(
      async (output) => {
        if (output.status === 'SUCCESS') {
          let appointmentData = output.data.appointmentWithPreviousAppointment.appointment;
          let getDomainObjectPermission = output.data.domainObjectPrivileges;
          let getCreatePermission = false;
          let getReadPermission = false;
          let getUpdatePermission = false;
          let getDeletePermission = false;
          let appointmentSyncDetails = {
            // type: this.state.appointmentType,
            // name: this.state.facilityName,
            date: appointmentData.date,
            // location: facilityLocation,
          };

          // store.dispatch(CalenderSyncDetailsAction.setCalenderSyncDetails(appointmentSyncDetails));
          // store.dispatch(CalenderSyncDetailsAction.setCalenderSyncDetails(appointmentData));

          for (let domainObjectKey in getDomainObjectPermission) {
            if (domainObjectKey.toLowerCase() === 'appointment') {
              let appointmentPermission = getDomainObjectPermission[domainObjectKey];
              let getPermission = Utils.getDomainObjectRolePermission(appointmentPermission);
              getCreatePermission = getPermission[0];
              getReadPermission = getPermission[1];
              getUpdatePermission = getPermission[2];
              getDeletePermission = getPermission[3];

              this.setState({
                showAppointmentPermission: true,
                getCreatePermission: getCreatePermission,
                getReadPermission: getReadPermission,
                getUpdatePermission: getUpdatePermission,
                getDeletePermission: getDeletePermission,
              });
            } else if (domainObjectKey.toLowerCase() === 'appointmentnote') {
              let appointmentNotePermission = getDomainObjectPermission[domainObjectKey];

              let showAppointmentNotePermission = false;
              if (appointmentNotePermission.length > 0 && appointmentNotePermission !== null) {
                showAppointmentNotePermission = true;
              }
              let getPermission = Utils.getDomainObjectRolePermission(appointmentNotePermission);
              getCreatePermission = getPermission[0];
              getReadPermission = getPermission[1];
              getUpdatePermission = getPermission[2];
              getDeletePermission = getPermission[3];
              this.setState({
                showAppointmentNotePermission: showAppointmentNotePermission,
                getAppointmentNoteCreatePermission: getCreatePermission,
                getAppointmentNoteReadPermission: getReadPermission,
                getAppointmentNoteUpdatePermission: getUpdatePermission,
                getAppointmentNoteDeletePermission: getDeletePermission,
              });
            } else if (domainObjectKey.toLowerCase() === 'appointmenttransportation') {
              let appointmentTransportationPermission = getDomainObjectPermission[domainObjectKey];
              let showAppointmentTransportationPermission = false;
              if (appointmentTransportationPermission.length > 0 && appointmentTransportationPermission !== null) {
                showAppointmentTransportationPermission = true;
              }
              let getPermission = Utils.getDomainObjectRolePermission(appointmentTransportationPermission);
              getCreatePermission = getPermission[0];
              getReadPermission = getPermission[1];
              getUpdatePermission = getPermission[2];
              getDeletePermission = getPermission[3];
              this.setState({
                showAppointmentTransportationPermission: showAppointmentTransportationPermission,
                getAppointmentTransportationCreatePermission: getCreatePermission,
                getAppointmentTransportationReadPermission: getReadPermission,
                getAppointmentTransportationUpdatePermission: getUpdatePermission,
                getAppointmentTransportationDeletePermission: getDeletePermission,
              });
            }
          }

          let isTransportationChecked = true;
          store.dispatch(appointmentDataAction.setAppointment(appointmentData));
          let previousAppointmentId = output.data.appointmentWithPreviousAppointment.previousAppointmentId;
          let appointmentTaskList = appointmentData.appointmentTakList;
          if (appointmentTaskList.length > 0) {
            for (let i = 0; i < appointmentTaskList.length; i++) {
              if (appointmentTaskList[i].type.type === 'Note') {
                this.noteListArray.push(appointmentTaskList[i]);
              } else if (appointmentTaskList[i].type.type === 'Facility') {
                this.appointmentTaskFacilityList.push(appointmentTaskList[i]);
                this.setState({
                  appointmentTaskReminderList: appointmentTaskList[i].reminderList,
                });
              } else {
                this.appointmentFacilityList.push(appointmentTaskList[i]);
              }
            }
          }

          if (appointmentData) {
            isTransportationChecked = appointmentData.isTransportation;
          }
          let viewStartDate = Utils.convertUTCDateToUserTimeZoneViewDateFormate(appointmentData.date);
          let newStartDate = Utils.convertUTCDateToUserTimeZoneDate(appointmentData.date);
          let doctorView = false;
          let getPreviousAppointment = true;
          if (previousAppointmentId === null) {
            getPreviousAppointment = false;
          }

          if (appointmentData.transportationList !== null && appointmentData.transportationList.length > 0) {
            for (let i = 0; i < appointmentData.transportationList.length; i++) {
              let ref = React.createRef();
              this.appointmentReminderRefList.push(ref);
              /*Set Default Operation as Update */
              appointmentData.transportationList[i].transportationId = i;
            }
          }
          let googleLocationAPIKey = Utils.getLocationAPIKey();

          Geocode.setApiKey(googleLocationAPIKey);
          Geocode.setLanguage('en');
          Geocode.setLocationType('ROOFTOP');
          let locationDetails = [];

          let locaData1 = [];
          if (appointmentData.type.type === 'Doctor Appointment') {
            locationDetails.push(appointmentData.doctorLocation);
            for (let i = 0; i < locationDetails.length; i++) {
              let locdata = await Utils.getLatLongMapData(
                locationDetails[i].shortDescription,
                locationDetails[i].address.line,
                locationDetails[i].address.city,
                locationDetails[i].address.state.country.name,
                locationDetails[i].address.state.name,
                i
              );
              // if (locdata !== {}) {
              locaData1.push(locdata);
              // }
            }
          } else {
            locationDetails.push(appointmentData.facilityLocation);
            for (let i = 0; i < locationDetails.length; i++) {
              let locdata = await Utils.getLatLongMapData(
                locationDetails[i].shortDescription,
                locationDetails[i].address.line,
                locationDetails[i].address.city,
                locationDetails[i].address.state.country.name,
                locationDetails[i].address.state.name,
                i
              );

              // if (locdata !== {}) {
              locaData1.push(locdata);
              // }
            }
          }

          if (appointmentData.type.type === 'Doctor Appointment') {
            if (!appointmentData.reminderList || appointmentData.appointmentTakList !== null) {
              for (let i = 0; i < appointmentData.reminderList.length; i++) {
                let ref = React.createRef();
                this.appointmentReminderRefList.push(ref);
                /*Set Default Operation as Update */
                appointmentData.reminderList[i].operation = 'update';
              }
              this.setState({
                render: true,
                isDoctor: true,
                createSimilarAppointmentDetails: appointmentData,
                appointmentDoctor: appointmentData.doctorLocation.doctor,
                appoinmentId: appointmentData.id,
                preApptId: previousAppointmentId,
                startDate: new Date(newStartDate),
                appointmentStartDate: viewStartDate,
                timeView: appointmentData.dateTime,
                appointmentType: appointmentData.type.type,
                preDescription: appointmentData.appointmentPreNote,
                description: appointmentData.description,
                appoiPurpose: appointmentData.purpose,
                location: appointmentData.location,
                childAppointmentList: appointmentData.childAppointmentList,
                appointmentNoteList: appointmentData.noteList,
                appointmentTaskList: this.appointmentFacilityList,
                reminderItems: appointmentData.reminderList,
                expenseitems: appointmentData.reminderList,
                viewFacilityCard: false,
                viewDoctorCard: true,
                isAppointmentDoctorCardView: true,
                isAppointmentFacilityCardView: false,
                doctorRow: doctorView,
                appointmentStatus: appointmentData.status.status,
                previousButton: getPreviousAppointment,
                appointmentTaskFacilityList: this.appointmentTaskFacilityList,
                doctor: appointmentData.doctorLocation.doctor,
                doctorLocation: appointmentData.doctorLocation,
                locationWebsite: appointmentData.doctorLocation.website,
                locationPhone: Utils.displayUsaPhoneNumberFormate(appointmentData.doctorLocation.phone),
                addressLocations: locaData1,
                locationFax: appointmentData.doctorLocation.fax,
                locationCity: appointmentData.doctorLocation.address.city,
                locationLine: appointmentData.doctorLocation.address.line,
                locationState: appointmentData.doctorLocation.address.state.name,
                locationCountry: appointmentData.doctorLocation.address.state.country.name,
                locationZipCode: appointmentData.doctorLocation.address.zipCode,
                selectedDoctorLocation: appointmentData.doctorLocation,
                isDoctorLocation: true,
                facilityTransportationAddress: appointmentData.doctorLocation.address,
                appointmentTransportationAddress: appointmentData.doctorLocation.address,
                transportationList: appointmentData.transportationList,
                locationNickName: appointmentData.doctorLocation.nickName,
                line: appointmentData.doctorLocation.address.line,
                city: appointmentData.doctorLocation.address.city,
                state: appointmentData.doctorLocation.address.state.code,
                country: appointmentData.doctorLocation.address.state.country.code,
                pincode: appointmentData.doctorLocation.address.zipCode,
                isTransportationChecked: isTransportationChecked,
              });
            } else {
              this.setState({
                createSimilarAppointmentDetails: appointmentData,
                appointmentDoctor: appointmentData.doctorLocation.doctor,
                appoinmentId: appointmentData.id,
                startDate: new Date(newStartDate),
                appointmentStartDate: viewStartDate,
                preApptId: previousAppointmentId,
                timeView: appointmentData.dateTime,
                appointmentType: appointmentData.type.type,
                render: true,
                isDoctor: true,
                preDescription: appointmentData.appointmentPreNote,
                description: appointmentData.description,
                appoiPurpose: appointmentData.purpose,
                location: appointmentData.location,
                childAppointmentList: appointmentData.childAppointmentList,
                appointmentNoteList: appointmentData.noteList,
                appointmentTaskList: [],
                reminderItems: [],
                expenseitems: appointmentData.reminderList,

                viewFacilityCard: false,
                viewDoctorCard: true,
                isAppointmentDoctorCardView: true,
                isAppointmentFacilityCardView: false,
                doctorRow: doctorView,
                doctor: appointmentData.doctorLocation.doctor,
                doctorLocation: appointmentData.doctorLocation,
                appointmentStatus: appointmentData.status.status,
                previousButton: getPreviousAppointment,
                appointmentTaskFacilityList: this.appointmentTaskFacilityList,
                locationWebsite: appointmentData.doctorLocation.website,
                locationPhone: Utils.displayUsaPhoneNumberFormate(appointmentData.doctorLocation.phone),
                addressLocations: locaData1,
                selectedDoctorLocation: appointmentData.doctorLocation,
                isDoctorLocation: true,
                locationFax: appointmentData.doctorLocation.fax,
                locationCity: appointmentData.doctorLocation.address.city,
                locationLine: appointmentData.doctorLocation.address.line,
                locationState: appointmentData.doctorLocation.address.state.name,
                locationCountry: appointmentData.doctorLocation.address.state.country.name,
                locationZipCode: appointmentData.doctorLocation.address.zipCode,
                facilityTransportationAddress: appointmentData.doctorLocation.address,
                appointmentTransportationAddress: appointmentData.doctorLocation.address,
                transportationList: appointmentData.transportationList,
                locationNickName: appointmentData.doctorLocation.nickName,
                line: appointmentData.doctorLocation.address.line,
                city: appointmentData.doctorLocation.address.city,
                state: appointmentData.doctorLocation.address.state.code,
                country: appointmentData.doctorLocation.address.state.country.code,
                pincode: appointmentData.doctorLocation.address.zipCode,
                isTransportationChecked: isTransportationChecked,
              });
            }
            this.getDoctorDetails();
            // this.getDoctorLocationList();
          } else {
            if (!appointmentData.reminderList || appointmentData.appointmentTakList !== null) {
              for (let i = 0; i < appointmentData.reminderList.length; i++) {
                let ref = React.createRef();
                this.appointmentReminderRefList.push(ref);
                /*Set Default Operation as Update */
                appointmentData.reminderList[i].operation = 'update';
              }

              this.setState({
                createSimilarAppointmentDetails: appointmentData,
                render: true,
                isDoctor: false,
                isFacility: true,
                appoinmentId: appointmentData.id,
                preApptId: previousAppointmentId,
                startDate: new Date(newStartDate),
                appointmentStartDate: viewStartDate,
                timeView: appointmentData.dateTime,
                appointmentType: appointmentData.type.type,
                preDescription: appointmentData.appointmentPreNote,
                description: appointmentData.description,
                appoiPurpose: appointmentData.purpose,
                location: appointmentData.location,
                childAppointmentList: appointmentData.childAppointmentList,
                appointmentNoteList: appointmentData.noteList,
                appointmentTaskList: this.appointmentFacilityList,
                reminderItems: appointmentData.reminderList,
                expenseitems: appointmentData.reminderList,
                viewFacilityCard: true,
                viewDoctorCard: false,
                isAppointmentDoctorCardView: false,
                isAppointmentFacilityCardView: true,
                facilityLocation: appointmentData.facilityLocation.facility,
                facilityName: appointmentData.facilityLocation.facility.name,
                viewFacilityType: appointmentData.facilityLocation.facility.type,
                facilityDesc: appointmentData.facilityLocation.facility.description,
                appointmentStatus: appointmentData.status.status,
                previousButton: getPreviousAppointment,
                appointmentTaskFacilityList: this.appointmentTaskFacilityList,
                facilityAppointmentLocation: appointmentData.facilityLocation,
                selectedDoctorLocation: appointmentData.facilityLocation,
                isDoctorLocation: true,
                locationWebsite: appointmentData.facilityLocation.website,
                locationPhone: Utils.displayUsaPhoneNumberFormate(appointmentData.facilityLocation.phone),
                addressLocations: locaData1,
                locationFax: appointmentData.facilityLocation.fax,
                locationCity: appointmentData.facilityLocation.address.city,
                locationLine: appointmentData.facilityLocation.address.line,
                locationState: appointmentData.facilityLocation.address.state.name,
                locationCountry: appointmentData.facilityLocation.address.state.country.name,
                locationZipCode: appointmentData.facilityLocation.address.zipCode,
                facilityTransportationAddress: appointmentData.facilityLocation.address,
                appointmentTransportationAddress: appointmentData.facilityLocation.address,
                transportationList: appointmentData.transportationList,
                locationNickName: appointmentData.facilityLocation.nickName,
                line: appointmentData.facilityLocation.address.line,
                city: appointmentData.facilityLocation.address.city,
                state: appointmentData.facilityLocation.address.state.code,
                country: appointmentData.facilityLocation.address.state.country.code,
                pincode: appointmentData.facilityLocation.address.zipCode,
                isTransportationChecked: isTransportationChecked,
              });
            } else {
              this.setState({
                createSimilarAppointmentDetails: appointmentData,
                appoinmentId: appointmentData.id,
                startDate: new Date(newStartDate),
                appointmentStartDate: viewStartDate,
                preApptId: previousAppointmentId,
                timeView: appointmentData.dateTime,
                appointmentType: appointmentData.type.type,
                render: true,
                isDoctor: false,
                preDescription: appointmentData.appointmentPreNote,
                description: appointmentData.description,
                appoiPurpose: appointmentData.purpose,
                location: appointmentData.location,
                childAppointmentList: appointmentData.childAppointmentList,
                appointmentNoteList: appointmentData.noteList,
                appointmentTaskList: [],
                reminderItems: [],
                expenseitems: appointmentData.reminderList,
                viewFacilityCard: true,
                viewDoctorCard: false,
                isAppointmentDoctorCardView: false,
                isAppointmentFacilityCardView: true,
                facilityLocation: appointmentData.facilityLocation.facility,
                facilityName: appointmentData.facilityLocation.facility.name,
                viewFacilityType: appointmentData.facilityLocation.facility.type,
                facilityDesc: appointmentData.facilityLocation.facility.description,
                appointmentStatus: appointmentData.status.status,
                previousButton: getPreviousAppointment,
                appointmentTaskFacilityList: this.appointmentTaskFacilityList.id,
                facilityAppointmentLocation: appointmentData.facilityLocation,
                locationWebsite: appointmentData.facilityLocation.website,
                locationPhone: Utils.displayUsaPhoneNumberFormate(appointmentData.facilityLocation.phone),
                addressLocations: locaData1,
                selectedDoctorLocation: appointmentData.facilityLocation,
                isDoctorLocation: true,
                locationFax: appointmentData.facilityLocation.fax,
                locationCity: appointmentData.facilityLocation.address.city,
                locationLine: appointmentData.facilityLocation.address.line,
                locationState: appointmentData.facilityLocation.address.state.name,
                locationCountry: appointmentData.facilityLocation.address.state.country.name,
                locationZipCode: appointmentData.facilityLocation.address.zipCode,
                facilityTransportationAddress: appointmentData.facilityLocation.address,
                appointmentTransportationAddress: appointmentData.facilityLocation.address,
                transportationList: appointmentData.transportationList,
                locationNickName: appointmentData.facilityLocation.nickName,
                line: appointmentData.facilityLocation.address.line,
                city: appointmentData.facilityLocation.address.city,
                state: appointmentData.facilityLocation.address.state.code,
                country: appointmentData.facilityLocation.address.state.country.code,
                pincode: appointmentData.facilityLocation.address.zipCode,
                isTransportationChecked: isTransportationChecked,
              });
            }
            this.getFacilityDetails();
          }
        } else {
        }
      }
    );
  }

  showMap() {
    this.setState({
      showMapView: true,
      showLocationView: false,
    });
  }

  closeButton() {
    this.setState({
      showMapView: false,
      showLocationView: true,
    });
  }

  getMapView(locations) {
    return <Map data={locations}></Map>;
  }

  /* Doctor location list */
  getDoctorLocationList() {
    const postObject = {
      header: this.header,
      doctor: {
        member: {
          id: this.memberDetails.member,
        },
      },
    };

    FetchServerData.callPostService('appointmentms/getMemberDoctorLocations', postObject).then((output) => {
      if (Utils.equalsIgnoreCase(output.status, 'SUCCESS')) {
        let doctorLocationList = output.data;
        if (doctorLocationList.length > 0) {
          // if (doctorLocationList !== []) {
          doctorLocationList.unshift({ name: 'Add New Doctor', id: 'Add New Doctor' });
          // }
          let doctorLocationListArray = doctorLocationList.reduce((unique, o) => {
            if (!unique.some((obj) => obj.name === o.name && obj.id === o.id)) {
              unique.push(o);
            }
            return unique;
          }, []);
          this.setState({
            isDoctor: true,

            isFacility: false,
            doctorLocationList: doctorLocationListArray,
          });
        } else {
          this.setState({
            showCreateDoctorModal: true,
            isCreateDoctor: true,
            isCreateFacility: false,
          });
        }
      } else if (Utils.equalsIgnoreCase(output.status, 'FAIL')) {
        if (output.errorCode && Utils.equalsIgnoreCase(output.errorCode, 'DOC_100')) {
          /* doctor is Not created, Go To doctor create Page */
          this.setState({
            showCreateDoctorModal: true,
            isCreateDoctor: true,
            isCreateFacility: false,
          });
        }
      }
    });
  }

  getDoctorDetails() {
    const postObject = {
      header: this.header,
      doctor: {
        member: {
          id: this.memberDetails.member,
        },
      },
    };

    FetchServerData.callPostService('appointmentms/getMemberDoctorLocations', postObject).then((output) => {
      if (Utils.equalsIgnoreCase(output.status, 'SUCCESS')) {
        let doctorLocationList = output.data;
        if (doctorLocationList.length > 0) {
          // if (doctorLocationList !== []) {
          doctorLocationList.unshift({ name: 'Add New Doctor', id: 'Add New Doctor' });
          // }
          let doctorLocationListArray = doctorLocationList.reduce((unique, o) => {
            if (!unique.some((obj) => obj.name === o.name && obj.id === o.id)) {
              unique.push(o);
            }
            return unique;
          }, []);

          for (let i = 0; i < doctorLocationListArray.length; i++) {
            if (doctorLocationListArray[i].locationId === this.state.doctorLocation.id) {
              this.setState({
                isDoctor: true,
                isFacility: false,

                doctor: doctorLocationListArray[i],

                doctorLocationList: doctorLocationListArray,
                selectedDoctorLocation: doctorLocationListArray,
              });
              this.getDoctorLocation(doctorLocationListArray[i].locationId, doctorLocationListArray[i].doctorId);
            } else {
              let selectedDoctor = this.state.doctor;
              this.setState({
                isDoctor: true,
                isFacility: false,

                doctor: selectedDoctor,

                doctorLocationList: doctorLocationListArray,
                selectedDoctorLocation: doctorLocationListArray,
              });
              // this.getDoctorLocation(this.state.doctorLocation.id, this.state.doctor.id);
            }
          }
        } else {
          this.setState({
            showCreateDoctorModal: true,
            isCreateDoctor: true,
            isCreateFacility: false,
          });
        }
      } else if (Utils.equalsIgnoreCase(output.status, 'FAIL')) {
        if (output.errorCode && Utils.equalsIgnoreCase(output.errorCode, 'DOC_100')) {
          /* doctor is Not created, Go To doctor create Page */
          this.setState({
            showCreateDoctorModal: true,
            isCreateDoctor: true,
            isCreateFacility: false,
          });
        }
      }
    });
  }

  getFacilityDetails() {
    const postObject = {
      header: this.header,
      facility: {
        member: {
          id: this.memberDetails.member,
        },
      },
    };
    FetchServerData.callPostService('/appointmentms/getMemberFacilityLocations/', postObject).then((output) => {
      if (Utils.equalsIgnoreCase(output.status, 'SUCCESS')) {
        let facilityLocationList = output.data;
        if (facilityLocationList.length > 0) {
          // if (facilityLocationList !== []) {
          facilityLocationList.unshift({ name: 'Add New Facility', id: 'Add New Facility' });
          // }
          let facilityLocationListArray = facilityLocationList.reduce((unique, o) => {
            if (!unique.some((obj) => obj.name === o.name && obj.id === o.id)) {
              unique.push(o);
            }
            return unique;
          }, []);

          for (let i = 0; i < facilityLocationListArray.length; i++) {
            if (facilityLocationListArray[i].locationId === this.state.facilityAppointmentLocation.id) {
              this.setState({
                isFacility: true,
                isDoctor: false,
                selectedDoctorLocation: facilityLocationListArray,
                facilityLocationList: facilityLocationListArray,
                facilityLocation: facilityLocationListArray[i],
                newFacilityLocation: facilityLocationListArray[i],
              });
              this.getFacilityLocation(
                facilityLocationListArray[i].locationId,
                facilityLocationListArray[i].facilityId
              );
            } else {
              this.setState({
                isFacility: true,
                isDoctor: false,
                selectedDoctorLocation: facilityLocationListArray,
                facilityLocationList: facilityLocationListArray,
                // facilityLocation: facilityLocationListArray[i],
                newFacilityLocation: facilityLocationListArray[i],
              });
            }
          }
        } else {
          this.setState({
            showCreateFacilityModal: true,
            isCreateFacility: true,
            isCreateDoctor: false,
          });
        }
      } else if (Utils.equalsIgnoreCase(output.status, 'FAIL')) {
      }
    });
  }
  /* Facility location list */
  getFacilityLocationList() {
    const postObject = {
      header: this.header,
      facility: {
        member: {
          id: this.memberDetails.member,
        },
      },
    };
    FetchServerData.callPostService('/appointmentms/getMemberFacilityLocations/', postObject).then((output) => {
      if (Utils.equalsIgnoreCase(output.status, 'SUCCESS')) {
        let facilityLocationList = output.data;
        if (facilityLocationList.length > 0) {
          // if (facilityLocationList !== []) {
          facilityLocationList.unshift({ name: 'Add New Facility', id: 'Add New Facility' });
          // }
          let facilityLocationListArray = facilityLocationList.reduce((unique, o) => {
            if (!unique.some((obj) => obj.name === o.name && obj.id === o.id)) {
              unique.push(o);
            }
            return unique;
          }, []);
          this.setState({
            isFacility: true,
            isDoctor: false,

            facilityLocationList: facilityLocationListArray,
          });
        } else {
          this.setState({
            showCreateFacilityModal: true,
            isCreateFacility: true,
            isCreateDoctor: false,
          });
        }
      } else if (Utils.equalsIgnoreCase(output.status, 'FAIL')) {
      }
    });
  }

  toNewFacility(e) {
    this.props.history.push({
      pathname: '/addFacility',
      state: { action: 'create', title: 'Add Facility', pageId: 'addmemberFacility', facilityId: '' },
    });
  }
  toNewDoctor(e) {
    this.props.history.push({
      pathname: '/addDoctor',
      state: { action: 'create', title: 'Add Doctor', pageId: 'memberDoctor', doctorId: '' },
    });
  }
  createFacilityModalToggle(e) {
    this.setState({ showCreateFacilityModal: !this.state.showCreateFacilityModal });
  }

  createDoctorModalToggle(e) {
    this.setState({ showCreateDoctorModal: !this.state.showCreateDoctorModal });
  }
  handleAppointmentTypeChange(e) {
    if (e.target.value === 'Doctor Appointment') {
      this.setState({
        isDoctor: true,
        isFacility: false,
        isDoctorLocation: false,
        appointmentType: e.target.value,
      });
      this.getDoctorLocationList();
    } else {
      this.setState({
        isDoctor: false,
        isFacility: true,
        isDoctorLocation: false,
        appointmentType: e.target.value,
      });
      this.getFacilityLocationList();
    }
  }

  handleDoctorChange(doctor) {
    console.log('doctor', doctor);
    if (doctor !== null) {
      if (doctor.name === 'Add New Doctor') {
        this.props.history.push({
          pathname: '/addDoctor',
          state: { action: 'create', title: 'Add Doctor', pageId: 'memberDoctor', doctorId: '' },
        });
      } else {
        this.setState({ doctor: doctor, isDoctorLocation: false });
        if (doctor) {
          this.getDoctorLocation(doctor.locationId, doctor.doctorId);
        }

        this.state.doctor = doctor;
      }
    }
    if (undefined !== this.state.onChange && null !== this.state.onChange) {
      this.state.onChange(this.state);
    }
  }

  handleDoctorEditChange(e) {
    this.setState({ doctor: e.target.value });
  }

  /* On selecting appointment type doctor get the doctor location list */
  getDoctorLocation(locationId, doctorId) {
    const postObject = {
      header: this.header,
      doctorLocation: {
        id: locationId,
        doctor: {
          id: doctorId,
        },
      },
    };
    FetchServerData.callPostService('/doctorms/getLocation', postObject).then((output) => {
      if (Utils.equalsIgnoreCase(output.status, 'SUCCESS')) {
        let doctorLocation = output.data;
        this.setState({
          selectedDoctorLocation: doctorLocation,
          isDoctorLocation: true,
        });
      } else if (Utils.equalsIgnoreCase(output.status, 'FAIL')) {
      }
    });
  }

  isTransportationRequired(e) {
    const postObject = {
      header: this.header,

      appointment: {
        id: this.state.id,
        isTransportation: e.target.checked,
      },
    };
    FetchServerData.callPostService('/appointmentms/updateTrasporationFlag', postObject).then((output) => {
      if (Utils.equalsIgnoreCase(output.status, 'SUCCESS')) {
        let TransportationCheck = output.data;
        this.setState({
          isTransportationChecked: TransportationCheck.isTransportation,
        });
      } else if (Utils.equalsIgnoreCase(output.status, 'FAIL')) {
      }
    });
  }

  handleReferenceAppointmentDoctorChange(e) {
    this.setState({ referenceAppointmentDoctor: e.target.value });
  }
  handleReferenceAppointmentFacilityLocationChange(e) {
    this.setState({ referenceAppointmentFacilityLocation: e.target.value });
  }

  handleFacilityLocationChange(facility) {
    if (facility.name === 'Add New Facility') {
      this.props.history.push({
        pathname: '/addFacility',
        state: { action: 'create', title: 'Add Facility', pageId: 'addmemberFacility', facilityId: '' },
      });
    } else {
      this.setState({ facilityLocation: facility, isDoctorLocation: false });
      if (facility) {
        this.getFacilityLocation(facility.locationId, facility.facilityId);
      }
      this.state.facilityLocation = facility;
      if (undefined !== this.state.onChange && null !== this.state.onChange) {
        this.state.onChange(this.state);
      }
    }
  }

  handleFacilityLocationEditChange(e) {
    this.setState({ facilityLocation: e.target.value });
  }

  /* On selecting appointment type facility get the facility location list */
  getFacilityLocation(locationId, facilityId) {
    const postObject = {
      header: this.header,
      facilityLocation: {
        id: locationId,
        facility: {
          id: facilityId,
        },
      },
    };
    FetchServerData.callPostService('/facilityms/getLocation', postObject).then((output) => {
      if (Utils.equalsIgnoreCase(output.status, 'SUCCESS')) {
        let facilityLocation = output.data;

        this.setState({
          selectedDoctorLocation: facilityLocation,
          isDoctorLocation: true,
        });
      } else if (Utils.equalsIgnoreCase(output.status, 'FAIL')) {
      }
    });
  }

  handleStartDateChange(data) {
    this.setState({ startDate: data });
  }
  handleReferenceAppointmentStartDateChange(data) {
    this.setState({ referenceAppointmentStartDate: data });
  }

  handleDescriptionChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 256);
    if (res.length < 256) {
      this.setState({ description: res });
    } else {
      Utils.maxFieldLength(256);
    }
  }

  handleAppoiPurposeChange(e) {
    if (e.target.value === 'Other') {
      this.setState({
        isOther: true,
        appoiPurpose: e.target.value,
      });
    } else {
      this.setState({
        isOther: false,
        appoiPurpose: e.target.value,
      });
    }
  }

  handleTaskNoteChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 256);
    if (res.length < 256) {
      this.setState({ taskNote: res });
    } else {
      Utils.maxFieldLength(256);
    }
  }

  handleFacilityChange(e) {
    this.setState({ facility: e.target.value });
  }

  handleNoteDateChange(data) {
    this.setState({ noteDate: data });
  }

  handleTaskOccurrenceChange(e) {
    this.setState({ taskOccurrence: e.target.value });
  }

  handleAppointmentTransportationChange(inputTransportation) {
    this.setState({ appointmentTransportation: inputTransportation });
  }

  getStateList(id) {
    const postObject = this.header;
    let url = 'statems/findByCountry/';
    FetchServerData.callPostService(url + id, postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let stateList = output.data;
        this.stateListArray = stateList;
        this.setState({
          render: true,
        });
      } else {
      }
    });
  }

  transportationToggle = () => {
    this.setState({ transportationModal: !this.state.transportationModal });
  };

  /* delete Transportation Data In Backend */
  deleteTransportation = (transportationData) => {};

  /* add Transportation Data In Backend */
  addTransportationInBackend = (transportationData) => {
    let transportationReminder = [];
    let postObject = {};

    if (this.appointmentTransportationRef.current.state) {
      let reminderList = this.appointmentTransportationRef.current.state.transportationReminder;
      for (let i = 0; i < reminderList.length; i++) {
        let reminderDateTime = Utils.convertUserTimeZoneDateToUTCDate(reminderList[i].startDate);

        let reminderObj = {
          content: reminderList[i].note,
          date: reminderDateTime,

          operation: reminderList[i].operation,
        };
        transportationReminder.push(reminderObj);
      }

      let appointmentTransportationDate = this.appointmentTransportationRef.current.state.transportationDate;

      let dateTime = Utils.convertUserTimeZoneDateToUTCDate(appointmentTransportationDate);

      postObject = {
        header: this.header,

        transportation: {
          date: dateTime,

          description: this.appointmentTransportationRef.current.state.transportationDesc,
          accompany: this.appointmentTransportationRef.current.state.contactMemberName,
          address: {
            line: this.appointmentTransportationRef.current.state.line,
            city: this.appointmentTransportationRef.current.state.city,
            zipCode: this.appointmentTransportationRef.current.state.zipCode,
            state: {
              code: this.appointmentTransportationRef.current.state.state,
              id: this.appointmentTransportationRef.current.state.stateId,
            },
          },
          accompanyType: {
            type: this.appointmentTransportationRef.current.state.transportationType,
          },

          appointment: {
            id: this.state.id,
          },
          reminderList: transportationReminder,
        },
      };
    }

    FetchServerData.callPostService('/apptransportms/create', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let outputData = output.data;
          let transportation = this.state.transportationList;
          // this.getAppointmentDetails(this.state.id);
          transportation.push(outputData);
          PtsAlert.success(output.message);
          this.setState({
            transportationModal: false,
            transportationList: transportation,
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  /* update Transportation Data In Backend */
  updateTransportationInBackend = (transportationData) => {
    this.getAppointmentDetails(this.state.id);
  };

  /* remove the appointment Note*/
  appointmentNoteRemove(item) {
    let items = [];
    if (item.operation && 'update' === item.operation) {
      /* Existing Item Deleted, Mark it deleted */
      let index = item.index;

      for (let i = 0; i < this.state.appointmentNoteList.length; i++) {
        let item = this.state.appointmentNoteList[i];
        if (this.state.appointmentNoteList[i].operation === 'update' && index === i) {
          item.operation = 'delete';
        }

        items.push(item);
      }

      this.setState({ appointmentNoteList: items });
    }
    if (item.operation && 'add' === item.operation) {
      /* Added Item Deleted, Remove from list */
      let index = item.index;
      for (let i = 0; i < this.state.appointmentNoteList.length; i++) {
        if (this.state.appointmentNoteList[i].operation === 'add' && index === i) {
          this.appointmentNoteRefList.splice(i, 1);
          // continue;
        } else {
          items.push(this.state.appointmentNoteList[i]);
        }
      }
      this.setState({ appointmentNoteList: items });
    }

    /*Go Back to Location list view if all new Locations are deleted */
    let flag = true;
    for (let i = 0; i < items.length; i++) {
      if (items[i].operation === 'add') {
        flag = false;
      }
    }
    if (flag) {
      this.backToAppointmentNoteList();
    }
  }

  appointmentItemChanged(item) {}

  addAppointmentTask = (e) => {
    this.referenceAppointmentRef = React.createRef();
    this.setState({
      referenceAppointmentCreate: true,
    });
  };

  addAppointmentFacilityTask = (e) => {
    this.setState({
      taskFacilityAddRow: true,
      appointmentTaskReminderList: [],
      taskNote: '',
      taskType: '',
      appointmentReference: '',
      taskOccurrence: '',
      facility: '',
      noteDate: '',
    });
  };

  addTransportationRequest() {
    this.setState({
      transportationModal: true,
    });
  }

  /* add  Appointment Data In Backend */
  addAppointmentInBackend(e) {
    e.preventDefault();
    let parentId = localStorage.getItem('parentAppointmentId');

    let dateTime = Utils.convertUserTimeZoneDateToUTCDate(this.state.startDate);

    const postObject = {
      header: this.header,
      appointment: {
        date: dateTime,

        description: this.state.description,
        appointmentPreNote: this.state.preDescription,
        appointmentPurposeDescription: this.state.appoiPurposeDes,

        type: {
          type: this.state.appointmentType,
        },
        member: {
          id: this.memberDetails.member,
        },
        frequency: {
          frequency: this.state.frequency,
        },

        facilityLocation: {
          id: this.state.facilityLocation.locationId,
        },
        doctorLocation: {
          id: this.state.doctor.locationId,
        },
        parentAppointment: {
          id: parentId,
        },
        reminderList: [],
        purpose: this.state.appoiPurpose,
      },
    };

    FetchServerData.callPostService('/appointmentms/create', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let outputData = output.data;
          let appointmentDetails = outputData.appointmentWithPreviousAppointment.appointment;
          PtsAlert.success(outputData.message);

          let fccPageState = {
            id: appointmentDetails.id,
            action: 'view',
          };
          store.dispatch(fccPageStateAction.setFccPageState(fccPageState));

          if (this.state.pageId === 'addAppointment') {
            this.props.history.push({
              pathname: '/viewMemberAppointment',
              state: {
                appointmentData: outputData,
                pageId: 'createAppointment',
                action: 'view',
              },
            });
          } else if (this.state.pageId === 'memberdeatils') {
            this.props.history.push({
              pathname: '/memberDetails',
            });
          } else if (this.state.pageId === 'viewDoctorAppointment') {
            this.props.history.push({
              pathname: '/memberAppointment',
            });
          } else {
            this.setState({
              selectedAppointments: outputData.purpose + '-' + outputData.date,
              createAppointmentModal: false,
            });
          }
        } else if (Utils.equalsIgnoreCase(output.status, 'FAIL')) {
          if (output.errorCode && Utils.equalsIgnoreCase(output.errorCode, 'DuplicateAppointmentEvent')) {
            // PtsAlert.warn(output.message);
            // this.warningMessage(output.message)
            this.setState({
              sameDateAppointmentWindow: true,
              WarningMessage: output.message,
            });
          }
        } else {
        }
      })
      .catch((error) => {});
  }
  addSameAppointmentInBackend(e) {
    e.preventDefault();
    let parentId = localStorage.getItem('parentAppointmentId');

    let dateTime = Utils.convertUserTimeZoneDateToUTCDate(this.state.startDate);

    const postObject = {
      header: this.header,
      appointment: {
        date: dateTime,
        forceCreateOnSameDate: true,
        description: this.state.description,
        appointmentPreNote: this.state.preDescription,
        appointmentPurposeDescription: this.state.appoiPurposeDes,

        type: {
          type: this.state.appointmentType,
        },
        member: {
          id: this.memberDetails.member,
        },
        frequency: {
          frequency: this.state.frequency,
        },

        facilityLocation: {
          id: this.state.facilityLocation.locationId,
        },
        doctorLocation: {
          id: this.state.doctor.locationId,
        },
        parentAppointment: {
          id: parentId,
        },
        reminderList: [],
        purpose: this.state.appoiPurpose,
      },
    };

    FetchServerData.callPostService('/appointmentms/create', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let outputData = output.data;
          let appointmentDetails = outputData.appointmentWithPreviousAppointment.appointment;
          PtsAlert.success(outputData.message);

          let fccPageState = {
            id: appointmentDetails.id,
            action: 'view',
          };
          store.dispatch(fccPageStateAction.setFccPageState(fccPageState));

          if (this.state.pageId === 'addAppointment') {
            this.props.history.push({
              pathname: '/viewMemberAppointment',
              state: {
                appointmentData: outputData,
                pageId: 'createAppointment',
                action: 'view',
              },
            });
          } else if (this.state.pageId === 'memberdeatils') {
            this.props.history.push({
              pathname: '/memberDetails',
            });
          } else if (this.state.pageId === 'viewDoctorAppointment') {
            this.props.history.push({
              pathname: '/memberAppointment',
            });
          } else {
            this.setState({
              selectedAppointments: outputData.purpose + '-' + outputData.date,
              createAppointmentModal: false,
            });
          }
          this.setState({
            sameDateAppointmentWindow: false,
          });
        } else {
        }
      })
      .catch((error) => {});
  }
  /* update Reference Appointment Data In Backend */

  updateAppointmentInBackend = (e) => {
    let dateTime = Utils.convertUserTimeZoneDateToUTCDate(this.state.startDate);
    let doctorLocationId = '';
    let facilityLocationId = '';
    if (this.state.doctor) {
      doctorLocationId = this.state.doctor.locationId;
    }
    if (this.state.facilityLocation) {
      facilityLocationId = this.state.facilityLocation.locationId;
    }
    const postObject = {
      header: this.header,
      appointment: {
        id: this.state.id,
        date: dateTime,
        isTransportation: this.state.isTransportationChecked,
        description: this.state.description,
        appointmentPreNote: this.state.preDescription,
        appointmentPurposeDescription: this.state.appoiPurposeDes,
        type: {
          type: this.state.appointmentType,
        },
        member: {
          id: this.memberDetails.member,
        },
        frequency: {
          frequency: this.state.frequency,
        },

        facilityLocation: {
          id: facilityLocationId,
        },
        doctorLocation: {
          id: doctorLocationId,
        },
        reminderList: [],
        purpose: this.state.appoiPurpose,
      },
    };
    FetchServerData.callPostService('/appointmentms/update', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let appointmentData = output.data.appointment;
          let timeZone = store.getState().userData.userData.address.timezone;
          let utcCutoff = moment(appointmentData.date);
          // let viewStartDate = utcCutoff.tz(timeZone.name).format('DD MMM YYYY hh:mm a');
          let viewStartDate = Utils.convertUTCDateToUserTimeZoneViewDateFormate(appointmentData.date);
          let newStartDate = Utils.convertUTCDateToUserTimeZoneDate(appointmentData.date);
          PtsAlert.success(output.message);
          if (appointmentData.type.type === 'Doctor Appointment') {
            this.setState({
              isEditButtonHide: true,
              isButtonHide: false,
              startDate: new Date(newStartDate),
              appointmentStartDate: viewStartDate,
              timeView: appointmentData.dateTime,
              appointmentType: appointmentData.type.type,
              preDescription: appointmentData.appointmentPreNote,
              description: appointmentData.description,
              appoiPurpose: appointmentData.purpose,
              location: appointmentData.location,
              doctor: appointmentData.doctorLocation.doctor,
              doctorLocation: appointmentData.doctorLocation,
              appointmentDoctor: appointmentData.doctorLocation.doctor,
              action: 'view',
            });
            this.getDoctorDetails();
            // this.getDoctorLocationList();
          } else {
            this.setState({
              isEditButtonHide: true,
              isButtonHide: false,
              startDate: new Date(newStartDate),
              appointmentStartDate: viewStartDate,
              timeView: appointmentData.dateTime,
              appointmentType: appointmentData.type.type,
              facilityName: appointmentData.facilityLocation.facility.name,
              viewFacilityType: appointmentData.facilityLocation.facility.type,
              preDescription: appointmentData.appointmentPreNote,
              description: appointmentData.description,
              appoiPurpose: appointmentData.purpose,
              facilityAppointmentLocation: appointmentData.facilityLocation,
              action: 'view',
            });
            this.getFacilityDetails();
          }
        } else if (Utils.equalsIgnoreCase(output.status, 'FAIL')) {
          if (output.errorCode && Utils.equalsIgnoreCase(output.errorCode, 'DuplicateAppointmentEvent')) {
            // PtsAlert.warn(output.message);
            // this.warningMessage(output.message)
            this.setState({
              sameDateAppointmentWindow: true,
              WarningMessage: output.message,
            });
          }
        } else {
        }
      })
      .catch((error) => {});
  };
  updateSameAppointmentInBackend = (e) => {
    let dateTime = Utils.convertUserTimeZoneDateToUTCDate(this.state.startDate);
    let doctorLocationId = '';
    let facilityLocationId = '';
    if (this.state.doctor) {
      doctorLocationId = this.state.doctor.locationId;
    }
    if (this.state.facilityLocation) {
      facilityLocationId = this.state.facilityLocation.locationId;
    }
    const postObject = {
      header: this.header,
      appointment: {
        id: this.state.id,
        date: dateTime,
        forceCreateOnSameDate: true,
        isTransportation: this.state.isTransportationChecked,
        description: this.state.description,
        appointmentPreNote: this.state.preDescription,
        appointmentPurposeDescription: this.state.appoiPurposeDes,
        type: {
          type: this.state.appointmentType,
        },
        member: {
          id: this.memberDetails.member,
        },
        frequency: {
          frequency: this.state.frequency,
        },

        facilityLocation: {
          id: facilityLocationId,
        },
        doctorLocation: {
          id: doctorLocationId,
        },
        reminderList: [],
        purpose: this.state.appoiPurpose,
      },
    };
    FetchServerData.callPostService('/appointmentms/update', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let appointmentData = output.data.appointment;
          let timeZone = store.getState().userData.userData.address.timezone;
          let utcCutoff = moment(appointmentData.date);
          // let viewStartDate = utcCutoff.tz(timeZone.name).format('DD MMM YYYY hh:mm a');
          let viewStartDate = Utils.convertUTCDateToUserTimeZoneViewDateFormate(appointmentData.date);
          let newStartDate = Utils.convertUTCDateToUserTimeZoneDate(appointmentData.date);
          PtsAlert.success(output.message);
          if (appointmentData.type.type === 'Doctor Appointment') {
            this.setState({
              isEditButtonHide: true,
              isButtonHide: false,
              startDate: new Date(newStartDate),
              appointmentStartDate: viewStartDate,
              timeView: appointmentData.dateTime,
              appointmentType: appointmentData.type.type,
              preDescription: appointmentData.appointmentPreNote,
              description: appointmentData.description,
              appoiPurpose: appointmentData.purpose,
              location: appointmentData.location,
              doctor: appointmentData.doctorLocation.doctor,
              doctorLocation: appointmentData.doctorLocation,
              appointmentDoctor: appointmentData.doctorLocation.doctor,
              action: 'view',
            });
            this.getDoctorDetails();
            // this.getDoctorLocationList();
          } else {
            this.setState({
              isEditButtonHide: true,
              isButtonHide: false,
              startDate: new Date(newStartDate),
              appointmentStartDate: viewStartDate,
              timeView: appointmentData.dateTime,
              appointmentType: appointmentData.type.type,
              facilityName: appointmentData.facilityLocation.facility.name,
              viewFacilityType: appointmentData.facilityLocation.facility.type,
              preDescription: appointmentData.appointmentPreNote,
              description: appointmentData.description,
              appoiPurpose: appointmentData.purpose,
              facilityAppointmentLocation: appointmentData.facilityLocation,
              action: 'view',
            });
            this.getFacilityDetails();
          }
          this.setState({
            sameDateAppointmentWindow: false,
          });
        } else {
        }
      })
      .catch((error) => {});
  };
  toAppointmentEdit = (e) => {
    this.setState({
      isButtonHide: true,
      isEditButtonHide: false,
    });
  };

  viewCancel = () => {
    if (this.state.pageId === 'memberdeatils') {
      this.props.history.push({
        pathname: '/memberDetails',
      });
    } else if (this.state.pageId === 'viewDoctor') {
      this.props.history.push({
        pathname: '/viewDoctor',
        state: { action: 'view', title: 'Doctor Details', doctorId: this.state.doctorViewId },
      });
    } else if (this.state.pageId === 'viewFacility') {
      this.props.history.push({
        pathname: '/viewFacility',
        state: {
          action: 'view',
          title: 'Facility Details',
          facilityId: this.state.facilityViewId,
          pageId: 'appointment',
        },
      });
    } else {
      this.props.history.push({
        pathname: '/memberAppointment',
      });
    }
  };
  viewPrintCancel = () => {
    this.setState({ action: 'view' });
  };

  createCancel = () => {
    if (this.state.pageId === 'memberdeatils') {
      this.props.history.push({
        pathname: '/memberDetails',
      });
    } else {
      this.props.history.push({
        pathname: '/memberAppointment',
      });
    }
  };

  /* get previous Appointment Data */
  previousAppointment = (e, appointmentId) => {
    let id = appointmentId;
    const postObject = {
      header: this.header,
      appointment: {
        id: id,
      },
    };

    FetchServerData.callPostService('/appointmentms/getPreviousAppointment/', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let appointmentData = output.data.appointment;
        let previousAppointmentId = output.data.previousAppointmentId;
        let appointmentTaskList = appointmentData.appointmentTakList;
        if (appointmentTaskList.length > 0) {
          for (let i = 0; i < appointmentTaskList.length; i++) {
            if (appointmentTaskList[i].type.type === 'Note') {
              this.noteListArray.push(appointmentTaskList[i]);
            } else if (appointmentTaskList[i].type.type === 'Facility') {
              this.appointmentTaskFacilityList.push(appointmentTaskList[i]);
              this.setState({
                appointmentTaskReminderList: appointmentTaskList[i].reminderList,
              });
            } else {
              this.appointmentFacilityList.push(appointmentTaskList[i]);
            }
          }
        }
        if (appointmentData !== null) {
          let doctorView = false;
          let newStartDate = Utils.convertUTCDateToUserTimeZoneDate(appointmentData.date);
          let viewStartDate = Utils.convertUTCDateToUserTimeZoneViewDateFormate(appointmentData.date);
          let getPreviousAppointment = true;
          if (previousAppointmentId === null) {
            getPreviousAppointment = false;
          }
          if (appointmentData.type.type === 'Doctor Appointment') {
            if (!appointmentData.reminderList || appointmentData.appointmentTakList !== null) {
              for (let i = 0; i < appointmentData.reminderList.length; i++) {
                let ref = React.createRef();
                this.appointmentReminderRefList.push(ref);
                /*Set Default Operation as Update */
                appointmentData.reminderList[i].operation = 'update';
              }

              this.setState({
                render: true,
                isDoctor: true,
                appointmentDoctor: appointmentData.doctorLocation.doctor,
                appoinmentId: appointmentData.id,
                preApptId: previousAppointmentId,
                startDate: new Date(newStartDate),
                appointmentStartDate: viewStartDate,
                timeView: appointmentData.dateTime,
                appointmentType: appointmentData.type.type,
                preDescription: appointmentData.appointmentPreNote,
                description: appointmentData.description,
                appoiPurpose: appointmentData.purpose,
                location: appointmentData.location,
                childAppointmentList: appointmentData.childAppointmentList,
                appointmentNoteList: appointmentData.noteList,
                appointmentTaskList: this.appointmentFacilityList,
                reminderItems: appointmentData.reminderList,
                expenseitems: appointmentData.reminderList,
                viewFacilityCard: false,
                viewDoctorCard: true,
                isAppointmentDoctorCardView: true,
                isAppointmentFacilityCardView: false,
                doctorRow: doctorView,
                appointmentStatus: appointmentData.status.status,
                previousButton: getPreviousAppointment,
                appointmentTaskFacilityList: this.appointmentTaskFacilityList,
                doctor: appointmentData.doctorLocation.doctor,
                doctorLocation: appointmentData.doctorLocation,
                locationWebsite: appointmentData.doctorLocation.website,
                locationPhone: Utils.displayUsaPhoneNumberFormate(appointmentData.doctorLocation.phone),
                locationFax: appointmentData.doctorLocation.fax,
                locationCity: appointmentData.doctorLocation.address.city,
                locationLine: appointmentData.doctorLocation.address.line,
                locationState: appointmentData.doctorLocation.address.state.name,
                locationCountry: appointmentData.doctorLocation.address.state.country.name,
                locationZipCode: appointmentData.doctorLocation.address.zipCode,
                facilityTransportationAddress: appointmentData.doctorLocation.address,
                appointmentTransportationAddress: appointmentData.doctorLocation.address,
                transportationList: appointmentData.transportationList,
                locationNickName: appointmentData.doctorLocation.nickName,
                line: appointmentData.doctorLocation.address.line,
                city: appointmentData.doctorLocation.address.city,
                state: appointmentData.doctorLocation.address.state.code,
                country: appointmentData.doctorLocation.address.state.country.code,
                pincode: appointmentData.doctorLocation.address.zipCode,
              });
            } else {
              this.setState({
                appointmentDoctor: appointmentData.doctorLocation.doctor,
                appoinmentId: appointmentData.id,
                startDate: new Date(newStartDate),
                appointmentStartDate: viewStartDate,
                preApptId: previousAppointmentId,
                timeView: appointmentData.dateTime,
                appointmentType: appointmentData.type.type,
                render: true,
                isDoctor: true,
                preDescription: appointmentData.appointmentPreNote,
                description: appointmentData.description,
                appoiPurpose: appointmentData.purpose,
                location: appointmentData.location,
                childAppointmentList: appointmentData.childAppointmentList,
                appointmentNoteList: appointmentData.noteList,
                appointmentTaskList: [],
                reminderItems: [],
                expenseitems: appointmentData.reminderList,
                viewFacilityCard: false,
                viewDoctorCard: true,
                isAppointmentDoctorCardView: true,
                isAppointmentFacilityCardView: false,
                doctorRow: doctorView,
                doctor: appointmentData.doctorLocation.doctor,
                doctorLocation: appointmentData.doctorLocation,
                appointmentStatus: appointmentData.status.status,
                previousButton: getPreviousAppointment,
                appointmentTaskFacilityList: this.appointmentTaskFacilityList,
                locationWebsite: appointmentData.doctorLocation.website,
                locationPhone: Utils.displayUsaPhoneNumberFormate(appointmentData.doctorLocation.phone),
                locationFax: appointmentData.doctorLocation.fax,
                locationCity: appointmentData.doctorLocation.address.city,
                locationLine: appointmentData.doctorLocation.address.line,
                locationState: appointmentData.doctorLocation.address.state.name,
                locationCountry: appointmentData.doctorLocation.address.state.country.name,
                locationZipCode: appointmentData.doctorLocation.address.zipCode,
                facilityTransportationAddress: appointmentData.doctorLocation.address,
                appointmentTransportationAddress: appointmentData.doctorLocation.address,
                transportationList: appointmentData.transportationList,
                locationNickName: appointmentData.doctorLocation.nickName,
                line: appointmentData.doctorLocation.address.line,
                city: appointmentData.doctorLocation.address.city,
                state: appointmentData.doctorLocation.address.state.code,
                country: appointmentData.doctorLocation.address.state.country.code,
                pincode: appointmentData.doctorLocation.address.zipCode,
              });
            }

            this.getDoctorDetails();
            // this.getDoctorLocationList();
          } else {
            if (!appointmentData.reminderList || appointmentData.appointmentTakList !== null) {
              for (let i = 0; i < appointmentData.reminderList.length; i++) {
                let ref = React.createRef();
                this.appointmentReminderRefList.push(ref);
                /*Set Default Operation as Update */
                appointmentData.reminderList[i].operation = 'update';
              }

              this.setState({
                render: true,
                isDoctor: false,
                isFacility: true,
                appoinmentId: appointmentData.id,
                preApptId: previousAppointmentId,
                startDate: new Date(newStartDate),
                appointmentStartDate: viewStartDate,
                timeView: appointmentData.dateTime,
                appointmentType: appointmentData.type.type,
                preDescription: appointmentData.appointmentPreNote,
                description: appointmentData.description,
                appoiPurpose: appointmentData.purpose,
                location: appointmentData.location,
                childAppointmentList: appointmentData.childAppointmentList,
                appointmentNoteList: appointmentData.noteList,
                appointmentTaskList: this.appointmentFacilityList,
                reminderItems: appointmentData.reminderList,
                expenseitems: appointmentData.reminderList,
                viewFacilityCard: true,
                viewDoctorCard: false,
                isAppointmentDoctorCardView: false,
                isAppointmentFacilityCardView: true,
                facilityLocation: appointmentData.facilityLocation.facility,
                facilityName: appointmentData.facilityLocation.facility.name,
                viewFacilityType: appointmentData.facilityLocation.facility.type,
                facilityDesc: appointmentData.facilityLocation.facility.description,
                appointmentStatus: appointmentData.status.status,
                previousButton: getPreviousAppointment,
                appointmentTaskFacilityList: this.appointmentTaskFacilityList,
                facilityAppointmentLocation: appointmentData.facilityLocation,
                locationWebsite: appointmentData.facilityLocation.website,
                locationPhone: Utils.displayUsaPhoneNumberFormate(appointmentData.facilityLocation.phone),
                locationFax: appointmentData.facilityLocation.fax,
                locationCity: appointmentData.facilityLocation.address.city,
                locationLine: appointmentData.facilityLocation.address.line,
                locationState: appointmentData.facilityLocation.address.state.name,
                locationCountry: appointmentData.facilityLocation.address.state.country.name,
                locationZipCode: appointmentData.facilityLocation.address.zipCode,
                facilityTransportationAddress: appointmentData.facilityLocation.address,
                appointmentTransportationAddress: appointmentData.facilityLocation.address,
                transportationList: appointmentData.transportationList,
                locationNickName: appointmentData.facilityLocation.nickName,
                line: appointmentData.facilityLocation.address.line,
                city: appointmentData.facilityLocation.address.city,
                state: appointmentData.facilityLocation.address.state.code,
                country: appointmentData.facilityLocation.address.state.country.code,
                pincode: appointmentData.facilityLocation.address.zipCode,
              });
            } else {
              this.setState({
                appoinmentId: appointmentData.id,
                startDate: new Date(newStartDate),
                appointmentStartDate: viewStartDate,
                preApptId: previousAppointmentId,
                timeView: appointmentData.dateTime,
                appointmentType: appointmentData.type.type,
                render: true,
                isDoctor: false,
                preDescription: appointmentData.appointmentPreNote,
                description: appointmentData.description,
                appoiPurpose: appointmentData.purpose,
                location: appointmentData.location,
                childAppointmentList: appointmentData.childAppointmentList,
                appointmentNoteList: appointmentData.noteList,
                appointmentTaskList: [],
                reminderItems: [],
                expenseitems: appointmentData.reminderList,
                viewFacilityCard: true,
                viewDoctorCard: false,
                isAppointmentDoctorCardView: false,
                isAppointmentFacilityCardView: true,
                facilityLocation: appointmentData.facilityLocation.facility,
                facilityName: appointmentData.facilityLocation.facility.name,
                viewFacilityType: appointmentData.facilityLocation.facility.type,
                facilityDesc: appointmentData.facilityLocation.facility.description,
                appointmentStatus: appointmentData.status.status,
                previousButton: getPreviousAppointment,
                appointmentTaskFacilityList: this.appointmentTaskFacilityList.id,
                facilityAppointmentLocation: appointmentData.facilityLocation,
                locationWebsite: appointmentData.facilityLocation.website,
                locationPhone: Utils.displayUsaPhoneNumberFormate(appointmentData.facilityLocation.phone),
                locationFax: appointmentData.facilityLocation.fax,
                locationCity: appointmentData.facilityLocation.address.city,
                locationLine: appointmentData.facilityLocation.address.line,
                locationState: appointmentData.facilityLocation.address.state.name,
                locationCountry: appointmentData.facilityLocation.address.state.country.name,
                locationZipCode: appointmentData.facilityLocation.address.zipCode,
                facilityTransportationAddress: appointmentData.facilityLocation.address,
                appointmentTransportationAddress: appointmentData.facilityLocation.address,
                transportationList: appointmentData.transportationList,
                locationNickName: appointmentData.facilityLocation.nickName,
                line: appointmentData.facilityLocation.address.line,
                city: appointmentData.facilityLocation.address.city,
                state: appointmentData.facilityLocation.address.state.code,
                country: appointmentData.facilityLocation.address.state.country.code,
                pincode: appointmentData.facilityLocation.address.zipCode,
              });
            }
            this.getFacilityDetails();
          }
        } else {
          this.setState({
            appoinmentId: '',
            startDate: '',
            time: '',
            appointmentType: '',
            frequency: '',
            preDescription: '',
            description: '',
            appoiPurpose: '',
            location: '',
            appointmentTaskList: [],
            appointmentReminderList: [],
            expenseitems: '',
            doctorName: '',
            appointmentStatus: '',
          });
        }
      } else {
      }
    });
  };

  toTransportationRequest() {
    this.setState({
      transportationModal: true,
    });
  }

  transportationToggle = () => {
    this.setState({ transportationModal: !this.state.transportationModal });
  };

  transportationModal() {
    this.setState({
      transportationModal: true,
      transportationType: '',
      contactMemberName: '',
      transportationStartDate: format(new Date(), 'yyyy-MM-dd'),
      transportationTime: format(new Date(), 'hh-mm'),
      transportationDesc: '',
      line: '',
      country: '',
      state: '',
      city: '',
      pinCode: '',
      transportationReminder: [],
    });
  }

  addReminder(type) {
    let items = this.state.reminderItems;
    let item = {
      action: 'create',
      operation: 'add',
      content: this.state.appoiPurpose,
    };
    items.push(item);
    this.appointmentReminderRefList.push(React.createRef());

    this.setState({ reminderItems: items });
  }

  addReferenceAppointmentReminder(type) {
    let items = this.state.referenceAppointmentReminderItems;
    let item = {
      action: 'create',
      operation: 'add',
    };
    items.push(item);
    this.appointmentReminderRefList.push(React.createRef());

    this.setState({ referenceAppointmentReminderItems: items });
  }

  cancelViewAction = (e, appointmentId) => {
    this.setState({
      isButtonHide: false,
      isEditButtonHide: true,
    });
  };

  /* add reference Appointment Task  Data in backend */
  addAppointmentTaskInBackend = (e) => {
    let dateTime = Utils.convertUserTimeZoneDateToUTCDate(this.referenceAppointmentRef.current.state.noteDate);

    const postObject = {
      header: this.header,
      appointmentTask: {
        note: this.referenceAppointmentRef.current.state.taskNote,
        date: dateTime,
        appointment: {
          id: this.state.id,
        },
        referenceAppointment: {
          id: this.referenceAppointmentRef.current.state.appointmentReferenceId,
        },
        facility: {},
        type: {
          type: 'Appointment',
        },
        occurance: {
          occurance: this.referenceAppointmentRef.current.state.taskOccurrence,
        },
        reminderList: [],
      },
    };
    FetchServerData.callPostService('/appointmentTaskms/create', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          PtsAlert.success(output.message);
          let createdReferenceAppointmentList = this.state.appointmentTaskList;
          createdReferenceAppointmentList.push(output.data);

          this.setState({
            referenceAppointmentCreate: false,
            appointmentTaskList: createdReferenceAppointmentList,
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  /* update reference Appointment Task  Data in backend */
  updateAppointmentTaskInBackend = (data) => {
    this.getAppointmentDetails(this.state.id);
  };

  /* add  Appointment Facility Task  Data in backend */
  addFacilityTaskInBackend = (facilityData) => {
    let facilityReminder = [];
    let postObject = {};
    if (this.appointmentTaskFacilityRef.current.state) {
      let reminderList = this.appointmentTaskFacilityRef.current.state.reminderItems;
      for (let i = 0; i < reminderList.length; i++) {
        let reminderObj = {
          content: reminderList[i].note,

          date: reminderList[i].startDate,

          operation: reminderList[i].operation,
        };
        facilityReminder.push(reminderObj);
      }
      let appointmentTaskFacilityDate = this.appointmentTaskFacilityRef.current.state.noteDate;
      let dateTime = Utils.convertUserTimeZoneDateToUTCDate(appointmentTaskFacilityDate);
      postObject = {
        header: this.header,
        appointmentTask: {
          note: this.appointmentTaskFacilityRef.current.state.taskNote,
          appointment: {
            id: this.state.id,
          },
          facilityLocation: {
            id: this.appointmentTaskFacilityRef.current.state.facility,
          },
          date: dateTime,
          type: {
            type: 'Facility',
          },
          occurance: {
            occurance: this.appointmentTaskFacilityRef.current.state.taskOccurrence,
          },
          reminderList: facilityReminder,
        },
      };
    }
    FetchServerData.callPostService('/appointmentTaskms/create', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          PtsAlert.success(output.message);
          let facility = this.state.appointmentTaskFacilityList;
          facility.push(output.data);
          this.setState({
            taskFacilityAddRow: false,
            appointmentTaskFacilityList: facility,
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  /* update  Appointment Facility Task  Data in backend */
  updateFacilityTaskInBackend = (facilityData) => {
    let facilityReminder = [];
    let postObject = {};
    let reminderObj = {};

    for (let i = 0; i < this.state.appointmentTaskFacilityList.length; i++) {
      if (i === facilityData.index) {
        let reminderList = this.appointmentTaskFacilityRef[i].current.state.reminderItems;
        for (let i = 0; i < reminderList.length; i++) {
          if (reminderList[i].operation === 'update') {
            facilityReminder.push(reminderList[i]);
          } else if (reminderList[i].operation === 'delete') {
            facilityReminder.push(reminderList[i]);
          } else {
            reminderObj = {
              content: reminderList[i].note,
              date: reminderList[i].startDate,
              operation: reminderList[i].operation,
            };
          }
          facilityReminder.push(reminderObj);
        }
        var newArray = facilityReminder.filter((value) => Object.keys(value).length !== 0);
        let appointmentTaskFacilityDate = this.appointmentTaskFacilityRef[i].current.state.noteDate;

        let dateTime = Utils.convertUserTimeZoneDateToUTCDate(appointmentTaskFacilityDate);

        postObject = {
          header: this.header,
          appointmentTask: {
            id: this.appointmentTaskFacilityRef[i].current.state.id,
            note: this.appointmentTaskFacilityRef[i].current.state.taskNote,
            appointment: {
              id: this.state.id,
            },
            facilityLocation: {
              id: this.appointmentTaskFacilityRef[i].current.state.facility,
            },
            date: dateTime,
            type: {
              type: 'Facility',
            },
            occurance: {
              occurance: this.appointmentTaskFacilityRef[i].current.state.taskOccurrence,
            },
            reminderList: newArray,
          },
        };
      }
    }
    FetchServerData.callPostService('appointmentTaskms/update', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          PtsAlert.success(output.message);
          this.getAppointmentDetails(this.state.id);
          for (let i = 0; i < this.state.appointmentTaskFacilityList.length; i++) {
            if (i === facilityData.index) {
              this.appointmentTaskFacilityRef[i].current.state.action = 'view';
            }
          }

          this.setState({
            taskFacilityAddRow: false,
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  /* delete  Appointment Facility Task  Data in backend */
  deleteFacilityTaskInBackend = (facilityData) => {
    const postObject = {
      header: this.header,
      appointmentTask: {
        id: facilityData.id,
      },
    };

    FetchServerData.callPostService('/appointmentTaskms/delete/', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          for (let i = 0; i < this.state.appointmentTaskFacilityList.length; i++) {
            if (this.state.appointmentTaskFacilityList[i].id === facilityData.id) {
              this.state.appointmentTaskFacilityList.splice(i, 1);
            }
          }
          this.setState({
            appointmentTaskFacilityList: this.state.appointmentTaskFacilityList,
            sweetAlert: false,
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  /* select reference  Appointment  Task  from list */
  selectAppointment = (e) => {
    if (this.state.taskOccurrence === '') {
      PtsAlert.error('Please Select Occurrence');
    } else {
      this.d = new Date();
      this.currentDate = moment(this.d).format('DD/MMMM/YYYY');
      let monYrArr = this.currentDate.split('/');
      let currentMonth = monYrArr[1];
      let year = monYrArr[2];
      this.setState({
        headerMonth: currentMonth,
        headerYear: year,
      });
      this.getReferenceAppointments();
      this.setState({
        listModal: true,
      });
    }
  };

  /* get  Member Appointments Month wise data */
  getReferenceAppointments = () => {
    const postObject = {
      header: this.header,
      appointmentTask: {
        occurance: {
          occurance: this.state.taskOccurrence,
        },
        appointment: {
          id: this.state.appoinmentId,
          member: {
            id: this.memberDetails.member,
          },
        },
      },
    };
    FetchServerData.callPostService('appointmentms/getReferanceAppointmentForMember', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let appointmentListArr = [];
          appointmentListArr = output.data;
          this.setState({
            appointmentList: appointmentListArr,
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  /* create reference  Appointment  Task   */
  createAppointmentModal = (e) => {
    if (this.state.taskOccurrence === '') {
      PtsAlert.error('Please Select Occurrence');
    } else {
      this.setState({
        createAppointmentModal: true,
        pageId: '/viewMemberAppointment',
        referenceAppointmentStartDate: new Date(),
        referenceAppointmentType: '',
        referenceAppointmentDescription: '',
        referenceAppointmentAppoiPurpose: '',
        referenceAppointmentDoctor: '',
        referenceAppointmentReminderItems: [],
        listModal: false,
      });
    }
  };

  toggle = () => {
    this.setState({
      listModal: !this.state.listModal,
    });
  };

  cancelAppointmentTask = (type) => {
    this.setState({
      referenceAppointmentCreate: false,
    });
  };

  /*  delete Appointment  In Backend*/
  deleteAppointment = () => {
    const postObject = {
      header: this.header,
      appointment: {
        id: this.state.id,
      },
    };

    FetchServerData.callPostService('/appointmentms/delete', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          this.props.history.push({
            pathname: '/memberAppointment',
          });
          this.setState({
            appointmentAlert: false,
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  editAppointmentNote = (type, note) => {};

  printAppointmentContent(e) {
    e.preventDefault();
    let printContents = document.getElementById('printform').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    document.body.innerHTML = originalContents;
    window.print();
    this.setState({
      action: 'view',
      render: true,
    });
  }

  /*  add Appointment Note in backend*/
  addAppointmentNotesInBackend(note) {
    for (let i = 0; i < this.state.appointmentNoteList.length; i++) {
      if (
        this.state.appointmentNoteList[i] &&
        note.index === i &&
        this.appointmentNoteRefList[i] &&
        this.appointmentNoteRefList[i].current
      ) {
        let noteObj = {
          appointment: {
            id: this.state.id,
          },
          occurance: {
            occurance: this.appointmentNoteRefList[i].current.state.occurrence,
          },
          note: this.appointmentNoteRefList[i].current.state.note,
          shortDescription: this.appointmentNoteRefList[i].current.state.title,
        };
        const postObject = {
          header: this.header,
          appointmentNote: noteObj,
        };
        FetchServerData.callPostService('/appointmentms/createNote', postObject)
          .then((output) => {
            if (output.status === 'SUCCESS') {
              let noteListData = output.data;

              for (let i = 0; i < this.state.appointmentNoteList.length; i++) {
                this.appointmentNoteRefList[i].current.state.action = 'view';
              }

              this.setState({
                appointmentNoteList: noteListData.noteList,
              });
            } else {
            }
          })
          .catch((error) => {});
      }
    }
  }

  updateAppointmentNotes = (appointmentNote) => {
    let postObject = {};
    for (let i = 0; i < this.state.appointmentNoteList.length; i++) {
      if (appointmentNote.id === this.state.appointmentNoteList[i].id) {
        postObject = {
          header: this.header,
          appointmentNote: {
            id: appointmentNote.id,
            note: appointmentNote.note,
            // date: this.state.noteDate,
            appointment: {
              id: this.state.id,
            },
            occurance: {
              occurance: appointmentNote.occurrence,
            },
            shortDescription: appointmentNote.title,
          },
        };
        FetchServerData.callPostService('/appointmentms/updateNote', postObject).then((output) => {
          if (output.status === 'SUCCESS') {
            let outputData = output.data;
            this.state.appointmentNoteList = [];
            this.setState({
              appointmentNoteList: outputData.noteList,
            });
          } else {
          }
        });
      }
    }
  };
  /*  delete Appointment Note in backend*/
  deleteAppointmentNote = (note) => {
    let id = note.id;
    const postObject = {
      header: this.header,
      appointmentNote: {
        id: id,
      },
    };

    FetchServerData.callPostService('/appointmentms/deleteNote/', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          for (let i = 0; i < this.state.appointmentNoteList.length; i++) {
            if (this.state.appointmentNoteList[i].id === id) {
              this.state.appointmentNoteList.splice(i, 1);
            }
          }
          this.setState({
            appointmentNoteList: this.state.appointmentNoteList,
            sweetAlert: false,
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  addAppointmentNotes() {
    if (this.state.appointmentType === 'Doctor Appointment') {
      let noteTitle =
        'Dr.' +
        ' ' +
        this.state.appointmentDoctor.firstName +
        ' ' +
        this.state.appointmentDoctor.lastName +
        ' ' +
        'Appointment';
      let notes = this.state.appointmentNoteList;
      let note = {
        operation: 'add',
        action: 'create',
        appointment: {
          id: this.state.id,
        },
        appointmentOccurance: noteTitle,
        occurance: {
          occurance: '',
        },
        shortDescription: noteTitle,
        type: {
          type: 'Note',
        },
        reminderList: null,
      };
      notes.push(note);
      this.setState({
        appointmentNoteList: notes,
      });
    } else {
      let notes = this.state.appointmentNoteList;
      let note = {
        operation: 'add',
        action: 'create',
        appointment: {
          id: this.state.id,
        },
        shortDescription: this.state.facilityName,
        appointmentOccurance: this.state.facilityName,
        occurance: {
          occurance: '',
        },
        type: {
          type: 'Note',
        },
        reminderList: null,
      };
      notes.push(note);
      this.setState({
        appointmentNoteList: notes,
      });
    }
  }

  backToAppointmentNoteList() {
    this.setState({ taskCreateNoteListRow: false, taskNoteListRow: true });
  }

  addNewReminder = (reminderData) => {
    for (let i = 0; i < this.state.reminderItems.length; i++) {
      if (
        this.state.reminderItems[i] &&
        reminderData.index === i &&
        this.appointmentReminderRefList[i] &&
        this.appointmentReminderRefList[i].current &&
        this.appointmentReminderRefList[i].current.validateReminder(i)
      ) {
        this.state.reminderItems[i] = reminderData;
        this.appointmentReminderRefList[i].current.state = reminderData;
        this.appointmentReminderRefList[i].current.state.action = 'view';
        this.setState({ render: true });
      }
    }
  };

  addNewReminderToBackend = (reminderData) => {
    let postObject = {};
    for (let i = 0; i < this.state.reminderItems.length; i++) {
      if (
        this.state.reminderItems[i] &&
        reminderData.index === i &&
        this.appointmentReminderRefList[i] &&
        this.appointmentReminderRefList[i].current &&
        this.appointmentReminderRefList[i].current.validateReminder(i)
      ) {
        this.state.reminderItems[i] = reminderData;
        this.appointmentReminderRefList[i].current.state = reminderData;
        let reminderDateTime = Utils.convertUserTimeZoneDateToUTCDate(
          this.appointmentReminderRefList[i].current.state.startDate
        );
        postObject = {
          header: this.header,
          reminder: {
            content: this.appointmentReminderRefList[i].current.state.note,
            date: reminderDateTime,
            appointment: {
              id: this.state.id,
            },
          },
        };
        FetchServerData.callPostService('/appointmentms/createReminder/', postObject)
          .then((output) => {
            if (output.status === 'SUCCESS') {
              this.state.reminderItems = [];
              this.appointmentReminderRefList[i].current.state.action = 'view';
              let reminders = [];
              PtsAlert.success(output.message);
              if (output.data.reminderList.length > 0) {
                for (let i = 0; i < output.data.reminderList.length; i++) {
                  reminders.push(output.data.reminderList[i]);
                }
              }

              this.setState({
                render: true,
                reminderItems: reminders,
              });
            } else {
            }
          })
          .catch((error) => {});
      }
    }
  };

  updateReminder = (reminderData) => {
    let postObject = {};
    for (let i = 0; i < this.state.reminderItems.length; i++) {
      if (
        this.state.reminderItems[i] &&
        reminderData.index === i &&
        this.appointmentReminderRefList[i] &&
        this.appointmentReminderRefList[i].current &&
        this.appointmentReminderRefList[i].current.validateReminder()
      ) {
        this.state.reminderItems[i] = reminderData;
        this.appointmentReminderRefList[i].current.state = reminderData;

        let reminderDateTime = Utils.convertUserTimeZoneDateToUTCDate(
          this.appointmentReminderRefList[i].current.state.startDate
        );
        postObject = {
          header: this.header,
          reminder: {
            content: this.appointmentReminderRefList[i].current.state.note,
            id: this.appointmentReminderRefList[i].current.state.id,
            date: reminderDateTime,
            appointment: {
              id: this.state.id,
            },
          },
        };
        FetchServerData.callPostService('/appointmentms/updateReminder/', postObject)
          .then((output) => {
            if (output.status === 'SUCCESS') {
              let outputData = output.data;
              this.state.reminderItems = [];
              this.setState({
                reminderItems: outputData.reminderList,
              });
            } else {
            }
          })
          .catch((error) => {});
      }
    }
  };

  deleteReminderInBackend = (reminderData) => {
    const postObject = {
      header: this.header,
      reminder: {
        id: reminderData.id,
        appointment: {
          id: this.state.id,
        },
      },
    };

    FetchServerData.callPostService('/appointmentms/deleteReminder/', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          for (let i = 0; i < this.state.reminderItems.length; i++) {
            if (this.state.reminderItems[i].id === reminderData.id) {
              this.state.reminderItems.splice(i, 1);
            }
          }
          this.setState({
            reminderItems: this.state.reminderItems,
            sweetAlert: false,
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  deleteReminder(item) {
    let items = [];
    if (item.operation && 'update' === item.operation) {
      /* Existing Item Deleted, Mark it deleted */
      let index = item.index;

      for (let i = 0; i < this.state.reminderItems.length; i++) {
        let item = this.state.reminderItems[i];
        if (this.state.reminderItems[i].operation === 'update' && index === i) {
          item.operation = 'delete';
        }

        items.push(item);
      }

      this.setState({ reminderItems: items });
    }
    if (item.operation && 'add' === item.operation) {
      /* Added Item Deleted, Remove from list */
      let index = item.index;
      for (let i = 0; i < this.state.reminderItems.length; i++) {
        if (this.state.reminderItems[i].operation === 'add' && index === i) {
          this.appointmentReminderRefList.splice(i, 1);
          // continue;
        } else {
          items.push(this.state.reminderItems[i]);
        }
      }
      this.setState({ reminderItems: items });
    }
  }

  addReferenceAppointmentNewReminder = (reminderData) => {
    for (let i = 0; i < this.state.referenceAppointmentReminderItems.length; i++) {
      if (
        this.state.referenceAppointmentReminderItems[i] &&
        reminderData.index === i &&
        this.appointmentReminderRefList[i] &&
        this.appointmentReminderRefList[i].current &&
        this.appointmentReminderRefList[i].current.validateReminder(i)
      ) {
        this.state.referenceAppointmentReminderItems[i] = reminderData;
        this.appointmentReminderRefList[i].current.state = reminderData;
        this.appointmentReminderRefList[i].current.state.action = 'view';
        this.setState({ render: true });
      }
    }
  };

  updateReferenceAppointmentReminder = (reminderData) => {
    for (let i = 0; i < this.state.referenceAppointmentReminderItems.length; i++) {
      if (
        this.state.referenceAppointmentReminderItems[i] &&
        reminderData.index === i &&
        this.appointmentReminderRefList[i] &&
        this.appointmentReminderRefList[i].current &&
        this.appointmentReminderRefList[i].current.validateReminder()
      ) {
        this.state.referenceAppointmentReminderItems[i] = reminderData;
        this.appointmentReminderRefList[i].current.state = reminderData;
        this.appointmentReminderRefList[i].current.state.action = 'view';
        this.setState({ render: true });
      }
    }
  };

  referenceAppointmentReminderItemDeleted(item) {
    let items = [];
    if (item.operation && 'update' === item.operation) {
      /* Existing Item Deleted, Mark it deleted */
      let index = item.index;

      for (let i = 0; i < this.state.referenceAppointmentReminderItems.length; i++) {
        let item = this.state.referenceAppointmentReminderItems[i];
        if (this.state.referenceAppointmentReminderItems[i].operation === 'update' && index === i) {
          item.operation = 'delete';
        }

        items.push(item);
      }

      this.setState({ referenceAppointmentReminderItems: items });
    }
    if (item.operation && 'add' === item.operation) {
      /* Added Item Deleted, Remove from list */
      let index = item.index;
      for (let i = 0; i < this.state.referenceAppointmentReminderItems.length; i++) {
        if (this.state.referenceAppointmentReminderItems[i].operation === 'add' && index === i) {
          this.appointmentReminderRefList.splice(i, 1);
          // continue;
        } else {
          items.push(this.state.referenceAppointmentReminderItems[i]);
        }
      }
      this.setState({ referenceAppointmentReminderItems: items });
    }
  }

  addAppointmentStatusInBackend = (status) => {
    let appointmentStatus = status;
    const postObject = {
      header: this.header,
      appointment: {
        id: this.state.appoinmentId,
        status: {
          status: appointmentStatus,
        },
        member: {
          id: this.memberDetails.member,
        },
      },
    };

    FetchServerData.callPostService('appointmentms/updateStatus', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let appointmentData = output.data;

          this.props.history.push({
            pathname: '/memberAppointment',
          });
        } else {
        }
      })
      .catch((error) => {});
  };
  appointmentSyncToCalenderInBackend = () => {
    const postObject = {
      header: this.header,
      appointment: {
        id: this.state.appoinmentId,
      },
    };

    FetchServerData.callPostService('/calendarEventMS/syncAppointmentToCalendar', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          // PtsAlert.success(output.message);
          this.setState({
            calendarSyncModal: true,
          });
        } else {
        }
      })
      .catch((error) => {});
  };
  handleCancelAppointmentConfirm(status) {
    let appointmentStatus = status;
    const postObject = {
      header: this.header,
      appointment: {
        id: this.state.appoinmentId,
        status: {
          status: appointmentStatus,
        },
        member: {
          id: this.memberDetails.member,
        },
      },
    };

    FetchServerData.callPostService('appointmentms/updateStatus', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let appointmentData = output.data;
          this.setState({
            appointmentStatus: appointmentData.appointment.status.status,
            openCancelAppointment: false,
          });
          this.props.history.push({
            pathname: '/memberAppointment',
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  markOpenedAppointmentStatusInBackend() {
    const postObject = {
      header: this.header,
      appointment: {
        id: this.state.appoinmentId,
      },
    };

    FetchServerData.callPostService('/appointmentms/markScheduled', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let appointmentData = output.data;

          this.props.history.push({
            pathname: '/memberAppointment',
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  changeAppointmentStatusInBackend() {
    this.setState({ openCancelAppointment: true });
  }
  /*Add reminder to facility task */
  addFacilityTaskReminder() {
    let items = this.state.facilityTaskReminderItems;
    let item = {
      action: 'create',
      operation: 'add',
    };
    items.push(item);
    this.facilityTaskReminderRefList.push(React.createRef());

    this.setState({
      facilityTaskReminderItems: items,
      facilityReminderRow: false,
    });
  }

  toDoctorCreate = (e) => {
    this.props.history.push({
      pathname: '/addDoctor',
      state: { action: 'create', title: 'Add Doctor', pageId: 'memberDoctor', doctorId: '' },
    });
  };

  toFacilityCreate = (e) => {
    this.props.history.push({
      pathname: '/addFacility',
      state: { action: 'create', title: 'Add Facility', pageId: 'addmemberFacility', facilityId: '' },
    });
  };

  repeatAppointment() {
    this.props.history.push({
      pathname: '/createSimilarAppointment',
      state: {
        action: 'repeatAppointment',
        title: 'Add Appointment',
        createSimilarAppointmentDetails: this.state.createSimilarAppointmentDetails,
      },
    });
  }

  rescheduledAppointment() {
    this.props.history.push({
      pathname: '/createSimilarAppointment',
      state: {
        action: 'repeatAppointment',
        title: 'Add Appointment',
        pageId: 'rescheduledAppointment',
        createSimilarAppointmentDetails: this.state.createSimilarAppointmentDetails,
      },
    });
  }

  showAppointmentHierarchy() {
    this.props.history.push({
      pathname: '/appointmentHierarchy',
      state: { title: 'Appointment Hierarchy', appointmentId: this.state.appoinmentId },
    });
  }

  printPreviewAppointment() {
    this.setState({
      action: 'printAppointment',
    });
  }

  handleClose = () => {
    this.setState({ openCancelAppointment: false });
  };
  handleSameDateAppointmentCreateWindowClose = () => {
    this.setState({
      sameDateAppointmentWindow: false,
    });
  };

  calendarSyncToggle = () => {
    this.setState({
      calendarSyncModal: !this.state.calendarSyncModal,
    });
  };
  render() {
    this.appointmentTransportationRef = [];

    for (let i = 0; this.state.transportationList && i < this.state.transportationList.length; i++) {
      this.appointmentTransportationRef[i] = React.createRef();
    }

    this.appointmentTaskFacilityRef = [];
    for (let i = 0; this.state.appointmentTaskFacilityList && i < this.state.appointmentTaskFacilityList.length; i++) {
      this.appointmentTaskFacilityRef[i] = React.createRef();
    }
    this.appointmentNoteRefList = [];
    for (let i = 0; this.state.appointmentNoteList && i < this.state.appointmentNoteList.length; i++) {
      this.appointmentNoteRefList[i] = React.createRef();
    }
    this.appointmentReminderRefList = [];
    for (let i = 0; this.state.reminderItems && i < this.state.reminderItems.length; i++) {
      this.appointmentReminderRefList[i] = React.createRef();
    }
    if (this.state.render === false) {
      return <div />;
    } else if (this.state.action === 'create') {
      return this.createAppointment();
    }
    if (this.state.action === 'edit') {
      return this.editAppointment();
    } else if (this.state.action === 'view') {
      return this.viewAppointment();
    } else if (this.state.action === 'printAppointment') {
      return this.printAppointment();
    }
  }

  viewAppointment() {
    if (this.state.render === false) {
      return <div />;
    } else {
      if (this.state.isFreeUser === true) {
        return this.viewAppointmentWithAdvertisement();
      } else {
        return this.viewAppointmentWithoutAdvertisement();
      }
    }
  }

  createAppointment() {
    let reminderList = [];
    this.state.reminderItems.forEach((element, index) => {
      if ('delete' !== element.operation) {
        reminderList.push(
          <Col md={6} style={{ marginTop: '1em' }}>
            <Reminder
              ref={this.appointmentReminderRefList[index]}
              key={index}
              data={element}
              srNo={index}
              reminderDate={this.state.startDate}
              pageId={this.state.pageId}
              action="create"
              onReminderAdd={this.addNewReminder.bind(this)}
              onDelete={this.deleteReminder.bind(this)}
              onUpdate={this.updateReminder.bind(this)}
            />
          </Col>
        );
      }
    });
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={'Create Appointment For' + ' ' + this.memberName}
          domainObject="Appointment"
        />
        <ValidatorForm style={{ width: '100%' }} ref="form" onSubmit={this.addAppointmentInBackend.bind(this)}>
          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={6} style={{ position: 'relative', padding: '5px' }}>
              <Card md={12} style={{ height: '100%', borderRadius: '20px' }}>
                <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                  {undefined !== this.state.title && this.state.title.length > 0 && (
                    <Label
                      style={{
                        marginLeft: '0.5em',
                      }}
                      className="appointment_section_header"
                    >
                      {this.state.title}
                    </Label>
                  )}
                </CardHeader>
                <CardBody style={{ padding: '2em' }}>
                  <Row md={12} style={{ padding: '0.5em' }}>
                    <div md={12} style={{ width: '100%' }}>
                      <Row className="dateRow" style={{ marginBottom: '0.9em' }}>
                        <Col md={6}>
                          <FormControl variant="outlined" fullWidth>
                            <InputLabel id="appointmentType-label">Appointment Type*</InputLabel>
                            <Select
                              required
                              id="appointmentType"
                              value={this.state.appointmentType}
                              label="Appointment Type*"
                              onChange={(e) => {
                                this.handleAppointmentTypeChange(e);
                              }}
                            >
                              {this.appointmentTypeListArray.map((event, keyIndex) => {
                                return (
                                  <MenuItem key={keyIndex} value={event.type}>
                                    {event.type}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </FormControl>
                        </Col>
                        {this.state.isDoctor && (
                          <>
                            <Col md={6} style={{ marginTop: '-1em' }}>
                              <Autocomplete
                                id="Role"
                                options={this.state.doctorLocationList}
                                value={this.state.doctor}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, value) => {
                                  this.handleDoctorChange(value);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} variant="outlined" label="Doctor" margin="normal" required />
                                )}
                              />
                            </Col>
                          </>
                        )}

                        {this.state.isFacility && (
                          <>
                            <Col md={6} style={{ marginTop: '-1em' }}>
                              <Autocomplete
                                id="Role"
                                options={this.state.facilityLocationList}
                                value={this.state.facilityLocation}
                                getOptionLabel={(option) => option.name}
                                onChange={(event, value) => {
                                  this.handleFacilityLocationChange(value);
                                }}
                                renderInput={(params) => (
                                  <TextField {...params} variant="outlined" label="Facility" margin="normal" required />
                                )}
                              />
                            </Col>
                          </>
                        )}
                      </Row>
                      <Row style={{ marginBottom: '0.9em' }}>
                        <Col md={6}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <ThemeProvider theme={materialTheme}>
                              <DateTimePicker
                                value={this.state.startDate}
                                variant="outlined"
                                label="Date"
                                fullWidth
                                id="ptsDateTimePicker"
                                inputVariant="outlined"
                                format="MM/dd/yyyy  h:mm aa"
                                onChange={this.handleStartDateChange.bind(this)}
                              />
                            </ThemeProvider>
                          </MuiPickersUtilsProvider>{' '}
                        </Col>
                        <Col md={6}>
                          <input
                            className="pts_dataList_dropDown"
                            type="text"
                            required
                            id="demo-simple-select-outlined"
                            list="data"
                            placeholder="Appointment Purpose*"
                            label="Appointment Purpose*"
                            value={this.state.appoiPurpose}
                            onChange={(event, value) => {
                              this.handleAppoiPurposeChange(event, value);
                            }}
                          />
                          <datalist id="data">
                            {this.appointmentPurposeListData.map((event, key) => (
                              <option key={event.purpose} value={event.purpose} />
                            ))}
                          </datalist>
                        </Col>
                      </Row>

                      <Row style={{ marginBottom: '0.9em' }}>
                        <Col md={12}>
                          <TextareaAutosize
                            aria-label="minimum height"
                            style={{ width: '100%', padding: '10px' }}
                            minRows={3}
                            id={'note' + this.state.index}
                            value={this.state.description}
                            name="note"
                            inputprops={{ maxLength: 2048 }}
                            onChange={this.handleDescriptionChange.bind(this)}
                            placeholder="Description"
                          />
                        </Col>
                      </Row>
                    </div>
                  </Row>
                </CardBody>
              </Card>
            </Col>
            <Col md={6} style={{ position: 'relative', padding: '5px' }}>
              <Card
                md={12}
                style={{
                  height: '100%',
                  borderRadius: '20px',
                }}
              >
                <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                  <Row>
                    <Col md={11}>
                      <Label
                        style={{
                          marginLeft: '0.5em',
                        }}
                        className="appointment_section_header"
                      >
                        Location Details
                      </Label>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {this.state.isDoctorLocation && (
                    <Row style={{ marginTop: '1em' }}>
                      <Col md={12}>
                        <Location data={this.state.selectedDoctorLocation} action="simpleView" />
                      </Col>
                    </Row>
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div style={{ textAlign: 'center' }}>
            <CreateButton>Create</CreateButton>
            &nbsp;
            <CancelIconButton onClick={(e) => this.createCancel()} />
          </div>
        </ValidatorForm>
        <Dialog
          open={this.state.sameDateAppointmentWindow}
          onClose={this.handleSameDateAppointmentCreateWindowClose.bind(this)}
          onHide={this.close}
          style={{ padding: '1em' }}
        >
          <DialogContent style={{ marginTop: '2em' }}>
            <Row>
              <Col md={12} style={{ textAlign: 'center' }}>
                <h5>{this.state.WarningMessage}</h5>
                <br />
                {this.state.WarningMessage ? <h5>Do you still want to continue to create?</h5> : ''}
              </Col>
            </Row>
          </DialogContent>
          <DialogActions style={{ marginTop: '0em', padding: '1em', marginLeft: 'auto', marginRight: 'auto' }}>
            <Button
              variant="contained"
              size="large"
              style={{ background: '#0f666b', color: '#fff', marginRight: '1rem' }}
              startIcon={<SaveIcon />}
              onClick={this.addSameAppointmentInBackend.bind(this)}
            >
              Ok
            </Button>
            <Button
              variant="contained"
              size="large"
              style={{ background: '#717373', color: '#fff', marginRight: '1rem' }}
              startIcon={<CloseIcon />}
              onClick={this.handleSameDateAppointmentCreateWindowClose.bind(this)}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
        <Modal isOpen={this.state.showCreateFacilityModal} toggle={this.createFacilityModalToggle.bind(this)} size="md">
          <Row style={{ marginTop: '1em' }}>
            {' '}
            <Col md={11} style={{ textAlign: 'center' }}>
              {' '}
              <Label
                style={{
                  fontWeight: '600',
                  color: '#162c50',
                  marginLeft: '0.5em',
                  textTransform: 'capitalize',
                  fontSize: '25px',
                }}
              >
                {this.props.title}
              </Label>
            </Col>
            <Col md={1}>
              <Fab
                aria-label="add"
                style={{
                  float: 'right',
                  fontSize: 'small',
                  marginRight: '1.2em',
                  height: '15px',
                  width: '35px',
                  background: '#76767d',
                  cursor: 'pointer',
                  color: 'white',
                  backgroundImage: '-webkit-linear-gradient(bottom, #843b07 0%, #f7b571 100%);',
                  border: '2px solid #edf0f1',
                }}
              >
                <CloseIcon onClick={this.createFacilityModalToggle.bind(this)} />
              </Fab>
            </Col>
          </Row>{' '}
          <ModalBody>
            <Row style={{ padding: '1em', textAlign: 'center' }}>
              <Col md={12}>
                <h4 style={{ padding: '0.5em 1em', fontWeight: 'bold' }}>
                  There are No Facilities set yet. Kindly Add Facility details.{' '}
                </h4>

                <Button
                  variant="contained"
                  size="large"
                  style={{ background: '#ef6603', color: '#fff', marginRight: '1rem', textTransform: 'capitalize' }}
                  onClick={this.toNewFacility.bind(this)}
                >
                  Add Facility
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
        <Modal isOpen={this.state.showCreateDoctorModal} toggle={this.createDoctorModalToggle.bind(this)} size="md">
          <Row style={{ marginTop: '1em' }}>
            {' '}
            <Col md={11} style={{ textAlign: 'center' }}>
              {' '}
              <Label
                style={{
                  fontWeight: '600',
                  color: '#162c50',
                  marginLeft: '0.5em',
                  textTransform: 'capitalize',
                  fontSize: '25px',
                }}
              >
                {this.props.title}
              </Label>
            </Col>
            <Col md={1}>
              <Fab
                aria-label="add"
                style={{
                  float: 'right',
                  fontSize: 'small',
                  marginRight: '1.2em',
                  height: '15px',
                  width: '35px',
                  background: '#76767d',
                  cursor: 'pointer',
                  color: 'white',
                  backgroundImage: '-webkit-linear-gradient(bottom, #843b07 0%, #f7b571 100%);',
                  border: '2px solid #edf0f1',
                }}
              >
                <CloseIcon onClick={this.createDoctorModalToggle.bind(this)} />
              </Fab>
            </Col>
          </Row>{' '}
          <ModalBody>
            <Row style={{ padding: '1em', textAlign: 'center' }}>
              <Col md={12}>
                <h4 style={{ padding: '0.5em 1em', fontWeight: 'bold' }}>
                  There are No Doctors set yet. Kindly Add Doctor details
                </h4>

                <Button
                  variant="contained"
                  size="large"
                  style={{ background: '#ef6603', color: '#fff', marginRight: '1rem', textTransform: 'capitalize' }}
                  onClick={this.toNewDoctor.bind(this)}
                >
                  Add Doctor
                </Button>
              </Col>
            </Row>
          </ModalBody>
        </Modal>
      </>
    );
  }

  viewAppointmentWithAdvertisement() {
    if (this.state.render === false) {
      return <div />;
    } else {
      let referenceAppointmentReminderList = [];
      this.state.referenceAppointmentReminderItems.forEach((element, index) => {
        if ('delete' !== element.operation) {
          referenceAppointmentReminderList.push(
            <Col md={4} style={{ marginTop: '1em' }}>
              <Reminder
                ref={this.appointmentReminderRefList[index]}
                key={index}
                data={element}
                srNo={index}
                reminderDate={this.state.referenceAppointmentStartDate}
                pageId={this.state.pageId}
                action="create"
                getDeletePermission={this.state.getDeletePermission}
                getUpdatePermission={this.state.getUpdatePermission}
                onReminderAdd={this.addReferenceAppointmentNewReminder.bind(this)}
                onDelete={this.referenceAppointmentReminderItemDeleted.bind(this)}
                onUpdate={this.updateReferenceAppointmentReminder.bind(this)}
              />
            </Col>
          );
        }
      });
      let taskNotes = [];
      this.state.appointmentNoteList.forEach((note, index) => {
        if ('delete' !== note.operation) {
          taskNotes.unshift(
            <Col md={6} style={{ marginTop: '1em' }}>
              <AppointmentNote
                ref={this.appointmentNoteRefList[index]}
                key={index}
                data={note}
                srno={index}
                action={note.action ? note.action : 'view'}
                type="appointment"
                noteId={this.state.noteId}
                pageId={this.state.pageId}
                appoinmentId={this.state.id}
                getCreatePermission={this.state.getAppointmentNoteCreatePermission}
                getDeletePermission={this.state.getAppointmentNoteDeletePermission}
                getUpdatePermission={this.state.getAppointmentNoteUpdatePermission}
                onNoteEdit={this.editAppointmentNote.bind(this, note)}
                onNoteDelete={this.deleteAppointmentNote.bind(this)}
                onDelete={this.appointmentNoteRemove.bind(this)}
                onNoteUpdate={this.updateAppointmentNotes.bind(this)}
                onChange={this.appointmentItemChanged.bind(this)}
                addNote={this.addAppointmentNotesInBackend.bind(this)}
                onSuccess={this.getAppointmentDetails.bind(this)}
              />
            </Col>
          );
        }
      });
      let reminderList = [];
      this.state.reminderItems.forEach((reminder, index) => {
        this.appointmentReminderRefList[index] = React.createRef();
        if ('delete' !== reminder.operation) {
          reminderList.push(
            <Col md={4} style={{ marginTop: '1em' }} key={index}>
              <Reminder
                ref={this.appointmentReminderRefList[index]}
                key={index}
                reminderDate={this.state.startDate}
                data={reminder}
                srNo={index}
                pageId={this.state.pageId}
                action={reminder.action ? reminder.action : 'view'}
                getDeletePermission={this.state.getDeletePermission}
                getUpdatePermission={this.state.getUpdatePermission}
                onReminderAdd={this.addNewReminderToBackend.bind(this)}
                onCancel={this.deleteReminder.bind(this)}
                onUpdate={this.updateReminder.bind(this)}
                onDelete={this.deleteReminderInBackend.bind(this)}
              />
            </Col>
          );
        }
      });

      let appointmentTaskFacilityList = [];
      this.state.appointmentTaskFacilityList.forEach((facility, index) => {
        appointmentTaskFacilityList.push(
          <Col md={6}>
            <AppointmentFacility
              ref={this.appointmentTaskFacilityRef[index]}
              key={index}
              data={facility}
              startDate={this.state.startDate}
              addressData={this.state.appointmentTransportationAddress}
              srNo={index}
              action="view"
              pageId={this.state.pageId}
              onUpdate={this.updateFacilityTaskInBackend.bind(this)}
              onDelete={this.deleteFacilityTaskInBackend.bind(this)}
            />
          </Col>
        );
      });

      let appointmentTransportationList = [];
      this.state.transportationList.forEach((transportation, index) => {
        appointmentTransportationList.push(
          <Col md={6}>
            <Transportation
              ref={this.appointmentTransportationRef[index]}
              getCreatePermission={this.state.getAppointmentTransportationCreatePermission}
              getDeletePermission={this.state.getAppointmentTransportationDeletePermission}
              getUpdatePermission={this.state.getAppointmentTransportationUpdatePermission}
              pageId={this.state.pageId}
              title="Transportation Request"
              data={transportation}
              key={index}
              srNo={index}
              action="view"
              transportationType="appointment"
              onChange={this.handleAppointmentTransportationChange.bind(this)}
              onDelete={this.deleteTransportation.bind(this)}
              onSuccess={this.getAppointmentDetails.bind(this)}
            />
          </Col>
        );
      });
      return (
        <>
          {this.state.isEditButtonHide && (
            <>
              <>
                {this.state.isViewButtonHide && (
                  <>
                    <MenuWithBreadScrum
                      breadCrumb={this.breadCrumb}
                      history={this.props.history}
                      status={this.state.appointmentStatus}
                      appointmentDate={this.state.startDate}
                      getDeletePermission={this.state.getDeletePermission}
                      getCreatePermission={this.state.getCreatePermission}
                      printCallback={this.printPreviewAppointment.bind(this)}
                      markOpenCallback={this.markOpenedAppointmentStatusInBackend.bind(this)}
                      markCompleteCallback={this.addAppointmentStatusInBackend.bind(this, 'Completed')}
                      backCallback={this.viewCancel.bind(this)}
                      syncToCalenderCallback={this.appointmentSyncToCalenderInBackend.bind(this)}
                      name={'Appointment For' + ' ' + this.memberName}
                      domainObject="Appointment"
                      deleteCallback={this.deleteAppointment.bind(this)}
                      createSimilarCallback={this.repeatAppointment.bind(this)}
                      HierarchyCallback={this.showAppointmentHierarchy.bind(this)}
                      referenceAppointmentLength={this.state.appointmentTaskList.length}
                    />
                  </>
                )}
              </>
            </>
          )}

          {this.state.isButtonHide && (
            <MenuWithBreadScrum
              status={this.state.appointmentStatus}
              appointmentDate={this.state.startDate}
              getDeletePermission={this.state.getDeletePermission}
              getCreatePermission={this.state.getCreatePermission}
              name={'Appointment For' + ' ' + this.memberName}
              domainObject="Appointment"
              breadCrumb={this.breadCrumb}
              history={this.props.history}
            />
          )}

          <div>
            <Row style={{ position: 'relative', marginLeft: '1em', marginRight: '1em', marginTop: '1em' }}>
              {this.state.isEditButtonHide && (
                <>
                  <Col md={5} style={{ position: 'relative', padding: '8px' }}>
                    {this.state.viewDoctorCard && (
                      <>
                        <Card
                          style={{
                            borderRadius: '20px',
                            height: '100%',
                          }}
                        >
                          <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0px' }}>
                            <Row>
                              <Col md={8} style={{ paddingLeft: '20px' }}>
                                <img
                                  src={doctor}
                                  className="img-circle"
                                  width="30px"
                                  height="30px"
                                  style={{ marginTop: '0.5em' }}
                                  alt=""
                                />
                                <label
                                  style={{
                                    textTransform: 'capitalize',
                                    paddingLeft: '1em',
                                  }}
                                  className="appointment_section_header"
                                >
                                  {'Dr.' +
                                    ' ' +
                                    this.state.appointmentDoctor.firstName +
                                    ' ' +
                                    this.state.appointmentDoctor.lastName}
                                </label>
                                <h6 className="doctorHeader">{this.state.appointmentDoctor.specialist}</h6>
                                {this.state.appointmentDoctor.phone && (
                                  <Row style={{ padding: '3px', marginLeft: '0em' }}>
                                    <Col md={2} style={{ padding: '0em' }}>
                                      <Tooltip title={this.state.appointmentDoctor.phone} aria-label="add">
                                        <PhoneIcon />
                                      </Tooltip>
                                    </Col>
                                    <Col md={10} style={{ padding: ' 0em', marginLeft: '-0.9em' }}>
                                      <a href={'tel:' + this.state.appointmentDoctor.phone} target="_top">
                                        {Utils.displayUsaPhoneNumberFormate(this.state.appointmentDoctor.phone)}
                                      </a>
                                    </Col>
                                  </Row>
                                )}
                                {this.state.appointmentDoctor.email && (
                                  <Row style={{ padding: '3px', marginLeft: '0em' }}>
                                    <Col md={2} style={{ padding: '0em' }}>
                                      <Tooltip title={this.state.appointmentDoctor.email} aria-label="add">
                                        <EmailIcon />
                                      </Tooltip>
                                    </Col>
                                    <Col md={10} style={{ padding: ' 0em', marginLeft: '-0.9em' }}>
                                      <a href={'mailto:' + this.state.appointmentDoctor.email} target="_top">
                                        {this.state.appointmentDoctor.email}
                                      </a>
                                    </Col>
                                  </Row>
                                )}
                              </Col>
                              <Col
                                md={4}
                                style={{
                                  padding: '7px 20px',
                                  verticalAlign: 'middle',
                                  alignItems: 'center',
                                }}
                              >
                                <a href={'mailto:' + this.state.appointmentDoctor.email} target="_top">
                                  <Tooltip title={this.state.appointmentDoctor.email} aria-label="add">
                                    <MailIcon className="ptsAvtar_icon" />
                                  </Tooltip>
                                </a>
                                <a href={'tel:' + this.state.appointmentDoctor.phone} target="_top">
                                  <Tooltip title={this.state.appointmentDoctor.phone} aria-label="add">
                                    <PhoneIcon className="ptsAvtar_icon" />
                                  </Tooltip>
                                </a>

                                <>
                                  {this.state.isViewButtonHide && (
                                    <>
                                      {this.state.getUpdatePermission && (
                                        <span className="edit" style={{ float: 'right' }}>
                                          <EditIcon
                                            className="ptsAvtar_icon"
                                            id="toAppointmentEdit"
                                            onClick={(e) => this.toAppointmentEdit(e)}
                                          />
                                        </span>
                                      )}
                                    </>
                                  )}
                                </>
                              </Col>
                            </Row>
                          </CardHeader>
                          <CardBody>
                            <Row>
                              <Col md={4}>
                                <span style={{ color: '#000' }}>
                                  <strong>Date</strong>
                                </span>
                              </Col>
                              <Col md={8}>
                                <span style={{ color: '#000' }}>{this.state.appointmentStartDate}</span>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <span style={{ color: '#000' }}>
                                  <strong>Status</strong>
                                </span>
                              </Col>
                              <Col md={8}>
                                <span style={{ color: '#000' }}>{this.state.appointmentStatus} </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <span style={{ color: '#000' }}>
                                  <strong>Purpose</strong>
                                </span>
                              </Col>
                              <Col md={8}>
                                <span style={{ color: '#000' }}>{this.state.appoiPurpose} </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <span style={{ color: '#000' }}>
                                  <strong>Description</strong>
                                </span>
                              </Col>
                              <Col md={8}>
                                <span style={{ color: '#000' }}>
                                  <pre
                                    className="view_AppointmentNote"
                                    style={{ marginLeft: '-1.9px', textAlign: 'justify' }}
                                  >
                                    {this.state.description}
                                  </pre>
                                </span>
                              </Col>
                            </Row>
                            {this.state.appointmentStatus === 'Completed' ||
                            this.state.startDate < this.state.currentCompareDateTime ? (
                              ''
                            ) : (
                              <>
                                {this.state.getAppointmentTransportationCreatePermission === true ? (
                                  <Row>
                                    <Col md={6}>
                                      <span style={{ color: '#000' }}>
                                        <strong>Is Transportation Required?</strong>
                                        &nbsp;{' '}
                                        <Checkbox
                                          checked={this.state.isTransportationChecked}
                                          onChange={(e) => this.isTransportationRequired(e)}
                                          color="primary"
                                          inputprops={{ 'aria-label': 'secondary checkbox' }}
                                        />
                                      </span>
                                    </Col>
                                    <Col md={6}>
                                      {this.state.transportationList.length > 0 ? (
                                        ''
                                      ) : (
                                        <>
                                          {this.state.isTransportationChecked && (
                                            <Row>
                                              <Col md={12}>
                                                <Button
                                                  type="submit"
                                                  style={{
                                                    backgroundColor: '#27AE60',
                                                    color: '#fff',
                                                    fontSize: '0.8em',
                                                  }}
                                                  variant="contained"
                                                  onClick={(e) => this.toTransportationRequest(e, 'appointment')}
                                                >
                                                  Raise Transportation request
                                                </Button>
                                              </Col>
                                            </Row>
                                          )}
                                        </>
                                      )}
                                    </Col>
                                  </Row>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </CardBody>
                        </Card>
                      </>
                    )}
                    {this.state.viewFacilityCard && (
                      <>
                        <Card
                          style={{
                            borderRadius: '20px',
                            height: '100%',
                          }}
                        >
                          <CardHeader
                            style={{
                              borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
                            }}
                          >
                            <Row>
                              <Col md={8}>
                                <label className="appointment_section_header">Facility Appointment</label>
                              </Col>
                              <Col md={4}>
                                {this.state.isViewButtonHide && (
                                  <>
                                    {this.state.getUpdatePermission && (
                                      <span className="edit" style={{ float: 'right' }}>
                                        <EditIcon
                                          className="ptsAvtar_icon"
                                          id="toAppointmentEdit"
                                          onClick={(e) => this.toAppointmentEdit(e)}
                                        />
                                      </span>
                                    )}
                                  </>
                                )}
                              </Col>
                            </Row>
                          </CardHeader>
                          <CardBody>
                            <Row>
                              <Col md={4}>
                                <span style={{ color: '#000' }}>
                                  <strong>Date</strong>
                                </span>
                              </Col>
                              <Col md={8}>
                                <span style={{ color: '#000' }}>{this.state.appointmentStartDate}</span>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <span style={{ color: '#000' }}>
                                  <strong>Name</strong>
                                </span>
                              </Col>
                              <Col md={8}>
                                <span style={{ color: '#000' }}>{this.state.facilityName} </span>
                              </Col>
                            </Row>

                            <Row>
                              <Col md={4}>
                                <span style={{ color: '#000' }}>
                                  <strong>Type</strong>
                                </span>
                              </Col>
                              <Col md={8}>
                                <span style={{ color: '#000' }}>{this.state.viewFacilityType} </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <span style={{ color: '#000' }}>
                                  <strong>Purpose</strong>
                                </span>
                              </Col>
                              <Col md={8}>
                                <span style={{ color: '#000' }}>{this.state.appoiPurpose} </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <span style={{ color: '#000' }}>
                                  <strong>Description</strong>
                                </span>
                              </Col>
                              <Col md={8}>
                                <span style={{ color: '#000' }}>
                                  <pre
                                    className="view_AppointmentNote"
                                    style={{ marginLeft: '-1.9px', textAlign: 'justify' }}
                                  >
                                    {this.state.description}
                                  </pre>
                                </span>
                              </Col>
                            </Row>
                            {this.state.appointmentStatus === 'Completed' ||
                            this.state.startDate < this.state.currentCompareDateTime ? (
                              ''
                            ) : (
                              <>
                                {this.state.getAppointmentTransportationCreatePermission === true ? (
                                  <>
                                    <Row>
                                      <Col md={6}>
                                        {this.state.isViewButtonHide && (
                                          <span style={{ color: '#000' }}>
                                            <strong>Is Transportation Required?</strong>
                                            &nbsp;{' '}
                                            <Checkbox
                                              checked={this.state.isTransportationChecked}
                                              onChange={(e) => this.isTransportationRequired(e)}
                                              color="primary"
                                              inputprops={{ 'aria-label': 'secondary checkbox' }}
                                            />
                                          </span>
                                        )}
                                      </Col>
                                      <Col md={6}>
                                        <>
                                          {this.state.isTransportationChecked && (
                                            <>
                                              <Row>
                                                <Col md={12}>
                                                  <Button
                                                    type="submit"
                                                    style={{
                                                      backgroundColor: '#27AE60',
                                                      color: '#fff',
                                                      fontSize: '0.8em',
                                                    }}
                                                    variant="contained"
                                                    onClick={(e) => this.toTransportationRequest(e, 'appointment')}
                                                  >
                                                    Raise Transportation request
                                                  </Button>
                                                </Col>
                                              </Row>
                                            </>
                                          )}
                                        </>
                                      </Col>
                                    </Row>
                                  </>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </CardBody>
                        </Card>
                      </>
                    )}
                  </Col>

                  <Col md={7} style={{ position: 'relative', padding: '8px' }}>
                    {this.state.showLocationView && (
                      <Card
                        style={{
                          borderRadius: '20px',
                          height: '100%',
                        }}
                      >
                        <CardHeader
                          style={{
                            borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
                            paddingBottom: '0.1em',
                            color: 'rgb(22, 44, 80)',
                            fontWeight: 'bold',
                          }}
                        >
                          &nbsp;<label className="appointment_section_header">Location Details</label>
                        </CardHeader>
                        <CardBody>
                          {this.state.viewDoctorCard && (
                            <>
                              <Row style={{ marginTop: '1em' }}>
                                <Col md={12}>
                                  <Location
                                    ref={this.locationReference}
                                    data={this.state.doctorLocation}
                                    action="simpleView"
                                  />
                                </Col>
                              </Row>
                              {/* <Row md={12} style={{ marginLeft: 'auto' }}>
                                {' '}
                                <Button
                                  type={this.state.type ? this.state.type : ''}
                                  variant="contained"
                                  size="small"
                                  style={{
                                    background: '#717373',
                                    color: '#fff',
                                    marginRight: '1rem',
                                    marginBottom: '1em',
                                  }}
                                  onClick={(e) => this.showMap()}
                                >
                                  Show Map
                                </Button>
                              </Row> */}
                            </>
                          )}
                          {this.state.viewFacilityCard && (
                            <>
                              <Row>
                                <Col md={12}>
                                  <Location
                                    ref={this.locationReference}
                                    data={this.state.facilityAppointmentLocation}
                                    action="simpleView"
                                  />
                                </Col>
                              </Row>
                              {/* <Row md={12} style={{ marginLeft: 'auto' }}>
                                {' '}
                                <Button
                                  type={this.state.type ? this.state.type : ''}
                                  variant="contained"
                                  size="small"
                                  style={{
                                    background: '#717373',
                                    color: '#fff',
                                    marginRight: '1rem',
                                    marginBottom: '1em',
                                  }}
                                  onClick={(e) => this.showMap()}
                                >
                                  Show Map
                                </Button>
                              </Row> */}
                            </>
                          )}
                        </CardBody>
                      </Card>
                    )}

                    {this.state.showMapView && (
                      <Row>
                        <Col md={12}>
                          <Card style={{ height: '100%', borderRadius: '20px' }}>
                            <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                              <Row>
                                <Col md={10}>
                                  <Label style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}>
                                    Location Details
                                  </Label>
                                </Col>
                                <Col md={2}></Col>
                              </Row>
                            </CardHeader>
                            <CardBody>
                              {this.state.viewDoctorCard && (
                                <>
                                  <Row style={{ marginTop: '1em' }}>
                                    <Col md={12}>
                                      <Location
                                        ref={this.locationReference}
                                        data={this.state.doctorLocation}
                                        action="simpleView"
                                      />
                                    </Col>
                                  </Row>
                                </>
                              )}
                              {this.state.viewFacilityCard && (
                                <>
                                  <Row>
                                    <Col md={12}>
                                      <Location
                                        ref={this.locationReference}
                                        data={this.state.facilityAppointmentLocation}
                                        action="simpleView"
                                      />
                                    </Col>
                                  </Row>
                                </>
                              )}
                              <Row style={{ margin: '0em' }}>
                                <Col md={12}>
                                  <Row md={12}>
                                    <Col md={12} style={{ padding: '0em' }}>
                                      {' '}
                                      <span className="closeFloatingButton" aria-label="add">
                                        {' '}
                                        <CloseIcon
                                          onClick={this.closeButton.bind(this)}
                                          style={{ float: 'right', fontSize: '15px', cursor: 'pointer' }}
                                        />
                                      </span>
                                    </Col>
                                  </Row>{' '}
                                  <Row>
                                    <Col md={12}>{this.getMapView(this.state.addressLocations)} </Col>
                                  </Row>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>{' '}
                      </Row>
                    )}
                  </Col>
                </>
              )}

              {this.state.isButtonHide && this.editAppointment()}
            </Row>
            {this.state.showAppointmentNotePermission === true ? (
              <Row style={{ margin: 'auto', marginLeft: '0.6em', marginRight: '0.6em', marginTop: '0.6em' }}>
                <Col md={12}>
                  <Card style={{ width: '100%', borderRadius: '20px', margin: 'auto' }}>
                    <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                      <Row>
                        <Col md={9}>
                          <Label
                            style={{
                              fontWeight: '600',
                            }}
                            className="appointment_section_header"
                            id="noteTitle"
                          >
                            Notes
                          </Label>
                        </Col>

                        <Col md={3} style={{ textAlign: 'end' }}>
                          {this.state.isViewButtonHide && (
                            <>
                              {this.state.getAppointmentNoteCreatePermission && (
                                <Tooltip title="Add Notes" aria-label="add">
                                  <span className="addNote" style={{ float: 'right' }}>
                                    <AddIconButton onClick={this.addAppointmentNotes.bind(this)} btnName="Add Note" />
                                  </span>
                                </Tooltip>
                              )}
                            </>
                          )}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row>{taskNotes}</Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : (
              <></>
            )}

            <Row style={{ margin: 'auto', marginLeft: '0.6em', marginRight: '0.6em', marginTop: '1em' }}>
              <Col md={12}>
                <Card style={{ width: '100%', borderRadius: '20px', margin: 'auto' }}>
                  <CardHeader
                    style={{
                      borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
                    }}
                  >
                    <Row className="">
                      <Col md={9} className=" appointment_section_header">
                        <Label className="reminderTitle" style={{ fontWeight: '600', color: '#0099f1' }}>
                          Reminders
                        </Label>
                      </Col>

                      <Col md={3}>
                        {this.state.startDate < this.state.currentCompareDateTime &&
                        this.state.startDate !== this.state.currentCompareDateTime ? (
                          ''
                        ) : (
                          <>
                            {this.state.isViewButtonHide && (
                              <>
                                {this.state.getCreatePermission && (
                                  <Tooltip title="Add Reminder" aria-label="add">
                                    <span className="addReminder" style={{ float: 'right' }}>
                                      <AddIconButton onClick={this.addReminder.bind(this)} btnName="Add Reminder" />
                                    </span>
                                  </Tooltip>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>{this.state.taskReminderListDiv && <Row>{reminderList}</Row>}</CardBody>
                </Card>
              </Col>
            </Row>
            <Row style={{ position: 'relative', marginLeft: '0.6em', marginRight: '0.6em', marginTop: '1em' }}>
              <Col md={12}>
                <Card style={{ borderRadius: '20px', margin: 'auto' }}>
                  <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                    <Row>
                      <Col md={9} className="">
                        <Label className="referenceAppointmentTitle appointment_section_header">
                          Reference Appointments
                        </Label>
                      </Col>

                      <Col md={3} style={{ textAlign: 'end' }}>
                        {this.state.isViewButtonHide && (
                          <>
                            {this.state.getCreatePermission && (
                              <Tooltip title="Add Appointment" aria-label="add">
                                <span className="addReferenceAppointment" style={{ float: 'right' }}>
                                  <AddIconButton
                                    onClick={this.addAppointmentTask.bind(this)}
                                    btnName="Add Reference Appointment"
                                  />
                                </span>
                              </Tooltip>
                            )}
                          </>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {this.state.referenceAppointmentCreate && (
                      <>
                        <ReferenceAppointment
                          action="create"
                          ref={this.referenceAppointmentRef}
                          history={this.props.history}
                          onCreate={this.addAppointmentTaskInBackend.bind(this)}
                          onCancel={this.cancelAppointmentTask.bind(this)}
                          appointmentId={this.state.id}
                        ></ReferenceAppointment>
                      </>
                    )}
                    {this.state.appointmentTaskList.length > 0 && (
                      <ReferenceAppointment
                        action="list"
                        getDeletePermission={this.state.getDeletePermission}
                        getUpdatePermission={this.state.getUpdatePermission}
                        isPrintView={this.state.isPrintView}
                        history={this.props.history}
                        onSuccess={this.updateAppointmentTaskInBackend.bind(this)}
                        appointmentId={this.state.id}
                        referenceAppointmentList={this.state.appointmentTaskList}
                      />
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* Facility Visit */}
            {this.state.showAppointmentTransportationPermission === true ? (
              <Row
                style={{ position: 'relative', marginLeft: '0.6em', marginRight: '0.6em', marginTop: '1em' }}
                id="isTransportationChecked"
                className="transporation_list"
              >
                <Col md={12}>
                  <Card style={{ borderRadius: '20px', margin: 'auto' }}>
                    <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                      <Row>
                        <Col md={9}>
                          <Label className="transportationTitle appointment_section_header">Transportation</Label>
                        </Col>

                        <Col md={3} style={{ textAlign: 'end' }}>
                          {this.state.isViewButtonHide && (
                            <>
                              {this.state.getAppointmentTransportationCreatePermission &&
                                (this.state.appointmentStatus === 'Completed' ||
                                this.state.startDate < this.state.currentCompareDateTime ? (
                                  ''
                                ) : (
                                  <Tooltip title="Add  Transportation" aria-label="add">
                                    <span className="addTransportation" style={{ float: 'right' }}>
                                      <AddIconButton
                                        onClick={this.addTransportationRequest.bind(this)}
                                        btnName="Add Transportation"
                                      />
                                    </span>
                                  </Tooltip>
                                ))}
                            </>
                          )}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      {this.state.transportationList.length > 0 && (
                        <Transportation
                          transportationAppointmentList={this.state.transportationList}
                          isPrintView={this.state.isPrintView}
                          onTransportationSuccess={this.updateTransportationInBackend.bind(this)}
                          appointmentId={this.state.id}
                          action="list"
                          getCreatePermission={this.state.getAppointmentTransportationCreatePermission}
                          getDeletePermission={this.state.getAppointmentTransportationDeletePermission}
                          getUpdatePermission={this.state.getAppointmentTransportationUpdatePermission}
                          onSuccess={this.getAppointmentDetails.bind(this)}
                        ></Transportation>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : (
              <></>
            )}

            <>
              {this.state.isViewButtonHide && (
                <>
                  <div
                    style={{ textAlign: 'center', padding: '0em 0em 1em', margin: '1em' }}
                    className="btn_appointment_group"
                  >
                    <BackButton
                      type="button"
                      style={{ backgroundColor: '#162c50', color: '#fff', marginBottom: '1em !important' }}
                      variant="contained"
                      page="appointment"
                      startIcon={<CloseIcon />}
                      onClick={(e) => this.viewCancel()}
                    >
                      Back
                    </BackButton>
                    {this.state.getCreatePermission ||
                    this.state.getUpdatePermission ||
                    this.state.getDeletePermission ? (
                      <>
                        {this.state.startDate < this.state.currentCompareDateTime &&
                        this.state.startDate !== this.state.currentCompareDateTime &&
                        this.state.appointmentStatus === 'Scheduled' ? (
                          <>
                            {' '}
                            <Button
                              type="submit"
                              size="small"
                              style={{
                                background: '#1b7189',
                                color: '#fff',
                                marginRight: '1rem',
                                marginBottom: '1em',
                                textTransform: 'capitalize',
                              }}
                              variant="contained"
                              startIcon={<OfflinePinIcon />}
                              onClick={(e) => this.addAppointmentStatusInBackend('Completed')}
                            >
                              {' '}
                              Mark Completed
                            </Button>
                          </>
                        ) : (
                          ''
                        )}
                        {this.state.appointmentStatus === 'Scheduled' ? (
                          <Button
                            type="submit"
                            style={{
                              background: '#1b7189',
                              color: '#fff',
                              marginRight: '1rem',
                              marginBottom: '1em',
                              textTransform: 'capitalize',
                            }}
                            variant="contained"
                            size="small"
                            startIcon={<CancelScheduleSendIcon />}
                            onClick={this.changeAppointmentStatusInBackend.bind(this)}
                          >
                            {' '}
                            Mark Cancelled
                          </Button>
                        ) : (
                          ''
                        )}
                        &nbsp;
                      </>
                    ) : (
                      <></>
                    )}
                    &nbsp;
                    {this.state.previousButton && (
                      <Button
                        type="submit"
                        size="small"
                        style={{
                          background: '#1b7189',
                          color: '#fff',
                          marginRight: '1rem',
                          marginBottom: '1em',
                          textTransform: 'capitalize',
                        }}
                        variant="contained"
                        onClick={(e) => this.previousAppointment(e, this.state.preApptId)}
                      >
                        {' '}
                        Previous Appointment
                      </Button>
                    )}
                    &nbsp;
                    {this.state.appointmentStatus === 'Cancelled' ? (
                      <>
                        {' '}
                        <Button
                          type="submit"
                          size="small"
                          style={{
                            background: '#1b7189',
                            color: '#fff',
                            marginRight: '1rem',
                            marginBottom: '1em',
                            textTransform: 'capitalize',
                          }}
                          variant="contained"
                          onClick={(e) => this.markOpenedAppointmentStatusInBackend()}
                        >
                          {' '}
                          Mark Opened
                        </Button>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </>
              )}
            </>
            {/* // )} */}
          </div>

          <Modal isOpen={this.state.transportationModal} toggle={this.transportationToggle} size="lg">
            <ModalHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }} toggle={this.transportationToggle}>
              <Label style={{ fontWeight: '600', color: '#162c50' }}>Transportation Request</Label>
            </ModalHeader>
            <ModalBody>
              <Row>
                <Transportation
                  ref={this.appointmentTransportationRef}
                  title="Transportation Request"
                  data={this.state.appointmentTransportation}
                  addressData={this.state.appointmentTransportationAddress}
                  transportationDate={this.state.startDate}
                  action="create"
                  pageId={this.state.pageId}
                  onChange={this.handleAppointmentTransportationChange.bind(this)}
                  onTransportationAdd={this.addTransportationInBackend.bind(this)}
                  onUpdate={this.updateTransportationInBackend.bind(this)}
                  onSuccess={this.getAppointmentDetails.bind(this)}
                />
              </Row>
            </ModalBody>
          </Modal>

          <Dialog
            open={this.state.openCancelAppointment}
            onClose={this.handleClose.bind(this)}
            style={{ padding: '1em' }}
          >
            <DialogContent>
              <Row style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                <Col md={12} style={{ textAlign: 'center' }}>
                  <ErrorOutlineIcon
                    style={{
                      border: '2px solid #fff',
                      borderRadius: '25%',
                      background: '#066f72 !important',
                      color: 'red',
                      fontSize: '50px',
                    }}
                  />
                </Col>
              </Row>
              <span style={{ textAlign: 'center' }}>
                <h2 style={{ fontSize: '20px' }}>Do you really want to cancel appointment?</h2>

                <h5 style={{ color: '#0f666b', fontWeight: 'bold', fontSize: '25px' }}>{this.state.text}</h5>

                <h2 style={{ fontSize: '20px' }}>It cannot be recovered once cancelled.</h2>
              </span>
            </DialogContent>
            <DialogActions style={{ marginTop: '1em', padding: '2em', marginLeft: 'auto', marginRight: 'auto' }}>
              <Button
                variant="contained"
                size="large"
                style={{ background: '#0f666b', color: '#fff', marginRight: '1rem' }}
                startIcon={<SaveIcon />}
                onClick={(e) => this.handleCancelAppointmentConfirm('Cancelled ')}
              >
                Confirm
              </Button>
              <Button
                variant="contained"
                size="large"
                style={{ background: '#717373', color: '#fff', marginRight: '1rem' }}
                startIcon={<CloseIcon />}
                onClick={this.handleClose.bind(this)}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.calendarSyncModal}
            onClose={this.calendarSyncToggle.bind(this)}
            style={{ padding: '1em' }}
          >
            <DialogContent>
              <Row>
                <Col md={12} style={{ textAlign: 'center' }}>
                  <h5>
                    Sent to <b style={{ color: '#0f666b' }}>{this.userEmail ? this.userEmail : ''}</b>.
                  </h5>
                  <h5> Check your email for the appointment invite.</h5>
                </Col>
              </Row>
            </DialogContent>
            <DialogActions style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              <Button
                variant="contained"
                size="small"
                style={{ background: '#0f666b', color: '#fff', marginRight: '1rem' }}
                startIcon={<SaveIcon />}
                onClick={(e) => this.calendarSyncToggle()}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    }
  }

  viewAppointmentWithoutAdvertisement() {
    if (this.state.render === false) {
      return <div />;
    } else {
      let referenceAppointmentReminderList = [];
      this.state.referenceAppointmentReminderItems.forEach((element, index) => {
        if ('delete' !== element.operation) {
          referenceAppointmentReminderList.push(
            <Col md={4} style={{ marginTop: '1em' }}>
              <Reminder
                ref={this.appointmentReminderRefList[index]}
                key={index}
                data={element}
                srNo={index}
                reminderDate={this.state.referenceAppointmentStartDate}
                pageId={this.state.pageId}
                action="create"
                getDeletePermission={this.state.getDeletePermission}
                getUpdatePermission={this.state.getUpdatePermission}
                onReminderAdd={this.addReferenceAppointmentNewReminder.bind(this)}
                onDelete={this.referenceAppointmentReminderItemDeleted.bind(this)}
                onUpdate={this.updateReferenceAppointmentReminder.bind(this)}
              />
            </Col>
          );
        }
      });
      let taskNotes = [];
      this.state.appointmentNoteList.forEach((note, index) => {
        if ('delete' !== note.operation) {
          taskNotes.unshift(
            <Col md={6} style={{ marginTop: '1em' }}>
              <AppointmentNote
                ref={this.appointmentNoteRefList[index]}
                key={index}
                data={note}
                srno={index}
                action={note.action ? note.action : 'view'}
                type="appointment"
                noteId={this.state.noteId}
                pageId={this.state.pageId}
                appoinmentId={this.state.id}
                getCreatePermission={this.state.getAppointmentNoteCreatePermission}
                getDeletePermission={this.state.getAppointmentNoteDeletePermission}
                getUpdatePermission={this.state.getAppointmentNoteUpdatePermission}
                onNoteEdit={this.editAppointmentNote.bind(this, note)}
                onNoteDelete={this.deleteAppointmentNote.bind(this)}
                onDelete={this.appointmentNoteRemove.bind(this)}
                onNoteUpdate={this.updateAppointmentNotes.bind(this)}
                onChange={this.appointmentItemChanged.bind(this)}
                addNote={this.addAppointmentNotesInBackend.bind(this)}
                onSuccess={this.getAppointmentDetails.bind(this)}
              />
            </Col>
          );
        }
      });

      let reminderList = [];
      this.state.reminderItems.forEach((reminder, index) => {
        if ('delete' !== reminder.operation) {
          reminderList.push(
            <Col md={4} style={{ marginTop: '1em' }}>
              <Reminder
                ref={this.appointmentReminderRefList[index]}
                key={index}
                reminderDate={this.state.startDate}
                data={reminder}
                srNo={index}
                pageId={this.state.pageId}
                action={reminder.action ? reminder.action : 'view'}
                getDeletePermission={this.state.getDeletePermission}
                getUpdatePermission={this.state.getUpdatePermission}
                onReminderAdd={this.addNewReminderToBackend.bind(this)}
                onCancel={this.deleteReminder.bind(this)}
                onUpdate={this.updateReminder.bind(this)}
                onDelete={this.deleteReminderInBackend.bind(this)}
              />
            </Col>
          );
        }
      });

      let appointmentTaskFacilityList = [];
      this.state.appointmentTaskFacilityList.forEach((facility, index) => {
        appointmentTaskFacilityList.push(
          <Col md={6}>
            <AppointmentFacility
              ref={this.appointmentTaskFacilityRef[index]}
              key={index}
              data={facility}
              startDate={this.state.startDate}
              addressData={this.state.appointmentTransportationAddress}
              srNo={index}
              action="view"
              pageId={this.state.pageId}
              onUpdate={this.updateFacilityTaskInBackend.bind(this)}
              onDelete={this.deleteFacilityTaskInBackend.bind(this)}
            />
          </Col>
        );
      });

      let appointmentTransportationList = [];
      this.state.transportationList.forEach((transportation, index) => {
        appointmentTransportationList.push(
          <Col md={6}>
            <Transportation
              ref={this.appointmentTransportationRef[index]}
              getCreatePermission={this.state.getAppointmentTransportationCreatePermission}
              getDeletePermission={this.state.getAppointmentTransportationDeletePermission}
              getUpdatePermission={this.state.getAppointmentTransportationUpdatePermission}
              pageId={this.state.pageId}
              title="Transportation Request"
              data={transportation}
              key={index}
              srNo={index}
              action="view"
              transportationType="appointment"
              onChange={this.handleAppointmentTransportationChange.bind(this)}
              onDelete={this.deleteTransportation.bind(this)}
              onSuccess={this.getAppointmentDetails.bind(this)}
            />
          </Col>
        );
      });
      return (
        <>
          {this.state.isEditButtonHide && (
            <>
              <>
                {this.state.isViewButtonHide && (
                  <>
                    <MenuWithBreadScrum
                      breadCrumb={this.breadCrumb}
                      history={this.props.history}
                      status={this.state.appointmentStatus}
                      appointmentDate={this.state.startDate}
                      getDeletePermission={this.state.getDeletePermission}
                      getCreatePermission={this.state.getCreatePermission}
                      printCallback={this.printPreviewAppointment.bind(this)}
                      markOpenCallback={this.markOpenedAppointmentStatusInBackend.bind(this)}
                      markCompleteCallback={this.addAppointmentStatusInBackend.bind(this, 'Completed')}
                      backCallback={this.viewCancel.bind(this)}
                      syncToCalenderCallback={this.appointmentSyncToCalenderInBackend.bind(this)}
                      name={'Appointment For' + ' ' + this.memberName}
                      domainObject="Appointment"
                      deleteCallback={this.deleteAppointment.bind(this)}
                      createSimilarCallback={this.repeatAppointment.bind(this)}
                      HierarchyCallback={this.showAppointmentHierarchy.bind(this)}
                      referenceAppointmentLength={this.state.appointmentTaskList.length}
                    />
                  </>
                )}
              </>
              {/* )} */}
            </>
          )}
          {this.state.isButtonHide && (
            <MenuWithBreadScrum
              status={this.state.appointmentStatus}
              appointmentDate={this.state.startDate}
              getDeletePermission={this.state.getDeletePermission}
              getCreatePermission={this.state.getCreatePermission}
              name={'Appointment For' + ' ' + this.memberName}
              domainObject="Appointment"
              breadCrumb={this.breadCrumb}
              history={this.props.history}
            />
          )}

          <div>
            <Row style={{ position: 'relative', marginLeft: '1em', marginRight: '1em', marginTop: '1em' }}>
              {this.state.isEditButtonHide && (
                <>
                  <Col md={5} style={{ position: 'relative', padding: '8px' }}>
                    {this.state.viewDoctorCard === true && (
                      <>
                        <Card
                          style={{
                            borderRadius: '20px',
                            height: '100%',
                          }}
                        >
                          <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0px' }}>
                            <Row>
                              <Col md={8} style={{ paddingLeft: '20px' }}>
                                <img
                                  src={doctor}
                                  className="img-circle"
                                  width="30px"
                                  height="30px"
                                  style={{ marginTop: '0.5em' }}
                                  alt=""
                                />
                                <label
                                  style={{
                                    textTransform: 'capitalize',
                                    paddingLeft: '1em',
                                  }}
                                  className="appointment_section_header"
                                >
                                  {'Dr.' +
                                    ' ' +
                                    this.state.appointmentDoctor.firstName +
                                    ' ' +
                                    this.state.appointmentDoctor.lastName}
                                </label>
                                <h6 className="doctorHeader">{this.state.appointmentDoctor.specialist}</h6>
                                {this.state.appointmentDoctor.phone && (
                                  <Row style={{ padding: '3px', marginLeft: '0em' }}>
                                    <Col md={2} style={{ padding: '0em' }}>
                                      <Tooltip title={this.state.appointmentDoctor.phone} aria-label="add">
                                        <PhoneIcon />
                                      </Tooltip>
                                    </Col>
                                    <Col md={10} style={{ padding: ' 0em', marginLeft: '-0.9em' }}>
                                      <a href={'tel:' + this.state.appointmentDoctor.phone} target="_top">
                                        {Utils.displayUsaPhoneNumberFormate(this.state.appointmentDoctor.phone)}
                                      </a>
                                    </Col>
                                  </Row>
                                )}
                                {this.state.appointmentDoctor.email && (
                                  <Row style={{ padding: '3px', marginLeft: '0em' }}>
                                    <Col md={2} style={{ padding: '0em' }}>
                                      <Tooltip title={this.state.appointmentDoctor.email} aria-label="add">
                                        <EmailIcon />
                                      </Tooltip>
                                    </Col>
                                    <Col md={10} style={{ padding: ' 0em', marginLeft: '-0.9em' }}>
                                      <a href={'mailto:' + this.state.appointmentDoctor.email} target="_top">
                                        {this.state.appointmentDoctor.email}
                                      </a>
                                    </Col>
                                  </Row>
                                )}
                              </Col>
                              <Col
                                md={4}
                                style={{
                                  padding: '7px 20px',
                                  verticalAlign: 'middle',
                                  alignItems: 'center',
                                }}
                              >
                                <a href={'mailto:' + this.state.appointmentDoctor.email} target="_top">
                                  <Tooltip title={this.state.appointmentDoctor.email} aria-label="add">
                                    <MailIcon className="ptsAvtar_icon" />
                                  </Tooltip>
                                </a>
                                <a href={'tel:' + this.state.appointmentDoctor.phone} target="_top">
                                  <Tooltip title={this.state.appointmentDoctor.phone} aria-label="add">
                                    {/* <PhoneIcon className="ptsAvtar_icon" title={this.state.appointmentDoctor.phone} /> */}
                                    <PhoneIcon className="ptsAvtar_icon" />
                                  </Tooltip>
                                </a>

                                <>
                                  {this.state.isViewButtonHide && (
                                    <>
                                      {this.state.getUpdatePermission && (
                                        <span className="edit" style={{ float: 'right' }}>
                                          <EditIcon
                                            className="ptsAvtar_icon"
                                            id="toAppointmentEdit"
                                            onClick={(e) => this.toAppointmentEdit(e)}
                                          />
                                        </span>
                                      )}
                                    </>
                                  )}
                                </>
                              </Col>
                            </Row>
                          </CardHeader>
                          <CardBody>
                            <Row>
                              <Col md={4}>
                                <span style={{ color: '#000' }}>
                                  <strong>Date</strong>
                                </span>
                              </Col>
                              <Col md={8}>
                                <span style={{ color: '#000' }}>{this.state.appointmentStartDate}</span>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <span style={{ color: '#000' }}>
                                  <strong>Status</strong>
                                </span>
                              </Col>
                              <Col md={8}>
                                <span style={{ color: '#000' }}>{this.state.appointmentStatus} </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <span style={{ color: '#000' }}>
                                  <strong>Purpose</strong>
                                </span>
                              </Col>
                              <Col md={8}>
                                <span style={{ color: '#000' }}>{this.state.appoiPurpose} </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <span style={{ color: '#000' }}>
                                  <strong>Description</strong>
                                </span>
                              </Col>
                              <Col md={8}>
                                <span style={{ color: '#000' }}>
                                  <pre
                                    className="view_AppointmentNote"
                                    style={{ marginLeft: '-1.9px', textAlign: 'justify' }}
                                  >
                                    {this.state.description}
                                  </pre>
                                </span>
                              </Col>
                            </Row>
                            {this.state.appointmentStatus === 'Completed' ||
                            this.state.startDate < this.state.currentCompareDateTime ? (
                              ''
                            ) : (
                              <>
                                {this.state.getAppointmentTransportationCreatePermission === true ? (
                                  <Row>
                                    <Col md={6}>
                                      <span style={{ color: '#000' }}>
                                        <strong>Is Transportation Required?</strong>
                                        &nbsp;{' '}
                                        <Checkbox
                                          checked={this.state.isTransportationChecked}
                                          onChange={(e) => this.isTransportationRequired(e)}
                                          color="primary"
                                          inputprops={{ 'aria-label': 'secondary checkbox' }}
                                        />
                                      </span>
                                    </Col>
                                    <Col md={6}>
                                      <>
                                        {this.state.isTransportationChecked && (
                                          <Row>
                                            <Col md={12}>
                                              {/* <a href="#isTransportationChecked"> */}
                                              <Button
                                                type="submit"
                                                style={{
                                                  backgroundColor: '#27AE60',
                                                  color: '#fff',
                                                  fontSize: '0.8em',
                                                }}
                                                variant="contained"
                                                onClick={(e) => this.toTransportationRequest(e, 'appointment')}
                                              >
                                                Raise Transportation request
                                              </Button>
                                              {/* </a> */}
                                            </Col>
                                          </Row>
                                        )}
                                      </>
                                    </Col>
                                  </Row>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </CardBody>
                        </Card>
                      </>
                    )}
                    {this.state.viewFacilityCard && (
                      <>
                        <Card
                          style={{
                            borderRadius: '20px',
                            height: '100%',
                          }}
                        >
                          <CardHeader
                            style={{
                              borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
                            }}
                          >
                            <Row>
                              <Col md={8}>
                                <label className="appointment_section_header">Facility Appointment</label>
                              </Col>
                              <Col md={4}>
                                {this.state.isViewButtonHide && (
                                  <>
                                    {this.state.getUpdatePermission && (
                                      <span className="edit" style={{ float: 'right' }}>
                                        <EditIcon
                                          className="ptsAvtar_icon"
                                          id="toAppointmentEdit"
                                          onClick={(e) => this.toAppointmentEdit(e)}
                                        />
                                      </span>
                                    )}
                                  </>
                                )}
                              </Col>
                            </Row>
                          </CardHeader>
                          <CardBody>
                            <Row>
                              <Col md={4}>
                                <span style={{ color: '#000' }}>
                                  <strong>Date</strong>
                                </span>
                              </Col>
                              <Col md={8}>
                                <span style={{ color: '#000' }}>{this.state.appointmentStartDate}</span>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <span style={{ color: '#000' }}>
                                  <strong>Name</strong>
                                </span>
                              </Col>
                              <Col md={8}>
                                <span style={{ color: '#000' }}>{this.state.facilityName} </span>
                              </Col>
                            </Row>

                            <Row>
                              <Col md={4}>
                                <span style={{ color: '#000' }}>
                                  <strong>Type</strong>
                                </span>
                              </Col>
                              <Col md={8}>
                                <span style={{ color: '#000' }}>{this.state.viewFacilityType} </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <span style={{ color: '#000' }}>
                                  <strong>Purpose</strong>
                                </span>
                              </Col>
                              <Col md={8}>
                                <span style={{ color: '#000' }}>{this.state.appoiPurpose} </span>
                              </Col>
                            </Row>
                            <Row>
                              <Col md={4}>
                                <span style={{ color: '#000' }}>
                                  <strong>Description</strong>
                                </span>
                              </Col>
                              <Col md={8}>
                                <span style={{ color: '#000' }}>
                                  <pre
                                    className="view_AppointmentNote"
                                    style={{ marginLeft: '-1.9px', textAlign: 'justify' }}
                                  >
                                    {this.state.description}
                                  </pre>
                                </span>
                              </Col>
                            </Row>
                            {this.state.appointmentStatus === 'Completed' ||
                            this.state.startDate < this.state.currentCompareDateTime ? (
                              ''
                            ) : (
                              <>
                                {this.state.getAppointmentTransportationCreatePermission === true ? (
                                  <Row>
                                    <Col md={6}>
                                      {this.state.isViewButtonHide && (
                                        <span style={{ color: '#000' }}>
                                          <strong>Is Transportation Required?</strong>
                                          &nbsp;{' '}
                                          <Checkbox
                                            checked={this.state.isTransportationChecked}
                                            onChange={(e) => this.isTransportationRequired(e)}
                                            color="primary"
                                            inputprops={{ 'aria-label': 'secondary checkbox' }}
                                          />
                                        </span>
                                      )}
                                    </Col>
                                    <Col md={6}>
                                      <>
                                        {this.state.isTransportationChecked && (
                                          <Row>
                                            <Col md={12}>
                                              {/* <a href="#isTransportationChecked"> */}
                                              <Button
                                                type="submit"
                                                style={{
                                                  backgroundColor: '#27AE60',
                                                  color: '#fff',
                                                  fontSize: '0.8em',
                                                }}
                                                variant="contained"
                                                onClick={(e) => this.toTransportationRequest(e, 'appointment')}
                                              >
                                                Raise Transportation request
                                              </Button>
                                              {/* </a> */}
                                            </Col>
                                          </Row>
                                        )}
                                      </>
                                      {/* )} */}
                                    </Col>
                                  </Row>
                                ) : (
                                  <></>
                                )}
                              </>
                            )}
                          </CardBody>
                        </Card>
                      </>
                    )}
                  </Col>
                  <Col md={7} style={{ position: 'relative', padding: '8px' }}>
                    {this.state.showLocationView && (
                      <Card
                        style={{
                          borderRadius: '20px',
                          height: '100%',
                        }}
                      >
                        <CardHeader
                          style={{
                            borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
                            paddingBottom: '0.1em',
                            color: 'rgb(22, 44, 80)',
                            fontWeight: 'bold',
                          }}
                        >
                          {/* <LocalHospitalIcon /> */}
                          &nbsp;<label className="appointment_section_header">Location Details</label>
                        </CardHeader>
                        <CardBody>
                          {this.state.viewDoctorCard && (
                            <>
                              <Row style={{ marginTop: '1em' }}>
                                <Col md={12}>
                                  <Location
                                    ref={this.locationReference}
                                    data={this.state.doctorLocation}
                                    action="simpleView"
                                  />
                                </Col>
                              </Row>
                              {/* <Row md={12} style={{ marginLeft: 'auto' }}>
                                {' '}
                                <Button
                                  type={this.state.type ? this.state.type : ''}
                                  variant="contained"
                                  size="small"
                                  style={{
                                    background: '#717373',
                                    color: '#fff',
                                    marginRight: '1rem',
                                    marginBottom: '1em',
                                  }}
                                  onClick={(e) => this.showMap()}
                                >
                                  Show Map
                                </Button>
                              </Row> */}
                            </>
                          )}
                          {this.state.viewFacilityCard && (
                            <>
                              <Row>
                                <Col md={12}>
                                  <Location
                                    ref={this.locationReference}
                                    data={this.state.facilityAppointmentLocation}
                                    action="simpleView"
                                  />
                                </Col>
                              </Row>
                              {/* <Row md={12} style={{ marginLeft: 'auto' }}>
                                {' '}
                                <Button
                                  type={this.state.type ? this.state.type : ''}
                                  variant="contained"
                                  size="small"
                                  style={{
                                    background: '#717373',
                                    color: '#fff',
                                    marginRight: '1rem',
                                    marginBottom: '1em',
                                  }}
                                  onClick={(e) => this.showMap()}
                                >
                                  Show Map
                                </Button>
                              </Row> */}
                            </>
                          )}
                        </CardBody>
                      </Card>
                    )}

                    {this.state.showMapView && (
                      <Row>
                        <Col md={12}>
                          <Card style={{ height: '100%', borderRadius: '20px' }}>
                            <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                              <Row>
                                <Col md={10}>
                                  <Label style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}>
                                    Location Details
                                  </Label>
                                </Col>
                                <Col md={2}></Col>
                              </Row>
                            </CardHeader>
                            <CardBody>
                              {/* <Row>
                              <Col md={12}>
                                <Location action="view" pageId="incident" data={this.state.locationDetails} />
                              </Col>
                            </Row> */}
                              {this.state.viewDoctorCard && (
                                <>
                                  <Row style={{ marginTop: '1em' }}>
                                    <Col md={12}>
                                      <Location
                                        ref={this.locationReference}
                                        data={this.state.doctorLocation}
                                        action="simpleView"
                                      />
                                    </Col>
                                  </Row>
                                </>
                              )}
                              {this.state.viewFacilityCard && (
                                <>
                                  <Row>
                                    <Col md={12}>
                                      <Location
                                        ref={this.locationReference}
                                        data={this.state.facilityAppointmentLocation}
                                        action="simpleView"
                                      />
                                    </Col>
                                  </Row>
                                </>
                              )}
                              <Row style={{ margin: '0em' }}>
                                <Col md={12}>
                                  <Row md={12}>
                                    <Col md={12} style={{ padding: '0em' }}>
                                      {' '}
                                      <span className="closeFloatingButton" aria-label="add">
                                        {' '}
                                        <CloseIcon
                                          onClick={this.closeButton.bind(this)}
                                          style={{ float: 'right', fontSize: '15px', cursor: 'pointer' }}
                                        />
                                      </span>
                                    </Col>
                                  </Row>{' '}
                                  <Row>
                                    {/* <Card style={{ width: '100%', padding: '3em' }}>
                                <CardBody> */}
                                    <Col md={12}>{this.getMapView(this.state.addressLocations)} </Col>
                                    {/* </CardBody>
                              </Card> */}
                                  </Row>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        </Col>{' '}
                      </Row>
                    )}
                  </Col>
                </>
              )}

              {this.state.isButtonHide && this.editAppointment()}
            </Row>
            {this.state.showAppointmentNotePermission === true ? (
              <Row style={{ margin: 'auto', marginLeft: '0.6em', marginRight: '0.6em', marginTop: '0.6em' }}>
                <Col md={12}>
                  <Card style={{ width: '100%', borderRadius: '20px', margin: 'auto' }}>
                    <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                      <Row>
                        <Col md={9}>
                          <Label
                            style={{
                              fontWeight: '600',
                            }}
                            className="appointment_section_header"
                            id="noteTitle"
                          >
                            Notes
                          </Label>
                        </Col>

                        <Col md={3} style={{ textAlign: 'end' }}>
                          {this.state.isViewButtonHide && (
                            <>
                              {this.state.getAppointmentNoteCreatePermission && (
                                <Tooltip title="Add Notes" aria-label="add">
                                  <span className="addNote" style={{ float: 'right' }}>
                                    <AddIconButton onClick={this.addAppointmentNotes.bind(this)} btnName="Add Note" />
                                  </span>
                                </Tooltip>
                              )}
                            </>
                          )}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      <Row>{taskNotes}</Row>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : (
              <></>
            )}
            <Row style={{ margin: 'auto', marginLeft: '0.6em', marginRight: '0.6em', marginTop: '1em' }}>
              <Col md={12}>
                <Card style={{ width: '100%', borderRadius: '20px', margin: 'auto' }}>
                  <CardHeader
                    style={{
                      borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
                    }}
                  >
                    <Row className="">
                      <Col md={9} className="appointment_section_header">
                        <Label className="reminderTitle" style={{ fontWeight: '600', color: '#0099f1' }}>
                          Reminders
                        </Label>
                      </Col>

                      <Col md={3}>
                        {this.state.startDate < this.state.currentCompareDateTime &&
                        this.state.startDate !== this.state.currentCompareDateTime ? (
                          ''
                        ) : (
                          <>
                            {this.state.isViewButtonHide && (
                              <>
                                {this.state.getCreatePermission && (
                                  <Tooltip title="Add Reminder" aria-label="add">
                                    <span className="addReminder">
                                      <AddIconButton onClick={this.addReminder.bind(this)} btnName="Add Reminder" />
                                    </span>
                                  </Tooltip>
                                )}
                              </>
                            )}
                          </>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>{reminderList}</Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row style={{ position: 'relative', marginLeft: '0.6em', marginRight: '0.6em', marginTop: '1em' }}>
              <Col md={12}>
                <Card style={{ borderRadius: '20px', margin: 'auto' }}>
                  <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                    <Row>
                      <Col md={9}>
                        <Label className="referenceAppointmentTitle appointment_section_header">
                          Reference Appointments
                        </Label>
                      </Col>

                      <Col md={3} style={{ textAlign: 'end' }}>
                        {this.state.isViewButtonHide && (
                          <>
                            {this.state.getCreatePermission && (
                              <Tooltip title="Add Appointment" aria-label="add">
                                <span className="addReferenceAppointment" style={{ float: 'right' }}>
                                  <AddIconButton
                                    onClick={this.addAppointmentTask.bind(this)}
                                    btnName="Add Reference Appointment"
                                  />
                                </span>
                              </Tooltip>
                            )}
                          </>
                        )}
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {this.state.referenceAppointmentCreate && (
                      <>
                        <ReferenceAppointment
                          action="create"
                          history={this.props.history}
                          ref={this.referenceAppointmentRef}
                          onCreate={this.addAppointmentTaskInBackend.bind(this)}
                          onCancel={this.cancelAppointmentTask.bind(this)}
                          appointmentId={this.state.id}
                        ></ReferenceAppointment>
                      </>
                    )}
                    {this.state.appointmentTaskList.length > 0 && (
                      <ReferenceAppointment
                        action="list"
                        history={this.props.history}
                        isPrintView={this.state.isPrintView}
                        getDeletePermission={this.state.getDeletePermission}
                        getUpdatePermission={this.state.getUpdatePermission}
                        onSuccess={this.updateAppointmentTaskInBackend.bind(this)}
                        appointmentId={this.state.id}
                        referenceAppointmentList={this.state.appointmentTaskList}
                      />
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>

            {/* Facility Visit */}
            {this.state.showAppointmentTransportationPermission === true ? (
              <Row
                style={{ position: 'relative', marginLeft: '0.6em', marginRight: '0.6em', marginTop: '1em' }}
                id="isTransportationChecked"
                className="transporation_list"
              >
                <Col md={12}>
                  <Card style={{ borderRadius: '20px' }}>
                    <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                      <Row>
                        <Col md={9}>
                          <Label style={{}} className="transportationTitle appointment_section_header">
                            Transportation
                          </Label>
                        </Col>

                        <Col md={3} style={{ textAlign: 'end' }}>
                          {this.state.isViewButtonHide && (
                            <>
                              {this.state.getAppointmentTransportationCreatePermission &&
                                (this.state.appointmentStatus === 'Completed' ||
                                this.state.startDate < this.state.currentCompareDateTime ? (
                                  ' '
                                ) : (
                                  <Tooltip title="Add  Transportation" aria-label="add">
                                    <span className="addTransportation" style={{ float: 'right' }}>
                                      <AddIconButton
                                        onClick={this.addTransportationRequest.bind(this)}
                                        btnName="Add Transportation"
                                      />
                                    </span>
                                  </Tooltip>
                                ))}
                            </>
                          )}
                        </Col>
                      </Row>
                    </CardHeader>
                    <CardBody>
                      {this.state.transportationList.length > 0 && (
                        <Transportation
                          transportationAppointmentList={this.state.transportationList}
                          isPrintView={this.state.isPrintView}
                          getCreatePermission={this.state.getAppointmentTransportationCreatePermission}
                          getDeletePermission={this.state.getAppointmentTransportationDeletePermission}
                          getUpdatePermission={this.state.getAppointmentTransportationUpdatePermission}
                          onTransportationSuccess={this.updateTransportationInBackend.bind(this)}
                          appointmentId={this.state.id}
                          action="list"
                          onSuccess={this.getAppointmentDetails.bind(this)}
                        ></Transportation>
                      )}
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            ) : (
              <></>
            )}
            <>
              {this.state.isViewButtonHide && (
                <>
                  <div style={{ textAlign: 'center', padding: '0em 0em 1em' }} className="btn_appointment_group">
                    <BackButton
                      type="button"
                      style={{ backgroundColor: '#162c50', color: '#fff', marginBottom: '1em !important' }}
                      variant="contained"
                      startIcon={<CloseIcon />}
                      page="appointment"
                      onClick={(e) => this.viewCancel()}
                    >
                      Back
                    </BackButton>
                    {this.state.getCreatePermission ||
                    this.state.getUpdatePermission ||
                    this.state.getDeletePermission ? (
                      <>
                        {this.state.startDate < this.state.currentCompareDateTime &&
                        this.state.startDate !== this.state.currentCompareDateTime &&
                        this.state.appointmentStatus === 'Scheduled' ? (
                          <>
                            {' '}
                            <Button
                              type="submit"
                              size="small"
                              style={{
                                background: '#1b7189',
                                color: '#fff',
                                marginRight: '1rem',
                                marginBottom: '1em',
                                textTransform: 'capitalize',
                              }}
                              variant="contained"
                              onClick={(e) => this.addAppointmentStatusInBackend('Completed')}
                              startIcon={<OfflinePinIcon />}
                            >
                              {' '}
                              Mark Completed
                            </Button>
                          </>
                        ) : (
                          ''
                        )}
                        {this.state.appointmentStatus === 'Scheduled' ? (
                          <Button
                            type="submit"
                            style={{
                              background: '#1b7189',
                              color: '#fff',
                              marginRight: '1rem',
                              marginBottom: '1em',
                              textTransform: 'capitalize',
                            }}
                            variant="contained"
                            size="small"
                            startIcon={<CancelScheduleSendIcon />}
                            onClick={this.changeAppointmentStatusInBackend.bind(this)}
                          >
                            {' '}
                            Mark Cancelled
                          </Button>
                        ) : (
                          ''
                        )}
                        &nbsp;
                      </>
                    ) : (
                      <></>
                    )}
                    &nbsp;
                    {this.state.previousButton && (
                      <Button
                        type="submit"
                        size="small"
                        style={{
                          background: '#1b7189',
                          color: '#fff',
                          marginRight: '1rem',
                          marginBottom: '1em',
                          textTransform: 'capitalize',
                        }}
                        variant="contained"
                        onClick={(e) => this.previousAppointment(e, this.state.preApptId)}
                      >
                        {' '}
                        Previous Appointment
                      </Button>
                    )}
                    &nbsp;
                    {this.state.appointmentStatus === 'Cancelled' ? (
                      <>
                        {' '}
                        <Button
                          type="submit"
                          size="small"
                          style={{
                            background: '#1b7189',
                            color: '#fff',
                            marginRight: '1rem',
                            marginBottom: '1em',
                            textTransform: 'capitalize',
                          }}
                          variant="contained"
                          // startIcon={<SaveIcon />}
                          onClick={(e) => this.markOpenedAppointmentStatusInBackend()}
                        >
                          {' '}
                          Mark Opened
                        </Button>
                      </>
                    ) : (
                      ''
                    )}
                  </div>
                </>
              )}
            </>
          </div>

          <Modal isOpen={this.state.transportationModal} toggle={this.transportationToggle} size="lg">
            <ModalHeader toggle={this.transportationToggle}>
              <b>Transportation Request</b>
            </ModalHeader>
            <ModalBody>
              <Row>
                <Transportation
                  ref={this.appointmentTransportationRef}
                  title="Transportation Request"
                  data={this.state.appointmentTransportation}
                  addressData={this.state.appointmentTransportationAddress}
                  transportationDate={this.state.startDate}
                  action="create"
                  pageId={this.state.pageId}
                  onChange={this.handleAppointmentTransportationChange.bind(this)}
                  onTransportationAdd={this.addTransportationInBackend.bind(this)}
                  onUpdate={this.updateTransportationInBackend.bind(this)}
                />
              </Row>
            </ModalBody>
          </Modal>
          <Dialog
            open={this.state.openCancelAppointment}
            onClose={this.handleClose.bind(this)}
            // onHide={this.close}
            style={{ padding: '1em' }}
          >
            <DialogContent>
              <Row style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                <Col md={12} style={{ textAlign: 'center' }}>
                  <ErrorOutlineIcon
                    style={{
                      border: '2px solid #fff',
                      borderRadius: '25%',
                      background: '#066f72 !important',
                      color: 'red',
                      fontSize: '50px',
                    }}
                  />
                </Col>
              </Row>
              <span style={{ textAlign: 'center' }}>
                <h2 style={{ fontSize: '20px' }}>Do you really want to cancel appointment?</h2>

                <h5 style={{ color: '#0f666b', fontWeight: 'bold', fontSize: '25px' }}>{this.state.text}</h5>

                <h2 style={{ fontSize: '20px' }}>It cannot be recovered once cancelled.</h2>
              </span>
            </DialogContent>
            <DialogActions style={{ marginTop: '1em', padding: '2em', marginLeft: 'auto', marginRight: 'auto' }}>
              <Button
                variant="contained"
                size="large"
                style={{ background: '#0f666b', color: '#fff', marginRight: '1rem' }}
                startIcon={<SaveIcon />}
                onClick={(e) => this.handleCancelAppointmentConfirm('Cancelled ')}
              >
                Confirm
              </Button>
              <Button
                variant="contained"
                size="large"
                style={{ background: '#717373', color: '#fff', marginRight: '1rem' }}
                startIcon={<CloseIcon />}
                onClick={this.handleClose.bind(this)}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.calendarSyncModal}
            onClose={this.calendarSyncToggle.bind(this)}
            style={{ padding: '1em' }}
          >
            <DialogContent>
              <Row>
                <Col md={12} style={{ textAlign: 'center' }}>
                  <h5>
                    Sent to <b style={{ color: '#0f666b' }}>{this.userEmail ? this.userEmail : ''}</b>.
                  </h5>
                  <h5> Check your email for the appointment invite.</h5>
                </Col>
              </Row>
            </DialogContent>
            <DialogActions style={{ marginLeft: 'auto', marginRight: 'auto' }}>
              <Button
                variant="contained"
                size="small"
                style={{ background: '#0f666b', color: '#fff', marginRight: '1rem' }}
                startIcon={<SaveIcon />}
                onClick={(e) => this.calendarSyncToggle()}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    }
  }

  editAppointment() {
    return (
      <>
        <Col md={5}>
          <Card md={12} style={{ borderRadius: '20px', height: '99%' }}>
            <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', marginTop: '0.5em' }}>
              {undefined !== this.state.title && this.state.title.length > 0 && (
                <Label
                  style={{
                    fontWeight: '600',
                    color: '#162c50',
                    marginLeft: '0.5em',
                  }}
                >
                  {this.state.title}
                </Label>
              )}
            </CardHeader>
            <CardBody>
              <Row md={12} style={{ padding: '0.5em' }}>
                <div md={12} style={{ width: '100%' }}>
                  <Row className="dateRow" style={{ marginBottom: '0.9em' }}>
                    <Col md={6} style={{ marginBottom: '0.5em' }}>
                      <FormControl variant="outlined" fullWidth>
                        <InputLabel id="appointmentType-label">Appointment Type*</InputLabel>
                        <Select
                          id="appointmentType"
                          value={this.state.appointmentType}
                          label="Appointment Type"
                          required
                          onChange={(e) => {
                            this.handleAppointmentTypeChange(e);
                          }}
                        >
                          {this.appointmentTypeListArray.map((event, keyIndex) => {
                            return (
                              <MenuItem key={keyIndex} value={event.type}>
                                {event.type}
                              </MenuItem>
                            );
                          })}
                        </Select>
                      </FormControl>
                    </Col>
                    {this.state.isDoctor && (
                      <>
                        <Col md={6} style={{ marginTop: '-1em' }}>
                          <Autocomplete
                            id="Role1"
                            options={this.state.doctorLocationList}
                            value={this.state.doctor}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, value) => {
                              this.handleDoctorChange(value);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} variant="outlined" label="Doctor" margin="normal" required />
                            )}
                          />
                        </Col>
                      </>
                    )}

                    {this.state.isFacility && (
                      <>
                        <Col md={6} style={{ marginTop: '-1em' }}>
                          <Autocomplete
                            id="Role"
                            options={this.state.facilityLocationList}
                            value={this.state.facilityLocation}
                            getOptionLabel={(option) => option.name}
                            onChange={(event, value) => {
                              this.handleFacilityLocationChange(value);
                            }}
                            renderInput={(params) => (
                              <TextField {...params} variant="outlined" label="Facility" margin="normal" required />
                            )}
                          />
                        </Col>
                      </>
                    )}
                  </Row>
                  <Row style={{ marginBottom: '0.9em' }}>
                    <Col md={6}>
                      {/* <DatePicker
                        selected={this.state.startDate}
                        onChange={this.handleStartDateChange.bind(this)}
                        timeInputLabel="Time:"
                        dateFormat="MM/dd/yyyy h:mm aa"
                        placeholderText="Date*"
                        required
                        showTimeInput
                      /> */}
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ThemeProvider theme={materialTheme}>
                          <DateTimePicker
                            value={this.state.startDate}
                            variant="outlined"
                            label="Date"
                            fullWidth
                            className="ptsDateTimePicker"
                            // margin="normal"
                            inputVariant="outlined"
                            timeInputLabel="Time:"
                            // minDate={subDays(new Date(), 1)}
                            format="MM/dd/yyyy  h:mm aa"
                            style={{ background: '#fff' }}
                            onChange={this.handleStartDateChange.bind(this)}
                            // renderInput={(props) => (
                            //   <TextField
                            //     {...props}
                            //     variant="outlined"
                            //     label="Date"
                            //     margin="normal"
                            //     required
                            //     style={{ background: '#fff' }}
                            //   />
                            // )}
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>
                    </Col>
                    <Col md={6} style={{ marginBottom: '0.5em' }}>
                      <input
                        className="pts_dataList_dropDown"
                        type="text"
                        id="demo-simple-select-outlined"
                        list="data"
                        required
                        placeholder="Appointment Purpose*"
                        label="Appointment Purpose"
                        value={this.state.appoiPurpose}
                        onChange={(event, value) => {
                          this.handleAppoiPurposeChange(event, value);
                        }}
                      />
                      <datalist id="data">
                        {this.appointmentPurposeListData.map((event, key) => (
                          <option key={event.purpose} value={event.purpose} />
                        ))}
                      </datalist>
                    </Col>
                  </Row>

                  <Row style={{ marginBottom: '0.9em' }}>
                    <Col md={12}>
                      <TextareaAutosize
                        aria-label="minimum height"
                        style={{ width: '100%', padding: '10px' }}
                        minRows={3}
                        id={'note' + this.state.index}
                        value={this.state.description}
                        name="note"
                        inputprops={{ maxLength: 2048 }}
                        onChange={this.handleDescriptionChange.bind(this)}
                        // onPressEnter={this.handleDescriptionChange.bind(this)}
                        placeholder="Description"
                      />
                    </Col>
                  </Row>
                </div>
              </Row>
              {this.state.appointmentStatus === 'Completed' ||
              this.state.startDate < this.state.currentCompareDateTime ? (
                ''
              ) : (
                <>
                  {this.state.getAppointmentTransportationCreatePermission === true ? (
                    <Row>
                      <Col md={6}>
                        {this.state.isViewButtonHide && (
                          <span style={{ color: '#000' }}>
                            <strong>Is Transportation Required?</strong>
                            &nbsp;{' '}
                            <Checkbox
                              checked={this.state.isTransportationChecked}
                              onChange={(e) => this.isTransportationRequired(e)}
                              color="primary"
                              inputprops={{ 'aria-label': 'secondary checkbox' }}
                            />
                          </span>
                        )}
                      </Col>
                    </Row>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </CardBody>
            <CardFooter>
              <div style={{ textAlign: 'center', padding: '0em 0em 1em 0em' }}>
                <SaveButton type="submit" onClick={(e) => this.updateAppointmentInBackend()} />
                &nbsp;
                <CancelIconButton onClick={(e) => this.cancelViewAction(e, this.state.appoinmentId)} />
              </div>
            </CardFooter>
          </Card>
        </Col>
        <Col md={6} style={{ position: 'relative', padding: '5px' }}>
          <Card
            md={12}
            style={{
              height: '100%',
              borderRadius: '20px',
            }}
          >
            <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
              <Row>
                <Col md={11}>
                  <Label
                    style={{
                      marginLeft: '0.5em',
                    }}
                    className="appointment_section_header"
                  >
                    Location Details
                  </Label>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              {this.state.isDoctorLocation && (
                <Row style={{ marginTop: '1em' }}>
                  <Col md={12}>
                    <Location data={this.state.selectedDoctorLocation} action="simpleView" />
                  </Col>
                </Row>
              )}
            </CardBody>
          </Card>
        </Col>
        <Dialog
          open={this.state.sameDateAppointmentWindow}
          onClose={this.handleSameDateAppointmentCreateWindowClose.bind(this)}
          onHide={this.close}
          style={{ padding: '1em' }}
        >
          <DialogContent style={{ marginTop: '2em' }}>
            <Row>
              <Col md={12} style={{ textAlign: 'center' }}>
                <h5>{this.state.WarningMessage}</h5>
                <br />
                {this.state.WarningMessage ? <h5>Do you still want to continue to create?</h5> : ''}
              </Col>
            </Row>
          </DialogContent>
          <DialogActions style={{ marginTop: '0em', padding: '1em', marginLeft: 'auto', marginRight: 'auto' }}>
            <Button
              variant="contained"
              size="large"
              style={{ background: '#0f666b', color: '#fff', marginRight: '1rem' }}
              startIcon={<SaveIcon />}
              onClick={this.updateSameAppointmentInBackend.bind(this)}
            >
              Ok
            </Button>
            <Button
              variant="contained"
              size="large"
              style={{ background: '#717373', color: '#fff', marginRight: '1rem' }}
              startIcon={<CloseIcon />}
              onClick={this.handleSameDateAppointmentCreateWindowClose.bind(this)}
            >
              Cancel
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  printAppointment() {
    let referenceAppointmentReminderList = [];
    this.state.referenceAppointmentReminderItems.forEach((element, index) => {
      if ('delete' !== element.operation) {
        referenceAppointmentReminderList.push(
          <Col md={4} style={{ marginTop: '1em' }}>
            <Reminder
              ref={this.appointmentReminderRefList[index]}
              key={index}
              data={element}
              srNo={index}
              reminderDate={this.state.referenceAppointmentStartDate}
              pageId={this.state.pageId}
              action="create"
              onReminderAdd={this.addReferenceAppointmentNewReminder.bind(this)}
              onDelete={this.referenceAppointmentReminderItemDeleted.bind(this)}
              onUpdate={this.updateReferenceAppointmentReminder.bind(this)}
            />
          </Col>
        );
      }
    });
    let taskNotes = [];
    this.state.appointmentNoteList.forEach((note, index) => {
      if ('delete' !== note.operation) {
        taskNotes.unshift(
          <Col md={6} style={{ marginTop: '1em' }}>
            <AppointmentNote
              ref={this.appointmentNoteRefList[index]}
              key={index}
              data={note}
              srno={index}
              action="PrintPreviewNote"
              type="appointment"
              isPrintView="view_print"
              noteId={this.state.noteId}
              pageId={this.state.pageId}
              appoinmentId={this.state.id}
              getCreatePermission={false}
              getDeletePermission={false}
              getUpdatePermission={false}
              onNoteEdit={this.editAppointmentNote.bind(this, note)}
              onNoteDelete={this.deleteAppointmentNote.bind(this)}
              onDelete={this.appointmentNoteRemove.bind(this)}
              onNoteUpdate={this.updateAppointmentNotes.bind(this)}
              onChange={this.appointmentItemChanged.bind(this)}
              addNote={this.addAppointmentNotesInBackend.bind(this)}
              onSuccess={this.getAppointmentDetails.bind(this)}
            />
          </Col>
        );
      }
    });

    let reminderList = [];
    this.state.reminderItems.forEach((reminder, index) => {
      this.appointmentReminderRefList[index] = React.createRef();
      if ('delete' !== reminder.operation) {
        reminderList.push(
          <Col md={4} style={{ marginTop: '1em' }}>
            <Reminder
              ref={this.appointmentReminderRefList[index]}
              key={index}
              reminderDate={this.state.startDate}
              data={reminder}
              srNo={index}
              pageId={this.state.pageId}
              isPrintView="view_print"
              action={reminder.action ? reminder.action : 'view'}
              onReminderAdd={this.addNewReminderToBackend.bind(this)}
              onCancel={this.deleteReminder.bind(this)}
              onUpdate={this.updateReminder.bind(this)}
              onDelete={this.deleteReminderInBackend.bind(this)}
            />
          </Col>
        );
      }
    });

    let appointmentTaskFacilityList = [];
    this.state.appointmentTaskFacilityList.forEach((facility, index) => {
      appointmentTaskFacilityList.push(
        <Col md={6}>
          <AppointmentFacility
            ref={this.appointmentTaskFacilityRef[index]}
            key={index}
            data={facility}
            startDate={this.state.startDate}
            addressData={this.state.appointmentTransportationAddress}
            srNo={index}
            action="view"
            isPrintView="view_print"
            pageId={this.state.pageId}
            onUpdate={this.updateFacilityTaskInBackend.bind(this)}
            onDelete={this.deleteFacilityTaskInBackend.bind(this)}
          />
        </Col>
      );
    });

    let appointmentTransportationList = [];
    this.state.transportationList.forEach((transportation, index) => {
      appointmentTransportationList.push(
        <Col md={6}>
          <Transportation
            ref={this.appointmentTransportationRef[index]}
            pageId={this.state.pageId}
            getCreatePermission={this.state.getAppointmentTransportationCreatePermission}
            getDeletePermission={this.state.getAppointmentTransportationDeletePermission}
            getUpdatePermission={this.state.getAppointmentTransportationUpdatePermission}
            title="Transportation Request"
            data={transportation}
            key={index}
            srNo={index}
            action="view"
            isPrintView="view_print"
            transportationType="appointment"
            onChange={this.handleAppointmentTransportationChange.bind(this)}
            onDelete={this.deleteTransportation.bind(this)}
            onSuccess={this.getAppointmentDetails.bind(this)}
          />
        </Col>
      );
    });
    return (
      <>
        {this.state.isPrintView === 'view_print' ? (
          <>
            <Row md={12} className={this.state.headerClassName}>
              <Col md={4}>
                <h5 style={{ textTransform: 'capitalize' }}>
                  <b>{this.title + ' For ' + this.memberName}</b>
                </h5>
              </Col>
              <Col md={8} style={{ textTransform: 'capitalize' }}></Col>
            </Row>
          </>
        ) : (
          <MenuWithBreadScrum
            backCallback={this.viewPrintCancel.bind(this)}
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            name={'Print Preview of Appointment' + ' For ' + this.memberName}
            domainObject="Appointment"
          />
        )}
        {this.state.isViewButtonHide && (
          <Row>
            <Col md={12}>
              <ReactToPrint
                trigger={() => (
                  <span style={{ cursor: 'pointer', fontSize: 'x-larger', marginRight: '2em', float: 'right' }}>
                    <Tooltip title="Print">
                      <Button
                        className="add"
                        style={{
                          background: '#867e7e',
                          color: 'white',
                          fontSize: 'x-large',
                          textTransform: 'capitalize',
                          cursor: 'pointer',
                          fontSize: 'x-larger',
                          float: 'right',
                          margin: '0.2em 0.5em 0.3em 0em',
                        }}
                        startIcon={<PrintRoundedIcon />}
                      >
                        Print
                      </Button>
                    </Tooltip>
                  </span>
                )}
                content={() => this.componentRef}
              />
            </Col>
          </Row>
        )}
        <div id="printform" ref={(el) => (this.componentRef = el)} style={{ margin: '1em' }}>
          <Row style={{ position: 'relative', margin: '1em' }}>
            {this.state.isEditButtonHide && (
              <>
                <Col md={5} style={{ position: 'relative', padding: '5px' }}>
                  {this.state.viewDoctorCard && (
                    <>
                      <Card
                        style={{
                          borderRadius: '20px',
                          height: '100%',
                        }}
                      >
                        <Row style={{ position: 'relative', marginTop: '1em', marginLeft: '0.1em' }}>
                          <Col md={12}>
                            {' '}
                            <label style={{ color: '#000' }}>{'Appointment For ' + this.memberName}</label>
                          </Col>
                        </Row>
                        <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', padding: '0px' }}>
                          <Row>
                            <Col md={8} style={{ paddingLeft: '20px' }}>
                              <img
                                src={doctor}
                                className="img-circle"
                                width="30px"
                                height="30px"
                                style={{ marginTop: '0.5em' }}
                                alt=""
                              />
                              <label
                                style={{
                                  textTransform: 'capitalize',
                                  paddingLeft: '1em',
                                }}
                                className="appointment_section_header"
                              >
                                {'Dr.'} {this.state.appointmentDoctor.firstName} &nbsp;
                                {this.state.appointmentDoctor.lastName}
                              </label>
                              <h6 className="doctorHeader">
                                {null !== this.state.appointmentDoctor.specialist &&
                                undefined !== this.state.appointmentDoctor.specialist
                                  ? this.state.appointmentDoctor.specialist
                                  : ''}
                              </h6>
                              {this.state.appointmentDoctor.phone && (
                                <Row style={{ padding: '3px', marginLeft: '0em' }}>
                                  <Col md={2} style={{ padding: '0em' }}>
                                    <Tooltip title={this.state.appointmentDoctor.phone} aria-label="add">
                                      <PhoneIcon />
                                    </Tooltip>
                                  </Col>
                                  <Col md={10} style={{ padding: ' 0em', marginLeft: '-0.9em' }}>
                                    <a href={'tel:' + this.state.appointmentDoctor.phone} target="_top">
                                      {Utils.displayUsaPhoneNumberFormate(this.state.appointmentDoctor.phone)}
                                    </a>
                                    {/* {this.state.phone} */}
                                  </Col>
                                </Row>
                              )}
                              {this.state.appointmentDoctor.email && (
                                <Row style={{ padding: '3px', marginLeft: '0em' }}>
                                  <Col md={2} style={{ padding: '0em' }}>
                                    <Tooltip title={this.state.appointmentDoctor.email} aria-label="add">
                                      <EmailIcon />
                                    </Tooltip>
                                  </Col>
                                  <Col md={10} style={{ padding: ' 0em', marginLeft: '-0.9em' }}>
                                    <a href={'mailto:' + this.state.appointmentDoctor.email} target="_top">
                                      {this.state.appointmentDoctor.email}
                                    </a>
                                  </Col>
                                </Row>
                              )}
                            </Col>
                            <Col
                              md={4}
                              style={{
                                padding: '7px 20px',
                                verticalAlign: 'middle',
                                alignItems: 'center',
                              }}
                            ></Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md={4}>
                              <span style={{ color: '#000' }}>
                                <strong>Date</strong>
                              </span>
                            </Col>
                            <Col md={8}>
                              <span style={{ color: '#000' }}>{this.state.appointmentStartDate}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <span style={{ color: '#000' }}>
                                <strong>Status</strong>
                              </span>
                            </Col>
                            <Col md={8}>
                              <span style={{ color: '#000' }}>{this.state.appointmentStatus} </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <span style={{ color: '#000' }}>
                                <strong>Purpose</strong>
                              </span>
                            </Col>
                            <Col md={8}>
                              <span style={{ color: '#000' }}>{this.state.appoiPurpose} </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <span style={{ color: '#000' }}>
                                <strong>Email</strong>
                              </span>
                            </Col>
                            <Col md={8}>
                              <span style={{ color: '#000' }}>
                                <a href={'mailto:' + this.state.email} target="_top">
                                  {this.state.appointmentDoctor.email}
                                </a>{' '}
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <span style={{ color: '#000' }}>
                                <strong>Phone</strong>
                              </span>
                            </Col>
                            <Col md={8}>
                              <span style={{ color: '#000' }}>
                                <a href={'tel:' + this.state.phone} target="_top">
                                  {Utils.displayUsaPhoneNumberFormate(this.state.appointmentDoctor.phone)}
                                </a>
                              </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <span style={{ color: '#000' }}>
                                <strong>Description</strong>
                              </span>
                            </Col>
                            <Col md={8}>
                              <span style={{ color: '#000' }}>
                                <pre
                                  className="view_AppointmentNote"
                                  style={{ marginLeft: '-1.9px', textAlign: 'justify' }}
                                >
                                  {this.state.description}
                                </pre>
                              </span>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </>
                  )}
                  {this.state.viewFacilityCard && (
                    <>
                      <Card
                        style={{
                          borderRadius: '20px',
                          height: '100%',
                        }}
                      >
                        <Row style={{ position: 'relative', marginTop: '1em', marginLeft: '0.1em' }}>
                          <Col md={12}>
                            {' '}
                            <label style={{ color: '#000' }}>{'Appointment For ' + this.memberName}</label>
                          </Col>
                        </Row>
                        <CardHeader
                          style={{
                            borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
                          }}
                        >
                          <Row>
                            <Col md={8}>
                              <label className="appointment_section_header">Facility Appointment</label>
                            </Col>
                            <Col md={4}></Col>
                          </Row>
                        </CardHeader>
                        <CardBody>
                          <Row>
                            <Col md={4}>
                              <span style={{ color: '#000' }}>
                                <strong>Date</strong>
                              </span>
                            </Col>
                            <Col md={8}>
                              <span style={{ color: '#000' }}>{this.state.appointmentStartDate}</span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <span style={{ color: '#000' }}>
                                <strong>Name</strong>
                              </span>
                            </Col>
                            <Col md={8}>
                              <span style={{ color: '#000' }}>{this.state.facilityName} </span>
                            </Col>
                          </Row>

                          <Row>
                            <Col md={4}>
                              <span style={{ color: '#000' }}>
                                <strong>Type</strong>
                              </span>
                            </Col>
                            <Col md={8}>
                              <span style={{ color: '#000' }}>{this.state.viewFacilityType} </span>
                            </Col>
                          </Row>
                          <Row>
                            <Col md={4}>
                              <span style={{ color: '#000' }}>
                                <strong>Description</strong>
                              </span>
                            </Col>
                            <Col md={8}>
                              <span style={{ color: '#000' }}>
                                <pre
                                  className="view_AppointmentNote"
                                  style={{ marginLeft: '-1.9px', textAlign: 'justify' }}
                                >
                                  {this.state.description}
                                </pre>
                              </span>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    </>
                  )}
                </Col>
                <Col md={7} style={{ position: 'relative', padding: '8px' }}>
                  {this.state.showLocationView && (
                    <Card
                      style={{
                        borderRadius: '20px',
                        height: '100%',
                      }}
                    >
                      <CardHeader
                        style={{
                          borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
                          paddingBottom: '0.1em',
                          color: 'rgb(22, 44, 80)',
                          fontWeight: 'bold',
                        }}
                      >
                        {/* <LocalHospitalIcon /> */}
                        &nbsp;<label className="appointment_section_header">Location Details</label>
                      </CardHeader>
                      <CardBody>
                        {this.state.viewDoctorCard && (
                          <>
                            <Row style={{ marginTop: '1em' }}>
                              <Col md={12}>
                                <Location
                                  ref={this.locationReference}
                                  data={this.state.doctorLocation}
                                  action="simpleView"
                                />
                              </Col>
                            </Row>
                            <Row md={12} style={{ marginLeft: 'auto' }}></Row>
                          </>
                        )}
                        {this.state.viewFacilityCard && (
                          <>
                            <Row>
                              <Col md={12}>
                                <Location
                                  ref={this.locationReference}
                                  data={this.state.facilityAppointmentLocation}
                                  action="simpleView"
                                />
                              </Col>
                            </Row>
                            {/* <Row md={12} style={{ marginLeft: 'auto' }}>
                              {' '}
                              <Button
                                type={this.state.type ? this.state.type : ''}
                                variant="contained"
                                size="small"
                                style={{
                                  background: '#717373',
                                  color: '#fff',
                                  marginRight: '1rem',
                                  marginBottom: '1em',
                                }}
                                onClick={(e) => this.showMap()}
                              >
                                Show Map
                              </Button>
                            </Row> */}
                          </>
                        )}
                      </CardBody>
                    </Card>
                  )}

                  {this.state.showMapView && (
                    <Row>
                      <Col md={12}>
                        <Card style={{ height: '100%', borderRadius: '20px' }}>
                          <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                            <Row>
                              <Col md={10}>
                                <Label style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}>
                                  Location Details
                                </Label>
                              </Col>
                              <Col md={2}></Col>
                            </Row>
                          </CardHeader>
                          <CardBody>
                            {/* <Row>
                              <Col md={12}>
                                <Location action="view" pageId="incident" data={this.state.locationDetails} />
                              </Col>
                            </Row> */}
                            {this.state.viewDoctorCard && (
                              <>
                                <Row style={{ marginTop: '1em' }}>
                                  <Col md={12}>
                                    <Location
                                      ref={this.locationReference}
                                      data={this.state.doctorLocation}
                                      action="simpleView"
                                    />
                                  </Col>
                                </Row>
                              </>
                            )}
                            {this.state.viewFacilityCard && (
                              <>
                                <Row>
                                  <Col md={12}>
                                    <Location
                                      ref={this.locationReference}
                                      data={this.state.facilityAppointmentLocation}
                                      action="simpleView"
                                    />
                                  </Col>
                                </Row>
                              </>
                            )}
                            <Row style={{ margin: '0em' }}>
                              <Col md={12}>
                                <Row md={12}>
                                  <Col md={12} style={{ padding: '0em' }}>
                                    {' '}
                                    <span className="closeFloatingButton" aria-label="add">
                                      {' '}
                                      <CloseIcon
                                        onClick={this.closeButton.bind(this)}
                                        style={{ float: 'right', fontSize: '15px', cursor: 'pointer' }}
                                      />
                                    </span>
                                  </Col>
                                </Row>{' '}
                                <Row>
                                  {/* <Card style={{ width: '100%', padding: '3em' }}>
                                <CardBody> */}
                                  <Col md={12}>{this.getMapView(this.state.addressLocations)} </Col>
                                  {/* </CardBody>
                              </Card> */}
                                </Row>
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>{' '}
                    </Row>
                  )}
                </Col>
              </>
            )}
            {this.state.isButtonHide && this.editAppointment()}
          </Row>
          <Row style={{ margin: 'auto', marginLeft: '0.6em', marginRight: '0.6em', marginTop: '1em' }}>
            <Col md={12}>
              <Card style={{ width: '100%', borderRadius: '20px', margin: 'auto' }}>
                <CardHeader
                  style={{
                    borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
                  }}
                >
                  <Row className="">
                    <Col md={12} className="appointment_section_header">
                      <Label className="reminderTitle" style={{ fontWeight: '600', color: '#0099f1' }}>
                        Reminders
                      </Label>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>{this.state.taskReminderListDiv && <Row>{reminderList}</Row>}</CardBody>
              </Card>
            </Col>
          </Row>
          {this.state.showAppointmentNotePermission === true ? (
            <Row style={{ margin: 'auto', marginLeft: '0.6em', marginRight: '0.6em', marginTop: '0.6em' }}>
              <Col md={12}>
                <Card style={{ width: '100%', borderRadius: '20px', margin: 'auto' }}>
                  <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                    <Row>
                      <Col md={12}>
                        <Label
                          style={{
                            fontWeight: '600',
                            marginLeft: '0.5em',
                          }}
                          className="appointment_section_header"
                          id="noteTitle"
                        >
                          Notes
                        </Label>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>{taskNotes}</Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <></>
          )}
          <Row style={{ margin: '0.6em 0.6em auto' }}>
            <Col md={12}>
              <Card style={{ width: '100%', borderRadius: '20px', margin: 'auto' }}>
                <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                  <Row>
                    <Col md={12}>
                      <Label
                        style={{
                          marginLeft: '0.5em',
                        }}
                        className="referenceAppointmentTitle appointment_section_header"
                      >
                        Reference Appointments
                      </Label>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  {this.state.referenceAppointmentCreate && (
                    <>
                      <ReferenceAppointment
                        action="create"
                        history={this.props.history}
                        ref={this.referenceAppointmentRef}
                        onCreate={this.addAppointmentTaskInBackend.bind(this)}
                        onCancel={this.cancelAppointmentTask.bind(this)}
                        appointmentId={this.state.id}
                      ></ReferenceAppointment>
                    </>
                  )}
                  {this.state.appointmentTaskList.length > 0 && (
                    <ReferenceAppointment
                      action="list"
                      history={this.props.history}
                      isPrintView={this.state.isPrintView}
                      onSuccess={this.updateAppointmentTaskInBackend.bind(this)}
                      appointmentId={this.state.id}
                      referenceAppointmentList={this.state.appointmentTaskList}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          {this.state.showAppointmentTransportationPermission === true ? (
            <Row style={{ margin: '0.6em 0.6em auto' }} id="isTransportationChecked" className="transporation_list">
              <Col md={12}>
                <Card style={{ width: '100%', borderRadius: '20px', margin: 'auto' }}>
                  <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                    <Row>
                      <Col md={12} className="">
                        <Label
                          style={{
                            marginLeft: '0.5em',
                          }}
                          className="transportationTitle appointment_section_header"
                        >
                          Transportation
                        </Label>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    {this.state.transportationList.length > 0 && (
                      <Transportation
                        transportationAppointmentList={this.state.transportationList}
                        isPrintView={this.state.isPrintView}
                        getCreatePermission={this.state.getAppointmentTransportationCreatePermission}
                        getDeletePermission={this.state.getAppointmentTransportationDeletePermission}
                        getUpdatePermission={this.state.getAppointmentTransportationUpdatePermission}
                        onTransportationSuccess={this.updateTransportationInBackend.bind(this)}
                        appointmentId={this.state.id}
                        action="list"
                        onSuccess={this.getAppointmentDetails.bind(this)}
                      ></Transportation>
                    )}
                  </CardBody>
                </Card>
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }
}

export default MemberAppointment;
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#008181',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: 'white',
        color: '#005476',
      },
    },
  },
});
