import Aos from 'aos';
import React from 'react';
import { Provider } from 'react-redux';
import { HashRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Admin from './layouts/AdminLayout/Admin';
import Auth from './layouts/AdminLayout/Auth';
import { SponsorLayout } from './layouts/AdminLayout/SponsorLayout';
import ApplicationLayout from './layouts/ApplicationLayout';
import LoginLayout from './layouts/LoginLayout';
import MemberDetailLayout from './layouts/MemberDetailLayout';
import WebsiteLayout from './layouts/WebsiteLayout';
import FirebaseInit from './provider/FirebaseInit';
import Utils from './provider/Utils';
import AdminDashboard from './pts/adminPanel/adminDashboard/AdminDashboard';
import AdminForgetPassword from './pts/adminPanel/adminForgetPassword/AdminForgetPassword';
import AdminResetPassword from './pts/adminPanel/adminForgetPassword/AdminResetPassword';
import AdminLogin from './pts/adminPanel/adminLogin/AdminLogin';
import AdminProfile from './pts/adminPanel/adminProfile/AdminProfile';
import AdminSponsorshipList from './pts/adminPanel/adminSponsorship/AdminSponsorshipList';
import Sponsor from './pts/adminPanel/sponsor/Sponsor';
import SponsorList from './pts/adminPanel/sponsor/SponsorList';
import Appointment from './pts/components/appointment/Appointment';
import { MemberAppointment } from './pts/components/appointment/MemberAppointment';
import MemberAppointmentList from './pts/components/appointment/MemberAppointmentList';
import appointmentHierarchy from './pts/components/appointmentHierarchy/appointmentHierarchy';
import BillingDetailsHome from './pts/components/billingDetails/BillingDetailsHome';
import MemberCommunication from './pts/components/communication/MemberCommunication';
import { MemberCommunicationList } from './pts/components/communication/MemberCommunicationList';
import ContactUs from './pts/components/contactUs/ContactUs';
import MyRequest from './pts/components/dashboard/MyRequest';
import { MemberDoctor } from './pts/components/doctor/MemberDoctor';
import { MemberDoctorList } from './pts/components/doctor/MemberDoctorList';
import Event from './pts/components/events/Event';
import MemberEvent from './pts/components/events/MemberEvent';
import MemberEventList from './pts/components/events/MemberEventList';
import MemberFacility from './pts/components/facility/MemberFacility';
import MemberFacilityList from './pts/components/facility/MemberFacilityList';
import FamilyCircle from './pts/components/familyCircle/FamilyCircle';
import FamilyCircleList from './pts/components/familyCircle/FamilyCircleList';
import { AddFamilyMember } from './pts/components/familyMember/AddFamilyMember';
import FccHome from './pts/components/fccHome/FccHome';
import { DoctorImport } from './pts/components/import/DoctorImport';
import FacilityImport from './pts/components/import/FacilityImport';
import ImportList from './pts/components/import/ImportList';
import Incident from './pts/components/incident/Incident';
import MemberIncident from './pts/components/incident/MemberIncident';
import MemberIncidentList from './pts/components/incident/MemberIncidentList';
import Login from './pts/components/login/Login';
import { AddMember } from './pts/components/member/AddMember';
import MemberDetails from './pts/components/member/MemberDetails';
import MemberProfile from './pts/components/member/MemberProfile';
import MemberHome from './pts/components/member/memberHome/MemberHome';
import Canceled from './pts/components/paymentGateway/canceled/Canceled';
import Checkout from './pts/components/paymentGateway/checkout/Checkout';
import BusinessPromotion from './pts/components/paymentGateway/pricing/BusinessPromotion/BusinessPromotion';
import PlanDetails from './pts/components/paymentGateway/pricing/PlanDetails';
import Pricing from './pts/components/paymentGateway/pricing/Pricing';
import PricingHome from './pts/components/paymentGateway/pricing/PricingHome';
import RenewPlan from './pts/components/paymentGateway/renewPlan/RenewPlan';
import Success from './pts/components/paymentGateway/success/Success';
import UpgradePlan from './pts/components/paymentGateway/upgradePlan/UpgradePlan';
import PrivacyPolicy from './pts/components/privacyPolicy/PrivacyPolicy';
import PrivacyPolicyWeb from './pts/components/privacyPolicy/PrivacyPolicyWeb';
import Profile from './pts/components/profile/Profile';
import ProfileHome from './pts/components/profile/ProfileHome';
import CreateSimilarPurchase from './pts/components/purchases/CreateSimilarPurchase';
import MyPurchase from './pts/components/purchases/MyPurchase';
import MyPurchasesList from './pts/components/purchases/MyPurchasesList';
import ForgotPassword from './pts/components/registration/ForgotPassword';
import Registration from './pts/components/registration/Registration';
import ResetPassword from './pts/components/registration/ResetPassword';
import { TermsConditions } from './pts/components/registration/TermsConditions';
import Verification from './pts/components/registration/Verification';
// import Sponsorship from './pts/components/sponsorship/Sponsorship';
import AdminSponsorLogin from './pts/adminPanel/adminSponsorLogin/AdminSponsorLogin';
import AdminSponsorship from './pts/adminPanel/adminSponsorship/AdminSponsorship';
import Advertisement from './pts/adminPanel/advertisement/Advertisement';
import AdvertisementList from './pts/adminPanel/advertisement/AdvertisementList';
import Help from './pts/adminPanel/help/Help';
import ReferralBonus from './pts/components/refrealBonus/ReferralBonus';
import { TermsCondition } from './pts/components/termsCondition/TermsCondition';
import TermsConditionsWebsite from './pts/components/termsCondition/TermsConditionWebsite';
import './pts/scss/styles.scss';
import store from './redux/store';
import VerificationRequest from './views/pages/VerificationRequest';
import Verify from './views/pages/Verify';
import updateCredential from './views/pages/updateCredential';

import AdminSponsorForgetPassword from './pts/adminPanel/AdminSponsorForgetPassword/AdminSponsorForgetPassword';
import AdminSponsorResetPassword from './pts/adminPanel/AdminSponsorForgetPassword/AdminSponsorResetPassword';
import AdminPaidAdvertisementForgetPassword from './pts/adminPanel/adminPaidAdvertisementForgetPassword/AdminPaidAdvertisementForgetPassword';
import AdminPaidAdvertisementResetPassword from './pts/adminPanel/adminPaidAdvertisementForgetPassword/AdminPaidAdvertisementResetPassword';
import AdminPaidAdvertisementLogin from './pts/adminPanel/adminPaidAdvertisementLogin/AdminPaidAdvertisementLogin';

import { PaidAdvertiserLayout } from './layouts/AdminLayout/PaidAdvertiserLayout';
import AdminProfileManagement from './pts/adminPanel/adminProfileManagement/AdminProfileManagement';
import AdminProfileManagementList from './pts/adminPanel/adminProfileManagement/AdminProfileManagementList';
import AdminUser from './pts/adminPanel/adminUser/AdminUser';
import AdminUserList from './pts/adminPanel/adminUser/AdminUserList';
import EmailTemplate from './pts/adminPanel/emailTemplate/EmailTemplate';
import EmailTemplateList from './pts/adminPanel/emailTemplate/EmailTemplateList';
import FccProfileManagement from './pts/adminPanel/fccProfileManagement/FccProfileManagement';
import FccProfileManagementList from './pts/adminPanel/fccProfileManagement/FccProfileManagementList';
import LoginCount from './pts/adminPanel/loginCount/LoginCount';
import PaidAdvertisement from './pts/adminPanel/paidAdvertisement/PaidAdvertisement';
import PaidAdvertisementList from './pts/adminPanel/paidAdvertisement/PaidAdvertisementList';
import PaidAdvertiser from './pts/adminPanel/paidAdvertiser/PaidAdvertiser';
import PaidAdvertiserList from './pts/adminPanel/paidAdvertiser/PaidAdvertiserList';
import PaidCommercials from './pts/adminPanel/paidCommercials/PaidCommercials';
import PaidCommercialsList from './pts/adminPanel/paidCommercials/PaidCommercialsList';
import PrivacyPolicyTemplate from './pts/adminPanel/privacyPolicyTemplate/PrivacyPolicyTemplate';
import PrivacyPolicyTemplateList from './pts/adminPanel/privacyPolicyTemplate/PrivacyPolicyTemplateList';
import TermsConditionTemplate from './pts/adminPanel/terms&ConditionTemplate/TermsConditionTemplate';
import TermsConditionList from './pts/adminPanel/terms&ConditionTemplate/TermsConditionTemplateList';
import ConsolidatedView from './pts/components/consolidatedView/ConsolidatedView';
import FrequentlyAskedQuestion from './pts/components/fccFAQ/FrequentlyAskedQuestion';
import PrescriptionsImport from './pts/components/import/PrescriptionsImport';
import PremiumFeatures from './pts/components/premiumFeatures/PremiumFeatures';
import Prescriptions from './pts/components/prescriptions/Prescriptions';
import PrescriptionsList from './pts/components/prescriptions/PrescriptionsList';
import PtsAboutUs from './pts/components/ptsAboutUs/PtsAboutUs';
import UserLoginCount from './pts/adminPanel/loginCount/UserLoginCount';
// import UseCase from './views/pages/website/UseCase';

class App extends React.Component {
  constructor(props) {
    super(props);
    // FirebaseInit.requestFirebaseNotificationPermission();

    FirebaseInit.requestFirebaseNotificationPermission()
      .then((firebaseToken) => {
        FirebaseInit.onMessageListener();
        FirebaseInit.registerServiceWorker();
        /* Update Token whenever token changes, Token will be uploaded on server only if user Login successfully */
        Utils.setFCMTokenForUser();
      })
      .catch((err) => {});
  }

  render() {
    Aos.init();
    return (
      <Provider store={store}>
        <ToastContainer
          style={{ alignItems: 'center', fontSize: 'small', width: 'auto', maxHeight: '1em' }}
          position="top-right"
          autoClose={4000}
          hideProgressBar={true}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <HashRouter>
          <Switch>
            <MemberDetailLayout path="/viewFamilyMember" component={FamilyCircle} />
            <MemberDetailLayout path="/familyCircle" component={FamilyCircleList} />
            <MemberDetailLayout path="/addFamilyMember" component={AddFamilyMember} />
            <MemberDetailLayout path="/memberDetails" component={MemberDetails} />
            <MemberDetailLayout path="/memberAppointment" component={MemberAppointmentList} />
            <MemberDetailLayout path="/addAppointment" component={MemberAppointment} />
            <MemberDetailLayout path="/viewMemberAppointment" component={MemberAppointment} isSidebarCollapse={false} />
            <MemberDetailLayout path="/viewEditMemberAppointments" component={MemberAppointment} />
            <MemberDetailLayout path="/createSimilarAppointment" component={Appointment} />
            <MemberDetailLayout path="/createDoctorAppointment" component={Appointment} />
            <MemberDetailLayout path="/facilityAppointments" component={Appointment} />
            <MemberDetailLayout path="/appointmentHierarchy" component={appointmentHierarchy} />
            <MemberDetailLayout path="/doctorAppointment" component={MemberDoctorList} />
            <MemberDetailLayout path="/addDoctor" component={MemberDoctor} />
            <MemberDetailLayout path="/viewDoctor" component={MemberDoctor} />
            <MemberDetailLayout path="/editDoctorAppointment" component={MemberDoctor} />
            <MemberDetailLayout path="/memberFacility" component={MemberFacilityList} />
            <MemberDetailLayout path="/addFacility" component={MemberFacility} />
            <MemberDetailLayout path="/viewFacility" component={MemberFacility} />
            <MemberDetailLayout path="/editMemberFacility" component={MemberFacility} />
            <MemberDetailLayout path="/member_event" component={MemberEventList} />
            <MemberDetailLayout path="/addEvent" component={MemberEvent} />
            <MemberDetailLayout path="/viewEvent" component={MemberEvent} />
            <MemberDetailLayout path="/editEvent" component={MemberEvent} />
            <MemberDetailLayout path="/createSimilarEvent" component={Event} />
            <MemberDetailLayout path="/memberPurchase" component={MyPurchasesList} />
            <MemberDetailLayout path="/addPurchase" component={MyPurchase} />
            <MemberDetailLayout path="/viewPurchase" component={MyPurchase} />
            <MemberDetailLayout path="/edit_purchase" component={MyPurchase} />
            <MemberDetailLayout path="/createSimilarPurchase" component={CreateSimilarPurchase} />

            <MemberDetailLayout path="/incidentAppointment" component={MemberIncidentList} />
            <MemberDetailLayout path="/addIncident" component={MemberIncident} />
            <MemberDetailLayout path="/createSimilarIncident" component={Incident} />
            <MemberDetailLayout path="/viewIncident" component={MemberIncident} />
            <MemberDetailLayout path="/incidentEdit" component={MemberIncident} />
            <MemberDetailLayout path="/profile" component={Profile} />
            <MemberDetailLayout path="/editProfile" component={Profile} />
            <MemberDetailLayout path="/memberProfile" component={MemberProfile} />
            <MemberDetailLayout path="/editMemberProfile" component={MemberProfile} />
            <MemberDetailLayout path="/memberCommunication" component={MemberCommunicationList} />
            <MemberDetailLayout path="/memberCommunication" component={MemberCommunicationList} />
            <MemberDetailLayout path="/viewCommunication" component={MemberCommunication} />
            <MemberDetailLayout path="/addMessageThread" component={MemberCommunication} />
            <MemberDetailLayout path="/import" component={ImportList} />
            <MemberDetailLayout path="/viewFacilityImport" component={FacilityImport} />
            <MemberDetailLayout path="/viewDoctorImport" component={DoctorImport} />
            <MemberDetailLayout path="/viewPrescriptionsImport" component={PrescriptionsImport} />
            <MemberDetailLayout path="/prescriptionsList" component={PrescriptionsList} />
            <MemberDetailLayout path="/addPrescription" component={Prescriptions} />
            <MemberDetailLayout path="/viewPrescriptions" component={Prescriptions} />
            <MemberDetailLayout path="/premiumFeatures" component={PremiumFeatures} />
            <ApplicationLayout path="/referralBonus" component={ReferralBonus} />
            <ApplicationLayout path="/upgradePlan" component={UpgradePlan} />
            <ApplicationLayout path="/renewPlan" component={RenewPlan} />
            <ApplicationLayout path="/homePricing" component={PricingHome} />
            <ApplicationLayout path="/privacyPolicy" component={PrivacyPolicy} />
            <ApplicationLayout path="/profileHome" component={ProfileHome} />
            <ApplicationLayout path="/editProfileHome" component={ProfileHome} />
            <ApplicationLayout path="/success" component={Success} />
            <ApplicationLayout path="/viewMember" component={MyRequest} />
            <ApplicationLayout path="/addMember" component={AddMember} />
            <ApplicationLayout path="/aboutUs" component={PtsAboutUs} />
            <ApplicationLayout path="/consolidatedView" component={ConsolidatedView} />
            <ApplicationLayout path="/termsCondition" component={TermsCondition} />

            <ApplicationLayout path="/home" component={MemberHome} />
            <ApplicationLayout path="/termsconditionhome" component={TermsCondition} />
            <ApplicationLayout path="/billingDetailsHome" component={BillingDetailsHome} />
            <WebsiteLayout path="/privacyPolicyWeb" component={PrivacyPolicyWeb} />
            <WebsiteLayout path="/BusinessPromotion" component={BusinessPromotion} />
            <WebsiteLayout path="/contactUs" component={ContactUs} />
            <WebsiteLayout path="/pricing" component={Pricing} />
            <WebsiteLayout path="/planDetails" component={PlanDetails} />
            <ApplicationLayout path="/checkout" component={Checkout} />
            <ApplicationLayout path="/canceled" component={Canceled} />
            <WebsiteLayout path="/termsConditions" component={TermsConditionsWebsite} />
            <WebsiteLayout path="/fcc" component={FccHome} />
            <WebsiteLayout path="/termsConditionsRegistration" component={TermsConditions} />
            <LoginLayout path="/login" component={Login} />
            <LoginLayout path="/createAccount" component={Registration} />
            <LoginLayout path="/forgotPassword" component={ForgotPassword} />
            <LoginLayout path="/verification" component={Verification} />
            <LoginLayout path="/updateCredential" component={updateCredential} />
            <LoginLayout path="/verify" component={Verify} />
            <LoginLayout path="/verificationRequest" component={VerificationRequest} />
            <LoginLayout path="/resetpassword" component={ResetPassword} />
            <Auth path="/APLogin" component={AdminLogin} />
            <Admin path="/ApHome" component={AdminDashboard} />
            <Auth path="/APForget" Component={AdminForgetPassword} />
            <Auth path="/APReset" Component={AdminResetPassword} />
            <Admin path="/adminUserProfile" Component={AdminProfile} />
            <Admin path="/sponsorList" component={SponsorList} />
            <Admin path="/addSponsor" Component={Sponsor} />
            <SponsorLayout path="/viewSponsor" component={Sponsor} />
            <SponsorLayout path="/editSponsor" component={Sponsor} />
            <SponsorLayout path="/sponsorshipList" component={AdminSponsorshipList} />
            <SponsorLayout path="/addSponsorship" component={AdminSponsorship} />
            <SponsorLayout path="/viewSponsorship" component={AdminSponsorship} />
            <SponsorLayout path="/editSponsorship" component={AdminSponsorship} />
            <SponsorLayout path="/advertisementList" component={AdvertisementList} />
            <SponsorLayout path="/addAdvertisement" component={Advertisement} />
            <SponsorLayout path="/viewAdvertisement" component={Advertisement} />
            <SponsorLayout path="/editAdvertisement" component={Advertisement} />
            <SponsorLayout path="/help" component={Help} />
            <Auth path="/APSponsorLogin" component={AdminSponsorLogin} />
            <Auth path="/APSponsorForget" component={AdminSponsorForgetPassword} />
            <Auth path="/APSponsorReset" component={AdminSponsorResetPassword} />
            <Auth path="/APPaidAdvertiserLogin" component={AdminPaidAdvertisementLogin} />
            <Auth path="/APPaidAdvertiserForget" component={AdminPaidAdvertisementForgetPassword} />
            <Auth path="/APPaidAdvertiserReset" component={AdminPaidAdvertisementResetPassword} />
            <Admin path="/paidAdvertiserList" component={PaidAdvertiserList} />
            <Admin path="/addAdvertiser" component={PaidAdvertiser} />
            <Admin path="/viewEmailTemplate" component={EmailTemplate} />
            <Admin path="/editEmailTemplate" component={EmailTemplate} />
            <Admin path="/emailTemplateList" component={EmailTemplateList} />
            <Admin path="/fccProfileList" component={FccProfileManagementList} />
            <Admin path="/addProfileManagement" component={FccProfileManagement} />
            <Admin path="/viewProfileManagement" component={FccProfileManagement} />
            <Admin path="/editProfileManagement" component={FccProfileManagement} />
            <Admin path="/apSetting" component={AdminProfileManagementList} />
            <Admin path="/addApProfileManagement" component={AdminProfileManagement} />
            <Admin path="/viewApProfileManagement" component={AdminProfileManagement} />
            <Admin path="/editApProfileManagement" component={AdminProfileManagement} />
            <Admin path="/apUserList" component={AdminUserList} />
            <Admin path="/addAdminUser" component={AdminUser} />
            <Admin path="/viewAdminUser" component={AdminUser} />
            <Admin path="/editAdminUser" component={AdminUser} />
            <Admin path="/aptermsConditionsList" component={TermsConditionList} />
            <Admin path="/addTermsCondition" component={TermsConditionTemplate} />
            <Admin path="/viewTermsCondition" component={TermsConditionTemplate} />
            <Admin path="/editTermsCondition" component={TermsConditionTemplate} />
            <Admin path="/privacyPolicyList" component={PrivacyPolicyTemplateList} />
            <Admin path="/addPrivacyPolicy" component={PrivacyPolicyTemplate} />
            <Admin path="/viewPrivacyPolicy" component={PrivacyPolicyTemplate} />
            <Admin path="/editPrivacyPolicy" component={PrivacyPolicyTemplate} />
            <Admin path="/loginCount" component={LoginCount} />
            <Admin path="/userLoginCount" component={UserLoginCount} />
            <PaidAdvertiserLayout path="/viewAdvertiser" component={PaidAdvertiser} />
            <PaidAdvertiserLayout path="/editAdvertiser" component={PaidAdvertiser} />
            <PaidAdvertiserLayout path="/paidCommercialsList" component={PaidCommercialsList} />
            <PaidAdvertiserLayout path="/viewCommercials" component={PaidCommercials} />
            <PaidAdvertiserLayout path="/addCommercials" component={PaidCommercials} />
            <PaidAdvertiserLayout path="/editCommercials" component={PaidCommercials} />
            <PaidAdvertiserLayout path="/paidAdvertisementList" component={PaidAdvertisementList} />
            <PaidAdvertiserLayout path="/addPaidAdvertisement" component={PaidAdvertisement} />
            <PaidAdvertiserLayout path="/viewPaidAdvertisement" component={PaidAdvertisement} />
            <PaidAdvertiserLayout path="/editPaidAdvertisement" component={PaidAdvertisement} />
            <PaidAdvertiserLayout path="/paidHelp" component={Help} />
            <MemberDetailLayout path="/faq" component={FrequentlyAskedQuestion} />
            <ApplicationLayout path="/faqHome" component={MemberHome} />
            <Route path="/" component={FccHome} />
          </Switch>
        </HashRouter>
      </Provider>
    );
  }
}
export default App;
