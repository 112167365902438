import Fab from '@material-ui/core/Fab';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import PrintIcon from '@material-ui/icons/Print';
import 'bootstrap/dist/css/bootstrap.min.css';
import React from 'react';
import { Col, Row } from 'reactstrap';
import store from '../../../redux/store';
import PrintIconButton from '../buttons/PrintIconButton';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
//const loader = Loaders();
import './FrequentlyAskedQuestion.scss';

export class FrequentlyAskedQuestionHome extends React.Component {
  title = 'Frequently Asked Question';
  breadCrumb = [];
  header = store.getState().header.header;

  constructor(props) {
    super(props);

    this.state = {
      showSuccessAlert: false,
      showFailAlert: false,
      value: 0,

      errorMessageColor: '#000',
      checkbox: false,
    };
  }
  nextPage() {
    this.props.history.push('/createAccount');
  }

  printContent() {
    let printContents = document.getElementById('printform').innerHTML;
    let originalContents = document.body.innerHTML;
    document.body.innerHTML = printContents;
    window.print();

    document.body.innerHTML = originalContents;
    this.setState({ render: true });
    document.location.reload(true);
  }

  scrollToSection(elementID) {
    if (document.getElementById(elementID)) {
      document.getElementById(elementID).scrollIntoView();
    }
  }

  handleClickCheckBox = (e) => {
    if (e.target.checked === 'true') {
      this.setState({
        checkbox: e.target.checked,
        isDisabled: false,
      });
    } else {
      this.setState({
        checkbox: e.target.checked,
        isDisabled: true,
      });
    }
  };
  render() {
    return (
      <>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={this.title}
          headerWidth="home"
        />
        <div id="printform" style={{ padding: '3em 2em 2em 2em', background: '#fff' }}>
          <div style={{ border: '2px solid lightgray', marginTop: ' 10px', padding: '1em' }}>
            <section className="contact" style={{ padding: '2em', background: '#fff' }}>
              <Row md={12} style={{ margin: '0.5em' }}>
                <Col style={{ textAlign: 'center' }}>
                  <span
                    style={{
                      textAlign: 'center',
                      color: '#000',
                      fontSize: 'large',

                      float: 'center',
                    }}
                  >
                    <strong>
                      <h3>
                        <u>FREQUENTLY ASKED QUESTIONS</u>
                      </h3>
                    </strong>
                  </span>
                  <span
                    style={{
                      textAlign: 'center',
                      color: '#000',
                      fontSize: 'large',
                      float: 'right',
                    }}
                  >
                    <PrintIconButton
                      variant="contained"
                      size="small"
                      style={{ float: 'right', background: '#1b7189', color: '#fff', marginRight: '1rem' }}
                      startIcon={<PrintIcon />}
                      onClick={(e) => this.printContent(e)}
                    />
                  </span>
                </Col>
              </Row>
              <Row md={12} style={{ margin: '0.5em' }}>
                <ol style={{ padding: '1em' }}>
                  <ul style={{ padding: '0.5em 0em 0em 2em', listStyle: 'disc', fontSize: '14px' }}>
                    <li>
                      {' '}
                      <b>What are the key benefits of using Family Care Circle?</b>
                    </li>
                    <br />
                    <p>
                      FCC will improve communication between multiple Caregivers of a loved one. Appointment tracking
                      helps in keeping up with Notes, Pre-Appointment Labs, Post Appointment Notes, Instructions and
                      Transportation needs. Here are a few examples of how FCC can help with caregiving.
                    </p>
                    <p>
                      Request a Family Member or Caregiver in the Family Circle to help with Transportation. Send a
                      quick update to all Family Members about a recent appointment. Make a quick note with date and
                      location about a Fall or other incident for quick reference. Quickly review historical data to
                      answer questions like “When was the last time Mom fell?”
                    </p>
                    <br />
                    <li>
                      <b>What is the difference between a Member and a Family Member?</b>
                    </li>
                    <br />
                    <p>
                      A Member is the focal point of the Family Circle. All information (appointments, incidents,
                      communication, and notes) within the Family Care Circle is focused on the Member. Family Members
                      are the Caregivers of the Member. FCC is focused on successful communication between all
                      Caregivers by tracking key information and having it readily available to all.
                    </p>
                    <br />
                    <li>
                      <b>What is the role of an Authorized Caregiver?</b>
                    </li>
                    <br />
                    <p>
                      Authorized Caregivers represents the Member in the situations where the Member is not able to
                      approve the creation of the Family Care Circle. An Authorized Caregiver is essentially acting on
                      the Member’s behalf. The Authorized Caregiver and Member are the only ones that can delete the
                      Family Circle’s data. The Authorized Caregiver and Member have unrestricted rights within the
                      Family Care Circle.
                    </p>
                    <br />
                    <li>
                      <b>What is the difference between the role of Admin and Family Member?</b>
                    </li>
                    <br />
                    <p>
                      Admins are in complete control of FCC and are allowed to expand the Family Circle by inviting
                      others to join. They also have the ability to update & delete Family Members and Caregivers.
                    </p>
                    <p>
                      Family Members can create notes, appointments, incidents, and transportation request. Notes can be
                      sent within multiple segments of FCC and are only seen by the users the note is addressed to.
                    </p>
                    <br />
                    <li>
                      <b>How many Family Care Circles can a family member be part of?</b>
                    </li>
                    <br />
                    <p>
                      A family member can be part of as many Family Care Circles as needed and may easily start a new
                      Family Care Circle around themselves at any time.
                    </p>
                    <br />
                    <li>
                      <b>
                        Why does Family Care Circle require a Family Member to have an email address and phone number?
                      </b>
                    </li>
                    <br />
                    <p>
                      Email messaging gives Family Care Circle a method to send notifications outside of the
                      application. FCC will also use this gateway to Invite Family Members to join a Family Care Circle,
                      to communicate notes and reminders between Family Members when needed.
                    </p>
                    <br />
                    <li>
                      <b>What if a member or Family Member does not have an email address?</b>
                    </li>
                    <br />
                    <p>
                      Email addresses are a key part of communication and authentication. We suggest you help all
                      members to setup an e-mail with a Gmail account or another free emailing service.
                    </p>
                    <br />
                    <li>
                      <b>What if a Doctor has multiple locations?</b>
                    </li>
                    <br />
                    <p>
                      No Problem. When you set up a new Doctor or edit an existing Doctor, you can easily add multiple
                      locations. Then, when creating an appointment, you can select the Doctor and location.
                    </p>
                    <br />
                    <li>
                      <b>What are Facilities?</b>
                    </li>
                    <br />
                    <p>
                      Facilities are locations where a Member would go for procedures like Blood Test, MRIs, Pet Scans
                      or Surgeries.
                    </p>
                    <br />
                    <li>
                      <b>What is an Incident?</b>
                    </li>
                    <br />
                    <p>
                      An incident would be health or well-being occurrences that you might want to track to determine
                      frequency and/or help understand if there is an issue to be concerned about. A Fall is a good
                      example of an incident. Trending may be found with a historical review of key information (date,
                      location, and notes). Tracking falls and incidents will help you communicate a concern at the
                      member's next Dr Appt. “Dad fell 3 times in 2 months.”
                    </p>
                    <br />
                    <li>
                      <b>Can I track the purchase of medical devices?</b>
                    </li>
                    <br />
                    <p>
                      Yes, you can easily enter and track purchases such as Hearing aids or eyeglasses in the purchase
                      management section. You can set reminders for new purchases where you will get emails.
                    </p>
                    <br />
                    <li>
                      <b>How secure is my data?</b>
                    </li>
                    <br />
                    <p>
                      FCC implements current security practices and will continue to maintain the latest updates to keep
                      your information secure.
                    </p>
                    <br />
                    <li>
                      <b>What is an Event?</b>
                    </li>
                    <br />
                    <p>
                      An event can be personal, public or social occasion or activity. Member can request
                      transportation, enter notes and have communication regarding the same.  Ex: You are visiting a
                      sick person and would like the family to send flowers and get well wishes along with request help
                      with a transportation need.
                    </p>
                    <br />
                    <li>
                      <b>How can I quickly import Doctors or Facilities?</b>
                    </li>
                    <br />
                    <p>
                      The Import utility allows you to enter in a list of doctors or facilities into an Excel template.
                      The Excel template contains import tabs for Doctors and Facilities. Each tab has sample data
                      needed. Start by downloading the sample excel sheet and fill data as per instructions given. You
                      may find this easier then adding multiple doctors one at a time. This feature is very helpful for
                      authorized caregivers that are looking for a way to transfer information from another source.
                    </p>
                    <br />
                    <li>
                      <b>How do I inactivate a Member’s Family Circle account?</b>
                    </li>
                    <br />
                    <p>
                      We are sorry to hear you are thinking about closing your account with us. You will find a “Delete”
                      button that will allow you to simply remove a Member’s Circle. This will permanently delete all
                      appointments, incidents, notes, doctors, and facilities associated with that member. Only the
                      Member or an Authorized Caregiver can complete this task.
                    </p>
                    <br />
                    <li>
                      <b>How to delete account of Family Care Circle?</b>
                    </li>
                    <br />
                    <p>
                      If you delete this account, you will be deleted from all Family Care Circles, your history of
                      appointments, events, prescriptions will be deleted.To delete account, go to Profile and click
                      DELETE ACCOUNT, enter your password and click CONFIRM. Your FCC account will be deleted.
                    </p>
                  </ul>
                </ol>
              </Row>
            </section>
            <Row>
              <Col md={12}>
                <span style={{ float: 'right' }}>
                  <a onClick={() => this.scrollToSection('termsConditionSection')} style={{ textDecoration: 'none' }}>
                    <Fab
                      aria-label="add"
                      style={{
                        float: 'right',
                        fontSize: 'small',
                        height: '15px',
                        width: '35px',
                        background: '#ef6603',
                        cursor: 'pointer',
                        color: 'white',
                        backgroundImage: '-webkit-linear-gradient(bottom, #843b07 0%, #f7b571 100%);',
                        border: '2px solid #edf0f1',
                      }}
                    >
                      <ArrowUpwardIcon />
                    </Fab>{' '}
                  </a>
                </span>
              </Col>
            </Row>
          </div>
        </div>
      </>
    );
  }
}

export default FrequentlyAskedQuestionHome;
