import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import appUserDataAction from '../../../redux/appUserData/appUserDataAction';
import store from '../../../redux/store';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
import PtsAlert from '../ptsAlert/PtsAlert';
class PremiumFeatures extends Component {
  // PremiumFeatures = store.getState().premiumFeatures.premiumFeatures;
  PremiumFeatures = store.getState().userData.premiumFeatures;

  memberData = store.getState().memberData.memberData;

  memberHomeUrl = store.getState().memberHomeUrl.memberHomeUrl.homeUrl;
  breadCrumb = [];
  title = 'Prescriptions List';
  constructor(props) {
    super(props);
    if (this.memberData !== null) {
      let name = this.memberData.firstname + ' ' + this.memberData.lastname;
      this.breadCrumb = [
        { pageid: this.memberHomeUrl, uiname: name },
        { pageid: '', uiname: 'Prescriptions List' },
      ];
    }
    let pageId = '';

    this.state = { confirmBox: false, endDate: '', closeModal: props.closeModal, reload: false };
  }

  render() {
    if (Utils.PremiumFeatures(this.PremiumFeatures) === 1) {
      return this.showFirstPremiumFeatures();
    } else if (Utils.PremiumFeatures(this.PremiumFeatures) === 2) {
      return this.showSecondPremiumFeatures();
    } else if (Utils.PremiumFeatures(this.PremiumFeatures) === 3) {
      return this.showThirdPremiumFeatures();
    }
  }
  startTrialPeriod() {
    const header = store.getState().header.header;

    const postObject = {
      header: header,
    };
    FetchServerData.callPostService('/userPremiumFeatureMs/startTrial', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          PtsAlert.success(output.message);
          let premiumFeatureTrialinfo = output.data.premiumFeatureTrialinfo;

          let userData = {
            appuserVo: output.data,
          };
          this.setState({
            confirmBox: true,
            endDate: premiumFeatureTrialinfo.endDate,
          });
          store.dispatch(appUserDataAction.setUser(userData));

          // store.dispatch(PremiumFeatureAction.setPremiumFeature(output.data.premiumFeatureTrialinfo));
        } else {
        }
      })
      .catch((error) => {});
  }

  extendTrialPeriod() {
    const header = store.getState().header.header;

    const postObject = {
      header: header,
    };
    FetchServerData.callPostService('/userPremiumFeatureMs/extendTrial', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let premiumFeatureTrialinfo = output.data.premiumFeatureTrialinfo;
          let userDetails = output.data;
          let userData = { appuserVo: '' };
          Object.keys(userDetails).forEach(function (key) {
            userData[key] = userDetails[key];
          });
          PtsAlert.success(output.message);
          this.setState({
            confirmBox: true,
            endDate: premiumFeatureTrialinfo.endDate,
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  purchaseSubscription = () => {
    this.props.history.push({
      pathname: '/homePricing',
    });
  };
  handleConfirmBoxClose = () => {
    this.setState({
      confirmBox: false,
    });
  };
  handleConfirmBox = () => {
    if (this.props.pageId === 'home') {
      this.setState({
        confirmBox: false,
      });
      this.state.closeModal(this.state);
      // this.props.onSuccess();
      this.forceUpdate();
    } else {
      this.setState({
        confirmBox: false,
      });
      this.props.history.push({
        pathname: '/prescriptionsList',
      });
    }
  };
  showFirstPremiumFeatures() {
    return (
      <>
        {this.props.pageId === 'home' ? (
          <></>
        ) : (
          <MenuWithBreadScrum breadCrumb={this.breadCrumb} history={this.props.history} name={this.title} />
        )}{' '}
        <Row style={{ margin: '1em' }}>
          <Col md={12}>
            <Card
              style={{
                height: '100%',
                borderRadius: '20px',
                boxShadow:
                  '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2)',
              }}
            >
              <CardHeader
                style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', textAlign: 'center', color: '#000' }}
              >
                <h3>
                  <b>Premium Features</b>
                </h3>
              </CardHeader>
              <CardBody>
                <Row style={{ textAlign: 'left', padding: '15px' }}>
                  <Col md={12} style={{ margin: 'auto' }}>
                    <p>This feature is part of our premium package and is available for subscribed users.</p>

                    <p style={{ lineHeight: '2em' }}>
                      The premium package includes the following:
                      <ul style={{ marginLeft: '1.5em' }}>
                        <li>
                          Consolidated View – Available on the home screen quick view of appointments across all the
                          circles you are enrolled in.
                        </li>
                        <li>
                          Prescriptions – Enter all of your medicines and vitamins to have readily available for your
                          next office visit.
                        </li>
                        <li>Ad Free experience – Ads are suppressed on all screens.</li>
                        <li>Unlimited Family Circles – Create or participate in as many Circles as needed.</li>
                        <li>Unlimited Appointments per month – Track as many appointments as needed.</li>
                      </ul>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Button
                      variant="contained"
                      size="small"
                      // type="submit"
                      style={{ background: '#138D75', color: '#fff', marginLeft: '3em' }}
                      onClick={this.startTrialPeriod.bind(this)}
                    >
                      Start A 30 day Trial Period
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      className="purchaseSubscription"
                      variant="contained"
                      size="small"
                      style={{
                        background: '#f26d21',
                        color: '#fff',
                        borderBottom: '3px solid #636262',
                        borderRadius: '5px',
                        margin: '50px auto',
                      }}
                      onClick={(e) => this.purchaseSubscription(e)}
                    >
                      Purchase Subscription
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Dialog
          open={this.state.confirmBox}
          onClose={this.handleConfirmBoxClose.bind(this)}
          onHide={this.close}
          style={{ padding: '1em' }}
        >
          <DialogContent style={{ marginTop: '1em' }}>
            <Row>
              <Col md={12} style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                <p style={{ lineHeight: '2em', fontSize: '18px' }}>
                  Your trial period has started.
                  <br /> You can enjoy all premium functionality till{' '}
                  {moment(this.state.endDate ? this.state.endDate : '').format('MMM DD, YYYY')}.
                </p>
              </Col>
            </Row>
          </DialogContent>
          <DialogActions style={{ marginTop: '1em', padding: '2em', marginLeft: 'auto', marginRight: 'auto' }}>
            <Button
              variant="contained"
              size="large"
              style={{ background: '#0f666b', color: '#fff', marginRight: '1rem' }}
              // startIcon={<SaveIcon />}
              onClick={(e) => this.handleConfirmBox(e)}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  showSecondPremiumFeatures() {
    return (
      <>
        {' '}
        {this.props.pageId === 'home' ? (
          <></>
        ) : (
          <MenuWithBreadScrum breadCrumb={this.breadCrumb} history={this.props.history} name={this.title} />
        )}
        <Row style={{ margin: '1em' }}>
          <Col md={12}>
            <Card
              style={{
                height: '100%',
                borderRadius: '20px',
                boxShadow:
                  '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2)',
              }}
            >
              <CardHeader
                style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', textAlign: 'center', color: '#000' }}
              >
                <h3>
                  <b>Premium Features</b>
                </h3>
              </CardHeader>
              <CardBody>
                <Row style={{ textAlign: 'left', padding: '15px' }}>
                  <Col md={12} style={{ margin: 'auto' }}>
                    <p>
                      Your Trial Period has expired on{' '}
                      {moment(this.PremiumFeatures ? this.PremiumFeatures.endDate : '').format('MMM DD, YYYY')}. Enroll
                      today to continue using our premium features. Consider enrolling today and become a subscribed
                      user.
                    </p>

                    <p style={{ lineHeight: '2em' }}>
                      The premium package includes the following:
                      <ul style={{ marginLeft: '1.5em' }}>
                        <li>
                          Consolidated View – Available on the home screen for a quick view of appointments across all
                          the circles you are enrolled in.
                        </li>
                        <li>
                          Prescriptions – Enter all of your medicines and vitamins to have readily available for your
                          next office visit.
                        </li>
                        <li>Ad Free experience – Ads are suppressed on all screens.</li>
                        <li>Unlimited Family Circles – Create or participate in as many Circles as needed.</li>
                        <li>Unlimited Appointments per month – Track as many appointments as needed.</li>
                      </ul>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    {/* <Button
                      variant="contained"
                      size="small"
                      // type="submit"
                      style={{ background: '#138D75', color: '#fff', marginLeft: '3em' }}
                      onClick={this.extendTrialPeriod.bind(this)}
                    >
                      Enroll me today
                    </Button>
                    &nbsp;&nbsp; */}
                    <Button
                      className="purchaseSubscription"
                      variant="contained"
                      size="small"
                      style={{
                        background: '#f26d21',
                        color: '#fff',
                        borderBottom: '3px solid #636262',
                        borderRadius: '5px',
                        margin: '50px auto',
                      }}
                      onClick={(e) => this.purchaseSubscription(e)}
                    >
                      Purchase Subscription
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Dialog
          open={this.state.confirmBox}
          onClose={this.handleConfirmBoxClose.bind(this)}
          onHide={this.close}
          style={{ padding: '1em' }}
        >
          <DialogContent style={{ marginTop: '1em' }}>
            <Row>
              <Col md={12} style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                <p style={{ lineHeight: '2em', fontSize: '18px' }}>
                  Your trial period has started.
                  <br /> You can enjoy all premium functionality till{' '}
                  {moment(this.state.endDate ? this.state.endDate : '').format('MMM DD, YYYY')}
                </p>
              </Col>
            </Row>
          </DialogContent>
          <DialogActions style={{ marginTop: '1em', padding: '2em', marginLeft: 'auto', marginRight: 'auto' }}>
            <Button
              variant="contained"
              size="large"
              style={{ background: '#0f666b', color: '#fff', marginRight: '1rem' }}
              // startIcon={<SaveIcon />}
              onClick={(e) => this.handleConfirmBox(e)}
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }
}

export default PremiumFeatures;
