import { Card } from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import TextField from '@material-ui/core/TextField';
import subDays from 'date-fns/subDays';
import moment from 'moment';
import React, { Component } from 'react';

import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { createMuiTheme } from '@material-ui/core';
import { DateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import { CardBody, Col, Row } from 'reactstrap';
import Utils from '../../../provider/Utils';
import store from '../../../redux/store';
import DeleteButton from '../buttons/DeleteButton';
import EditButton from '../buttons/EditButton';
import PtsAlert from '../ptsAlert/PtsAlert';
import ReminderTimer from '../reminderTimer/ReminderTimer';
import './Reminder.scss';
export default class Reminder extends Component {
  reminderIntervalList = store.getState().staticData.reminderIntervalList;
  originalState = null;

  constructor(props) {
    super(props);
    let action = props.action;
    let time1 = '';
    if (props.data.time !== undefined) {
      let time = props.data.time;
      let tmeDteArr = time.split('.');
      let startTime = tmeDteArr[0];
      time1 = moment(startTime).format('HH:MM');
    }
    let isViewButtonHide = true;
    if (props.isPrintView === 'view_print') {
      isViewButtonHide = false;
    }
    let startDate = '';
    let viewStartDate = '';
    let newStartDate = '';
    let appointmentDate = '';
    appointmentDate = props.reminderDate;
    /* if action is create then this function call*/
    if (props.action === 'create') {
      appointmentDate = props.reminderDate;

      /* set 24 hours before Reminder DateTime*/
      let date = new Date(new Date(props.reminderDate).getTime() - 60 * 60 * 24 * 1000);
      let reminderDateTime = new Date(date.toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0];

      /* set one hours Reminder DateTime*/
      let newDate = new Date(new Date());
      newDate.setHours(newDate.getHours() + 1);
      let onHoursDateTime = new Date(newDate.toString().split('GMT')[0] + ' UTC').toISOString().split('.')[0];

      /* set current date DateTime*/
      let currentDateTime = new Date(new Date().getTime() + 1 * 24 * 60 * 60 * 1000);
      if (appointmentDate < currentDateTime) {
        /*if current date  is greater than appointment date then one hours reminder date set */
        if (onHoursDateTime !== undefined) {
          startDate = onHoursDateTime;
          let now = new Date(startDate);

          newStartDate = now;
          viewStartDate = now;
        }
      } else {
        /*if appointment date is greater than current date then 24 hours reminder date set */
        if (reminderDateTime !== undefined) {
          startDate = reminderDateTime;
          let now = new Date(startDate);

          newStartDate = now;
          viewStartDate = now;
        }
      }
    } else {
      /* if action is view then this function call*/
      if (props.data.date !== undefined) {
        startDate = props.data.date;
        let now = new Date(startDate);

        newStartDate = now;
        viewStartDate = now;
      }
    }

    let pageId = props.pageId;
    let isButtonHide = false;
    if (
      pageId === 'editMemberAppointments' ||
      pageId === 'addAppointment' ||
      pageId === 'createEvent' ||
      pageId === 'editEvent' ||
      pageId === 'view_purchase' ||
      pageId === 'createPurchase' ||
      pageId === 'add_purchase'
    ) {
      isButtonHide = true;
    }

    this.state = {
      id: props.data.id,
      createdOn: props.data.createdOn,
      createdByName: props.data.createdByName,
      appointmentDate: appointmentDate,
      note: props.data.content,
      content: props.data.content,
      operation: props.data.operation,
      reminderDate: props.data.date,
      time: time1,
      date: props.data.date,
      timeView: props.data.dateTime,
      index: props.srNo,
      onDelete: props.onDelete,
      onChange: props.onChange,
      onReminderEdit: props.onReminderEdit,
      onReminderDelete: props.onReminderDelete,
      onUpdate: props.onUpdate,
      onCancel: props.onCancel,
      onReminderCreate: props.onReminderCreate,
      onReminderAdd: props.onReminderAdd,
      action: action,
      render: true,
      startDate: new Date(newStartDate),
      viewStartDate: viewStartDate,
      status: props.data.status,
      isButtonHide: isButtonHide,
      isViewButtonHide: isViewButtonHide,
      getDeletePermission: props.getDeletePermission,
      getUpdatePermission: props.getUpdatePermission,
      currentCompareDateTime: new Date(),
    };

    this.originalState = this.state;
  }

  updateReminderDate(e) {
    this.setState({ date: e.target.value });
  }

  handleDescriptionChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 256);
    if (res.length < 256) {
      this.setState({ note: res, content: res });
    } else {
      Utils.maxFieldLength(256);
    }
  }
  handleTimeChange(e) {
    let val = e.target.value;
    this.setState({ time: val });
  }

  handleStartDateChange(date) {
    if (date > this.state.appointmentDate) {
      PtsAlert.error('Enter Reminder date before Appointment Date');
      this.setState({
        startDate: this.state.startDate,
        date: date,
        viewStartDate: Utils.convertUTCDateToUserTimeZoneViewDateFormate(date),
      });
    } else {
      this.setState({
        startDate: date,
        date: date,
        viewStartDate: Utils.convertUTCDateToUserTimeZoneViewDateFormate(date),
      });
    }
  }
  updateReminder(e) {
    if (this.validateReminder() === false) {
      this.setState({ action: 'edit' });
      return;
    }

    if (this.state.onUpdate) {
      this.setState({ action: 'view' });
      this.state.onUpdate(this.state);
    }
  }

  createFacilityReminder(e) {
    if (undefined !== this.state.onReminderCreate && null !== this.state.onReminderCreate) {
      this.setState({ action: 'editReminder' });
      this.state.onReminderCreate(this.state);
    }
  }
  editReminderItem(e, id) {
    this.setState({
      id: id,
    });

    if (undefined !== this.state.onReminderEdit && null !== this.state.onReminderEdit) {
      this.state.onReminderEdit(this.state);
    }
    this.setState({ action: 'edit', render: true });
  }
  deleteReminder(e) {
    if (this.state.onDelete) {
      this.state.onDelete(this.state);
    }
  }

  cancelReminder(e) {
    if (this.state.onCancel) {
      this.state.onCancel(this.state);
    }
  }

  addReminder(e) {
    if (this.state.onReminderAdd) {
      this.state.onReminderAdd(this.state);
    }
  }

  backToViewAction() {
    let startDate = this.props.data.date;
    let now = new Date(startDate);
    let newState = this.originalState;
    newState.startDate = now;
    newState.action = 'view';
    this.setState(newState);
  }

  validateReminder(index) {
    if (!this.state.note) {
      document.getElementById('note' + this.state.index).focus();
      PtsAlert.error('Please Enter Note Details');

      return false;
    }
    return true;
  }
  render() {
    if (this.state.action === 'create') {
      return this.createReminder();
    } else if (this.state.action === 'edit') {
      return this.editReminder();
    } else if (this.state.action === 'editReminder') {
      return this.editViewReminder();
    } else if (this.state.action === 'printReminder') {
      return this.printReminder();
    } else {
      return this.viewReminder();
    }
  }

  createReminder() {
    return (
      <Card
        style={{
          backgroundColor: '#fbe2e3',
          marginBottom: ' 0px !important',
        }}
      >
        <CardBody>
          <Row style={{ padding: '10px' }}>
            <TextField
              fullWidth
              required
              variant="outlined"
              id={'note' + this.state.index}
              label="Title"
              value={this.state.note}
              name="note"
              inputProps={{ maxLength: 256 }}
              onChange={this.handleDescriptionChange.bind(this)}
              type="text"
              className="textField"
              style={{ background: '#fff' }}
            />
          </Row>
          <Row style={{ padding: '10px' }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <ThemeProvider theme={materialTheme}>
                <DateTimePicker
                  value={this.state.startDate}
                  variant="outlined"
                  fullWidth
                  // margin="normal"
                  inputVariant="outlined"
                  timeInputLabel="Time:"
                  minDate={subDays(new Date(), 1)}
                  format="MM/dd/yyyy  h:mm aa"
                  style={{ background: '#fff' }}
                  label="Date"
                  onChange={this.handleStartDateChange.bind(this)}
                />
              </ThemeProvider>
            </MuiPickersUtilsProvider>
          </Row>
          <Row>
            <Row style={{ marginTop: '0.5em', marginLeft: 'auto', marginRight: 'auto' }}>
              <Avatar className="ptsAvatar_note">
                <i
                  className="fa fa-check"
                  onClick={this.addReminder.bind(this)}
                  aria-hidden="true"
                  style={{ fontSize: '0.6em', color: '#162c50', cursor: 'pointer' }}
                ></i>
              </Avatar>
              &nbsp;
              <Avatar className="ptsAvatar_note">
                <i
                  className="fa fa-remove"
                  onClick={(e) => this.cancelReminder(e)}
                  aria-hidden="true"
                  style={{ fontSize: '0.6em', color: '#162c50', cursor: 'pointer' }}
                ></i>
              </Avatar>
            </Row>
          </Row>
        </CardBody>
      </Card>
    );
  }

  editReminder() {
    return (
      <Card
        style={{
          backgroundColor: '#fbe2e3',
          marginBottom: ' 0px !important',
        }}
      >
        <CardBody>
          <Row style={{ padding: '10px' }}>
            <TextField
              fullWidth
              required
              variant="outlined"
              id={'note' + this.state.index}
              label="Title"
              value={this.state.note}
              name="note"
              inputProps={{ maxLength: 256 }}
              onChange={this.handleDescriptionChange.bind(this)}
              type="text"
              className="textField"
              style={{ background: '#fff' }}
            />
          </Row>
          <Row style={{ padding: '10px' }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <ThemeProvider theme={materialTheme}>
                <DateTimePicker
                  value={this.state.startDate}
                  variant="outlined"
                  fullWidth
                  label="Date"
                  // margin="normal"
                  inputVariant="outlined"
                  timeInputLabel="Time:"
                  minDate={subDays(new Date(), 1)}
                  format="MM/dd/yyyy  h:mm aa"
                  style={{ background: '#fff' }}
                  onChange={this.handleStartDateChange.bind(this)}
                />
              </ThemeProvider>
            </MuiPickersUtilsProvider>
          </Row>
          <Row>
            <Row style={{ marginTop: '0.5em', marginLeft: 'auto', marginRight: 'auto' }}>
              <Avatar className="ptsAvatar_note">
                <i
                  className="fa fa-check"
                  onClick={this.updateReminder.bind(this)}
                  aria-hidden="true"
                  style={{ fontSize: '0.6em', color: '#162c50', cursor: 'pointer' }}
                ></i>
              </Avatar>
              &nbsp;
              <Avatar className="ptsAvatar_note">
                <i
                  className="fa fa-remove"
                  onClick={(e) => this.backToViewAction(e)}
                  aria-hidden="true"
                  style={{ fontSize: '0.6em', color: '#162c50', cursor: 'pointer' }}
                ></i>
              </Avatar>
            </Row>
          </Row>
        </CardBody>
      </Card>
    );
  }

  editViewReminder() {
    return (
      <Card
        style={{
          backgroundColor: '#fbe2e3',
          marginBottom: ' 0px !important',
        }}
      >
        <CardBody>
          <Row style={{ padding: '10px' }}>
            <TextField
              fullWidth
              variant="outlined"
              id={'note' + this.state.index}
              label="Title"
              value={this.state.note}
              required
              name="note"
              inputProps={{ maxLength: 256 }}
              onChange={this.handleDescriptionChange.bind(this)}
              type="text"
              className="textField"
              style={{ background: '#fff' }}
            />
          </Row>
          <Row style={{ padding: '10px' }}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <ThemeProvider theme={materialTheme}>
                <DateTimePicker
                  value={this.state.startDate}
                  variant="outlined"
                  fullWidth
                  label="Date"
                  // margin="normal"
                  inputVariant="outlined"
                  timeInputLabel="Time:"
                  minDate={subDays(new Date(), 1)}
                  format="MM/dd/yyyy  h:mm aa"
                  style={{ background: '#fff' }}
                  onChange={this.handleStartDateChange.bind(this)}
                />
              </ThemeProvider>
            </MuiPickersUtilsProvider>
          </Row>

          <Row>
            <Row style={{ marginTop: '0.5em', marginLeft: 'auto', marginRight: 'auto' }}>
              <Avatar className="ptsAvatar">
                <i
                  className="fa fa-check"
                  onClick={(e) => this.createFacilityReminder(e)}
                  aria-hidden="true"
                  style={{ fontSize: '0.6em', color: '#162c50' }}
                ></i>
              </Avatar>
              &nbsp;
              <Avatar className="ptsAvatar">
                <i
                  className="fa fa-close"
                  onClick={this.deleteReminder.bind(this)}
                  aria-hidden="true"
                  style={{ fontSize: '0.6em', color: '#162c50' }}
                ></i>
              </Avatar>
            </Row>
          </Row>
        </CardBody>
      </Card>
    );
  }

  viewReminder() {
    this.state.id = this.props.data.id;
    let startDate = '';
    let viewStartDate = '';

    if (this.props.data.date !== undefined) {
      startDate = this.props.data.date;

      let now = new Date(startDate);
      viewStartDate = Utils.convertUTCDateToUserTimeZoneViewDateFormate(now);
    }
    return (
      <div className="reminder_details_view">
        <div className="panel panel-yellow">
          <div className="panel-heading">
            <Row>
              <Col md={12} style={{ textAlign: 'center' }}>
                <span style={{ color: '#566573', padding: '5px 0px' }}>
                  {this.props.data.content ? <strong>{this.props.data.content}</strong> : ''}
                </span>
                {this.state.isViewButtonHide && (
                  <>
                    {this.props.getUpdatePermission === true && (
                      <>
                        {this.state.startDate < this.state.currentCompareDateTime ? (
                          ''
                        ) : (
                          <EditButton onClick={(e) => this.editReminderItem(e, this.props.data.id)} />
                        )}
                      </>
                    )}
                    {this.props.getDeletePermission === true && (
                      <DeleteButton domainObject="Reminder" onClick={(e) => this.deleteReminder(e)} />
                    )}
                  </>
                )}
              </Col>
            </Row>
          </div>
          <div className="panel-body">
            <span style={{ color: '#566573' }}>{viewStartDate}</span>
            <span>
              <ReminderTimer startDate={Utils.convertUTCDateToUserTimeZoneDate(this.state.startDate)} />
            </span>
          </div>
          <div className="panel-footer">
            <label
              style={{
                fontWeight: 'normal',
                color: '#888888',
                width: 'auto',
                fontSize: 'xx-small',
                textAlign: 'left',
                padding: '0',
                margin: '0',
                marginLeft: '0em',
              }}
            >
              {this.props.data.createdByName && 'Created by ' + this.props.data.createdByName}
              {this.props.data.createdOn && ' on ' + Utils.convertUTCDateToUserTimeZoneDate(this.props.data.createdOn)}
            </label>
          </div>
        </div>
      </div>
    );
  }

  printReminder() {
    let startDate = '';
    let viewStartDate = '';
    if (this.props.data.date !== undefined) {
      startDate = this.props.data.date;

      let now = new Date(startDate);
      viewStartDate = Utils.convertUTCDateToUserTimeZoneViewDateFormate(now);
    }
    
    return (
      <div className="reminder_details_view">
        <div className="panel panel-yellow">
          <div className="panel-heading">
            <Row>
              <Col md={12} style={{ textAlign: 'center' }}>
                <span style={{ color: '#566573', padding: '5px 0px' }}>
                  {this.props.data.content ? <strong>{this.props.data.content}</strong> : ''}
                </span>
              </Col>
            </Row>
          </div>
          <div className="panel-body">
            <span style={{ color: '#566573' }}>{viewStartDate}</span>
            <span>
              <ReminderTimer startDate={this.state.startDate} />
            </span>
          </div>
          <div className="panel-footer">
            <label
              style={{
                fontWeight: 'normal',
                color: '#888888',
                width: 'auto',
                fontSize: 'xx-small',
                textAlign: 'left',
                padding: '0',
                margin: '0',
                marginLeft: '0em',
              }}
            >
              {this.props.data.createdByName && 'Created by ' + this.props.data.createdByName}
              {this.props.data.createdOn && ' on ' + Utils.convertUTCDateToUserTimeZoneDate(this.props.data.createdOn)}
            </label>
          </div>
        </div>
      </div>
    );
  }
}
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#008181',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: 'white',
        color: '#008181',
      },
    },
  },
});
