import React, { Component } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { Row, Col, Card, CardBody, CardHeader, CardFooter } from 'reactstrap';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import { ValidatorForm } from 'react-material-ui-form-validator';
import InputAdornment from '@material-ui/core/InputAdornment';
import IconButton from '@material-ui/core/IconButton';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import FetchServerData from '../../provider/FetchServerData';
import store from '../../redux/store';
import PtsAlert from '../../pts/components/ptsAlert/PtsAlert';
export default class updateCredential extends Component {
  constructor(props) {
    super(props);
    let action = props.action;
    if (
      null !== props.history &&
      props.history !== undefined &&
      props.history.location !== null &&
      props.history.location.state !== null &&
      props.history.location.state !== undefined
    ) {
      action = props.history.location.state.action;
    }
    this.state = {
      email: '',
      password: '',
      code: '',
      confPassword: '',
      showPassword: false,
      showConfPassword: false,
      action: action,
    };
  }
  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }
  handlePasswordChange(e) {
    this.setState({ password: e.target.value });
  }
  handleConfPasswordChange(e) {
    this.setState({ confPassword: e.target.value });
  }
  handleVerificationCodeChange(e) {
    this.setState({ code: e.target.value });
  }
  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }
  handleClickShowConfPassword() {
    this.setState({ showConfPassword: !this.state.showConfPassword });
  }
  updateCredentialInBackend = () => {
    if (this.state.password !== this.state.confPassword) {
      alert("Passwords don't match");
    } else {
      const header = store.getState().header.header;

      const postObject = {
        header: header,
        appuserVo: {
          email: this.state.email,
          credential: this.state.password,
        },
      };
      FetchServerData.callPostService('/userms/updateCredential', postObject).then((output) => {
        if (output.status === 'SUCCESS') {
          PtsAlert.success(output.message);

          this.props.history.push({
            pathname: '/login',
          });
        } else {
        }
      });
    }
  };
  render() {
    return (
      <>
        <Col md={4}></Col>
        <Col md={4}>
          <Card class="formCard" style={{ marginTop: '64px' }}>
            <Row md={12} style={{ marginTop: '2em' }}>
              <Col>
                <CardHeader style={{ width: '100%', borderBottom: '1px solid #dcdcdc' }}>
                  <h4 style={{ color: '#169ba2' }}>
                    <b>Update Password</b>
                  </h4>
                </CardHeader>
              </Col>
            </Row>
            <CardBody>
              <ValidatorForm ref="form" onSubmit={this.updateCredentialInBackend.bind(this)}>
                <Row>
                  <Col md={1}></Col>
                  <Col md={10}>
                    <TextField
                      margin="normal"
                      variant="outlined"
                      required
                      fullWidth
                      id="email"
                      type="email"
                      onChange={this.handleEmailChange.bind(this)}
                      label="Email"
                      name="email"
                      autoComplete="email"
                      autoFocus
                      value={this.state.email}
                    />
                  </Col>
                  <Col md={1}></Col>
                </Row>

                <Row>
                  <Col md={1}></Col>
                  <Col md={10}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="New Password"
                      type={this.state.showPassword ? 'text' : 'password'}
                      id="password"
                      autoComplete="current-password"
                      onChange={this.handlePasswordChange.bind(this)}
                      value={this.state.password}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowPassword.bind(this)}
                            >
                              {this.state.showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Col>
                  <Col md={1}></Col>
                </Row>
                <Row>
                  <Col md={1}></Col>
                  <Col md={10}>
                    <TextField
                      variant="outlined"
                      margin="normal"
                      required
                      fullWidth
                      name="password"
                      label="Confirm Password"
                      type={this.state.showConfPassword ? 'text' : 'password'}
                      id="password"
                      autoComplete="current-password"
                      onChange={this.handleConfPasswordChange.bind(this)}
                      value={this.state.confPassword}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              aria-label="toggle password visibility"
                              onClick={this.handleClickShowConfPassword.bind(this)}
                            >
                              {this.state.showConfPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Col>
                  <Col md={1}></Col>
                </Row>
                <Row md={12} style={{ marginTop: '2em' }}>
                  <Col>
                    <CardFooter style={{ width: '100%' }}>
                      <div style={{ textAlign: 'center' }}>
                        <Button
                          type="submit"
                          style={{ backgroundColor: '#1a7088', color: '#fff' }}
                          variant="contained"
                          startIcon={<LockOpenIcon />}
                        >
                          Save
                        </Button>
                        &nbsp;
                        {/* <NavLink
                          style={{ backgroundColor: '#777777', color: '#fff' }}
                          to="/login"
                          activeClassName="selectedLink"
                          strict
                        >
                          <Button style={{ backgroundColor: '#777777', color: '#fff' }} startIcon={<CancelIcon />}>
                            Cancel
                          </Button>
                        </NavLink> */}
                      </div>
                    </CardFooter>
                  </Col>
                </Row>
              </ValidatorForm>
            </CardBody>
          </Card>
        </Col>
      </>
    );
  }
}
