import Link from '@material-ui/core/Link';
import TextField from '@material-ui/core/TextField';
import React, { Component } from 'react';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Card, CardBody, CardFooter, CardHeader, Col, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import CancelIconButton from '../buttons/CancelIconButton';
import GenericButton from '../buttons/GenericButton';
import PtsAlert from '../ptsAlert/PtsAlert';
export default class Verification extends Component {
  constructor(props) {
    super(props);
    let action = props.action;
    let email = props.email;
    let isDisabled = false;
    let pathUrl = '';
    if (props.history && props.history.location && props.history.location.state) {
      action = props.history.location.state.action;
      email = props.history.location.state.email;
      pathUrl = props.history.location.state.url;
    }

    let emailAddress = '';

    if (pathUrl === '/createAccount') {
      emailAddress = email;
    } else if (pathUrl === '/login') {
      emailAddress = email;
    } else {
      let str = window.location.href;
      let urlArr = str.split('?');
      let urlEmail = urlArr[1].split('=');
      emailAddress = urlEmail[1];
    }

    if (emailAddress && emailAddress.length > 0) {
      isDisabled = true;
    }
    this.state = {
      email: emailAddress,
      isEmailDisabled: isDisabled,
      code: '',
      showPassword: false,
      action: action,
    };
  }
  handleVerificationCodeChange(e) {
    this.setState({ code: e.target.value });
  }
  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }
  cancelView(e) {
    this.props.history.push({
      pathname: '/login',
      state: {},
    });
  }

  resendOTP() {
    if (Utils.isNullOrEmpty(this.state.email)) {
      return;
    }
    const postObject = {
      registration: {
        email: this.state.email,
      },
    };
    FetchServerData.callPostService('/regms/verificationCodeRequest', postObject)
      .then((response) => {
        let output = response;
        if (output.status === 'SUCCESS') {
          PtsAlert.success('OTP Sent on ' + this.state.email);
        }
      })
      .catch((error) => {});
  }

  verifyAccountInBackend = () => {
    const postObject = {
      registrationVo: {
        emailOrPhone: this.state.email,
        varificationCode: this.state.code,
      },
    };
    FetchServerData.callPostService('/regms/verify', postObject)
      .then((response) => {
        let output = response;
        if (output.status === 'SUCCESS') {
          this.props.history.push({
            pathname: '/login',
          });
        } else {
        }
      })
      .catch((error) => {});
  };
  render() {
    return (
      <div md={12} style={{ display: 'flex', padding: '2em' }}>
        <Card
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            margin: 'auto',
            padding: '1em',
          }}
        >
          <CardHeader>
            <Row md={12} style={{ marginTop: '2em' }}>
              <span style={{ color: '#169ba2', fontSize: 'large' }}>
                <b>Account Verification</b>
              </span>
            </Row>
          </CardHeader>
          <ValidatorForm
            ref="form"
            onSubmit={this.verifyAccountInBackend.bind(this)}
            style={{
              width: '100%',
              alignItems: 'center',
              textAlign: 'center',
              padding: '1em',
            }}
          >
            <CardBody>
              <Row style={{ padding: '0.5em' }}>
                <Col md={12}>
                  <span>
                    <strong>Congratulations! You are successfully registered on Family Care Circle.</strong>
                    <br></br>
                    An Authentication Code has been sent to your registered email address. Please check your email for
                    more details.
                  </span>
                </Col>
              </Row>
              <Row style={{ padding: '0.5em' }}>
                <Col md={12}>
                  {this.state.isEmailDisabled && this.state.isEmailDisabled === true && (
                    <TextField
                      variant="outlined"
                      required
                      disabled
                      fullWidth
                      id="email"
                      autoComplete="off"
                      style={{ color: '#000' }}
                      onChange={this.handleEmailChange.bind(this)}
                      label="Email"
                      name="email"
                      value={this.state.email}
                    />
                  )}
                  {!this.state.isEmailDisabled ||
                    (this.state.isEmailDisabled !== true && (
                      <TextField
                        variant="outlined"
                        required
                        fullWidth
                        id="email"
                        autoComplete="off"
                        style={{ color: '#000' }}
                        onChange={this.handleEmailChange.bind(this)}
                        label="Email"
                        name="email"
                        value={this.state.email}
                      />
                    ))}
                </Col>
              </Row>
              <Row style={{ padding: '0.5em' }}>
                <Col md={12}>
                  <TextField
                    margin="normal"
                    variant="outlined"
                    required
                    value={this.state.code}
                    onChange={this.handleVerificationCodeChange.bind(this)}
                    fullWidth
                    id="code"
                    autoComplete="off"
                    label="Authentication Code"
                    name="code"
                  />
                </Col>
              </Row>
            </CardBody>
            <CardFooter style={{ width: '100%' }}>
              <Row md={12}>
                <div style={{ width: '100%', textAlign: 'center' }}>
                  <GenericButton type="submit" name="Verify" />
                  <CancelIconButton onClick={(e) => this.cancelView()} />
                  <Link
                    variant="body2"
                    style={{ float: 'right', cursor: 'pointer' }}
                    onClick={this.resendOTP.bind(this)}
                  >
                    Resend Authentication Code?
                  </Link>
                </div>
              </Row>
            </CardFooter>
          </ValidatorForm>
        </Card>
      </div>
    );
  }
}
