import {
  CLEAR_ADMIN_FCC_PAGE_STATE,
  GET_ADMIN_FCC_PAGE_STATE,
  SET_ADMIN_FCC_PAGE_STATE,
} from './adminFccPageStateType';

const getAdminFccPageState = () => {
  return {
    type: GET_ADMIN_FCC_PAGE_STATE,
  };
};

const setAdminFccPageState = (data) => {
  return {
    type: SET_ADMIN_FCC_PAGE_STATE,
    payload: data,
  };
};

const clearAdminFccPageState = (data) => {
  return {
    type: CLEAR_ADMIN_FCC_PAGE_STATE,
    payload: data,
  };
};
const adminFccPageStateAction = { getAdminFccPageState, setAdminFccPageState, clearAdminFccPageState };
export default adminFccPageStateAction;
