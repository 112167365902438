import { CLEAR_FAMILY_CIRCLE_STATE, GET_FAMILY_CIRCLE_STATE, SET_FAMILY_CIRCLE_STATE } from './familyCircleTypes';

const initialState = {
  familyCircleState: {},
};

const familyCircleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FAMILY_CIRCLE_STATE:
      return {
        ...state,
      };
    case SET_FAMILY_CIRCLE_STATE:
      return {
        ...state,
        familyCircleState: action.payload,
      };

    case CLEAR_FAMILY_CIRCLE_STATE:
      return {
        ...state,
        familyCircleState: null,
      };
    default:
      return state;
  }
};

export default familyCircleReducer;
