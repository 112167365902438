import React, { Component } from 'react';
import { Col, Row, Card } from 'reactstrap';
import FetchServerData from '../../../../provider/FetchServerData';
import selectedPlanAction from '../../../../redux/selectedPlan/selectedPlanAction';
import store from '../../../../redux/store';
import MenuWithBreadScrum from '../../menu/MenuWithBreadScrum';
import './PricingHome.scss';

export default class PricingHome extends Component {
  title = 'Pricing';
  breadCrumb = [
    { pageid: '/profileHome', uiname: 'User Profile Details' },
    { pageid: '', uiname: 'Pricing' },
  ];
  header = store.getState().header.header;
  userId = store.getState().userData.userData.id;
  constructor(props) {
    super(props);
    this.state = {
      businessPromotionForm: false,
      firmName: '',
      firmWebsite: '',
      planList: [],
      planPriceType: '',
      planPrice: '',
      modal: false,
      planItemsList: [],
      freePlanItemsList: [],
      planListDetails: [],
      discount: '',
      render: false,
    };
    this.getPlanesData();
  }

  getPlanesData = () => {
    FetchServerData.callPostService('planms/geAllPlans').then((output) => {
      if (output.status === 'SUCCESS') {
        let plansData = output.data;

        let monthlyPlanPrice = '';
        let monthlyDiscount = '';
        let planItemsList = [];
        let freePlanItemsList = [];
        let planListDetails = [];
        let planType = '';
        let FccSubscriptionName = '';
        for (let i = 0; i < plansData.length; i++) {
          plansData[0].monthlyPlanClass = 'plan-inner_free_plan_card_plan_card_bottom';
          plansData[0].monthlyPlanBtnClass = 'plan-inner_free_plan_button_plan_card_bottom';
          plansData[1].monthlyPlanClass = 'plan-inner_basic_plan_card_plan_card_bottom';
          plansData[1].monthlyPlanBtnClass = 'plan-inner_basic_plan_button_plan_card_bottom';

          for (let j = 0; j < plansData[i].planList.length; j++) {
            monthlyPlanPrice = plansData[i].planList[0].price;
            monthlyDiscount = plansData[i].planList[0].discountPercent;
            planType = plansData[i].planList[0].id;
            planItemsList = plansData[i].planList[0].planItems;
            FccSubscriptionName = plansData[i].planName;
            let userSelectedPlan = {
              selectedPlanId: plansData[i].id,
              selectedPlanName: plansData[i].planName,
              selectedPlanList: plansData[i].planList[0],
            };
            store.dispatch(selectedPlanAction.setSelectedPlan(userSelectedPlan));
            if (plansData[i].planName === 'FCC Subscription') {
              planListDetails = plansData[i].planList;
            }
          }
        }

        this.setState({
          render: true,
          planList: plansData,
          planPriceType: planType,
          planPrice: monthlyPlanPrice,
          planItemsList: planItemsList,
          freePlanItemsList: freePlanItemsList,
          discount: monthlyDiscount,
          planListDetails: planListDetails,
          FccSubscriptionName: FccSubscriptionName,
        });
      } else {
      }
    });
  };

  toBusinessPromotionForm = (planDetails, e) => {
    console.log('planDetails', planDetails);
    let userSelectedPlan = {
      selectedPlanId: planDetails.id,
      selectedPlanName: planDetails.name,
      selectedPlanList: planDetails,
    };
    store.dispatch(selectedPlanAction.setSelectedPlan(userSelectedPlan));
    if (this.state.FccSubscriptionName === 'FCC Subscription') {
      this.props.history.push({
        pathname: '/checkout',
      });
    } else {
      this.props.history.push({
        pathname: '/termsConditionsRegistration',
      });
    }
  };

  handlePlanPriceTypeChange(e, planId) {
    for (let i = 0; i < this.state.planList.length; i++) {
      for (let j = 0; j < this.state.planList[i].planList.length; j++) {
        if (parseInt(e.target.value) === this.state.planList[i].planList[j].id) {
          let userSelectedPlan = {
            selectedPlanId: planId,
            selectedPlanName: this.state.planList[i].planName,
            selectedPlanList: this.state.planList[i].planList[j],
          };
          store.dispatch(selectedPlanAction.setSelectedPlan(userSelectedPlan));
          this.setState({
            planPrice: this.state.planList[i].planList[j].price,
            discount: this.state.planList[i].planList[j].discountPercent,
            planPriceType: e.target.value,
            planItemsList: this.state.planList[i].planList[j].planItems,
          });
        }
      }
    }
    this.setState({
      planPriceType: e.target.value,
    });
  }

  displayPlanType(planPriceList) {
    let optionTemplate = planPriceList.map((v) => <li value={v.id}>{v.plantype}</li>);

    return optionTemplate;
  }

  displayPlanCard() {
    let plan = [];
    for (let i = 0; i < this.state.planList.length; i++) {
      let feature = [];
      let price = [];
      plan.push(
        <>
          {this.state.planList[i].planName === 'FCC Subscription' ? (
            <Col md={3} style={{ margin: 'auto' }}>
              <Card>
                <div className={this.state.planList[i].monthlyPlanClass} data-aos="zoom-in" data-aos-delay={300}>
                  <div className="plan-inner">
                    <div className="entry-title">
                      <h3 style={{ padding: '7px' }}> {this.state.planList[i].planName}</h3>
                      <div className="price">
                        {/* {this.state.planList[i].planName === 'FCC Subscription' ? (
                        <> */}
                        <span style={{ left: '12px' }}>
                          {this.state.planPrice === 19.1
                            ? '$' + this.state.planPrice + '0'
                            : '$' + this.state.planPrice}
                        </span>
                        {/* </> */}
                        {/* ) : (
                        <span style={{ left: '12px' }}> {'$' + '0.00'}</span>
                      )} */}
                      </div>
                    </div>
                    <div className="entry-content">
                      <ul style={{ height: ' 317px' }}>
                        {this.state.planList[i].planName === 'FCC Subscription' ? (
                          <>
                            <li>
                              {' '}
                              <b>
                                {this.state.discount === '0.0'
                                  ? ''
                                  : 'Discount : ' + Math.round(this.state.discount) + '%'}
                              </b>
                            </li>
                          </>
                        ) : (
                          ''
                        )}
                        {feature}
                        {this.state.planList[i].planName === 'FCC Subscription' && (
                          <>
                            {/* // <li style={{ padding: '2em' }}>
                        //   {' '}
                        //   <select
                        //     className="planPriceDropDown"
                        //     value={this.state.planPriceType}
                        //     onChange={(e) => {
                        //       this.handlePlanPriceTypeChange(e, this.state.planList[i].id);
                        //     }}
                        //   > */}
                            {this.displayPlanType(this.state.planList[i].planList)}
                            {/* //   </select>
                        // </li> */}
                          </>
                        )}
                      </ul>
                    </div>
                    <Row>
                      <Col md={12} style={{ textAlign: 'center' }}>
                        <div className={this.state.planList[i].monthlyPlanBtnClass}>
                          <a
                            onClick={this.toBusinessPromotionForm.bind(this, this.state.FccSubscriptionName)}
                            style={{ borderRadius: '10px', cursor: 'pointer' }}
                          >
                            {this.state.planList[i].planName === 'FREE WITH ADS'
                              ? 'Register Now'
                              : [this.state.planList[i].planName === 'FCC Subscription' ? ' Buy Now' : 'Register']}
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Card>
            </Col>
          ) : (
            ''
          )}
        </>
      );

      for (let j = 0; j < this.state.planList[i].planList.length; j++) {
        this.state.planList[i].planList[j].planItems.sort((a, b) => a.displaySequence - b.displaySequence);
        for (let k = 0; k < this.state.planList[i].planList[j].planItems.length; k++) {
          if (this.state.planList[i].planList[j].plantype === 'Monthly') {
            feature.push(<li>{this.state.planList[i].planList[j].planItems[k].name}</li>);
          } else if (this.state.planList[i].planList[j].plantype === null) {
            feature.push(<li>{this.state.planList[i].planList[j].planItems[k].name}</li>);
          }
        }
      }
      for (let j = 0; j < this.state.planList[i].planList.length; j++) {
        if (this.state.planList[i].planList[j].plantype === 'Monthly') {
          price.push(<span style={{ left: '7px' }}> {'$' + this.state.planList[i].planList[j].price}</span>);
        } else if (this.state.planList[i].planList[j].plantype === null) {
          price.push(<span style={{ left: '7px' }}> {'$' + this.state.planList[i].planList[j].price}</span>);
        }
      }
    }

    return plan;
  }
  showFeature(planList) {
    console.log(planList.planItems);
    let feature = [];
    let planItems = planList.planItems.sort((a, b) => a.displaySequence - b.displaySequence);
    for (let i = 0; i < planItems.length; i++) {
      feature.push(<li>{planItems[i].name}</li>);
    }
    return feature;
  }
  displayPlansCardList() {
    if (this.state.render === false) {
      return <div />;
    } else {
      let colours = ['#dd4b5e', '#53cfe9', '#48C9B0', '#A569BD', '#EB984E'];
      console.log(this.state.planList, 'this.state.planList');
      return this.state.planListDetails.map((planData, index) => {
        console.log(colours, colours);
        return (
          <>
            {' '}
            {/* {planData.planName === 'FCC Subscription' ? ( */}
            <Col md={3} style={{ marginTop: planData.plantype === 'Daily' ? '2em' : '0em' }}>
              <div
                className={planData.monthlyPlanClass}
                data-aos="zoom-in"
                data-aos-delay={300}
                style={{ backgroundColor: colours[index % colours.length], width: '320px' }}
              >
                <div className="plan-inner">
                  <div className="home-entry-title" style={{ backgroundColor: colours[index % colours.length] }}>
                    <h3 style={{ padding: '7px', backgroundColor: colours[index % colours.length] }}>
                      {' '}
                      {planData.plantype}
                    </h3>
                    <div className="price" style={{ backgroundColor: colours[index % colours.length] }}>
                      {/* {planData.planName === 'FCC Subscription' ? ( */}
                      <>
                        <span style={{ left: '12px' }}>
                          {/* {this.state.planPrice === 19.1
                              ? '$' + this.state.planPrice + '0' */}
                          {'$' + planData.price}
                        </span>
                      </>
                      {/* ) : (
                        <span style={{ left: '12px' }}> {'$' + '0.00'}</span>
                      )} */}
                    </div>
                  </div>
                  <div className="entry-content">
                    <ul
                    // style={{ height: '277px' }}
                    >
                      {/* {planData.planName === 'FCC Subscription' ? ( */}
                      <>
                        <li>
                          {' '}
                          <b>{'Discount : ' + Math.round(planData.discountPercent) + '%'}</b>
                        </li>
                      </>
                      {/* ) : (
                        ''
                      )} */}
                      {this.showFeature(planData)}
                      {/* <li>
                        <h5>
                          {' '}
                          <b> {planData.plantype}</b>
                        </h5>
                      </li> */}
                    </ul>
                  </div>
                  <Row>
                    <Col md={12} style={{ textAlign: 'center' }}>
                      <div
                        className="plan-inner_free_plan_button_plan_card_bottom"
                        // style={{ backgroundColor: colours[index % colours.length] }}
                      >
                        <a
                          onClick={this.toBusinessPromotionForm.bind(this, planData)}
                          style={{
                            borderRadius: '10px',
                            cursor: 'pointer',
                            backgroundColor: colours[index % colours.length],
                          }}
                        >
                          {this.state.FccSubscriptionName === 'FREE WITH ADS'
                            ? 'Register Now'
                            : [this.state.FccSubscriptionName === 'FCC Subscription' ? ' Buy Now' : 'Register']}
                        </a>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            {/* ) : (
              ''
            )} */}
          </>
        );
      });
    }
  }
  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <MenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            name={this.title}
            domainObject="Pricing"
            headerWidth="home"
          />

          <section id="pricing" className="pricing" style={{ padding: '1em', color: '#fff', marginLeft: '1.5em' }}>
            <Row>
              <Col md={12} style={{ color: '#000' }}>
                <h4>
                  <b> FCC Subscription</b>
                </h4>
              </Col>
            </Row>
            {/* <div className="row container" style={{ marginTop: '1.5em', marginRight: 'auto', marginLeft: 'auto' }}> */}
            <Row style={{ marginTop: '2em' }}>
              {/* {this.displayPlanCard()} */}
              {this.displayPlansCardList()}
            </Row>
            {/* </div> */}
          </section>
        </>
      );
    }
  }
}
