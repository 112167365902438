import React, { Component } from 'react';
import store from '../../../redux/store';
import { Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap';
import TextField from '@material-ui/core/TextField';
import PtsAlert from '../../components/ptsAlert/PtsAlert';
import CancelIconButton from '../../components/buttons/CancelIconButton';
import CreateButton from '../../components/buttons/CreateButton';
import SaveButton from '../../components/buttons/SaveButton';
import FetchServerData from '../../../provider/FetchServerData';
import AdminMenuWithBreadScrum from '../adminMenu/AdminMenuWithBreadScrum';
import profileManagementAction from '../../../redux/adminPanel/profileManagement/ProfileManagementAction';
import Utils from '../../../provider/Utils';
import BackButton from '../../components/buttons/BackButton';
class AdminProfileManagement extends Component {
  header = store.getState().adminHeader.adminHeader;
  // adminProfileDetails = store.getState().adminProfileDetails.adminProfileDetails;
  adminFccPageState = store.getState().adminFccPageState.adminFccPageState;

  adminBreadCrumb = [
    { pageid: 'apSetting', uiname: 'Admin Panle Profile Management List' },
    { pageid: '', uiname: 'Admin Panle Profile Details' },
  ];
  constructor(props) {
    super(props);
    let action = props.action;
    let adminProfileId = props.adminProfileId;
    let pageId = '';
    let title = '';
    let canRender = false;

    if (!adminProfileId && props.history && props.history.location && props.history.location.state) {
      adminProfileId = props.history.location.state.adminProfileId;
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
      title = props.history.location.state.title;
    }

    if (null !== adminProfileId && adminProfileId > 0) {
      this.getAdminProfileDetails(adminProfileId);
    } else {
      canRender = true;
    }
    if (this.adminFccPageState) {
      pageId = this.adminFccPageState.pageId;
    }
    if (adminProfileId === undefined) {
      if (pageId === 'adminProfileList') {
        adminProfileId = this.adminFccPageState.id;
        action = this.adminFccPageState.action;
        title = this.adminFccPageState.title;
      } else {
        adminProfileId = this.adminProfileDetails.id;
        action = 'edit';
      }
      if (action !== 'create') {
        this.getProfileDetails(adminProfileId);
      }
    }

    this.state = {
      action: action,
      adminProfileId: adminProfileId,
      pageId: pageId,
      title: title,
      adminProfile: '',
      initialPage: 0,
      pageSize: 10,
      profileList: [],
      menus: [],
      activeMenus: new Map(),
      updateProfile: false,
      addProfile: true,
      isPaging: false,
      getUpdatePermission: false,
      getDeletePermission: false,
      render: false,
    };
  }

  getAdminProfileDetails(adminProfileId) {
    const postObject = {
      header: this.header,
      profile: {
        id: adminProfileId,
      },
    };

    FetchServerData.callPostService('/apProfilems/get', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let adminProfileDetails = output.data.profile;
          store.dispatch(profileManagementAction.setProfile(adminProfileDetails));
          let getPermission = Utils.getRolePermission(output.data.privilegeList);
          let getUpdatePermission = getPermission[3];
          let getDeletePermission = getPermission[4];
          this.setState({
            render: true,
            id: adminProfileDetails.id,
            adminProfile: adminProfileDetails.name,
            menus: adminProfileDetails.menus,
            getUpdatePermission: getUpdatePermission,
            getDeletePermission: getDeletePermission,
          });
        } else {
        }
      })
      .catch((error) => {});
  }
  handleAdminProfileChange(e) {
    this.setState({ adminProfile: e.target.value });
  }

  createCancel() {
    this.props.history.push({
      pathname: '/apSetting',
    });
  }
  editCancel() {
    this.props.history.push({
      pathname: '/viewApProfileManagement',
      state: {
        action: 'view',
        title: 'adminProfile Details',
        adminProfileId: this.state.id,
      },
    });
  }

  createAdminProfileToBackend() {
    const postObject = {
      header: this.header,
      profile: {
        name: this.state.adminProfile,
      },
    };
    FetchServerData.callPostService('/apProfilems/create', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        PtsAlert.success(output.message);
        let profileDetails = output.data.profile;
        // this.getAdminProfileDetails(profileDetails.id);
        this.setState({
          // action: "edit",
          updateProfile: true,
          addProfile: false,
          id: profileDetails.id,
          adminProfile: profileDetails.name,
          menus: profileDetails.menus,
        });
      } else {
      }
    });
  }

  updateAdminProfileToBackend() {
    const postObject = {
      header: this.header,
      profile: {
        name: this.state.adminProfile,
        id: this.state.id,
        menus: this.state.menus,
      },
    };
    FetchServerData.callPostService('/apProfilems/update', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        PtsAlert.success(output.message);
        let adminProfileData = output.data.profile;
        this.props.history.push({
          pathname: '/viewApProfileManagement',
          state: { action: 'view', title: 'adminProfile Details', adminProfileId: adminProfileData.id },
        });
      } else {
      }
    });
  }

  editAdminProfile() {
    this.props.history.push({
      pathname: '/editApProfileManagement',
      state: {
        action: 'edit',
        title: 'Edit Admin Profile',
        adminProfileId: this.state.id,
      },
    });
  }
  viewCancel() {
    this.props.history.push({
      pathname: '/apSetting',
    });
  }

  deleteAdminProfileToBackend = () => {
    const postObject = {
      header: this.header,
      profile: {
        id: this.state.id,
      },
    };
    FetchServerData.callPostService('/apProfilems/delete', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        this.props.history.push({
          pathname: '/apSetting',
        });
      } else {
      }
    });
  };

  showrolesubMenu(menuId) {
    let menus = this.state.activeMenus;
    if (menus.has(menuId)) {
      menus.delete(menuId);
    } else {
      menus.set(menuId, new Set());
    }
    this.setState({ activeMenus: menus });
  }
  showDomainObject(domainObjectId, menuId) {
    let domainObjects = this.state.activeMenus.get(menuId);
    if (domainObjects.has(domainObjectId)) {
      //remove
      domainObjects.delete(domainObjectId);
    } else {
      //Add
      domainObjects.add(domainObjectId);
    }
    this.setState({ activeMenus: this.state.activeMenus.set(menuId, domainObjects) });
  }

  onDomainObjectPrivilegeClicked(id) {
    this.state.menus.map((menu) => {
      menu.domainObjects.map((domainObject) => {
        domainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
          if (domainObjectPrivilege.id === id) {
            if (domainObjectPrivilege.isactive === false) {
              this.handleDomainObjectPrivilegeChecked(domainObjectPrivilege, domainObject, menu);
            } else {
              this.handleDomainObjectPrivilegeUnChecked(domainObjectPrivilege, domainObject, menu);
            }
            return;
          }
        });
      });
      menu.childMenus?.map((submenu) => {
        submenu.domainObjects.map((domainObject) => {
          domainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
            if (domainObjectPrivilege.id === id) {
              if (domainObjectPrivilege.isactive === false) {
                this.handleDomainObjectPrivilegeChecked(domainObjectPrivilege, domainObject, menu);
              } else {
                this.handleDomainObjectPrivilegeUnChecked(domainObjectPrivilege, domainObject, menu);
              }

              return;
            }
          });
        });
      });
    });
    this.setState({ menus: this.state.menus });
  }

  handleDomainObjectPrivilegeChecked(inputDomainObjectPrivilege, inputdomainObject, menu) {
    let inputPrivilge = inputDomainObjectPrivilege;
    if (inputPrivilge.name === '*') {
      inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
        domainObjectPrivilege.isactive = false;
      });
      inputDomainObjectPrivilege.isactive = true;
    } else if (inputPrivilge.name === 'Read') {
      inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
        let privilege = domainObjectPrivilege;
        if (privilege.name === 'Read') {
          domainObjectPrivilege.isactive = true;
        }
        if (privilege.name === '*') {
          domainObjectPrivilege.isactive = false;
        }
      });
    } else {
      inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
        let privilege = domainObjectPrivilege;
        if (privilege.name === 'Read') {
          domainObjectPrivilege.isactive = true;
        } else if (privilege.name === inputPrivilge.name) {
          domainObjectPrivilege.isactive = true;
        }
      });

      let isCreateCheckd = false;
      let isUpdateCheckd = false;
      let isDeleteheckd = false;
      let isApproveCheckd = false;
      inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
        let privilege = domainObjectPrivilege;
        if (privilege.name === 'Create' && domainObjectPrivilege.isactive === true) {
          isCreateCheckd = true;
        } else if (privilege.name === 'Update' && domainObjectPrivilege.isactive === true) {
          isUpdateCheckd = true;
        } else if (privilege.name === 'Delete' && domainObjectPrivilege.isactive === true) {
          isDeleteheckd = true;
        } else if (privilege.name === 'Approve' && domainObjectPrivilege.isactive === true) {
          isApproveCheckd = true;
        }
      });
      // console.log("isCreateCheckd=" + isCreateCheckd, "isUpdateCheckd=" + isUpdateCheckd, "isDeleteheckd=" + isDeleteheckd, "isApproveCheckd=" + isApproveCheckd);
      if (isCreateCheckd && isUpdateCheckd && isDeleteheckd && isApproveCheckd) {
        inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
          let privilege = domainObjectPrivilege;
          if (privilege.name === '*') {
            domainObjectPrivilege.isactive = true;
          } else {
            domainObjectPrivilege.isactive = false;
          }
        });
      } else {
        inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
          let privilege = domainObjectPrivilege;
          if (privilege.name === '*') {
            domainObjectPrivilege.isactive = false;
          }
        });
      }
    }
    inputdomainObject.isactive = true;
    this.handleDomainObjectChecked(inputdomainObject, menu);
  }

  handleDomainObjectPrivilegeUnChecked(inputDomainObjectPrivilege, inputdomainObject, menu) {
    let inputPrivilge = inputDomainObjectPrivilege;
    if (inputPrivilge.name === '*') {
      inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
        domainObjectPrivilege.isactive = false;
      });
    } else if (inputPrivilge.name === 'Read') {
      let flag = false;
      inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
        let privilege = domainObjectPrivilege;
        if (privilege.name !== 'Read' && domainObjectPrivilege.isactive === true) {
          flag = true;
        }
      });
      // console.log('Read deselected flag:', flag);

      inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
        let privilege = domainObjectPrivilege;
        if (privilege.name === 'Read') {
          // console.log("inputDomainObjectPrivilege", inputDomainObjectPrivilege);
          inputDomainObjectPrivilege.isactive = flag;
        }
      });
    } else {
      inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
        let privilege = domainObjectPrivilege;
        if (privilege.name === inputPrivilge.name) {
          domainObjectPrivilege.isactive = false;
        }
      });
    }

    let flag = false;
    inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
      if (domainObjectPrivilege.isactive === true) {
        flag = true;
      }
    });
    if (flag === false) {
      this.handleDomainObjectUnChecked(inputdomainObject, menu);
    }
  }

  renderDomainObjectPrivileges(domainObjectPrivilege, menuId, domainObjectId, action) {
    return (
      <>
        {action === 'view' ? (
          <div style={{ width: '21em', marginLeft: '5em', paddingBottom: '0px' }}>
            {domainObjectPrivilege.isactive ? (
              <>
                <span className="bold" style={{ color: 'green' }}>
                  {' '}
                  {domainObjectPrivilege.name}&nbsp;&nbsp;
                </span>
                <i class="fa fa-check" aria-hidden="true"></i>
              </>
            ) : (
              <span style={{ color: 'black' }}> {domainObjectPrivilege.name}&nbsp;</span>
            )}
          </div>
        ) : (
          <li className="list-group-item list-group-item-action prev" style={{ width: '21em', marginLeft: '2em' }}>
            <input
              className="checkbox"
              type="checkbox"
              checked={domainObjectPrivilege.isactive}
              onChange={this.onDomainObjectPrivilegeClicked.bind(this, domainObjectPrivilege.id)}
            />
            &nbsp; {domainObjectPrivilege.name}
          </li>
        )}
      </>
    );
  }

  onDomainObjectClicked(id) {
    this.state.menus.map((menu) => {
      menu.domainObjects.map((domainObject) => {
        if (domainObject.id === id) {
          if (domainObject.isactive === false) {
            this.handleDomainObjectChecked(domainObject, menu, true);
          } else {
            this.handleDomainObjectUnChecked(domainObject, menu, true);
          }
          return;
        }
      });
      menu.childMenus?.map((submenu) => {
        submenu.domainObjects.map((domainObject) => {
          if (domainObject.id === id) {
            if (domainObject.isactive === false) {
              this.handleDomainObjectChecked(domainObject, submenu, true);
            } else {
              this.handleDomainObjectUnChecked(domainObject, submenu, true);
            }
          }
          return;
        });
      });
    });
    this.setState({ menus: this.state.menus });
  }

  handleDomainObjectChecked(inputdomainObject, inputMenu, checkPrivilges) {
    if (checkPrivilges && checkPrivilges === true) {
      inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
        // if (domainObjectPrivilege.name === '*') {
        //   domainObjectPrivilege.isactive = true;
        // } else {
        domainObjectPrivilege.isactive = true;
        // }
      });
    }

    inputdomainObject.isactive = true;
    this.state.menus.map((menu) => {
      menu.domainObjects.map((domainObject) => {
        if (inputdomainObject.name === domainObject.name) {
          menu.isactive = true;
          domainObject.isactive = true;
        }
      });

      menu.childMenus?.map((submenu) => {
        submenu.domainObjects.map((domainObject) => {
          if (inputdomainObject.name === domainObject.name) {
            submenu.isactive = true;
            menu.isactive = true;
          }
        });
      });
    });
  }

  handleDomainObjectUnChecked(inputdomainObject, menu, checkPrivilges) {
    if (checkPrivilges && checkPrivilges == true) {
      inputdomainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
        domainObjectPrivilege.isactive = false;
      });
    }
    inputdomainObject.isactive = false;
    this.state.menus.map((menu) => {
      let flag = false;
      let found = false;
      menu.domainObjects.map((domainObject) => {
        if (inputdomainObject.name === domainObject.name) {
          domainObject.isactive = false;
          found = true;
        }
        if (domainObject.isactive === true) {
          flag = true;
        }
      });
      if (found) {
        menu.isactive = flag;
      }

      menu.childMenus?.map((submenu) => {
        let subFlag = false;
        let subFound = false;
        submenu.domainObjects.map((subdomainObject) => {
          if (inputdomainObject.name === subdomainObject.name) {
            subdomainObject.isactive = false;
            submenu.isactive = false;
            subFound = true;
          }
          if (subdomainObject.isactive === true) {
            subFlag = true;
          }
        });

        if (subFound === true) {
          menu.childMenus.map((submenu) => {
            if (submenu.isactive === true) {
              subFlag = true;
            }
          });
          submenu.isactive = subFlag;
          menu.isactive = subFlag;
        }
      });
    });
  }

  renderDomainObject(domainObject, menu, action) {
    let activeMenus = this.state.activeMenus;
    let menuId = menu.id;
    return (
      <>
        {action === 'view' ? (
          <>
            <div style={{ width: '21em', paddingBottom: '0px', marginLeft: '2em' }}>
              {domainObject.isactive ? (
                <>
                  <span className="bold" style={{ fontSize: '15px', color: '#e67300' }}>
                    {' '}
                    {domainObject.name}&nbsp;&nbsp;
                  </span>
                  <i class="fa fa-check" aria-hidden="true"></i>
                </>
              ) : (
                <span className="bold" style={{ fontSize: '15px', color: 'black' }}>
                  {' '}
                  {domainObject.name}&nbsp;
                </span>
              )}
            </div>
            <div className="row">
              <>
                <div className="row d-flex">
                  <ul className="sub-menu list-group" stype={{ display: 'inline' }}>
                    {domainObject.domainObjectPrivileges.map((domainObjectPrivilege) =>
                      this.renderDomainObjectPrivileges(domainObjectPrivilege, menuId, domainObject.id, action)
                    )}
                  </ul>
                </div>
              </>
            </div>
          </>
        ) : (
          <li className="list-group-item list-group-item-action prev">
            <input
              className="checkbox"
              type="checkbox"
              checked={domainObject.isactive}
              onChange={this.onDomainObjectClicked.bind(this, domainObject.id, menu)}
            />
            &nbsp; &nbsp; {domainObject.name}&nbsp; &nbsp;
            <a className="btn-flat pull-right ps-2" style={{ display: 'contents' }}>
              <i
                className={
                  activeMenus.has(menuId) && activeMenus.get(menuId).has(domainObject.id)
                    ? 'fa fa-lg fa-minus'
                    : 'fa fa-lg fa-plus'
                }
                onClick={this.showDomainObject.bind(this, domainObject.id, menuId)}
              ></i>
              {activeMenus.has(menuId) && activeMenus.get(menuId).has(domainObject.id) ? (
                <ul className="sub-menu list-group" stype={{ marginLeft: '2em' }}>
                  {domainObject.domainObjectPrivileges.map((domainObjectPrivilege) =>
                    this.renderDomainObjectPrivileges(domainObjectPrivilege, menuId, domainObject.id, action)
                  )}
                </ul>
              ) : (
                ''
              )}
            </a>
          </li>
        )}
      </>
    );
  }

  onMenuClicked(id) {
    this.state.menus.map((menu) => {
      if (menu.id === id) {
        if (menu.isactive === false) {
          this.handleMenuChecked(menu);
        } else {
          this.handleMenuUnChecked(menu);
        }
      }
      if (menu.childMenus && menu.childMenus.length > 0) {
        menu.childMenus.map((submenu) => {
          if (submenu.id === id) {
            if (submenu.isactive === false) {
              this.handleMenuChecked(submenu, menu);
            } else {
              this.handleMenuUnChecked(submenu, menu);
            }
          }
        });
      }
    });

    this.setState({ menus: this.state.menus });
  }

  handleMenuChecked(inputmenu) {
    this.state.menus.map((menu) => {
      if (menu.menuid === inputmenu.menuid) {
        menu.isactive = true;
        menu.childMenus?.map((submenu) => {
          submenu.isactive = true;
          submenu.domainObjects.map((domainObject) => {
            domainObject.isactive = true;
            domainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
              domainObjectPrivilege.isactive = true;
            });
          });
        });
        menu.domainObjects.map((domainObject) => {
          domainObject.isactive = true;
          domainObject.domainObjectPrivileges?.map((domainObjectPrivilege) => {
            // if (domainObjectPrivilege.name === '*') {
            //   domainObjectPrivilege.isactive = true;
            // } else {
            domainObjectPrivilege.isactive = true;
            // }
          });
        });
      } else {
        menu.childMenus?.map((submenu) => {
          if (submenu.menuid === inputmenu.menuid) {
            submenu.isactive = true;
            submenu.domainObjects.map((domainObject) => {
              domainObject.isactive = true;
              domainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
                // if (domainObjectPrivilege.name === '*') {
                //   domainObjectPrivilege.isactive = true;
                // } else {
                domainObjectPrivilege.isactive = true;
                // }
              });
            });
            menu.isactive = true;
          }
        });
      }
    });
  }

  handleMenuUnChecked(inputmenu, parentmenu) {
    this.state.menus.map((menu) => {
      if (menu.menuid === inputmenu.menuid) {
        menu.isactive = false;
        menu.childMenus?.map((submenu) => {
          submenu.isactive = false;
          submenu.domainObjects.map((domainObject) => {
            domainObject.isactive = false;
            domainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
              domainObjectPrivilege.isactive = false;
            });
          });
        });
        menu.domainObjects.map((domainObject) => {
          domainObject.isactive = false;
          domainObject.domainObjectPrivileges.map((domainObjectPrivilege) => {
            domainObjectPrivilege.isactive = false;
          });
        });
      } else {
        menu.childMenus?.map((submenu) => {
          if (submenu.menuid === inputmenu.menuid) {
            submenu.isactive = false;
            submenu.domainObjects.map((domainObject) => {
              domainObject.isactive = false;
              domainObject.domainObjectPrivileges?.map((domainObjectPrivilege) => {
                domainObjectPrivilege.isactive = false;
              });
            });

            let flag = false;
            menu.childMenus.map((submenu) => {
              if (submenu.isactive === true) {
                flag = true;
              }
            });
            menu.isactive = flag;
          }
        });
      }
    });
  }

  renderMenus(menu, action) {
    if (menu.childMenus && menu.childMenus.length > 0) {
      return (
        <>
          {action === 'view' ? (
            <div className="row d-flex">
              <li className="list-group-item list-group-item-action prev border-0" style={{ marginLeft: '1em' }}>
                <div style={{ width: '25em', paddingBottom: '0px' }}>
                  {menu.isactive ? (
                    <>
                      <span className="bold" style={{ fontSize: '16px', color: '#0099f1' }}>
                        {' '}
                        {menu.uiname}&nbsp;&nbsp;
                      </span>
                      <i class="fa fa-check" aria-hidden="true" style={{ color: '#0099f1' }}></i>
                    </>
                  ) : (
                    <span className="bold" style={{ fontSize: '15px', color: 'black' }}>
                      {' '}
                      {menu.uiname}&nbsp;
                    </span>
                  )}
                </div>
                <div className="row d-flex">
                  <ul className="list-group" style={{ display: 'content', listStyleType: 'none' }}>
                    {menu.childMenus.map((submenu) => this.renderMenus(submenu, 'view'))}
                  </ul>
                </div>
              </li>
            </div>
          ) : (
            <li className="list-group-item list-group-item-action prev" style={{ marginLeft: '1em' }}>
              <input
                className="checkbox"
                type="checkbox"
                checked={menu.isactive}
                onChange={this.onMenuClicked.bind(this, menu.id)}
              />
              &nbsp; &nbsp;{menu.uiname}&nbsp; &nbsp;
              <a style={{ display: 'contents' }}>
                <i
                  className={this.state.activeMenus.has(menu.id) ? 'fa fa-lg fa-minus' : 'fa fa-lg fa-plus'}
                  onClick={this.showrolesubMenu.bind(this, menu.id)}
                ></i>
                {this.state.activeMenus.has(menu.id) ? (
                  <ul className="list-group" style={{ display: 'content' }}>
                    {menu.childMenus.map((submenu) => this.renderMenus(submenu, action))}
                  </ul>
                ) : (
                  ''
                )}
              </a>
            </li>
          )}
        </>
      );
    } else {
      return (
        <>
          {action === 'view' ? (
            <div className="row d-flex">
              <li className="list-group-item list-group-item-action prev border-0" style={{ marginLeft: '1em' }}>
                <div style={{ width: '25em', paddingBottom: '0px' }}>
                  {menu.isactive ? (
                    <>
                      <span className="bold" style={{ fontSize: '16px', color: '#0099f1' }}>
                        {' '}
                        {menu.uiname}&nbsp;&nbsp;
                      </span>
                      <i class="fa fa-check" aria-hidden="true" style={{ color: '#0099f1' }}></i>
                    </>
                  ) : (
                    <span className="bold" style={{ fontSize: '15px', color: 'black' }}>
                      {' '}
                      {menu.uiname}&nbsp;
                    </span>
                  )}
                </div>
                <div className="row d-flex">
                  <ul
                    className="sub-menu list-group"
                    style={{ display: 'flex', marginLeft: '2em', width: 'fit-content' }}
                  >
                    {menu.domainObjects.map((domainObject) => this.renderDomainObject(domainObject, menu, action))}
                  </ul>
                </div>
              </li>
            </div>
          ) : (
            <li className="list-group-item list-group-item-action prev" style={{ marginLeft: '1em' }}>
              <div>
                <input
                  className="checkbox"
                  type="checkbox"
                  checked={menu.isactive}
                  onChange={this.onMenuClicked.bind(this, menu.id)}
                />
                &nbsp; &nbsp;{menu.uiname}&nbsp; &nbsp;
                {menu.domainObjects.length > 0 ? (
                  <a style={{ display: 'contents' }}>
                    <i
                      className={this.state.activeMenus.has(menu.id) ? 'fa fa-lg fa-minus' : 'fa fa-lg fa-plus'}
                      onClick={this.showrolesubMenu.bind(this, menu.id)}
                    ></i>
                    {this.state.activeMenus.has(menu.id) ? (
                      <ul
                        className="sub-menu list-group"
                        style={{ display: 'flex', marginLeft: '2em', width: 'fit-content' }}
                      >
                        {menu.domainObjects.map((domainObject) => this.renderDomainObject(domainObject, menu, action))}
                      </ul>
                    ) : (
                      ''
                    )}
                  </a>
                ) : (
                  ''
                )}
              </div>
            </li>
          )}
        </>
      );
    }
  }

  render() {
    if (this.state.action === 'create') {
      return this.createProfile();
    } else if (this.state.action === 'view') {
      return this.viewProfile();
    } else if (this.state.action === 'edit') {
      return this.editProfile();
    }
  }

  createProfile() {
    return (
      <>
        <AdminMenuWithBreadScrum
          breadCrumb={this.adminBreadCrumb}
          history={this.props.history}
          name={'Create Admin Profile'}
          domainObject="Admin Profile"
        />
        <Row style={{ position: 'relative', margin: '1em' }}>
          <Col md={12} style={{ position: 'relative', width: '550px' }}>
            <Card className="formCard">
              <CardHeader className="cardHeader">
                <Row>
                  <Col md={9}>
                    {/* {undefined !== this.state.title &&
											this.state.title.length > 0 && ( */}
                    <Label className="cardHeaderTitle">Admin Profile Details</Label>
                    {/* )} */}
                  </Col>
                </Row>
              </CardHeader>{' '}
              <CardBody>
                {this.state.addProfile ? (
                  <Row>
                    <Col md={4} style={{ textAlign: 'center' }}>
                      <TextField
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                        id="adminProfileData"
                        type="text"
                        onChange={this.handleAdminProfileChange.bind(this)}
                        value={this.state.adminProfile}
                        label="Admin Profile"
                        name="lname"
                        inputProps={{
                          maxLength: 128,
                          style: { textTransform: 'capitalize' },
                        }}
                      />
                    </Col>
                    <Col md={4}>
                      <div style={{ paddingTop: '25px' }}>
                        <CreateButton
                          id="loginButton"
                          type="submit"
                          onClick={this.createAdminProfileToBackend.bind(this)}
                        >
                          Create
                        </CreateButton>
                        &nbsp;
                        <CancelIconButton onClick={(e) => this.createCancel()} />
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <Row>
                    <Col md={4} style={{ textAlign: 'center' }}>
                      <TextField
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                        id="adminProfileData"
                        type="text"
                        onChange={this.handleAdminProfileChange.bind(this)}
                        value={this.state.adminProfile}
                        label="Admin Profile"
                        name="lname"
                        inputProps={{
                          maxLength: 128,
                          style: { textTransform: 'capitalize' },
                        }}
                      />
                    </Col>
                  </Row>
                )}
                <div className="mt-4">
                  <ul className="list-group text-start">
                    {this.state.menus &&
                      this.state.menus
                        .sort((a, b) => a.mainMenuId - b.mainMenuId)
                        .map((menu) => this.renderMenus(menu))}
                  </ul>
                </div>
                {this.state.updateProfile && (
                  <div style={{ textAlign: 'center', padding: '1em 0em 1em' }}>
                    <SaveButton id="loginButton" type="submit" onClick={this.updateAdminProfileToBackend.bind(this)}>
                      Save
                    </SaveButton>
                    &nbsp;
                    <CancelIconButton onClick={(e) => this.editCancel()} />
                  </div>
                )}
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }

  viewProfile() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <AdminMenuWithBreadScrum
            breadCrumb={this.adminBreadCrumb}
            history={this.props.history}
            backCallback={this.viewCancel.bind(this)}
            name="Admin Profile"
            deleteCallback={this.deleteAdminProfileToBackend.bind(this)}
            editCallback={this.editAdminProfile.bind(this)}
            getDeletePermission={this.state.getDeletePermission}
            getUpdatePermission={this.state.getUpdatePermission}
          />
          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={12} className="sponsorDetailColumn" style={{ position: 'relative', padding: '5px' }}>
              <Card style={{ height: '100%' }}>
                <CardHeader className="cardHeader">
                  <Row>
                    <Col md={10}>
                      <Label className="cardHeaderTitle">
                        {/* Advertisement Details */}
                        {this.state.adminProfile}
                      </Label>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <div className="mt-0">
                    <ul
                      className="list-group text-start"
                      style={{ display: 'inline-flex', flexWrap: 'wrap', flexDirection: 'row', listStyleType: 'none' }}
                    >
                      {this.state.menus &&
                        this.state.menus
                          .sort((a, b) => a.mainMenuId - b.mainMenuId)
                          .map((menu) => this.renderMenus(menu, 'view'))}
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div className="sponsorDetailFooter">
            <BackButton
              type="button"
              style={{ backgroundColor: '#162c50', color: '#fff' }}
              variant="contained"
              onClick={(e) => this.viewCancel()}
            >
              Back
            </BackButton>
          </div>
        </>
      );
    }
  }

  editProfile() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <AdminMenuWithBreadScrum
            breadCrumb={this.adminBreadCrumb}
            history={this.props.history}
            name={'Edit Admin Profile'}
            domainObject="Admin Profile"
          />
          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={12} style={{ position: 'relative', width: '550px' }}>
              <Card className="formCard">
                <CardHeader className="cardHeader">
                  <Row>
                    <Col md={9}>
                      <Label className="cardHeaderTitle">Admin Profile Details</Label>
                    </Col>
                  </Row>
                </CardHeader>{' '}
                <CardBody>
                  <Row>
                    <Col md={4} style={{ textAlign: 'center' }}>
                      <TextField
                        margin="normal"
                        variant="outlined"
                        required
                        fullWidth
                        id="adminProfile"
                        type="text"
                        onChange={this.handleAdminProfileChange.bind(this)}
                        value={this.state.adminProfile}
                        label="Admin Profile"
                        name="lname"
                        inputProps={{
                          maxLength: 128,
                          style: { textTransform: 'capitalize' },
                        }}
                      />
                    </Col>
                  </Row>{' '}
                  <div className="mt-4">
                    <ul className="list-group text-start">
                      {this.state.menus &&
                        this.state.menus
                          .sort((a, b) => a.mainMenuId - b.mainMenuId)
                          .map((menu) => this.renderMenus(menu))}
                    </ul>
                  </div>
                  <div style={{ textAlign: 'center', padding: '1em 0em 1em' }}>
                    <SaveButton id="loginButton" type="submit" onClick={this.updateAdminProfileToBackend.bind(this)}>
                      Save
                    </SaveButton>
                    &nbsp;
                    <CancelIconButton onClick={(e) => this.editCancel()} />
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </>
      );
    }
  }
}
export default AdminProfileManagement;
