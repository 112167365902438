import { CLEAR_USER_DATA, GET_USER_DATA, SET_USER_DATA } from './appUserDataTypes';

const initialState = {
  userData: {},
  userDetails: {},
  premiumFeatures: {},
};

const userDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USER_DATA:
      return {
        ...state,
      };
    case SET_USER_DATA:
      return {
        ...state,
        userData: action.payload.appuserVo,
        userDetails: action.payload,
        premiumFeatures: action.payload.appuserVo.premiumFeatureTrialinfo,
      };

    case CLEAR_USER_DATA:
      return {
        ...state,
        userData: null,
        userDetails: null,
        PremiumFeatures: null,
      };
    default:
      return state;
  }
};

export default userDataReducer;
