import { CLEAR_MEMBER_LIST, GET_MEMBER_LIST, SET_MEMBER_LIST } from './memberListTypes';
const initialState = {
  memberList: {},
};

const memberListReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MEMBER_LIST:
      return {
        ...state,
      };
    case SET_MEMBER_LIST:
      return {
        ...state,
        memberList: action.payload,
      };

    case CLEAR_MEMBER_LIST:
      return {
        ...state,
        memberList: null,
      };
    default:
      return state;
  }
};

export default memberListReducer;
