import React, { Component } from 'react';
import './CommercialsTemplate2.scss';
class CommercialsTemplate2 extends Component {
  constructor(props) {
    // let pageId = props.pageId;
    let action = props.action;
    let advertisementDetails = props.data;
    console.log('this.props.data.leftimage', props.data);
    super(props);
    this.state = {
      action: action,

      heading: advertisementDetails ? advertisementDetails.template.heading : '',
      subHeading: advertisementDetails ? advertisementDetails.template.subheading : '',
      message: advertisementDetails ? advertisementDetails.template.message : '',
      website: advertisementDetails ? advertisementDetails.template.website : '',
      action: this.props.action,
      onChange: this.props.onChange,
      imagePosition: '',
      leftImageFile: this.props.data ? this.props.data.leftimage : '',
      rightImageFile: this.props.data ? this.props.data.rightimage : '',
      showRemoveButton: false,
      backgroundImageFile: this.props.data ? this.props.data.template.backgroundimage : '',

      render: true,
    };
  }
  render() {
    return (
      <>
        {this.state.backgroundImageFile !== '' ? (
          <>
            {this.state.website !== '' ? (
              <div
                className="row"
                style={{
                  margin: 'auto',
                  alignItems: 'center',
                  display: 'flex',
                  alignContent: 'center',
                  textAlign: 'center',
                }}
              >
                <a
                  href={
                    this.state.website.includes('https://') || this.state.website.includes('http://')
                      ? this.state.website
                      : 'http://' + this.state.website
                  }
                  target="_blank"
                  style={{ display: 'flex', textAlign: 'center', margin: 'auto' }}
                >
                  <img
                    src={`data:image/png;base64,${this.state.backgroundImageFile}`}
                    width="100%"
                    style={{
                      maxHeight: '100px',

                      display: 'block',
                    }}
                  />
                </a>
              </div>
            ) : (
              <div
                className="row"
                style={{
                  margin: 'auto',
                  alignItems: 'center',
                  display: 'flex',
                  alignContent: 'center',
                  textAlign: 'center',
                }}
              >
                <img
                  src={`data:image/png;base64,${this.state.backgroundImageFile}`}
                  width="100%"
                  style={{
                    maxHeight: '100px',

                    display: 'block',
                  }}
                />
              </div>
            )}
          </>
        ) : (
          <>
            {this.state.website !== '' ? (
              <div
                className="row"
                style={{
                  margin: 'auto',
                  alignItems: 'center',
                  display: 'flex',
                  alignContent: 'center',
                  textAlign: 'center',
                }}
              >
                <a
                  href={
                    this.state.website.includes('https://') || this.state.website.includes('http://')
                      ? this.state.website
                      : 'http://' + this.state.website
                  }
                  target="_blank"
                  style={{ display: 'flex', textAlign: 'center', margin: 'auto' }}
                ></a>
              </div>
            ) : (
              <div
                className="row"
                style={{
                  margin: 'auto',
                  alignItems: 'center',
                  display: 'flex',
                  alignContent: 'center',
                  textAlign: 'center',
                }}
              >
                <a
                  href={this.state.website}
                  target="_blank"
                  style={{ display: 'flex', textAlign: 'center', margin: 'auto' }}
                ></a>
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

export default CommercialsTemplate2;
