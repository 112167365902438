import { CLEAR_MEMBER_DATA, GET_MEMBER_DATA, SET_MEMBER_DATA } from './memberDataTypes';

const initialState = {
  memberData: {},
};

const memberDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MEMBER_DATA:
      return {
        ...state,
      };
    case SET_MEMBER_DATA:
      return {
        ...state,
        memberData: action.payload,
      };

    case CLEAR_MEMBER_DATA:
      return {
        ...state,
        memberData: null,
      };
    default:
      return state;
  }
};

export default memberDataReducer;
