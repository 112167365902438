import { CLEAR_FCC_PAGE_STATE, GET_FCC_PAGE_STATE, SET_FCC_PAGE_STATE } from './fccPageStateType';

const initialState = {
  fccPageState: {},
};

const fccPageStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_FCC_PAGE_STATE:
      return {
        ...state,
      };
    case SET_FCC_PAGE_STATE:
      return {
        ...state,
        fccPageState: action.payload,
      };

    case CLEAR_FCC_PAGE_STATE:
      return {
        ...state,
        fccPageState: null,
      };
    default:
      return state;
  }
};

export default fccPageStateReducer;
