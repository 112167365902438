import { GET_ADMIN_STATIC_DATA, SET_ADMIN_STATIC_DATA } from './adminStaticDataTypes';

const getAdminStaticData = () => {
  return {
    type: GET_ADMIN_STATIC_DATA,
  };
};
const setAdminStaticData = (data) => {
  return {
    type: SET_ADMIN_STATIC_DATA,
    payload: data,
  };
};
const adminStaticDataAction = { getAdminStaticData, setAdminStaticData };
export default adminStaticDataAction;
