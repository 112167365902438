import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import store from '../../../redux/store';
import CommercialsTemplate from '../commercialsTemplate/CommercialsTemplate';
import FccAdvertisement from '../fccAdvertisement/FccAdvertisement';
import SponsorShipTemplate from '../sponsorShipTemplate/SponsorShipTemplate';
import { connect } from 'react-redux';
class Advertisement extends Component {
  isFreeUser = store.getState().userData.userData.isFreeUser;
  sponsorUser = store.getState().Sponsorship.Sponsorship;
  commercials = store.getState().commercialsDetails.commercials;
  constructor(props) {
    let str = props.pageId;
    let urlArr = str.split('/');
    let pageId = urlArr[1];
    super(props);
    this.state = {
      commercialsDetails: this.commercials,
      pageId: pageId,
      render: true,
      sponsorUser: this.sponsorUser,
      isFreeUser: this.isFreeUser,
    };
  }
  render() {
    if (this.isFreeUser === true) {
      if (this.state.sponsorUser !== null) {
        return <SponsorShipTemplate pageId={this.props.pageId} />;
      } else if (this.state.commercialsDetails !== null) {
        return <CommercialsTemplate pageId={this.props.pageId} />;
      } else {
        return <FccAdvertisement advertisementType="Horizontal" />;
      }
    } else {
      if (this.state.sponsorUser !== null) {
        return <SponsorShipTemplate pageId={this.props.pageId} />;
      } else {
        return <></>;
      }
    }
  }
}
const mapStateToProps = (state) => {
  return { isFreeUser: state.userData.userData.isFreeUser };
};
export default connect(mapStateToProps)(Advertisement);
