import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import React, { Component } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ValidatorForm } from 'react-material-ui-form-validator';
import 'react-tabs/style/react-tabs.css';
import { Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import store from '../../../redux/store';
import CancelIconButton from '../buttons/CancelIconButton';
import CreateButton from '../buttons/CreateButton';
import MenuWithBreadScrum from '../menu/MenuWithBreadScrum';
import PtsAlert from '../ptsAlert/PtsAlert';
import Checkbox from '@material-ui/core/Checkbox';
import fccPageStateAction from '../../../redux/fccPageState/fccPageStateAction';
import { MuiPickersUtilsProvider, DateTimePicker, LocalizationProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/styles';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { createMuiTheme } from '@material-ui/core';
class CreateSimilarPurchase extends Component {
  purchaseFrequencyList = store.getState().staticData.purchaseFrequencyList;
  purchaseTypeList = store.getState().staticData.purchaseTypeList;
  header = store.getState().header.header;
  memberDetails = store.getState().memberData.memberData;
  memberName = store.getState().memberData.memberData.firstname + ' ' + store.getState().memberData.memberData.lastname;
  title = 'Purchase Details';
  breadCrumb = [
    { pageid: '/memberDetails', uiname: this.memberName },
    { pageid: '/memberPurchase', uiname: 'Purchase List' },
    { pageid: '', uiname: 'Purchase Details' },
  ];
  purchaseReminderRefList = [];
  purchaseNoteRefList = [];

  constructor(props) {
    super(props);
    let purchaseId = props.purchaseId;
    let action = '';
    let title = '';
    let purchaseDetails = {};
    let pageId = '';
    let isButtonHide = false;
    let isEditButtonHide = true;

    if (!purchaseId && props.history && props.history.location && props.history.location.state) {
      purchaseId = props.history.location.state.purchaseId;
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
      title = props.history.location.state.title;
      purchaseDetails = props.history.location.state.purchaseData;
    }
    let date = purchaseDetails.date;
    let viewStartDate = Utils.convertUTCDateToUserTimeZoneDate(date);
    let selectedDoctor = {};
    if (purchaseDetails.doctor !== null) {
      selectedDoctor = {
        doctorName:
          purchaseDetails.doctor.salutation +
          ' ' +
          purchaseDetails.doctor.firstName +
          ' ' +
          purchaseDetails.doctor.lastName,
        id: purchaseDetails.doctor.id,
      };
    } else {
      selectedDoctor = {};
    }
    let showPrescribedBy = false;
    if (purchaseDetails.isPrescribedBy === true) {
      showPrescribedBy = true;
    } else {
      showPrescribedBy = false;
    }
    // if (purchaseDetails.reminderList.length > 0) {
    let newStartDate = Utils.convertUTCDateToUserTimeZoneDate(purchaseDetails.date);
    this.state = {
      render: false,
      id: purchaseDetails.id,
      viewPurchaseCard: true,
      editPurchaseCard: false,
      startDate: new Date(newStartDate),
      purchaseStartDate: viewStartDate,
      purchaseType: purchaseDetails.type,
      selectedDoctor: selectedDoctor,
      doctor: selectedDoctor.name,
      shortDescription: purchaseDetails.shortDescription,
      longDescription: purchaseDetails.description,
      // reminderItems: purchaseDetails.reminderList,
      // purchaseNoteList: purchaseDetails.noteList,
      noteListRow: true,
      title: title,
      showPrescribedBy: showPrescribedBy,
      isButtonHide: isButtonHide,
      pageId: pageId,
      doctorList: [],
      isPrescribedBy: purchaseDetails.isPrescribedBy,
      isEditButtonHide: isEditButtonHide,
      action: action,
    };
    // }
    this.getDoctorList();
  }

  getDoctorList() {
    const postObject = {
      header: this.header,
      doctor: {
        member: {
          id: this.memberDetails.member,
        },
      },
    };
    FetchServerData.callPostService('doctorms/getMemberDoctors', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let doctorListData = output.data.list;
        this.setState({
          doctorList: doctorListData,
          render: true,
        });
      } else {
      }
    });
  }

  handlePurchaseStartDateChange(date) {
    this.setState({ startDate: date });
  }

  handlePurchaseTypeChange(e) {
    this.setState({ purchaseType: e.target.value });
  }

  handleDoctorChange(selectedDoctor) {
    this.state.selectedDoctor = selectedDoctor;
    this.setState({
      selectedDoctor: selectedDoctor,
    });
    if (undefined !== this.state.onChange && null !== this.state.onChange) {
      this.state.onChange(this.state);
    }
  }

  handleLongDescriptionChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 256);
    if (res.length < 256) {
      this.setState({ longDescription: res });
    } else {
      Utils.maxFieldLength(256);
    }
  }

  handlePrescribedByChange(e) {
    if (e.target.checked === true) {
      this.setState({ isPrescribedBy: e.target.checked, showPrescribedBy: true });
    } else {
      this.setState({ isPrescribedBy: e.target.checked, showPrescribedBy: false });
    }
  }

  addPurchaseInBackend(e) {
    e.preventDefault();

    let dateTime = Utils.convertUserTimeZoneDateToUTCDate(this.state.startDate);
    const postObject = {
      header: this.header,
      purchase: {
        date: dateTime,
        shortDescription: this.state.shortDescription,
        description: this.state.longDescription,
        type: this.state.purchaseType,
        isPrescribedBy: this.state.isPrescribedBy,
        member: {
          id: this.memberDetails.member,
        },
        doctor: {
          id: this.state.selectedDoctor.id,
        },
      },
    };
    FetchServerData.callPostService('/purchasems/create', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let purchaseData = output.data;
          PtsAlert.success(output.message);
          let fccPageState = {
            id: purchaseData.purchase.id,
            action: 'view',
          };
          store.dispatch(fccPageStateAction.setFccPageState(fccPageState));

          this.props.history.push({
            pathname: '/viewPurchase',
            state: {
              action: 'view',
              title: 'Purchase Details',
              // purchaseId: purchaseData.id,
              purchaseDetails: purchaseData,
              pageId: 'createPurchase',
            },
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  createCancel() {
    this.props.history.push({
      pathname: '/memberPurchase',
    });
  }

  render() {
    return (
      <div>
        <MenuWithBreadScrum
          breadCrumb={this.breadCrumb}
          history={this.props.history}
          name={'Create Similar Purchase For' + ' ' + this.memberName}
          domainObject=""
        />
        <ValidatorForm style={{ width: '100%' }} ref="form" onSubmit={this.addPurchaseInBackend.bind(this)}>
          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={12} style={{ position: 'relative', padding: '5px' }}>
              <Card className="doctorViewCard" style={{ height: '100%' }}>
                <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                  <Label style={{ fontWeight: '600', color: '#162c50', marginLeft: '0.5em' }}>
                    Create Similar Purchase
                  </Label>
                </CardHeader>
                <CardBody>
                  <Row style={{ marginTop: '1em' }}>
                    <Col md={6}>
                      <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <ThemeProvider theme={materialTheme}>
                          <DateTimePicker
                            value={this.state.startDate}
                            variant="outlined"
                            fullWidth
                            className="ptsDateTimePicker"
                            inputVariant="outlined"
                            timeInputLabel="Time:"
                            format="MM/dd/yyyy  h:mm aa"
                            style={{ background: '#fff' }}
                            onChange={this.handlePurchaseStartDateChange.bind(this)}
                          />
                        </ThemeProvider>
                      </MuiPickersUtilsProvider>
                    </Col>
                    <Col md={6}>
                      <input
                        style={{
                          height: '56px',
                          width: '100%',
                          padding: '1em',
                          borderRadius: '3px',
                          border: '1px solid #c4c4c4',
                        }}
                        type="text"
                        required
                        id="Purchase-outlined"
                        list="Purchase Type"
                        placeholder="Purchase Type*"
                        label="Purchase Type*"
                        value={this.state.purchaseType}
                        onChange={(event, value) => {
                          this.handlePurchaseTypeChange(event, value);
                        }}
                      />
                      <datalist id="Purchase Type">
                        {this.purchaseTypeList.map((event, key) => (
                          <option key={event.id} value={event.type} />
                        ))}
                      </datalist>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <Row>
                        <Col md={5} style={{ padding: '2em' }}>
                          <span style={{ color: '#000' }}>
                            <strong>Is Prescribed by Doctor ?</strong>
                            &nbsp;{' '}
                            <Checkbox
                              checked={this.state.isPrescribedBy}
                              onChange={(e) => this.handlePrescribedByChange(e)}
                              color="primary"
                              inputprops={{ 'aria-label': 'secondary checkbox' }}
                            />
                          </span>
                        </Col>
                        {this.state.showPrescribedBy && (
                          <Col md={7}>
                            <Autocomplete
                              id="Prescribed By"
                              options={this.state.doctorList}
                              value={this.state.selectedDoctor}
                              getOptionLabel={(option) => option.doctorName}
                              onChange={(event, value) => {
                                this.handleDoctorChange(value);
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="outlined"
                                  label="Prescribed By"
                                  margin="normal"
                                  required
                                />
                              )}
                            />
                          </Col>
                        )}
                      </Row>
                    </Col>
                  </Row>

                  <Row style={{ marginTop: '1em' }}>
                    <Col md={12}>
                      <TextareaAutosize
                        aria-label="minimum height"
                        fullWidth
                        style={{ width: '100%' }}
                        variant="outlined"
                        value={this.state.longDescription}
                        onChange={this.handleLongDescriptionChange.bind(this)}
                        minRows={4}
                        placeholder="Description"
                        required
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <div style={{ textAlign: 'center' }}>
            <CreateButton>Create</CreateButton>
            &nbsp;
            <CancelIconButton onClick={(e) => this.createCancel()} />
          </div>
        </ValidatorForm>
      </div>
    );
  }
}

export default CreateSimilarPurchase;
const materialTheme = createMuiTheme({
  overrides: {
    MuiPickersToolbar: {
      toolbar: {
        backgroundColor: '#008181',
      },
    },
    MuiPickersCalendarHeader: {
      switchHeader: {
        backgroundColor: 'white',
        color: '#008181',
      },
    },
  },
});
