import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import AddIcon from '@material-ui/icons/Add';
import MaterialTable from 'material-table';
import React, { Component } from 'react';
import { Card, CardBody, CardHeader, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import UiUtils from '../../../provider/UiUtils';
import store from '../../../redux/store';
import CancelIconButton from '../buttons/CancelIconButton';
import PtsAlert from '../ptsAlert/PtsAlert';

class BillingDetailsHome extends Component {
  title = 'Billing Details';
  breadCrumb = [{ pageid: '/billing_details', uiname: 'Billing Details' }];
  header = store.getState().header.header;
  userId = store.getState().userData.userData.memberId;

  addressRef = React.createRef();
  addressRef = {};
  constructor(props) {
    super(props);
    let action = props.action;
    let memberId = props.memberId;
    let title = '';
    let pageId = '';
    if (
      null == memberId &&
      null !== props.history &&
      props.history !== undefined &&
      props.history.location !== null &&
      props.history.location.state !== null &&
      props.history.location.state !== undefined
    ) {
      memberId = props.history.location.state.memberId;
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
      title = props.history.location.state.title;
    }

    this.state = {
      pageId: pageId,
      action: action,
      title: title,
      render: false,
      id: memberId,
      fname: '',
      mname: '',
      lname: '',
      salutation: {},
      description: '',
      dob: '',
      phone: '',
      email: '',
      gender: '',
      designation: '',
      address: {},
      line: '',
      city: '',
      state: '',
      country: '',
      zipCode: '',
      salutationList: [
        { id: '1', salutation: 'Mr' },
        { id: '2', salutation: 'Miss' },
        { id: '3', salutation: 'Mrs' },
      ],
      orderList: [],
      checked: true,
      orderItemsModal: false,
      orderItemList: [],
      memberListModal: false,
      memberList: [],
      pageSize: 5,
      isPaging: false,
      selectedUser: [],
      subscriptionId: '',
      selectedUserList: [],
      numberOfUser: '',
      isDisplayPlanSubscription: false,
      isQuestionFreePlanSubscription: false,
      frereSubscription: {},
      frereSubscriptionName: '',
      frereSubscriptionPrice: '',
    };
    this.getProfileData();
  }

  getProfileData() {
    const postObject = {
      header: this.header,
    };

    FetchServerData.callPostService('/memberms/getProfile', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let profileData = output.data.member;
        let profileAddress = profileData.address;
        let orderListData = output.data.orderList;
        let frereSubscription = output.data.freeSubscription;
        let salutation = this.state.salutationList[1];
        let orderItem = [];
        let selectedUserList = [];
        let subscriptionId = '';
        let numberOfUser = '';
        if (orderListData.length > 0) {
          this.setState({
            isDisplayPlanSubscription: true,
            isQuestionFreePlanSubscription: false,
          });
        } else {
          this.setState({
            isDisplayPlanSubscription: false,
            isQuestionFreePlanSubscription: true,
          });
        }
        for (let i = 0; i < orderListData.length; i++) {
          orderItem = orderListData[i].orderItems;
          subscriptionId = orderListData[i].payment.subscription.id;
          selectedUserList = orderListData[i].payment.subscription.childSubscriptionList;
          numberOfUser = orderListData[i].payment.subscription.plan.numberOfUsers;
        }

        if (profileData.salutation === 'Mr') {
          salutation = this.state.salutationList[0];
        } else if (profileData.salutation === 'Miss') {
          salutation = this.state.salutationList[1];
        } else {
          salutation = this.state.salutationList[2];
        }
        if (profileData != null) {
          if (
            (profileAddress !== '' && profileAddress !== null) ||
            (frereSubscription !== null && frereSubscription !== '')
          ) {
            this.setState({
              render: true,
              salutation: salutation,
              id: profileData.id,
              fname: profileData.firstName,
              mname: profileData.middleName,
              lname: profileData.lastName,
              dob: profileData.dob,
              email: profileData.email,
              phone: profileData.phone,
              gender: profileData.gender,
              address: profileAddress,
              orderList: orderListData,
              orderItemList: orderItem,
              subscriptionId: subscriptionId,
              selectedUserList: selectedUserList,
              numberOfUser: numberOfUser,
              frereSubscription: frereSubscription,
            });
          } else {
            this.setState({
              render: true,
              id: profileData.id,
              salutation: salutation,
              fname: profileData.firstName,
              mname: profileData.middleName,
              lname: profileData.lastName,
              dob: profileData.dob,
              email: profileData.email,
              phone: profileData.phone,
              gender: profileData.gender,
              orderList: orderListData,
              address: null,
              frereSubscription: {},
              orderItemList: orderItem,
              subscriptionId: subscriptionId,
              selectedUserList: selectedUserList,
              numberOfUser: numberOfUser,
            });
          }
        } else {
          this.setState({
            render: false,
          });
        }
      }
    });
  }

  handleAddressChange(address) {
    this.setState({ address: address });
  }

  handleSubscriptionChargeChange(e) {
    if (this.state.checked === false) {
      const postObject = {
        email: this.state.email,
      };
      FetchServerData.callPostService('pg/makeSubscriptionManualCharge', postObject)
        .then((output) => {
          if (output.status === 'SUCCESS') {
            this.setState({ checked: !this.state.checked });
          } else {
          }
        })
        .catch((error) => {});
    } else {
      const postObject = {
        email: this.state.email,
      };
      FetchServerData.callPostService('pg/makeSubscriptionAutocharge', postObject)
        .then((output) => {
          if (output.status === 'SUCCESS') {
            this.setState({ checked: !this.state.checked });
          } else {
          }
        })
        .catch((error) => {});
    }
  }

  cancelSubscription(e) {
    const postObject = {
      email: this.state.email,
    };
    FetchServerData.callPostService('pg/cancelOnCurrentSubscriptionEnd', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          this.getProfileData();
        } else {
        }
      })
      .catch((error) => {});
  }

  showOrderItems() {
    this.setState({
      orderItemsModal: true,
    });
  }

  showOrderItemList() {
    return this.state.orderItemList.map((data, index) => {
      if (this.state.render === false) {
        return <div />;
      } else {
        return (
          <>
            <tr style={{ textAlign: 'left', background: '#fff' }}>
              <td>{index + 1}</td>
              <td>{data.plan.description}</td>
              <td>{data.plan.price}</td>
            </tr>
          </>
        );
      }
    });
  }

  orderItemsModalToggle = () => {
    this.setState({
      orderItemsModal: !this.state.orderItemsModal,
    });
  };

  memberListModalToggle = () => {
    this.setState({
      memberListModal: !this.state.memberListModal,
    });
  };

  selectedParticipant(e, rowData, type) {
    const { checked } = e.target;
    let selectedMemberList = this.state.selectedUser;

    let memberObj = {};
    if (checked === true) {
      memberObj = {
        user: {
          id: rowData.id,
          name: rowData.name,
        },
      };
      selectedMemberList.push(memberObj);

      if (selectedMemberList.length > this.state.numberOfUser) {
        PtsAlert.error('Please Select ' + this.state.numberOfUser + ' users to add in family circle ');
      }
      this.setState({
        selectedUser: selectedMemberList,
      });
    } else {
      for (let i = 0; i < selectedMemberList.length; i++) {
        if (selectedMemberList[i].user.id === rowData.id) {
          selectedMemberList.splice(i, 1);
        }
      }
      this.setState({
        selectedUser: selectedMemberList,
      });
    }
  }

  showMemberList = (e, note) => {
    const postObject = {
      header: this.header,
      member: {
        id: this.userId,
      },
    };
    let url = 'memberms/getFamilyMembersAppuser';
    FetchServerData.callPostService(url, postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let memberList = [];
        let participantList = [];
        let isPaging = false;
        participantList = output.data;
        if (memberList.length > 5) {
          isPaging = true;
        }

        this.setState({
          isPaging: isPaging,
          expanded: false,
          memberListModal: true,
          memberList: participantList,
        });
      } else {
      }
    });
  };

  addSelectedUserList() {
    const postObject = {
      header: this.header,
      ptsSubscription: {
        id: this.state.subscriptionId,
        childSubscriptionList: this.state.selectedUser,
      },
    };
    FetchServerData.callPostService('subcriptionms/create', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          PtsAlert.success(output.message);

          this.setState({
            memberListModal: false,
          });
          this.getProfileData();
        } else {
        }
      })

      .catch((error) => {});
  }
  purchaseSubscription = () => {
    this.props.history.push({
      pathname: '/homePricing',
      state: { pageId: 'Purchase Pricing' },
    });
  };

  displayPlanSubscription() {
    let orderListItem = this.state.orderList.map((requestData, index) => {
      return (
        <>
          {requestData.payment.subscription.status === 'active' && (
            <>
              <Col md={4} style={{ margin: '0px 45px 0px 0px', padding: '1em' }}>
                <div data-aos="zoom-in" data-aos-delay={300}>
                  {/* <div className="plan"> */}
                  <div className="plan-inner">
                    <div className="entry-title">
                      <h3 style={{ padding: '7px', textTransform: 'capitalize' }}>
                        {requestData.payment.subscription.plan.description}{' '}
                      </h3>
                      <div className="price">
                        <span style={{ left: '7px' }}>{'$' + requestData.payment.subscription.price}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
              <Col md={4} style={{ padding: '1em' }}>
                <Row>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Label style={{ color: '#000', textAlign: 'right' }}> Start Date:</Label>
                  </Col>
                  <Col md={6}>{requestData.payment.subscription.startDate}</Col>
                </Row>
                <Row>
                  <Col md={6} style={{ textAlign: 'right' }}>
                    <Label style={{ color: '#000', textAlign: 'right' }}> End Date:</Label>
                  </Col>
                  <Col md={6}>{requestData.payment.subscription.endDate}</Col>
                </Row>
                <Row>
                  <Col md={12} style={{ textAlign: 'center' }}>
                    <b>Manual Charge</b>
                    <span>
                      <Switch
                        checked={this.state.checked}
                        onChange={this.handleSubscriptionChargeChange.bind(this)}
                        color="primary"
                        name="checkedB"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                      />
                    </span>
                    <b>Auto Charge</b>
                  </Col>
                </Row>
              </Col>
              <Col md={3} style={{ padding: '1em' }}>
                <>
                  <Button
                    variant="contained"
                    size="small"
                    style={{
                      background: '#f26d21',
                      color: '#fff',
                      // height: '100%',
                      borderBottom: '3px solid #636262',
                      borderRadius: '5px',
                      margin: '0 auto',
                      width: '100%',
                    }}
                    onClick={(e) => this.cancelSubscription(e)}
                  >
                    Cancel Subscription
                  </Button>
                </>
              </Col>
            </>
          )}
        </>
      );
    });
    return orderListItem;
  }

  displayFreeSubscription() {
    return (
      <>
        <>
          <Col md={4} style={{ margin: '0px 45px 0px 0px', padding: '1em' }}>
            <div
              // id="price"
              // className={this.state.planList[i].monthlyPlanClass}
              data-aos="zoom-in"
              data-aos-delay={300}
            >
              {/* <div className="plan"> */}
              <div className="plan-inner">
                <div className="entry-title">
                  <h3 style={{ padding: '7px', textTransform: 'capitalize' }}>
                    {null !== this.state.frereSubscription.plan && undefined !== this.state.frereSubscription.plan
                      ? this.state.frereSubscription.plan.name
                      : ''}{' '}
                  </h3>
                  <div className="price">
                    <span>
                      {'$'}
                      {null !== this.state.frereSubscription.plan && undefined !== this.state.frereSubscription.plan
                        ? this.state.frereSubscription.plan.price
                        : ''}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </Col>

          <Col md={2} style={{ padding: '1em' }}>
            <>
              <Button
                variant="contained"
                size="small"
                style={{
                  background: '#f26d21',
                  color: '#fff',
                  // height: '100%',
                  borderBottom: '3px solid #636262',
                  borderRadius: '5px',
                  margin: '0 auto',
                  width: '100%',
                }}
                onClick={this.purchaseSubscription}
              >
                Purchase Subscription
              </Button>
            </>
          </Col>
        </>
      </>
    );
  }

  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={12} style={{ position: 'relative', padding: '5px' }}>
              <Card
                style={{
                  height: '100%',
                  borderRadius: '20px',
                  boxShadow:
                    '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2)',
                }}
              >
                <CardHeader style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}>
                  <Row>
                    <Col md={9}>
                      <Label style={{ fontWeight: '600', color: '#0099f1', marginLeft: '0.5em' }}>
                        Plan and Subscription
                      </Label>
                    </Col>
                  </Row>
                </CardHeader>

                <CardBody>
                  <Row>
                    {this.state.isQuestionFreePlanSubscription && this.displayFreeSubscription()}
                    {this.state.isDisplayPlanSubscription && this.displayPlanSubscription()}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={12} style={{ position: 'relative', padding: '5px' }}>
              <Card>
                <CardHeader
                  style={{
                    background: '#1ABC9C',
                    color: '#fff',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
                    padding: '8px',
                  }}
                >
                  <b>Member List</b>
                </CardHeader>

                <CardBody style={{ padding: '0' }}>
                  <Table aria-label="simple table">
                    <TableHead style={{ fontFamily: 'bold' }}>
                      <TableRow>
                        <TableCell align="left">
                          <b>#</b>
                        </TableCell>
                        <TableCell align="left">
                          <b>Name</b>
                        </TableCell>
                        <TableCell align="left">
                          <Fab
                            aria-label="add"
                            style={{
                              float: 'left',
                              fontSize: 'small',
                              height: '15px',
                              width: '35px',
                              background: '#76767d',
                              cursor: 'pointer',
                              color: 'white',
                              backgroundImage: '-webkit-linear-gradient(bottom, #843b07 0%, #f7b571 100%);',
                              border: '2px solid #edf0f1',
                            }}
                            onClick={this.showMemberList.bind(this)}
                          >
                            <AddIcon />
                          </Fab>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.selectedUserList.map((row, index) => (
                        <TableRow>
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>

                          <TableCell align="left">
                            {null !== row.user.firstName && undefined !== row.user.firstName ? row.user.firstName : ''}{' '}
                            {null !== row.user.lastName && undefined !== row.user.lastName ? row.user.lastName : ''}
                          </TableCell>
                          <TableCell></TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={12} style={{ position: 'relative', padding: '5px' }}>
              <Card>
                <CardHeader
                  style={{
                    background: '#1ABC9C',
                    color: '#fff',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
                    padding: '8px',
                  }}
                >
                  <b> Order History</b>
                </CardHeader>

                <CardBody style={{ padding: '0' }}>
                  <Table aria-label="simple table">
                    <TableHead style={{ fontFamily: 'bold' }}>
                      <TableRow>
                        <TableCell align="left">
                          <b>#</b>
                        </TableCell>
                        <TableCell align="left">
                          <b>Order</b>
                        </TableCell>
                        <TableCell align="left">
                          <b>Date</b>
                        </TableCell>
                        <TableCell align="left">
                          <b>Price</b>
                        </TableCell>
                        <TableCell align="left">
                          <b>Status</b>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.orderList.map((row, index) => (
                        <TableRow style={{ backgroundColor: index % 2 === 0 ? '#fff' : '#e4f0ff' }}>
                          <TableCell component="th" scope="row">
                            {index + 1}
                          </TableCell>
                          <TableCell align="left">
                            <a
                              style={{ textDecoration: 'underline', cursor: 'pointer' }}
                              onClick={this.showOrderItems.bind(this)}
                            >
                              {' '}
                              {row.orderid}
                            </a>
                          </TableCell>
                          <TableCell align="left">{row.date}</TableCell>
                          <TableCell align="left">{row.price}</TableCell>
                          <TableCell align="left">{row.status}</TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Modal isOpen={this.state.orderItemsModal} toggle={this.orderItemsModalToggle} size="md">
            <ModalHeader toggle={this.orderItemsModalToggle}>Order Details</ModalHeader>
            <ModalBody>
              <Table
                responsive
                className="table table-striped"
                style={{
                  border: '1px solid',
                  margin: '0.5em 0em 0em 0em',
                  width: '100%',
                }}
              >
                <thead
                  style={{
                    background: '#1ABC9C',
                    color: '#fff',
                    borderBottom: '1px solid rgba(0, 0, 0, 0.125)',
                    padding: '8px',
                  }}
                >
                  <tr>
                    <th>
                      <b>#</b>
                    </th>
                    <th>
                      <b>Order Item</b>
                    </th>
                    <th>
                      <b>Price</b>
                    </th>
                  </tr>
                </thead>
                <tbody>{this.showOrderItemList()}</tbody>
              </Table>
            </ModalBody>
          </Modal>

          <Modal isOpen={this.state.memberListModal} toggle={this.memberListModalToggle} size="lg">
            <ModalHeader toggle={this.memberListModalToggle}>Member List</ModalHeader>
            <ModalBody>
              <Row>
                <Col md={12}>
                  <MaterialTable
                    title="Member List"
                    icons={UiUtils.getMaterialTableIcons()}
                    data={this.state.memberList}
                    columns={[
                      { title: '#', render: (rowData) => rowData.tableData.id + 1, width: '5%' },
                      { title: 'Name', render: (rowData) => rowData.name },
                      {
                        title: 'Select',
                        field: 'imageUrl',
                        width: '10%',
                        render: (rowData) => (
                          <input
                            type="checkbox"
                            defaultChecked={rowData.checked}
                            name="checkedSacCode"
                            onChange={(e) => this.selectedParticipant(e, rowData, 'member')}
                          />
                        ),
                      },
                    ]}
                    options={{
                      sorting: true,
                      actionsColumnIndex: -1,
                      pageSize: this.state.pageSize,
                      headerStyle: { backgroundColor: '#1ABC9C', fontWeight: 'bold', color: '#fff', align: 'left' },
                      rowStyle: {
                        height: '2em',
                        textTransform: 'capitalize',
                      },
                      filtering: true,
                      paging: this.state.isPaging,
                    }}
                  />
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <div style={{ textAlign: 'center' }}>
                <Button
                  variant="contained"
                  size="small"
                  style={{ background: '#ef6603', color: '#fff' }}
                  onClick={(e) => this.addSelectedUserList()}
                  type="submit"
                >
                  Add
                </Button>
                &nbsp;&nbsp;
                <CancelIconButton onClick={(e) => this.memberListModalToggle()} />
              </div>
            </ModalFooter>
          </Modal>
        </>
      );
    }
  }
}

export default BillingDetailsHome;
