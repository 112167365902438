import MaterialTable from '@material-table/core';
import Badge from '@material-ui/core/Badge';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Fab from '@material-ui/core/Fab';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputAdornment from '@material-ui/core/InputAdornment';
import Menu from '@material-ui/core/Menu';
import TextareaAutosize from '@material-ui/core/TextareaAutosize';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import CancelIcon from '@material-ui/icons/Cancel';
import CloseIcon from '@material-ui/icons/Close';
import DescriptionIcon from '@material-ui/icons/Description';
import GamepadIcon from '@material-ui/icons/Gamepad';
import InfoIcon from '@material-ui/icons/Info';
import NotificationsIcon from '@material-ui/icons/Notifications';
import SaveIcon from '@material-ui/icons/Save';
import SendIcon from '@material-ui/icons/Send';
import 'intro.js/introjs.css';
import moment from 'moment-timezone';
import React, { Component } from 'react';
import Avatar, { ConfigProvider } from 'react-avatar';
import Geocode from 'react-geocode';
import { Card, CardBody, CardHeader, Col, Modal, ModalBody, ModalFooter, Row } from 'reactstrap';
import AppleStore from '../../../../assets/images/appleStore.png';
import familyCare from '../../../../assets/images/familyCare.png';
import GooglePlayStore from '../../../../assets/images/googlePlayStore.png';
import FetchServerData from '../../../../provider/FetchServerData';
import UiUtils from '../../../../provider/UiUtils';
import Utils from '../../../../provider/Utils';
import appUserAction from '../../../../redux/appUser/appUserAction';
import appUserDataAction from '../../../../redux/appUserData/appUserDataAction';
import applicationStateAction from '../../../../redux/applicationState/applicationStateAction';
import consolidatedMemberAction from '../../../../redux/consolidatedMemberDetails/consolidatedMemberAction';
import fccPageStateAction from '../../../../redux/fccPageState/fccPageStateAction';
import helpDataAction from '../../../../redux/help/displayHelpAction';
import memberDataAction from '../../../../redux/memberData/memberDataAction';
import memberHomeUrlAction from '../../../../redux/memberHomeUrl/memberHomeUrlAction';
import memberListAction from '../../../../redux/memberList/memberListAction';
import PremiumFeatureAction from '../../../../redux/premiumFeatureDetails/PremiumFeatureAction';
import selectedPlanAction from '../../../../redux/selectedPlan/selectedPlanAction';
import store from '../../../../redux/store';
import Advertisement from '../../advertisement/Advertisement';
import DeleteButton from '../../buttons/DeleteButton';
import ConsentForm from '../../consentForm/ConsentForm';
import MenuWithBreadScrum from '../../menu/MenuWithBreadScrum';
import PtsModal from '../../modal/PtsModal';
import PremiumFeatures from '../../premiumFeatures/PremiumFeatures';
import PrivacyPolicy from '../../privacyPolicy/PrivacyPolicy';
import PtsAlert from '../../ptsAlert/PtsAlert';
import TermsCondition from '../../termsCondition/TermsCondition';
import GetHelp from '../getHelp/GetHelp';
import MemberCardComponent from '../memberCard/MemberCardComponent';
import './MemberHome.scss';
class MemberHome extends Component {
  header = store.getState().header.header;
  memberName = store.getState().userData.userData.firstName + ' ' + store.getState().userData.userData.lastName;
  isFreeUser = store.getState().userData.userData.isFreeUser;
  sponsorUser = store.getState().Sponsorship.Sponsorship;
  displayHelp = store.getState().help.help;
  subscriptionExpire = store.getState().userData.userDetails.expiredSubscription;
  subscriptionExpiring = store.getState().userData.userDetails.expiringSubscription;
  subscriptionExpireDay = store.getState().userData.userDetails.days;
  subscriptionEndDate = store.getState().userData.userDetails.subscriptionEndDate;
  premiumFeatures = store.getState().userData.premiumFeatures;
  showTandCDetails = store.getState().userData.userData.tandCChanged;
  title = 'Home';
  breadCrumb = [];
  AppointmentList = [];
  noAppointmentList = [];
  transportationList = [];
  noTransportationList = [];
  premiumFeaturesStatus = '';
  constructor(props) {
    super(props);

    if (this.premiumFeatures !== null && this.premiumFeatures.status !== null) {
      this.premiumFeaturesStatus = this.premiumFeatures.status.status;
    } else {
      this.premiumFeaturesStatus = '';
    }
    let showPremiumFeatures = false;
    if (this.premiumFeatures !== null && this.premiumFeaturesStatus === 'Active') {
      showPremiumFeatures = true;
    } else {
      showPremiumFeatures = false;
    }
    let visitorUrlCode = '';
    let str = undefined;
    let url = '';
    let urlArr = '';
    let urlEmail = '';
    let calendarState = {};
    store.dispatch(
      applicationStateAction.setApplicationState({
        calendarState,
      })
    );
    store.dispatch(consolidatedMemberAction.setConsolidatedMember({}));
    store.dispatch(memberHomeUrlAction.setMemberHomeUrl({}));
    store.dispatch(memberListAction.setMemberList([]));
    let action = props.action;
    let menuNotification = '';
    let pageId = '';
    let subscriptionExpire = false;
    let subscriptionExpiring = false;
    if (props.history && props.history.location && props.history.location.state) {
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
    }
    if (pageId === 'loginPage') {
      subscriptionExpire = this.subscriptionExpire;
      subscriptionExpiring = this.subscriptionExpiring;
    } else {
      subscriptionExpire = false;
      subscriptionExpiring = false;
    }
    let showTnCModal = false;
    if (this.showTandCDetails === true) {
      showTnCModal = true;
    } else {
      showTnCModal = false;
    }
    /*current URL path for sponsorship template*/
    let currentLocationUrl = window.location.href;
    let pathArray = currentLocationUrl.split('/');
    let host = pathArray[4];
    let currentLocationPath = '/' + host;

    str = window.location.href;
    let canRender = false;
    if (str !== undefined) {
      url = str.split('=');
      if (url.length > 1) {
        urlArr = str.split('?');
        urlEmail = urlArr[1].split('=');
        visitorUrlCode = urlEmail[1];
      }
    }

    if (visitorUrlCode) {
      this.visitor(visitorUrlCode);
    }
    this.state = {
      memberList: [],
      allNotificationRequestList: [],
      myRequestList: [],
      anchorEl: null,
      myRequestAnchorEl: null,
      totalNotificationCount: '',
      myRequestCount: '',
      rejectRequest: false,
      acceptRequest: true,
      notification: false,
      myRequest: true,
      isDisplayCard: false,
      isQuestionCard: false,
      render: canRender,
      consentFormModal: false,
      checkbox: false,
      action: action,
      familyMemberMemberId: '',
      transportationRequestList: [],
      transportationRequest: null,
      UpComingAppointmentList: [],
      anchorEl1: null,
      open1: false,
      openCommunication: false,
      transportation: false,
      acceptTransportationRequest: true,
      rejectTransportationRequest: false,
      transportationNotification: true,
      transportationRequestOpen: false,
      isArrayNotEmpty: false,
      isArrayEmpty: false,
      isMessageArrayNotEmpty: false,
      isMessageArrayEmpty: false,
      isTransportationListNotEmpty: false,
      isTransportationListEmpty: true,
      MessagesList: [],
      anchorEl2: null,
      reason: '',
      confirmWindowOpen: false,
      rejectTransportationWindow: false,
      transportationRejectWindowOpen: false,
      transportationId: '',
      isTaskTransportation: '',
      transportationRejectReason: '',
      pageSize: 10,
      isPaging: false,
      isFreeUser: this.isFreeUser,
      mouseOverButton: false,
      mouseOverMenu: false,
      sponsorUser: this.sponsorUser,
      currentLocationPath: currentLocationPath,
      visitorCode: visitorUrlCode,
      stepsEnabled: false,
      initialStep: 0,
      subscriptionEndDate: this.subscriptionEndDate,

      displayFloatButton: this.displayHelp,

      subscriptionExpireModel: subscriptionExpire,
      subscriptionExpiringModel: subscriptionExpiring,
      subscriptionExpireDay: this.subscriptionExpireDay,
      memberDetailsList: [],
      accompanyMemberName: '',
      myRequestsToCaregivers: [],
      caregiversRequestsToMe: [],
      getStartHelpModal: false,
      initialPage: 0,
      memberNamesList: [],
      openPremiumModal: false,
      showPremiumFeatures: showPremiumFeatures,
      confirmBox: false,
      openTnCModal: showTnCModal,
      checkboxTNC: false,
    };
    this.getHomeData();
    this.isMobile();
  }

  isMobile() {
    // https://dev.to/timhuang/a-simple-way-to-detect-if-browser-is-on-a-mobile-device-with-javascript-44j3
    if (/ Android | webOS | iPhone | iPad | iPod | BlackBerry | IEMobile | Opera Mini /i.test(navigator.userAgent)) {
      return true;
    } else {
      return false;
    }
  }
  /*if visitor enter the visitor url the call this service*/
  visitor(visitorUrlCode) {
    // make API call
    const postObject = {
      visitorCode: visitorUrlCode,
    };
    FetchServerData.callPostService('visitorms/create', postObject)
      .then((response) => {
        let output = response;
        if (output.status === 'SUCCESS') {
          this.props.history.push({
            pathname: '/',
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  async getHomeData() {
    const postObject = {
      header: this.header,
    };
    store.dispatch(memberDataAction.clearMember(null));
    await FetchServerData.callPostService('homems/getClassicMemberDetails', postObject).then(async (output) => {
      if (output.status === 'SUCCESS') {
        let memberData = output.data;

        let memberListData = output.data.memberList;
        let myRequestListData = memberData.myRequestsToMember;
        let myRequestsToCaregivers = memberData.caregiversRequestsToMe;
        let caregiversRequestsToMe = memberData.caregiversRequestsToMe;
        let subscriptionExpire = memberData.expiringSubscription;
        let subscriptionExpireDay = memberData.subscriptionInformation.days;
        let userSubscription = memberData.subscriptionInformation.userSubscription;
        let userSelectedPlan = {};
        if (userSubscription !== null) {
          if (userSubscription.plan !== null) {
            userSelectedPlan = {
              selectedPlanName: userSubscription.plan.name,
              selectedPlanList: userSubscription.plan,
            };
          }
          store.dispatch(selectedPlanAction.setSelectedPlan(userSelectedPlan));
        }
        let googleLocationAPIKey = 'AIzaSyC2X652RPdeR7LYXtbK6X7Vemh_VEQdgMM';

        Geocode.setApiKey(googleLocationAPIKey);
        Geocode.setLanguage('en');
        Geocode.setLocationType('ROOFTOP');
        Geocode.enableDebug();
        let locationDetails = [];
        locationDetails.push({
          // nickName: 'Hadapsar ',
          // shortDescription: 'Hadapsar ',
          // fax: null,
          // website: null,
          // phone: null,
          // createdOn: '2023-07-03T14:39:27.000+00:00',
          // updatedOn: '2023-07-03T14:41:42.000+00:00',
          // createdBy: 1,
          // updatedBy: 1,
          // version: 2,
          // createdByName: 'Sana Hakim',
          // id: 252,
          // incident: null,
          address: {
            line: '',
            city: '',
            zipCode: '',
            version: 0,
            id: 833,
            state: {
              name: 'California',
              code: 'CA',
              namecode: 'California (CA)',
              snum: '0',
              id: 3924,
              country: {
                name: 'United States',
                code: 'USA',
                namecode: 'United States (USA)',
                isoCode: 'USD',
                id: 231,
              },
            },
            geolocation: null,
          },
        });
        let locaData1 = [];

        for (let i = 0; i < locationDetails.length; i++) {
          let locdata = await Utils.getLatLongMapData(
            locationDetails[i].shortDescription,
            locationDetails[i].address.line,
            locationDetails[i].address.city,
            locationDetails[i].address.state.country.name,
            locationDetails[i].address.state.name,
            i
          );
          locaData1.push(locdata);
        }
        let isPaging = false;
        if (memberListData && memberListData.length > 0) {
          /*if memberList length is greater than 0 display card  */
          this.setState({
            render: true,
            isDisplayCard: true,
            isQuestionCard: false,
            memberDetailsList: memberListData,
          });
        } else {
          /*if memberList length is less than 0 welcome screen   */
          this.setState({
            render: true,
            isDisplayCard: false,
            isQuestionCard: true,
          });
        }
        let memberNameList = [];
        let fullName = '';
        memberListData.map((data) => {
          let fullName = data.firstname.trim() + ' ' + data.lastname.trim();
          if (memberNameList.includes(fullName) === false) {
            memberNameList.push(fullName);
          }
        });
        store.dispatch(memberListAction.setMemberList(memberNameList));

        if (myRequestListData.length > 0) {
          /*if myRequestListData  length  is greater than 0 then show table */
          this.setState({
            myRequest: true,
          });
        } else {
          /*if myRequestListData  length  is less than 0 then hide table */
          this.setState({
            myRequest: false,
          });
        }

        if (myRequestListData.length > 10) {
          isPaging = true;
        }

        this.setState({
          render: true,
          isPaging: isPaging,
          subscriptionExpireDay: subscriptionExpireDay,
          memberList: memberListData,
          memberDetailsList: memberListData,
          memberNameList: memberNameList,
          memberAddress: locaData1,
          myRequestsToCaregivers: myRequestsToCaregivers,
          caregiversRequestsToMe: caregiversRequestsToMe,
          myRequestList: myRequestListData,

          myRequestCount: myRequestListData.length,
        });
      } else {
      }
    });
  }
  toggleSteps = () => {
    this.setState((prevState) => ({ stepsEnabled: !prevState.stepsEnabled }));
  };
  onExit = () => {
    this.setState(() => ({ stepsEnabled: false }));
  };

  consentFormToggle = () => {
    this.setState({
      consentFormModal: !this.state.consentFormModal,
    });
  };

  acceptConsentForm = (e, familyMemberMemberId) => {
    this.setState({
      consentFormModal: true,
      familyMemberMemberId: familyMemberMemberId,
    });
  };

  handleTransportationRejectReasonChange(e) {
    let val = e.target.value;
    this.setState({ transportationRejectReason: val });
  }

  handleRejectTransportationWindowClose = () => {
    this.setState({
      rejectTransportationWindow: false,
    });
  };

  handleClickCheckBox = (e) => {
    if (e.target.checked === 'true') {
      this.setState({
        checkbox: e.target.checked,
        isDisabled: false,
      });
    } else {
      this.setState({
        checkbox: e.target.checked,
        isDisabled: true,
      });
    }
  };

  handleTAndCClickCheckBox = (e) => {
    if (e.target.checked === 'true') {
      this.setState({
        checkboxTNC: e.target.checked,
      });
    } else {
      this.setState({
        checkboxTNC: e.target.checked,
      });
    }
  };
  /*delete send  Request In Backend*/
  deleteRequestInBackend = (e, memberId) => {
    const postObject = {
      header: this.header,
      membership: {
        id: memberId,
      },
    };

    FetchServerData.callPostService('/caregiverms/deleteMembershipRequest', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let memberData = output.data;
          let myRequestListData = memberData.myRequestsToMember;
          PtsAlert.success(output.message);
          this.setState({
            myRequestList: myRequestListData,
          });
          this.getHomeData();
        } else {
        }
      })
      .catch((error) => {});
  };
  acceptTandCtInBackend(e) {
    const postObject = {
      header: this.header,
    };

    FetchServerData.callPostService('/userms/acceptTandC', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let userDetails = output.data.appuserVo;
          PtsAlert.success(output.message);
          this.setState({
            openTnCModal: false,
            openTnCModal: userDetails.tandCChanged,
          });
          let userData = {
            appuserVo: output.data.appuserVo,
          };

          store.dispatch(appUserDataAction.setUser(userData));
        } else {
        }
      })
      .catch((error) => {});
  }
  /*accept family Member  or member Request In Backend*/
  acceptMemberRequestInBackend(e, id) {
    let requestMemberId = id;
    if (this.state.checkbox) {
      const postObject = {
        header: this.header,
        memberVo: {
          familyMemberMemberId: this.state.familyMemberMemberId,
          isActive: this.state.acceptRequest,
        },
      };
      FetchServerData.callPostService('memberms/acceptMembershipRequest', postObject).then((output) => {
        if (output.status === 'SUCCESS') {
          let memberData = output.data;
          PtsAlert.success(output.message);
          let myRequestsToCaregivers = memberData.myRequestsTocaregivers;
          let caregiversRequestsToMe = memberData.caregiversRequestsToMe;
          let notificationRequestData = [...myRequestsToCaregivers, ...caregiversRequestsToMe];
          this.setState({
            render: true,
            consentFormModal: false,
            allNotificationRequestList: notificationRequestData,
            myRequestsToCaregivers: myRequestsToCaregivers,
            caregiversRequestsToMe: caregiversRequestsToMe,

            totalNotificationCount: notificationRequestData.length,
          });

          this.getHomeData();
        } else {
        }
      });
    } else {
      document.getElementById('acceptTerms').focus();
    }
  }

  /*reject family Member  or member Request In Backend*/
  rejectMemberRequestInBackend(id) {
    let requestMemberId = id;
    const postObject = {
      header: this.header,
      memberVo: {
        familyMemberMemberId: requestMemberId,
        isActive: this.state.rejectRequest,
      },
    };
    FetchServerData.callPostService('memberms/rejectMembershipRequest', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        PtsAlert.success(output.message);
        let memberData = output.data;

        let myRequestsToCaregivers = memberData.myRequestsTocaregivers;
        let caregiversRequestsToMe = memberData.caregiversRequestsToMe;
        let notificationRequestData = [...myRequestsToCaregivers, ...caregiversRequestsToMe];
        this.setState({
          render: true,
          consentFormModal: false,
          allNotificationRequestList: notificationRequestData,
          myRequestsToCaregivers: myRequestsToCaregivers,
          caregiversRequestsToMe: caregiversRequestsToMe,

          totalNotificationCount: notificationRequestData.length,
        });
        this.getHomeData();
      } else {
      }
    });
  }

  toRejectTransportationWindow = (e, requestMemberData) => {
    this.setState({
      rejectTransportationWindow: true,
      transportationId: requestMemberData.id,
      isTaskTransportation: requestMemberData.isTaskTransporation,
    });
  };

  acceptTransportationRequestInBackend(e, requestData) {
    let transportationId = requestData.id;
    const postObject = {
      header: this.header,
      transportationVo: {
        id: transportationId,
        reason: '',
        isApprove: this.state.acceptTransportationRequest,
      },
    };
    FetchServerData.callPostService('apptransportms/transportationApproval', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        PtsAlert.success(output.message);
        this.setState({
          transportationRequestOpen: false,
        });
        this.getHomeData();
      } else {
      }
    });
  }

  rejectTransportationRequestInBackend(e, requestData) {
    if (this.state.isTaskTransportation === false) {
      const postObject = {
        header: this.header,
        transportationVo: {
          id: this.state.transportationId,
          reason: this.state.transportationRejectReason,
          isApprove: this.state.rejectTransportationRequest,
        },
      };
      FetchServerData.callPostService('apptransportms/transportationApproval ', postObject).then((output) => {
        if (output.status === 'SUCCESS') {
          PtsAlert.success(output.message);
          this.setState({
            rejectTransportationWindow: false,
            transportationRequestOpen: false,
          });
          this.getHomeData();
        } else {
        }
      });
    } else {
      const postObject = {
        header: this.header,
        transportationVo: {
          id: this.state.transportationId,
          reason: this.state.transportationRejectReason,
          isApprove: this.state.rejectTransportationRequest,
        },
      };
      FetchServerData.callPostService('appTaskTransportms/transportationApproval ', postObject).then((output) => {
        if (output.status === 'SUCCESS') {
          PtsAlert.success(output.message);
          this.setState({
            rejectTransportationWindow: false,
            transportationRequestOpen: false,
          });
          this.getHomeData();
        } else {
        }
      });
    }
  }

  addSelfMember = (e) => {
    this.props.history.push({
      pathname: '/addMember',
      state: { action: 'view', title: 'Add Member' },
    });
  };

  addExistingMember = (e) => {
    this.props.history.push({
      pathname: '/addMember',
      state: { action: 'edit', title: 'Add Member', pathUrl: 'home' },
    });
  };

  memberDetailsComponent(id, memberData) {
    store.dispatch(memberDataAction.setMember(memberData));
    this.props.history.push({
      pathname: '/memberDetails',
      state: {
        memberData: memberData,
        pageId: 'home',
      },
    });
  }

  goToAppointments(id, memberData) {
    store.dispatch(memberDataAction.setMember(memberData));
    this.props.history.push({
      pathname: '/memberAppointment',
      state: {
        memberData: memberData,
        pageId: 'home',
      },
    });
  }

  goToCommunications(id, memberData) {
    store.dispatch(memberDataAction.setMember(memberData));
    this.props.history.push({
      pathname: '/memberCommunication',
      state: {
        memberData: memberData,
        pageId: 'home',
      },
    });
  }

  goToIncident(id, memberData) {
    store.dispatch(memberDataAction.setMember(memberData));
    this.props.history.push({
      pathname: '/incidentAppointment',
      state: {
        memberData: memberData,
        pageId: 'home',
      },
    });
  }

  toResendRequestInBackend = (e, familyMemberId) => {
    let familyMemberMemberId = familyMemberId;
    const postObject = {
      header: this.header,
      memberVo: {
        familyMemberMemberId: familyMemberMemberId,
      },
    };

    FetchServerData.callPostService('/memberms/resendRequest', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          PtsAlert.success(output.message);
          this.getHomeData();
          this.props.history.push({
            pathname: '/home',
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  toSelfFamilyMember(e) {
    this.props.history.push({
      pathname: '/addMember',
      state: { action: 'view' },
    });
  }

  toExistingFamilyMember(e) {
    this.props.history.push({
      pathname: '/addMember',
      state: { action: 'edit', title: 'Add Member', pathUrl: 'home' },
    });
  }

  handleAppointmentClick = (memberId, event) => {
    let UpComingAppointmentData = [];
    for (let i = 0; i < this.state.memberList.length; i++) {
      if (this.state.memberList[i].id === memberId) {
        UpComingAppointmentData = this.state.memberList[i].upcommingAppointmentList;
      }
    }

    let isArrayNotEmpty = '';
    let isArrayEmpty = '';
    if (UpComingAppointmentData.length === 0) {
      isArrayNotEmpty = false;
      isArrayEmpty = true;
    } else {
      isArrayNotEmpty = true;
      isArrayEmpty = false;
    }
    this.setState({
      anchorEl1: event.currentTarget,
      open1: true,
      isArrayNotEmpty: isArrayNotEmpty,
      isArrayEmpty: isArrayEmpty,
      render: true,
      UpComingAppointmentList: UpComingAppointmentData,
    });
  };

  handleCommunicationsClick = (memberId, event) => {
    let UpComingMessageData = [];
    for (let i = 0; i < this.state.memberList.length; i++) {
      if (this.state.memberList[i].id === memberId) {
        UpComingMessageData = this.state.memberList[i].unreadMessageList;
      }
    }

    let isMessageArrayNotEmpty = '';
    let isMessageArrayEmpty = '';
    if (UpComingMessageData.length === 0) {
      isMessageArrayNotEmpty = false;
      isMessageArrayEmpty = true;
    } else {
      isMessageArrayNotEmpty = true;
      isMessageArrayEmpty = false;
    }

    this.setState({
      openCommunication: true,
      render: true,
      anchorEl2: event.currentTarget,
      isMessageArrayNotEmpty: isMessageArrayNotEmpty,
      isMessageArrayEmpty: isMessageArrayEmpty,
      MessagesList: UpComingMessageData,
    });
  };

  handleCommunicationClose = () => {
    this.setState({ anchorEl2: null });
  };

  handleTransportationClose = () => {
    this.setState({ transportationRequest: null });
  };

  handleAppointmentClose = () => {
    this.setState({ anchorEl1: null });
  };

  displayWelComeScreenRequestSendToLovedOne() {
    const colours = ['#F39C12', '#A569BD', '#008080', '#3498DB', '#F39C12'];
    return this.state.myRequestList.map((requestData, index) => {
      if (this.state.render === false) {
        return <div />;
      } else {
        return (
          <>
            <li
              onClick={this.handleClose}
              style={{
                borderBottom: '0px',
                wordWrap: ' break-word',
                listStyle: 'none',
                backgroundColor: index % 2 === 0 ? '#ECF0F1' : 'aliceblue',
                padding: '0.5em',
                marginTop: '0.3em',
              }}
            >
              <Row>
                <Col md={12} style={{ margin: '0.6em 0em 0em 0em' }}>
                  <>
                    <h5 style={{ fontSize: '15px' }}>
                      You have outstanding request to join a Family Care Circle for{' '}
                      <b>{requestData.membername ? requestData.membername : ''} </b>
                    </h5>
                  </>
                </Col>
              </Row>
            </li>
          </>
        );
      }
    });
  }

  /*display Welcome Screen member and caregiver request Notification Message*/
  displayWelComeScreenNotificationMessage() {
    const colours = ['#F39C12', '#A569BD', '#008080', '#3498DB', '#F39C12'];
    return this.state.caregiversRequestsToMe?.map((requestData, index) => {
      if (this.state.render === false) {
        return <div />;
      } else {
        return (
          <>
            {requestData.requestraisedby === 'FM' && (
              <li
                onClick={this.handleClose}
                style={{
                  borderBottom: '0px',
                  wordWrap: ' break-word',
                  listStyle: 'none',
                  backgroundColor: index % 2 === 0 ? '#ECF0F1' : 'aliceblue',
                  padding: '0.5em',
                  marginTop: '0.3em',
                }}
              >
                <Row>
                  <Col md={8} style={{ margin: '0.6em 0em 0em 0em' }}>
                    <>
                      <h6 style={{ fontSize: '14px', lineHeight: '20px' }}>
                        <b style={{ textTransform: 'capitalize' }}>
                          {requestData.caregivername ? requestData.caregivername : ''}{' '}
                        </b>
                        has requested to join your caregiver circle as{' '}
                        <b> {requestData.role ? requestData.role : ''}</b>.
                      </h6>
                    </>
                  </Col>
                  <Col md={4}></Col>
                </Row>
              </li>
            )}
          </>
        );
      }
    });
  }

  /*display caregiver request Notification Message to new user welcome screen*/
  displayWelComeScreenCaregiverNotification() {
    return this.state.myRequestsToCaregivers.map((requestData, index) => {
      if (this.state.render === false) {
        return <div />;
      } else {
        let name = requestData.firstName + ' ' + requestData.lastName;
        return (
          <>
            {requestData.requestraisedby === 'M' && (
              <>
                <li
                  onClick={this.handleClose}
                  style={{
                    borderBottom: '0px',
                    wordWrap: ' break-word',
                    listStyle: 'none',
                    backgroundColor: index % 2 === 0 ? '#ECF0F1' : 'aliceblue',
                    padding: '0.5em',
                    marginTop: '0.3em',
                  }}
                >
                  <Row>
                    <Col md={8} style={{ margin: '0.5em 0em' }}>
                      <>
                        <span style={{ paddingTop: '1px' }}>
                          <h5 style={{ fontSize: '15px' }}>
                            <b style={{ textTransform: 'capitalize' }}>
                              {requestData.membername ? requestData.membername : ''}
                            </b>{' '}
                            has requested you to join her/his caregiver circle as{' '}
                            <b> {requestData.role ? requestData.role : ''}</b>.
                          </h5>
                        </span>
                      </>
                    </Col>
                    <Col md={4}>
                      <div style={{ textAlign: 'left' }}>
                        <Button
                          size="small"
                          variant="contained"
                          value={this.state.acceptRequest}
                          // onClick={(e) => this.acceptConsentForm(e, requestData.id)}
                          onClick={(e) => this.acceptMemberRequestInBackend(e, requestData.id)}
                          style={{ backgroundColor: '#27AE60', color: '#fff' }}
                        >
                          Accept
                        </Button>
                        &nbsp; &nbsp;
                        <Button
                          size="small"
                          variant="contained"
                          value={this.state.rejectRequest}
                          onClick={(e) => this.rejectMemberRequestInBackend(requestData.id)}
                          style={{ backgroundColor: '#EC7063', color: '#fff' }}
                        >
                          Reject
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </li>

                {/* </Card> */}
              </>
            )}
          </>
        );
      }
    });
  }

  /*show notification box of home screen*/
  showNotification() {
    const colours = ['#F39C12', '#A569BD', '#008080', '#3498DB', '#F39C12'];
    return this.state.caregiversRequestsToMe.map((requestData, index) => {
      if (this.state.render === false) {
        return '';
      } else {
        let name = '';
        if (requestData.requestraisedby === 'M') {
          name = requestData.membername;
        } else {
          name = requestData.caregivername;
        }
        return (
          <Row
            onClick={this.handleClose}
            style={{
              backgroundColor: index % 2 === 0 ? '#ECF0F1' : '#fff',
              paddingTop: '0em',
              borderBottom: '0px',
              width: '472px',
              wordWrap: ' break-word',
              marginLeft: '7px',
              marginRight: '7px',
              padding: '1.5em',
            }}
            key={index}
          >
            <Col md={12}>
              <Row>
                <Col md={2} style={{ paddingTop: '1px' }}>
                  <ConfigProvider>
                    <Avatar
                      color={colours[index % colours.length]}
                      textSizeRatio={1.75}
                      name={null !== name && undefined !== name ? name : ''}
                      round="50px"
                      size="50px"
                    />
                  </ConfigProvider>
                </Col>
                <Col md={10} style={{ paddingTop: '13px', paddingLeft: '10px' }}>
                  {requestData.requestraisedby === 'M' ? (
                    <>
                      <span style={{ paddingTop: '1px' }}>
                        <h5 style={{ fontSize: '15px' }}>
                          <b style={{ textTransform: 'capitalize' }}>
                            {requestData.membername ? requestData.membername : ''}
                          </b>{' '}
                          has requested you to join her/his caregiver circle as{' '}
                          <b> {requestData.role ? requestData.role : ''}</b>.
                        </h5>
                      </span>
                    </>
                  ) : (
                    <>
                      <span
                        style={{
                          wordWrap: 'break-word',
                          whiteSpace: 'normal',
                        }}
                      >
                        <h6 style={{ fontSize: '14px', lineHeight: '20px' }}>
                          <b style={{ textTransform: 'capitalize' }}>
                            {requestData.caregivername ? requestData.caregivername : ''}{' '}
                          </b>
                          has requested to join your caregiver circle as{' '}
                          <b> {requestData.role ? requestData.role : ''}</b>.
                        </h6>
                      </span>
                    </>
                  )}
                </Col>
              </Row>

              <Row>
                <Col md={12} style={{ textAlign: 'left', paddingLeft: '77px' }}>
                  <Button
                    size="small"
                    variant="contained"
                    value={this.state.acceptRequest}
                    onClick={(e) => this.acceptMemberRequestInBackend(e, requestData.id)}
                    // onClick={(e) => this.acceptConsentForm(e, requestData.id)}
                    style={{ backgroundColor: '#27AE60', color: '#fff' }}
                  >
                    Accept
                  </Button>
                  &nbsp; &nbsp;
                  <Button
                    size="small"
                    variant="contained"
                    value={this.state.rejectRequest}
                    onClick={(e) => this.rejectMemberRequestInBackend(requestData.id)}
                    style={{ backgroundColor: '#EC7063', color: '#fff' }}
                  >
                    Reject
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          // </tr>
        );
      }
    });
  }

  toMemberDetails = (e, data) => {
    this.props.history.push({
      pathname: '/viewMember',
      state: {
        action: 'view',
        title: 'Member Details',
        memberId: data.id,
        familyMemberMemberId: data.familyMemberMemberId,
      },
    });
  };

  handleClick = (event) => this.setState({ anchorEl: event.currentTarget });
  handleClose = () => this.setState({ anchorEl: null });

  handleTransportationNotificationClick = (memberId, name, event) => {
    let appointmentTransportation = [];
    for (let i = 0; i < this.state.memberList.length; i++) {
      if (this.state.memberList[i].id === memberId) {
        appointmentTransportation = this.state.memberList[i].transporationRequestList;
      }
    }

    let isTransportationListNotEmpty = '';
    let isTransportationListEmpty = '';

    if (appointmentTransportation.length === 0) {
      isTransportationListNotEmpty = false;
      isTransportationListEmpty = true;
    } else {
      isTransportationListNotEmpty = true;
      isTransportationListEmpty = false;
    }
    this.setState({
      accompanyMemberName: name,
      isTransportationListNotEmpty: isTransportationListNotEmpty,
      isTransportationListEmpty: isTransportationListEmpty,
      transportationRequestOpen: true,
      transportationRequest: event.currentTarget,
      transportationRequestList: appointmentTransportation,
    });
  };

  /*show and hide create Family Circle For Me button */
  isUserMember() {
    if (!this.state.memberList || this.state.memberList.length <= 0) {
      return false;
    }
    for (let i = 0; i < this.state.memberList.length; i++) {
      if (
        this.state.memberList[i].appuserId === this.header.appuserId ||
        Utils.equalsIgnoreCase(this.state.memberList[i].relationship, 'My Circle')
      ) {
        return true;
      }
    }
    return false;
  }

  /*show SponsorShip Adv  to sponsor user*/
  showSponsorShipAdv() {
    let advertisementList = this.state.sponsorUser.sponsor.advertisementList;
    for (let i = 0; i < advertisementList.length; i++) {
      if (advertisementList[i].uid === this.state.currentLocationPath) {
        return (
          <Card style={{ display: 'block', height: '100px', width: '100%', padding: '1em', background: '#F4F6F7' }}>
            <Row>
              <Col md={4}>
                <p className="sponsorHeading">
                  <u>
                    {null !== advertisementList[i].heading && undefined !== advertisementList[i].heading
                      ? advertisementList[i].heading
                      : ''}
                  </u>
                </p>

                <p className="sponsorSubheading">
                  <i>
                    {null !== advertisementList[i].subHeading && undefined !== advertisementList[i].subHeading
                      ? advertisementList[i].subHeading
                      : ''}
                  </i>
                </p>
              </Col>
              <Col md={8}>
                <p>
                  {null !== advertisementList[i].text && undefined !== advertisementList[i].text
                    ? advertisementList[i].text
                    : ''}
                </p>
              </Col>
            </Row>
          </Card>
        );
      }
    }
  }

  scrollToSection(elementID) {
    if (document.getElementById(elementID)) {
      document.getElementById(elementID).scrollIntoView();
    }
  }

  handleSubscriptionExpireModelClose = () => {
    this.setState({
      subscriptionExpireModel: false,
    });
  };

  handleSubscriptionExpiringModelClose = () => {
    this.setState({
      subscriptionExpiringModel: false,
    });
  };

  closeFloatingButton() {
    this.setState({ displayFloatButton: false });
    let displayFloat = false;

    store.dispatch(helpDataAction.setHelpData(displayFloat));
  }

  renewSubscriptionPlan = () => {
    this.props.history.push({
      pathname: '/checkout',
    });
  };
  openGetHelpModal() {
    this.setState({
      getStartHelpModal: true,
    });
  }
  consolidatedView = () => {
    if (this.state.isFreeUser === true) {
      if (this.premiumFeatures !== null && this.premiumFeaturesStatus === 'Expired') {
        this.setState({
          openPremiumModal: true,
        });
      } else if (this.premiumFeatures === null) {
        this.setState({
          openPremiumModal: true,
        });
      } else {
        this.props.history.push({
          pathname: '/consolidatedView',
        });
      }
    } else {
      this.props.history.push({
        pathname: '/consolidatedView',
      });
    }
  };
  getStartHelpModelToggle = () => {
    this.setState({
      getStartHelpModal: !this.state.getStartHelpModal,
    });
  };
  premiumModalToggle = () => {
    this.setState({
      openPremiumModal: !this.state.openPremiumModal,
    });
  };
  termsConditionToggle = () => {
    this.setState({
      openTermsConditionModal: false,
    });
    document.location.reload(true);
  };

  privacyPolicyToggle = () => {
    this.setState({
      openPrivacyPolicyModal: false,
    });
    document.location.reload(true);
  };
  tnCModalToggle = () => {
    let postObject = {
      header: this.header,
    };
    FetchServerData.callPostService('userms/logout', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        store.dispatch(appUserAction.logout());
        this.props.history.push('/login');

        this.setState({
          openTnCModal: !this.state.openTnCModal,
        });
        let calendarState = {};
        store.dispatch(
          applicationStateAction.setApplicationState({
            calendarState,
          })
        );
        store.dispatch(fccPageStateAction.setFccPageState({}));
      } else {
        store.dispatch(appUserAction.logout());
        store.dispatch(fccPageStateAction.setFccPageState({}));
        store.dispatch(memberDataAction.setMember({}));
        store.dispatch(consolidatedMemberAction.setConsolidatedMember({}));
        store.dispatch(memberListAction.setMemberList([]));
        store.dispatch(PremiumFeatureAction.setPremiumFeature({}));
        let calendarState = {};
        store.dispatch(
          applicationStateAction.setApplicationState({
            calendarState,
          })
        );
        this.props.history.push('/login');
      }
    });
  };
  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }
  showMobileIcon() {
    return (
      <>
        <a
          style={{ color: '#fff', cursor: 'pointer' }}
          href="https://apps.apple.com/us/app/family-care-circle/id1602521967"
          target="_blank"
        >
          <img src={AppleStore} style={{ height: '3em' }} />
        </a>
        &nbsp;&nbsp;
        <a
          target="_blank"
          href="https://play.google.com/store/apps/details?id=com.familycarecircle.fccmobileapp"
          style={{ color: '#fff', cursor: 'pointer' }}
        >
          <img src={GooglePlayStore} style={{ height: '3.5em' }} />
        </a>
      </>
    );
  }
  openTermsCondition() {
    // this.props.history.push({
    //   pathname: '/termsConditions',
    // });
    // this.setState({
    //   openTermsConditionModal: true,
    //   openTnCModal: false,
    // });
  }

  openPrivacyPolicy() {
    this.setState({
      openPrivacyPolicyModal: true,
      openTnCModal: false,
    });
  }
  displayWelComeScreen() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <MenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            name={this.title}
            headerWidth="home"
          />
          {this.isMobile() === true && (
            <Row>
              <Col md={12} style={{ textAlign: 'center' }}>
                <p style={{ fontSize: '17px' }}>
                  <b>Use mobile apps for better User experience</b> &nbsp;&nbsp;
                  {this.showMobileIcon()}{' '}
                </p>
              </Col>
            </Row>
          )}

          <div
            md={12}
            style={{
              backgroundColor: 'transparent',
              alignItems: 'flex-end',
            }}
          >
            <section className="homePageSection">
              <Row style={{ margin: '1em' }}>
                <Col md={5} style={{ textAlign: 'center' }}>
                  <img src={familyCare} alt="" className="welcomeCardImg" />
                </Col>
                <Col md={7}>
                  <Card>
                    <CardBody
                      style={{
                        background: '#B2F9CF',
                        color: '#000',
                        fontSize: '15px',
                        borderRadius: '5px',
                        paddingBottom: '0px',
                        paddingRight: '5px',
                      }}
                    >
                      <p style={{ margin: '2px' }}>
                        <i>
                          Welcome <b>{this.memberName}</b>
                        </i>
                      </p>
                      <p>
                        <i>
                          You are now ready to create a Family Care Circle for yourself or for a loved one. A Family
                          Care Circle contains caregivers who help share care, transportation needs and track the
                          general well-being of a loved one. FCC helps you keep notes, track incidents like falls and
                          keep key information available for discussion at the next doctor appointment or with other
                          caregivers. FCC also allows you to have a private communication between one or more caregivers
                          related to an upcoming appointment or to communicate quickly with others on the status of a
                          recent appointment. This important tool allows for sharing of important information and better
                          caregiving of your loved ones.<br></br>
                          Sometimes, it takes only one act of kindness and caring to change a person’s life.<br></br>So,
                          Let’s Get Started...
                          {/* At this point, you can create a Family Care Circle for yourself or create a Circle of
                          caregivers for a loved one.
                          <br />
                          A Family Care Circle contains caregivers whom help share care, transportation needs and
                          general well-being of a loved one.
                          <br />
                          FCC helps you keep notes, incidents like falls and other key information available for
                          discussion at the next doctor appointment. FCC allows you private communication between the
                          one or more caregivers related to an upcoming appointment or communicating quickly with others
                          the status of recent appointment. Allowing for better family health care of your loved ones.
                          <br />
                          Sometimes, it takes only one act of kindness and caring to change a person’s life.
                          <br />
                          So, Let’s Start... */}
                        </i>
                      </p>
                      <div style={{ padding: '0em 0em 0em 1em' }}>
                        <ul style={{ listStyle: 'none' }}></ul>
                      </div>
                    </CardBody>
                  </Card>
                  {/* {this.state.allNotificationRequestList.length > 0 ? (
                    <> */}
                  <Card>
                    <CardHeader style={{ padding: '0px 0px 0px 15px' }}>
                      <Row>
                        {this.state.caregiversRequestsToMe.length === 0 ? (
                          <Col md={8} style={{ marginTop: '3.5em' }}></Col>
                        ) : (
                          <Col md={8} style={{ marginTop: '10px' }}>
                            <p style={{ fontSize: '15px', color: '#3498DB' }}>
                              <b>Following are requests to join your Family Care Circle.</b>
                            </p>
                          </Col>
                        )}
                        <Col md={4} style={{ paddingTop: '8px', paddingLeft: '0em' }}>
                          <Button
                            size="small"
                            variant="contained"
                            className="createSelf"
                            onClick={(e) => this.toSelfFamilyMember()}
                            style={{
                              backgroundColor: '#05565b',
                              color: '#fff',
                              textTransform: 'none',
                            }}
                          >
                            Create Circle for Myself
                          </Button>
                        </Col>
                      </Row>
                    </CardHeader>
                    {this.state.caregiversRequestsToMe.length > 0 && (
                      <CardBody style={{ paddingTop: '0em' }}>
                        <>
                          <Row>
                            <Col md={12}>
                              <ul>{this.displayWelComeScreenNotificationMessage()}</ul>
                            </Col>
                          </Row>
                        </>
                      </CardBody>
                    )}
                  </Card>

                  {this.state.myRequestsToCaregivers.length > 0 ? (
                    <Card>
                      <CardHeader style={{ padding: '0px 0px 0px 15px' }}>
                        <p style={{ fontSize: '15px', color: '#3498DB', margin: 'revert' }}>
                          <b>Following people have requested you to join their Circle, as Caregiver</b>
                        </p>
                      </CardHeader>

                      <CardBody style={{ paddingTop: '0px ' }}>
                        <Row>
                          <Col md={12}>{this.displayWelComeScreenCaregiverNotification()}</Col>
                        </Row>
                      </CardBody>
                    </Card>
                  ) : (
                    ''
                  )}

                  <section id="myRequest" style={{ paddingTop: '0em' }}>
                    <Card>
                      <Row>
                        {this.state.myRequest ? (
                          <Col md={8} style={{ paddingTop: '13px', paddingLeft: '28px' }}>
                            <p style={{ fontSize: '15px', color: '#3498DB' }}>
                              <b>Following are your outstanding requests to join a Family Care Circle</b>
                            </p>
                          </Col>
                        ) : (
                          <>
                            <Col md={8} style={{ paddingTop: '3.5em', paddingLeft: '28px' }}></Col>
                          </>
                        )}
                        <Col md={4} style={{ paddingTop: '8px', paddingLeft: '0.5em' }}>
                          <Button
                            className="createLoveOne"
                            size="small"
                            variant="contained"
                            onClick={(e) => this.toExistingFamilyMember()}
                            style={{
                              backgroundColor: '#05565b',
                              color: '#fff',
                              textTransform: 'none',
                            }}
                          >
                            Create Circle for Loved One
                          </Button>
                        </Col>
                      </Row>
                      {this.state.myRequest && (
                        <CardBody style={{ paddingTop: '0em' }}>
                          <>
                            <Row>
                              <Col md={12}>
                                <ul>{this.displayWelComeScreenRequestSendToLovedOne()}</ul>
                              </Col>
                            </Row>
                          </>
                        </CardBody>
                      )}
                    </Card>
                  </section>
                </Col>
              </Row>
            </section>
          </div>

          <Modal isOpen={this.state.consentFormModal} toggle={this.consentFormToggle} size="xl">
            <Row style={{ marginTop: '1em' }}>
              <Col md={11}></Col>
              <Col md={1}>
                <Fab
                  aria-label="add"
                  style={{
                    float: 'left',
                    fontSize: 'small',
                    height: '15px',
                    width: '35px',
                    background: '#76767d',
                    cursor: 'pointer',
                    color: 'white',

                    border: '2px solid #edf0f1',
                  }}
                  id="myRequestIcon"
                >
                  <CloseIcon onClick={(e) => this.consentFormToggle()} />
                </Fab>
              </Col>
            </Row>
            <ModalBody>
              <Row>
                <Col md={12}>
                  <ConsentForm></ConsentForm>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Row md={12} style={{ margin: '0.5em', alignItems: 'center', width: '100%' }}>
                <Col md={6} style={{ textAlign: 'center' }}>
                  <FormControlLabel
                    id="acceptTerms"
                    control={
                      <Checkbox
                        style={{ color: 'black' }}
                        checked={this.state.checkbox}
                        onChange={this.handleClickCheckBox}
                      />
                    }
                    label="I accept the consent form"
                    style={{ color: '#162c50', fontWeight: 'bold' }}
                  />
                </Col>
                <Col md={6} style={{ textAlign: 'center' }}>
                  {this.state.checkbox && this.state.checkbox === true && (
                    <Button
                      variant="contained"
                      size="small"
                      style={{
                        // float: 'right',
                        background: '#ef6603',
                        color: '#fff',
                      }}
                      startIcon={<ArrowForwardIcon />}
                      onClick={(e) => this.acceptMemberRequestInBackend(e, this.state.id)}
                    >
                      Accept
                    </Button>
                  )}
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    variant="contained"
                    size="small"
                    style={{ backgroundColor: '#777777', color: '#fff' }}
                    startIcon={<CancelIcon />}
                    onClick={(e) => this.consentFormToggle()}
                  >
                    Decline
                  </Button>
                </Col>
              </Row>
            </ModalFooter>
          </Modal>
          <Dialog
            open={this.state.subscriptionExpireModel}
            onClose={this.handleSubscriptionExpireModelClose.bind(this)}
            onHide={this.handleSubscriptionExpireModelClose.bind(this)}
            style={{ padding: '1em' }}
            className="subscriptionModal"
          >
            <DialogContent style={{ textAlign: 'center' }}>
              <Row>
                <Col md={12}>
                  <span className="closeFloatingButton" aria-label="add">
                    <CloseIcon
                      onClick={this.handleSubscriptionExpireModelClose.bind(this)}
                      style={{ float: 'right', fontSize: '15px', cursor: 'pointer' }}
                    />
                  </span>
                </Col>
              </Row>

              <p style={{ fontSize: '18px', padding: '1em' }}>
                Your Subscription has expired. Please Renew it to continue Ad free experience.
              </p>
            </DialogContent>
            <DialogActions style={{ padding: '0em 0em 1em 0em', marginLeft: 'auto', marginRight: 'auto' }}>
              <Button
                variant="contained"
                size="medium"
                style={{ background: '#0f666b', color: '#fff', marginRight: '1rem' }}
                onClick={this.renewSubscriptionPlan.bind(this)}
              >
                Renew Plan
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.subscriptionExpiringModel}
            onClose={this.handleSubscriptionExpiringModelClose.bind(this)}
            onHide={this.handleSubscriptionExpiringModelClose.bind(this)}
            style={{ padding: '1em' }}
            className="subscriptionModal"
          >
            <DialogContent style={{ textAlign: 'center' }}>
              <Row>
                <Col md={12}>
                  <span className="closeFloatingButton" aria-label="add">
                    <CloseIcon
                      onClick={this.handleSubscriptionExpiringModelClose.bind(this)}
                      style={{ float: 'right', fontSize: '15px', cursor: 'pointer' }}
                    />
                  </span>
                </Col>
              </Row>

              <p style={{ fontSize: '18px', padding: '1em' }}>
                Your subscription will expire on {moment(this.state.subscriptionEndDate).format('DD MMM YYYY')}. Please
                renew to use ad-free services.
              </p>
            </DialogContent>
            <DialogActions style={{ padding: '0em 0em 1em 0em', marginLeft: 'auto', marginRight: 'auto' }}>
              <Button
                variant="contained"
                size="medium"
                style={{ background: '#0f666b', color: '#fff', marginRight: '1rem' }}
                onClick={this.renewSubscriptionPlan.bind(this)}
              >
                Renew Plan
              </Button>
            </DialogActions>
          </Dialog>
        </>
      );
    }
  }

  displayMemberCardScreen() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <MenuWithBreadScrum
            breadCrumb={this.breadCrumb}
            history={this.props.history}
            name={this.title}
            headerWidth="home"
          />
          {this.isMobile() === true && (
            <Row>
              <Col md={12} style={{ textAlign: 'center' }}>
                <p style={{ fontSize: '17px' }}>
                  <b>Use mobile apps for better User experience</b> &nbsp;&nbsp;
                  {this.showMobileIcon()}{' '}
                </p>
              </Col>
            </Row>
          )}

          <div
            md={12}
            style={{
              backgroundColor: 'transparent',
              alignItems: 'flex-end',
            }}
          >
            <section className="homePageSection">
              <Row style={{ padding: '10px 10px 0px' }}>
                <Col md={5}>
                  <Advertisement pageId="Home" />
                </Col>

                <Col md={7}>
                  <Row>
                    <Col md={12} style={{ textAlign: 'right' }}>
                      {this.state.myRequest && (
                        <>
                          <Tooltip title="My Requests">
                            <Badge
                              badgeContent={this.state.myRequestCount}
                              color="secondary"
                              overlap="rectangular"
                              style={{
                                float: 'right',
                                margin: '0.8em 0.8em 0.8em 0.8em',
                              }}
                            >
                              <Button
                                variant="contained"
                                size="small"
                                startIcon={<SendIcon />}
                                style={{
                                  background: '#1976d2',
                                  color: '#fff',
                                  border: '2px solid #edf0f1',
                                  cursor: 'pointer',
                                  float: 'right',
                                  textTransform: 'capitalize',
                                }}
                                onClick={() => this.scrollToSection('myRequest')}
                              >
                                My Requests
                              </Button>
                            </Badge>
                          </Tooltip>
                        </>
                      )}
                      {this.isUserMember() === false && this.state.caregiversRequestsToMe.length > 0 && (
                        <>
                          <Tooltip title="Requests for me">
                            <Badge
                              badgeContent={
                                this.state.caregiversRequestsToMe ? this.state.caregiversRequestsToMe.length : ''
                              }
                              color="primary"
                              overlap="rectangular"
                              style={{
                                float: 'right',
                                margin: '0.8em 0em 0.8em 0.8em',
                              }}
                            >
                              <Button
                                // type={this.state.type ? this.state.type : ''}
                                variant="contained"
                                size="small"
                                startIcon={<NotificationsIcon className="notifications" />}
                                style={{
                                  background: 'chocolate',
                                  color: '#fff',
                                  border: '2px solid #edf0f1',
                                  cursor: 'pointer',
                                  float: 'right',
                                  textTransform: 'capitalize',
                                }}
                                onClick={this.handleClick}
                                id="notificationBellIcon"
                              >
                                Requests for me
                              </Button>
                            </Badge>
                          </Tooltip>

                          <Menu
                            id="simple-menu3"
                            anchorEl={this.state.anchorEl}
                            keepMounted
                            open={Boolean(this.state.anchorEl)}
                            onClose={this.handleClose}
                            className="upcomingApp"
                            style={{ marginTop: '3em', padding: '0em' }}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                          >
                            <table className="table table-striped" id="upCommingAppointments">
                              <tbody>{this.showNotification()}</tbody>
                            </table>
                          </Menu>
                        </>
                      )}
                      {this.state.isDisplayCard && this.state.isDisplayCard === true && (
                        <>
                          <Tooltip title="Create a Family Care Circle for a loved one or Friend">
                            <Button
                              variant="contained"
                              size="small"
                              startIcon={<AddIcon />}
                              style={{
                                background: 'green',
                                color: '#fff',
                                border: '2px solid #edf0f1',
                                cursor: 'pointer',
                                float: 'right',
                                textTransform: 'capitalize',
                                margin: '0.8em 0em 0.8em 0.8em',
                              }}
                              onClick={this.addExistingMember.bind(this)}
                            >
                              Create New Circle
                            </Button>
                          </Tooltip>
                          {this.isUserMember() === false && (
                            <Tooltip title="Create Circle For Myself">
                              <Button
                                variant="contained"
                                size="small"
                                startIcon={<AddIcon />}
                                style={{
                                  background: 'black',
                                  color: '#fff',
                                  border: '2px solid #edf0f1',
                                  cursor: 'pointer',
                                  float: 'right',
                                  textTransform: 'capitalize',
                                  margin: '0.8em 0em 0.8em 0.8em',
                                }}
                                onClick={this.addSelfMember.bind(this)}
                                id="addSelfIcon"
                              >
                                Create Circle For Myself
                              </Button>
                            </Tooltip>
                          )}
                          <Tooltip title="Get Start">
                            <Button
                              variant="contained"
                              size="small"
                              startIcon={<InfoIcon />}
                              style={{
                                background: '#8E44AD',
                                color: '#fff',
                                border: '2px solid #edf0f1',
                                cursor: 'pointer',
                                float: 'right',
                                textTransform: 'capitalize',
                                margin: '0.8em 0em 0.8em 0.8em',
                              }}
                              onClick={this.openGetHelpModal.bind(this)}
                            >
                              Getting Started
                            </Button>
                          </Tooltip>
                          <Tooltip title="Consolidated View">
                            <>
                              <Badge
                                badgeContent={<i class="fa fa-dollar" style={{ fontSize: '18px' }}></i>}
                                color="secondary"
                                className="consolidatedViewBtn"
                                overlap="rectangular"
                                style={{
                                  float: 'right',
                                  // margin: '0.8em 0.8em 0.8em 0.8em',
                                  marginRight: '12em',
                                }}
                              ></Badge>

                              <Button
                                variant="contained"
                                size="small"
                                startIcon={<GamepadIcon />}
                                className="consolidatedViewBtn"
                                style={{
                                  background: '#C0392B',
                                  color: '#fff',
                                  border: '2px solid #edf0f1',
                                  cursor: 'pointer',
                                  float: 'right',
                                  textTransform: 'capitalize',
                                  margin: '0.8em 0em 0.8em 0.8em',
                                }}
                                onClick={this.consolidatedView.bind(this)}
                                // endIcon={<img src={Crown} style={{ height: '1.3em' }} />}
                              >
                                Consolidated View
                              </Button>
                            </>
                          </Tooltip>
                        </>
                      )}
                    </Col>
                  </Row>
                  {this.state.showPremiumFeatures === true ? (
                    <Row id="movetxt">
                      <Col md={12} style={{ textAlign: 'right' }}>
                        <span
                          style={{
                            fontSize: '15px',
                            color: 'red',
                            marginRight: this.state.myRequest === true ? '0.8em' : '0em',
                          }}
                        >
                          Your trial period has started. Enjoy all premium features until{' '}
                          {moment(this.premiumFeatures ? this.premiumFeatures.endDate : '').format('MMM DD, YYYY')}.
                        </span>
                      </Col>
                    </Row>
                  ) : (
                    ''
                  )}
                </Col>
              </Row>
              <Row style={{ margin: '5px' }}>
                {this.state.memberList.map((currentMemberDetails, index) => (
                  <MemberCardComponent
                    position={index}
                    memberDetail={currentMemberDetails}
                    memberNamesList={this.state.memberNameList}
                    memberAddress={this.state.memberAddress}
                    render={this.state.render}
                    parentRef={this}
                    key={index}
                    history={this.props.history}
                    onSuccess={this.getHomeData.bind(this)}
                  />
                ))}
              </Row>
            </section>

            <section id="myRequest" style={{ padding: '0px 0px 3px 2px' }} className="myRequest">
              {this.state.myRequest && (
                <>
                  {this.state.isDisplayCard && this.state.isDisplayCard === true && (
                    <Row style={{ position: 'relative' }}>
                      <Col md={12} style={{ padding: '1.9em 1.9em 1.9em 1.6em', position: 'relative' }}>
                        <MaterialTable
                          icons={UiUtils.getMaterialTableIcons()}
                          title="Your Requests for Members to join their Caregiver Circle"
                          columns={[
                            { title: '#', render: (rowData) => rowData.tableData.index + 1, width: '5%' },
                            { title: 'Member Name', field: 'membername' },
                            { title: 'Member Email', field: 'memberemail' },

                            { title: 'Status', field: 'registrationstatus' },
                            {
                              width: '2%',
                              disableClick: true,
                              render: (rowData) =>
                                rowData.status !== null ? (
                                  <Button
                                    type="submit"
                                    style={{
                                      backgroundColor: '#1a7088',
                                      color: '#fff',
                                      fontSize: 'xx-small',
                                      height: '3em',
                                      width: '14em',
                                    }}
                                    variant="contained"
                                    onClick={(e) => this.toResendRequestInBackend(e, rowData.id)}
                                  >
                                    Resend Request
                                  </Button>
                                ) : (
                                  ' '
                                ),
                            },
                            {
                              width: '1%',
                              disableClick: true,
                              render: (rowData) => (
                                <DeleteButton
                                  domainObject="Do you want to delete this request?"
                                  onClick={(e) => this.deleteRequestInBackend(e, rowData.id)}
                                  pageId="home"
                                />
                              ),
                            },
                          ]}
                          data={this.state.myRequestList}
                          onPageChange={(page, pageSize) => {
                            this.handleMaterialTablePageChange(page, pageSize);
                          }}
                          options={{
                            sorting: true,
                            actionsColumnIndex: -1,
                            pageSize: this.state.pageSize,
                            initialPage: this.state.initialPage,
                            headerStyle: {
                              backgroundColor: '#066f72',
                              fontWeight: 'bold',
                              color: '#fff',
                              align: 'left',
                            },
                            rowStyle: {
                              height: '2em',
                            },
                            filtering: false,
                            // paging: this.state.isPaging,
                            paging: this.state.myRequestList.length > this.state.pageSize,
                          }}
                          actions={[]}
                        />
                      </Col>
                    </Row>
                  )}
                </>
              )}
            </section>
          </div>

          <Modal isOpen={this.state.consentFormModal} toggle={this.consentFormToggle} size="xl">
            <Row style={{ marginTop: '1em' }}>
              <Col md={11}></Col>
              <Col md={1}>
                <Fab
                  aria-label="add"
                  style={{
                    float: 'left',
                    fontSize: 'small',
                    height: '15px',
                    width: '35px',
                    background: '#76767d',
                    cursor: 'pointer',
                    color: 'white',

                    border: '2px solid #edf0f1',
                  }}
                  id="myRequestIcon"
                >
                  <CloseIcon onClick={(e) => this.consentFormToggle()} />
                </Fab>
              </Col>
            </Row>
            <ModalBody>
              <Row>
                <Col md={12}>
                  <ConsentForm></ConsentForm>
                </Col>
              </Row>
            </ModalBody>
            <ModalFooter>
              <Row md={12} style={{ margin: '0.5em', alignItems: 'center', width: '100%' }}>
                <Col md={6} style={{ textAlign: 'center' }}>
                  <FormControlLabel
                    id="acceptTerms"
                    control={
                      <Checkbox
                        style={{ color: 'black' }}
                        checked={this.state.checkbox}
                        onChange={this.handleClickCheckBox}
                      />
                    }
                    label="I accept the consent form"
                    style={{ color: '#162c50', fontWeight: 'bold' }}
                  />
                </Col>
                <Col md={6} style={{ textAlign: 'center' }}>
                  {this.state.checkbox && this.state.checkbox === true && (
                    <Button
                      variant="contained"
                      size="small"
                      style={{
                        // float: 'right',
                        background: '#ef6603',
                        color: '#fff',
                      }}
                      startIcon={<ArrowForwardIcon />}
                      onClick={(e) => this.acceptMemberRequestInBackend(e, this.state.familyMemberMemberId)}
                    >
                      Accept
                    </Button>
                  )}
                  &nbsp;&nbsp;&nbsp;
                  <Button
                    variant="contained"
                    size="small"
                    style={{ backgroundColor: '#777777', color: '#fff' }}
                    startIcon={<CancelIcon />}
                    onClick={(e) => this.consentFormToggle()}
                  >
                    Decline
                  </Button>
                </Col>
              </Row>
            </ModalFooter>
          </Modal>
          <PtsModal open={this.state.getStartHelpModal} onClose={this.getStartHelpModelToggle} title="Getting Started">
            <GetHelp />
          </PtsModal>
          <Dialog
            open={this.state.rejectTransportationWindow}
            onClose={this.handleRejectTransportationWindowClose.bind(this)}
            onHide={this.close}
            style={{ padding: '1em' }}
          >
            <DialogContent style={{ marginTop: '2em' }}>
              <Row>
                <Col md={12} style={{ marginLeft: 'auto', marginRight: 'auto' }}>
                  <TextareaAutosize
                    aria-label="minimum height"
                    fullWidth
                    style={{ width: '100%' }}
                    variant="outlined"
                    value={this.state.transportationRejectReason}
                    onChange={this.handleTransportationRejectReasonChange.bind(this)}
                    minRows={4}
                    placeholder="Reason"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <DescriptionIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Col>
              </Row>
            </DialogContent>
            <DialogActions style={{ marginTop: '1em', padding: '2em', marginLeft: 'auto', marginRight: 'auto' }}>
              <Button
                variant="contained"
                size="large"
                style={{ background: '#0f666b', color: '#fff', marginRight: '1rem' }}
                startIcon={<SaveIcon />}
                onClick={(e) => this.rejectTransportationRequestInBackend(e)}
              >
                Reject
              </Button>
              <Button
                variant="contained"
                size="large"
                style={{ background: '#717373', color: '#fff', marginRight: '1rem' }}
                startIcon={<CloseIcon />}
                onClick={this.handleRejectTransportationWindowClose.bind(this)}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.subscriptionExpireModel}
            onClose={this.handleSubscriptionExpireModelClose.bind(this)}
            onHide={this.handleSubscriptionExpireModelClose.bind(this)}
            style={{ padding: '1em' }}
            // fullWidth
            // maxWidth="md"
            className="subscriptionModal"
          >
            <DialogContent style={{ textAlign: 'center' }}>
              <Row>
                <Col md={12}>
                  <span className="closeFloatingButton" aria-label="add">
                    <CloseIcon
                      onClick={this.handleSubscriptionExpireModelClose.bind(this)}
                      style={{ float: 'right', fontSize: '15px', cursor: 'pointer' }}
                    />
                  </span>
                </Col>
              </Row>

              <p style={{ fontSize: '18px', padding: '1em 1em 0em 1em' }}>
                Your Subscription has expired. Please Renew it to continue Ad free experience.
              </p>
            </DialogContent>
            <DialogActions style={{ padding: '0em 0em 1em 0em', marginLeft: 'auto', marginRight: 'auto' }}>
              <Button
                variant="contained"
                size="medium"
                style={{ background: '#0f666b', color: '#fff', marginRight: '1rem' }}
                onClick={this.renewSubscriptionPlan.bind(this)}
              >
                Renew Plan
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.subscriptionExpiringModel}
            onClose={this.handleSubscriptionExpiringModelClose.bind(this)}
            onHide={this.handleSubscriptionExpiringModelClose.bind(this)}
            style={{ padding: '1em' }}
            className="subscriptionModal"
          >
            <DialogContent style={{ textAlign: 'center' }}>
              <Row>
                <Col md={12}>
                  <span className="closeFloatingButton" aria-label="add">
                    <CloseIcon
                      onClick={this.handleSubscriptionExpiringModelClose.bind(this)}
                      style={{ float: 'right', fontSize: '15px', cursor: 'pointer' }}
                    />
                  </span>
                </Col>
              </Row>

              <p style={{ fontSize: '18px', padding: '1em' }}>
                {/* Your Subscription will expire soon. Please Renew it to continue Ad free experience.{' '} */}
                Your subscription will expire on {moment(this.state.subscriptionEndDate).format('DD MMM YYYY')}. Please
                renew to use ad-free services.
              </p>
            </DialogContent>
            <DialogActions style={{ padding: '0em 0em 1em 0em', marginLeft: 'auto', marginRight: 'auto' }}>
              <Button
                variant="contained"
                size="medium"
                style={{ background: '#0f666b', color: '#fff', marginRight: '1rem' }}
                onClick={this.renewSubscriptionPlan.bind(this)}
              >
                Renew Plan
              </Button>
            </DialogActions>
          </Dialog>
          <PtsModal
            open={this.state.openPremiumModal}
            onClose={this.premiumModalToggle}
            // title="Family Care Circle Document"
          >
            {Utils.PremiumFeatures(this.premiumFeatures) === 1 ? (
              this.showFirstPremiumFeatures()
            ) : (
              <PremiumFeatures
                history={this.props.history}
                pageId="home"
                isFreeUser={this.state.isFreeUser}
                modalOpen={this.state.openPremiumModal}
                closeModal={this.premiumModalToggle.bind(this)}
                onSuccess={this.getHomeData.bind(this)}
              />
            )}
          </PtsModal>
          <Dialog
            open={this.state.confirmBox}
            onClose={this.handleConfirmBoxClose.bind(this)}
            onHide={this.close}
            style={{ padding: '1em' }}
          >
            <DialogContent style={{ marginTop: '1em' }}>
              <Row>
                <Col md={12} style={{ marginLeft: 'auto', marginRight: 'auto', textAlign: 'center' }}>
                  <p style={{ lineHeight: '2em', fontSize: '18px' }}>
                    Your trial period has started.
                    <br /> You can enjoy all premium functionality till{' '}
                    {moment(this.state.endDate ? this.state.endDate : '').format('MMM DD, YYYY')}.
                  </p>
                </Col>
              </Row>
            </DialogContent>
            <DialogActions style={{ marginTop: '1em', padding: '2em', marginLeft: 'auto', marginRight: 'auto' }}>
              <Button
                variant="contained"
                size="large"
                style={{ background: '#0f666b', color: '#fff', marginRight: '1rem' }}
                // startIcon={<SaveIcon />}
                onClick={(e) => this.handleConfirmBox(e)}
              >
                Ok
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={this.state.openTnCModal}
            onClose={this.tnCModalToggle.bind(this)}
            onHide={this.close}
            style={{ padding: '1em' }}
          >
            <DialogContent style={{ marginTop: '1em' }}>
              <Row>
                <Col md={12} style={{ textAlign: 'center' }}>
                  <span style={{ color: '#162c50', margin: '1.1em 1em', padding: '1em' }}>
                    {' '}
                    We have updated our&nbsp;
                    <a
                      href="#/termsConditions"
                      target="_blank"
                      // onClick={(e) => this.openTermsCondition()}
                      style={{ color: '#007bff', cursor: 'pointer', fontWeight: '600' }}
                    >
                      <u>Terms & Conditions</u>{' '}
                    </a>
                    and{' '}
                    <a
                      href="#/privacyPolicyWeb"
                      target="_blank"
                      // onClick={(e) => this.openPrivacyPolicy()}
                      style={{ color: '#007bff', cursor: 'pointer', fontWeight: '600' }}
                    >
                      {' '}
                      <u> Privacy Policy</u>
                    </a>
                    . <br />
                    Please review and accept before proceeding.
                  </span>{' '}
                  <br /> <br />
                  <span style={{ color: '#162c50', margin: '1.1em 1em', padding: '1em' }}>
                    <Checkbox
                      style={{ color: 'black' }}
                      checked={this.state.checkboxTNC}
                      onChange={this.handleTAndCClickCheckBox}
                    />
                    &nbsp; I accept the{' '}
                    <a
                      href="#/termsConditions"
                      target="_blank"
                      // onClick={(e) => this.openTermsCondition()}
                      style={{ color: '#007bff', cursor: 'pointer', fontSize: '13px' }}
                    >
                      Terms & Conditions{' '}
                    </a>{' '}
                    and{' '}
                    <a
                      href="#/privacyPolicyWeb"
                      target="_blank"
                      // onClick={(e) => this.openPrivacyPolicy()}
                      style={{ color: '#007bff', cursor: 'pointer', fontSize: '13px' }}
                    >
                      {' '}
                      Privacy Policy
                    </a>
                    .
                  </span>
                </Col>
              </Row>
            </DialogContent>
            <DialogActions style={{ marginTop: '1em', padding: '2em', marginLeft: 'auto', marginRight: 'auto' }}>
              {this.state.checkboxTNC && this.state.checkboxTNC === true ? (
                <Button
                  variant="contained"
                  size="small"
                  style={{
                    // float: 'right',
                    background: '#ef6603',
                    color: '#fff',
                  }}
                  onClick={(e) => this.acceptTandCtInBackend(e)}
                >
                  Accept
                </Button>
              ) : (
                <Button
                  variant="contained"
                  size="small"
                  disabled
                  style={{
                    // float: 'right',
                    opacity: '0.5',
                    background: '#ef6603',
                    color: '#fff',
                  }}
                  onClick={(e) => this.acceptTandCtInBackend(e)}
                >
                  Accept
                </Button>
              )}
              &nbsp;
              <Button
                variant="contained"
                size="small"
                style={{ background: '#717373', color: '#fff', marginRight: '1rem' }}
                // startIcon={<CloseIcon />}
                onClick={this.tnCModalToggle.bind(this)}
              >
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
          <PtsModal
            open={this.state.openTermsConditionModal}
            onClose={this.termsConditionToggle}
            // title="Family Care Circle Document"
          >
            <TermsCondition pageId="registration" />
          </PtsModal>
          <PtsModal
            open={this.state.openPrivacyPolicyModal}
            onClose={this.privacyPolicyToggle}
            // title="Family Care Circle Document"
          >
            <PrivacyPolicy pageId="registration" />
          </PtsModal>
        </>
      );
    }
  }
  purchaseSubscription = () => {
    this.props.history.push({
      pathname: '/homePricing',
    });
  };
  showFirstPremiumFeatures() {
    return (
      <>
        <Row style={{ margin: '1em' }}>
          <Col md={12}>
            <Card
              style={{
                height: '100%',
                borderRadius: '20px',
                boxShadow:
                  '0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2)',
              }}
            >
              <CardHeader
                style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)', textAlign: 'center', color: '#000' }}
              >
                <h3>
                  <b>Premium Features</b>
                </h3>
              </CardHeader>
              <CardBody>
                <Row style={{ textAlign: 'left', padding: '15px' }}>
                  <Col md={12} style={{ margin: 'auto' }}>
                    <p>This feature is part of our premium package and is available for subscribed users.</p>

                    <p style={{ lineHeight: '2em' }}>
                      The premium package includes the following:
                      <ul style={{ marginLeft: '1.5em' }}>
                        <li>
                          Consolidated View – Available on the home screen quick view of appointments across all the
                          circles you are enrolled in.
                        </li>
                        <li>
                          Prescriptions – Enter all of your medicines and vitamins to have readily available for your
                          next office visit.
                        </li>
                        <li>Ad Free experience – Ads are suppressed on all screens.</li>
                        <li>Unlimited Family Circles – Create or participate in as many Circles as needed.</li>
                        <li>Unlimited Appointments per month – Track as many appointments as needed.</li>
                      </ul>
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col md={12}>
                    <Button
                      variant="contained"
                      size="small"
                      // type="submit"
                      style={{ background: '#138D75', color: '#fff', marginLeft: '3em' }}
                      onClick={this.startTrialPeriod.bind(this)}
                    >
                      Start A 30 day Trial Period
                    </Button>
                    &nbsp;&nbsp;
                    <Button
                      className="purchaseSubscription"
                      variant="contained"
                      size="small"
                      style={{
                        background: '#f26d21',
                        color: '#fff',
                        borderBottom: '3px solid #636262',
                        borderRadius: '5px',
                        margin: '50px auto',
                      }}
                      onClick={(e) => this.purchaseSubscription(e)}
                    >
                      Purchase Subscription
                    </Button>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </>
    );
  }
  handleConfirmBoxClose = () => {
    this.setState({
      confirmBox: false,
    });
  };
  handleConfirmBox = () => {
    this.setState({
      confirmBox: false,
    });
    this.getHomeData();
  };
  startTrialPeriod() {
    const header = store.getState().header.header;

    const postObject = {
      header: header,
    };
    FetchServerData.callPostService('/userPremiumFeatureMs/startTrial', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          PtsAlert.success(output.message);
          let premiumFeatureTrialinfo = output.data.premiumFeatureTrialinfo;

          this.setState({
            render: this.toResendRequestInBackend,
            openPremiumModal: false,
            confirmBox: true,
            isFreeUser: output.data.isFreeUser,
            endDate: premiumFeatureTrialinfo.endDate,
          });
          // this.getHomeData();
          let userData = {
            appuserVo: output.data,
          };
          store.dispatch(appUserDataAction.setUser(userData));
          this.premiumFeatures = output.data.premiumFeatureTrialinfo;

          // store.dispatch(PremiumFeatureAction.setPremiumFeature(output.data.premiumFeatureTrialinfo));
        } else {
        }
      })
      .catch((error) => {});
  }

  render() {
    if (this.state.isDisplayCard === true) {
      if (this.state.render === false) {
        return <div />;
      } else {
        return this.displayMemberCardScreen();
      }
    } else {
      return this.displayWelComeScreen();
    }
  }
}
// const mapStateToProps = (state) => {
//   return { isFreeUser: state.userData.userData.isFreeUser };
// };
export default MemberHome;
