import React, { Component } from 'react';
import ReactApexChart from 'react-apexcharts';
import { Card, CardBody, CardFooter, Col, Container, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import store from '../../../redux/store';
import { AdminDashboardNav } from '../adminDashboardNav/AdminDashboardNav';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import Button from '@material-ui/core/Button';
import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import moment from 'moment-timezone';
import calender from '../../../assets/images/calender.png';
import './AdminDashboard.scss';
import AdminMenuWithBreadScrum from '../adminMenu/AdminMenuWithBreadScrum';
import BreadCrumbDetailsAction from '../../../redux/breadCrumbDetails/BreadCrumbDetailsAction';
import Utils from '../../../provider/Utils';
import ActiveMenuAction from '../../../redux/activeMenu/ActiveMenuAction';
export class AdminDashboard extends Component {
  header = store.getState().adminHeader.adminHeader;
  title = 'Home';
  breadCrumb = [];
  constructor(props) {
    super(props);
    let action = props.action;
    let pageId = '';
    if (
      null !== props.history &&
      props.history !== undefined &&
      props.history.location !== null &&
      props.history.location.state !== null &&
      props.history.location.state !== undefined
    ) {
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
    }

    this.state = {
      email: '',
      password: '',
      showPassword: false,
      action: action,
      pageId: pageId,
      render: false,
      chartData: [],
      contStatDetails: [],
      countStatMonth: [],
      TotalCaregivers: [],
      FacilityCount: [],
      PurchaseCount: [],
      EventCount: [],
      SetupComplete: [],
      IncidentCount: [],
      AppointmentCount: [],
      DoctorCount: [],
      CreatedByPOA: [],
      Newly_Created: [],
      TotalCircle: [],
      FreeUser: [],
      startDate: new Date(),
      endDate: new Date(),
    };
    this.getStatisticData();
  }

  getStatisticData = (e) => {
    const postObject = {
      header: this.header,
    };
    FetchServerData.callPostService('/adminpanelStatisticms/statData', postObject)
      .then((response) => {
        let output = response;
        if (output.status === 'SUCCESS') {
          let statisticData = output.data.statsData;
          let userChartData = output.data.users;
          let contStatData = output.data.countStatsData;
          let countStatMonth = output.data.months;
          let userLabels = [];
          let userValue = [];

          for (let i = 0; i < userChartData.length; i++) {
            let date = userChartData[i].year + '\n' + userChartData[i].monthName;
            userLabels.push(date);
            userValue.push(userChartData[i].numberOfUsers);
          }

          this.setState({
            render: true,
            statisticData: statisticData,
            chartData: statisticData.users,
            contStatDetails: contStatData,
            TotalCaregivers: contStatData.TotalCaregivers,
            FacilityCount: contStatData.FacilityCount,
            PurchaseCount: contStatData.PurchaseCount,
            EventCount: contStatData.EventCount,
            SetupComplete: contStatData.SetupComplete,
            IncidentCount: contStatData.IncidentCount,
            AppointmentCount: contStatData.AppointmentCount,
            DoctorCount: contStatData.DoctorCount,
            CreatedByPOA: contStatData.CreatedByPOA,
            Newly_Created: contStatData.Newly_Created,
            TotalCircle: contStatData.TotalCircle,
            FreeUser: contStatData.FreeUser,
            countStatMonth: countStatMonth,
            priceSeries: [
              {
                name: 'Users',
                type: 'area',
                data: userValue.reverse(),
              },
            ],
            // priceOptions: {
            //   chart: {
            //     height: 350,
            //     type: 'line',

            //     zoom: {
            //       enabled: false,
            //     },
            //     toolbar: {
            //       show: false,
            //     },
            //   },
            //   tooltip: {
            //     x: {
            //       show: false,
            //     },
            //   },
            //   grid: {
            //     yaxis: {
            //       lines: {
            //         show: false,
            //       },
            //     },
            //   },

            //   dataLabels: {
            //     enabled: false,
            //   },
            //   stroke: {
            //     curve: 'smooth',
            //   },
            //   title: {
            //     text: '',
            //     align: 'center',
            //   },

            //   legend: {
            //     show: true,
            //     showForSingleSeries: true,
            //     position: 'top',
            //     horizontalAlign: 'center',
            //   },
            //   labels: userLabels.reverse(),
            //   xaxis: {
            //     tooltip: {
            //       enabled: false,
            //     },
            //     // max: new Date(new Date().setHours(24, 0, 0, 0)).getTime(), // end date
            //     type: 'datetime',
            //     labels: {
            //       format: 'yyyy-MMM',
            //       show: true,
            //     },
            //     title: {
            //       text: 'Year',
            //       style: {
            //         colors: '#808b96',
            //       },
            //       labels: {
            //         style: {
            //           colors: '#808b96 ',
            //         },
            //       },
            //       tickPlacement: 'between',
            //     },

            //     axisBorder: {
            //       show: true,
            //       color: '#34495E',
            //       height: 1,
            //       width: '100%',
            //       offsetX: 0,
            //       offsetY: 0,
            //     },
            //     axisTicks: {
            //       show: true,
            //       color: '#34495E',
            //     },
            //   },

            //   yaxis: [
            //     {
            //       axisTicks: {
            //         show: true,
            //       },
            //       axisBorder: {
            //         show: true,
            //         color: '#247BA0',
            //       },

            //       labels: {
            //         formatter: function (val, index) {
            //           return val.toFixed();
            //         },
            //         style: {
            //           colors: '#247BA0',
            //         },
            //       },
            //       title: {
            //         text: 'Users',
            //         style: {
            //           color: '#247BA0',
            //         },
            //       },
            //     },
            //   ],
            // },
            priceOptions: {
              chart: {
                height: 350,
                type: 'area',
                zoom: {
                  enabled: false,
                },
                toolbar: {
                  show: false,
                },
              },
              dataLabels: {
                enabled: false,
              },
              stroke: {
                curve: 'smooth',
              },
              markers: {
                size: 5,
                hover: {
                  size: 9,
                },
              },
              xaxis: {
                type: 'datetime',
                categories: userLabels.reverse(),
                // max: Date.now(),
                // tickAmount: 15,
                max: new Date(new Date().setHours(24, 0, 0, 0)).getTime(),
              },
              tooltip: {
                x: {
                  show: true,
                  format: 'dd-MMM-yy',
                  formatter: function (value, { series, seriesIndex, dataPointIndex, w }) {
                    let date = new Date(value);
                    return moment(date).format('DD-MMM-yy');
                  },
                },
              },
            },
          });
        } else {
        }
      })
      .catch((error) => {});
  };
  showTotalCaregiversTableCell() {
    for (let i = 0; i < this.state.TotalCaregivers.length; i++) {
      return (
        <>
          {' '}
          <TableCell>{this.state.TotalCaregivers[i].area}</TableCell>
          <TableCell>{this.state.TotalCaregivers[i].stat}</TableCell>
        </>
      );
    }
  }
  showFacilityCountTableCell() {
    for (let i = 0; i < this.state.FacilityCount.length; i++) {
      return (
        <>
          {' '}
          <TableCell>{this.state.FacilityCount[i].area}</TableCell>
          <TableCell>{this.state.FacilityCount[i].stat}</TableCell>
        </>
      );
    }
  }

  showPurchaseCountTableCell() {
    for (let i = 0; i < this.state.PurchaseCount.length; i++) {
      return (
        <>
          {' '}
          <TableCell>{this.state.PurchaseCount[i].area}</TableCell>
          <TableCell>{this.state.PurchaseCount[i].stat}</TableCell>
        </>
      );
    }
  }

  showEventCountTableCell() {
    for (let i = 0; i < this.state.EventCount.length; i++) {
      return (
        <>
          {' '}
          <TableCell>{this.state.EventCount[i].area}</TableCell>
          <TableCell>{this.state.EventCount[i].stat}</TableCell>
        </>
      );
    }
  }

  showSetupCompleteTableCell() {
    for (let i = 0; i < this.state.SetupComplete.length; i++) {
      return (
        <>
          {' '}
          <TableCell>{this.state.SetupComplete[i].area}</TableCell>
          <TableCell>{this.state.SetupComplete[i].stat}</TableCell>
        </>
      );
    }
  }

  showIncidentCountTableCell() {
    for (let i = 0; i < this.state.IncidentCount.length; i++) {
      return (
        <>
          {' '}
          <TableCell>{this.state.IncidentCount[i].area}</TableCell>
          <TableCell>{this.state.IncidentCount[i].stat}</TableCell>
        </>
      );
    }
  }

  showAppointmentCountTableCell() {
    for (let i = 0; i < this.state.AppointmentCount.length; i++) {
      return (
        <>
          {' '}
          <TableCell>{this.state.AppointmentCount[i].area}</TableCell>
          <TableCell>{this.state.AppointmentCount[i].stat}</TableCell>
        </>
      );
    }
  }

  showDoctorCountTableCell() {
    for (let i = 0; i < this.state.DoctorCount.length; i++) {
      return (
        <>
          {' '}
          <TableCell>{this.state.DoctorCount[i].area}</TableCell>
          <TableCell>{this.state.DoctorCount[i].stat}</TableCell>
        </>
      );
    }
  }

  showCreatedByPOATableCell() {
    for (let i = 0; i < this.state.CreatedByPOA.length; i++) {
      return (
        <>
          {' '}
          <TableCell>{this.state.CreatedByPOA[i].area}</TableCell>
          <TableCell>{this.state.CreatedByPOA[i].stat}</TableCell>
        </>
      );
    }
  }

  showNewly_CreatedTableCell() {
    for (let i = 0; i < this.state.Newly_Created.length; i++) {
      return (
        <>
          {' '}
          <TableCell>{this.state.Newly_Created[i].area}</TableCell>
          <TableCell>{this.state.Newly_Created[i].stat}</TableCell>
        </>
      );
    }
  }

  showTotalCircleTableCell() {
    for (let i = 0; i < this.state.TotalCircle.length; i++) {
      return (
        <>
          {' '}
          <TableCell>{this.state.TotalCircle[i].area}</TableCell>
          <TableCell>{this.state.TotalCircle[i].stat}</TableCell>
        </>
      );
    }
  }

  showFreeUserTableCell() {
    for (let i = 0; i < this.state.FreeUser.length; i++) {
      return (
        <>
          {' '}
          <TableCell>{this.state.FreeUser[i].area}</TableCell>
          <TableCell>{this.state.FreeUser[i].stat}</TableCell>
        </>
      );
    }
  }

  handleStartDateChange(date) {
    this.setState({
      startDate: date,
    });
  }
  handleEndDateChange(date) {
    this.setState({
      endDate: date,
    });
  }

  checkLoginCountInBackend() {
    this.props.history.push({
      pathname: '/loginCount',
    });
  }
  checkUserLoginCountInBackend() {
    this.props.history.push({
      pathname: '/userLoginCount',
    });
  }
  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      let breadCrumb = [{ pageid: '/ApHome', uiname: 'Home' }];

      let userMenuArray = store.getState().adminUserDetails.adminUserDetails.profile.menus;
      for (let i = 0; i < userMenuArray.length; i++) {
        for (let j = 0; breadCrumb && j < breadCrumb.length; j++) {
          if (userMenuArray[i].pageid === breadCrumb[j].pageid) {
            store.dispatch(ActiveMenuAction.setActiveMenu(userMenuArray[i].id));
          }
        }
      }
      store.dispatch(BreadCrumbDetailsAction.setBreadCrumbDetails(breadCrumb));

      return (
        <>
          <AdminMenuWithBreadScrum breadCrumb={this.breadCrumb} history={this.props.history} name={this.title} />
          <AdminDashboardNav data={this.state.statisticData} />
          {/* Page content */}
          <Container className="mt--7" fluid>
            <Row>
              <Col md={9}>
                <Card style={{ position: 'relative' }}>
                  <CardBody>
                    {/* Chart */}

                    {/* <ReactApexCharts
                      options={this.state.priceOptions}
                      series={this.state.priceSeries}
                      type="area"
                      height={300}
                    /> */}
                    <ReactApexChart
                      options={this.state.priceOptions}
                      series={this.state.priceSeries}
                      type="area"
                      height={350}
                    />
                  </CardBody>
                </Card>
              </Col>
              <Col md={3}>
                <Card style={{ position: 'relative', cursor: 'pointer', height: '398px' }}>
                  <CardBody
                    style={{ textAlign: 'center', paddingTop: '4em' }}
                    onClick={(e) => this.checkLoginCountInBackend()}
                  >
                    <img src={calender} alt="" style={{ height: '250px' }} />
                  </CardBody>
                  <CardFooter className="staticBtn d-flex row justify-content-around">
                    <Button
                      variant="contained"
                      style={{ background: '#ef6603', color: '#fff', textTransform: 'capitalize' }}
                      onClick={(e) => this.checkLoginCountInBackend()}
                    >
                      Login Statistics
                    </Button>
                    <Button
                      variant="contained"
                      style={{ background: '#fe344c', color: '#fff', textTransform: 'capitalize' }}
                      onClick={(e) => this.checkUserLoginCountInBackend()}
                    >
                      User Statistics
                    </Button>
                  </CardFooter>
                </Card>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <>
                  <Card>
                    <Table aria-label="simple table" className="countStatMonth">
                      <TableHead
                        className="countHeader"
                        style={{ fontFamily: 'bold', background: '#1ABC9C', color: '#fff' }}
                      >
                        <TableRow style={{ color: '#fff' }}>
                          <TableCell>
                            <b>Area</b>
                          </TableCell>
                          <TableCell>
                            <b>Stats</b>
                          </TableCell>
                          {this.state.countStatMonth.map((row, index) => (
                            <TableCell>
                              <b>{moment(row).format('MMM-YY')}</b>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          {this.showTotalCaregiversTableCell()}
                          {this.state.TotalCaregivers.map((data, index) => (
                            <>
                              <TableCell> {data.result}</TableCell>
                            </>
                          ))}
                        </TableRow>
                        <TableRow>
                          {this.showFacilityCountTableCell()}
                          {this.state.FacilityCount.map((data, index) => (
                            <>
                              <TableCell> {data.result}</TableCell>
                            </>
                          ))}
                        </TableRow>
                        <TableRow>
                          {this.showPurchaseCountTableCell()}
                          {this.state.PurchaseCount.map((data, index) => (
                            <>
                              <TableCell> {data.result}</TableCell>
                            </>
                          ))}
                        </TableRow>
                        <TableRow>
                          {this.showEventCountTableCell()}
                          {this.state.EventCount.map((data, index) => (
                            <>
                              <TableCell> {data.result}</TableCell>
                            </>
                          ))}
                        </TableRow>
                        <TableRow>
                          {this.showSetupCompleteTableCell()}
                          {this.state.SetupComplete.map((data, index) => (
                            <>
                              <TableCell> {data.result}</TableCell>
                            </>
                          ))}
                        </TableRow>
                        <TableRow>
                          {this.showIncidentCountTableCell()}
                          {this.state.IncidentCount.map((data, index) => (
                            <>
                              <TableCell> {data.result}</TableCell>
                            </>
                          ))}
                        </TableRow>
                        <TableRow>
                          {this.showAppointmentCountTableCell()}
                          {this.state.AppointmentCount.map((data, index) => (
                            <>
                              <TableCell> {data.result}</TableCell>
                            </>
                          ))}
                        </TableRow>
                        <TableRow>
                          {this.showDoctorCountTableCell()}
                          {this.state.DoctorCount.map((data, index) => (
                            <>
                              <TableCell> {data.result}</TableCell>
                            </>
                          ))}
                        </TableRow>
                        <TableRow>
                          {this.showCreatedByPOATableCell()}
                          {this.state.CreatedByPOA.map((data, index) => (
                            <>
                              <TableCell> {data.result}</TableCell>
                            </>
                          ))}
                        </TableRow>
                        <TableRow>
                          {this.showNewly_CreatedTableCell()}
                          {this.state.Newly_Created.map((data, index) => (
                            <>
                              <TableCell> {data.result}</TableCell>
                            </>
                          ))}
                        </TableRow>
                        <TableRow>
                          {this.showTotalCircleTableCell()}
                          {this.state.TotalCircle.map((data, index) => (
                            <>
                              <TableCell> {data.result}</TableCell>
                            </>
                          ))}
                        </TableRow>

                        <TableRow>
                          {this.showFreeUserTableCell()}
                          {this.state.FreeUser.map((data, index) => (
                            <>
                              <TableCell> {data.result}</TableCell>
                            </>
                          ))}
                        </TableRow>
                      </TableBody>
                    </Table>
                  </Card>
                </>
              </Col>
            </Row>
          </Container>
        </>
      );
    }
  }
}
export default AdminDashboard;
