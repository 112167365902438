import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import React, { Component } from 'react';
export class SyncToCalenderButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      onClick: props.onClick,
      caption: props.caption,
    };
  }

  onClick() {
    if (this.state.onClick) {
      this.state.onClick();
    }
  }
  render() {
    return (
      <Tooltip
        title={
          this.props.pageId === 'Event'
            ? 'Send an email with a calendar invite to this Event'
            : 'Send an email with a calendar invite to this appointment'
        }
      >
        <Button
          type={this.state.type}
          variant="contained"
          style={{
            background: '#867e7e',
            color: 'white',
            fontSize: 'x-large',
            textTransform: 'capitalize',
            cursor: 'pointer',
            fontSize: 'x-larger',
            float: 'right',
            margin: '0.2em 0.5em 0.3em 0em',
          }}
          startIcon={<EventAvailableIcon />}
          onClick={this.onClick.bind(this)}
        >
          Send me invite
        </Button>
      </Tooltip>
    );
  }
}
export default SyncToCalenderButton;
