import { IconButton, InputAdornment } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import EmailIcon from '@material-ui/icons/Email';
import LanguageIcon from '@material-ui/icons/Language';
import PhoneIcon from '@material-ui/icons/Phone';
import PinDropIcon from '@material-ui/icons/PinDrop';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import React, { Component } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import adminFccPageStateAction from '../../../redux/adminPanel/adminFccPageState/adminFccPageStateAction';
import paidAdvertiserDetailsAction from '../../../redux/adminPanel/paidAdvertiserDetails/paidAdvertiserDetailsAction';
import store from '../../../redux/store';
import BackButton from '../../components/buttons/BackButton';
import CancelIconButton from '../../components/buttons/CancelIconButton';
import CreateButton from '../../components/buttons/CreateButton';
import SaveButton from '../../components/buttons/SaveButton';
import PtsAlert from '../../components/ptsAlert/PtsAlert';
import AdminAddress from '../adminAddress/AdminAddress';
import AdminMenuWithBreadScrum from '../adminMenu/AdminMenuWithBreadScrum';
import BreadCrumbDetailsAction from '../../../redux/breadCrumbDetails/BreadCrumbDetailsAction';
class PaidAdvertiser extends Component {
  title = 'Paid Advertiser Details';
  addressRef = React.createRef();
  header = store.getState().adminHeader.adminHeader;
  paidAdvertiserDetails = store.getState().paidAdvertiserDetails.paidAdvertiserDetails;
  adminFccPageState = store.getState().adminFccPageState.adminFccPageState;
  userDetails = store.getState().adminUserDetails.adminUserDetails;
  adminBreadCrumb = [
    // { pageid: '/home', uiname: 'home' },
    { pageid: '/paidAdvertiserList', uiname: 'Paid Advertiser List' },
    { pageid: '/viewAdvertiser', uiname: 'Paid Advertiser Details' },
  ];
  adviserHomeBreadCrumb = [];
  adviserBreadCrumb = [{ pageid: '/viewAdvertiser', uiname: 'Paid Advertiser Details' }];
  addressRef = {};
  constructor(props) {
    super(props);
    let advertiserId = props.advertiserId;
    let action = props.action;
    let title = '';
    let pageId = '';
    if (!advertiserId && props.history && props.history.location && props.history.location.state) {
      advertiserId = props.history.location.state.advertiserId;
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
      title = props.history.location.state.title;
    }
    let canRender = false;
    let breadCrumb = [];
    if (this.userDetails.isUserLogin === 'paidAdvertiser') {
      if (pageId === 'PaidAdvertiserLoginPage') {
        breadCrumb = this.adviserHomeBreadCrumb;
        let adviserBreadCrumb = [{ pageid: '/viewAdvertiser', uiname: 'Paid Advertiser Details' }];
        if (adviserBreadCrumb) {
          Utils.getAdminPanelActiveMenu(adviserBreadCrumb);
          store.dispatch(BreadCrumbDetailsAction.setBreadCrumbDetails(adviserBreadCrumb));
        }
      } else {
        breadCrumb = this.adviserBreadCrumb;
      }
    } else {
      breadCrumb = this.adminBreadCrumb;
    }

    if (null !== advertiserId && advertiserId > 0) {
      this.getPaidAdvertiserDetails(advertiserId);
    } else {
      canRender = true;
    }
    if (this.adminFccPageState) {
      pageId = this.adminFccPageState.pageId;
    }
    if (advertiserId === undefined) {
      if (pageId === 'paidAdvertiserList') {
        advertiserId = this.adminFccPageState.id;
        action = this.adminFccPageState.action;
        title = this.adminFccPageState.title;
      } else {
        advertiserId = this.paidAdvertiserDetails.id;
        action = 'view';
      }
      if (action !== 'create') {
        this.getPaidAdvertiserDetails(advertiserId);
      }
    }
    this.state = {
      id: advertiserId,
      email: '',
      password: '',
      showPassword: false,
      action: action,
      pageId: pageId,
      website: '',
      fax: '',
      name: '',
      address: {},
      render: canRender,
      title: title,
      userList: [],
      status: {},
      isStatus: true,
      breadCrumb: breadCrumb,
      getUpdatePermission: false,
      getDeletePermission: false,
    };
  }

  getPaidAdvertiserDetails(advertiserId) {
    // let id = advertiserId;
    const postObject = {
      header: this.header,
      paidAdvertiser: {
        id: advertiserId,
      },
    };

    FetchServerData.callPostService('/paidadvertiserms/get', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let paidAdvertiserDetails = output.data.paidAdvertiser;
          store.dispatch(paidAdvertiserDetailsAction.setPaidAdvertiser(paidAdvertiserDetails));
          let getPermission = Utils.getRolePermission(output.data.privilegeList);
          let getUpdatePermission = getPermission[3];
          let getDeletePermission = getPermission[4];
          let userStatus = paidAdvertiserDetails.status;
          let advertiserStatus = '';
          if (userStatus === null) {
            advertiserStatus = false;
          } else {
            if (userStatus.status === 'Active') {
              advertiserStatus = true;
            } else {
              advertiserStatus = false;
            }
          }

          if (paidAdvertiserDetails.userList !== null && paidAdvertiserDetails.userList.length > 0) {
            let user = {};
            for (let i = 0; i < paidAdvertiserDetails.userList.length; i++) {
              user = paidAdvertiserDetails.userList[i];
            }
            this.setState({
              render: true,
              id: paidAdvertiserDetails.id,
              email: paidAdvertiserDetails.email,
              phone: paidAdvertiserDetails.phone,
              website: paidAdvertiserDetails.website,
              fax: paidAdvertiserDetails.fax,
              name: paidAdvertiserDetails.name,
              fname: user.firstName,
              lname: user.lastName,
              adminEmail: user.email,
              status: paidAdvertiserDetails.status,
              address: paidAdvertiserDetails.address,
              userList: paidAdvertiserDetails.userList,
              isStatus: advertiserStatus,
              getDeletePermission: getDeletePermission,
              userStatus: paidAdvertiserDetails.status.status,
              getUpdatePermission: getUpdatePermission,
              getDeletePermission: getDeletePermission,
            });
          } else {
            this.setState({
              render: true,
              id: paidAdvertiserDetails.id,
              email: paidAdvertiserDetails.email,
              phone: paidAdvertiserDetails.phone,
              website: paidAdvertiserDetails.website,
              fax: paidAdvertiserDetails.fax,
              name: paidAdvertiserDetails.name,
              status: paidAdvertiserDetails.status,
              address: paidAdvertiserDetails.address,
              userList: [],
              fname: '',
              lname: '',
              adminEmail: '',
              isStatus: advertiserStatus,
              getDeletePermission: getDeletePermission,
              userStatus: paidAdvertiserDetails.status.status,
              getUpdatePermission: getUpdatePermission,
              getDeletePermission: getDeletePermission,
            });
          }
        } else {
        }
      })
      .catch((error) => {});
  }
  handleWebsiteChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ website: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }
  handlePhoneChange({ target: { value } }) {
    this.setState((prevState) => ({
      phone: Utils.convertPhoneNumberToUsaPhoneNumberFormate(value, prevState.phone),
    }));
  }

  handleEmailChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ email: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }

  handleAdminEmailChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ adminEmail: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }

  handlePasswordChange(e) {
    this.setState({
      password: e.target.value,
    });
  }
  handleFnameChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 128);

    if (res.length < 128) {
      this.setState({ fname: res });
    } else {
      Utils.maxFieldLength(128);
    }
  }

  handleLnameChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 128);

    if (res.length < 128) {
      this.setState({ lname: res });
    } else {
      Utils.maxFieldLength(128);
    }
  }
  gotToButton() {
    document.getElementById('loginButton').focus();
  }
  gotToPassword() {
    document.getElementById('password').focus();
  }

  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  handleFaxChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ fax: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }
  handleStatusChange(e) {
    this.setState({ isStatus: !this.state.isStatus });
  }
  handleAddressChange(address) {
    this.setState({ address: address });
  }

  createCancel() {
    this.props.history.push({
      pathname: '/paidAdvertiserList',
    });
  }
  viewCancel() {
    store.dispatch(paidAdvertiserDetailsAction.setPaidAdvertiser());
    this.props.history.push({
      pathname: '/paidAdvertiserList',
    });
  }

  editCancel() {
    let adminFccPageState = {
      id: this.state.id,
      action: 'view',
      title: 'Advertiser Details',
      pageId: 'paidAdvertiserList',
    };
    store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
    this.props.history.push({
      pathname: '/viewAdvertiser',
      state: {
        action: 'view',
        title: 'Advertiser Details',
        advertiserId: this.state.id,
      },
    });
  }

  addPaidAdvertiserToBackend(e) {
    e.preventDefault();
    let mobileNumber = this.state.phone.replace(/[^\d]/g, '');
    const postObject = {
      header: this.header,

      paidAdvertiser: {
        email: this.state.email,
        name: this.state.name,
        website: this.state.website,
        fax: this.state.fax,
        phone: mobileNumber,

        address: {
          line: this.addressRef.current.state.line,
          state: {
            name: this.addressRef.current.state.state.name,
            code: this.addressRef.current.state.state.code,

            id: this.addressRef.current.state.state.id,
            country: this.addressRef.current.state.country,
          },
          city: this.addressRef.current.state.city,
          zipCode: this.addressRef.current.state.zipCode,
        },
        userList: [
          {
            email: this.state.adminEmail,
            firstName: this.state.fname,
            lastName: this.state.lname,
            credential: this.state.password,
          },
        ],
      },
    };
    FetchServerData.callPostService('/paidadvertiserms/create', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        // let paidAdvertiserDetails = output.data;
        PtsAlert.success(output.message);
        this.props.history.push({
          pathname: '/paidAdvertiserList',
        });
      } else {
      }
    });
  }

  updateAdvertiserInBackend(e) {
    e.preventDefault();
    let mobileNumber = this.state.phone.replace(/[^\d]/g, '');
    let advertiserStatus;
    if (this.state.isStatus === true) {
      advertiserStatus = 'Active';
    } else {
      advertiserStatus = 'Inactive';
    }
    const postObject = {
      header: this.header,

      paidAdvertiser: {
        id: this.state.id,
        email: this.state.email,
        name: this.state.name,
        website: this.state.website,
        fax: this.state.fax,
        phone: mobileNumber,
        status: {
          status: advertiserStatus,
        },
        address: {
          id: this.addressRef.current.state.id,
          line: this.addressRef.current.state.line,
          state: {
            name: this.addressRef.current.state.state.name,
            code: this.addressRef.current.state.state.code,

            id: this.addressRef.current.state.state.id,
            country: this.addressRef.current.state.country,
          },
          city: this.addressRef.current.state.city,
          zipCode: this.addressRef.current.state.zipCode,
        },
        userList: [
          {
            email: this.state.adminEmail,
            firstName: this.state.fname,
            lastName: this.state.lname,
            credential: this.state.password,
          },
        ],
      },
    };
    FetchServerData.callPostService('/paidadvertiserms/update', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let paidAdvertiserDetails = output.data.paidAdvertiser;

        PtsAlert.success(output.message);
        let adminFccPageState = {
          id: this.state.id,
          action: 'view',
          title: 'Advertiser Details',
          pageId: 'paidAdvertiserList',
        };
        store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
        this.props.history.push({
          pathname: '/viewAdvertiser',
          state: {
            action: 'view',
            title: 'Advertiser Details',
            advertiserId: paidAdvertiserDetails.id,
          },
        });
      } else {
      }
    });
  }

  editSponsorToBackend(e) {
    let adminFccPageState = {
      action: 'edit',
      title: 'Advertiser Details',
      id: this.state.id,
      pageId: 'paidAdvertiserList',
    };
    store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
    this.props.history.push({
      pathname: '/editAdvertiser',
      state: {
        action: 'edit',
        title: 'Advertiser Details',
        advertiserId: this.state.id,
      },
    });
  }

  deleteSponsorToBackend = () => {
    const postObject = {
      header: this.header,
      paidAdvertiser: {
        id: this.state.id,
      },
    };

    FetchServerData.callPostService('/paidadvertiserms/delete', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          this.props.history.push({
            pathname: '/paidAdvertiserList',
          });
        } else {
        }
      })
      .catch((error) => {});
  };
  render() {
    if (this.state.action === 'create') {
      return this.createPaidAdvertiser();
    }
    if (this.state.action === 'edit') {
      return this.editPaidAdvertiser();
    } else if (this.state.action === 'view') {
      return this.viewPaidAdvertiser();
    }
  }

  createPaidAdvertiser() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <AdminMenuWithBreadScrum
            breadCrumb={this.state.breadCrumb}
            history={this.props.history}
            name={'Create Paid Advertiser'}
            domainObject="Paid Advertiser"
          />
          <ValidatorForm style={{ width: '100%' }} ref="form" onSubmit={this.addPaidAdvertiserToBackend.bind(this)}>
            <Row style={{ position: 'relative', margin: '1em' }}>
              <Col md={6} style={{ position: 'relative', width: '550px' }}>
                <Card className="formCard">
                  <CardHeader className="cardHeader">
                    <Row>
                      <Col md={9}>
                        {/* {undefined !== this.state.title &&
                          this.state.title.length > 0 && ( */}
                        <Label className="cardHeaderTitle">Paid Advertiser Details</Label>
                        {/* )} */}
                      </Col>
                    </Row>
                  </CardHeader>{' '}
                  <CardBody>
                    <Row>
                      <Col md={12}>
                        <TextField
                          margin="normal"
                          variant="outlined"
                          required
                          fullWidth
                          id="name"
                          type="text"
                          onChange={this.handleNameChange.bind(this)}
                          label="Name"
                          name="lname"
                          inputProps={{
                            maxLength: 128,
                            style: { textTransform: 'capitalize' },
                          }}
                          value={this.state.name}
                        />
                      </Col>
                    </Row>{' '}
                    <Row>
                      <Col md={12}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          id="website"
                          label="Website"
                          inputProps={{ maxLength: 64 }}
                          value={this.state.website}
                          onChange={this.handleWebsiteChange.bind(this)}
                          name="website"
                          type="text"
                          margin="normal"
                          className="textField"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <TextField
                          required
                          margin="normal"
                          variant="outlined"
                          fullWidth
                          type="tel"
                          id="phone"
                          onChange={this.handlePhoneChange.bind(this)}
                          label="Phone"
                          name="phone"
                          // inputProps={{ maxLength: 10 }}
                          value={this.state.phone}
                          pattern="^-?[0-9]\d*\.?\d*$"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        {/* <TextField
                          required
                          margin="normal"
                          variant="outlined"
                          type="email"
                          fullWidth
                          id="email"
                          inputProps={{ maxLength: 64 }}
                          onChange={this.handleEmailChange.bind(this)}
                          label="Email"
                          name="email"
                          value={this.state.email}
                        /> */}
                        <TextValidator
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="email"
                          value={this.state.email}
                          label="Email "
                          name="email"
                          type="text"
                          autoComplete="email"
                          style={{ width: '100%' }}
                          validators={['required', 'isEmail']}
                          errorMessages={['this field is required', 'email is not valid']}
                          onChange={this.handleEmailChange.bind(this)}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          id={'fax' + this.state.index}
                          label="Fax"
                          value={this.state.fax}
                          onChange={this.handleFaxChange.bind(this)}
                          name="fax"
                          inputProps={{ maxLength: 64 }}
                          type="text"
                          margin="normal"
                          className="textField"
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={6}>
                <Card className="formCard">
                  <CardHeader className="cardHeader">
                    <Row>
                      <Col md={12}>
                        <Label className="cardHeaderTitle">Location</Label>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <AdminAddress
                      ref={this.addressRef}
                      onChange={this.handleAddressChange.bind(this)}
                      data={this.state.address}
                      action="create"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row style={{ position: 'relative', margin: '1em', marginLeft: '24px', marginRight: '24px' }}>
              <Col md={12} style={{ position: 'relative', padding: '5px' }}>
                <Card className="formCard">
                  <CardHeader className="cardHeader">
                    <Row>
                      <Col md={12}>
                        <Label className="cardHeaderTitle">Paid Advertiser User</Label>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={6}>
                        <TextField
                          required
                          margin="normal"
                          variant="outlined"
                          fullWidth
                          id="fname"
                          inputProps={{ maxLength: 64 }}
                          onChange={this.handleFnameChange.bind(this)}
                          label="First Name"
                          name="fname"
                          value={this.state.fname}
                        />
                      </Col>
                      <Col md={6}>
                        <TextField
                          required
                          margin="normal"
                          variant="outlined"
                          type="lname"
                          fullWidth
                          id="lname"
                          inputProps={{ maxLength: 64 }}
                          onChange={this.handleLnameChange.bind(this)}
                          label="Last Name"
                          name="lname"
                          value={this.state.lname}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        {/* <TextField
                          required
                          margin="normal"
                          variant="outlined"
                          type="adminEmail"
                          fullWidth
                          id="adminEmail"
                          inputProps={{ maxLength: 64 }}
                          onChange={this.handleAdminEmailChange.bind(this)}
                          label="Email"
                          name="adminEmail"
                          value={this.state.adminEmail}
                        /> */}
                        <TextValidator
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="adminEmail"
                          value={this.state.adminEmail}
                          label="Email "
                          name="adminEmail"
                          type="text"
                          autoComplete="email"
                          style={{ width: '100%' }}
                          validators={['required', 'isEmail']}
                          errorMessages={['this field is required', 'email is not valid']}
                          onChange={this.handleAdminEmailChange.bind(this)}
                        />
                      </Col>
                      <Col md={6}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          value={this.state.password}
                          name="password"
                          label="Password"
                          type={this.state.showPassword ? 'text' : 'password'}
                          id="password"
                          autoComplete="current-password"
                          onChange={this.handlePasswordChange.bind(this)}
                          InputProps={{
                            // <-- This is where the toggle button is added.
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowPassword.bind(this)}
                                >
                                  {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* </CardBody> */}

            <div style={{ textAlign: 'center', padding: '0em 0em 1em' }}>
              <CreateButton id="loginButton" type="submit">
                Create
              </CreateButton>
              &nbsp;
              <CancelIconButton onClick={(e) => this.createCancel()} />
            </div>
          </ValidatorForm>
        </>
      );
    }
  }

  viewPaidAdvertiser() {
    if (this.state.render === false) {
      return <div />;
    } else {
      if (this.state.breadCrumb) {
        Utils.getAdminPanelActiveMenu(this.state.breadCrumb);
        store.dispatch(BreadCrumbDetailsAction.setBreadCrumbDetails(this.state.breadCrumb));
      }
      return (
        <>
          <AdminMenuWithBreadScrum
            deleteCallback={this.deleteSponsorToBackend.bind(this)}
            editCallback={this.editSponsorToBackend.bind(this)}
            getDeletePermission={this.state.getDeletePermission}
            getUpdatePermission={this.state.getUpdatePermission}
            breadCrumb={this.state.breadCrumb}
            history={this.props.history}
            name={'Paid Advertiser Home'}
            domainObject="Paid Advertiser"
          />

          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={6} className="sponsorDetailColumn" style={{ position: 'relative', padding: '5px' }}>
              <Card style={{ height: '100%' }}>
                <>
                  <CardHeader className="cardHeader">
                    <Row>
                      <Col md={10}>
                        <Label className="cardHeaderTitle">Paid Advertiser Details</Label>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <div style={{ padding: '0em 0em 0em 2em' }}>
                        <h3
                          style={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {this.state.name}
                        </h3>
                      </div>
                    </Row>
                    {this.state.phone ? (
                      <Row style={{ padding: '3px', marginLeft: '0em' }}>
                        <Col md={1}>
                          <Tooltip title="Phone" aria-label="add">
                            <PhoneIcon id="ptsIcon" />
                          </Tooltip>
                        </Col>
                        <Col md={11}>
                          <a href={'tel:' + this.state.phone} target="_top">
                            {Utils.displayUsaPhoneNumberFormate(this.state.phone)}
                          </a>
                        </Col>
                      </Row>
                    ) : (
                      <span style={{ display: 'none' }}></span>
                    )}
                    {this.state.email ? (
                      <Row style={{ padding: '3px', marginLeft: '0em' }}>
                        <Col md={1}>
                          <Tooltip title="Email" aria-label="add">
                            <EmailIcon id="ptsIcon" />
                          </Tooltip>
                        </Col>
                        <Col md={11}>
                          <a href={'mailto:' + this.state.email} target="_top">
                            {this.state.email}
                          </a>
                        </Col>
                      </Row>
                    ) : (
                      <span style={{ display: 'none' }}></span>
                    )}

                    {this.state.fax ? (
                      <Row style={{ padding: '3px', marginLeft: '0em' }}>
                        <Col md={1}>
                          <Tooltip title="Fax" aria-label="add">
                            <i id="ptsIcon" className="fa fa-fax" aria-hidden="true" style={{ fontSize: '1.5em' }} />
                          </Tooltip>
                        </Col>
                        <Col md={11}>{this.state.fax}</Col>
                      </Row>
                    ) : (
                      <span style={{ display: 'none' }}></span>
                    )}

                    {this.state.website ? (
                      <Row style={{ padding: '3px', marginLeft: '0em' }}>
                        <Col md={1}>
                          <Tooltip title="Website" aria-label="add">
                            <LanguageIcon style={{ fontSize: 'large', fontSize: '1.65em' }} />
                          </Tooltip>
                        </Col>
                        <Col md={11}>
                          <a
                            href={
                              this.state.website.includes('https://') || this.state.website.includes('http://')
                                ? this.state.website
                                : 'http://' + this.state.website
                            }
                            target="blank"
                          >
                            {/* <a href={'http://' + this.state.website} target="blank"> */}
                            {this.state.website}
                          </a>
                        </Col>
                      </Row>
                    ) : (
                      <span style={{ display: 'none' }}></span>
                    )}
                    <Row style={{ marginTop: '6px' }}>
                      <Col md={1}>
                        <label style={{ paddingRight: '0px' }}> Status:</label>
                      </Col>
                      <Col md={11} style={{ textAlign: 'left', paddingLeft: '30px' }}>
                        {' '}
                        <span style={{ color: '#000' }}>{this.state.userStatus}</span>
                      </Col>
                    </Row>
                    {this.state.userList.map((user) => (
                      <>
                        <Row style={{ marginTop: '4px' }}>
                          <Col md={12}>
                            <label style={{ paddingRight: '0px' }}>Sponsor User:</label>{' '}
                            <span style={{ color: '#000', paddingLeft: '5px' }}>
                              {user.firstName} {user.lastName}
                            </span>
                          </Col>
                        </Row>

                        <Row style={{ marginTop: '4px' }}>
                          <Col md={12}>
                            <label style={{ paddingRight: '0px' }}>User email:</label>{' '}
                            {/* <span style={{ color: '#000', paddingLeft: "5px" }}>{user.email}</span> */}
                            <a href={'mailto:' + user.email} target="_top" style={{ paddingLeft: '5px' }}>
                              {user.email}
                            </a>
                          </Col>
                        </Row>
                      </>
                    ))}
                  </CardBody>
                </>
              </Card>
            </Col>
            <Col md={6} className="sponsorDetailColumn">
              <Card style={{ height: '100%' }}>
                <CardHeader className="cardHeader">
                  <Row>
                    <Col md={10}>
                      <Label className="cardHeaderTitle">Location</Label>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={1}>
                      <Tooltip title="Location" aria-label="add">
                        <PinDropIcon id="ptsIcon" />
                      </Tooltip>
                    </Col>
                    <Col md={11} style={{ padding: '0em 0em 0em 1em' }}>
                      <Row>{Utils.getAddressAsText(this.state.address)}</Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <div className="sponsorDetailFooter">
            {this.userDetails.isUserLogin === 'paidAdvertiser' ? (
              <></>
            ) : (
              <BackButton
                type="button"
                style={{ backgroundColor: '#162c50', color: '#fff' }}
                variant="contained"
                onClick={(e) => this.viewCancel()}
              >
                Back
              </BackButton>
            )}
          </div>
        </>
      );
    }
  }

  editPaidAdvertiser() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <AdminMenuWithBreadScrum
            breadCrumb={this.state.breadCrumb}
            history={this.props.history}
            name={'Create Paid Advertiser'}
            domainObject="Paid Advertiser"
          />
          <ValidatorForm style={{ width: '100%' }} ref="form" onSubmit={this.updateAdvertiserInBackend.bind(this)}>
            <Row style={{ position: 'relative', margin: '1em' }}>
              <Col md={6} style={{ position: 'relative' }}>
                <Card className="formCard">
                  <CardHeader className="cardHeader">
                    <Row>
                      <Col md={9}>
                        {/* {undefined !== this.state.title &&
                          this.state.title.length > 0 && ( */}
                        <Label className="cardHeaderTitle">Paid Advertiser Info</Label>
                        {/* )} */}
                      </Col>
                    </Row>
                  </CardHeader>{' '}
                  <CardBody>
                    <Row>
                      <Col md={12}>
                        <TextField
                          margin="normal"
                          variant="outlined"
                          required
                          fullWidth
                          id="name"
                          type="text"
                          onChange={this.handleNameChange.bind(this)}
                          label="Name"
                          name="lname"
                          inputProps={{
                            maxLength: 128,
                            style: { textTransform: 'capitalize' },
                          }}
                          value={this.state.name}
                        />
                      </Col>
                    </Row>{' '}
                    <Row>
                      <Col md={12}>
                        <TextField
                          fullWidth
                          variant="outlined"
                          id="website"
                          label="Website"
                          inputProps={{ maxLength: 64 }}
                          value={this.state.website}
                          onChange={this.handleWebsiteChange.bind(this)}
                          name="website"
                          type="text"
                          margin="normal"
                          className="textField"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        <TextField
                          required
                          margin="normal"
                          variant="outlined"
                          fullWidth
                          type="tel"
                          id="phone"
                          onChange={this.handlePhoneChange.bind(this)}
                          label="Phone"
                          name="phone"
                          // inputProps={{ maxLength: 10 }}
                          value={this.state.phone}
                          pattern="^-?[0-9]\d*\.?\d*$"
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        {/* <TextField
                          required
                          margin="normal"
                          variant="outlined"
                          type="email"
                          fullWidth
                          id="email"
                          inputProps={{ maxLength: 64 }}
                          onChange={this.handleEmailChange.bind(this)}
                          label="Email"
                          name="email"
                          value={this.state.email}
                        /> */}
                      </Col>
                    </Row>
                    <Row>
                      <Col md={12}>
                        {/* <TextField
                          fullWidth
                          variant="outlined"
                          id={'fax' + this.state.index}
                          label="Fax"
                          value={this.state.fax}
                          onChange={this.handleFaxChange.bind(this)}
                          name="fax"
                          inputProps={{ maxLength: 64 }}
                          type="text"
                          margin="normal"
                          className="textField"
                        /> */}
                        <TextValidator
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="email"
                          value={this.state.email}
                          label="Email "
                          name="email"
                          type="text"
                          autoComplete="email"
                          style={{ width: '100%' }}
                          validators={['required', 'isEmail']}
                          errorMessages={['this field is required', 'email is not valid']}
                          onChange={this.handleEmailChange.bind(this)}
                        />
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '8px' }}>
                      <Col md={1}>
                        <label style={{ paddingRight: '0px' }}> Status:</label>
                      </Col>
                      <Col md={11} style={{ textAlign: 'left', paddingLeft: '30px' }}>
                        {' '}
                        <span style={{ color: '#000' }}>{this.state.userStatus}</span>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col md={6}>
                <Card className="formCard">
                  <CardHeader className="cardHeader">
                    <Row>
                      <Col md={12}>
                        <Label className="cardHeaderTitle">Location</Label>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <AdminAddress
                      ref={this.addressRef}
                      onChange={this.handleAddressChange.bind(this)}
                      data={this.state.address}
                      action="edit"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
            <Row style={{ position: 'relative', margin: '1em', marginLeft: '24px', marginRight: '24px' }}>
              <Col md={12} style={{ position: 'relative', padding: '5px' }}>
                <Card className="formCard">
                  <CardHeader className="cardHeader">
                    <Row>
                      <Col md={12}>
                        <Label className="cardHeaderTitle">Paid Advertiser User</Label>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col md={6}>
                        <TextField
                          required
                          margin="normal"
                          variant="outlined"
                          fullWidth
                          id="fname"
                          inputProps={{ maxLength: 64 }}
                          onChange={this.handleFnameChange.bind(this)}
                          label="First Name"
                          name="fname"
                          value={this.state.fname}
                        />
                      </Col>
                      <Col md={6}>
                        <TextField
                          required
                          margin="normal"
                          variant="outlined"
                          type="lname"
                          fullWidth
                          id="lname"
                          inputProps={{ maxLength: 64 }}
                          onChange={this.handleLnameChange.bind(this)}
                          label="Last Name"
                          name="lname"
                          value={this.state.lname}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md={6}>
                        {/* <TextField
                          required
                          margin="normal"
                          variant="outlined"
                          type="adminEmail"
                          fullWidth
                          id="adminEmail"
                          inputProps={{ maxLength: 64 }}
                          onChange={this.handleAdminEmailChange.bind(this)}
                          label="Email"
                          name="adminEmail"
                          value={this.state.adminEmail}
                        /> */}
                        <TextValidator
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          id="adminEmail"
                          value={this.state.adminEmail}
                          label="Email "
                          name="adminEmail"
                          type="text"
                          autoComplete="email"
                          style={{ width: '100%' }}
                          validators={['required', 'isEmail']}
                          errorMessages={['this field is required', 'email is not valid']}
                          onChange={this.handleAdminEmailChange.bind(this)}
                        />
                      </Col>
                      <Col md={6}>
                        <TextField
                          variant="outlined"
                          margin="normal"
                          required
                          fullWidth
                          value={this.state.password}
                          name="password"
                          label="Password"
                          type={this.state.showPassword ? 'text' : 'password'}
                          id="password"
                          autoComplete="current-password"
                          onChange={this.handlePasswordChange.bind(this)}
                          InputProps={{
                            // <-- This is where the toggle button is added.
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={this.handleClickShowPassword.bind(this)}
                                >
                                  {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
            {/* </CardBody> */}

            <div style={{ textAlign: 'center', padding: '0em 0em 1em' }}>
              <SaveButton id="loginButton" type="submit">
                Save
              </SaveButton>
              &nbsp;
              <CancelIconButton onClick={(e) => this.editCancel()} />
            </div>
          </ValidatorForm>
        </>
      );
    }
  }
}

export default PaidAdvertiser;
