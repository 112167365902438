import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import { IconButton, InputAdornment } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import PinDropIcon from '@material-ui/icons/PinDrop';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import React, { Component } from 'react';
import { TextValidator, ValidatorForm } from 'react-material-ui-form-validator';
import { Card, CardBody, CardHeader, Col, Label, Row } from 'reactstrap';
import Autocomplete from '@material-ui/lab/Autocomplete';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import store from '../../../redux/store';
import BackButton from '../../components/buttons/BackButton';
import CancelIconButton from '../../components/buttons/CancelIconButton';
import CreateButton from '../../components/buttons/CreateButton';
import SaveButton from '../../components/buttons/SaveButton';
import PtsAlert from '../../components/ptsAlert/PtsAlert';
import AdminAddress from '../adminAddress/AdminAddress';
import adminFccPageStateAction from '../../../redux/adminPanel/adminFccPageState/adminFccPageStateAction';
import paidAdvertiserDetailsAction from '../../../redux/adminPanel/paidAdvertiserDetails/paidAdvertiserDetailsAction';
import AdminMenuWithBreadScrum from '../adminMenu/AdminMenuWithBreadScrum';
import ToggleButton from '@material-ui/lab/ToggleButton';
import LanguageIcon from '@material-ui/icons/Language';
class AdminUser extends Component {
  title = 'Paid Advertiser Details';
  addressRef = React.createRef();
  header = store.getState().adminHeader.adminHeader;
  adminUserDetails = store.getState().adminUserDetails.adminUserDetails;
  UserProfileList = store.getState().userProfile.UserProfileList;
  adminFccPageState = store.getState().adminFccPageState.adminFccPageState;
  adminBreadCrumb = [
    { pageid: '/apUserList', uiname: 'Admin User List' },
    { pageid: '', uiname: 'Admin User Details' },
  ];
  constructor(props) {
    super(props);
    let userId = props.userId;
    let action = props.action;
    let title = '';
    let pageId = '';
    if (!userId && props.history && props.history.location && props.history.location.state) {
      userId = props.history.location.state.userId;
      action = props.history.location.state.action;
      pageId = props.history.location.state.pageId;
      title = props.history.location.state.title;
    }
    let canRender = false;
    let breadCrumb = [];

    if (null !== userId && userId > 0) {
      this.getAdminUserDetails(userId);
    } else {
      canRender = true;
    }
    if (this.adminFccPageState) {
      pageId = this.adminFccPageState.pageId;
    }
    if (userId === undefined) {
      if (pageId === 'adminUserList') {
        userId = this.adminFccPageState.id;
        action = this.adminFccPageState.action;
        title = this.adminFccPageState.title;
      } else {
        userId = this.adminUserDetails.id;
        action = 'view';
      }
      if (action !== 'create') {
        this.getAdminUserDetails(userId);
      }
    }
    this.state = {
      id: userId,
      action: action,
      pageId: pageId,
      email: '',
      password: '',
      fname: '',
      lname: '',
      phone: '',
      userProfile: null,
      render: canRender,
      title: title,
      userStatus: {},
      isStatus: true,
      showPassword: false,
      breadCrumb: breadCrumb,
      render: false,
      getUpdatePermission: false,
      getDeletePermission: false,
    };
  }

  getAdminUserDetails(userId) {
    const postObject = {
      header: this.header,
      appuser: {
        id: userId,
      },
    };

    FetchServerData.callPostService('/adminpanelAppuserms/get', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let adminUserDetails = output.data.appuser;
          store.dispatch(paidAdvertiserDetailsAction.setPaidAdvertiser(adminUserDetails));
          let getPermission = Utils.getRolePermission(output.data.privilegeList);
          let getUpdatePermission = getPermission[3];
          let getDeletePermission = getPermission[4];
          let userStatus = '';
          if (userStatus === null) {
            userStatus = false;
          } else {
            if (adminUserDetails.userStatus?.status === 'Active') {
              userStatus = true;
            } else {
              userStatus = false;
            }
          }

          this.setState({
            render: true,
            id: adminUserDetails.id,
            email: adminUserDetails.email,
            phone: adminUserDetails.phone,
            fname: adminUserDetails.firstName,
            lname: adminUserDetails.lastName,
            userStatus: adminUserDetails.userStatus,
            userProfile: adminUserDetails.profile,
            isStatus: userStatus,
            getUpdatePermission: getUpdatePermission,
            getDeletePermission: getDeletePermission,
          });
        } else {
        }
      })
      .catch((error) => {});
  }

  handleAdminEmailChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 64);
    if (res.length < 64) {
      this.setState({ email: res });
    } else {
      Utils.maxFieldLength(64);
    }
  }
  handlePhoneChange({ target: { value } }) {
    this.setState((prevState) => ({
      phone: Utils.convertPhoneNumberToUsaPhoneNumberFormate(value, prevState.phone),
    }));
  }

  handlePasswordChange(e) {
    this.setState({
      password: e.target.value,
    });
  }
  handleFnameChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 128);

    if (res.length < 128) {
      this.setState({ fname: res });
    } else {
      Utils.maxFieldLength(128);
    }
  }

  handleLnameChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 128);

    if (res.length < 128) {
      this.setState({ lname: res });
    } else {
      Utils.maxFieldLength(128);
    }
  }

  handleClickShowPassword() {
    this.setState({ showPassword: !this.state.showPassword });
  }

  handleStatusChange(e) {
    this.setState({ isStatus: !this.state.isStatus });
  }

  handleUserProfileChange(selectedValue) {
    this.setState({ userProfile: selectedValue });
  }

  createCancel() {
    this.props.history.push({
      pathname: '/apUserList',
    });
  }
  viewCancel() {
    store.dispatch(paidAdvertiserDetailsAction.setPaidAdvertiser());
    this.props.history.push({
      pathname: '/apUserList',
    });
  }

  editCancel() {
    let adminFccPageState = {
      id: this.state.id,
      action: 'view',
      title: 'Admin User Details',
      pageId: 'adminUserList',
    };
    store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
    this.props.history.push({
      pathname: '/viewAdminUser',
      state: {
        action: 'view',
        title: 'Admin User Details',
        userId: this.state.id,
      },
    });
  }

  addAdminUserToBackend(e) {
    let mobileNumber = this.state.phone.replace(/[^\d]/g, '');
    e.preventDefault();
    const postObject = {
      header: this.header,
      appuser: {
        firstName: this.state.fname,
        lastName: this.state.lname,
        email: this.state.email,
        phone: mobileNumber,
        credential: this.state.password,
        profile: {
          id: this.state.userProfile.id,
        },
      },
    };
    FetchServerData.callPostService('/adminpanelAppuserms/create', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let adminUserDetails = output.data.appuser;
        PtsAlert.success(output.message);
        let adminFccPageState = {
          id: this.state.id,
          action: 'view',
          title: 'Admin User Details',
          pageId: 'adminUserList',
        };
        store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
        this.props.history.push({
          pathname: '/viewAdminUser',
          state: {
            action: 'view',
            title: 'Admin User Details',
            userId: adminUserDetails.id,
          },
        });
      } else {
      }
    });
  }

  editUserProfileToBackend(e) {
    let adminFccPageState = {
      action: 'edit',
      title: 'Admin User Details',
      id: this.state.id,
      pageId: 'editUserList',
    };
    store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
    this.props.history.push({
      pathname: '/editAdminUser',
      state: {
        action: 'edit',
        title: 'Admin User Details',
        userId: this.state.id,
      },
    });
  }
  updateAdminUserToBackend(e) {
    e.preventDefault();
    let mobileNumber = this.state.phone.replace(/[^\d]/g, '');
    let userStatus;
    if (this.state.isStatus === true) {
      userStatus = 'Active';
    } else {
      userStatus = 'InActive';
    }
    const postObject = {
      header: this.header,
      appuser: {
        id: this.state.id,
        firstName: this.state.fname,
        lastName: this.state.lname,
        email: this.state.email,
        phone: mobileNumber,
        userStatus: {
          status: userStatus,
        },
        profile: {
          id: this.state.userProfile.id,
        },
      },
    };
    FetchServerData.callPostService('/adminpanelAppuserms/update', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let adminUserDetails = output.data;
        PtsAlert.success(output.message);
        let adminFccPageState = {
          id: this.state.id,
          action: 'view',
          title: 'Admin User Details',
          pageId: 'adminUserList',
        };
        store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
        this.props.history.push({
          pathname: '/viewAdminUser',
          state: {
            action: 'view',
            title: 'Admin User Details',
            userId: adminUserDetails.id,
          },
        });
      } else {
      }
    });
  }
  deleteUserProfileToBackend = () => {
    const postObject = {
      header: this.header,
      appuser: {
        id: this.state.id,
      },
    };

    FetchServerData.callPostService('/adminpanelAppuserms/delete', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          this.props.history.push({
            pathname: '/apUserList',
          });
        } else {
        }
      })
      .catch((error) => {});
  };
  render() {
    if (this.state.action === 'create') {
      return this.createAdminUser();
    } else if (this.state.action === 'edit') {
      return this.editAdminUser();
    } else if (this.state.action === 'view') {
      return this.viewAdminUser();
    }
  }

  createAdminUser() {
    return (
      <>
        <AdminMenuWithBreadScrum
          breadCrumb={this.state.breadCrumb}
          history={this.props.history}
          name={'Create Admin User'}
          domainObject="Admin User"
        />
        <ValidatorForm style={{ width: '100%' }} ref="form" onSubmit={this.addAdminUserToBackend.bind(this)}>
          <Row style={{ position: 'relative', margin: '1em', marginLeft: '24px', marginRight: '24px' }}>
            <Col md={12} style={{ position: 'relative', padding: '5px' }}>
              <Card className="formCard">
                <CardHeader className="cardHeader">
                  <Row>
                    <Col md={12}>
                      <Label className="cardHeaderTitle">Create Admin User</Label>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <TextField
                        required
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        id="fname"
                        inputProps={{ maxLength: 64 }}
                        onChange={this.handleFnameChange.bind(this)}
                        label="First Name"
                        name="fname"
                        value={this.state.fname}
                      />
                    </Col>
                    <Col md={6}>
                      <TextField
                        required
                        margin="normal"
                        variant="outlined"
                        type="lname"
                        fullWidth
                        id="lname"
                        inputProps={{ maxLength: 64 }}
                        onChange={this.handleLnameChange.bind(this)}
                        label="Last Name"
                        name="lname"
                        value={this.state.lname}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <TextValidator
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        value={this.state.email}
                        label="Email "
                        name="email"
                        type="email"
                        autoComplete="email"
                        style={{ width: '100%' }}
                        validators={['required', 'isEmail']}
                        errorMessages={['this field is required', 'email is not valid']}
                        onChange={this.handleAdminEmailChange.bind(this)}
                      />
                    </Col>
                    <Col md={6} style={{ marginTop: '16px' }}>
                      <Autocomplete
                        size="small"
                        fullWidth
                        id="appUserRoleList"
                        options={this.UserProfileList}
                        value={this.state.userProfile}
                        defaultValue={this.state.userProfile}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          this.handleUserProfileChange(value);
                        }}
                        inputprops={{ style: { textTransform: 'capitalize' } }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Admin User Profile"
                            variant="outlined"
                            validators={['required']}
                            errormessages={['This field is required']}
                            required
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <TextField
                        required
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        type="tel"
                        id="phone"
                        onChange={this.handlePhoneChange.bind(this)}
                        label="Phone"
                        name="phone"
                        // inputProps={{ maxLength: 10 }}
                        value={this.state.phone}
                        pattern="^-?[0-9]\d*\.?\d*$"
                      />
                    </Col>
                    <Col md={6}>
                      <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        value={this.state.password}
                        name="password"
                        label="Password"
                        type={this.state.showPassword ? 'text' : 'password'}
                        id="password"
                        autoComplete="current-password"
                        onChange={this.handlePasswordChange.bind(this)}
                        InputProps={{
                          // <-- This is where the toggle button is added.
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={this.handleClickShowPassword.bind(this)}
                              >
                                {this.state.showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div style={{ textAlign: 'center', padding: '0em 0em 1em' }}>
            <CreateButton id="loginButton" type="submit">
              Create
            </CreateButton>
            &nbsp;
            <CancelIconButton onClick={(e) => this.createCancel()} />
          </div>
        </ValidatorForm>
      </>
    );
  }

  viewAdminUser() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <AdminMenuWithBreadScrum
            deleteCallback={this.deleteUserProfileToBackend.bind(this)}
            editCallback={this.editUserProfileToBackend.bind(this)}
            getUpdatePermission={this.state.getUpdatePermission}
            getDeletePermission={this.state.getDeletePermission}
            breadCrumb={this.state.breadCrumb}
            history={this.props.history}
            // name={'Admin User'}
            domainObject="Admin User"
          />

          <Row style={{ position: 'relative', margin: '1em' }}>
            <Col md={12} className="sponsorDetailColumn" style={{ position: 'relative', padding: '5px' }}>
              <Card style={{ height: '100%' }}>
                <>
                  <CardHeader className="cardHeader">
                    <Row>
                      <Col md={10}>
                        <Label className="cardHeaderTitle">Admin User Details</Label>
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <div style={{ padding: '0em 0em 0em 2em' }}>
                        <h3
                          style={{
                            textTransform: 'capitalize',
                          }}
                        >
                          {this.state.fname + ' ' + this.state.lname}
                        </h3>
                      </div>
                    </Row>

                    {this.state.phone ? (
                      <Row style={{ padding: '3px', marginLeft: '0em' }}>
                        <Col md={1}>
                          <Tooltip title="Phone" aria-label="add">
                            <PhoneIcon id="ptsIcon" />
                          </Tooltip>
                        </Col>
                        <Col md={11}>
                          <a href={'tel:' + this.state.phone} target="_top">
                            {Utils.displayUsaPhoneNumberFormate(this.state.phone)}
                          </a>
                        </Col>
                      </Row>
                    ) : (
                      <span style={{ display: 'none' }}></span>
                    )}
                    {this.state.email ? (
                      <Row style={{ padding: '3px', marginLeft: '0em' }}>
                        <Col md={1}>
                          <Tooltip title="Email" aria-label="add">
                            <EmailIcon id="ptsIcon" />
                          </Tooltip>
                        </Col>
                        <Col md={11}>
                          <a href={'mailto:' + this.state.email} target="_top">
                            {this.state.email}
                          </a>
                        </Col>
                      </Row>
                    ) : (
                      <span style={{ display: 'none' }}></span>
                    )}

                    <Row style={{ marginTop: '6px' }}>
                      <Col md={1}>
                        <label style={{ paddingRight: '0px' }}> Status:</label>
                      </Col>
                      <Col md={11} style={{ textAlign: 'left', paddingLeft: '30px' }}>
                        {' '}
                        <span style={{ color: '#000' }}>{this.state.userStatus?.status}</span>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: '6px' }}>
                      <Col md={1}>
                        <label style={{ paddingRight: '0px' }}> Profile:</label>
                      </Col>
                      <Col md={11} style={{ textAlign: 'left', paddingLeft: '30px' }}>
                        {' '}
                        <span style={{ color: '#000' }}>{this.state.userProfile?.name}</span>
                      </Col>
                    </Row>
                  </CardBody>
                </>
              </Card>
            </Col>
          </Row>
          <div className="sponsorDetailFooter">
            <BackButton
              type="button"
              style={{ backgroundColor: '#162c50', color: '#fff' }}
              variant="contained"
              onClick={(e) => this.viewCancel()}
            >
              Back
            </BackButton>
          </div>
        </>
      );
    }
  }

  editAdminUser() {
    return (
      <>
        <AdminMenuWithBreadScrum
          breadCrumb={this.state.breadCrumb}
          history={this.props.history}
          name={'Edit Admin User'}
          domainObject="Admin User"
        />
        <ValidatorForm style={{ width: '100%' }} ref="form" onSubmit={this.updateAdminUserToBackend.bind(this)}>
          <Row style={{ position: 'relative', margin: '1em', marginLeft: '24px', marginRight: '24px' }}>
            <Col md={12} style={{ position: 'relative', padding: '5px' }}>
              <Card className="formCard">
                <CardHeader className="cardHeader">
                  <Row>
                    <Col md={12}>
                      <Label className="cardHeaderTitle">Edit Admin User</Label>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <Row>
                    <Col md={6}>
                      <TextField
                        required
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        id="fname"
                        inputProps={{ maxLength: 64 }}
                        onChange={this.handleFnameChange.bind(this)}
                        label="First Name"
                        name="fname"
                        value={this.state.fname}
                      />
                    </Col>
                    <Col md={6}>
                      <TextField
                        required
                        margin="normal"
                        variant="outlined"
                        type="lname"
                        fullWidth
                        id="lname"
                        inputProps={{ maxLength: 64 }}
                        onChange={this.handleLnameChange.bind(this)}
                        label="Last Name"
                        name="lname"
                        value={this.state.lname}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <TextValidator
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        value={this.state.email}
                        label="Email "
                        name="email"
                        type="email"
                        autoComplete="email"
                        style={{ width: '100%' }}
                        validators={['required', 'isEmail']}
                        errorMessages={['this field is required', 'email is not valid']}
                        onChange={this.handleAdminEmailChange.bind(this)}
                      />
                    </Col>
                    <Col md={6} style={{ marginTop: '16px' }}>
                      <Autocomplete
                        size="small"
                        fullWidth
                        id="appUserRoleList"
                        options={this.UserProfileList}
                        value={this.state.userProfile}
                        defaultValue={this.state.userProfile}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, value) => {
                          this.handleUserProfileChange(value);
                        }}
                        inputprops={{ style: { textTransform: 'capitalize' } }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            placeholder="Admin User Profile"
                            variant="outlined"
                            validators={['required']}
                            errormessages={['This field is required']}
                            required
                          />
                        )}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={6}>
                      <TextField
                        required
                        margin="normal"
                        variant="outlined"
                        fullWidth
                        type="tel"
                        id="phone"
                        onChange={this.handlePhoneChange.bind(this)}
                        label="Phone"
                        name="phone"
                        // inputProps={{ maxLength: 10 }}
                        value={this.state.phone}
                        pattern="^-?[0-9]\d*\.?\d*$"
                      />
                    </Col>
                    <Col md={6} style={{ marginTop: '16px' }}>
                      <label className="form-label " htmlFor="collapsible-email">
                        Status
                      </label>
                      <Tooltip title="Please click 'Click’ to change Status">
                        <span className="ms-4">
                          <ToggleButton
                            value={this.state.isStatus ? 'Active' : 'InActive'}
                            className="ToggleButtonActive"
                            onChange={this.handleStatusChange.bind(this)}
                            style={
                              this.state.isStatus
                                ? {
                                    background: 'rgb(0, 84, 118)',
                                    color: '#fff',
                                    height: '33px',
                                    width: '80px',
                                    borderRadius: '5px',
                                    textTransform: 'none',
                                  }
                                : {
                                    background: '#D7DBDD',
                                    color: '#000',
                                    height: '33px',
                                    width: '80px',
                                    borderRadius: '5px',
                                    textTransform: 'none',
                                  }
                            }
                          >
                            {this.state.isStatus ? 'Active' : 'InActive'}
                          </ToggleButton>
                        </span>
                      </Tooltip>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <div style={{ textAlign: 'center', padding: '0em 0em 1em' }}>
            <CreateButton id="loginButton" type="submit">
              Create
            </CreateButton>
            &nbsp;
            <CancelIconButton onClick={(e) => this.editCancel()} />
          </div>
        </ValidatorForm>
      </>
    );
  }
}

export default AdminUser;
