import React, { Component } from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import AdminFooter from '../../pts/adminPanel/adminFooters/AdminFooter';
import {Page, PageAlert, PageContent } from '../../pts';
import ContextProviders from '../../pts/components/utilities/ContextProviders';
import handleKeyAccessibility, { handleClickAccessibility } from '../../pts/helpers/handleTabAccessibility';
import routes from '../../views';
// import './Admin.scss';
import fccLogo from '../../assets/images/fccLogo.png';
import AdminNavbar from '../../pts/adminPanel/adminNavbar/AdminNavbar';
import PaidAdvertiserSidebar from '../../pts/adminPanel/adminSidebar/PaidAdvertiserSidebar';

const MOBILE_SIZE = 992;
// const MOBILE_SIZE = 768;

export class PaidAdvertiserLayout extends Component {
  // memberDetails = store.getState().memberData.memberData;
  // // loginUserName = store.getState().userData.userData.memberName;
  // loginUserName = store.getState().userData.userData.firstName + ' ' + store.getState().userData.userData.lastName;
  // header = store.getState().header.header;
  memberData = {};
  constructor(props) {
    super(props);
    // let pageId = '';

    if (props.history && props.history.location && props.history.location.state) {
      // pageId = props.history.location.state.pageId;
    }
    if (window.innerWidth > 992) {
      this.state = {
        sidebarCollapsed: true,
        isMobile: window.innerWidth <= MOBILE_SIZE,
        showChat1: true,
        memberMenuListArray: [],
        memberInfo: '',
        render: true,
        // user: this.header.email,
        props: this.props,
        helpModal: false,
        faqModal: false,
      };
    } else {
      this.state = {
        sidebarCollapsed: true,
        isMobile: window.innerWidth <= MOBILE_SIZE,
        showChat1: true,
        memberMenuListArray: [],
        memberInfo: '',
        render: true,
        // user: this.header.email,
        props: this.props,
        helpModal: false,
        faqModal: false,
      };
    }
  }

  handleResize = () => {
    if (window.innerWidth <= MOBILE_SIZE) {
      this.setState({ sidebarCollapsed: false, isMobile: true });
    } else {
      this.setState({ sidebarCollapsed: true, isMobile: true });
    }
  };

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
    document.addEventListener('keydown', handleKeyAccessibility);
    document.addEventListener('click', handleClickAccessibility);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  toggleSideCollapse = () => {
    if (this.state.sidebarCollapsed === true) {
      this.setState({
        sidebarCollapsed: false,
      });
    } else {
      this.setState({
        sidebarCollapsed: true,
      });
    }
  };

  closeChat = () => {
    this.setState({ showChat1: false });
  };

  termsAndConditionsHandler = (e) => {
    this.props.history.push('/termsCondition');
  };

  profileHandler = (e) => {
    this.props.history.push({
      pathname: '/profileHome',
    });
  };

  navigateToLink(linkToNavigate) {
    this.props.history.push(linkToNavigate);
  }

  helpModelToggle = () => {
    this.setState({
      helpModal: !this.state.helpModal,
    });
  };

  helpModalOpen = () => {
    this.setState({
      helpModal: true,
    });
  };
  faqModalOpen = () => {
    this.setState({
      faqModal: true,
    });
  };

  faqModalToggle = () => {
    this.setState({
      faqModal: !this.state.faqModal,
    });
  };
  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      const { sidebarCollapsed } = this.state;
      const sidebarCollapsedClass = !sidebarCollapsed ? 'side-menu-collapsed' : '';
      let memberNavBar = (
        <PaidAdvertiserSidebar
          isSidebarCollapsed={this.state.sidebarCollapsed}
          toggleSidebar={this.toggleSideCollapse.bind(this)}
          {...this.props}
          routes={routes}
          logo={{
            innerLink: '/adminUserProfile',
            imgSrc: fccLogo,
            imgAlt: '...',
          }}
        />
      );
      return (
        <>
          <ContextProviders>
            <div className={`app ${sidebarCollapsedClass}`}>
              {/* <div className={`app ${this.state.sidebarCollapsedClass}`}> */}
              <PageAlert />
              <div className="app-body">
                <AdminNavbar
                  // brandText={getBrandText(this.props.location.pathname)}
                  history={this.props.history}
                  toggleSidebar={this.toggleSideCollapse}
                  isSidebarCollapsed={sidebarCollapsed}
                  routes={routes}
                  {...this.props}
                />

                <Page>
                  {memberNavBar}
                  <PageContent>
                    <Switch>
                      {routes.map((page, index) => (
                        <Route path={page.path} component={page.component} key={index} />
                      ))}
                      <Redirect to="/ApLogin" />
                    </Switch>
                  </PageContent>
                </Page>
              </div>

              <AdminFooter />
            </div>
          </ContextProviders>
        </>
      );
    }
  }
}
export default withRouter(PaidAdvertiserLayout);
