import { CLEAR_SPONSORSHIP, GET_SPONSORSHIP, SET_SPONSORSHIP } from './SponsorshipTypes';

const initialState = {
  Sponsorship: {},
  SponsorshipDetails: {},
};

const SponsorshipReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_SPONSORSHIP:
      return {
        ...state,
      };
    case SET_SPONSORSHIP:
      return {
        ...state,

        Sponsorship: action.payload.sponsorUser,
        SponsorshipDetails: action.payload.sponsorship,
      };

    case CLEAR_SPONSORSHIP:
      return {
        ...state,
        Sponsorship: null,
      };
    default:
      return state;
  }
};

export default SponsorshipReducer;
