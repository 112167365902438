import Tooltip from '@material-ui/core/Tooltip';
import PrintRoundedIcon from '@material-ui/icons/PrintRounded';
import React, { Component } from 'react';
import Button from '@material-ui/core/Button';

export class PrintIconButton extends Component {
  constructor(props) {
    super(props);
    this.state = {
      domainObject: props.onClick,
      onClick: props.onClick,
      caption: props.caption,
    };
  }

  onClick() {
    if (this.state.onClick) {
      this.state.onClick();
    }
  }
  render() {
    return (
      <span
        onClick={this.onClick.bind(this)}
        style={{ cursor: 'pointer', fontSize: 'x-larger', marginRight: '0.5em', float: 'right' }}
      >
        <Tooltip title="Print">
          <Button
            className="add"
            onClick={this.onClick.bind(this)}
            style={{
              background: '#867e7e',
              color: 'white',
              fontSize: 'x-large',
              textTransform: 'capitalize',
              cursor: 'pointer',
              fontSize: 'x-larger',
              float: 'right',
              margin: '0.2em 0.5em 0.3em 0em',
            }}
            startIcon={<PrintRoundedIcon />}
          >
            Print Preview
          </Button>
        </Tooltip>
      </span>
    );
  }
}
export default PrintIconButton;
