import GoogleMapReact from 'google-map-react';
import React from 'react';
import Geocode from 'react-geocode';
import Marker from './Marker';
// implementation of this function is needed for codesandbox example to work
// you can remove it otherwise
const distanceToMouse = (pt, mp) => {
  if (pt && mp) {
    // return distance between the marker and mouse pointer
    return Math.sqrt((pt.x - mp.x) * (pt.x - mp.x) + (pt.y - mp.y) * (pt.y - mp.y));
  }
};

Geocode.setApiKey('AIzaSyC2X652RPdeR7LYXtbK6X7Vemh_VEQdgMM');
Geocode.setLanguage('en');
Geocode.setLocationType('ROOFTOP');

// Enable or disable logs. Its optional.
Geocode.enableDebug();

export default function Map(props) {
  console.log(props, 'props');
  return (
    <GoogleMapReact
      style={{ position: 'absolute !important', height: '40em' }}
      yesIWantToUseGoogleMapApiInternals={true}
      bootstrapURLKeys={{
        key: 'AIzaSyC2X652RPdeR7LYXtbK6X7Vemh_VEQdgMM',
        language: 'en',
        region: 'US',
      }}
      defaultCenter={{ lat: props.data[0].lat, lng: props.data[0].lng }}
      defaultZoom={13}
      distanceToMouse={distanceToMouse}
    >
      {props.data.map(({ lat, lng, id, title, shortDescription, line, city, country, state }) => {
        console.log('state', state);
        return (
          <Marker
            key={id}
            lat={lat}
            lng={lng}
            text={id}
            tooltip={title}
            shortDescription={shortDescription ? shortDescription : ''}
            line={line}
            city={city ? city : ''}
            country={country}
            state={state}
          />
        );
      })}
    </GoogleMapReact>
  );
}
