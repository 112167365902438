import {
  CLEAR_MEMBER_COMMUNICATION_STATE,
  GET_MEMBER_COMMUNICATION_STATE,
  SET_MEMBER_COMMUNICATION_STATE,
} from './memberCommunicationStateTypes';

const initialState = {
  memberCommunicationState: {},
};

const memberCommunicationStateReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MEMBER_COMMUNICATION_STATE:
      return {
        ...state,
      };
    case SET_MEMBER_COMMUNICATION_STATE:
      return {
        ...state,
        memberCommunicationState: action.payload,
      };

    case CLEAR_MEMBER_COMMUNICATION_STATE:
      return {
        ...state,
        memberCommunicationState: null,
      };
    default:
      return state;
  }
};

export default memberCommunicationStateReducer;
