import { CLEAR_BREADCRUMB_DETAILS, GET_BREADCRUMB_DETAILS, SET_BREADCRUMB_DETAILS } from './BreadCrumbDetailsType';

const initialState = {
  breadCrumbDetails: [],
};

const BreadCrumbDetailsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_BREADCRUMB_DETAILS:
      return {
        ...state,
      };
    case SET_BREADCRUMB_DETAILS:
      return {
        ...state,

        breadCrumbDetails: action.payload,
      };

    case CLEAR_BREADCRUMB_DETAILS:
      return {
        ...state,

        breadCrumbDetails: null,
      };
    default:
      return state;
  }
};

export default BreadCrumbDetailsReducer;
