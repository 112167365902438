import MaterialTable from '@material-table/core';
import React, { Component } from 'react';
import { Col, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import UiUtils from '../../../provider/UiUtils';
import Utils from '../../../provider/Utils';
import adminFccPageStateAction from '../../../redux/adminPanel/adminFccPageState/adminFccPageStateAction';
import applicationStateAction from '../../../redux/applicationState/applicationStateAction';
import store from '../../../redux/store';
import AdminMenuWithBreadScrum from '../adminMenu/AdminMenuWithBreadScrum';
import BreadCrumbDetailsAction from '../../../redux/breadCrumbDetails/BreadCrumbDetailsAction';

class EmailTemplateList extends Component {
  title = 'Email Template List';
  breadCrumb = [{ pageid: '/emailTemplateList', uiname: 'Email Template List' }];
  header = store.getState().adminHeader.adminHeader;
  emailTemplateState = store.getState().applicationState.emailTemplateState;
  emailTemplateDetails = {};
  constructor(props) {
    super(props);
    store.dispatch(applicationStateAction.setApplicationState({}));
    store.dispatch(adminFccPageStateAction.setAdminFccPageState());
    if (this.emailTemplateState) {
      this.emailTemplateDetails = Object.getOwnPropertyNames(this.emailTemplateState);
    }
    if (this.breadCrumb) {
      Utils.getAdminPanelActiveMenu(this.breadCrumb);
      store.dispatch(BreadCrumbDetailsAction.setBreadCrumbDetails(this.breadCrumb));
    }
    if (this.emailTemplateDetails.length > 0) {
      this.state = {
        emailTemplateList: [],
        isPaging: false,
        initialPage:
          this.emailTemplateState.initialPage && this.emailTemplateState.initialPage > 0
            ? this.emailTemplateState.initialPage
            : 0,
        pageSize:
          this.emailTemplateState.pageSize && this.emailTemplateState.pageSize ? this.emailTemplateState.pageSize : 0,
        emailTemplateId: this.emailTemplateState.emailTemplateId,
        render: false,
      };
    } else {
      this.state = {
        emailTemplateList: [],
        isPaging: false,
        initialPage: 0,
        pageSize: 10,
        emailTemplateId: '',
        render: false,
      };
    }
    this.getEmailTemplateList();
  }

  getEmailTemplateList() {
    const postObject = {
      header: this.header,
    };

    FetchServerData.callPostService('/emailTemplatems/getList', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let emailTemplateList = output.data.emailTemplateList;
        let isPaging = false;
        if (emailTemplateList.length > 10) {
          isPaging = true;
        }
        this.setState({
          isPaging: isPaging,
          emailTemplateList: emailTemplateList,
          render: true,
          initialPage: this.state.initialPage,
          pageSize: this.state.pageSize,
        });
      } else {
      }
    });
  }

  toEmailTemplateDetails(e, data) {
    let emailTemplateState = {
      emailTemplateId: data.id,
      initialPage: this.state.initialPage,
      pageSize: this.state.pageSize,
    };
    store.dispatch(applicationStateAction.setApplicationState({ emailTemplateState }));
    let adminFccPageState = {
      id: data.id,
      action: 'view',
      title: 'Email Template Details',
      pageId: 'sponsorList',
    };
    store.dispatch(adminFccPageStateAction.setAdminFccPageState(adminFccPageState));
    this.props.history.push({
      pathname: '/viewEmailTemplate',
      state: { action: 'view', title: 'Email Template Details', emailTemplateId: data.id, pageId: 'sponsorList' },
    });
  }

  handleMaterialTablePageChange(page, pageSize) {
    this.setState({ initialPage: page, pageSize: pageSize });
  }

  render() {
    if (this.state.render === false) {
      return <div />;
    } else {
      return (
        <>
          <AdminMenuWithBreadScrum breadCrumb={this.breadCrumb} history={this.props.history} name={this.title} />

          <Row style={{ position: 'relative', margin: '1em 0em', maxWidth: '100%' }}>
            <Col md={12}>
              <MaterialTable
                onRowClick={(event, rowData) => {
                  this.toEmailTemplateDetails(event, rowData);
                  event.stopPropagation();
                }}
                icons={UiUtils.getMaterialTableIcons()}
                title=""
                localization={{
                  body: {
                    emptyDataSourceMessage: Utils.communizeTableText(),
                  },
                }}
                columns={[
                  {
                    title: '#',
                    render: (rowData) => rowData.tableData.index + 1,

                    width: '5%',
                    align: 'left',
                  },
                  {
                    title: 'Name',
                    field: 'uid',
                    width: '35%',
                  },
                  {
                    title: 'Subject',
                    field: 'subject',
                    width: '30%',
                  },
                  {
                    title: 'Description',
                    field: 'description',
                    width: '30%',
                    // cellStyle: { textTransform: 'none' },
                  },
                ]}
                data={this.state.emailTemplateList}
                onPageChange={(page, pageSize) => {
                  this.handleMaterialTablePageChange(page, pageSize);
                }}
                options={{
                  sorting: true,
                  actionsColumnIndex: -1,
                  initialPage: this.state.initialPage,
                  pageSize: this.state.pageSize,
                  paging: this.state.emailTemplateList.length > this.state.pageSize,
                  headerStyle: {
                    backgroundColor: '#066f72',
                    fontWeight: 'bold',
                    color: '#fff',
                    align: 'left',
                  },
                  pageSizeOptions: [5, 10, 20, 50, 100],
                  filtering: true,
                  // paging: this.state.isPaging,
                  rowStyle: (rowData) => ({
                    backgroundColor: rowData.id === this.state.emailTemplateId ? '#FEF5E7' : '#fff',
                    height: '3em',
                    textTransform: 'capitalize',
                  }),

                  tableLayout: 'auto',
                }}
                actions={[]}
              />
            </Col>
          </Row>
        </>
      );
    }
  }
}

export default EmailTemplateList;
