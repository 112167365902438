import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Button from '@material-ui/core/Button';
import Fab from '@material-ui/core/Fab';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import CreateIcon from '@material-ui/icons/Create';
import EditIcon from '@material-ui/icons/Edit';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import es from 'date-fns/locale/es';
import moment from 'moment';
import React, { Component } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { ValidatorForm } from 'react-material-ui-form-validator';
import { Link } from 'react-router-dom';
import { CardHeader, Col, Modal, ModalBody, ModalHeader, Row } from 'reactstrap';
import FetchServerData from '../../../provider/FetchServerData';
import Utils from '../../../provider/Utils';
import store from '../../../redux/store';
import CancelIconButton from '../buttons/CancelIconButton';
import CreateButton from '../buttons/CreateButton';
import DeleteIconButton from '../buttons/DeleteIconButton';
import SaveButton from '../buttons/SaveButton';
import Location from '../location/Location';
import PtsAlert from '../ptsAlert/PtsAlert';
import Reminder from '../reminder/Reminder';
import './appointmentNotes.scss';
import Transportation from './Transportation';
registerLocale('es', es);
export default class AppointmentFacility extends Component {
  reminderIntervalList = store.getState().staticData.reminderIntervalList;
  taskOccuranceList = store.getState().staticData.taskOccuranceList;
  header = store.getState().header.header;
  memberDetails = store.getState().memberData.memberData;
  reminderRefList = [];
  locationReference = [];
  appointmentTransportationRef = [];
  originalState = null;
  constructor(props) {
    super(props);
    let action = props.action;
    let isButtonHide = false;
    if (props.pageId === 'editMemberAppointments') {
      isButtonHide = true;
    }
    if (action === 'create') {
      this.state = {
        id: '',
        note: '',
        appointmentStartDate: props.startDate,
        dateWord: '',
        occurrence: '',
        operation: '',
        index: '',
        onDelete: '',
        onChange: '',
        onEdit: '',
        onAddFacilityTransportation: '',
        onFacilityDelete: '',
        onNoteUpdate: '',
        onClick: '',
        action: action,
        render: true,
        startDate: '',
        isButtonHide: isButtonHide,
        taskNote: '',
        taskOccurrence: '',
        facility: '',
        noteDate: new Date(),
        facilityDate: '',
        facilityCreatedBy: '',
        facilityCreatedDate: '',
        facilityUpdatedDate: '',
        reminderItems: [],
        onFacilityAdd: props.onFacilityAdd,
        onFacilityCancel: props.onFacilityCancel,
        facilityList: [],
        onUpdate: props.onUpdate,
        facilityLocation: '',
        transportationModal: true,
        transportationList: [],
        pageId: props.pageId,
        onCancel: props.onCancel,
        appointmentTaskId: '',
        facilityTransportationAddress: {},
        expanded: true,
      };
    } else {
      let eventTransportationAddress = props.addressData;
      this.state = {
        appointmentTaskId: '',
        id: props.data.id,
        appointmentStartDate: props.startDate,
        note: props.data.note,
        dateWord: moment(props.data.date).format('DD MMM YYYY'),
        occurrence: props.data.occurance.occurance,
        operation: props.data.operation,
        date: props.data.date,
        index: props.srNo,
        onDelete: props.onDelete,
        onChange: props.onChange,
        onEdit: props.onEdit,
        onAddFacilityTransportation: props.onAddFacilityTransportation,
        onFacilityDelete: props.onFacilityDelete,
        onNoteUpdate: props.onNoteUpdate,
        onClick: props.onClick,
        action: action,
        render: true,
        startDate: props.data.createdOn,
        isButtonHide: isButtonHide,
        taskNote: props.data.note,
        taskOccurrence: props.data.occurance.occurance,
        appointmentTaskReminderList: props.data.reminderList,
        facility: '',
        facilityCreatedBy: props.data.createdByName,
        facilityCreatedDate: props.data.createdOn,
        facilityUpdatedDate: props.data.updatedOn,
        reminderItems: props.data.reminderList,
        onFacilityAdd: props.onFacilityAdd,
        onFacilityCancel: props.onFacilityCancel,
        facilityList: [],
        onUpdate: props.onUpdate,
        facilityLocation: props.data.facilityLocation,
        appointmentTransportationAddress: eventTransportationAddress,
        transportationModal: false,
        transportationList: [],
        pageId: props.pageId,
        onCancel: props.onCancel,
        facilityTransportationAddress: {},
        expanded: true,
      };
      this.viewFacilityTask(this.state.id);
    }
    window.scrollTo(0, 0);
    this.originalState = this.state;
  }

  componentDidMount() {
    const postObject = {
      header: this.header,
      facility: {
        member: {
          id: this.memberDetails.member,
        },
      },
    };

    FetchServerData.callPostService('/facilityms/getMemberFacilityLocations/', postObject).then((output) => {
      if (output.status === 'SUCCESS') {
        let facilityList = output.data;
        this.setState({
          facilityList: facilityList,
        });
      } else {
      }
    });
  }

  handleFacilityChange(e) {
    this.setState({ facility: e.target.value });
  }

  handleOccurrenceChange(e) {
    this.setState({ taskOccurrence: e.target.value });
  }

  handleDateChange(date) {
    this.setState({ noteDate: date });
  }

  handleNoteChange(e) {
    let val = e.target.value;
    let res = val.substring(0, 256);
    if (res.length < 256) {
      this.setState({ taskNote: res });
    } else {
      Utils.maxFieldLength(256);
    }
  }

  updateReminderDate(e) {
    this.setState({ date: e.target.value });
  }

  handleDescriptionChange(e) {
    let val = e.target.value;
    this.setState({ note: val });
  }

  handleDescriptionBlurChange(e) {
    let val = e.target.value;
    this.setState({ note: val });
  }
  handleStartDateChange(e) {
    this.setState({ amount: e.target.value });
  }

  toTransportationRequest() {
    this.setState({
      transportationModal: true,
    });
  }

  appointmentNoteRemove(e) {
    if (undefined !== this.state.onDelete && null !== this.state.onDelete) {
      this.state.onDelete(this.state);
    }
  }

  editFacilityTask(e) {
    const postObject = {
      header: this.header,
      appointmentTask: {
        id: this.state.id,
      },
    };

    FetchServerData.callPostService('/appointmentTaskms/get', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let facilityTaskData = output.data;
          let startDate = '';
          let newStartDate = '';
          let viewStartDate = '';

          if (facilityTaskData.date !== undefined) {
            startDate = facilityTaskData.date;

            newStartDate = Utils.getUtcToLocalDate(startDate);
            viewStartDate = Utils.getUtcToLocalDate(startDate);
          }
          if (facilityTaskData.reminderList.length > 0) {
            for (let i = 0; i < facilityTaskData.reminderList.length; i++) {
              let ref = React.createRef();
              this.reminderRefList.push(ref);
              facilityTaskData.reminderList[i].operation = 'update';
            }
            this.setState({
              appointmentTaskId: facilityTaskData.id,
              taskNote: facilityTaskData.note,
              taskOccurrence: facilityTaskData.occurance.occurance,
              reminderItems: facilityTaskData.reminderList,
              facility: facilityTaskData.facilityLocation.id,
              facilityTransportationAddress: facilityTaskData.facilityLocation.address,
              noteDate: new Date(newStartDate),
              facilityDate: viewStartDate,
              facilityId: facilityTaskData.facilityLocation.facility.id,
              facilityLocationId: facilityTaskData.facilityLocation.id,
              action: 'edit',
              render: true,
            });
          } else {
            this.setState({
              appointmentTaskId: facilityTaskData.id,
              taskNote: facilityTaskData.note,
              taskOccurrence: facilityTaskData.occurance.occurance,
              reminderItems: [],
              facility: facilityTaskData.facilityLocation.id,
              facilityTransportationAddress: facilityTaskData.facilityLocation.address,
              noteDate: new Date(newStartDate),
              facilityDate: viewStartDate,
              facilityId: facilityTaskData.facilityLocation.facility.id,
              action: 'edit',
              render: true,
            });
          }
        } else {
        }
      })
      .catch((error) => {});
  }

  viewFacilityTask(e) {
    const postObject = {
      header: this.header,
      appointmentTask: {
        id: this.state.id,
      },
    };

    FetchServerData.callPostService('/appointmentTaskms/get', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let facilityTaskData = output.data;
          let startDate = '';
          let newStartDate = '';
          let viewStartDate = '';

          if (facilityTaskData.date !== undefined) {
            startDate = facilityTaskData.date;
            newStartDate = Utils.getUtcToLocalDate(startDate);
            viewStartDate = Utils.getUtcToLocalDate(startDate);
          }
          if (facilityTaskData.reminderList.length > 0) {
            for (let i = 0; i < facilityTaskData.reminderList.length; i++) {
              let ref = React.createRef();
              this.reminderRefList.push(ref);
              facilityTaskData.reminderList[i].operation = 'update';
            }
            this.setState({
              appointmentTaskId: facilityTaskData.id,
              noteDate: new Date(newStartDate),
              facilityDate: viewStartDate,
              facilityLocationId: facilityTaskData.facilityLocation.id,
              facilityId: facilityTaskData.facilityLocation.facility.id,
              facilityTransportationAddress: facilityTaskData.facilityLocation.address,
              reminderItems: facilityTaskData.reminderList,
              transportationList: facilityTaskData.transportationList,
            });
          } else {
            this.setState({
              appointmentTaskId: facilityTaskData.id,
              noteDate: new Date(newStartDate),
              facilityDate: viewStartDate,
              facilityTransportationAddress: facilityTaskData.facilityLocation.address,
              facilityLocationId: facilityTaskData.facilityLocation.id,
              reminderItems: [],
              facilityId: facilityTaskData.facilityLocation.facility.id,
              transportationList: facilityTaskData.transportationList,
            });
          }
        } else {
        }
      })
      .catch((error) => {});
  }

  deleteFacility() {
    if (this.state.onDelete) {
      this.state.onDelete(this.state);
    }
  }

  deleteAlert(e) {
    if (undefined !== this.state.onFacilityDelete && null !== this.state.onFacilityDelete) {
      this.state.onFacilityDelete(this.state);
    }
  }

  updateAppointmentNotes(e) {
    if (undefined !== this.state.onNoteUpdate && null !== this.state.onNoteUpdate) {
      this.setState({ action: 'view' });
      this.state.onNoteUpdate(this.state);
    }
  }

  viewAppointmentTask(e) {
    if (undefined !== this.state.onClick && null !== this.state.onClick) {
      this.state.onClick(this.state);
    }
  }

  backToViewAction() {
    let newState = this.originalState;
    newState.action = 'view';
    this.setState(newState);
  }
  transportationToggle = () => {
    this.setState({ transportationModal: !this.state.transportationModal });
  };
  addReminder() {
    let items = this.state.reminderItems;
    let item = {
      action: 'create',
      operation: 'add',
    };
    items.push(item);
    this.setState({
      reminderItems: items,
    });
  }

  addFacilityTaskInBackend(e) {
    e.preventDefault();
    if (this.state.onFacilityAdd) {
      this.state.onFacilityAdd(this.state);
    }
  }
  updateFacilityTaskInBackend(e) {
    if (this.state.onUpdate) {
      this.state.onUpdate(this.state);
    }
  }
  cancelFacilityTask(e) {
    this.setState({ action: 'view' });
  }
  cancelFacility(e) {
    if (this.state.onCancel) {
      this.state.onCancel(this.state);
    }
  }
  addNewReminder = (reminderData) => {
    for (let i = 0; i < this.state.reminderItems.length; i++) {
      if (
        this.state.reminderItems[i] &&
        reminderData.index === i &&
        this.reminderRefList[i] &&
        this.reminderRefList[i].current &&
        this.reminderRefList[i].current.validateReminder(i)
      ) {
        this.state.reminderItems[i] = reminderData;
        this.reminderRefList[i].current.state = reminderData;
        this.reminderRefList[i].current.state.action = 'view';
        this.setState({ render: true });
      }
    }
  };

  updateReminder = (reminderData) => {
    for (let i = 0; i < this.state.reminderItems.length; i++) {
      if (
        this.state.reminderItems[i] &&
        reminderData.index === i &&
        this.reminderRefList[i] &&
        this.reminderRefList[i].current &&
        this.reminderRefList[i].current.validateReminder()
      ) {
        this.state.reminderItems[i] = reminderData;
        this.reminderRefList[i].current.state = reminderData;
        this.reminderRefList[i].current.state.action = 'view';
        this.setState({ render: true });
      }
    }
  };
  reminderItemDeleted(item) {
    let items = [];
    if (item.operation && 'update' === item.operation) {
      /* Existing Item Deleted, Mark it deleted */
      let index = item.index;

      for (let i = 0; i < this.state.reminderItems.length; i++) {
        let item = this.state.reminderItems[i];

        if (this.state.reminderItems[i].operation === 'update' && index === i) {
          item.operation = 'delete';
        }

        items.push(item);
      }

      this.setState({ reminderItems: items });
    }
    if (item.operation && 'add' === item.operation) {
      /* Added Item Deleted, Remove from list */
      let index = item.index;
      for (let i = 0; i < this.state.reminderItems.length; i++) {
        if (this.state.reminderItems[i].operation === 'add' && index === i) {
          this.reminderRefList.splice(i, 1);
          // continue;
        } else {
          items.push(this.state.reminderItems[i]);
        }
      }
      this.setState({ reminderItems: items });
    }
  }
  handleAppointmentTransportationChange(inputTransportation) {
    this.setState({ appointmentTransportation: inputTransportation });
  }

  addTransportationInBackend = (transportationData) => {
    let transportationReminder = [];
    let postObject = {};

    if (this.appointmentTransportationRef.current.state) {
      let reminderList = this.appointmentTransportationRef.current.state.transportationReminder;
      for (let i = 0; i < reminderList.length; i++) {
        let reminderDateTime = new Date(reminderList[i].startDate).toISOString();

        let reminderObj = {
          content: reminderList[i].note,
          date: reminderDateTime,

          operation: reminderList[i].operation,
        };
        transportationReminder.push(reminderObj);
      }
      let appointmentTransportationDate = this.appointmentTransportationRef.current.state.transportationDate;

      let dateTime = Utils.convertUserTimeZoneDateToUTCDate(appointmentTransportationDate);

      postObject = {
        header: this.header,

        transportation: {
          date: dateTime,

          description: this.appointmentTransportationRef.current.state.transportationDesc,
          accompany: this.appointmentTransportationRef.current.state.contactMemberName,
          address: {
            line: this.appointmentTransportationRef.current.state.line,
            city: this.appointmentTransportationRef.current.state.city,
            zipCode: this.appointmentTransportationRef.current.state.zipCode,
            state: {
              code: this.appointmentTransportationRef.current.state.state,
            },
          },
          accompanyType: {
            type: this.appointmentTransportationRef.current.state.transportationType,
          },

          appointmentTask: {
            id: this.state.id,
          },
          reminderList: transportationReminder,
        },
      };
    }

    FetchServerData.callPostService('/appTaskTransportms/create', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let outputData = output.data;
          let transportation = this.state.transportationList;
          transportation.push(outputData);
          PtsAlert.success(output.message);
          this.setState({
            transportationModal: false,
            transportationList: transportation,
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  updateTransportationInBackend = (transportationData) => {
    let transportationReminder = [];
    let postObject = {};
    let reminderObj = {};
    for (let i = 0; i < this.state.transportationList.length; i++) {
      if (i === transportationData.index) {
        let reminderList = this.appointmentTransportationRef[i].current.state.transportationReminder;
        for (let i = 0; i < reminderList.length; i++) {
          if (reminderList[i].operation === 'update') {
            transportationReminder.push(reminderList[i]);
          } else if (reminderList[i].operation === 'delete') {
            transportationReminder.push(reminderList[i]);
          } else {
            reminderObj = {
              content: reminderList[i].note,

              date: reminderList[i].startDate,

              operation: reminderList[i].operation,
            };
          }

          transportationReminder.push(reminderObj);
        }
        let newArray = transportationReminder.filter((value) => Object.keys(value).length !== 0);

        let appointmentTransportationDate = this.appointmentTransportationRef[i].current.state.transportationDate;

        let dateTime = Utils.convertUserTimeZoneDateToUTCDate(appointmentTransportationDate);

        postObject = {
          header: this.header,

          transportation: {
            id: this.appointmentTransportationRef[i].current.state.id,
            date: dateTime,

            description: this.appointmentTransportationRef[i].current.state.transportationDesc,
            accompany: this.appointmentTransportationRef[i].current.state.contactMemberName,
            appointmentTask: {
              id: this.state.appointmentTaskId,
            },
            address: {
              line: this.appointmentTransportationRef[i].current.state.line,
              city: this.appointmentTransportationRef[i].current.state.city,
              zipCode: this.appointmentTransportationRef[i].current.state.zipCode,
              state: {
                code: this.appointmentTransportationRef[i].current.state.state,
              },
            },
            accompanyType: {
              type: this.appointmentTransportationRef[i].current.state.transportationType,
            },

            appointment: {
              id: this.state.id,
            },
            reminderList: newArray,
          },
        };
      }
    }
    FetchServerData.callPostService('/appTaskTransportms/update', postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          let outputData = output.data;
          PtsAlert.success(output.message);
          for (let i = 0; i < this.state.transportationList.length; i++) {
            if (this.state.transportationList[i].id === outputData.id)
              this.state.transportationList[i].createdByName = outputData.createdByName;
          }
          for (let i = 0; i < this.state.transportationList.length; i++) {
            if (i === transportationData.index) {
              this.appointmentTransportationRef[i].current.state.action = 'view';
            }
          }

          this.setState({
            transportationModal: false,
            transportationList: this.state.transportationList,
            render: true,
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  deleteFacilityTransportationInBackend = (transportationData) => {
    const postObject = {
      header: this.header,
      transportation: {
        id: transportationData.id,
      },
    };
    let url = '/appTaskTransportms/delete/';
    FetchServerData.callPostService(url, postObject)
      .then((output) => {
        if (output.status === 'SUCCESS') {
          for (let i = 0; i < this.state.transportationList.length; i++) {
            if (this.state.transportationList[i].id === transportationData.id) {
              this.state.transportationList.splice(i, 1);
            }
          }
          this.setState({
            transportationList: this.state.transportationList,
            sweetAlert: false,
          });
        } else {
        }
      })
      .catch((error) => {});
  };

  render() {
    this.reminderRefList = [];
    for (let i = 0; this.state.reminderItems && i < this.state.reminderItems.length; i++) {
      this.reminderRefList[i] = React.createRef();
    }
    this.appointmentTransportationRef = [];
    for (let i = 0; this.state.transportationList && i < this.state.transportationList.length; i++) {
      this.appointmentTransportationRef[i] = React.createRef();
    }

    if (this.state.render === false) {
      return '';
    }

    if (this.state.action === 'create') {
      return this.createFacility();
    } else if (this.state.action === 'edit') {
      return this.editFacility();
    } else {
      return this.viewFacility();
    }
  }

  createFacility() {
    let reminderList = [];

    this.state.reminderItems.forEach((reminder, index) => {
      this.reminderRefList[index] = React.createRef();
      if ('delete' !== reminder.operation) {
        reminderList.push(
          <Col md={6} style={{ marginTop: '1em' }}>
            <Reminder
              ref={this.reminderRefList[index]}
              key={index}
              data={reminder}
              srNo={index}
              reminderDate={this.state.noteDate}
              pageId={this.state.pageId}
              action={reminder.action ? reminder.action : 'view'}
              onReminderAdd={this.addNewReminder.bind(this)}
              onDelete={this.reminderItemDeleted.bind(this)}
              onUpdate={this.updateReminder.bind(this)}
            />
          </Col>
        );
      }
    });
    return (
      <div>
        <ValidatorForm style={{ width: '100%' }} ref="form" onSubmit={this.addFacilityTaskInBackend.bind(this)}>
          <Row md={12} style={{ padding: '0.5em', marginBottom: '1.8em' }}>
            <Col md={6}>
              <div>
                <Row style={{ marginTop: '1.2em' }}>
                  <Col md={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="facility-label">Facility*</InputLabel>
                      <Select
                        // labelId="facility-label"
                        id="facility"
                        required
                        value={this.state.facility}
                        label="Facility"
                        onChange={(e) => {
                          this.handleFacilityChange(e);
                        }}
                        startAdornment={
                          <InputAdornment position="start">
                            <CreateIcon />
                          </InputAdornment>
                        }
                      >
                        {this.state.facilityList.map((event, keyIndex) => {
                          return (
                            <MenuItem key={keyIndex} value={event.locationId}>
                              {event.name}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                    <br></br>
                  </Col>

                  <Col md={6}>
                    <FormControl variant="outlined" fullWidth>
                      <InputLabel id="occurrence-label">Occurrence*</InputLabel>
                      <Select
                        // labelId="occurrence-label"
                        id="occurrence"
                        required
                        value={this.state.taskOccurrence}
                        label="occurrence"
                        onChange={(e) => {
                          this.handleOccurrenceChange(e);
                        }}
                        startAdornment={
                          <InputAdornment position="start">
                            <CreateIcon />
                          </InputAdornment>
                        }
                      >
                        {this.taskOccuranceList.map((event, keyIndex) => {
                          return (
                            <MenuItem key={keyIndex} value={event.occurance}>
                              {event.occurance}
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </FormControl>
                  </Col>
                </Row>

                <Row style={{ marginTop: '1.2em' }}>
                  <Col md={6}>
                    <DatePicker
                      selected={this.state.noteDate}
                      onChange={this.handleDateChange.bind(this)}
                      timeInputLabel="Time:"
                      dateFormat="MM/dd/yyyy h:mm aa"
                      placeholderText="Date*"
                      required
                      showTimeInput
                      style={{
                        border: '1px solid #c4c4c4 !important',
                      }}
                    />
                  </Col>

                  <Col md={6}>
                    <TextField
                      fullWidth
                      variant="outlined"
                      id="note"
                      label="Facility Visit Details"
                      required
                      inputProps={{ maxLength: 256 }}
                      value={this.state.taskNote}
                      onChange={this.handleNoteChange.bind(this)}
                      name="note"
                      type="text"
                      className="textField"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <CreateIcon />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Col>
                </Row>
              </div>
            </Col>

            <Col md={6}>
              <CardHeader>
                <Row>
                  <Col md={11}>Reminder</Col>
                  <Col md={1} style={{ float: 'right' }}>
                    <Tooltip title="Add Location" aria-label="add">
                      <Fab
                        aria-label="add"
                        style={{
                          float: 'right',
                          fontSize: 'small',
                          height: '15px',
                          width: '35px',
                          background: '#76767d',
                          cursor: 'pointer',
                          color: 'white',
                          border: '2px solid #edf0f1',
                        }}
                        onClick={this.addReminder.bind(this)}
                        id="iconButton"
                      >
                        <AddIcon />
                      </Fab>
                    </Tooltip>
                  </Col>
                </Row>
              </CardHeader>

              <Row>{reminderList}</Row>
            </Col>
          </Row>
          <div style={{ textAlign: 'center', padding: '0.5em' }}>
            <CreateButton>Create</CreateButton>
            &nbsp;
            <CancelIconButton onClick={(e) => this.cancelFacility()} />
          </div>
        </ValidatorForm>
      </div>
    );
  }

  editFacility() {
    let reminderList = [];

    this.state.reminderItems.forEach((reminder, index) => {
      this.reminderRefList[index] = React.createRef();
      if ('delete' !== reminder.operation) {
        reminderList.push(
          <Col md={6} style={{ marginTop: '1em' }}>
            <Reminder
              ref={this.reminderRefList[index]}
              key={index}
              data={reminder}
              srNo={index}
              reminderDate={this.state.noteDate}
              pageId={this.state.pageId}
              action={reminder.action ? reminder.action : 'view'}
              onReminderAdd={this.addNewReminder.bind(this)}
              onDelete={this.reminderItemDeleted.bind(this)}
              onUpdate={this.updateReminder.bind(this)}
            />
          </Col>
        );
      }
    });
    return (
      <div>
        <Row md={12} style={{ padding: '0.5em', marginBottom: '1.8em' }}>
          <Col md={12}>
            <div>
              <Row style={{ marginTop: '1.2em' }}>
                <Col md={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="facility-label">Facility*</InputLabel>
                    <Select
                      // labelId="facility-label"
                      id="facility"
                      required
                      value={this.state.facility}
                      label="Facility"
                      onChange={(e) => {
                        this.handleFacilityChange(e);
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <CreateIcon />
                        </InputAdornment>
                      }
                    >
                      {this.state.facilityList.map((event, keyIndex) => {
                        return (
                          <MenuItem key={keyIndex} value={event.locationId}>
                            {event.name}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                  <br></br>
                </Col>

                <Col md={6}>
                  <FormControl variant="outlined" fullWidth>
                    <InputLabel id="occurrence-label">Occurrence*</InputLabel>
                    <Select
                      // labelId="occurrence-label"
                      id="occurrence"
                      value={this.state.taskOccurrence}
                      label="occurrence"
                      required
                      onChange={(e) => {
                        this.handleOccurrenceChange(e);
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <CreateIcon />
                        </InputAdornment>
                      }
                    >
                      {this.taskOccuranceList.map((event, keyIndex) => {
                        return (
                          <MenuItem key={keyIndex} value={event.occurance}>
                            {event.occurance}
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                </Col>
              </Row>

              <Row style={{ marginTop: '1.2em' }}>
                <Col md={6}>
                  <DatePicker
                    selected={this.state.noteDate}
                    onChange={this.handleDateChange.bind(this)}
                    timeInputLabel="Time:"
                    dateFormat="MM/dd/yyyy h:mm aa"
                    placeholderText="Date*"
                    required
                    showTimeInput
                    style={{
                      border: '1px solid #c4c4c4 !important',
                    }}
                  />
                </Col>

                <Col md={6}>
                  <TextField
                    fullWidth
                    variant="outlined"
                    id="note"
                    label="Facility Visit Details"
                    value={this.state.taskNote}
                    inputProps={{ maxLength: 256 }}
                    onChange={this.handleNoteChange.bind(this)}
                    name="note"
                    type="text"
                    className="textField"
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CreateIcon />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
        <Row md={12}>
          <Col ms={12}>
            <CardHeader>
              <Row>
                <Col md={12}>Reminder</Col>
                <Col md={12} style={{ float: 'right' }}>
                  <Tooltip title="Add Location" aria-label="add">
                    <Fab
                      aria-label="add"
                      style={{
                        float: 'right',
                        fontSize: 'small',
                        height: '15px',
                        width: '35px',
                        background: '#76767d',
                        cursor: 'pointer',
                        color: 'white',
                        border: '2px solid #edf0f1',
                      }}
                      onClick={this.addReminder.bind(this)}
                      id="iconButton"
                    >
                      <AddIcon />
                    </Fab>
                  </Tooltip>
                </Col>
              </Row>
            </CardHeader>

            <Row>{reminderList}</Row>
          </Col>
        </Row>
        <Row style={{ padding: '13px' }}>
          <Col
            md={6}
            style={{
              textAlign: 'right',
            }}
          >
            <SaveButton type="submit" onClick={(e) => this.updateFacilityTaskInBackend()} />
            &nbsp;
            <CancelIconButton type="button" onClick={(e) => this.cancelFacilityTask()} />
          </Col>
        </Row>
      </div>
    );
  }

  viewFacility() {
    let reminderList = [];

    this.state.reminderItems.forEach((reminder, index) => {
      this.reminderRefList[index] = React.createRef();

      if ('delete' !== reminder.operation) {
        reminderList.push(
          <Col md={6} style={{ marginTop: '1em' }}>
            <Reminder
              ref={this.reminderRefList[index]}
              key={index}
              data={reminder}
              srNo={index}
              reminderDate={this.state.noteDate}
              pageId={this.state.pageId}
              action={reminder.action ? reminder.action : 'view'}
              onReminderAdd={this.addNewReminder.bind(this)}
              onDelete={this.reminderItemDeleted.bind(this)}
              onUpdate={this.updateReminder.bind(this)}
            />
          </Col>
        );
      }
    });

    let transportationList = [];
    this.state.transportationList.forEach((transportation, index) => {
      transportationList.push(
        <Col md={12}>
          <Transportation
            ref={this.appointmentTransportationRef[index]}
            pageId={this.state.pageId}
            title="Transportation Request"
            data={transportation}
            key={index}
            srNo={index}
            action="view"
            transportationType="facility"
            onDelete={this.deleteFacilityTransportationInBackend.bind(this)}
            onUpdate={this.updateTransportationInBackend.bind(this)}
          />
        </Col>
      );
    });
    return (
      <>
        <Accordion
          defaultExpanded={this.state.expanded}
          style={{
            marginTop: '1.6em',
            borderRadius: '20px',
            cursor: 'pointer',
            backgroundColor: '#D7BDE2',
          }}
        >
          <AccordionSummary
            expandIcon={
              <ExpandMoreIcon
                style={{
                  fontSize: 'small',
                  height: '2em',
                  width: '2em',
                  background: '#649e7f',
                  cursor: 'pointer',
                  color: 'white',
                  border: '2px solid #edf0f1',
                  borderRadius: '50%',
                  marginTop: '0em',
                }}
                id="iconButton"
              />
            }
            aria-controls="panel1a-content"
            id="panel1a-header"
            onClick={(e) => this.viewFacilityTask(e, this.state.appointmentTaskId)}
          >
            <Row md={12} className="accordionText" style={{ paddingTop: '22px' }}>
              <Col md={8}>
                <span style={{ color: '#2c60bf', padding: '0.5em' }}>
                  <strong>{this.state.taskNote}</strong>
                </span>
              </Col>
              <Col md={4} style={{ marginTop: '2px' }}>
                {this.state.isButtonHide && (
                  <>
                    <EditIcon
                      style={{
                        float: 'right',
                        fontSize: 'small',
                        height: '2em',
                        width: '2em',
                        background: '#76767d',
                        cursor: 'pointer',
                        color: 'white',
                        border: '2px solid #edf0f1',
                        borderRadius: '50%',
                        padding: '2px',
                      }}
                      onClick={(e) => this.editFacilityTask(e, this.state.id)}
                      id="iconButton"
                    />

                    <DeleteIconButton
                      domainObject="Appointment Note"
                      style={{
                        border: '2px solid #fff',
                        borderRadius: '25%',
                        background: '#066f72 !important',
                        color: 'white',
                        fontSize: 'x-large',
                      }}
                      onClick={(e) => this.deleteFacility(e, this.state.id)}
                    />
                  </>
                )}
              </Col>
              {/* </> */}
            </Row>

            <Row>
              <Col md={12} style={{ padding: 0, margin: 0 }}>
                <label
                  style={{
                    fontWeight: 'normal',
                    color: '#888888',
                    width: '100%',
                    fontSize: 'xx-small',
                    textAlign: 'left',
                    padding: '6px',
                    margin: '0',
                  }}
                >
                  {this.state.facilityCreatedBy && 'Created by ' + this.state.facilityCreatedBy}
                  {this.state.facilityCreatedDate &&
                    ' on ' + moment(this.state.facilityCreatedDate).format('DD MMM YYYY')}
                </label>
              </Col>
            </Row>
          </AccordionSummary>
          <AccordionDetails style={{ cursor: 'default' }}>
            <Link
              to={{
                pathname: '/viewFacility',

                state: {
                  action: 'view',
                  title: 'Facility Details',
                  facilityId: this.state.facilityId,
                  pageId: 'viewMemberAppointment',
                  locationId: this.state.facilityLocationId,
                },
              }}
              style={{ color: 'black', textDecoration: 'none' }}
            >
              <Col md={12}>
                <Row>
                  <Col md={3}>
                    <label>Date</label>
                  </Col>
                  <Col md={9}>{this.state.facilityDate}</Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <label>Occurance</label>
                  </Col>
                  <Col md={9}>{this.state.taskOccurrence}</Col>
                </Row>
                <Row>
                  <Col md={3}>
                    <label>Facility</label>
                  </Col>{' '}
                  <Col md={9}> {this.state.facilityLocation.facility.name} </Col>{' '}
                </Row>
              </Col>
            </Link>
            <Row>
              <Col md={12}>
                <Location ref={this.locationReference} data={this.state.facilityLocation} action="simpleView" />
              </Col>
            </Row>

            <Row style={{ margin: '0.5em 0em 0.5em 0em' }}>{reminderList}</Row>
            <Modal isOpen={this.state.transportationModal} toggle={this.transportationToggle} size="lg">
              <ModalHeader
                toggle={this.transportationToggle}
                style={{ borderBottom: '1px solid rgba(0, 0, 0, 0.125)' }}
              >
                Transportation Request
              </ModalHeader>
              <ModalBody>
                <Row>
                  <Transportation
                    ref={this.appointmentTransportationRef}
                    title="Transportation Request"
                    data={this.state.appointmentTransportation}
                    addressData={this.state.facilityTransportationAddress}
                    transportationDate={this.state.noteDate}
                    action="create"
                    pageId={this.state.pageId}
                    onChange={this.handleAppointmentTransportationChange.bind(this)}
                    onTransportationAdd={this.addTransportationInBackend.bind(this)}
                    onUpdate={this.updateTransportationInBackend.bind(this)}
                  />
                </Row>
              </ModalBody>
            </Modal>
            <Row>
              <Col md={12} style={{ padding: '1em' }}>
                <span style={{ color: '#2c60bf', padding: '0.5em' }}>
                  <strong>Facility Transportation</strong>
                </span>
              </Col>
            </Row>
            <Row>
              <Col md={12} style={{ padding: '1em' }}>
                <Button
                  type="submit"
                  style={{
                    backgroundColor: '#27AE60',
                    color: '#fff',
                    fontSize: '0.8em',
                  }}
                  variant="contained"
                  onClick={(e) => this.toTransportationRequest(e)}
                >
                  Raise Transportation request
                </Button>
              </Col>
            </Row>
            <Row>{transportationList}</Row>
          </AccordionDetails>
        </Accordion>
      </>
    );
  }
}
